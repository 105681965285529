import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./TDSRecon.css";
import SelectField from "../../../../components/ui/basicSelectInput";

import BoxWrapper from "../../../../components/ui/boxWrapper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import BasicInputField from "../../../../components/ui/basicInput"

const monthArray = [
  { id: 4, value: "April" },
  { id: 5, value: "May" },
  { id: 6, value: "June" },
  { id: 7, value: "July" },
  { id: 8, value: "August" },
  { id: 9, value: "September" },
  { id: 10, value: "October" },
  { id: 11, value: "November" },
  { id: 12, value: "December" },
  { id: 1, value: "January" },
  { id: 2, value: "February" },
  { id: 3, value: "March" },
];

export default function Gtsr9InwardComparison() {
  let history = useHistory();
  const formRef = useRef(null);
  const [newReconFormOpen, setNewReconFormOpen] = useState(false);
  const [newReconFormData, setNewReconFormData] = useState({
    business_entity_id: localStorage.getItem("business_entity_id"),
  });

  const [newReconHistoryTable, setNewReconHistoryTable] = useState([]);
  const [yearFromArr, setFromYearArr] = useState([]);
  const [monthsArrau, setMonthsArray] = useState(monthArray)


  const [errorMessageForBoxWrapper, setErrorMessageForBoxWrapper] = useState("");
  const [closeBoxWrapper, setCloseBoxWrapper] = useState(false);
  const [loaderForTable, setLoaderForTable] = useState(true);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedFromMonth, setSelectedFromMonth] = useState("");
  const [selectedToMonth, setSelectedToMonth] = useState("");
  const [selectedComparisonType, setSelectedComparisonType] = useState("");

  const handleComparisonTypeChange = (e) => {
    console.log(e.target)
    setSelectedComparisonType(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleFromMonthChange = (e) => {
    setSelectedFromMonth(e.target.value);
  };

  const handleToMonthChange = (e) => {
    setSelectedToMonth(e.target.value);
  };

  const getTdsReconDetails = () => {
    toast.configure();
    axios
      .get(
        `/gstr9_comparison_summary/${localStorage.getItem(
          "business_entity_id"
        )}/Inward Comparison`
      )
      .then((res) => {
        setLoaderForTable(false);
        setNewReconHistoryTable(res.data.records);
        setFromYearValues(res.data.financial_years)
      })
      .catch((error) => {
        setLoaderForTable(false);
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    const payloadData = {
      'financial_year': selectedYear,
      'from_month': selectedFromMonth,
      'to_month': selectedToMonth,
      'type1': 'Inward Comparison',
      'type2': selectedComparisonType,
      'business_entity_id': localStorage.getItem("business_entity_id")
    }

    axios.post('/gstr9_comparison', payloadData)
      .then((res) => {
        getTdsReconDetails();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors, {
          autoClose: 3000,
          progress: 0,
        });
      })

    newReconFormModalClose()
  };

  useEffect(() => {
    getTdsReconDetails();
    setFromYearValues()
  }, []);

  const newReconFormModalOpen = () => {
    setNewReconFormOpen(true);

  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const genarateExcel = (item) => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `${item.comparison_type2}.xlsx`;


    axios.get(`/gstr9_comparison_report/${item.id}`)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      }).catch((err) => {

      })
  }


  const newReconFormModalClose = () => {
    setNewReconFormOpen(false);
    setSelectedYear('')
    setSelectedFromMonth('')
    setSelectedToMonth('')
    setSelectedComparisonType('')
    setNewReconFormData({
      business_entity_id: localStorage.getItem("business_entity_id"),
    });
  };


  const historyTableBody = () => {
    const showErrorDetails = (item) => {
      axios
        .get(`/form_26/get_json/${item.id}`)
        .then((res) => {
          setErrorMessageForBoxWrapper(res?.data?.records?.message);
          setCloseBoxWrapper(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };


    const updatedShowDetails = (item) => {
      localStorage.setItem("gstr9_financail_year", `${item.financial_year}`);
      history.push({
        pathname: `/GSTR9-ReportingPage/${item.id}`,
      });
    };

    if (loaderForTable) {
      console.log("Loader");
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={4}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (newReconHistoryTable && newReconHistoryTable.length > 0) {
      return newReconHistoryTable.map((item, index) => {

        return (
          <tr key={index}>
            <td>{item.financial_year}</td>
            <td>{item.from_month}</td>
            <td>{item.to_month}</td>
            <td>{item.run_date_time}</td>
            <td>{item.comparison_type2}</td>
            <td>{item.comparison_status}</td>
            <td>
              {
                (item.comparison_status === "Pass") ? (
                  <img onClick={() => genarateExcel(item)} style={{ marginLeft: '5px', height: '23px', width: '25px', cursor: 'pointer' }} src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg" />
                ) : (
                  <img style={{ marginLeft: '5px', height: '23px', width: '25px', cursor: 'pointer' }} src="https://res.cloudinary.com/dkcsi1cmy/image/upload/v1715854976/ohmrrio7mu8yzdzdzrrs.svg" />
                )
              }
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={4}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  const setFromYearValues = () => {
    let current_year = new Date().getFullYear();
    let yearArr = [];
    for (let i = current_year - 10; i <= current_year; i++) {
      const toYear = String(i + 1)
      yearArr.push({ id: i, year: `${i}-${toYear.slice(2)}` });
    }
    setFromYearArr(yearArr);
  };

  const refreshUploadHistoryTable = () => {
    getTdsReconDetails();
  };

  const FunForcloseBoxWapper = () => {
    setCloseBoxWrapper(false);
  };

  return (
    <>
      <div className="new-reconciliation-form">
        <button
          className="button-outlined-primary"
          onClick={newReconFormModalOpen}
        >
          Inward Comparison
        </button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={newReconFormOpen}
          onHide={newReconFormModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton className="modal-header">
            <Modal.Title className="modal-title">
              Inward Comparison
            </Modal.Title>
          </Modal.Header>

          <ValidatorForm
            onSubmit={handleSubmit} onError={(errors) => console.log(errors)}
          >
            <Modal.Body>

              <div
                style={{
                  padding: "0px 20px 20px 20px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    <p style={{ fontSize: "16px" }}>
                      Select Financial Year
                      <span style={{ color: "red" }}>*</span>:
                    </p>
                  </Grid>
                  <Grid item sm={9}>
                    <SelectField
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choice_id="year"
                      choice_name="year"
                      name="year"
                      choices={yearFromArr}
                      value={selectedYear}
                      onChange={handleYearChange}
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  padding: "0px 20px 20px 20px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    <p style={{ fontSize: "16px" }}>
                      From Month<span style={{ color: "red" }}>*</span>:
                    </p>
                  </Grid>
                  <Grid item sm={3}>
                    <SelectField
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choice_id="value"
                      choice_name="value"
                      name="from_month"
                      value={selectedFromMonth}
                      onChange={handleFromMonthChange}
                      choices={monthsArrau}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <p style={{ fontSize: "16px" }}>
                      To Month<span style={{ color: "red" }}>*</span>:
                    </p>
                  </Grid>
                  <Grid item sm={3}>
                    <SelectField
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choice_id="value"
                      choice_name="value"
                      name="to_month"
                      value={selectedToMonth}
                      onChange={handleToMonthChange}
                      choices={monthsArrau}
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  padding: "0px 20px 20px 20px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    <p style={{ fontSize: "16px" }}>Comaparison Type <span style={{ color: "red" }}>*</span>:</p>
                  </Grid>
                  <Grid item sm={9}>
                    <SelectField
                      choice_id="type"
                      choice_name="type"
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choices={[
                        { id: 0, type: "GSTR2B vs GSTR3B" },
                        { id: 1, type: "Books Vs GSTR2B" },
                        { id: 2, type: "Books Vs GSTR3B" },
                        { id: 2, type: "RCM-Books Vs GSTR3B" }
                      ]}
                      value={selectedComparisonType}
                      onChange={handleComparisonTypeChange}
                    />
                  </Grid>
                </Grid>
              </div>


            </Modal.Body>
            <Modal.Footer>
              <button
                className="button-primary"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </button>
              <button
                className="button-outlined-secondary"
                type="button"
                onClick={newReconFormModalClose}
              >
                Close
              </button>
            </Modal.Footer>
          </ValidatorForm>
        </Modal>
      </div>

      {closeBoxWrapper && (
        <BoxWrapper
          boxTitle="Error"
          cutIcon={true}
          children={errorMessageForBoxWrapper}
          closeBoxWapper={FunForcloseBoxWapper}
          colorForMessage="red"
          ErrorIcon={<ErrorOutlineOutlinedIcon />}
        />
      )}

      <BoxWrapper
        boxTitle="Inward Comparison"
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={refreshUploadHistoryTable}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Fiscal Year</th>
                <th>From Month</th>
                <th>To Month</th>
                <th>Created Date</th>
                <th>Comaparison Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{historyTableBody()}</tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}
