// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  .CreditEvalution_pageHeader__3kKhR {\n      width: 90%;\n      margin: auto;\n    background-color: #c4d0d9;\n    padding: 8px 0px 8px 10px;\n    box-shadow: 0px 0px 2px 0px #000;\n    font-size: 14px;\n    font-weight: bold;\n    margin-top: 90px;\n  }\n \n  .CreditEvalution_TabPanelSection__2kK5v{\n    width: 85%;\n    margin: auto;\n    margin-top: 50px;\n    margin-bottom: 100px;\n  }", "",{"version":3,"sources":["webpack://src/Containers/home/EnviromentConfig/CreditEvalution/CreditEvalution.module.css"],"names":[],"mappings":";EACE;MACI,UAAU;MACV,YAAY;IACd,yBAAyB;IACzB,yBAAyB;IACzB,gCAAgC;IAChC,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;EACtB","sourcesContent":["\n  .pageHeader {\n      width: 90%;\n      margin: auto;\n    background-color: #c4d0d9;\n    padding: 8px 0px 8px 10px;\n    box-shadow: 0px 0px 2px 0px #000;\n    font-size: 14px;\n    font-weight: bold;\n    margin-top: 90px;\n  }\n \n  .TabPanelSection{\n    width: 85%;\n    margin: auto;\n    margin-top: 50px;\n    margin-bottom: 100px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "CreditEvalution_pageHeader__3kKhR",
	"TabPanelSection": "CreditEvalution_TabPanelSection__2kK5v"
};
export default ___CSS_LOADER_EXPORT___;
