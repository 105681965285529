import React, { useState } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../../../components/ui/inputField";
import ValidatedDatePicker from "../../../../../components/ui/DatePicker";
import axios from "../../../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberFormat from "react-number-format";
import SelectField from "../../../../../components/ui/select";

export default function Add(props) {
  const { cancelAddForm, updateGetApi } = props;
  // const form = useRef("form")
  const [addTdsData, setAddTdsData] = useState({
    section: null,
  });

  const addInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddTdsData({
      ...addTdsData,
      [name]: value,
    });
  };

  const handleFromDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setAddTdsData({
      ...addTdsData,
      from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const handleToDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setAddTdsData({
      ...addTdsData,
      to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const finalAddData = {
    user: localStorage.getItem("user_id"),
    record: [addTdsData],
  };

  const addFormsubmitHandler = (e) => {
    toast.configure();
    axios
      .post("/tds/threshold", finalAddData)
      .then((res) => {
        if (res.data.status === true) {
          cancelAddForm();
          updateGetApi();
          toast.success(res.data.message, { autoClose: 2000 });
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ....section dropdown data......
  const sectionDropdownValue = [
    { section: "192A" },
    { section: "193(Securites)" },
    { section: "193(Debentures)" },
    { section: "194" },
    { section: "194A" },
    { section: "194B" },
    { section: "194BB" },
    { section: "194C" },
    { section: "194D" },
    { section: "194DA" },
    { section: "194E" },
    { section: "194EE" },
    { section: "194G" },
    { section: "194H" },
    { section: "194IA" },
    { section: "194I(A)" },
    { section: "194IB" },
    { section: "194I(B)" },
    { section: "194IC" },
    { section: "194J(2%)" },
    { section: "194J(10%)" },
    { section: "194LA" },
    { section: "194LB" },
    { section: "194LD" },
    { section: "194N" },
    { section: "194Q" },
    { section: "194R" },
  
  ];
  // ..........194C dropdown..........
  const section_194c = [
    { criteria: "HUF and Individual/Single Payment" },
    { criteria: "HUF and Individual/Aggregate Payment" },
    { criteria: "Other than HUF and Individual/Single Payment" },
    { criteria: "Other than HUF and Individual/Aggregate Payment" },
  ];
  // ...........194Q dropdown value.......
  const section_194q = [{ criteria: "With PAN" }, { criteria: " Without PAN" }];
  // ...........194D dropdown value.......
  const section_194d = [
    { criteria: "Domestic Companies" },
    { criteria: " Others than Domestic companies" },
  ];

  return (
    <ValidatorForm
      useRef="form"
      onSubmit={addFormsubmitHandler}
      onError={(errors) => console.log(errors)}
    >
      <SelectField
        choice_id="section"
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        choice_name="section"
        SelectLabel="Section"
        name="section"
        value={addTdsData.section ?? ""}
        textOnchangeFunction={addInputChangeHandler}
        choices={sectionDropdownValue}
      />

      {/* .......change....... */}
      <InputField
        textLabel="Standard TDS rate"
        inputType="text"
        name="standard_tds_rate"
        value={addTdsData.standard_tds_rate}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="In forced TDS rate"
        inputType="text"
        name="inforced_tds_rate"
        value={addTdsData.inforced_tds_rate}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />
      {/* ......... */}

      <div style={{ marginTop: "20px" }}>
        <label>₹ Threshold limit for deduction:</label> <br />
        <NumberFormat
          className="numberFormate"
          style={{ paddingLeft: "10.5px" }}
          value={
            addTdsData.threshold_limit_for_deduction
              ? addTdsData.threshold_limit_for_deduction
              : ""
          }
          thousandsGroupStyle="lakh"
          prefix="₹"
          required
          decimalSeparator="."
          displayType="number"
          type="text"
          decimalScale={2}
          thousandSeparator={true}
          allowNegative={true}
          onValueChange={(values) => {
            const { value } = values;
            setAddTdsData({
              ...addTdsData,
              threshold_limit_for_deduction: parseFloat(value),
            });
          }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          From Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="from_date"
          value={addTdsData.from_date ?? ""}
          onChange={(v) => handleFromDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          To Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="to_date"
          value={addTdsData.to_date ?? ""}
          onChange={(v) => handleToDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
      </div>

      {addTdsData.section !== "194C" &&
        addTdsData.section !== "194Q" &&
        addTdsData.section !== "194D" && (
          <InputField
            textLabel="criteria"
            inputType="text"
            name="criteria"
            value={addTdsData.criteria}
            // requiredField="*"
            // validator={["required"]}
            // errorMsg={["This field is required"]}
            textOnchangeFunction={addInputChangeHandler}
          />
        )}

      {addTdsData.section === "194C" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={addTdsData.criteria ?? ""}
          textOnchangeFunction={addInputChangeHandler}
          choices={section_194c}
        />
      )}

      {addTdsData.section === "194Q" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={addTdsData.criteria ?? ""}
          textOnchangeFunction={addInputChangeHandler}
          choices={section_194q}
        />
      )}

      {addTdsData.section === "194D" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={addTdsData.criteria ?? ""}
          textOnchangeFunction={addInputChangeHandler}
          choices={section_194d}
        />
      )}

      <InputField
        textLabel="Particulars"
        inputType="text"
        name="particulars"
        value={addTdsData.particulars}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      {/* .......Button........ */}
      <div className="mt-20">
        <button className="button-primary" type="submit">
          Submit
        </button>
        <button
          type="button"
          className="button-outlined-secondary"
          onClick={cancelAddForm}
        >
          cancel
        </button>
      </div>
    </ValidatorForm>
  );
}
