import React, { useState, useMemo } from "react";
import { Button, styled } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import NumberFormat from "react-number-format";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Modal from "react-bootstrap/Modal";
import axios from "../../../../axios-order";
import classes from "./tdsPayables.module.css";
// import classes from "./tdsPaybles.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectField from "../../../../components/ui/select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledAutoComplete = styled(Autocomplete)(() => ({
  "& legend": {
    display: "none",
  },

  "& input": {
    height: 0,
  },
}));

export default function Add(props) {
  const {
    cancelAddForm,
    updateTable,
    updateMasterTable,
    updateReversalOfProvision,
  } = props;
  // const form = useRef("form")
  const [vendorTableData, setVendorTableData] = useState([]);
  const [dataForSearch, setDataForSearch] = useState([]);

  const [addTdsData, setAddTdsData] = useState({});
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");

  // vendor master api call for select provision modal
  const getAllVendormasterData = () => {
    setLoading(true);
    axios
      .get(`/get_provison_master/${localStorage.getItem("business_entity_id")}`)

      .then((res) => {
        setLoading(false);
        setVendorTableData(res.data.record);
        setDataForSearch(res.data.record);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const addInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setAddTdsData({
      ...addTdsData,
      [name]: value,
    });
  };

  useMemo(() => {
    let tdsPayable = (addTdsData.amount * addTdsData.tds_percentage) / 100;
    setAddTdsData({
      ...addTdsData,
      tds_payable: tdsPayable,
    });
  }, [addTdsData.amount, addTdsData.tds_percentage]);

  const handleProvisionCreatedDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setAddTdsData({
        ...addTdsData,
        provision_create_date: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      });
    }
  };

  const finalAddData = {
    org_code: localStorage.getItem("business_entity_id"),
    records: [addTdsData],
  };

  const addFormsubmitHandler = (e) => {
    console.log(finalAddData);
    toast.configure();
    axios
      .post("/tds/provision_vendor", finalAddData)
      .then((res) => {
        cancelAddForm();
        if (res.data.status === true) {
          toast.success(res.data.message, { autoClose: 2000 });
          updateMasterTable();
          setAddTdsData({});
          updateTable();
          updateReversalOfProvision();
          setInputValue("");
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // form cancel button handler fn
  const cancelAddFormHandler = () => {
    cancelAddForm();
    setAddTdsData({});
    setInputValue("");
  };

  // .............select vendor.....................
  const selectVendorModalOpen = () => {
    getAllVendormasterData();
    setOpenVendorModal(true);
  };

  const setVendorDetails = (value) => {
    setAddTdsData({
      ...addTdsData,
      vendor_legal_name: value.vendor_legal_name,
      pan: value.vendor_pan,
      vendor_gstin: value.vendor_gstin,
      vendor_id: value.vendor_id,
    });

    setOpenVendorModal(false);
  };

  const selectVendorModalSearch = (e) => {
    let value = e.target.value;
    const searchresult = dataForSearch.filter((item) => {
      return (
        item.vendor_legal_name.toLowerCase().indexOf(value.toLowerCase()) >=
        0 || item.vendor_pan.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    });
    setVendorTableData(searchresult);
  };

  const vendorTableBodyData = () => {
    if (vendorTableData && vendorTableData.length > 0) {
      return vendorTableData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.vendor_pan}</td>
            <td>{item.vendor_gstin}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setVendorDetails(item)}
              >
                Select
              </span>
            </td>
          </tr>
        );
      });
    } else if (loading && vendorTableData.length === 0) {
      return (
        <tr>
          <td style={{ textAlign: "center" }} colSpan={4}>
            Loading,Please wait...
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={4}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  const selectVendorModalClose = () => {
    setOpenVendorModal(false);
  };

  // ....section dropdown data......
  const sectionDropdownValue = [
    { section: "192A" },
    { section: "193(Securites)" },
    { section: "193(Debentures)" },
    { section: "194" },
    { section: "194A" },
    { section: "194B" },
    { section: "194BB" },
    { section: "194C" },
    { section: "194D" },
    { section: "194DA" },
    { section: "194E" },
    { section: "194EE" },
    { section: "194G" },
    { section: "194H" },
    { section: "194IB" },
    { section: "194IA" },
    { section: "194IC" },
    { section: "194J(2%)" },
    { section: "194J(10%)" },
    { section: "194LA" },
    { section: "194LB" },
    { section: "194LD" },
    { section: "194Q" },
    { section: "206AA" },
    { section: "206AB" },
  ];

  return (
    <>
      {/* select vendor modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openVendorModal}
        onHide={() => selectVendorModalClose()}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Any Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "350px" }}>
          <div>
            <TextField
              id="outlined-password-input"
              label="Search by legal name and PAN"
              fullWidth={true}
              size="small"
              onChange={selectVendorModalSearch}
            />
          </div>
          <div className={classes.modalTable}>
            <table className={classes.tablecss}>
              <tbody>
                <tr>
                  <th>legal name</th>
                  <th>PAN</th>
                  <th>GSTIN</th>
                  <th>Action</th>
                </tr>
                {vendorTableBodyData()}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            variant="outlined"
            onClick={selectVendorModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        {/* modal open button */}
        <button
          style={{ marginTop: "10px", width: "100%" }}
          className="button-outlined-primary"
          onClick={() => selectVendorModalOpen()}
        >
          select Vendor*
        </button>

        <ValidatorForm
          useRef="form"
          onSubmit={addFormsubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          {/* .......select button for pan and legal name */}
          <InputField
            textLabel="Vendor Legal Name"
            inputType="text"
            name="vendor_legal_name"
            value={addTdsData.vendor_legal_name ?? ""}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            textOnchangeFunction={addInputChangeHandler}
            inputProps={{ readOnly: true }}
          />

          <InputField
            textLabel="PAN"
            inputType="text"
            name="pan"
            value={addTdsData.pan ?? ""}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            textOnchangeFunction={addInputChangeHandler}
            inputProps={{ readOnly: true }}
          />
          {/* .... */}
          <div style={{ marginTop: "20px" }}>
            <label>
              Provision Created Date<span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              name="provision_created_date"
              value={addTdsData.provision_create_date ?? ""}
              onChange={(v) => handleProvisionCreatedDateChange(v)}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <label>
              ₹Amount<span style={{ color: "red" }}>*</span>:
            </label>{" "}
            <br />
            <NumberFormat
              className={classes.numberFormate}
              style={{ paddingLeft: "10.5px" }}
              value={addTdsData.amount ?? ""}
              thousandsGroupStyle="lakh"
              prefix="₹"
              required
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddTdsData({
                  ...addTdsData,
                  amount: value,
                });
              }}
            />{" "}
          </div>

          {/* Added by Vyshnavi */}
          {/* <div style={{ marginTop: "20px" }}>
            <label>
              TDS Section:
            </label>{" "}
            <br />
            <StyledAutoComplete
              id="combo-box-demo"
              style={{ padding: "0!important" }}
              name="tds_section"
              inputValue={inputValue}
              size="small"
              // value={addTdsData.tds_section ?? ""}
              onChange={(e, value) => {
                // if (value === null) {
                //   setAddTdsData({
                //     ...addTdsData,
                //     tds_section: "",
                //   });
                // } else {
                //   setAddTdsData({
                //     ...addTdsData,
                //     tds_section: value.section ?? "",
                //   });
                // }
                setAddTdsData({
                  ...addTdsData,
                  tds_section: value.section.toUpperCase() ?? "",
                });
              }}
              onInputChange={(event, inputValue) => {
                event.preventDefault();
                setInputValue(inputValue);
              }}
              options={sectionDropdownValue}
              getOptionLabel={(option) => option.section}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="tds_section"
                />
              )}
            />
          </div> */}
          <div style={{ marginTop: "20px" }}>
            <label>
              TDS Section<span style={{ color: "red" }}>*</span>:
            </label>{" "}
            <br />
            <StyledAutoComplete
              id="combo-box-demo"
              style={{ padding: "0!important" }}
              name="tds_section"
              inputValue={inputValue}
              size="small"
              // value={addTdsData.tds_section ?? ""}
              onChange={(e, value) => {
                // if (value === null) {
                //   setAddTdsData({
                //     ...addTdsData,
                //     tds_section: "",
                //   });
                // } else {
                //   setAddTdsData({
                //     ...addTdsData,
                //     tds_section: value.section ?? "",
                //   });
                // }
                setAddTdsData({
                  ...addTdsData,
                  tds_section: value?.section?.toUpperCase() ||  "",
                });
              }}
              onInputChange={(event, inputValue) => {
                event.preventDefault();
                setInputValue(inputValue);
              }}
              options={sectionDropdownValue}
              getOptionLabel={(option) => option.section}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="tds_section"
                  required
                />
              )}
            />
          </div>

          {/* <SelectField
            choice_id="section"
            requiredField="*"
            validator={['required']}
            errorMsg={['This field is required']}
            choice_name="section"
            SelectLabel="TDS Section"
            name="tds_section"
            value={addTdsData.tds_section ?? ''}
            textOnchangeFunction={addInputChangeHandler}
            choices={sectionDropdownValue}
          /> */}

          <InputField
            textLabel="TDS %"
            inputType="text"
            name="tds_percentage"
            value={addTdsData.tds_percentage ?? ""}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            textOnchangeFunction={addInputChangeHandler}
          />

          <div style={{ marginTop: "20px" }}>
            <label>
              ₹ TDS Payable<span style={{ color: "red" }}>*</span>:
            </label>{" "}
            <br />
            <NumberFormat
              className={classes.numberFormate}
              style={{ paddingLeft: "10.5px" }}
              value={addTdsData.tds_payable ?? ""}
              thousandsGroupStyle="lakh"
              prefix="₹"
              required
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddTdsData({
                  ...addTdsData,
                  tds_payable: value,
                });
              }}
            />{" "}
          </div>

          {/* .......Button........ */}
          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={cancelAddFormHandler}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </div>
    </>
  );
}
