import React, { Component } from "react";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../../../components/ui/Table/Table";
import SelectField from "../../../../components/ui/selectInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";

class OrigaBatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCol: [
        { key: "cust_legal_name" },
        { key: "cust_gstin" },
        { key: "invoice_no" },
        { key: "invoice_date" },
        { key: "due_date" },
        { key: "tax_amount" },
        { key: "total_amount" },
      ],
      oldInvoiceData: [],
      invoiceData: [],
      total_records: 0,
      invoiceHeader: [
        { label: "Customer Legal Name" },
        { label: "Customer GSTIN" },
        { label: "Invoice No" },
        { label: "Invoice Date" },
        { label: "Due Date" },
        { label: "Tax Amount" },
        { label: "Total Amount" },
      ],
      bank: "",
      bankData: [],
      searchInput: "",
    };
    this.handleBankChange = this.handleBankChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  getInvoices() {
    axios
      .get("/origa/get_invoices/" + localStorage.getItem("business_entity_id"))
      .then((res) => {
        this.setState({
          oldInvoiceData: res.data.data,
          invoiceData: res.data.data,
          total_records: res.data.data.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getInvoices();
    axios
      .get("/get_banks/" + localStorage.getItem("business_entity_id"))
      .then((res) => {
        this.setState({
          bankData: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleBankChange(event) {
    let value = event.target.value;
    this.setState({
      bank: value,
    });
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldInvoiceData.length) {
        let invoiceDataNew = this.state.oldInvoiceData.filter((value) => {
          return (
            String(value.invoice_no).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.cust_legal_name).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.cust_gstin).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          invoiceData: invoiceDataNew,
        });
      }
    } else {
      this.setState({
        invoiceData: this.state.oldInvoiceData,
      });
    }
  };

  onSubmitHandler() {
    let selected_ids = [];

    let bank = this.state.bank;

    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );

    Object.keys(selected_input).map((v, i) => {
      selected_ids.push(selected_input[v].value);
    });
    axios
      .post("/origa/post_invoice_collections", {
        bank: bank,
        selected_ids: selected_ids,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status) {
          this.getInvoices();
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => {
              console.log(errors);
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <SelectField
                  SelectLabel="Select Bank"
                  requiredField="*"
                  errorMsg={["This field is required"]}
                  choice_id="ifsc_code"
                  choice_name="bank_name"
                  choices={this.state.bankData}
                  name="bank"
                  value={this.state.bank}
                  textOnchangeFunction={this.handleBankChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatorForm
                ref="form"
              >
                <InputField
                  textLabel="Search By Invoice"
                  inputType="text"
                  name="Search_by_customer_name"
                  value={this.state.searchInput ?? ""}
                  textOnchangeFunction={this.handleSearchInputChange}
                />
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>
        {/* datatable component */}
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTable
                headers={this.state.invoiceHeader}
                columns={this.state.invoiceCol}
                data={this.state.invoiceData}
                multiselect={true}
                multiselectkey="invoice_id"
                name="invoices"
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default OrigaBatch;
