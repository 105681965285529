import React, { Component } from 'react';
import classes from './ARCIDAgency.module.css';
import axios from '../../../../axios-order';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '../../../../components/ui/Cards';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DataTables from 'material-ui-datatables';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SellerIDAgency from '../../../../asserts/templates/seller_id_agency.xlsx';
import NumberFormat from 'react-number-format';
import ClearIcon from '@material-ui/icons/Clear';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputField from '../../../../components/ui/inputField';
import { TablePagination } from '@material-ui/core';
import RefreshIcon from '@mui/icons-material/Refresh';
import Drawer from '@material-ui/core/Drawer';
import ValidatedDatePicker from '../../../../components/ui/DatePicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoxWrapper from '../../../../components/ui/boxWrapper';
import VisibilityIcon from '@material-ui/icons/Visibility';

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split('-');
    return old_date[2] + '/' + old_date[1] + '/' + old_date[0];
  }
};

class InvoiceDiscountingAgency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      read_only: '',
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        {
          label: 'GSTIN',
          render: (name, all) => {
            return all['record']['GSTIN'];
          },
        },
        {
          label: 'Invoice Number',
          render: (name, all) => {
            return all['record']['InvoiceNo'];
          },
        },
        { key: 'row', label: 'Row #' },
        {
          label: 'Errors',
          render: (name, all) => {
            return Object.keys(all['errors_in']).map((i, v) => {
              return (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: 'red' }}>
                    {all['errors_in'][i].join(',')}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,
      selleridAgency_data: [],
      Original_selleridAgency_data: [],
      showUploadData: false,
      loading: false,

      add_DrawerOpen: false,
      addIDAgencyData: {},

      edit_DrawerOpen: false,
      editIDAgencyData: {},

      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: '',

      uploadHistoryTableData: [],
      showUploadHistroyTable: false,
      loading: true,
    };
  }

  // ID Agency get api data
  IdAgencyGetApi = (serachIn) => {
    const finalData = {
      business_entity_id: localStorage.getItem('business_entity_id'),
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      search_txt: serachIn,
    };
    axios
      .post(`/arc/selleridAgency`, finalData)
      .then((res) => {
        this.setState({
          selleridAgency_data: res.data.records,
          rowCount: res.data.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // table pagination fns
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.IdAgencyGetApi(this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.IdAgencyGetApi(this.state.searchInput);
    });
  };

  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.IdAgencyGetApi(this.state.searchInput);
      }
    );
  };

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = () => {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Seller ID Agency.xlsx`;

    axios
      .post('/seller_id_agency_extract/download', {
        data: this.state.selleridAgency_data,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          'business_entity_id'
        )}/${9}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.IdAgencyGetApi();
    this.getHistoryTableUploadData();
  }

  fileUploader = (event) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/selleridagency`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem('business_entity_id')
          ),
        })
        .then((data) => {
          dis.getHistoryTableUploadData();
        })
        .catch((error) => {
          // toast.error(error, { autoClose:5000, progress: 0 })
        });
    };
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.IdAgencyGetApi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerIDAgency}
        download={'Seller ID Agency.xlsx'}
        style={{ textDecoration: 'none' }}
      >
        {' '}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: '20px' }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: '20px' }} />
        UPLOAD TEMPLATE{' '}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>

      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: 'right' }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: '20px' }} />
        Refresh
      </Button>
      {/* <CreateButton label="add Customer" basePath={basePath} /> */}
    </div>
  );

  // ------------------------ID Agency Table--------------------
  arc_selleridAgency_TableHeader() {
    const tableHeader = [
      'agency name',
      'contract fees GST',
      'contract fees TDS',
      'contract from date',
      'contract to date',
      'deal no',
      'create date',
      'modified date',
      'action',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_selleridAgency_TableBody() {
    const editIDAgency = (
      IDcontract_fees_refNo,
      IDcontract_fees,
      agency_name,
      IDcontract_fees_GST,
      IDcontract_fees_TDS,
      IDcontract_from_date,
      IDcontract_to_date,
      deal_no
    ) => {
      let contract_FromDate = dateFormat(IDcontract_from_date);
      let contract_ToDate = dateFormat(IDcontract_to_date);
      this.setState({
        editIDAgencyData: {
          contractFeesRefNo: IDcontract_fees_refNo,
          contractFees: IDcontract_fees,
          agencyName: agency_name,
          contractFeesGst: IDcontract_fees_GST,
          contractFeesTds: IDcontract_fees_TDS,
          contractFromDate: IDcontract_from_date,
          contractToDate: IDcontract_to_date,
          dealNo: deal_no,
        },
        contractFromDateDisplay: contract_FromDate,
        contractToDateDisplay: contract_ToDate,
        edit_DrawerOpen: true,
      });
    };

    if (this.state.selleridAgency_data.length >= 1) {
      return this.state.selleridAgency_data.map((tableData, index) => {
        const {
          IDcontract_fees_refNo,
          IDcontract_fees,
          agency_name,
          IDcontract_fees_GST,
          IDcontract_fees_TDS,
          IDcontract_from_date,
          IDcontract_to_date,
          deal_no,
          create_date,
          modify_date,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{agency_name}</td>
            <td> {numberFormat(IDcontract_fees_GST)}</td>
            <td>{numberFormat(IDcontract_fees_TDS)}</td>
            <td>{IDcontract_from_date}</td>
            <td>{IDcontract_to_date}</td>
            <td>{deal_no}</td>
            <td>{create_date}</td>
            <td>{modify_date}</td>
            <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editIDAgency(
                    IDcontract_fees_refNo,
                    IDcontract_fees,
                    agency_name,
                    IDcontract_fees_GST,
                    IDcontract_fees_TDS,
                    IDcontract_from_date,
                    IDcontract_to_date,
                    deal_no
                  )
                }
              >
                edit
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}
            colspan={10}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={10}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // ------------------------ID Agency Table end--------------------

  // ------------------------ID Agency add-------------------------
  // add ID agency [add button] drawer open handeler---
  addIDAgency_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // contact to date handler
  handleContractToDateChange = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addIDAgencyData: {
          ...prevState.addIDAgencyData,
          contractToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // contact from  date handler
  handleContractFromDateChange = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addIDAgencyData: {
          ...prevState.addIDAgencyData,
          contractFromDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractFromDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // add ID agency input field handler[number format]
  addIDAgency_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      addIDAgencyData: {
        ...prevState.addIDAgencyData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add ID agency inputfield handler---
  addIDAgency_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addIDAgencyData: {
        ...prevState.addIDAgencyData,
        [name]: String(value),
      },
    }));
  };

  // add ID agency add submit handler
  addIDAgency_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/selleridagency`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.addIDAgencyData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.IdAgencyGetApi();
          this.setState({
            add_DrawerOpen: false,
            addIDAgencyData: {},
            contractFromDateDisplay: '',
            contractToDateDisplay: '',
          });
          toast.success('Invoice discounting agency added successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add ID drawer close handler---
  addIDAgency_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addIDAgencyData: {},
      contractFromDateDisplay: '',
      contractToDateDisplay: '',
    });
  };

  // ------------------------ID Agency add end----------------------

  // ------------------------ID Agency edit-------------------------
  // edit ID agency [edit button] drawer open handeler---
  editIDAgency_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // contact to date handler
  editContractToDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editIDAgencyData: {
          ...prevState.editIDAgencyData,
          contractToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // contact from  date handler
  editContractFromDateChangehandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editIDAgencyData: {
          ...prevState.editIDAgencyData,
          contractFromDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractFromDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // edit ID agency input field handler[number format]
  editIDAgency_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      editIDAgencyData: {
        ...prevState.editIDAgencyData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit ID agency inputfield handler---
  editIDAgency_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editIDAgencyData: {
        ...prevState.editIDAgencyData,
        [name]: String(value),
      },
    }));
  };

  // edit ID agency edit submit handler
  editIDAgency_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/selleridagency`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.editIDAgencyData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.IdAgencyGetApi();
          this.setState({ edit_DrawerOpen: false });
          toast.success('ID Agency updated successfully', { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // edit ID drawer close handler---
  editIDAgency_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false });
  };

  // ------------------------ID Agency add end----------------------
  closeUploadData = () => {
    this.setState({ showUploadData: false });
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({ showUploadData: true });
        let res = response.data.records.records;
        if (response.data.records.type === 'json') {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === 'Fail') {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),

              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    // const { push, ...props } = this.props;

    return (
      <React.Fragment>
        <div style={{ marginRight: '34px', marginBottom: '10px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/providerSelection">
              Home
            </Link>
            <Link color="inherit">ARC</Link>
            <Typography color="textPrimary">
              Upload Invoice Disounting Agency Data
            </Typography>
          </Breadcrumbs>
        </div>

        {/* upload temp Button */}
        <div>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
          >
            upload{' '}
          </Button>
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper boxTitle="Upload History">
              <this.CollectionListActions />
              <div className="tableResponisve">
                <table className="tableCss">
                  <thead>
                    <tr>
                      <th>Last Upload Data & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === 'In Progress' ? (
                              <VisibilityIcon color="disabled" />
                            ) : item.status === 'Uploaded' ? (
                              <VisibilityIcon color="disabled" />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: 'red', textAlign: 'center' }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: 'none',
                          marginBottom: '0px',
                          padding: '0px',
                          marginTop: '0px',
                        }}
                      >
                        <li style={{ display: 'inline-block' }}>
                          Upload Details
                        </li>
                        <li style={{ display: 'inline-block', float: 'right' }}>
                          <ClearIcon
                            onClick={this.closeUploadData}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: '30px', marginRight: '30px' }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: '300',
                            margin: '20px 30px 0px 30px',
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: 'red' }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}

        {/* table */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: 'none',
                      marginBottom: '0px',
                      padding: '0px',
                      marginTop: '0px',
                    }}
                  >
                    <li style={{ display: 'inline-block' }}>
                      Invoice Discounting Agency Detalis
                    </li>
                    <li style={{ display: 'inline-block', float: 'right' }}>
                      <Button size="small" onClick={this.export_to_excel}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Button
                        style={{ marginTop: '35px' }}
                        size="small"
                        variant="outlined"
                        onClick={this.addIDAgency_drawerOpen}
                      >
                        Add ID Agency
                      </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          // textLabel="Search"
                          inputPlacholder="Search...."
                          inputType="text"
                          requiredField=" "
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ''}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_selleridAgency_TableHeader()}</tr>
                      {this.arc_selleridAgency_TableBody()}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                  component="div"
                  count={this.state.rowCount} //total row count
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          onClose={this.addIDAgency_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addIDAgency_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addIDAgencyData.agencyName ?? ''}
                textOnchangeFunction={this.addIDAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Deal No"
                name="dealNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addIDAgencyData.dealNo ?? ''}
                textOnchangeFunction={this.addIDAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contract Fees Ref No"
                name="contractFeesRefNo"
                requiredField=""
                value={this.state.addIDAgencyData.contractFeesRefNo ?? ''}
                textOnchangeFunction={this.addIDAgency_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addIDAgencyData.contractFees
                      ? this.state.addIDAgencyData.contractFees
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addIDAgencyData: {
                        ...prevState.addIDAgencyData,
                        contractFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees GST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addIDAgencyData.contractFeesGst
                      ? this.state.addIDAgencyData.contractFeesGst
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addIDAgencyData: {
                        ...prevState.addIDAgencyData,
                        contractFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees Tds:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addIDAgencyData.contractFeesTds
                      ? this.state.addIDAgencyData.contractFeesTds
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addIDAgencyData: {
                        ...prevState.addIDAgencyData,
                        contractFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>
              <div style={{ marginTop: '20px' }}>
                <label>Contact From Date:</label>
                <ValidatedDatePicker
                  name="contractFromDate"
                  value={this.state.contractFromDateDisplay ?? ''}
                  onChange={(v) => this.handleContractFromDateChange(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Contract To Date:</label>
                <ValidatedDatePicker
                  name="contractToDate"
                  value={this.state.contractToDateDisplay ?? ''}
                  onChange={(v) => this.handleContractToDateChange(v)}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.addIDAgency_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}

        {/* arc edit drawer  */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.editIDAgency_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editIDAgency_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editIDAgencyData.agencyName ?? ''}
                textOnchangeFunction={this.editIDAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Deal No"
                name="dealNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editIDAgencyData.dealNo ?? ''}
                textOnchangeFunction={this.editIDAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contract Fees Ref No"
                name="contractFeesRefNo"
                requiredField=""
                value={this.state.editIDAgencyData.contractFeesRefNo ?? ''}
                textOnchangeFunction={this.editIDAgency_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editIDAgencyData.contractFees
                      ? this.state.editIDAgencyData.contractFees
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editIDAgencyData: {
                        ...prevState.editIDAgencyData,
                        contractFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees GST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editIDAgencyData.contractFeesGst
                      ? this.state.editIDAgencyData.contractFeesGst
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editIDAgencyData: {
                        ...prevState.editIDAgencyData,
                        contractFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Contract Fees Tds:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editIDAgencyData.contractFeesTds
                      ? this.state.editIDAgencyData.contractFeesTds
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editIDAgencyData: {
                        ...prevState.editIDAgencyData,
                        contractFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Contract From Date:</label>
                <ValidatedDatePicker
                  name="contractFromDate"
                  value={this.state.contractFromDateDisplay ?? ''}
                  onChange={(v) => this.editContractFromDateChangehandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Contract To Date:</label>
                <ValidatedDatePicker
                  name="contractToDate"
                  value={this.state.contractToDateDisplay ?? ''}
                  onChange={(v) => this.editContractToDateChangeHandler(v)}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.editIDAgency_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}
      </React.Fragment>
    );
  }
}

export default InvoiceDiscountingAgency;
