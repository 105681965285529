  import React, { useState,useEffect } from 'react';
import BoxWrapper from '../../components/ui/boxWrapper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import viewImage from './view.png';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import axios from "../../axios-order"
import { Snackbar, SnackbarContent } from '@mui/material';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import RefreshIcon from '@mui/icons-material/Refresh';

import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import {  Input } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Reciepent from "./excel/RecipientMaster.xlsx"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,TablePagination,  FormHelperText} from '@mui/material';
import ToggleSwitch from '../../components/ui/ToggleSwitch/ToggleSwitch';

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function CreateRecipientMaster({ handleClose }) {
  const [formValues, setFormValues] = useState({
    id:null,
    gstin: '',
    trade_name: '',
    address_two: '',
    phone: '',
    legal_name: '',
    address_one: '',
    location: '',
    pincode: '',
    email: '',
    state: '', // New field for state
  });

  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0); 
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [records, setRecords] = useState([]);
  const [isUploading, setIsUploading] = useState(false); 
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [errorSnackbar, setErrorSnackbar] = useState({ open: false, message: '' });
  const [summaryData, setSummaryData] = useState(null);
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('')
  const [errorMessage,setErrorMessage] = useState('')
  const [responseData, setResponseData] = useState(null);
  const [page, setPage] = useState(0);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [ReciepentMaster, setReciepentMaster] = useState(false);
const [rowsPerPage, setRowsPerPage] = useState(25); // Default to 25 rows per page
const [editedRowId, setEditedRowId] = useState(null);
const [highlightedCell, setHighlightedCell] = useState({ rowId: null, cellKey: null });


  

  
  

 
  const handleFileChange = async (event) => {
    
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Set the file state
  
      // Prevent multiple uploads
      if (isUploading) {
        return;
      }
  
      // Set loading state and upload progress flag
      setLoading(true);
      setIsUploading(true);
  
      const businessEntityId = localStorage.getItem('business_entity_id');
      const user = localStorage.getItem('user_id');
  
      if (!businessEntityId) {
        // alert('Business entity ID is missing.');
        setLoading(false);
        setIsUploading(false);
        return;
      }
  
      if (!selectedFile) {
        // alert('Please select a file first.');
        setLoading(false);
        setIsUploading(false);
        setSnackbar({ open: true, message: 'Please select a file first.', severity: 'error' });
        return;
      }
  
      try {
        // Convert file to Base64
        const base64File = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = `data:${selectedFile.type};base64,${btoa(reader.result)}`;
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsBinaryString(selectedFile);
        });
  
        // First URL call - File Upload
        await axios.post('/einvoice/import_recipient_master', {
          business_entity_id: businessEntityId,
          user: user,
          file: base64File, // Base64 encoded file
        });
  
        setSnackbar({ open: true, message: 'File uploaded successfully.', severity: 'success' });
  
        // After successful upload, make the second URL call
        const formNumber = '100'; // Replace with actual form number or state variable if needed
        const response = await axios.get(`/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`);
  
       
        setUploadHistory(response.data.record); // Update your state with the history data
  
        setTimeout(() => {
       
          refresh();
        }, 1000);
  
      } catch (error) {
        console.error('Error uploading file or retrieving history:', error);
      } finally {
        // Reset loading state and upload progress flag
        setLoading(false);
        setIsUploading(false);
          // Clear file input value to allow re-uploading the same file
      event.target.value = null;
      }
    }
  };
  
  
 
  //  const fetchData = async () => {
  //   try {
  //     const businessEntityId = localStorage.getItem('business_entity_id');
  //     const response = await axios.get(`/einvoice/get_recipient_master/${businessEntityId}/`);
  //     setRows(response.data.records);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setLoading(false);
  //   }
  // };

  // List of Indian states
  const states = [
    { code: 'AP', name: 'Andhra Pradesh' },
    { code: 'AR', name: 'Arunachal Pradesh' },
    { code: 'AS', name: 'Assam' },
    { code: 'BR', name: 'Bihar' },
    { code: 'CG', name: 'Chhattisgarh' },
    { code: 'GO', name: 'Goa' },
    { code: 'GJ', name: 'Gujarat' },
    { code: 'HR', name: 'Haryana' },
    { code: 'HP', name: 'Himachal Pradesh' },
    { code: 'JH', name: 'Jharkhand' },
    { code: 'KA', name: 'Karnataka' },
    { code: 'KL', name: 'Kerala' },
    { code: 'MP', name: 'Madhya Pradesh' },
    { code: 'MH', name: 'Maharashtra' },
    { code: 'MN', name: 'Manipur' },
    { code: 'ME', name: 'Meghalaya' },
    { code: 'MI', name: 'Mizoram' },
    { code: 'NL', name: 'Nagaland' },
    { code: 'OD', name: 'Odisha' },
    { code: 'PB', name: 'Punjab' },
    { code: 'RJ', name: 'Rajasthan' },
    { code: 'SK', name: 'Sikkim' },
    { code: 'TN', name: 'Tamil Nadu' },
    { code: 'TS', name: 'Telangana' },
    { code: 'TR', name: 'Tripura' },
    { code: 'UP', name: 'Uttar Pradesh' },
    { code: 'UT', name: 'Uttarakhand' },
    { code: 'WB', name: 'West Bengal' },
    { code: 'DL', name: 'Delhi' },
    { code: 'LD', name: 'Lakshadweep' },
    { code: 'PY', name: 'Puducherry' },
    { code: 'AN', name: 'Andaman and Nicobar Islands' },
    { code: 'CH', name: 'Chandigarh' },
    { code: 'DD', name: 'Dadra and Nagar Haveli and Daman and Diu' },
    { code: 'LA', name: 'Ladakh' },
    { code: 'JK', name: 'Jammu and Kashmir' },
    { code: 'OT', name: 'Other Territory' },
    { code: 'OC', name: 'Other Country' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  const handleInputgstChange = (e) => {
    const { name, value } = e.target;
  
   
     // Convert input to uppercase, allow only alphabets and numbers, and limit to 15 characters
  const upperCaseValue = value.toUpperCase();
  const gstinPattern = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$/;
  if (/^[A-Za-z0-9]*$/.test(upperCaseValue) && upperCaseValue.length <= 15) {
    setFormValues({
      ...formValues,
      [name]: upperCaseValue,
    });
    if (upperCaseValue.length === 15 && !gstinPattern.test(upperCaseValue)) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        gstin: 'Invalid GSTIN',  // Set error message if the GSTIN format is invalid
      }));
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        gstin: '',  // Clear error message if the GSTIN is valid
      }));
    }
  }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // Allow only numeric input and ensure the length is at most 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    
    // Allow only digits and limit to 6 characters
    if (/^\d{0,6}$/.test(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  

  const handleInputsupplierChange = (e) => {
    const { name, value } = e.target;
   // Allow alphanumeric characters, spaces, and special characters
   if (/^[A-Za-z0-9\s!@#$%^&*(),.?"':{}|<>\\/\-]*$/.test(value)) {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  };


  const handleInputlocationChange = (e) => {
    const { name, value } = e.target;
  
    // Allow only alphabetic characters and spaces
    if (/^[A-Za-z\s]*$/.test(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const handleClear = () => {
    setFormValues({
      gstin: '',
      trade_name: '',
      address_two: '',
      phone: '',
      legal_name: '',
      address_one: '',
      location: '',
      pincode: '',
      email: '',
      state: '', // Reset state
    });
    setImage(null);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const businessEntityId = localStorage.getItem('business_entity_id');
    const mandatoryFields = [
      { name: 'gstin', message: 'GSTIN is required' },
      { name: 'trade_name', message: 'Trade Name is required' },
      { name: 'legal_name', message: 'Legal Name is required' },
      { name: 'address_one', message: 'Address 1 is required' },
      { name: 'location', message: 'Location is required' },
      { name: 'pincode', message: 'Pin Code is required' },
      { name: 'state', message: 'State is required' }
    ];
    const newFieldErrors = {};
  
 
      if (formValues.phone && formValues.phone.length !== 10) {
    setSnackbar({
        open: true,
         message: 'Please enter a 10-digit phone number',
        severity: 'error'
      });
      return;
   }

  
   if (formValues.pincode && formValues.pincode.length !== 6) {
    setSnackbar({
      open: true,
      message: 'Pin code must be  6 digits',
      severity: 'error'
   });
    return;
 }
  
    const missingFields = mandatoryFields.filter(field => !formValues[field]);
  
    //     if (missingFields.length > 0) {
    //   setSnackbar({
    //      open: true,
    //    message: 'Please Enter Mandatory Fields',
    //    severity: 'error'
    //  });
    //    return;
    // }
    mandatoryFields.forEach(field => {
      if (!formValues[field.name]) {
        newFieldErrors[field.name] = field.message;
      }
    });

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      setSnackbar({
        open: true,
        message: 'Please fill all mandatory fields',
        severity: 'error'
      });
      return;
    }

    try {
      const response = await axios.post('/einvoice/post_recipient_master', {
        ...formValues,
        id: formValues.id ?? null,
        business_entity_id: businessEntityId,
        image: image || '', // Include Base64 image string in payload
      });
  

     
      // Display the response message in an alert box
     if (response.data.status) {
  setSnackbar({
    open: true,
    message: response.data.message,
    severity: 'success', // Change this to 'error' or other severity based on the response
  });
  setHighlightedCell({ rowId: formValues.id, cellKey: 'trade_name' }); // Change 'trade_name' to the appropriate key
  setTimeout(() => {
    setHighlightedCell({ rowId: null, cellKey: null });
  }, 7000);
  handleToggle({ target: { checked: false } });
}
 // Clear all fields
 setFormValues({
  gstin: '',
  trade_name: '',
  address_two: '',
  phone: '',
  legal_name: '',
  address_one: '',
  location: '',
  pincode: '',
  email: '',
  state: '',
});

setFieldErrors({});
    refresh();
      handleClose();
      
    } catch (error) {
      console.error('Error:', error); // Debugging: Log the error object
     

      setErrorSnackbar({
        open: true,
        message: error.response?.data?.message || 'An unexpected error occurred.',
      });
    }
  };
  
  
  
  
  
  
  
  



const handleDownloadTemplate = () => {
  
  const link = document.createElement('a');
  link.href = Reciepent;
  link.download = 'RecipientMaster.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);



};

const handleCloseBox = () => {
  setBoxVisible(false); // Hide the box when close button is clicked
};

const handleExportExcel = () => {
  const bid = localStorage.getItem('business_entity_id');
  const fileName = 'Recipients'; // Assuming 'RecipientMaster' is the file name you want to use

  if (bid) {
    const url = `/einvoice/export_recipient_master/${fileName}/${bid}`;

    axios.get(url)
      .then(response => { 
       
        // Assuming the response data is a Base64 encoded string
        const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const base64Data = response.data.file; // Adjust based on the actual response format
        downloadBase64File(contentType, base64Data, `${fileName}.xlsx`);
      })
      .catch(error => {
        console.error("Error exporting file:", error);
      });
  } else {
    console.error('Business entity ID not found in localStorage');
  }
};


const handleExcel = async () => {
  const businessEntityIdRaw = localStorage.getItem('business_entity_id');
  // const str = businessEntityIdRaw ? String(businessEntityIdRaw) : null;
  
  // Check if business entity ID is available
  if (!businessEntityIdRaw) {
    console.error('Business entity ID is missing');
    return;
  }

  try {
    // Check if response data is available
    if (!responseData) {
      console.error('Response data is missing');
      return;
    }

    const payloadData = {
      'business_entity_id': businessEntityIdRaw,
      'data': responseData
    };

   

    // Make the POST request with query parameters
    const response = await axios.post(`/einvoice/download_error_data`, payloadData);



    // Check if base64 data is present in the response
    const base64Data = response.data.file; // Ensure this matches the structure of your response
    if (base64Data) {
      const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const filename = 'RecipientUploadErrorLogs.xlsx';
      downloadBase64File(contentType, base64Data, filename);
    } else {
      console.error('Base64 data not found in the response');
    }

  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error fetching Excel file:', error);
  }
};





// // Utility function to handle Base64 file download
const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};







const refresh = async (pageNumber = 0, rowsPerPage = 25, searchTxt = '') => {

  const businessEntityId = localStorage.getItem('business_entity_id');
  const formNumber = '100'; // Replace with actual form number if needed

  try {
 
     // First URL call - Static URL with number_of_rows_per_page and page_number
     const postResponse = await axios.post('/einvoice/upload_recipient', {
      number_of_rows_per_page: rowsPerPage,
      page_number: pageNumber,
      business_entity_id: businessEntityId,
      search_txt: searchTxt,
    });

   
    setRowCount(postResponse.data.row_count);
    if (postResponse.data.records) {
      setRows(postResponse.data.records);  // Update the rows state with the received records
    }

       // After successful POST, make the second URL call to retrieve upload history
       const response = await axios.get(`/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`);

       
       setUploadHistory(response.data.record); 
       setRefreshTrigger(prev => !prev); 
  } catch (error) {
    console.error('Error refreshing data:', error);
    // Handle error if necessary
  }
};
 
useEffect(() => {
  refresh(page, rowsPerPage, searchTerm); // Call refresh with current search term
}, [page, rowsPerPage, searchTerm]); // Add searchTerm as a dependency


const searchInputOnchange = async (event) => {
  const value = event.target.value;
  setSearchText(value);
  await refresh(value); // Call refresh with the search text
};






const handleSearchChange = (event) => {
  const newSearchTerm = event.target.value;
  setSearchTerm(newSearchTerm);
  refresh(page, rowsPerPage, newSearchTerm); // Refresh data with the new search term
};


const filteredRows = Array.isArray(rows)
  ? rows.filter(row => Object.values(row).some(value => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())))
  : [];

  const handleEyeIconClick = async (id) => {
    try {
      const response = await axios.get(`/einvoice/get_data_upload_json/${id}`);
      const { data } = response;
          // Display the message in an alert box
             // Save the entire response data in the new state
    setResponseData(data);
    
      if (data && data.records && data.records.records && data.records.records.summary) {
        setBoxVisible(true);
        setSummaryData(data.records.records.summary);
      }

      if (data && data.records && data.records.message) {
        // Set message and visibility based on status
        if (!data.records.status) { // If status is false
          setErrorMessage(data.records.message);
          setSnackbar({ open: true, message: data.records.message, severity: 'error' });
          handleCloseBox();
        } else {
          setErrorMessage('');
        }
      }
      
      // Check if the response contains a message
      if (data && data.records && data.records.message) {
        // Display success or informational message based on the response
        if (data.records.status) {
          // setSnackbar({ open: true, message: data.records.message, severity: 'error' });
        } else {
          // setSnackbar({ open: true, message: data.records.message, severity: 'error' });
        }
      }
      setDialogData(data.records.records);
    
        // Toggle visibility
        
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar({ open: true, message: 'Failed to fetch data', severity: 'error' });
    }
  };

  const cardStyle1 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#6f9eaf',
    textAlign: 'left', // Aligns text to the left
    width: '300px',
    height: '70px',
    color: 'white',
    fontSize: '20px',
    padding: '10px', // Adds padding for spacing
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Aligns content to the top
    alignItems: 'flex-start'
  
  };
  
  const cardStyle2 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#007965',
    textAlign: 'left', // Aligns text to the left
    width: '300px',
    height: '70px', // Ensure consistent height with the first card
    color: 'white',
    fontSize: '20px',
    padding: '10px', // Adds padding for spacing
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Aligns content to the top
    alignItems: 'flex-start' // Aligns content to the start (left)
  };
  
  
  const cardStyle3 = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#eb596e',
    textAlign: 'left', // Aligns text to the left
    width: '300px',
    height: '70px', // Ensure consistent height with other cards
    color: 'white',
    fontSize: '20px',
    padding: '10px', // Adds padding for spacing
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Aligns content to the top
    alignItems: 'flex-start' // Aligns content to the start (left)
  };
  

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogData(null);
  };

 const handleEdit = (row) => {
  handleToggle({ target: { checked: true } });

 
    // Scroll the page up by 20px
    window.scrollTo({
      top: window.pageYOffset - 580,
      behavior: 'smooth'
    });
  setFormValues({
    id:row.id || '',
    gstin: row.recipient_gstin || '',
    trade_name: row.recipient_trade_name || '',
    address_two: row.recipient_address_two || '',
    phone: row.recipient_phone || '',
    legal_name: row.recipient_legal_name || '',
    address_one: row.recipient_address_one || '',
    location: row.recipient_location || '',
    pincode: row.recipient_pincode || '',
    email: row.recipient_email || '',
    state: row.recipient_state || '',
  });
  setEditedRowId(row.id);
  setTimeout(() => {
    setEditedRowId(null);
  }, 7000);
};


const handleToggleChange = () => {
  setShowUploadHistoryTable(!showUploadHistoryTable);
};
  
const handleToggle = (event) =>{
  setReciepentMaster(event.target.checked)
  if (!event.target.checked){
  setFormValues({
    id:null,
    gstin: '',
    trade_name: '',
    address_two: '',
    phone: '',
    legal_name: '',
    address_one: '',
    location: '',
    pincode: '',
    email: '',
    state: '',
  })
}
}

  
  

  return (
    <div>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <ToggleSwitch
         id="toggleUploadRecipient"  
          onChange={handleToggleChange}
          value={showUploadHistoryTable}
          label="Upload Recipient"
         
        />
         <div className="container">
          <div>
            <input
              type="checkbox"
              className="toggle-switch"
              id="default1"
              onChange={handleToggle}
              checked={ReciepentMaster}
            />
            <label htmlFor="default1"></label>
          </div>
          <p> Add RecipientMaster</p>
        </div>
      </div>
    </div>
    { showUploadHistoryTable &&  (
            <BoxWrapper boxTitle="Recipient Uploads"
            //  enableSearchInput={true}
              //  searchInputPlaceholder="Search..."
                //  searchInputOnchange={searchInputOnchange}
            >
         
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         <div  style={{ display: 'flex'}} >  
      <Button
       style={{ whiteSpace: 'nowrap' }}
        onClick={handleDownloadTemplate}
      >
          <GetAppIcon style={{ fontSize: "20px" }} />
        Download Template
      </Button>
      <Button component="label" size="small" color="primary" style={{ whiteSpace: 'nowrap' }}>
        <PublishIcon style={{ fontSize: "20px" }} />
       
        UPLOAD TEMPLATE
        <input
          accept=".xlsx"
          name="file"
          type="file"
          hidden
          onChange={handleFileChange} // Handle file input change
        />
      </Button>
     
      </div>
      <Button
  variant="contained"
  onClick={() => refresh()} 
  style={{ 
    marginTop: '10px', 
    marginLeft: '500px',
    backgroundColor: 'white',
    
    border: 'none',
    color: '#3e71c2', // To ensure text is visible on white background
    boxShadow: 'none', // Optional: Remove the box-shadow if desired
  }}
  startIcon={<RefreshIcon />}
>
  REFRESH
</Button>
</div>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell  style={{ fontSize: '15px', fontWeight: 'bold' ,paddingLeft:'15px'}}>Upload Date</TableCell>
            
            <TableCell  style={{ fontSize: '15px', fontWeight: 'bold' ,paddingLeft:'15px' }}>Status</TableCell>
            <TableCell  style={{ fontSize: '15px', fontWeight: 'bold',paddingLeft:'15px' }}>Upload Type</TableCell>
            <TableCell style={{ fontSize: '15px', fontWeight: 'bold',paddingLeft:'15px' }}>Action</TableCell> 
            
          </TableRow>
        </TableHead>
        <TableBody>
          {uploadHistory.map((record) => (
            <TableRow key={record.id}>
              <TableCell style={{ padding: '3px',paddingLeft:'15px' }}>{record.upload_date}</TableCell>     
              <TableCell style={{ padding: '3px'  ,paddingLeft:'15px' }}>{record.status}</TableCell>    
              <TableCell style={{ padding: '3px',paddingLeft:'15px'  }}>{record.upload_type}</TableCell>
              <TableCell style={{ padding: '3px' ,paddingLeft:'15px'  }}>
                <IconButton onClick={() => handleEyeIconClick(record.id)}>
                  {/* <VisibilityIcon /> */}
                  <img src={viewImage} alt="View" style={{ width: '24px', height: '24px' }} />
                </IconButton>
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    

    
      </BoxWrapper>
      )}

      {isBoxVisible && (
  <BoxWrapper 
  boxTitle="Uploads Details"
  exportToExcelEnable={true}
  exportToExcelFn={handleExcel} 
  cutIcon={true}  // This enables the close icon
  closeBoxWapper={handleCloseBox}  // This is the function that will be called when the close button is clicked
 
  >
    {/* {errorMessage && (
      <p style={{ color: 'red' }}>{errorMessage}</p>
    )} */}
    {  summaryData ? (
      <div>
        {/* Cards Section */}
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={cardStyle1}>
            <div>Total Records</div>
            <div>{summaryData.total_records}</div>
          </div>
          <div style={cardStyle2}>
            <div>Valid Records</div>
            <div>{summaryData.valid_records}</div>
          </div>
          <div style={cardStyle3}>
            <div>Invalid Records</div>
            <div>{summaryData.invalid_records}</div>
          </div>
        </div>

        {/* Table Section */}
        <div style={{ marginTop: '20px' }}> {/* This wrapper creates a new line for the table */}
        {summaryData.invalid_records !== 0 && (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>GSTIN</TableCell>
          <TableCell>INVOICE NUMBER</TableCell>
          <TableCell>ROW#</TableCell>
          <TableCell>ERRORS</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dialogData && Array.isArray(dialogData.details) ? (
          dialogData.details.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{/* Replace with actual field */}</TableCell>
              <TableCell>{/* Replace with actual field */}</TableCell>
              <TableCell>{item.row}</TableCell>
              <TableCell>
                {item.errors_in ? (
                  Object.entries(item.errors_in).map(([field, errors], i) => (
                    <div key={i}>
                      {field}: {errors.join(', ')}
                    </div>
                  ))
                ) : ''}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>No data available</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
)}
        </div>
      </div>
    ) : (
      <div>Loading...</div>
    )}
  </BoxWrapper>
  
)}
      <Snackbar open={errorSnackbar.open} 
      
      autoHideDuration={6000}
      sx={{ mt: 4 }}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
       onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })}>
      <Alert onClose={() => setErrorSnackbar({ ...errorSnackbar, open: false })}  variant="filled" severity="error">
        {errorSnackbar.message}
      </Alert>
    </Snackbar>
 {ReciepentMaster && (
      <BoxWrapper boxTitle="Recipient Master">
        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
    
                fullWidth
                id="supplier-gstin"
                label="GSTIN*"
                name="gstin"
                value={formValues.gstin}
                onChange={handleInputgstChange}
                error={!!fieldErrors.gstin} // Apply red border if GSTIN is invalid
        helperText={fieldErrors.gstin || ''} 
                
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
              
                fullWidth
                id="trade-name"
                label="Trade Name*"
                name="trade_name"
                value={formValues.trade_name}
                onChange={handleInputChange}
                error={!!fieldErrors.trade_name}
                helperText={fieldErrors.trade_name || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
             
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                value={formValues.phone}
                onChange={handlePhoneChange}
                error={!!fieldErrors.phone}
                helperText={fieldErrors.phone || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
           
                fullWidth
                id="legal-name"
                label="Legal Name*"
                name="legal_name"
                value={formValues.legal_name}
                onChange={handleInputChange}
                error={!!fieldErrors.legal_name}
                helperText={fieldErrors.legal_name || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
               
                fullWidth
                id="supplier-address1"
                label="Recipient Address 1*"
                name="address_one"
                value={formValues.address_one}
                onChange={handleInputsupplierChange}
                error={!!fieldErrors.address_one}
                helperText={fieldErrors.address_one || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                fullWidth
                id="supplier-address2"
                label="Recipient Address 2"
                name="address_two"
                value={formValues.address_two}
                onChange={handleInputsupplierChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
               
                fullWidth
                id="supplier-location"
                label="Recipient Location*"
                name="location"
                value={formValues.location}
                onChange={handleInputlocationChange}
                error={!!fieldErrors.location}
                helperText={fieldErrors.location || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
              
                fullWidth
                id="pin-code"
                label="Pin Code*"
                name="pincode"
                value={formValues.pincode}
                onChange={handlePinChange}
                error={!!fieldErrors.pincode}
                helperText={fieldErrors.pincode || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                fullWidth
                id="email-id"
                label="Email ID"
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!fieldErrors.state} >
                <InputLabel id="state-label">State*</InputLabel>
                <Select
               
                  labelId="state-label"
                  id="state"
                  name="state"
          
                  value={formValues.state}
                  onChange={handleInputChange}
                  label="State*"
                >
                  {states.map((state) => (
                    <MenuItem key={state.code} value={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldErrors.state || ''}</FormHelperText>
              </FormControl>
            </Grid>
         
            <Grid item xs={12} display="flex" justifyContent="flex-start">
  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      sx={{ width: 120 }}
    >
      Add
    </Button>
    <Button
      variant="outlined"
      color="secondary"
      onClick={handleClear}
    >
      Clear
    </Button>
  
  </Box>
  
</Grid>



   
          </Grid>
          
        </Box>
      

      </BoxWrapper>
      )}
     
      <Snackbar
  open={snackbar.open}
  autoHideDuration={6000}
  onClose={() => setSnackbar({ ...snackbar, open: false })}
  sx={{ mt: 4 }}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioned at the top-right corner
>
  <Alert onClose={() => setSnackbar({ ...snackbar, open: false })}  variant="filled" severity={snackbar.severity} sx={{ width: '100%' }}>
    {snackbar.message}
  </Alert>
</Snackbar>

      <BoxWrapper  boxTitle="Recipient Details"
             enableSearchInput={true}
             searchInputPlaceholder="Search..."  
             searchInputOnchange={handleSearchChange}  
             exportToExcelEnable={true}
             exportToExcelFn={handleExportExcel} 
               >
      
      
    <div style={{ maxHeight: '400px', overflow: 'auto', scrollbarWidth: 'thin' }}>
  <Table sx={{ tableLayout: 'auto' }}>
    <TableHead>
      <TableRow>
        {[
          'GSTIN',
          'LEGAL NAME',
          'TRADE NAME',
          'ADDRESS 1',
          'ADDRESS 2',
          'LOCATION',
          'STATE',
          'PINCODE',
          'EMAIL',
          'PHONE',
          'ACTION',
        ].map((header) => (
          <TableCell
            key={header}
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
              zIndex: 1,
              padding: '8px 16px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
  {rows && rows.length > 0 ? (
    rows.map((row) => (
      <TableRow key={row.id}   style={{
        backgroundColor: editedRowId === row.id ? 'lightgreen' : 'transparent',
        transition: 'background-color 4s ease',
      }}
      >
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_gstin}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_legal_name}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_trade_name}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_address_one}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_address_two}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_location}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_state}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_pincode}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_email}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>{row.recipient_phone}</TableCell>
        <TableCell style={{ padding: '8px 16px' }}>
          <EditIcon style={{ cursor: 'pointer' }} 
           sx={{
            '&:hover': {
              color: 'blue',  // Change to your desired hover color
            },
          }}
          onClick={() => handleEdit(row)} />
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={11}>No data found!</TableCell>
    </TableRow>
  )}
</TableBody>

  </Table>
</div>

  
  

    </BoxWrapper>
    <TablePagination
      
        rowsPerPageOptions={[25, 50, 100,1000,3000]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
          refresh(newPage, rowsPerPage, searchTerm); // Pass the newPage to refresh
        }}
        onRowsPerPageChange={(event) => {
          const newRowsPerPage = parseInt(event.target.value, 10);
          setRowsPerPage(newRowsPerPage);
          setPage(0); // Reset to the first page when rows per page changes
          refresh(0, newRowsPerPage, searchTerm); // Refresh with page 0 when changing rows per page
        }}
      />
   
    </div>
  );
}

export default CreateRecipientMaster;
