import React from "react";
import { Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import CircularProgress from "@mui/material/CircularProgress";

const DashboardFilter = (props) => {
  const {
    DropdownsData,
    filterTableFlag,
    filterSubmitHandler,
    setFilterValues,
    filterValues,
    loader,
    BillTableFlag,
    cancelFilterForm,
    UnallocatedTableFlag,
    error,
  } = props;

  return (
    <div className="top_header_container">
      <Grid container spacing={1}>
        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>
            {" "}
            As of Date <span style={{ color: "red" }}>*:</span>
          </label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.as_of_date}
            value={filterValues.as_of_date ?? ""}
            onChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["as_of_date"]: newInputValue,
              });
            }}
            // onInputChange={(event, newInputValue) => {
            //   setFilterValues({
            //     ...filterValues,
            //     ['as_of_date']: newInputValue,
            //   });
            // }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
          {filterValues.as_of_date ? (
            ""
          ) : (
            <span style={{ color: "red" }}>This field is required.</span>
          )}
        </Grid>

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Vendor GSTIN :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.vendor_gstin}
              value={filterValues.vendor_gstin ?? ""}
              onChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["vendor_gstin"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Vendor Name :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.vendor_name}
              value={filterValues.vendor_name ?? ""}
              inputValue={filterValues.vendor_name ?? ""}
              onInputChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["vendor_name"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Vendor PAN :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.vendor_pan}
              value={filterValues.vendor_pan ?? ""}
              onChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["vendor_pan"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Vendor Code :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.vendor_code}
              inputValue={filterValues.vendor_code ?? ""}
              onInputChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["vendor_code"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>GST Bill Type:</label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.registeration_type}
            value={filterValues.registeration_type ?? ""}
            onChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["registeration_type"]: newInputValue,
              });
            }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
        </Grid>

        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>Purchase Manager:</label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.account_manager}
            inputValue={filterValues.account_manager ?? ""}
            onInputChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["account_manager"]: newInputValue,
              });
            }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
        </Grid>

        {filterTableFlag && BillTableFlag && (
          <>
            <Grid item xs={3}>
              <label> Ageing Bucket :</label>
              <Autocomplete
                id="combo-box-demo"
                options={DropdownsData && DropdownsData.ageing_bucket}
                value={filterValues.ageing ?? ""}
                onChange={(event, newInputValue) => {
                  setFilterValues({
                    ...filterValues,
                    ["ageing"]: newInputValue,
                  });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <label> Due Bucket :</label>
              <Autocomplete
                id="combo-box-demo"
                options={DropdownsData && DropdownsData.due_bucket}
                value={filterValues.due ?? ""}
                onChange={(event, newInputValue) => {
                  setFilterValues({
                    ...filterValues,
                    ["due"]: newInputValue,
                  });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
          </>
        )}

{filterTableFlag && UnallocatedTableFlag && (
          <>
            <Grid item xs={3}>
              <label> Adjustment Methods :</label>
              <Autocomplete
                id="combo-box-demo"
                options={DropdownsData && DropdownsData.adjustment_methods}
                value={filterValues.adjustment_methods ?? ""}
                onChange={(event, newInputValue) => {
                  setFilterValues({
                    ...filterValues,
                    ["adjustment_methods"]: newInputValue,
                  });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div style={{ marginTop: "10px" }}>
        <button
          onClick={filterSubmitHandler}
          className="button-primary"
          disabled={loader}
        >
          {loader ? (
            <>
              <CircularProgress size={24} style={{ margin: "5px " }} />
              loading...
            </>
          ) : (
            "Search"
          )}
        </button>

        <button
          className="button-outlined-secondary"
          onClick={cancelFilterForm}
        >
          clear
        </button>
        {error && <span style={{ color: "red" }}>No Data Found!</span>}
      </div>
    </div>
  );
};

export default DashboardFilter;
