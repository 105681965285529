import classes from "./footer.module.css";
import React from "react";

const footer = (props) => {
  return (
    <>
      <div
        className={
          window.location.pathname === "/home"
            ? classes.FooterForHome
            : classes.FooterForGlobal
        }
      >
        <p style={{ fontWeight: "500" }}>
          For support , please contact{" "}
          <a
            target="_self"
            href='mailto:support@cappitallwant.com'
            style={{ color: "#000", fontWeight: "600" }}
          >
            support@cappitallwant.com
          </a>
        </p>

        <div>
          Powered by CWS &trade; |{" "}
          <a
            target="_blank"
            href="https://www.cappitallwant.com/"
            style={{ color: "#000", fontWeight: "600" }}
          >
            Terms of use
          </a>
        </div>
      </div>
    </>
  );
};

export default footer;
