import React, { useState, useRef, useEffect } from "react";
import RightDrawer from "../../../../components/ui/RightDrawer";
import Inputfield from "../../../../components/ui/inputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Modal from "@mui/material/Modal";
import { Grid } from "@material-ui/core";

import Box from "@mui/material/Box";
import MonthWiseDatePicker from "../../../../components/ui/monthWiseDatePicker";
import TextField from "@mui/material/TextField";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import GSTR1Template from "../../../../asserts/templates/gst_template.xlsx";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GstrSave = () => {
  const form = useRef("form");
  const [username, setUserName] = useState("");
  const [openEstablisConnectionDrawer, setOpenEstablisConnectionDrawer] =
    useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [auth_otp, setAuthOTP] = useState("");
  const [establishConnectionButton, setEstablisConnectionButton] = useState(
    "button-outlined-secondary"
  );
  //   get apis data
  const [otpApiData, setOtpApidata] = useState({});
  const [authAPiResData, setAuthApiResData] = useState({});
  const [vyanaList, setVyanaList] = useState([]);

  //   file upload section
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [fileUploadData, setFileUploadData] = useState({});
  const [monthWiseDate, setMonthWiseDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorForFileUploadForm, setErrorForFileUploadForm] = useState("");

  const getApiVyanaList = () => {
    axios
      .get(`/vyana/get_list/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (res.data.status === true) {
          setVyanaList(res.data.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error.response.message);
      });
  };

  useEffect(() => {
    getApiVyanaList();
  }, []);

  const getOtpForAuthHandler = () => {
    toast.configure();
    let payload = {
      user_name: username,
      business_entity_id: localStorage.getItem("business_entity_id"),
    };

    axios
      .post("/vyana/send_otp", payload)
      .then((res) => {
        if (res.data.status === true) {
          setOpenOTPModal(true);
          setOtpApidata({
            ...otpApiData,
            transaction_id: res.data.transaction_id,
            app_key: res.data.app_key,
          });
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            progress: 0,
          });
          setOpenOTPModal(false);
          setEstablisConnectionButton("button-outlined-secondary");
        }
      })
      .catch((error) => {
        console.log(error.response.message);
      });
  };

  const closeEstablisConnectionDrawerHamdler = () => {
    setOpenEstablisConnectionDrawer(false);
    setUserName("");
  };

  const vyanaAuthHandler = () => {
    toast.configure();
    let payload = {
      user_name: username,
      business_entity_id: localStorage.getItem("business_entity_id"),
      app_key: otpApiData.app_key,
      transaction_id: otpApiData.transaction_id,
      otp: auth_otp,
    };

    axios
      .post("/vyana/authenticate", payload)
      .then((res) => {
        if (res.data.status === true) {
          setOpenOTPModal(false);
          setOpenEstablisConnectionDrawer(false);
          setEstablisConnectionButton("button-connection-established");
          setAuthApiResData({
            ...authAPiResData,
            sek: res.data.sek,
            auth_token: res.data.auth_token,
          });
          getApiVyanaList();
          setTimeout(() => {
            setEstablisConnectionButton("button-outlined-secondary");
          }, 120000);
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            progress: 0,
          });
          setOpenOTPModal(true);
          setEstablisConnectionButton("button-outlined-secondary");
        }
      })
      .catch((error) => {
        console.log(error.response.message);
        setOpenOTPModal(true);
        setEstablisConnectionButton("button-outlined-secondary");
      });
  };

  const closeOptModalHandler = () => {
    setAuthOTP("");
    setOpenOTPModal(false);
  };

  const fileUploadHandler = (e) => {
    if (e.target.files.length) {
      const uploadedfile = e.target.files[0];
      setUploadedFileName(uploadedfile.name);
      setErrorForFileUploadForm("");
      const reader = new FileReader();
      reader.readAsDataURL(uploadedfile);
      reader.onload = function () {
        let file_value = reader.result;
        setFileUploadData({
          ...fileUploadData,
          file: file_value,
        });
      };
    }
  };

  const monthwiseDateHandler = (date) => {
    let selectedDate = new Date(date);
    setMonthWiseDate(selectedDate);
    let month = selectedDate.getMonth() + 1;
    let year = selectedDate.getFullYear();
    setErrorForFileUploadForm("");
    setFileUploadData({
      ...fileUploadData,
      period: `${month}${year}`,
    });
  };

  const fileUploadSubmitHandler = () => {
    toast.configure();
    if (establishConnectionButton === "button-outlined-secondary") {
      return alert("Please establish connection.");
    }

    if (!fileUploadData.file) {
      setLoading(false);
      return setErrorForFileUploadForm("All fields are required.");
    } else if (!fileUploadData.period) {
      setLoading(false);
      return setErrorForFileUploadForm("All fields are required.");
    }

    let payload = {
      ...fileUploadData,
      business_entity_id: localStorage.getItem("business_entity_id"),
      user_name: username,
      transaction_id: otpApiData.transaction_id,
      app_key: otpApiData.app_key,
      sek: authAPiResData.sek,
      auth_token: authAPiResData.auth_token,
    };
    setLoading(true);
    axios
      .post("/vyana/post", payload)
      .then((res) => {
        if (res.data.status === true) {
          setLoading(false);
          setFileUploadData({});
          setUploadedFileName("");
          setMonthWiseDate("");
          setErrorForFileUploadForm("");
          getApiVyanaList();
        } else {
          setLoading(false);
          getApiVyanaList();
          toast.error(res.data.error, {
            autoClose: 3000,
            progress: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error.response.message);
        setLoading(false);
      });
  };

  const cancelFilUploadFormHandler = () => {
    setFileUploadData({});
    setUploadedFileName("");
    setMonthWiseDate(null);
    setErrorForFileUploadForm("");
    setLoading(false);
  };

  return (
    <div>
      {/* establish connection for vyna */}
      <div style={{ textAlign: "right" }}>
        <a
          href={GSTR1Template}
          download={"gstr-1template.xlsx"}
          style={{ textDecoration: "none" }}
        >
          <button className="button-primary" type="button">
            Download Template
          </button>
        </a>

        <button
          className={establishConnectionButton}
          onClick={() => setOpenEstablisConnectionDrawer(true)}
        >
          {establishConnectionButton == "button-outlined-secondary"
            ? "Establish Connection"
            : "Connection Established"}
        </button>
      </div>
      {/*  */}
      <RightDrawer
        drawerName="Establish Connection"
        open={openEstablisConnectionDrawer}
      >
        <ValidatorForm
          ref={form}
          onSubmit={getOtpForAuthHandler}
          onError={(errors) => console.log(errors)}
        >
          <Inputfield
            textLabel="User Name"
            inputType="text"
            name="user_name"
            value={username}
            textOnchangeFunction={(e) => setUserName(e.target.value)}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
          />
          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={closeEstablisConnectionDrawerHamdler}
            >
              Close
            </button>
          </div>
        </ValidatorForm>
      </RightDrawer>

      <Modal open={openOTPModal}>
        <Box sx={style}>
          <ValidatorForm
            ref={form}
            onSubmit={vyanaAuthHandler}
            onError={(errors) => console.log(errors)}
          >
            <Inputfield
              textLabel="OTP"
              inputType="text"
              name="OTP"
              value={auth_otp}
              textOnchangeFunction={(e) => setAuthOTP(e.target.value)}
              requiredField="*"
              validator={["required"]}
              errorMsg={["This field is required"]}
            />
            <div className="mt-20">
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                className="button-outlined-secondary"
                type="button"
                onClick={closeOptModalHandler}
              >
                Close
              </button>
            </div>
          </ValidatorForm>
        </Box>
      </Modal>

      {/*  */}
      <BoxWrapper boxTitle="Filter">
        <ValidatorForm
          ref={form}
          onSubmit={fileUploadSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={3}>
            <Grid item sm={5}>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  marginTop: "32px",
                }}
              >
                <label
                  className="button-primary"
                  style={{ padding: "7px 15px" }}
                >
                  <input
                    type="file"
                    hidden
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => fileUploadHandler(e)}
                  />
                  Upload
                </label>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="eg. cappitallwant.xlsx"
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={uploadedFileName}
                  style={{ marginTop: "5px" }}
                />
              </div>
            </Grid>
            <Grid item sm={5}>
              <label>
                Period<span style={{ color: "red" }}>*</span>
              </label>
              <MonthWiseDatePicker
                value={monthWiseDate}
                onChange={(date) => monthwiseDateHandler(date)}
              />
            </Grid>
            <Grid item sm={2}>
              <div style={{ marginTop: "28px" }}>
                {loading ? (
                  <button
                    type="button"
                    className="button-outlined-primary"
                    style={{ padding: "8px 15px" }}
                  >
                    <CircularProgress />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-primary"
                    style={{ padding: "8px 15px" }}
                  >
                    Submit
                  </button>
                )}

                <button
                  type="button"
                  onClick={cancelFilUploadFormHandler}
                  className="button-outlined-secondary"
                  style={{ padding: "8px 15px" }}
                >
                  Cancel
                </button>
              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
        <p style={{ color: "red" }}>{errorForFileUploadForm}</p>
      </BoxWrapper>
      {/*  */}
      <BoxWrapper boxTitle="GSTR-1 Save">
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Period</th>
                <th>Transaction Id</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vyanaList.length >= 1 &&
                vyanaList.map((v, i) => {
                  return (
                    <tr key={`${i}vyana`}>
                      <td>{i + 1}</td>
                      <td>{v.period}</td>
                      <td>{v.transaction_id}</td>
                      <td>{v.status}</td>
                      <td>
                        {v.status === "Pass" && (
                          <RemoveRedEyeOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() =>
                              alert(`Reference No : ${v.reference_no}`)
                            }
                          />
                        )}
                        {v.status === "Fail" && (
                          <RemoveRedEyeOutlinedIcon
                            className="action-icon-button deleteIcon"
                            onClick={() => alert(`Error : ${v.error_resp}`)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}

              {vyanaList.length == 0 && (
                <tr>
                  <td style={{ color: "red", textAlign: "center" }} colSpan={5}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </div>
  );
};
export default GstrSave;
