import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axios from "../../../axios-order";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Table1 from "./Table1";
import { AppBar, Tab, Tabs } from "@mui/material";
import BarChartForExceutive from "./BarChatForExceutive";
import BoxWrapper from "../../../components/ui/boxWrapper";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(0,123,255)",
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)  #80bdff',
    },
  },
}));

export default function Executivesummary() {
  const [tabIndex, setTabIndes] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [reportingPeriod, setreportingPeriod] = useState("");

  const [getApiDetailsForReportingPeriod, setGetApiDetailsForReportingPeriod] =
    useState([]);
  const [getApiForCategoryMaster, setGetApiForCategoryMaster] = useState([]);
  const [
    getApiForTableDataExcutiveSummary,
    setGetApiForTableDataExcutiveSummary,
  ] = useState([]);
  const [getApiForTableDataExportTOExcel, setGetApiForTableDataExportTOExcel] =
    useState([]);

  const [postApiForReportingPeriod, setPostApiForReportingPeriod] =
    useState("");
  const [postApiForCategory, setPostApiForCategory] = useState("");

  const [dataForBarCharts, setDataForBarCharts] = useState([]);

  //.................useState For Error Message.....................
  const [toDisplay, setToDisplay] = useState(
    "Please Select Category Master and Reporting Period"
  );
  const [divColor, setDivColor] = useState("red");
  const [divVisibility, setDivVisibility] = useState("visible");
  const [displayproperty, setDisplayproperty] = useState("none");

  const [trigar, setTrigar] = useState(false);

  const tabIndexHandler = (event, value) => {
    setTabIndes(value);
  };

  //................DownloadBase For Export To Excell.............
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //.............get Api For Bar Charts.......................
  const callGetApiForBarCharts = () => {
    axios
      .post(`/iars_executive_summary_graph`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        ReportingPeriodId: postApiForReportingPeriod,
        CategoryMasterId: postApiForCategory,
      })
      .then((res) => {
        setDataForBarCharts(res.data.records);
      })
      .catch((err) => console.log(err));
  };

  //.....................Export To Excel.................................
  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Executive Summary.xlsx`;
    axios
      .post(`/iars_executive_summary/download`, {
        data: getApiForTableDataExportTOExcel,
        reportingPeriod,
        CategoryMaster: categoryName,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === "fail") {
          alert("Unable to process download....");
        } else {
          downloadBase64File(contentType, res.data.file, filename);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ApiCategoryMaster = () => {
    functionForErrorMessage("Loading...", "green", "block");
    axios
      .post(`/getCategoryMaster`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          functionForErrorMessage("", "green", "none");
          setGetApiForCategoryMaster(res.data.data);
        } else {
          functionForErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        functionForErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  };

  const getApiForReportingPeriod = () => {
    functionForErrorMessage("Loading...", "green", "block");
    axios
      .post(`/getReportingPeriod`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          functionForErrorMessage("", "", "none");
          setGetApiDetailsForReportingPeriod(res.data.data);
        } else {
          functionForErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        functionForErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  };

  const handleOpenAndCloseModel = () => {
    setPostApiForCategory("");
    setPostApiForReportingPeriod("");
    setToDisplay("");
    setTrigar(false);
    setDivColor("red");
    setDivVisibility("visible");
    setDisplayproperty("none");
  };

  const functionForErrorMessage = (print, color, displaypropertyForError) => {
    setToDisplay(print);
    setDivColor(color);
    setDisplayproperty(displaypropertyForError);
  };

  const handleSubmit = () => {
    if (postApiForReportingPeriod === "" || postApiForCategory === "") {
      functionForErrorMessage("* Mandatory Fields", "red", "block");
    } else {
      functionForErrorMessage("Loading....", "green", "block");
      callGetApiForBarCharts();
      axios
        .post(`/iars_executive_summary`, {
          business_entity_id: localStorage.getItem("business_entity_id"),
          ReportingPeriodId: postApiForReportingPeriod,
          CategoryMasterId: postApiForCategory,
        })
        .then((res) => {
          if (res.data.status === false) {
            functionForErrorMessage("No Data Present!", "red", "block");
            setTrigar(false);
          }
          if (res.data.status === true) {
            functionForErrorMessage(
              "Data Fetch Successfully",
              "green",
              "block"
            );
            setGetApiForTableDataExcutiveSummary(res.data.records);
            setGetApiForTableDataExportTOExcel(res.data);
            setTrigar(true);
          }
        })
        .catch(() => {
          functionForErrorMessage(
            "Server Error Try After Some Time or Check Your Internet",
            "red",
            "block"
          );
          setTrigar(false);
        });
    }
  };

  useEffect(() => {
    ApiCategoryMaster();
    getApiForReportingPeriod();
  }, []);

  useEffect(() => {
    if (postApiForCategory !== "") {
      let categoryDetail = getApiForCategoryMaster.filter(
        (item) => item.CategoryMasterId === postApiForCategory
      );
      setCategoryName(categoryDetail[0].CategoryName);
    }
  }, [postApiForCategory]);

  useEffect(() => {
    if (postApiForReportingPeriod !== "") {
      let reportingPeriodDetail = getApiDetailsForReportingPeriod.filter(
        (item) => item.ReportingPeriodId === postApiForReportingPeriod
      );
      setreportingPeriod(reportingPeriodDetail[0].ReportingPeriod);
    }
  }, [postApiForReportingPeriod]);

  return (
    <React.Fragment>
      <BoxWrapper boxTitle="Filter">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "10px",
          }}
        >
          <div style={{ width: "500px" }}>
            {" "}
            <label>
              Category Master <span className="mandatory-field-IARS">*</span>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForCategory}
                disabled={getApiForCategoryMaster === "" ? true : false}
                onChange={(e) => setPostApiForCategory(e.target.value)}
                input={<BootstrapInput />}
              >
                {/*  <option aria-label="None" value="" /> */}
                {getApiForCategoryMaster.map((v) => {
                  return (
                    <MenuItem value={v.CategoryMasterId}>
                      {v.CategoryName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div style={{ width: "500px" }}>
            <label>
              Reporting Period <span className="mandatory-field-IARS">*</span>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForReportingPeriod}
                disabled={getApiDetailsForReportingPeriod === "" ? true : false}
                onChange={(e) => setPostApiForReportingPeriod(e.target.value)}
                input={<BootstrapInput />}
              >
                {/*  <option aria-label="None" value="" /> */}
                {getApiDetailsForReportingPeriod.map((v) => {
                  return (
                    <MenuItem value={v.ReportingPeriodId}>
                      {v.ReportingPeriod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div style={{ marginTop: "30px" }}>
            <button
              style={{ padding: "8px 15px" }}
              type="submit"
              onClick={handleSubmit}
              className="button-primary"
            >
              Submit
            </button>
            <button
              style={{ padding: "8px 15px" }}
              className="button-outlined-secondary"
              type="button"
              onClick={handleOpenAndCloseModel}
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          style={{
            color: divColor,
            visibility: divVisibility,
            display: displayproperty,
            fontWeight: "600",
            padding: "5px 0px",
          }}
        >
          {toDisplay}
        </div>
        <div style={{ height: "10px" }}></div>
      </BoxWrapper>

      <div>
        {trigar && (
          <div className="divForCharts-IARS">
            <BarChartForExceutive
              BarChartTitle="Executive Summary - Consolidated Quantity"
              tooltipFormat="₹ {point.y:.2f}"
              data={dataForBarCharts}
            />
          </div>
        )}
        {trigar && (
          <>
            <AppBar
              position="static"
              color="transparent"
              style={{ marginTop: "5px" }}
            >
              <Tabs
                value={tabIndex}
                onChange={tabIndexHandler}
                indicatorColor="primary"
                textColor="primary"
                // centered
              >
                <Tab label="Summary" />
                <Tab label="Detail Summary" />
                <div
                  style={{
                    margin: "10px",
                    display: "flex",
                    flexDirection: "row-reverse",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={export_to_excel}
                  >
                    Export To Excel
                  </Button>
                </div>
              </Tabs>
            </AppBar>
            <Table1
              // barCharts={dataForBarCharts}
              data={getApiForTableDataExcutiveSummary}
              tabIndex={tabIndex}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
}
