import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import SelectField from "../../../../components/ui/basicSelectInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import BasicInputField from "../../../../components/ui/basicInput";
import axios from "../../../../axios-order";
import gstReconStyle from "./Gst2aReconciliation.module.css";
import ValidatedDatePicker from "../../../../components/ui/monthWiseDatePicker";
import ValidatedMonthAndYearPicker from "../../../../components/ui/monthWiseDatePicker";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import RefreshIcon from "@mui/icons-material/Refresh";
import CryptoJS from "crypto-js";
import Modal from "react-bootstrap/Modal";
import BoxWrapper from "../../../../components/ui/boxWrapper";

export default function Gst2aReconciliation() {
  const history = useHistory();
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  const [fromMonthAndYear, setFromMonthAndYear] = useState("");
  const [toMontAndYear, setToMontAndYear] = useState("");
  const [forDisplayingFromMonthAndYear, setForDisplayingFromMonthAndYear] =
    useState("");
  const [forDisplayingToMonthAndYear, setForDisplayingToMonthAndYear] =
    useState("");
  const [roundValue, setRoundValue] = useState("0");
  const [customRoundOff, setCustomRoundOff] = useState("");
  const [trigarCustomRoundOff, setTrigarCustomRoundOff] = useState(false);
  const [trigarCircularProgress, setTrigarCircularProgress] = useState(false);

  //............Get Api For Table....................
  const [recordForTable, setRecordForTable] = useState([]);
  const [recordFromTableSearch, setRecordFromTableSearch] = useState([]);

  //..........For Error Message...............
  const [errorMeaage, setErrorMeaage] = useState("");
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
    useState("hidden");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");

  const [loaderForTable, setLoaderForTable] = useState(false);

  //....................Function For Open and Close Model...............
  const handleOpenAndCloseModel = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setForDisplayingToMonthAndYear("");
    setForDisplayingFromMonthAndYear("");
    setFromMonthAndYear("");
    setToMontAndYear("");
    setRoundValue(0);
    setCustomRoundOff("");
    setTrigarCustomRoundOff(false);
    setErrorMeaage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
  };

  //......handle Search Event................
  const handleSearchEvent = (e) => {
    let Search = e.target.value.trim();
    const searchName = recordFromTableSearch.filter((v) => {
      return (
        String(v.recon_date).toLowerCase().indexOf(Search.toLowerCase()) >= 0 ||
        String(v.recon_status).toLowerCase().indexOf(Search.toLowerCase()) >=
          0 ||
        String(moment(v.from_tax_period, "MMYYYY").format("MMMM-YYYY"))
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0 ||
        String(moment(v.to_tax_period, "MMYYYY").format("MMMM-YYYY"))
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0
      );
    });
    setRecordForTable(searchName);
  };

  //..........On Change Event To Handle From Tax-Period (Month And Year).....
  const inputHandlerForYearAndFromMonth = (v) => {
    setForDisplayingFromMonthAndYear(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let MonthAndYear;
    if (mm < 10) {
      MonthAndYear = `0${mm}${yy}`;
    } else {
      MonthAndYear = `${mm}${yy}`;
    }
    setFromMonthAndYear(MonthAndYear);
  };

  //...................On Change Event To Handle To Tax-Period (Month And Year).....
  const inputHandlerForYearAndToMonth = (v) => {
    setForDisplayingToMonthAndYear(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let toMonthAndYear;
    if (mm < 10) {
      toMonthAndYear = `0${mm}${yy}`;
    } else {
      toMonthAndYear = `${mm}${yy}`;
    }
    setToMontAndYear(toMonthAndYear);
  };

  //...........On Change Event To Handle Threshold Value...................
  const roundValueInputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "threshold_value" && value === 11) {
      setRoundValue(value);
      setTrigarCustomRoundOff(!trigarCustomRoundOff);
    } else if (name === "threshold_value") {
      setRoundValue(value);
      setTrigarCustomRoundOff(false);
    } else if (name === "customRoundOff") {
      setCustomRoundOff(value);
    }
  };

  //............For Successfull Message function................
  const forSuccessfullMessage = () => {
    setErrorMeaage("Recon Data Added Successfully!");
    setColorForErrorMessage("green");
    setErrorMessageVisibilityStyle("visible");
    setErrorMessageDisplayStyle("block");
    setTimeout(() => {
      handleOpenAndCloseModel();
    }, 3000);
  };

  //..................Submit Button......................
  const onSubmitHandler = () => {
    setTrigarCircularProgress(true);
    let roundValueData;

    if (roundValue === "11") roundValueData = `${customRoundOff}`;
    else roundValueData = `${roundValue}`;

    axios
      .post(`/gst2a_reconciliation`, {
        fromMonth: fromMonthAndYear,
        toMonth: toMontAndYear,
        threshold_value: roundValueData,
        gstin: localStorage.getItem("gst"),
      })
      .then((res) => {
        setTrigarCircularProgress(false);
        getApiForGst2aRecon();
        forSuccessfullMessage();
        // if (res.data.status === false) {
        //   setTrigarCircularProgress(false);
        //   setErrorMeaage(res.data.message);
        //   setErrorMessageVisibilityStyle("visible");
        //   setErrorMessageDisplayStyle("block")
        // } if (res.data.status === true) {
        //   setTrigarCircularProgress(false);
        //   getApiForGst2aRecon();
        //   forSuccessfullMessage();
        // }
      })
      .catch((err) => {
        setTrigarCircularProgress(false);
        setErrorMeaage(err?.response?.data?.message);
        setErrorMessageVisibilityStyle("visible");
        setErrorMessageDisplayStyle("block");
      });
  };

  //..............Get Api For Table...................
  const getApiForGst2aRecon = () => {
    setLoaderForTable(true);
    axios
      .post(`/get_gst2a_recon_summary`, { gst: localStorage.getItem("gst") })
      .then((res) => {
        if (res.data.status === false) {
          setLoaderForTable(false);
        } else {
          setLoaderForTable(false);
          setRecordForTable(res.data.record);
          setRecordFromTableSearch(res.data.record);
        }
      })
      .catch(() => setLoaderForTable(false));
  };

  useEffect(() => {
    getApiForGst2aRecon();
  }, []);

  // ........gst Recon report route........
  const getGSTReconReport = (v) => {
    const encrypt = (text) => {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };

    history.push({
      pathname: `/gst2aReconReport/${encrypt(v)}`,
      data: v, // your data array of objects
    });
  };

  const refreshUploadHistoryTable = () => {
    getApiForGst2aRecon();
  };

  return (
    <React.Fragment>
      <div>
        <button
          className="button-outlined-primary"
          onClick={handleOpenAndCloseModel}
        >
          New Reconciliation
        </button>
      </div>

      {/* Recon modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openAndCloseModel}
        onHide={() => handleOpenAndCloseModel()}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>GSTR-2A Reconciliation</Modal.Title>
        </Modal.Header>
        <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Modal.Body>
            {/* 1st row */}
            <Grid container spacing={1}>
              <Grid item sm={2}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  From Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div style={{ marginTop: "10px" }}>
                  <ValidatedDatePicker
                    placeholder="Month & Year"
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={forDisplayingFromMonthAndYear}
                    onChange={(v) => inputHandlerForYearAndFromMonth(v)}
                  />
                </div>
              </Grid>
              <Grid item sm={2}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  To Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div style={{ marginTop: "10px" }}>
                  <ValidatedMonthAndYearPicker
                    minDateForDatepicker={forDisplayingFromMonthAndYear}
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    placeholder="Month & Year"
                    value={forDisplayingToMonthAndYear}
                    onChange={(v) => inputHandlerForYearAndToMonth(v)}
                  />
                </div>
              </Grid>
            </Grid>
            {/* 2nd row */}
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item sm={2}>
                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                  Threshold value<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={10}>
                <SelectField
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  choice_id="id"
                  choice_name="roundOff"
                  name="threshold_value"
                  value={roundValue ?? "0"}
                  choices={[
                    { id: 0, roundOff: "No Threshold Value" },
                    { id: 1, roundOff: "Upto 1" },
                    { id: 5, roundOff: "Upto 5" },
                    { id: 10, roundOff: "Upto 10" },
                    { id: 11, roundOff: "Custom" },
                  ]}
                  textOnchangeFunction={roundValueInputHandler}
                />
              </Grid>
            </Grid>
            {trigarCustomRoundOff ? (
              <>
                <div className={gstReconStyle.monthstyle}>
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <p style={{ marginTop: "10px", fontSize: "16px" }}>
                        Custom Round off
                        <span style={{ color: "red" }}>*</span> :
                      </p>
                    </Grid>
                    <Grid item sm={9}>
                      <BasicInputField
                        inputType="number"
                        name="customRoundOff"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        value={customRoundOff ?? ""}
                        textOnchangeFunction={roundValueInputHandler}
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              ""
            )}
            {/* error row */}
            <div
              style={{
                color: colorForErrorMessage,
                paddingTop: "10px",
                fontWeight: "600",
                visibility: errorMessageVisibilityStyle,
                display: errorMessageDisplayStyle,
              }}
            >
              {errorMeaage}
            </div>
            {trigarCircularProgress ? (
              <div
                style={{
                  color: "green",
                  paddingTop: "10px",
                  fontWeight: "600",
                }}
              >
                Please Wait......
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ marginTop: "10px" }}>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={handleOpenAndCloseModel}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </ValidatorForm>
      </Modal>

      {/* reccon history */}
      <BoxWrapper
        boxTitle="GSTR-2A Reconciliation"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
        // add button
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={refreshUploadHistoryTable}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>From Month</th>
                <th>To Month</th>
                <th>Recon Date</th>
                <th>Status</th>
                <th>Report</th>
              </tr>
            </thead>
            <tbody>
              {recordForTable
                ?.sort(function (a, b) {
                  let dateSplitFora = a.recon_date.split("-");
                  let dateSplitByYearFora = JSON.stringify(
                    dateSplitFora[2]
                  ).split(" ");
                  let inDateFormateFirstDate = new Date(
                    `${dateSplitByYearFora[0]}-${dateSplitFora[1]}-${dateSplitFora[0]}`
                  );

                  let dateSplitForb = b.recon_date.split("-");
                  let dateSplitByYearForb = JSON.stringify(
                    dateSplitForb[2]
                  ).split(" ");
                  let inDateFormateSecondDate = new Date(
                    `${dateSplitByYearForb[0]}-${dateSplitForb[1]}-${dateSplitForb[0]}`
                  );

                  return inDateFormateSecondDate - inDateFormateFirstDate;
                })
                ?.map((v,index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {moment(v.from_tax_period, "MMYYYY").format(
                          "MMMM-YYYY"
                        )}
                      </td>
                      <td>
                        {moment(v.to_tax_period, "MMYYYY").format("MMMM-YYYY")}
                      </td>
                      <td>{v.recon_date}</td>
                      <td>{v.recon_status}</td>
                      <td>
                        {" "}
                        {v.recon_status === "Pass" ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => getGSTReconReport(v.recon_id)}
                          >
                            <VisibilityOutlinedIcon className="action-icon-button showIcon" />
                          </div>
                        ) : (
                          <div style={{ cursor: "pointer" }}>
                            <VisibilityOutlinedIcon color="disabled" />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : recordForTable.length === 0 ? (
                <tr>
                  <td colSpan={5} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </React.Fragment>
  );
}
