import React, { Component } from 'react';
import classes from './Onboarding.module.css';
import axios from '../../../../axios-order';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button } from '@material-ui/core';
import InputField from '../../../../components/ui/inputField';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SelectField from '../../../../components/ui/select';
import DatePicker from '../../../../components/ui/DatePicker';
import residential_status from '../../../../asserts/jsonData/residential_status.json';
import Drawer from '@material-ui/core/Drawer';
import NumberToWords from 'number-to-words';
import CustomTable from '../../../../components/ui/Table/Table';
import { toast } from 'react-toastify';
import Alert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidatedDatePicker from '../../../../components/ui/DatePicker';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split('-');
    return old_date[2] + '/' + old_date[1] + '/' + old_date[0];
  }
};

const checkImage = (value) => {
  if (String(value.split(':')[1].split('/')[0]) === 'image') {
    return true;
  } else {
    return false;
  }
};
class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ownerOnly: false,
      loader: false,
      is_onboarded: false,
      ownerHeader: [
        { label: 'PAN' },
        { label: 'Owner Name' },
        { label: 'Owner Aadhaar' },
        { label: 'Ownership Percentage' },
      ],
      ownerCol: [
        { key: 'companyOwnerPAN' },
        { key: 'companyOwnerName' },
        { key: 'companyOwnerAadhaar' },
        { key: 'ownershipPercentage' },
      ],
      fileHeader: [
        { label: 'Attachment Type' },
        { label: 'Attachment Name' },
        { label: 'Attachment' },
      ],
      fileCol: [
        { key: 'attachmentType' },
        { key: 'attachmentName' },
        {
          key: 'attachment',
          render: (index, row) => {
            return (
              <img
                alt="Loading"
                src={row['attachment']}
                style={{ width: '100px', heigth: '100px' }}
              />
            );
          },
        },
      ],
      data: {
        business_entity_id: localStorage.getItem('business_entity_id'),
        netProfitPriorYear: '',
        inventory: '',
        applicationDocuments: [],
        creditLimit: '',
        shortTermBorrowings: '',
        applicationKYCData: [
          {
            firstName: '',
            applicantType: '',
            source: '',
          },
          {
            companyFirmName: '',
            pan: '',
            applicantType: '',
            source: '',
          },
          {
            companyFirmName: '',
            registeredAddressLine1: '',
            registrationNumber: '',
            dateOfIncorporation: '',
            applicantType: '',
            source: '',
          },
        ],
        turnoverNextYear: '',
        currentExperienceInJob: '',
        currentInvestment: '',
        grossProfitPriorYear: '',
        netWorthLatestYear: '',
        netProfitLatestYear: '',
        highestQualificationDesc: '',
        loanPurposeDescription: '',
        otherTotalBorrowing: '',
        metadata: {
          panVerified: false,
          gstnVerified: false,
          numberOfOwners: '',
        },
        grossProfitPrior2Year: '',
        loanType: 'Non-Individual',
        legalStructure: '',
        invoiceDiscountDto: [
          { debtorId: '24', annualTurnover: '10000000', creditPeriod: '60' },
        ],
        loanPurposeCategory: 'Invoice Discounting',
        grossProfitLatestYear: '',
        turnoverLatestYear: '',
        turnoverPriorYear: '',
        netProfitPrior2Year: '',
        longTermBorrowings: '',
        nonCurrentInvestment: '',
        otherAssets: '',
        netProfitNextYear: '',
        receivables: '',
        turnoverPrior2Years: '',
        businessType: '',
        companyOwners: [],
        netWorthNextYear: '',
        borrowerId: '',
      },
      owner_data: [],
      files_data: [],
      business_entity_id: localStorage.getItem('business_entity_id'),
      addOwnerData: {
        business_entity_id: parseInt(
          localStorage.getItem('business_entity_id')
        ),
      },
      editOwnerData: {
        business_entity_id: parseInt(
          localStorage.getItem('business_entity_id')
        ),
      },
      showOwnerdata: {},
      addFilesData: {
        business_entity_id: parseInt(
          localStorage.getItem('business_entity_id')
        ),
      },
      editFilesData: {
        business_entity_id: parseInt(
          localStorage.getItem('business_entity_id')
        ),
      },
      addFilesOpen: false,
    };
    this.setBalanceSheetData = this.setBalanceSheetData.bind(this);
    this.setEntityData = this.setEntityData.bind(this);
    this.setOwnerData = this.setOwnerData.bind(this);
    this.setFileData = this.setFileData.bind(this);
    this.setFinanceData = this.setFinanceData.bind(this);
    this.handleAddFilesInputChange = this.handleAddFilesInputChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  updateStatus = () => {
    this.setState({
      loader: true,
    });
    axios
      .get(
        `/bl/get_onboard_status/` + localStorage.getItem('business_entity_id')
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            status: res.data.data.status,
            loader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        toast.configure();
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  GetStatus = (props) => {
    if (props.status === 3) {
      return (
        <div>
          Billionloans Onboarding Status:
          <Alert severity="info">
            PENDING
            <RefreshIcon onClick={this.updateStatus} />
          </Alert>
        </div>
      );
    } else if (props.status === 4) {
      return (
        <div>
          Billionloans Onboarding Status:
          <Alert severity="success">ACCEPTED</Alert>
        </div>
      );
    } else if (props.status === 5) {
      return (
        <div>
          Billionloans Onboarding Status:
          <Alert severity="error">REJECTED</Alert>
        </div>
      );
    }
  };

  numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumSignificantDigits: 10,
    }).format(value);

  handleDateOwnerChange = (v) => {
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      addOwnerData: {
        ...prevState.addOwnerData,
        dob: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  addOwnerData = () => {
    this.setState({ addDrawer: true });
  };

  addFilesOpenToggleData = () => {
    this.setState({ addFilesOpen: true });
  };

  addOwnerSubmitHandler = () => {
    toast.configure();
    axios
      .post(`/post_entity_owner`, this.state.addOwnerData)
      .then((res) => {
        this.setState((prevState) => ({ ...prevState, ownerOnly: true }));
        this.getting_complete_data();
        this.setState((prevState) => ({
          ...prevState,
          addDrawer: false,
          ownerOnly: false,
        }));
      })
      .catch((error) => {
        toast.configure();
        toast.error(error?.response?.data, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  setFileData = (data) => {
    let attachments = [];
    data.map((v, i) => {
      return attachments.push({
        attachmentName: v.document_name,
        attachmentType: v.document_type,
        attachment: v.document,
      });
    });
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        applicationDocuments: attachments,
      },
      files_data: data,
    }));
  };

  getFilesData = () => {
    axios
      .get(`/get_files/${this.state.business_entity_id}`)
      .then((res) => {
        this.setFileData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editOwnerHandler = (
    first_name,
    last_name,
    dob,
    mobile,
    owner_pan,
    title,
    company_name,
    cin,
    percentage,
    telephone,
    father_spouse_name,
    nationality,
    designation,
    residential_status,
    din,
    middle_name,
    aadhaar_number
  ) => {
    this.setState((prevState) => ({
      editOwnerData: {
        ...prevState.editOwnerData,
        first_name: first_name,
        last_name: last_name,
        dob: dob,
        mobile: mobile,
        owner_pan: owner_pan,
        title: title,
        company_name: company_name,
        cin: cin,
        percentage: percentage,
        telephone: telephone,
        father_spouse_name: father_spouse_name,
        nationality: nationality,
        designation: designation,
        residential_status: residential_status,
        din: din,
        middle_name: middle_name,
        aadhaar_number: aadhaar_number,
      },
    }));
    this.setState({ openEditDrawer: true });
  };

  setEntityData = (data) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        applicationKYCData: [
          {
            firstName: data['contact_person'],
            applicantType: 'Non-Individual',
            source: 'User Input',
          },
          {
            companyFirmName: data['entity_name'],
            pan: data['pan'],
            applicantType: 'Non-Individual',
            source: 'Pan Input',
          },
          {
            companyFirmName: data['entity_name'],
            registeredAddressLine1: data['address'],
            registrationNumber: data['gst'],
            dateOfIncorporation: data['date_incorporation'],
            applicantType: 'Non-Individual',
            source: 'GSTIN Input',
          },
        ],
        loanPurposeDescription: 'Business Loan - Invoice Discounting',
        loanType: 'Non-Individual',
        legalStructure: data['constitution_id'],
        loanPurposeCategory: 'Invoice Discounting',
        businessType: data['industry_id'],
        userId: data['contact_email_id'],
        phoneNumber: data['contact_number'],
      },
    }));
  };

  handleDateInputChange = (value) => {
    let data = this.state.data;
    const pad = '00';
    const yy = value.getFullYear().toString();
    const mm = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    data['applicationKYCData'][2]['dateOfIncorporation'] = `${yy}-${(
      pad + mm
    ).slice(-2)}-${(pad + dd).slice(-2)}`;

    this.setState({
      data: data,
    });
  };

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'registeredAddressLine1') {
      let data = this.state.data;
      data['applicationKYCData'][2]['registeredAddressLine1'] = value;

      this.setState({
        data: data,
      });
    } else {
      const arr = [
        'netProfitPriorYear',
        'inventory',
        'creditLimit',
        'shortTermBorrowings',
        'turnoverNextYear',
        'currentExperienceInJob',
        'currentInvestment',
        'grossProfitPriorYear',
        'netWorthLatestYear',
        'netProfitLatestYear',
        'loanPurposeDescription',
        'otherTotalBorrowing',
        'grossProfitPrior2Year',
        'grossProfitLatestYear',
        'turnoverLatestYear',
        'turnoverPriorYear',
        'netProfitPrior2Year',
        'longTermBorrowings',
        'nonCurrentInvestment',
        'otherAssets',
        'netProfitNextYear',
        'receivables',
        'turnoverPrior2Years',
        'netWorthNextYear',
      ];
      if (arr.indexOf(name) !== -1) {
        var numb = value.match(/\d/g);
        if (numb && numb.length > 10) return false;
        numb = numb ? numb.join('') : 0;
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            [name]: parseInt(numb),
          },
        }));
      } else {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            [name]: String(value),
          },
        }));
      }
    }
  };

  handleEditOwnerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'percentage') {
      let per_value = value === '' ? 0 : value;

      if (!this.checkPercentage(per_value)) return false;

      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseFloat(per_value),
        },
      }));
    } else if (name === 'title') {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === 'nationality') {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === 'residential_status') {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        editOwnerData: {
          ...prevState.editOwnerData,
          [name]: String(value),
        },
      }));
    }
  };

  editOwnerSubmitHandler = () => {
    toast.configure();
    axios
      .put(`/put_entity_owner`, this.state.editOwnerData)
      .then((res) => {
        this.setState((prevState) => ({ ...prevState, ownerOnly: true }));
        this.getting_complete_data();
        this.setState((prevState) => ({
          ...prevState,
          openEditDrawer: false,
          ownerOnly: false,
        }));
      })
      .catch((error) => {
        // toast.configure();
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  showOwnerHandler = (
    first_name,
    last_name,
    dob,
    mobile,
    owner_pan,
    title,
    company_name,
    cin,
    percentage,
    telephone,
    father_spouse_name,
    nationality,
    designation,
    residential_status,
    din,
    middle_name,
    aadhaar_number,
    nationality_value
  ) => {
    this.setState((prevState) => ({
      showOwnerData: {
        ...prevState.showOwnerData,
        first_name: first_name,
        last_name: last_name,
        dob: dob,
        mobile: mobile,
        owner_pan: owner_pan,
        title: title,
        company_name: company_name,
        cin: cin,
        percentage: percentage,
        telephone: telephone,
        father_spouse_name: father_spouse_name,
        nationality: nationality,
        designation: designation,
        residential_status: residential_status,
        din: din,
        middle_name: middle_name,
        aadhaar_number: aadhaar_number,
        nationality_value: nationality_value,
      },
    }));
    this.setState({ openShowDrawer: true });
  };

  deleteOwnerHandler = (owner_pan) => {
    axios
      .delete(
        `/delete_entity_owner/${this.state.business_entity_id}/${owner_pan}`
      )
      .then((res) => {
        this.getting_complete_data();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddOwnerInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'percentage') {
      let per_value = value === '' ? 0 : value;
      if (!this.checkPercentage(per_value)) return false;
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseFloat(per_value),
        },
      }));
    } else if (name === 'title') {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === 'nationality') {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else if (name === 'residential_status') {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: parseInt(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        addOwnerData: {
          ...prevState.addOwnerData,
          [name]: String(value),
        },
      }));
    }
  };

  ownerTableHeader = () => {
    const tableHeader = [
      'first Name',
      'last name',
      'date of Birth',
      'mobile',
      'pan',
      'Action',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  ownerTableData = () => {
    if (this.state.owner_data.length >= 1) {
      return this.state.owner_data.map((tableData, index) => {
        const {
          first_name,
          last_name,
          dob,
          mobile,
          owner_pan,
          title,
          company_name,
          cin,
          percentage,
          telephone,
          father_spouse_name,
          nationality,
          designation,
          residential_status,
          din,
          middle_name,
          aadhaar_number,
          nationality_value,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: 'capitalize' }}>{first_name}</td>
            <td>{last_name}</td>
            <td>{dateFormat(dob)}</td>
            <td>{mobile}</td>
            <td>{owner_pan}</td>
            <td>
              <Button
                size="small"
                onClick={() =>
                  this.showOwnerHandler(
                    first_name,
                    last_name,
                    dob,
                    mobile,
                    owner_pan,
                    title,
                    company_name,
                    cin,
                    percentage,
                    telephone,
                    father_spouse_name,
                    nationality,
                    designation,
                    residential_status,
                    din,
                    middle_name,
                    aadhaar_number,
                    nationality_value
                  )
                }
              >
                show
              </Button>
              |
              <Button
                size="small"
                onClick={() =>
                  this.editOwnerHandler(
                    first_name,
                    last_name,
                    dob,
                    mobile,
                    owner_pan,
                    title,
                    company_name,
                    cin,
                    percentage,
                    telephone,
                    father_spouse_name,
                    nationality,
                    designation,
                    residential_status,
                    din,
                    middle_name,
                    aadhaar_number
                  )
                }
              >
                edit
              </Button>
              |
              <Button
                size="small"
                onClick={() => this.deleteOwnerHandler(owner_pan)}
              >
                delete
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  //---Add owner---

  setFinanceData = (data) => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const previous2Year = currentYear - 2;
    const nextyear = currentYear + 1;
    data.map((v, i) => {
      if (v.fiscal_year === currentYear) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,

            netProfitLatestYear: v.net_profit,

            grossProfitLatestYear: v.gross_profit,
            turnoverLatestYear: v.turnover,
          },
        }));
      } else if (v.fiscal_year === previousYear) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            netProfitPriorYear: v.net_profit,
            grossProfitPriorYear: v.gross_profit,
            turnoverPriorYear: v.turnover,
          },
        }));
      } else if (v.fiscal_year === previous2Year) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            grossProfitPrior2Year: v.gross_profit,
            netProfitPrior2Year: v.net_profit,
            turnoverPrior2Years: v.turnover,
          },
        }));
      } else if (v.fiscal_year === nextyear) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            turnoverNextYear: v.turnover,
            netProfitNextYear: v.net_profit,
          },
        }));
      }
    });
  };

  setBalanceSheetData = (data) => {
    const currentYear = new Date().getFullYear();
    const nextyear = currentYear + 1;

    data.map((v, i) => {
      const year = v.as_of_date.split('-')[0];
      if (year === currentYear) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            inventory: v.inventory,
            shortTermBorrowings: v.short_term_borrowings,
            currentInvestment: v.current_investments,
            netWorthLatestYear: v.net_worth,
            otherTotalBorrowing: v.other_total_borrowing,
            longTermBorrowings: v.long_term_borrowings,
            nonCurrentInvestment: v.non_current_investments,
            otherAssets: v.other_assets,
            receivables: v.trade_receivables,
          },
        }));
      } else if (year === nextyear) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            netWorthNextYear: v.net_worth,
          },
        }));
      }
    });
  };

  setOwnerData = (data) => {
    let owners = [];
    let total_percentage = 0;
    data.map((v, i) => {
      total_percentage += parseFloat(v.percentage);
      return owners.push({
        companyOwnerAadhaar: v.aadhaar_number,
        companyOwnerName: v.first_name,
        companyOwnerPAN: v.owner_pan,
        ownershipPercentage: v.percentage,
      });
    });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        companyOwners: owners,
        metadata: {
          panVerified: false,
          gstnVerified: false,
          numberOfOwners: NumberToWords.toWords(owners.length),
        },
      },
      owner_data: data,
      total_percentage: total_percentage,
    }));
  };

  checkPercentage = (value) => {
    var x = parseFloat(value);
    if (isNaN(x) || x < 0 || x > 100) {
      return false;
    }
    return true;
  };

  getting_complete_data = () => {
    axios
      .get(`/get_complete_entity_data/${this.state.business_entity_id}`)
      .then((res) => {
        if (!this.state.ownerOnly) {
          this.setEntityData(res.data.Entity);
          this.setFinanceData(res.data.financial);
          this.setBalanceSheetData(res.data.balancesheet);
        }
        this.setOwnerData(res.data.owner);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  get_input_data = () => {
    this.setState({
      loader: true,
    });
    axios
      .get(`/bl/get_onboard/${this.state.business_entity_id}`)
      .then((res) => {
        if (res.data.status && res.data.data && res.data.data.status >= 3) {
          this.setState({
            data: res.data.data.record,
            status: res.data.data.status,
            is_onboarded: true,
            ownerOnly: true,
          });
        } else {
          if (res.data.status) {
            // alert(1);
            this.setState({
              data: res.data.data.record,
              ownerOnly: true,
            });
          }

          this.getFilesData();
          this.getting_complete_data();
        }
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  };

  componentDidMount() {
    toast.configure();
    axios
      .get('/get_industry_data')
      .then((res) => {
        if (res.data.records) {
          this.setState({ industry_data: res.data.records });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
    this.get_input_data();
  }

  onSubmitHandler() {
    toast.configure();
    this.setState({
      loader: true,
    });
    axios
      .post('/bl/onboard', this.state.data)
      .then((res) => {
        if (res.data.status) {
          // this.getInvoices();
          this.get_input_data();
          this.updateStatus();
        }
        this.setState({ loader: false });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });

        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  }

  filesTableHeader() {
    const tableHeader = [
      'Document Name',
      'Document Type',
      'Document',
      'Actions',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  filesTableData() {
    if (this.state.files_data.length >= 1) {
      return this.state.files_data.map((tableData, index) => {
        const { document_name, document_type, document, document_id } =
          tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: 'capitalize' }}>{document_name}</td>
            <td>{document_type}</td>
            <td>
              {checkImage(document) ? (
                <img
                  src={document}
                  style={{ width: '80px', height: '80px' }}
                  alt="Loading"
                />
              ) : (
                <FileCopyIcon />
              )}
            </td>

            <td>
              <Button
                size="small"
                onClick={() =>
                  this.showFileHandler(document_name, document_type, document)
                }
              >
                show
              </Button>
              |
              <Button
                size="small"
                onClick={() =>
                  this.editFileHandler(
                    document_name,
                    document_type,
                    document,
                    document_id
                  )
                }
              >
                edit
              </Button>
              |
              <Button
                size="small"
                onClick={() => this.deleteFileHandler(document_id)}
              >
                delete
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  editFileHandler = (document_name, document_type, document, document_id) => {
    this.setState((prevState) => ({
      editFilesData: {
        ...prevState.editFilesData,
        document_name: document_name,
        document_type: document_type,
        document: document,
        document_id: document_id,
      },
    }));
    this.setState({ openEditFilesDrawer: true });
  };

  showFileHandler = (document_name, document_type, document) => {
    this.setState((prevState) => ({
      showFilesData: {
        ...prevState.showFilesData,
        document: document,
        document_name: document_name,
        document_type: document_type,
      },
    }));
    this.setState({ openFilesShowDrawer: true });
  };

  showFilesDrawerClose = () => {
    this.setState({ openFilesShowDrawer: false });
  };

  deleteFileHandler = (document_id) => {
    axios
      .delete(
        `/delete_entity_documents/${this.state.business_entity_id}/${document_id}`
      )
      .then((res) => {
        this.getFilesData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddFilesInputChange = (event) => {
    const name = event.target.name;
    if (name === 'document') {
      this.setState((prevState) => ({
        invalidImage: null,
        addFilesData: {
          ...prevState.addFilesData,
          document: null,
        },
      }));
      const files = event.target.files[0];

      if (!files) {
        this.setState({ invalidImage: 'Please select image.' });
        return false;
      }

      if (!files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
        this.setState({
          invalidImage:
            'Please select valid file. Only jpg|jpeg|png|pdf is supported',
        });
        return false;
      }

      const p1 = new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = (event) => {
          resolve(event.target.result);
        };
      });

      Promise.all([p1])
        .then((values) => {
          this.setState((prevState) => ({
            addFilesData: {
              ...prevState.addFilesData,
              [name]: values[0],
            },
          }));
        })
        .catch((error) => console.log(error));
    } else {
      const value = event.target.value;
      this.setState((prevState) => ({
        addFilesData: {
          ...prevState.addFilesData,
          [name]: String(value),
        },
      }));
    }
  };

  handleEditFilesInputChange = (event) => {
    const name = event.target.name;

    if (name === 'document') {
      // const dis = this;

      this.setState((prevState) => ({
        invalidImage: null,
        editFilesData: {
          ...prevState.editFilesData,
          document: null,
        },
      }));
      const files = event.target.files[0];

      if (!files) {
        this.setState({ invalidImage: 'Please select image.' });
        return false;
      }

      if (!files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
        this.setState({
          invalidImage:
            'Please select valid file. Only jpg|jpeg|png|pdf is supported',
        });
        return false;
      }

      const p1 = new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = (event) => {
          resolve(event.target.result);
        };
      });

      Promise.all([p1])
        .then((values) => {
          this.setState((prevState) => ({
            editFilesData: {
              ...prevState.editFilesData,
              [name]: values[0],
            },
          }));
        })
        .catch((error) => console.log(error));
    } else {
      const value = event.target.value;
      this.setState((prevState) => ({
        editFilesData: {
          ...prevState.editFilesData,
          [name]: String(value),
        },
      }));
    }
  };

  drawerToggleClose = () => {
    this.setState({ addDrawer: false });
  };

  drawerFilesToggleClose = () => {
    this.setState({ addFilesOpen: false });
  };

  drawerFilesEditToggleClose = () => {
    this.setState({ openEditFilesDrawer: false });
  };

  editDrawerClose = () => {
    this.setState({ openEditDrawer: false });
  };

  showDrawerClose = () => {
    this.setState({ openShowDrawer: false });
  };

  showFilesDrawerClose = () => {
    this.setState({ openFilesShowDrawer: false });
  };

  addFilesSubmitHandler = () => {
    if (this.state.invalidImage) {
      return false;
    }
    axios
      .post(
        `/post_files/${this.state.business_entity_id}`,
        this.state.addFilesData
      )
      .then((res) => {
        this.getFilesData();
        this.setState({ addFilesOpen: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editFilesSubmitHandler = () => {
    if (this.state.invalidImage) {
      return false;
    }
    axios
      .put(`/put_entity_files`, this.state.editFilesData)
      .then((res) => {
        this.getFilesData();
        this.setState({ openEditFilesDrawer: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return this.state.loader ? (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px" }}>
        <div><CircularProgress size={40} /></div>
        <div>Loading Please Wait</div>
      </div>
    ) : (
      <React.Fragment>
        {this.state.status && (
          <this.GetStatus
            status={this.state.status}
            onClick={this.updateStatus}
          />
        )}
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => {
              console.log(errors);
            }}
          >
            <div className={classes.paperHeader}>Entity Details</div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Entity Email Id"
                  name="userId"
                  value={this.state.data.userId ?? ''}
                  inputProps={{ readOnly: 'readonly' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Phone Number"
                  name="phoneNumber"
                  value={this.state.data.phoneNumber ?? ''}
                  inputProps={{ readOnly: 'readonly' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Contact Name"
                  name="entityName"
                  value={this.state.data.applicationKYCData[0].firstName ?? ''}
                  inputProps={{ readOnly: 'readonly' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Company Firm Name"
                  name="companyFirmName"
                  value={
                    this.state.data.applicationKYCData[1].companyFirmName ?? ''
                  }
                  inputProps={{ readOnly: 'readonly' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="PAN"
                  name="pan"
                  value={this.state.data.applicationKYCData[1].pan ?? ''}
                  inputProps={{ readOnly: 'readonly' }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                {this.state.is_onboarded ? (
                  <InputField
                    inputType="text"
                    textLabel="Date Of Incorporation"
                    requiredField="*"
                    name="dateOfIncorporation"
                    value={
                      this.state.data.applicationKYCData[2].dateOfIncorporation
                        ? dateFormat(
                          this.state.data.applicationKYCData[2]
                            .dateOfIncorporation
                        )
                        : ''
                    }
                    inputProps={{ readOnly: 'readonly' }}
                  />
                ) : (
                  <div style={{ marginTop: '15px' }}>
                    <label>
                      Date of incorporation{' '}
                      <span style={{ color: 'red' }}>*</span>:
                    </label>
                    <ValidatedDatePicker
                      name="dateOfIncorporation"
                      validators={['required']}
                      errorMessages={['Date is required']}
                      value={
                        this.state.data.applicationKYCData[2]
                          .dateOfIncorporation ?? ''
                      }
                      onChange={(v) => this.handleDateInputChange(v)}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Legal Structure"
                  name="legalStructure"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.data.legalStructure ?? ''}
                  textOnchangeFunction={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                {/* <InputField
                  inputType="text"
                  textLabel="Industry"
                  name="businessType"
                  value={this.state.data.businessType ?? ""}
                  inputProps={{ readOnly: "readonly" }}
                /> */}
                <SelectField
                  SelectLabel="Industry"
                  choice_id="industry_name"
                  choice_name="industry_name"
                  choices={this.state.industry_data}
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  name="businessType"
                  value={this.state.data && this.state.data.businessType}
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
            </Grid>
            <div className={classes.paperHeader}>Finance Details</div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Turnover(Current Financial Year)"
                  name="turnoverLatestYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.turnoverLatestYear &&
                    this.numberFormat(this.state.data.turnoverLatestYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Gross Profit(Current Financial Year)"
                  name="grossProfitLatestYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.grossProfitLatestYear &&
                    this.numberFormat(this.state.data.grossProfitLatestYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Profit(Current Financial Year)"
                  name="netProfitLatestYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netProfitLatestYear &&
                    this.numberFormat(this.state.data.netProfitLatestYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Turnover(Previous Financial Year)"
                  name="turnoverPriorYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.turnoverPriorYear &&
                    this.numberFormat(this.state.data.turnoverPriorYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Gross Profit(Previous Financial Year)"
                  name="grossProfitPriorYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.grossProfitPriorYear &&
                    this.numberFormat(this.state.data.grossProfitPriorYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Profit(Previous Financial Year)"
                  name="netProfitPriorYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netProfitPriorYear &&
                    this.numberFormat(this.state.data.netProfitPriorYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Turnover(Previous to Previous Financial Year)"
                  name="turnoverPrior2Years"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.turnoverPrior2Years &&
                    this.numberFormat(this.state.data.turnoverPrior2Years)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Gross Profit(Previous to Previous Financial Year)"
                  name="grossProfitPrior2Year"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.grossProfitPrior2Year &&
                    this.numberFormat(this.state.data.grossProfitPrior2Year)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Profit(Previous to Previous Financial Year)"
                  name="netProfitPrior2Year"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netProfitPrior2Year &&
                    this.numberFormat(this.state.data.netProfitPrior2Year)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Turnover(Projected)"
                  name="turnoverNextYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.turnoverNextYear &&
                    this.numberFormat(this.state.data.turnoverNextYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Profit(Projected)"
                  name="netProfitNextYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netProfitNextYear &&
                    this.numberFormat(this.state.data.netProfitNextYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
            </Grid>
            <div className={classes.paperHeader}>
              Balance Sheet Details - “Latest Financial Year Details”{' '}
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Inventory"
                  name="inventory"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.inventory &&
                    this.numberFormat(this.state.data.inventory)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Short Term Borrowings"
                  name="shortTermBorrowings"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.shortTermBorrowings &&
                    this.numberFormat(this.state.data.shortTermBorrowings)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Current Investment"
                  name="currentInvestment"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.currentInvestment &&
                    this.numberFormat(this.state.data.currentInvestment)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Worth(Current Financial Year)"
                  name="netWorthLatestYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netWorthLatestYear &&
                    this.numberFormat(this.state.data.netWorthLatestYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Long Term Borrowings"
                  name="longTermBorrowings"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.longTermBorrowings &&
                    this.numberFormat(this.state.data.longTermBorrowings)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Non-Current Investment"
                  name="nonCurrentInvestment"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.nonCurrentInvestment &&
                    this.numberFormat(this.state.data.nonCurrentInvestment)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Other Assets"
                  name="otherAssets"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.otherAssets &&
                    this.numberFormat(this.state.data.otherAssets)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Receivables"
                  name="receivables"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.receivables &&
                    this.numberFormat(this.state.data.receivables)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Net Worth(Projected)"
                  name="netWorthNextYear"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.netWorthNextYear &&
                    this.numberFormat(this.state.data.netWorthNextYear)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>
            </Grid>

            <div className={classes.paperHeader}>
              Billion Loans Specific Details:
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Expected Credit Limit"
                  name="creditLimit"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.creditLimit &&
                    this.numberFormat(this.state.data.creditLimit)
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Registered Address Line1"
                  name="registeredAddressLine1"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data['applicationKYCData'][2][
                    'registeredAddressLine1'
                    ] ?? ''
                  }
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Current Experience in Job(Owner)"
                  name="currentExperienceInJob"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.data.currentExperienceInJob ?? ''}
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Highest Qualification(Owner)"
                  name="highestQualificationDesc"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.data.highestQualificationDesc ?? ''}
                  textOnchangeFunction={this.handleInputChange}
                  inputProps={
                    this.state.is_onboarded && { readOnly: 'readonly' }
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <InputField
                  inputType="text"
                  textLabel="Other Total Borrowing"
                  name="otherTotalBorrowing"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={
                    this.state.data.otherTotalBorrowing &&
                    this.numberFormat(this.state.data.otherTotalBorrowing)
                  }
                  textOnchangeFunction={this.handleInputChange}
                />
              </Grid>
            </Grid>

            {/* ---Owner table--- */}
            <div className={classes.tableMainDiv}>
              <div className={classes.table_title}>Owner</div>

              {this.state.is_onboarded ? (
                <CustomTable
                  headers={this.state.ownerHeader}
                  columns={this.state.ownerCol}
                  data={this.state.data.companyOwners}
                  name="owners"
                />
              ) : (
                <div className={classes.table_Body}>
                  <div className={classes.searchField}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        {this.state.total_percentage < 100 && (
                          <Button
                            size="small"
                            onClick={() => this.addOwnerData()}
                            variant="outlined"
                          >
                            add owner
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </div>
                  <div className={classes.tableResponisve}>
                    <table className={classes.tablecss}>
                      <tbody>
                        <tr>{this.ownerTableHeader()}</tr>
                        {this.ownerTableData()}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className={classes.tableMainDiv}>
              <div className={classes.table_title}>Document Details</div>
              {this.state.is_onboarded ? (
                <CustomTable
                  headers={this.state.fileHeader}
                  columns={this.state.fileCol}
                  data={this.state.data.applicationDocuments}
                  name="owners"
                />
              ) : (
                <div className={classes.table_Body}>
                  <div className={classes.searchField}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Button
                          size="small"
                          onClick={() => this.addFilesOpenToggleData()}
                          variant="outlined"
                        >
                          ADD Document
                        </Button>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </div>
                  <div className={classes.tableResponisve}>
                    <table className={classes.tablecss}>
                      <tbody>
                        <tr>{this.filesTableHeader()}</tr>
                        {this.filesTableData()}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            {!this.state.is_onboarded && (
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
            )}
          </ValidatorForm>
        </div>
        {/* ---Files Add--- */}
        {/* ---ADD OWNER--- */}
        <Drawer
          anchor="right"
          open={this.state.addDrawer}
          onClose={this.drawerToggleClose}
        >
          <div className={classes.editDetails}>
            <div className={classes.drawerBody}>
              {/* date_incorporation, msme */}
              <ValidatorForm
                ref="form"
                onSubmit={this.addOwnerSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="title"
                  SelectLabel="Title"
                  name="title"
                  value={this.state.addOwnerData.title ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                  choices={[
                    {
                      id: 1,
                      title: 'Mr.',
                    },
                    {
                      id: 2,
                      title: 'Ms.',
                    },
                    {
                      id: 3,
                      title: 'Mrs.',
                    },
                  ]}
                />

                <InputField
                  inputType="text"
                  textLabel="First Name"
                  name="first_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.first_name ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Middle Name"
                  name="middle_name"
                  value={this.state.addOwnerData.middle_name ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Last Name"
                  name="last_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.last_name ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <div style={{ marginTop: '20px' }}>
                  <label>
                    Date Of Birth
                    <span style={{ color: 'red' }}>*</span>:
                  </label>
                  <DatePicker
                    name="dob"
                    value={this.state.addOwnerData.dob ?? ''}
                    onChange={(v) => this.handleDateOwnerChange(v)}
                    validators={['required']}
                    errorMessages={['Date is required']}
                  />
                </div>

                <InputField
                  inputType="text"
                  textLabel="Mobile"
                  name="mobile"
                  requiredField="*"
                  validator={['required', 'matchRegexp:^[0-9]{10}$']}
                  errorMsg={['This field is required', 'Invalid mobile number']}
                  value={this.state.addOwnerData.mobile ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="nationality_name"
                  SelectLabel="Nationality"
                  name="nationality"
                  value={this.state.addOwnerData.nationality ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                  choices={[{ id: 1, nationality_name: 'Indian' }]}
                />

                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="resident_status"
                  SelectLabel="Residential Status"
                  name="residential_status"
                  value={this.state.addOwnerData.residential_status ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                  choices={residential_status}
                />

                <InputField
                  inputType="text"
                  textLabel="Designation"
                  name="designation"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.designation ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="PAN"
                  name="owner_pan"
                  requiredField="*"
                  validator={[
                    'required',
                    'matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$',
                  ]}
                  errorMsg={['This field is required', 'Invalid PAN Number']}
                  value={this.state.addOwnerData.owner_pan ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="aadhaar Number"
                  requiredField="*"
                  name="aadhaar_number"
                  validator={['required', 'matchRegexp:^[0-9]{12}$']}
                  errorMsg={[
                    'This field is required',
                    'Invalid Aadhar number.',
                  ]}
                  value={this.state.addOwnerData.aadhaar_number ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="DIN"
                  name="din"
                  validator={['matchRegexp:^[0-9]{8}$']}
                  errorMsg={['Invalid DIN Number']}
                  value={this.state.addOwnerData.din ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="CIN"
                  name="cin"
                  validator={[
                    'matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$',
                  ]}
                  errorMsg={['Invalid CIN']}
                  value={this.state.addOwnerData.cin ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Company Name"
                  name="company_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.company_name ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Father/Spouse Name"
                  name="father_spouse_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.father_spouse_name ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Landline Number"
                  name="telephone"
                  requiredField="*"
                  validator={['required', 'matchRegexp:^[0-9]{10}$']}
                  errorMsg={[
                    'This field is required',
                    'Invalid telephone number.',
                  ]}
                  value={this.state.addOwnerData.telephone ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="% Share"
                  name="percentage"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.addOwnerData.percentage ?? ''}
                  textOnchangeFunction={this.handleAddOwnerInputChange}
                />

                <Button
                  style={{ marginTop: '20px' }}
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{
                    float: 'right',
                    marginTop: '20px',
                    marginBottom: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={this.drawerToggleClose}
                >
                  cancel
                </Button>
              </ValidatorForm>
            </div>
          </div>
        </Drawer>

        {/* ---EDIT OWNER--- */}
        <Drawer
          anchor="right"
          open={this.state.openEditDrawer}
          onClose={this.editDrawerClose}
        >
          <div className={classes.editDetails}>
            <div className={classes.drawerBody}>
              {/* date_incorporation, msme */}
              <ValidatorForm
                ref="form"
                onSubmit={this.editOwnerSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="title"
                  SelectLabel="Title"
                  name="title"
                  value={this.state.editOwnerData.title ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                  choices={[
                    {
                      id: 1,
                      title: 'Mr.',
                    },
                    {
                      id: 2,
                      title: 'Ms.',
                    },
                    {
                      id: 3,
                      title: 'Mrs.',
                    },
                  ]}
                />

                <InputField
                  inputType="text"
                  textLabel="First Name"
                  name="first_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.first_name ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Middle Name"
                  name="middle_name"
                  value={this.state.editOwnerData.middle_name ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Last Name"
                  name="last_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.last_name ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <div style={{ marginTop: '20px' }}>
                  <label>
                    Date Of Birth
                    <span style={{ color: 'red' }}>*</span>:
                  </label>
                  <DatePicker
                    name="dob"
                    value={this.state.editOwnerData.dob ?? ''}
                    onChange={(v) => this.handleDateOwnerChange(v)}
                    validators={['required']}
                    errorMessages={['Date is required']}
                  />
                </div>

                <InputField
                  inputType="text"
                  textLabel="Mobile"
                  name="mobile"
                  requiredField="*"
                  validator={['required', 'matchRegexp:^[0-9]{10}$']}
                  errorMsg={['This field is required', 'Invalid Mobile number']}
                  value={this.state.editOwnerData.mobile ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                {/* <InputField
                  inputType="text"
                  textLabel="Nationality"
                  name="nationality"
                  requiredField="*"
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  value={this.state.editOwnerData.nationality ?? ""}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                /> */}
                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="nationality"
                  SelectLabel="Nationality"
                  name="nationality"
                  value={this.state.editOwnerData.nationality ?? 1}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                  choices={[{ id: 1, nationality: 'Indian' }]}
                />

                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="resident_status"
                  SelectLabel="Residential Status"
                  name="residential_status"
                  value={this.state.editOwnerData.residential_status ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                  choices={residential_status}
                />

                <InputField
                  inputType="text"
                  textLabel="Designation"
                  name="designation"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.designation ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="PAN"
                  name="owner_pan"
                  requiredField="*"
                  validator={[
                    'required',
                    'matchRegexp:^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$',
                  ]}
                  errorMsg={['This field is required', 'Invalid PAN number']}
                  value={this.state.editOwnerData.owner_pan ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="aadhaar Number"
                  name="aadhaar_number"
                  validator={['required', 'matchRegexp:^[0-9]{12}$']}
                  errorMsg={[
                    'This field is required',
                    'Invalid Aadhar number.',
                  ]}
                  value={this.state.editOwnerData.aadhaar_number ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="DIN"
                  name="din"
                  validator={['matchRegexp:^[0-9]{8}$']}
                  errorMsg={['Invalid DIN Number']}
                  value={this.state.editOwnerData.din ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="CIN"
                  name="cin"
                  validator={[
                    'matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$',
                  ]}
                  errorMsg={['Invalid CIN']}
                  value={this.state.editOwnerData.cin ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Company Name"
                  name="company_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.company_name ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Father/Spouse Name"
                  name="father_spouse_name"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.father_spouse_name ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="Landline Number"
                  name="telephone"
                  requiredField="*"
                  validator={['required', 'matchRegexp:^[0-9]{10}$']}
                  errorMsg={[
                    'This field is required',
                    'Invalid telephone number.',
                  ]}
                  value={this.state.editOwnerData.telephone ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <InputField
                  inputType="text"
                  textLabel="% Share"
                  name="percentage"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  value={this.state.editOwnerData.percentage ?? ''}
                  textOnchangeFunction={this.handleEditOwnerInputChange}
                />

                <Button
                  style={{ marginTop: '20px' }}
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{
                    float: 'right',
                    marginTop: '20px',
                    marginBottom: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={this.editDrawerClose}
                >
                  cancel
                </Button>
              </ValidatorForm>
            </div>
          </div>
        </Drawer>

        {/* ---Show owner--- */}
        <Drawer
          anchor="right"
          open={this.state.openShowDrawer}
          onClose={this.showDrawerClose}
        >
          <div className={classes.showDetails}>
            <div
              style={{
                background: '#c4d0d9',
                paddingLeft: '20px',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              Owner Details
            </div>
            {this.state.showOwnerData ? (
              <>
                <div className={classes.showDetailsBody}>
                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="First Name"
                    defaultValue={this.state.showOwnerData.first_name}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Middle Name"
                    defaultValue={
                      this.state.showOwnerData.middle_name
                        ? this.state.showOwnerData.middle_name
                        : ''
                    }
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Last Name"
                    defaultValue={this.state.showOwnerData.last_name}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Date Of Birth"
                    defaultValue={dateFormat(this.state.showOwnerData.dob)}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Mobile"
                    defaultValue={this.state.showOwnerData.mobile}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Nationality"
                    defaultValue={this.state.showOwnerData.nationality_value}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Father/Spouse Name"
                    defaultValue={this.state.showOwnerData.father_spouse_name}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Company Name"
                    defaultValue={this.state.showOwnerData.company_name}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Designation"
                    defaultValue={this.state.showOwnerData.designation}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Landline Number"
                    defaultValue={this.state.showOwnerData.telephone}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="PAN"
                    defaultValue={this.state.showOwnerData.owner_pan}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="DIN"
                    defaultValue={this.state.showOwnerData.din}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="CIN"
                    defaultValue={this.state.showOwnerData.cin}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="% Share"
                    defaultValue={this.state.showOwnerData.percentage}
                  />
                </div>
              </>
            ) : (
              'No Data Found'
            )}
            <Button
              style={{
                float: 'right',
                marginTop: '20px',
                marginBottom: '30px',
              }}
              variant="contained"
              size="small"
              onClick={this.showDrawerClose}
            >
              cancel
            </Button>
          </div>
        </Drawer>
        {/*---Owner Table End--- */}

        {/* ---Document table--- */}

        <Drawer
          anchor="right"
          open={this.state.addFilesOpen}
          onClose={this.drawerFilesToggleClose}
        >
          <div className={classes.editDetails}>
            <div className={classes.drawerBody}>
              {/* date_incorporation, msme */}
              <ValidatorForm
                ref="form"
                onSubmit={this.addFilesSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <div style={{ color: 'red', width: '300px' }}>
                  {this.state.addFilesData.document_type ===
                    'COMPANY PROFILE' &&
                    'Please ensure to upload Balance Sheet, Profit & Loss A/C, Cash Flow Statement documents for last 3 years'}
                </div>
                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="value"
                  SelectLabel="Document Type"
                  name="document_type"
                  value={this.state.addFilesData.document_type ?? ''}
                  textOnchangeFunction={this.handleAddFilesInputChange}
                  choices={[
                    {
                      id: 'PAN',
                      value: 'PAN',
                    },
                    {
                      id: 'GSTIN',
                      value: 'GSTIN',
                    },
                    {
                      id: 'COMPANY PROFILE',
                      value: 'COMPANY PROFILE',
                    },
                    {
                      id: 'OTHERS',
                      value: 'OTHERS',
                    },
                  ]}
                />

                <InputField
                  inputType="text"
                  textLabel="Document Name"
                  name="document_name"
                  requiredField="*"
                  validator={['required']}
                  value={this.state.addFilesData.document_name ?? ''}
                  textOnchangeFunction={this.handleAddFilesInputChange}
                />
                <div>
                  <img
                    alt="Loading"
                    style={{ width: '300px', height: '300px' }}
                    src={this.state.addFilesData.document ?? ''}
                  />

                  <InputField
                    inputType="file"
                    textLabel="Document"
                    name="document"
                    requiredField="*"
                    textOnchangeFunction={this.handleAddFilesInputChange}
                  />
                  {this.state.invalidImage && (
                    <div style={{ color: 'red' }}>
                      {this.state.invalidImage}
                    </div>
                  )}
                </div>
                <Button
                  style={{ marginTop: '20px' }}
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{
                    float: 'right',
                    marginTop: '20px',
                    marginBottom: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={this.drawerFilesToggleClose}
                >
                  cancel
                </Button>
              </ValidatorForm>
            </div>
          </div>
        </Drawer>

        <Drawer
          anchor="right"
          open={this.state.openEditFilesDrawer}
          onClose={this.drawerFilesEditToggleClose}
        >
          <div className={classes.editDetails}>
            <div className={classes.drawerBody}>
              {/* date_incorporation, msme */}
              <ValidatorForm
                ref="form"
                onSubmit={this.editFilesSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <SelectField
                  choice_id="id"
                  requiredField="*"
                  validator={['required']}
                  errorMsg={['This field is required']}
                  choice_name="value"
                  SelectLabel="Document Type"
                  name="document_type"
                  value={this.state.editFilesData.document_type ?? ''}
                  textOnchangeFunction={this.handleEditFilesInputChange}
                  choices={[
                    {
                      id: 'PAN',
                      value: 'PAN',
                    },
                    {
                      id: 'GSTIN',
                      value: 'GSTIN',
                    },
                    {
                      id: 'COMPANY PROFILE',
                      value: 'COMPANY PROFILE',
                    },
                    {
                      id: 'OTHERS',
                      value: 'OTHERS',
                    },
                  ]}
                />

                <InputField
                  inputType="text"
                  textLabel="Document Name"
                  name="document_name"
                  requiredField="*"
                  validator={['required']}
                  value={this.state.editFilesData.document_name ?? ''}
                  textOnchangeFunction={this.handleEditFilesInputChange}
                />

                <img
                  alt="Loading"
                  style={{ width: '300px', height: '300px' }}
                  src={this.state.editFilesData.document ?? ''}
                />

                <InputField
                  inputType="file"
                  textLabel="Document"
                  name="document"
                  requiredField="*"
                  textOnchangeFunction={this.handleEditFilesInputChange}
                />
                {this.state.invalidImage && (
                  <div style={{ color: 'red' }}>{this.state.invalidImage}</div>
                )}
                <Button
                  style={{ marginTop: '20px' }}
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{
                    float: 'right',
                    marginTop: '20px',
                    marginBottom: '30px',
                  }}
                  variant="contained"
                  size="small"
                  onClick={this.drawerFilesEditToggleClose}
                >
                  cancel
                </Button>
              </ValidatorForm>
            </div>
          </div>
        </Drawer>

        {/* Show Files */}
        <Drawer
          anchor="right"
          open={this.state.openFilesShowDrawer}
          onClose={this.showFilesDrawerClose}
        >
          <div className={classes.showDetails}>
            <div
              style={{
                background: '#c4d0d9',
                paddingLeft: '20px',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              File Details
            </div>
            {this.state.showFilesData ? (
              <>
                <div className={classes.showDetailsBody}>
                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Document Type"
                    defaultValue={this.state.showFilesData.document_type}
                  />

                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Document Name"
                    defaultValue={this.state.showFilesData.document_name}
                  />

                  <img
                    alt="Loading"
                    style={{ width: '300px', height: '300px' }}
                    src={this.state.showFilesData.document ?? ''}
                  />
                </div>
              </>
            ) : (
              'No Data Found'
            )}
            <Button
              style={{
                float: 'right',
                marginTop: '20px',
                marginBottom: '30px',
              }}
              variant="contained"
              size="small"
              onClick={this.showFilesDrawerClose}
            >
              cancel
            </Button>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default Onboarding;
