import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
// import highcharts3d from "highcharts/highcharts-3d";
// import { setDayWithOptions } from 'date-fns/fp';

// highcharts3d(Highcharts);


function generateDarkColorHex() {
    let color = "#";
    for (let i = 0; i < 3; i++)
        color += ("0" + Math.floor(Math.random() * Math.pow(16, 2) / 2).toString(16)).slice(-2);
    return color;
}



function getRandColor(same, darkness, total_series) {

    let arr = [];
    let i = 0;
    for (i; i < total_series; i++) {
        const randomColor = generateDarkColorHex();
        arr.push(randomColor);
    }
    return arr;
}

const setOptions = (props) => {

    return props.data && {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            margin: [30, 0, 30, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true
        },
        title: {
            text: props.pieChartTitle
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b> '
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size:'80%',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y} '
                },
                showInLegend: true
            }
        },
        series: [{
            showInLegend: true,
            data: props.data,
            colors: getRandColor(false, 5, props.data.length)
        }],

    }
}


const MyStockChart = (props) => <HighchartsReact
containerProps={{ style: { height: "300px"  } }}
    highcharts={Highcharts}
    allowChartUpdate={true}
    options={setOptions(props)}

/>

export default MyStockChart