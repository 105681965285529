import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Grid, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import dasign3b from "./GSTR-3B_recon.module.css";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router";
import axios from "../../../../axios-order";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import CryptoJS from "crypto-js";
import CircularProgress from "@material-ui/core/CircularProgress";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(0,123,255)",
    },
  },
}));

const GSTR_3B_Recon = () => {
  const history = useHistory();
  //Filter
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [period, setPeriod] = useState("");

  //ErrorMessage
  const [displayProperty, setDisplayProperty] = useState("none");
  const [message, setMessage] = useState("");
  const [colorForMessage, setColorForMessage] = useState("");

  const [tableData, setTableData] = useState([]);

  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loaderInSubmit, setLoaderInSubmit] = useState(false);

  const dateForFun = new Date();
  const date = dateForFun.getFullYear();

  const Financial = [
    { id: `${date}`, value: `${date}-${parseInt(String(date).slice(-2)) + 1}` },
    {
      id: `${date - 1}`,
      value: `${date - 1}-${parseInt(String(date - 1).slice(-2)) + 1}`,
    },
    {
      id: `${date - 2}`,
      value: `${date - 2}-${parseInt(String(date - 2).slice(-2)) + 1}`,
    },
  ];

  const Quter = [
    { id: "quarter1", value: `Quarter 1 Apr-Jun` },
    { id: "quarter2", value: `Quarter 2 Jul-Sep` },
    { id: "quarter3", value: `Quarter 3 Oct-Dec` },
    { id: "quarter4", value: `Quarter 4 Jan-Mar` },
  ];

  const month = [
    { id: "01", value: "January" },
    { id: "02", value: "February" },
    { id: "03", value: "March" },
    { id: "04", value: "April" },
    { id: "05", value: "May" },
    { id: "06", value: "June" },
    { id: "07", value: "July" },
    { id: "08", value: "August" },
    { id: "09", value: "September" },
    { id: "10", value: "October" },
    { id: "11", value: "November" },
    { id: "12", value: "December" },
  ];

  const handleReportPage = (id) => {
    const encrypt = (text) => {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };
    history.push(`/GSTR_3B_ReportingPage/${encrypt(id)}`);
  };

  const funForErrorMessage = (display, message, color) => {
    setDisplayProperty(display);
    setMessage(message);
    setColorForMessage(color);
  };

  const handleSubmitInFilter = () => {
    if (year === "" || period === "") {
      funForErrorMessage(
        "block",
        "Financial year and Period field cannot be empty",
        "red"
      );
    } else {
      setLoaderInSubmit(true);
      funForErrorMessage("none", "", "");
      let DateInDiff = 0;
      if (year === `${date}`) {
        DateInDiff = `${date}-${parseInt(String(date).slice(-2)) + 1}`;
      } else if (year === `${date - 1}`) {
        DateInDiff = `${date - 1}-${parseInt(String(date - 1).slice(-2)) + 1}`;
      } else {
        DateInDiff = `${date - 2}-${parseInt(String(date - 2).slice(-2)) + 1}`;
      }
      axios
        .post(`/gstr3b/generate_report/`, {
          business_entity_id: localStorage.getItem("business_entity_id"),
          year: year,
          month: period,
          DateYear: DateInDiff,
        })
        .then((res) => {
          if (res.data.status === true) {
            setLoaderInSubmit(false);
            funForErrorMessage("block", res.data.message, "green");
            getApiForTable();
          } else {
            setLoaderInSubmit(false);
            funForErrorMessage("block", res.data.message, "red");
          }
        })
        .catch(() => {
          setLoaderInSubmit(false);
          funForErrorMessage("block", "something went wrong", "red");
        });
    }
  };

  const getApiForTable = () => {
    setLoaderForTable(true);
    axios
      .get(
        `/gstr3b/get_summary_list/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        if (res.data.status === true) {
          setLoaderForTable(false);
          setTableData(res.data.records);
        } else {
          if (res.data.status === false) {
            setTableData([]);
            setLoaderForTable(false);
          }
        }
      })
      .catch((err) => setLoaderForTable(false));
  };

  useEffect(() => {
    getApiForTable();
  }, []);

  return (
    <div>
      <BoxWrapper boxTitle="GSTR-3B Reconciliation">
        <Grid container spacing={3}>
          <Grid item xs={6} lg={3}>
            <label>
              Financial year<sup style={{ color: "red" }}>*</sup>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={year}
                // disabled={getApiDetailsForRegionName === "" ? true : false}
                onChange={(e) => {
                  setYear(e.target.value);
                  setQuarter("");
                  setPeriod("");
                }}
                input={<BootstrapInput />}
              >
                {Financial.map((v) => {
                  return <MenuItem value={v.id}>{v.value}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} lg={4}>
            <label>
              Quarter<sup style={{ color: "red" }}>*</sup>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={quarter}
                // disabled={getApiDetailsForRegionName === "" ? true : false}
                onChange={(e) => {
                  setQuarter(e.target.value);
                }}
                input={<BootstrapInput />}
              >
                {Quter.map((v) => {
                  return <MenuItem value={v.id}>{v.value}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} lg={4}>
            <label>
              Period<sup style={{ color: "red" }}>*</sup>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={period}
                disabled={quarter === "" ? true : false}
                onChange={(e) => {
                  setPeriod(e.target.value);
                }}
                input={<BootstrapInput />}
              >
                {month.map((v) => {
                  if (quarter === "quarter1") {
                    if (v.id === "04" || v.id === "05" || v.id === "06") {
                      return <MenuItem value={v.id}>{v.value}</MenuItem>;
                    }
                  } else if (quarter === "quarter2") {
                    if (v.id === "07" || v.id === "08" || v.id === "09")
                      return <MenuItem value={v.id}>{v.value}</MenuItem>;
                  } else if (quarter === "quarter3") {
                    if (v.id === "10" || v.id === "11" || v.id === "12") {
                      return <MenuItem value={v.id}>{v.value}</MenuItem>;
                    }
                  } else {
                    if (v.id === "01" || v.id === "02" || v.id === "03")
                      return <MenuItem value={v.id}>{v.value}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1} lg={1}>
            {loaderInSubmit ? (
              <Button
                variant="contained"
                disabled
                className={dasign3b.topHeaderButton}
                size="small"
              >
                <CircularProgress size={22} style={{ color: "white" }} />
                <span
                  style={{
                    textTransform: "capitalize",
                    paddingLeft: "10px",
                  }}
                >
                  Uploading...
                </span>
              </Button>
            ) : (
              <button
                className="button-primary"
                type="button"
                // className={dasign3b.topHeaderButton}
                style={{ margin: "28px 0px", padding: "8px 15px" }}
                onClick={handleSubmitInFilter}
              >
                Submit
              </button>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            color: colorForMessage,
            display: displayProperty,
            marginTop: "5px",
            fontWeight: "bold",
          }}
        >
          <>
            <span style={{ width: "20px", height: "20px", paddingTop: "6px" }}>
              {message}
            </span>{" "}
            <ClearTwoToneIcon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => {
                funForErrorMessage("none", "", "");
                setYear("");
                setQuarter("");
                setPeriod("");
              }}
            />
          </>
        </div>
      </BoxWrapper>
      <BoxWrapper boxTitle="GSTR-3B Report">
        <div style={{ textAlign: "right" }}>
          <button className="button-outlined-primary" onClick={getApiForTable}>
            {/* <RefreshIcon style={{ fontSize: "20px" }} /> */}
            Refresh
          </button>
        </div>
        <div className="responsive-table" style={{ marginTop: "15px" }}>
          <table className="custome-table">
            <thead>
              <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                <th>Year</th>
                <th>Period</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={5}
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    Loading..Please Wait!
                  </td>
                </tr>
              ) : (
                <>
                  {tableData?.map((v) => {
                    console.log(v.status);
                    return (
                      <tr>
                        <td>{v.year}</td>
                        <td>{v.month}</td>
                        <td>
                          {v.created_date
                            ? `${v.created_date.split("T")[0]}  ${
                                v.created_date.split("T")[1]
                              }`
                            : ""}
                        </td>
                        <td>{v.status}</td>
                        <td style={{ cursor: "pointer" }}>
                          {v.status === "Pass" ? (
                            <VisibilityOutlinedIcon
                              className="action-icon-button showIcon"
                              onClick={() => handleReportPage(v.id)}
                            />
                          ) : v.status === "Failed" ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon color="disabled" />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {tableData.length === 0 && (
                    <tr>
                      <td
                        colSpan={5}
                        style={{ color: "red", textAlign: "center" }}
                      >
                        No Data Found!!
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </div>
  );
};

export default GSTR_3B_Recon;
