import React, { Component } from "react";
import classes from "./Dashboard.module.css";
import { Container, CircularProgress } from "@material-ui/core";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "../../../../asserts/css/Onboarding.css";

// Number format
const numberFormat = (value) => {
  if (value) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  } else {
    return "₹0";
  }
};

const dateFormat = (value) => {
  if (typeof value === "undefined") return "";
  let old_date = String(value).split("-");
  return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
};
// ---End---
class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiEndPoint: this.props.location.data,
      allData: [],
      basic_details: {},
      InvoiceDetails: [],
      DebitNote: [],
      CreditNote: [],
      CollectionDetails: [],
      AsOfDate: "",
      loading: false,
      error: false,
    };
  }
  componentDidMount = () => {
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    this.setState({
      AsOfDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
      loading: true,
    });

    if (this.state.apiEndPoint) {
      axios
        .get(
          `/invoices/Customer_invoices${this.state.apiEndPoint[0]
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ allData: res.data });
          this.setState({
            basic_details: res.data.basic_details,
            InvoiceDetails: [res.data.invoice_details],
            DebitNote: res.data.debit_note,
            CreditNote: res.data.credit_note,
            CollectionDetails: res.data.collection_details,
          });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  };

  // invoice Details table
  Invoice_DetailsTableHeader() {
    const tableHeader = [
      "invoice number",
      "e invoice number",
      "invoice date",
      "Invoice Amount - Basic",
      "Amount (Non Taxable value)",
      "GST Amount",
      "Total Invoice Amount",
      "due date",
      "po number",
      "invoice type",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  Invoice_DetailsTableData() {
    if (this.state?.InvoiceDetails.length > 0) {
      return this.state?.InvoiceDetails?.map((tableData, index) => {
        const {
          invoice_number,
          e_invoice_number,
          amount_non_taxable,
          invoice_date,
          taxable_value,
          due_date,
          po_number,
          total_invoice_amount,
          invoice_type,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ width: 150 }}> {invoice_number}</td>
            <td>{e_invoice_number}</td>
            <td style={{ width: 100 }}>{dateFormat(invoice_date)}</td>
            <td>{numberFormat(taxable_value)}</td>
            <td>{numberFormat(amount_non_taxable)}</td>
            <td>{numberFormat(tableData["non-taxable_value"])}</td>
            <td>{numberFormat(total_invoice_amount)}</td>
            <td style={{ width: 100 }}>{dateFormat(due_date)}</td>
            <td style={{ width: 200 }}>{po_number ? po_number : "-"}</td>
            <td>{invoice_type ? invoice_type : "-"}</td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td colSpan={10}>Loading...</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colSpan={10} style={{ color: "red" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  }

  // debit note
  Debit_NoteTableHeader() {
    const tableHeader = [
      "debit note no",
      "debit note date",
      "Amount(Taxable)",
      "Amount (Non Taxable value)",
      "GST Amount",
      "Total Amount",
      "Reason",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  Debit_NoteTableData() {
    return this.state.DebitNote.length > 0 ? (
      this.state.DebitNote.map((tableData, index) => {
        const {
          amount_non_taxable,
          amount_taxable,
          debit_note_date,
          debit_note_number,
          reason,
          tax_amount,
          total_amount,
        } = tableData;
        return (
          <tr key={index}>
            <td>{debit_note_number}</td>
            <td>{dateFormat(debit_note_date)}</td>
            <td>{numberFormat(amount_taxable)}</td>
            <td>{numberFormat(amount_non_taxable)}</td>
            <td>{numberFormat(tax_amount)}</td>
            <td>{numberFormat(total_amount)}</td>
            <td>{reason}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7} style={{ color: "red" }}>
          No Data Found!
        </td>
      </tr>
    );
  }
  // debit note
  Credit_NoteTableHeader() {
    const tableHeader = [
      "credit note no",
      "credit note date",
      "Amount(Taxable)",
      "Amount (Non Taxable value)",
      "GST Amount",
      "Total Amount",
      "Reason",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  Credit_NoteTableData() {
    return this.state.CreditNote.length > 0 ? (
      this.state.CreditNote.map((tableData, index) => {
        const {
          amount_non_taxable,
          amount_taxable,
          credit_note_date,
          credit_note_number,
          reason,
          tax_amount,
          total_amount,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{credit_note_number}</td>
            <td>{dateFormat(credit_note_date)}</td>
            <td>{numberFormat(amount_taxable)}</td>
            <td>{numberFormat(amount_non_taxable)}</td>
            <td>{numberFormat(tax_amount)}</td>
            <td>{numberFormat(total_amount)}</td>

            <td>{reason}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={7} style={{ color: "red" }}>
          No Data Found!
        </td>
      </tr>
    );
  }

  // debit note
  collection_TableHeader() {
    const tableHeader = [
      "collection date",
      "bank account  number",
      "collection amount",
      "transaction reference number",
      "payment date",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  collection_TableData() {
    return this.state.CollectionDetails.length ? (
      this.state.CollectionDetails.map((tableData, index) => {
        const {
          bank_account_number,
          collection_amount,
          collection_date,
          payment_date,
          transaction_reference_number,
        } = tableData;
        return (
          <tr key={index}>
            <td>{dateFormat(collection_date)}</td>
            <td>{bank_account_number}</td>
            <td>{numberFormat(collection_amount)}</td>
            <td>{transaction_reference_number}</td>
            <td>{dateFormat(payment_date)}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={6} style={{ color: "red" }}>
          No Data Found!
        </td>
      </tr>
    );
  }

  // excel download for invoice data
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  InvoiceDetailsDataDownload = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `invoice report.xlsx`;
    axios
      .post(`/invoices/download`, { data: this.state.allData })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  backHanlder = () => {
    window.history.back();
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <button
                style={{ marginBottom: "10px" }}
                className="button-outlined-primary"
                onClick={this.backHanlder}
              >
                back
              </button>
            </Grid>
            <Grid item xs={9}>
              {" "}
            </Grid>
            <Grid item xs={2}>
              <div style={{ float: "right" }}>
                <button
                  className="button-primary"
                  onClick={this.InvoiceDetailsDataDownload}
                >
                  Export to Excel
                </button>
              </div>
            </Grid>
          </Grid>

          <div>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                background: "#d04646",
                color: "#fff",
                padding: "8px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Invoice Report
            </div>

            {this.state.loading ? (
              <div style={{ marginTop: "50px" }}>
                <center>
                  <CircularProgress />
                  <br></br>
                  Loading,Please wait...
                </center>
              </div>
            ) : this.state.error ? (
              <div>
                <center>
                  Please go to home page and click the show button to see the
                  report.
                  <br></br>
                  <button
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    className="button-outlined-primary"
                    onClick={this.backHanlder}
                  >
                    Home
                  </button>
                </center>
              </div>
            ) : (
              <div className={classes.invoiceForm}>
                {/* invoice Report */}
                <div
                  className={classes.InvoiceReport}
                  style={{ marginBottom: "20px" }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <table className={classes.tablecss}>
                        <tbody>
                          <tr>
                            <td>As of Date:</td>
                            <td>{this.state.AsOfDate}</td>
                          </tr>
                          <tr>
                            <td>Customer Name: </td>
                            <td>{this.state.basic_details?.customer_name}</td>
                          </tr>
                          <tr>
                            <td>Customer Code:</td>
                            <td>{this.state.basic_details?.customer_code}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                    <Grid item xs={6}>
                      <table id="customer-Details" className={classes.tablecss}>
                        <tbody>
                          <tr>
                            <td>Customer PAN :</td>
                            <td>{this.state.basic_details?.pan}</td>
                          </tr>
                          <tr>
                            <td>Customer GSTIN:</td>
                            <td>{this.state.basic_details?.gstin}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </div>

                {/* Invoice Details */}
                <div
                  className={classes.InvoiceReport}
                  style={{ marginBottom: "20px" }}
                >
                  <div className={classes.tableDivHeader}>Invoice Details</div>
                  <table id="table" className="custome-table">
                    <tbody>
                      <tr>{this.Invoice_DetailsTableHeader()}</tr>
                      {this.Invoice_DetailsTableData()}
                    </tbody>
                  </table>
                </div>
                {/* debit Note */}
                <div
                  className={classes.InvoiceReport}
                  style={{ marginBottom: "20px" }}
                >
                  <div className={classes.tableDivHeader}>Debit Note</div>
                  <table id="table2" className="custome-table">
                    <tbody>
                      <tr>{this.Debit_NoteTableHeader()}</tr>
                      {this.Debit_NoteTableData()}
                    </tbody>
                  </table>
                </div>
                {/* Credit Note */}
                <div
                  className={classes.InvoiceReport}
                  style={{ marginBottom: "20px" }}
                >
                  <div className={classes.tableDivHeader}>Credit Note</div>
                  <table id="table3" className="custome-table">
                    <tbody>
                      <tr>{this.Credit_NoteTableHeader()}</tr>
                      {this.Credit_NoteTableData()}
                    </tbody>
                  </table>
                </div>
                {/* Collection Details */}
                <div
                  className={classes.InvoiceReport}
                  style={{ marginBottom: "20px" }}
                >
                  <div className={classes.tableDivHeader}>
                    Collection Details
                  </div>
                  <table id="table4" className="custome-table">
                    <tbody>
                      <tr>{this.collection_TableHeader()}</tr>
                      {this.collection_TableData()}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Showdetails;
