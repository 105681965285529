import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

const BasicTextFields = (props) => {
  return (
    <div>
      <label>
        {props.textLabel}
        <span style={{ color: "red" }}>{props.requiredField}</span>
      </label>
      <TextValidator
        validators={props.validator}
        errorMessages={props.errorMsg}
        inputProps={props.inputProps ? props.inputProps : {}}
        value={props.value}
        name={props.name}
        type={props.inputType}
        placeholder={props.inputPlacholder}
        onChange={props.textOnchangeFunction}
        fullWidth="true"
        size="small"
        variant="outlined"
        autoComplete="true"
        style={{ background: "#fff", borderRadius: "5px" }}
      />
    </div>
  );
};

export default BasicTextFields;
