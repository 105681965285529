import React, { Component } from 'react';
import classes from './ARCInvoiceDiscounting.module.css';
import axios from '../../../../axios-order';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '../../../../components/ui/Cards';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DataTables from 'material-ui-datatables';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SellerID from '../../../../asserts/templates/seller_invoice_discounting.xlsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputField from '../../../../components/ui/inputField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Drawer from '@material-ui/core/Drawer';
import ClearIcon from '@material-ui/icons/Clear';
import ValidatedDatePicker from '../../../../components/ui/DatePicker';
import Modal from 'react-bootstrap/Modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TablePagination } from '@material-ui/core';
import BoxWrapper from '../../../../components/ui/boxWrapper';
import RefreshIcon from '@mui/icons-material/Refresh';

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  // if (typeof value === "undefined") return false;
  if (value) {
    let old_date = String(value).split('-');
    return old_date[2] + '/' + old_date[1] + '/' + old_date[0];
  }
};

class InvoiceDiscounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      read_only: '',
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: 'GSTIN',
          render: (name, all) => {
            return all['record']['BuyerGSTIN'];
          },
        },
        {
          label: 'Invoice Number',
          render: (name, all) => {
            return all['record']['InvoiceNo'];
          },
        },
        { key: 'row', label: 'Row #' },
        {
          label: 'Errors',
          render: (name, all) => {
            return Object.keys(all['errors_in']).map((i, v) => {
              return (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: 'red' }}>
                    {all['errors_in'][i].join(',')}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,
      seller_invoice_discounting_data: [],
      Original_seller_invoice_discounting_data: [],
      showUploadData: false,
      loading: false,
      showDrawer: false,
      showInvoiceDisData: {},

      addARCSellerID_DrawerOpen: false,
      addARCSellerIDData: {},

      editARCSellerID_DrawerOpen: false,
      editARCSellerIDData: {},

      // customer[modal]
      allArcCustomerData: [],
      OriginalAllArcCustomerData: [],
      gst_buyerName: {
        buyerName: '',
      },

      // idagency modal
      selleridAgency_data: [],
      Original_selleridAgency_data: [],

      // pagination state
      page: 0,
      rowsPerPage: 5,
      rowCount: 0,
      searchInput: '',

      // upload history table section
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      //Pagination For Agency
      pageForAgency: 0,
      rowCountForAgency: 0,
      rowsPerPageForAgency: 25,

      //Pagination For Customer
      pageForCustomer: 0,
      rowCountForCustomer: 0,
      rowsPerPageForCustomer: 25,
      loading: true,
    };
  }

  getSellercustomerDataApi = (serachIn) => {
    const finalData = {
      number_of_rows_per_page: this.state.rowsPerPageForCustomer,
      page_number: this.state.pageForCustomer,
      search_txt: serachIn,
      seller_code: localStorage.getItem('business_entity_id'),
    };

    axios
      .post(`/arc/sellerCustomer`, finalData)
      .then((res) => {
        this.setState({
          allArcCustomerData: res.data.records,
          OriginalAllArcCustomerData: res.data.records,
          rowCountForCustomer: res.data.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ID Agency get api data
  IdAgencyGetApi = (serachIn) => {
    const finalData = {
      business_entity_id: localStorage.getItem('business_entity_id'),
      number_of_rows_per_page: this.state.rowsPerPageForAgency,
      page_number: this.state.pageForAgency,
      search_txt: serachIn,
    };
    axios
      .post(`/arc/selleridAgency`, finalData)
      .then((res) => {
        this.setState({
          selleridAgency_data: res.data.records,
          Original_selleridAgency_data: res.data.records,
          rowCountForAgency: res.data.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  IdGetApi = (serachIn) => {
    const finalData = {
      // "user_id": this.state.user_id,
      business_entity_id: localStorage.getItem('business_entity_id'),
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      search_txt: serachIn,
    };
    axios
      .post(`/arc/seller_invoice_discounting`, finalData)
      .then((res) => {
        this.setState({
          seller_invoice_discounting_data: res.data.records,
          Original_seller_invoice_discounting_data: res.data.records,
          rowCount: res.data.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // table pagination fns
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.IdGetApi(this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.IdGetApi(this.state.searchInput);
    });
  };

  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.IdGetApi(this.state.searchInput);
      }
    );
  };

  //table Pagination For Select Agency
  handleChangePageForAgency = (event, newPage) => {
    this.setState({ pageForAgency: newPage }, () => {
      this.IdAgencyGetApi(this.state.idAgencyModalSearchInput);
    });
  };

  handleChangeRowsPerPageForAgency = (event) => {
    this.setState(
      { rowsPerPageForAgency: +event.target.value, pageForAgency: 0 },
      () => {
        this.IdAgencyGetApi(this.state.idAgencyModalSearchInput);
      }
    );
  };

  arcIDAgencyModalSearch = (event) => {
    this.setState(
      {
        idAgencyModalSearchInput: event.target.value,
        pageForAgency: 0,
        rowsPerPageForAgency: 25,
      },
      () => {
        this.IdAgencyGetApi(this.state.idAgencyModalSearchInput);
      }
    );
  };

  //table Pagination For Select Customer
  handleChangePageForCustomer = (event, newPage) => {
    this.setState({ pageForCustomer: newPage }, () => {
      this.getSellercustomerDataApi(this.state.customerModalSearchInput);
    });
  };

  handleChangeRowsPerPageForCustomer = (event) => {
    this.setState(
      { rowsPerPageForCustomer: +event.target.value, pageForCustomer: 0 },
      () => {
        this.getSellercustomerDataApi(this.state.customerModalSearchInput);
      }
    );
  };

  arcCustomerModalSearch = (event) => {
    this.setState(
      {
        customerModalSearchInput: event.target.value,
        pageForCustomer: 0,
        rowsPerPageForCustomer: 25,
      },
      () => {
        this.getSellercustomerDataApi(this.state.customerModalSearchInput);
      }
    );
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          'business_entity_id'
        )}/${10}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getSellercustomerDataApi();
    this.IdAgencyGetApi();
    this.IdGetApi();
    this.getHistoryTableUploadData();
  }

  // arc idAgency table header[idAgency table data in modal]---
  idAgencyModal_TableHeader() {
    const tableHeader = ['agency name', 'deal no', 'select'];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc idAgency table body[idAgency table data in modal]---
  idAgencyModal_tableBody() {
    const addGstin = (agency_name, deal_no) => {
      this.getSellercustomerDataApi();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          agencyName: agency_name,
          dealNo: deal_no,
        },
        idAgencyModalOpen: false,
        idAgencyModalBackDrop: false,
        idAgencyModalSearchInput: '',
      }));
    };

    if (this.state.selleridAgency_data.length >= 1) {
      return this.state.selleridAgency_data.map((tableData, index) => {
        const { agency_name, deal_no } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {agency_name}</td>
            <td>{deal_no}</td>

            <td>
              <AddCircleOutlineIcon
                onClick={() => addGstin(agency_name, deal_no)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // arc customer table header[customer table data in modal]---
  customerModal_TableHeader() {
    const tableHeader = ['legal Name', 'gstin', 'pan', 'select'];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc customer table body[customer table data in modal]---
  customerModal_tableBody() {
    const addGstin = (cust_gstin, cust_legal_name) => {
      this.getSellercustomerDataApi();
      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          gstin: cust_gstin,
        },
        gst_buyerName: {
          buyerName: cust_legal_name,
        },
        customerModalOpen: false,
        customerModalBackDrop: false,
        customerModalSearchInput: '',
      }));
    };

    if (this.state.allArcCustomerData.length >= 1) {
      return this.state.allArcCustomerData.map((tableData, index) => {
        const { cust_gstin, cust_legal_name, cust_pan } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {cust_legal_name}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>
              <AddCircleOutlineIcon
                onClick={() => addGstin(cust_gstin, cust_legal_name)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ------------------------------------------ARC ID Data modal[ customer table] end---------------------------

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = () => {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Seller Invoice Discounting.xlsx`;

    axios
      .post('/seller_invoice_discounting_extract/download', {
        data: this.state.seller_invoice_discounting_data,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fileUploader = (event) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/sellerinvoicediscounting`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem('business_entity_id')
          ),
        })
        .then((data) => {
          dis.getHistoryTableUploadData();
        })
        .catch((error) => {
          // toast.error(error, { autoClose:5000, progress: 0 })
        });
    };
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.IdGetApi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerID}
        download={'Seller InvoiceDiscouting.xlsx'}
        style={{ textDecoration: 'none' }}
      >
        {' '}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: '20px' }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: '20px' }} />
        UPLOAD TEMPLATE{' '}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>
      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: 'right' }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: '20px' }} />
        Refresh
      </Button>
    </div>
  );

  // arc invoice discounting table header
  arc_invoiceDiscounting_TableHeader() {
    const tableHeader = [
      'Customer name',
      'invoice no',
      'invoice date',
      'deal no',
      'Buyer gstin',
      'Action',
      'Show',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc invoice Discounting table body
  arc_invoiceDiscounting__data() {
    const showToggleDrawer = (
      technical_agency_name,
      lending_agency_name,
      deal_opening_date,
      disbursement_date,
      actual_passthrough_date,
      final_settlement_date,
      collection_date,
      actual_interest_returned,
      interest_upfront,
      invoice_amount_basic,
      net_amount_collected,
      processing_fees,
      actual_margin_returned,
      interest_perc,
      days_interest_heldback,
      processing_fees_net,
      margin_held_back,
      interest_held_back,
      margin_perc,
      processing_fees_gst,
      actual_interest,
      create_date,
      modify_date
    ) => {
      this.setState({
        showInvoiceDisData: {
          technical_agency_name: technical_agency_name,
          lending_agency_name: lending_agency_name,
          deal_opening_date: deal_opening_date,
          disbursement_date: disbursement_date,
          actual_passthrough_date: actual_passthrough_date,
          final_settlement_date: final_settlement_date,
          collection_date: collection_date,
          actual_interest_returned: actual_interest_returned,
          interest_upfront: interest_upfront,
          invoice_amount_basic: invoice_amount_basic,
          net_amount_collected: net_amount_collected,
          processing_fees: processing_fees,
          actual_margin_returned: actual_margin_returned,
          interest_perc: interest_perc,
          days_interest_heldback: days_interest_heldback,
          processing_fees_net: processing_fees_net,
          margin_held_back: margin_held_back,
          interest_held_back: interest_held_back,
          margin_perc: margin_perc,
          processing_fees_gst: processing_fees_gst,
          actual_interest: actual_interest,
          create_date: create_date,
          modify_date: modify_date,
        },
        showDrawer: true,
      });
    };

    const editARCSellerID = (
      invoice_no,
      invoice_date,
      deal_no,
      cust_gstin,
      technical_agency_name,
      lending_agency_name,
      deal_opening_date,
      disbursement_date,
      actual_passthrough_date,
      final_settlement_date,
      collection_date,
      actual_interest_returned,
      interest_upfront,
      invoice_amount_basic,
      net_amount_collected,
      processing_fees,
      actual_margin_returned,
      interest_perc,
      days_interest_heldback,
      processing_fees_net,
      margin_held_back,
      interest_held_back,
      margin_perc,
      processing_fees_gst,
      actual_interest
    ) => {
      const deal_opening_date_yyyy = dateFormat(deal_opening_date);
      const collection_date_yyyy = dateFormat(collection_date);
      const date_of_disbursement_yyyy = dateFormat(disbursement_date);
      const payment_date_yyyy = dateFormat(actual_passthrough_date);
      const deal_close_date_yyyy = dateFormat(final_settlement_date);

      this.setState({
        editARCSellerIDData: {
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          dealNo: deal_no,
          gstin: cust_gstin,
          paymentDate: actual_passthrough_date,
          dealCloseDate: final_settlement_date,
          agencyName: technical_agency_name,
          lenderName: lending_agency_name,
          dealOpeningDate: deal_opening_date,
          disbursementDate: disbursement_date,
          collectionDate: collection_date,
          invoiceValueBasic: invoice_amount_basic,
          amountCollectedFromCustomer: net_amount_collected,
          processingCharges: processing_fees,
          interestPercentage: interest_perc,
          marginHeldBack: margin_held_back,
          interestHeldBack: interest_held_back,
          marginPercentage: margin_perc,
          gstOnProcessingCharges: processing_fees_gst,
          actualInterest: actual_interest,
        },
        editARCSellerID_DrawerOpen: true,
        dealOpeningDateDisplay: deal_opening_date_yyyy,
        collectionDateDisplay: collection_date_yyyy,
        disbursementDateDisplay: date_of_disbursement_yyyy,
        paymentDateDisplay: payment_date_yyyy,
        dealCloseDateDisplay: deal_close_date_yyyy,
      });
    };

    if (this.state.seller_invoice_discounting_data!==undefined && this.state.seller_invoice_discounting_data.length >= 1) {
      return this.state.seller_invoice_discounting_data.map(
        (tableData, index) => {
          const {
            cust_name,
            invoice_no,
            invoice_date,
            deal_no,
            cust_gstin,
            technical_agency_name,
            lending_agency_name,
            deal_opening_date,
            disbursement_date,
            actual_passthrough_date,
            final_settlement_date,
            collection_date,
            actual_interest_returned,
            interest_upfront,
            invoice_amount_basic,
            net_amount_collected,
            processing_fees,
            actual_margin_returned,
            interest_perc,
            days_interest_heldback,
            processing_fees_net,
            margin_held_back,
            interest_held_back,
            margin_perc,
            processing_fees_gst,
            actual_interest,
            create_date,
            modify_date,
          } = tableData; //destructuring
          return (
            <tr key={index}>
              <td>{cust_name}</td>
              <td> {invoice_no}</td>
              <td>{invoice_date}</td>
              <td>{deal_no}</td>
              <td>{cust_gstin}</td>
              <td>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    editARCSellerID(
                      invoice_no,
                      invoice_date,
                      deal_no,
                      cust_gstin,
                      technical_agency_name,
                      lending_agency_name,
                      deal_opening_date,
                      disbursement_date,
                      actual_passthrough_date,
                      final_settlement_date,
                      collection_date,
                      actual_interest_returned,
                      interest_upfront,
                      invoice_amount_basic,
                      net_amount_collected,
                      processing_fees,
                      actual_margin_returned,
                      interest_perc,
                      days_interest_heldback,
                      processing_fees_net,
                      margin_held_back,
                      interest_held_back,
                      margin_perc,
                      processing_fees_gst,
                      actual_interest
                    )
                  }
                >
                  edit
                </Button>
              </td>
              <td>
                <VisibilityIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    showToggleDrawer(
                      technical_agency_name,
                      lending_agency_name,
                      deal_opening_date,
                      disbursement_date,
                      actual_passthrough_date,
                      final_settlement_date,
                      collection_date,
                      actual_interest_returned,
                      interest_upfront,
                      invoice_amount_basic,
                      net_amount_collected,
                      processing_fees,
                      actual_margin_returned,
                      interest_perc,
                      days_interest_heldback,
                      processing_fees_net,
                      margin_held_back,
                      interest_held_back,
                      margin_perc,
                      processing_fees_gst,
                      actual_interest,
                      create_date,
                      modify_date
                    )
                  }
                />
              </td>
            </tr>
          );
        }
      );
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // ------------------------ARC Seller ID add-------------------------
  // add arc seller ID [add button] drawer open handeler---
  addARCSellerID_drawerOpen = () => {
    this.setState({ addARCSellerID_DrawerOpen: true });
  };

  // contact to date handler
  addContractToDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          contractToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  //1. invice   date handler
  addInvoiceDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 2. deal Opening Date to date handler
  addDealOpeningDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          dealOpeningDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        dealOpeningDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // 3. collection Date handler
  addCollectionDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          collectionDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        collectionDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // 4. payment Date handler
  addPaymentDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          paymentDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 5. deal Close Date handler
  addDealCloseDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          dealCloseDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        dealCloseDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 6. Disbursement Date handler
  addDisbursementDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addARCSellerIDData: {
          ...prevState.addARCSellerIDData,
          disbursementDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        disbursementDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // add arc seller ID input field handler[number format]
  addARCSellerID_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      addARCSellerIDData: {
        ...prevState.addARCSellerIDData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add arc seller ID inputfield handler---
  addARCSellerID_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addARCSellerIDData: {
        ...prevState.addARCSellerIDData,
        [name]: String(value),
      },
    }));
  };

  // add arc seller ID  submit handler
  addARCSellerID_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerinvoicediscounting`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.addARCSellerIDData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.IdAgencyGetApi();
          this.IdGetApi();
          this.setState((prevState) => ({
            ...prevState,
            addARCSellerID_DrawerOpen: false,
            addARCSellerIDData: {},
            invoiceDateDisplay: '',
            dealOpeningDateDisplay: '',
            dealCloseDateDisplay: '',
            paymentDateDisplay: '',
            disbursementDateDisplay: '',
            collectionDateDisplay: '',
          }));
          toast.success('invoice Discounting added successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, { autoClose: 2000 });
        this.setState({
          addARCSellerID_DrawerOpen: false,
          addARCSellerIDData: {},
          invoiceDateDisplay: '',
          dealOpeningDateDisplay: '',
          dealCloseDateDisplay: '',
          paymentDateDisplay: '',
          disbursementDateDisplay: '',
          collectionDateDisplay: '',
        });
      });
  };

  // add arc seller ID drawer close handler---
  addARCSellerID_DrawerClose = () => {
    this.setState({
      addARCSellerID_DrawerOpen: false,
      addARCSellerIDData: {},
      gst_buyerName: {},
      invoiceDateDisplay: '',
      dealOpeningDateDisplay: '',
      dealCloseDateDisplay: '',
      paymentDateDisplay: '',
      disbursementDateDisplay: '',
      collectionDateDisplay: '',
    });
  };

  // ------------------------ ARC Seller ID  add end----------------------

  // ------------------------ARC Seller ID edit-------------------------
  // edit arc seller ID [edit button] drawer open handeler---
  editARCSellerID_drawerOpen = () => {
    this.setState({ editARCSellerID_DrawerOpen: true });
  };

  // contact to date handler
  editContractToDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          contractToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        contractToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  //1. invice   date handler
  editInvoiceDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 2. deal Opening Date to date handler
  editDealOpeningDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          dealOpeningDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        dealOpeningDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // 3. collection Date handler
  editCollectionDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          collectionDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        collectionDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // 4. payment Date handler
  editPaymentDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          paymentDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 5. deal Close Date handler
  editDealCloseDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          dealCloseDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        dealCloseDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // 6. Disbursement Date handler
  editDisbursementDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editARCSellerIDData: {
          ...prevState.editARCSellerIDData,
          disbursementDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        disbursementDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // edit arc seller ID input field handler[number format]
  editARCSellerID_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      editARCSellerIDData: {
        ...prevState.editARCSellerIDData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit arc seller ID inputfield handler---
  editARCSellerID_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editARCSellerIDData: {
        ...prevState.editARCSellerIDData,
        [name]: String(value),
      },
    }));
  };

  // edit arc seller ID  submit handler
  editARCSellerID_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerinvoicediscounting`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.editARCSellerIDData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
          // toast.error(res.data.record.details[0].errors_in.invalid_data[0], {
          //   autoClose: 2000,
          // });
        } else {
          this.IdAgencyGetApi();
          this.setState({
            editARCSellerID_DrawerOpen: false,
          });
          toast.success('Record updated successfully', { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          editARCSellerID_DrawerOpen: false,
        });
      });
  };

  // edit arc seller ID drawer close handler---
  editARCSellerID_DrawerClose = () => {
    this.setState({
      editARCSellerID_DrawerOpen: false,
    });
  };

  // ------------------------ ARC Seller ID  edit end----------------------

  closeUploadData = () => {
    this.setState({ showUploadData: false });
  };

  showToggleDrawerClose = (event) => {
    this.setState({ showDrawer: false });
  };

  // customer  modal handler
  addCustomerModalOpen = () => {
    this.setState({
      customerModalOpen: true,
      customerModalBackDrop: true,
    });
  };

  addCustomerModalClose = () => {
    this.setState({
      customerModalOpen: false,
      customerModalBackDrop: false,
    });
  };

  // ID Agency modal handler
  addIDAgencyModalOpen = () => {
    this.setState({
      idAgencyModalOpen: true,
      customerModalBackDrop: true,
    });
  };

  addIDAgencyModalClose = () => {
    this.setState({
      idAgencyModalOpen: false,
      customerModalBackDrop: false,
    });
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({ showUploadData: true });
        let res = response.data.records.records;

        if (response.data.records.type === 'json') {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === 'Fail') {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    // const { push, ...props } = this.props;

    return (
      <React.Fragment>
        {/* -----------------add id agency modal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.idAgencyModalOpen}
          onHide={this.addIDAgencyModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.idAgencyModalSearchInput ?? ''}
                      textOnchangeFunction={this.arcIDAgencyModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.idAgencyModal_TableHeader()}</tr>
                  {this.idAgencyModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCountForAgency} //total row count
              rowsPerPage={this.state.rowsPerPageForAgency}
              page={this.state.pageForAgency}
              onPageChange={this.handleChangePageForAgency}
              onRowsPerPageChange={this.handleChangeRowsPerPageForAgency}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addIDAgencyModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add id agency modal end  */}

        {/* -----------------add collection modal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.customerModalOpen}
          onHide={this.addCustomerModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.customerModalSearchInput ?? ''}
                      textOnchangeFunction={this.arcCustomerModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.customerModal_TableHeader()}</tr>
                  {this.customerModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCountForCustomer} //total row count
              rowsPerPage={this.state.rowsPerPageForCustomer}
              page={this.state.pageForCustomer}
              onPageChange={this.handleChangePageForCustomer}
              onRowsPerPageChange={this.handleChangeRowsPerPageForCustomer}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addCustomerModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add collection modal end  */}
        <div style={{ marginRight: '34px', marginBottom: '10px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/providerSelection">
              Home
            </Link>
            <Link color="inherit">ARC</Link>
            <Typography color="textPrimary">
              Upload Invoice Discounting Data
            </Typography>
          </Breadcrumbs>
        </div>

        {/* upload temp Button */}
        <div>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
          >
            upload{' '}
          </Button>
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper boxTitle="Upload History">
              <this.CollectionListActions />
              <div className="tableResponisve">
                <table className="tableCss">
                  <thead>
                    <tr>
                      <th>Last Upload Data & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === 'In Progress' ? (
                              <VisibilityIcon color="disabled" />
                            ) : item.status === 'Uploaded' ? (
                              <VisibilityIcon color="disabled" />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: 'red', textAlign: 'center' }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: 'none',
                          marginBottom: '0px',
                          padding: '0px',
                          marginTop: '0px',
                        }}
                      >
                        <li style={{ display: 'inline-block' }}>
                          Upload Details
                        </li>
                        <li style={{ display: 'inline-block', float: 'right' }}>
                          <ClearIcon
                            onClick={this.closeUploadData}
                            style={{ color: 'red' }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: '30px', marginRight: '30px' }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: '300',
                            margin: '20px 30px 0px 30px',
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: 'red' }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}

        {/* arc customer table */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: 'none',
                      marginBottom: '0px',
                      padding: '0px',
                      marginTop: '0px',
                    }}
                  >
                    <li style={{ display: 'inline-block' }}>
                      Invoice Discounting Details
                    </li>
                    <li style={{ display: 'inline-block', float: 'right' }}>
                      <Button size="small" onClick={this.export_to_excel}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Button
                        style={{ marginTop: '35px' }}
                        size="small"
                        variant="outlined"
                        onClick={this.addARCSellerID_drawerOpen}
                      >
                        Add ID
                      </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          // textLabel="Search"
                          inputPlacholder="Search...."
                          inputType="text"
                          requiredField=" "
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ''}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_invoiceDiscounting_TableHeader()}</tr>
                      {this.arc_invoiceDiscounting__data()}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                  component="div"
                  count={this.state.rowCount} //total row count
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Drawer
          anchor="right"
          open={this.state.showDrawer}
          onClose={this.showToggleDrawerClose}
        >
          <div className={classes.showDetails}>
            <>
              <div className={classes.showDetailsBody}>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Technical Agency Name"
                  defaultValue={
                    this.state.showInvoiceDisData.technical_agency_name
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Name of the Lender"
                  defaultValue={
                    this.state.showInvoiceDisData.lending_agency_name
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Deal Opening Date"
                  defaultValue={this.state.showInvoiceDisData.deal_opening_date}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label=" Disbursement Date"
                  defaultValue={this.state.showInvoiceDisData.disbursement_date}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Date of Payment"
                  defaultValue={
                    this.state.showInvoiceDisData.actual_passthrough_date
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Deal Close Date"
                  defaultValue={
                    this.state.showInvoiceDisData.final_settlement_date
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Collection Date"
                  defaultValue={this.state.showInvoiceDisData.collection_date}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label=" Interest to be  Returned"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.actual_interest_returned
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Monthly Interest Charged"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.interest_upfront
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Invoice value Basic"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.invoice_amount_basic
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Net Amount Collected"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.net_amount_collected
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Processing Fees"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.processing_fees
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Actual Margin Returned"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.actual_margin_returned
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Interest %"
                  defaultValue={`${this.state.showInvoiceDisData.interest_perc} `}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Days Interest Heldback"
                  defaultValue={
                    this.state.showInvoiceDisData.days_interest_heldback
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Processing Fees Net"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.processing_fees_net
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Margin Held Back (value)"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.margin_held_back
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Interest Held Back (value)"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.interest_held_back
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Margin %"
                  defaultValue={this.state.showInvoiceDisData.margin_perc}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="GST on Processing charges"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.processing_fees_gst
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Actual Interest"
                  defaultValue={numberFormat(
                    this.state.showInvoiceDisData.actual_interest
                  )}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Create Date"
                  defaultValue={this.state.showInvoiceDisData.create_date}
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Modify Date"
                  defaultValue={this.state.showInvoiceDisData.ModifyDate}
                />
              </div>
            </>
            <Button
              style={{
                marginTop: '25px',
                marginBottom: '30px',
                marginLeft: '10px',
              }}
              variant="contained"
              size="small"
              onClick={this.showToggleDrawerClose}
            >
              Back
            </Button>
          </div>
        </Drawer>

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.addARCSellerID_DrawerOpen}
          variant={
            this.state.customerModalBackDrop === true ? 'persistent' : ''
          }
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addARCSellerID_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: '45px' }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addIDAgencyModalOpen}
              >
                select agency*
              </Button>

              <InputField
                inputType="text"
                textLabel="Deal No"
                name="dealNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addARCSellerIDData.dealNo ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addARCSellerIDData.agencyName ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>Deal Opening Date:</label>
                <ValidatedDatePicker
                  name="dealOpeningDate"
                  value={this.state.dealOpeningDateDisplay ?? ''}
                  onChange={(v) => this.addDealOpeningDateChangeHandler(v)}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Name of the Lender"
                name="lenderName"
                value={this.state.addARCSellerIDData.lenderName ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              {/* modal open button */}
              <Button
                style={{ marginTop: '45px' }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addCustomerModalOpen}
              >
                select customer*
              </Button>

              {/* 1.extra field */}
              <InputField
                inputType="text"
                textLabel="Buyer Name"
                name="buyerName"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.gst_buyerName.buyerName ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Buyer GSTIN"
                name="gstin"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addARCSellerIDData.gstin ?? ''}
                inputProps={{
                  readOnly: true,
                }}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addARCSellerIDData.invoiceNo ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>
                  Invoice Date<span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="invoiceDate"
                  validators={['required']}
                  errorMessages={['Date is required']}
                  value={this.state.invoiceDateDisplay ?? ''}
                  onChange={(v) => this.addInvoiceDateChangeHandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Invoice Value - Basic:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.invoiceValueBasic
                      ? this.state.addARCSellerIDData.invoiceValueBasic
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        invoiceValueBasic: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ GST on the Invoice:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.gstOnTheInvoice
                      ? this.state.addARCSellerIDData.gstOnTheInvoice
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        gstOnTheInvoice: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Invoice Total:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.invoiceTotal
                      ? this.state.addARCSellerIDData.invoiceTotal
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        invoiceTotal: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <InputField
                inputType="text"
                textLabel="Margin %"
                name="marginPercentage"
                validator={[
                  'matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$',
                  'maxFloat:100.00',
                ]}
                errorMsg={['invalid percentage.', 'invalid percentage.']}
                value={this.state.addARCSellerIDData.marginPercentage ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Margin Held Back (Value):</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.marginHeldBack
                      ? this.state.addARCSellerIDData.marginHeldBack
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        marginHeldBack: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Monthly Chargable Interest:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.monthlyChargableInterest
                      ? this.state.addARCSellerIDData.monthlyChargableInterest
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        monthlyChargableInterest: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Interest Held Back (Value):</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.interestHeldBack
                      ? this.state.addARCSellerIDData.interestHeldBack
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        interestHeldBack: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <InputField
                inputType="text"
                textLabel="No of Days for which Interest is held back "
                name="noOfDaysForWhichInterestHeldBack"
                value={
                  this.state.addARCSellerIDData
                    .noOfDaysForWhichInterestHeldBack ?? ''
                }
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Processing Charges:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.processingCharges
                      ? this.state.addARCSellerIDData.processingCharges
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        processingCharges: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ GST on Processing Charges:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.gstOnProcessingCharges
                      ? this.state.addARCSellerIDData.gstOnProcessingCharges
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        gstOnProcessingCharges: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Net Amount Disbursed:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.netAmountDisbursed
                      ? this.state.addARCSellerIDData.netAmountDisbursed
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        netAmountDisbursed: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Disbursement:</label>
                <ValidatedDatePicker
                  name="disbursementDate"
                  value={this.state.disbursementDateDisplay ?? ''}
                  onChange={(v) => this.addDisbursementDateChangeHandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Amount Collected from Customer:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.amountCollectedFromCustomer
                      ? this.state.addARCSellerIDData
                          .amountCollectedFromCustomer
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        amountCollectedFromCustomer: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Collection:</label>
                <ValidatedDatePicker
                  name="collectionDate"
                  value={this.state.collectionDateDisplay ?? ''}
                  onChange={(v) => this.addCollectionDateChangeHandler(v)}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Interest %"
                validator={[
                  'matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$',
                  'maxFloat:100.00',
                ]}
                errorMsg={['invalid percentage.', 'invalid percentage.']}
                name="interestPercentage"
                value={this.state.addARCSellerIDData.interestPercentage ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Actual Interest"
                name="actualInterest"
                value={this.state.addARCSellerIDData.actualInterest ?? ''}
                textOnchangeFunction={this.addARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Interest to be returned:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.interestToBeReturned
                      ? this.state.addARCSellerIDData.interestToBeReturned
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        interestToBeReturned: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Pass Through(Final Settlement) Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addARCSellerIDData.passThroughAmount
                      ? this.state.addARCSellerIDData.passThroughAmount
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addARCSellerIDData: {
                        ...prevState.addARCSellerIDData,
                        passThroughAmount: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Payment:</label>
                <ValidatedDatePicker
                  name="paymentDate"
                  value={this.state.paymentDateDisplay ?? ''}
                  onChange={(v) => this.addPaymentDateChangeHandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Deal Close Date:</label>
                <ValidatedDatePicker
                  name="contractFromDate"
                  value={this.state.dealCloseDateDisplay ?? ''}
                  onChange={(v) => this.addDealCloseDateChangeHandler(v)}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.addARCSellerID_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}

        {/* arc edit drawer  */}
        <Drawer
          anchor="right"
          open={this.state.editARCSellerID_DrawerOpen}
          onClose={this.editARCSellerID_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editARCSellerID_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Deal No"
                name="dealNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.editARCSellerIDData.dealNo ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.editARCSellerIDData.agencyName ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>Deal Opening Date:</label>
                <ValidatedDatePicker
                  name="dealOpeningDate"
                  value={this.state.dealOpeningDateDisplay ?? ''}
                  onChange={(v) => this.editDealOpeningDateChangeHandler(v)}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Name of the Lender"
                name="lenderName"
                value={this.state.editARCSellerIDData.lenderName ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              {/* 1.extra field */}
              {/* <InputField
                inputType="text"
                textLabel="Buyer Name"
                name="buyerName"
                value={this.state.editARCSellerIDData.buyerName ?? ""}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              /> */}

              <InputField
                inputType="text"
                textLabel="Buyer GSTIN"
                name="gstin"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editARCSellerIDData.gstin ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editARCSellerIDData.invoiceNo ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validators={['required']}
                errorMessages={['Date is required']}
                value={this.state.editARCSellerIDData.invoiceDate ?? ''}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Invoice Value - Basic:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.invoiceValueBasic
                      ? this.state.editARCSellerIDData.invoiceValueBasic
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        invoiceValueBasic: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ GST on the Invoice:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.gstOnTheInvoice
                      ? this.state.editARCSellerIDData.gstOnTheInvoice
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        gstOnTheInvoice: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Invoice Total:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.invoiceTotal
                      ? this.state.editARCSellerIDData.invoiceTotal
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        invoiceTotal: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Margin %"
                name="marginPercentage"
                validator={[
                  'matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$',
                  'maxFloat:100.00',
                ]}
                errorMsg={['invalid percentage.', 'invalid percentage.']}
                value={this.state.editARCSellerIDData.marginPercentage ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Margin Held Back (Value):</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.marginHeldBack
                      ? this.state.editARCSellerIDData.marginHeldBack
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        marginHeldBack: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Monthly Chargable Interest:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.monthlyChargableInterest
                      ? this.state.editARCSellerIDData.monthlyChargableInterest
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        monthlyChargableInterest: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Interest Held Back (Value):</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.interestHeldBack
                      ? this.state.editARCSellerIDData.interestHeldBack
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        interestHeldBack: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="No of Days for which Interest is held back "
                name="noOfDaysForWhichInterestHeldBack"
                value={
                  this.state.editARCSellerIDData
                    .noOfDaysForWhichInterestHeldBack ?? ''
                }
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Processing Charges:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.processingCharges
                      ? this.state.editARCSellerIDData.processingCharges
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        processingCharges: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ GST on Processing Charges:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.gstOnProcessingCharges
                      ? this.state.editARCSellerIDData.gstOnProcessingCharges
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        gstOnProcessingCharges: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Net Amount Disbursed:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.netAmountDisbursed
                      ? this.state.editARCSellerIDData.netAmountDisbursed
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        netAmountDisbursed: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Disbursement:</label>
                <ValidatedDatePicker
                  name="disbursementDate"
                  value={this.state.disbursementDateDisplay ?? ''}
                  onChange={(v) => this.editDisbursementDateChangeHandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Amount Collected from Customer:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.amountCollectedFromCustomer
                      ? this.state.editARCSellerIDData
                          .amountCollectedFromCustomer
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        amountCollectedFromCustomer: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Collection:</label>
                <ValidatedDatePicker
                  name="collectionDate"
                  value={this.state.collectionDateDisplay ?? ''}
                  onChange={(v) => this.editCollectionDateChangeHandler(v)}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Interest %"
                validator={[
                  'matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$',
                  'maxFloat:100.00',
                ]}
                errorMsg={['invalid percentage.', 'invalid percentage.']}
                name="interestPercentage"
                value={this.state.editARCSellerIDData.interestPercentage ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Actual Interest"
                name="actualInterest"
                value={this.state.editARCSellerIDData.actualInterest ?? ''}
                textOnchangeFunction={this.editARCSellerID_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>₹ Interest to be Returned:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.interestToBeReturned
                      ? this.state.editARCSellerIDData.interestToBeReturned
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        interestToBeReturned: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>₹ Pass Through(Final Settlement) Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editARCSellerIDData.passThroughAmount
                      ? this.state.editARCSellerIDData.passThroughAmount
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editARCSellerIDData: {
                        ...prevState.editARCSellerIDData,
                        passThroughAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Date of Payment:</label>
                <ValidatedDatePicker
                  name="paymentDate"
                  value={this.state.paymentDateDisplay ?? ''}
                  onChange={(v) => this.editPaymentDateChangeHandler(v)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>Deal Close Date:</label>
                <ValidatedDatePicker
                  name="contractFromDate"
                  value={this.state.dealCloseDateDisplay ?? ''}
                  onChange={(v) => this.editDealCloseDateChangeHandler(v)}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.editARCSellerID_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}
      </React.Fragment>
    );
  }
}

export default InvoiceDiscounting;
