import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
});

export default function HomeCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={props.onClickCardHandler}>
      <CardActionArea style={{ background: `${props.backgroundColor}` }}>
        <CardContent>
          <Typography
            style={{ color: "#fff", fontWeight: "bold" }}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {props.card_title}
          </Typography>
          <Typography
            style={{
              color: "#fff",
              fontFamily: "Dosis",
              fontWeight: "600",
              fontSize: "16px",
            }}
            variant="body2"
            component="p"
          >
            {props.card_content}
          </Typography>
          {props.iconForCard && (
            <img
              src={props.iconForCard}
              alt="Loading"
              width="80px"
              height="70px"
              style={{
                position: "absolute",
                top: "13px",
                padding: "10px",
                right: "5px",
              }}
            />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
