import React, { useState } from "react";

import classes from "./GSTReconciliation.module.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

function B2CSummary(props) {
  const { B2CSummaryData } = props;
  const [b2CSummaryTableData, setB2CSummaryData] = useState(B2CSummaryData);
  const [b2CSummaryTableDataForSearch, setB2CSummaryDataForSerach] =
    useState(B2CSummaryData);

  const [searchInput, setSearchInput] = useState("");

  const requestSearch = (event) => {
    const searchVal = event.target.value;
    const filteredRows = b2CSummaryTableDataForSearch.filter((row) => {
      return (
        row.state_lower &&
        row.state_lower.toLowerCase().includes(searchVal.toLowerCase())
      );
    });
    console.log(filteredRows);
    setB2CSummaryData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchInput("");
    requestSearch(searchInput);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const B2CSummaryTableBody = () => {
    if (b2CSummaryTableData && b2CSummaryTableData.length >= 1) {
      return b2CSummaryTableData.map((tableData, index) => {
        const {
          cess_diff,
          cgst_diff,
          state,
          cws_cess,
          cws_cgst,
          cws_igst,
          cws_sgst,
          cws_taxable_value,
          gstr_cess,
          gstr_cgst,
          gstr_igst,
          gstr_sgst,
          gstr_taxable_value,
          igst_diff,
          remarks,
          sgst_diff,
          taxable_value_diff,
          monthYear,
          cws_total_amount,
          gstr_total_amount,
          total_amount_diff,
        } = tableData;
        return (
          <tr>
            <td>{state}</td>
            <td>{monthYear}</td>
            <td>{numberFormat(cws_taxable_value)}</td>
            <td>{numberFormat(cws_cgst)}</td>
            <td>{numberFormat(cws_sgst)}</td>
            <td>{numberFormat(cws_igst)}</td>
            <td>{numberFormat(cws_cess)}</td>
            <td>{numberFormat(cws_total_amount)}</td>

            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_cgst)}</td>
            <td>{numberFormat(gstr_sgst)}</td>
            <td>{numberFormat(gstr_igst)}</td>
            <td>{numberFormat(gstr_cess)}</td>
            <td>{numberFormat(gstr_total_amount)}</td>

            <td>{numberFormat(taxable_value_diff)}</td>
            <td>{numberFormat(cgst_diff)}</td>
            <td>{numberFormat(sgst_diff)}</td>
            <td>{numberFormat(igst_diff)}</td>
            <td>{numberFormat(cess_diff)}</td>
            <td>{numberFormat(total_amount_diff)}</td>

            <td style={{ whiteSpace: "pre-wrap" }}>{remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={16} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="B2C SUMMARY"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={(searchVal) => requestSearch(searchVal)}
      >
        <div className="responsive-table">
          <table className={classes.AllInvoiceReconTableCss}>
            <tr>
              <th rowSpan={2}>STATE</th>
              <th rowSpan={2}>MONTH & YEAR</th>

              <th style={{ background: "#ffffb3" }} colSpan={6} align="center">
                As per Book
              </th>
              <th style={{ background: "#5cd65c" }} colSpan={6} align="center">
                As per GSTR1
              </th>
              <th style={{ background: "#ff8533" }} colSpan={6} align="center">
                Difference
              </th>
              <th
                style={{
                  background: "#ffc107",
                }}
                rowSpan={2}
              >
                Remarks{" "}
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                Total Amount
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                SGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                IGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CESS
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                Total Amount
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                Total Amount
              </th>
            </tr>
            {B2CSummaryTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default B2CSummary;
