
import React, { useState, useEffect } from "react";
import { Box, Paper, Modal } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Button, Grid, TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import RightDrawer from "../../components/ui/RightDrawer";
import TextField from "@material-ui/core/TextField";
import axios from "../../axios-order"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  
  boxShadow: 24,
  background: "#fff",
};

//getting the api url from env file and converting to string
const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;


const  Configure = ({
    openZohoDrawer,
    DrawerClose,
    effitracImportFormloader,
    nameOfTheModel,
    selected,
    handleClick,
    setMsgServity
}) => {
  const [drawer, setDrawer] = useState(false);
  const [index, setIndex] = useState();
  const [listOfMailItems,setListOfMailItems]=useState([])
  const [triggerZohoTable, setTriggerZohoTable] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]); 
  const closeDrawer = () => {
    setIndex();
    setDrawer(false);
  };

  const openModal = (index) => {
    setDrawer(true);
    setIndex(index);
  };

  const [data, setData] = useState(null); 
  const [enableMailButton,setEnableMailBtn]=useState(true)

  useEffect(() => {
  
    if (selected) {
      const urlLink = `${apiUrl}/cwsapims/api/v1/report/vendor/byReportId?reportId=${selected}`;
      
      axios
        .get(urlLink)
        .then(response => {
          setData(response.data); 
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }

    
    
    if (listOfMailItems.length>=1){
      setEnableMailBtn(false)
    }else{
      setEnableMailBtn(true)
    }
  }, [selected,listOfMailItems]);

 
 
  const handleItemSelect = (gstin,index) => {

    const filteredListOfItems=listOfMailItems.length>0?listOfMailItems.filter(item=>item===index):[]

    if (filteredListOfItems.length===0){
      setListOfMailItems(prev=>[...prev,index])
    }else{
      const updatedList = listOfMailItems.filter((item) => item !== index);
      setListOfMailItems(updatedList)
   }

    // setSelectedItems(itemReportId)
    setSelectedItems((prevSelected) => {
      console.log("Prev Selected ==>"+prevSelected);

      console.log("Prev Selectedgstin ==>"+gstin);
      if (prevSelected.includes(gstin)) {
        return prevSelected.filter((gstin) => gstin !== gstin);
      } else {
        return [...prevSelected, gstin];
      }
    });

  };
  


 const handleSubmit = () => {
  // Construct and send the requests for each selected item
  selectedItems.forEach((selectedItem) => {
    const selectedItemData = data.find((item) => item.gstin === selectedItem);
    if (selectedItemData) {
      const urlLink = `${apiUrl}/cwsapims/api/v1/report/vendor/sendMail?reportId=${selectedItemData.reportId}&gstin=${selectedItemData.gstin}`;
      
      axios
        .get(urlLink)
        .then((response) => {
         
          const data=response.data
          
          if (data==='FAILED'){
            handleClick('Email Delivery Failed ❌')
            setMsgServity('error')
          }else{
            handleClick('Email Delivered ✉️')
            setMsgServity('success')
          }
        })
        .catch((error) => {
          console.error("Error sending request:", error);
        });
    }
  });
  DrawerClose()
};

 

  return (
    <Modal
      keepMounted
      open={openZohoDrawer}
      // onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            color: "#fff",
            background: "#d04646",
            padding: "7px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontFamily: "Dosis", marginBottom: "0px" }}>
            {nameOfTheModel}
          </h4>
          {triggerZohoTable ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                 
                </div>
               
                <div>
                  
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="modal-body">
          {triggerZohoTable ? (
            <>
            
            </>
          ) : (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse",
                  }}
                >
               
                  
                </div>
               
              </div>
              

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0px",
                  borderBottom: "2px solid #ddd",
                  fontFamily: "Dosis",
                }}
              > 
                <h5>
               
                </h5>
               
             
              </div>
             

<div className="modal-body">
  {data ? (
    <div className="responsive-table">
      <table className="custome-table">
        <thead>
          <tr>
          <th style={{ marginRight:'13px' }}>
                   
                      Select
                    </th>
            <th>Vendor Name</th>
            <th>GSTIN</th>
            <th>No of ProbableMatch</th>
            <th>No of Mismatch</th>
            <th>No of MissingGstr2B</th>
            <th>No of MissingInBooks</th>
          </tr>
        </thead>
        <tbody>

      {data.map((item, index) => (
  
        <tr key={`${item.reportId}-${index}`}>
            <td>
            <Checkbox
              onChange={() => handleItemSelect(item.gstin,index)}
            />
          </td>
          <td>{item.vendorName}</td>
          <td>{item.gstin}</td>
          <td>{item.probableMatch}</td>
          <td>{item.mismatch}</td>
          <td>{item.missingGstr2B}</td>
          <td>{item.missingInBooks}</td>
          <td>
    
            </td>
        </tr>
      ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p>Loading...</p>
  )}
      <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
          <Button  variant="outlined" disabled={enableMailButton}
             color="primary" onClick={handleSubmit} style={{marginRight:"2%"}}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={DrawerClose}>
            Close
          </Button>
        </div>
      </div>
    </>
          )}
        </div>
        <div className="modal-footer">
          
        </div>
        

        <RightDrawer
         
        >
    
        </RightDrawer>
      </Box>
    </Modal>
  );
};

export default Configure;

