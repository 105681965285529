import React, { useEffect } from 'react';
import axios from "../../../../axios-order";
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Typography, TablePagination } from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Bar } from 'react-chartjs-2';
import LoadingButton from '@mui/lab/LoadingButton';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StorageIcon from '@mui/icons-material/Storage';

const ItcReversalReportContainer = () => {
    let history = useHistory();
    const location = useLocation();
    const props = location.state.items || {};
    const report_id = props.id;
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [summaryrecords, setSummaryRecords] = React.useState([]);
    const [reclaimedRecords, setReclainedRecords] = React.useState([]);
    const [reversalRecords, setReversalRecords] = React.useState([]);
    const [lapsedRecords, setLapsedRecords] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [showGraph, setShowGraph] = React.useState(false);
    const [excelLoading, setExcelLoading] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPage(0)
        setRowsPerPage(5)
    };

    useEffect(() => {
        setLoading(true)
        getItcReversalSummaryData();
    }, []);

    const toggleView = () => {
        setShowGraph(!showGraph);
    };

    const getItcReversalSummaryData = () => {
        axios.get(`/get_itc_reversal_summary_data/${report_id}`)
            .then((res) => {
                if (res.data.status) {
                    setSummaryRecords(res.data.itc_summary_records);
                    setReclainedRecords(res.data.itc_reclaim_summary_records)
                    setReversalRecords(res.data.itc_reversal_summary_records);
                    setLapsedRecords(res.data.itc_lapsed_summary_records);
                }

            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                setLoading(false)
            })
    };

    const tabs = [
        { label: "ITC SUMMARY", icon: <SummarizeIcon /> },
        { label: "ITC REVERSAL SUMMARY", icon: <ModelTrainingIcon /> },
        { label: "ITC RECLAIM SUMMARY", icon: <ChecklistIcon /> },
        { label: "ITC LAPSED SUMMARY", icon: <NewReleasesIcon /> },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updatedShowDetails = (item) => {
        history.push(`/tableDetails`, {
            report_id: report_id,
            rule: item.particulars,
            recon_id: props.recon_id,
            item: item
        });
    }

    const updatedreclaimed = (item) => {
        history.push(`/tableDetails`, {
            report_id: report_id,
            rule: 'ITC Reclaim Summary - ' + item.particulars,
            recon_id: props.recon_id,
            item: item
        });
    }

    const updatedreversalSummary = (item) => {
        history.push(`/tableDetails`, {
            report_id: report_id,
            rule: 'ITC Reversal Summary',
            recon_id: props.recon_id,
            item: item
        });
    }

    const updatedlapsedSummary = (item) => {
        history.push(`/tableDetails`, {
            report_id: report_id,
            rule: 'Lapsed ITC',
            recon_id: props.recon_id,
            item: item
        });
    }


    const renderGraph = () => {
        const data = {
            labels: summaryrecords.map(record => record.particulars),
            datasets: [
                {
                    label: 'Taxable Value',
                    data: summaryrecords.map(record => (record.taxable_value)),
                    backgroundColor: 'rgba(80, 80, 216, 0.8)',
                },
                {
                    label: 'IGST',
                    data: summaryrecords.map(record => (record.igst)),
                    backgroundColor: 'rgba(130, 202, 157, 0.8)',
                },
                {
                    label: 'CGST',
                    data: summaryrecords.map(record => (record.cgst)),
                    backgroundColor: 'rgba(255, 198, 88, 0.8)',
                },
                {
                    label: 'SGST',
                    data: summaryrecords.map(record => (record.sgst)),
                    backgroundColor: 'rgba(208, 237, 87, 0.8)',
                },
                {
                    label: 'Cess',
                    data: summaryrecords.map(record => (record.cess)),
                    backgroundColor: 'rgba(255, 115, 0, 0.8)',
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        barPercentage: 0.5,
                        categoryPercentage: 0.6,
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#666',
                            callback: (value) => formatCurrency(value),
                        },
                        gridLines: {
                            display: true,
                            color: 'rgba(0, 0, 0, 0.1)',
                            lineWidth: 1,
                            drawBorder: false,
                            zeroLineColor: 'rgba(0, 0, 0, 0.1)',
                            zeroLineWidth: 1,
                        },
                    },
                ],
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (tooltipItem) => formatCurrency(tooltipItem.yLabel),
                },
            },
            legend: {
                display: true,
                position: 'top',
            },
        };

        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3%',
                padding: '20px',
                borderRadius: '10px',
                position: 'relative',
            }}>
                <div style={{ width: '100%', height: '400px' }}>
                    <Bar data={data} options={options} />
                </div>
            </div>
        );
    };

    const formatCurrency = (value) => {
        if (value == null) return '₹0.00';
        return `₹${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const renderSummaryTable = () => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table aria-label="customized table" sx={{ borderRadius: '20px' }}>
                <TableRow sx={{ backgroundColor: '#303841' }}>
                    <TableCell sx={{ color: 'white' }} align="center">Sr.No</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Particulars</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Taxable Value</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">IGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">CGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">SGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Cess</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Total Invoice Value</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Action</TableCell>
                </TableRow>

                <TableBody>
                    {summaryrecords.length > 0 ? (
                        summaryrecords.map((row, index) => (
                            <TableRow key={row.particulars}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">{row.particulars}</TableCell>
                                <TableCell align="center">{formatCurrency(row.taxable_value)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.igst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.cgst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.sgst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.cess)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.total_invoice_value) || '0.00'}</TableCell>
                                <TableCell align="center">
                                    {["Other Reversal", "Debit Note", "Credit Note"].includes(row.particulars) ? null : (
                                        <VisibilityIcon style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => updatedShowDetails(row)} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}>
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderReclaimedSummaryTable = () => (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table aria-label="customized table">
                <TableRow sx={{ backgroundColor: '#303841' }}>
                    <TableCell sx={{ color: 'white' }} align="center">Sr.No</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Particulars</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Taxable Value</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">IGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">CGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">SGST</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Cess</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Total Invoice Value</TableCell>
                    <TableCell sx={{ color: 'white' }} align="center">Action</TableCell>
                </TableRow>
                <TableBody>
                    {reclaimedRecords.length > 0 ? (
                        reclaimedRecords.map((row, index) => (
                            <TableRow key={row.particulars}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }} align="center">{row.particulars}</TableCell>
                                <TableCell align="center">{formatCurrency(row.taxable_value)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.igst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.cgst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.sgst)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.cess)}</TableCell>
                                <TableCell align="center">{formatCurrency(row.total_invoice_value) || 'N/A'}</TableCell>
                                <TableCell align="center">
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => updatedreclaimed(row)} />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}>
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderReversalSummaryTable = () => (
        <TableContainer component={Paper} style={{ maxHeight: '54vh' }}>
            <Table stickyHeader>
                <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
                    {[
                        "GSTIN",
                        "Vendor Name",
                        "Taxable Value",
                        "IGST",
                        "CGST",
                        "SGST",
                        "Cess",
                        "Total Invoice Value",
                        "Action"
                    ].map(header => (
                        <TableCell key={header} align="center" sx={{ color: 'white' }} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {header}
                        </TableCell>
                    ))}
                </TableRow>
                <TableBody>
                    {reversalRecords.length > 0 ? (
                        reversalRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.gstin}>
                                {[
                                    row.gstin || '',
                                    row.vendor_name || '',
                                    formatCurrency(row.taxable_value),
                                    formatCurrency(row.igst),
                                    formatCurrency(row.cgst),
                                    formatCurrency(row.sgst),
                                    formatCurrency(row.cess),
                                    formatCurrency(row.total_invoice_value) || 'N/A',
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => updatedreversalSummary(row)} />
                                ].map((cellData, cellIndex) => (
                                    <TableCell key={cellIndex} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {cellData}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}>
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>
    );

    const renderLapsedSummaryTable = () => (
        <TableContainer component={Paper} style={{ maxHeight: '54vh', overflow: 'auto' }}>
            <Table stickyHeader>
                <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
                    {[
                        "GSTIN",
                        "Vendor Name",
                        "Taxable Value",
                        "IGST",
                        "CGST",
                        "SGST",
                        "Cess",
                        "Total Invoice Value",
                        "Action"
                    ].map(header => (
                        <TableCell key={header} align="center" sx={{ color: 'white' }} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {header}
                        </TableCell>
                    ))}
                </TableRow>
                <TableBody>
                    {lapsedRecords.length > 0 ? (
                        lapsedRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.gstin}>
                                {[
                                    row.gstin || '',
                                    row.vendor_name || '',
                                    formatCurrency(row.taxable_value),
                                    formatCurrency(row.igst),
                                    formatCurrency(row.cgst),
                                    formatCurrency(row.sgst),
                                    formatCurrency(row.cess),
                                    formatCurrency(row.total_invoice_value) || '0.00',
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => updatedlapsedSummary(row)} />
                                ].map((cellData, cellIndex) => (
                                    <TableCell key={cellIndex} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {cellData}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center" sx={{ fontWeight: 'bold' }}>
                                No data found!
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>
    );

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleDownloadReport = (item) => {
        setExcelLoading(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `ITC Reversal Report.xlsx`;
        const payLoadData = {
            "summary_id": report_id,
            "org_code": localStorage.getItem("business_entity_id")
        }

        axios
            .post(`/generate_itc_reversal_report`, payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res.data.file, filename);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExcelLoading(false)
            });
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!loading ? (
                <>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                        <Button
                            color='warning'
                            variant="outlined"
                            onClick={toggleView}
                            endIcon={showGraph ? <StorageIcon /> : <StackedBarChartIcon />}
                        >
                            {showGraph ? 'Data View' : 'Report View'}
                        </Button>

                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold', textAlign: 'center', color: '#000000', fontStyle: 'Montserrat', flexGrow: 1 }}
                        >
                            ITC REVERSAL SUMMARY
                            <AnalyticsIcon style={{ marginBottom: '4px', color: '#000000' }} />
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                onClick={() => history.push('/itcRevarsalReport')}
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                            >
                                BACK
                            </Button>
                            <LoadingButton
                                variant="outlined"
                                color="secondary"
                                onClick={handleDownloadReport}
                                endIcon={<FileDownloadIcon />}
                                style={{ marginLeft: '16px' }}
                                loading={excelLoading}
                            >
                                Export
                            </LoadingButton>
                        </div>
                    </div>

                    {showGraph ? (
                        renderGraph() // Show the graph when showGraph is true
                    ) : (
                        <>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{ marginTop: 1.5, marginBottom: 1, borderBottom: 1, borderColor: 'divider' }}
                            >
                                {tabs.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center', color: value === index ? '#ffffff' : '#000000' }}>
                                                {tab.icon}
                                                <span style={{ marginLeft: '8px' }}>{tab.label}</span>
                                            </div>
                                        }
                                        sx={{
                                            width: 280,
                                            height: 50,
                                            borderRadius: 1,
                                            backgroundColor: value === index ? '#80d8ff' : '#fafafa',
                                            color: value === index ? '#ffffff' : '#000000',
                                            fontWeight: '550',
                                            transition: 'background-color 0.2s, color 0.2s',
                                            '&:hover': {
                                                backgroundColor: '#80d8ff'
                                            },
                                            borderColor: '#000000',
                                            borderStyle: 'solid',
                                            borderRight: '0.5px',
                                        }}
                                    />
                                ))}
                            </Tabs>

                            {value === 0 && renderSummaryTable()}
                            {value === 1 &&
                                <>
                                    {renderReversalSummaryTable()}
                                    {/* <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        style={{ marginLeft: '68%' }}
                                        count={lapsedRecords.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    /> */}
                                </>
                            }
                            {value === 2 &&
                                <>
                                    {renderReclaimedSummaryTable()}

                                </>
                            }
                            {value === 3 &&
                                <>
                                    {renderLapsedSummaryTable()}
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        style={{ marginLeft: '68%' }}
                                        count={lapsedRecords.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            }
                        </>
                    )}
                </>
            ) : (
                <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>
            )}
        </div>
    );

};

export default ItcReversalReportContainer;
