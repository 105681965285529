import React, { Component } from "react";
import classes from "./ARCInvoiceManagement.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SellerInvoice from "../../../../asserts/templates/seller_invoice.xlsx";
import SellerCreditNote from "../../../../asserts/templates/seller_credit_note.xlsx";
import SellerDebitNote from "../../../../asserts/templates/seller_debit_note.xlsx";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Paper } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import Modal from "react-bootstrap/Modal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomTable from "../../../../components/ui/Table/Table";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";
import ZohoModel from "../../../../components/ZohoModel/ZohoModel";

let flagForDebit = 1;
let flagForCredit = 1;
let flagForInvoice = 1;
let variableForTimer = null;

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

// date  format converter[dd/mm/yyyy to yyyy/mm/dd]
const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};

const dateFormatWithDash = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
  }
};

// date  format converter[dd/mm/yyyy to dd-mm-yyyy]
const dateFormatWithslash = (value) => {
  if (value) {
    let old_date = String(value).split("/");
    return old_date[0] + "-" + old_date[1] + "-" + old_date[2];
  }
};

class InvoiceManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCol: [
        {
          key: "invoiceNo",
          render: (index, row) => {
            return row["invoice_no"];
          },
        },
        {
          key: "invoiceDate",
          render: (index, row) => {
            return row["invoice_date"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["cust_gstin"];
          },
        },
        {
          key: "totalAmount",
          render: (index, row) => {
            return numberFormat(row["total_amount"]);
          },
        },

        {
          key: "dueDate",
          render: (index, row) => {
            // return dateFormat(row['due_date']);
            return row["due_date"];
          },
        },
      ],
      invoiceData: null,
      oldInvoiceData: null,
      actionHeaderForZohoInvoice: [
        { id: "cust_pan", name: "Customer PAN" },
        { id: "gst_invoice_type", name: "GST Invoice Type" },
        { id: "taxable_value", name: "Taxable Value" },
        { id: "non_taxable_adj", name: "Non Taxable Value" },
        { id: "cess", name: "CESS" },
        { id: "gst_tax_rate", name: "GST Tax %" },
        { id: "hsn_sac_code", name: "HSN/SAC Code" },
        { id: "cgst", name: "CGST" },
        { id: "igst", name: "IGST" },
        { id: "sgst", name: "SGST" },
        { id: "pos", name: "Place Of Supply" },
        { id: "invoice_collection_agency", name: "Invoice Collection Agency" },
        { id: "erp_cust_code", name: "Customer Code" },
      ],
      actionHeaderForZohoDebit: [
        { id: "erp_cust_code", name: "Customer Code" },
        { id: "debitNoteDate", name: "Debit Note Date" },
        { id: "cust_pan", name: "Customer PAN" },
        { id: "taxable_value", name: "Taxable Value" },
        { id: "non_taxable_adj", name: "Non Taxable Value" },
        { id: "cess", name: "CESS" },
        { id: "gst_tax_rate", name: "GST Tax %" },
        { id: "hsn_sac_code", name: "HSN/SAC Code" },
        { id: "cgst", name: "CGST" },
        { id: "igst", name: "IGST" },
        { id: "sgst", name: "SGST" },
        { id: "tax_amount", name: "Tax Amount" },
        { id: "pos", name: "Place Of Supply" },
        { id: "debit_note_reason", name: "Debit Note Reason" },
      ],
      actionHeaderForZohoCredit: [
        { id: "erp_cust_code", name: "Customer Code" },
        { id: "credit_note_date", name: "Credit Note Date" },
        { id: "cust_pan", name: "Customer PAN" },
        { id: "taxable_value", name: "Taxable Value" },
        { id: "non_taxable_adj", name: "Non Taxable Value" },
        { id: "cess", name: "CESS" },
        { id: "gst_tax_rate", name: "GST Tax %" },
        { id: "hsn_sac_code", name: "HSN/SAC Code" },
        { id: "cgst", name: "CGST" },
        { id: "igst", name: "IGST" },
        { id: "sgst", name: "SGST" },
        { id: "tax_amount", name: "Tax Amount" },
        { id: "pos", name: "Place Of Supply" },
        { id: "credit_note_reason", name: "Credit Note Reason" },
      ],
      invoiceHeader: [
        { label: "Invoice Number" },
        { label: "Invoice Date" },
        { label: "GSTIN" },
        { label: "Total Amount" },
        { label: "Due Date" },
      ],
      actions: [
        {
          label: <VisibilityIcon style={{ cursor: "pointer" }} />,
          onclickEvent: (index, row) => this.openModal(index),
        },
      ],
      effitracInvShow: false,

      // debit effitrac table
      debitEffitracCol: [
        {
          key: "invoiceNo",
          render: (index, row) => {
            return row["invoice_no"];
          },
        },
        {
          key: "invoiceDate",
          render: (index, row) => {
            // return row['invoiceDate']
            //   ? dateFormatWithslash(row['invoiceDate'])
            //   : row['invoice_date'];
            return row["invoice_date"];
          },
        },
        {
          key: "debitNoteNo",
          render: (index, row) => {
            return row["debit_note_no"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["cust_gstin"];
          },
        },
        {
          key: "totalAmount",
          render: (index, row) => {
            return numberFormat(row["total_amount"]);
          },
        },
      ],
      debitEffitracData: null,
      oldDebitEffitracData: null,
      debitEffitracHeader: [
        { label: "Invoice Number" },
        { label: "Invoice Date" },
        { label: "Debit Note No" },
        { label: "GSTIN" },
        { label: "Total Amount" },
      ],
      debitEffitracActions: [
        {
          label: <VisibilityIcon style={{ cursor: "pointer" }} />,
          onclickEvent: (index, row) => this.openEffitracDebitShowModal(index),
        },
      ],
      effitracDebitShow: false,
      debitTargetId: null,
      // credit effitrac table
      creditEffitracCol: [
        {
          key: "invoiceNo",
          render: (index, row) => {
            return row["invoice_no"];
          },
        },
        {
          key: "invoiceDate",
          render: (index, row) => {
            // return dateFormat(row['invoice_date']);
            return row["invoice_date"];
          },
        },
        {
          key: "creditNoteNo",
          render: (index, row) => {
            return row["credit_note_no"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["cust_gstin"];
          },
        },
        {
          key: "totalAmount",
          render: (index, row) => {
            return numberFormat(row["total_amount"]);
          },
        },
      ],
      creditEffitracData: null,
      // oldCreditEffitracData: null,
      searchForCreditDate: null,
      creditEffitracHeader: [
        { label: "Invoice Number" },
        { label: "Invoice Date" },
        { label: "Credit Note No" },
        { label: "GSTIN" },
        { label: "Total Amount" },
      ],
      creditEffitracActions: [
        {
          label: <VisibilityIcon style={{ cursor: "pointer" }} />,
          onclickEvent: (index, row) => this.openEffitracCreditShowModal(index),
        },
      ],
      effitracCreditShow: false,
      creditTargetId: null,
      // credit effitrac table end

      uploadUrl: null,
      read_only: "",
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      searchInput: "",

      targetId: null,
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["GSTIN"];
          },
        },
        {
          label: "Invoice Number",
          render: (name, all) => {
            return all["record"]["InvoiceNo"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,
      download_file: SellerInvoice,
      download_file_name: "seller_invoice.xlsx",
      section_name: "Invoice",
      allArcSellerdebitNote: [],
      OriginalDataAllArcSellerdebitNote: [],
      showDebitNoteDetailsData: {},

      allArcInvoiceData: [],
      OriginalallArcInvoiceData: [],
      showInvoiceDetailsData: {},

      allArcSellerCreditNote: [],
      OriginalSellerCreditNote: [],
      showCreditNoteDetailsData: {},

      showUploadData: false,
      debitNoteTableData: false,
      invoiceTableData: true,
      creditNoteTableData: false,

      showInvoiceDrawer: false,
      showCreditNoteDrawer: false,
      showDebitNoteDrawer: false,
      loading: false,
      // add
      addInvoice_DrawerOpen: false,
      addInvoiceData: {},

      addDebitNote_DrawerOpen: false,
      addDebitNoteData: {},

      addCreditNote_DrawerOpen: false,
      addCreditNoteData: {},
      creditNoteDateDisplay: "",

      // edit
      editInvoice_DrawerOpen: false,
      editInvoiceData: {},

      editDebitNote_DrawerOpen: false,
      editDebitNoteData: {},

      editCreditNote_DrawerOpen: false,
      editCreditNoteData: {},

      // customer table modal
      allArcSellerCustomerData: {},
      OriginalDataAllArcSellerCustomerData: {},
      customerTableModalShow: false,
      invoiceModalBackDrop: false,

      // debit table modal
      invoiceApiDataForDebitModal: [],
      invoiceApiSearchDataForDebitModal: [],
      debitModalShow: false,
      debitModalBackDrop: false,

      // credit table modal
      invoiceApiDataForCreditModal: [],
      invoiceApiSearchDataForCreditModal: [],
      creditNoteModalShow: false,
      creditNoteModalBackDrop: false,

      //place of supply in edit invoice Note
      placeOfsupply: "",

      //get Api For Place Of Supply
      getApiForPlaceOfSupply: [],

      // import effitrac / tally
      openImportEffitracDrawer: false,

      EffitracInvoiceFetchData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },

      // import effitrac /tally debit
      openImportEffitracDebitDrawer: false,
      effitracDebitFetchData: {},

      // Edited by Harsh For Cridit
      openZohoCreditDrawer: false,
      // zohoFetchDate:{},

      //Debit Note (Harsh)
      openZohoDebitDrawer: false,
      zohoDataForCWS: false,
      // zohoFetchDateForDebit: {},
      zohoDate: {},

      //Invoice note
      openZohoDrawerForInvoice: false,
      messageForConnectionForInvoice: "Establish Connection",
      buttonColorForInvoice: "red",
      grantToken: "",
      url: "",
      // zohoFetchDateForInvoice: {},

      // import effitrac /tally credit
      openImportEffitracCreditDrawer: false,
      effitracCreditFetchData: {
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },

      // pagination state.....
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      invoiceSearchInput: "",
      // debit
      debitPage: 0,
      debitRowsPerPage: 25,
      debitRowCount: 0,
      debitSearchInput: "",
      // credit
      creditPage: 0,
      creditRowsPerPage: 25,
      creditRowCount: 0,
      creditSearchInput: "",

      // upload history table data
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,
      //Customer Select
      pageForAddInvoice: 0,
      rowsPerPageForAddInvoice: 25,
      rowCountForAddInvoice: 0,

      flagForZoho: false,
      timerForZoho: 60,
      // variableForTimer: null,

      disableEnableButtonForZoho: true,

      // loader...
      loaderForInvoice: true,
      loaderForDebit: true,
      loaderForCredit: true,
    };
  }

  handleChangePageForAddInvoice = (event, newPage) => {
    this.setState({ pageForAddInvoice: newPage }, () => {
      this.getSellercustomerDataApi(this.state.invoiceSearchInput);
    });
  };

  handleChangeRowsPerPageForAddInvoice = (event) => {
    this.setState(
      { rowsPerPageForAddInvoice: +event.target.value, pageForAddInvoice: 0 },
      () => {
        this.getSellercustomerDataApi(this.state.invoiceSearchInput);
      }
    );
  };

  // table serach
  arcInvoiceModalSearchInput = (event) => {
    this.setState(
      {
        invoiceSearchInput: event.target.value,
        pageForAddInvoice: 0,
        rowsPerPageForAddInvoice: 25,
      },
      () => {
        this.getSellercustomerDataApi(this.state.invoiceSearchInput);
      }
    );
  };

  openModal = (index) => {
    this.setState(
      {
        targetId: index,
      },
      () => {
        this.setState({
          effitracInvShow: true,
        });
      }
    );
  };

  closeEffitracInvShowHandler = () => {
    this.setState({
      effitracInvShow: false,
      targetId: null,
    });
  };

  openEffitracDebitShowModal = (index) => {
    this.setState(
      {
        debitTargetId: index,
      },
      () => {
        this.setState({
          effitracDebitShow: true,
        });
      }
    );
  };

  closeEffitracDebitShowHandler = () => {
    this.setState({
      effitracDebitShow: false,
      debitTargetId: null,
    });
  };

  openEffitracCreditShowModal = (index) => {
    this.setState(
      {
        creditTargetId: index,
      },
      () => {
        this.setState({
          effitracCreditShow: true,
        });
      }
    );
  };

  closeEffitracCreditShowHandler = () => {
    this.setState({
      effitracCreditShow: false,
      creditTargetId: null,
    });
  };

  // seller customer api call for popup[modal]
  getSellercustomerDataApi = (serachIn) => {
    axios
      .post(`/arc/sellerCustomer`, {
        number_of_rows_per_page: this.state.rowsPerPageForAddInvoice,
        page_number: this.state.pageForAddInvoice,
        search_txt: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcSellerCustomerData: res.data.records,
          OriginalDataAllArcSellerCustomerData: res.data.records,
          rowCountForAddInvoice: res?.data?.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller invoice get api function
  getSellerInvoiceDataApi = (invoiceSearch) => {
    const finalData = {
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      search_text: invoiceSearch,
      seller_code: localStorage.getItem("business_entity_id"),
    };

    axios
      .post(`/arc/sellerInvoice`, finalData)
      .then((res) => {
        this.setState({
          allArcInvoiceData: res.data.records,
          OriginalallArcInvoiceData: res.data.records,

          invoiceApiDataForDebitModal: res.data.records,
          invoiceApiSearchDataForDebitModal: res.data.records,

          invoiceApiDataForCreditModal: res.data.records,
          invoiceApiSearchDataForCreditModal: res.data.records,
          rowCount: res.data.row_count,
          loaderForInvoice: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller debit note get api function
  sellerDebitNoteGetApi = (debitSearch) => {
    const finalData = {
      number_of_rows_per_page: this.state.debitRowsPerPage,
      page_number: this.state.debitPage,
      search_text: debitSearch,
      seller_code: localStorage.getItem("business_entity_id"),
    };

    axios
      .post(`/arc/sellerdebitNote`, finalData)
      .then((res) => {
        this.setState({
          allArcSellerdebitNote: res.data.records,
          OriginalDataAllArcSellerdebitNote: res.data.records,

          debitRowCount: res.data.row_count,
          loaderForDebit: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller credit note get api function
  sellerCreditNoteGetApi = (creditSearch) => {
    const finalData = {
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.creditPage,
      search_text: creditSearch,
      seller_code: localStorage.getItem("business_entity_id"),
    };
    axios
      .post(`/arc/sellercreditNote`, finalData)
      .then((res) => {
        this.setState({
          allArcSellerCreditNote: res.data.records,
          creditRowCount: res.data.row_count,
          loaderForCredit: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSellerLastUploadDate = () => {
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/Seller Invoice`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = dateFormatWithDash(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDateInvoice: Date,
          lastUploadTimeInvoice: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/Seller Debit Note`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = dateFormatWithDash(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDateDebit: Date,
          lastUploadTimeDebit: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/Seller Credit Note`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = dateFormatWithDash(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDateCredit: Date,
          lastUploadTimeCredit: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForPlaceOfSupply = () => {
    axios
      .get(`/get_pos_state_data`)
      .then((res) => {
        this.setState({ getApiForPlaceOfSupply: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  functionForDebitDrawer = () => {
    this.setState({
      openZohoDebitDrawer: true,
      messageForConnectionForInvoice: "Connection Established",
      buttonColorForInvoice: "green",
    });
    this.clickDebitNote();
  };

  functionForCreditDrawer = () => {
    this.setState({
      openZohoCreditDrawer: true,
      messageForConnectionForInvoice: "Connection Established",
      buttonColorForInvoice: "green",
    });
    this.clickCreditNote();
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  // last upload table data fn
  getSellerUploadHistoryData = () => {
    if (this.state.section_name === "Invoice") {
      axios
        .get(
          `/arc/get_data_upload_history/${localStorage.getItem(
            "business_entity_id"
          )}/${2}`
        )
        .then((res) => {
          this.setState({ uploadHistoryTableData: res.data.record });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (this.state.section_name === "Debit Note") {
      axios
        .get(
          `/arc/get_data_upload_history/${localStorage.getItem(
            "business_entity_id"
          )}/${4}`
        )
        .then((res) => {
          this.setState({ uploadHistoryTableData: res.data.record });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (this.state.section_name === "Credit Note") {
      axios
        .get(
          `/arc/get_data_upload_history/${localStorage.getItem(
            "business_entity_id"
          )}/${3}`
        )
        .then((res) => {
          this.setState({ uploadHistoryTableData: res.data.record });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentDidMount() {
    this.clickInvoice();
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });
    let drawer = domain.get("drawer");

    drawer &&
      localStorage.getItem("DrawerForInvoice") === "true" &&
      this.setState({
        openZohoDrawerForInvoice: true,
        messageForConnectionForInvoice: "Connection Established",
        buttonColorForInvoice: "green",
      });

    drawer &&
      localStorage.getItem("DrawerForDebit") === "true" &&
      this.functionForDebitDrawer();
    drawer &&
      localStorage.getItem("DrawerForCredit") === "true" &&
      this.functionForCreditDrawer();

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    // this.getApiForClientID();
    this.getSellercustomerDataApi();
    this.getSellerInvoiceDataApi();
    this.sellerDebitNoteGetApi();
    this.sellerCreditNoteGetApi();
    this.getSellerLastUploadDate();
    this.getApiForPlaceOfSupply();
    this.getSellerUploadHistoryData();
  }

  componentDidUpdate() {
    if (
      this.state.buttonColorForInvoice === "green" &&
      !this.state.flagForZoho
    ) {
      this.setState({ flagForZoho: true });
      variableForTimer = setInterval(() => {
        this.setState((prevstate) => ({
          timerForZoho: prevstate.timerForZoho - 1,
        }));
      }, 1000);
    }

    if (this.state.timerForZoho === 0) {
      clearInterval(variableForTimer);
      this.setState({
        flagForZoho: false,
        timerForZoho: 10,
        buttonColorForInvoice: "red",
      });
    }
  }

  // -----------------------------------------------Table Search--------------------------------------
  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    // invoice serach
    if (this.state.isEffitrac) {
      const query = this.state.searchInput.trim().toLowerCase();
      const searchAdminData = this.state.oldInvoiceData.filter((item) => {
        return Object.keys(item).some((key) => {
          const regex = new RegExp(
            `^${query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").trim()}`,
            "i"
          );
          return regex.test(String(item[key]));
        });
      });

      // let searchAdminData = this.state.oldInvoiceData.filter((value) => {
      //   const query = this.state.searchInput.trim().toLowerCase();
      //   return (
      //     String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
      //     String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
      //     String(moment(value.invoice_date).format("DD-MM-YYYY")).indexOf(moment(query).format("DD-MM-YYYY")) >= 0 ||

      //     String(value.total_amount).toLowerCase().indexOf(query) >= 0 ||
      //     String(numberFormat(value.total_amount))
      //       .toLowerCase()
      //       .indexOf(query) >= 0 ||
      //     value.due_date.indexOf(moment(query).format("DD-MMM-YYYY")) >= 0
      //   );
      // });

      this.setState({ invoiceData: searchAdminData });
    } else if (this.state.isDebitEffitrac) {
      let searchedDataForDebitNote = this.state.oldDebitEffitracData.filter(
        (value) => {
          const query = this.state.searchInput.trim().toLowerCase();
          return (
            String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
            String(value.debit_note_no).toLowerCase().indexOf(query) >= 0 ||
            String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
            String(value.invoice_date).indexOf(query) >= 0 ||
            String(value.total_amount).toLowerCase().indexOf(query) >= 0 ||
            String(numberFormat(value.total_amount))
              .toLowerCase()
              .indexOf(query) >= 0
          );
        }
      );
      this.setState({ debitEffitracData: searchedDataForDebitNote });
    } else if (this.state.isCreditEffitrac) {
      let searchedDataForCreditNote = this.state.searchForCreditDate.filter(
        (value) => {
          const query = this.state.searchInput.trim().toLowerCase();
          return (
            String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
            String(value.invoice_date).indexOf(query) >= 0 ||
            String(value.credit_note_no).toLowerCase().indexOf(query) >= 0 ||
            String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
            String(value.total_amount).toLowerCase().indexOf(query) >= 0 ||
            String(numberFormat(value.total_amount))
              .toLowerCase()
              .indexOf(query) >= 0
          );
        }
      );
      this.setState({ creditEffitracData: searchedDataForCreditNote });
    }
  };

  // -----------------------------------------------Table Search End--------------------------------------

  // -----------------------------------------------file upload handler--------------------------------------
  fileUploader = (event) => {
    this.setState({ loading: true });
    // toast.configure()
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    // var file_value;

    let dis = this;
    // var file_values=[]
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(dis.state.uploadUrl, {
          file: file_value,
          business_entity_id: localStorage.getItem("business_entity_id"),
        })
        .then((data) => {
          dis.getSellerUploadHistoryData();
          dis.setState({ loading: false });

          if (dis.state.section_name === "Invoice") {
            axios
              .post(
                `/arc/sellerInvoice` , {
                  number_of_rows_per_page: this.state.rowsPerPage,
                  page_number: this.state.page,
                  search_text: "",
                  seller_code: localStorage.getItem("business_entity_id"),
                }
              )
              .then((res) => {
                dis.setState({
                  allArcInvoiceData: res.data.records,
                  OriginalallArcInvoiceData: res.data.records,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (dis.state.section_name === "Debit Note") {
            axios
              .post(
                `/arc/sellerdebitNote` , {
                  number_of_rows_per_page: this.state.debitRowsPerPage,
      page_number: this.state.debitPage,
      search_text: "",
      seller_code: localStorage.getItem("business_entity_id"),
                }
              )
              .then((res) => {
                dis.setState({
                  allArcSellerdebitNote: res.data.records,
                  OriginalDataAllArcSellerdebitNote: res.data.records,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (dis.state.section_name === "Credit Note") {
            axios
              .post(
                `/arc/sellercreditNote`,{
                  number_of_rows_per_page: this.state.rowsPerPage,
                  page_number: this.state.creditPage,
                  search_text: "",
                  seller_code: localStorage.getItem("business_entity_id"),
                }
              )
              .then((res) => {
                dis.setState({
                  allArcSellerCreditNote: res.data.records,
                  OriginalSellerCreditNote: res.data.records,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          dis.setState({
            error: error?.response?.data?.message,
            total_records: 0,
          });
        });
    };
  };

  // excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel_Zoho = (dataFromZoho) => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `${this.state.section_name} Zoho` + `.xlsx`;
    let url = "";
    if (this.state.section_name === "Invoice") {
      url = "/seller_invoice_extract/download";
    } else if (this.state.section_name === "Debit Note") {
      url = "/seller_debit_note_extract/download";
    } else {
      url = "/seller_credit_note_extract/download";
    }

    axios
      .post(url, {
        seller_code: localStorage.getItem("business_entity_id"),
        data: dataFromZoho,
        zoho_export: true,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = this.state.section_name + `.xlsx`;
    let url = "";
    let data = [];

    if (this.state.isEffitrac && this.state.section_name === "Invoice") {
      url = "/seller_invoice_extract/download";

      data = this.state.invoiceData.map((row, index) => {
        let newRow = { ...row };
        newRow["invoiceDate"] = dateFormat(newRow["invoiceDate"]);
        newRow["dueDate"] = dateFormat(newRow["dueDate"]);
        return newRow;
      });
    } else if (
      this.state.isDebitEffitrac &&
      this.state.section_name === "Debit Note"
    ) {
      url = "/seller_debit_note_extract/download";
      if (this.state.zohoDataForCWS) {
        data = this.state.debitEffitracData;
        // .map((v) => {
        //   let newRow = { ...v };
        //   newRow['invoiceDate'] = moment(newRow['invoiceDate']).format(
        //     'DD-MM-YYYY'
        //   );
        //   newRow['debitNoteDate'] = moment(newRow['debitNoteDate']).format(
        //     'DD-MM-YYYY'
        //   );
        //   return newRow;
        // });
      } else {
        data = this.state.debitEffitracData.map((row, index) => {
          let newRow = { ...row };
          newRow["invoiceDate"] = moment(newRow["invoiceDate"]).format(
            "DD-MM-YYYY"
          );
          newRow["debitNoteDate"] = dateFormat(newRow["debitNoteDate"]);
          return newRow;
        });
      }
    } else if (
      this.state.isCreditEffitrac &&
      this.state.section_name === "Credit Note"
    ) {
      url = "/seller_credit_note_extract/download";
      data = this.state.creditEffitracData.map((row, index) => {
        let newRow = { ...row };
        newRow["invoiceDate"] = dateFormat(newRow["invoiceDate"]);
        newRow["creditNoteDate"] = dateFormat(newRow["creditNoteDate"]);
        return newRow;
      });
    } else if (this.state.section_name === "Invoice") {
      url = "/seller_invoice_extract/download";
      data = this.state.allArcInvoiceData;
    } else if (this.state.section_name === "Debit Note") {
      url = "/seller_debit_note_extract/download";
      data = this.state.allArcSellerdebitNote;
    } else if (this.state.section_name === "Credit Note") {
      url = "/seller_credit_note_extract/download";
      data = this.state.allArcSellerCreditNote;
    }

    if (
      (this.state.isEffitrac && this.state.section_name === "Invoice") ||
      (this.state.isDebitEffitrac &&
        this.state.section_name === "Debit Note") ||
      (this.state.isCreditEffitrac && this.state.section_name === "Credit Note")
    ) {
      axios
        .post(url, {
          seller_code: localStorage.getItem("business_entity_id"),
          data: data,
          zoho_export: true,
        })
        .then((res) => {
          this.downloadBase64File(contentType, res.data.file, filename);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(url, {
          seller_code: localStorage.getItem("business_entity_id"),
          data: data,
        })
        .then((res) => {
          this.downloadBase64File(contentType, res.data.file, filename);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // -----------------------------------------------file upload handler end--------------------------------------
  // ---------------------------------------------file upload handler response-----------------------------------

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getSellerUploadHistoryData();
    if (this.state.section_name === "Invoice") {
      this.getSellerInvoiceDataApi();
    } else if (this.state.section_name === "Debit Note") {
      this.sellerDebitNoteGetApi();
    } else {
      this.sellerCreditNoteGetApi();
    }
  };

  CollectionListActions = () => (
    <div>
      <a
        href={this.state.download_file}
        download={this.state.download_file_name}
        style={{ textDecoration: "none", marginRight: "30px" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          {/* DOWNLOAD {this.state.section_name} TEMPLATE */}
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        {/* UPLOAD {this.state.section_name} TEMPLATE{" "} */}
        UPLOAD TEMPLATE
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>

      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
      {/* <CreateButton label="add Customer" basePath={basePath} /> */}
    </div>
  );

  // ---------------------------------------------file upload handler response end--------------------------------------
  // ---------------------------------------------file upload button for all three--------------------------------------

  clickInvoice = (props) => {
    let promise = new Promise((reslove, reject) => {
      this.setState({
        showUploadHistroyTable: false,
        uploadUrl: "arc/excelupload/sellerinvoice",
        download_file: SellerInvoice,
        download_file_name: "seller_invoice.xlsx",
        section_name: "Invoice",
        debitNoteTableData: false,
        invoiceTableData: true,
        creditNoteTableData: false,
        showUploadData: false,
        disableEnableButtonForZoho: true,
      });
      return reslove(true);
    });

    promise.then((value) => {
      this.getSellerUploadHistoryData();
      this.getSellerInvoiceDataApi();
    });
  };

  clickDebitNote = (props) => {
    let promise = new Promise((resolve, reject) => {
      this.setState({
        // invoiceCol: [
        //   {
        //     key: "invoiceNo",
        //     render: (index, row) => {
        //       return row["invoiceNo"];
        //     },
        //   },
        //   {
        //     key: "invoiceDate",
        //     render: (index, row) => {
        //       return dateFormat(row["invoiceDate"]);
        //     },
        //   },
        //   {
        //     key: "debitNoteNo",
        //     render: (index, row) => {
        //       return row["debitNoteNo"];
        //     },
        //   },
        //   {
        //     key: "gstin",
        //     render: (index, row) => {
        //       return row["gstin"];
        //     },
        //   },

        //   {
        //     key: "totalAmount",
        //     render: (index, row) => {
        //       return row["totalAmount"];
        //     },
        //   },
        // ],
        invoiceData: null,
        oldInvoiceData: null,
        // invoiceHeader: [
        //   { label: "Invoice Number" },
        //   { label: "Invoice Date" },
        //   { label: "Debit Note Number" },

        //   { label: "GSTIN" },

        //   { label: "Total Amount" },
        // ],
        uploadUrl: "arc/excelupload/sellerdebit",
        download_file: SellerDebitNote,
        download_file_name: "seller_debit_note.xlsx",
        section_name: "Debit Note",
        debitNoteTableData: true,
        invoiceTableData: false,
        creditNoteTableData: false,
        showUploadData: false,
        disableEnableButtonForZoho: true,
        showUploadHistroyTable: false,
      });
      return resolve(true);
    });
    promise.then((value) => {
      this.getSellerUploadHistoryData();
      this.sellerDebitNoteGetApi();
    });
  };

  clickCreditNote = (props) => {
    let promise = new Promise((resolve, reject) => {
      this.setState({
        showUploadHistroyTable: false,
        uploadUrl: "arc/excelupload/sellercredit",
        download_file: SellerCreditNote,
        download_file_name: "seller_credit_note.xlsx",
        section_name: "Credit Note",
        debitNoteTableData: false,
        invoiceTableData: false,
        creditNoteTableData: true,
        showUploadData: false,
        disableEnableButtonForZoho: true,
      });
      return resolve(true);
    });

    promise.then((value) => {
      this.getSellerUploadHistoryData();
      this.sellerCreditNoteGetApi();
    });
  };

  // arc customer table header---
  arc_Customer_TableHeader() {
    const tableHeader = ["legal Name", "gstin", "pan", "select"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc Customer table body
  arc_CustomerTableBody__data() {
    const addGstin_Pan = (cust_gstin, cust_pan, cust_code) => {
      this.getSellercustomerDataApi();
      this.setState({
        addInvoiceData: {
          gstin: cust_gstin,
          pan: cust_pan,
          customerCode: cust_code,
        },
        customerTableModalShow: false,
        invoiceModalBackDrop: false,
        invoiceSearchInput: "",
      });
    };

    if (this.state.allArcSellerCustomerData.length >= 1) {
      return this.state.allArcSellerCustomerData.map((tableData, index) => {
        const { cust_gstin, cust_legal_name, cust_pan, cust_code } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {cust_legal_name}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>
              <AddCircleOutlineIcon
                onClick={() => addGstin_Pan(cust_gstin, cust_pan, cust_code)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ------------------------------------------ARC invoice modal[ customer table] end---------------------------
  // ---------------------------------------------Arc debit note modal[invoice table]--------------------------------------
  // table search-----
  arcDebitNoteModalSearchInput = (event) => {
    this.setState({ debitNoteSearchInput: event.target.value }, () => {
      this.arcDebitNoteModalSearch(event);
    });
  };

  arcDebitNoteModalSearch = (event) => {
    let { debitNoteSearchInput } = this.state;
    let searchAdminData = this.state.invoiceApiSearchDataForDebitModal.filter(
      (value) => {
        const query = debitNoteSearchInput.toLowerCase();
        return (
          String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
          String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
          String(value.invoice_date).toLowerCase().indexOf(query) >= 0 ||
          String(value.cust_pan).toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ invoiceApiDataForDebitModal: searchAdminData });
  };
  // invoice table header
  arc_debitNoteModal_TableHeader() {
    const tableHeader = [
      "gstin",
      "pan",
      "invoice number",
      "Invoice date",
      "select",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_debitNoteModal_tableBody() {
    const addInvoice = (
      invoice_no,
      invoice_date,
      cust_gstin,
      cust_pan,
      cust_code
    ) => {
      let invoice_Date_yyyy = dateFormat(invoice_date);
      this.getSellerInvoiceDataApi();
      this.setState({
        addDebitNoteData: {
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          gstin: cust_gstin,
          pan: cust_pan,
          customerCode: cust_code,
        },
        debitModalShow: false,
        debitModalBackDrop: false,
        invoiceDateDisplay: invoice_Date_yyyy,
        debitNoteSearchInput: "",
      });
    };
    if (this.state.invoiceApiDataForDebitModal.length >= 1) {
      return this.state.invoiceApiDataForDebitModal.map((tableData, index) => {
        const { invoice_no, invoice_date, cust_gstin, cust_pan, cust_code } =
          tableData; //destructuring
        return (
          <tr key={index}>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>
              <AddCircleOutlineIcon
                onClick={() =>
                  addInvoice(
                    invoice_no,
                    invoice_date,
                    cust_gstin,
                    cust_pan,
                    cust_code
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // ---------------------------------------------Arc debit note modal[invoice table]--------------------------------------

  // ---------------------------------------------Arc credit note modal[invoice table]--------------------------------------
  // table search-----
  arcCreditNoteModalSearchInput = (event) => {
    this.setState({ creditNoteSearchInput: event.target.value }, () => {
      this.arcCreditNoteModalSearch(event);
    });
  };

  arcCreditNoteModalSearch = (event) => {
    let { creditNoteSearchInput } = this.state;
    let searchAdminData = this.state.invoiceApiSearchDataForCreditModal.filter(
      (value) => {
        const query = creditNoteSearchInput.toLowerCase();
        return (
          String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
          String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
          String(value.invoice_date).toLowerCase().indexOf(query) >= 0 ||
          String(value.cust_pan).toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ invoiceApiDataForCreditModal: searchAdminData });
  };
  // invoice table header
  arc_creditNoteModal_TableHeader() {
    const tableHeader = [
      "gstin",
      "pan",
      "invoice number",
      "Invoice date",
      "select",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_creditNoteModal_tableBody() {
    const addInvoice = (
      invoice_no,
      invoice_date,
      cust_gstin,
      cust_pan,
      cust_code
    ) => {
      this.getSellerInvoiceDataApi();
      let invoice_Date_yyyy = dateFormat(invoice_date);
      this.setState({
        addCreditNoteData: {
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          gstin: cust_gstin,
          pan: cust_pan,
          customerCode: cust_code,
        },
        creditNoteModalShow: false,
        creditNoteModalBackDrop: false,
        invoiceDateDisplay: invoice_Date_yyyy,
        creditNoteSearchInput: "",
      });
    };
    if (this.state.invoiceApiDataForCreditModal.length >= 1) {
      return this.state.invoiceApiDataForCreditModal.map((tableData, index) => {
        const { invoice_no, invoice_date, cust_gstin, cust_pan, cust_code } =
          tableData; //destructuring
        return (
          <tr key={index}>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>
              <AddCircleOutlineIcon
                onClick={() =>
                  addInvoice(
                    invoice_no,
                    invoice_date,
                    cust_gstin,
                    cust_pan,
                    cust_code
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // ---------------------------------------------Arc debit note modal[invoice table]--------------------------------------

  // ---------------------------------------------Arc invoice table--------------------------------------
  // invoice table header
  arc_SellerInvoiceTableHeader() {
    const tableHeader = [
      "Customer Name",
      "invoice number",
      "Invoice date",
      "Gstin",
      "Total Amount",
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // invoice table data
  arc_SellerInvoice() {
    const showInvoiceDetails = (
      cust_code,
      einvoice_irn,
      modify_date,
      modified_date,
      po_no,
      cust_name,
      invoice_no,
      invoice_date,
      cust_gstin,
      gst_tax_rate,
      create_date,
      created_date,
      cust_pan,
      due_date,
      invoice_collection_agency,
      gst_invoice_type,
      taxable_value,
      adjusted_sgst,
      adjusted_cgst,
      adjusted_igst,
      cess,
      cgst,
      sgst,
      igst,
      adjusted_tax_amount,
      adjusted_non_taxable_adj,
      adjusted_total_amount,
      adjusted_taxable_value,
      tax_amount,
      non_taxable_adj,
      total_amount,
      hsn_sac_code,
      pos
    ) => {
      this.setState({
        showInvoiceDetailsData: {
          cust_code: cust_code,
          cust_pan: cust_pan,
          due_date: due_date,
          invoice_collection_agency: invoice_collection_agency,
          gst_invoice_type: gst_invoice_type,
          taxable_value: taxable_value,
          adjusted_sgst: adjusted_sgst,
          adjusted_cgst: adjusted_cgst,
          adjusted_igst: adjusted_igst,
          cess: cess,
          gst_tax_rate: gst_tax_rate,
          hsnsaccode: hsn_sac_code,
          pos: pos,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          adjusted_tax_amount: adjusted_tax_amount,
          adjusted_non_taxable_adj: adjusted_non_taxable_adj,
          adjusted_total_amount: adjusted_total_amount,
          adjusted_taxable_value: adjusted_taxable_value,
          tax_amount: tax_amount,
          non_taxable_adj: non_taxable_adj,
          total_amount: total_amount,
          create_date: create_date,
          modify_date: modify_date,
          created_date: created_date,
          modified_date: modified_date,
        },
        showInvoiceDrawer: true,
      });
    };

    const editARCInvoice = (
      tax_amount,
      adjusted_non_taxable_adj,
      non_taxable_adj,
      adjusted_igst,
      modify_date,
      modified_date,
      taxable_value,
      adjusted_total_amount,
      adjusted_taxable_value,
      invoice_no,
      einvoice_irn,
      po_no,
      cgst,
      invoice_date,
      adjusted_tax_amount,
      create_date,
      created_date,
      hsn_sac_code,
      cust_pan,
      invoice_collection_agency,
      pos,
      total_amount,
      igst,
      adjusted_sgst,
      cust_code,
      seller_code,
      due_date,
      gst_tax_rate,
      gst_invoice_type,
      adjusted_cgst,
      cust_gstin,
      cust_name,
      cess,
      sgst
    ) => {
      let invoice_date_yyyy = dateFormat(invoice_date);
      let due_date_yyyy = dateFormat(due_date);
      this.setState({
        editInvoiceData: {
          customerCode: cust_code,
          einvoiceIrn: einvoice_irn,
          poNo: po_no,
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          gstin: cust_gstin,
          pan: cust_pan,
          dueDate: due_date,
          invoiceCollectionAgency: invoice_collection_agency,
          invoiceType: gst_invoice_type,
          taxableValue: taxable_value,
          cess: cess,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          taxAmount: tax_amount,
          nonTaxableValue: non_taxable_adj,
          totalAmount: total_amount,
          gstTaxRate: gst_tax_rate,
          hsnSacCode: hsn_sac_code,
          placeOfSupply: pos,
        },
        editInvoice_DrawerOpen: true,
        invoiceDateDisplay: invoice_date_yyyy,
        dueDateDisplay: due_date_yyyy,
      });
    };

    if (this.state.allArcInvoiceData.length >= 1) {
      return this.state.allArcInvoiceData.map((tableData, index) => {
        const {
          tax_amount,
          adjusted_non_taxable_adj,
          non_taxable_adj,
          adjusted_igst,
          modify_date,
          modified_date,
          taxable_value,
          adjusted_total_amount,
          adjusted_taxable_value,
          invoice_no,
          einvoice_irn,
          po_no,
          cgst,
          invoice_date,
          adjusted_tax_amount,
          create_date,
          created_date,
          hsn_sac_code,
          cust_pan,
          invoice_collection_agency,
          pos,
          total_amount,
          igst,
          adjusted_sgst,
          cust_code,
          seller_code,
          due_date,
          gst_tax_rate,
          gst_invoice_type,
          adjusted_cgst,
          cust_gstin,
          cust_name,
          cess,
          sgst,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ width: 300 }}>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{cust_gstin}</td>
            <td>{numberFormat(total_amount)}</td>
            {/* <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editARCInvoice(
                    tax_amount,
                    adjusted_non_taxable_adj,
                    non_taxable_adj,
                    adjusted_igst,
                    modify_date,
                    taxable_value,
                    adjusted_total_amount,
                    adjusted_taxable_value,
                    invoice_no,
                    einvoice_irn,
                    po_no,
                    cgst,
                    invoice_date,
                    adjusted_tax_amount,
                    create_date,
                    hsn_sac_code,
                    cust_pan,
                    invoice_collection_agency,
                    pos,
                    total_amount,
                    igst,
                    adjusted_sgst,
                    cust_code,
                    seller_code,
                    due_date,
                    gst_tax_rate,
                    invoice_type,
                    adjusted_cgst,
                    cust_gstin,
                    cust_name,
                    cess,
                    sgst
                  )
                }
              >
                edit
              </Button>
            </td> */}
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showInvoiceDetails(
                    cust_code,
                    einvoice_irn,
                    modify_date,
                    modified_date,
                    po_no,
                    cust_name,
                    invoice_no,
                    invoice_date,
                    cust_gstin,
                    gst_tax_rate,
                    create_date,
                    created_date,
                    cust_pan,
                    due_date,
                    invoice_collection_agency,
                    gst_invoice_type,
                    taxable_value,
                    adjusted_sgst,
                    adjusted_cgst,
                    adjusted_igst,
                    cess,
                    cgst,
                    sgst,
                    igst,
                    adjusted_tax_amount,
                    adjusted_non_taxable_adj,
                    adjusted_total_amount,
                    adjusted_taxable_value,
                    tax_amount,
                    non_taxable_adj,
                    total_amount,
                    hsn_sac_code,
                    pos
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loaderForInvoice) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ---------------------------------------------Arc invoice table end--------------------------------------

  // ---------------------------------------------Arc invoice add--------------------------------------
  // add invoice drawer open handler---
  addInvoice_ButtonHandler = () => {
    this.setState({ addInvoice_DrawerOpen: true });
  };

  // invoice date handler
  handleInvoiceDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addInvoiceData: {
          ...prevState.addInvoiceData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // due date handler
  handleDueDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addInvoiceData: {
          ...prevState.addInvoiceData,
          dueDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        dueDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      }));
    }
  };
  // add Invoice inputfield handler---
  addInvoice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addInvoiceData: {
        ...prevState.addInvoiceData,
        [name]: String(value),
      },
    }));
  };

  // add invoice input field handler[number format]
  addInvoice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addInvoiceData: {
        ...prevState.addInvoiceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add invoice add submit handler
  addInvoice_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerinvoice`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.addInvoiceData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.getSellerInvoiceDataApi();
          this.setState({
            addInvoice_DrawerOpen: false,
            addInvoiceData: {},
            dueDateDisplay: "",
            invoiceDateDisplay: "",
          });
          toast.success("Invoice added successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add invoice drawer close handler---
  addInvoice_DrawerClose = () => {
    this.setState({
      addInvoice_DrawerOpen: false,
      addInvoiceData: {},
      dueDateDisplay: "",
      invoiceDateDisplay: "",
    });
  };
  // ---------------------------------------------Arc invoice add--------------------------------------

  // ---------------------------------------------Arc invoice edit-------------------------------------
  // edit invoice drawer open handler---
  editInvoice_ButtonHandler = () => {
    this.setState({ editInvoice_DrawerOpen: true });
  };

  // invoice date handler
  editInvoiceDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editInvoiceData: {
          ...prevState.editInvoiceData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // due date handler
  editDueDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editInvoiceData: {
          ...prevState.editInvoiceData,
          dueDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        dueDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      }));
    }
  };

  // edit Invoice inputfield handler---
  editInvoice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editInvoiceData: {
        ...prevState.editInvoiceData,
        [name]: String(value),
      },
    }));
  };

  // edit invoice input field handler[number format]
  editInvoice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editInvoiceData: {
        ...prevState.editInvoiceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit invoice edit submit handler
  editInvoice_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerinvoice`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.editInvoiceData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.getSellerInvoiceDataApi();
          this.setState({ editInvoice_DrawerOpen: false });
          toast.success("Invoice updated successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ editInvoice_DrawerOpen: false });
      });
  };

  // edit invoice drawer close handler---
  editInvoice_DrawerClose = () => {
    this.setState({
      editInvoice_DrawerOpen: false,
    });
  };
  // ---------------------------------------------Arc invoice edit end---------------------------------

  // --------------------arc debit note table-------------
  arc_SellerdebitNoteTableHeader() {
    const tableHeader = [
      "Customer Name",
      "invoice number",
      "Invoice date",
      "Debit note no",
      "Gstin",
      "Pan",
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_SellerdebitNotedata() {
    const showdebitnoteDetails = (
      tax_amount,
      non_taxable_adj,
      modify_date,
      modified_date,
      taxable_value,
      invoice_no,
      cgst,
      invoice_date,
      create_date,
      created_date,
      hsn_sac_code,
      cust_pan,
      pos,
      total_amount,
      igst,
      debit_note_date,
      cust_code,
      seller_code,
      gst_tax_rate,
      cust_gstin,
      cust_name,
      cess,
      debit_note_no,
      sgst,
      debit_note_reason
    ) => {
      this.setState({
        showDebitNoteDetailsData: {
          cust_code: cust_code,
          debit_note_no: debit_note_no,
          debit_note_date: debit_note_date,
          taxable_value: taxable_value,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          total_amount: total_amount,
          non_taxable_adj: non_taxable_adj,
          debit_note_reason: debit_note_reason,
          tax_amount: tax_amount,
          gstTaxRate: gst_tax_rate,
          hsnSacCode: hsn_sac_code,
          placeOfSupply: pos,
          create_date: create_date,
          modify_date: modify_date,
          created_date: created_date,
          modified_date: modified_date,
        },
        showDebitNoteDrawer: true,
      });
    };

    const editARCDebitNote = (
      tax_amount,
      non_taxable_adj,
      modify_date,
      modified_date,
      taxable_value,
      invoice_no,
      cgst,
      invoice_date,
      create_date,
      created_date,
      hsn_sac_code,
      cust_pan,
      pos,
      total_amount,
      igst,
      debit_note_date,
      cust_code,
      seller_code,
      gst_tax_rate,
      cust_gstin,
      cust_name,
      cess,
      debit_note_no,
      sgst,
      debit_note_reason
    ) => {
      let invoice_Date_yyyy = dateFormat(invoice_date);
      let debit_Note_yyyy = dateFormat(debit_note_date);
      this.setState({ placeOfsupply: pos });
      this.setState({
        editDebitNoteData: {
          customerCode: cust_code,
          // name: cust_name,
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          gstin: cust_gstin,
          pan: cust_pan,

          debitNoteNo: debit_note_no,
          debitNoteDate: debit_note_date,
          taxableValue: taxable_value,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          cess: cess,
          totalAmount: total_amount,
          nonTaxableValue: non_taxable_adj,
          debitNoteReason: debit_note_reason,
          taxAmount: tax_amount,
          gstTaxRate: gst_tax_rate,
          hsnSacCode: hsn_sac_code,
          placeOfSupply: pos,
        },
        editDebitNote_DrawerOpen: true,
        invoiceDateDisplay: invoice_Date_yyyy,
        debitNoteDateDisplay: debit_Note_yyyy,
      });
    };

    if (this.state.allArcSellerdebitNote.length >= 1) {
      return this.state.allArcSellerdebitNote.map((tableData, index) => {
        const {
          tax_amount,
          non_taxable_adj,
          modify_date,
          modified_date,
          taxable_value,
          invoice_no,
          cgst,
          invoice_date,
          create_date,
          created_date,
          hsn_sac_code,
          cust_pan,
          pos,
          total_amount,
          igst,
          debit_note_date,
          cust_code,
          seller_code,
          gst_tax_rate,
          cust_gstin,
          cust_name,
          cess,
          debit_note_no,
          sgst,
          debit_note_reason,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{debit_note_no}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            {/* <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editARCDebitNote(
                    tax_amount,
                    non_taxable_adj,
                    modify_date,
                    taxable_value,
                    invoice_no,
                    cgst,
                    invoice_date,
                    create_date,
                    hsn_sac_code,
                    cust_pan,
                    pos,
                    total_amount,
                    igst,
                    debit_note_date,
                    cust_code,
                    seller_code,
                    gst_tax_rate,
                    cust_gstin,
                    cust_name,
                    cess,
                    debit_note_no,
                    sgst,
                    debit_note_reason
                  )
                }
              >
                edit
              </Button>
            </td> */}
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showdebitnoteDetails(
                    tax_amount,
                    non_taxable_adj,
                    modify_date,
                    modified_date,
                    taxable_value,
                    invoice_no,
                    cgst,
                    invoice_date,
                    create_date,
                    created_date,
                    hsn_sac_code,
                    cust_pan,
                    pos,
                    total_amount,
                    igst,
                    debit_note_date,
                    cust_code,
                    seller_code,
                    gst_tax_rate,
                    cust_gstin,
                    cust_name,
                    cess,
                    debit_note_no,
                    sgst,
                    debit_note_reason
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loaderFor) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // -------------------debit note table end----------------------

  // --------------------------add debit note -------------------------
  // add debit note drawer open handler---
  addDebitNote_ButtonHandler = () => {
    this.setState({ addDebitNote_DrawerOpen: true });
  };

  // debit note date handler
  handleDebitNoteDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addDebitNoteData: {
          ...prevState.addDebitNoteData,
          debitNoteDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        debitNoteDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // add debit note inputfield handler---
  addDebitNote_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addDebitNoteData: {
        ...prevState.addDebitNoteData,
        [name]: String(value),
      },
    }));
  };

  // add debit note input field handler[number format]
  addDebitNote_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addDebitNoteData: {
        ...prevState.addDebitNoteData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add debit note add submit handler
  addDebitNote_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerdebit`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.addDebitNoteData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.sellerDebitNoteGetApi();
          this.setState({
            addDebitNote_DrawerOpen: false,
            addDebitNoteData: {},
            invoiceDateDisplay: "",
            debitNoteDateDisplay: "",
          });
          toast.success("Debit note added successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add debit note drawer close handler---
  addDebitNote_DrawerClose = () => {
    this.setState({
      addDebitNote_DrawerOpen: false,
      addDebitNoteData: {},
      debitNoteDateDisplay: "",
      invoiceDateDisplay: "",
    });
  };

  // edit debit note drawer open handler---
  editDebitNote_ButtonHandler = () => {
    this.setState({ editDebitNote_DrawerOpen: true });
  };

  // debit note date handler
  editDebitNoteDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editDebitNoteData: {
          ...prevState.editDebitNoteData,
          debitNoteDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        debitNoteDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // edit debit note inputfield handler---
  editDebitNote_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editDebitNoteData: {
        ...prevState.editDebitNoteData,
        [name]: String(value),
      },
    }));
  };

  // edit debit note input field handler[number format]
  editDebitNote_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editDebitNoteData: {
        ...prevState.editDebitNoteData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit debit note edit submit handler
  editDebitNote_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerdebit`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.editDebitNoteData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.sellerDebitNoteGetApi();
          this.setState({
            editDebitNote_DrawerOpen: false,
          });
          toast.success("Debit Note updated successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        this.setState({
          editDebitNote_DrawerOpen: false,
        });
      });
  };

  // edit debit note drawer close handler---
  editDebitNote_DrawerClose = () => {
    this.setState({
      editDebitNote_DrawerOpen: false,
    });
  };

  // credit note table
  arc_SellerCebitNoteTableHeader() {
    const tableHeader = [
      "Customer Name",
      "invoice number",
      "Invoice date",
      "Credit note no",
      "Gstin",
      "Pan",
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_SellerCebitNotedata() {
    const showCreditnoteDetails = (
      tax_amount,
      non_taxable_adj,
      modify_date,
      modified_date,
      taxable_value,
      credit_note_reason,
      credit_note_no,
      invoice_no,
      cgst,
      invoice_date,
      is_discount_baddebt,
      create_date,
      created_date,
      hsn_sac_code,
      cust_pan,
      pos,
      total_amount,
      igst,
      cust_code,
      seller_code,
      gst_tax_rate,
      cust_gstin,
      cust_name,
      credit_note_date,
      cess,
      sgst
    ) => {
      this.setState({
        showCreditNoteDetailsData: {
          cust_code: cust_code,
          credit_note_no: credit_note_no,
          credit_note_date: credit_note_date,
          taxable_value: taxable_value,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          total_amount: total_amount,
          non_taxable_adj: non_taxable_adj,
          credit_note_reason: credit_note_reason,
          tax_amount: tax_amount,
          is_discount_baddebt: is_discount_baddebt,
          gstTaxRate: gst_tax_rate,
          hsnSacCode: hsn_sac_code,
          placeOfSupply: pos,
          create_date: create_date,
          modify_date: modify_date,
          created_date: created_date,
          modified_date: modified_date,
        },
        showCreditNoteDrawer: true,
      });
    };

    const editARCCreditNote = (
      tax_amount,
      non_taxable_adj,
      modify_date,
      modified_date,
      taxable_value,
      credit_note_reason,
      credit_note_no,
      invoice_no,
      cgst,
      invoice_date,
      is_discount_baddebt,
      create_date,
      created_date,
      hsn_sac_code,
      cust_pan,
      pos,
      total_amount,
      igst,
      cust_code,
      seller_code,
      gst_tax_rate,
      cust_gstin,
      cust_name,
      credit_note_date,
      cess,
      sgst
    ) => {
      let invoice_Date_yyyy = dateFormat(invoice_date);
      let credit_Note_yyyy = dateFormat(credit_note_date);

      this.setState({
        editCreditNoteData: {
          customerCode: cust_code,
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          gstin: cust_gstin,
          pan: cust_pan,
          isDiscountBadDebtOtherreason: is_discount_baddebt,
          creditNoteNo: credit_note_no,
          creditNoteDate: credit_note_date,
          taxableValue: taxable_value,
          nonTaxableValue: non_taxable_adj,
          cgst: cgst,
          sgst: sgst,
          igst: igst,
          cess: cess,
          totalAmount: total_amount,
          gstTaxRate: gst_tax_rate,
          hsnSacCode: hsn_sac_code,
          placeOfSupply: pos,
          creditNoteReason: credit_note_reason,
          taxAmount: tax_amount,
        },
        editCreditNote_DrawerOpen: true,
        invoiceDateDisplay: invoice_Date_yyyy,
        creditNoteDateDisplay: credit_Note_yyyy,
      });
    };

    if (this.state.allArcSellerCreditNote.length >= 1) {
      return this.state.allArcSellerCreditNote.map((tableData, index) => {
        const {
          tax_amount,
          non_taxable_adj,
          modify_date,
          modified_date,
          taxable_value,
          credit_note_reason,
          credit_note_no,
          invoice_no,
          cgst,
          invoice_date,
          is_discount_baddebt,
          create_date,
          created_date,
          hsn_sac_code,
          cust_pan,
          pos,
          total_amount,
          igst,
          cust_code,
          seller_code,
          gst_tax_rate,
          cust_gstin,
          cust_name,
          credit_note_date,
          cess,
          sgst,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{credit_note_no}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            {/* <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editARCCreditNote(
                    tax_amount,
                    non_taxable_adj,
                    modify_date,
                    taxable_value,
                    credit_note_reason,
                    credit_note_no,
                    invoice_no,
                    cgst,
                    invoice_date,
                    is_discount_baddebt,
                    create_date,
                    hsn_sac_code,
                    cust_pan,
                    pos,
                    total_amount,
                    igst,
                    cust_code,
                    seller_code,
                    gst_tax_rate,
                    cust_gstin,
                    cust_name,
                    credit_note_date,
                    cess,
                    sgst
                  )
                }
              >
                edit
              </Button>
            </td> */}
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showCreditnoteDetails(
                    tax_amount,
                    non_taxable_adj,
                    modify_date,
                    modified_date,
                    taxable_value,
                    credit_note_reason,
                    credit_note_no,
                    invoice_no,
                    cgst,
                    invoice_date,
                    is_discount_baddebt,
                    create_date,
                    created_date,
                    hsn_sac_code,
                    cust_pan,
                    pos,
                    total_amount,
                    igst,
                    cust_code,
                    seller_code,
                    gst_tax_rate,
                    cust_gstin,
                    cust_name,
                    credit_note_date,
                    cess,
                    sgst
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loaderForCredit) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // --------------------Credit note table end------------

  // --------------------------add Credit note -------------------------
  // add Credit note drawer open handler---
  addCreditNote_ButtonHandler = () => {
    this.setState({ addCreditNote_DrawerOpen: true });
  };

  // Credit note date handler
  handleCreditNoteDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addCreditNoteData: {
          ...prevState.addCreditNoteData,
          creditNoteDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        creditNoteDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // add Credit note inputfield handler---
  addCreditNote_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addCreditNoteData: {
        ...prevState.addCreditNoteData,
        [name]: String(value),
      },
    }));
  };

  // add Credit note input field handler[number format]
  addCreditNote_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addCreditNoteData: {
        ...prevState.addCreditNoteData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add Credit note add submit handler
  addCreditNote_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercredit`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.addCreditNoteData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.sellerCreditNoteGetApi();
          this.setState({
            addCreditNote_DrawerOpen: false,
            addCreditNoteData: {},
            creditNoteDateDisplay: "",
            invoiceDateDisplay: "",
          });
          toast.success("Credit Note added successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add Credit note drawer close handler---
  addCreditNote_DrawerClose = () => {
    this.setState({
      addCreditNote_DrawerOpen: false,
      addCreditNoteData: {},
      creditNoteDateDisplay: "",
      invoiceDateDisplay: "",
    });
  };
  // ----------------------add Credit note end-------------------
  // --------------------------edit Credit note -------------------------
  // edit Credit note drawer open handler---
  editCreditNote_ButtonHandler = () => {
    this.setState({ editCreditNote_DrawerOpen: true });
  };

  // Credit note date handler
  editCreditNoteDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editCreditNoteData: {
          ...prevState.editCreditNoteData,
          creditNoteDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        creditNoteDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // edit Credit note inputfield handler---
  editCreditNote_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editCreditNoteData: {
        ...prevState.editCreditNoteData,
        [name]: String(value),
      },
    }));
  };

  // edit Credit note input field handler[number format]
  editCreditNote_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editCreditNoteData: {
        ...prevState.editCreditNoteData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit Credit note edit submit handler
  editCreditNote_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercredit`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.editCreditNoteData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.sellerCreditNoteGetApi();
          this.setState({
            editCreditNote_DrawerOpen: false,
          });
          toast.success("Credit Note updated successfully", {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        this.setState({
          editCreditNote_DrawerOpen: false,
        });
      });
  };

  // edit Credit note drawer close handler---
  editCreditNote_DrawerClose = () => {
    this.setState({
      editCreditNote_DrawerOpen: false,
    });
  };
  // ----------------------add Credit note end-------------------

  closeUploadData = () => {
    this.setState({ showUploadData: false });
    if (this.state.section_name === "Invoice") {
      this.getSellerInvoiceDataApi();
    } else if (this.state.section_name === "Debit Note") {
      this.sellerDebitNoteGetApi();
    } else if (this.state.section_name === "Credit Note") {
      this.sellerCreditNoteGetApi();
    }
  };

  // close invoice drawer
  closeInvoiceDrawer = () => {
    this.setState({ showInvoiceDrawer: false });
  };

  // close credit drawer
  closeShowDrawer_CreditNoteData = () => {
    this.setState({ showCreditNoteDrawer: false });
  };
  // close debit Drawer
  closeShowDrawer_DebitNoteData = () => {
    this.setState({ showDebitNoteDrawer: false });
  };

  customerTableModalOpen = () => {
    this.setState({
      customerTableModalShow: true,
      invoiceModalBackDrop: true,
    });
  };

  customerTableModalClose = () => {
    this.setState({
      customerTableModalShow: false,
      invoiceModalBackDrop: false,
    });
  };

  // debit note modal
  debitModalOpen = () => {
    this.setState({
      debitModalShow: true,
      debitModalBackDrop: true,
    });
  };

  debitModalClose = () => {
    this.setState({
      debitModalShow: false,
      debitModalBackDrop: false,
    });
  };

  // debit note modal
  creditNoteModalOpen = () => {
    this.setState({
      creditNoteModalShow: true,
      creditNoteModalBackDrop: true,
    });
  };

  creditNoteModalClose = () => {
    this.setState({
      creditNoteModalShow: false,
      creditNoteModalBackDrop: false,
    });
  };

  // import Effitrac /tally
  importEffitracDrawerOpen = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importToCWS = (data) => {
    toast.configure();
    axios
      .post("/arc/effitrac/sellerinvoice", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: data.map((row, index) => {
          let newRow = { ...row };
          newRow["invoiceDate"] = newRow["invoiceDate"]
            ? dateFormatWithDash(newRow["invoiceDate"])
            : null;
          newRow["dueDate"] = newRow["dueDate"]
            ? dateFormatWithDash(newRow["dueDate"])
            : null;
          return newRow;
        }),
      })
      .then((res) => {
        this.setState({
          showUploadHistroyTable: true,
        });
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
        this.refreshUploadHistoryTable();

        //Edited by harsh Json Formate had been changed Status Code: 204 NO CONTENT

        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status === "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),
        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        if (error?.response?.data?.message !== "") {
          toast.error(error?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        } else {
          toast.error("Server Error Try After Some Time", {
            autoClose: 5000,
            progress: 0,
          });
        }
      });
  };

  importEffitracInvoiceFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        invoiceFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracInvoiceToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        invoiceToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importEffitracDueFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        dueFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importEffitracDueToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        dueToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importEffitracFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  closeImportEffitracDrawer = () => {
    this.setState({
      openImportEffitracDrawer: false,
      EffitracInvoiceFetchData: {},
    });
  };

  // import effitrac/tally debit section
  import_Debit_Effitrac_tally = () => {
    this.setState({ openImportEffitracDebitDrawer: true });
  };

  importDebitFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        invoiceFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importDebitToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        invoiceToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importDebitOriginalInvFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        originalInvFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importDebitOriginalInvToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        originalInvToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importDebitModifiedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importDebitModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  debitEffitracFormSubmitHandler = () => {
    this.setState({ debitEffitracFormloading: true });
    this.setState((prevState) => ({
      effitracDebitFetchData: {
        ...prevState.effitracDebitFetchData,
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    }));
    axios
      .post("/arc/effitrac/debitnotereport", this.state.effitracDebitFetchData)
      .then((res) => {
        if (res.data.status) {
          this.setState({
            debitEffitracData: res.data.records.records,
            oldDebitEffitracData: res.data.records.records,
            isDebitEffitrac: true,
            debitEffitracFormloading: false,
            openImportEffitracDebitDrawer: false,
            effitracDebitFetchData: {},
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ debitEffitracFormloading: false });
      });
  };

  debitImportToCWS = (funData) => {
    toast.configure();
    let dis = this;
    let recordForZohoAndEff;
    if (dis.state.zohoDataForCWS) {
      recordForZohoAndEff = funData.map((row) => {
        let newRow = { ...row };
        newRow["invoiceDate"] = newRow["invoiceDate"]
          ? // ? moment(newRow['invoiceDate']).format('DD-MM-YYYY')
            dateFormatWithslash(newRow["invoiceDate"])
          : null;
        newRow["debitNoteDate"] = newRow["debitNoteDate"]
          ? moment(newRow["debitNoteDate"]).format("DD-MM-YYYY")
          : null;
        return newRow;
      });
    } else {
      recordForZohoAndEff = funData.map((row, index) => {
        let newRow = { ...row };
        newRow["invoiceDate"] = newRow["invoiceDate"]
          ? dateFormatWithslash(newRow["invoiceDate"])
          : null;
        newRow["debitNoteDate"] = newRow["debitNoteDate"]
          ? dateFormatWithDash(newRow["debitNoteDate"])
          : null;
        return newRow;
      });
    }

    axios
      .post("/arc/effitrac/sellerdebit", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: recordForZohoAndEff,
      })
      .then((res) => {
        this.setState({
          showUploadHistroyTable: true,
        });
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
        this.refreshUploadHistoryTable();

        // Json had been changed Status Code: 204 NO CONTENT
        // if (res.data.message) {
        //   toast.error(res?.data?.message, {
        //     autoClose: 5000,
        //     progress: 0,
        //   });
        // }
        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status === "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),

        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        toast.error(error.response?.data.message, {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  closeDebitEffitractally = () => {
    this.setState({ openImportEffitracDebitDrawer: false });
  };

  // import effitrac/tally credit section
  import_Credit_Effitrac_tally = () => {
    this.setState({ openImportEffitracCreditDrawer: true });
  };

  importCreditFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        invoiceFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importCreditToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        invoiceToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importCreditModifiedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importCreditModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importCreditOriginalInvFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        originalInvFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importCreditOriginalInvToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        originalInvToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  creditEffitracFormSubmitHandler = () => {
    this.setState({ creditEffitracFormloading: true });
    this.setState((prevState) => ({
      effitracCreditFetchData: {
        ...prevState.effitracCreditFetchData,
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    }));
    axios
      .post(
        "/arc/effitrac/creditnotereport",
        this.state.effitracCreditFetchData
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            creditEffitracData: res.data.records.records,
            searchForCreditDate: res.data.records.records,
            isCreditEffitrac: true,
            creditEffitracFormloading: false,
            openImportEffitracCreditDrawer: false,
            effitracCreditFetchData: {},
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ creditEffitracFormloading: false });
      });
  };

  creditImportToCWS = (dataFromCredit) => {
    toast.configure();
    axios
      .post("/arc/effitrac/sellercredit", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: dataFromCredit.map((row, index) => {
          let newRow = { ...row };
          newRow["invoiceDate"] = newRow["invoiceDate"]
            ? dateFormatWithDash(newRow["invoiceDate"])
            : null;
          newRow["creditNoteDate"] = newRow["creditNoteDate"]
            ? dateFormatWithDash(newRow["creditNoteDate"])
            : null;
          return newRow;
        }),
      })
      .then((res) => {
        this.setState({
          showUploadHistroyTable: true,
        });
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
        this.refreshUploadHistoryTable();

        //Edited by harsh has no Respond was comming from backend error code 204

        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status === "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),

        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  closeCreditEffitractally = () => {
    this.setState({
      openImportEffitracCreditDrawer: false,
      effitracCreditFetchData: {},
    });
  };

  // effitrac form handler---------------------------------------------------------------------------------------
  effitracFetchHandler = () => {
    this.setState({ InvoiceEffitracFormloading: true });
    this.setState((prevState) => ({
      EffitracInvoiceFetchData: {
        ...prevState.EffitracInvoiceFetchData,
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
      },
    }));

    axios
      .post(
        "/arc/effitrac/salesinvoicereport",
        this.state.EffitracInvoiceFetchData
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({
            invoiceData: res.data.records.records,
            oldInvoiceData: res.data.records.records,
            isEffitrac: true,
            InvoiceEffitracFormloading: false,
            openImportEffitracDrawer: false,
            EffitracInvoiceFetchData: {},
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ InvoiceEffitracFormloading: false });
      });
  };

  // back button after import effitrac data
  backToPrevTableHandler = () => {
    this.setState({
      showUploadHistroyTable: false,
      isEffitrac: false,
      isDebitEffitrac: false,
      isCreditEffitrac: false,
      zohoDataForCWS: false,
      disableEnableButtonForZoho: true,
    });
  };

  //Edited by Harsh Invoice
  openInvoiceDrawerOpen = () => {
    flagForInvoice = 1;
    this.setState({ openZohoDrawerForInvoice: true });
    localStorage.setItem("DrawerForInvoice", "true");
  };

  closeZohoInvoiceDrawer = () => {
    clearInterval(variableForTimer);
    this.setState({
      openZohoDrawerForInvoice: false,
      zohoDate: {},
      messageForConnectionForInvoice: "Establish Connection",
      buttonColorForInvoice: "red",
      grantToken: "",
    });
    localStorage.setItem("DrawerForInvoice", "false");
  };

  establishConnectionForInvoice = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.settings.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${
      this.state.url
    }/zohoTesting&access_type=offline&prompt=Consent`;
  };

  inputHandlerForZohoInvoice = () => {
    clearInterval(variableForTimer);
    flagForInvoice += 1;

    toast.configure();

    if (flagForInvoice === 2) {
      this.setState({ InvoiceEffitracFormloading: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          type_of_data: "seller_invoice",
          code: this.state.grantToken,
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
              InvoiceEffitracFormloading: false,
            });
          } else {
            this.setState({
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
              InvoiceEffitracFormloading: false,
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            zohoDate: {},
            messageForConnectionForInvoice: "Establish Connection",
            buttonColorForInvoice: "red",
            grantToken: "",
            InvoiceEffitracFormloading: false,
          });

          if (err?.response?.data?.message !== "") {
            toast.error(err?.response?.data?.message, {
              autoClose: 5000,
              progress: 0,
            });
          } else {
            toast.error("Server Error Try After Some Time", {
              autoClose: 5000,
              progress: 0,
            });
          }
        });
    }
  };

  // Edited by Harsh For Debit

  handleZohoDrawerOpen = () => {
    flagForDebit = 1;
    this.setState({ openZohoDebitDrawer: true });
    localStorage.setItem("DrawerForDebit", "true");
  };

  handleZohoDrawerCloseForDebit = () => {
    clearInterval(variableForTimer);
    this.setState({
      openZohoDebitDrawer: false,
      debitEffitracFormloading: false,
      zohoDate: {},
      messageForConnectionForInvoice: "Establish Connection",
      buttonColorForInvoice: "red",
      grantToken: "",
    });
    localStorage.setItem("DrawerForDebit", "false");
  };

  inputHandlerForZohoDebit = () => {
    clearInterval(variableForTimer);
    flagForDebit += 1;

    toast.configure();

    if (flagForDebit === 2) {
      this.setState({ debitEffitracFormloading: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "seller_debit_note",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          console.log(res.data.records);
          if (res.data.status === true) {
            this.setState({
              debitEffitracFormloading: false,
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
            });
          } else {
            this.setState({
              debitEffitracFormloading: false,
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            debitEffitracFormloading: false,
            zohoDate: {},
            messageForConnectionForInvoice: "Establish Connection",
            buttonColorForInvoice: "red",
            grantToken: "",
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  handleZohoDrawerForCredit = () => {
    flagForCredit = 1;
    this.setState({ openZohoCreditDrawer: true });
    localStorage.setItem("DrawerForCredit", "true");
  };

  handleZohoDrawerCloseForCredit = () => {
    clearInterval(variableForTimer);
    this.setState({
      openZohoCreditDrawer: false,
      zohoDate: {},
      messageForConnectionForInvoice: "Establish Connection",
      buttonColorForInvoice: "red",
      grantToken: "",
    });
    localStorage.setItem("DrawerForCredit", "false");
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    }
  };

  inputHandlerForZohoCredit = () => {
    clearInterval(variableForTimer);
    flagForCredit += 1;

    toast.configure();

    if (flagForCredit === 2) {
      this.setState({ creditEffitracFormloading: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "seller_credit_note",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
              creditEffitracFormloading: false,
            });
          } else {
            this.setState({
              zohoDate: {},
              messageForConnectionForInvoice: "Establish Connection",
              buttonColorForInvoice: "red",
              grantToken: "",
              creditEffitracFormloading: false,
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            zohoDate: {},
            messageForConnectionForInvoice: "Establish Connection",
            buttonColorForInvoice: "red",
            grantToken: "",
            creditEffitracFormloading: false,
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };
  // .............................................pagination sand search........................................
  // table pagination fns
  // invoice
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getSellerInvoiceDataApi(this.state.invoiceSearchInput);
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.getSellerInvoiceDataApi(this.state.invoiceSearchInput);
    });
  };
  // table serach
  invoiceSearchHandler = (event) => {
    this.setState(
      { invoiceSearchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.getSellerInvoiceDataApi(this.state.invoiceSearchInput);
      }
    );
  };

  // debit...
  handleChangePageForDebit = (event, newPage) => {
    this.setState({ debitPage: newPage }, () => {
      this.sellerDebitNoteGetApi(this.state.debitSearchInput);
    });
  };
  handleChangeRowsPerPageForDebit = (event) => {
    this.setState(
      { debitRowsPerPage: +event.target.value, debitPage: 0 },
      () => {
        this.sellerDebitNoteGetApi(this.state.debitSearchInput);
      }
    );
  };
  // table serach
  debitSearchHandler = (event) => {
    this.setState(
      { debitSearchInput: event.target.value, page: 0, debitRowsPerPage: 25 },
      () => {
        this.sellerDebitNoteGetApi(this.state.debitSearchInput);
      }
    );
  };

  // credit...
  handleChangePageForCredit = (event, newPage) => {
    this.setState({ creditPage: newPage }, () => {
      this.sellerCreditNoteGetApi(this.state.creditSearchInput);
    });
  };

  handleChangeRowsPerPageForCredit = (event) => {
    this.setState(
      { creditRowsPerPage: +event.target.value, creditPage: 0 },
      () => {
        this.sellerCreditNoteGetApi(this.state.creditSearchInput);
      }
    );
  };
  // table serach
  creditSearchHandler = (event) => {
    this.setState(
      { creditSearchInput: event.target.value, page: 0, creditRowsPerPage: 25 },
      () => {
        this.sellerCreditNoteGetApi(this.state.creditSearchInput);
      }
    );
  };

  // view file upload details
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({
          showUploadData: true,
          uploadStatusFail: response.data.records,
        });

        let res = response.data.records.records;

        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),

              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
          });
        }
      })
      .catch((error) => {});
  };

  zohoHandlerForAll = () => {
    if (this.state.section_name === "Invoice") {
      this.openInvoiceDrawerOpen();
    } else if (this.state.section_name === "Debit Note") {
      this.handleZohoDrawerOpen();
    } else if (this.state.section_name === "Credit Note") {
      this.handleZohoDrawerForCredit();
    }
  };

  handlAllEffitally = () => {
    if (this.state.section_name === "Invoice") {
      this.importEffitracDrawerOpen();
    } else if (this.state.section_name === "Debit Note") {
      this.import_Debit_Effitrac_tally();
    } else if (this.state.section_name === "Credit Note") {
      this.import_Credit_Effitrac_tally();
    }
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `${this.state.section_name}UploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { push, ...props } = this.props;

    return (
      <React.Fragment>
        {this.state.openZohoDrawerForInvoice && (
          <ZohoModel
            openZohoDrawer={this.state.openZohoDrawerForInvoice}
            buttonColor={this.state.buttonColorForInvoice}
            establishConnection={this.establishConnectionForInvoice}
            messageForConnection={this.state.messageForConnectionForInvoice}
            timerForZohoInSalesCustomer={this.state.timerForZoho}
            closeZohoDrawer={this.closeZohoInvoiceDrawer}
            zohoDateChangeHandler={this.zohoDateChangeHandler}
            effitracImportFormloader={this.state.InvoiceEffitracFormloading}
            fetchingdataForZoho={this.inputHandlerForZohoInvoice}
            zohoDate={this.state.zohoDate}
            logApitName="seller_invoice"
            nameOfTheModel="Invoice Management"
            importToCWS={this.importToCWS}
            exportToExcel={this.export_to_excel_Zoho}
            zohoTableHeader={this.state.invoiceHeader}
            zohoTableColumn={this.state.invoiceCol}
            drawerKeys={this.state.actionHeaderForZohoInvoice}
          />
        )}

        {this.state.openZohoDebitDrawer && (
          <ZohoModel
            openZohoDrawer={this.state.openZohoDebitDrawer}
            buttonColor={this.state.buttonColorForInvoice}
            establishConnection={this.establishConnectionForInvoice}
            messageForConnection={this.state.messageForConnectionForInvoice}
            timerForZohoInSalesCustomer={this.state.timerForZoho}
            closeZohoDrawer={this.handleZohoDrawerCloseForDebit}
            zohoDateChangeHandler={this.zohoDateChangeHandler}
            effitracImportFormloader={this.state.debitEffitracFormloading}
            fetchingdataForZoho={this.inputHandlerForZohoDebit}
            zohoDate={this.state.zohoDate}
            logApitName="seller_debit_note"
            nameOfTheModel="Debit Note Management"
            importToCWS={this.debitImportToCWS}
            exportToExcel={this.export_to_excel_Zoho}
            zohoTableHeader={this.state.debitEffitracHeader}
            zohoTableColumn={this.state.debitEffitracCol}
            drawerKeys={this.state.actionHeaderForZohoDebit}
          />
        )}

        {this.state.openZohoCreditDrawer && (
          <ZohoModel
            openZohoDrawer={this.state.openZohoCreditDrawer}
            buttonColor={this.state.buttonColorForInvoice}
            establishConnection={this.establishConnectionForInvoice}
            messageForConnection={this.state.messageForConnectionForInvoice}
            timerForZohoInSalesCustomer={this.state.timerForZoho}
            closeZohoDrawer={this.handleZohoDrawerCloseForCredit}
            zohoDateChangeHandler={this.zohoDateChangeHandler}
            effitracImportFormloader={this.state.creditEffitracFormloading}
            fetchingdataForZoho={this.inputHandlerForZohoCredit}
            zohoDate={this.state.zohoDate}
            logApitName="seller_credit_note"
            nameOfTheModel="Credit Note Management"
            importToCWS={this.creditImportToCWS}
            exportToExcel={this.export_to_excel_Zoho}
            zohoTableHeader={this.state.creditEffitracHeader}
            zohoTableColumn={this.state.creditEffitracCol}
            drawerKeys={this.state.actionHeaderForZohoCredit}
          />
        )}

        {/* -----------add invoice modal-----------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.customerTableModalShow}
          onHide={this.customerTableModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.invoiceSearchInput ?? ""}
                      textOnchangeFunction={this.arcInvoiceModalSearchInput}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.arc_Customer_TableHeader()}</tr>
                  {this.arc_CustomerTableBody__data()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCountForAddInvoice} //total row count
              rowsPerPage={this.state.rowsPerPageForAddInvoice}
              page={this.state.pageForAddInvoice}
              onPageChange={this.handleChangePageForAddInvoice}
              onRowsPerPageChange={this.handleChangeRowsPerPageForAddInvoice}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.customerTableModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add invoice modal end  */}

        {/* -----------------add debit modal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.debitModalShow}
          onHide={this.debitModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.invoiceSearchInput ?? ""}
                      textOnchangeFunction={this.invoiceSearchHandler}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.arc_debitNoteModal_TableHeader()}</tr>
                  {this.arc_debitNoteModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCount} //total row count
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.debitModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* add debit modal end  */}
        {/* -----------------add credit modal---------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.creditNoteModalShow}
          onHide={this.creditNoteModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.invoiceSearchInput ?? ""}
                      textOnchangeFunction={this.invoiceSearchHandler}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.arc_creditNoteModal_TableHeader()}</tr>
                  {this.arc_creditNoteModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCount} //total row count
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.creditNoteModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/*...........Edited by Harsh..................*/}
        {/* import Zoho Invoice */}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDrawerForInvoice}
          onClose={this.closeZohoInvoiceDrawer}
        >
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              className="button-outlined-primary"
              disabled={
                this.state.buttonColorForInvoice === "green" ? true : false
              }
              onClick={this.establishConnectionForInvoice}
            >
              {this.state.messageForConnectionForInvoice}
            </button>
          </div>
          {this.state.buttonColorForInvoice === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color: this.state.timerForZoho < 30 ? "red" : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZoho} Seconds
              </span>
            </div>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.inputHandlerForZohoInvoice}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "10px" }}>
              <label>
                Create From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                name="modified_from_date"
                value={this.state.zohoDate.modified_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "modified_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div className="mt-20">
              {this.state.InvoiceEffitracFormloading ? (
                <>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeZohoInvoiceDrawer}
              >
                cancel
              </Button>
            </ValidatorForm>
          
          </div>
        </Drawer> */}

        {/* import tally/Effitrac drawer */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeImportEffitracDrawer}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.effitracFetchHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDateInvoice}{" "}
              {this.state.lastUploadTimeInvoice}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>Invoice From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="invoiceFromDate"
                value={
                  this.state.EffitracInvoiceFetchData.invoiceFromDate ?? ""
                }
                onChange={(v) =>
                  this.importEffitracInvoiceFromDateChangeHandler(v)
                }
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Invoice To Date:</label>
              <ValidatedDatePicker
                name="invoiceToDate"
                value={this.state.EffitracInvoiceFetchData.invoiceToDate ?? ""}
                onChange={(v) =>
                  this.importEffitracInvoiceToDateChangeHandler(v)
                }
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Due From Date:</label>
              <ValidatedDatePicker
                name="dueFromDate"
                value={this.state.EffitracInvoiceFetchData.dueFromDate ?? ""}
                onChange={(v) => this.importEffitracDueFromDateChangeHandler(v)}
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Due To Date:</label>
              <ValidatedDatePicker
                name="dueToDate"
                value={this.state.EffitracInvoiceFetchData.dueToDate ?? ""}
                onChange={(v) => this.importEffitracDueToDateChangeHandler(v)}
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={
                  this.state.EffitracInvoiceFetchData.modifiedFromDate ?? ""
                }
                onChange={(v) => this.importEffitracFromDateChangeHandler(v)}
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.EffitracInvoiceFetchData.modifiedToDate ?? ""}
                onChange={(v) =>
                  this.importEffitracModifiedToDateChangeHandler(v)
                }
                // validators={["required"]}
                // errorMessages={["Date is required"]}
              />
            </div>

            {/* submit and back button */}
            <div className="mt-20">
              {this.state.InvoiceEffitracFormloading ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeImportEffitracDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/*...........Edited by Harsh..................*/}
        {/* import Zoho debit */}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDebitDrawer}
          // onClose={this.closeCreditEffitractally}
        >
          <div style={{ textAlign: "center" }}>
            <button
              className="button-outlined-primary"
              disabled={
                this.state.buttonColorForInvoice === "green" ? true : false
              }
              onClick={this.establishConnectionForInvoice}
            >
              {this.state.messageForConnectionForInvoice}
            </button>
          </div>
          {this.state.buttonColorForInvoice === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color: this.state.timerForZoho < 30 ? "red" : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZoho} Seconds
              </span>
            </div>
          )}
          <ValidatorForm
            ref="form"
            onSubmit={this.inputHandlerForZohoDebit}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "10px" }}>
              <label>
                Create From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                name="modified_from_date"
                value={this.state.zohoDate.modified_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "modified_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

              {this.state.debitEffitracFormloading ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.handleZohoDrawerCloseForDebit}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer> */}

        {/* import debit effitrac/tally */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracDebitDrawer}
          onClose={this.closeDebitEffitractally}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.debitEffitracFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDateDebit} {this.state.lastUploadTimeDebit}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={this.state.effitracDebitFetchData.modifiedFromDate ?? ""}
                onChange={(v) =>
                  this.importDebitModifiedFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.effitracDebitFetchData.modifiedToDate ?? ""}
                onChange={(v) => this.importDebitModifiedToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Debit Note From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="invoiceFromDate"
                value={this.state.effitracDebitFetchData.invoiceFromDate ?? ""}
                onChange={(v) => this.importDebitFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Debit Note To Date:</label>
              <ValidatedDatePicker
                name="invoiceToDate"
                value={this.state.effitracDebitFetchData.invoiceToDate ?? ""}
                onChange={(v) => this.importDebitToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Org Inv From Date:</label>
              <ValidatedDatePicker
                name="originalInvFromDate"
                value={
                  this.state.effitracDebitFetchData.originalInvFromDate ?? ""
                }
                onChange={(v) =>
                  this.importDebitOriginalInvFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Org Inv To Date:</label>
              <ValidatedDatePicker
                name="originalInvToDate"
                value={
                  this.state.effitracDebitFetchData.originalInvToDate ?? ""
                }
                onChange={(v) =>
                  this.importDebitOriginalInvToDateChangeHandler(v)
                }
              />
            </div>

            {/* submit and back button */}
            <div className="mt-20">
              {this.state.debitEffitracFormloading ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeDebitEffitractally}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/*...........Edited by Harsh..................*/}
        {/* import Zoho */}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoCreditDrawer}
          // onClose={this.closeCreditEffitractally}
        >
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              // style={{
              //   background: this.state.buttonColorForInvoice,
              //   color: "white",
              // }}
              className="button-outlined-primary"
              disabled={
                this.state.buttonColorForInvoice === "green" ? true : false
              }
              onClick={this.establishConnectionForInvoice}
            >
              {this.state.messageForConnectionForInvoice}
            </button>
            {/* <Button
                variant="contained"
                style={{
                  background: this.state.buttonColorForInvoice,
                  color: "white",
                }}
                size="small"
                disabled={
                  this.state.buttonColorForInvoice === "green" ? true : false
                }
                onClick={this.establishConnectionForInvoice}
              >
                {this.state.messageForConnectionForInvoice}
              </Button> */}
        {/* </div>
          {this.state.buttonColorForInvoice === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color: this.state.timerForZoho < 30 ? "red" : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZoho} Seconds
              </span>
            </div>
          )}
          <ValidatorForm
            ref="form"
            onSubmit={this.inputHandlerForZohoCredit}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                Create From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                name="modified_from_date"
                value={this.state.zohoDate.modified_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "modified_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

              {this.state.creditEffitracFormloading ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.handleZohoDrawerCloseForCredit}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>  */}

        {/* import credit effitrac/tally */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracCreditDrawer}
          onClose={this.closeCreditEffitractally}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.creditEffitracFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDateCredit}{" "}
              {this.state.lastUploadTimeCredit}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={
                  this.state.effitracCreditFetchData.modifiedFromDate ?? ""
                }
                onChange={(v) =>
                  this.importCreditModifiedFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.effitracCreditFetchData.modifiedToDate ?? ""}
                onChange={(v) =>
                  this.importCreditModifiedToDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Credit Note From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="invoiceFromDate"
                value={this.state.effitracCreditFetchData.invoiceFromDate ?? ""}
                onChange={(v) => this.importCreditFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Credit Note To Date:</label>
              <ValidatedDatePicker
                name="invoiceToDate"
                value={this.state.effitracCreditFetchData.invoiceToDate ?? ""}
                onChange={(v) => this.importCreditToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Org Inv From Date:</label>
              <ValidatedDatePicker
                name="originalInvFromDate"
                value={
                  this.state.effitracCreditFetchData.originalInvFromDate ?? ""
                }
                onChange={(v) =>
                  this.importCreditOriginalInvFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Org Inv To Date:</label>
              <ValidatedDatePicker
                name="originalInvToDate"
                value={
                  this.state.effitracCreditFetchData.originalInvToDate ?? ""
                }
                onChange={(v) =>
                  this.importCreditOriginalInvToDateChangeHandler(v)
                }
              />
            </div>

            {/* submit and back button */}
            <div className="mt-20">
              {this.state.creditEffitracFormloading ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeCreditEffitractally}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* HEADER */}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div></div>

          <div style={{ display: "flex" }}>
            <button
              className={
                this.state.section_name === "Invoice"
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={this.clickInvoice}
              {...props}
            >
              Invoice Management
            </button>
            <button
              className={
                this.state.section_name === "Debit Note"
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={this.clickDebitNote}
              {...props}
            >
              Debit Note Management
            </button>
            <button
              className={
                this.state.section_name === "Credit Note"
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={this.clickCreditNote}
              {...props}
            >
              Credit Note Management
            </button>
          </div>
        </div>
        {(localStorage.getItem('is_uploads') !== "N") &&<>
        <div>
          <ToggleSwitch
            value={this.state.showUploadHistroyTable}
            onChange={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
            label={`Upload ${this.state.section_name}`}
          />
        </div>

        {/* File upload history table  */}
        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                this.state.disableEnableButtonForZoho &&
                true
              }
              zohoEffitracFn={this.zohoHandlerForAll}
              enableImportToEffTallybutton={
                localStorage.getItem("Trigger_Effitrac/Tally_Button") &&
                localStorage.getItem("Trigger_Effitrac/Tally_Button") === "true"
              }
              importToEffiTallyFn={this.handlAllEffitally} //function for effitally
            >
              <div style={{ marginBottom: "10px" }}>
                {" "}
                {this.state.uploadUrl && <this.CollectionListActions />}
              </div>

              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.length > 0 &&
                      this.state.uploadHistoryTableData.map((item, i) => {
                        return (
                          <tr>
                            <td>{item.upload_date}</td>
                            <td>{item.status}</td>
                            <td>{item.upload_type}</td>
                            <td>
                              {item.status === "In Progress" ? (
                                <RemoveRedEyeOutlinedIcon
                                  className="action-icon-button-disabled"
                                  color="disabled"
                                />
                              ) : item.status === "Uploaded" ? (
                                <RemoveRedEyeOutlinedIcon
                                  className="action-icon-button-disabled"
                                  color="disabled"
                                />
                              ) : (
                                <RemoveRedEyeOutlinedIcon
                                  className="action-icon-button showIcon"
                                  onClick={() => this.uploadReport(item.id)}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          style={{ color: "red", textAlign: "center" }}
                          colspan={4}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}
        </>}

        {/* ---- */}
        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div
                      class="form_title_name"
                      style={{ background: "#dddddd" }}
                    >
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        {/* .........................................debit note  */}
        {this.state.debitNoteTableData ? (
          <>
            {/* arc seller debit note table */}
            <BoxWrapper
              boxTitle="Debit Note Details"
              backButtonForZohoEnable={this.state.isDebitEffitrac}
              backButton={this.backToPrevTableHandler}
              importToCwsEnableForZoho={this.state.isDebitEffitrac}
              importToCwsForZoho={this.debitImportToCWS}
              exportToExcelEnable={true}
              exportToExcelFn={this.export_to_excel}
              enableSearchInput={true}
              searchInputPlaceholder={"Search..."}
              searchInputOnchange={
                this.state.isDebitEffitrac
                  ? this.handleSearchInputChange
                  : this.debitSearchHandler
              }
            >
              {this.state.isDebitEffitrac ? (
                <CustomTable
                  headers={this.state.debitEffitracHeader}
                  columns={this.state.debitEffitracCol}
                  data={this.state.debitEffitracData}
                  actions={this.state.debitEffitracActions}
                />
              ) : (
                <>
                  <div className="responsive-table">
                    <table className="custome-table">
                      <tbody>
                        <tr>{this.arc_SellerdebitNoteTableHeader()}</tr>
                        {this.arc_SellerdebitNotedata()}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                    component="div"
                    count={this.state.debitRowCount} //total row count
                    rowsPerPage={this.state.debitRowsPerPage}
                    page={this.state.debitPage}
                    onPageChange={this.handleChangePageForDebit}
                    onRowsPerPageChange={this.handleChangeRowsPerPageForDebit}
                  />
                </>
              )}
            </BoxWrapper>
          </>
        ) : (
          ""
        )}

        {/* add debit note drawer  */}
        <Drawer
          anchor="right"
          open={this.state.addDebitNote_DrawerOpen}
          variant={this.state.debitModalBackDrop === true ? "persistent" : ""}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}

            {/* modal open button */}
            <Button
              style={{ marginTop: "45px" }}
              size="small"
              fullWidth
              variant="outlined"
              onClick={this.debitModalOpen}
            >
              select invoice*
            </Button>

            <ValidatorForm
              ref="form"
              onSubmit={this.addDebitNote_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // requiredField=""
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.addDebitNoteData.customerCode ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.addDebitNoteData.gstin ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                requiredField=""
                validator={["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["Invalid PAN Numaber"]}
                value={this.state.addDebitNoteData.pan ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.addDebitNoteData.invoiceNo ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addDebitNoteData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Debit Note No"
                name="debitNoteNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addDebitNoteData.debitNoteNo ?? ""}
                textOnchangeFunction={this.addDebitNote_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Debit Note Date
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="debitNoteDate"
                  value={this.state.debitNoteDateDisplay ?? ""}
                  onChange={(v) => this.handleDebitNoteDateChange(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        taxableValue: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Non Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        nonTaxableValue: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ SGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        sgst: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        cgst: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ IGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        igst: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CESS:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  name="cess"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        cess: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addDebitNoteData.gstTaxRate ?? ""}
                textOnchangeFunction={this.addDebitNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                value={this.state.addDebitNoteData.hsnSacCode}
                textOnchangeFunction={this.addDebitNote_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Tax Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        taxAmount: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Total Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addDebitNoteData: {
                        ...prevState.addDebitNoteData,
                        totalAmount: String(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label> <br />
                <Autocomplete
                  id="combo-box-demo"
                  name="placeOfSupply"
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        addDebitNoteData: {
                          ...prevState.addDebitNoteData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        addDebitNoteData: {
                          ...prevState.addDebitNoteData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                    />
                  )}
                />
              </div>
              <InputField
                inputType="text"
                textLabel="Debit Note Reason"
                name="debitNoteReason"
                requiredField=""
                value={this.state.addDebitNoteData.debitNoteReason ?? ""}
                textOnchangeFunction={this.addDebitNote_inputHandler}
              />
              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.addDebitNote_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* edit debit note drawer  */}
        <Drawer
          anchor="right"
          open={this.state.editDebitNote_DrawerOpen}
          onClose={this.editDebitNote_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editDebitNote_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="ERP Customer Code"
                name="customerCode"
                // requiredField="*"
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.editDebitNoteData.customerCode ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.editDebitNoteData.gstin ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                // requiredField="*"
                validator={["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                errorMsg={["Invalid PAN Numaber"]}
                value={this.state.editDebitNoteData.pan ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editDebitNoteData.invoiceNo ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editDebitNoteData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Debit Note No"
                name="debitNoteNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editDebitNoteData.debitNoteNo ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Debit Note Date
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="debitNoteDate"
                  value={this.state.debitNoteDateDisplay ?? ""}
                  onChange={(v) => this.editDebitNoteDateChangeHandler(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.taxableValue
                      ? this.state.editDebitNoteData.taxableValue
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        taxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Non Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.nonTaxableValue
                      ? this.state.editDebitNoteData.nonTaxableValue
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        nonTaxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ SGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.sgst
                      ? this.state.editDebitNoteData.sgst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        sgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.cgst
                      ? this.state.editDebitNoteData.cgst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        cgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ IGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.igst
                      ? this.state.editDebitNoteData.igst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        igst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CESS:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.cess
                      ? this.state.editDebitNoteData.cess
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        cess: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editDebitNoteData.gstTaxRate ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                value={this.state.editDebitNoteData.hsnSacCode}
                textOnchangeFunction={this.editDebitNote_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Tax Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.taxAmount
                      ? this.state.editDebitNoteData.taxAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        taxAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Total Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editDebitNoteData.totalAmount
                      ? this.state.editDebitNoteData.totalAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editDebitNoteData: {
                        ...prevState.editDebitNoteData,
                        totalAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label> <br />
                <Autocomplete
                  // style={{ marginTop: "20px" }}
                  id="combo-box-demo"
                  name="placeOfSupply"
                  value={this.state.editDebitNoteData.placeOfSupply ?? ""}
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        editDebitNoteData: {
                          ...prevState.editDebitNoteData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        editDebitNoteData: {
                          ...prevState.editDebitNoteData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                      // label="Place of Supply"
                    />
                  )}
                />
              </div>
              <InputField
                inputType="text"
                textLabel="Debit Note Reason"
                name="debitNoteReason"
                requiredField=""
                value={this.state.editDebitNoteData.debitNoteReason ?? ""}
                textOnchangeFunction={this.editDebitNote_inputHandler}
              />
              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.editDebitNote_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* show data  */}
        <RightDrawer
          drawerName="Debit Note Details"
          open={this.state.showDebitNoteDrawer}
          onClose={this.closeShowDrawer_DebitNoteData}
        >
          {Object.keys(this.state.showDebitNoteDetailsData).length && (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Debit Note No"
                value={this.state.showDebitNoteDetailsData.debit_note_no ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Debit Note Date"
                value={this.state.showDebitNoteDetailsData.debit_note_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Taxable Value"
                value={numberFormat(
                  this.state.showDebitNoteDetailsData.taxable_value
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="CGST"
                value={numberFormat(this.state.showDebitNoteDetailsData.cgst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="SGST"
                value={numberFormat(this.state.showDebitNoteDetailsData.sgst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="IGST"
                value={numberFormat(this.state.showDebitNoteDetailsData.igst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="GST Tax %"
                value={this.state.showDebitNoteDetailsData.gstTaxRate ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="HSN/SAC Code"
                value={this.state.showDebitNoteDetailsData.hsnSacCode ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Total Amount"
                value={numberFormat(
                  this.state.showDebitNoteDetailsData.total_amount
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Non Taxable Value"
                value={numberFormat(
                  this.state.showDebitNoteDetailsData.non_taxable_adj
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Tax Amount"
                value={numberFormat(
                  this.state.showDebitNoteDetailsData.tax_amount
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Place Of Supply"
                value={this.state.showDebitNoteDetailsData.placeOfSupply ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Debit Note Reason"
                value={this.state.showDebitNoteDetailsData.debit_note_reason}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="ERP Customer Code"
                value={this.state.showDebitNoteDetailsData.cust_code}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showDebitNoteDetailsData.created_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showDebitNoteDetailsData.modified_date}
              />
            </>
          )}
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.closeShowDrawer_DebitNoteData}
          >
            close
          </button>
        </RightDrawer>
        {/* end */}

        {/*1. ......................................................................................invoice  */}
        {this.state.invoiceTableData ? (
          <>
            {/* arc seller debit note table */}
            <BoxWrapper
              boxTitle="Invoice Details"
              backButtonForZohoEnable={this.state.isEffitrac}
              backButton={this.backToPrevTableHandler}
              importToCwsEnableForZoho={this.state.isEffitrac}
              importToCwsForZoho={this.importToCWS}
              exportToExcelEnable={true}
              exportToExcelFn={this.export_to_excel}
              enableSearchInput={true}
              searchInputPlaceholder={"Search..."}
              searchInputOnchange={
                this.state.isEffitrac
                  ? this.handleSearchInputChange
                  : this.invoiceSearchHandler
              }
            >
              {this.state.isEffitrac ? (
                <CustomTable
                  headers={this.state.invoiceHeader}
                  columns={this.state.invoiceCol}
                  data={this.state.invoiceData}
                  actions={this.state.actions}
                />
              ) : (
                <>
                  <div className="responsive-table">
                    <table className="custome-table">
                      <tbody>
                        <tr>{this.arc_SellerInvoiceTableHeader()}</tr>
                        {this.arc_SellerInvoice()}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                    component="div"
                    count={this.state.rowCount} //total row count
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />{" "}
                </>
              )}
            </BoxWrapper>
          </>
        ) : (
          ""
        )}

        {/* add invoice drawer  */}
        <Drawer
          anchor="right"
          variant={this.state.invoiceModalBackDrop === true ? "persistent" : ""}
          open={this.state.addInvoice_DrawerOpen}
          // onClose={this.addInvoice_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addInvoice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: "45px" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.customerTableModalOpen}
              >
                select customer*
              </Button>
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // requiredField="*"
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.addInvoiceData.customerCode ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.addInvoiceData.gstin ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                // requiredField=""
                validator={["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["Invalid PAN Numaber"]}
                value={this.state.addInvoiceData.pan ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addInvoiceData.invoiceNo ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Invoice Date
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="invoiceDate"
                  value={this.state.invoiceDateDisplay ?? ""}
                  onChange={(v) => this.handleInvoiceDateChange(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Einvoice IRN"
                name="einvoiceIrn"
                requiredField=""
                value={this.state.addInvoiceData.einvoiceIrn ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Due Date
                  <span style={{ color: "red" }}></span>:
                </label>
                <ValidatedDatePicker
                  name="dueDate"
                  value={this.state.dueDateDisplay ?? ""}
                  onChange={(v) => this.handleDueDateChange(v)}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        taxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Non Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        nonTaxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ SGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        sgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        cgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ IGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        igst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CESS:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        cess: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addInvoiceData.gstTaxRate ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                value={this.state.addInvoiceData.hsnSacCode ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Tax Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        taxAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Total Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addInvoiceData: {
                        ...prevState.addInvoiceData,
                        totalAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Invoice Type"
                name="invoiceType"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.addInvoiceData.invoiceType ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />
              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label>
                <Autocomplete
                  id="combo-box-demo"
                  name="placeOfSupply"
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        addInvoiceData: {
                          ...prevState.addInvoiceData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        addInvoiceData: {
                          ...prevState.addInvoiceData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                      // label="Place of Supply"
                    />
                  )}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="PO No"
                name="poNo"
                value={this.state.addInvoiceData.poNo ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Collection Agency"
                name="invoiceCollectionAgency"
                value={this.state.addInvoiceData.invoiceCollectionAgency ?? ""}
                textOnchangeFunction={this.addInvoice_inputHandler}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.addInvoice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* edit invoice drawer  */}
        <Drawer
          anchor="right"
          open={this.state.editInvoice_DrawerOpen}
          onClose={this.editInvoice_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editInvoice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // requiredField="*"
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.customerCode ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.gstin ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                // requiredField="*"
                validator={["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                errorMsg={["Invalid PAN Numaber"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editInvoiceData.pan ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.invoiceNo ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Type"
                name="invoiceType"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.invoiceType ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Einvoice IRN"
                name="einvoiceIrn"
                requiredField=""
                value={this.state.editInvoiceData.einvoiceIrn ?? ""}
                textOnchangeFunction={this.editInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Due Date
                  <span style={{ color: "red" }}></span>:
                </label>
                <ValidatedDatePicker
                  name="dueDate"
                  value={this.state.dueDateDisplay ?? ""}
                  onChange={(v) => this.handleDueDateChange(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.taxableValue
                      ? this.state.editInvoiceData.taxableValue
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        taxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Non Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.nonTaxableValue
                      ? this.state.editInvoiceData.nonTaxableValue
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        nonTaxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ SGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.sgst
                      ? this.state.editInvoiceData.sgst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        sgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.cgst
                      ? this.state.editInvoiceData.cgst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        cgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ IGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.igst
                      ? this.state.editInvoiceData.igst
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        igst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CESS:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.cess
                      ? this.state.editInvoiceData.cess
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        cess: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editInvoiceData.gstTaxRate ?? ""}
                textOnchangeFunction={this.editInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.editInvoiceData.hsnSacCode ?? ""}
                textOnchangeFunction={this.editInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Tax Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.taxAmount
                      ? this.state.editInvoiceData.taxAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        taxAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Total Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editInvoiceData.totalAmount
                      ? this.state.editInvoiceData.totalAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editInvoiceData: {
                        ...prevState.editInvoiceData,
                        totalAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="PO No"
                name="poNo"
                requiredField=""
                value={this.state.editInvoiceData.poNo ?? ""}
                textOnchangeFunction={this.editInvoice_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Collection Agency"
                name="invoiceCollectionAgency"
                value={this.state.editInvoiceData.invoiceCollectionAgency ?? ""}
                textOnchangeFunction={this.editInvoice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label> <br />
                <Autocomplete
                  // style={{ marginTop: "20px" }}
                  id="combo-box-demo"
                  name="placeOfSupply"
                  value={this.state.editInvoiceData.placeOfSupply ?? ""}
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        editInvoiceData: {
                          ...prevState.editInvoiceData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        editInvoiceData: {
                          ...prevState.editInvoiceData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                      // label="Place of Supply"
                    />
                  )}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.editInvoice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* invoice drawer show data */}
        <RightDrawer
          open={this.state.showInvoiceDrawer}
          onClose={this.closeInvoiceDrawer}
          drawerName="Invoice Details"
        >
          {Object.keys(this.state.showInvoiceDetailsData).length != 0 && (
            <>
              <TextField
                inputProps={{ readOnly: true }}
                fullWidth
                label="Customer PAN"
                value={this.state.showInvoiceDetailsData.cust_pan ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="GST Invoice Type"
                value={this.state.showInvoiceDetailsData.gst_invoice_type ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Due Date"
                value={this.state.showInvoiceDetailsData.due_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Taxable Value"
                value={numberFormat(
                  this.state.showInvoiceDetailsData.taxable_value
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Non Taxable Value"
                value={numberFormat(
                  this.state.showInvoiceDetailsData.non_taxable_adj
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="CESS"
                value={numberFormat(this.state.showInvoiceDetailsData.cess)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="GST Tax %"
                value={this.state.showInvoiceDetailsData.gst_tax_rate ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="HSN/SAC Code"
                value={this.state.showInvoiceDetailsData.hsnsaccode ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="CGST"
                value={numberFormat(this.state.showInvoiceDetailsData.cgst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="IGST"
                value={numberFormat(this.state.showInvoiceDetailsData.igst)}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="SGST"
                value={numberFormat(this.state.showInvoiceDetailsData.sgst)}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Invoice Collection Agency"
                value={
                  this.state.showInvoiceDetailsData.invoice_collection_agency
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Place Of Supply"
                value={this.state.showInvoiceDetailsData.pos ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="ERP Customer Code"
                value={this.state.showInvoiceDetailsData.cust_code}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showInvoiceDetailsData.created_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showInvoiceDetailsData.modified_date}
              />
            </>
          )}
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.closeInvoiceDrawer}
          >
            close
          </button>
        </RightDrawer>

        {/* Invoice table end */}

        {/* ..............................................................................................credit note table */}
        {this.state.creditNoteTableData ? (
          <>
            {/* arc seller crdit note table */}
            <BoxWrapper
              boxTitle=" Credit Note Details"
              backButtonForZohoEnable={this.state.isCreditEffitrac}
              backButton={this.backToPrevTableHandler}
              importToCwsEnableForZoho={this.state.isCreditEffitrac}
              importToCwsForZoho={this.creditImportToCWS}
              exportToExcelEnable={true}
              exportToExcelFn={this.export_to_excel}
              enableSearchInput={true}
              searchInputPlaceholder={"Search..."}
              searchInputOnchange={
                this.state.isCreditEffitrac
                  ? this.handleSearchInputChange
                  : this.creditSearchHandler
              }
            >
              {this.state.isCreditEffitrac ? (
                <CustomTable
                  headers={this.state.creditEffitracHeader}
                  columns={this.state.creditEffitracCol}
                  data={this.state.creditEffitracData}
                  actions={this.state.creditEffitracActions}
                />
              ) : (
                <>
                  <div className="responsive-table">
                    <table className="custome-table">
                      <tbody>
                        <tr>{this.arc_SellerCebitNoteTableHeader()}</tr>
                        {this.arc_SellerCebitNotedata()}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                    component="div"
                    count={this.state.creditRowCount} //total row count
                    rowsPerPage={this.state.creditRowsPerPage}
                    page={this.state.creditPage}
                    onPageChange={this.handleChangePageForCredit}
                    onRowsPerPageChange={this.handleChangeRowsPerPageForCredit}
                  />
                </>
              )}
            </BoxWrapper>
          </>
        ) : (
          ""
        )}

        {/* add credit note drawer  */}
        <Drawer
          anchor="right"
          open={this.state.addCreditNote_DrawerOpen}
          variant={
            this.state.creditNoteModalBackDrop === true ? "persistent" : ""
          }
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}

            <ValidatorForm
              ref="form"
              onSubmit={this.addCreditNote_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: "45px" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.creditNoteModalOpen}
              >
                select invoice*
              </Button>
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // requiredField="*"
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.addCreditNoteData.customerCode ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.addCreditNoteData.gstin ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                requiredField=""
                validator={["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["Invalid PAN Numaber"]}
                value={this.state.addCreditNoteData.pan ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.addCreditNoteData.invoiceNo ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCreditNoteData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="Credit Note No"
                name="creditNoteNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCreditNoteData.creditNoteNo ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <div style={{ marginTop: "20px" }}>
                <label>
                  Credit Note Date
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="creditNoteDate"
                  value={this.state.creditNoteDateDisplay ?? ""}
                  onChange={(v) => this.handleCreditNoteDateChange(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        taxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ Non Taxable Value:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        nonTaxableValue: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ SGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        sgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ CGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        cgst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ IGST:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        igst: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <label>₹ CESS:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        cess: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>
              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addCreditNoteData.gstTaxRate ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                value={this.state.addCreditNoteData.hsnSacCode ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <div style={{ marginTop: "20px" }}>
                <label>₹ Tax Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        taxAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <label>₹ Total Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCreditNoteData: {
                        ...prevState.addCreditNoteData,
                        totalAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Is Discount/Bad Debt/Other reason"
                name="isDiscountBadDebtOtherreason"
                requiredField=""
                value={
                  this.state.addCreditNoteData.isDiscountBadDebtOtherreason ??
                  ""
                }
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label> <br />
                <Autocomplete
                  // style={{ marginTop: "20px" }}
                  id="combo-box-demo"
                  name="placeOfSupply"
                  value={this.state.addCreditNoteData.placeOfSupply ?? ""}
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        addCreditNoteData: {
                          ...prevState.addCreditNoteData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        addCreditNoteData: {
                          ...prevState.addCreditNoteData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                      // label="Place of Supply"
                    />
                  )}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Credit Note Reason"
                name="creditNoteReason"
                requiredField=""
                value={this.state.addCreditNoteData.creditNoteReason ?? ""}
                textOnchangeFunction={this.addCreditNote_inputHandler}
              />
              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.addCreditNote_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* edit credit note drawer  */}
        <Drawer
          anchor="right"
          open={this.state.editCreditNote_DrawerOpen}
          onClose={this.editCreditNote_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editCreditNote_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="cuistomerCode"
                // requiredField="*"
                // validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                // errorMsg={["This field is required"]}
                value={this.state.editCreditNoteData.customerCode ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required"]}
                value={this.state.editCreditNoteData.gstin ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                requiredField="*"
                inputProps={{
                  readOnly: true,
                }}
                validator={[
                  "required",
                  "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}",
                ]}
                errorMsg={["This field is required", "Invalid PAN Numaber"]}
                value={this.state.editCreditNoteData.pan ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCreditNoteData.invoiceNo ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCreditNoteData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Credit Note No"
                name="creditNoteNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCreditNoteData.creditNoteNo ?? ""}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Credit Note Date
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="creditNoteDate"
                  value={this.state.creditNoteDateDisplay ?? ""}
                  onChange={(v) => this.editCreditNoteDateChangeHandler(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              <InputField
                inputType="text"
                textLabel="Taxable Value"
                name="taxableValue"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editCreditNoteData.taxableValue
                    ? numberFormat(this.state.editCreditNoteData.taxableValue)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="Non Taxable Value"
                name="nonTaxableValue"
                value={
                  this.state.editCreditNoteData.nonTaxableValue
                    ? numberFormat(
                        this.state.editCreditNoteData.nonTaxableValue
                      )
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="SGST"
                name="sgst"
                value={
                  this.state.editCreditNoteData.sgst
                    ? numberFormat(this.state.editCreditNoteData.sgst)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="CGST"
                name="cgst"
                value={
                  this.state.editCreditNoteData.cgst
                    ? numberFormat(this.state.editCreditNoteData.cgst)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="IGST"
                name="igst"
                value={
                  this.state.editCreditNoteData.igst
                    ? numberFormat(this.state.editCreditNoteData.igst)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="CESS"
                name="cess"
                value={
                  this.state.editCreditNoteData.cess
                    ? numberFormat(this.state.editCreditNoteData.cess)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />
              <InputField
                inputType="text"
                textLabel="GST Tax %"
                name="gstTaxRate"
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editCreditNoteData.gstTaxRate ?? ""}
                textOnchangeFunction={this.editCreditNote_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="HSN/SAC Code"
                name="hsnSacCode"
                value={this.state.editCreditNoteData.hsnSacCode ?? ""}
                textOnchangeFunction={this.editCreditNote_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Tax Amount"
                name="taxAmount"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editCreditNoteData.taxAmount
                    ? numberFormat(this.state.editCreditNoteData.taxAmount)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <InputField
                inputType="text"
                textLabel="Total Amount"
                name="totalAmount"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editCreditNoteData.totalAmount
                    ? numberFormat(this.state.editCreditNoteData.totalAmount)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.editCreditNote_inputNumberHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>Place Of Supply</label> <br />
                <Autocomplete
                  // style={{ marginTop: "20px" }}
                  id="combo-box-demo"
                  name="placeOfSupply"
                  value={this.state.editCreditNoteData.placeOfSupply ?? ""}
                  onChange={(e, value) => {
                    if (value === null) {
                      this.setState((prevState) => ({
                        editCreditNoteData: {
                          ...prevState.editCreditNoteData,
                          placeOfSupply: "",
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        editCreditNoteData: {
                          ...prevState.editCreditNoteData,
                          placeOfSupply: String(value),
                        },
                      }));
                    }
                  }}
                  options={this.state.getApiForPlaceOfSupply}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      name="placeOfSupply"
                      // label="Place of Supply"
                    />
                  )}
                />
              </div>

              {/* Is Discount/Bad Debt/Other reason */}
              <InputField
                inputType="text"
                textLabel="Is Discount/Bad Debt/Other reason"
                name="isDiscountBadDebtOtherreason"
                requiredField=""
                value={
                  this.state.editCreditNoteData.isDiscountBadDebtOtherreason ??
                  ""
                }
                textOnchangeFunction={this.editCreditNote_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Credit Note Reason"
                name="creditNoteReason"
                requiredField=""
                value={this.state.editCreditNoteData.creditNoteReason ?? ""}
                textOnchangeFunction={this.editCreditNote_inputHandler}
              />
              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="outlined"
                size="small"
                onClick={this.editCreditNote_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer>

        {/* crdit note show drawer  */}
        <RightDrawer
          drawerName="Credit Note Details"
          open={this.state.showCreditNoteDrawer}
          onClose={this.closeShowDrawer_CreditNoteData}
        >
          {Object.keys(this.state.showCreditNoteDetailsData).length ? (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Credit Note No"
                value={this.state.showCreditNoteDetailsData.credit_note_no}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Credit Note Date"
                value={this.state.showCreditNoteDetailsData.credit_note_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Taxable Value"
                value={numberFormat(
                  this.state.showCreditNoteDetailsData.taxable_value
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="CGST"
                value={numberFormat(this.state.showCreditNoteDetailsData.cgst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="SGST"
                value={numberFormat(this.state.showCreditNoteDetailsData.sgst)}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="IGST"
                value={numberFormat(this.state.showCreditNoteDetailsData.igst)}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="GST Tax %"
                value={this.state.showCreditNoteDetailsData.gstTaxRate ?? ""}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="HSN/SAC Code"
                value={this.state.showCreditNoteDetailsData.hsnSacCode ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Total Amount"
                value={numberFormat(
                  this.state.showCreditNoteDetailsData.total_amount
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Tax Amount"
                value={numberFormat(
                  this.state.showCreditNoteDetailsData.tax_amount
                )}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Place Of Supply"
                value={this.state.showCreditNoteDetailsData.placeOfSupply ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Credit Note Reason"
                value={this.state.showCreditNoteDetailsData.credit_note_reason}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Is Discount Baddebt"
                value={this.state.showCreditNoteDetailsData.is_discount_baddebt}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="EPR Customer Code"
                value={this.state.showCreditNoteDetailsData.cust_code}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showCreditNoteDetailsData.created_date}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showCreditNoteDetailsData.modified_date}
              />
            </>
          ) : (
            ""
          )}

          <button
            className="button-outlined-secondary mt-20"
            onClick={this.closeShowDrawer_CreditNoteData}
          >
            close
          </button>
        </RightDrawer>

        {/* credit note table end */}

        {/* effitrac debit show drawer */}
        <Drawer
          anchor="right"
          open={this.state.effitracDebitShow}
          onClose={this.closeEffitracDebitShowHandler}
        >
          <div className={classes.showDetails}>
            <>
              <div className={classes.showDetailsBody}>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer Code"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .erp_cust_code
                  }
                />

                {this.state.zohoDataForCWS ? (
                  <>
                    <TextField
                      className={classes.showTextField}
                      inputProps={{ readOnly: true }}
                      fullWidth
                      label="Debit Note Date"
                      defaultValue={
                        this.state.debitTargetId !== null &&
                        this.state.debitEffitracData[this.state.debitTargetId]
                          .debitNoteDate
                      }
                    />
                  </>
                ) : (
                  <TextField
                    className={classes.showTextField}
                    inputProps={{ readOnly: true }}
                    fullWidth
                    label="Debit Note Date"
                    defaultValue={
                      this.state.debitTargetId !== null &&
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .debit_note_date
                    }
                  />
                )}

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer PAN"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .cust_pan
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Taxable Value"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .taxable_value
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Non Taxable Value"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .non_taxable_adj
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CESS"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .cess
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="GST Tax %"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .gst_tax_rate
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="HSN/SAC Code"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .hsn_sac_code
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CGST"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .cgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="IGST"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .igst
                    )
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="SGST"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .sgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Tax Amount"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    numberFormat(
                      this.state.debitEffitracData[this.state.debitTargetId]
                        .tax_amount
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Place Of Supply"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId].pos
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Debit Note Reason"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .debit_note_reason
                  }
                />

                {/* <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Fetrack"
                  defaultValue={
                    this.state.debitTargetId != null &&
                    this.state.debitEffitracData[this.state.debitTargetId]
                      .fetrack
                  }
                /> */}
              </div>
            </>
            <Button
              style={{
                marginTop: "25px",
                marginBottom: "30px",
                marginLeft: "10px",
              }}
              variant="contained"
              size="small"
              onClick={this.closeEffitracDebitShowHandler}
            >
              Back
            </Button>
          </div>
        </Drawer>

        {/* credit note effitrac table show drawer */}

        <Drawer
          anchor="right"
          open={this.state.effitracCreditShow}
          onClose={this.closeEffitracCreditShowHandler}
        >
          <div className={classes.showDetails}>
            <>
              <div className={classes.showDetailsBody}>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer Code"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .erp_cust_code
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Credit Note Date"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .credit_note_date
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer PAN"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .cust_pan
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Taxable Value"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .taxable_value
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Non Taxable Value"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .non_taxable_adj
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CESS"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .cess
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="GST Tax %"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .gst_tax_rate
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="HSN/SAC Code"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .hsn_sac_code
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CGST"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .cgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="IGST"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .igst
                    )
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="SGST"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .sgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Tax Amount"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    numberFormat(
                      this.state.creditEffitracData[this.state.creditTargetId]
                        .tax_amount
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Place Of Supply"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId].pos
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Credit Note Reason"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .credit_note_reason
                  }
                />

                {/* <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Fetrack"
                  defaultValue={
                    this.state.creditTargetId != null &&
                    this.state.creditEffitracData[this.state.creditTargetId]
                      .fetrack
                  }
                /> */}
              </div>
            </>
            <Button
              style={{
                marginTop: "25px",
                marginBottom: "30px",
                marginLeft: "10px",
              }}
              variant="contained"
              size="small"
              onClick={this.closeEffitracCreditShowHandler}
            >
              Back
            </Button>
          </div>
        </Drawer>

        <Drawer
          anchor="right"
          open={this.state.effitracInvShow}
          onClose={this.closeEffitracInvShowHandler}
        >
          <div className={classes.showDetails}>
            <>
              <div className={classes.showDetailsBody}>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer PAN"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].cust_pan
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Invoice Type"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].gst_invoice_type
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Taxable Value"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId].taxable_value
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Non Taxable Value"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId]
                        .non_taxable_adj
                    )
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CESS"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId].cess
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="GST Tax %"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].gst_tax_rate
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="HSN/SAC Code"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].hsn_sac_code
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="CGST"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId].cgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="IGST"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId].igst
                    )
                  }
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="SGST"
                  defaultValue={
                    this.state.targetId != null &&
                    numberFormat(
                      this.state.invoiceData[this.state.targetId].sgst
                    )
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Place Of Supply"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].pos
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Invoice Collection Agency"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId]
                      .invoice_collection_agency
                  }
                />

                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="Customer Code"
                  defaultValue={
                    this.state.targetId != null &&
                    this.state.invoiceData[this.state.targetId].erp_cust_code
                  }
                />
              </div>
            </>
            <Button
              style={{
                marginTop: "25px",
                marginBottom: "30px",
                marginLeft: "10px",
              }}
              variant="contained"
              size="small"
              onClick={this.closeEffitracInvShowHandler}
            >
              Back
            </Button>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default InvoiceManagement;
