import React from "react";
import moment from "moment";

const PayableDetailsReportTable = (props) => {
  const { payableDetailsReportTableData } = props;

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <div>
      <table className="custome-table">
        <thead>
          <tr>
            <th>Financial Year </th>
            <th>Vendor Code</th>
            <th>GSTIN</th>
            <th>Vendor Name</th>
            <th>Bill No</th>
            <th>Bill Date</th>
            <th>Expense Type</th>
            <th>Due Date</th>
            <th>Due Month</th>
            <th>Invoice Due Status</th>
            <th>Due Week</th>
            <th>Due Past</th>
            {/* <th>Ageing</th> */}
            <th>Grand Total</th>
          </tr>
        </thead>
        <tbody>
          {"error" in payableDetailsReportTableData ? (
            <tr>
              <td colSpan={12} style={{ color: "red" }}>
                No Data Found!
              </td>
            </tr>
          ) : (
            payableDetailsReportTableData.map((v, i) => {
              return (
                <tr>
                  <td>{v.finanical_year ?? ""}</td>
                  <td>{v.erp_vendor_code ?? ""}</td>
                  <td>{v.vendor_gstin ?? ""}</td>
                  <td>{v.vendor_name ?? ""}</td>
                  <td>{v.bill_no ?? ""}</td>
                  <td>{moment(v.bill_date).format("DD-MM-YYYY") ?? ""}</td>
                  <td>{v.expense_type ?? ""}</td>
                  <td>{moment(v.due_date).format("DD-MM-YYYY") ?? ""}</td>
                  <td>{v.due_month ?? ""}</td>
                  <td>{v.invoice_due_status ?? ""}</td>
                  <td>{v.due_week ?? ""}</td>
                  <td>{v.days_past_due_date ?? ""}</td>
                  <td>{indianRupeesFormat(v.grand_total.toFixed(2)) ?? ""}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PayableDetailsReportTable;
