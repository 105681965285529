import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from '@mui/material/Chip';
import SelectField from '../../../../components/ui/basicSelectInput'

const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" }
];

export default function GSTComputationReport() {

    const [trigarLoader, setTrigarLoader] = useState(false);
    const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);

    const [tdsHistoryTable, setTdsHistoryTable] = useState(true);

    const [triggerButton, setTriggerButton] = useState(true);
    const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);

    const [tdsReconHistoryData, setTdsReconHistoryData] = useState([]);


    const [loaderForTable, setLoaderForTable] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');

    const [responseMessage, setResponseMessage] = useState("");

    const [yearFromArr, setFromYearArr] = useState([]);

    const [selectedFinancailYear, setSelectedFinancialYear] = useState('')

    const [selectedFromMonth, setSelectedFromMonth] = useState('')
    const [selectedToMonth, setSelectedToMonth] = useState('')

    let history = useHistory();

    // Function to open Snackbar
    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);


        // setSnackbarOpen(false);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };

    const setFromYearValues = () => {
        let current_year = new Date().getFullYear();
        let yearArr = [];
        for (let i = current_year - 10; i <= current_year + 10; i++) {
            let startYear = i;
            let endYear = i + 1;
            let endYearShort = endYear.toString().slice(-2);
            yearArr.push({ id: i, year: `${startYear}-${endYearShort}` });
        }
        setFromYearArr(yearArr);
    };


    // .............use Effect ..........
    const getTdsPayableRecon = () => {

        setLoaderForTable(true);
        axios
            .get(`/get_gst_computation_summary/${localStorage.getItem("business_entity_id")}`)
            .then((res) => {
                setLoaderForTable(false);
                setTdsReconHistoryData(res.data.records);

            })
            .catch(() => setLoaderForTable(false));
    };

    useEffect(() => {
        getTdsPayableRecon();
        setFromYearValues()
    }, []);


    //................Handle Close Event..............
    const handleOpenAndCloseModel = () => {
        setSelectedFromMonth('')
        setTriggerButton(true);
        setSelectedToMonth('')
        setSelectedFinancialYear('')
        setTriggerModelFromToDate(false);
        // setTrigarModel(false);
        setTrigarLoader(false);
        setTrigarForErrorMessage(false);
    };


    //.......................Submit Handler...............
    const onSubmitHandlerForData = () => {

        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'from_month': selectedFromMonth,
            'to_month': selectedToMonth,
            'financial_year': selectedFinancailYear,
            // 'org_gstin' : localStorage.getItem('gst')
        }
        axios.post('create_gst_computation_report', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    handleSnackbarOpen(res.data.message, 'success')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()
                } else {
                    handleSnackbarOpen(res.data.message, 'error')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()

                }

            })
            .catch((err) => {
                getTdsPayableRecon();
                handleOpenAndCloseModel()
                handleSnackbarOpen(err?.data?.message, 'error')
            })



    };


    const updatedShowDetails = (item) => {
        // fail_reason
        if (item.report_status === "Generated") {
            history.push({
                pathname: `/gst_computation_report_data/${item.id}`,
                state: { items: item }
            })

            localStorage.setItem('is_confirm', item.is_confirm)
        } else {
            handleSnackbarOpen(item.fail_reason, 'error')
        }
    }


    const tdsPayablesHistoryTableBody = () => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (loaderForTable) {
            return (
                <tr>
                    <td
                        style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                        colSpan={6}
                    >
                        Loading…Please wait
                    </td>
                </tr>
            );
        } else if (tdsReconHistoryData && tdsReconHistoryData.length !== 0) {
            return tdsReconHistoryData.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.financial_year}</td>
                        <td>{item.from_month}-{item.to_month}</td>
                        {/* <td>{monthNames[item.tax_period.slice(-2)-1]}</td> */}
                        <td>{item.run_date_time}</td>
                        {/* <td>{item.report_type.toUpperCase().replace(/_/g, ' ')}</td> */}
                        <td>
                            <Chip
                                label={item.report_status}
                                color={
                                    item.report_status === "Generated" ? "success" :
                                        item.report_status === "Fail" ? "error" :
                                            "warning"
                                }
                                variant="outlined"
                            />
                        </td>
                        <td><VisibilityOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => updatedShowDetails(item)}
                            style={{ marginTop: '2px' }}
                            color="primary"
                        /></td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
                        No Data Found!
                    </td>
                </tr>
            );
        }
    };


    const handleTDSREconciliationButtonEvent = () => {
        setTriggerModelFromToDate(true);
    };


    return (
        <React.Fragment>

            {triggerButton && (
                <div>
                    <button
                        className="button-outlined-primary"
                        onClick={handleTDSREconciliationButtonEvent}
                    >
                        New GST Computation Report
                    </button>
                </div>
            )}

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={triggerModelFromToDate}
                onHide={handleOpenAndCloseModel}
                dialogClassName="modal-50w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>GST Computation Report</Modal.Title>
                </Modal.Header>
                <ValidatorForm
                    useRef="form"
                    onSubmit={onSubmitHandlerForData}
                    onError={(errors) => console.log(errors)}
                >
                    <Modal.Body>
                        <Grid container spacing={2}>
                            
                            <Grid item sm={4}>
                                <div>
                                    <SelectField
                                        SelectLabel="Financial Year "
                                        validator={["required"]}
                                        // requiredField="*"
                                        errorMsg={["This field is required"]}
                                        choice_id="year"
                                        choice_name="year"
                                        choices={yearFromArr}
                                        name="financial_year"
                                        value={selectedFinancailYear}
                                        textOnchangeFunction={(e) => setSelectedFinancialYear(e.target.value)}
                                    />
                                </div>
                            </Grid>

                            <Grid item sm={4}>
                                <div>
                                    <SelectField
                                        SelectLabel="From Month "
                                        validator={["required"]}
                                        // requiredField="*"
                                        errorMsg={["This field is required"]}
                                        choice_id="label"
                                        choice_name="label"
                                        choices={months}
                                        name="period"
                                        value={selectedFromMonth}
                                        textOnchangeFunction={(e) => setSelectedFromMonth(e.target.value)}
                                    />
                                </div>
                            </Grid>

                            <Grid item sm={4}>
                                <div>
                                    <SelectField
                                        SelectLabel="From Month "
                                        validator={["required"]}
                                        // requiredField="*"
                                        errorMsg={["This field is required"]}
                                        choice_id="label"
                                        choice_name="label"
                                        choices={months}
                                        name="period"
                                        value={selectedToMonth}
                                        textOnchangeFunction={(e) => setSelectedToMonth(e.target.value)}
                                    />
                                </div>
                            </Grid>


                        </Grid>
                        {trigarLoader ? (
                            <div
                                style={{ fontWeight: "600", color: "green", padding: "5px" }}
                            >
                                Please wait recon is in progress
                            </div>
                        ) : (
                            ""
                        )}
                        {trigarForErrorMessage && (
                            <div style={{ color: "red", fontWeight: "700" }}>
                                No Records Present!
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="button-outlined-secondary"
                            type="button"
                            onClick={handleOpenAndCloseModel}
                        >
                            Cancel
                        </button>
                        <button className="button-primary" type="submit">
                            Submit
                        </button>

                    </Modal.Footer>
                </ValidatorForm>
            </Modal>

            {tdsHistoryTable && (
                <BoxWrapper
                    boxTitle="GST COMPUTATION REPORT"

                >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    <th>FINANCILA YEAR</th>
                                    <th>PERIOD</th>
                                    <th>RUN DATE TIME</th>
                                    {/* <th>REPORT TYPE</th> */}
                                    <th>REPORT STATUS</th>
                                    <th>Report</th>
                                </tr>
                                {tdsPayablesHistoryTableBody()}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>

        </React.Fragment>
    );
}
