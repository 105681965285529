import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, onChange, value }) => {
  return (
    <div className="container">
      <div>
        <input
          type="checkbox"
          className="toggle-switch"
          id="default"
          onChange={onChange}
          checked={value}
        />
        <label for="default"></label>
      </div>
      <p> {label}</p>
    </div>
  );
};

export default ToggleSwitch;
