import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  listStyleOnHover: {
    "&:hover": {
      color: "#fff",
    },
  },

  menuText: {
    color: "#fff",
    fontWeight: "200px",
  },
  menuText1: {
    color: "#fff",
    fontWeight: "200px",
    paddingLeft: "14px",
  },
  menuText2: {
    color: "#fff",
    fontWeight: "200px",
    paddingLeft: "30px",
  },
  subMenu2: {
    marginLeft: "15px",
  },
  subMenu: {
    marginLeft: "15px",
  },
  subMenu1: {
    marginLeft: "0",
  },
  paddingOnList: {
    paddingLeft: "16px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    // display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#138e9c",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    padding: theme.spacing(2, 2),
    // background: "#fafafa",

    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  drawerHeaderSpace: {
    display: "flex",
    alignItems: "center",

    padding: theme.spacing(0, 0),
    // background: "#fafafa",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  listStyle: {
    overflowY: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const TDSPayable = () => {
  const classes = useStyles();

  return (
    <>
      <List style={{ padding: "0px" }}>
        <ListItem
          button
          component={NavLink}
          to={`/TDSRepo`}
          activeStyle={{
            fontWeight: "bold",
            color: "#fff",
            background: "rgba(241,222,250,0.275)",
          }}
          className={classes.listStyleOnHover}
        >
          <ListItemText
            className={classes.subMenu}
            primary={"26AS-TDS Repository"}
          />
        </ListItem>

        <Divider />

        <ListItem
          button
          component={NavLink}
          to={`/TDSReconciliationSaleV`}
          activeStyle={{
            fontWeight: "bold",
            color: "#fff",
            background: "rgba(241,222,250,0.275)",
          }}
          className={classes.listStyleOnHover}
        >
          <ListItemText
            className={classes.subMenu}
            primary={"TDS Receivables Recon"}
          />
        </ListItem>

        <Divider />

        <ListItem
          button
          component={NavLink}
          to={`/Gst2aTdsReconciliation`}
          activeStyle={{
            fontWeight: "bold",
            color: "#fff",
            background: "rgba(241,222,250,0.275)",
          }}
          className={classes.listStyleOnHover}
        >
          <ListItemText
            className={classes.subMenu}
            primary={"TDS Payables Recon"}
          />
        </ListItem>

        <Divider />

        <ListItem
          button
          component={NavLink}
          to={`/TDSProvision`}
          activeStyle={{
            fontWeight: "bold",
            color: "#fff",
            background: "rgba(241,222,250,0.275)",
          }}
          className={classes.listStyleOnHover}
        >
          <ListItemText className={classes.subMenu} primary={"Provision"} />
        </ListItem>

        <Divider />
      </List>
    </>
  );
};

export default TDSPayable;
