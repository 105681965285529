// import React, { useState } from "react";

// import classes from "./Report.module.css";
// import BoxWrapper from "../../../../../components/ui/boxWrapper";

// function MissingInGSTR(props) {
//   const { missingInGSTRData } = props;
//   const [missingInGSTRTableData, setMissingInGSTRTableData] =
//     useState(missingInGSTRData);
//   const [searchInput, setSearchInput] = useState("");

//   const requestSearch = (event) => {
//     const searchVal = event.target.value;
//     const filteredRows = missingInGSTRData.filter((row) => {
//       return (
//         String(row.GSTIN).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.Vendor_Name)
//           .toLowerCase()
//           .indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.DOC_NUMBER).toLowerCase().indexOf(searchVal.toLowerCase()) >=
//           0 ||
//         String(row.DOC_DATE).toLowerCase().indexOf(searchVal.toLowerCase()) >=
//           0 ||
//         String(row.DOC_TYPE).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
//       );
//     });
//     setMissingInGSTRTableData(filteredRows);
//   };

//   const cancelSearch = () => {
//     setSearchInput("");
//     requestSearch(searchInput);
//   };

//   // indian rupeess system---
//   const numberFormat = (value) =>
//     new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumSignificantDigits: 10,
//     }).format(value);
//   // ---End---
//   const missingInGSTRTableBody = () => {
//     if (missingInGSTRTableData.length >= 1) {
//       return missingInGSTRTableData.map((tableData, index) => {
//         const {
//           GSTIN,
//           Vendor_Name,
//           DOC_NUMBER,
//           DOC_DATE,
//           DOC_TYPE,
//           Taxable_Value,
//           IGST,
//           CGST,
//           SGST,
//           Cess,
//           Total_Amount,
//         } = tableData;
//         return (
//           <tr>
//             <td>{GSTIN}</td>
//             <td>{Vendor_Name}</td>
//             <td>{DOC_NUMBER}</td>
//             <td>{DOC_DATE}</td>
//             <td>{DOC_TYPE}</td>
//             <td>{numberFormat(Taxable_Value)}</td>
//             <td>{numberFormat(CGST)}</td>
//             <td>{numberFormat(SGST)}</td>
//             <td>{numberFormat(IGST)}</td>
//             <td>{numberFormat(Cess)}</td>
//             <td>{numberFormat(Total_Amount)}</td>
//           </tr>
//         );
//       });
//     } else {
//       return (
//         <tr>
//           <td colSpan={10} style={{ color: "red", textAlign: "left" }}>
//             No Data Found!
//           </td>
//         </tr>
//       );
//     }
//   };

//   return (
//     <>
//       <BoxWrapper
//         boxTitle="Reconciliation Status : Invoice(s) Missing in GSTR-2B"
//         // search input
//         enableSearchInput={true}
//         searchInputPlaceholder="Search..."
//         searchInputOnchange={requestSearch}
//       >
//         <div className={classes.ResponsiveTable}>
//           <table className={classes.missingInAccountTableCss}>
//             <tr>
//               <th rowSpan={2}>GSTIN</th>
//               <th rowSpan={2} align="right">
//                 VENDOR NAME
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC NUMBER
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC DATE
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC TYPE
//               </th>
//               <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
//                 As per Books
//               </th>
//             </tr>
//             <tr>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//             </tr>
//             {missingInGSTRTableBody()}
//           </table>
//         </div>
//       </BoxWrapper>
//     </>
//   );
// }

// export default MissingInGSTR;

import React, { useEffect, useState } from 'react';
import classes from './Report.module.css';
import BoxWrapper from '../../../../../components/ui/boxWrapper';

function MissingInGSTR({ missingstr, onTabClick }) {
  useEffect(() => {
    onTabClick();
  }, [onTabClick]);

  const [searchInput, setSearchInput] = useState('');
  const invoiceData = missingstr?.record?.missingInGstr ?? [];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = invoiceData.filter((invoice) => {
    // Perform a null check before accessing the 'includes' method
    const gstin = invoice.GSTIN || '';
    const vendorName = invoice.Vendor_Name || '';
  
    return (
      gstin.includes(searchInput) ||
      vendorName.includes(searchInput)
    );
  });

  return (
    <>
      <BoxWrapper
        searchInputPlaceholder="Search..."
        boxTitle="Reconciliation Status:  Invoice(s) Missing in GSTR-2B"
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <thead>
              <tr>
                <th rowSpan={2}>GSTIN</th>
                <th rowSpan={2} align="right">
                  VENDOR NAME
                </th>
                <th rowSpan={2} align="right">
                  DOC NUMBER
                </th>
                <th rowSpan={2} align="right">
                  DOC DATE
                </th>
                <th rowSpan={2} align="right">
                  DOC TYPE
                </th>
                <th style={{ background: '#ffcc66' }} colSpan={6} align="center">
                  As per Books
                </th>
              </tr>
              <tr>
                <th style={{ background: '#ffcc66' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  TOTAL AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
             
              {filteredData.map((invoice, index) => (
  <tr key={index}>
    <td style={{ color: 'black' }}>{invoice.GSTIN}</td>
    <td style={{ color: 'black' }}>{invoice.Vendor_Name}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_NUMBER}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_DATE}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_TYPE}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value}</td>
    <td style={{ color: 'black' }}>{invoice.CGST}</td>
    <td style={{ color: 'black' }}>{invoice.SGST}</td>
    <td style={{ color: 'black' }}>{invoice.IGST}</td>
    <td style={{ color: 'black' }}>{invoice.Cess}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Amount}</td>
  </tr>
))}

            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MissingInGSTR;

