import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import SearchBar from "material-ui-search-bar";
import classes from "./Report.module.css";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MissingInGSTR(props) {
  const { missingInGSTRData } = props;
  const [missingInGSTRTableData, setMissingInGSTRTableData] =
    useState(missingInGSTRData);
  const [searchInput, setSearchInput] = useState("");

  const requestSearch = (e) => {
    const searchVal = e.target.value;
    const filteredRows = missingInGSTRData.filter((row) => {
      return (
        String(row.GSTIN).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0 ||
        String(row.Vendor_Name)
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) >= 0 ||
        String(row.DOC_NUMBER).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.DOC_DATE).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.DOC_TYPE).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
      );
    });
    setMissingInGSTRTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchInput("");
    requestSearch(searchInput);
  };

  // indian date format
  const indianFormat = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---
  const missingInGSTRTableBody = () => {
    if (missingInGSTRTableData.length >= 1) {
      return missingInGSTRTableData.map((tableData, index) => {
        const {
          GSTIN,
          Vendor_Name,
          DOC_NUMBER,
          DOC_DATE,
          DOC_TYPE,
          Taxable_Value,
          IGST,
          CGST,
          SGST,
          Cess,
          Total_Amount,
        } = tableData;
        return (
          <tr>
            <td>{GSTIN}</td>
            <td>{Vendor_Name}</td>
            <td>{DOC_NUMBER}</td>
            <td>{indianFormat(DOC_DATE)}</td>

            <td>{DOC_TYPE}</td>
            <td>{numberFormat(Taxable_Value)}</td>
            <td>{numberFormat(CGST)}</td>
            <td>{numberFormat(SGST)}</td>
            <td>{numberFormat(IGST)}</td>
            <td>{numberFormat(Cess)}</td>
            <td>{numberFormat(Total_Amount)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={10} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Missing In GSTR-2A"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={(e) => requestSearch(e)}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                VENDOR NAME
              </th>
              <th rowSpan={2} align="right">
                DOC NUMBER
              </th>
              <th rowSpan={2} align="right">
                DOC DATE
              </th>
              <th rowSpan={2} align="right">
                DOC TYPE
              </th>
              <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
                As per Books
              </th>
            </tr>
            <tr>
              <th style={{ background: "#c6ffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {missingInGSTRTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MissingInGSTR;
