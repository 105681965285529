
import React, { useEffect, useState } from 'react';
import BoxWrapper from '../../components/ui/boxWrapper';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,

} from '@mui/material';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import axios from "../../axios-order";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ToggleSwitch from '../../components/ui/ToggleSwitch/ToggleSwitch';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function CreateSupplierMaster({  }) {
  const [formValues, setFormValues] = useState({
    supplierGstin: '',
    tradeName: '',
    supplierAddress2: '',
    phoneNumber: '',
    legalName: '',
    supplierAddress1: '',
    supplierLocation: '',
    pinCode: '',
    emailId: '',
    state: '', // Ensure this is always a string
  });

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isClearClicked, setIsClearClicked] = useState(false); 
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [fetchCount, setFetchCount] = useState(0);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [open, setOpen] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [errors, setErrors] = useState({
    bank_name: false,
    account_number: false,
    ifsc_code: false,
    account_holder_name: false,
  });
  const [bankDetails, setBankDetails] = useState({
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    account_holder_name: ''
  });
    // Function to handle dialog open
    const handleClickOpen = () => {
      setOpen(true);
    };
  



    // Function to handle dialog close
    const handleClose = () => {
      setOpen(false);
      setErrors({
        bank_name: false,
        account_number: false,
        ifsc_code: false,
        account_holder_name: false,
      });
      fetchBankDetails();
    
    };

     // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleIFSCChange = (e) => {
    const { value } = e.target;

    // Regex to check if value contains valid characters
    const letters = value.match(/[A-Za-z]/g) || [];
    const numbers = value.match(/[0-9]/g) || [];

    // Calculate lengths
    const lettersCount = letters.length;
    const numbersCount = numbers.length;

    // Check limits and construct new value
    if (lettersCount <= 4 && numbersCount <= 7 && value.length <= 11) {
        setBankDetails((prevState) => ({
            ...prevState,
            ifsc_code: value // Allow both upper and lower case letters
        }));
        // Reset error if input is valid
        setErrors((prev) => ({ ...prev, ifsc_code: false }));
    } else {
        // Prevent further input if limits are exceeded
        if (lettersCount > 4 || numbersCount > 7) {
            // Optional: Set error if the limit is exceeded
            setErrors((prev) => ({ ...prev, ifsc_code: true }));
        }
    }
};






 

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const states = [
    { code: 'AP', name: 'Andhra Pradesh' },
    { code: 'AR', name: 'Arunachal Pradesh' },
    { code: 'AS', name: 'Assam' },
    { code: 'BR', name: 'Bihar' },
    { code: 'CT', name: 'Chhattisgarh' },
    { code: 'GA', name: 'Goa' },
    { code: 'GJ', name: 'Gujarat' },
    { code: 'HR', name: 'Haryana' },
    { code: 'HP', name: 'Himachal Pradesh' },
    { code: 'JH', name: 'Jharkhand' },
    { code: 'KA', name: 'Karnataka' },
    { code: 'KL', name: 'Kerala' },
    { code: 'MP', name: 'Madhya Pradesh' },
    { code: 'MH', name: 'Maharashtra' },
    { code: 'MN', name: 'Manipur' },
    { code: 'ML', name: 'Meghalaya' },
    { code: 'MZ', name: 'Mizoram' },
    { code: 'NL', name: 'Nagaland' },
    { code: 'OR', name: 'Odisha' },
    { code: 'PB', name: 'Punjab' },
    { code: 'RJ', name: 'Rajasthan' },
    { code: 'SK', name: 'Sikkim' },
    { code: 'TN', name: 'Tamil Nadu' },
    { code: 'TG', name: 'Telangana' },
    { code: 'TR', name: 'Tripura' },
    { code: 'UP', name: 'Uttar Pradesh' },
    { code: 'UT', name: 'Uttarakhand' },
    { code: 'WB', name: 'West Bengal' },
    { code: 'AN', name: 'Andaman and Nicobar Islands' },
    { code: 'CH', name: 'Chandigarh' },
    { code: 'DH', name: 'Dadra and Nagar Haveli and Daman and Diu' },
    { code: 'DL', name: 'Delhi' },
    { code: 'JK', name: 'Jammu and Kashmir' },
    { code: 'LA', name: 'Ladakh' },
    { code: 'LD', name: 'Lakshadweep' },
    { code: 'PY', name: 'Puducherry' },
    { code: 'NT', name: 'Other Territory' },
    { code: 'OC', name: 'Other Country' }
  ];
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the input value contains only digits and limit it to 6 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const handlePinChange = (e) => {
    const { name, value } = e.target;
    
    // Allow only digits and limit to 6 characters
    if (/^\d{0,6}$/.test(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };


  const handleSupplierlocation = (e) => {
    const { name, value } = e.target;
    // Allow only alphabetic characters (letters) by using a regular expression
  if (/^[A-Za-z\s]*$/.test(value)) {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  }
  

  const handleClear = () => {
    setFormValues({
     
      tradeName: '',
      supplierAddress2: '',
      phoneNumber: '',
     supplierGstin:formValues.supplierGstin,
     legalName:formValues.legalName,
     emailId:'',
      supplierAddress1: '',
      supplierLocation: '',
      pinCode: '',
     
      state: '', // Reset state
    });
  
    setIsClearClicked(true);
    setImage(null);
  };

  
 
  const fetchData = async () => {
   
    let isMounted = true;
  
    
    
    const gstin = localStorage.getItem('gst');
    const entityName = localStorage.getItem('entity_name');
    const contactNumber = localStorage.getItem('contact_number');
    const emailId = localStorage.getItem('contact_email_id');
    const entityAddress = localStorage.getItem('Entity_Address');
    const businessEntityId = localStorage.getItem('business_entity_id');
  
    // Set initial form values from localStorage
    setFormValues(prevValues => ({
      ...prevValues,
      supplierGstin: gstin || '',
      legalName: entityName || '',
      emailId: emailId || '',
      phoneNumber: contactNumber || '',
      supplierAddress1: entityAddress || '',
    }));
  
    if (businessEntityId) {
      try {
        // Fetch complete entity data
        const completeEntityResponse = await axios.get(`/get_complete_entity_data/${businessEntityId}`);
        const completeEntityData = completeEntityResponse.data;
  
        if (isMounted) {
          localStorage.setItem('contact_number', completeEntityData.Entity.contact_number);
          localStorage.setItem('Entity_Address', completeEntityData.Entity.entity_address);
          localStorage.setItem('contact_email_id', completeEntityData.Entity.contact_email_id);
  
          setFormValues(prevValues => ({
            ...prevValues,
            supplierGstin: completeEntityData.gstin || prevValues.supplierGstin,
            legalName: completeEntityData.entityName || prevValues.legalName,
            emailId: completeEntityData.emailId || prevValues.emailId,
            phoneNumber: completeEntityData.contactNumber || prevValues.phoneNumber,
            supplierAddress1: completeEntityData.entityAddress || prevValues.supplierAddress1,
            // Update other fields as needed
          }));
        }
  
        // Fetch supplier master data
        const supplierResponse = await axios.get(`/einvoice/get_supplier_master/${businessEntityId}/`);
        if (supplierResponse.data.status) {
          if (isMounted) {
            setSupplierData(supplierResponse.data.records);
            setSupplierId(supplierResponse.data.records[0].id); 
          }
        } else {
          throw new Error(supplierResponse.data.message);
        }
        await fetchBankDetails(businessEntityId); 
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
       
   
      }

    } else {
      setLoading(false);
    }
  
    return () => {
      isMounted = false;
    };
  };
  
  const fetchBankDetails = async () => {
    try {
      const businessEntityId = localStorage.getItem('business_entity_id') 
        const response = await axios.get(`/einvoice/get_supplier_bank_info/${businessEntityId}`);
        

        if (response.data && response.data.status) {
            const records = response.data.records; // Access the records from the response

            // Extracting bank details from the records
            const newBankDetails = {
                bank_name: records.bank_name || '',
                account_number: records.account_number || '',
                ifsc_code: records.ifsc_code || '',
                account_holder_name: records.account_holder_name || ''
            };

            // Update the state and log the new state
            setBankDetails(newBankDetails);
           
        }
    } catch (error) {
        console.error('Error fetching bank details:', error);
    }
};


// Log bankDetails whenever it updates
useEffect(() => {
    console.log("Current Bank Details State:", bankDetails);
}, [bankDetails]); 

  useEffect(() => {
    // Call fetchData initially
    fetchData();
    
    // Schedule the second call after 1 second
    const timer = setTimeout(() => {
      setFetchCount(prevCount => prevCount + 1);
    }, 1000);

    // Clean up timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Call fetchData again if fetchCount is 1 (after 1 second)
    if (fetchCount === 1) {
      fetchData();
    }
  }, [fetchCount]);


 

  const handleSubmit = (e) => {
    e.preventDefault();

    const { tradeName, supplierLocation, pinCode, state , phoneNumber } = formValues;

    if (!tradeName || !supplierLocation || !pinCode || !state) {
      setSnackbarMessage("Please Enter Mandatory Fields");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    
  // Validate phone number only if it's provided
  const phoneNumberStr = String(phoneNumber).trim();
  if (phoneNumberStr && !/^\d{10}$/.test(phoneNumberStr)) {
    setSnackbarMessage("Phone number must be 10 digits");
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    return;
  }
    const pinCodeStr = String(pinCode);
    if (!/^\d{6}$/.test(pinCodeStr)) {
      setSnackbarMessage("Pin code must be  6 digits");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    
   
    const businessEntityId = localStorage.getItem('business_entity_id');
  
    const payload = {
      business_entity_id:businessEntityId, // Add business entity ID if you have one, or remove this key if not needed
      gstin: formValues.supplierGstin,
      legal_name: formValues.legalName,
      trade_name: formValues.tradeName,
      address_one: formValues.supplierAddress1,
      address_two: formValues.supplierAddress2,
      location: formValues.supplierLocation,
      state: formValues.state,
      pincode: formValues.pinCode,
      email: formValues.emailId,
      phone:  phoneNumberStr || '',
      company_logo: image || '', // Include Base64 image string in payload if available
    };
  
    axios.post(`/einvoice/post_supplier_master`, payload)
      .then(response => response.data)
      .then(data => {
        
        if (data.status) {
          setSnackbarMessage(data.message);
      
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
           // Clear specific fields
        setFormValues(prevValues => ({
          ...prevValues,
          tradeName: '',
          supplierLocation: '',
          supplierAddress2: '',
          pinCode: '',
          state: '',
        }));
          fetchData();
          handleToggleChange({ target: { checked: false } });
        }
     
        
      } )
      .catch((error) => {
        console.error('Error:', error);
     
      
  
        // Default error message
        let errorMessage = 'Error Uploading data';
      
        // Check if the error response is available
        if (error.response) {
          // If response data is available, extract the message
          errorMessage = error.response.data.message || errorMessage;
        } else if (error.message) {
          // If there's an error message directly, use it
          errorMessage = error.message;
        }
      
        // Set Snackbar message and open it
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  
  };
  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
        
    // Check if the file size is larger than 1MB (1MB = 1 * 1024 * 1024 bytes)
    if (file.size > 1 * 1024 * 1024) {
      setSnackbarMessage('File size exceeds 1MB. Please upload a smaller file.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true); // Show the Snackbar
      return; // Exit the function if file is too large
    }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImage(base64String); 
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // alert('Please upload a valid image file.');
    }
  };

  const handleRemoveImage = () => {
    
  setImagePreview(null); 
    setImage(null); 
  };

  const handleEdit = (row) => {
    handleToggleChange({ target: { checked: true } });
    
    setFormValues({
      supplierGstin: row.supplier_gstin,
      tradeName: row.supplier_trade_name,
      supplierAddress2: row.supplier_address_two,
      phoneNumber: row.supplier_phone,
      legalName: row.supplier_legal_name,
      supplierAddress1: row.supplier_address_one,
      supplierLocation: row.supplier_location,
      pinCode: row.supplier_pincode,
      emailId: row.supplier_email,
      state: row.supplier_state,
    });
      // Set the image preview from base64
  if (row.supplier_company_logo) {
    setImagePreview(`data:image/png;base64,${row.supplier_company_logo}`);
    setImage(`data:image/png;base64,${row.supplier_company_logo}`); // Store the base64 string if needed
  }
  };
  const handleToggleChange = () => {
    setShowUploadHistoryTable(!showUploadHistoryTable);
  };


   // Handle form submit
  //  const handleSub = () => {
  //   const business_entity_id = localStorage.getItem('business_entity_id');
  
  //   // Validate required fields
  //   const newErrors = {
  //     bank_name: !bankDetails.bank_name,
  //     account_number: !bankDetails.account_number,
  //     ifsc_code: !bankDetails.ifsc_code  || bankDetails.ifsc_code.length !== 11,
  //     account_holder_name: !bankDetails.account_holder_name,
  //   };
  
  //   if (newErrors.ifsc_code) {
  //    alert('IFSC code must be exactly 11 characters long.');
  // }


  //   setErrors(newErrors);
  
  //   // Check if any error exists
  //   if (Object.values(newErrors).some((error) => error)) {
     
  //     return; // Do not submit if there are errors
  //   }
  
  //   // Make the POST request using .then() and .catch()
  //   axios.post('/einvoice/add_supplier_bank_info', {
  //     business_entity_id: business_entity_id,
  //     supplier_id: supplierId, 
  //     bank_name: bankDetails.bank_name,
  //     account_number: bankDetails.account_number,
  //     ifsc_code: bankDetails.ifsc_code,
  //     account_holder_name: bankDetails.account_holder_name,
  //   })
  //   .then((response) => {
  //     // Handle success
  //      toast.success(response.data.message);
  // // Close the dialog after submission
  //     setOpen(false);
  //   })
  //   .catch((error) => {
  //     // Handle error
  //     console.error('Error submitting bank details:', error);
  // toast.error(error.response.data.message);
  //   });
  // };
  // Handle form submit
const handleSub = () => {
  const business_entity_id = localStorage.getItem('business_entity_id');

  // Validate required fields
  const newErrors = {
      bank_name: !bankDetails.bank_name,
      account_number: !bankDetails.account_number,
      ifsc_code: !bankDetails.ifsc_code || bankDetails.ifsc_code.length !== 11,
      account_holder_name: !bankDetails.account_holder_name,
  };

  if (newErrors.ifsc_code) {
      toast.error('IFSC code must be exactly 11 characters long.');
  }

  setErrors(newErrors);

  // Check if any error exists
  if (Object.values(newErrors).some((error) => error)) {
      return; // Do not submit if there are errors
  }

  // Make the POST request using .then() and .catch()
  axios.post('/einvoice/add_supplier_bank_info', {
      business_entity_id: business_entity_id,
      supplier_id: supplierId,
      bank_name: bankDetails.bank_name,
      account_number: bankDetails.account_number,
      ifsc_code: bankDetails.ifsc_code,
      account_holder_name: bankDetails.account_holder_name,
  })
  .then((response) => {
      // Handle success
      toast.success(response.data.message);
      // Close the dialog after submission
      setOpen(false);
  })
  .catch((error) => {
      // Handle error
      console.error('Error submitting bank details:', error);
      toast.error(error.response.data.message);
  });
};

  
  
  return (
    <div>
     <BoxWrapper  boxTitle="Supplier Details"
      enableAddButton={true}
      addButtonLabel={"ADD BANK INFO"}
      addButtonHandlerFn={handleClickOpen}
               
               >
                  <div style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                 <Table sx={{ tableLayout: 'auto' }}>
                   <TableHead>
                     <TableRow>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>GSTIN</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold',whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis'  }}>LEGAL NAME</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold',whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis'  }}>TRADE NAME</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold',whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis' }}>ADDRESS 1</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold',whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis'  }}>ADDRESS 2</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>LOCATION</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>STATE</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>PINCODE</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>EMAIL</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>PHONE</TableCell>
                       <TableCell style={{ fontSize: '15px', fontWeight: 'bold' }}>ACTION</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                       {supplierData.length > 0 ? (
                         supplierData.map((row) => (
                           <TableRow key={row.id}>
                             <TableCell>{row.supplier_gstin}</TableCell>
                             <TableCell>{row.supplier_legal_name}</TableCell>
                             <TableCell>{row.supplier_trade_name}</TableCell>
                             <TableCell>{row.supplier_address_one}</TableCell>
                             <TableCell>{row.supplier_address_two}</TableCell>
                             <TableCell>{row.supplier_location}</TableCell>
                             <TableCell>{row.supplier_state}</TableCell>
                             <TableCell>{row.supplier_pincode}</TableCell>
                             <TableCell>{row.supplier_email}</TableCell>
                             <TableCell>{row.supplier_phone}</TableCell>
                             <TableCell>
                     <EditIcon 
                       style={{ cursor: 'pointer' }} 
                       sx={{
                         '&:hover': {
                           color: 'blue',  // Change to your desired hover color
                         },
                       }}
                       onClick={() => handleEdit(row)} 
                     />
                   </TableCell>
                           </TableRow>
                         ))
                       ) : (
                         <TableRow>
                           <TableCell colSpan={10}>No data found!</TableCell>
                         </TableRow>
                       )}
                     </TableBody>
                 </Table>
                 </div>
               </BoxWrapper>
               <div>
        <ToggleSwitch
         id="toggleUploadRecipient"  
          onChange={handleToggleChange}
          value={showUploadHistoryTable}
          label="Add Supplier Details"
         
        />
       
      </div>
      {showUploadHistoryTable && (
      <BoxWrapper boxTitle="Business Details (Supplier Details)">
        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="supplier-gstin"
                label="Supplier GSTIN"
                name="supplierGstin"
                variant="outlined"
               
                value={formValues.supplierGstin}
             
              
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                fullWidth
                id="trade-name"
                label="Trade Name*"
                name="tradeName"
                value={formValues.tradeName}
                onChange={handleInputChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isClearClicked && formValues.tradeName === '' ? 'red' : undefined, // Conditional border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="phone"
                label="Phone"
                name="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{ maxLength: 10 }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isClearClicked && !formValues.phoneNumber ? 'red' : undefined, // Conditional border color
                    },
                  },
                }}
    
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                // disabled
                id="legal-name"
                label="Legal Name"
                name="legalName"
                value={formValues.legalName}
                // onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="supplier-address1"
                label="Supplier Address 1"
                name="supplierAddress1"
                value={formValues.supplierAddress1}
                onChange={handleInputChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isClearClicked && !formValues.supplierAddress1 ? 'red' : undefined, // Conditional border color
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="supplier-address2"
                label="Supplier Address 2"
                name="supplierAddress2"
                value={formValues.supplierAddress2}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                fullWidth
                id="supplier-location"
                label="Supplier Location*"
                name="supplierLocation"
                value={formValues.supplierLocation}
                onChange={handleSupplierlocation}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isClearClicked && formValues.supplierLocation === '' ? 'red' : undefined,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                fullWidth
                id="pin-code"
                label="Pin Code*"
                name="pinCode"
                value={formValues.pinCode}
                onChange={handlePinChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: isClearClicked && formValues.pinCode === '' ? 'red' : undefined,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="email-id"
                label="Email ID"
                name="emailId"
                type="email"
                // disabled
                value={formValues.emailId}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth >
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  // required
                  id="state"
                  name="state"
                  value={formValues.state || ''} // Ensure it defaults to an empty string
                  onChange={handleInputChange}
                  label="State"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: isClearClicked && !formValues.state ? 'red' : undefined, // Conditional border color
                      },
                    },
                  }}
                >
                  {states.map((state) => (
                    <MenuItem key={state.name} value={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept=".jpg,.jpeg,.png,.pdf"
                id="upload-image"
                type="file"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
               <label htmlFor="upload"
                style={{ fontSize: '16px',marginRight:'30px' }}
                >
                
    COMPANY LOGO
  </label>
  <label htmlFor="upload-image">
  <Button
    variant="outlined"
    color="secondary"
    component="span"
    sx={{
      borderColor: 'green', // Outline color
      color: 'green', // Text color
      textTransform: 'none', // Ensure text is not transformed to uppercase
      '&:hover': {
        borderColor: 'darkgreen', // Outline color on hover
        color: 'darkgreen', // Text color on hover
      },
    }}
  >
    Upload
  </Button>
</label>

              <Button
                variant="outlined"
                color="secondary"
                component="span"
                sx={{
                  borderColor: 'red', // Outline color
                  color: 'red', // Text color
                   textTransform: 'none',
                  '&:hover': {
                    borderColor: 'red', // Outline color on hover
                    color: 'red', // Text color on hover
                  },
                }}
            
                  onClick={handleRemoveImage}
                    style={{ marginLeft: '20px' }}
                >
                 Remove
                </Button>
              {imagePreview && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={imagePreview}
            alt="Company Logo"
            style={{ maxWidth: '60%', maxHeight: '140px', border: '1px solid #ccc' }}
          />


         <div>
     
       </div>
       </div>
      )}
 <div style={{ fontSize: '10px',color:"#909499" }}>
  <p>Preferred Image Dimensions: 240*240 pixels @ 72 DPI</p>
  <p>Maximum File Size: 1MB</p>
  <p>Format - PDF, JPG, JPEG & PNG</p>
</div>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClear}
                >
                  Clear
                </Button>
             
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        sx={{ mt: 4 }} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioned at the bottom-right corner
      >
        <Alert onClose={handleSnackbarClose}  severity={snackbarSeverity}   variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </BoxWrapper>
      )}
     
        {/* Dialog Box for Adding Bank Info */}
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Bank Information</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
       
            margin="dense"
            label="Bank Name"
            name="bank_name"
            value={bankDetails.bank_name} 
            onChange={handleChange}
            fullWidth
            error={errors.bank_name} // Show red border if there's an error
            helperText={errors.bank_name ? "Bank Name is required" : ""}
          />
          <TextField
            margin="dense"
            label="Bank Number"
            name="account_number"
            value={bankDetails.account_number}
            onChange={handleChange}
            fullWidth  
                  error={errors.account_number} // Show red border if there's an error
            helperText={errors.account_number ? "Bank Number is required" : ""}
          />
          <TextField
            margin="dense"
            label="IFSC"
            name="ifsc_code"
            value={bankDetails.ifsc_code}
            onChange={handleIFSCChange}
            fullWidth
            error={errors.ifsc_code} // Show red border if there's an error
            helperText={errors.ifsc_code ? "IFSC Code is required" : ""}
          />
          <TextField
            margin="dense"
            label="Account Holder Name"
            name="account_holder_name"
            value={bankDetails.account_holder_name}
            onChange={handleChange}
            fullWidth
            error={errors.account_holder_name} // Show red border if there's an error
            helperText={errors.account_holder_name ? "Account Holder Name is required" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSub} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
     
    </div>
  );
}

export default CreateSupplierMaster;
