import React, { Component } from "react";
import classes from "./UserMgmt.module.css";
import axios from "../../../axios-order";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/inputField";
import SelectField from "../../../components/ui/select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../components/appBar/appBar";
import Footer from "../../../components/footer/footer";
import { TablePagination } from "@material-ui/core";
import BoxWapper from "../../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "../../../HOC/layout/layout.css";

import RightDrawer from "../../../components/ui/RightDrawer";

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class UserMgmt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("user_id"),
      addDrawer: false,
      editDrawer: false,
      showDrawer: false,
      userOriginalData: null,
      userData: [],
      showUserData: null,
      addUserData: {},
      user_role: 2,
      status: 1,
      editData: {
        user_id: "",
        user_name: "",
        user_contact_number: "",
        user_email_id: "",
        status: "",
        user_role: "",
      },
      // pagination state
      page: 0,
      rowsPerPage: 5,
      rowCount: 0,
      searchInput: "",
      loading: true,
    };
  }

  // table pagination fns
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getUsers(event, this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.getUsers(event, this.state.searchInput);
    });
  };

  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.getUsers(event, this.state.searchInput);
      }
    );
  };

  // ONLOAD API CALL
  componentDidMount = () => {
    this.getUsers();
  };

  // all list of user api---
  getUsers = (event, serachIn) => {
    const finalData = {
      user_id: this.state.user_id,
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      search_text: serachIn,
    };

    axios
      .post(`/get_list_users`, finalData)
      .then((res) => {
        if (res.data.records) {
          this.setState({
            userData: res.data.records,
            userOriginalData: res.data.records,
            rowCount: res.data.row_count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };
  // add api call---
  addUserHandler = () => {
    toast.configure();
    axios
      .post("/post_user_data", this.state.addUserData)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.setState({ addDrawer: false });
        this.getUsers();
        this.setState((prevState) => ({
          addUserData: {
            ...prevState.addUserData,
            user_name: "",
            user_email_id: "",
            user_contact_number: "",
            user_role: "",
            status: "",
            user_password: "",
          },
        }));
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ addDrawer: false });
      });
  };
  // edit api---
  editUserHandler = () => {
    toast.configure();
    axios
      .put(`/put_user_data/${this.state.editData.user_id}`, this.state.editData)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.getUsers();
        this.setState({ editDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ editDrawer: false });
      });
  };

  // drawer toggels---
  addToggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ addDrawer: true });
  };

  editToggleDrawer = (
    user_id,
    user_name,
    user_email_id,
    user_contact_number,
    user_role,
    status
  ) => {
    this.setState({ editDrawer: true });
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData, // keep all other key-value pairs
        user_id: user_id,
        user_name: user_name,
        user_email_id: user_email_id,
        user_contact_number: user_contact_number,
        user_role: user_role,
        status: status,
      },
    }));
  };
  // show api call---
  showToggleDrawer = (user_id) => {
    axios
      .get(`/get_user_data/${user_id}`)
      .then((res) => {
        this.setState({ showUserData: res.data.records });
        this.setState({ showDrawer: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // close drawer---
  addToggleDrawerClose = (event) => {
    this.setState({ addDrawer: false, addUserData: {} });
  };
  editToggleDrawerClose = (event) => {
    this.setState({ editDrawer: false });
  };
  showToggleDrawerClose = (event) => {
    this.setState({ showDrawer: false });
  };

  // Handle onChange create form---
  handleInputChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        [name]: String(value),
      },
    }));
  };

  handleEditInputChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        [name]: String(value),
      },
    }));
  };

  // user table---
  userTableHeader() {
    const tableHeader = [
      "name",
      "email id",
      "contact no",
      "role",
      "status",
      "action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  userTableData() {
    if (this.state.userData.length > 0) {
      return this.state.userData.map((tableData, index) => {
        const {
          user_id,
          user_name,
          user_email_id,
          user_contact_number,
          user_role,
          status,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{user_name}</td>
            <td>{user_email_id}</td>
            <td>{user_contact_number}</td>
            <td style={{ textTransform: "capitalize" }}>{user_role}</td>
            <td>{status}</td>
            <td>
              <Button
                style={{ minWidth: "0px", color: "#63acf7", margin: "0px 4px" }}
                size="small"
                onClick={() => this.showToggleDrawer(user_id)}
              >
                <VisibilityOutlinedIcon style={{ fontSize: "20px" }} />
              </Button>
              |
              <Button
                style={{ minWidth: "0px", color: "#3b3b6b", margin: "0px 4px" }}
                size="small"
                onClick={() =>
                  this.editToggleDrawer(
                    user_id,
                    user_name,
                    user_email_id,
                    user_contact_number,
                    user_role,
                    status
                  )
                }
              >
                <EditOutlinedIcon style={{ fontSize: "20px" }} />
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.userData.length === 0 && this.state.loading) {
      return (
        <tr>
          <td colspan={8}>Loading.Please wait...</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  backHanlder = () => {
    this.props.history.push(`/home`);
  };

  render() {
    return (
      <>
        <Appbar />
        <div className={classes.userMgmt}>
          <BoxWapper
            boxTitle="User Management"
            // search input
            enableSearchInput={this.state.loading === false ? true : ""}
            searchInputPlaceholder="Search..."
            searchInputOnchange={this.handleSearchInputChange}
            // back button
            backButtonForZohoEnable={true}
            backButton={this.backHanlder}
            // add button
            enableAddButton={true}
            addButtonLabel="Add User"
            addButtonHandlerFn={this.addToggleDrawer}
          >
            <div className={classes.responsiveTable}>
              <table className={classes.customeTable}>
                <tbody>
                  <tr>{this.userTableHeader()}</tr>
                  {this.userTableData()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={this.state.rowCount} //total row count
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </BoxWapper>

          {/* Add user */}
          <RightDrawer
            drawerName="Add User"
            open={this.state.addDrawer}
            onClose={this.toggleDrawerClose}
          >
            <ValidatorForm
              ref="form"
              onSubmit={this.addUserHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                textLabel="User Name"
                inputType="text"
                name="user_name"
                value={this.state.addUserData.user_name ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleInputChange}
              />
              <InputField
                textLabel="Email Id"
                inputType="text"
                name="user_email_id"
                value={this.state.addUserData.user_email_id ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleInputChange}
              />
              <InputField
                textLabel="Contact No"
                inputType="text"
                name="user_contact_number"
                value={this.state.addUserData.user_contact_number}
                requiredField="*"
                validator={["required", "matchRegexp:^[0-9]{10}$"]}
                errorMsg={["This field is required", "Invalid contact number"]}
                textOnchangeFunction={this.handleInputChange}
              />
              <SelectField
                choice_id="role"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="role"
                SelectLabel="Role"
                name="user_role"
                value={this.state.addUserData.user_role ?? ""}
                textOnchangeFunction={this.handleInputChange}
                choices={[
                  {
                    id: 0,
                    role: "ADMIN",
                  },
                  {
                    id: 1,
                    role: "CHAMP",
                  },
                  {
                    id: 2,
                    role: "USER",
                  },
                  {
                    id: 3,
                    role: "LEADERSHIP",
                  },
                ]}
              />
              <SelectField
                choice_id="status"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="status"
                SelectLabel="Status"
                name="status"
                value={this.state.addUserData.status ?? ""}
                textOnchangeFunction={this.handleInputChange}
                choices={[
                  {
                    id: 1,
                    status: "Inactive",
                  },
                  {
                    id: 0,
                    status: "Active",
                  },
                ]}
              />
              <InputField
                textLabel="Password"
                inputType="password"
                name="user_password"
                value={this.state.addUserData.user_password ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleInputChange}
              />

              <div className="mt-20">
                <button className="button-primary" type="submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={this.addToggleDrawerClose}
                >
                  cancel
                </button>
              </div>
            </ValidatorForm>
          </RightDrawer>

          {/* Edit user */}
          <RightDrawer
            drawerName="Edit User"
            open={this.state.editDrawer}
            onClose={this.toggleEditClose}
          >
            <ValidatorForm
              ref="form"
              onSubmit={this.editUserHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                textLabel="Name"
                inputType="text"
                name="user_name"
                value={this.state.editData.user_name}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                textLabel="Email Id"
                inputType="text"
                inputProps={{ readOnly: true }}
                name="user_email_id"
                value={this.state.editData.user_email_id}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                textLabel="Contact Number"
                inputType="text"
                name="user_contact_number"
                value={this.state.editData.user_contact_number}
                requiredField="*"
                validator={["required", "matchRegexp:^[0-9]{10}$"]}
                errorMsg={["This field is required", "Invalid contact number"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <SelectField
                choice_id="user_role"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="user_role"
                SelectLabel="Role"
                name="user_role"
                value={this.state.editData.user_role}
                textOnchangeFunction={this.handleEditInputChange}
                choices={[
                  {
                    id: 0,
                    user_role: "ADMIN",
                  },
                  {
                    id: 1,
                    user_role: "CHAMP",
                  },
                  {
                    id: 2,
                    user_role: "USER",
                  },
                  {
                    id: 3,
                    user_role: "LEADERSHIP",
                  },
                ]}
              />
              <SelectField
                choice_id="status"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="status"
                SelectLabel="Status"
                name="status"
                value={this.state.editData.status}
                textOnchangeFunction={this.handleEditInputChange}
                choices={[
                  {
                    id: 1,
                    status: "Inactive",
                  },
                  {
                    id: 0,
                    status: "Active",
                  },
                ]}
              />

              <div className="mt-20">
                <button className="button-primary" type="submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={this.editToggleDrawerClose}
                >
                  cancel
                </button>
              </div>
            </ValidatorForm>
          </RightDrawer>

          {/* Show user details*/}
          <RightDrawer
            drawerName="User Details"
            open={this.state.showDrawer}
            onClose={this.toggleEditClose}
          >
            {this.state.showUserData && (
              <>
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="User ID"
                  defaultValue={this.state.showUserData.user_id}
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="User Name"
                  defaultValue={this.state.showUserData.user_name}
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="User Email ID"
                  defaultValue={this.state.showUserData.user_email_id}
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="user contact number"
                  defaultValue={this.state.showUserData.user_contact_number}
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="User Role"
                  defaultValue={this.state.showUserData.user_role}
                />
                <TextField
                  className={classes.showTextField}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  label="User Status"
                  defaultValue={this.state.showUserData.status}
                />

                <button
                  type="button"
                  className="button-outlined-secondary mt-20"
                  onClick={this.showToggleDrawerClose}
                >
                  Close
                </button>
              </>
            )}
          </RightDrawer>
        </div>

        <div>
          <Footer />
        </div>
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(UserMgmt);
