import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Paper, Typography, Box, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Sector } from 'recharts';

import LoadingButton from '@mui/lab/LoadingButton';

import CircularProgress from "@mui/material/CircularProgress";
import axios from '../../../axios-order';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import FilterListIcon from '@mui/icons-material/FilterList';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import '../../Dashboards/./sales/ageingSummary/dashboard.css';
import TablePagination from "@mui/material/TablePagination";
import DashboardCards from './DashBoard';
import Top5CustomerChart from './top_5cutomer_bar_chart'
import ColumnChart from '../../../components/charts/barchart';


const fetchDateOptions = () => {
    const currentDate = new Date();
    const options = [];
    options.push(`${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`)
    for (let i = 1; i <= 11; i++) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() - i;
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const formattedDate = `${lastDayOfMonth.getFullYear()}-${('0' + (lastDayOfMonth.getMonth() + 1)).slice(-2)}-${('0' + lastDayOfMonth.getDate()).slice(-2)}`;
        options.push(formattedDate);
    }
    return options

};

const colors = ["#4318FF", "#6AD2FF", "#607d8b"];

const RADIAN = Math.PI / 180;

const AgeingPaybleReport = () => {
    let history = useHistory();

    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [graphData, setGraphData] = useState([])
    const [notYetDueGraphData, setNotYetDueGraphData] = useState([])
    const [dispalTable, setDisplyTable] = useState(false)
    const [tableData, setTableData] = useState([])
    const [activeIndex, SetActiveIndex] = useState(0)
    const [ageingRecivableData, setAgeingRecivableData] = useState({})
    const [top5CustomerData, setTop5CustomerData] = useState([])
    const [monthlyTrenChartData, setMonthlyTrenChartData] = useState([])
    const [selectedAsOfDate, setSelectedAsOfDate] = useState(fetchDateOptions()[0]);
    const [selectedGSTInvoiceType, setSelectedGSTInvoiceType] = useState('');
    const [selectedAccountManager, setSelectedAccountManager] = useState('');
    const [dateOptions, setDateOptions] = useState(fetchDateOptions());
    const [accountManagers, setAccountManagers] = useState([]);
    const [vendorDetails, setVendorDetails] = useState([])
    const [billDetails, setBillDetails] = useState([])
    const [selectedDeatils, setSelectedDetails] = useState(0);
    const [selectedTopCustomerData, setSelectedTopCustomerData] = useState('5')
    const [topCustomerData, setTopCustomerData] = useState({})
    const [selectedTopCustomeType, setSelectedTopCustomeType] = useState('agst_ref')
    const [vendorTotalElements, setVendorTotalElements] = useState(0);
    const [billTotalElements, setbillTotalElements] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedEntity, setSelectedEntity] = useState(localStorage.getItem("business_entity_id"))
    const [entityData, setEntityData] = useState([])
    const [haveMultiEntity, setHaveMultiEntity] = useState(false)
    const [exportButtonLaoding, setExportButtonLaoding] = useState(false)

    const handleAsOfDateChange = (newValue) => {
        setSelectedAsOfDate(newValue);
        console.log('Selected As of Date:', newValue);
    };

    const handleGSTInvoiceTypeChange = (event) => {
        setSelectedGSTInvoiceType(event.target.value);
    };

    const handleAccountManagerChange = (event) => {
        setSelectedAccountManager(event.target.value);
    };

    const prevFilters = useRef({
        selectedAsOfDate,
        selectedGSTInvoiceType,
        selectedAccountManager,
        selectedEntity
    });

    const isFirstRender = useRef(true);

    useEffect(() => {
        const filtersChanged =
            selectedAsOfDate !== prevFilters.current.selectedAsOfDate ||
            selectedGSTInvoiceType !== prevFilters.current.selectedGSTInvoiceType ||
            selectedAccountManager !== prevFilters.current.selectedAccountManager ||
            selectedEntity !== prevFilters.current.selectedEntity;

        if (isFirstRender.current || filtersChanged) {
            setLoading(true);
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
        }

        prevFilters.current = {
            selectedAsOfDate,
            selectedGSTInvoiceType,
            selectedAccountManager,
            selectedEntity
        };
        getAgeingPaybleReportDetails()

    }, [selectedAsOfDate, selectedGSTInvoiceType, selectedAccountManager, page, rowsPerPage, selectedEntity])

    useEffect(() => {
        getReletedEntitiesData()
    }, [])

    const getReletedEntitiesData = () => {
        axios.get(`/get_related_entites_for_report/${localStorage.getItem("business_entity_id")}`)
            .then((res) => {
                const records = res.data.records
                if (records.length > 1) {
                    setEntityData(records)
                    setHaveMultiEntity(true)
                }

            }).catch((err) => {

            })
    }


    const getAgeingPaybleReportDetails = () => {

        let payLoadData = {
            'business_entity_id': selectedEntity,
            'filter_date': selectedAsOfDate,
            'singl_report': true,
            'page_size': rowsPerPage,
            'page': page
        }

        if (selectedGSTInvoiceType) {
            payLoadData['gst_invoice_type'] = selectedGSTInvoiceType
        }

        if (selectedAccountManager) {
            payLoadData['acc_manger'] = selectedAccountManager
        }

        axios.post('/get_aging_paybles_data', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    setDashboardData(res.data.ageing_payble_data.card_details)
                    setGraphData(res.data.ageing_payble_data.overdue_by_age_summary)
                    setAgeingRecivableData(res.data.ageing_payble_data)
                    setTop5CustomerData(res.data.ageing_payble_data.top_5_customers_by_outstanding_amount.top_5_customer_data)
                    setTopCustomerData(res.data.ageing_payble_data.top_5_customers_by_outstanding_amount)
                    setMonthlyTrenChartData(res.data.ageing_payble_data.monthly_trend_chart_for_not_yet_due_and_overdue_amount)
                    setLoading(false)
                    setNotYetDueGraphData(res.data.ageing_payble_data.not_yet_due_by_age_summary)
                    setVendorDetails(res.data.vendor_details)
                    setVendorTotalElements(res.data.pagination_vendor.total_records || 0)
                    setBillDetails(res.data.bill_details)
                    setbillTotalElements(res.data.pagination_bill.total_records || 0)
                }

            })
            .catch((err) => {
                setLoading(false)
            })

    }

    const onPieEnter = (_, index) => {
        SetActiveIndex(index)
    };

    const renderPeiGraph = () => {


        const renderActiveShape = (props) => {
            const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const mx = cx + (outerRadius + 10) * cos;
            const my = cy + (outerRadius + 10) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;

            return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">
                        {`Amount: ₹${value.toFixed(2)}`}
                    </text>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>
                </g>
            );
        };

        if (!graphData || graphData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover', marginBottom: '10px' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>No Graph Available</Typography>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={graphData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total_amount"
                        onMouseEnter={onPieEnter}
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );


    };

    const renderPeiGraphAp = () => {
        const renderActiveShape = (props) => {
            const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const mx = cx + (outerRadius + 10) * cos;
            const my = cy + (outerRadius + 10) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;

            return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">
                        {`Amount: ₹${value.toFixed(2)}`}
                    </text>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>
                </g>
            );
        };

        if (!notYetDueGraphData || notYetDueGraphData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }
        return (
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={notYetDueGraphData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total_amount"
                        onMouseEnter={onPieEnter}
                    >
                        {notYetDueGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );
    }

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const handleDownloadReport = (item) => {
        setExportButtonLaoding(true)
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `Ageing Payble Report.xlsx`;
        const payLoadData = {
            business_entity_id: localStorage.getItem("business_entity_id"),
            filter_date: selectedAsOfDate,
            master_type: "purchases",
            child_type: selectedDeatils === 0 ? "vendor_details" : "bill_details",
            singl_report: true

        }
        if (selectedGSTInvoiceType) {
            payLoadData['gst_invoice_type'] = selectedGSTInvoiceType
        }

        if (selectedAccountManager) {
            payLoadData['acc_manger'] = selectedAccountManager
        }
        axios
            .post(`/aging_recivable_excel_report`, payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res.data.file, filename);
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setExportButtonLaoding(false)
            });;
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const renderTable = () => {
        const rowsPerPageOptions = [25, 100, 200, 300, 500];
        return (
            <>
                <Paper sx={{ p: 2, borderTop: '5px solid #36A2EB', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>{selectedDeatils !== 0 ? "BILL DETAILS" : "VENDOR DETAILS"}</Typography>
                        <LoadingButton
                            variant="outlined"
                            color='success'
                            style={{ height: '35px' }}
                            loading={exportButtonLaoding}
                            onClick={handleDownloadReport}
                            endIcon={<img
                                style={{ height: 20, width: 20, objectFit: 'contain' }}
                                src='https://img.icons8.com/?size=48&id=117561&format=png'
                                alt="Export Icon" />}
                        >
                            Export
                        </LoadingButton>
                    </div>
                    <Tabs
                        onChange={handleChange}
                        value={selectedDeatils}
                        aria-label="Tabs where selection follows focus"
                        selectionFollowsFocus
                        textColor="secondary"
                        indicatorColor="secondary"
                        style={{ marginBottom: '1.5%' }}
                    >
                        <Tab label="VENDOR DETAILS" />
                        <Tab label="BILL DETAILS" />
                    </Tabs>
                    <div class="CustomerTable">
                        <div className="responsive-table" style={{ minHeight: '350px' }}>

                            {selectedDeatils !== 0 ? renderBillDetailsTable() : renderVendorDetailsTable()}
                        </div>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={selectedDeatils !== 0 ? billTotalElements : vendorTotalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </>
        );
    };

    const renderVendorDetailsTable = () => {
        return (


            <table className="custome-long-table">
                <tbody>
                    <tr>
                        <th>VENDOR NAME</th>
                        <th>VENDOR GSTIN</th>
                        <th>VENDOR PAN</th>
                        <th>ERP VENDOR CODE</th>

                        <th>GST REGISTRATION TYPE</th>
                        <th>AS OF DATE</th>
                        <th>NO OF BILL DUE</th>
                        <th>NO OF BILL NOT DUE</th>
                        <th>EXCESS</th>
                        <th>TOTAL OUTSTANDING</th>
                        <th>DUE AMOUNT</th>
                        <th>NOT DUE AMOUNT</th>

                    </tr>
                    {(vendorDetails && vendorDetails.length >= 1) ? vendorDetails.map((item, index) => (
                        <tr key={index}>
                            <td>{item.vendor_name}</td>
                            <td>{item.vendor_gstin}</td>
                            <td>{item.vendor_pan}</td>
                            <td>{item.erp_vendor_code}</td>
                            <td>{item.gst_registration_type}</td>
                            <td>{item.as_of_date}</td>
                            <td>{item.bills_due}</td>
                            <td>{item.bills_not_yet_due}</td>
                            <td>{item.Excess}</td>
                            <td>{item.total_outstanding_all}</td>
                            <td>{item.over_due}</td>
                            <td>{item.not_yet_due}</td>

                        </tr>
                    )) : (
                        <tr>
                            <td style={{ color: "red" }} colspan={14}>
                                No data found!
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>



        )
    }

    const handleChange = (event, newValue) => {
        setSelectedDetails(newValue);
        setPage(0);
        setRowsPerPage(25);
    };

    const renderBillDetailsTable = () => {
        return (


            <table className="custome-long-table">
                <tbody>
                    <tr>
                        <th>VENDOR NAME</th>
                        <th>VENDOR GSTIN</th>
                        <th>VENDOR PAN</th>
                        <th>ERP VENDOR CODE</th>
                        <th>BILL NUMBER</th>
                        <th>BILL DATE</th>
                        <th>AS OF DATE</th>
                        <th>DUE DATE</th>
                        <th>TOTAL AMOUNT</th>
                        <th>TAXABLE VALUE</th>
                        <th>NON TAXABLE VALUE</th>
                        <th>AMOUNT PAID</th>
                        <th>TDS AMOUNT</th>
                        <th>AMOUNT OUTSTANDING</th>
                        <th>EXCESS AMOUNT</th>
                        <th>DAYS PAST DUE DATE</th>
                        <th>BUCKET</th>
                    </tr>
                    {(billDetails && billDetails.length >= 1) ? billDetails.map((item, index) => (
                        <tr key={index}>
                            <td>{item.vendor_name}</td>
                            <td>{item.vendor_gstin}</td>
                            <td>{item.vendor_pan}</td>
                            <td>{item.erp_vendor_code}</td>
                            <td>{item.invoice_no}</td>
                            {/* <td>{item.cust_registration_type}</td> */}
                            <td>{item.invoice_date}</td>
                            <td>{item.as_of_date}</td>
                            <td>{item.due_date}</td>
                            <td>{item.total_amount}</td>
                            <td>{item.taxable_value}</td>
                            <td>{item.non_taxable_adj}</td>
                            <td>{item.total_amount}</td>
                            <td>{item.tds_amount}</td>
                            <td>{item.total_outstanding}</td>
                            <td>{item.Excess}</td>
                            <td>{item.due_days}</td>

                            <td>{item.over_due_bucket ? item.over_due_bucket : item.not_yet_due_bucket}</td>
                            {/* <th>{item.Advance}</th>
                            <th>{item.Excess}</th> */}

                        </tr>
                    )) : (
                        <tr>
                            <td style={{ color: "red" }} colspan={14}>
                                No data found!
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>



        )
    }



    const renderBarChart = () => {
        if (!monthlyTrenChartData || monthlyTrenChartData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }

        const chartData = [
            {
                name: "Overdue",
                data: monthlyTrenChartData.map(item => item["Over due"] || 0),
            },
            {
                name: "Not Yet Due",
                data: monthlyTrenChartData.map(item => item["Not yet due"] || 0),
            }
        ];

        const categories = monthlyTrenChartData.map(item => item.period);

        return (
            <ColumnChart
                chartData={chartData}
                categories={categories}
            />
        );
    };

    const resetFilters = () => {
        setSelectedGSTInvoiceType('')
        setSelectedAccountManager('')
        setSelectedAsOfDate(fetchDateOptions()[0])
    }



    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2%' }}>
                <Grid container alignItems="center" spacing={3}>
                    {/* Dropdown on the left */}
                    <Grid item xs={3} md={2.5} >
                        {haveMultiEntity && <FormControl variant="outlined" fullWidth>
                            <InputLabel id="entity-select-label">Select Entity</InputLabel>
                            <Select
                                style={{ height: '40px' }}
                                labelId="entity-select-label"
                                value={selectedEntity}
                                onChange={(event) => setSelectedEntity(event.target.value)}
                                label="Select Entity"
                            >
                                {entityData.map((entity) => (
                                    <MenuItem key={entity.business_entity_id} value={entity.business_entity_id}>
                                        {entity.entity_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                    </Grid>

                    {/* Centered Heading */}
                    <Grid item xs={12} md={5.5} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'center',
                                letterSpacing: '0.04rem',
                                fontFamily: `'Montserrat', sans-serif`,
                                textTransform: 'uppercase',
                            }}
                        >
                            <span style={{ fontWeight: 'bold', color: '#1D3557' }}>Ageing Payble</span>
                            <span style={{ fontWeight: 'normal', color: "#212121" }}> Dashboard</span>
                            <AnalyticsIcon style={{ marginLeft: '10px', color: '#E76F51', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                        </Typography>
                    </Grid>

                    {/* Buttons on the right */}
                    <Grid item xs={6} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => history.push('ageing_report_dashboard')} variant="contained" startIcon={<ArrowBackIcon />}>
                            BACK
                        </Button>
                        <Button variant="contained" onClick={resetFilters} color="secondary" endIcon={<FilterAltOffIcon />} style={{ marginLeft: '16px' }}>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000080', marginBottom: '1%' }}>FILTERS<FilterListIcon style={{ marginBottom: '4px' }} /></Typography>
            <Paper elevation={1} style={{ padding: 20, backgroundColor: '#FFFFFF', marginBottom: '4%' }}>
                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={12} sm={4}>
                        <label>
                            As of Date <span style={{ color: 'red' }}>*:</span>
                        </label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={dateOptions}
                            value={selectedAsOfDate}
                            onChange={(event, newValue) => {
                                handleAsOfDateChange(newValue);
                            }}
                            disableClearable={true}
                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                        />
                    </Grid>

                    {/* GST Invoice Type Filter */}
                    <Grid item xs={12} sm={4}>
                        <label> GST Invoice Type </label>
                        <FormControl fullWidth variant="outlined">
                            <Select
                                value={selectedGSTInvoiceType}
                                onChange={handleGSTInvoiceTypeChange}
                                label="GST Invoice Type"
                                style={{ height: 40 }}
                            >
                                <MenuItem value="Export">Export</MenuItem>
                                <MenuItem value="Regular B2B">Regular B2B</MenuItem>
                                <MenuItem value="Unregistered">Unregistered</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Account Manager Filter */}
                    <Grid item xs={12} sm={4}>
                        <label> Account Manager: </label>
                        <FormControl fullWidth variant="outlined">
                            <Select
                                value={selectedAccountManager}
                                onChange={handleAccountManagerChange}
                                label="Account Manager"
                                style={{ height: 40 }}
                            >
                                {/* Populate options dynamically from backend */}
                                {accountManagers && accountManagers.map((manager) => (
                                    <MenuItem key={manager.id} value={manager.id}>
                                        {manager.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            {!loading ? (<Grid container lg={12} spacing={2} >


                <Grid lg={12} container
                    md={6}
                    direction="row"
                    mb={2}
                    ml={1}
                    spacing={1.8}>

                    <DashboardCards
                        dashboardData={dashboardData}
                    />

                </Grid>
                <Grid item xs={12} md={isFullScreen ? 12 : 6}>
                    <Paper sx={{ p: 2, borderTop: '5px solid #36A2EB', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold' }}>OVERDUE AGE SUMMARY</Typography>




                        </div>

                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderPeiGraph()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={isFullScreen ? 12 : 6}>
                    <Paper sx={{ p: 2, borderTop: '5px solid #2b5876', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold' }}>NOT YET DUE AGE SUMMARY</Typography>

                        </div>

                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderPeiGraphAp()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Top5CustomerChart
                    top5CustomerData={top5CustomerData}
                    selectedReportTypeForTop5={"ageing_recivable"}
                    singleReport={true}
                // onChngeTop5CustomersChart={onChngeTop5CustomersChart}
                />

                <Grid item xs={12} md={isFullScreen ? 12 : 12}>
                    <Paper sx={{ p: 2, borderTop: '5px solid #4a62c4', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold' }}>MONTHLY TREND CHART FOR NOT YET DUE AND DUE AMOUNT.</Typography>



                        </div>

                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderBarChart()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={isFullScreen ? 12 : 12}>
                    <>

                        {renderTable()}
                    </>
                </Grid>


            </Grid>)

                : <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>}
        </Container>
    )
}

export default AgeingPaybleReport