// @flow
import React, { useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../components/ui/inputField";
import ValidatedDatePicker from "../../../components/ui/DatePicker";
import axios from "../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Add(props) {
  const { cancelEditForm, editFormData, updateGetApi } = props;
  // const form = useRef("form")
  const [editTdsData, setEditTdsData] = useState({});

  useMemo(() => {
    setEditTdsData(editFormData);
  }, [editFormData]);

  const editInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditTdsData({
      ...editTdsData,
      [name]: value,
    });
  };

  const handleFromDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setEditTdsData({
      ...editTdsData,
      valid_from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const handleToDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setEditTdsData({
      ...editTdsData,
      valid_to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const finalEditData = {
    org_gstin: `${localStorage.getItem("gst")}`,
    records: [editTdsData],
  };

  const editFormsubmitHandler = (e) => {
    e.preventDefault();
    toast.configure();
    axios
      .put("/tds/exemption/edit", finalEditData)
      .then((res) => {
        if (res.data.status === true) {
          cancelEditForm();
          updateGetApi();
          toast.success(res.data.message, { autoClose: 2000 });
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ValidatorForm
      useRef="form"
      onSubmit={editFormsubmitHandler}
      onError={(errors) => console.log(errors)}
    >
      {Object.keys(editTdsData).length >= 1 && (
        <>
          <FormControl>
            {/* <FormLabel id="demo-controlled-radio-buttons-group">TDS</FormLabel> */}
            <label>
              TDS Type<span style={{ color: "red" }}>*</span>
            </label>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="TDSType"
              value={editTdsData.TDSType}
              onChange={editInputChangeHandler}
            >
              <FormControlLabel
                value="Sales"
                control={<Radio disabled />}
                label="Sales"
              />
              <FormControlLabel
                value="Purchase"
                control={<Radio disabled />}
                label="Purchase"
              />
            </RadioGroup>
          </FormControl>
          <InputField
            textLabel="Legal Name"
            inputType="text"
            name="vendor_legal_name"
            value={editTdsData.vendor_legal_name}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            textOnchangeFunction={editInputChangeHandler}
          />

          <InputField
            textLabel="PAN"
            inputType="text"
            name="pan"
            value={editTdsData.pan}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            inputProps={{ readOnly: true }}
            textOnchangeFunction={editInputChangeHandler}
          />

          <div style={{ marginTop: "20px" }}>
            <label>
              From Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              name="valid_from_date"
              value={editTdsData.valid_from_date ?? ""}
              onChange={(v) => handleFromDateChange(v)}
              validators={["required"]}
              errorMessages={["This field is required"]}
              disabled={true}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <label>
              To Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              name="to_date"
              value={editTdsData.valid_to_date ?? ""}
              onChange={(v) => handleToDateChange(v)}
              validators={["required"]}
              errorMessages={["This field is required"]}
              disabled={true}
            />
          </div>

          <InputField
            textLabel="Section"
            inputType="text"
            name="tds_section"
            value={editTdsData.tds_section}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            inputProps={{ readOnly: true }}
            textOnchangeFunction={editInputChangeHandler}
          />

          <InputField
            textLabel="Description"
            inputType="text"
            name="description"
            value={editTdsData.description}
            // requiredField="*"
            // validator={["required"]}
            // errorMsg={["This field is required"]}
            textOnchangeFunction={editInputChangeHandler}
          />
        </>
      )}
      {/* .......Button........ */}
      <div className="mt-20">
        <button className="button-primary" type="submit">
          Submit
        </button>
        <button
          className="button-outlined-secondary"
          type="button"
          onClick={cancelEditForm}
        >
          cancel
        </button>
      </div>
    </ValidatorForm>
  );
}
