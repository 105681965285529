import React, { Component } from 'react';
import classes from './ARCCollectionAgency.module.css';
import axios from '../../../../axios-order';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '../../../../components/ui/Cards';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DataTables from 'material-ui-datatables';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SellerCollectionAgency from '../../../../asserts/templates/seller_collection_agency.xlsx';
import ClearIcon from '@material-ui/icons/Clear';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputField from '../../../../components/ui/inputField';
import Drawer from '@material-ui/core/Drawer';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ValidatedDatePicker from '../../../../components/ui/DatePicker';
import { TablePagination } from '@material-ui/core';
import BoxWrapper from '../../../../components/ui/boxWrapper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  // if (typeof value === "undefined") return false;
  if (value) {
    let old_date = String(value).split('-');
    return old_date[2] + '/' + old_date[1] + '/' + old_date[0];
  }
};

class CollectionAgency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      read_only: '',
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: 'GSTIN',
          render: (name, all) => {
            return all['record']['GSTIN'];
          },
        },
        {
          label: 'Invoice Number',
          render: (name, all) => {
            return all['record']['Subscription_Invoice_no'];
          },
        },
        { key: 'row', label: 'Row #' },
        {
          label: 'Errors',
          render: (name, all) => {
            return Object.keys(all['errors_in']).map((i, v) => {
              return (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: 'red' }}>
                    {all['errors_in'][i].join(',')}
                  </span>
                </div>
              );
            });
          },
        },
      ],
      sellerCustomerData: [],
      error: null,

      ArcCollectionAgencyData: [],
      OriginalArcCollectionAgencyData: [],
      showUploadData: false,
      loading: false,

      add_DrawerOpen: false,
      addCollectionAgencyData: {},

      edit_DrawerOpen: false,
      editCollectionAgencyData: {},

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: '',

      // upload history table section
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.CollectionAgencyGetApiData(event, this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.CollectionAgencyGetApiData(event, this.state.searchInput);
    });
  };

  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.CollectionAgencyGetApiData(event, this.state.searchInput);
      }
    );
  };

  CollectionAgencyGetApiData = (event, serachIn) => {
    axios
      .post(`/arc/seller_collection_agency`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_txt: serachIn,
        business_entity_id: localStorage.getItem('business_entity_id'),
      })
      .then((res) => {
        this.setState({
          ArcCollectionAgencyData: res.data.records,
          OriginalArcCollectionAgencyData: res.data.records,
          rowCount: res.data.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          'business_entity_id'
        )}/${6}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.CollectionAgencyGetApiData();
    this.getHistoryTableUploadData();
  }

  fileUploader = (event) => {
    this.setState({ loading: true });
    // toast.configure()
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);

    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/sellercollectionagency`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem('business_entity_id')
          ),
        })
        .then((data) => {
          dis.getHistoryTableUploadData();
        })
        .catch((error) => {
          // toast.error(error, { autoClose:5000, progress: 0 })
        });
    };
  };

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = () => {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Seller Collection Agency.xlsx`;

    axios
      .post('/seller_collection_agency_extract/download', {
        data: this.state.ArcCollectionAgencyData,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.CollectionAgencyGetApiData();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerCollectionAgency}
        download={'seller_collection_agency.xlsx'}
        style={{ textDecoration: 'none' }}
      >
        {' '}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: '20px' }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: '20px' }} />
        UPLOAD TEMPLATE{' '}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>
      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: 'right' }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: '20px' }} />
        Refresh
      </Button>
      {/* <CreateButton label="add Customer" basePath={basePath} /> */}
    </div>
  );

  //-------------------- Collection agency table--------------------------
  arc_CollectionAgency_TableHeader() {
    const tableHeader = [
      'agency name',
      'sub. Inv#',
      'sub. fees',
      'sub. fees GST',
      'sub. fees TDS',
      'sub. from date',
      'sub. to date',
      'create date',
      'modified Date',
      'Action',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_CollectionAgency__data() {
    const editCollectionAgency = (
      agency_name,
      subscription_fees,
      subscription_fees_TDS,
      subscription_fees_GST,
      subscription_Invoice_no,
      subscription_from_date,
      subscription_to_date
    ) => {
      let subscriptionFromDate_yyyy_mm = dateFormat(subscription_from_date);
      let subscriptionToDate_yyyy_mm = dateFormat(subscription_to_date);

      this.setState({
        editCollectionAgencyData: {
          agencyName: agency_name,
          subscriptionFeesTds: subscription_fees_TDS,
          subscriptionFeesGst: subscription_fees_GST,
          subscriptionInvoiceNo: subscription_Invoice_no,
          subscriptionFromDate: subscription_from_date,
          subscriptionToDate: subscription_to_date,
          subscriptionFees: subscription_fees,
        },
        edit_DrawerOpen: true,
        subscriptionToDateDisplay: subscriptionToDate_yyyy_mm,
        subscriptionFromDateDisplay: subscriptionFromDate_yyyy_mm,
      });
    };

    if (this.state.ArcCollectionAgencyData.length >= 1) {
      return this.state.ArcCollectionAgencyData.map((tableData, index) => {
        const {
          agency_name,
          subscription_Invoice_no,
          subscription_fees,
          subscription_fees_TDS,
          subscription_fees_GST,
          subscription_from_date,
          subscription_to_date,
          create_date,
          modify_date,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{agency_name}</td>
            <td>{subscription_Invoice_no}</td>
            <td>{numberFormat(subscription_fees)}</td>
            <td>{numberFormat(subscription_fees_GST)}</td>

            <td> {numberFormat(subscription_fees_TDS)}</td>

            <td>{subscription_from_date}</td>
            <td>{subscription_to_date}</td>
            <td>{create_date}</td>
            <td>{modify_date}</td>
            <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editCollectionAgency(
                    agency_name,
                    subscription_fees,
                    subscription_fees_TDS,
                    subscription_fees_GST,
                    subscription_Invoice_no,
                    subscription_from_date,
                    subscription_to_date
                  )
                }
              >
                edit
              </Button>
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}
            colspan={10}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={10}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  //-------------------- Collection agency table End--------------------------
  // -------------------Colllection agency add -------------------------------
  // add collection agency [add button] drawer open handeler---
  addCollectionAgency_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // invoice date handler
  handleSubscriptionFromDateChange = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addCollectionAgencyData: {
          ...prevState.addCollectionAgencyData,
          subscriptionFromDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        subscriptionFromDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // invoice date handler
  handleSubscriptionToDateChange = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addCollectionAgencyData: {
          ...prevState.addCollectionAgencyData,
          subscriptionToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        subscriptionToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // add collection agency input field handler[number format]
  addCollectionAgency_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      addCollectionAgencyData: {
        ...prevState.addCollectionAgencyData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add collection agency inputfield handler---
  addCollectionAgency_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addCollectionAgencyData: {
        ...prevState.addCollectionAgencyData,
        [name]: String(value),
      },
    }));
  };

  // add collection agency add submit handler
  addCollectionAgency_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercollectionagency`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.addCollectionAgencyData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.CollectionAgencyGetApiData();
          this.setState({ add_DrawerOpen: false, addCollectionAgencyData: {} });
          toast.success('Collection agency added successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add collection drawer close handler---
  addCollectionAgency_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addCollectionAgencyData: {},
      subscriptionToDateDisplay: '',
      subscriptionFromDateDisplay: '',
    });
  };

  // -------------------Colllection agency add end----------------------------

  // -------------------Colllection agency edit -------------------------------
  // edit collection agency [edit button] drawer open handeler---
  editCollectionAgency_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // invoice date handler
  editSubscriptionFromDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editCollectionAgencyData: {
          ...prevState.editCollectionAgencyData,
          subscriptionFromDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        subscriptionFromDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // invoice date handler
  editSubscriptionToDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editCollectionAgencyData: {
          ...prevState.editCollectionAgencyData,
          subscriptionToDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(
            -2
          )}-${yy}`,
        },
        subscriptionToDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // edit collection agency input field handler[number format]
  editCollectionAgency_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      editCollectionAgencyData: {
        ...prevState.editCollectionAgencyData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit collection agency inputfield handler---
  editCollectionAgency_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editCollectionAgencyData: {
        ...prevState.editCollectionAgencyData,
        [name]: String(value),
      },
    }));
  };

  // edit collection agency edit submit handler
  editCollectionAgency_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercollectionagency`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.editCollectionAgencyData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
          // toast.error(res.data.record.details[0].errors_in.invalid_data[0], {
          //   autoClose: 2000,
          // });
        } else {
          this.CollectionAgencyGetApiData();
          this.setState({ edit_DrawerOpen: false });
          toast.success('Collection Agency updated successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        this.setState({ edit_DrawerOpen: false });
      });
  };

  // edit collection drawer close handler---
  editCollectionAgency_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false, editCollectionAgencyData: {} });
  };

  // -------------------Colllection agency edit end----------------------------

  closeUploadData = () => {
    this.setState({ showUploadData: false });
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({ showUploadData: true });
        let res = response.data.records.records;

        if (response.data.records.type === 'json') {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === 'Fail') {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    // const { push, ...props } = this.props;

    return (
      <React.Fragment>
        <div style={{ marginRight: '34px', marginBottom: '10px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/providerSelection">
              Home
            </Link>
            <Link color="inherit">ARC</Link>
            <Typography color="textPrimary">
              Upload Collection Agency Data
            </Typography>
          </Breadcrumbs>
        </div>

        {/* upload temp Button */}
        <div>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
          >
            upload{' '}
          </Button>
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper boxTitle="Upload History">
              <this.CollectionListActions />
              <div className={classes.tableResponisve}>
                <table className={classes.tableCss}>
                  <thead>
                    <tr>
                      <th>Last Upload Data & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === 'In Progress' ? (
                              <VisibilityIcon color="disabled" />
                            ) : item.status === 'Uploaded' ? (
                              <VisibilityIcon color="disabled" />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: 'red', textAlign: 'center' }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: 'none',
                          marginBottom: '0px',
                          padding: '0px',
                          marginTop: '0px',
                        }}
                      >
                        <li style={{ display: 'inline-block' }}>
                          Upload Details
                        </li>
                        <li style={{ display: 'inline-block', float: 'right' }}>
                          <ClearIcon
                            onClick={this.closeUploadData}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: '30px', marginRight: '30px' }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: '300',
                            margin: '20px 30px 0px 30px',
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: 'red' }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}

        {/* table  */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: 'none',
                      marginBottom: '5px',
                      padding: '0px',
                      marginTop: '0px',
                    }}
                  >
                    <li style={{ display: 'inline-block' }}>
                      Collection Agency Details
                    </li>
                    <li style={{ display: 'inline-block', float: 'right' }}>
                      <Button size="small" onClick={this.export_to_excel}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Button
                        style={{ marginTop: '35px' }}
                        size="small"
                        variant="outlined"
                        onClick={this.addCollectionAgency_drawerOpen}
                      >
                        Add Collection
                      </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          // textLabel="Search"
                          inputPlacholder="Search...."
                          inputType="text"
                          requiredField=" "
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ''}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_CollectionAgency_TableHeader()}</tr>
                      {this.arc_CollectionAgency__data()}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                  component="div"
                  count={this.state.rowCount} //total row count
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          onClose={this.addCollectionAgency_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addCollectionAgency_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={this.state.addCollectionAgencyData.agencyName ?? ''}
                textOnchangeFunction={this.addCollectionAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Subscription Invoice No"
                name="subscriptionInvoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.addCollectionAgencyData.subscriptionInvoiceNo ?? ''
                }
                textOnchangeFunction={this.addCollectionAgency_inputHandler}
              />

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyData.subscriptionFees
                      ? this.state.addCollectionAgencyData.subscriptionFees
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyData: {
                        ...prevState.addCollectionAgencyData,
                        subscriptionFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees GST<span style={{ color: 'red' }}>*</span>
                  :
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyData.subscriptionFeesGst
                      ? this.state.addCollectionAgencyData.subscriptionFeesGst
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyData: {
                        ...prevState.addCollectionAgencyData,
                        subscriptionFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees TDS<span style={{ color: 'red' }}>*</span>
                  :
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyData.subscriptionFeesTds
                      ? this.state.addCollectionAgencyData.subscriptionFeesTds
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyData: {
                        ...prevState.addCollectionAgencyData,
                        subscriptionFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  Subscription From Date
                  <span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="subscriptionFromDate"
                  value={this.state.subscriptionFromDateDisplay ?? ''}
                  onChange={(v) => this.handleSubscriptionFromDateChange(v)}
                  validators={['required']}
                  errorMessages={['Date is required']}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  Subscription To Date
                  <span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="subscriptionToDate"
                  value={this.state.subscriptionToDateDisplay ?? ''}
                  onChange={(v) => this.handleSubscriptionToDateChange(v)}
                  validators={['required']}
                  errorMessages={['Date is required']}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.addCollectionAgency_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}

        {/* edit add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.editCollectionAgency_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editCollectionAgency_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCollectionAgencyData.agencyName ?? ''}
                textOnchangeFunction={this.editCollectionAgency_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Subscription Invoice No"
                name="subscriptionInvoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyData.subscriptionInvoiceNo ??
                  ''
                }
                textOnchangeFunction={this.editCollectionAgency_inputHandler}
              />

              {/*     <InputField
                inputType="text"
                textLabel="Subscription Fees"
                name="subscriptionFees"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editCollectionAgencyData.subscriptionFees
                    ? numberFormat(
                        this.state.editCollectionAgencyData.subscriptionFees
                      )
                    : numberFormat(0)
                }
                textOnchangeFunction={
                  this.editCollectionAgency_inputNumberHandler
                }
              /> */}
              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  value={
                    this.state.editCollectionAgencyData.subscriptionFees
                      ? this.state.editCollectionAgencyData.subscriptionFees
                      : ''
                  }
                  style={{ paddingLeft: '10.5px' }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyData: {
                        ...prevState.editCollectionAgencyData,
                        subscriptionFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees GST<span style={{ color: 'red' }}>*</span>
                  :
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  value={
                    this.state.editCollectionAgencyData.subscriptionFeesGst
                      ? this.state.editCollectionAgencyData.subscriptionFeesGst
                      : ''
                  }
                  style={{ paddingLeft: '10.5px' }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyData: {
                        ...prevState.editCollectionAgencyData,
                        subscriptionFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Subscription Fees TDS<span style={{ color: 'red' }}>*</span>
                  :
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  value={
                    this.state.editCollectionAgencyData.subscriptionFeesTds
                      ? this.state.editCollectionAgencyData.subscriptionFeesTds
                      : ''
                  }
                  style={{ paddingLeft: '10.5px' }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyData: {
                        ...prevState.editCollectionAgencyData,
                        subscriptionFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <InputField
                inputType="text"
                textLabel=" Subscription From Date"
                name="subscriptionFromDate"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyData.subscriptionFromDate ?? ''
                }
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: '20px' }}>
                <label>
                  Subscription To Date
                  <span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="subscriptionToDate"
                  value={this.state.subscriptionToDateDisplay ?? ''}
                  onChange={(v) => this.editSubscriptionToDateChangeHandler(v)}
                  validators={['required']}
                  errorMessages={['Date is required']}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.editCollectionAgency_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc edit drawer end */}
      </React.Fragment>
    );
  }
}

export default CollectionAgency;
