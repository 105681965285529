import React, { useState } from 'react';
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from '../../../../../components/ui/inputField';
import SelectField from "../../../../../components/ui/select";
import { Button } from '@material-ui/core';
import axios from '../../../../../axios-order';
import DatePicker from "../../../../../components/ui/DatePicker";
import './DebitNote.css';
import moment from 'moment'
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

function reformatDateString(s) {
    var b = s.split(/\D/);
    return b.reverse().join('-');
}

function EditDebitNoteForm(props) {
    const { editData, updateTableAfterSubmit, closeEditFormDrawer, getDebitNoteDetail } = props
    const [editFormData, setEditFormData] = useState(editData)

    const inputChangeHandler = (event) => {
        const { name, value } = event.target
        setEditFormData({
            ...editFormData,
            [name]: value
        })
    }

    const convertToDefaultEventPara = (name, date) => {
        const value = moment(date).format("DD-MM-YYYY")
        return {
            target: {
                name, value
            }
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()
        toast.configure();

        delete editFormData.inv_date;
        delete editFormData.createDate;
        delete editFormData.modifyDate;
        delete editFormData.vendorName;

        let data = { ...editFormData };
        const formData = { ...data };
        formData["debitNoteDate"] = formData["debitNoteDate"] && moment(formData["debitNoteDate"], "DD-MM-YYYY").format("YYYY-MM-DD")
        formData["originalBillDate"] = formData["originalBillDate"] && moment(formData["originalBillDate"], "DD-MM-YYYY").format("YYYY-MM-DD")
        formData["bookingDate"] = formData["bookingDate"] && moment(formData["bookingDate"], "DD-MM-YYYY").format("YYYY-MM-DD")
        formData["boeDate"] = formData["boeDate"] && moment(formData["boeDate"], "DD-MM-YYYY").format("YYYY-MM-DD")

        axios
            .post(`/vendor/vendordebitnote`, { entityGst: localStorage.getItem("gst"), record: formData })
            .then((res) => {
                if (res.data.status === false) {
                    let error = "Error: ";
                    Object.keys(res.data.record.details[0].errors_in).map(value => {
                        error += String(value) + " " + res.data.record.details[0].errors_in[value];
                    })
                    toast.error(error, { autoClose: 2000 });
                }
                else {
                    closeEditFormDrawer()
                    updateTableAfterSubmit()
                    getDebitNoteDetail()
                    toast.success("Vendor Debit Note Updated Successfully", { autoClose: 3000 })
                }
            })
            .catch((error) => {
                toast.error(error, { autoClose: 3000 })
            });
    }

    return (
        <div >
            <ValidatorForm
                useRef="form"
                onSubmit={submitHandler}
                onError={(errors) => console.log(errors)}
            >
                <InputField
                    inputType="text"
                    textLabel="Vendor Code+"
                    name="erpVendorCode"
                    value={editFormData.erpVendorCode ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                    inputProps={{ readOnly: "true" }}
                />
                {/* <InputField
                    inputType="text"
                    textLabel="GSTIN"
                    name="vendorGstin"
                    value={editFormData.vendorGstin ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <InputField
                    inputType="text"
                    textLabel="PAN"
                    name="vendorPan"
                    value={editFormData.vendorPan ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                /> */}

                <InputField
                    inputType="text"
                    textLabel="Original Bill Number"
                    name="originalBillNumber"
                    requiredField="*"
                    validator={["required"]}
                    errorMsg={["This field is required"]}
                    value={editFormData.originalBillNumber ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                    inputProps={{ readOnly: true }}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        Original Bill Date
                        <span style={{ color: "red" }}>*</span>:
                    </label>
                    <DatePicker
                        name="originalBillDate"
                        inputProps={{ readOnly: true }}
                        value={editFormData.originalBillDate ? reformatDateString(editFormData.originalBillDate) : ""}
                        onChange={date => inputChangeHandler(convertToDefaultEventPara("originalBillDate", date))}
                        validators={["required"]}
                        errorMessages={["Date is required"]}
                        readOnly={true}
                    />
                </div>

                <InputField
                    inputType="text"
                    textLabel="E Invoice"
                    name="eInvoice"
                    value={editFormData.eInvoice ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        Booking/recording date
                        :
                    </label>
                    <DatePicker
                        name="bookingDate"
                        value={editFormData.bookingDate ? reformatDateString(editFormData.bookingDate) : ""}
                        onChange={date => inputChangeHandler(convertToDefaultEventPara("bookingDate", date))}
                    />
                </div>

                <InputField
                    inputType="text"
                    textLabel="Debit Note Number"
                    name="debitNoteNumber"
                    requiredField="*"
                    validator={["required"]}
                    errorMsg={["This field is required"]}
                    value={editFormData.debitNoteNumber ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                    inputProps={{ readOnly: "true" }}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        Debit Note Date
                        <span style={{ color: "red" }}>*</span>:
                    </label>
                    <DatePicker
                        inputProps={{ readOnly: true }}
                        name="debitNoteDate"
                        value={editFormData.debitNoteDate ? reformatDateString(editFormData.debitNoteDate) : ""}
                        onChange={date => inputChangeHandler(convertToDefaultEventPara("debitNoteDate", date))}
                        validators={["required"]}
                        errorMessages={["Date is required"]}
                        readOnly={true}
                    />
                </div>
                <InputField
                    inputType="text"
                    textLabel="HSN/SAC Code"
                    name="hsnSacCode"
                    value={editFormData.hsnSacCode ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ Taxable Value<span style={{ color: "red" }}>*</span>:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.taxableValue ? editFormData.taxableValue : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        required
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "taxableValue": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ Non Taxable Value:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.nonTaxableValue ? editFormData.nonTaxableValue : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "nonTaxableValue": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ SGST:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.sgst ? editFormData.sgst : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "sgst": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ CGST:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.cgst ? editFormData.cgst : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "cgst": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ IGST:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.igst ? editFormData.igst : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "igst": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ CESS:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.cess ? editFormData.cess : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "cess": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ Tax Amount:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.taxAmount ? editFormData.taxAmount : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "taxAmount": parseFloat(value)
                            })
                        }}
                    /> </div>

                <InputField
                    inputType="text"
                    textLabel="TCS Section"
                    name="tcsSection"
                    value={editFormData.tcsSection ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ TCS Payable:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.tcsPayable ? editFormData.tcsPayable : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "tcsPayable": parseFloat(value)
                            })
                        }}
                    /> </div>

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ Total Amount<span style={{ color: "red" }}>*</span>:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.totalAmount ? editFormData.totalAmount : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        required
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "totalAmount": parseFloat(value)
                            })
                        }}
                    /> </div>
                <InputField
                    inputType="text"
                    textLabel="TDS Section"
                    name="tdsSection"
                    value={editFormData.tdsSection ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ TDS Payable:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.tdsPayable ? editFormData.tdsPayable : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "tdsPayable": parseFloat(value)
                            })
                        }}
                    /> </div>
                <InputField
                    inputType="text"
                    textLabel="Expense Type"
                    name="expenseType"
                    value={editFormData.expenseType ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <SelectField
                    SelectLabel="Type of input Tax Credit"
                    choice_id="id"
                    choice_name="taxCreditType"
                    name="taxCreditType"
                    value={editFormData.taxCreditType ?? ""}
                    choices={[
                        { id: "Input", taxCreditType: "Input" },
                        { id: "Input Services", taxCreditType: "Input Services" },
                        { id: "Capital goods", taxCreditType: "Capital goods" },
                    ]}
                    textOnchangeFunction={inputChangeHandler}
                />

                <SelectField
                    SelectLabel="ITC Eligibility"
                    choice_id="id"
                    choice_name="itcEligibility"

                    name="itcEligibility"
                    value={editFormData.itcEligibility ?? ""}
                    choices={[
                        { id: "Yes", itcEligibility: "Yes" },
                        { id: "No", itcEligibility: "No" },
                        { id: "Unknown", itcEligibility: "Unknown" },
                    ]}
                    textOnchangeFunction={inputChangeHandler}
                />

                <SelectField
                    SelectLabel="RCM Applicability"
                    choice_id="id"
                    choice_name="rcmApplicability"
                    name="rcmApplicability"

                    value={editFormData.rcmApplicability ?? ""}
                    choices={[
                        { id: "Yes", rcmApplicability: "Yes" },
                        { id: "No", rcmApplicability: "No" },
                        { id: "Unknown", rcmApplicability: "Unknown" },
                    ]}
                    textOnchangeFunction={inputChangeHandler}
                />


                <SelectField
                    SelectLabel="Dispute"
                    choice_id="id"
                    choice_name="dispute"
                    name="dispute"
                    value={editFormData.dispute ?? ""}
                    choices={[
                        { id: "Yes", dispute: "Yes" },
                        { id: "No", dispute: "No" },
                    ]}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        ₹ Dispute Value:
                    </label> <br />
                    <NumberFormat
                        className="numberFormate"
                        style={{ paddingLeft: "10.5px" }}
                        value={editFormData.disputeValue ? editFormData.disputeValue : ""}
                        thousandsGroupStyle="lakh"
                        prefix="₹"
                        decimalSeparator="."
                        displayType="number"
                        type="text"
                        decimalScale={2}
                        thousandSeparator={true}
                        allowNegative={true}
                        onValueChange={(values) => {
                            const { value } = values;
                            setEditFormData({
                                ...editFormData,
                                "disputeValue": parseFloat(value)
                            })
                        }}
                    /> </div>
                <InputField
                    inputType="text"
                    textLabel="Bill of Entry Number"
                    name="boeNumber"
                    value={editFormData.boeNumber ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                <div style={{ marginTop: "20px" }}>
                    <label>
                        Bill of Entry Date:
                    </label>
                    <DatePicker
                        name="boeDate"
                        value={editFormData.boeDate ? reformatDateString(editFormData.boeDate) : ""}
                        onChange={date => inputChangeHandler(convertToDefaultEventPara("boeDate", date))}
                    />
                </div>

                <InputField
                    inputType="text"
                    textLabel="Port Code"
                    name="portCode"
                    value={editFormData.portCode ?? ""}
                    textOnchangeFunction={inputChangeHandler}
                />

                {/* submit and back button */}
                <div className="drawer-footer">
                    <Button
                        style={{ marginTop: "20px" }}
                        variant="contained"
                        size="small"
                        type="submit"

                    >
                        Submit
                    </Button>
                    <Button
                        style={{
                            float: "right",
                            marginTop: "20px",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={closeEditFormDrawer}
                    >
                        cancel
                    </Button>
                </div>
            </ValidatorForm>
        </div>
    );
}

export default EditDebitNoteForm;