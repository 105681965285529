import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import SelectField from "../../../../components/ui/basicSelectInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import BasicInputField from "../../../../components/ui/basicInput";
import axios from "../../../../axios-order";
import designForGstr2b from "./Gstr2bReconciliation.module.css";
import ValidatedDatePicker from "../../../../components/ui/monthWiseDatePicker";
import ValidatedMonthAndYearPicker from "../../../../components/ui/monthWiseDatePicker";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import CryptoJS from "crypto-js";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Trial from "../GSTR-2A_Reconciliation/ReportPage/Trial";
import Checkbox from '@mui/material/Checkbox';

export default function Gstr2bReconciliation() {
  const history = useHistory();
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  const [fromMonthAndYear, setFromMonthAndYear] = useState("");
  const [toMontAndYear, setToMontAndYear] = useState("");
  const [forDisplayingFromMonthAndYear, setForDisplayingFromMonthAndYear] =
    useState("");
  const [forDisplayingToMonthAndYear, setForDisplayingToMonthAndYear] =
    useState("");
  const [roundValue, setRoundValue] = useState(0);
  const [customRoundOff, setCustomRoundOff] = useState("");
  const [trigarCustomRoundOff, setTrigarCustomRoundOff] = useState(false);

  //............Get Api For Table....................
  const [recordForTable, setRecordForTable] = useState([]);
  const [recordFromTableSearch, setRecordFromTableSearch] = useState([]);

  //..........For Error Message...............
  const [errorMeaage, setErrorMeaage] = useState("");
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");
  const [loaderForTable, setLoaderForTable] = useState(false);


  //Latest Recon_ID
  const [latestReconData, setLatestReconData] = useState([]);

  const [checked, setChecked] = useState(false)
  const [forDisplayingFromMonthAndYearGSTR, setForDisplayingFromMonthAndYearGSTR] = useState("");
  const [forDisplayingToMonthAndYearGSTR, setForDisplayingToMonthAndYearGSTR] = useState("");
  const [fromMonthAndYearGSTR, setFromMonthAndYearGSTR] = useState("");
  const [toMontAndYearGSTR, setToMontAndYearGSTR] = useState("");



  //...............Function For Open and Close Model........
  const handleOpenAndCloseModel = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    functionForErrorMessage("", "none", "");
    setForDisplayingToMonthAndYear("");
    setForDisplayingFromMonthAndYear("");
    setFromMonthAndYear("");
    setToMontAndYear("");
    setRoundValue(0);
    setCustomRoundOff("");
    setTrigarCustomRoundOff(false);
    setForDisplayingFromMonthAndYearGSTR("");
    setForDisplayingToMonthAndYearGSTR("");
    setToMontAndYearGSTR('');
    setFromMonthAndYearGSTR('');
    setChecked(false)
  };

  //............function For Error And Successfull..........
  const functionForErrorMessage = (color, displays, message) => {
    setErrorMeaage(message);
    setColorForErrorMessage(color);
    setErrorMessageDisplayStyle(displays);
  };

  //......handle Search Event................
  const handleSearchEvent = (e) => {
    let Search = e.target.value;
    const searchName = recordFromTableSearch.filter((v) => {
      return (
        String(v.recon_date).toLowerCase().indexOf(Search.toLowerCase()) >= 0 ||
        String(moment(v.books_from_tax_period, "MMYYYY").format("MMMM-YYYY")).indexOf(Search) >=
        0 ||
        String(moment(v.books_to_tax_period, "MMYYYY").format("MMMM-YYYY")).indexOf(Search) >=
        0 ||
        String(moment(v.gstr_from_tax_period, "MMYYYY").format("MMMM-YYYY")).indexOf(Search) >=
        0 ||
        String(moment(v.gstr_to_tax_period, "MMYYYY").format("MMMM-YYYY")).indexOf(Search) >=
        0 ||
        String(v.org_gstin).toLowerCase().indexOf(Search.toLowerCase()) >= 0
      );
    });
    setRecordForTable(searchName);
  };

  const inputHandlerForYearAndFromMonth = (v) => {
    setForDisplayingFromMonthAndYear(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let MonthAndYear;
    if (mm < 10) {
      MonthAndYear = `0${mm}${yy}`;
    } else {
      MonthAndYear = `${mm}${yy}`;
    }
    setFromMonthAndYear(MonthAndYear);
  };

  //...........On Change Event To Handle To Tax-Period (Month And Year)................
  const inputHandlerForYearAndToMonth = (v) => {
    setForDisplayingToMonthAndYear(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let toMonthAndYear;
    if (mm < 10) {
      toMonthAndYear = `0${mm}${yy}`;
    } else {
      toMonthAndYear = `${mm}${yy}`;
    }
    setToMontAndYear(toMonthAndYear);
  };

  const inputHandlerForYearAndFromMonthGSTR = (v) => {
    setForDisplayingFromMonthAndYearGSTR(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let MonthAndYear;
    if (mm < 10) {
      MonthAndYear = `0${mm}${yy}`;
    } else {
      MonthAndYear = `${mm}${yy}`;
    }
    setFromMonthAndYearGSTR(MonthAndYear);
  };

  const inputHandlerForYearAndToMonthGSTR = (v) => {
    setForDisplayingToMonthAndYearGSTR(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    let toMonthAndYear;
    if (mm < 10) {
      toMonthAndYear = `0${mm}${yy}`;
    } else {
      toMonthAndYear = `${mm}${yy}`;
    }
    setToMontAndYearGSTR(toMonthAndYear);
  };

  const handleChangeCheckbox = () => {
    setChecked(!checked)  
  }

  //...........On Change Event To Handle Threshold Value........
  const roundValueInputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "threshold_value" && value === 11) {
      setRoundValue(value);
      setTrigarCustomRoundOff(!trigarCustomRoundOff);
    } else if (name === "threshold_value") {
      setRoundValue(value);
      setTrigarCustomRoundOff(false);
    } else if (name === "customRoundOff") {
      setCustomRoundOff(value);
    }
  };



  // const handleButtonClick = (reconId) => {
  //   localStorage.setItem('latestReconId', reconId);
  //   history.push('/Trial');
  // };

  const handleReconClick = (reconId) => {
    localStorage.setItem('latestReconId', reconId);
    history.push(`/Trial/${reconId}`);
  };
  //..................Submit Button....................
  const onSubmitHandler = () => {
    functionForErrorMessage("green", "block", "Loading....");
    let roundValueData;

    if (roundValue === "11") roundValueData = `${customRoundOff}`;
    else roundValueData = `${roundValue}`;

    axios
      .post(`/gstr2b_recon`, {
        org_gstin: localStorage.getItem("gst"),
        books_from_tax_period: fromMonthAndYear,
        books_to_tax_period: toMontAndYear,
        gstr_from_tax_period: checked ? fromMonthAndYearGSTR : fromMonthAndYear,
        gstr_to_tax_period: checked ? toMontAndYearGSTR : toMontAndYear,
        tval: roundValueData,
      })
      .then((res) => {
        if (res.data.status === true) {
          handleOpenAndCloseModel();
          // setTimeout(() => {
          //   history.push('/Trial'); 

          // }, 1500);
          getApiForGstr2bRecon();
        } else {
          functionForErrorMessage("red", "block", res.data.message);
        }
      })
      .catch((err) => {
        functionForErrorMessage(
          "red",
          "block",
          "An error occurred in the upload. Please try again later!"
        );
      });
  };

  //..............Get Api For Table..................
  const getApiForGstr2bRecon = () => {
    setLoaderForTable(true);
    axios
      .post(`/gstr2b_recon_summary`, { org_gstin: localStorage.getItem("gst") })
      .then((res) => {
        setLoaderForTable(false);
        setRecordForTable(res.data.data);
        setRecordFromTableSearch(res.data.data);
        const reconDate = res.data.data;
        reconDate.sort((a, b) => new Date(b.recon_date) - new Date(a.recon_date))
        setLatestReconData(reconDate)
      })
      .catch(() => setLoaderForTable(false));
  };

  useEffect(() => {
    getApiForGstr2bRecon();
  }, []);

  // ........gst Recon report route........
  const getGSTReconReport = (v) => {
    const encrypt = (text) => {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };

    history.push({
      pathname: `/gstr2bReconciliationReport/${encrypt(v)}`,
      data: v, // your data array of objects
    });
  };

  const refreshUploadHistoryTable = () => {
    getApiForGstr2bRecon();
  };

  return (
    <React.Fragment>
      <div>
        <button
          className="button-outlined-primary"
          onClick={handleOpenAndCloseModel}
        >
          New Reconciliation
        </button>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openAndCloseModel}
        onHide={handleOpenAndCloseModel}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>GSTR-2B Reconciliation</Modal.Title>
        </Modal.Header>
        <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Modal.Body>
            <Grid item sm={12} container>
              <Checkbox checked={checked}
                onChange={handleChangeCheckbox}
              />
              <p onClick={handleChangeCheckbox} style={{ marginTop: "10px", fontSize: "16px", cursor: 'pointer' }}>
                Advanced Criteria
              </p>



            </Grid>
            <Grid container spacing={1}>
              {/* color:'#576CBC' */}
              {checked && <Grid item sm={12}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  Books Period :
                </p>
              </Grid>}
              <Grid item sm={2}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  From Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div style={{ marginTop: "10px" }}>
                  <ValidatedDatePicker
                    placeholder="Month & Year"
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={forDisplayingFromMonthAndYear}
                    onChange={(v) => inputHandlerForYearAndFromMonth(v)}
                  />
                </div>
              </Grid>
              <Grid item sm={2}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  To Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div style={{ marginTop: "10px" }}>
                  <ValidatedMonthAndYearPicker
                    minDateForDatepicker={forDisplayingFromMonthAndYear}
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    placeholder="Month & Year"
                    value={forDisplayingToMonthAndYear}
                    onChange={(v) => inputHandlerForYearAndToMonth(v)}
                  />
                </div>
              </Grid>

              {/* color:'#C060A1' */}

              {checked && <Grid item sm={12}>
                <p style={{ marginTop: "14px", fontSize: "16px" }}>
                  GSTR-2B Period :
                </p>
              </Grid>}

              {checked && <>

                <Grid item sm={2}>
                  <p style={{ marginTop: "14px", fontSize: "16px" }}>
                    From Month<span style={{ color: "red" }}>*</span>:
                  </p>
                </Grid>

                <Grid item sm={4}>
                  <div style={{ marginTop: "10px" }}>
                    <ValidatedDatePicker
                      placeholder="Month & Year"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={forDisplayingFromMonthAndYearGSTR}
                      onChange={(v) => inputHandlerForYearAndFromMonthGSTR(v)}
                    />
                  </div>
                </Grid>

                <Grid item sm={2}>
                  <p style={{ marginTop: "14px", fontSize: "16px" }}>
                    To Month<span style={{ color: "red" }}>*</span>:
                  </p>
                </Grid>

                <Grid item sm={4}>
                  <div style={{ marginTop: "10px" }}>
                    <ValidatedMonthAndYearPicker
                      minDateForDatepicker={forDisplayingFromMonthAndYearGSTR}
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      placeholder="Month & Year"
                      value={forDisplayingToMonthAndYearGSTR}
                      onChange={(v) => inputHandlerForYearAndToMonthGSTR(v)}
                    />
                  </div>
                </Grid>
              </>}

              <Grid item sm={2}>
                <p style={{ marginTop: "6px", fontSize: "16px" }}>
                  Threshold value<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={10}>
                <SelectField
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  choice_id="id"
                  choice_name="roundOff"
                  name="threshold_value"
                  value={roundValue ?? 0}
                  choices={[
                    { id: 0, roundOff: "No Threshold Value" },
                    { id: 1, roundOff: "Upto 1" },
                    { id: 5, roundOff: "Upto 5" },
                    { id: 10, roundOff: "Upto 10" },
                    { id: 11, roundOff: "Custom" },
                  ]}
                  textOnchangeFunction={roundValueInputHandler}
                />
              </Grid>
            </Grid>
            {trigarCustomRoundOff ? (
              <>
                <div className={designForGstr2b.monthstyle}>
                  <Grid container spacing={3}>
                    <Grid item sm={3}>
                      <p style={{ marginTop: "30px", fontSize: "16px" }}>
                        Custom Round off
                        <span style={{ color: "red" }}>*</span> :
                      </p>
                    </Grid>
                    <Grid item sm={9}>
                      <BasicInputField
                        inputType="number"
                        name="customRoundOff"
                        validator={["required"]}
                        errorMsg={["This field is required"]}
                        value={customRoundOff ?? ""}
                        textOnchangeFunction={roundValueInputHandler}
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              ""
            )}
            <div
              style={{
                color: colorForErrorMessage,
                paddingTop: "10px",
                fontWeight: "600",
                display: errorMessageDisplayStyle,
              }}
            >
              {errorMeaage}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button className="button-primary" type="submit">
                Submit
              </button>
              <button
                className="button-outlined-secondary"
                type="button"
                onClick={handleOpenAndCloseModel}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </ValidatorForm>
      </Modal>

      <BoxWrapper
        boxTitle="GSTR-2B Reconciliation"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
        // add button
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={refreshUploadHistoryTable}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Books From month</th>
                <th>Books To Month</th>
                <th>GSTR From month</th>
                <th>GSTr To Month</th>
                <th>Recon Date</th>
                <th>GSTIN</th>
                <th>Status</th>
                <th>Report</th>
                {/* <th>Recon Progress</th> */}
              </tr>
            </thead>
            <tbody>
              {recordForTable
                ?.sort(function (a, b) {
                  let dateSplitFora = a.recon_date.split("-");
                  let dateSplitByYearFora = JSON.stringify(
                    dateSplitFora[2]
                  ).split(" ");
                  let inDateFormateFirstDate = new Date(
                    `${dateSplitByYearFora[0]}-${dateSplitFora[1]}-${dateSplitFora[0]}`
                  );

                  let dateSplitForb = b.recon_date.split("-");
                  let dateSplitByYearForb = JSON.stringify(
                    dateSplitForb[2]
                  ).split(" ");
                  let inDateFormateSecondDate = new Date(
                    `${dateSplitByYearForb[0]}-${dateSplitForb[1]}-${dateSplitForb[0]}`
                  );

                  return inDateFormateSecondDate - inDateFormateFirstDate;
                })
                ?.map((v) => {
                  return (                                                
                    <tr key={v.recon_id}>
                      <td>{moment(v.books_from_tax_period, "MMYYYY").format("MMMM-YYYY")}</td>
                      <td>{moment(v.books_to_tax_period, "MMYYYY").format("MMMM-YYYY")}</td>
                      <td>{moment(v.gstr_from_tax_period, "MMYYYY").format("MMMM-YYYY")}</td>
                      <td>{moment(v.gstr_to_tax_period, "MMYYYY").format("MMMM-YYYY")}</td>
                      <td>{v.recon_date}</td>
                      <td>{v.org_gstin}</td>
                      <td>{v.status}</td>
                      <td>
                        {v.status === "Pass" ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => getGSTReconReport(v.recon_id)}
                          >
                            <VisibilityOutlinedIcon className="action-icon-button showIcon" />
                          </div>
                        ) : (
                          <div style={{ cursor: "pointer" }}>
                            <VisibilityOutlinedIcon color="disabled" />
                          </div>
                        )}
                      </td>
                      {/* <td>{v.status === "In Progress" && <a href="#">Link</a>}</td> */}
                      {/* <td>
  {v.status === "In Progress" ? (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => handleReconClick(v.recon_id)}
    
    >
      Link
    </div>
  ) : (
    <div style={{ cursor: "pointer" }}>
      <VisibilityOutlinedIcon color="disabled" />
    </div>
  )}
</td> */}
                    </tr>
                  );
                })}

              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={6}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : recordForTable.length === 0 ? (
                <tr>
                  <td colSpan={6} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </React.Fragment>
  );
}
