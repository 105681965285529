import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import axios from "../../../../axios-order";
import MonthDatePicker from "../../../../components/ui/monthWiseDatePicker";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import DashboardBarChart from "../../Sales/TDSReconciliationSale/Dashboard/dashboardBarChart";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from '@mui/material/Chip';

import DatePicker from '../../../../components/ui/DatePicker'

export default function ItemWiseReportHsn() {
    

    const [trigarLoader, setTrigarLoader] = useState(false);
    const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);

    const [tdsHistoryTable, setTdsHistoryTable] = useState(true);

    const [triggerButton, setTriggerButton] = useState(true);
    const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);

    const [tdsReconHistoryData, setTdsReconHistoryData] = useState([]);


    const [loaderForTable, setLoaderForTable] = useState(false);

    const [chartData, setChartData] = useState([]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');

    const [responseMessage, setResponseMessage] = useState("");

    const [fromDateAndMonthAndYear, setFromDateAndMonthAndYear] = useState("");
    const [toDateAndMonthAndYear, setToDateAndMonthAndYear] = useState("");
    const [fromDateMonthYearDisplay, setFromDateMonthYearDisplay] =
        useState(null);
    const [toDateMonthYearDisplay, setToDateMonthYearDisplay] = useState(null);

    

    let history = useHistory();

    // Function to open Snackbar
    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);


        // setSnackbarOpen(false);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };


    // .............use Effect ..........
    const getTdsPayableRecon = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
        }
        setLoaderForTable(true);
        axios
            .post('/get_item_wise_report_records', payLoadData)
            .then((res) => {
                setLoaderForTable(false);
                setTdsReconHistoryData(res.data.records);

            })
            .catch(() => setLoaderForTable(false));
    };

    useEffect(() => {
        getTdsPayableRecon();
    }, []);


    //.............function To handle Form Date............
   

 
    //..................function To handle To Date...........
    

    const inputHandlerFromDatePicker = (v) => {

        setFromDateMonthYearDisplay(v);
        setFromDateAndMonthAndYear(v);
    };

    //............function for To(DatePicker)...............
    const inputHandlerToDatePicker = (v) => {

        setToDateMonthYearDisplay(v);
        setToDateAndMonthAndYear(v);
    };
    //................Handle Close Event..............
    const handleOpenAndCloseModel = () => {
        
        setTriggerButton(true);
        setTriggerModelFromToDate(false);
        // setTrigarModel(false);
        setTrigarLoader(false);
        setTrigarForErrorMessage(false);
    };


    //.......................Submit Handler...............
    const onSubmitHandlerForData = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'start_period': fromDateAndMonthAndYear,
            'end_period': toDateAndMonthAndYear
        }
        axios.post('create_item_wise_report_summury', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    handleSnackbarOpen(res.data.message, 'success')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()
                } else {
                    handleSnackbarOpen(res.data.message, 'error')
                    getTdsPayableRecon();
                    handleOpenAndCloseModel()
                }

            })
            .catch((err) => {
                getTdsPayableRecon();
                handleOpenAndCloseModel()
            })

    };


    const updatedShowDetails = (item) => {
        console.log('item..................', item)
        history.push({
            pathname: `/itemWiseReportContainer/${item.id}`,
            state: { items: item }
        })
    }


    const tdsPayablesHistoryTableBody = () => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (loaderForTable) {
            return (
                <tr>
                    <td
                        style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                        colSpan={5}
                    >
                        Loading…Please wait
                    </td>
                </tr>
            );
        } else if (tdsReconHistoryData && tdsReconHistoryData.length !== 0) {
            return tdsReconHistoryData.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.from_tax_period}</td>
                        <td>{item.to_tax_period}</td>
                        {/* <td>{monthNames[item.tax_period.slice(-2)-1]}</td> */}
                        <td>{item.run_date_time}</td>
                        <td><Chip label={item.report_status} color="success" variant="outlined" /></td>
                        <td><VisibilityOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => updatedShowDetails(item)}
                            style={{ marginTop: '2px' }}
                            color="primary"
                        /></td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td style={{ textAlign: "center", color: "red" }} colSpan={5}>
                        No Data Found!
                    </td>
                </tr>
            );
        }
    };


    const handleTDSREconciliationButtonEvent = () => {
        setTriggerModelFromToDate(true);
    };


    return (
        <React.Fragment>
            {triggerButton && (
                <div>
                    <button
                        className="button-outlined-primary"
                        onClick={handleTDSREconciliationButtonEvent}
                    >
                        New ITEM WISE Report
                    </button>
                </div>
            )}

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={triggerModelFromToDate}
                onHide={handleOpenAndCloseModel}
                dialogClassName="modal-50w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>ITEM WISE Report</Modal.Title>
                </Modal.Header>
                <ValidatorForm
                    useRef="form"
                    onSubmit={onSubmitHandlerForData}
                    onError={(errors) => console.log(errors)}
                >
                    <Modal.Body>
                        <Grid container spacing={3}>
                            <Grid item sm={2}>
                                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                                    From Date<span style={{ color: "red" }}>*</span>:
                                </p>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <DatePicker
                                        // maxDate={toDateMonthYearDisplay ?? ''}
                                        placeholder="From Date"
                                        errorMessages={["This field is required"]}
                                        validators={["required"]}
                                        value={fromDateMonthYearDisplay ?? ""}
                                        onChange={(v) => inputHandlerFromDatePicker(v)}
                                    />
                                </div>
                            </Grid>

                            <Grid item sm={2}>
                                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                                    To Date<span style={{ color: "red" }}>*</span>:
                                </p>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <DatePicker
                                        minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
                                        placeholder="To Date"
                                        errorMessages={["This field is required"]}
                                        validators={["required"]}
                                        value={toDateMonthYearDisplay ?? ""}
                                        onChange={(v) => inputHandlerToDatePicker(v)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {trigarLoader ? (
                            <div
                                style={{ fontWeight: "600", color: "green", padding: "5px" }}
                            >
                                Please wait recon is in progress
                            </div>
                        ) : (
                            ""
                        )}
                        {trigarForErrorMessage && (
                            <div style={{ color: "red", fontWeight: "700" }}>
                                No Records Present!
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="button-primary" type="submit">
                            Submit
                        </button>
                        <button
                            className="button-outlined-secondary"
                            type="button"
                            onClick={handleOpenAndCloseModel}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </ValidatorForm>
            </Modal>



            {tdsHistoryTable && (
                <BoxWrapper
                    boxTitle="ITEM WISE REPORT"

                >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    <th>FROM PERIOD</th>
                                    <th>TO PERIOD</th>
                                    <th>RUN DATE TIME</th>
                                    <th>REPORT STATUS</th>
                                    <th>Report</th>
                                </tr>
                                {tdsPayablesHistoryTableBody()}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            )}

            {/* {tdsReconHistoryData.length > 0 && <BoxWrapper boxTitle="ITC REPORT SUMMARY">
                <div>
                    <DashboardBarChart
                        chartTitle=""
                        chartCategories={tdsReconHistoryData.map(item => item.financial_year)}
                        yAxisTitle="Amount"
                        chartData={chartData}
                    />
                </div>

            </BoxWrapper>} */}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000} // Adjust as needed
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Use the state variable to determine severity
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    );
}
