import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Grid, Button } from "@material-ui/core";
import DesignInGSTR3BReport from "../GSTR-3B_recon.module.css";
import DownloadIcon from "@mui/icons-material/Download";
import ThreePointOne from "./ThreePointOne";
import ThreePointTwo from "./ThreePointTwo";
import Four from "./Four";
import Five from "./Five";
import FivePointOne from "./FivePointOne";
import InterestCalculator from "./InterestCalculator";
import axios from "../../../../../axios-order";
import CryptoJS from "crypto-js";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const Report = () => {
  const [triggerAllTrueOrFalse, setTriggerAllTrueOrFalse] = useState(true);
  const [triggerThreePointOne, setTriggerThreePointOne] = useState(false);
  const [triggerThreePointTwo, setTriggerThreePointTwo] = useState(false);
  const [triggerFour, setTriggerFour] = useState(false);
  const [triggerFive, setTriggerFive] = useState(false);
  const [triggerFivePointOne, setTriggerFivePointOne] = useState(false);
  const [triggerIntersetRate, setTriggerIntersetRate] = useState(false);

  //Data For Table
  const [details, setDetails] = useState({});
  const [threePointOne, setThreePointOne] = useState([]);
  const [threePointTwo, setThreePointTwo] = useState([]);
  const [four, setFour] = useState([]);
  const [five, setFive] = useState([]);
  const [fivePointOne, setFivePointOne] = useState([]);
  const [listOfPlaceOfSupply, setListOfPlaceOfSupply] = useState([]);

  //Total Cslculator
  const [totalthreePointOneIGST, setTotalthreePointOneIGST] = useState(0);
  const [totalthreePointOneCGST, setTotalthreePointOneCGST] = useState(0);
  const [totalthreePointOneSGST, setTotalthreePointOneSGST] = useState(0);
  const [totalthreePointOneCEE, setTotalthreePointOneCEE] = useState(0);

  const [totalThreePointTwoUnValue, setTotalThreePointTwoUnValue] = useState(0);
  const [totalThreePointTwoUnIGST, setTotalThreePointTwoUnIGST] = useState(0);
  const [totalThreePointTwoCompValue, setTotalThreePointTwoCompValue] =
    useState(0);
  const [totalThreePointTwoCompIGST, setTotalThreePointTwoCompIGST] =
    useState(0);
  const [totalThreePointTwoUinValue, setTotalThreePointTwoUniValue] =
    useState(0);
  const [totalThreePointTwoUinIGST, setTotalThreePointTwoUniIGST] = useState(0);

  const [totalFourIGST, setTotalFourIGST] = useState(0);
  const [totalFourCGST, setTotalFourCGST] = useState(0);
  const [totalFourSGST, setTotalFourSGST] = useState(0);
  const [totalFourCESS, setTotalFourCESS] = useState(0);

  const [totalFiveInterState, setTotalFiveInterState] = useState(0);
  const [totalFiveIntraState, setTotalFiveIntraState] = useState(0);

  const [totalFivePointOneIGST, setTotalFivePointOneIGST] = useState(0);
  const [totalFivePointOneCGST, setTotalFivePointOneCGST] = useState(0);
  const [totalFivePointOneSGST, setTotalFivePointOneSGST] = useState(0);
  const [totalFivePointOneCESS, setTotalFivePointOneCESS] = useState(0);

  const [displayType, setDisplayType] = useState("none");
  const [colorOfText, setColorOfText] = useState("");
  const [message, setMessage] = useState("");

  const [jsonId, setJsonId] = useState();
  const [triggerLoader, setTriggerLoader] = useState(false);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  useEffect(() => {
    axios
      .get(`/gstr3b/get_list_pos/`)
      .then((res) => setListOfPlaceOfSupply(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setTriggerLoader(true);
    const decrypt = (data) => {
      return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    };
    let activeUrl = window.location.href;
    let id = activeUrl.split("/");

    setJsonId(decrypt(id[4]));

    axios
      .get(`/gstr3b/get_gstr3b_data/${decrypt(id[4])}`)
      .then((res) => {
        setTriggerLoader(false);
        setDetails(res?.data?.details);
        setThreePointOne(res?.data?.data?.reverse_charge);
        setThreePointTwo(res?.data?.data["Interstate supplies"]);
        setFour(res?.data?.data?.ITC);
        setFive(res?.data?.data["Exempt/Nil rated supply"]);
        setFivePointOne(res?.data?.data["Interest and late fee"]);
      })
      .catch((err) => {
        setTriggerLoader(false);
        ErrorMessage(
          "block",
          "#ebccd1",
          "Server Error Please Try After Some Time"
        );
      });
  }, []);

  // if(window.location.reload.name === "reload") {
  //   alert("stop");
  // }

  const grandTotal = () => {
    let TotalthreePointOneIGST = 0;
    let TotalthreePointOneCGST = 0;
    let TotalthreePointOneSGST = 0;
    let TotalthreePointOneCEE = 0;

    for (let i of threePointOne) {
      if (i?.igst !== undefined) {
        TotalthreePointOneIGST =
          TotalthreePointOneIGST + parseFloat(i?.igst !== "" ? i?.igst : 0);
        // setTotalthreePointOneIGST( (prev) => prev + parseFloat(i?.igst)  )
      }
    }

    for (let c of threePointOne) {
      if (c?.cgst !== undefined) {
        TotalthreePointOneCGST =
          TotalthreePointOneCGST + parseFloat(c?.cgst !== "" ? c?.cgst : 0);
        // setTotalthreePointOneCGST( (prev) => prev + parseFloat(c?.cgst) )
      }
    }

    for (let s of threePointOne) {
      if (s?.sgst !== undefined) {
        TotalthreePointOneSGST =
          TotalthreePointOneSGST + parseFloat(s?.sgst !== "" ? s?.sgst : 0);
        // setTotalthreePointOneSGST( (prev) => prev + parseFloat(s?.sgst) )
      }
    }

    for (let c of threePointOne) {
      if (c?.cess !== undefined) {
        TotalthreePointOneCEE =
          TotalthreePointOneCEE + parseFloat(c?.cess !== "" ? c?.cess : 0);
        // setTotalthreePointOneCEE( (prev) => prev + parseFloat(c?.cess) )
      }
    }

    setTotalthreePointOneIGST(TotalthreePointOneIGST);
    setTotalthreePointOneCGST(TotalthreePointOneCGST);
    setTotalthreePointOneSGST(TotalthreePointOneSGST);
    setTotalthreePointOneCEE(TotalthreePointOneCEE);
  };

  const grandTotalThreePointTwo = () => {
    let TotalThreePointTwoUnValue = 0;
    let TotalThreePointTwoUnIGST = 0;
    let TotalThreePointTwoCompValue = 0;
    let TotalThreePointTwoCompIGST = 0;
    let TotalThreePointTwoUniValue = 0;
    let TotalThreePointTwoUniIGST = 0;

    for (let i of threePointTwo) {
      if (i?.unreg_taxable_value !== undefined) {
        TotalThreePointTwoUnValue =
          TotalThreePointTwoUnValue +
          parseFloat(
            i?.unreg_taxable_value !== "" ? i?.unreg_taxable_value : 0
          );
      }
    }
    for (let i of threePointTwo) {
      if (i?.unreg_igst !== undefined) {
        TotalThreePointTwoUnIGST =
          TotalThreePointTwoUnIGST +
          parseFloat(i?.unreg_igst !== "" ? i?.unreg_igst : 0);
      }
    }
    for (let i of threePointTwo) {
      if (i?.comp_taxable_value !== undefined) {
        TotalThreePointTwoCompValue =
          TotalThreePointTwoCompValue +
          parseFloat(i?.comp_taxable_value !== "" ? i?.comp_taxable_value : 0);
      }
    }
    for (let i of threePointTwo) {
      if (i?.comp_igst !== undefined) {
        TotalThreePointTwoCompIGST =
          TotalThreePointTwoCompIGST +
          parseFloat(i?.comp_igst !== "" ? i?.comp_igst : 0);
      }
    }
    for (let i of threePointTwo) {
      if (i?.uin_taxable_value !== undefined) {
        TotalThreePointTwoUniValue =
          TotalThreePointTwoUniValue +
          parseFloat(i?.uin_taxable_value !== "" ? i?.uin_taxable_value : 0);
      }
    }
    for (let i of threePointTwo) {
      if (i?.uin_igst !== undefined) {
        TotalThreePointTwoUniIGST =
          TotalThreePointTwoUniIGST +
          parseFloat(i?.uin_igst !== "" ? i?.uin_igst : 0);
      }
    }
    setTotalThreePointTwoUnValue(TotalThreePointTwoUnValue);
    setTotalThreePointTwoUnIGST(TotalThreePointTwoUnIGST);
    setTotalThreePointTwoCompValue(TotalThreePointTwoCompValue);
    setTotalThreePointTwoCompIGST(TotalThreePointTwoCompIGST);
    setTotalThreePointTwoUniValue(TotalThreePointTwoUniValue);
    setTotalThreePointTwoUniIGST(TotalThreePointTwoUniIGST);
  };

  const grandTotatFour = () => {
    let TotalFourIGST = 0;
    let TotalFourCGST = 0;
    let TotalFourSGST = 0;
    let TotalFourCESS = 0;
    for (let i of four) {
      if (i.igst !== undefined) {
        TotalFourIGST =
          TotalFourIGST + parseFloat(i?.igst !== "" ? i?.igst : 0);
        // setTotalFourIGST( (prev) => prev + parseFloat(i?.igst) )
      }
    }

    for (let c of four) {
      if (c.cgst !== undefined) {
        TotalFourCGST =
          TotalFourCGST + parseFloat(c?.cgst !== "" ? c?.cgst : 0);
        // setTotalFourCGST( (prev) => prev + parseFloat(c?.cgst) )
      }
    }

    for (let s of four) {
      if (s.sgst !== undefined) {
        TotalFourSGST =
          TotalFourSGST + parseFloat(s?.sgst !== "" ? s?.sgst : 0);
        // setTotalFourSGST( (prev) => prev + parseFloat(s?.sgst) )
      }
    }

    for (let c of four) {
      if (c.cess !== undefined) {
        TotalFourCESS =
          TotalFourCESS + parseFloat(c?.cess !== "" ? c?.cess : 0);
        // setTotalFourCESS( (prev) => prev + parseFloat(c?.cess) )
      }
    }
    setTotalFourIGST(TotalFourIGST);
    setTotalFourCGST(TotalFourCGST);
    setTotalFourSGST(TotalFourSGST);
    setTotalFourCESS(TotalFourCESS);
  };

  const grandTotalFive = () => {
    let InterState = 0;
    let Intrastate = 0;
    for (let i of five) {
      if (i?.interstate !== undefined) {
        InterState =
          InterState + parseFloat(i?.interstate !== "" ? i?.interstate : 0);
      }
    }
    for (let i of five) {
      if (i?.intrastate !== undefined) {
        Intrastate =
          Intrastate + parseFloat(i?.intrastate !== "" ? i?.intrastate : 0);
      }
    }
    setTotalFiveInterState(InterState);
    setTotalFiveIntraState(Intrastate);
  };

  const grandTotalFivePointOne = () => {
    let FivePointOneIGST = 0;
    let FivePointOneCGST = 0;
    let FivePointOneSGST = 0;
    let FivePointOneCESS = 0;
    for (let i of fivePointOne) {
      if (i?.igst !== undefined) {
        FivePointOneIGST =
          FivePointOneIGST + parseFloat(i?.igst !== "" ? i?.igst : 0);
      }
    }

    for (let i of fivePointOne) {
      if (i?.cgst !== undefined) {
        FivePointOneCGST =
          FivePointOneCGST + parseFloat(i?.cgst !== "" ? i?.cgst : 0);
      }
    }

    for (let i of fivePointOne) {
      if (i?.sgst !== undefined) {
        FivePointOneSGST =
          FivePointOneSGST + parseFloat(i?.sgst !== "" ? i?.sgst : 0);
      }
    }
    for (let i of fivePointOne) {
      if (i?.cess !== undefined) {
        FivePointOneCESS =
          FivePointOneCESS + parseFloat(i?.cess !== "" ? i?.cess : 0);
      }
    }

    setTotalFivePointOneIGST(FivePointOneIGST);
    setTotalFivePointOneCGST(FivePointOneCGST);
    setTotalFivePointOneSGST(FivePointOneSGST);
    setTotalFivePointOneCESS(FivePointOneCESS);
  };

  useEffect(() => {
    grandTotal();
  }, [threePointOne]);

  useEffect(() => {
    grandTotalThreePointTwo();
  }, [threePointTwo]);

  useEffect(() => {
    grandTotatFour();
  }, [four]);

  useEffect(() => {
    grandTotalFive();
  }, [five]);

  useEffect(() => {
    grandTotalFivePointOne();
  }, [fivePointOne]);

  const handleModule = (v) => {
    if (v === 3.1) {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(true);
      setTriggerThreePointTwo(false);
      setTriggerFour(false);
      setTriggerFive(false);
      setTriggerFivePointOne(false);
    } else if (v === 3.2) {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(false);
      setTriggerThreePointTwo(true);
      setTriggerFour(false);
      setTriggerFive(false);
      setTriggerFivePointOne(false);
    } else if (v === 4) {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(false);
      setTriggerThreePointTwo(false);
      setTriggerFour(true);
      setTriggerFive(false);
      setTriggerFivePointOne(false);
    } else if (v === 5) {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(false);
      setTriggerThreePointTwo(false);
      setTriggerFour(false);
      setTriggerFive(true);
      setTriggerFivePointOne(false);
    } else if (v === 5.1) {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(false);
      setTriggerThreePointTwo(false);
      setTriggerFour(false);
      setTriggerFive(false);
      setTriggerFivePointOne(true);
    } else if (v === "InterestRate") {
      setTriggerAllTrueOrFalse(false);
      setTriggerThreePointOne(false);
      setTriggerThreePointTwo(false);
      setTriggerFour(false);
      setTriggerFive(false);
      setTriggerFivePointOne(false);
      setTriggerIntersetRate(true);
    }
  };

  const handleCancelButton = () => {
    setTriggerAllTrueOrFalse(true);
    setTriggerThreePointOne(false);
    setTriggerThreePointTwo(false);
    setTriggerFour(false);
    setTriggerFive(false);
    setTriggerFivePointOne(false);
    setTriggerIntersetRate(false);
  };

  const upDatedJson = (data, Type) => {
    ErrorMessage(
      "block",
      "#ebccd1",
      "It seems that you have changed the values. Please Click on save button To Save the Value"
    );
    if (Type === "ThreePointOne") {
      setThreePointOne(data);
    } else if (Type === "ThreePointTwo") {
      setThreePointTwo(data);
    } else if (Type === "Four") {
      setFour(data);
    } else if (Type === "Five") {
      setFive(data);
    } else {
      setFivePointOne(data);
    }
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = () => {
    ErrorMessage(
      "block",
      "#dff0d8",
      "Please Wait We Are Preparing Excel Sheet...."
    );
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `GSTR3B-File.xlsx`;

    axios
      .post(`/gstr3b/excel_export/`, {
        details: { ...details },
        data: {
          reverse_charge: threePointOne,
          ITC: four,
          "Exempt/Nil rated supply": five,
          "Interest and late fee": fivePointOne,
          "Interstate supplies": threePointTwo,
        },
      })
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
          ErrorMessage("none", "", "");
        } else {
          alert("Unable To Download Files");
        }
      })
      .catch(() => alert("Something Went Wrong!! Unable To Download Files"));
  };

  const handleSaveJson = () => {
    ErrorMessage("block", "#dff0d8", "Please Wait....");
    axios
      .post(`/gstr3b/update_gstr3b_data/`, {
        id: jsonId,
        data: {
          details: { ...details },
          data: {
            reverse_charge: threePointOne,
            ITC: four,
            "Exempt/Nil rated supply": five,
            "Interest and late fee": fivePointOne,
            "Interstate supplies": threePointTwo,
          },
        },
      })
      .then((res) => {
        ErrorMessage("block", "#dff0d8", "Data Saved Successfully");
      })
      .catch((err) =>
        ErrorMessage(
          "block",
          "#ebccd1",
          "Server Error Please Try After Some Time"
        )
      );
  };

  const ErrorMessage = (display, color, message) => {
    setDisplayType(display);
    setColorOfText(color);
    setMessage(message);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div>
            GSTIN: <span>{details?.gstin}</span>
          </div>
          <div>
            Legal name: <spna>{details?.legal_name}</spna>
          </div>
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={5}></Grid>

        <Grid item xs={1}>
          <div>
            Year: <span>{details?.year}</span>
          </div>
          <div>
            Month: <span>{details?.month}</span>
          </div>
        </Grid>
      </Grid>

      <Paper
        elevation={2}
        square="false"
        style={{
          color: colorOfText === "#ebccd1" ? "red" : "green",
          display: displayType,
          background: colorOfText,
          padding: "5px",
          paddingLeft: "15px",
          marginTop: "10px",
        }}
      >
        {message}{" "}
        <span style={{ paddingLeft: "10px" }}>
          <CloseIcon
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={() => ErrorMessage("none", "", "")}
          />
        </span>
      </Paper>

      {triggerLoader ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <div>
            <CircularProgress />
          </div>
          <div>Loading..Please Wait!</div>
        </div>
      ) : (
        <>
          {triggerAllTrueOrFalse ? (
            <>
              <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid item xs={12} lg={4} onClick={() => handleModule(3.1)}>
                  <Paper elevation={3} className={DesignInGSTR3BReport.cards}>
                    <div>
                      3.1 Tax on outward and reverse charge inward supplies
                    </div>
                  </Paper>
                  <Paper elevation={3} square="false">
                    <div
                      style={{
                        padding: "10px",
                        minHeight: "98px",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <span>Integrated Tax: </span>
                          <strong>
                            {numberFormat(totalthreePointOneIGST)}
                          </strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>Central: </span>{" "}
                          <strong>
                            {numberFormat(totalthreePointOneCGST)}
                          </strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>State/UT Tax: </span>{" "}
                          <strong>
                            {numberFormat(totalthreePointOneSGST)}
                          </strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>CESS (₹): </span>{" "}
                          <strong>{numberFormat(totalthreePointOneCEE)}</strong>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={4} onClick={() => handleModule(3.2)}>
                  <Paper elevation={3} className={DesignInGSTR3BReport.cards}>
                    <div>3.2 Inter-state Supplies</div>
                  </Paper>
                  <Paper elevation={3} square="false">
                    <div
                      style={{
                        padding: "10px",
                        minHeight: "98px",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <span>Total Taxable value: </span>
                          <strong>
                            {numberFormat(
                              totalThreePointTwoUnValue +
                                totalThreePointTwoCompValue +
                                totalThreePointTwoUinValue
                            )}
                          </strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>Total Integrated Tax: </span>{" "}
                          <strong>
                            {numberFormat(
                              totalThreePointTwoUnIGST +
                                totalThreePointTwoCompIGST +
                                totalThreePointTwoUinIGST
                            )}
                          </strong>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={4} onClick={() => handleModule(4)}>
                  <Paper elevation={3} className={DesignInGSTR3BReport.cards}>
                    <div>4 Eligible ITC</div>
                  </Paper>
                  <Paper elevation={3} square="false">
                    <div
                      style={{
                        padding: "10px",
                        minHeight: "98px",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <span>Integrated Tax: </span>
                          <strong>{numberFormat(totalFourIGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>Central: </span>{" "}
                          <strong>{numberFormat(totalFourCGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>State/UT Tax: </span>{" "}
                          <strong>{numberFormat(totalFourSGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>CESS (₹): </span>{" "}
                          <strong>{numberFormat(totalFourCESS)}</strong>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={4} onClick={() => handleModule(5)}>
                  <Paper elevation={3} className={DesignInGSTR3BReport.cards}>
                    <div>
                      5 Values of exempt, Nil-rated and non-GST inward supplies
                    </div>
                  </Paper>
                  <Paper elevation={3} square="false">
                    <div
                      style={{
                        padding: "10px",
                        minHeight: "98px",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <span>Inter-state supplies:</span>
                          <strong>{numberFormat(totalFiveInterState)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>Intra-state supplies:</span>{" "}
                          <strong>{numberFormat(totalFiveIntraState)}</strong>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}></Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={4} onClick={() => handleModule(5.1)}>
                  <Paper elevation={3} className={DesignInGSTR3BReport.cards}>
                    <div>5.1 Interest & late fee payable</div>
                  </Paper>
                  <Paper elevation={3} square="false">
                    <div
                      style={{
                        padding: "10px",
                        minHeight: "98px",
                        cursor: "pointer",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <span>Integrated Tax: </span>
                          <strong>{numberFormat(totalFivePointOneIGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>Central: </span>{" "}
                          <strong>{numberFormat(totalFivePointOneCGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>State/UT Tax: </span>{" "}
                          <strong>{numberFormat(totalFivePointOneSGST)}</strong>
                        </Grid>
                        <Grid item xs={6}>
                          <span>CESS (₹): </span>{" "}
                          <strong>{numberFormat(totalFivePointOneCESS)}</strong>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <Grid item xs={4}>
                  <div
                    onClick={handleSaveJson}
                    className={DesignInGSTR3BReport.lastButton}
                  >
                    Save
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div
                    className={DesignInGSTR3BReport.lastButton}
                    onClick={exportToExcel}
                  >
                    <DownloadIcon />
                    Generate Excel
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div
                    className={DesignInGSTR3BReport.lastButton}
                    onClick={() => handleModule("InterestRate")}
                  >
                    Calculate Interest Rate
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {triggerThreePointOne ? (
                <ThreePointOne
                  cancel={handleCancelButton}
                  data={threePointOne}
                  upDateJSON={upDatedJson}
                />
              ) : triggerThreePointTwo ? (
                <ThreePointTwo
                  dataForPointTwo={threePointTwo}
                  cancel={handleCancelButton}
                  upDateJSON={upDatedJson}
                  dropDownForPlaceOfSupply={listOfPlaceOfSupply}
                />
              ) : triggerFour ? (
                <Four
                  cancel={handleCancelButton}
                  data={four}
                  upDateJSON={upDatedJson}
                />
              ) : triggerFive ? (
                <Five
                  dataForFive={five}
                  cancel={handleCancelButton}
                  upDateJSON={upDatedJson}
                />
              ) : triggerFivePointOne ? (
                <FivePointOne
                  dateForFivePointOne={fivePointOne}
                  cancel={handleCancelButton}
                  upDateJSON={upDatedJson}
                />
              ) : (
                <InterestCalculator cancel={handleCancelButton} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Report;
