import React, { useState } from "react";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import classes from "./Report.module.css";

function AllInvoiceRecon(props) {
  const { AllInvoiceReconData } = props;
  const [allInvoiceReconData, setAllInvoiceReconData] =
    useState(AllInvoiceReconData);

  const searchInputHandler = (event) => {
    let searchInput = event.target.value;
    let searchData = AllInvoiceReconData.filter((value) => {
      const query = searchInput.toLowerCase();
      return (
        String(value.GSTIN).toLowerCase().indexOf(query) >= 0 ||
        String(value.Vendor_Name).toLowerCase().indexOf(query) >= 0 ||
        String(value.DOC_NUMBER).toLowerCase().indexOf(query) >= 0 ||
        String(value.DATE).toLowerCase().indexOf(query) >= 0 ||
        String(value.TYPE).toLowerCase().indexOf(query) >= 0
      );
    });
    setAllInvoiceReconData(searchData);
  };

  // indian date format
  const indianFormat = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const AllInvoiceReconTableBody = () => {
    if (allInvoiceReconData.length >= 1) {
      return allInvoiceReconData.map((tableData, index) => {
        const {
          GSTIN,
          Vendor_Name,
          DOC_NUMBER,
          DATE,
          TYPE,
          Taxable_Value_Books,
          CGST_Books,
          SGST_Books,
          IGST_Books,
          Cess_Books,
          Total_Amount_Books,

          Taxable_Value_GSTR2A,
          IGST_GSTR2A,
          SGST_GSTR2A,
          CGST_GSTR2A,
          Cess_GSTR2A,
          Total_Amount_Books_GSTR2A,

          Taxable_Value_Diff,
          TaxValue_Diff,
          Total_Amount_Diff,
          gstr2a_amendment_flag,
        } = tableData;
        return (
          <tr>
            <td>{GSTIN}</td>
            <td>{Vendor_Name}</td>
            <td>{DOC_NUMBER}</td>
            <td>{DATE && indianFormat(DATE)}</td>

            <td>{TYPE}</td>

            <td>{numberFormat(Taxable_Value_Books)}</td>
            <td>{numberFormat(CGST_Books)}</td>
            <td>{numberFormat(SGST_Books)}</td>
            <td>{numberFormat(IGST_Books)}</td>
            <td>{numberFormat(Cess_Books)}</td>
            <td>{numberFormat(Total_Amount_Books)}</td>

            <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
            <td>{numberFormat(CGST_GSTR2A)}</td>
            <td>{numberFormat(SGST_GSTR2A)}</td>
            <td>{numberFormat(IGST_GSTR2A)}</td>
            <td>{numberFormat(Cess_GSTR2A)}</td>
            <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>

            <td>{numberFormat(Taxable_Value_Diff)}</td>
            <td>{numberFormat(TaxValue_Diff)}</td>
            <td>{numberFormat(Total_Amount_Diff)}</td>

            <td>{tableData["Remarks 2"]}</td>
            <td>{tableData.gstr2a_amendment_flag}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status : All Invoice(s) Reconciliation Report"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={searchInputHandler}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.AllInvoiceReconTableCss}>
            <tr>
              <th className={classes.ThInner} rowSpan={2}>
                GSTIN
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                VENDOR NAME
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                DOC NUMBER
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                DATE
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                DOC TYPE
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ffffb3" }}
                colSpan={6}
                align="center"
              >
                As per Book
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#5cd65c" }}
                colSpan={6}
                align="center"
              >
                As per GSTR2A
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ff8533" }}
                colSpan={3}
                align="center"
              >
                Difference
              </th>

              <th
                className={classes.ThInner}
                style={{ background: "#ffc107" }}
                rowSpan={2}
              >
                Remarks
              </th>
              <th className={classes.ThInner} rowSpan={2}>
                Is Amended
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                SGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                IGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CESS
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TAX
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {AllInvoiceReconTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default AllInvoiceRecon;
