import React, { useState } from "react";
import classes from "./CreditEvalution.module.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/footer/footer";
import axios from "../../axios-order";
import { Button, Grid } from "@material-ui/core";
import TabForm from "./Tabs/TabForm";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import TableForCriditEvalution from "./TableForCriditEvalution";
import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Expected_Sales_Order_Template from "../../asserts/templates/Expected_Sales_Order_Template.xlsx";
import Balance_Sheet_Income_Statement_Template from "../../asserts/templates/Balance_Sheet_Income_Statement_Template.xlsx";
import "../../HOC/layout/layout.css";
import BoxWrapper from "../../components/ui/boxWrapper";

function getSteps() {
  return ["DPO", "DSO", "DIO", "NETWORTH", "OPERATING PROFIT", "CURRENT RATIO"];
}

const jsonData = {
  dpo: {
    dpo_latest: "90",
    dpo_previous: "90",
    dpo_previous_to: "90",
    pan: "",
  },
  dso: {
    dso_latest: "90",
    dso_previous: "90",
    dso_previous_to: "90",
  },
  dio: {
    dio_latest: "40",
    dio_previous: "40",
    dio_previous_to: "40",
  },
  net_worth: {
    nw_latest: "1.5",
    nw_previous: "1.5",
    nw_previous_to: "1.5",
  },
  operating_profit: {
    op_latest: "15",
    op_previous: "15",
    op_previous_to: "15",
  },
  current_ratio: {
    cr_latest: "1",
    cr_previous: "1",
    cr_previous_to: "1",
  },
};

export default function CreditEvalution() {
  // const form = useRef("form")
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [file_nameOne, setFile_nameOne] = useState("");
  const [file_nameTwo, setFile_nameTwo] = useState("");
  const [file_uploadFirstOne, setFile_uploadFirstOne] = useState("");
  const [file_uploadFirstTwo, setFile_uploadFirstTwo] = useState("");

  const [dataForTable, setDataForTable] = useState([]);
  const [dataForPie, setDataForPie] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [forButtonInBottom, setForButtonInBottom] = useState(false);

  const [displayForMessage, setDisplayForMessage] = useState("none");
  const [colorForMessage, setColorForMessage] = useState("");
  const [message, setMessage] = useState("");

  const [dpoData, setDpoData] = useState(jsonData.dpo);
  const [dsoData, setDsoData] = useState(jsonData.dso);
  const [dioData, setDioData] = useState(jsonData.dio);
  const [networthData, setNetworthData] = useState(jsonData.net_worth);
  const [operating_profitData, setOperating_profitData] = useState(
    jsonData.operating_profit
  );
  const [current_ratioData, setCurrent_ratioData] = useState(
    jsonData.current_ratio
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ........handle stepper back.......
  const handleStepperBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    functionForErrorMessageAndSucc("", "none", "");
  };

  const backToTable = () => {
    setTrigger(true);
  };

  const clearData = () => {
    setDpoData(jsonData.dpo);
    setDsoData(jsonData.dso);
    setDioData(jsonData.dio);
    setNetworthData(jsonData.net_worth);
    setOperating_profitData(jsonData.operating_profit);
    setCurrent_ratioData(jsonData.current_ratio);
    setFile_nameOne("");
    setFile_nameTwo("");
    setFile_uploadFirstOne("");
    setFile_uploadFirstTwo("");
    setActiveStep(0);
    setForButtonInBottom(false);
    functionForErrorMessageAndSucc("", "none", "");
  };

  // function To handle Error Mesage And Succesfully
  const functionForErrorMessageAndSucc = (message, Display, color) => {
    setDisplayForMessage(Display);
    setColorForMessage(color);
    setMessage(message);
  };

  // handle stepper next
  const handleStepperNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 0) {
      if (
        dpoData.dpo_latest === "" ||
        dpoData.dpo_previous === "" ||
        dpoData.dpo_previous_to === ""
      ) {
        functionForErrorMessageAndSucc(
          "All field are mandatory.",
          "block",
          "red"
        );
      } else if (file_uploadFirstOne === "" || file_uploadFirstTwo === "") {
        functionForErrorMessageAndSucc(
          "Please Upload Template",
          "block",
          "red"
        );
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessageAndSucc("", "none", "");
      }
    } else if (activeStep === 1) {
      if (
        dsoData.dso_latest === "" ||
        dsoData.dso_previous === "" ||
        dsoData.dso_previous_to === ""
      )
        functionForErrorMessageAndSucc(
          "All field are mandatory.",
          "block",
          "red"
        );
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessageAndSucc("", "none", "");
      }
    } else if (activeStep === 2) {
      if (
        dioData.dio_latest === "" ||
        dioData.dio_previous === "" ||
        dioData.dio_previous_to === ""
      )
        functionForErrorMessageAndSucc(
          "All field are mandatory.",
          "block",
          "red"
        );
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessageAndSucc("", "none", "");
      }
    } else if (activeStep === 3) {
      if (
        networthData.nw_latest === "" ||
        networthData.nw_previous === "" ||
        networthData.nw_previous_to === ""
      )
        functionForErrorMessageAndSucc(
          "All field are mandatory.",
          "block",
          "red"
        );
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessageAndSucc("", "none", "");
      }
    } else if (activeStep === 4) {
      if (
        operating_profitData.op_latest === "" ||
        operating_profitData.op_previous === "" ||
        operating_profitData.op_previous_to === ""
      )
        functionForErrorMessageAndSucc(
          "All field are mandatory.",
          "block",
          "red"
        );
      else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessageAndSucc("", "none", "");
      }
    }
  };

  // ........form input change handler.........
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    if (activeStep === 0) {
      functionForErrorMessageAndSucc("", "none", "");
      setDpoData({
        ...dpoData,
        [name]: value,
      });
    } else if (activeStep === 1) {
      functionForErrorMessageAndSucc("", "none", "");
      setDsoData({
        ...dsoData,
        [name]: value,
      });
    } else if (activeStep === 2) {
      functionForErrorMessageAndSucc("", "none", "");
      setDioData({
        ...dioData,
        [name]: value,
      });
    } else if (activeStep === 3) {
      functionForErrorMessageAndSucc("", "none", "");
      setNetworthData({
        ...networthData,
        [name]: value,
      });
    } else if (activeStep === 4) {
      functionForErrorMessageAndSucc("", "none", "");
      setOperating_profitData({
        ...operating_profitData,
        [name]: value,
      });
    } else if (activeStep === 5) {
      functionForErrorMessageAndSucc("", "none", "");
      setCurrent_ratioData({
        ...current_ratioData,
        [name]: value,
      });
    }
  };

  // ........form submit handler.......
  const finalData = {
    ...dpoData,
    ...dsoData,
    ...dioData,
    ...networthData,
    ...operating_profitData,
    ...current_ratioData,
    business_entity_id: localStorage.getItem("business_entity_id"),
    ce_temp: file_uploadFirstOne,
    sales_order_temp: file_uploadFirstTwo,
  };

  const CreditEvalutionFormHandler = () => {
    if (
      current_ratioData.cr_latest === "" ||
      current_ratioData.cr_previous === "" ||
      current_ratioData.cr_previous_to === ""
    ) {
      functionForErrorMessageAndSucc(
        "All field are mandatory.",
        "block",
        "red"
      );
    } else if (file_uploadFirstOne === "" || file_uploadFirstTwo === "")
      functionForErrorMessageAndSucc("Please Upload Template", "block", "red");
    else {
      functionForErrorMessageAndSucc(
        "Please Wait Credit Evaluation Under Process....",
        "block",
        "green"
      );
      axios
        .post(`/credit_evaluvation`, finalData)
        .then((res) => {
          if (res.data.status === true) {
            setDataForTable(res.data.details);
            setDataForPie(res.data.pie_chart);
            setTrigger(true);
            functionForErrorMessageAndSucc("", "none", "");
            setForButtonInBottom(true);
          } else {
            functionForErrorMessageAndSucc(res.data.message, "block", "red");
            setTrigger(false);
          }
        })
        .catch((err) => {
          functionForErrorMessageAndSucc(
            "Server Error Try After Some Time or Check Your Internet.",
            "block",
            "red"
          );
          setTrigger(false);
        });
    }
  };

  //.........cancel button for file one
  const cencelButtonForFileOne = () => {
    setFile_nameOne("");
    setFile_uploadFirstOne("");
  };

  //..........cancel button for file two
  const cencelButtonForFileTwo = () => {
    setFile_nameTwo("");
    setFile_uploadFirstTwo("");
  };

  //.................handleOpenAndCloseFun............
  const handleOpenAndCloseFun = () => {
    setTrigger(false);
  };
  //........fileUploader.......
  const fileUploaderOne = (event) => {
    event.preventDefault();
    functionForErrorMessageAndSucc("", "none", "");
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      // console.log("file_data",file_data);
      let fileName = file_data.name;
      setFile_nameOne(fileName);
      const reader = new FileReader();
      reader.readAsDataURL(file_data);

      reader.onload = function () {
        let file_value = reader.result;
        setFile_uploadFirstOne(file_value);
      };

      document.getElementById("upload-1").value = null;
    }
  };

  //.............file Upload 2
  const fileUploaderTwo = (event) => {

    functionForErrorMessageAndSucc("", "none", "");
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      setFile_nameTwo(fileName);
      
      const reader = new FileReader();
      reader.readAsDataURL(file_data);

      reader.onload = function () {
        let file_value = reader.result;
        setFile_uploadFirstTwo(file_value);
      };
      document.getElementById("file_values").value = null;
    }
  };

  const onSubmitFormHandler = () => {};

  return (
    <>
      {trigger ? (
        <div>
          {trigger && (
            <>
              <TableForCriditEvalution
                tableData={dataForTable}
                pieChart={dataForPie}
                handleOpenClose={handleOpenAndCloseFun}
              />
            </>
          )}
        </div>
      ) : (
        <>
          <BoxWrapper
            boxTitle="Credit Evaluation"
            enableAddButton={true}
            addButtonLabel="Download Template"
            addButtonHandlerFn={handleClick}
          >
            {/* ........File upolad section......... */}
            <div style={{ margin: "auto", width: "80%", marginTop: "0px" }}>
              <ValidatorForm
                useRef="form"
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3} md={2}></Grid>
                  <Grid item xs={3} md={1}>
                    <label for="upload-1" className="button-primary">
                      Upload
                      <input
                        id="upload-1"
                        name="crditEvaluationFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        type="file"
                        hidden
                        onChange={fileUploaderOne}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      placeholder="Upload Balance Sheet and Income Statement Template"
                      value={file_nameOne}
                      name="GSTJson_file_uploader"
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <button
                      className="button-outlined-secondary"
                      onClick={cencelButtonForFileOne}
                    >
                      Cancel
                    </button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>

            {/*.......................File Upload Section 2...................*/}
            <div style={{ margin: "auto", width: "80%", marginBottom: "5px" }}>
              <ValidatorForm
                form
                onSubmit={onSubmitFormHandler}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3} md={2}></Grid>
                  <Grid item xs={3} md={1}>
                    <label className="button-primary">
                      Upload
                      <input
                        id="file_values"
                        name="crditEvaluationFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        type="file"
                        hidden
                        onChange={fileUploaderTwo}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      placeholder="Upload expected Sales Order Template"
                      value={file_nameTwo}
                      name="GSTJson_file_uploader"
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <button
                      className="button-outlined-secondary"
                      onClick={cencelButtonForFileTwo}
                    >
                      cancel
                    </button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>

            <div
              style={{
                color: colorForMessage,
                display: displayForMessage,
                paddingLeft: "8px",
                fontWeight: "bold",
                width: "90%",
                marginTop: "15px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              {message}
            </div>

            {/*........ steppers...... */}
            <div style={{ margin: "auto", width: "80%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <p>{label}</p>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {/* stepper form */}
            <div style={{ margin: "auto", width: "90%", padding: "10px" }}>
              {activeStep === 0 && (
                <>
                  <TabForm
                    data={dpoData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-primary"
                      onClick={handleStepperNext}
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {activeStep === 1 && (
                <>
                  <TabForm
                    data={dsoData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={handleStepperBack}
                    >
                      Back
                    </button>
                    <button
                      className="button-primary"
                      onClick={handleStepperNext}
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <TabForm
                    data={dioData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={handleStepperBack}
                    >
                      Back
                    </button>
                    <button
                      className="button-primary"
                      onClick={handleStepperNext}
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {activeStep === 3 && (
                <>
                  <TabForm
                    data={networthData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={handleStepperBack}
                    >
                      Back
                    </button>
                    <button
                      className="button-primary"
                      onClick={handleStepperNext}
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {activeStep === 4 && (
                <>
                  <TabForm
                    data={operating_profitData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={handleStepperBack}
                    >
                      Back
                    </button>
                    <button
                      className="button-primary"
                      onClick={handleStepperNext}
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {activeStep === 5 && (
                <>
                  <TabForm
                    data={current_ratioData}
                    handleInputChange={handleEditInputChange}
                    steps={activeStep}
                  />
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={handleStepperBack}
                    >
                      Back
                    </button>
                    <button
                      className="button-primary"
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                      onClick={CreditEvalutionFormHandler}
                    >
                      SUBMIT
                    </button>
                  </div>
                </>
              )}

              {forButtonInBottom && (
                <>
                  <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <button
                      className="button-outlined-secondary"
                      style={{
                        width: "6%",
                        padding: "7px",
                      }}
                      onClick={backToTable}
                    >
                      {"<-"} Previous Page
                    </button>
                    <button
                      className="button-primary"
                      style={{
                        width: "10%",
                        padding: "7px",
                        marginRight: "0px",
                      }}
                      onClick={clearData}
                    >
                      Clear Data
                    </button>
                  </div>
                </>
              )}
            </div>
          </BoxWrapper>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <a
                href={Balance_Sheet_Income_Statement_Template}
                download={Balance_Sheet_Income_Statement_Template.xlsx}
                onClick={handleClose}
                style={{ textDecoration: "none", color: "black" }}
              >
                Balance Sheet & Income Statement
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href={Expected_Sales_Order_Template}
                download={"Expected_Sales_Order_Template.xlsx"}
                onClick={handleClose}
                style={{ textDecoration: "none", color: "black" }}
              >
                Expected Sales Order{" "}
              </a>
            </MenuItem>
          </Menu>
        </>
      )}

      {/* ---Footer--- */}
      {trigger === false && (
        <div className={classes.profileFooter}>
          <Footer />
        </div>
      )}
      {/* ---Footer End---  */}
    </>
  );
}
