import React, { useState } from 'react';
import { Paper, Grid, Button, CircularProgress } from "@material-ui/core";
import { Download as DownloadIcon, Close as CloseIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import axios from '../../../../../axios-order'

import DesignInGSTR9BReport from "../GSTR-9B_recon.module.css";
import FourPointOne from './FourPointOne';
import Five from './Five';
import Six from './Six';
import Seven from './Seven';
import Eight from './Eight';
import Nine from './Nine';
import Ten from './Ten';
import Fourteen from './Fourteen';
import Fifteen from './Fifteen';
import Sixteen from './Sixteen';
import Seventeen from './Seventeen';
import Eighteen from './Eighteen';

import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


const useStyles = makeStyles({
  card: {
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
});

const numberFormat = (value) => new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumSignificantDigits: 10,
}).format(value);



const Report9 = () => {
  const { reconId } = useParams();
  const [triggerLoader, setTriggerLoader] = useState(false);
  const [activeModule, setActiveModule] = useState(null);
  const [tableData, setTableData] = useState({})
  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  const [responseMessage, setResponseMessage] = useState("");


  const updateTableData = (name, data) => {
    console.log('upadting table-9 .......... \n', name, data)
    setTableData(prevTableData => ({
      ...prevTableData,
      [name]: data
    }));
    storeTableItems(tableData)
  }

  const storeTableItems = (data) => {
    const four_n = data['4.Details of advances, inwards and outward supplies made during the financial year on which tax is payable'][13]
    const four_g = data['4.Details of advances, inwards and outward supplies made during the financial year on which tax is payable'][6]
    const six_o = data['6.Details of ITC availed during the financial year'][21]
    const five_n = data['5.Details of outward supplies made during the financial year on which tax is not payable'][13]
    const tableItems = {
      'four_n': four_n,
      'four_g': four_g,
      'six_o': six_o,
      'five_n': five_n
    }

    console.log('tableItems..........', tableItems)

    const tableItemsJSON = JSON.stringify(tableItems);

    // Store in localStorage
    localStorage.setItem('tableItems', tableItemsJSON);
  }

  const get_gstr9_report_data = () => {
    setTriggerLoader(true)
    axios.get(`gstr9_master_summary/${localStorage.getItem(
      "business_entity_id"
    )}/${reconId}`)
      .then((res) => {
        const records = res.data.records
        setTableData(records)
        storeTableItems(records)
        setTriggerLoader(false)
      }).catch((err) => {
        setTriggerLoader(false)
      })
  }

  useEffect(() => {
    get_gstr9_report_data()

  }, [])

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleModule = (module) => setActiveModule(module);

  const handleCancelButton = () => setActiveModule(null);

  const renderModuleContent = () => {
    switch (activeModule) {
      case 4:
        return <FourPointOne
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['4.Details of advances, inwards and outward supplies made during the financial year on which tax is payable']}
        />;
      case 5:
        return <Five
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['5.Details of outward supplies made during the financial year on which tax is not payable']}
        />;
      case 6:
        return <Six
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['6.Details of ITC availed during the financial year']}
        />;
      case 7:
        return <Seven
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['7.Details of ITC Reversed and  Ineligible ITC for the financial year']}
        />;
      case 8:
        return <Eight
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['8.Other ITC related information']}
        />;
      case 9:
        return <Nine
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['9.Details of tax paid as declared in returns filed during the financial year']}
        />;
      case 10:
        return <Ten
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period']}
        />;
      case 14:
        return <Fourteen
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['14.Differential tax paid on account of declaration in 10 & 11 above']}
        />;
      case 15:
        return <Fifteen
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['15.Particulars of Demands and Refunds']}
        />;
      case 16:
        return <Sixteen
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis']}
        />;
      case 17:
        return <Seventeen
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['17. HSN wise summary of Outward Supplies']}
        />;
      case 18:
        return <Eighteen
          handleBack={handleCancelButton}
          updateTableData={updateTableData}
          data={tableData['18. HSN wise summary of Inward Supplies']}
        />;
      default: return null;
    }
  };


  const renderModule = (moduleNumber, title, descriptions) => (
    <Grid className={classes.card} item xs={12} lg={4} onClick={() => handleModule(moduleNumber)}>
      <Paper square elevation={3} className={DesignInGSTR9BReport.cards}>
        <div style={{ fontSize: "15px", height: '5vh', whiteSpace: 'pre-line' }}>{title}</div>
      </Paper>
      <Paper elevation={3} square>
        <div style={{ padding: "10px", height: '25vh', cursor: "pointer" }}>
          <Grid container spacing={3}>
            {descriptions.map((desc, index) => (
              <Grid item xs={6} key={index}>
                <span>{desc.label}</span><br />
                <strong>{numberFormat(desc.value)}</strong>
              </Grid>
            ))}
          </Grid>
        </div>
      </Paper>
    </Grid>
  );

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const genarateExcel = () => {
    setLoading(true)
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `GSTR-9.xlsx`;

    const body = {
      'business_enity_id': localStorage.getItem(
        "business_entity_id"
      ),
      'financail_year': localStorage.getItem('gstr9_financail_year'),
      'recon_id': reconId

    }
    axios.post('/gstr9/recon_report', body)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
  }

  const onSaveGstr9Summary = () => {
    const payLoadData = {
      'business_enity_id': localStorage.getItem(
        "business_entity_id"
      ),
      'gstr9_summary_data': tableData,
      'recon_id': reconId
    }
    axios.post('/save_gstr9_summary_data', payLoadData)
      .then((res) => {
        handleSnackbarOpen(res?.data?.message, 'success')
      })
      .catch((err) => {

      })
  }

  const getValue = (key, field) => {

    if (tableData && tableData[key]) {
      const sum = tableData[key].reduce((acc, item) => {
        const accValue = parseFloat(acc) || 0;
        const itemValue = parseFloat(item[field]) || 0;
        return accValue + itemValue;
      }, 0);
      return sum
    }
    return 0
  }

  const getValue10 = (key, field) => {
    if (tableData && tableData[key]) {
      const sum = tableData[key].reduce((acc, item) => {
        if (item.name !== "Total turnover (5N +10-11)") {
          const accValue = parseFloat(acc) || 0;
          const itemValue = parseFloat(item[field]) || 0;
          return accValue + itemValue;
        }
        return acc;
      }, 0);
      return sum;
    }
    return 0;
  };




  const getFormattedResults = (items) => {
    console.log('15.Particulars of Demands and Refunds..........', items)
    let categoryTotals = {
      "Refund claimed": 0,
      "Refund sanctioned": 0,
      "Refund pending": 0,
      "Demand of Taxes": 0,
      "Taxes paid": 0,
      "Demands pending": 0
    };

    if (items && items.length >= 1) {

      items.forEach(item => {
        const total = (parseFloat(item.taxable_value) || 0) + (parseFloat(item.igst) || 0) + (parseFloat(item.cgst) || 0) + (parseFloat(item.cess) || 0) + (parseFloat(item.sgst) || 0);
        switch (item.name) {
          case "(A).Total Refund claimed":
            categoryTotals["Refund claimed"] = total;
            break;
          case "(B).Total Refund sanctioned":
            categoryTotals["Refund sanctioned"] = total;
            break;
          case "(D).Total Refund Pending":
            categoryTotals["Refund pending"] = total;
            break;
          case "(E).Total demand of taxes":
            categoryTotals["Demand of Taxes"] = total;
            break;
          case "(F).Total taxes paid in respect of E above":
            categoryTotals["Taxes paid"] = total;
            break;
          case "(G).Total demands pending out of E above":
            categoryTotals["Demands pending"] = total;
            break;
          default:
            break;
        }
      });
    }
    return categoryTotals

  };




  const getTableCustomeTotal = (key, index) => {

    if (!tableData || !tableData[key] || tableData[key].length === 0) {
      return {
        taxableValue: 0,
        igst: 0,
        cgst: 0,
        sgst: 0,
        cess: 0,
      };
    }
    const lastItem = tableData[key][tableData[key].length - index];
    return {
      taxableValue: lastItem.taxable_value || 0,
      igst: lastItem.igst || 0,
      cgst: lastItem.cgst || 0,
      sgst: lastItem.sgst || 0,
      cess: lastItem.cess || 0,
    };
  }

  const getTable5Value = () => {
    const key = '5.Details of outward supplies made during the financial year on which tax is not payable'
    if (!tableData || !tableData[key] || tableData[key].length === 0) {
      return 0
    }
    const lastItem = tableData[key][tableData[key].length - 2];
    return lastItem.taxable_value
  }

  const getPaidthoroughCash = (key) =>{
    if (tableData && tableData[key]) {
      const sum = tableData[key].reduce((acc, item) => {
        const accValue = parseFloat(acc) || 0;
        const itemValue = parseFloat(item.igst) + parseFloat(item.cgst) + parseFloat(item.sgst) + parseFloat(item.cess);
        return accValue + itemValue;
      }, 0);
      console.log('paid through cash.........', sum)
      return sum
      
    }
    return 0
  }

  const table4Data = getTableCustomeTotal('4.Details of advances, inwards and outward supplies made during the financial year on which tax is payable', 1);
  const table6Data = getTableCustomeTotal('6.Details of ITC availed during the financial year', 1);
  const table7Data = getTableCustomeTotal('7.Details of ITC Reversed and  Ineligible ITC for the financial year', 2);
  const table8Data = getTableCustomeTotal('8.Other ITC related information', 11);
  const table5Value = getTable5Value()
  const table15Data = getFormattedResults(tableData["15.Particulars of Demands and Refunds"]);


  return (
    <>
      {triggerLoader ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <CircularProgress />
          <div>Loading Please Wait</div>
        </div>
      ) : (
        <>
          {activeModule === null ? (
            <>
              <Grid container spacing={3}>
                {renderModule(4, '4.Details of advances, inwards and outward supplies made during the financial year on which tax is payable', [
                  { label: 'Taxable value', value: table4Data['taxableValue'] },
                  { label: 'Integrated Tax (₹)', value: table4Data['igst'] },
                  { label: 'Central Tax (₹)', value: table4Data['cgst'] },
                  { label: 'State / UT Tax (₹)', value: table4Data['sgst'] },
                  { label: 'CESS (₹)', value: table4Data['cess'] }
                ])}

                {renderModule(5, '5.Details of outward supplies made during the financial year on which tax is not payable', [
                  { label: 'Value (₹)', value: table5Value },
                ])}

                {renderModule(6, '6.Details of ITC availed during the financial year', [
                  { label: 'Integrated Tax (₹)', value: table6Data['igst'] },
                  { label: 'Central Tax (₹)', value: table6Data['cgst'] },
                  { label: 'State UI/Tax (₹)', value: table6Data['sgst'] },
                  { label: 'CESS (₹)', value: table6Data['cess'] },
                ])}

                {renderModule(7, '7.Details of ITC Reversed and  Ineligible ITC for the financial year', [
                  { label: 'Integrated Tax (₹)', value: table7Data['igst'] },
                  { label: 'Central Tax (₹)', value: table7Data['cgst'] },
                  { label: 'State UI/Tax (₹)', value: table7Data['sgst'] },
                  { label: 'CESS (₹)', value: table7Data['cess'] },
                ])}

                {renderModule(8, '8.Other ITC related information', [
                  { label: 'Integrated Tax (₹)', value: table8Data['igst'] },
                  { label: 'Central Tax (₹)', value: table8Data['cgst'] },
                  { label: 'State UI/Tax (₹)', value: table8Data['sgst'] },
                  { label: 'CESS (₹)', value: table8Data['cess'] },
                ])}

                {renderModule(9, '9.Details of tax paid as declared in returns filed during the financial year', [
                  { label: 'Tax payable', value: getValue('9.Details of tax paid as declared in returns filed during the financial year', 'tax_payble') },
                  { label: 'Paid through Cash', value: getValue('9.Details of tax paid as declared in returns filed during the financial year', 'paid_through_itc')},
                  { label: 'Paid through ITC', value: getPaidthoroughCash('9.Details of tax paid as declared in returns filed during the financial year') },
                ])}

                {renderModule(10, '10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', [
                  { label: 'Taxable value', value: getValue10('10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', 'taxable_value') },
                  { label: 'Integrated Tax (₹)', value: getValue10('10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', 'igst') },
                  { label: 'Central Tax (₹)', value: getValue10('10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', 'cgst') },
                  { label: 'State/UT tax (₹)', value: getValue10('10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', 'sgst') },
                  { label: 'CESS (₹)', value: getValue10('10, 11, 12 & 13. Particulars of the transactions for the financial year declared in returns of the next financial year till the specified period', 'cess') },
                ])}

                {renderModule(14, '14.Differential tax paid on account of declaration in 10 & 11 above', [
                  { label: 'Taxable value', value: getValue('14.Differential tax paid on account of declaration in 10 & 11 above', 'tax_payble') },
                  { label: 'Tax Paid', value: getValue('14.Differential tax paid on account of declaration in 10 & 11 above', 'paid_through_itc') },
                ])}

                {renderModule(15, '15.Particulars of Demands and Refunds', [
                  { label: 'Refund claimed', value: table15Data['Refund claimed'] },
                  { label: 'Refund sectioned', value: table15Data['Refund sanctioned'] },
                  { label: 'Refund pending', value: table15Data['Refund pending'] },
                  { label: 'Demand of Taxes', value: table15Data['Demand of Taxes'] },
                  { label: 'Taxes paid', value: table15Data['Taxes paid'] },
                  { label: 'Demands pending', value: table15Data['Demands pending'] },
                ])}

                {renderModule(16, '16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', [
                  { label: 'Taxable value', value: getValue('16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', 'taxable_value') },
                  { label: 'Integrated Tax (₹)', value: getValue('16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', 'igst') },
                  { label: 'Central Tax (₹)', value: getValue('16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', 'cgst') },
                  { label: 'State / UT Tax (₹)', value: getValue('16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', 'sgst') },
                  { label: 'CESS (₹)', value: getValue('16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis', 'cess') },
                ])}

                {renderModule(17, '17. HSN wise summary of Outward Supplies', [
                  { label: 'Taxable value', value: getValue('17. HSN wise summary of Outward Supplies', 'taxable_value') },
                  { label: 'Integrated Tax (₹)', value: getValue('17. HSN wise summary of Outward Supplies', 'igst') },
                  { label: 'Central Tax (₹)', value: getValue('17. HSN wise summary of Outward Supplies', 'cgst') },
                  { label: 'State / UT Tax (₹)', value: getValue('17. HSN wise summary of Outward Supplies', 'sgst') },
                  { label: 'CESS (₹)', value: getValue('17. HSN wise summary of Outward Supplies', 'cess') },
                ])}

                {renderModule(18, '18. HSN wise summary of Inward Supplies', [
                  { label: 'Taxable value', value: getValue('18. HSN wise summary of Inward Supplies', 'taxable_value') },
                  { label: 'Integrated Tax (₹)', value: getValue('18. HSN wise summary of Inward Supplies', 'igst') },
                  { label: 'Central Tax (₹)', value: getValue('18. HSN wise summary of Inward Supplies', 'cgst') },
                  { label: 'State / UT Tax (₹)', value: getValue('18. HSN wise summary of Inward Supplies', 'sgst') },
                  { label: 'CESS (₹)', value: getValue('18. HSN wise summary of Inward Supplies', 'cess') },
                ])}

              </Grid>

              <Grid
                container
                spacing={2}
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <Grid item xs={4}>
                  <div
                    className={DesignInGSTR9BReport.lastButton}
                    onClick={onSaveGstr9Summary}
                  >
                    Save
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <LoadingButton loading={loading} variant="contained" style={{ width: '100%', height: '32px', backgroundColor: loading ? '#808080' : '#3b3b6b' }}
                    onClick={genarateExcel}>
                    <span> <DownloadIcon />
                      Generate Excel</span>
                  </LoadingButton>

                </Grid>

                <Grid item xs={4}>
                  <div
                    className={DesignInGSTR9BReport.lastButton}
                  >
                    Compute Laibilities
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {/* <Button variant="contained" color="primary" onClick={handleCancelButton}>Back</Button> */}
              {renderModuleContent()}
            </>
          )}
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}

        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Report9;
