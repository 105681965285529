import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
// import highcharts3d from "highcharts/highcharts-3d";
// import { setDayWithOptions } from 'date-fns/fp';
drilldown(Highcharts);
// highcharts3d(Highcharts);

function generateDarkColorHex() {
  let color = "#";
  for (let i = 0; i < 3; i++)
    color += (
      "0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
    ).slice(-2);
  return color;
}

function getRandColor(same, darkness, total_series) {
  let arr = [];
  let i = 0;
  for (i; i < total_series; i++) {
    const randomColor = generateDarkColorHex();
    arr.push(randomColor);
  }
  return arr;
}

const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);

const setOptions = (props) => {
  const {
    tooltipHeader,
    fnForDrillDown,
    enableDrilldownFn,
    maxValue,
    minValue,
  } = props;

  return (
    props.data && {
      chart: {
        type: "column",
        zoomType: "xy",
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 10,
          depth: 60,
          viewDistance: 25,
        },
      },
      title: {
        text: props.BarChartTitle,
      },
      subtitle: {
        text: props.subtitle,
      },
      credits: {
        enabled: false,
      },

      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
        title: {
          text: props.xAxisTitle,
        },
      },
      yAxis: {
        max: maxValue,
        min: minValue,
        title: {
          text: props.yAxisTitle,
        },
      },
      legend: {
        enabled: false,
      },
      isResponsiveOptions: {},
      plotOptions: {
        column: {
          dataLabels: {
            enabled: false,
          },
          colorByPoint: true,
          allowPointSelect: true,
          showInLegend: true,
          cursor: "pointer",
          point: {
            events: {
              click: function (e) {
                if (e.point.name === "Others") {
                  return false;
                } else {
                  if (enableDrilldownFn) {
                    fnForDrillDown(e.point.name);
                  }
                }
              },
            },
          },
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },

      // ' {point.y:.1f}₹'
      // this.point.name +'<br>'+ numberFormat(this.point.y)
      tooltip: {
        // pointFormat: props.tooltipFormat
        formatter: function () {
          return (
            this.point[tooltipHeader] +
            "<br>" +
            numberFormat(this.point.y.toFixed(2))
          );
        },
        valueDecimals: 2,
      },

      series: [
        {
          type: "column",
          colorByPoint: true,
          showInLegend: false,
          data: props.data,
          colors: getRandColor(false, 5, props.data.length),
        },
      ],
    }
  );
};

const MyStockChart = (props) => (
  <HighchartsReact
    highcharts={Highcharts}
    allowChartUpdate={true}
    options={setOptions(props)}
  />
);

export default MyStockChart;
