import React, { useState, useEffect ,useRef} from "react";
import { TextField } from "@mui/material";
import axios from "../../axios-order";
import BoxWrapper from "../../components/ui/boxWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";
import IconButton from "@mui/material/IconButton"; // Import MUI IconButton
import DownloadIcon from "@mui/icons-material/Download"; 
import MuiAlert from "@material-ui/lab/Alert";
import ToggleSwitch from "../../components/ui/ToggleSwitch/ToggleSwitch";
const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export default function Gst2aRepository() {
  const fileInputRef = useRef(null);
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  

  const [fileName, setFileName] = useState("");
  const [fileDataToUpload, setFileDataToUpload] = useState("");
  const [trigarCircularProgress, setTrigarCircularProgress] = useState(false);

  //...............For Error Message..........................
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
    useState("hidden");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");

  //.........Record From Get Api.................
  const [recordFromApi, setRecordFromApi] = useState([]);
  const [recordFromApiForSearch, setRecordFromApiForSearch] = useState([]);

  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loaderForFileUpload, setLoaderForFileUpload] = useState(false);

  //This is for Gst Auto Download
  const [fromMonthYearDisplay, setFromMonthYearDisplay] = useState("");
  const [fromToYearDisplay, setFromToYearDisplay] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  //SnackBar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [message, setMessage] = useState("");

  // Function to close Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarSeverity("success");
    setResponseMessage("");
  };


  //...........Open And Close Event For Model............
  const handleOpenAndClose = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setErrorMessage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
  };

  const fileUploadCancelButtonHandler = () => {
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
  
    // Clear error message and upload states
    setErrorMessage("");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
      // Reset file input field to allow re-uploading the same file
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Clears the input value
      }
  };
  
  //......Handle Search....................
  const handleSearch = (e) => {
    let Search = e.target.value;
    const searchName = recordFromApiForSearch.filter((v) => {
      return (
        String(v.gstr_upload_file_type)
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0 ||
        String(v.financial_year).toLowerCase().indexOf(Search.toLowerCase()) >=
          0 ||
        String(v.generation_date).toLowerCase().indexOf(Search.toLowerCase()) >=
          0 ||
        String(v.upload_date).toLowerCase().indexOf(Search.toLowerCase()) >=
          0 ||
        String(moment(v.tax_period, "MMYYYY").format("MMMM-YYYY"))
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0
      );
    });
    setRecordFromApi(searchName);
  };

  //........For Succesfull Message..................
  const functionForSuccesfullMessage = () => {
    setColorForErrorMessage("green");
    setErrorMessageVisibilityStyle("visible");
    setErrorMessageDisplayStyle("block");
    // setErrorMessage("Data Fetched Successfully");
    // setTimeout(() => handleOpenAndClose(), 3000);
  };

  const handleFileUpload = (e) => {
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setErrorMessage("");

    if (e.target.files.length) {
      const file_data = e.target.files[0];
      let fileName = file_data.name;
      setFileName(fileName);

      // Check for PDF file type
      const fileType = file_data.type;
      if (fileType !== "application/pdf") {
        // Handle invalid file type
        setErrorMessageVisibilityStyle("visible");
        setErrorMessageDisplayStyle("block");
        setErrorMessage("Please attach only PDF files!");
        setLoaderForFileUpload(false);
        return; // Exit if the file is not a PDF
      }

      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      reader.onload = function () {
        let file_value = reader.result;
        setFileDataToUpload(file_value);
      };
    }
  };

  // Submit Event For File Upload in axios
  const handleSubmitEvent = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    if (fileDataToUpload === "") {
      setErrorMessageVisibilityStyle("visible");
      setErrorMessageDisplayStyle("block");
      setErrorMessage("Please Upload The File!");
      setLoaderForFileUpload(false);
      window.setTimeout(() => {
        setErrorMessageVisibilityStyle("visible");
      }, 2000);
    } else {
      setTrigarCircularProgress(true);
      setLoaderForFileUpload(true);
  
      const business_entity_id = localStorage.getItem("business_entity_id");
      const filename = fileName; // Get the filename
  
      axios
        .post(`/ais/upload_file`, {
          business_entity_id: business_entity_id,
          file: fileDataToUpload,
          document_name: filename, // Pass the filename as part of the payload
        })
        .then((res) => {
          setTrigarCircularProgress(false);
          setLoaderForFileUpload(false);
  
          if (res.data.status === false) {
            setErrorMessageVisibilityStyle("visible");
            setErrorMessageDisplayStyle("block");
            setErrorMessage(res.data.message);
          } else {
            setErrorMessage(res.data.message);
            functionForSuccesfullMessage();
            getApiForGst2aRepository();
  
            // Clear success message and reset form after 3 seconds
            setTimeout(() => {
              setErrorMessage(""); // Clear the success message
              setFileDataToUpload(""); // Clear the file data
              setFileName(""); // Clear the filename
              if (fileInputRef.current) {
                fileInputRef.current.value = null; // Clear the file input
              }
            }, 3000); // Clear after 3 seconds
          }
        })
        .catch((error) => {
          setErrorMessageVisibilityStyle("visible");
          setErrorMessageDisplayStyle("block");
          setErrorMessage(error.response?.data?.message || "");
          setLoaderForFileUpload(false);
        });
    }
  };
  

  //.......................Get Api For Gst................
  const getApiForGst2aRepository = () => {
    setLoaderForTable(true);
    const business_entity_id = localStorage.getItem("business_entity_id");
    axios
      .get(`/ais/get_ais_data/${business_entity_id}`)
      .then((res) => {
        if (res.data.status === false) {
          setLoaderForTable(false);
        } else {
          setLoaderForTable(false);
          setRecordFromApi(res.data.data);
        //   setRecordFromApiForSearch(res.data.record);
        }
      })
      .catch((error) => {
        setLoaderForTable(false);
        setErrorMessage("Error Fetching Data");
      });
  };

  useEffect(() => {
    getApiForGst2aRepository();
  }, []);


  const handleDownload = (id) => {
    const business_entity_id = localStorage.getItem('business_entity_id');
    
    axios.get(`/ais/download/${business_entity_id}/${id}`)
      .then((response) => {
        // Assuming the file data is under the `file` key and it's base64 encoded
        const base64Data = response.data.file; 
  
        // Convert base64 string to binary data
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
  
        // Create a Blob from the binary data
        const blob = new Blob([bytes], { type: 'application/pdf' });
  
        // Create a link element for downloading the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Set file name for the download
        link.setAttribute('download', `AISReport${id}.pdf`); // Change file name/extension as needed
  
        // Append the link to the body and trigger download
        document.body.appendChild(link);
        link.click();
        
        // Clean up the link element after download
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };
  
  

  return (
    <React.Fragment>
      <ToggleSwitch
        onChange={handleOpenAndClose}
        label="AIS Repository  "
      />

      {openAndCloseModel ? (
        <>
          <BoxWrapper boxTitle="Upload AIS">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <label className="button-primary" style={{ cursor: "pointer" }}>
                Upload
                <input
                  type="file"
                  accept=".pdf"
                  ref={fileInputRef} 
                  onChange={handleFileUpload}
                  hidden
                />
              </label>
              <div>
                <TextField
                  value={fileName}
                  variant="standard"
                  style={{ width: "350px", paddingLeft: "10px" }}
                  placeholder="No Data Have Uploaded"
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div>
                {loaderForFileUpload ? (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Uploading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleSubmitEvent}
                  >
                    Submit
                  </button>
                )}

                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={fileUploadCancelButtonHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
            <p style={{ color: "red" }}>{errorMessage}</p>
          </BoxWrapper>
        </>
      ) : (
        ""
      )}

      {/*..................Table.......................*/}

      <BoxWrapper
      boxTitle="AIS Repository"
      enableSearchInput={true}
      searchInputPlaceholder="Search..."
      searchInputOnchange={handleSearch} // Define this function as needed
      enableAddButton={true}
      addButtonLabel="Refresh"
      addButtonHandlerFn={getApiForGst2aRepository}
    >
      <div className="responsive-table">
        <table className="custome-table">
          <thead>
            <tr>
              <th>Date of Incorporation</th>
              <th>PAN</th>
              <th>Financial Year</th>
              <th>Assessment Year</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loaderForTable ? (
              <tr>
                <td
                  colSpan={9}
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Loading…Please wait
                </td>
              </tr>
            ) : recordFromApi.length === 0 ? (
              <tr>
                <td colSpan={9} style={{ color: "red", textAlign: "center" }}>
                  No Data Found!
                </td>
              </tr>
            ) : (
              recordFromApi.map((item) => (
                <tr key={item.date_of_incorporation}>
                 <td>{item.date_of_incorporation}</td>
                  <td>{item.pan}</td>
                  <td>{item.financial_year}</td>
                  <td>{item.assessment_year}</td>
                  <td>{item.status}</td>
                  <td>
                <IconButton onClick={() => handleDownload(item.id)}>
                  <DownloadIcon
                    style={{ cursor: "pointer" , color: "inherit" }}
                    sx={{ "&:hover": { color: "blue" } }}
                  />
                </IconButton>
              </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </BoxWrapper>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: "25%" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}
          {message}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}
