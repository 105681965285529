import React , {useEffect} from 'react';
import cwsLogo from "../../asserts/images/cws_final_logo.png";
import "./layout.css";
import axios from "../../axios-order";

const LandingPage = () => {
  
  useEffect( () => {
    axios.get(`cws_tally_uploads/get_url/${localStorage.getItem('business_entity_id')}`)
    .then( res => {
      let status = res.data.status;
      let urlForTally = res.data.url;
      localStorage.setItem("UploadTallyStatus" , status );
      localStorage.setItem("urlForTally" , urlForTally );
    } )
    .catch( () => {
      localStorage.setItem("UploadTally" , "false");
      localStorage.setItem("urlForTally" , "" );
    } )
  } , [] )
  
  return (
    <>
      {/* ---Welcome Message--- */}
      <div className="WelcomeMessage">
        <img src={cwsLogo} height={170} width={350} alt="Loading"/>
        <h3>Welcome to cappitall want Network</h3>
      </div>
    </>
  )
};


export default LandingPage;