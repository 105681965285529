import React, { Component, Fragment } from "react";
import classes from "./ARCCustomer.module.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios-order";

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [this.props.location.data],
    };
  }

  // table
  arc_Customer_show_TableHeader() {
    const tableHeader = [
      "Contact Name",
      "Contact Email",
      "Contact No",
      "Address",
      "State",
      "Country",
      "Account Manger Name",
      "Account Manger Email",
      "Account Manger Contact Number",
      "Credit Limit",
      "Credit days",
      "Registration Type",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc Customer table body
  arc_Customer_show_data() {

    if (String(this.state.allData[0]) !== "undefined") {
      return this.state.allData.map((tableData, index) => {
        const {
          CustRegistrationType,
          CustAcctmgrName,
          CustAcctmgrNo,
          CustAcctmgrEmail,
          CustCreditLimit,
          CustAddress,
          CustContactName,
          CustContactEmail,
          CustContactNo,
          CustState,
          CustCreditDays,
          CustCountry
        } = tableData; //destructuring
        return (
          <>
            <tr>
              <td>Contact Name</td>
              <td>{CustContactName}</td>
            </tr>
            <tr>
              <td>Contact Email</td>
              <td>{CustContactEmail}</td>
            </tr>
            <tr>
              <td>Contact Number</td>
              <td>{CustContactNo}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{CustAddress}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{CustState}</td>
            </tr>
            <tr>
              <td>Country</td>
              <td>{CustCountry}</td>
            </tr>
            <tr>
              <td>Account Manger Name</td>
              <td>{CustAcctmgrName}</td>
            </tr>
            <tr>
              <td>Account Manger Email</td>
              <td>{CustAcctmgrEmail}</td>
            </tr>
            <tr>
              <td>Account Manger contact Number</td>
              <td>{CustAcctmgrNo}</td>
            </tr>
            <tr>
              <td>Credit Limit</td>
              <td>{CustCreditLimit}</td>
            </tr>
            <tr>
              <td>Crdit Data</td>
              <td>{CustCreditDays}</td>
            </tr>
            <tr>
              <td>Registration Type</td>
              <td>{CustRegistrationType}</td>
            </tr>
          </>
        );
      })
    } else {
      return (
        <tr>
          <td style={{ color: "red", textAlign: "center" }} colspan={12}>
            No data found!
            </td>
        </tr>
      );
    }



  }


  //   ---Download---
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `gst_recon_export_${this.state.allData.filing_date}.xlsx`;
    axios
      .post(`/gst/download`, { data: { json_data: this.state.allData } })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    console.log("show Data================>", this.state.allData)
    return (
      <React.Fragment>
        <Container>
          {/* ---dashboard header--- */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={classes.Paper}
                style={{
                  background: "#c4d0d9",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >

                <br></br>Customer Detail
              </Paper>
            </Grid>
          </Grid>


          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.Main_frame}>
                <div className={classes.form_title_bar}>
                  <div class="form_title_name">
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: "0px",
                        padding: "0px",
                        marginTop: "0px",
                      }}
                    >
                      <li style={{ display: "inline-block" }}>Customer</li>
                      <li style={{ display: "inline-block", float: "right" }}>


                        <div>
                          {/* <Button onClick={this.downloadToExcel}>
                            Export to Excel
                          </Button> */}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={classes.form_body_part}>
                  <div className={classes.tableResponisve}>
                    <table className={classes.showDataTable}>
                      <tbody>
                        {/* <tr>{this.arc_Customer_show_TableHeader()}</tr> */}
                        {this.arc_Customer_show_data()}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
export default Showdetails;
