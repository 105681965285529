import React, { useState, useEffect } from 'react';
import classes from './CreditEvalution.module.css';
import PieChart from './PieChart';
import { Button, Grid } from '@material-ui/core';
import axios from '../../axios-order';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PdfDisplay from './PdfDisplay';

export default function TableForCriditEvalution({
  tableData,
  pieChart,
  handleOpenClose,
}) {
  const [tableDataToDisplay, setTableDataToDisplay] = useState(tableData);
  const [tableDataToSearch, setTableDataToSearch] = useState(tableData);
  const [sendingPdfDataForDownloadingPdf, setSendingPdfDataForDownloadingPdf] =
    useState(null);
  const [dataForPieChartToDisaplay, setDataForPieChartToDisaplay] = useState(
    []
  );
  const [triggerPdfDisplay, setTriggerPdfDisplay] = useState(false);
  const [pdfVariable, setPdfVariable] = useState(null);

  useEffect(() => {
    setTableDataToDisplay(tableData);
    setTableDataToSearch(tableData);
    const dataForPieChart = pieChart.filter((v) => v.y !== 0);
    setDataForPieChartToDisaplay(dataForPieChart);
  }, [tableData, pieChart]);

  //............Pdf Download ......................
  const downloadPdfButton = (data) => {
    setSendingPdfDataForDownloadingPdf(data);
    setTriggerPdfDisplay(true);
    axios
      .post(`/CE_PDF_config_for_UI`, {
        business_entity_id: localStorage.getItem('business_entity_id'),
        details: data,
      })
      .then((res) => {
        if (res.data.status === true) {
          setPdfVariable(res.data.details);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((error) => console.log(error));
  };

  //...........Search...........
  const handleSearch = (e) => {
    let inputSearch = e.target.value;
    const search = tableDataToSearch.filter((v) => {
      return (
        String(v.customer_name)
          .toLowerCase()
          .indexOf(inputSearch.toLowerCase()) >= 0
      );
    });
    setTableDataToDisplay(search);
  };

  //..............DownloadBase For Export To Excell..............
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleCloseButtonForPdf = () => {
    setTriggerPdfDisplay(false);
    setPdfVariable(null);
  };

  const export_to_excel = () => {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Credit Evaluation Report.xlsx`;
    axios
      .post(`/credit_evaluation/download`, {
        details: tableData,
        business_entity_id: localStorage.getItem('business_entity_id'),
      })
      .then((res) => {
        if (res.data.status === 'pass') {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert('Unable to process download....');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {triggerPdfDisplay ? (
        <PdfDisplay
          handleCloseButton={handleCloseButtonForPdf}
          dataForPdf={pdfVariable}
          dataForPdfDownload={sendingPdfDataForDownloadingPdf}
        />
      ) : (
        <>
          <div className={classes.strightLine}>Credit Evaluation Reports</div>
          <div className={classes.pieChart}>
            <PieChart
              // pieChartTitle="Risk Level"
              data={dataForPieChartToDisaplay}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button
                size="small"
                variant="contained"
                component="label"
                fullWidth
                style={{ marginTop: '5px', padding: '5px' }}
                onClick={handleOpenClose}
              >
                back
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <div style={{ textAlign: 'center' }}>
                <Button
                  style={{
                    marginTop: '5px',
                    border: '1px solid #138e9c',
                    color: '#138e9c',
                  }}
                  onClick={export_to_excel}
                >
                  Export To Excel
                </Button>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <input
                className={classes.inputSearch}
                style={{ width: '100%', marginTop: '5px' }}
                type="text"
                placeholder="Search By Customer Name..."
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
          <div className={classes.tableResponsive}>
            <table className={classes.LongTableCss}>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>DPO</th>
                  <th>DSO</th>
                  <th>DIO</th>
                  <th>Net Worth Calculation</th>
                  <th>Current Ratio</th>
                  <th>Operating Profit</th>
                  <th>Total Score</th>
                  <th>Zone</th>
                  <th>Net Worth %</th>
                  <th>Sales(months)</th>
                  <th>Calculated Credit limit</th>
                  <th>Show</th>
                </tr>
              </thead>
              <tbody>
                {tableDataToDisplay.map((v) => {
                  return (
                    <tr tabindex="0">
                      <td>{v.customer_name}</td>
                      <td>{v.dpo}</td>
                      <td>{v.dso}</td>
                      <td>{v.dio}</td>
                      <td>{v.net_worth_calculation}</td>
                      <td>{v.current_ratio}</td>
                      <td>{v.operating_profit}</td>
                      <td>{v.total_score}</td>
                      <td>{v.zone}</td>
                      <td>{v.net_worth}</td>
                      <td>{v.sales}</td>
                      <td>{v.calculated_credit_limit}</td>
                      <td>
                        <p
                          style={{ cursor: 'pointer' }}
                          onClick={() => downloadPdfButton(v)}
                        >
                          <VisibilityIcon />
                        </p>
                      </td>
                    </tr>
                  );
                })}
                {tableDataToDisplay.length === 0 && (
                  <tr>
                    <td
                      colSpan={10}
                      style={{ color: 'red', textAlign: 'center' }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
