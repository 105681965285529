import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../../axios-order";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import { useHistory } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const GstPayablityReport = () => {
    const { reconId } = useParams();
    let history = useHistory();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState("");


    useEffect(() => {
        getPayablityData();
    }, []);

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        setData(updatedData);
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);


    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };


    const getPayablityData = () => {
        axios.get(`/get_computation_report_data_table2/${reconId}`)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                setError(err.message || 'An error occurred');
            });
    };

    const handleSave = () => {
        const payLoadData = {
            'table3_data': data,
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'summary_id': reconId
        }
        setLoading(true)
        axios.post('/generate_computation_table2', payLoadData)
            .then(response => {
                handleSnackbarOpen(response?.data?.message, 'success')
                setLoading(false)
                localStorage.setItem('is_confirm', response?.data?.is_confirm)
                getPayablityData()

            })
            .catch(error => {
                handleSnackbarOpen(error?.data?.message, 'error')
                setLoading(false)
            });
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <BoxWrapper
                boxTitle="GST PAYABILITY"
                // exportToExcelEnable={true}
                backButtonForZohoEnable={true}

                backButton={() => history.push(`/gst_computation_report_data/${reconId}`)}
            >
                <div className="responsive-table" style={{ minHeight: (data && data.length > 0) && '54vh' }}>
                    <table className="custome-table">
                        <thead>
                            <tr>
                                <th>Particulars</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>S/UTGST</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data ? (
                                data.length > 0 ? (
                                    data.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.particulars}</td>
                                            {row.particulars === "Opening ITC carried forward from prev month" ? (
                                                <>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={row.igst}
                                                            onChange={(e) => handleChange(index, 'igst', e.target.value)}
                                                            step="0.01"
                                                            style={{ width: '50%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={row.cgst}
                                                            onChange={(e) => handleChange(index, 'cgst', e.target.value)}
                                                            step="0.01"
                                                            style={{ width: '50%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={row.sgst}
                                                            onChange={(e) => handleChange(index, 'sgst', e.target.value)}
                                                            step="0.01"
                                                            style={{ width: '50%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}
                                                        />
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>{row.igst.toFixed(2)}</td>
                                                    <td>{row.cgst.toFixed(2)}</td>
                                                    <td>{row.sgst.toFixed(2)}</td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td style={{ color: "red" }} colSpan={4}>
                                            No data found!
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td colSpan={4}>Loading... Please wait...</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            </BoxWrapper>
            <LoadingButton
                color="primary"
                onClick={handleSave}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                style={{ width: '10%', height: '35px', marginTop: '10px', marginLeft: '2%', alignSelf: 'flex-end' }}
            >
                <span>Save</span>
            </LoadingButton>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default GstPayablityReport;
