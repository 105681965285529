import React, { Component } from "react";
import classes from "./BuyerOnboarding.module.css";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../../../components/ui/Table/Table";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

class BuyerOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataCol: [
        { key: "cust_legal_name" },
        { key: "cust_gstin" },
        { key: "cust_pan" },
      ],
      data: null,
      oldData: null,
      dataHeader: [
        { label: "Customer Legal Name" },
        { label: "Customer GSTIN" },
        { label: "Customer PAN" },
      ],
      searchInput: "",
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  getCustomers() {
    axios
      .get("/bl/get_customers/" + localStorage.getItem("business_entity_id"))
      .then((res) => {
        this.setState({
          data: res.data.data,
          oldData: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error, { autoClose: 5000 });
      });
  }

  componentDidMount() {
    this.getCustomers();
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldData.length) {
        let dataNew = this.state.oldData.filter((value) => {
          return (
            String(value.invoice_no).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.cust_legal_name).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.cust_gstin).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          data: dataNew,
        });
      }
    } else {
      this.setState({
        data: this.state.oldData,
      });
    }
  };

  onSubmitHandler() {
    toast.configure();
    let selected_ids = [];
    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );

    Object.keys(selected_input).map((v, i) => {
     return selected_ids.push(selected_input[v].value);
    });
    this.setState({
      data: null,
    });
    axios
      .post("/bl/post_empanelled_buyers", {
        buyers: selected_ids,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status) {
          this.getCustomers();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.getCustomers();
      });
  }

  render() {
    return this.state.data === null ? (
      <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , marginTop:"100px"}}>
        <div><CircularProgress size={40} /></div>
        <div>Loading Please Wait</div>
      </div>
    ) : (
      <React.Fragment>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => {
              console.log(errors);
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>

        {/* table */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: "none",
                      marginBottom: "0px",
                      padding: "0px",
                      marginTop: "0px",
                    }}
                  >
                    <li style={{ display: "inline-block" }}>
                      Buyer Onboarding
                    </li>
                    <li
                      style={{ display: "inline-block", float: "right" }}
                    ></li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <ValidatorForm
                        ref="form"
                        // onSubmit={this.onSubmitHandler}
                        // onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          textLabel="Search"
                          inputType="text"
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ""}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  {/* <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_selleridAgency_TableHeader()}</tr>
                      {this.arc_selleridAgency_TableBody()}
                    </tbody>
                  </table> */}
                  <CustomTable
                    headers={this.state.dataHeader}
                    columns={this.state.dataCol}
                    data={this.state.data}
                    multiselect={true}
                    multiselectkey="cust_pan"
                    name="invoices"
                    selectCondition={(item) => !item.empanelled_id}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* datatable component */}
        {/* <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTable
                headers={this.state.dataHeader}
                columns={this.state.dataCol}
                data={this.state.data}
                multiselect={true}
                multiselectkey="cust_pan"
                name="invoices"
                selectCondition={(item) => !item.empanelled_id}
              />
            </Grid>
          </Grid>
        </div> */}
      </React.Fragment>
    );
  }
}

export default BuyerOnboarding;
