import React, { Component } from "react";
import classes from "./InvoiceAuthorization.module.css";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../../../components/ui/Table/Table";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};
class InvoiceAuthorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataCol: [
        { key: "cust_legal_name" },
        { key: "cust_gstin" },
        { key: "invoice_no" },
        {
          key: "invoice_date",
          render: (index, row) => {
            return dateFormat(row["invoice_date"]);
          },
        },
        {
          key: "due_date",
          render: (index, row) => {
            return dateFormat(row["due_date"]);
          },
        },
        { key: "taxable_amount" },
        { key: "tax_amount" },
        { key: "total_amount" },
        {
          key: "status",
          render: (index, row) => {
            return row["status"] === "" ? (
              <CircularProgress size={25} />
            ) : (
              // <this.GetStatus index={index} row={row} />
              this.GetStatus({ index: index, row: row })
            );
          },
        },
      ],
      data: null,
      dataHeader: [
        { label: "Customer Legal Name" },
        { label: "Customer GSTIN" },
        { label: "Invoice No" },
        { label: "Invoice Date" },
        { label: "Due Date" },
        { label: "Taxable Amount" },
        { label: "GST Amount" },
        { label: "Total Amount" },
        { label: "Status" },
      ],
      searchInput: "",
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  updateStatus = (index, invoice_id) => {
    let data = this.state.data;
    data[index]["status"] = "";
    this.setState({
      data,
    });
    axios
      .get(
        "/bl/get_invoice_status/" +
          localStorage.getItem("business_entity_id") +
          "/" +
          invoice_id
      )
      .then((res) => {
        if (res.data.status) {
          let status = res.data.data.status;
          if (String(status).toLowerCase() === "approved") {
            this.getCustomers();
          } else {
            data[index]["status"] = status;
            this.setState({
              data,
            });
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  GetStatus = (props) => {
    if (String(props.row["status"]).toLowerCase() === "accepted") {
      return (
        <Button
          onClick={() => {
            this.updateStatus(props.index, props.row["invoice_id"]);
          }}
        >
          <Alert severity="success">
            {props.row["status"]}
            <RefreshIcon />
          </Alert>
        </Button>
      );
    } else if (String(props.row["status"]).toLowerCase() === "rejected") {
      return (
        <Button
          onClick={() => {
            this.updateStatus(props.index, props.row["invoice_id"]);
          }}
        >
          <Alert severity="error">
            {props.row["status"]}
            {/* <RefreshIcon /> */}
          </Alert>
        </Button>
      );
    } else if (props.row["status"] === "initialized") {
      return (
        <Button
          onClick={() => {
            this.updateStatus(props.index, props.row["invoice_id"]);
          }}
        >
          <Alert severity="info">
            {props.row["status"]}
            <RefreshIcon />
          </Alert>
        </Button>
      );
    } else {
      return "";
    }
  };

  getCustomers() {
    this.setState({
      data: null,
    });
    axios
      .get("/bl/get_invoices/" + localStorage.getItem("business_entity_id"))
      .then((res) => {
        this.setState({
          data: res.data.data,
          oldData: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateEmpanelledBuyers = () => {
    toast.configure();
    this.setState({
      data: null,
    });
    axios
      .get("/bl/update_buyers/" + localStorage.getItem("business_entity_id"))
      .then((res) => {
        if (res.data.status) {
          this.getCustomers();
        }
      })
      .catch((error) => {
        this.getCustomers();
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  componentDidMount() {
    this.getCustomers();
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldData.length) {
        let dataNew = this.state.oldData.filter((value) => {
          return (
            String(value.invoice_no).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.cust_legal_name).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.cust_gstin).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.status).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          data: dataNew,
        });
      }
    } else {
      this.setState({
        data: this.state.oldData,
      });
    }
  };

  onSubmitHandler() {
    let selected_ids = [];
    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );

    Object.keys(selected_input).map((v, i) => {
     return selected_ids.push(selected_input[v].value);
    });
    this.setState({
      data: null,
    });
    axios
      .post("/bl/post_invoice_authorizing", {
        invoices: selected_ids,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status) {
          this.getCustomers();
        }
      })
      .catch((error) => {});
  }

  render() {
    return this.state.data === null ? (
      <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , marginTop:"100px"}}>
        <div><CircularProgress size={40} /></div>
        <div>Loading Please Wait</div>
      </div>
    ) : (
      <React.Fragment>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => {
              console.log(errors);
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.updateEmpanelledBuyers}
                  fullWidth
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p>
                          In case you are unable to find your invoices to be
                          sent for authorization, please refresh empanelled
                          Buyers
                        </p>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon />
                  </HtmlTooltip>
                  Refresh Empanelled Buyers
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>

        {/* table */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: "none",
                      marginBottom: "0px",
                      padding: "0px",
                      marginTop: "0px",
                    }}
                  >
                    <li style={{ display: "inline-block" }}>
                      Invoice Authorization
                    </li>
                    <li
                      style={{ display: "inline-block", float: "right" }}
                    ></li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <ValidatorForm
                        ref="form"
                        // onSubmit={this.onSubmitHandler}
                        // onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          textLabel="Search"
                          inputType="text"
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ""}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  <CustomTable
                    headers={this.state.dataHeader}
                    columns={this.state.dataCol}
                    data={this.state.data}
                    multiselect={true}
                    multiselectkey="invoice_id"
                    name="invoices"
                    selectCondition={(item) => item.status === null}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default InvoiceAuthorization;
