import React, { useState, useEffect } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import DatePicker from "../../../../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const InterestCalculateTwo = ({ backToReport }) => {
  const [
    grandtotalDelayedPaymentTaxLiabilityAmount,
    setGrandtotalDelayedPaymentTaxLiabilityAmount,
  ] = useState(0);
  const [
    grandTotalDelayedPaymentInterestAmount,
    setGrandTotalDelayedPaymentInterestAmount,
  ] = useState(0);
  const [
    grandTotalDelayedPaymentPayableAmount,
    setGrandTotalDelayedPaymentPayableAmount,
  ] = useState(0);

  const indianRupeesFormat = (value) =>
    value !== ""
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumSignificantDigits: 10,
        }).format(value)
      : "";

  const [
    interestOnExcessAvailmentOfInputTaxCredit,
    setInterestOnExcessAvailmentOfInputTaxCredit,
  ] = useState({
    PaymentDueDateIGST: null,
    PaymentDateIGST: null,
    DelayInPaymentIGST: "",
    TaxLiabilityAmountIGST: "",
    InterestAmountIGST: "",
    TotatPayableAmount: "",
  });

  const [
    interestOnExcessAvailmentOfInputTaxCreditCGST,
    setInterestOnExcessAvailmentOfInputTaxCreditCGST,
  ] = useState({
    PaymentDueDateCGST: null,
    PaymentDateCGST: null,
    TaxLiabilityAmountCGST: "",
    DelayInPaymentCGST: "",
    InterestAmountCGST: "",
    TotatPayableAmountCGST: "",
  });

  const [
    interestOnExcessAvailmentOfInputTaxCreditSGST,
    setInterestOnExcessAvailmentOfInputTaxCreditSGST,
  ] = useState({
    PaymentDueDateSGST: null,
    PaymentDateSGST: null,
    TaxLiabilityAmountSGST: "",
    DelayInPaymentSGST: "",
    InterestAmountSGST: "",
    TotatPayableAmountSGST: "",
  });

  useEffect(() => {
    let Total =
      parseInt(
        interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST || 0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST ||
          0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST ||
          0
      );
    setGrandtotalDelayedPaymentTaxLiabilityAmount(Total);
  }, [
    interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST,
    interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST,
    interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST,
  ]);

  useEffect(() => {
    let TotalInterest =
      parseInt(
        interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST || 0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST || 0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST || 0
      );
    setGrandTotalDelayedPaymentInterestAmount(TotalInterest);
  }, [
    interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST,
    interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST,
    interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST,
  ]);

  useEffect(() => {
    let TotalPayable =
      parseInt(
        interestOnExcessAvailmentOfInputTaxCredit.TotatPayableAmount || 0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditCGST.TotatPayableAmountCGST ||
          0
      ) +
      parseInt(
        interestOnExcessAvailmentOfInputTaxCreditSGST.TotatPayableAmountSGST ||
          0
      );
    setGrandTotalDelayedPaymentPayableAmount(TotalPayable);
  }, [
    interestOnExcessAvailmentOfInputTaxCredit.TotatPayableAmount,
    interestOnExcessAvailmentOfInputTaxCreditCGST.TotatPayableAmountCGST,
    interestOnExcessAvailmentOfInputTaxCreditSGST.TotatPayableAmountSGST,
  ]);

  const calculateInterestAmount = (
    TaxLiabilityAmount,
    DelayInPayment,
    TaxType
  ) => {
    const value =
      (parseInt(TaxLiabilityAmount) * 24 * parseInt(DelayInPayment)) / 36500;
    console.log(TaxLiabilityAmount, DelayInPayment, value);
    if (TaxType === "IGST") {
      setInterestOnExcessAvailmentOfInputTaxCredit({
        ...interestOnExcessAvailmentOfInputTaxCredit,
        InterestAmountIGST: Math.round(value),
      });
    } else if (TaxType === "CGST") {
      setInterestOnExcessAvailmentOfInputTaxCreditCGST({
        ...interestOnExcessAvailmentOfInputTaxCreditCGST,
        InterestAmountCGST: Math.round(value),
      });
    } else {
      setInterestOnExcessAvailmentOfInputTaxCreditSGST({
        ...interestOnExcessAvailmentOfInputTaxCreditSGST,
        InterestAmountSGST: Math.round(value),
      });
    }
  };

  useEffect(() => {
    console.log("hell");
    if (
      interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST !== "" &&
      interestOnExcessAvailmentOfInputTaxCredit.DelayInPaymentIGST !== ""
    ) {
      calculateInterestAmount(
        interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST,
        interestOnExcessAvailmentOfInputTaxCredit.DelayInPaymentIGST,
        "IGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST,
    interestOnExcessAvailmentOfInputTaxCredit.DelayInPaymentIGST,
  ]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST !==
        "" &&
      interestOnExcessAvailmentOfInputTaxCreditCGST.DelayInPaymentCGST !== ""
    ) {
      calculateInterestAmount(
        interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST,
        interestOnExcessAvailmentOfInputTaxCreditCGST.DelayInPaymentCGST,
        "CGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST,
    interestOnExcessAvailmentOfInputTaxCreditCGST.DelayInPaymentCGST,
  ]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST !==
        "" &&
      interestOnExcessAvailmentOfInputTaxCreditSGST.DelayInPaymentSGST !== ""
    ) {
      calculateInterestAmount(
        interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST,
        interestOnExcessAvailmentOfInputTaxCreditSGST.DelayInPaymentSGST,
        "SGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST,
    interestOnExcessAvailmentOfInputTaxCreditSGST.DelayInPaymentSGST,
  ]);

  const calculateDateDifferent = (DueDate, PaymentDate, TaxType) => {
    const taxLabilityIGSTDueDate = moment(DueDate).format("MM/DD/YYYY");
    const taxLabilityIGSTDate = moment(PaymentDate).format("MM/DD/YYYY");
    const date1 = new Date(taxLabilityIGSTDueDate);
    const date2 = new Date(taxLabilityIGSTDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (TaxType === "IGST") {
      setInterestOnExcessAvailmentOfInputTaxCredit({
        ...interestOnExcessAvailmentOfInputTaxCredit,
        DelayInPaymentIGST: diffDays,
      });
    } else if (TaxType === "CGST") {
      setInterestOnExcessAvailmentOfInputTaxCreditCGST({
        ...interestOnExcessAvailmentOfInputTaxCreditCGST,
        DelayInPaymentCGST: diffDays,
      });
    } else {
      setInterestOnExcessAvailmentOfInputTaxCreditSGST({
        ...interestOnExcessAvailmentOfInputTaxCreditSGST,
        DelayInPaymentSGST: diffDays,
      });
    }
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
  };

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCredit.PaymentDueDateIGST !== null &&
      interestOnExcessAvailmentOfInputTaxCredit.PaymentDateIGST !== null
    ) {
      calculateDateDifferent(
        interestOnExcessAvailmentOfInputTaxCredit.PaymentDueDateIGST,
        interestOnExcessAvailmentOfInputTaxCredit.PaymentDateIGST,
        "IGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCredit.PaymentDueDateIGST,
    interestOnExcessAvailmentOfInputTaxCredit.PaymentDateIGST,
  ]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDueDateCGST !==
        null &&
      interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDateCGST !== null
    ) {
      calculateDateDifferent(
        interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDueDateCGST,
        interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDateCGST,
        "CGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDueDateCGST,
    interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDateCGST,
  ]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDueDateSGST !==
        null &&
      interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDateSGST !== null
    ) {
      calculateDateDifferent(
        interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDueDateSGST,
        interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDateSGST,
        "SGST"
      );
    }
  }, [
    interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDueDateSGST,
    interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDateSGST,
  ]);

  const calculateTotalPayableAmount = (
    TaxLiabilityAmount,
    InterestAmount,
    TaxType
  ) => {
    const intersetAmount =
      parseInt(TaxLiabilityAmount) + parseInt(InterestAmount);
    console.log(intersetAmount, InterestAmount);
    if (TaxType === "IGST") {
      setInterestOnExcessAvailmentOfInputTaxCredit({
        ...interestOnExcessAvailmentOfInputTaxCredit,
        TotatPayableAmount: Math.round(intersetAmount),
      });
    } else if (TaxType === "CGST") {
      setInterestOnExcessAvailmentOfInputTaxCreditCGST({
        ...interestOnExcessAvailmentOfInputTaxCreditCGST,
        TotatPayableAmountCGST: Math.round(intersetAmount),
      });
    } else {
      setInterestOnExcessAvailmentOfInputTaxCreditSGST({
        ...interestOnExcessAvailmentOfInputTaxCreditSGST,
        TotatPayableAmountSGST: Math.round(intersetAmount),
      });
    }
  };

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST !== "" &&
      interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST,
        interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST,
        "IGST"
      );
    }
  }, [interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST !==
        "" &&
      interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST,
        interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST,
        "CGST"
      );
    }
  }, [interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST]);

  useEffect(() => {
    if (
      interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST !==
        "" &&
      interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnExcessAvailmentOfInputTaxCreditSGST.TaxLiabilityAmountSGST,
        interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST,
        "SGST"
      );
    }
  }, [interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST]);

  return (
    <>
      <BoxWrapper boxTitle="Interest on Excess Availment of Input Tax Credit">
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <ValidatorForm
            useRef="form"
            // onSubmit={onSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
              <thead>
                <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                  <th>Tax type</th>
                  <th>ITC reversal amount</th>
                  <th>Availment due date</th>
                  <th>ITC reversal date</th>
                  <th>Period of availment</th>
                  <th>Interest amount</th>
                  <th>Total payable amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnExcessAvailmentOfInputTaxCredit({
                          ...interestOnExcessAvailmentOfInputTaxCredit,
                          TaxLiabilityAmountIGST: value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCredit.PaymentDueDateIGST
                      }
                      disabled={
                        interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCredit({
                          ...interestOnExcessAvailmentOfInputTaxCredit,
                          PaymentDueDateIGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCredit.PaymentDateIGST
                      }
                      disabled={
                        interestOnExcessAvailmentOfInputTaxCredit.TaxLiabilityAmountIGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCredit({
                          ...interestOnExcessAvailmentOfInputTaxCredit,
                          PaymentDateIGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {
                            interestOnExcessAvailmentOfInputTaxCredit.DelayInPaymentIGST
                          }
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCredit.InterestAmountIGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCredit.TotatPayableAmount
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <td>CGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnExcessAvailmentOfInputTaxCreditCGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditCGST,
                          TaxLiabilityAmountCGST: value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDueDateCGST
                      }
                      disabled={
                        interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCreditCGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditCGST,
                          PaymentDueDateCGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCreditCGST.PaymentDateCGST
                      }
                      disabled={
                        interestOnExcessAvailmentOfInputTaxCreditCGST.TaxLiabilityAmountCGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCreditCGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditCGST,
                          PaymentDateCGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {
                            interestOnExcessAvailmentOfInputTaxCreditCGST.DelayInPaymentCGST
                          }
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCreditCGST.InterestAmountCGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCreditCGST.TotatPayableAmountCGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <td>SGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnExcessAvailmentOfInputTaxCreditSGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditSGST,
                          TaxLiabilityAmountSGST: value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDueDateSGST
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCreditSGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditSGST,
                          PaymentDueDateSGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnExcessAvailmentOfInputTaxCreditSGST.PaymentDateSGST
                      }
                      onChange={(v) =>
                        setInterestOnExcessAvailmentOfInputTaxCreditSGST({
                          ...interestOnExcessAvailmentOfInputTaxCreditSGST,
                          PaymentDateSGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {
                            interestOnExcessAvailmentOfInputTaxCreditSGST.DelayInPaymentSGST
                          }
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCreditSGST.InterestAmountSGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnExcessAvailmentOfInputTaxCreditSGST.TotatPayableAmountSGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr style={{ background: "#BFBFBF", fontWeight: "bold" }}>
                  <td>Total</td>
                  <td>{grandtotalDelayedPaymentTaxLiabilityAmount}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{grandTotalDelayedPaymentInterestAmount}</td>
                  <td style={{ color: "red" }}>
                    {grandTotalDelayedPaymentPayableAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </ValidatorForm>
        </div>
      </BoxWrapper>
    </>
  );
};

export default InterestCalculateTwo;
