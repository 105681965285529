import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { SelectValidator } from 'react-material-ui-form-validator';
// import { ListItemSecondaryAction } from '@material-ui/core';

const BasicTextFields = (props) => {
    return (
        <div style={{ marginTop: "20px" }}>
            <label>{props.SelectLabel}<span style={{ color: 'red' }}>{props.requiredField}</span>:</label>
            <SelectValidator validators={props.validator} inputProps={props.inputProps ? props.inputProps : {}}
                errorMessages={props.errorMsg} variant="outlined" size="small" fullWidth="true" name={props.name} value={props.value} style={{ background: "#fff", borderRadius: "5px" }} onChange={props.textOnchangeFunction}>
                {props.choices && props.choices.length > 0 ? (
                    props.choices.map((items) => (
                        <MenuItem
                            key={items[props.choice_id]} // Ensure each MenuItem has a unique key
                            value={items[props.choice_id]}
                        >
                            {items[props.choice_name]}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>
                        <Typography variant="body2" style={{ color: 'red', textAlign: 'center', width: '100%' }}>
                            No options available
                        </Typography>
                    </MenuItem>
                )}
            </SelectValidator>
        </div>
    );
};

export default BasicTextFields;