import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ValidatorComponent } from "react-material-ui-form-validator";

class ValidatedDatePicker extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      minDateForDatepicker,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...rest}
            minDate={minDateForDatepicker}
            fullWidth
            // label="Month And Year"
            views={["month", "year"]}
            size="small"
            // format="dd/MM/yyyy"
            inputVariant="outlined"
            error={!isValid}
            inputProps={{ readOnly: true }}
            helperText={(!isValid && this.getErrorMessage()) || helperText}
            style={{ background: "#fff", borderRadius: "5px" }}
            // KeyboardButtonProps={{
            //     'aria-label': 'change date',
            //   }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default ValidatedDatePicker;
