import React, { useState, useEffect } from 'react';
import ColumnChart from '../../../components/charts/barchart';
import { Button, Container, Grid, Paper, Typography,Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {PieChart, Pie, Cell,Legend, ResponsiveContainer,Sector} from 'recharts';
import CircularProgress from "@mui/material/CircularProgress";
import axios from '../../../axios-order';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from "react-router-dom";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCards from './DashBoard';
import Top5CustomerChart from './top_5cutomer_bar_chart'


const fetchDateOptions = () => {
    const currentDate = new Date();
    const options = [];
    for (let i = 11; i >= 0; i--) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() - i;
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const formattedDate = `${lastDayOfMonth.getFullYear()}-${('0' + (lastDayOfMonth.getMonth() + 1)).slice(-2)}-${('0' + lastDayOfMonth.getDate()).slice(-2)}`;
        options.push(formattedDate);
    }
    return options.reverse()

};

const colors = ["#4318FF", "#6AD2FF", "#607d8b"];

const RADIAN = Math.PI / 180;




const AgeingReportDashBoard = () => {
    let history = useHistory();
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false)
    const [graphData, setGraphData] = useState([])
    const [apGraphData, setApGraphData] = useState([])
    const [activeIndex, SetActiveIndex] = useState(0)
    const [value, setValue] = useState(0);
    const [apValue, setApValue] = useState(0)
    const [ageingRecivableData, setAgeingRecivableData] = useState({})
    const [top5CustomerData, setTop5CustomerData] = useState([])
    const [monthlyTrenChartData, setMonthlyTrenChartData] = useState([])
    const [selectedReportType, setSelectedReportType] = useState('ageing_recivable')
    const [selectedReportTypeForTop5, setSelectedReportTypeForTop5] = useState('ageing_recivable')
    const [selectedAsOfDate, setSelectedAsOfDate] = useState(fetchDateOptions()[0]);
    const [selectedGSTInvoiceType, setSelectedGSTInvoiceType] = useState('');
    const [selectedAccountManager, setSelectedAccountManager] = useState('');


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 1) {
            setGraphData(ageingRecivableData.ar_not_yet_due_by_age_summary)
        } else {
            setGraphData(ageingRecivableData.ar_overdue_by_age_summary)
        }
    };

    const handleChangeAP = (event, newValue) => {
        setApValue(newValue);
        if (newValue == 1) {
            setApGraphData(ageingRecivableData.ap_not_yet_due_by_age_summary)
        } else {
            setApGraphData(ageingRecivableData.ap_overdue_by_age_summary)
        }
    }


    useEffect(() => {
        setLoading(true)
        getAgeingReportDashboardData()

    }, [selectedAsOfDate, selectedGSTInvoiceType, selectedAccountManager])

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getAgeingReportDashboardData = () => {
        let payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'filter_date': getTodayDate(),

        }

        if (selectedGSTInvoiceType) {
            payLoadData['gst_invoice_type'] = selectedGSTInvoiceType
        }

        if (selectedAccountManager) {
            payLoadData['acc_manger'] = selectedAccountManager
        }

        axios.post('/get_ageing_report_dashboard_data', payLoadData)
            .then((res) => {
                console.log(res.data.status)
                if (res.data.status) {
                    setDashboardData(res.data.ageing_report_dashboard_data.card_details)
                    setGraphData(res.data.ageing_report_dashboard_data.ar_overdue_by_age_summary)
                    setApGraphData(res.data.ageing_report_dashboard_data.ap_overdue_by_age_summary)
                    setAgeingRecivableData(res.data.ageing_report_dashboard_data)
                    setTop5CustomerData(res.data.ageing_report_dashboard_data.ar_top_5_customers_by_outstanding_amount)
                    setMonthlyTrenChartData(res.data.ageing_report_dashboard_data.ar_monthly_trend_chart_for_not_yet_due_and_overdue_amount)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const onPieEnter = (_, index) => {
        SetActiveIndex(index)
    };

    const renderPeiGraph = () => {


        const renderActiveShape = (props) => {
            const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const mx = cx + (outerRadius + 10) * cos;
            const my = cy + (outerRadius + 10) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;

            return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">
                        {`Amount: ₹${value.toFixed(2)}`}
                    </text>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>
                </g>
            );
        };

        if (!graphData || graphData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={graphData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total_amount"
                        onMouseEnter={onPieEnter}
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );


    };

    const renderPeiGraphAp = () => {
        const renderActiveShape = (props) => {
            const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
            const sin = Math.sin(-RADIAN * midAngle);
            const cos = Math.cos(-RADIAN * midAngle);
            const mx = cx + (outerRadius + 10) * cos;
            const my = cy + (outerRadius + 10) * sin;
            const ex = mx + (cos >= 0 ? 1 : -1) * 22;
            const ey = my;

            return (
                <g>
                    <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 6}
                        outerRadius={outerRadius + 10}
                        fill={fill}
                    />
                    <path d={`M${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#333">
                        {`Amount: ₹${value.toFixed(2)}`}
                    </text>
                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={cos >= 0 ? 'start' : 'end'} fill="#999">
                        {`(Rate ${(percent * 100).toFixed(2)}%)`}
                    </text>
                </g>
            );
        };

        if (!apGraphData || apGraphData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={apGraphData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total_amount"
                        onMouseEnter={onPieEnter}
                    >
                        {apGraphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );
    }

    const renderBarChart = () => {
        if (!monthlyTrenChartData || monthlyTrenChartData.length === 0) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <img
                        src="https://img.freepik.com/premium-vector/server-icon-flat-design_1039903-499.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user"
                        alt="No Data"
                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                    <Typography variant="subtitle1" gutterBottom>Empty Dataset</Typography>
                </div>
            );
        }

        const chartData = [
            {
                name: "Overdue",
                data: monthlyTrenChartData.map(item => item["Over due"] || 0),
            },
            {
                name: "Not Yet Due",
                data: monthlyTrenChartData.map(item => item["Not yet due"] || 0),
            }
        ];

        const categories = monthlyTrenChartData.map(item => item.period);

        return (
            <ColumnChart
                chartData={chartData}
                categories ={categories}
            />
        );
    };

    const onChngeMonthlyChart = (e) => {
        setSelectedReportType(e.target.value)
        if (e.target.value === "ageing_recivable") {
            setMonthlyTrenChartData(ageingRecivableData.ar_monthly_trend_chart_for_not_yet_due_and_overdue_amount)
        } else {
            setMonthlyTrenChartData(ageingRecivableData.ap_monthly_trend_chart_for_not_yet_due_and_overdue_amount)
        }
    }

    const onChngeTop5CustomersChart = (e) => {
        setSelectedReportTypeForTop5(e.target.value)
        if (e.target.value === "ageing_recivable") {
            setTop5CustomerData(ageingRecivableData.ar_top_5_customers_by_outstanding_amount)
        } else {
            setTop5CustomerData(ageingRecivableData.ap_top_5_customers_by_outstanding_amount)
        }
    }

    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>
                <Button
                    onClick={() => history.push('ageing_recivable_report_dashboard')}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    sx={{
                        borderColor: '#2196f3',
                        color: '#2196f3',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#2196f3',
                            color: 'white',
                            transform: 'scale(1.1)',
                        },
                        '&:active': {
                            transform: 'scale(1)',
                        },
                    }}
                >
                    <EqualizerIcon sx={{ mr: 1 }} />
                    Ageing Receivable
                </Button>

                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            letterSpacing: '0.04rem',
                            fontFamily: `'Montserrat', sans-serif`,
                            textTransform: 'uppercase',
                        }}
                    >
                        <span style={{ fontWeight: 'bold', color: '#1D3557' }}>Ageing Report</span>
                        <span style={{ fontWeight: 'normal', color: "#212121" }}> Dashboard</span>
                        <AnalyticsIcon style={{ marginLeft: '10px', color: '#E76F51', fontSize: '1.8rem', verticalAlign: 'middle' }} />
                    </Typography>
                </div>

                <Button
                    onClick={() => history.push('ageing_payble_report_dashboard')}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    sx={{
                        borderColor: '#2196f3',
                        color: '#2196f3',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#2196f3',
                            color: 'white',
                            transform: 'scale(1.1)',
                        },
                        '&:active': {
                            transform: 'scale(1)',
                        },
                    }}
                >
                    <DashboardIcon sx={{ mr: 1 }} />
                    Ageing Payable
                </Button>
            </div>

            {!loading ? (
            <Grid container lg={12} spacing={1} >

                <Grid lg={12} container
                    md={6}
                    direction="row"
                    mb={2}
                    spacing={1.8}>

                    <DashboardCards
                        dashboardData={dashboardData}
                    />

                </Grid>
                <Grid item xs={6} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>


                            <Typography
                                variant="body1"
                                style={{
                                    color: '#3B5998',
                                    fontWeight: 700,

                                    marginBottom: 2,
                                    textTransform: 'capitalize',
                                    fontFamily: `'Poppins', sans-serif`,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                AGEING RECEIVABLE AGE SUMMARY
                            </Typography>


                        </div>
                        <Tabs
                            onChange={handleChange}
                            value={value}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab label="OVERDUE" />
                            <Tab label="NOT YET DUE" />
                        </Tabs>
                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderPeiGraph()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={6} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#3B5998',
                                    fontWeight: 700,
                                    marginBottom: 2,
                                    textTransform: 'capitalize',
                                    fontFamily: `'Poppins', sans-serif`,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                AGEING PAYABLE AGE SUMMARY
                            </Typography>
                        </div>
                        <Tabs
                            onChange={handleChangeAP}
                            value={apValue}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab label="OVERDUE" />
                            <Tab label="NOT YET DUE" />
                        </Tabs>
                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderPeiGraphAp()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Top5CustomerChart
                    top5CustomerData={top5CustomerData}
                    selectedReportTypeForTop5={selectedReportTypeForTop5}
                    onChngeTop5CustomersChart={onChngeTop5CustomersChart}
                />


                <Grid item xs={12} md={12}>
                    <Paper sx={{ p: 2, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#3B5998',
                                    fontWeight: 700,
                                    marginBottom: '5px',
                                    textTransform: 'capitalize',
                                    fontFamily: `'Poppins', sans-serif`,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                MONTHLY TREND CHART FOR NOT YET DUE AND DUE AMOUNT.
                            </Typography>

                            <div>
                                <FormControl style={{ flex: '1', minWidth: 200, maxWidth: '20%' }} variant="outlined">
                                    <InputLabel>Report Type</InputLabel>
                                    <Select

                                        value={selectedReportType}
                                        onChange={onChngeMonthlyChart}
                                        label="Report Type"
                                        fullWidth
                                        style={{ height: '48px' }}
                                    >
                                        <MenuItem value="ageing_recivable">Ageing Receivable</MenuItem>
                                        <MenuItem value="ageing_payble">Ageing Payable</MenuItem>
                                    </Select>
                                </FormControl>


                            </div>

                        </div>

                        <ResponsiveContainer style={{ marginTop: '1%' }} width="100%" height={300} id="chart-container">
                            {renderBarChart()}
                        </ResponsiveContainer>
                    </Paper>
                </Grid>


            </Grid>)

                : <div style={{ textAlign: "center", marginTop: 50 }}>
                    <CircularProgress />
                    <br />
                    Loading, Please wait...
                </div>}
        </Container>
    )
}

export default AgeingReportDashBoard