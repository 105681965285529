import React from "react";

const PayableSummaryTable = (props) => {
  const { payCycleSummaryTableData } = props;

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <div>
      <table className="custome-table">
        <thead>
          <tr>
            <th>Expense Type</th>
            <th>Total Payables</th>
            <th>Over Due</th>
            <th>Current Due</th>
            <th>Not Yet Due</th>
          </tr>
        </thead>
        <tbody>
          {"error" in payCycleSummaryTableData ? (
            <tr>
              <td colSpan={5} style={{ color: "red" }}>
                No Data Found!
              </td>
            </tr>
          ) : (
            <>
              {Object.keys(payCycleSummaryTableData).map((v, i) => {
                return v !== "total_overdue" &&
                  v !== "total_current_due" &&
                  v !== "total_not_yet_due" &&
                  v !== "total_total_paybles" &&
                  v !== "from_date" &&
                  v !== "to_date" &&
                  v !== "as_of_date" ? (
                  <>
                    <tr>
                      <td>{v}</td>
                      <td>
                        {indianRupeesFormat(
                          payCycleSummaryTableData[v].total_paybles
                        )}
                      </td>
                      <td>
                        {indianRupeesFormat(
                          payCycleSummaryTableData[v].OverDue
                        )}
                      </td>
                      <td>
                        {indianRupeesFormat(
                          payCycleSummaryTableData[v]["Current Due"]
                        )}
                      </td>
                      <td>
                        {indianRupeesFormat(
                          payCycleSummaryTableData[v]["Not Yet Due"]
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                );
              })}
              <tr>
                <td>Grand Total</td>
                <td>
                  {indianRupeesFormat(
                    payCycleSummaryTableData.total_total_paybles
                  )}
                </td>
                <td>
                  {indianRupeesFormat(payCycleSummaryTableData.total_overdue)}
                </td>
                <td>
                  {indianRupeesFormat(
                    payCycleSummaryTableData.total_current_due
                  )}
                </td>
                <td>
                  {indianRupeesFormat(
                    payCycleSummaryTableData.total_not_yet_due
                  )}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PayableSummaryTable;
