import React, { useState, useEffect } from 'react';
import BoxWrapper from '../boxwra';
import ConfirmationDialog from '../../../Sales/TDSReconciliationSale/ConfirmationDailog';


function Six({ handleBack, updateTableData, data }) {
  const [tableData, setTableData] = useState(data);
  const [tableName, setTableName] = useState("6.Details of ITC availed during the financial year");
  const [saveDailog, setSaveDailogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmBack, setConfirmBack] = useState(false);

  const submitHandle = () => {
    updateTableData(tableName, tableData);
    setUnsavedChanges(false);
  };


  const handleBackButton = () => {
    if (unsavedChanges) {
      setConfirmBack(true);
    } else {
      handleBack();
    }
  };

  const confirmBackHandle = () => {
    submitHandle();
    handleBack();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [itemName, field, type] = name.split('.');



    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === itemName && (item.type === type || (item.type === null && type === ''))
          ? { ...item, [field]: value }
          : item
      )
    );

    setUnsavedChanges(true);
  };

  const getSubTotalRowValue = (item, key_name) => {

    // console.log(key_name)
    let value = 0
    switch (item.name) {
      case "(I) Sub total (B to H above)":

        for (let i = 1; i < 15; i++) {
          value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }
        
        return value.toFixed(2)

      case "(J) Difference (I - A) above":

        let I_val = 0
        for (let i = 1; i < 15; i++) {
          I_val += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }
        return I_val.toFixed(2) - parseFloat(tableData[0][key_name])

      case "(N) Sub total (K to M above)":

        for (let i = 17; i < 20; i++) {
          value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
        }

        return value.toFixed(2)

      case "(O) Total ITC availed (I + N) above":
       
        let n_value = 0
        let i_value = 0
        for (let i = 17; i < 20; i++) {
          n_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
        }

        for (let i = 1; i < 15; i++) {
          i_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }
        const res = parseFloat(n_value) + parseFloat(i_value)

        return res.toFixed(2)


    }
    // console.log(value)
    return value.toFixed(2)
  }

  const renderTableRows = () => {
    const rowSpanConditions = {
      1: 3,
      4: 3,
      7: 3,
      10: 2
    };



    return tableData.map((item, index) => {
      const rowSpan = rowSpanConditions[index] || 0;
      switch (true) {
        case item.name === "(I) Sub total (B to H above)":
        case item.name === "(J) Difference (I - A) above":
        case item.name === "(N) Sub total (K to M above)":
        case item.name === "(O) Total ITC availed (I + N) above":
          return (
            <tr key={index} style={{ backgroundColor: 'white' }}>
              {rowSpan ? (
                <>
                  <td rowSpan={rowSpan}>{item.name}</td>
                  <td>{item.type}</td>
                </>
              ) : item.type ? (
                <>
                  <td>{item.type}</td>
                </>
              ) : (
                <>
                  <td colSpan={1}>{item.name}</td>
                  <td></td>
                </>
              )}
              <td>
                <input
                  type="text"
                  name={`${item.name}.igst.${item.type ? item.type : ''}`}
                  value={getSubTotalRowValue(item, 'igst')}
                  // onChange={handleInputChange}
                  disabled={!item.manual_type}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.cgst.${item.type ? item.type : ''}`}
                  value={getSubTotalRowValue(item, 'cgst')}
                  // onChange={handleInputChange}
                  disabled={!item.manual_type}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.sgst.${item.type ? item.type : ''}`}
                  value={getSubTotalRowValue(item, 'sgst')}
                  // onChange={handleInputChange}
                  disabled={!item.manual_type}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.cess.${item.type ? item.type : ''}`}
                  value={getSubTotalRowValue(item, 'cess')}
                  // onChange={handleInputChange}
                  disabled={!item.manual_type}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                />
              </td>
            </tr>
          );

        default:

          return (
            <tr key={index} style={{ backgroundColor: 'white' }}>
              {rowSpan ? (
                <>
                  <td rowSpan={rowSpan}>{item.name}</td>
                  <td>{item.type}</td>
                </>
              ) : item.type ? (
                <>
                  <td>{item.type}</td>
                </>
              ) : (
                <>
                  <td colSpan={1}>{item.name}</td>
                  <td></td>
                </>
              )}
              <td>
                <input
                  type="text"
                  name={`${item.name}.igst.${item.type ? item.type : ''}`}
                  value={item.igst}
                  onChange={handleInputChange}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.cgst.${item.type ? item.type : ''}`}
                  value={item.cgst}
                  onChange={handleInputChange}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.sgst.${item.type ? item.type : ''}`}
                  value={item.sgst}
                  onChange={handleInputChange}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${item.name}.cess.${item.type ? item.type : ''}`}
                  value={item.cess}
                  onChange={handleInputChange}
                  disabled={!item.manual_type}
                />
              </td>
            </tr>
          );
      }
    });
  };


  return (
    <>
      <BoxWrapper
        backButtonForZohoEnable={true}
        backButton={handleBackButton}
        boxTitle={tableName}
      >
        <>
          <p style={{ marginLeft: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Note: </span> The fields where the system computed values would be modified by more/less 20% shall be highlighted in <span style={{ color: 'red' }}>Red</span> for reference and attention.
          </p>
          <hr style={{ marginLeft: '10px' }} />
        </>

        <div style={{ maxHeight: '47vh' }} className="responsive-table">
          <table style={{ width: '100%', backgroundColor: 'white' }} className="summary-tab-single-year-table-css">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Details</th>
                <th>Type</th>
                <th>Integrated Tax (₹)</th>
                <th>Central Tax (₹)</th>
                <th>State/UT Tax (₹)</th>
                <th>CESS (₹)</th>
              </tr>
            </thead>
            <tbody>
              {(tableData && tableData.length >= 1) ? renderTableRows() : (
                <tr>
                  <td colSpan={6} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      <ConfirmationDialog
        open={confirmBack}
        onClose={() => setConfirmBack(false)}
        onConfirm={confirmBackHandle}
        title="Unsaved Changes"
        content="You have unsaved changes. Do you want to save them before leaving?"
      />
    </>
  );
}

export default Six;
