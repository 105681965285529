import React,{useState,useEffect} from 'react';
 import BoxWrapper from "../../../../components/ui/boxWrapper";
import axios from "../../../../axios-order";



import DownloadIcon from "@mui/icons-material/Download";



const customModalStyles = {
    content: {
      width: '200px',
      height: '40px',
      margin:'470px',
      paddingLeft: '50px',
      backgroundColor: '#ed3434',
      overflow:'hidden',
      left:'90px'
    }
  };

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL 

export default function Gst2aStatus() {
  const [status, setStatus] = useState(false);
    const [data, setData] = useState([]);
  
    const [errorMessage, setErrorMessage] = useState('');
    const [showBoxWrapper, setShowBoxWrapper] = useState(false);
    const [responseData, setResponseData] = useState("");
      const [displayResponse, setDisplayResponse] = useState(false);  
      const [taskId, setTaskId] = useState('');
      const [showDownloadButton,setShowDownloadButton]=useState(false)
      const [downloadButtonId,setDownloadBtnId]=useState('')

      useEffect(() => {
        // Call the additional API only when taskId is available
        if (taskId) {
          const additionalApiUrl = `/autodownloadapi/${taskId}`;
          axios.get(additionalApiUrl)
            .then(response => {
              console.log('Additional API response:', response.data);
              // Process the additional API response as needed
            })
            .catch(error => {
              console.error('Error calling additional API:', error);
            });
        }
      }, [taskId]);

    


  const handleButtonClick = () => {
    setShowBoxWrapper(!showBoxWrapper);
  };
 //This is for just Refresh button
 const handleRefresh = () => {
  axios
    .get(`${apiUrl}/gstDownload/gstrclientstatus/${localStorage.getItem('business_entity_id')}`)
    .then((response) => {
      setData(response.data);
      console.log(response);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

useEffect(() => {
  handleRefresh(); // Call the API on component mount
}, []);
  
    useEffect(() => {
         axios
          .get(`${apiUrl}/gstDownload/gstrclientstatus/${localStorage.getItem("business_entity_id")}`)
        
          .then(response => {
            setData(response.data);
            console.log(response)
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
      }, []);
data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

      const handleDownload = (gst_task_id, business_entity_id) => {
        setShowDownloadButton(true)
        setDownloadBtnId(gst_task_id)
        const url = `${apiUrl}/gstDownload/gstrtaskdownload/`;
          const requestData = new FormData();
          requestData.append('task_id', gst_task_id);
          requestData.append('entity_id', business_entity_id);
      
        axios.post(url, requestData)
          .then(response => {
            console.log('API response:', response.data);
            setResponseData(response.data)
            setDisplayResponse(true);
            const autoTaskId = extractAutoTaskId(response.data);
        if (autoTaskId) {
          setTaskId(autoTaskId);
        }

            setTimeout(() => {
              setResponseData('');
              setDisplayResponse(false);
            }, 10000); // 5 seconds in milliseconds
          })
          
            // Process the response data as needed
         
          .catch(error => {
            console.error('Error sending data:', error);
           
          });
      };
      const extractAutoTaskId = (response) => {
        const autoTaskIdPattern = /Auto Task id :(\d+)/;
        const match = response.match(autoTaskIdPattern);
        return match ? match[1] : null;
      };

      

    const handle = (gst_task_id, business_entity_id) => {
      const url = `${apiUrl}/gstDownload/gstrtaskstatus/`;
      const requestData = new FormData();
      requestData.append('task_id', gst_task_id);
      requestData.append('entity_id', business_entity_id);
    
      axios.post(url, requestData)
        .then(response => {
           
          console.log('API response:', response.data);
        
          // Process the response data as needed
          handleRefresh();
        })
        .catch(error => {
          console.error('Error sending data:', error);
          // setErrorMessage('An error occurred. Please try again later.');
          if (error.response && error.response.status !== 200) {
            setErrorMessage('An error occurred.');
          }
         
        });
    };
    
    const business_entity_id = localStorage.getItem('business_entity_id');


    const handleClick = (first_col, second_col) => {
      axios.post(`${apiUrl}/table/`, { first_col, second_col })
        .then(response => {
          console.log('Data sent to backend:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
    };

    
    return (
      <>
        <BoxWrapper
      boxTitle="GSTR Status"
      style={{ height: '900px' }}
      enableAddButton={true}
          addButtonLabel="Refresh"
          addButtonHandlerFn={handleRefresh}
    >
      <div className="responsive-table">
        <table className="custome-table">
          <thead>
            <tr>
              <th>GST Requested</th>
              <th>Created Date</th>
              <th>Modified Date</th>
              <th>From</th>
              <th>To</th>
              <th>Task ID</th>
              <th>Status</th>
              <th>Pull Data</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
                <tr key={item.auto_task_id}>
                  <td>{item.gst_requested}</td>
                  <td>{item.created_date.split('T')[0]}</td>
                  <td>{item.modified_date.split('T')[0]}</td>
                  <td>{item.from_period}</td>
                  <td>{item.to_period}</td>
                  <td>{item.gst_task_id}</td>
                  <td>{item.task_status}</td>
                
<td>
  {item.task_status === 'completed' && item.download_status === null ? (
    !(showDownloadButton && item.gst_task_id===downloadButtonId)&&<button className="download-button" onClick={() => handleDownload(item.gst_task_id, business_entity_id)}>
      <DownloadIcon className="action-icon-button download" />
    </button>
  ) : (
    item.task_status === 'Initiated' || item.task_status === 'queued' || item.task_status === 'Queued' ? (
      <button className="download-button" onClick={() => handle(item.gst_task_id, business_entity_id)}>Check</button>
    ) : null
  )}
</td>

                </tr>
              ))}
            {data.length <= 0 && (
              <tr>
                <td colSpan={5} style={{ color: "red", textAlign: "center" }}>
                  No Data Found!!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

    
    </BoxWrapper>

    {responseData && (
 <div>
        <p style={{fontSize:'20px',color:'green'}}>DOWNLOADED GSTR DATA <br /></p>
        <p>{responseData}</p>
</div>
    )}
      </>
    );
  }