import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import "./login.css";
import cws_logo from "../../asserts/images/cws_final_logo.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "../../axios-order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cappitall Want Solutions (CWS) offers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{ textDecoration: "underline" }}>
          Invoice Discounting & Invoice Collection Services-
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We are a market place for{" "}
          <span style={{ fontWeight: 600 }}>Invoice Discounting</span> and{" "}
          <span style={{ fontWeight: 600 }}>Collection service</span>s and
          assist to reach multiple service providers efficiently to ensure you
          choose the one best suited for your organization. You get to choose
          multiple service providers to help you in optimizing debtors related
          working capital management
        </p>
        <h5 style={{ textDecoration: "underline" }}>
          ARC – Accounting Reconciliation and Compliance
        </h5>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We provide assistance in ready to upload{" "}
          <span style={{ fontWeight: 600 }}>Accounting entries</span> relating
          to Invoice Discounting and Collection transactions ensuring accuracy
          and efficiency in handling them.
        </p>
        <p style={{ marginTop: "20px", marginBottom: "20px" }}>
          We track the entire receivable cycle including discounting and
          collection services if availed within our platform or outside and
          enhances the visibility of debtors related working capital management
          with access to various dashboards and reporting.
        </p>
        <p>
          We also help in{" "}
          <span style={{ fontWeight: 600 }}>Reconciliation</span> and{" "}
          <span style={{ fontWeight: 600 }}>Compliance</span> of TDS and GST
          related transactions.
        </p>
        <h6
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Want to know how? Reach our customer support!! @
          <a style={{ color: "#000" }} href="mailto:support@cappitallwant.com">
            {" "}
            support@cappitallwant.com
          </a>
        </h6>
        <p style={{ textAlign: "center" }}>
          <span style={{ fontWeight: 600 }}>Coming Soon... </span> Onboarding
          more solution partners for Invoice discounting and collections!!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const MyLoginPage = () => {
  let history = useHistory();
  const [user_email_id, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [Forgotpassword, setForgotpassword] = React.useState(false);
  const [Message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   event.preventDefault();
  // };

  // onchange function for email and password
  const onChangeHandler = (event) => {
    event.preventDefault();
    if (event.target.name === "user_email_id") {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  // onSubmit function
  let trigger = 1;
  const submitHandler = () => {
    setLoading(true);
    toast.configure();
    if (Forgotpassword) {
      axios
        .post("/forgot_password", { user_email_id })
        .then((res) => {
          setStatus(res.data.status);
          setMessage(res.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 2000 });
        });
    } else {
      axios
        .post("/login", { user_email_id, password })
        .then((res) => {
          setLoading(false);
          trigger = 1;
          const user_token = res.data.token;
          const user_role = res.data.user_role;
          const user_id = res.data.id;
          const user_name = res.data.user_name;
          localStorage.setItem("token", user_token);
          localStorage.setItem("role", user_role);
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("user_name", user_name);

          history.push("/home");
        })
        .catch((error) => {
          if (error.response !== undefined) {
            trigger += 1;
            if (trigger < 5) {
              submitHandler();
            } else {
              setLoading(false);
              trigger = 1;
              toast.error(error.response.data.message, { autoClose: 2000 });
            }
          } else {
            trigger += 1;
            if (trigger < 5) {
              submitHandler();
            } else {
              setLoading(false);
              trigger = 1;
              toast.error("Server Error Try After Some Time.", {
                autoClose: 2000,
              });
            }
          }
        });
    }
  };

  // route function for registation page
  const toRegistationPage = () => {
    history.push("/Registration");
  };

  const fogotPasswordHandler = () => {
    setForgotpassword(true);
  };

  const justLogin = () => {
    setForgotpassword(false);
    setStatus("");
    setMessage("");
    setUsername("");
  };

  return (
    <div id="Login-section">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <div className="login-form-section">
              <div>
                <img className="loginLogo" src={cws_logo} alt="cappitallwant" />
              </div>

              {Forgotpassword ? (
                ""
              ) : (
                <div className="login-form-title">
                  <center>Login here!</center>
                </div>
              )}
              {Forgotpassword ? (
                <div>
                  <center style={{ fontSize: "24px" }}>
                    Reset your password
                  </center>
                  <center style={{ fontSize: "14px" }}>
                    Enter your user account's verified email address and we will
                    send you a temporary password.
                  </center>
                </div>
              ) : (
                ""
              )}

              <div className="login-form-body">
                <ValidatorForm
                  onSubmit={submitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <TextValidator
                    margin="dense"
                    fullWidth={true}
                    label="Email Id"
                    name="user_email_id"
                    value={user_email_id}
                    variant="outlined"
                    size="small"
                    validators={["required"]}
                    errorMessages={["Please enter the Email id."]}
                    onChange={onChangeHandler}
                  />
                  {Forgotpassword ? (
                    ""
                  ) : (
                    <TextValidator
                      margin="dense"
                      fullWidth={true}
                      label="Password"
                      name="password"
                      variant="outlined"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      validators={["required"]}
                      errorMessages={["Please enter the password."]}
                      onChange={onChangeHandler}
                      // icon for show password

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff size="small" />
                              ) : (
                                <Visibility size="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <IconButton
                      //       aria-label="toggle password visibility"
                      //       onClick={handleClickShowPassword}
                      //       // onMouseDown={handleMouseDownPassword}
                      //       edge="end"
                      //     >
                      //       {showPassword ? <VisibilityOff /> : <Visibility />}
                      //     </IconButton>
                      //   </InputAdornment>
                      // }
                    />
                  )}

                  <Button
                    style={{
                      marginTop: "15px",
                      fontFamily: "Dosis",
                      fontSize: "16px",
                      fontWeight: "550",
                    }}
                    type="submit"
                    margin="dense"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                  >
                    {Forgotpassword ? (
                      "Reset Password"
                    ) : loading ? (
                      <>
                        <CircularProgress
                          size={22}
                          style={{ color: "white" }}
                        />{" "}
                        <span style={{ paddingLeft: "5px" }}></span>
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  {/* {Message?<DoneIcon style={{color:"green"}}/>:""} */}
                  {status ? (
                    <center style={{ color: "green" }}>{Message}</center>
                  ) : (
                    <center style={{ color: "red" }}>{Message}</center>
                  )}

                  <p className="RegisterLink">
                    Don't have an account yet?{" "}
                    <span
                      className="RegisterLinkSpan"
                      onClick={toRegistationPage}
                    >
                      Register
                    </span>
                  </p>
                  <div className="forgot-password">
                    {Forgotpassword ? (
                      <span style={{ cursor: "pointer" }} onClick={justLogin}>
                        Just Login
                      </span>
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={fogotPasswordHandler}
                      >
                        Forgot Password?
                      </span>
                    )}
                  </div>
                  <div className="know-more">
                    <a href="https://www.cappitallwant.com/" target="_blank ">
                      Know more
                    </a>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}></Grid>
        </Grid>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Container>
    </div>
  );
};

export default MyLoginPage;
