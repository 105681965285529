import React from "react";
import classes from "./Report.module.css";

function GSTSummaryDashBoard(props) {
  const { SummaryData } = props;

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---b2bExportData

  return (
    <>
      <div className={classes.B2cExport}>
        {Object.keys(SummaryData).length > 0  && (
          <>
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
              }}
            >
              <h6>Invoice(s)</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
         {SummaryData.invoices.map((invItem, i) => {
                 return (
                   <tr key={i}>
                      <td>{invItem.Particulars}</td>
                      <td>{invItem.No_Of_Invoice}</td>
                      <td>{numberFormat(invItem.Taxable_Value)}</td>
                      <td>{numberFormat(invItem.IGST)}</td>
                      <td>{numberFormat(invItem.CGST)}</td>
                      <td>{numberFormat(invItem.SGST)}</td>
                      <td>{numberFormat(invItem.Cess)}</td>
                      <td>{numberFormat(invItem.Total_GST)}</td>
                      <td>{numberFormat(invItem.Total_Invoice_Value)}</td>
                   </tr>
                 );
                })}
              </table>
            </div>

            {/* ........credit notes....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Credit Notes</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {SummaryData.creditNotes.map((invItem, i) => {
                  return (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
                      <td>{invItem.No_Of_Invoice}</td>
                      <td>{numberFormat(invItem.Taxable_Value)}</td>
                      <td>{numberFormat(invItem.IGST)}</td>
                      <td>{numberFormat(invItem.CGST)}</td>
                      <td>{numberFormat(invItem.SGST)}</td>
                      <td>{numberFormat(invItem.Cess)}</td>
                      <td>{numberFormat(invItem.Total_GST)}</td>
                      <td>{numberFormat(invItem.Total_Invoice_Value)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>

            {/* .......Debit note....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Debit Notes</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {SummaryData.debitNotes.map((invItem, i) => {
                  return (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
                       <td>{invItem.No_Of_Invoice}</td>
                      <td>{numberFormat(invItem.Taxable_Value)}</td>
                      <td>{numberFormat(invItem.IGST)}</td>
                      <td>{numberFormat(invItem.CGST)}</td>
                      <td>{numberFormat(invItem.SGST)}</td>
                      <td>{numberFormat(invItem.Cess)}</td>
                      <td>{numberFormat(invItem.Total_GST)}</td>
                      <td>{numberFormat(invItem.Total_Invoice_Value)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>

            {/* .......Import invoices....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Import Invoices</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {SummaryData.importInvoices.map((invItem, i) => {
                  return (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
                    <td>{invItem.No_Of_Invoice}</td>
                      <td>{numberFormat(invItem.Taxable_Value)}</td>
                      <td>{numberFormat(invItem.IGST)}</td>
                      <td>{numberFormat(invItem.CGST)}</td>
                      <td>{numberFormat(invItem.SGST)}</td>
                      <td>{numberFormat(invItem.Cess)}</td>
                      <td>{numberFormat(invItem.Total_GST)}</td>
                      <td>{numberFormat(invItem.Total_Invoice_Value)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>

            {/* .......SEZ invoices....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>SEZ Invoices</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {SummaryData.sezInvoices.map((invItem, i) => {
                  return (
                    <tr key={i}>
                    <td>{invItem.Particulars}</td>
                   	<td>{invItem.No_Of_Invoice}</td>
                      <td>{numberFormat(invItem.Taxable_Value)}</td>
                      <td>{numberFormat(invItem.IGST)}</td>
                      <td>{numberFormat(invItem.CGST)}</td>
                      <td>{numberFormat(invItem.SGST)}</td>
                      <td>{numberFormat(invItem.Cess)}</td>
                      <td>{numberFormat(invItem.Total_GST)}</td>
                      <td>{numberFormat(invItem.Total_Invoice_Value)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GSTSummaryDashBoard;
