// import React, { useState } from "react";

// import classes from "./Report.module.css";
// import moment from "moment";
// import BoxWrapper from "../../../../../components/ui/boxWrapper";

// function MatchedInvoice(props) {
//   const { matchedInvoiceData } = props;
//   const [matchedInvoiceTableData, setMatchedInvoiceTableData] =
//     useState(matchedInvoiceData);

//   const [searchInput, setSearchInput] = useState("");
//   // const [searchInputFilter, setSearchInputFilter] = useState()

//   // indian date format
//   // const indianFormat = (date) => {
//   //     let dateArr = date.split("-");
//   //     return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
//   // };

//   // indian rupeess system---
//   const numberFormat = (value) =>
//     new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumSignificantDigits: 10,
//     }).format(value);
//   // ---End---

//   const matchedInvoiceTableBody = () => {
//     if (matchedInvoiceTableData.length >= 1) {
//       return matchedInvoiceTableData.map((tableData, index) => {
//         const {
//           GSTIN,
//           Vendor_Name,
//           DOC_NUMBER,
//           DOC_DATE,
//           DOC_TYPE,

//           Taxable_Value_Books,
//           CGST_Books,
//           SGST_Books,
//           IGST_Books,
//           Cess_Books,
//           Total_Amount_Books,
//           Taxable_Value_GSTR2A,
//           CGST_GSTR2A,
//           SGST_GSTR2A,
//           IGST_GSTR2A,
//           Cess_GSTR2A,
//           Total_Amount_Books_GSTR2A,
//         } = tableData;

//         return (
//           <tr>
//             <td>{GSTIN}</td>
//             <td>{Vendor_Name}</td>
//             <td>{DOC_NUMBER}</td>
//             <td>{DOC_DATE}</td>
//             <td>{DOC_TYPE}</td>
//             <td>{numberFormat(Taxable_Value_Books)}</td>
//             <td>{numberFormat(CGST_Books)}</td>
//             <td>{numberFormat(SGST_Books)}</td>
//             <td>{numberFormat(IGST_Books)}</td>
//             <td>{numberFormat(Cess_Books)}</td>
//             <td>{numberFormat(Total_Amount_Books)}</td>

//             <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
//             <td>{numberFormat(CGST_GSTR2A)}</td>
//             <td>{numberFormat(SGST_GSTR2A)}</td>
//             <td>{numberFormat(IGST_GSTR2A)}</td>
//             <td>{numberFormat(Cess_GSTR2A)}</td>
//             <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>
//           </tr>
//         );
//       });
//     } else {
//       return (
//         <tr>
//           <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
//             No Data Found!
//           </td>
//         </tr>
//       );
//     }
//   };
//   const searchInputHandler = (event) => {
//     let value = event.target.value;
//     // setSearchInput(value)

//     const fullFill = new Promise((res, rej) => {
//       setSearchInput(value);
//       return res(true);
//     });

//     fullFill.then(() => globalSearch());

//     // setSearchInputFilter(() => { globalSearch(event) })
//   };

//   const globalSearch = (event) => {
//     let searchData = matchedInvoiceData.filter((value) => {
//       const query = searchInput.toLowerCase();
//       return (
//         String(value.GSTIN).toLowerCase().indexOf(query) >= 0 ||
//         String(value.Vendor_Name).toLowerCase().indexOf(query) >= 0 ||
//         String(value.DOC_NUMBER).toLowerCase().indexOf(query) >= 0 ||
//         String(value.DOC_DATE).toLowerCase().indexOf(query) >= 0 ||
//         String(value.DOC_TYPE).toLowerCase().indexOf(query) >= 0
//       );
//     });
//     setMatchedInvoiceTableData(searchData);
//   };

//   return (
//     <>
//       <BoxWrapper
//         boxTitle="Reconciliation Status :Matched Invoice(s)"
//         // search input
//         enableSearchInput={true}
//         searchInputPlaceholder="Search..."
//         searchInputOnchange={searchInputHandler}
//       >
//         <div className={classes.ResponsiveTable}>
//           <table className={classes.missingInAccountTableCss}>
//             <tr>
//               <th rowSpan={2}>GSTIN</th>
//               <th rowSpan={2} align="right">
//                 VENDOR NAME
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC NUMBER
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC DATE
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC TYPE
//               </th>
//               <th style={{ background: "#ffcc66" }} colSpan={6} align="center">
//                 As per Books
//               </th>
//               <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
//                 As per GSTR2B
//               </th>
//             </tr>
//             <tr>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#ffcc66" }} align="right">
//                 TOTAL AMOUNT
//               </th>

//               <th style={{ background: "#c6ffb3" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#c6ffb3" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//             </tr>
//             {matchedInvoiceTableBody()}
//           </table>
//         </div>
//       </BoxWrapper>
//     </>
//   );
// }

// export default MatchedInvoice;
import React, { useEffect, useState } from 'react';
import classes from './Report.module.css';
import BoxWrapper from '../../../../../components/ui/boxWrapper';
import axios from "../../../../../axios-order";


function MatchedInvoice({  matchedInvoiceData,onTabClick }) {

  useEffect(()=>{
     onTabClick()
  },[onTabClick])

  const invoiceData = matchedInvoiceData?.record?.matched ?? [];

  const [searchInput, setSearchInput] = useState('');
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const filteredData = invoiceData.filter((invoice) => {
    // Perform a null check before accessing the 'includes' method
    const gstin = invoice.GSTIN || '';
    const vendorName = invoice.Vendor_Name || '';
    
          return (
     gstin.includes(searchInput) ||
    vendorName.includes(searchInput)
    );
     });


  


  return (
    <>
     <BoxWrapper
        // searchInputPlaceholder="Search..."
        boxTitle="Reconciliation Status: Matched Invoice(s)"
        //  enableSearchInput={true}
        
        //  searchInputOnchange={searchInputHandler}
          
        
      >

      <div className={classes.ResponsiveTable}>
        <table className={classes.missingInAccountTableCss}>
          {/* Table header */}
          <thead>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">VENDOR NAME</th>
              <th rowSpan={2} align="right">DOC NUMBER</th>
              <th rowSpan={2} align="right">DOC DATE</th>
              <th rowSpan={2} align="right">DOC TYPE</th>
              <th style={{ background: '#ffcc66' }} colSpan={6} align="center">As per Books</th>
              <th style={{ background: '#c6ffb3' }} colSpan={6} align="center">As per GSTR2B</th>
            </tr>
            <tr>
              <th style={{ background: '#ffcc66' }} align="right">TAXABLE VALUE</th>
              <th style={{ background: '#ffcc66' }} align="right">CGST</th>
              <th style={{ background: '#ffcc66' }} align="right">SGST</th>
              <th style={{ background: '#ffcc66' }} align="right">IGST</th>
              <th style={{ background: '#ffcc66' }} align="right">CESS</th>
              <th style={{ background: '#ffcc66' }} align="right">TOTAL AMOUNT</th>
              <th style={{ background: '#c6ffb3' }} align="right">TAXABLE VALUE</th>
              <th style={{ background: '#c6ffb3' }} align="right">CGST</th>
              <th style={{ background: '#c6ffb3' }} align="right">SGST</th>
              <th style={{ background: '#c6ffb3' }} align="right">IGST</th>
              <th style={{ background: '#c6ffb3' }} align="right">CESS</th>
              <th style={{ background: '#c6ffb3' }} align="right">TOTAL AMOUNT</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {filteredData.map((invoice, index) => (
              <tr key={index}>
                <td style={{ color: "black" }}>{invoice.GSTIN}</td>
                <td style={{ color: "black" }}>{invoice.Vendor_Name}</td>
                <td style={{ color: "black" }}>{invoice.DOC_NUMBER}</td>
                <td style={{ color: "black" }}>{invoice.DOC_DATE}</td>
                <td style={{ color: "black" }}>{invoice.DOC_TYPE}</td>
                <td style={{ color: "black" }}>{invoice.Taxable_Value_Books}</td>
                <td style={{ color: "black" }}>{invoice.CGST_Books}</td>
                <td style={{ color: "black" }}>{invoice.SGST_Books}</td>
                <td style={{ color: "black" }}>{invoice.IGST_Books}</td>
                <td style={{ color: "black" }}>{invoice.Cess_Books}</td>
                <td style={{ color: "black" }}>{invoice.Total_Amount_Books}</td>
                <td style={{ color: "black" }}>{invoice.Taxable_Value_GSTR2B}</td>
                <td style={{ color: "black" }}>{invoice.CGST_GSTR2B}</td>
                <td style={{ color: "black" }}>{invoice.SGST_GSTR2B}</td>
                <td style={{ color: "black" }}>{invoice.IGST_GSTR2B}</td>
                <td style={{ color: "black" }}>{invoice.Cess_GSTR2B}</td>
                <td style={{ color: "black" }}>{invoice.Total_Amount_Books_GSTR2B}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </BoxWrapper>
    </>
  );
}

export default MatchedInvoice;








