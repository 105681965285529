import React, { useEffect, useState } from "react";
import BoxWrapper from "../../components/ui/boxWrapper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Grid } from "@material-ui/core";
import DatePicker from "../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import moment from "moment";
import KarzaModal from "../../components/ui/basicModal";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Input from '@mui/material/Input';

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL

export default function GenerateReport({ backfun }) {

  const jsonkeyName = {
    report_type: "",
    financial_year: "",
    as_of_date: "",
    from_date: "",
    to_date: "",
    report_typeForApi: "",
  }

  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [triggerButtonForAsOfDate, setTriggerButtonForAsOfDate] = useState(false);
  const [subjectAreaJson, setsubjectAreaJson] = useState(jsonkeyName);
  const [reportTypeDropDown, setReportTypeDropDown] = useState([]);

  const [triggeremailId, setTriggeremailId] = useState(false);
  const [jsonData, setJsonData] = useState({
    oneEmail: localStorage.getItem("EmailId") || "",
    twoEmail: "",
    threeEmail: ""
  })

  const dateForFun = new Date();
  const date = dateForFun.getFullYear();

  const Financial = [
    `${date}-${parseInt(date) + 1}`,

    `${date - 1}-${parseInt(date - 1) + 1}`,

    `${date - 2}-${parseInt(date - 2) + 1}`,

    `${date - 3}-${parseInt(date - 3) + 1}`,

    `${date - 4}-${parseInt(date - 4) + 1}`,
  ];

  const handleFromDate = (v) => {
    setFromDate(v);
    const FromDate = new Date(v);
    // const pad = "00";
    // const yy = FromDate.getFullYear().toString();
    // const mm = (FromDate.getMonth() + 1).toString();
    // const dd = FromDate.getDate().toString();
    const yy = FromDate.getFullYear().toString();
    const mm = (FromDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = FromDate.getDate().toString().padStart(2, '0');

    setsubjectAreaJson({
      ...subjectAreaJson,
      // from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
      from_date: `${yy}-${(mm).slice(-2)}-${(dd).slice(-2)}`
    })
  }

  const handleToDate = (v) => {
    settoDate(v)
    const toDate = new Date(v);
    const pad = "00";
    // const yy = toDate.getFullYear().toString();
    // const mm = (toDate.getMonth() + 1).toString();
    // const dd = toDate.getDate().toString();
    const yy = toDate.getFullYear().toString();
    const mm = (toDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = toDate.getDate().toString().padStart(2, '0');
    setsubjectAreaJson({
      ...subjectAreaJson,
      // to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
        to_date: `${yy}-${(mm).slice(-2)}-${(dd).slice(-2)}`
    })
  }

  const handleAsofDate = () => {
    setTriggerButtonForAsOfDate(!triggerButtonForAsOfDate);
    const pad = "00";
    const yy = dateForFun.getFullYear().toString();
    const mm = (dateForFun.getMonth() + 1).toString();
    const dd = dateForFun.getDate().toString();
    setsubjectAreaJson({
      ...subjectAreaJson,
      as_of_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`
    })
  };


  const handleChangeForReportingType = (values) => {
    let splitValue = "";
    for (let x in reportTypeDropDown) {
      if (reportTypeDropDown[x] === values) splitValue = x;
    }

    setsubjectAreaJson({
      ...subjectAreaJson,
      report_type: values,
      report_typeForApi: splitValue,
    })

    splitValue = "";
  }

  const handleEmailID = () => {
    if (subjectAreaJson.report_type === "") {
      alert("Report Type can't be empty");
    } else if (subjectAreaJson.as_of_date === "" && subjectAreaJson.financial_year === "" && subjectAreaJson.from_date === "" && subjectAreaJson.to_date === "") {
      alert("Please select any one date filter");
    } else {
      setTriggeremailId(!triggeremailId);
      setJsonData({
        oneEmail: localStorage.getItem("EmailId") || "",
        twoEmail: "",
        threeEmail: ""
      })
    }
  }

  const handleDownload = () => {
    let FromDateForApi = "";
    let ToDateForApi = "";

    if (subjectAreaJson.report_type === "") {
      alert("Report Type can't be empty");
    } else if (subjectAreaJson.as_of_date === "" && subjectAreaJson.financial_year === "" && subjectAreaJson.from_date === "" && subjectAreaJson.to_date === "") {
      alert("Please select any one date filter");
    } else {
      if (subjectAreaJson.as_of_date !== "") {
        FromDateForApi = `2017-04-01`;
        ToDateForApi = subjectAreaJson.as_of_date;
      } else if (subjectAreaJson.financial_year !== "") {
        const splitDate = subjectAreaJson.financial_year.split("-");
        FromDateForApi = `${splitDate[0]}-04-01`;
        ToDateForApi = `${splitDate[1]}-03-31`;
      } else {
        FromDateForApi = moment(subjectAreaJson.from_date).format("YYYY-MM-DD");
        ToDateForApi = moment(subjectAreaJson.to_date).format("YYYY-MM-DD");
      }

      if (triggeremailId) {
          let arr = [];
          for (let i in jsonData) {
            if (jsonData[i] !== "") {
              arr.push(jsonData[i])
            }
          }

          // console.log(`http://192.168.100.207:8083/cwsapims/api/v1/report/email-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}&emailIds=[${arr}]`);
          // console.log(`${apiUrl}/cwsapims/api/v1/report/email-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}&emailIds=${arr.join(",")}`);

          // const download = document.createElement("a");
          // download.target = "_blank";
          // download.href = `http://192.168.100.207:8083/cwsapims/api/v1/report/email-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}&emailIds=[${arr}]`
          // download.click();
          const emailIds = arr.join(',');
          const url = `${apiUrl}/cwsapims/api/v1/report/email-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}&emailIds=${emailIds}`;
          console.log(url)
          window.open(url, "_blank");
          
          arr = [];
          FromDateForApi = "";
          ToDateForApi = "";
          setJsonData({
            oneEmail: localStorage.getItem("EmailId") || "",
            twoEmail: "",
            threeEmail:""
          })
          setFromDate(null);
          settoDate(null);
          setTriggerButtonForAsOfDate(false);
          setsubjectAreaJson(jsonkeyName);
          setTriggeremailId(false);
        

      } else {
        // const download = document.createElement("a");
        // download.target = "_blank";
        // download.href = `${apiUrl}/cwsapims/api/v1/report/download-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}`
        // download.click();
        axios
       .get(`${apiUrl}/cwsapims/api/v1/report/download-report?reportType=${subjectAreaJson.report_typeForApi}&businessEntityCode=${localStorage.getItem("business_entity_id")}&financialYear=&fromDate=${FromDateForApi}&toDate=${ToDateForApi}`)
      .then(response => {
        console.log(response);
        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
        FromDateForApi = "";
        ToDateForApi = "";
        setJsonData({
          oneEmail: localStorage.getItem("EmailId") || "",
          twoEmail: "",
          threeEmail: ""
        })
        setFromDate(null);
        settoDate(null);
        setTriggerButtonForAsOfDate(false);
        setsubjectAreaJson(jsonkeyName);
        setTriggeremailId(false);
      }
    }
    backfun();
  }

  useEffect(() => {
    axios.get(`${apiUrl}/cwsapims/api/v1/report/dropDown`)
      .then(res => setReportTypeDropDown(res.data))
      .catch(() => setReportTypeDropDown([]))

    if (triggerButtonForAsOfDate === false) {
      setsubjectAreaJson({
        ...subjectAreaJson,
        as_of_date: ""
      })
    }
  }, [triggerButtonForAsOfDate]);

  return (
    <>
      <KarzaModal
        title="Email Address"
        open={triggeremailId}
        modalSize={"lg"}
        onClose={handleEmailID}
        modalBody={
          <>
            <Grid container spacing={1} style={{ padding: "20px" }}>
              <Grid item xs={12} lg={4} >
                <InputLabel htmlFor="input-with-icon-adornment">
                  User 1 Email Id:
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  disabled={localStorage.getItem("EmailId") ? true : false}
                  value={jsonData.oneEmail}
                  onChange={(e) => setJsonData({ ...jsonData, oneEmail: e.target.value })}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} lg={4} >
                <InputLabel htmlFor="input-with-icon-adornment">
                  User 2 Email Id:
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  onChange={(e) => setJsonData({ ...jsonData, twoEmail: e.target.value })}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </Grid>
              
              <Grid item xs={12} lg={4} >
                <InputLabel htmlFor="input-with-icon-adornment">
                  User 3 Email Id:
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  onChange={(e) => setJsonData({ ...jsonData, threeEmail: e.target.value })}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <button
                  type="button"
                  className="button-primary"
                  style={{ width: "100%", padding: "8px", marginTop: "20px" }}
                  onClick={handleDownload}
                >
                  Send
                </button>
              </Grid>
            </Grid>
          </>
        }
        enableFooter={false}
      />
      <BoxWrapper
        boxTitle="Generate Report"
        backButtonForZohoEnable={true}
        backButton={backfun}
      >
        <>
          <Grid container spacing={1}>
            <Grid item xs={6} lg={3}>
              <label style={{ color: "black" }} >Report<sup className="redcolor">*</sup>:</label> <br />
              <Autocomplete
                id="combo-box-demo"
                value={subjectAreaJson.report_type}
                onChange={(e, value) => {
                  if (value === null) {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      report_type: "",
                      report_typeForApi: "",
                    })
                  } else {
                    handleChangeForReportingType(value)
                  }
                }}
                options={Object.values(reportTypeDropDown)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    name="placeOfSupply"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}></Grid>

            <Grid item xs={12} lg={12}>
              <p style={{ color: "black" }} >Please Select atleast one input :</p>
            </Grid>

            <Grid item xs={6} lg={3}>
              <label style={{ color: "black" }} >Financial Year:</label> <br />
              <Autocomplete
                id="combo-box-demo"
                value={subjectAreaJson.financial_year}
                disabled={subjectAreaJson.as_of_date === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? false : true}
                onChange={(e, value) => {
                  if (value === null) {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      financial_year: "",
                      period: "",
                      from_date: "",
                      to_date: ""
                    })
                  } else {
                    setsubjectAreaJson({
                      ...subjectAreaJson,
                      financial_year: value
                    });
                  }
                }}
                options={Financial}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" placeholder={subjectAreaJson.as_of_date === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? "Select" : "Disable"} />
                )}
              />
            </Grid>
            <Grid item lg={0.5}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "35px",
                  marginRight: "2px",
                  textAlign: "center",
                }}
              >
                or
              </div>
            </Grid>
            <Grid item lg={2}>
              <label style={{ color: "black" }} >As of Date:</label> <br />
              <button
                style={
                  triggerButtonForAsOfDate === false
                    ? {
                      width: "100%",
                      padding: "7px 15px",
                    }
                    : {
                      width: "90%",
                      background: "rgb(208, 70, 70)",
                      padding: "7px 15px",
                      color: "#fff",
                    }
                }
                className="button-outlined-primary"
                disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? false : true}
                onClick={handleAsofDate}
              >
                {subjectAreaJson.financial_year === "" && subjectAreaJson.to_date === "" && subjectAreaJson.from_date === "" ? triggerButtonForAsOfDate ? "selected" : "As of Date" : "disabled"}
              </button>
            </Grid>
            <Grid item lg={0.5}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "35px",
                  marginRight: "2px",
                  textAlign: "center",
                }}
              >
                or
              </div>
            </Grid>
            <Grid item lg={6}>
              <ValidatorForm
                useRef="form"
                // onSubmit={onSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                    <label style={{ color: "black" }} >
                      From Date:
                    </label>
                    <DatePicker
                      // maxDate={toDateMonthYearDisplay ?? ''}
                      placeholder={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? "From Date" : "Disabled"}
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? false : true}
                      value={fromDate ?? ""}
                      onChange={(v) => handleFromDate(v)}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <label style={{ color: "black" }} >
                      To Date:
                    </label>
                    <DatePicker
                      // minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
                      placeholder={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? "To Date" : "Disabled"}
                      errorMessages={["This field is required"]}
                      disabled={subjectAreaJson.financial_year === "" && subjectAreaJson.as_of_date === "" ? false : true}
                      validators={["required"]}
                      value={toDate ?? ""}
                      onChange={(v) => handleToDate(v)}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
            <Grid item xs={9} lg={8}></Grid>
            <Grid item xs={2} lg={2} style={{ marginTop: "28px" }}>
              <button
                type="button"
                className="button-primary"
                style={{ width: "100%", padding: "8px" }}
                onClick={handleEmailID}
              >
                Email
              </button>
            </Grid>
            <Grid item xs={1} lg={2} style={{ marginTop: "30px" }} >
              <button
                type="button"
                className="button-outlined-secondary"
                style={{ width: "100%", padding: "8px" }}
                onClick={handleDownload}
              >
                Generate
              </button>
            </Grid>

          </Grid>
        </>
      </BoxWrapper>
    </>
  );
}
