import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ValidatorComponent } from "react-material-ui-form-validator";

class ValidatedDatePicker extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      format,
      views,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...rest}
            fullWidth="true"
            size="small"
            format={format ?? "dd/mm/yyyy"}
            views={views ?? ["date", "month", "year"]}
            error={!isValid}
            helperText={(!isValid && this.getErrorMessage()) || helperText}
            style={{ background: "#fff", borderRadius: "5px" }}
            inputProps={{ readOnly: true }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
//fiscal year
export default ValidatedDatePicker;
