import React, { Component } from "react";
import classes from "./pendingRequest.module.css";
import axios from "../../../axios-order";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../components/ui/inputField";
import { ValidatorForm } from "react-material-ui-form-validator";
import Appbar from "../../../components/appBar/appBar";
import Footer from "../../../components/footer/footer";
import BoxWapper from "../../../components/ui/boxWrapper";

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class addNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalPendingData: {},
      pendingRequestData: [],
      business_entity_id: localStorage.getItem("business_entity_id"),
      loading: true,
    };
  }

  // ---entity table search---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.entityTableSearch(event);
    });
  };

  entityTableSearch = (event) => {
    let { searchInput } = this.state;
    let searchData = this.state.originalPendingData.filter((value) => {
      const query = searchInput.toLowerCase();

      return (
        (value.entity_name || "").toLowerCase().indexOf(query) >= 0 ||
        (value.gst || "").toLowerCase().indexOf(query) >= 0 ||
        (value.status || "").toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ pendingRequestData: searchData });
  };

  componentDidMount = () => {
    axios
      .get("/get_pending_entities")
      .then((res) => {
        this.setState({
          pendingRequestData: res.data.records,
          originalPendingData: res.data.records,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };
  // ---pending request  entity list table---
  pendingEntityTableHeader() {
    const tableHeader = [
      "entity name",
      "contact email id",

      "gst",
      "pan",
      "Services",
      " service status",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  pendingEntityHandler = (business_entity_id) => {
    localStorage.setItem("business_mgmt_entity_id", business_entity_id);
    this.props.history.push({
      pathname: `/businessEntityManagement/assignServices`,
      // data: `${business_entity_id}`,
    });
  };

  pendingEntityTableData() {
    if (this.state.pendingRequestData.length >= 1) {
      return this.state.pendingRequestData.map((tableData, index) => {
        const {
          entity_name,
          contact_email_id,
          business_entity_id,
          gst,
          pan,
          service_status,
          service_id,
          requested_categories,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{entity_name}</td>
            <td>{contact_email_id}</td>

            <td>{gst}</td>
            <td>{pan}</td>
            <td>
              {requested_categories === null
                ? "Not requested"
                : requested_categories}
            </td>
            <td style={{ textTransform: "capitalize" }}>{service_status}</td>
            <td>
              <Button
                size="small"
                onClick={() => this.pendingEntityHandler(business_entity_id)}
                style={{
                  color: "#d04646",
                  fontFamily: "Dosis",
                  fontWeight: "500",
                }}
              >
                approve
              </Button>
            </td>
          </tr>
        );
      });
    } else if (
      this.state.pendingRequestData.length === 0 &&
      this.state.loading === true
    ) {
      return (
        <tr>
          <td colspan={8}>Loading.Please wait...</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  backHanlder = () => {
    this.props.history.push(`/home`);
  };
  render() {
    return (
      <>
        <Appbar />
        <div className={classes.pendingRequestSection}>
          <BoxWapper
            boxTitle="Pending Entities"
            // search input
            enableSearchInput={this.state.loading === false ? true : ""}
            searchInputPlaceholder="Search..."
            searchInputOnchange={this.handleSearchInputChange}
            // back button
            backButtonForZohoEnable={true}
            backButton={this.backHanlder}
          >
            <div className={classes.responsiveTable}>
              <table className={classes.customeTable}>
                <tbody>
                  <tr>{this.pendingEntityTableHeader()}</tr>
                  {this.pendingEntityTableData()}
                </tbody>
              </table>
            </div>
          </BoxWapper>
        </div>
        {/* ---Footer--- */}
        <div>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(addNewUser);
