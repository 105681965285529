import React, { useEffect, useState } from 'react';
import BoxWrapper from '../boxwra';
import axios from 'axios';
import DesignInGSTR9BReport from "../GSTR-9B_recon.module.css";
import ConfirmationDialog from '../../../Sales/TDSReconciliationSale/ConfirmationDailog';


const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const data_1 = [
  {
    "key_particulars": "supplies_unreg_b2c",
    "label": "(A) Supplies made to Unregistered person (B2C)",
    "taxable_value": "100.00",
    "igst_value": "10.00",
    "cgst_value": "5.00",
    "sgst_value": "5.00",
    "cess_value": "2.00"
  },
  {
    "key_particulars": "supplies_reg_b2b",
    "label": "(B) Supplies made to registered person (B2B)",
    "taxable_value": "200.00",
    "igst_value": "20.00",
    "cgst_value": "10.00",
    "sgst_value": "10.00",
    "cess_value": "4.00"
  },
  {
    "key_particulars": "supplies_zero_rated",
    "label": "(C) Zero rated supply (Export) on payment of tax (Except Supplies to SEZ)",
    "taxable_value": "150.00",
    "igst_value": "15.00",
    "cgst_value": "7.50",
    "sgst_value": "7.50",
    "cess_value": "3.00"
  }
];

function Nine({handleBack, updateTableData, data }) {
  const [tableData, setTableData] = useState(data);
  const [inputData, setInputData] = useState({});
  const [tableName, setTableName] = useState("9.Details of tax paid as declared in returns filed during the financial year")

  const [saveDailog, setSaveDailogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [confirmBack, setConfirmBack] = useState(false);

  const submitHandle = () => {
    updateTableData(tableName, tableData)
    setUnsavedChanges(false)
  };

  const handleBackButton = () => {
    if (unsavedChanges) {
      setConfirmBack(true);
    } else {
      handleBack();
    }
  };

  const confirmBackHandle = () => {
    submitHandle()
    handleBack()
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [itemName, field] = name.split('.');

    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === itemName
          ? { ...item, [field]: value }
          : item
      )
    );

    setUnsavedChanges(true)
  };

  return (
    <>
      <BoxWrapper
        backButtonForZohoEnable={true}
        backButton={handleBackButton}
        boxTitle="9. Details of tax paid as declared in return filed during the financial year"
      >
        <div style={{ maxHeight: '47vh' }} className="responsive-table">
          <table style={{ width: '100%', backgroundColor: 'white' }} className="summary-tab-single-year-table-css">
            <thead>
              <tr>
                <th rowSpan={2} style={{ width: '25%' }}>Description</th>
                <th rowSpan={2} style={{ width: '15%' }}>Tax payable (₹)</th>
                <th rowSpan={2} style={{ width: '15%' }}>Paid Through Cash (₹)</th>
                <th colSpan={4}>Paid Through ITC (₹)</th>
              </tr>
              <tr>
                <th>Integrated Tax (₹)</th>
                <th>Central Tax (₹)</th>
                <th>State/UT Tax (₹)</th>
                <th>CESS (₹)</th>
              </tr>
            </thead>
            <tbody>
              {(tableData && tableData.length>=1) ? tableData && tableData.map((item, index) => (
                <tr key={index} style={{ backgroundColor: 'white' }}>
                  <td>{item.name}</td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.tax_payble`}
                      value={item.tax_payble}
                      onChange={handleInputChange}
                      style={{ width: '150px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.paid_through_itc`}
                      value={item.paid_through_itc || ''}
                      onChange={handleInputChange}
                      style={{ width: '150px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.igst`}
                      value={item.igst || ''}
                      onChange={handleInputChange}
                      style={{ width: '110px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.cgst`}
                      value={item.cgst || ''}
                      onChange={handleInputChange}
                      style={{ width: '110px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.sgst`}
                      value={item.sgst || ''}
                      onChange={handleInputChange}
                      style={{ width: '110px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className='blue-color'
                      name={`${item.name}.cess`}
                      value={item.cess || ''}
                      onChange={handleInputChange}
                      style={{ width: '110px' }}
                      disabled={!item.manual_type}
                    />
                  </td>
                </tr>
              )):(
                <tr>
                  <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
          <button type="button" className='button-primary' onClick={() => setSaveDailogOpen(true)}>Save</button>
          <button type="button" className='button-primary' >Cancel</button>
        </div>
      </BoxWrapper>
      <ConfirmationDialog
        open={saveDailog}
        onClose={() => setSaveDailogOpen(false)}
        onConfirm={submitHandle}
        title="Confirm Save"
        text="Do you want to save your changes? This action will update the current data."
      />

      <ConfirmationDialog
        open={confirmBack}
        onConfirm={confirmBackHandle}
        onClose={() => handleBack()}
        title="Unsaved Changes"
        text="You have unsaved changes. Do you want to leave without saving?"
      />
    </>
  );
}

export default Nine;
