import React, { useEffect, useState } from "react";
import InvoiceMgmt from "./invoiceMgmt/invoiceMgmt";
import DebitNote from "./DebitNote/DebitNoteMain";
import CreditNote from "./CreditNote/CreditNoteMain";
import { Grid, Button, Paper } from "@material-ui/core";
import axios from "../../../../axios-order";
import "react-toastify/dist/ReactToastify.css";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";

function PurchaseMgmt(props) {
  const [showInvoiceSection, setShowInvoiceSection] = useState(true);
  const [showDebitNoteSection, setShowDebitNoteSection] = useState(false);
  const [showCreditNoteSection, setShowCreditNoteSection] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Bill Management");

  const getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getApiForClientID();
    localStorage.getItem("DrawerForInvoice") === "true" &&
      setShowInvoiceSection(true);
    localStorage.getItem("DrawerForDebit") === "true" && debitNoteHandler();
  }, []);

  const invoiceHandler = () => {
    setShowInvoiceSection(true);
    setShowDebitNoteSection(false);
    setShowCreditNoteSection(false);
    setHeaderTitle("Bill Management");
  };

  const debitNoteHandler = () => {
    setShowInvoiceSection(false);
    setShowDebitNoteSection(true);
    setShowCreditNoteSection(false);
    setHeaderTitle("Debit Note Management");
  };

  const creditNoteHandler = () => {
    setShowInvoiceSection(false);
    setShowDebitNoteSection(false);
    setShowCreditNoteSection(true);
    setHeaderTitle("Credit Note Management");
  };

  return (
    <div>
      <>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {/* <ToggleSwitch label={`Upload ${headerTitle.split(" ")[0]}`} /> */}
          </div>

          <div style={{ display: "flex" }}>
            <button
              className={
                showInvoiceSection === true
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={invoiceHandler}
              {...props}
            >
              Bill Management
            </button>
            <button
              className={
                showDebitNoteSection === true
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={debitNoteHandler}
              {...props}
            >
              Debit Note Management
            </button>
            <button
              className={
                showCreditNoteSection === true
                  ? "button-normal-secondary-active"
                  : "button-normal-secondary"
              }
              onClick={creditNoteHandler}
              {...props}
            >
              Credit Note Management
            </button>
          </div>
        </div>

        {showInvoiceSection && <InvoiceMgmt />}

        {showDebitNoteSection && <DebitNote />}

        {showCreditNoteSection && <CreditNote />}
      </>
    </div>
  );
}

export default PurchaseMgmt;
