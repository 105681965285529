import React, { useState } from "react";

import classes from "./Report.module.css";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MismatchedInvoice(props) {
  const { mismatchedInvoiceData } = props;
  const [mismatchedInvoiceTableData, setMismatchedInvoiceTableData] = useState(
    mismatchedInvoiceData
  );
  const [searchInput, setSearchInput] = useState("");

  const requestSearch = (event) => {
    const searchVal = event.target.value;
    const filteredRows = mismatchedInvoiceData.filter((row) => {
      return (
        String(row.GSTIN).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0 ||
        String(row.Vendor_Name)
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) >= 0 ||
        String(row.DOC_NUMBER).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.DOC_DATE).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.DOC_TYPE).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
      );
    });
    setMismatchedInvoiceTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchInput("");
    requestSearch(searchInput);
  };

  // indian date format
  const indianFormat = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  //-----Edited----
  const mismatchedInvoiceTableBody = () => {
    if (
      mismatchedInvoiceTableData &&
      mismatchedInvoiceTableData.length !== undefined &&
      mismatchedInvoiceTableData.length >= 1
    ) {
      return mismatchedInvoiceTableData.map((tableData, index) => {
        const {
          GSTIN,
          Vendor_Name,
          Book_Doc_Number,
          Book_Doc_Date,
          DOC_TYPE,
          Taxable_Value_Books,
          CGST_Books,
          SGST_Books,
          IGST_Books,
          Cess_Books,
          Total_Amount_Books,
          Taxable_Value_GSTR2A,
          CGST_GSTR2A,
          SGST_GSTR2A,
          IGST_GSTR2A,
          Cess_GSTR2A,
          Total_Amount_Books_GSTR2A,
          Taxable_Value_Diff,
          TaxValue_Diff,
          Total_Amount_Diff,
          Remarks,
        } = tableData;
        return (
          <tr>
            <td>{GSTIN}</td>
            <td>{Vendor_Name}</td>
            <td>{Book_Doc_Number}</td>
            <td>{indianFormat(Book_Doc_Date)}</td>

            <td>{DOC_TYPE}</td>
            <td>{numberFormat(Taxable_Value_Books)}</td>
            <td>{numberFormat(CGST_Books)}</td>
            <td>{numberFormat(SGST_Books)}</td>
            <td>{numberFormat(IGST_Books)}</td>
            <td>{numberFormat(Cess_Books)}</td>
            <td>{numberFormat(Total_Amount_Books)}</td>

            <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
            <td>{numberFormat(CGST_GSTR2A)}</td>
            <td>{numberFormat(SGST_GSTR2A)}</td>
            <td>{numberFormat(IGST_GSTR2A)}</td>
            <td>{numberFormat(Cess_GSTR2A)}</td>
            <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>

            <td>{numberFormat(Taxable_Value_Diff)}</td>
            <td>{numberFormat(TaxValue_Diff)}</td>
            <td>{numberFormat(Total_Amount_Diff)}</td>

            <td>{Remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={4} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status : Mis-Matched Invoice(s)"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={(searchVal) => requestSearch(searchVal)}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.AllInvoiceReconTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                CUSTOMER NAME
              </th>
              <th rowSpan={2} align="right">
                DOC NUMBER
              </th>
              <th rowSpan={2} align="right">
                DATE
              </th>
              <th rowSpan={2} align="right">
                TYPE
              </th>
              <th style={{ background: "#ffffb3" }} colSpan={6} align="center">
                As per Book
              </th>
              <th style={{ background: "#5cd65c" }} colSpan={6} align="center">
                As per GSTR2A
              </th>
              <th style={{ background: "#ff8533" }} colSpan={3} align="center">
                Difference
              </th>
              <th style={{ background: "#ffc107" }} rowSpan={2}>
                Remarks{" "}
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                SGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                IGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CESS
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TAX
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {mismatchedInvoiceTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MismatchedInvoice;
