import React, { Component, Fragment } from "react";
import classes from "./Dashboard.module.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "../../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import SelectField from "../../../../components/ui/selectInput";
import "../../../../asserts/css/Onboarding.css";

// fiscal year date ----->
const getCurrentFinancialYear = (i) => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 >= 4 && today.getMonth() + 1 <= 12) {
    fiscalyear = today.getFullYear() - i + "-" + (today.getFullYear() - i + 1);
  } else if (today.getMonth() + 1 < 4) {
    fiscalyear = today.getFullYear() - i - 1 + "-" + (today.getFullYear() - i);
  }
  return fiscalyear;
};

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

// indian date format
const indianFormat = (date) => {
  // alert(date);
  if (typeof date === "undefined" || date === "") return "";
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};
// ---End---

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: [],
      allData: [],
      allGSTIN: [],
      main_data: [],
      reciept_of_money_against_bd: [],
      on_customer_payment: [],
      tds_recievables: [],
      settlement: [],
      booking_of_intrest_expense: [],
      intrest_if_charged_periodically: [],
      processing_fees: [],
      fiscal_year: getCurrentFinancialYear(0),
      from_date: getCurrentFinancialYear(0).split("-")[0] + "-04-01",
      to_date: getCurrentFinancialYear(0).split("-")[1] + "-03-31",
      loaderForTableOne: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      loaderForTableOne: true,
    });
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    if (mm === 1 || mm === 2 || mm === 3) {
      const year = parseInt(yy) - 1;
      var fromDate = `${year.toString()}-04-01`;
      this.setState({ from_date: fromDate });
    } else {
      const fromDate = `${yy}-04-01`;
      this.setState({ from_date: fromDate });
    }
    var date = new Date();
    const lastDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    var toDate = `${yy}-${(pad + mm).slice(-2)}-${lastDate.toString()}`;

    this.setState({
      AsOfDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      to_date: toDate,
    });
    // ---End---

    let promise = new Promise((resolve, reject) => {
      axios
        .get(`id/getAllGstins/${localStorage.getItem("business_entity_id")}`)
        .then((res) => {
          if (
            typeof res.data === "undefined" ||
            !Object.keys(res.data).length
          ) {
            this.setState({ loaderForTableOne: false });
          } else {
            this.setState({
              allGSTIN: res.data.data,
              sellerName: res.data.seller_name,
              customer_name: res.data.data[0].customer_gstin,
            });
            resolve(res.data.data[0].customer_gstin);
          }
        });
    });

    Promise.all([promise]).then((customer_gstin) => {
      axios
        .get(
          `id/getTables/${customer_gstin}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          this.setState({
            // loaderForTableOne:false,
            allData: res.data,
            main_data: res.data.main_data,
          });
          const arry2 = [];
          let obj2 = {
            SL: "2: Receipt of Money against BD",
            sub: ["i", "ii", "iii", "iv", "v"],
            entry: [
              "Bank",
              "Interest Paid in Advance",
              "Processing Fees A/c",
              "GST on BD Fees",
              "Interim Loan A/c",
            ],
            debit: ["Dr", "Dr", "Dr", "Dr", "Cr"],
            Amount: [],
            Narration: [
              `Net Amount Disbursed`,
              "Advance Interest held back ",
              "BD Processing Fees",
              "GST on Processing fees",
              "Interim Loan",
            ],
          };

          Object.keys(res.data.reciept_of_money_against_bd).map((items, i) => {
            let amount1 = [];
            amount1.push(
              res.data.reciept_of_money_against_bd.net_amount_disbursed
            );
            amount1.push(
              res.data.reciept_of_money_against_bd.advance_intrest_held_back
            );
            amount1.push(
              res.data.reciept_of_money_against_bd.bd_processing_fees
            );
            amount1.push(
              res.data.reciept_of_money_against_bd.gst_on_processing_fees
            );
            amount1.push(res.data.reciept_of_money_against_bd.interim_loan);
            obj2.Amount = amount1;
            return obj2.Amount;
          });

          arry2.push(obj2);
          this.setState({ reciept_of_money_against_bd: arry2 });

          // 3rd table data
          const arry3 = [];
          let obj3 = {
            SL: "3: On customer payment",
            sub: ["i", "ii"],
            entry: ["Loan Settlement A/c", "Customer A/c"],
            debit: ["Dr", "Cr"],
            Amount: [],
            Narration: [`Net amount paid to Lender`, ""],
          };

          Object.keys(res.data.on_customer_payment).map((items, i) => {
            let amount3 = [];
            amount3.push(
              res.data.on_customer_payment.net_amount_paid_to_lender
            );
            amount3.push(res.data.on_customer_payment.customer_ac);
            obj3.Amount = amount3;
            return obj3.Amount;
          });

          arry3.push(obj3);
          this.setState({ on_customer_payment: arry3 });

          // 4th table data
          const arry4 = [];
          let obj4 = {
            SL: "4: TDS Receivables",
            sub: ["i", "ii"],
            entry: ["TDS Receivables", "Customer A/c"],
            debit: ["Dr", "Cr"],
            Amount: [],
            Narration: [`TDS Receivablesr`, ""],
          };

          Object.keys(res.data.tds_recievables).map((items, i) => {
            let amount4 = [];
            amount4.push(res.data.tds_recievables.tds_recievables);
            amount4.push(res.data.tds_recievables.customer_ac);
            obj4.Amount = amount4;
            return obj4.Amount;
          });

          arry4.push(obj4);
          this.setState({ tds_recievables: arry4 });

          // 5th table data
          const arry5 = [];
          let obj5 = {
            SL: "5: Settlement",
            sub: [
              "i",
              "ii",
              "iii",
              "iv",
              "",
              "i",
              "ii",
              "",
              "i",
              "ii",
              "iii",
              "iv",
            ],
            entry: [
              "Interest A/c",
              "Interest Recoverable",
              "Interest Payable",
              "Interest Paid In Advance (Interest Held Back )A/c",
              "",
              "Interim Loan A/c",
              "Loan Settlement A/c",
              "",
              "Bank A/c (seller)",
              "Interest Payable",
              "Loan Settlement A/c",
              "Interest Recoverable",
            ],
            debit: [
              "Dr",
              "Cr",
              "Cr",
              "Cr",
              "",
              "Dr",
              "Cr",
              "",
              "Dr",
              "Dr",
              "Cr",
              "Cr",
            ],
            Amount: [],
            Narration: [
              `Actual Interest paid`,
              "Interest recoverable",
              "Interest payable",
              "Advance Interest held back",
              "",
              "Interim Loan a/c",
              "",
              "",
              "Amount to receive ",
              "",
              "(Net amt paid to Lender - Interim Loan)",
              "",
            ],
          };

          Object.keys(res.data.settlement).map((items, i) => {
            let amount5 = [];
            amount5.push(res.data.settlement.actual_intrest_paid);
            amount5.push(res.data.settlement.intrest_recovarable);
            amount5.push(res.data.settlement.intrest_payable);
            amount5.push(res.data.settlement.advance_intrest_held_back);
            amount5.push("");
            amount5.push(res.data.settlement.interim_loan_ac);
            amount5.push(res.data.settlement.loan_settlement_ac);
            amount5.push("");
            amount5.push(res.data.settlement.amount_to_recieve);
            amount5.push(res.data.settlement.intrest_payable_2);
            amount5.push(res.data.settlement.loan_settlement_ac_2);
            amount5.push(res.data.settlement["intrest_recovarable/payable"]);
            obj5.Amount = amount5;
            return obj5.Amount;
          });

          arry5.push(obj5);
          this.setState({ settlement: arry5 });

          // 6th table data
          const arry6 = [];
          let obj6 = {
            SL: "6: Booking of interest expense",
            sub: ["i", "ii"],
            entry: [
              "Interest A/c",
              "Interest Paid in Advance (Interest Held Back ) A/C",
            ],
            debit: ["Dr", "Cr"],
            Amount: [],
            Narration: ["", "Interest per month"],
          };

          Object.keys(res.data.booking_of_intrest_expense).map((items, i) => {
            let amount6 = [];
            amount6.push(res.data.booking_of_intrest_expense.intrest_per_month);
            amount6.push(res.data.booking_of_intrest_expense.intrest_acc);
            obj6.Amount = amount6;
            return obj6.Amount;
          });

          arry6.push(obj6);
          this.setState({ booking_of_intrest_expense: arry6 });

          // 7th table data
          const arry7 = [];
          let obj7 = {
            SL: "7: Interest, If charged periodically",
            sub: ["i", "ii"],
            entry: ["Interest A/c", "Bank A/c"],
            debit: ["Dr", "Cr"],
            Amount: [],
            Narration: ["Monthly Interest Charged", ""],
          };

          Object.keys(res.data.intrest_if_charged_periodically).map(
            (items, i) => {
              let amount7 = [];
              amount7.push(
                res.data.intrest_if_charged_periodically.monthly_intrest_charged
              );
              amount7.push(res.data.intrest_if_charged_periodically.bank_ac);
              obj7.Amount = amount7;
              return obj7.Amount;
            }
          );

          arry7.push(obj7);
          this.setState({ intrest_if_charged_periodically: arry7 });

          // 8th table data
          const arry8 = [];
          let obj8 = {
            SL: "8: Processing Fees",
            sub: ["i", "ii"],
            entry: ["Invoice Discounting Contract fees", "Bank A/c"],
            debit: ["Dr", "Cr"],
            Amount: [],
            Narration: ["Contract fees Charged by Agency", ""],
          };

          Object.keys(res.data.processing_fees).map((items, i) => {
            let amount8 = [];
            amount8.push(
              res.data.processing_fees.contract_fees_charged_by_agency
            );
            amount8.push(res.data.processing_fees.bank_ac);
            obj8.Amount = amount8;
            return obj8.Amount;
          });

          arry8.push(obj8);
          this.setState({ processing_fees: arry8, loaderForTableOne: false });
        })
        .catch((error) => {
          this.setState({ loaderForTableOne: false });
          console.log(error);
        });
    });
    // ---End---
  };

  // fiscal yera filter---
  handleFiscalYear = (e) => {
    let value = e.target.value;
    this.setState({
      fiscal_year: value,
      from_date: value.split("-")[0] + "-04-01",
      to_date: value.split("-")[1] + "-03-31",
    });
  };

  // Customer Name  filter----
  handleCustomerName = (event) => {
    const target = event.target;
    const customer_name = target.value;
    const name = target.name;
    this.setState({
      [name]: String(customer_name),
    });

    axios
      .get(
        `id/getTables/${customer_name}/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        // 1st table data
        this.setState({
          main_data: res.data.main_data,
        });
        // 2nd table data
        const arry2 = [];
        let obj2 = {
          SL: "2: Receipt of Money against BD",
          sub: ["i", "ii", "iii", "iv", "v"],
          entry: [
            "Bank",
            "Interest Paid in Advance",
            "Processing Fees A/c",
            "GST on BD Fees",
            "Interim Loan A/c",
          ],
          debit: ["Dr", "Dr", "Dr", "Dr", "Cr"],
          Amount: [],
          Narration: [
            `Net Amount Disbursed`,
            "Advance Interest held back ",
            "BD Processing Fees",
            "GST on Processing fees",
            "Interim Loan",
          ],
        };

        Object.keys(res.data.reciept_of_money_against_bd).map((items, i) => {
          let amount1 = [];
          amount1.push(
            res.data.reciept_of_money_against_bd.net_amount_disbursed
          );
          amount1.push(
            res.data.reciept_of_money_against_bd.advance_intrest_held_back
          );
          amount1.push(res.data.reciept_of_money_against_bd.bd_processing_fees);
          amount1.push(
            res.data.reciept_of_money_against_bd.gst_on_processing_fees
          );
          amount1.push(res.data.reciept_of_money_against_bd.interim_loan);
          obj2.Amount = amount1;
          return obj2.Amount;
        });

        arry2.push(obj2);
        this.setState({ reciept_of_money_against_bd: arry2 });

        // 3rd table data
        const arry3 = [];
        let obj3 = {
          SL: "3: On customer payment",
          sub: ["i", "ii"],
          entry: ["Loan Settlement A/c", "Customer A/c"],
          debit: ["Dr", "Cr"],
          Amount: [],
          Narration: [`Net amount paid to Lender`, ""],
        };

        Object.keys(res.data.on_customer_payment).map((items, i) => {
          let amount3 = [];
          amount3.push(res.data.on_customer_payment.net_amount_paid_to_lender);
          amount3.push(res.data.on_customer_payment.customer_ac);
          obj3.Amount = amount3;
          return obj3.Amount;
        });

        arry3.push(obj3);
        this.setState({ on_customer_payment: arry3 });

        // 4th table data
        const arry4 = [];
        let obj4 = {
          SL: "4: TDS Receivables",
          sub: ["i", "ii"],
          entry: ["TDS Receivables", "Customer A/c"],
          debit: ["Dr", "Cr"],
          Amount: [],
          Narration: [`TDS Receivablesr`, ""],
        };

        Object.keys(res.data.tds_recievables).map((items, i) => {
          let amount4 = [];
          amount4.push(res.data.tds_recievables.tds_recievables);
          amount4.push(res.data.tds_recievables.customer_ac);
          obj4.Amount = amount4;
          return obj4.Amount;
        });

        arry4.push(obj4);
        this.setState({ tds_recievables: arry4 });

        // 5th table data
        const arry5 = [];
        let obj5 = {
          SL: "5: Settlement",
          sub: [
            "i",
            "ii",
            "iii",
            "iv",
            "",
            "i",
            "ii",
            "",
            "i",
            "ii",
            "iii",
            "iv",
          ],
          entry: [
            "Interest A/c",
            "Interest Recoverable",
            "Interest Payable",
            "Interest Paid In Advance (Interest Held Back )A/c",
            "",
            "Interim Loan A/c",
            "Loan Settlement A/c",
            "",
            "Bank A/c (seller)",
            "Interest Payable",
            "Loan Settlement A/c",
            "Interest Recoverable",
          ],
          debit: [
            "Dr",
            "Cr",
            "Cr",
            "Cr",
            "",
            "Dr",
            "Cr",
            "",
            "Dr",
            "Dr",
            "Cr",
            "Cr",
          ],
          Amount: [],
          Narration: [
            `Actual Interest paid`,
            "Interest recoverable",
            "Interest payable",
            "Advance Interest held back",
            "",
            "Interim Loan a/c",
            "",
            "",
            "Amount to receive ",
            "",
            "(Net amt paid to Lender - Interim Loan)",
            "",
          ],
        };

        Object.keys(res.data.settlement).map((items, i) => {
          let amount5 = [];
          amount5.push(res.data.settlement.actual_intrest_paid);
          amount5.push(res.data.settlement.intrest_recovarable);
          amount5.push(res.data.settlement.intrest_payable);
          amount5.push(res.data.settlement.advance_intrest_held_back);
          amount5.push("");
          amount5.push(res.data.settlement.interim_loan_ac);
          amount5.push(res.data.settlement.loan_settlement_ac);
          amount5.push("");
          amount5.push(res.data.settlement.amount_to_recieve);
          amount5.push(res.data.settlement.intrest_payable_2);
          amount5.push(res.data.settlement.loan_settlement_ac_2);
          amount5.push(res.data.settlement["intrest_recovarable/payable"]);
          obj5.Amount = amount5;
          return obj5.Amount;
        });

        arry5.push(obj5);
        this.setState({ settlement: arry5 });

        // 6th table data
        const arry6 = [];
        let obj6 = {
          SL: "6: Booking of interest expense",
          sub: ["i", "ii"],
          entry: [
            "Interest A/c",
            "Interest Paid in Advance (Interest Held Back ) A/C",
          ],
          debit: ["Dr", "Cr"],
          Amount: [],
          Narration: ["", "Interest per month"],
        };

        Object.keys(res.data.booking_of_intrest_expense).map((items, i) => {
          let amount6 = [];
          amount6.push(res.data.booking_of_intrest_expense.intrest_per_month);
          amount6.push(res.data.booking_of_intrest_expense.intrest_acc);
          obj6.Amount = amount6;
          return obj6.Amount;
        });

        arry6.push(obj6);
        this.setState({ booking_of_intrest_expense: arry6 });

        // 7th table data
        const arry7 = [];
        let obj7 = {
          SL: "7: Interest, If charged periodically",
          sub: ["i", "ii"],
          entry: ["Interest A/c", "Bank A/c"],
          debit: ["Dr", "Cr"],
          Amount: [],
          Narration: ["Monthly Interest Charged", ""],
        };

        Object.keys(res.data.intrest_if_charged_periodically).map(
          (items, i) => {
            let amount7 = [];
            amount7.push(
              res.data.intrest_if_charged_periodically.monthly_intrest_charged
            );
            amount7.push(res.data.intrest_if_charged_periodically.bank_ac);
            obj7.Amount = amount7;
            return obj7.Amount;
          }
        );

        arry7.push(obj7);
        this.setState({ intrest_if_charged_periodically: arry7 });

        // 8th table data
        const arry8 = [];
        let obj8 = {
          SL: "8: Processing Fees",
          sub: ["i", "ii"],
          entry: ["Invoice Discounting Contract fees", "Bank A/c"],
          debit: ["Dr", "Cr"],
          Amount: [],
          Narration: ["Contract fees Charged by Agency", ""],
        };

        Object.keys(res.data.processing_fees).map((items, i) => {
          let amount8 = [];
          amount8.push(
            res.data.processing_fees.contract_fees_charged_by_agency
          );
          amount8.push(res.data.processing_fees.bank_ac);
          obj8.Amount = amount8;
          return obj8.Amount;
        });

        arry8.push(obj8);
        this.setState({ processing_fees: arry8 });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // TABLE LOGIC
  // collection details table header
  entry_TableHeader() {
    const tableHeader = ["Sl No", " ", "entry", "Dr/Cr", "Amount", "Narration"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // CUUTOMER  details table header
  customer_detailsTableHeader() {
    const tableHeader = [
      "Customer Name",
      "GSTIN",
      "PAN",
      "TAN",
      "Inovice no",
      "Invoice Date",
      "Invoice Basic",
      "Invoice tax",
      "Invoice value",
      "Invoice TDS",
      "Net Amount Disbursed",
      "Advance Interest held back",
      "Monthly Interest Charged",
      "BD Processing Fees",
      "GST on Processing fees",
      "Interim Loan",
      "Net amt paid to Lender",
      "Actual Interest paid",
      "Loan Settlement ",
      "Interest recoverable/ payable",
      "# days interest held back",
      "# of months (@30days)",
      "Interest per month",
      "Lending agency name",
      "deal_number",
      "tech_agency",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  customer_details_data = () => {
    if (this.state.loaderForTableOne) {
      return (
        <td
          colSpan={8}
          style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
        >
          Loading…Please wait
        </td>
      );
    } else if (this.state.main_data > 0) {
      return this.state.main_data.map((items, index) => {
        return (
          <tr key={index}>
            {Object.keys(items).map((item, i) => {
              let dateArr = ["invoice_date"];
              let currencyArr = [
                "invoice_basic",
                "invoice_tax",
                "invoice_value",
                "invoice_tds",
                "net_amount_disbursed",
                "advance_intrest_held_back",
                "monthly_intrest_charged",
                "bd_processing_fees",
                "gst_on_processing_fees",
                "interim_loan",
                "net_amount_paid_to_lender",
                "actual_intrest_paid",
                "loan_settlement",
                "intrest_recovarable/payable",
                "intrest_per_month",
              ];
              let row_value = "";

              if (dateArr.indexOf(item) !== -1) {
                row_value = indianFormat(items[item]);
              } else if (currencyArr.indexOf(item) !== -1) {
                row_value = numberFormat(items[item]);
              } else {
                row_value = items[item];
              }
              return <td key={i}>{row_value}</td>;
            })}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={8} style={{ color: "red", textAlign: "center" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  Receipt_of_Money_against_BD_data = () => {
    return (
      this.state.reciept_of_money_against_bd &&
      this.state.reciept_of_money_against_bd.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 3rd table
  on_customer_payment = () => {
    return (
      this.state.on_customer_payment &&
      this.state.on_customer_payment.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>

                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 4th table
  tds_recievables = () => {
    return (
      this.state.tds_recievables &&
      this.state.tds_recievables.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 5th table
  settlement = () => {
    return (
      this.state.settlement &&
      this.state.settlement.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                {item.Amount[i] === "" ? (
                  <td></td>
                ) : (
                  <td>{numberFormat(item.Amount[i])}</td>
                )}

                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                {item.Amount[i] === "" ? (
                  <td></td>
                ) : (
                  <td>{numberFormat(item.Amount[i])}</td>
                )}
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 6th table
  booking_of_intrest_expense = () => {
    return (
      this.state.booking_of_intrest_expense &&
      this.state.booking_of_intrest_expense.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 7th table
  intrest_if_charged_periodically = () => {
    return (
      this.state.intrest_if_charged_periodically &&
      this.state.intrest_if_charged_periodically.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };
  // 8th table
  processing_fees = () => {
    return (
      this.state.processing_fees &&
      this.state.processing_fees.map((item, index) => (
        <Fragment>
          <tr style={{ borderBottom: "1px solid #ddd" }}>
            <td rowspan={item.sub.length + 1}>{item.SL}</td>
          </tr>
          {item.sub.map((subnum, i) => {
            return item.sub.length - 1 === i ? (
              <tr
                style={{ borderBottom: "1px solid #ddd" }}
                className={classes.subtableRow}
              >
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            ) : (
              <tr className={classes.subtableRow}>
                <td>{subnum}</td>
                <td>{item.entry[i]}</td>
                <td>{item.debit[i]}</td>
                <td>{numberFormat(item.Amount[i])}</td>
                <td>{item.Narration[i]}</td>
              </tr>
            );
          })}
        </Fragment>
      ))
    );
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `id_Dashboard.xlsx`;
    axios
      .post(`/id/download`, {
        data: this.state.allData,
        customer_name: this.state.sellerName && this.state.sellerName,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ---End---
  render() {
    return (
      <React.Fragment>
        <Container>
          {/* filter div */}
          <div>
            <Grid container spacing={3}>
              <Grid item xs={2}></Grid>

              <Grid item xs={8}>
                <div style={{ textAlign: "center" }}>
                  <div>
                    <Button onClick={this.downloadToExcel}>
                      Export to Excel
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid item xs={2}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.onSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <div>
                    <SelectField
                      SelectLabel="Customer Name"
                      validator={["required"]}
                      requiredField=" "
                      errorMsg={["This field is required"]}
                      choice_id="customer_gstin"
                      choice_name="customer_name"
                      choices={this.state.allGSTIN}
                      name="customer_name"
                      value={this.state.customer_name ?? ""}
                      textOnchangeFunction={this.handleCustomerName}
                    />
                  </div>
                </ValidatorForm>
              </Grid>
            </Grid>
          </div>

          {/* dashboard header */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={classes.Paper}
                style={{
                  background: "#c4d0d9",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {this.state.sellerName && this.state.sellerName}
                <br></br>Scheme of Entries for Invoice Discounting{" "}
              </Paper>
            </Grid>
          </Grid>

          <div className={classes.tableResponsive}>
            <table className={classes.LongTableCss}>
              <tbody>
                <tr>{this.customer_detailsTableHeader()}</tr>
                {this.customer_details_data()}
              </tbody>
            </table>
          </div>

          {/* 2: Receipt of Money against BD TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table className={classes.table_css}>
                  <tbody>
                    <tr>{this.entry_TableHeader()}</tr>
                    {this.Receipt_of_Money_against_BD_data()}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 3: on customer payment TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table3" className={classes.table_css}>
                  <tbody>{this.on_customer_payment()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 4: tds recievables TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table4" className={classes.table_css}>
                  <tbody>{this.tds_recievables()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 5:settlement TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table5" className={classes.table_css}>
                  <tbody>{this.settlement()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 6:settlement TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table6" className={classes.table_css}>
                  <tbody>{this.booking_of_intrest_expense()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 7:intrest if charged periodically TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table7" className={classes.table_css}>
                  <tbody>{this.intrest_if_charged_periodically()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          {/* 8:processing fees TABLE */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tableWapper}>
                <table id="table8" className={classes.table_css}>
                  <tbody>{this.processing_fees()}</tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
export default Dashboard;
