import React, { useEffect, useRef } from "react";
import CWS from "../../asserts/images/cws_final_logo.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Divider from "@material-ui/core/Divider";
import axios from "../../axios-order";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "5px",
};

export default function IarsLogIn() {
  const [open, setOpen] = React.useState(false);
  const [ConnectionDetailTableData, setConnectionDetailTableData] =
    React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const timer = useRef();

  const redirectToBestirSoftware = () => {
    {
      ConnectionDetailTableData.map((v) => {
        if (v.source_system === "IARS") {
          let urlLink = "http://" + v.connection_info.Hostname_IP + "/IARS/";
          window.open(urlLink);
          handleCloseEvent();
        }
      });
    }
  };

  const getConnectionDetails = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        setConnectionDetailTableData(res.data.record);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAutoRedirect = () => {
    handleOpen();
    timer.current = setTimeout(() => {
      redirectToBestirSoftware();
      handleClose();
    }, 5000);
  };

  const handleCloseEvent = () => {
    handleClose();
    clearTimeout(timer.current);
  };

  useEffect(() => {
    getConnectionDetails();
  }, []);

  return (
    <div
      style={{
        marginTop: "50px",
        fontFamily: "Arial,Verdan,Sans-Serif",
        fontSize: "12px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src={CWS} width="200" height="120" alt="Loading" />
        <div
          style={{
            color: "rgb(76, 76, 167)",
            textTransform: "capitalize",
            fontSize: "16px",
            marginTop: "14px",
            fontFamily: "Dosis",
            fontWeight: "550",
          }}
        >
          <span
            onClick={handleAutoRedirect}
            style={{ color: "red", cursor: "pointer" }}
          >
            Click Here
          </span>{" "}
          you will be Redirected to IARS Portal.....
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div>
                <h5 style={{ fontWeight: "600", color: "black" }}>
                  {" "}
                  Redirecting you to IARS Portal.....
                </h5>
                <Divider />
              </div>
              <div>
                <p>
                  You have chosen an option to login into IARS Portal.
                  Redirecting to you IARS Portal in a moment. If you're not
                  redirected within a few seconds, click <i>REDIRECT</i>.
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  {" "}
                  <Button variant="text" onClick={redirectToBestirSoftware}>
                    REDIRECT
                  </Button>{" "}
                </div>
                <div>
                  {" "}
                  <Button variant="text" onClick={handleCloseEvent}>
                    CANCEL
                  </Button>{" "}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
