import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./TDSRecon.css";
import SelectField from "../../../../components/ui/basicSelectInput";

import BoxWrapper from "../../../../components/ui/boxWrapper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function TDSReconciliation() {
  let history = useHistory();
  const [newReconFormOpen, setNewReconFormOpen] = useState(false);
  const [newReconFormData, setNewReconFormData] = useState({
    business_entity_id: localStorage.getItem("business_entity_id"),
  });
  const [newReconHistoryTable, setNewReconHistoryTable] = useState([]);
  const [yearFromArr, setFromYearArr] = useState([]);
  

  const [errorMessageForBoxWrapper, setErrorMessageForBoxWrapper] =
    useState("");
  const [closeBoxWrapper, setCloseBoxWrapper] = useState(false);

  const [loaderForTable, setLoaderForTable] = useState(true);

  const getTdsReconDetails = () => {
    toast.configure();
    axios
      .get(
        `/gstr9_summary/${localStorage.getItem(
          "business_entity_id"
        )}/${localStorage.getItem(
          "gst"
        )}`
      )
      .then((res) => {
        setLoaderForTable(false);
        setNewReconHistoryTable(res.data.records);
        setFromYearValues(res.data.financial_years)
      })
      .catch((error) => {
        setLoaderForTable(false);
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  const setFromYearValues = (years_list) => {
    
    let yearArr = [];
    for (let i = 0; i < years_list.length; i++) {
      yearArr.push({ id: i+1, year: years_list[i] });
    }
    setFromYearArr(yearArr);
    console.log('yearArr..........', yearArr)
  };

  

  useEffect(() => {
    getTdsReconDetails();
    
  }, []);

  const newReconFormModalOpen = () => {
    setNewReconFormOpen(true);
  };

  const newReconYearChangeHandler = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    
    setNewReconFormData({
      ...newReconFormData,
      [name]: value,
    });
  };

  const newReconFormSubmitHandler = () => {
    toast.configure();

    axios
      .post("/gstr9_recon", {...newReconFormData, 'gstin' : localStorage.getItem('gst'),})
      .then((res) => {
        setNewReconFormOpen(false);
        setNewReconFormData({
          business_entity_id: localStorage.getItem("business_entity_id"),
        });
        getTdsReconDetails();
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else { toast.warning(res?.data?.message); }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.errors, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  const newReconFormModalClose = () => {
    setNewReconFormOpen(false);
    setNewReconFormData({
      business_entity_id: localStorage.getItem("business_entity_id"),
    });
  };


  const historyTableBody = () => {
    const showErrorDetails = (item) => {
      axios
        .get(`/form_26/get_json/${item.id}`)
        .then((res) => {
          setErrorMessageForBoxWrapper(res?.data?.records?.message);
          setCloseBoxWrapper(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };


    const updatedShowDetails = (item) => {
      // localStorage.setItem("TDS_report_json_id", `${item.id}`);
      localStorage.setItem("gstr9_financail_year", `${item.financial_year}`);
      history.push({
        pathname: `/GSTR9-ReportingPage/${item.id}`,
      });
    };

    if (loaderForTable) {
      console.log("Loader");
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={4}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (newReconHistoryTable && newReconHistoryTable.length > 0) {
      return newReconHistoryTable.map((item, index) => {
        const { financial_year, period, recon_run_date_time, recon_status } = item; 
        return (
          <tr key={index}>
            <td>{financial_year}</td>
            <td>{period}</td>
            <td>{recon_run_date_time}</td>
            <td>{recon_status}</td>
            <td>
              {recon_status === "Pass" && (

                <VisibilityOutlinedIcon
                  className="action-icon-button showIcon"
                  onClick={() => updatedShowDetails(item)}
                />
              )}

              {recon_status === "Fail" && (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  onClick={() => showErrorDetails(item)}
                />
              )}

              {recon_status === "Initiated" && (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  color="disabled"
                />
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={4}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  const refreshUploadHistoryTable = () => {
    getTdsReconDetails();
  };

  const FunForcloseBoxWapper = () => {
    setCloseBoxWrapper(false);
  };

  return (
    <>
      <div className="new-reconciliation-form">
        <button
          className="button-outlined-primary"
          onClick={newReconFormModalOpen}
        >
          GSTR-9 Reconciliation
        </button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={newReconFormOpen}
          onHide={newReconFormModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton className="modal-header">
            <Modal.Title className="modal-title">
              GSTR-9 Reconciliation
            </Modal.Title>
          </Modal.Header>

          <ValidatorForm
            onSubmit={newReconFormSubmitHandler}
          >
            <Modal.Body>
              <div className="new-recon-form-body">
                <Grid container spacing={2}>
                  <Grid item sm>
                    <SelectField
                      SelectLabel="financial_year"
                      validator={["required"]}
                      requiredField="*"
                      errorMsg={["This field is required"]}
                      choice_id="year"
                      choice_name="year"
                      choices={yearFromArr}
                      name="financial_year"
                      value={newReconFormData.financial_year}
                      textOnchangeFunction={newReconYearChangeHandler}
                    />
                  </Grid>

                </Grid>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="button-primary"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={newReconFormModalClose}
              >
                Close
              </button>
            </Modal.Footer>
          </ValidatorForm>
        </Modal>
      </div>

      {closeBoxWrapper && (
        <BoxWrapper
          boxTitle="Error"
          cutIcon={true}
          children={errorMessageForBoxWrapper}
          closeBoxWapper={FunForcloseBoxWapper}
          colorForMessage="red"
          ErrorIcon={<ErrorOutlineOutlinedIcon />}
        />
      )}

      <BoxWrapper
        boxTitle="GSTR-9 Reconciliation"
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={refreshUploadHistoryTable}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Fiscal Year</th>
                <th>Period</th>
                <th>Created date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{historyTableBody()}</tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}
