import React from "react";
import classes from "../Table/Table.module.css";

const TableHeader = (columns) => {
  let tableHeader = columns;
  return tableHeader.map((value, index) => {
    return <th key={index}>{value.label.toUpperCase()}</th>;
  });
};

const TableBody = (props) => {
  if (props.data && props.data.length >= 1) {
    let columns = props.columns;
    return props.data.map((tableData, index) => {
      return (
        <tr key={index}>
          {props.multiselect ? (
            <td>
              {typeof props.selectCondition === "undefined" ||
              props.selectCondition(tableData) ? (
                <input
                  type="checkbox"
                  value={
                    typeof props.multiselectkey === "undefined"
                      ? index
                      : tableData[props.multiselectkey]
                  }
                  name={props.name + "[]"}
                />
              ) : (
                ""
              )}
            </td>
          ) : (
            ""
          )}
          {columns.map((v, i) => {
            return (
              <td>
                {typeof v.render === "undefined"
                  ? tableData[v.key]
                  : v.render(index, tableData)}
              </td>
            );
          })}
          {props.actions && (
            <td>
              {props.actions.map((v, i) => {
                return (
                  <a onClick={() => v.onclickEvent(index, tableData)}>
                    <span style={{ cursor: "pointer" }}>{v.label}</span>
                  </a>
                );
              })}
            </td>
          )}
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td style={{ color: "red" }} colspan={8}>
          No data found!
        </td>
      </tr>
    );
  }
};

const SelectAll = (event) => {
  let name = event.target.name;
  let inner_id_name = name.split("_all")[0];
  let all_names = document.getElementsByName(inner_id_name + "[]");
  if (event.target.checked) {
    for (let i = 0; i < all_names.length; i++) {
      all_names[i].checked = true;
    }
  } else {
    for (let i = 0; i < all_names.length; i++) {
      all_names[i].checked = false;
    }
  }
};

const Table = (props) => {
  return props.headers ? (
    <div className="responsive-table">
      <table className="custome-table">
        <tbody>
          <tr>
            {props.multiselect ? (
              <th>
                <input
                  type="checkbox"
                  value="1"
                  name={props.name + "_all"}
                  onChange={SelectAll}
                />
              </th>
            ) : (
              ""
            )}
            {TableHeader(props.headers)}
            {props.actions && <th>Actions</th>}
          </tr>
          {TableBody(props)}
        </tbody>
      </table>
    </div>
  ) : (
    ""
  );
};

export default Table;
