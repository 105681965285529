import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";

function ShowDetails(props) {
  const { showData, closeDrawer } = props;
  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <>
      {Object.keys(showData).length && (
        <div>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="ERP Vendor Code"
            margin="normal"
            value={showData.erpVendorCode}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="E Invoice"
            margin="normal"
            value={showData.eInvoice}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="   Booking/recording date
                "
            margin="dense"
            value={showData.bookingDate}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Debit Note Date"
            margin="normal"
            value={showData.debitNoteDate}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="HSN/SAC Code"
            margin="normal"
            value={showData.hsnSacCode}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Taxable Value"
            margin="normal"
            value={indianRupeesFormat(showData.taxableValue)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Non Taxable Value"
            margin="normal"
            value={indianRupeesFormat(showData.nonTaxableValue)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="SGST"
            margin="normal"
            value={indianRupeesFormat(showData.sgst)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="CGST"
            margin="normal"
            value={indianRupeesFormat(showData.cgst)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="IGST"
            margin="normal"
            value={indianRupeesFormat(showData.igst)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Cess"
            margin="normal"
            value={indianRupeesFormat(showData.cess)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Tax Amount"
            margin="normal"
            value={indianRupeesFormat(showData.taxAmount)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TCS Section"
            margin="normal"
            value={showData.tcsSection}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Total Amount"
            margin="normal"
            value={indianRupeesFormat(showData.totalAmount)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TDS Section"
            margin="normal"
            value={showData.tdsSection}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TDS Payable"
            margin="normal"
            value={indianRupeesFormat(showData.tdsPayable)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Expense Type"
            margin="normal"
            value={showData.expenseType}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Type of Input Tax Cedit"
            margin="normal"
            value={showData.taxCreditType}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="ITC Eligibility"
            margin="normal"
            value={showData.itcEligibility}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="RCM Applicability"
            margin="normal"
            value={showData.rcmApplicability}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Dispute"
            margin="normal"
            value={showData.dispute}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Dispute Value"
            margin="normal"
            value={indianRupeesFormat(showData.disputeValue)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Debit Note Reason"
            margin="normal"
            value={showData.debitNoteReason}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Bill of Entry Number"
            margin="normal"
            value={showData.boeNumber}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Bill of Entry Date"
            margin="dense"
            value={showData.boeDate}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Reference Date"
            margin="dense"
            value={showData.referenceDate}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Port Code"
            margin="normal"
            value={showData.portCode}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Create Date"
            margin="normal"
            value={showData.created_date}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Modify Date"
            margin="normal"
            value={showData.modified_date}
          />

          {/* submit and back button */}

          <button
            type="button"
            className="button-outlined-secondary mt-20"
            onClick={closeDrawer}
          >
            close
          </button>
        </div>
      )}
    </>
  );
}

export default ShowDetails;
