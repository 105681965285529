import React, { useEffect, useState, useRef } from "react";
import axios from "../../../../axios-order";
import {
  Button,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import PayableSummaryTable from "./PayableSummaryTable";
import PayableDetailsReportTable from "./PayableDetailsReport";
import PayablePieChart from "../../../../components/ui/PieCharts";
import BarChartRupeesFormat_withoutTooltipHeader from "../../../../components/ui/charts/barchart_withoutTooltipHeader";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import SelectField from "../../../../components/ui/basicSelectInput";
import BoxWapper from "../../../../components/ui/boxWrapper";

import "./Dashboard.css";

export default function Dashboard() {
  const inputEl = useRef(null);
  const [pieChartData, setPieChartData] = useState([]);
  const [payCycleSummaryData, setPayCycleSummaryData] = useState();

  const [payCyclePayableDetailsData, setpayCyclePayableDetailsData] =
    useState();
  const [
    payCyclePayableDetailsDownloadData,
    setpayCyclePayableDetailsDownloadData,
  ] = useState();

  const [showDateFilter, setShowDateFilter] = useState();
  const [payableCycleFilterData, setPayableCycleFilterData] =
    useState("As of Date");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loaderErrorMessage, setLoaderErrorMessage] = useState(false);

  const getPayableSummaryTableData = () => {
    setLoaderForTable(true);
    axios
      .get(
        `/vendor/pay_cycle_summary/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (res.data.error) {
          setLoaderErrorMessage(true);
          setLoaderForTable(false);
        } else {
          setLoaderForTable(false);
          setPayCycleSummaryData(res.data);
        }
      })
      .catch((error) => {
        setLoaderForTable(false);
        setLoaderErrorMessage(true);
      });
  };

  const getpaybleDetailedReportingData = (fromDate, toDate) => {
    axios
      .get(
        `/vendor/payble_detailed_reporting/${fromDate}/${toDate}/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setFromDate(null);
        setToDate(null);
        setpayCyclePayableDetailsData(res.data.records);
        setpayCyclePayableDetailsDownloadData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPieChartReportData = () => {
    axios
      .get(
        `/vendor/pie_chart_report/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        setLoading(false);
        setPieChartData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // current date
    var today = new Date();
    const pad = "00";
    const yy = today.getFullYear().toString();
    const mm = (today.getMonth() + 1).toString();
    const dd = today.getDate().toString();
    const currentDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;

    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthDate = lastDay.getDate().toString()
    const LastDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + lastMonthDate).slice(-2)}`

    getpaybleDetailedReportingData(currentDate, currentDate);

    getPayableSummaryTableData();
    getPieChartReportData();
  }, []);

  // ..............Bar chart data set............
  const PayablesBarChartData = [
    {
      name: "Total Overdue",
      y: payCycleSummaryData && payCycleSummaryData.total_overdue,
    },
    {
      name: "Total Current Due",
      y: payCycleSummaryData && payCycleSummaryData.total_current_due,
    },
    {
      name: "Total Not Yet Due",
      y: payCycleSummaryData && payCycleSummaryData.total_not_yet_due,
    },
    {
      name: "Total Payables",
      y: payCycleSummaryData && payCycleSummaryData.total_total_paybles,
    },
  ];

  const handlepayableCycleFilter = (event) => {
    const value = event.target.value;

    setPayableCycleFilterData(value);
    if (value === "As of Date") {
      // current date
      var today = new Date();
      const pad = "00";
      const yy = today.getFullYear().toString();
      const mm = (today.getMonth() + 1).toString();
      const dd = today.getDate().toString();
      const currentDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
        -2
      )}`;

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthDate = lastDay.getDate().toString()
    const LastDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + lastMonthDate).slice(-2)}`
    console.log(LastDate);

      // getpaybleDetailedReportingData(currentDate, LastDate);
      getpaybleDetailedReportingData(currentDate, currentDate);
      getPieChartReportData();
      getPayableSummaryTableData();
      setShowDateFilter(false);
    } else if (value === "Current Month") {
      var date = new Date();
      const pad = "00";
      const yy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();

      var firstDay = `${yy}-${(pad + mm).slice(-2)}-${(pad + 1).slice(-2)}`;
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const ld = lastDay.getDate().toString();
      const lDay = `${yy}-${(pad + mm).slice(-2)}-${(pad + ld).slice(-2)}`;
      setShowDateFilter(false);

      getpaybleDetailedReportingData(firstDay, lDay);
      getPieChartReportData();
      getPayableSummaryTableData();
    } else {
      setShowDateFilter(true);
    }
  };

  const handleFromDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setFromDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
    }
  };

  const handleToDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setToDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
    }
  };

  const filterDateSubmitHandler = () => {
    setLoading(true);

    getpaybleDetailedReportingData(fromDate, toDate);
    getPieChartReportData();
    getPayableSummaryTableData();
  };

  // download base64 files
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const payCycleExportToExcelDownload = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `payCycleSummary.xlsx`;
    axios
      .post(`/vendor/payablesummary/download`, {
        records_for_paycycle_summary1: payCycleSummaryData,
        records_for_paycycle_summary2: payCyclePayableDetailsDownloadData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4></h4>
        <div>
          {!loaderForTable && !loaderErrorMessage && (
            <button
              className="button-outlined-primary"
              onClick={payCycleExportToExcelDownload}
            >
              Export to Excel
            </button>
          )}
        </div>
      </div>

      {/* filter section  */}
      <BoxWapper boxTitle="Due Date-Filter">
        <ValidatorForm
          ref={inputEl}
          onSubmit={filterDateSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={3}>
            <Grid item xs={showDateFilter ? 4 : 12}>
              <label>Fiscal Year</label>
              <SelectField
                choice_id="payableCycleFilter"
                requiredField=""
                choice_name="payableCycleFilter"
                // SelectLabel="Filter"
                name="payableCycleFilter"
                value={payableCycleFilterData}
                textOnchangeFunction={handlepayableCycleFilter}
                choices={[
                  {
                    id: 0,
                    payableCycleFilter: "As of Date",
                  },
                  {
                    id: 1,
                    payableCycleFilter: "Current Month",
                  },
                  {
                    id: 2,
                    payableCycleFilter: "Custom Filter",
                  },
                ]}
              />
            </Grid>
            {showDateFilter && (
              <>
                <Grid item xs={3}>
                  <label>
                    From Date
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <ValidatedDatePicker
                    value={fromDate ?? ""}
                    onChange={(v) => handleFromDateChange(v)}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label>
                    To Date
                    <span style={{ color: "red" }}>*</span>:
                  </label>
                  <ValidatedDatePicker
                    minDateForDatepicker={fromDate}
                    value={toDate ?? ""}
                    onChange={(v) => handleToDateChange(v)}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </Grid>
                <Grid item xs={2}>
                  {loading && (
                    <Button
                      fullWidth
                      component="label"
                      size="small"
                      color="primary"
                      variant="outlined"
                      style={{
                        marginTop: "28px",
                        paddingTop: "6px",
                        paddingBottom: "7px",
                      }}
                    >
                      <CircularProgress size="20px" />
                      <span style={{ marginLeft: "5px" }}>Loading...</span>
                    </Button>
                  )}
                  {!loading && (
                    <button
                      style={{
                        marginTop: "28px",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                        width: "100%",
                      }}
                      className="button-primary"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </ValidatorForm>
      </BoxWapper>

      {loaderForTable ? (
        <p style={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress size={30} />
          <br></br>
          Loading,Please wait...
        </p>
      ) : loaderErrorMessage ? (
        <p style={{ textAlign: "center", color: "red", padding: "20px" }}>
          No Data Found!
        </p>
      ) : (
        <>
          {/* charts and tables */}
          <Grid container spacing={3}>
            {/* 1st row */}
            <Grid item xs={6}>
              <BoxWapper boxTitle="Payables">
                <div>
                  {payCycleSummaryData && (
                    <BarChartRupeesFormat_withoutTooltipHeader
                      yAxisTitle="Value"
                      data={PayablesBarChartData}
                    />
                  )}
                  {payCycleSummaryData && (
                    <Typography style={{ textAlign: "center", color: "#fff" }}>
                      *As per the date selection - no current due date available
                      to view pie chart
                    </Typography>
                  )}
                </div>
              </BoxWapper>
            </Grid>
            <Grid item xs={6}>
              <BoxWapper boxTitle="Payables Pie Chart">
                <div>
                  {pieChartData && <PayablePieChart data={pieChartData} />}

                  {pieChartData.length === 0 && (
                    <Typography style={{ textAlign: "center", color: "red" }}>
                      *As per the date selection - no current due date available
                      to view pie chart
                    </Typography>
                  )}
                </div>
              </BoxWapper>
            </Grid>
          </Grid>
          {/* 2nd row */}

          <BoxWapper boxTitle="Payables Summary">
            {" "}
            {payCycleSummaryData && (
              <PayableSummaryTable
                payCycleSummaryTableData={payCycleSummaryData}
              />
            )}
          </BoxWapper>

          <BoxWapper boxTitle="Payables Detailed Reporting">
            <div className="responsive-table">
              {payCyclePayableDetailsData && (
                <PayableDetailsReportTable
                  payableDetailsReportTableData={payCyclePayableDetailsData}
                />
              )}
            </div>
          </BoxWapper>
        </>
      )}
    </div>
  );
}
