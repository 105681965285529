import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

// indian rupeess system---
const indianRupessFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    // maximumSignificantDigits: 15,
  }).format(value?.toFixed(2));
// ---End---

//Number Into Million

const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

const StackedBarChart = (props) => (
  <HighchartsReact
    highcharts={Highcharts}
    allowChartUpdate={true}
    options={setOptions(props)}
  />
);

const setOptions = (props) => {
  const { fnForDrillDown } = props;
  return (
    // props.data &&
    {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: "category",
        title: {
          text: props.xAxisTitle,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: props.yAxisTitle,
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              "gray",
          },
          formatter: function () {
            return numFormatter(this.total);
          },
        },
      },
      // legend: {
      //   align: "right",
      //   x: -30,
      //   verticalAlign: "bottom",
      //   y: 55,
      //   floating: true,
      //   backgroundColor:
      //     Highcharts.defaultOptions.legend.backgroundColor || "white",
      //   borderColor: "#CCC",
      //   borderWidth: 1,
      //   shadow: false,
      // },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",

        formatter: function () {
          return this.series.name + ":" + indianRupessFormat(this.point.y);
        },
      },
      plotOptions: {
        series: {
          grouping: false,
        },
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
          cursor: "pointer",
          point: {
            events: {
              click: function (e) {
                fnForDrillDown(e.point.drilldownKey);
              },
            },
          },
        },
      },
      series: props.data,
    }
  );
};

export default StackedBarChart;
