import React from "react";
import classes from "./Report.module.css";

function GSTSummaryDashBoard(props) {
  const { SummaryData } = props;

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---b2bExportData

  return (
    <>
      <div className={classes.B2cExport}>
        {SummaryData && (
          <>
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
              }}
            >
              <h6>Invoice(s)</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
    
                {SummaryData.invoices.map((invItem, i) => (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices ? parseInt(invItem.num_invoices) : 0  }</td>
    <td>{invItem.taxable_value ? `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.igst_value ? `₹${parseFloat(invItem.igst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.cgst_value ? `₹${parseFloat(invItem.cgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.sgst_value ? `₹${parseFloat(invItem.sgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.cess_value ? `₹${parseFloat(invItem.cess_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.total_gst_value ? `₹${parseFloat(invItem.total_gst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
    <td>{invItem.total_invoice_value ? `₹${parseFloat(invItem.total_invoice_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}` : "₹0.00"}</td>
                    </tr>
))}




              </table>
            </div>

            {/* ........credit notes....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Credit Notes</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
     
                {/* {SummaryData.creditNotes.map((item, i) => (
                    <tr key={i}>
    <td>{item.Particulars}</td>
    <td>{item.num_invoices === "0" ? "0" : parseInt(item.num_invoices)}</td>
    <td>
      {item.taxable_value === null
        ? "0"
        : `${parseFloat(item.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })} ${item.taxable_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.igst_value === null
        ? "0"
        : `${parseFloat(item.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.igst_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.cgst_value === null
        ? "0"
        : `${parseFloat(item.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.cgst_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.sgst_value === null
        ? "0"
        : `${parseFloat(item.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.sgst_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.cess_value === null
        ? "0"
        : `${parseFloat(item.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.cess_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.total_gst_value === null
        ? "0"
        : `${parseFloat(item.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.total_gst_value !== "0" && '₹'}`}
    </td>
    <td>
      {item.total_invoice_value === null
        ? "0"
        : `${parseFloat(item.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${item.total_invoice_value !== "0" && '₹'}`}
    </td>
                    </tr>
))} */}
{SummaryData.creditNotes.map((item, i) => (
  <tr key={i}>
    <td>{item.Particulars}</td>
    <td>{item.num_invoices === "0" ? "0" : parseInt(item.num_invoices)}</td>
    <td>
      {item.taxable_value === null
        ? "₹0"
        : `₹${parseFloat(item.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}
    </td>
    <td>
      {item.igst_value === null
        ? "₹0"
        : `₹${parseFloat(item.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
    <td>
      {item.cgst_value === null
        ? "₹0"
        : `₹${parseFloat(item.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
    <td>
      {item.sgst_value === null
        ? "₹0"
        : `₹${parseFloat(item.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
    <td>
      {item.cess_value === null
        ? "₹0"
        : `₹${parseFloat(item.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
    <td>
      {item.total_gst_value === null
        ? "₹0"
        : `₹${parseFloat(item.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
    <td>
      {item.total_invoice_value === null
        ? "₹0"
        : `₹${parseFloat(item.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
    </td>
  </tr>
))}










              </table>
            </div>

            {/* .......Invoice note....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Debit Notes</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
  
                {/* {SummaryData.debitNotes.map((invItem, i) => (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === "0" ? "0" : (invItem.num_invoices ? parseInt(invItem.num_invoices) : 0)}</td>
    <td>{invItem.taxable_value === null ? "0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "0" : `₹${parseFloat(invItem.igst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.cgst_value === null ? "0" : `₹${parseFloat(invItem.cgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.sgst_value === null ? "0" : `₹${parseFloat(invItem.sgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.cess_value === null ? "0" : `₹${parseFloat(invItem.cess_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.total_gst_value === null ? "0" : `₹${parseFloat(invItem.total_gst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.total_invoice_value === null ? "0" : `₹${parseFloat(invItem.total_invoice_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
                    </tr>
))} */}

{SummaryData.debitNotes.map((invItem, i) => (
  <tr key={i}>
    <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === "0" ? "0" : (invItem.num_invoices ? parseInt(invItem.num_invoices) : 0)}</td>
    <td>{invItem.taxable_value === null ? "₹0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "₹0" : `₹${parseFloat(invItem.igst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.cgst_value === null ? "₹0" : `₹${parseFloat(invItem.cgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.sgst_value === null ? "₹0" : `₹${parseFloat(invItem.sgst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.cess_value === null ? "₹0" : `₹${parseFloat(invItem.cess_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.total_gst_value === null ? "₹0" : `₹${parseFloat(invItem.total_gst_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.total_invoice_value === null ? "₹0" : `₹${parseFloat(invItem.total_invoice_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
  </tr>
))}






              </table>
            </div>

            {/* .......Import invoices....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>Import Invoices</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {/* {SummaryData.importInvoices.map((invItem, i) => (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === "0" ? "0" : parseInt(invItem.num_invoices)}</td>
    <td>{invItem.taxable_value === null ? "0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "0" : `₹${parseFloat(invItem.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cgst_value === null ? "0" : `₹${parseFloat(invItem.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.sgst_value === null ? "0" : `₹${parseFloat(invItem.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cess_value === null ? "0" : `₹${parseFloat(invItem.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_gst_value === null ? "0" : `₹${parseFloat(invItem.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_invoice_value === null ? "0" : `₹${parseFloat(invItem.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
                    </tr>
))} */}
{SummaryData.importInvoices.map((invItem, i) => (
  <tr key={i}>
    <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === "0" ? "0" : parseInt(invItem.num_invoices)}</td>
    <td>{invItem.taxable_value === null ? "₹0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "₹0" : `₹${parseFloat(invItem.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cgst_value === null ? "₹0" : `₹${parseFloat(invItem.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.sgst_value === null ? "₹0" : `₹${parseFloat(invItem.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cess_value === null ? "₹0" : `₹${parseFloat(invItem.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_gst_value === null ? "₹0" : `₹${parseFloat(invItem.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_invoice_value === null ? "₹0" : `₹${parseFloat(invItem.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
  </tr>
))}




              </table>
            </div>

            {/* .......SEZ invoices....... */}
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              <h6>SEZ Invoices</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>CESS</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>
                {/* {SummaryData.sezInvoices.map((invItem, i) => (
                    <tr key={i}>
                      <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === null ? "0" : parseInt(invItem.num_invoices)}</td>
    <td>{invItem.taxable_value === null ? "0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "0" : `₹${parseFloat(invItem.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cgst_value === null ? "0" : `₹${parseFloat(invItem.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.sgst_value === null ? "0" : `₹${parseFloat(invItem.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cess_value === null ? "0" : `₹${parseFloat(invItem.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_gst_value === null ? "0" : `₹${parseFloat(invItem.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_invoice_value === null ? "0" : `₹${parseFloat(invItem.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
                    </tr>
))} */}
{SummaryData.sezInvoices.map((invItem, i) => (
  <tr key={i}>
    <td>{invItem.Particulars}</td>
    <td>{invItem.num_invoices === null ? "0" : parseInt(invItem.num_invoices)}</td>
    <td>{invItem.taxable_value === null ? "₹0" : `₹${parseFloat(invItem.taxable_value).toLocaleString("en-IN", { maximumFractionDigits: 2 })}`}</td>
    <td>{invItem.igst_value === null ? "₹0" : `₹${parseFloat(invItem.igst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cgst_value === null ? "₹0" : `₹${parseFloat(invItem.cgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.sgst_value === null ? "₹0" : `₹${parseFloat(invItem.sgst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.cess_value === null ? "₹0" : `₹${parseFloat(invItem.cess_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_gst_value === null ? "₹0" : `₹${parseFloat(invItem.total_gst_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
    <td>{invItem.total_invoice_value === null ? "₹0" : `₹${parseFloat(invItem.total_invoice_value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
  </tr>
))}




              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GSTSummaryDashBoard;
