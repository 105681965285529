import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, Drawer, TextField } from "@material-ui/core";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "../../../../../axios-order";
import Card from "../../../../../components/ui/Cards";
import CircularProgress from "@material-ui/core/CircularProgress";
import VendorDebitNote from "../../../../../asserts/templates/vendor_debit_note.xlsx";
import EditDebitNoteForm from "./editDebit";
import "./DebitNote.css";
import AddDebitNoteForm from "./addDebit";
import ShowDebitComp from "./showDebit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ValidatedDatePicker from "../../../../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import ZohoShowDebit from "./ZohoShowDebit";
import { TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";

import ToggleSwitch from "../../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../../components/ui/RightDrawer";
import ZohoModel from "../../../../../components/ZohoModel/ZohoModel";

let flagForDebitNote = 1;

function DebitMgmt(props) {
  const onCickOperation = true;
  const [openAddFormDrawer, setOpenAddFormDrawer] = useState(false);
  const [openShowDrawer, setOpenShowDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [editData, setEditData] = useState({});
  const [showData, setShowData] = useState({});

  const [uploadSection, setUploadSection] = useState(false);
  const [uploadResData, setUploadResData] = useState();
  const [uploadErrorMsg, setUploadErrorMsg] = useState();

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [tableSearch, setTableSearch] = useState("");
  const [stateForPage, setStateForPage] = useState(false);
  const [stateForRowPerPage, setStateForRowPerPage] = useState(false);
  const [stateTableSearch, setStateTableSearch] = useState(false);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [timers, setTimers] = useState(60);
  let timerforZoho = useRef();

  const Header = [
    { label: "vendor GSTIN" },
    { label: "BILL NUMBER" },
    { label: "BILL DATE" },
    { label: "Debit Note Number" },
    { label: "Debit Note Date" },
  ];

  const Column = [
    {
      key: "vendorGstin",
      render: (index, row) => {
        return row["vendorGstin"];
      },
    },
    {
      key: "originalBillNumber",
      render: (index, row) => {
        return row["originalBillNumber"];
      },
    },
    {
      key: "originalBillDate",
      render: (index, row) => {
        return row["originalBillDate"];
      },
    },
    {
      key: "debitNoteNumber",
      render: (index, row) => {
        return row["debitNoteNumber"];
      },
    },
    {
      key: "debitNoteDate",
      render: (index, row) => {
        return row["debitNoteDate"];
      },
    },
  ];
  const actionHeaderForZoho = [
    { id: "erpVendorCode", name: "ERP Vendor Code+" },
    { id: "vendorPan", name: "PAN" },
    { id: "bookingDate", name: "Booking date" },
    { id: "eInvoice", name: "E-Invoice" },
    { id: "hsnSacCode", name: "HSN/SAC Code" },
    { id: "taxableValue", name: "Taxable Value" },
    { id: "nonTaxableValue", name: "Non Taxable Value" },
    { id: "sgst", name: "SGST" },
    { id: "cgst", name: "CGST" },
    { id: "igst", name: "IGST" },
    { id: "cess", name: "CESS" },
    { id: "taxAmount", name: "Tax Amount" },
    { id: "tcsSection", name: "TCS Section" },
    { id: "tcsPayable", name: "TCS Payable" },
    { id: "totalAmount", name: "Total Amount" },
    { id: "tdsSection", name: "TDS Section" },
    { id: "tdsPayable", name: "TDS Payables" },
    { id: "expenseType", name: "Expense Type" },
    { id: "taxCreditType", name: "Type of tax credit" },
    { id: "itcEligibility", name: "ITC Eligibility" },
    { id: "rcmApplicability", name: "RCM Applicability" },
    { id: "dispute", name: "Dispute" },
    { id: "disputeValue", name: "Dispute Value" },
    { id: "debitNoteReason", name: "Debit Note Reason" },
    { id: "boeNumber", name: "Bill of Entry Number" },
    { id: "boeDate", name: "Bill of Entry Date" },
    { id: "referenceDate", name: "Reference Date" },
    { id: "portCode", name: "Port Code" },
    { id: "fetrack", name: "Fetrack" },
  ];

  //...............Zoho...........................
  const [zohoTriggerForDebit, setZohoTriggerForDebit] = useState({
    messageForConnection: "Establish Connection",
    buttonColor: "red",
    zohoShowButton: false,
  });
  const [triggerZohoBackImportCwsButton, setTriggerZohoBackImportCwsButton] =
    useState({
      zohoEffitrack: true,
      importToCws: false,
      backButton: false,
    });
  const [url, setUrl] = useState("");
  const [grantToken, setGrantToken] = useState("");
  const [zohoDate, setZohoDate] = useState({});
  const [submitLoaderForDebit, setSubmitLoaderForDebit] = useState(false);
  const [tableForZohoForDebit, setTableForZohoForDebit] = useState([]);
  const [tableForZohoSearchForDebit, setTableForZohoSearchForDebit] = useState(
    []
  );
  const [showForZoho, setShowForZoho] = useState({});
  const [showForZohoButton, setShowForZohoButton] = useState(false);

  const [uploadHistoryTableData, setUploadHistoryTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDebitNoteDetails = (serachIn) => {
    const finalData = {
      user_id: localStorage.getItem("user_id"),
      number_of_rows_per_page: rowsPerPage,
      page_number: page,
      search_text: serachIn,
      business_entity_id: localStorage.getItem("business_entity_id"),
    };

    axios
      .post(`/get_vendor_debit_details`, finalData)
      .then((res) => {
        setRowCount(res.data.row_count);
        setTableData(res.data.record);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // table pagination fns
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStateForPage(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setStateForRowPerPage(true);
  };

  // table serach
  const SearchInputHandler = (event) => {
    setTableSearch(event.target.value);
    setRowsPerPage(25);
    setPage(0);
    setStateTableSearch(true);
  };

  // upload history table data api fn call
  const getUploadHistoryDataApi = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${14}`
      )
      .then((res) => {
        setUploadHistoryTableData(res.data.record);
      })
      .catch((error) => {
        console.log(error);
        // alert("Unable to complete your request, Please retry!");
      });
  };

  useEffect(() => {
    if (stateTableSearch) {
      getDebitNoteDetails(tableSearch);
    }
    setStateTableSearch(false);
  }, [tableSearch]);

  useEffect(() => {
    if (stateForRowPerPage) {
      getDebitNoteDetails(tableSearch);
    }
    setStateForRowPerPage(false);
  }, [rowsPerPage]);

  useEffect(() => {
    if (stateForPage) {
      getDebitNoteDetails(tableSearch);
    }
    setStateForPage(false);
  }, [page]);

  useEffect(() => {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && setGrantToken(code);

    let drawer = domain.get("drawer");
    drawer &&
      localStorage.getItem("DrawerForDebit") === "true" &&
      setZohoTriggerForDebit({
        ...zohoTriggerForDebit,
        zohoShowButton: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    setUrl(url.origin);
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    getDebitNoteDetails();
    getUploadHistoryDataApi();
  }, []);

  // serach handler
  const searchHandler = (e) => {
    const value = e.target.value;
    let serachDataForZoho = tableForZohoSearchForDebit.filter((v) => {
      let SearchValue = value.trim();
      return (
        String(v.vendorGstin)
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(v.originalBillNumber)
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(moment(v.originalBillDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(moment(v.originalBillDate).format("DD/MM/YYYY"))
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(v.debitNoteNumber)
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(moment(v.debitNoteDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0 ||
        String(moment(v.debitNoteDate).format("DD/MM/YYYY"))
          .toLowerCase()
          .indexOf(SearchValue.toLowerCase()) >= 0
      );
    });
    setTableForZohoForDebit(serachDataForZoho);
  };

  const fileUploader = (event) => {
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendordebitnote`, {
          file: file_value,
          org_code: localStorage.getItem("business_entity_id"),
        })
        .then((res) => {
          getUploadHistoryDataApi();
        });
    };
  };

  const closeUploadBoxWapper = () => {
    setUploadSection(false);
    getDebitNoteDetails();
  };

  const addFormHandler = () => {
    setOpenAddFormDrawer(true);
  };

  const closeDrawer = () => {
    setOpenAddFormDrawer(false);
    setOpenEditDrawer(false);
    setOpenShowDrawer(false);
  };
  const editDataHandler = (item) => {
    delete item.vendorCode;
    delete item.orgCode;
    setEditData(item);
    setOpenEditDrawer(true);
  };

  const showDetailsHandler = (item) => {
    setShowData(item);
    setOpenShowDrawer(true);
  };

  //excel download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = (Data) => {
    let Datas = {};
    if (zohoTriggerForDebit.zohoShowButton) {
      Datas = {
        zoho_data: Data,
        business_entity_id: localStorage.getItem("business_entity_id"),
      }
    } else {
      Datas = {
        business_entity_id: localStorage.getItem("business_entity_id"),
      };
    }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendor_debit_note.xlsx`;

    axios
      .post("/vendor/vendordebitnotedownload", Datas)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      setZohoDate({
        ...zohoDate,
        [name]: dateValue,
      });
    } else {
      setZohoDate({
        ...zohoDate,
        [name]: dateValue,
      });
    }
  };

  useEffect(() => {
    if (zohoTriggerForDebit.buttonColor === "green") {
      timerforZoho.current = setInterval(() => {
        setTimers((prevState) => prevState - 1);
      }, 1000);
    }
  }, [zohoTriggerForDebit.buttonColor]);

  useEffect(() => {
    if (timers === 0) {
      clearInterval(timerforZoho.current);
      setTimers(60);
      setZohoTriggerForDebit({
        ...zohoTriggerForDebit,
        buttonColor: "red",
      });
    }
  }, [timers]);

  const handleZohoFun = () => {
    flagForDebitNote = 1;
    setZohoTriggerForDebit({
      ...zohoTriggerForDebit,
      zohoShowButton: true,
    });
    localStorage.setItem("DrawerForDebit", "true");
  };

  const closeZohoDrawer = () => {
    clearInterval(timerforZoho.current);
    setZohoTriggerForDebit({
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      zohoShowButton: false,
    });
    setZohoDate({});
    setSubmitLoaderForDebit(false);
    localStorage.setItem("DrawerForDebit", "false");
  };
  const establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.settings.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${url}/zohoTesting&access_type=offline&prompt=Consent`;
  };

  const fetchingdataForZoho = () => {
    clearInterval(timerforZoho.current);
    flagForDebitNote += 1;
    toast.configure();

    if (flagForDebitNote === 2) {
      setSubmitLoaderForDebit(true);
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: grantToken,
          type_of_data: "purchase_debit_note",
          redirect_uri: `${url}/zohoTesting`,
          date: zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            // closeZohoDrawer();
            setSubmitLoaderForDebit(false);
            setZohoTriggerForDebit({
              messageForConnection: "Establish Connection",
              buttonColor: "red",
              zohoShowButton: true,
            });
            setZohoDate({});
            // setTableForZohoForDebit(res.data.records);
            // setTableForZohoSearchForDebit(res.data.records);
            // setTriggerZohoBackImportCwsButton({
            //   zohoEffitrack: false,
            //   importToCws: true,
            //   backButton: true,
            // });
          } else {
            // closeZohoDrawer();
            setZohoTriggerForDebit({
              messageForConnection: "Establish Connection",
              buttonColor: "red",
              zohoShowButton: true,
            });
            setZohoDate({});
            setSubmitLoaderForDebit(false);
            toast.error("No Record Found!", {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          // closeZohoDrawer();
          setZohoTriggerForDebit({
            messageForConnection: "Establish Connection",
            buttonColor: "red",
            zohoShowButton: true,
          });
          setZohoDate({});
          setSubmitLoaderForDebit(false);
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };
  const handleImportToCWS = (dataFromZoho) => {
    axios
      .post(`/arc/effitrac/vendordebits`, {
        records: dataFromZoho,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        refreshUploadHistoryTable();
        setShowUploadHistoryTable(true);
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // const handleBackButton = () => {
  //   setShowUploadHistoryTable(false);
  //   setTriggerZohoBackImportCwsButton({
  //     zohoEffitrack: true,
  //     importToCws: false,
  //     backButton: false,
  //   });
  // };

  const handlerShowForZohoForDebit = (item) => {
    setShowForZoho(item);
    setShowForZohoButton(true);
  };

  const closeShowDrawerForZoho = () => {
    setShowForZohoButton(false);
  };

  const uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((res) => {
        setUploadSection(true);
        var jsonData = res.data.records;
        if (res.data.records.type === "message") {
          setUploadResData();
          setUploadErrorMsg(res.data.records.message);
        } else {
          setUploadErrorMsg();
          getDebitNoteDetails();
          setUploadResData(jsonData);
        }
      })
      .catch((error) => {});
  };

  // refresh Upload History Table using refresh button
  const refreshUploadHistoryTable = () => {
    getUploadHistoryDataApi();
    getDebitNoteDetails();
  };

  const errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: uploadResData,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendorDebitNoteUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {(localStorage.getItem('is_uploads') !== "N")&&<>
      <div>
        <ToggleSwitch
          onChange={() => setShowUploadHistoryTable(!showUploadHistoryTable)}
          value={showUploadHistoryTable}
          label={`Upload Debit Note`}
        />
      </div>

      {showUploadHistoryTable && (
        <BoxWrapper
          boxTitle="Upload History"
          zohoEffitracEnable={localStorage.getItem("Client_ID") && true}
          zohoEffitracFn={handleZohoFun}
        >
          <a
            href={VendorDebitNote}
            download={"vendor_debit_note.xlsx"}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <Button component="label" size="small" color="primary">
              <GetAppIcon style={{ fontSize: "20px" }} />
              DOWNLOAD TEMPLATE
            </Button>
          </a>

          <Button component="label" size="small" color="primary">
            <PublishIcon style={{ fontSize: "20px" }} />
            UPLOAD TEMPLATE
            <input
              accept=".xlsx"
              name="file"
              type="file"
              value=""
              hidden
              onChange={fileUploader}
            />
          </Button>

          <Button
            component="label"
            size="small"
            color="primary"
            style={{ float: "right" }}
            onClick={refreshUploadHistoryTable}
          >
            <RefreshIcon style={{ fontSize: "20px" }} />
            Refresh
          </Button>

          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>
                  <th>Last Upload Date & Time</th>
                  <th>Status</th>
                  <th>Upload Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadHistoryTableData.length > 0 &&
                  uploadHistoryTableData.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.upload_date}</td>
                        <td>{item.status}</td>
                        <td>{item.upload_type}</td>
                        <td>
                          {item.status === "In Progress" ? (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button"
                              color="disabled"
                            />
                          ) : item.status === "Uploaded" ? (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button"
                              color="disabled"
                            />
                          ) : (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button showIcon"
                              onClick={() => uploadReport(item.id)}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {uploadHistoryTableData.length === 0 && (
                  <tr>
                    <td
                      style={{ color: "red", textAlign: "center" }}
                      colspan={4}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      )}
      </>}

      {/* upload Details section */}
      {uploadSection && (
        <BoxWrapper
          boxTitle="Upload Details"
          cutIcon={uploadSection}
          closeBoxWapper={closeUploadBoxWapper}
          enableAddButton={
            uploadResData && uploadResData.records.summary.invalid_records > 0
              ? true
              : null
          }
          addButtonLabel="EXPORT"
          addButtonHandlerFn={errorLogExportHandlerForUpload}
        >
          {uploadErrorMsg && (
            <div style={{ marginBottom: "20px", color: "red" }}>
              {uploadErrorMsg}
            </div>
          )}

          {uploadResData && (
            <>
              <div>
                <Grid container spacing={3}>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#6f9eaf"
                      cardTitle="Total Records"
                      cardBody={uploadResData.records.summary.total_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#007965"
                      cardTitle="Successful Records"
                      cardBody={uploadResData.records.summary.valid_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#eb596e"
                      cardTitle="Error Records"
                      cardBody={uploadResData.records.summary.invalid_records}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="upload-details-table-section">
                <div className="tableResponisve">
                  {uploadResData.status === false && (
                    <table className="tableCss">
                      <thead>
                        <tr>
                          <th>GSTIN</th>
                          <th>Row #</th>
                          <th>Status</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploadResData.status === false &&
                          uploadResData.records.details.map((item, i, arr) => {
                            if (item.status === "Fail") {
                              return (
                                <tr>
                                  <td>{item.record.vendorGstin}</td>
                                  <td>{item.row}</td>
                                  <td>{item.status}</td>
                                  <td style={{ color: "red" }}>
                                    {Object.keys(item.errors_in).map(
                                      (v, i, ar) => {
                                        if (ar.length > 1) {
                                          return (
                                            <div>
                                              <span style={{ color: "black" }}>
                                                {v}
                                              </span>{" "}
                                              : {item.errors_in[v].join(",")}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              <span style={{ color: "black" }}>
                                                {v}
                                              </span>{" "}
                                              : {item.errors_in[v].join(",")}
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </>
          )}
        </BoxWrapper>
      )}

      {/* Invoice details table */}
      <BoxWrapper
        boxTitle="Debit Note Details"
        exportToExcelEnable={true}
        exportToExcelFn={() => exportToExcel(tableData)}
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={
          triggerZohoBackImportCwsButton.backButton
            ? searchHandler
            : SearchInputHandler
        }
      >
        <div style={{ marginBottom: "20px" }}>
          {/* add and search handler */}

          {/* {!triggerZohoBackImportCwsButton.backButton && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={addFormHandler}
                >
                  Add Debit note
                </Button>
              )} */}

          {/* invoice details table */}
          <>
            <div className="responsive-table">
              <table className="custome-table">
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Bill Number</th>
                    <th>Bill Date</th>
                    <th>Debit Note Number</th>
                    <th>Gstin</th>
                    <th>Pan</th>
                    <th>Show</th>
                    {/* <th>Show</th> */}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, i, arr) => {
                    return (
                      <tr>
                        <td>{item.vendorName}</td>
                        <td>{item.originalBillNumber}</td>
                        <td>{item.originalBillDate}</td>
                        <td>{item.debitNoteNumber}</td>
                        <td>{item.vendorGstin}</td>
                        <td>{item.vendorPan}</td>
                        {/* <td>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => editDataHandler(item)}
                            >
                              Edit
                            </Button>
                          </td> */}
                        <td>
                          <RemoveRedEyeOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => showDetailsHandler(item)}
                          />{" "}
                        </td>
                      </tr>
                    );
                  })}

                  {tableData.length === 0 && loading === false && (
                    <tr>
                      <td
                        colSpan={8}
                        style={{ color: "red", textAlign: "center" }}
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )}

                  {loading && (
                    <tr>
                      <td
                        colSpan={8}
                        style={{
                          color: "black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Loading...Please wait
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={rowCount} //total row count
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />{" "}
          </>
        </div>
      </BoxWrapper>

      {/* add Form drawer */}
      <Drawer
        anchor="right"
        open={openAddFormDrawer}
        onClose={() => setOpenAddFormDrawer(false)}
        variant={onCickOperation === true ? "persistent" : ""}
      >
        <div className="Drawer-title">Add Debit Note</div>
        <div className="drawer-body">
          <AddDebitNoteForm
            updateTableAfterSubmit={getDebitNoteDetails}
            closeAddFormDrawer={closeDrawer}
            getDebitNoteDetail={getDebitNoteDetails}
          />
        </div>
      </Drawer>

      {/* edit drawer */}
      <Drawer
        anchor="right"
        open={openEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      >
        <div className="Drawer-title">Edit Debit Note</div>
        <div className="drawer-body">
          <EditDebitNoteForm
            editData={editData}
            updateTableAfterSubmit={getDebitNoteDetails}
            closeEditFormDrawer={closeDrawer}
            getDebitNoteDetail={getDebitNoteDetails}
          />
        </div>
      </Drawer>

      {/* show drawer */}
      <RightDrawer
        drawerName="Debit Note Details"
        open={openShowDrawer}
        onClose={() => setOpenShowDrawer(false)}
      >
        <ShowDebitComp showData={showData} closeDrawer={closeDrawer} />
      </RightDrawer>

      <ZohoModel
        openZohoDrawer={zohoTriggerForDebit.zohoShowButton}
        buttonColor={zohoTriggerForDebit.buttonColor}
        establishConnection={establishConnection}
        messageForConnection={zohoTriggerForDebit.messageForConnection}
        timerForZohoInSalesCustomer={timers}
        closeZohoDrawer={closeZohoDrawer}
        zohoDateChangeHandler={zohoDateChangeHandler}
        effitracImportFormloader={submitLoaderForDebit}
        fetchingdataForZoho={fetchingdataForZoho}
        zohoDate={zohoDate}
        logApitName="purchase_debit_note"
        nameOfTheModel="Debit Note Management"
        importToCWS={handleImportToCWS}
        exportToExcel={exportToExcel}
        zohoTableHeader={Header}
        zohoTableColumn={Column}
        drawerKeys={actionHeaderForZoho}
      />
    </div>
  );
}

export default DebitMgmt;
