// import React from 'react';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import { ValidatorComponent } from 'react-material-ui-form-validator';

// class ValidatedDatePicker extends ValidatorComponent {
//   renderValidatorComponent() {
//     const {
//       errorMessages,
//       validators,
//       requiredError,
//       helperText,
//       minDateForDatepicker,
//       validatorListener,
//       value,
//       maxDateForDatepicker,
//       ...rest
//     } = this.props;
//     const { isValid } = this.state;

//     return (
//       <div>
//         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//           <KeyboardDatePicker
//             {...rest}
//             fullWidth="true"
//             size="small"
//             format="dd/MM/yyyy"
//             inputVariant="outlined"
//             error={!isValid}
//             value={value || null}
//             minDate={minDateForDatepicker}
          
//             maxDate={maxDateForDatepicker}
//             helperText={(!isValid && this.getErrorMessage()) || helperText}
//             style={{ background: '#fff', borderRadius: '5px' }}
//             // inputProps={{ readOnly: true }}
//             KeyboardButtonProps={{
//               'aria-label': 'change date',
//             }}
//           />
//         </MuiPickersUtilsProvider>
//       </div>
//     );
//   }
// }
// //testing
// export default ValidatedDatePicker;
import React from 'react';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class ValidatedDatePicker extends React.Component {


  render() {
    const { value, ...rest } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...rest}
          fullWidth={true}
          size="small"
          format="MM/yyyy"
          inputVariant="outlined"
          value={value ? moment(value, 'MMYYYY') : null}
    
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default ValidatedDatePicker;

