import React, { useState } from 'react';
import BoxWrapper from '../boxwra';
import ConfirmationDialog from '../../../Sales/TDSReconciliationSale/ConfirmationDailog';


function Sixteen({handleBack, updateTableData, data }) {
  const [tableData, setTableData] = useState(data);
  const [tableName, setTableName] = useState("16.Information on supplies received from composition taxpayers, deemed supply under section 143 and goods sent on approval basis")


  const [saveDailog, setSaveDailogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [confirmBack, setConfirmBack] = useState(false);

  const submitHandle = () => {
    updateTableData(tableName, tableData)
    setUnsavedChanges(false)
  };

  const handleBackButton = () => {
    if (unsavedChanges) {
      setConfirmBack(true);
    } else {
      handleBack();
    }
  };

  const confirmBackHandle = () => {
    submitHandle()
    handleBack()
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [itemName, field] = name.split(',');

    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === itemName
          ? { ...item, [field]: value }
          : item
      )
    );

    setUnsavedChanges(true)
  };


  return (
    <>
      <BoxWrapper
        backButtonForZohoEnable={true}
        backButton={handleBackButton}
        boxTitle={tableName}
      >
        <>
          <p style={{ marginLeft: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Note: </span> The fields where the system computed values would be modified by more/less 20% shall be highlighted in <span style={{ color: 'red' }}>Red</span> for reference and attention.
          </p>
          <hr style={{ marginLeft: '10px' }} />
        </>

        <div style={{ maxHeight: '47vh' }} className="responsive-table">
          <table style={{ width: '100%', backgroundColor: 'white' }} className="summary-tab-single-year-table-css">
            <thead>
              <tr >
                <th style={{ width: '30%' }}>Details</th>
                <th>Taxable Value (₹)</th>
                <th>Integrated Tax (₹)</th>
                <th>Central Tax (₹)</th>
                <th>State/UT Tax (₹)</th>
                <th>CESS (₹)</th>
              </tr>
            </thead>
            <tbody>
              {(tableData && tableData.length>=1) ? tableData.map((item, index) => {
                return (
                  <tr key={index} style={{ backgroundColor: 'white' }}>
                    <td >{item ? item.name : ''}</td>
                    <td>
                      <input
                        type="number"
                        className='blue-color'
                        name={`${item.name},taxable_value`}
                        value={item.taxable_value}
                        onChange={handleInputChange}
                        style={{ width: '150px' }}
                        disabled={!item.manual_type}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className='blue-color'
                        name={`${item.name},igst`}
                        value={item.igst}
                        onChange={handleInputChange}
                        style={{ width: '150px' }}
                        disabled={!item.manual_type}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className='blue-color'
                        name={`${item.name},cgst`}
                        value={item.cgst}
                        onChange={handleInputChange}
                        style={{ width: '150px' }}
                        disabled={!item.manual_type}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className='blue-color'
                        name={`${item.name},sgst`}
                        value={item.sgst}
                        onChange={handleInputChange}
                        style={{ width: '150px' }}
                        disabled={!item.manual_type}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className='blue-color'
                        name={`${item.name},cess`}
                        value={item.cess}
                        onChange={handleInputChange}
                        style={{ width: '150px' }}
                        disabled={!item.manual_type}
                      />
                    </td>
                  </tr>
                );
              }):(
                <tr>
                  <td colSpan={6} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style
          ={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
          <button type="button" className='button-primary' onClick={() => setSaveDailogOpen(true)}>Save</button>
          <button type="button" className='button-primary'>Cancel</button>
        </div>

      </BoxWrapper>
      <ConfirmationDialog
        open={saveDailog}
        onClose={() => setSaveDailogOpen(false)}
        onConfirm={submitHandle}
        title="Confirm Save"
        text="Do you want to save your changes? This action will update the current data."
      />

      <ConfirmationDialog
        open={confirmBack}
        onConfirm={confirmBackHandle}
        onClose={() => handleBack()}
        title="Unsaved Changes"
        text="You have unsaved changes. Do you want to leave without saving?"
      />
    </>
  );
}

export default Sixteen;
