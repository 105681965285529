import React, { memo } from "react";
import Drawer from "@material-ui/core/Drawer";

const RightDrawer = (props) => {
  const { open, onClose, drawerName, children } = props;
  return (
    <Drawer anchor="right" variant="persistent" open={open} onClose={onClose}>
      <div className="drawer">
        <div className="header">
          <h4>{drawerName ?? "Drawer Header"}</h4>
        </div>
        <div className="body">{children}</div>
      </div>
    </Drawer>
  );
};

export default memo(RightDrawer);
