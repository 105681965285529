import React, { useEffect, useState } from "react";
import classes from "./tdsPayables.module.css";
// import classes from "./tdsPaybles.module.css";
import axios from "../../../../axios-order";
import { Button, Grid, Drawer } from "@material-ui/core";
import AddTdsForm from "./add";
import EditTdsForm from "./edit";
import ReversalAddForm from "./reversalAdd";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import RightDrawer from "../../../../components/ui/RightDrawer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

export default function Provision(props) {
  const [provisionMasterData, setProvisionMasterData] = useState([]);
  const [provisionMasterTblSerachData, setProvisionMasterTblSearchData] =
    useState([]);
  const [addFormDrawerOpen, setAddFormDrawerOpen] = useState(false);
  const [editFormDrawerOpen, setEditFormDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const [createProvisionData, setCreateProvisionData] = useState([]);
  const [createProvisionTbSerachData, setCreateProvisonTbSearchData] = useState(
    []
  );
  const [reversalAddFormDrawerOpen, setReversalAddFormDrawerOpen] =
    useState(false);
  const [reversalTbData, setReversalTbData] = useState([]);
  const [reversalTbsearchData, setReversalTbSearchData] = useState([]);

  const [loaderForTableSummary, setLoaderForTableSummary] = useState(false);
  const [loaderForTableCreateProvision, setLoaderForTableCreateProvision] =
    useState(false);
  const [loaderForTableReversal, setLoaderForTableReversal] = useState(false);

  // provision master(summary) table data
  const getProvisionMasterData = () => {
    setLoaderForTableSummary(true);
    axios
      .get(`/tds/provision/get/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        setProvisionMasterData(res.data.record);
        setProvisionMasterTblSearchData(res.data.record);
        setLoaderForTableSummary(false);
      })
      .catch((err) => {
        setLoaderForTableSummary(false);
      });
  };

  // create provision get api call
  const getCreateProvision = () => {
    setLoaderForTableCreateProvision(true);
    axios
      .get(
        `/tds/provision_vendor/get/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTableCreateProvision(false);
        setCreateProvisionData(res.data.record);
        setCreateProvisonTbSearchData(res.data.record);
      })
      .catch((err) => {
        setLoaderForTableCreateProvision(false);
      });
  };

  // reversal table data get api call
  const reversalTbGetApiCall = () => {
    setLoaderForTableReversal(true);
    axios
      .get(
        `/tds/provision_reversal/get/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTableReversal(false);
        setReversalTbData(res.data.record);
        setReversalTbSearchData(res.data.record);
      })
      .catch((err) => {
        setLoaderForTableReversal(false);
      });
  };

  useEffect(() => {
    getProvisionMasterData();
    getCreateProvision();

    reversalTbGetApiCall();
  }, []);

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // TDS Provision Summary table search
  const serachTdsSummary = (e) => {
    const search = e.target.value;
    const searchJson = provisionMasterTblSerachData.filter((v) => {
      return (
        String(v.pan).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        String(v.vendor_legal_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0
      );
    });
    setProvisionMasterData(searchJson);
  };

  // reversal table search
  const reversalTbSearch = (e) => {
    const search = e.target.value;
    const searchJson = reversalTbsearchData.filter((v) => {
      return (
        String(v.pan).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        String(v.vendor_legal_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0
      );
    });
    setReversalTbData(searchJson);
  };

  // create provison table serach
  const createProvisonTbSearch = (e) => {
    const search = e.target.value;
    const searchJson = createProvisionTbSerachData.filter((v) => {
      return (
        String(v.pan).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        String(v.vendor_legal_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0
      );
    });
    setCreateProvisionData(searchJson);
  };

  // indian rupeess system
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  // ......................................TDS Provision Summary table..................................
  const summaryTableBodyData = () => {
    if (loaderForTableSummary) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={11}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (provisionMasterData && provisionMasterData.length > 0) {
      return provisionMasterData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.pan}</td>
            <td>
              {item.provision_create_date &&
                indianFormatDate(item.provision_create_date)}
            </td>
            <td>{numberFormat(item.provision_amount)}</td>
            <td>{numberFormat(item.provision_tds)} </td>
            <td>{item.booking_date && indianFormatDate(item.booking_date)} </td>
            <td>{item.bill_number} </td>
            <td>{numberFormat(item.reversel_provision_amount)} </td>
            <td>{numberFormat(item.reversel_provision_tds)} </td>
            {item.balance_provision_amount < 0 ? (
              <td style={{ color: "red" }}>
                {numberFormat(item.balance_provision_amount)}{" "}
              </td>
            ) : (
              <td>{numberFormat(item.balance_provision_amount)} </td>
            )}
            {item.balance_provision_tds < 0 ? (
              <td style={{ color: "red" }}>
                {numberFormat(item.balance_provision_tds)}{" "}
              </td>
            ) : (
              <td>{numberFormat(item.balance_provision_tds)} </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={11}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // ........................................create provision table all event...........................................

  const createProvisionTableBodyData = () => {
    if (loaderForTableCreateProvision) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={8}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (createProvisionData.length > 0) {
      return createProvisionData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.pan}</td>
            <td>
              {item.provision_create_date
                ? indianFormatDate(item.provision_create_date)
                : ""}
            </td>
            <td>{numberFormat(item.amount)}</td>
            <td>{item.tds_section}</td>
            <td>{item.tds_percentage}%</td>
            <td>{numberFormat(item.tds_payable)}</td>
            <td>
              {/* <span>
                <EditOutlinedIcon
                  className="action-icon-button editIcon"
                  style={{ cursor: "pointer" }}
                  onClick={() => editFormOpenDrawerHandler(item)}
                />
              </span>{" "}
              |{" "} */}
              <span>
                <DeleteForeverOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteTableRow(item.id)}
                  className="action-icon-button deleteIcon"
                />
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={8}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };
  // .......close add form drawer.....
  const addFormCloseDrawerHandler = () => {
    setAddFormDrawerOpen(false);
  };
  // ........open edit form drawer.....
  const editFormOpenDrawerHandler = (item) => {
    setEditFormDrawerOpen(true);
    setEditData(item);
  };
  // .......close edit form drawer.....
  const editFormCloseDrawerHandler = () => {
    setEditFormDrawerOpen(false);
  };

  // ........delete table row.........
  const deleteTableRow = (id) => {
    let text = "Are you sure! you want to delete this record.";
    if (window.confirm(text) === true) {
      axios
        .delete(`/tds/provision_vendor/delete/${id}`)
        .then((res) => {
          getProvisionMasterData();
          getCreateProvision();
          reversalTbGetApiCall();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  // close reversal add form drawer
  const reversalAddFormCloseDrawerHandler = () => {
    setReversalAddFormDrawerOpen(false);
  };

  const deleteReversalTableRow = (id) => {
    let text = "Are you sure! you want to delete this record.";
    if (window.confirm(text) === true) {
      axios
        .delete(`/tds/provision/delete/${id}`)
        .then((res) => {
          getProvisionMasterData();
          reversalTbGetApiCall();
          getCreateProvision();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  const reversalProvisionTableBodyData = () => {
    if (loaderForTableReversal) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={9}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (reversalTbData && reversalTbData.length > 0) {
      return reversalTbData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.pan}</td>
            <td>{item.bill_number} </td>
            <td>{item.booking_date && indianFormatDate(item.booking_date)}</td>
            <td>{numberFormat(item.reversel_provision_amount)}</td>
            <td>{item.tds_section}</td>
            <td>{item.tds_percentage}</td>
            <td>{numberFormat(item.reversel_provision_tds)}</td>
            <td>
              <DeleteForeverOutlinedIcon
                style={{ cursor: "pointer" }}
                onClick={() => deleteReversalTableRow(item.id)}
                className="action-icon-button deleteIcon"
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={9}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };
  // ...........................................reversal provision table event end .............................................
  //excel download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcelFinalData = {
    business_entity_id: localStorage.getItem("business_entity_id"),
    summary: provisionMasterData,
    create_provision: createProvisionData,
    reversal_provision: reversalTbData,
  };

  const exportToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `tds_provision_summary.xlsx`;

    axios
      .post("/tds/provision/summary/download", {
        data: exportToExcelFinalData,
      })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* ...........tds summary table........ */}
      <BoxWrapper
        boxTitle="TDS Provision Summary"
        // export to excel
        exportToExcelEnable={true}
        exportToExcelFn={exportToExcel}
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={serachTdsSummary}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>Vendor Name</th>
                <th>PAN</th>
                <th>Provision Created date*</th>
                <th>Provision Amount</th>
                <th>Provision TDS</th>
                <th>Booking Date (Reversal Date)</th>
                <th>Bill No</th>
                <th>Reversal of Provision Amount</th>
                <th>Reversal of Provision TDS</th>
                <th>Balance Provision Amount</th>
                <th>Balance Provision TDS</th>
              </tr>
              {summaryTableBodyData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* ...........create provision table........ */}

      <BoxWrapper
        boxTitle="Provision"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={createProvisonTbSearch}
        // add button
        enableAddButton={true}
        addButtonLabel="Create"
        addButtonHandlerFn={() => setAddFormDrawerOpen(true)}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th style={{ width: "200px" }}>Vendor Name</th>
                <th>PAN</th>
                <th>Provision Created date</th>
                <th>Amount</th>
                <th>TDS Section</th>
                <th>TDS %</th>
                <th>TDS Payable</th>
                <th>Action</th>
              </tr>
              {createProvisionTableBodyData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* .........provision second table ....... */}
      <BoxWrapper
        boxTitle="Reversal of Provision"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={reversalTbSearch}
        // add button
        enableAddButton={true}
        addButtonLabel="Date of Reversal"
        addButtonHandlerFn={() => setReversalAddFormDrawerOpen(true)}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>Vendor Name</th>
                <th>PAN</th>
                <th>Bill No</th>
                <th>Booking Date (Reversal Date)</th>
                <th>Taxable + Non Taxable Amount (excluding GST/CESS)</th>
                <th>TDS Section</th>
                <th>TDS %</th>
                <th>TDS Payable</th>
                <th>Action</th>
              </tr>
              {reversalProvisionTableBodyData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* ........add tds form drawer.......... */}
      <RightDrawer
        drawerName="Create provision"
        open={addFormDrawerOpen}
        onClose={addFormCloseDrawerHandler}
        variant={"persistent"}
      >
        <AddTdsForm
          cancelAddForm={addFormCloseDrawerHandler}
          updateTable={getCreateProvision}
          updateMasterTable={getProvisionMasterData}
          updateReversalOfProvision={reversalTbGetApiCall}
        />
      </RightDrawer>
      {/* ........edit tds form drawer...... */}
      <RightDrawer
        drawerName="Edit provision"
        open={editFormDrawerOpen}
        onClose={editFormCloseDrawerHandler}
      >
        <EditTdsForm
          cancelEditForm={editFormCloseDrawerHandler}
          editFormData={editData}
          updateTable={getCreateProvision}
          updateMasterTable={getProvisionMasterData}
          updateReversalOfProvision={reversalTbGetApiCall}
        />
      </RightDrawer>

      {/* ..........add reversal of provision....... */}
      <RightDrawer
        drawerName="Date of Reversal"
        open={reversalAddFormDrawerOpen}
        onClose={reversalAddFormCloseDrawerHandler}
        variant={"persistent"}
      >
        <ReversalAddForm
          cancelAddForm={reversalAddFormCloseDrawerHandler}
          // createProvisionData={createProvisionData}
          updateTableAfterSubmit={reversalTbGetApiCall}
          updateMasterTable={getProvisionMasterData}
          updateTable={getCreateProvision}
        />
      </RightDrawer>
    </>
  );
}
