import React from "react";
import { Grid } from "@material-ui/core";
import CustomCard from "../../../../components/ui/Cards";

// indian rupeess system---
const indianRupessFormat = (value) => {
  if (value) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 15,
    }).format(value?.toFixed(2));
  } else {
    return `₹0`;
  }
};
const Cards = (props) => {
  const { cardData } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#3b3b6b"
          cardTitle="Total Payables"
          cardBody={cardData && indianRupessFormat(cardData["Total Payable"])}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#00818A"
          cardTitle="Not Yet Due"
          cardBody={cardData && indianRupessFormat(cardData["Not Yet Due"])}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#065471"
          cardTitle="Total Overdue"
          cardBody={
            cardData && indianRupessFormat(cardData["Total Overdue Value"])
          }
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#1F4068"
          cardTitle="Excess Paid"
          cardBody={
            cardData && indianRupessFormat(cardData["Advance / Excess Payable"])
          }
        />
      </Grid>
      {/* 2nd row */}
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#519872"
          cardTitle="DPO"
          cardBody={cardData && cardData.DPO}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#4B5D67"
          cardTitle="#Bills Not Yet Due"
          cardBody={cardData && cardData["# Not Yet Due"]}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#322F3D"
          cardTitle="#Bills Overdue"
          cardBody={cardData && cardData["No of bills overdue"]}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#d04646"
          cardTitle="#Excess Paid"
          cardBody={cardData && cardData["Invoices Advance / Excess"]}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#011f42"
          cardTitle="Advance Amount"
          cardBody={cardData && indianRupessFormat(cardData["Advance Amount"])}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#2a0d5e"
          cardTitle="Advance Count"
          cardBody={cardData && cardData["Advance Count"]}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#1d5738"
          cardTitle="Dispute Amount"
          cardBody={cardData && indianRupessFormat(cardData["Dispute Amount"])}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#0e3442"
          cardTitle="Dispute Count"
          cardBody={cardData && cardData["Dispute Count"]}
        />
      </Grid>

    </Grid>
  );
};
export default Cards;
