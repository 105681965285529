import React from "react";
import "../TDSRecon.css";

export default function RevenueReport(props) {
  const { revenueData, onlyOneYear, nonTaxableValue } = props;
  // const revenueData = {
  //     "get_invoice_with_quater": [
  //         {
  //             "tds_quarter": "2",
  //             "tds_amount": 5,
  //             "taxable_value": 61000,
  //             "non_taxable_value": 0,
  //             "amount_paid": 11602861,
  //             "tax_deducted": 844717.3,
  //             "amount": -11541861,
  //             "tds": -844712.3
  //         },
  //         {
  //             "tds_quarter": "3",
  //             "tds_amount": 130,
  //             "taxable_value": 42000,
  //             "non_taxable_value": 0,
  //             "amount_paid": 21354823,
  //             "tax_deducted": 1773800.5,
  //             "amount": -21312823,
  //             "tds": -1773670.5
  //         },
  //         {
  //             "tds_quarter": "4",
  //             "tds_amount": 5,
  //             "taxable_value": 1000,
  //             "non_taxable_value": 0,
  //             "amount_paid": 22369855,
  //             "tax_deducted": 1557781.2,
  //             "amount": -22368855,
  //             "tds": -1557776.2
  //         }
  //     ],
  //     "get_invoice_without_quater": [
  //         {
  //             "customer_name": "ABC Pvt Ltd",
  //             "cust_gstin": "29ABACA5000B1ZG",
  //             "tds_amount": 140,
  //             "taxable_value": 1000,
  //             "non_taxable_value": 0,
  //             "customer_tan": "BLRB09190G",
  //             "amount_paid": 1186320,
  //             "tax_deducted": 118632,
  //             "amount": -1185320,
  //             "tds": -118492
  //         }
  //     ]
  // }

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <>
      {/* table 1 */}
      <div className="responsive-table">
        <table className="revenue-report-tab-customer-data-table-css">
          <thead>
            <tr>
              <th colSpan={4} style={{ background: "green" }}>
                Customer Data
              </th>
              <th
                colSpan={nonTaxableValue === "Yes" ? 3 : 2}
                style={{ background: "red" }}
              >
                As Per books
              </th>
              <th colSpan={2} style={{ background: "orange" }}>
                As Per 26As
              </th>
              <th colSpan={2} style={{ background: "blue" }}>
                Difference
              </th>
            </tr>
            <tr>
              <th>Financial Year</th>
              <th>CUSTOMER GSTIN</th>
              <th>CUSTOMER NAME</th>
              <th>CUSTOMER /DEDUCTED TAN</th>
              <th>Taxable Value</th>
              {nonTaxableValue === "Yes" && <th>Non-Taxable Value</th>}
              <th>TDS Value - Payment Advice</th>
              <th>Total Amount Paid/Credited</th>
              <th>TDS Deducted</th>
              <th>Amount</th>
              <th>TDS</th>
            </tr>
          </thead>
          <tbody>
            {revenueData?.map((value, i) => {
              return value?.get_invoice_without_quater?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{value.financial_year}</td>
                    <td>{item.cust_gstin}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.customer_tan}</td>

                    <td>{indianRupeesFormat(item.taxable_value)}</td>
                    {nonTaxableValue === "Yes" && (
                      <td>{indianRupeesFormat(item.non_taxable_value)}</td>
                    )}

                    <td>{indianRupeesFormat(item.tds_amount)}</td>

                    <td>{indianRupeesFormat(item.amount_paid)}</td>
                    <td>{indianRupeesFormat(item.tax_deducted)}</td>

                    <td>{indianRupeesFormat(item.amount)}</td>
                    <td>{indianRupeesFormat(item.tds)}</td>
                  </tr>
                );
              });
            })}
            {/* { (revenueData[0].length === 0 || revenueData[0] === null  ) && (
                            <tr>
                                <td style={{color:"red" , textAlign:"center"}} colSpan="5">No Data Found</td>
                            </tr>
                        )} */}
          </tbody>
        </table>
      </div>

      {/* tablw 2 */}
      {/*
            {onlyOneYear === "Yes" &&
                <div className="tableResponisve" style={{ marginTop: "30px" }}>
                    <table className="tableCss">
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Taxable Value</th>
                                <th>Non-Taxable Value</th>
                                <th>TDS Value - Payment Advice</th>
                                <th>Total Amount Paid/Credited</th>
                                <th>TDS Deducted</th>
                                <th>Amount</th>
                                <th>TDS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {revenueData.map((value, i) => {
                                return (
                                    value.get_invoice_with_quater.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>Q-{item.tds_quarter}</td>
                                                <td>{indianRupeesFormat(item.taxable_value)}</td>
                                                <td>{indianRupeesFormat(item.non_taxable_value)}</td>
                                                <td>{indianRupeesFormat(item.tds_amount)}</td>
                                                <td>{indianRupeesFormat(item.amount_paid)}</td>
                                                <td>{indianRupeesFormat(item.tax_deducted)}</td>
                                                <td>{indianRupeesFormat(item.amount)}</td>
                                                <td>{indianRupeesFormat(item.tds)}</td>
                                            </tr>
                                        )
                                    })
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }
            */}
    </>
  );
}
