import React, { Component, Fragment } from "react";
import classes from "./Dashboard.module.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "../../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SelectField from "../../../../components/ui/selectInput";
import "../../../../asserts/css/Onboarding.css";
import CircularProgress from "@material-ui/core/CircularProgress";

// fiscal year date ----->
const getCurrentFinancialYear = (i) => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 >= 4 && today.getMonth() + 1 <= 12) {
    fiscalyear = today.getFullYear() - i + "-" + (today.getFullYear() - i + 1);
  } else if (today.getMonth() + 1 < 4) {
    fiscalyear = today.getFullYear() - i - 1 + "-" + (today.getFullYear() - i);
  }

  return fiscalyear;
};

const getYears = (value) => {
  let arr = [];
  let i = 1;
  let date = "";
  for (i = 0; i < value; i++) {
    date = getCurrentFinancialYear(i);
    arr.push({ id: date, fiscal_year: date });
  }

  return arr;
};
// ---End---

const indianFormat = (date) => {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: null,
      allAgency: [],
      SubcriptionData: [],
      collectionAgency: [],
      collection_fee: [],
      collectionCharges: [],
      BuyerBankdata: [
        {
          id: "1",
          buyer: "abc Ltd1",
          buyerGstin: "12ABCDE1111A19Z2",
          buyerPAn: "ABCDE1111E",
          buyerTan: "ABCD1111E",
        },
      ],
      expandedRows: [],
      Buyerdata: {},
      loading: false,
      fiscal_year: getCurrentFinancialYear(0),
      from_date: getCurrentFinancialYear(0).split("-")[0] + "-04-01",
      to_date: getCurrentFinancialYear(0).split("-")[1] + "-03-31",
      ErrorMessage: false,
      loaderForTable: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loaderForTable: true });
    let promise = new Promise((resolve, reject) => {
      axios
        .get(
          `ic/allCollectionAgencies/${this.state.from_date}/${
            this.state.to_date
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (
            typeof res.data === "undefined" ||
            !Object.keys(res.data).length
          ) {
            this.setState({ ErrorMessage: true });
          } else {
            this.setState({
              allAgency: res.data.data,
              sellerName: res.data.seller_name,
              agency_name: res.data.data[0].agency_name,
            });
            resolve(res.data.data[0].agency_name);
          }
        });
    });

    // collection agency api [for one agency]
    Promise.all([promise]).then((agencyName) => {
      axios
        .get(
          `ic/collectionAgency/${this.state.from_date}/${this.state.to_date}/${
            agencyName[0]
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          let upperrow = [];
          res.data.data.map((items, i) => {
            let obj = {
              SL: `${i + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Subscription Fees",
                "GST Input Credit",
                "To TDS on Professional Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin", "CA", "", ""],
              Abcd: [
                `Agency Name: ${items.agency_name}`,
                "Subscription Period ",
                `From:${indianFormat(items.from)} To:${indianFormat(items.to)}`,
                `Subscription Invoice No:${items.subscription_invoice_no}`,
              ],
            };
            let row = [];
            const {
              subscription_fees,
              gst_input_credit,
              to_tds_on_professional_fees,
              to_vendor_account,
            } = items;
            row.push(subscription_fees);
            row.push(gst_input_credit);
            row.push(to_tds_on_professional_fees);
            row.push(to_vendor_account);
            obj.Amount = row;
            upperrow.push(obj);
          });
          this.setState({
            SubcriptionData: upperrow,
            collectionAgency: res.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // collection data api [for one agency]
      axios
        .get(
          `ic/collectionCharges/${this.state.from_date}/${this.state.to_date}/${
            agencyName[0]
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ loaderForTable: false });
          let mainArray = [];
          res.data.data.map((items, index) => {
            let obj = {
              SL: `${1}.${index + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Collection Charges",
                "GST Input Credit",
                "To TDS on Collection Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin2", "CA2", "", ""],
              Abcd: [
                `Collection fee invoice no:${items.collection_fees_invoice_number}`,
                `Date:${indianFormat(items.dated)}`,
                `Buyer name:${items.buyer_name}`,
                `Buyer GSTIN:${items.buyer_gstin}`,
              ],
            };
            let amountData = [];
            const {
              collection_charges,
              gst_input_credit,
              to_tds_on_collection_fees,
              to_vendor_account,
            } = items;
            amountData.push(collection_charges);
            amountData.push(gst_input_credit);
            amountData.push(to_tds_on_collection_fees);
            amountData.push(to_vendor_account);
            obj.Amount = amountData;
            mainArray.push(obj);
          });
          this.setState({
            collection_fee: mainArray,
            collectionCharges: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({ loaderForTable: false });
        });

      // buyer details api
      axios
        .get(
          `ic/collectionInvoices/${this.state.from_date}/${
            this.state.to_date
          }/${agencyName[0]}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ Buyerdata: res.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  // pdf downloader
  PDFConverter = () => {
    this.setState({ loading: true });
    const printArea = document.getElementById("jsx-template");
    html2canvas(printArea).then((canvas) => {
      const dataURL = canvas.toDataURL();
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(dataURL, "JPEG", 5, 5, width - 10, height - 10);
      pdf.save("Collection_Entries.pdf");
    });
    this.setState({ loading: false });
  };

  // collection details table header
  subcription_feeTableHeader() {
    const tableHeader = [
      "Sl No",
      " ",
      "entry",
      "Dr/Cr",
      "Amount",
      "Ledger A/c Group",
      "Narration",
    ];
    // let header = Object.keys(tableHeader)
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // table bank details
  renderTableHeaderBuyerBanketails() {
    const tableHeader = [
      "buyer name",
      " buyer gstin",
      "buyer pan",
      "buyer tan",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableHeader() {
    const tableHeader = ["Sl No", " ", "entry", "Dr/Cr", "Amount", "Narration"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // fiscal yera filter---
  handleFiscalYear = (e) => {
    let value = e.target.value;
    this.setState({
      fiscal_year: value,
      from_date: value.split("-")[0] + "-04-01",
      to_date: value.split("-")[1] + "-03-31",
    });

    let promise = new Promise((resolve, reject) => {
      axios
        .get(
          `ic/allCollectionAgencies/${value.split("-")[0] + "-04-01"}/${
            value.split("-")[1] + "-03-31"
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (
            typeof res.data === "undefined" ||
            !Object.keys(res.data).length
          ) {
          } else {
            this.setState({
              allAgency: res.data.data,
              sellerName: res.data.seller_name,
              agency_name: res.data.data[0].agency_name,
            });
            resolve(res.data.data[0].agency_name);
          }
        });
    });

    // collection agency api [for one agency]
    Promise.all([promise]).then((agencyName) => {
      axios
        .get(
          `ic/collectionAgency/${value.split("-")[0] + "-04-01"}/${
            value.split("-")[1] + "-03-31"
          }/${agencyName[0]}/${localStorage.getItem("business_entity_id")}}`
        )
        .then((res) => {
          let upperrow = [];
          res.data.data.map((items, i) => {
            let obj = {
              SL: `${i + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Subscription Fees",
                "GST Input Credit",
                "To TDS on Professional Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin", "CA", "", ""],
              Abcd: [
                `Agency Name: ${items.agency_name}`,
                "Subscription Period ",
                `From:${indianFormat(items.from)} To:${indianFormat(items.to)}`,
                `Subscription Invoice No:${items.subscription_invoice_no}`,
              ],
            };
            let row = [];
            const {
              subscription_fees,
              gst_input_credit,
              to_tds_on_professional_fees,
              to_vendor_account,
            } = items;
            row.push(subscription_fees);
            row.push(gst_input_credit);
            row.push(to_tds_on_professional_fees);
            row.push(to_vendor_account);
            obj.Amount = row;
            upperrow.push(obj);
          });
          this.setState({
            SubcriptionData: upperrow,
            collectionAgency: res.data.data,
          });
        })
        .catch((error) => {});

      // collection data api [for one agency]
      axios
        .get(
          `ic/collectionCharges/${value.split("-")[0] + "-04-01"}/${
            value.split("-")[1] + "-03-31"
          }/${agencyName[0]}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          let mainArray = [];
          res.data.data.map((items, index) => {
            let obj = {
              SL: `${1}.${index + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Collection Charges",
                "GST Input Credit",
                "To TDS on Collection Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin2", "CA2", "", ""],
              Abcd: [
                `Collection fee invoice no:${items.collection_fees_invoice_number}`,
                `Date:${indianFormat(items.dated)}`,
                `Buyer name:${items.buyer_name}`,
                `Buyer GSTIN:${items.buyer_gstin}`,
              ],
            };
            let amountData = [];
            const {
              collection_charges,
              gst_input_credit,
              to_tds_on_collection_fees,
              to_vendor_account,
            } = items;
            amountData.push(collection_charges);
            amountData.push(gst_input_credit);
            amountData.push(to_tds_on_collection_fees);
            amountData.push(to_vendor_account);
            obj.Amount = amountData;
            mainArray.push(obj);
          });
          this.setState({
            collection_fee: mainArray,
            collectionCharges: res.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // buyer details api
      axios
        .get(
          `ic/collectionInvoices/${value.split("-")[0] + "-04-01"}/${
            value.split("-")[1] + "-03-31"
          }/${agencyName[0]}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ Buyerdata: res.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  // agency name filter----
  handleAgencyName = (event) => {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    this.setState({
      [name]: String(value),
      SubcriptionData: [],
    });

    if (value === "All Payment Advices") {
      // buyer details api
      axios
        .get(
          `ic/collectionInvoices/${this.state.from_date}/${
            this.state.to_date
          }/${value}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ Buyerdata: res.data.data });
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({
        collectionAgency: [],
        collectionCharges: [],
      });
    } else {
      //collection agency api
      axios
        .get(
          `ic/collectionAgency/${this.state.from_date}/${
            this.state.to_date
          }/${value}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          let upperrow = [];
          res.data.data.map((items, i) => {
            let obj = {
              SL: `${i + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Subscription Fees",
                "GST Input Credit",
                "To TDS on Professional Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin", "CA", "", ""],
              Abcd: [
                `Agency Name: ${items.agency_name}`,
                "Subscription Period ",
                `From:${indianFormat(items.from)} To:${indianFormat(items.to)}`,
                `Subscription Invoice No:${items.subscription_invoice_no}`,
              ],
            };
            let row = [];
            const {
              subscription_fees,
              gst_input_credit,
              to_tds_on_professional_fees,
              to_vendor_account,
            } = items;
            row.push(subscription_fees);
            row.push(gst_input_credit);
            row.push(to_tds_on_professional_fees);
            row.push(to_vendor_account);
            obj.Amount = row;
            upperrow.push(obj);
          });
          this.setState({
            SubcriptionData: upperrow,
            collectionAgency: res.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // collection data api [for one agency]
      axios
        .get(
          `ic/collectionCharges/${this.state.from_date}/${
            this.state.to_date
          }/${value}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          let mainArray = [];
          res.data.data.map((items, index) => {
            let obj = {
              SL: `${1}.${index + 1}`,
              sub: ["i", "ii", "iii", "iv"],
              entry: [
                "Collection Charges",
                "GST Input Credit",
                "To TDS on Collection Fees",
                "To Vendor A/C",
              ],
              debit: ["Dr", "Dr", "Cr", "Cr"],
              Amount: [],
              group: ["Admin2", "CA2", "", ""],
              Abcd: [
                `Collection fee invoice no:${items.collection_fees_invoice_number}`,
                `Date:${indianFormat(items.dated)}`,
                `Buyer name:${items.buyer_name}`,
                `Buyer GSTIN:${items.buyer_gstin}`,
              ],
            };
            let amountData = [];
            const {
              collection_charges,
              gst_input_credit,
              to_tds_on_collection_fees,
              to_vendor_account,
            } = items;
            amountData.push(collection_charges);
            amountData.push(gst_input_credit);
            amountData.push(to_tds_on_collection_fees);
            amountData.push(to_vendor_account);
            obj.Amount = amountData;
            mainArray.push(obj);
          });
          this.setState({
            collection_fee: mainArray,
            collectionCharges: res.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // buyer details api
      axios
        .get(
          `ic/collectionInvoices/${this.state.from_date}/${
            this.state.to_date
          }/${value}/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          this.setState({ Buyerdata: res.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `ic_Dashboard.xlsx`;
    axios
      .post(`/ic/download`, {
        data: {
          collection_agencies: { data: this.state.collectionAgency },
          collection_charges: { data: this.state.collectionCharges },
          payment_advice: { data: this.state.Buyerdata },
        },
        customer_name: this.state.sellerName && this.state.sellerName,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          {/* filter div */}
          <div>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.onSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <div>
                    <SelectField
                      SelectLabel="Fiscal Year"
                      validator={["required"]}
                      requiredField=" "
                      errorMsg={["This field is required"]}
                      choice_id="id"
                      choice_name="fiscal_year"
                      choices={getYears(10)}
                      name="fiscal_year"
                      value={
                        this.state.fiscal_year ?? getCurrentFinancialYear(0)
                      }
                      textOnchangeFunction={this.handleFiscalYear}
                    />
                  </div>
                </ValidatorForm>
              </Grid>

              <Grid item xs={8}>
                <div style={{ textAlign: "center" }}>
                  <Button onClick={this.downloadToExcel}>
                    Export to Excel
                  </Button>
                  <p style={{ margin: "0px" }}>
                    Reporting period:{indianFormat(this.state.from_date)} To{" "}
                    {indianFormat(this.state.to_date)}
                  </p>
                </div>
              </Grid>

              <Grid item xs={2}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.onSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <div>
                    <SelectField
                      SelectLabel="Agency Name"
                      validator={["required"]}
                      requiredField=" "
                      errorMsg={["This field is required"]}
                      choice_id="agency_name"
                      choice_name="agency_name"
                      choices={this.state.allAgency}
                      name="agency_name"
                      value={this.state.agency_name ?? ""}
                      textOnchangeFunction={this.handleAgencyName}
                    />
                  </div>
                </ValidatorForm>
              </Grid>
            </Grid>
          </div>

          <div id="jsx-template">
            {/* dashboard header */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  className={classes.Paper}
                  style={{
                    background: "#c4d0d9",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {this.state.sellerName} <br></br>Scheme of Entries for Buyer
                  Collections{" "}
                </Paper>
              </Grid>
            </Grid>
            {this.state.loaderForTable ? (
              <Paper style={{ marginTop: "10px" }}>
                <p style={{ textAlign: "center", padding: "10px" }}>
                  <CircularProgress size={30} />
                </p>
              </Paper>
            ) : this.state.ErrorMessage ? (
              <Paper style={{ marginTop: "10px" }}>
                <p
                  style={{ textAlign: "center", color: "red", padding: "10px" }}
                >
                  'No Data Found!'
                </p>
              </Paper>
            ) : (
              ""
            )}
            {/*---TABLE---*/}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.tableWapper}>
                  <table
                    id="BuyerCollections"
                    className={classes.collectionDetalisTable}
                  >
                    <tbody>
                      {this.state.SubcriptionData &&
                      this.state.SubcriptionData.length ? (
                        <tr>{this.subcription_feeTableHeader()}</tr>
                      ) : (
                        ""
                      )}
                      {this.state.SubcriptionData &&
                        this.state.SubcriptionData.map((item, index) => (
                          <Fragment>
                            <tr style={{ borderBottom: "1px solid #ddd" }}>
                              <td rowspan={item.sub.length + 1}>{item.SL}</td>
                            </tr>
                            {item.sub.map((subnum, i) => {
                              return item.sub.length - 1 === i ? (
                                <tr
                                  style={{ borderBottom: "1px solid #ddd" }}
                                  className={classes.subtableRow}
                                >
                                  <td>{subnum}</td>
                                  <td>{item.entry[i]}</td>
                                  <td>{item.debit[i]}</td>
                                  <td>{numberFormat(item.Amount[i])}</td>
                                  <td>{item.group[i]}</td>
                                  <td>{item.Abcd[i]}</td>
                                </tr>
                              ) : (
                                <tr className={classes.subtableRow}>
                                  <td>{subnum}</td>
                                  <td>{item.entry[i]}</td>
                                  <td>{item.debit[i]}</td>
                                  <td>{numberFormat(item.Amount[i])}</td>
                                  <td>{item.group[i]}</td>
                                  <td>{item.Abcd[i]}</td>
                                </tr>
                              );
                            })}
                            {this.state.collection_fee &&
                              this.state.collection_fee.map((item, index) => (
                                <Fragment>
                                  <tr
                                    style={{ borderBottom: "1px solid #ddd" }}
                                  >
                                    <td rowspan={item.sub.length + 1}>
                                      {item.SL}
                                    </td>
                                  </tr>
                                  {item.sub.map((subnum, i) => {
                                    return item.sub.length - 1 === i ? (
                                      <tr
                                        style={{
                                          borderBottom: "1px solid #ddd",
                                        }}
                                        className={classes.subtableRow}
                                      >
                                        <td>{subnum}</td>
                                        <td>{item.entry[i]}</td>
                                        <td>{item.debit[i]}</td>
                                        <td>{numberFormat(item.Amount[i])}</td>
                                        <td>{item.group[i]}</td>
                                        <td>{item.Abcd[i]}</td>
                                      </tr>
                                    ) : (
                                      <tr className={classes.subtableRow}>
                                        <td>{subnum}</td>
                                        <td>{item.entry[i]}</td>
                                        <td>{item.debit[i]}</td>
                                        <td>{numberFormat(item.Amount[i])}</td>
                                        <td>{item.group[i]}</td>
                                        <td>{item.Abcd[i]}</td>
                                      </tr>
                                    );
                                  })}
                                </Fragment>
                              ))}
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>

            {/* sub table */}
            <div>
              {typeof this.state.Buyerdata !== "undefined" &&
                Object.keys(this.state.Buyerdata).map((v, i) => {
                  return (
                    <React.Fragment>
                      <ul
                        style={{
                          background: "#387a6f",
                          color: "#fff",
                          paddingLeft: "20px",
                        }}
                      >
                        <li style={{ display: "inline-block" }}>
                          Buyer Name:
                          {this.state.Buyerdata[v].values.customer_name}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Buyer Gstin:{this.state.Buyerdata[v].values.gstin}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          Buyer PAN:
                          {this.state.Buyerdata[v].values.customer_pan}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          {" "}
                          Buyer TAN:
                          {this.state.Buyerdata[v].values.customer_tan}
                        </li>
                      </ul>
                      {Object.keys(
                        this.state.Buyerdata[v].collection_reference_number
                      ).map((items, index) => {
                        let invoiceNumber =
                          this.state.Buyerdata[v].collection_reference_number[
                            items
                          ].invoice_numbers;
                        var invoiceNumber_length =
                          Object.keys(invoiceNumber).length;
                        var j = 0;
                        var m = 0;

                        return (
                          <table
                            id={this.state.Buyerdata[v].values.gstin}
                            className={classes.BuyerDetailTable}
                          >
                            <tbody>
                              <tr>{this.renderTableHeader()}</tr>
                              {/* {1st [i]} */}
                              <tr>
                                <td
                                  style={{ width: "10%" }}
                                  rowSpan={
                                    3 +
                                    (invoiceNumber_length === 0
                                      ? 0
                                      : invoiceNumber_length - 2) +
                                    ((invoiceNumber_length === 0
                                      ? 0
                                      : invoiceNumber_length - 2) *
                                      4 +
                                      (Object.keys(
                                        this.state.Buyerdata[v]
                                          .collection_reference_number[items]
                                          .invoice_numbers[
                                          "payment_advice_more_than_collected_amount_in_bank"
                                        ]
                                      ).length
                                        ? 1
                                        : 0) *
                                        4 +
                                      (Object.keys(
                                        this.state.Buyerdata[v]
                                          .collection_reference_number[items]
                                          .invoice_numbers[
                                          "payment_advice_not_available"
                                        ]
                                      ).length
                                        ? 1
                                        : 0) *
                                        4)
                                  }
                                >
                                  {index + 1}
                                </td>
                                <td
                                  rowSpan={3}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    width: "10%",
                                  }}
                                >
                                  i
                                </td>
                                <td
                                  rowSpan={3}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    width: "20%",
                                  }}
                                >
                                  Bank A/c
                                </td>
                                <td
                                  rowSpan={3}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    width: "10%",
                                  }}
                                >
                                  Dr
                                </td>
                                <td
                                  rowSpan={3}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    width: "10%",
                                  }}
                                >
                                  {numberFormat(
                                    this.state.Buyerdata[v]
                                      .collection_reference_number[items].values
                                      .total_amount
                                  )}
                                </td>
                                <td style={{ width: "40%" }}>
                                  Collection Ref no:
                                  {
                                    this.state.Buyerdata[v]
                                      .collection_reference_number[items].values
                                      .collection_reference_number
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Bank account number:
                                  {
                                    this.state.Buyerdata[v]
                                      .collection_reference_number[items].values
                                      .bank_account
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td style={{ borderBottom: "1px solid #ddd" }}>
                                  Collection Date:
                                  {indianFormat(
                                    this.state.Buyerdata[v]
                                      .collection_reference_number[items].values
                                      .collection_date
                                  )}
                                </td>
                              </tr>
                              {/* 2nd [ii] */}
                              {Object.keys(invoiceNumber).map(
                                (invoice_no, i) => {
                                  if (
                                    j === 0 &&
                                    invoice_no !==
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no !==
                                      "payment_advice_not_available"
                                  ) {
                                    j++;
                                    return (
                                      <tr>
                                        <td
                                          rowSpan={invoiceNumber_length - 2}
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          ii
                                        </td>
                                        <td
                                          rowSpan={invoiceNumber_length - 2}
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          TDS Receivables
                                        </td>
                                        <td
                                          rowSpan={invoiceNumber_length - 2}
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          {numberFormat(
                                            this.state.Buyerdata[v]
                                              .collection_reference_number[
                                              items
                                            ].invoice_numbers[invoice_no]
                                              .tds_recievables
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          invoice no:{invoice_no}
                                        </td>
                                      </tr>
                                    );
                                  } else if (
                                    invoice_no !==
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no !==
                                      "payment_advice_not_available"
                                  ) {
                                    return (
                                      <tr>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          {numberFormat(
                                            this.state.Buyerdata[v]
                                              .collection_reference_number[
                                              items
                                            ].invoice_numbers[invoice_no]
                                              .tds_recievables
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            borderBottom: "1px solid #ddd",
                                          }}
                                        >
                                          invoice no:{invoice_no}
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                              {/* 3rd [iii] */}
                              {Object.keys(invoiceNumber).map(
                                (invoice_no, i) => {
                                  if (
                                    m === 0 &&
                                    invoice_no !==
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no !==
                                      "payment_advice_not_available"
                                  ) {
                                    m++;
                                    return (
                                      <React.Fragment>
                                        <tr style={{ paddingTop: "20px" }}>
                                          <td
                                            rowSpan={
                                              (invoiceNumber_length - 2) * 4 +
                                              (Object.keys(
                                                this.state.Buyerdata[v]
                                                  .collection_reference_number[
                                                  items
                                                ].invoice_numbers[
                                                  "payment_advice_more_than_collected_amount_in_bank"
                                                ]
                                              ).length
                                                ? 1
                                                : 0) *
                                                4 +
                                              (Object.keys(
                                                this.state.Buyerdata[v]
                                                  .collection_reference_number[
                                                  items
                                                ].invoice_numbers[
                                                  "payment_advice_not_available"
                                                ]
                                              ).length
                                                ? 1
                                                : 0) *
                                                4
                                            }
                                          >
                                            iii
                                          </td>
                                          <td
                                            rowSpan={
                                              (invoiceNumber_length - 2) * 4 +
                                              (Object.keys(
                                                this.state.Buyerdata[v]
                                                  .collection_reference_number[
                                                  items
                                                ].invoice_numbers[
                                                  "payment_advice_more_than_collected_amount_in_bank"
                                                ]
                                              ).length
                                                ? 1
                                                : 0) *
                                                4 +
                                              (Object.keys(
                                                this.state.Buyerdata[v]
                                                  .collection_reference_number[
                                                  items
                                                ].invoice_numbers[
                                                  "payment_advice_not_available"
                                                ]
                                              ).length
                                                ? 1
                                                : 0) *
                                                4
                                            }
                                          >
                                            Customer A/c
                                          </td>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Cr
                                          </td>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            {numberFormat(
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .customer_ac
                                            )}
                                          </td>
                                          <td>invoice no:{invoice_no}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Transcation ref No:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .transaction_ref_no
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Date :
                                            {indianFormat(
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .payment_date
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Collection Agency:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .collection_agency
                                            }
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  } else if (
                                    invoice_no !==
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no !==
                                      "payment_advice_not_available"
                                  ) {
                                    return (
                                      <React.Fragment>
                                        <tr style={{ paddingTop: "20px" }}>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Cr
                                          </td>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            {numberFormat(
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .customer_ac
                                            )}
                                          </td>
                                          <td>invoice no:{invoice_no}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Transcation ref No:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .transaction_ref_no
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Date :
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .payment_date
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Collection Agency:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .collection_agency
                                            }
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  } else if (
                                    Object.keys(
                                      this.state.Buyerdata[v]
                                        .collection_reference_number[items]
                                        .invoice_numbers[invoice_no]
                                    ).length &&
                                    invoice_no ===
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no !==
                                      "payment_advice_not_available"
                                  ) {
                                    return (
                                      <React.Fragment>
                                        <tr style={{ paddingTop: "20px" }}>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Cr
                                          </td>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            {numberFormat(
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .customer_ac
                                            )}
                                          </td>
                                          <td>
                                            invoice no:
                                            <span style={{ color: "red" }}>
                                              Payment advice more than collected
                                              amount in bank
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Transcation ref No:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .transaction_ref_no
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Date :
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .payment_date
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Collection Agency:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .collection_agency
                                            }
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  } else if (
                                    invoice_no !==
                                      "payment_advice_more_than_collected_amount_in_bank" &&
                                    invoice_no ===
                                      "payment_advice_not_available" &&
                                    Object.keys(
                                      this.state.Buyerdata[v]
                                        .collection_reference_number[items]
                                        .invoice_numbers[invoice_no]
                                    ).length
                                  ) {
                                    return (
                                      <React.Fragment>
                                        <tr style={{ paddingTop: "20px" }}>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Cr
                                          </td>
                                          <td
                                            rowSpan={4}
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            {numberFormat(
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .customer_ac
                                            )}
                                          </td>
                                          <td>
                                            invoice no:
                                            <span style={{ color: "red" }}>
                                              Payment advice not available
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Transcation ref No:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .transaction_ref_no
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Payment Date :
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .payment_date
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            Collection Agency:
                                            {
                                              this.state.Buyerdata[v]
                                                .collection_reference_number[
                                                items
                                              ].invoice_numbers[invoice_no]
                                                .collection_agency
                                            }
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Dashboard;
