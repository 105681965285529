import React, { useMemo } from "react";
import TextField from "@material-ui/core/TextField";

function ShowDetails(props) {
  const { showData, closeDrawer } = props;
  const [data, setData] = React.useState({});

  useMemo(() => {
    setData(showData);
  }, [showData]);
  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <div>
      {Object.keys(data).length && (
        <>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="ERP Vendor Code"
            margin="normal"
            value={data.erpVendorCode?data.erpVendorCode:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="PAN"
            margin="normal"
            value={data.vendorPan?data.vendorPan:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Booking Date"
            margin="normal"
            value={data.bookingDate?data.bookingDate:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="eInvoice"
            margin="normal"
            value={data.eInvoice?data.eInvoice:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Due Date"
            margin="normal"
            value={data.dueDate?data.dueDate:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="HSN/SAC Code"
            margin="normal"
            value={data.hsnSacCode?data.hsnSacCode:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Taxable Value"
            margin="normal"
            value={data.taxableValue?indianRupeesFormat(data.taxableValue):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Non Taxable Value"
            margin="normal"
            value={data.nonTaxableValue?indianRupeesFormat(data.nonTaxableValue):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="SGST"
            margin="normal"
            value={data.sgst?indianRupeesFormat(data.sgst):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="CGST"
            margin="normal"
            value={data.cgst?indianRupeesFormat(data.cgst):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="IGST"
            margin="normal"
            value={data.igst?indianRupeesFormat(data.igst):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Cess"
            margin="normal"
            value={data.cess?indianRupeesFormat(data.cess):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Tax Amount"
            margin="normal"
            value={data.taxAmount?indianRupeesFormat(data.taxAmount):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TCS Section"
            margin="normal"
            value={data.tcsSection?data.tcsSection:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TCS Payable"
            margin="normal"
            value={data.tcsPayable?indianRupeesFormat(data.tcsPayable):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Total Amount"
            margin="normal"
            value={data.totalAmount?indianRupeesFormat(data.totalAmount):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TDS Section"
            margin="normal"
            value={data.tdsSection?data.tdsSection:''}
          />
          {/* ------------------------------------ */}
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="TDS Payables"
            margin="dense"
            value={data.tdsPayable?indianRupeesFormat(data.tdsPayable):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Expense Type"
            margin="dense"
            value={data.expenseType?data.expenseType:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Type of tax credit"
            margin="dense"
            value={data.taxCreditType?data.taxCreditType:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="ITC Eligibility"
            margin="dense"
            value={data.itcEligibility?data.itcEligibility:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="RCM Applicability"
            margin="dense"
            value={data.rcmApplicability?data.rcmApplicability:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="PO Number"
            margin="dense"
            value={data.poNumber?data.poNumber:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="PO Date"
            margin="normal"
            value={data.poDate?data.poDate:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Dispute"
            margin="dense"
            value={data.dispute?data.dispute:''}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Dispute Value"
            margin="dense"
            value={data.disputeValue?indianRupeesFormat(data.disputeValue):''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Bill of Entry Number"
            margin="dense"
            value={data.boeNumber?data.boeNumber:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Bill of Entry Date"
            margin="normal"
            value={data.boeDate?data.boeDate:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Reference Date"
            margin="normal"
            value={data.referenceDate?data.referenceDate:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Port Code"
            margin="normal"
            value={data.portCode?data.portCode:''}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Comments"
            margin="normal"
            value={data.comments?data.comments:''}
          />

          {/* <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Modify Date"
            margin="normal"
            value={data.modifyDate}
          /> */}
           <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Modify Date"
            margin="normal"
            value={data.modified_date?data.modified_date:''}
          />

          {/* <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Create Date"
            margin="normal"
            value={data.createDate}
          /> */}
           <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Create Date"
            margin="normal"
            value={data.created_date?data.created_date:''}
          />

        </>
      )}
      {/* submit and back button */}
      <div className="mt-20">
        <button className="button-outlined-secondary" onClick={closeDrawer}>
          close
        </button>
      </div>
    </div>
  );
}

export default ShowDetails;
