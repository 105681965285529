import React, { useState, useEffect } from "react";
import { Button, TextField, Grid } from "@mui/material";
// import gststyle from "./Gst2aRepository.module.css";
import axios from "../../../../axios-order";

import moment from "moment";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export default function Gstr3bRepository() {
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  const [businessEntityId, setBusinessEntityId] = useState("");
  // const [fileName, setFileName] = useState("");
  // const [fileDataToUpload, setFileDataToUpload] = useState("");
  const [trigarCircularProgress, setTrigarCircularProgress] = useState(false);

  //...............For Error Message..........................
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
    useState("hidden");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");

  //.........Record From Get Api.................
  const [recordFromApi, setRecordFromApi] = useState([]);
  const [recordFromApiForSearch, setRecordFromApiForSearch] = useState([]);

  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loaderForFileUpload, setLoaderForFileUpload] = useState(false);

  //............File Upload Data Saving in useState................
  const [fileDataToUpload, setFileDataToUpload] = useState('');
  const [GSTJsonfFile, setGSTJsonfFile] = useState("");
  const [fileName, setFileName] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  const [responseMessage, setResponseMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      const fileData = event.target.files[0];
      const fileName = fileData.name;
      setFileName(fileName);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = function () {
        const fileValue = reader.result;
        setFileDataToUpload(fileValue);
      };
    }
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    getApiForGst2aRepository();
  }, []);


  const handleSubmit = () => {
    if (!fileDataToUpload) {
      handleSnackbarOpen('Please select to a pdf file','error')
      return;
    }

    setLoading(true);

    const businessEntityId = localStorage.getItem("business_entity_id");

    const payLoad = {
      business_entity_id: businessEntityId,
      document_name: fileName,
      file: fileDataToUpload,
    };

  
    axios
      .post("/gstr3b/repository", payLoad)
      .then((res) => {
        setLoading(false);
        getApiForGst2aRepository();
        
        if (res.data) {
          handleSnackbarOpen(res?.data?.message,'success')
        }

      })
      .catch((err) => { 
        setLoading(false);
        console.log('error')
        
        handleSnackbarOpen(err?.response?.data?.message,'error')
      });
  };

  const handleOpenAndClose = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setErrorMessage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
  };

  const fileUploadCancelButtonHandler = () => {
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setErrorMessage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
  };

  const downloadPDF = (base64Data, fileName) => {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadFile = (document_id) => {
    const businessEntityId = localStorage.getItem("business_entity_id");
    axios
      .get(
        `gstr3b/download_document/${businessEntityId}/${document_id}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          downloadPDF(res.data.records, 'GSTR-3B repositry.pdf');
        }
      });
  };


  const getApiForGst2aRepository = () => {
    const businessEntityId = localStorage.getItem("business_entity_id");

    axios.get(`gstr3b_repository/${businessEntityId}`)
      .then((res) => {
        if (res.data) {
          // console.log(res.data.records)
          setRecordFromApi(res.data.records)
        }
      }).catch((err) => {

      })
  };

  return (
    <React.Fragment>
      <ToggleSwitch
        onChange={handleOpenAndClose}
        label="Upload GSTR-3B Return "
      />

      {openAndCloseModel ? (
        <>
          <BoxWrapper boxTitle="Upload GSTR-3B Return">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <label className="button-primary" style={{ cursor: "pointer" }}>
                Upload
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  hidden
                />
                {/* <input type="file" onChange={handleFileChange} /> */}
              </label>
              <div>
                <TextField
                  value={fileName}
                  variant="standard"
                  style={{ width: "350px", paddingLeft: "10px" }}
                  placeholder="No Data Have Uploaded"
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div>
                {loading ? (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Uploading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}

                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={fileUploadCancelButtonHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
            <p style={{ color: "red" }}>{errorMessage}</p>
          </BoxWrapper>
        </>
      ) : (
        ""
      )}

      {/*..................Table.......................*/}

      <BoxWrapper
        boxTitle="GSTR-3B Repository"
        // search input
        // enableSearchInput={true}
        // searchInputPlaceholder="Search..."
        // searchInputOnchange={handleSearch}

        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApiForGst2aRepository}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>ARN</th>
                <th>Tax Period </th>
                <th>Financial Year </th>

                <th>Upload Date</th>
                {/* <th>Status</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {recordFromApi.map((v, index) => (
                <tr key={index}>

                  <td>{v.arn}</td>
                  <td>{v.period}</td>
                  <td>{v.financial_year}</td>
                  <td>{v.created_time}</td>
                  <td>
                    <GetAppIcon
                      className="action-icon-button download"
                      onClick={() => downloadFile(v.id)}
                    />
                  </td>
                </tr>
              ))}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={7}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : recordFromApi?.length === 0 ? (
                <tr>
                  <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}

        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}
