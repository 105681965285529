import React from "react";
import classes from "./TdsREconciliationGst2A.module.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

export default function SectionWisereport(props) {
  const { reportData, sectionNameForReportPage } = props;

  const sectionReportData = reportData;
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  const tdsSectionWiseReportTableBody = () => {
    if (sectionReportData) {
      return sectionReportData.details.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item["company/non_company"]}</td>
            <td>{item.name}</td>
            <td>{item.pan}</td>
            <td>{item.bill_no}</td>
            <td>{indianFormatDate(item.booking_date)}</td>

            <td>{indianRupeesFormat(item.net_gross_amount)}</td>
            <td>{indianRupeesFormat(item.net_tds)}</td>
          </tr>
        );
      });
    }
  };
  // tdsReportNonComapnayTableBody
  const tdsReportNonComapnayTableBody = () => {
    if (sectionReportData && sectionReportData["Non-Company"]) {
      return sectionReportData["Non-Company"].details.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item["company/non_company"]}</td>
            <td>{item.name}</td>
            <td>{item.pan}</td>
            <td>{item.bill_no}</td>
            <td>{indianFormatDate(item.booking_date)}</td>
            <td>{indianRupeesFormat(item.gross_amount_as_per_provision)}</td>
            <td>{indianRupeesFormat(item.tds_on_provision)}</td>
          </tr>
        );
      });
    }
  };
  return (
    <div>
      {/* ...........header............... */}
      <div style={{ textAlign: "center" }}>
        <p>Details TDS Liability Summary Report</p>
      </div>
      <BoxWrapper
        boxTitle={`TDS u/s ${
          sectionNameForReportPage ? sectionNameForReportPage : ""
        }`}
      >
        <div className="responsive-table">
          <table className="custome-two-row-header-short-table">
            <tbody>
              <tr>
                <th style={{ background: "#C4D0D9" }} rowSpan={2}>
                  Company/Non-Company
                </th>
                <th style={{ background: "#C4D0D9" }} rowSpan={2}>
                  Name of Party
                </th>
                <th style={{ background: "#C4D0D9" }} rowSpan={2}>
                  Pan No
                </th>
                <th style={{ background: "#C4D0D9" }} rowSpan={2}>
                  Invoice No
                </th>
                <th style={{ background: "#C4D0D9" }} rowSpan={2}>
                  Booking/Recording Date
                </th>
                <th colSpan={2} style={{ background: "#C4D0D9" }}>
                  TDS Working
                </th>
              </tr>
              <tr>
                <th style={{ background: "#C4D0D9" }}>Gross Amount</th>
                <th style={{ background: "#C4D0D9" }}>TDS Payables</th>
              </tr>

              {sectionReportData && (
                <>
                  {tdsSectionWiseReportTableBody()}

                  {sectionReportData && (
                    <>
                      <tr style={{ background: "#3b3b6b ", color: "#fff" }}>
                        <td colSpan={5}>Total</td>
                        <td>
                          {sectionReportData &&
                            indianRupeesFormat(
                              sectionReportData.subtotal_net_gross_amount
                            )}
                        </td>
                        <td>
                          {sectionReportData &&
                            indianRupeesFormat(
                              sectionReportData.subtotal_net_tds
                            )}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}

              {/* {sectionReportData["Non-Company"] && (
                <>
                  {tdsReportNonComapnayTableBody()}
                  {sectionReportData["Non-Company"] &&
                    sectionReportData.company && (
                      <>
                        <tr style={{ background: "#3b3b6b ", color: "#fff" }}>
                          <td colSpan={5}>SubTotal</td>
                          <td>
                            {sectionReportData &&
                              indianRupeesFormat(
                                sectionReportData["Non-Company"]
                                  .subtotal_net_gross_amount
                              )}
                          </td>
                          <td>
                            {sectionReportData &&
                              indianRupeesFormat(
                                sectionReportData["Non-Company"]
                                  .subtotal_net_tds
                              )}
                          </td>
                        </tr>
                      </>
                    )}
                </>
              )} */}

              {/* <tr style={{ background: "#d04646", color: "#fff" }}>
                <td colSpan={5}>Total</td>
                <td>
                  {sectionReportData &&
                    indianRupeesFormat(
                      sectionReportData.total_net_gross_amount
                    )}
                </td>
                <td>
                  {sectionReportData &&
                    indianRupeesFormat(sectionReportData.total_net_tds)}
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </div>
  );
}
