import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CreateSupplierMaster from './createsuppliermaster';
import CreateRecipientMaster from './createrecipientmaster';

import ProductMaster from './productmaster';
import TaxInvoice from './taxinvoice';

const Einvoice = ({ handleClose }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleChange} centered>
        <Tab label="Supplier" />
        <Tab label="Recipient" />
        <Tab label="Product" />
        <Tab label="Tax Invoice" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {selectedTab === 0 && <CreateSupplierMaster handleClose={handleClose} />}
        {selectedTab === 1 && <CreateRecipientMaster handleClose={handleClose} />}
        {selectedTab === 2 && <ProductMaster handleClose={handleClose} />}
        {selectedTab === 3 && <TaxInvoice handleClose={handleClose} />}
      </Box>
    </Box>
  );
};

export default Einvoice;



