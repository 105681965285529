import React, { useState, useEffect } from "react";
import { Button, styled } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import SelectField from "../../../../components/ui/select";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Modal from "react-bootstrap/Modal";
import axios from "../../../../axios-order";
import classes from "./tdsPayables.module.css";
// import classes from "./tdsPaybles.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledAutoComplete = styled(Autocomplete)(() => ({
  "& legend": {
    display: "none",
  },

  "& input": {
    height: 0,
  },
}));

export default function Add(props) {
  const {
    cancelAddForm,
    updateTableAfterSubmit,
    updateMasterTable,
    updateTable,
  } = props;
  // const form = useRef("form");
  const [provisonData, setProvisionData] = useState([]);
  const [provisonDataForSearch, setProvisionDataForsearch] = useState([]);

  const [reversalAddData, setReversalAddData] = useState({});
  const [openProvisionModal, setOpenProvisionModal] = useState(false);
  const [openBillModal, setOpenBillModal] = useState(false);

  const [billData, setBillData] = useState([]);
  const [billDataForSearch, setBillDataForSearch] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loaderForProvision, setLoaderForProvision] = useState(false);

  const sectionDropdownValue = [
    { section: "192A" },
    { section: "193(Securites)" },
    { section: "193(Debentures)" },
    { section: "194" },
    { section: "194A" },
    { section: "194B" },
    { section: "194BB" },
    { section: "194C" },
    { section: "194D" },
    { section: "194DA" },
    { section: "194E" },
    { section: "194EE" },
    { section: "194G" },
    { section: "194H" },
    { section: "194IB" },
    { section: "194IA" },
    { section: "194IC" },
    { section: "194J(2%)" },
    { section: "194J(10%)" },
    { section: "194LA" },
    { section: "194LB" },
    { section: "194LD" },
    { section: "194Q" },
    { section: "206AA" },
    { section: "206AB" },
  ];

  const getProvision = () => {
    setLoaderForProvision(true);
    axios
      .get(
        `/tds/balance_zero_vendor_provision/get/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForProvision(false);
        setProvisionDataForsearch(res.data.record);
        setProvisionData(res.data.record);
      })
      .catch((err) => {
        setLoaderForProvision(false);
      });
  };

  // input change handler for invoice type
  const addInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setReversalAddData({
      ...reversalAddData,
      [name]: value,
    });
  };

  // indian rupeess system
  const indianRupeeFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  // final data for form submit handler
  const finalAddData = {
    org_code: localStorage.getItem("business_entity_id"),
    records: [reversalAddData],
  };

  // form submit post api call
  const addFormsubmitHandler = (e) => {
    toast.configure();
    axios
      .post("/tds/provision", finalAddData)
      .then((res) => {
        cancelAddForm();
        if (res.data.status === true) {
          toast.success(res.data.message, { autoClose: 2000 });
          setInputValue("");
          setReversalAddData({});
          updateTableAfterSubmit();
          updateMasterTable();
          updateTable();
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {});
  };

  // close form drawer button handler
  const cancelAddFormHandler = () => {
    cancelAddForm();
    setReversalAddData({});
  };

  // .............select provision data .....................
  const selectProvisionModalOpen = () => {
    getProvision();
    setOpenProvisionModal(true);
  };

  // select provision details from provision modal using select button from table
  const selectProvisionDetails = (value) => {
    setReversalAddData({
      ...reversalAddData,
      vendor_legal_name: value.vendor_legal_name,
      pan: value.pan,
      provision_create_date: value.provision_create_date,
      provision_tds: value.tds_payable,
      provision_amount: value.amount,
      provision_id: value.id,
      vendor_id: value.vendor_id,
      tds_percentage: value.tds_percentage,
    });
    setOpenProvisionModal(false);
  };

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // provison
  const selectProvisionModalSearch = (e) => {
    let value = e.target.value;
    const searchresult = provisonDataForSearch.filter((item) => {
      return (
        item.vendor_legal_name.toLowerCase().indexOf(value.toLowerCase()) >=
          0 || item.pan.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    });
    setProvisionData(searchresult);
  };
  console.log(provisonData);

  const provisionTableBodyData = () => {
    if (provisonData && provisonData.length > 0) {
      return provisonData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.pan}</td>
            <td>
              {item.provision_create_date &&
                indianFormatDate(item.provision_create_date)}
            </td>
            <td>{item.tds_section}</td>
            <td>{item.amount}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => selectProvisionDetails(item)}
              >
                Select
              </span>
            </td>
          </tr>
        );
      });
    } else if (loaderForProvision && provisonData.length === 0) {
      return (
        <tr>
          <td style={{ textAlign: "center" }} colSpan={6}>
            Loading,Please wait...
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  const selectProvisionModalClose = () => {
    setOpenProvisionModal(false);
  };

  // ...........select bill type............
  const selectBillModalOpenHandler = () => {
    axios
      .get(
        `/get_provison_invoices/${localStorage.getItem("business_entity_id")}/${
          reversalAddData.vendor_id
        }/${reversalAddData.inv_type}`
      )
      .then((res) => {
        setBillData(res.data.records);
        setBillDataForSearch(res.data.records);
        setOpenBillModal(true);
      })
      .catch((err) => {});
  };

  const setBillDetails = (value) => {
    if (value.inv_type === "Bill") {
      setReversalAddData({
        ...reversalAddData,
        booking_date: value.booking_date, //earlier it was bill_date now it's booking date
        bill_number: value.bill_number,
        bill_id: value.bill_id,
        reversal_provision_amount: value.reversal_provision_amount,
        reversal_provision_tds: value.reversel_provision_tds,
        tds_section: value.tds_section,
      });
      setInputValue(value.tds_section);
    } else if (value.inv_type === "Credit Note") {
      setReversalAddData({
        ...reversalAddData,
        booking_date: value.booking_date, //earlier it was bill_date now it's booking date
        bill_number: value.bill_number,
        credit_id: value.credit_id,
        reversal_provision_amount: value.reversal_provision_amount,
        reversal_provision_tds: value.reversel_provision_tds,
        tds_section: value.tds_section,
      });
      setInputValue(value.tds_section);
    } else {
      setReversalAddData({
        ...reversalAddData,
        booking_date: value.booking_date, //earlier it was bill_date now it's booking date
        bill_number: value.bill_number,
        debit_id: value.debit_id,
        reversal_provision_amount: value.reversal_provision_amount,
        reversal_provision_tds: value.reversel_provision_tds,
        tds_section: value.tds_section,
      });
      setInputValue(value.tds_section);
    }

    setOpenBillModal(false);
  };

  const selectBillModalSearch = (e) => {
    let value = e.target.value;
    const searchresult = billDataForSearch.filter((item) => {
      return (
        item.bill_number.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
        item.tds_section.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    });
    setBillData(searchresult);
  };

  const billTableBodyData = () => {
    if (billData.length > 0) {
      return billData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.bill_number}</td>
            <td>{item.booking_date}</td>
            <td>{indianRupeeFormat(item.reversal_provision_amount)}</td>
            <td>{item.tds_section}</td>
            <td>{indianRupeeFormat(item.reversel_provision_tds)}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setBillDetails(item)}
              >
                Select
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  const selectBillModalCloseHandler = () => {
    setOpenBillModal(false);
  };

  return (
    <>
      {/* select provision modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openProvisionModal}
        onHide={() => selectProvisionModalClose()}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Any Provision</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "350px" }}>
          <div>
            <TextField
              id="outlined-password-input"
              label="Search by legal name and PAN"
              fullWidth={true}
              size="small"
              onChange={selectProvisionModalSearch}
            />
          </div>
          <div className={classes.modalTable}>
            <table className={classes.tablecss}>
              <tbody>
                <tr>
                  <th>legal name</th>
                  <th>PAN</th>
                  <th>provision create date</th>
                  <th>TDS Section</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
                {provisionTableBodyData()}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            variant="outlined"
            onClick={selectProvisionModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* select bill modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBillModal}
        onHide={() => selectBillModalCloseHandler()}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Any Bill</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "350px" }}>
          <div>
            <TextField
              id="outlined-password-input"
              label="Search by bill number and Tds Section"
              fullWidth={true}
              size="small"
              onChange={selectBillModalSearch}
            />
          </div>
          <div className={classes.modalTable}>
            <table className={classes.tablecss}>
              <tbody>
                <tr>
                  <th>Bill Number</th>
                  <th>Booking Date</th>
                  <th>Taxable + Non Taxable Amount (excluding GST/CESS)</th>
                  <th>TDS Section</th>
                  <th>TDS Payable</th>
                  <th>Action</th>
                </tr>
                {billTableBodyData()}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            variant="outlined"
            onClick={selectBillModalCloseHandler}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        {/* modal open button */}
        <button
          type="button"
          className="button-outlined-primary"
          style={{ marginTop: "10px", width: "100%" }}
          onClick={() => selectProvisionModalOpen()}
        >
          select Provision*
        </button>

        <ValidatorForm
          useRef="form"
          onSubmit={addFormsubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <InputField
            textLabel="Vendor Legal Name"
            inputType="text"
            value={reversalAddData.vendor_legal_name ?? ""}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            inputProps={{ readOnly: true }}
          />

          <InputField
            textLabel="PAN"
            inputType="text"
            value={reversalAddData.pan ?? ""}
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            inputProps={{ readOnly: true }}
          />

          {/* ...select bill type...  */}
          <SelectField
            choice_id="inv_type"
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            choice_name="inv_type"
            SelectLabel="Bill Type"
            name="inv_type"
            value={reversalAddData.inv_type ?? ""}
            textOnchangeFunction={addInputChangeHandler}
            choices={[
              {
                id: 0,
                inv_type: "Bill",
              },
              {
                id: 1,
                inv_type: "Credit Note",
              },
              {
                id: 2,
                inv_type: "Debit Note",
              },
            ]}
          />

          {/* button for open bill modal */}
          {reversalAddData.inv_type && (
            <button
              type="button"
              style={{ width: "100%", marginTop: "20px" }}
              className="button-outlined-primary"
              onClick={selectBillModalOpenHandler}
            >
              select Bill*
            </button>
          )}

          {/* data from bill modal */}
          {reversalAddData.bill_number && (
            <>
              <InputField
                textLabel="Bill Number"
                inputType="text"
                value={reversalAddData.bill_number}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{ readOnly: true }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  Booking Date(Reversal Date)
                  <span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  // Added by Vyshnavi
                  value={reversalAddData.booking_date ?? ""}
                  // value={reversalAddData.provision_create_date ?? ""}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                  // disabled={false}
                />
              </div>

              <InputField
                textLabel="Taxable + Non Taxable Amount (excluding GST/CESS)"
                inputType="text"
                value={
                  indianRupeeFormat(
                    reversalAddData.reversal_provision_amount
                  ) ?? ""
                }
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{ readOnly: true }}
              />

              {/*Edit By Harsh*/}
              <div style={{ marginTop: "20px" }}>
                <label>
                  TDS Section<span style={{ color: "red" }}>*</span>:
                </label>{" "}
                <br />
                <StyledAutoComplete
                  id="combo-box-demo"
                  style={{ padding: "0!important" }}
                  name="tds_section"
                  inputValue={
                    inputValue ? inputValue : reversalAddData.tds_section
                  }
                  size="small"
                  // value={reversalAddData.tds_section ?? inputValue}
                  onChange={(e, value) => {
                    if (value === null) {
                      setReversalAddData({
                        ...reversalAddData,
                        tds_section: "",
                      });
                    } else {
                      setReversalAddData({
                        ...reversalAddData,
                        tds_section: value.section ?? "",
                      });
                    }
                  }}
                  onInputChange={(event, inputValue) => {
                    console.log(inputValue);
                    // event.preventDefault();
                    setInputValue(inputValue);
                  }}
                  options={sectionDropdownValue}
                  getOptionLabel={(option) => option.section}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="tds_section"
                      required
                    />
                  )}
                />
              </div>

              {/*
              <InputField
                textLabel="TDS Section"
                inputType="text"
                value={reversalAddData.tds_section}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{ readOnly: true }}
              />
            */}

              <InputField
                textLabel="TDS %"
                inputType="text"
                value={reversalAddData.tds_percentage}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{ readOnly: true }}
              />

              <InputField
                textLabel="TDS Payable"
                inputType="text"
                value={
                  indianRupeeFormat(reversalAddData.reversal_provision_tds) ??
                  ""
                }
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{ readOnly: true }}
              />
            </>
          )}

          {/* .......Button........ */}

          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              type="button"
              className="button-outlined-secondary"
              onClick={cancelAddFormHandler}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </div>
    </>
  );
}
