import React, { useEffect, useState } from "react";
import classes from "../BusinessEntityDetails.module.css";
import axios from "../../../axios-order";
import { Button, Grid, Drawer } from "@material-ui/core";
import AddTdsForm from "./add";
import EditTdsForm from "./edit";
import BoxWrapper from "../../../components/ui/boxWrapper";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RightDrawer from "../../../components/ui/RightDrawer";

export default function Exemption(props) {
  const [exemptionData, setExemptionData] = useState(null);
  const [exemptionDataForSearchBar, setExemptionDataForSearchBar] =
    useState(null);
  const [addFormDrawerOpen, setAddFormDrawerOpen] = useState(false);
  const [editFormDrawerOpen, setEditFormDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const getAllExemptionData = () => {
    axios
      .get(`/tds/exemption/get/${localStorage.getItem("gst")}`)
      .then((res) => {
        setExemptionData(res.data.record);
        setExemptionDataForSearchBar(res.data.record);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAllExemptionData();
  }, []);

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  const SearchEvent = (e) => {
    const search = e.target.value;
    const searchJson = exemptionDataForSearchBar.filter((v) => {
      return (
        String(v.pan).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        String(v.vendor_legal_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(v.tds_section).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setExemptionData(searchJson);
  };

  const tableBodyData = () => {
    if (exemptionData && exemptionData.length !== 0) {
      return exemptionData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.pan}</td>
            <td>{item.vendor_legal_name} </td>
            <td>{indianFormatDate(item.valid_from_date)}</td>
            <td>{indianFormatDate(item.valid_to_date)}</td>
            <td>{item.tds_section}</td>

            <td>{item.description}</td>
            <td>
              <Button
                style={{ minWidth: "0px" }}
                onClick={() => editFormOpenDrawerHandler(item)}
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>{" "}
              |{" "}
              <Button
                style={{ minWidth: "0px" }}
                onClick={() => deleteTableRow(item.id)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={9}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // .......close add form drawer.....
  const addFormCloseDrawerHandler = () => {
    setAddFormDrawerOpen(false);
  };
  // ........open edit form drawer.....
  const editFormOpenDrawerHandler = (item) => {
    setEditFormDrawerOpen(true);
    setEditData(item);
  };
  // .......close edit form drawer.....
  const editFormCloseDrawerHandler = () => {
    setEditFormDrawerOpen(false);
  };

  // ........delete table row.........
  const deleteTableRow = (id) => {
    let text = "Are you sure! you want to delete this record.";
    if (window.confirm(text) === true) {
      axios
        .delete(`/tds/exemption/delete/${id}`)
        .then((res) => {
          getAllExemptionData();
        })
        .catch((err) => {});
    } else {
    }
  };

  return (
    <>
      {/* ........Table div......... */}
      <BoxWrapper
        boxTitle="TDS Exemption"
        enableAddButton={true}
        addButtonLabel={"ADD"}
        addButtonHandlerFn={() => setAddFormDrawerOpen(true)}
        enableSearchInput={true}
        searchInputPlaceholder="Pan,Legal Name,Section"
        searchInputOnchange={SearchEvent}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>PAN</th>
                <th>legal name</th>
                <th>FROM DATE</th>
                <th>TO DATE</th>
                <th>section</th>

                <th>description</th>
                <th>Action</th>
              </tr>
              {tableBodyData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* ........add tds form drawer.......... */}
      <RightDrawer
        drawerName="Add Exemption"
        open={addFormDrawerOpen}
        onClose={addFormCloseDrawerHandler}
      >
        <AddTdsForm
          updateGetApi={getAllExemptionData}
          cancelAddForm={addFormCloseDrawerHandler}
        />
      </RightDrawer>
      {/* ........edit tds form drawer........... */}
      <RightDrawer
        drawerName="Edit Exemption"
        open={editFormDrawerOpen}
        onClose={editFormCloseDrawerHandler}
      >
        <EditTdsForm
          updateGetApi={getAllExemptionData}
          cancelEditForm={editFormCloseDrawerHandler}
          editFormData={editData}
        />
      </RightDrawer>
    </>
  );
}
