import React, { useState, useEffect } from "react";
import classes from "./CreditEvalution.module.css";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../../components/appBar/appBar";
import Footer from "../../../../components/footer/footer";
import axios from "../../../../axios-order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@material-ui/core";
import TabForm from "./Tabs/TabForm";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import "../../../../HOC/layout/layout.css";

function getSteps() {
  return ["DPO", "DSO", "DIO", "NETWORTH", "OPERATING PROFIT", "CURRENT RATIO"];
}

export default function CreditEvalution() {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [dpoData, setDpoData] = useState({});
  const [dsoData, setDsoData] = useState({});
  const [dioData, setDioData] = useState({});
  const [networthData, setNetworthData] = useState({});
  const [operating_profitData, setOperating_profitData] = useState({});
  const [current_ratioData, setCurrent_ratioData] = useState({});

  const [displayBlockOrNone, setDisplayBlockOrNone] = useState("none");
  const [message, setMessage] = useState("");

  const functionForErrorMessage = (display, messageForError) => {
    setMessage(messageForError);
    setDisplayBlockOrNone(display);
  };
  // ........handle stepper back.......
  const handleStepperBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    functionForErrorMessage("none");
  };

  // handle stepper next
  const handleStepperNext = () => {
    if (activeStep === 0) {
      let TotalvalueFordpo =
        parseFloat(dpoData.score_latest) +
        parseFloat(dpoData.score_previous) +
        parseFloat(dpoData.score_previous_to);
      console.log(typeof Totalvalue);
      let flag = true;
      for (let i of Object.keys(dpoData)) {
        if (dpoData[i] === "") {
          functionForErrorMessage("block", "All field are mandatory.");
          flag = false;
        }
      }
      if (dpoData.allocated_percentage > 100) {
        functionForErrorMessage("block", "invalid percentage.");
        flag = false;
      }
      if (TotalvalueFordpo !== 100) {
        functionForErrorMessage("block", "Total score must be 100.");
        flag = false;
      }
      if (flag) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessage("none");
      }
    } else if (activeStep === 1) {
      let TotalvalueForDso =
        parseFloat(dsoData.score_latest) +
        parseFloat(dsoData.score_previous) +
        parseFloat(dsoData.score_previous_to);
      let flag = true;
      for (let i of Object.keys(dsoData)) {
        if (dsoData[i] === "") {
          functionForErrorMessage("block", "All field are mandatory.");
          flag = false;
        }
      }
      if (dsoData.allocated_percentage > 100) {
        functionForErrorMessage("block", "invalid percentage.");
        flag = false;
      }
      if (TotalvalueForDso !== 100) {
        functionForErrorMessage("block", "Total score must be 100.");
        flag = false;
      }
      if (flag) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessage("none");
      }
    } else if (activeStep === 2) {
      let TotalvalueForDio =
        parseFloat(dioData.score_latest) +
        parseFloat(dioData.score_previous) +
        parseFloat(dioData.score_previous_to);
      let flag = true;
      for (let i of Object.keys(dioData)) {
        if (dioData[i] === "") {
          functionForErrorMessage("block", "All field are mandatory.");
          flag = false;
        }
      }
      if (dioData.allocated_percentage > 100) {
        functionForErrorMessage("block", "invalid percentage.");
        flag = false;
      }
      if (TotalvalueForDio !== 100) {
        functionForErrorMessage("block", "Total score must be 100.");
        flag = false;
      }
      if (flag) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessage("none");
      }
    } else if (activeStep === 3) {
      let TotalvalueForNetworthData =
        parseFloat(networthData.score_latest) +
        parseFloat(networthData.score_previous) +
        parseFloat(networthData.score_previous_to);
      let flag = true;
      for (let i of Object.keys(networthData)) {
        if (networthData[i] === "") {
          functionForErrorMessage("block", "All field are mandatory.");
          flag = false;
        }
      }
      if (networthData.allocated_percentage > 100) {
        functionForErrorMessage("block", "invalid percentage.");
        flag = false;
      }
      if (TotalvalueForNetworthData !== 100) {
        functionForErrorMessage("block", "Total score must be 100.");
        flag = false;
      }
      if (flag) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessage("none");
      }
    } else if (activeStep === 4) {
      let TotalvalueForOperating_ProfitData =
        parseFloat(operating_profitData.score_latest) +
        parseFloat(operating_profitData.score_previous) +
        parseFloat(operating_profitData.score_previous_to);
      let flag = true;
      for (let i of Object.keys(operating_profitData)) {
        if (operating_profitData[i] === "") {
          functionForErrorMessage("block", "All field are mandatory.");
          flag = false;
        }
      }
      if (operating_profitData.allocated_percentage > 100) {
        functionForErrorMessage("block", "invalid percentage.");
        flag = false;
      }
      if (TotalvalueForOperating_ProfitData !== 100) {
        functionForErrorMessage("block", "Total score must be 100.");
        flag = false;
      }
      if (flag) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        functionForErrorMessage("none");
      }
    }
  };

  // ........form input change handler.........
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    if (activeStep === 0) {
      functionForErrorMessage("none", "");
      setDpoData({
        ...dpoData,
        [name]: value,
      });
    } else if (activeStep === 1) {
      functionForErrorMessage("none", "");
      setDsoData({
        ...dsoData,
        [name]: value,
      });
    } else if (activeStep === 2) {
      functionForErrorMessage("none", "");
      setDioData({
        ...dioData,
        [name]: value,
      });
    } else if (activeStep === 3) {
      functionForErrorMessage("none", "");
      setNetworthData({
        ...networthData,
        [name]: value,
      });
    } else if (activeStep === 4) {
      functionForErrorMessage("none", "");
      setOperating_profitData({
        ...operating_profitData,
        [name]: value,
      });
    } else if (activeStep === 5) {
      functionForErrorMessage("none", "");
      setCurrent_ratioData({
        ...current_ratioData,
        [name]: value,
      });
    }
  };

  // ........use effect..........
  useEffect(() => {
    axios
      .get("/get_credit_evaluvation_config")
      .then((res) => {
        console.log(res.data.data.dpo);
        setDpoData(res.data.data.dpo);
        setDsoData(res.data.data.dso);
        setDioData(res.data.data.dio);
        setNetworthData(res.data.data.net_worth);
        setOperating_profitData(res.data.data.operating_profit);
        setCurrent_ratioData(res.data.data.current_ratio);
      })
      .catch((error) => {});
  }, []);

  // ........form submit handler.......
  const finalData = {
    dpo: dpoData,
    dso: dsoData,
    dio: dioData,
    net_worth: networthData,
    operating_profit: operating_profitData,
    current_ratio: current_ratioData,
  };

  const CreditEvalutionFormHandler = () => {
    let TotalvalueForCurrent_RatioData =
      parseFloat(current_ratioData.score_latest) +
      parseFloat(current_ratioData.score_previous) +
      parseFloat(current_ratioData.score_previous_to);
    let TotalAllocatedPercentage =
      parseFloat(dpoData.allocated_percentage) +
      parseFloat(dsoData.allocated_percentage) +
      parseFloat(dioData.allocated_percentage) +
      parseFloat(networthData.allocated_percentage) +
      parseFloat(operating_profitData.allocated_percentage) +
      parseFloat(current_ratioData.allocated_percentage);
    let flag = true;
    for (let i of Object.keys(current_ratioData)) {
      if (current_ratioData[i] === "") {
        flag = false;
        functionForErrorMessage("block", "All field are mandatory.");
      }
    }
    if (current_ratioData.allocated_percentage > 100) {
      functionForErrorMessage("block", "invalid percentage.");
      flag = false;
    }
    if (TotalvalueForCurrent_RatioData !== 100) {
      functionForErrorMessage("block", "Total score must be 100.");
      flag = false;
    }
    if (TotalAllocatedPercentage !== 100) {
      functionForErrorMessage(
        "block",
        "Total Allocated Percentage must be 100."
      );
      flag = false;
    }
    if (flag) {
      functionForErrorMessage("none");
      axios
        .post("/post_credit_evaluvation_config", finalData)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, { autoClose: 5000 });
          } else {
            toast.error(res.data.message, { autoClose: 5000, progress: 0 });
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <Appbar />
      <div style={{ margin: "100px 30px 50px 30px" }}>
        <BoxWrapper boxTitle="Credit Evaluation">
          {/*........ steppers...... */}
          <div style={{ margin: "auto", width: "70%", marginTop: "10px" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <p>{label}</p>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <div style={{ width: "80%", margin: "auto" }}>
            {activeStep === 0 && (
              <>
                <TabForm
                  data={dpoData}
                  handleInputChange={handleEditInputChange}
                />
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <button
                    className="button-primary"
                    onClick={handleStepperNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {activeStep === 1 && (
              <>
                <TabForm
                  data={dsoData}
                  handleInputChange={handleEditInputChange}
                />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button-outlined-secondary"
                    onClick={handleStepperBack}
                  >
                    Back
                  </button>
                  <button
                    className="button-primary"
                    onClick={handleStepperNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {activeStep === 2 && (
              <>
                <TabForm
                  data={dioData}
                  handleInputChange={handleEditInputChange}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button-outlined-secondary"
                    onClick={handleStepperBack}
                  >
                    Back
                  </button>
                  <button
                    className="button-primary"
                    onClick={handleStepperNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {activeStep === 3 && (
              <>
                <TabForm
                  data={networthData}
                  handleInputChange={handleEditInputChange}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button-outlined-secondary"
                    onClick={handleStepperBack}
                  >
                    Back
                  </button>
                  <button
                    className="button-primary"
                    onClick={handleStepperNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {activeStep === 4 && (
              <>
                <TabForm
                  data={operating_profitData}
                  handleInputChange={handleEditInputChange}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button-outlined-secondary"
                    onClick={handleStepperBack}
                  >
                    Back
                  </button>
                  <button
                    className="button-primary"
                    onClick={handleStepperNext}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {activeStep === 5 && (
              <>
                <TabForm
                  data={current_ratioData}
                  handleInputChange={handleEditInputChange}
                />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="button-outlined-secondary"
                    onClick={handleStepperBack}
                  >
                    Back
                  </button>
                  <button
                    className="button-primary"
                    onClick={CreditEvalutionFormHandler}
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            )}

            {
              <div
                style={{
                  color: "red",

                  display: displayBlockOrNone,
                  textAlign: "right",
                }}
              >
                {" "}
                {message}
              </div>
            }
          </div>
        </BoxWrapper>
      </div>

      {/* ---Footer--- */}
      <div className={classes.profileFooter}>
        <Footer />
      </div>
      {/* ---Footer End--- */}
    </>
  );
}
