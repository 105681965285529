import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../../../axios-order";
import "react-toastify/dist/ReactToastify.css";
import "../TDSReconciliationSale/TDSRecon.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import InfiniteScroll from 'react-infinite-scroll-component';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from "@mui/material/Tooltip";
import { red } from '@mui/material/colors';
import ConfirmationDialog from '../TDSReconciliationSale/ConfirmationDailog'

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import EmailIcon from '@mui/icons-material/Email';
import LoadingButton from '@mui/lab/LoadingButton';

import { TextField, Select, MenuItem, InputLabel, FormControl, IconButton, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // For 'Greater Than'
import RemoveIcon from '@mui/icons-material/Remove'; // For 'Equal To'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // For 'Less Than'


const initialPageSize = 1000;
export default function Form26asTCSreport() {
  let history = useHistory();
  const [form_data, setFormData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [emptyView, setEmptyView] = useState(false)
  const tableRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  const [responseMessage, setResponseMessage] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);

  const [emailButtonLoading, setEmailButtonLoading] = useState(false)

  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  const [invoiceSelected, setInvoiceSelected] = useState(false)

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [filterCondition, setFilterCondition] = useState('greaterThan');
  const [filterValue, setFilterValue] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectAllNotificationLockChecked, setSelectAllNotificationLockChecked] = useState(false);

  const handleNotificationRemarkChange = (index, value) => {
    const updatedData = [...filteredData];
    updatedData[index].notification_remarks = value;
    setFilteredData(updatedData);
  };

  const handleNotificationLockChange = (item, isChecked) => {
    const updatedData = filteredData.map(data =>
      data === item ? { ...data, notification_lock: isChecked } : data
    );
    setFilteredData(updatedData);

    // Check if all filtered items are locked to update the Select All checkbox
    setSelectAllNotificationLockChecked(updatedData.every(data => data.notification_lock));
  };

  const handleSelectAllNotificationLock = (isChecked) => {
    setSelectAllNotificationLockChecked(isChecked);

    const updatedData = filteredData.map(item => ({
      ...item,
      notification_lock: isChecked
    }));

    setFilteredData(updatedData);
  };


  const isPopoverOpen = Boolean(anchorEl);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchInput]);


  const fetchData = () => {
    setIsLoading(true);
    console.log('use effect calling FECTH DATA ')
    const entity_id = localStorage.getItem("business_entity_id")

    const financial_year = localStorage.getItem('financial_year_26_as')

    const payLoad = {
      'business_entity_id': entity_id,
      'financial_year': financial_year,
      'page_number': currentPage,
      'page_size': initialPageSize,
      'search_text': ''
    }

    axios
      .post('/form_26_tcs/get_data', payLoad)
      .then((res) => {
        if (res.data) {
          // setFormData((prevData) => [...prevData, ...res.data.records]);
          setFormData(res.data.records)
          setFilteredData(res.data.records)
          setCurrentPage((prevPage) => prevPage + 1);
          setIsLoading(false);
          if (res.data.records.length < initialPageSize) {
            setHasMore(false);
          }
        } else {
          setEmptyView(true)
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEmptyView(true)
      });

  };

  const loadMore = () => {
    fetchData();
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = () => {
    const payloadData = {

      "business_entity_id": localStorage.getItem("business_entity_id"),
      "financial_year": localStorage.getItem('financial_year_26_as')
    }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `TCS Receivables Reconciliation Report.xlsx`;

    axios.post('/form26astcsreport_for_table2', payloadData)
      .then((res) => {
        downloadBase64File(contentType, res?.data?.file, filename);
      }).catch((err) => {

      })
  };

  const navigatePairPage = (item, index) => {
    const pairing_data = {
      "business_entity_id": localStorage.getItem("business_entity_id"),
      "books_grouping_key": item.books_grouping_key,
      "books_grouping_type": item.books_grouping_type,
      "form_26as_grouping_key": item.form_26as_grouping_key,
      "grouping_key": item.form_26as_grouping_key,
      "form_26_grouping_type": item.form_26as_grouping_type ? item.form_26as_grouping_type : "",
      "financial_year": localStorage.getItem('financial_year_26_as')
    }
    localStorage.setItem("lastClickedIndex", index);

    history.push({
      pathname: `/TCS/Pairing/26AS`,
      state: { pairingData: pairing_data }
    });
  }


  const handleBackFunction = () => {
    history.goBack();
  }

  const handleDialogConfirm = () => {
    const body = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      financial_year: localStorage.getItem('financial_year_26_as')
    }
    axios.post('/form26_tcs/unpair_manual_pairs', body)
      .then((res) => {
        if (res.data) {
          handleSnackbarOpen(res.data.message, 'success')
        }
      }).catch((err) => {

      })
  }

  useEffect(() => {
    console.log(invoiceSelected)
  }, [invoiceSelected])

  const onClickSendEmailNotification = () => {
    setEmailButtonLoading(true)
    const pairing_data = {
      "entity_name": localStorage.getItem("entity_name"),
      "business_entity_id": localStorage.getItem("business_entity_id"),
      "financial_year": localStorage.getItem('financial_year_26_as'),
      "selected_items": selectedItems,
      "invoice_details_slected": invoiceSelected,
      "report_type" : "TCS"
    }
    axios.post('/form26/send_form_26as_report_email', pairing_data)
      .then((res) => {
        setEmailButtonLoading(false)
        setSelectedItems([])
        setSelectAllChecked(false)
        console.log(res.data)
        if (res.data.status) {
          handleSnackbarOpen(res.data.message, "success")
        } else {
          handleSnackbarOpen(res.data.message, "warning")
        }
      })
      .catch((err) => {
        setEmailButtonLoading(false)
        setSelectedItems([])
        setSelectAllChecked(false)
      })
    setInvoiceSelected(false)
  }

  const handleCheckboxChange = (item, isChecked) => {
    setSelectedItems(prevSelectedItems => {
      let newSelectedItems;
      if (isChecked) {
        newSelectedItems = [...prevSelectedItems, item];
      } else {
        newSelectedItems = prevSelectedItems.filter(selectedItem => selectedItem !== item);
      }
      const allItemsSelected = filteredData.filter(item => item["Difference"] > 10 && !item['notification_lock'])
        .every(item => newSelectedItems.includes(item));
      setSelectAllChecked(allItemsSelected);

      return newSelectedItems;
    });
  };


  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      const selected = filteredData.filter(item => item["Difference"] > 10 && !item['notification_lock']);
      setSelectedItems(selected);
    } else {
      setSelectedItems([]);
    }
  };


  const handleNoticationClick = () => {
    if (selectedItems.length === 0) {
      setEmailButtonLoading(false)
      handleSnackbarOpen('Please select records to send a notification.', 'warning')
      setSelectAllChecked(false);
      return ''
    } else {
      setNotificationDialogOpen(true)
    }
  }

  

  const handleSearchEvent = (event) => {
    localStorage.setItem("lastClickedIndex", null);
    const inputValue = event.target.value;
    setSearchInput(inputValue)

  }

  const handleSaveNofticationRemark = () => {
    const body = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: filteredData
    }
    axios.post('/form26_tcs/saveNotificationRemark', body)
      .then((res) => {

        if (res.data.status) {
          handleSnackbarOpen(res.data.message, "success")
        } else {
          handleSnackbarOpen(res.data.message, "warning")
        }

      }).catch((err) => {

      })
  }

  const filterData = () => {
    const filtered = form_data.filter(record => {
      const customerName = record['VENDOR NAME'] || '';
      const customerPan = record['VENDOR PAN'] || '';
      return customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
        customerPan.toLowerCase().includes(searchInput.toLowerCase());
    });
    setFilteredData(filtered);
  }

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFilterConditionChange = (event) => {
    setFilterCondition(event.target.value);
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  useEffect(() => {
    applyDifferenceFilter();
  }, [filterValue, filterCondition]);


  const applyDifferenceFilter = () => {
    const value = parseFloat(filterValue);

    if (isNaN(value)) {
      setFilteredData(form_data);
      return;
    }

    const filtered = form_data.filter(record => {
      const difference = parseFloat(record["Difference"]);

      if (isNaN(difference)) {
        return false;
      }

      switch (filterCondition) {
        case 'greaterThan':
          return difference > value;
        case 'equalTo':
          return difference === value;
        case 'lessThan':
          return difference < value;
        default:
          return true;
      }
    });

    setFilteredData(filtered);
  };

  const renderDifferenceFilter = () => {
    return (
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '220px', // Set the width to 300px or adjust as needed
            padding: '16px', // Add some padding for better spacing
          },
        }}
      >
        <div>
          <TextField
            label="Value"
            variant="outlined"
            size="small"
            type="number"
            value={filterValue}
            onChange={handleFilterValueChange}
            fullWidth
            style={{ marginBottom: '16px' }}
          />
          {/* Dropdown for condition */}
          <FormControl fullWidth variant="outlined" size="small" >
            <InputLabel>Condition</InputLabel>
            <Select
              value={filterCondition}
              onChange={handleFilterConditionChange}
              label="Condition"
            >
              <MenuItem value="greaterThan">
                <ArrowForwardIcon style={{ marginRight: 8 }} /> Greater Than
              </MenuItem>
              <MenuItem value="equalTo">
                <RemoveIcon style={{ marginRight: 8 }} /> Equal To
              </MenuItem>
              <MenuItem value="lessThan">
                <ArrowBackIcon style={{ marginRight: 8 }} /> Less Than
              </MenuItem>
            </Select>
          </FormControl>

          {/* Input for value */}

        </div>
      </Popover>
    )
  }

  const financial_year = localStorage.getItem('financial_year_26_as')

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <BoxWrapper
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
        exportToExcelEnable={true} exportToExcelFn={exportToExcel} boxTitle={`TCS detailed Summary  ( ${financial_year} )`}>
        
          <div
            className="responsive-table"
            style={{ scrollbarWidth: "thin", scrollbarHeight: '25px', maxHeight: '52vh' }}
            id="scrollableDiv"
          >
            <table className="summary-tab-single-year-table-css">
              <thead>
                <tr>
                  <th style={{ width: process.env.REACT_APP_MODE === "DEBUG" ? "45%" : "22%" }} colSpan={process.env.REACT_APP_MODE === "DEBUG" ? 7 : 3}>
                    Vendor Data
                  </th>
                  <th colSpan={process.env.REACT_APP_MODE === "DEBUG" ? 13 : 11}>
                    TCS Summary
                  </th>
                  <th rowSpan={2} className="action-column" style={{ width: process.env.REACT_APP_MODE === "DEBUG" ? "4.94%" : "2.95%" }}>Action</th>
                </tr>
                <tr>
                  <th>
                    <label style={{ display: 'flex', marginLeft: '25%', cursor: 'pointer' }}>
                      {filteredData.some(item => item["Difference"] > 10) && (
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #4CAF50',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                        />
                      )}
                      <span style={{ marginLeft: '5px' }}>Notification</span>
                    </label>
                  </th>

                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Grouping Key</th>}
                  <th>Vendor PAN</th>
                  <th>Vendor Name</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="vendor-name-26as-column">Vendor Name 26AS</th>}
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="books-tan-column">Books TAN</th>}
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="form26as-tan-column">26AS TAN</th>}
                  <th style={{ width: "5%" }}>Books TCS Opening Balance</th>
                  <th style={{ width: "5%" }}>TCS as per Books</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Count of Books records</th>}
                  <th style={{ width: "5%" }}>26AS TCS Opening Balance</th>
                  <th style={{ width: "5%" }}>TCS as per 26AS</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Count of 26AS records</th>}
                  <th style={{ width: "5%" }}>
                    Difference
                    <IconButton
                      onClick={handleFilterIconClick}
                      size="small"
                      style={{
                        color: filterValue && "#2196F3"
                      }}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </th>
                  <th style={{ width: "5%" }}>Carry Forward TCS - Books</th>
                  <th style={{ width: "5%" }}>Carry Forward TCS - 26AS</th>
                  <th style={{ width: "5%" }}>Previous Balance Availed in Current Year - Books</th>
                  <th style={{ width: "5%" }}>Previous Balance Availed in Current Year - 26AS</th>
                  <th rowSpan={2} style={{ width: "25%" }}>Notification Remark</th>
                  <th rowSpan={2} style={{ width: "5%" }}>
                    <label style={{ display: 'flex', marginLeft: '18%', cursor: 'pointer' }}>
                      {filteredData.some(item => item["Difference"] > 10) && (
                        <input
                          type="checkbox"
                          checked={selectAllNotificationLockChecked}
                          onChange={(e) => handleSelectAllNotificationLock(e.target.checked)}
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #4CAF50',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                        />
                      )}
                      <span style={{ marginLeft: '5px' }}>Notification Lock</span>
                    </label>
                  </th>
                </tr>
              </thead>
              {renderDifferenceFilter()}
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      style={{ color: "black", textAlign: 'center', fontWeight: "bold" }}
                      colSpan={12}
                    >
                      Loading…Please wait
                    </td>
                  </tr>
                ) :
                  filteredData.length >= 1 ? (filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {(item["Difference"]) > 10 && <input type='checkbox'
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #4CAF50',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                          disabled={item['notification_lock']}
                          checked={selectedItems.includes(item)}
                          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                        />}
                      </td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["books_grouping_key"] ? item["books_grouping_key"] : item["form_26as_grouping_key"]}</td>}
                      <td align="center">{item["VENDOR PAN"]}</td>
                      <td>
                        {item["VENDOR NAME"] && item["VENDOR NAME"].split(", ").map((name, i, arr) => (
                          <React.Fragment key={i}>
                            {name}
                            {i !== arr.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["vendor_name_26AS"]}</td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["books_tan"]}</td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["form26as_tan"]}</td>}
                      <td>{item["Books TCS Opening Balance"]}</td>
                      <td>{item["TCS as per Books"]}</td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["count_books_records"]}</td>}
                      <td>{item["26AS TCS Opening Balance"]}</td>
                      <td>{item["TCS as per 26AS"]}</td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["count_26as_records"]}</td>}
                      <td>{(item["Difference"] || item["Difference"] == 0) ? item["Difference"].toFixed(2) : ''}</td>
                      <td>{item["Carry Forward TCS - Books"]}</td>
                      <td>{item["Carry Forward TCS - 26AS"]}</td>
                      <td>{item["Previous Balance Availed in Current Year - Books"]}</td>
                      <td>{item["Previous Balance Availed in Current Year - 26AS"]}</td>

                      <td>
                        {(item["Difference"]) > 10 && <textarea
                          value={item.notification_remarks || ''}
                          onChange={(e) => handleNotificationRemarkChange(index, e.target.value)}
                          style={{
                            width: '90%',
                            height: '37px',
                            padding: '7px',
                            margin: '5px 0',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                            fontSize: '14px',
                            fontFamily: 'Arial, sans-serif',
                            resize: 'none', // Disable resizing if you want a fixed size
                            overflow: 'hidden', // Remove the scrollbar
                            transition: 'border-color 0.3s ease, box-shadow 0.3s ease'
                          }}
                          placeholder="Enter Remarks"
                          onFocus={(e) => (e.target.style.borderColor = '#4CAF50', e.target.style.boxShadow = '0 0 8px rgba(76, 175, 80, 0.4)')}
                          onBlur={(e) => (e.target.style.borderColor = '#ccc', e.target.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.1)')}
                        />}
                      </td>

                      {/* New Notification Lock Checkbox */}
                      <td>
                        {(item["Difference"]) > 10 && <input type='checkbox'
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #4CAF50',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                          checked={item['notification_lock']}
                          onChange={(e) => handleNotificationLockChange(item, e.target.checked)}
                        />}
                      </td>
                      <td className="action-column" style={{ backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>

                        <VisibilityOutlinedIcon onClick={() => navigatePairPage(item, index)} className="action-icon-button showIcon" />
                      </td>
                    </tr>
                  ))) : (
                    <tr>
                      <td
                        style={{ color: "black", textAlign: 'center', fontWeight: "bold" }}
                        colSpan={12}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>

      </BoxWrapper>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '10px', marginBottom: '1.2%' }}>
        <LoadingButton
          color="primary"
          onClick={handleNoticationClick}
          loading={emailButtonLoading}
          loadingPosition="start"
          startIcon={<EmailIcon />}
          variant="contained"
          style={{ width: '120px', height: '30px' }}
        >
          <span>Send</span>
        </LoadingButton>

        <button
          onClick={() => setDialogOpen(true)}
          className="button-outlined-primary"
          style={{ marginLeft: '0%', width: '180px', marginTop: '20px' }}
        >
          Unpair all Manual Pairs
        </button>

        <button
          onClick={handleBackFunction}
          className="button-outlined-primary"
          style={{ marginLeft: '0%', width: '120px', marginTop: '20px' }}
        >
          Back
        </button>

        <button
          onClick={handleSaveNofticationRemark}
          className="button-outlined-primary"
          style={{ marginLeft: '0%', width: '120px', marginTop: '20px' }}
        >
          SAVE
        </button>
      </div>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDialogConfirm}
        title={'Confirm Manual Pairs Unpairing'}
        text={`This will remove all manual pairs across all customers. Are you sure you want to proceed?`}
      />

      <ConfirmationDialog
        open={notificationDialogOpen}
        onClose={() => setNotificationDialogOpen(false)}
        onConfirm={onClickSendEmailNotification}
        title="Send Email Notification"
        text="Are you sure you want to send email notifications to the selected customers?"
        enableCheckBox={true}
        checkBoxLable={'Report with invoice details'}
        onChnageInvoiceCheckbox={() => setInvoiceSelected((prev) => !prev)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}

        </MuiAlert>
      </Snackbar>
    </div>
  );
}
