import React, { useState, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputField from '../../../../../components/ui/inputField';
import SelectField from '../../../../../components/ui/select';
import { Button, TextField } from '@material-ui/core';
import axios from '../../../../../axios-order';
import DatePicker from '../../../../../components/ui/DatePicker';
import './DebitNote.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NumberFormat from 'react-number-format';
import { TablePagination } from '@material-ui/core';

function AddDebit(props) {
  const addFormInitialData = {
    vendorGstin: '',
    vendorPan: '',
    originalBillNumber: '',
    originalBillDate: null,
    eInvoice: '',
    bookingDate: null,
    debitNoteNumber: '',
    debitNoteDate: null,
    hsnSacCode: '',
    taxableValue: 0,
    nonTaxableValue: 0,
    sgst: 0,
    cgst: 0,
    igst: 0,
    cess: 0,
    taxAmount: 0,
    tcsSection: '',
    tcsPayable: 0,
    totalAmount: 0,
    tdsSection: '',
    tdsPayable: 0,
    expenseType: '',
    taxCreditType: '',
    itcEligibility: '',
    rcmApplicability: '',
    dispute: '',
    disputeValue: 0,
    debitNoteReason: '',
    boeNumber: '',
    boeDate: null,
    referenceDate: null,
    portCode: '',
    erpVendorCode: '',
  };
  const { updateTableAfterSubmit, closeAddFormDrawer, getDebitNoteDetail } =
    props;
  const [addFormData, setAddFormData] = useState(addFormInitialData);
  const [getDataForSelectInvoice, setGetDataForSelectInvoice] = useState([]);
  const [modelOpenButton, setModelOpenButton] = useState(false);
  const [modelCloseButton, setModelCloseButton] = useState(false);

  // TablePagination
  const [pageForSelectVendor, setPageForSelectVendor] = useState(0);
  const [rowsPerPageForSelectVendor, setRowsPerPageForSelectVendor] =
    useState(25);
  const [rowCountForSelectVendor, setRowCountForSelectVendor] = useState(0);
  const [tableSearchForSelectVendor, setTableSearchForSelectVendor] =
    useState('');
  const [stateForPageForSelectVendor, setStateForPageForSelectVendor] =
    useState(false);
  const [
    stateForRowPerPageForSelectVendor,
    setStateForRowPerPageForSelectVendor,
  ] = useState(false);
  const [stateTableSearchForSelectVendor, setStateTableSearchForSelectVendor] =
    useState(false);

  // table pagination fns
  const handleChangePage = (event, newPage) => {
    setPageForSelectVendor(newPage);
    setStateForPageForSelectVendor(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageForSelectVendor(+event.target.value);
    setPageForSelectVendor(0);
    setStateForRowPerPageForSelectVendor(true);
  };

  const searchHandler = (event) => {
    setTableSearchForSelectVendor(event.target.value);
    setRowsPerPageForSelectVendor(25);
    setPageForSelectVendor(0);
    setStateTableSearchForSelectVendor(true);
  };

  useEffect(() => {
    if (stateTableSearchForSelectVendor) {
      getApiForVendorInvoice(tableSearchForSelectVendor);
    }
    setStateTableSearchForSelectVendor(false);
  }, [tableSearchForSelectVendor]);

  useEffect(() => {
    if (stateForRowPerPageForSelectVendor) {
      getApiForVendorInvoice(tableSearchForSelectVendor);
    }
    setStateForRowPerPageForSelectVendor(false);
  }, [rowsPerPageForSelectVendor]);

  useEffect(() => {
    if (stateForPageForSelectVendor) {
      getApiForVendorInvoice(tableSearchForSelectVendor);
    }
    setStateForPageForSelectVendor(false);
  }, [pageForSelectVendor]);

  const getApiForVendorInvoice = (serachIn) => {
    const finalData = {
      user_id: localStorage.getItem('user_id'),
      number_of_rows_per_page: rowsPerPageForSelectVendor,
      page_number: pageForSelectVendor,
      search_text: serachIn,
      business_entity_id: localStorage.getItem('business_entity_id'),
    };
    toast.configure();
    axios
      .post(`/get_vendor_invoice_details`, finalData)
      .then((res) => {
        setGetDataForSelectInvoice(res.data.record);
        setRowCountForSelectVendor(res?.data?.row_count);
      })
      .catch((error) => {
        toast.error('Server Error Try After Some Time', { autoClose: 2000 });
      });
  };

  //------------- Calling Get Api For Select Invoice In Add Section ------
  const handleClickEventForAdd = () => {
    setModelOpenButton(true);
    getApiForVendorInvoice();
  };

  function addDebitAdvice(value) {
    let invoiceDateForAdd = value.invoiceDate;
    let invoiceDateDisplayForAdd = invoiceDateForAdd
      .split('-')
      .reverse()
      .join('-');
    const invoiceDateConvertingIntoStringForAdd =
      invoiceDateDisplayForAdd.toString();
    setAddFormData({
      ...addFormData,
      erpVendorCode: value.erpVendorCode,
      vendorGstin: value.vendorGstin,
      vendorPan: value.vendorPan,
      originalBillNumber: value.invoiceNumber,
      originalBillDate: invoiceDateConvertingIntoStringForAdd,
    });
    setModelCloseButton(true);
    setModelOpenButton(false);
  }

  function modelCloseButtons() {
    setModelCloseButton(true);
    setModelOpenButton(false);
  }

  const convertToDefaultEventPara = (name, date) => {
    const value = moment(date).format('YYYY-MM-DD');
    return {
      target: {
        name,
        value,
      },
    };
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setAddFormData({
      ...addFormData,
      [name]: value,
    });
  };

  const closeAddFormDrawers = () => {
    setAddFormData(addFormInitialData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    toast.configure();
    axios
      .post(`/vendor/vendordebitnote`, {
        entityGst: localStorage.getItem('gst'),
        record: addFormData,
      })
      .then((res) => {
        if (res.data.status === false) {
          let error1 = 'Error: ';
          Object.keys(res.data.record.details[0].errors_in).map(
            (value, key) => {
              error1 += res.data.record.details[0].errors_in[value];
            }
          );
          toast.error(error1, { autoClose: 2000 });
        } else {
          closeAddFormDrawer();
          updateTableAfterSubmit();
          getDebitNoteDetail();
          setAddFormData(addFormInitialData);
          toast.success('Vendor Debit Note Added Successfully', {
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error(error, { autoClose: 2000 });
      });
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelOpenButton}
        onHide={modelCloseButton}
        dialogClassName="modal-50w"
        className="designModel"
      >
        <Modal.Header>
          <Modal.Title>Select Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="searchbarForModel">
            <TextField
              id="outlined-basic"
              size="small"
              placeholder="Search...."
              onChange={searchHandler}
              fullWidth
              variant="outlined"
            />
          </div>
          <div className="table-div-main">
            <table className="table-div">
              <thead>
                <tr>
                  <th>Vendor Name</th>
                  <th>GSTIN</th>
                  <th>Invoice Number</th>
                  <th>Invoice Date</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {getDataForSelectInvoice.map((value, key) => {
                  return (
                    <tr>
                      <td>{value.vendorName}</td>
                      <td>{value.vendorGstin}</td>
                      <td>{value.invoiceNumber}</td>
                      <td>{value.invoiceDate}</td>
                      <td>
                        <AddCircleOutlineIcon
                          onClick={() => addDebitAdvice(value)}
                        />
                      </td>
                    </tr>
                  );
                })}
                {getDataForSelectInvoice.length === 0 && (
                  <tr>
                    <td
                      colSpan={5}
                      style={{ color: 'red', textAlign: 'center' }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
            component="div"
            count={rowCountForSelectVendor} //total row count
            rowsPerPage={rowsPerPageForSelectVendor}
            page={pageForSelectVendor}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size="small" variant="outlined" onClick={modelCloseButtons}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <div className="selectDebitButton">
          <Button
            variant="outlined"
            style={{ borderColor: 'blue' }}
            onClick={handleClickEventForAdd}
          >
            SELECT Vendor<sup style={{ color: 'red' }}>*</sup>{' '}
          </Button>
        </div>
        <ValidatorForm
          useRef="form"
          onSubmit={submitHandler}
          onError={(errors) => console.log(errors)}
        >
          <InputField
            inputType="text"
            textLabel="GSTIN"
            name="vendorGstin"
            requiredField={addFormData.erpVendorCode === '' ? '*' : ''}
            validator={
              addFormData.erpVendorCode === ''
                ? [
                  'required',
                  'matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z][0-9A-Z]{1}$',
                ]
                : [
                  'matchRegexp:^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z][0-9A-Z]{1}$',
                ]
            }
            errorMsg={
              addFormData.erpVendorCode === ''
                ? ['This field is required', 'GSTIN is not valid']
                : ['GSTIN is not valid']
            }
            value={addFormData.vendorGstin ?? ''}
            textOnchangeFunction={inputChangeHandler}
            inputProps={{ readOnly: true }}
          />

          <InputField
            inputType="text"
            textLabel="Vendor Code+"
            name="erpVendorCode"
            requiredField={addFormData.vendorGstin === '' ? '*' : ''}
            validator={addFormData.vendorGstin === '' ? ['required'] : ''}
            errorMsg={
              addFormData.vendorGstin === '' ? ['This field is required'] : ''
            }
            value={addFormData.erpVendorCode ?? ''}
            textOnchangeFunction={inputChangeHandler}
            inputProps={{ readOnly: true }}
          />
          <InputField
            inputType="text"
            textLabel="PAN"
            name="vendorPan"
            validator={['matchRegexp:[A-Z]{5}[0-9]{4}[A-Z]{1}']}
            errorMsg={['PAN is not valid']}
            value={addFormData.vendorPan ?? ''}
            textOnchangeFunction={inputChangeHandler}
            inputProps={{ readOnly: true }}
          />
          <InputField
            inputType="text"
            textLabel="Original Bill Number"
            name="originalBillNumber"
            requiredField="*"
            validator={['required']}
            errorMsg={['This field is required']}
            value={addFormData.originalBillNumber ?? ''}
            textOnchangeFunction={inputChangeHandler}
            inputProps={{ readOnly: true }}
          />

          <div style={{ marginTop: '20px' }}>
            <label>
              Original Bill Date
              <span style={{ color: 'red' }}>*</span>:
            </label>
            <DatePicker
              name="originalBillDate"
              value={addFormData.originalBillDate ?? ''}
              onChange={(date) =>
                inputChangeHandler(
                  convertToDefaultEventPara('originalBillDate', date)
                )
              }
              validators={['required']}
              errorMessages={['Date is required']}
              readOnly={true}
              inputProps={{ readOnly: true }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>Booking/recording date</label>
            <DatePicker
              name="bookingDate"
              // validators={["required"]}
              // errorMessage={["This field is required"]}
              value={addFormData.bookingDate}
              onChange={(date) =>
                inputChangeHandler(
                  convertToDefaultEventPara('bookingDate', date)
                )
              }
            />
          </div>
          <InputField
            inputType="text"
            textLabel="E Invoice"
            name="eInvoice"
            value={addFormData.eInvoice ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <InputField
            inputType="text"
            textLabel="Debit Note Number"
            name="debitNoteNumber"
            requiredField="*"
            validator={['required']}
            errorMsg={['This field is required']}
            value={addFormData.debitNoteNumber ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>
              Debit Note Date
              <span style={{ color: 'red' }}>*</span>:
            </label>
            <DatePicker
              name="debitNoteDate"
              value={addFormData.debitNoteDate}
              onChange={(date) =>
                inputChangeHandler(
                  convertToDefaultEventPara('debitNoteDate', date)
                )
              }
              validators={['required']}
              errorMessages={['Date is required']}
            />
          </div>
          <InputField
            inputType="text"
            textLabel="HSN/SAC Code"
            name="hsnSacCode"
            value={addFormData.hsnSacCode ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>
              ₹ Taxable Value<span style={{ color: 'red' }}>*</span>:
            </label>{' '}
            <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.taxableValue ? addFormData.taxableValue : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              required
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  taxableValue: parseFloat(value),
                });
              }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ Non Taxable Value:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={
                addFormData.nonTaxableValue ? addFormData.nonTaxableValue : ''
              }
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  nonTaxableValue: parseFloat(value),
                });
              }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ SGST:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.sgst ? addFormData.sgst : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  sgst: parseFloat(value),
                });
              }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ CGST:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.cgst ? addFormData.cgst : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  cgst: parseFloat(value),
                });
              }}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ IGST:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.igst ? addFormData.igst : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  igst: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ CESS:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.cess ? addFormData.cess : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  cess: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ Tax Amount:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.taxAmount ? addFormData.taxAmount : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  taxAmount: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <InputField
            inputType="text"
            textLabel="TCS Section"
            name="tcsSection"
            value={addFormData.tcsSection ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>₹ TCS Payable:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.tcsPayable ? addFormData.tcsPayable : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  tcsPayable: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>₹ Total Amount:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.totalAmount ? addFormData.totalAmount : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  totalAmount: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <InputField
            inputType="text"
            textLabel="TDS Section"
            name="tdsSection"
            value={addFormData.tdsSection ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>₹ TDS Payable:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.tdsPayable ? addFormData.tdsPayable : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  tdsPayable: parseFloat(value),
                });
              }}
            />{' '}
          </div>
          <InputField
            inputType="text"
            textLabel="Expense Type"
            name="expenseType"
            value={addFormData.expenseType ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <SelectField
            SelectLabel="Type of Input Tax Credit"
            choice_id="id"
            choice_name="taxCreditType"
            name="taxCreditType"
            value={addFormData.taxCreditType ?? ''}
            choices={[
              { id: 'Input', taxCreditType: 'Input' },
              { id: 'Input Services', taxCreditType: 'Input Services' },
              { id: 'Capital goods', taxCreditType: 'Capital goods' },
            ]}
            textOnchangeFunction={inputChangeHandler}
          />

          <SelectField
            SelectLabel="ITC Eligibility"
            choice_id="id"
            choice_name="itcEligibility"
            name="itcEligibility"
            value={addFormData.itcEligibility ?? ''}
            choices={[
              { id: 'Yes', itcEligibility: 'Yes' },
              { id: 'No', itcEligibility: 'No' },
              { id: 'Unknown', itcEligibility: 'Unknown' },
            ]}
            textOnchangeFunction={inputChangeHandler}
          />

          <SelectField
            SelectLabel="RCM Applicability"
            choice_id="id"
            choice_name="rcmApplicability"
            name="rcmApplicability"
            value={addFormData.rcmApplicability ?? ''}
            choices={[
              { id: 'Yes', rcmApplicability: 'Yes' },
              { id: 'No', rcmApplicability: 'No' },
              { id: 'Unknown', rcmApplicability: 'Unknown' },
            ]}
            textOnchangeFunction={inputChangeHandler}
          />

          <SelectField
            SelectLabel="Dispute"
            choice_id="id"
            choice_name="dispute"
            name="dispute"
            value={addFormData.dispute ?? ''}
            choices={[
              { id: 'Yes', dispute: 'Yes' },
              { id: 'No', dispute: 'No' },
            ]}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>₹ Dispute Value:</label> <br />
            <NumberFormat
              className="numberFormate"
              style={{ paddingLeft: '10.5px' }}
              value={addFormData.disputeValue ? addFormData.disputeValue : ''}
              thousandsGroupStyle="lakh"
              prefix="₹"
              decimalSeparator="."
              displayType="number"
              type="text"
              decimalScale={2}
              thousandSeparator={true}
              allowNegative={true}
              onValueChange={(values) => {
                const { value } = values;
                setAddFormData({
                  ...addFormData,
                  disputeValue: parseFloat(value),
                });
              }}
            />{' '}
          </div>

          <InputField
            inputType="text"
            textLabel="Debit Note Reason"
            name="debitNoteReason"
            value={addFormData.debitNoteReason ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <InputField
            inputType="text"
            textLabel="Bill of Entry Number"
            name="boeNumber"
            value={addFormData.boeNumber ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />

          <div style={{ marginTop: '20px' }}>
            <label>Bill of Entry Date:</label>
            <DatePicker
              name="boeDate"
              value={addFormData.boeDate}
              onChange={(date) =>
                inputChangeHandler(convertToDefaultEventPara('boeDate', date))
              }
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <label>Reference Date:</label>
            <DatePicker
              name="referenceDate"
              value={addFormData.referenceDate}
              onChange={(date) =>
                inputChangeHandler(
                  convertToDefaultEventPara('referenceDate', date)
                )
              }
            />
          </div>

          <InputField
            inputType="text"
            textLabel="Port Code"
            name="portCode"
            value={addFormData.portCode ?? ''}
            textOnchangeFunction={inputChangeHandler}
          />
          {/* submit and back button */}
          <div className="drawer-footer">
            <Button
              style={{ marginTop: '20px' }}
              variant="contained"
              size="small"
              type="submit"
            >
              Submit
            </Button>
            <Button
              style={{
                float: 'right',
                marginTop: '20px',
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                closeAddFormDrawer();
                closeAddFormDrawers();
              }}
            >
              cancel
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </>
  );
}

export default AddDebit;
