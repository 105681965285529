import React, { useEffect, useState } from "react";
import { Grid, Button, Drawer, TextField } from "@material-ui/core";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "../../../../../axios-order";
import Card from "../../../../../components/ui/Cards";
import VendorCreditNote from "../../../../../asserts/templates/vendor_credit_note.xlsx";
import AddCreditNoteForm from "./addCreditNote";
import EditForm from "./editForm";
import ShowCreditForm from "./showCreditNote";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./CreditNote.css";
import "react-toastify/dist/ReactToastify.css";
import { TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";

import ToggleSwitch from "../../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../../components/ui/RightDrawer";

function InvoiceMgmt(props) {
  const onCickOperation = true;
  const [tableData, setTableData] = useState([]);

  const [editData, setEditData] = useState({});
  const [showData, setShowData] = useState({});

  const [openAddFormDrawer, setOpenAddFormDrawer] = useState(false);
  const [openShowDrawer, setOpenShowDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const [uploadSection, setUploadSection] = useState(false);
  const [uploadResData, setUploadResData] = useState();
  const [uploadErrorMsg, setUploadErrorMsg] = useState();

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [tableSearch, setTableSearch] = useState("");

  const [stateForPage, setStateForPage] = useState(false);
  const [stateForRowPerPage, setStateForRowPerPage] = useState(false);
  const [stateTableSearch, setStateTableSearch] = useState(false);

  const [uploadHistoryTableData, setUploadHistoryTableData] = useState([]);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [loading, setLoading] = useState(true);

  const getCreditNoteDetails = (serachIn) => {
    const finalData = {
      user_id: localStorage.getItem("user_id"),
      number_of_rows_per_page: rowsPerPage,
      page_number: page,
      search_text: serachIn,
      business_entity_id: localStorage.getItem("business_entity_id"),
    };
    axios
      .post(`/get_vendor_credit_details`, finalData)
      .then((res) => {
        setTableData(res?.data?.record);
        setRowCount(res?.data?.row_count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // table pagination fns
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStateForPage(true);
    // getCreditNoteDetails();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setStateForRowPerPage(true);
    // getCreditNoteDetails();
  };

  // table serach
  const SearchInputHandler = (event) => {
    setTableSearch(event.target.value);
    setPage(0);
    setRowsPerPage(25);
    setStateTableSearch(true);
  };

  useEffect(() => {
    if (stateTableSearch) {
      getCreditNoteDetails(tableSearch);
    }
    setStateTableSearch(false);
  }, [tableSearch]);

  useEffect(() => {
    if (stateForRowPerPage) {
      getCreditNoteDetails(tableSearch);
    }
    setStateForRowPerPage(false);
  }, [rowsPerPage]);

  useEffect(() => {
    if (stateForPage) {
      getCreditNoteDetails(tableSearch);
    }
    setStateForPage(false);
  }, [page]);

  // upload history table data api fn call
  const getUploadHistoryDataApi = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${13}`
      )
      .then((res) => {
        setUploadHistoryTableData(res.data.record);
      })
      .catch((error) => {
        console.log(error);
        // alert("Unable to complete your request, Please retry!");
      });
  };

  useEffect(() => {
    getUploadHistoryDataApi();
    getCreditNoteDetails();
  }, []);

  const fileUploader = (event) => {
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendorcreditnote`, {
          file: file_value,
          org_code: localStorage.getItem("business_entity_id"),
        })
        .then((res) => {
          getUploadHistoryDataApi();
        });
    };
  };
  const closeUploadBoxWapper = () => {
    setUploadSection(false);
    getCreditNoteDetails();
  };

  const addFormHandler = () => {
    setOpenAddFormDrawer(true);
  };

  const editDataHandler = (item) => {
    delete item.vendorCode;
    delete item.orgCode;
    setEditData(item);
    setOpenEditDrawer(true);
  };

  const showDetailsHandler = (item) => {
    setShowData(item);
    setOpenShowDrawer(true);
  };

  const closeDrawer = () => {
    setOpenAddFormDrawer(false);
    setOpenEditDrawer(false);
    setOpenShowDrawer(false);
  };

  //excel download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendor_credit_note.xlsx`;

    axios
      .post("vendor/vendorcreditnotedownload", {
        data: tableData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((res) => {
        setUploadSection(true);
        var jsonData = res.data.records;

        if (res.data.records.type === "message") {
          setUploadResData();
          setUploadErrorMsg(res.data.records.message);
        } else {
          setUploadErrorMsg();
          getCreditNoteDetails();
          setUploadResData(jsonData);
        }
      })
      .catch((error) => { });
  };

  // refresh Upload History Table using refresh button
  const refreshUploadHistoryTable = () => {
    getUploadHistoryDataApi();
    getCreditNoteDetails();
  };

  const errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: uploadResData,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendorCreditNoteUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {(localStorage.getItem('is_uploads') !== "N") && <>
      <div>
        <ToggleSwitch
          onChange={() => setShowUploadHistoryTable(!showUploadHistoryTable)}
          value={showUploadHistoryTable}
          label={`Upload Credit Note`}
        />
      </div>

      {showUploadHistoryTable && (
        <BoxWrapper boxTitle="Upload History">
          <a
            href={VendorCreditNote}
            download={"vendor_credit_note.xlsx"}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <Button component="label" size="small" color="primary">
              <GetAppIcon style={{ fontSize: "20px" }} />
              DOWNLOAD TEMPLATE
            </Button>
          </a>

          <Button component="label" size="small" color="primary">
            <PublishIcon style={{ fontSize: "20px" }} />
            UPLOAD TEMPLATE
            <input
              accept=".xlsx"
              name="file"
              type="file"
              value=""
              hidden
              onChange={fileUploader}
            />
          </Button>

          <Button
            component="label"
            size="small"
            color="primary"
            style={{ float: "right" }}
            onClick={refreshUploadHistoryTable}
          >
            <RefreshIcon style={{ fontSize: "20px" }} />
            Refresh
          </Button>

          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>
                  <th>Last Upload Date & Time</th>
                  <th>Status</th>
                  <th>Upload Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadHistoryTableData.length > 0 &&
                  uploadHistoryTableData.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.upload_date}</td>
                        <td>{item.status}</td>
                        <td>{item.upload_type}</td>
                        <td>
                          {item.status === "In Progress" ? (
                            <RemoveRedEyeOutlinedIcon color="disabled" />
                          ) : item.status === "Uploaded" ? (
                            <RemoveRedEyeOutlinedIcon color="disabled" />
                          ) : (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button showIcon"
                              onClick={() => uploadReport(item.id)}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {uploadHistoryTableData.length === 0 && (
                  <tr>
                    <td
                      style={{ color: "red", textAlign: "center" }}
                      colspan={4}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      )}
      </>}

      {uploadSection && (
        <BoxWrapper
          boxTitle="Upload Details"
          cutIcon={uploadSection}
          closeBoxWapper={closeUploadBoxWapper}
          enableAddButton={
            uploadResData && uploadResData.records.summary.invalid_records > 0
              ? true
              : null
          }
          addButtonLabel="EXPORT"
          addButtonHandlerFn={errorLogExportHandlerForUpload}
        >
          {uploadErrorMsg && (
            <div style={{ marginBottom: "20px", color: "red" }}>
              {uploadErrorMsg}
            </div>
          )}

          {uploadResData && (
            <>
              <div>
                <Grid container spacing={3}>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#6f9eaf"
                      cardTitle="Total Records"
                      cardBody={uploadResData.records.summary.total_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#007965"
                      cardTitle="Successful Records"
                      cardBody={uploadResData.records.summary.valid_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#eb596e"
                      cardTitle="Error Records"
                      cardBody={uploadResData.records.summary.invalid_records}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="upload-details-table-section">
                <div className="tableResponisve">
                  {uploadResData.status === false && (
                    <table className="tableCss">
                      <thead>
                        <tr>
                          <th>GSTIN</th>
                          <th>Row #</th>
                          <th>Status</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploadResData.status === false &&
                          uploadResData.records.details.map((item, i, arr) => {
                            if (item.status === "Fail") {
                              return (
                                <tr>
                                  <td>{item.record.vendorGstin}</td>
                                  <td>{item.row}</td>
                                  <td>{item.status}</td>
                                  <td style={{ color: "red" }}>
                                    {Object.keys(item.errors_in).map(
                                      (v, i, ar) => {
                                        if (ar.length > 1) {
                                          return (
                                            <div>
                                              <span style={{ color: "black" }}>
                                                {v}
                                              </span>{" "}
                                              : {item.errors_in[v].join(",")}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              <span style={{ color: "black" }}>
                                                {v}
                                              </span>{" "}
                                              : {item.errors_in[v].join(",")}
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </>
          )}
        </BoxWrapper>
      )}

      {/* credit details table */}
      <BoxWrapper
        boxTitle="Credit Note Details"
        exportToExcelEnable={true}
        exportToExcelFn={exportToExcel}
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={SearchInputHandler}
      >
        {/* invoice details table */}
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Vendor Name</th>
                <th>Bill Number</th>
                <th>Bill Date</th>
                <th>Credit Note Number</th>
                <th>Gstin</th>
                <th>Pan</th>
                <th>Show</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, i, arr) => {
                return (
                  <tr>
                    <td>{item.vendorName}</td>
                    <td>{item.originalBillNumber}</td>
                    <td>{item.originalBillDate}</td>
                    <td>{item.creditNoteNumber}</td>
                    <td>{item.vendorGstin}</td>
                    <td>{item.vendorPan}</td>
                    {/* <td>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => editDataHandler(item)}
                        >
                          Edit
                        </Button>
                      </td> */}
                    <td>
                      <RemoveRedEyeOutlinedIcon
                        className="action-icon-button showIcon"
                        onClick={() => showDetailsHandler(item)}
                      />
                    </td>
                  </tr>
                );
              })}

              {tableData.length === 0 && loading === false && (
                <tr>
                  <td colSpan={8} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td
                    colSpan={8}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading...Please wait
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
          component="div"
          count={rowCount} //total row count
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </BoxWrapper>

      {/* add Form drawer */}
      <Drawer
        anchor="right"
        open={openAddFormDrawer}
        onClose={() => setOpenAddFormDrawer(false)}
        variant={onCickOperation === true ? "persistent" : ""}
      >
        <div className="Drawer-title">Add Credit Note</div>
        <div className="drawer-body">
          <AddCreditNoteForm
            updateTableAfterSubmit={getCreditNoteDetails}
            closeAddFormDrawer={closeDrawer}
          />
        </div>
      </Drawer>

      {/* edit drawer */}
      <Drawer
        anchor="right"
        open={openEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      >
        <div className="Drawer-title">Edit Credit</div>
        <div className="drawer-body">
          <EditForm
            editData={editData}
            updateTableAfterSubmit={getCreditNoteDetails}
            closeEditFormDrawer={closeDrawer}
          />
        </div>
      </Drawer>

      {/* show drawer */}
      <RightDrawer
        drawerName="Credit Note Details"
        open={openShowDrawer}
        onClose={() => setOpenShowDrawer(false)}
      >
        <ShowCreditForm showData={showData} closeDrawer={closeDrawer} />
      </RightDrawer>
    </div>
  );
}

export default InvoiceMgmt;
