import BoxWrapper  from './boxWrapper'
import React, { useEffect, useState } from 'react';
import axios from  '../../../../../axios-order';
import { useHistory } from 'react-router-dom';
//  import './layou.css'



function Trial() {
  const history = useHistory(); 
  const [reconId, setReconId] = useState('');
  const [isLoading,setIsLoading] = useState(true)
  const [book_data,setBookData] = useState('')
  const [b2b_data,setB2bData] = useState('')
  const [b2ba_data,setB2baData] = useState('') 
  const [cdnr_data,setcdnrData] = useState('')
  const [cdnra_data,setcdnraData] = useState('')
  const [recongstr_Data,setgstrreconData] = useState('')


  const [merge_data,setmergeData] = useState('') 
  const [rcbook_data,setrcbookData] = useState('')
  const [itc_data,setitcData] = useState('')
  const [rcm_data,setrcmreconData] = useState('')
  const [datestatus_data,setdatestatusData] = useState('')

  
  const [taxcalc_data,settaxcalcData] = useState('') 
  const [taxcalcstatus_data,settaxstatusData] = useState('')
  const [subctgy_data,setsubctgyData] = useState('')
  const [allstatus_data,setallstatusData] = useState('')
  const [dashinv_data,setdashinvData] = useState('')
  const [dashcrd_data,setdashcrdData] = useState('') 
  const [dashdbt_data,setdashdbtData] = useState('')
  const [reconcomp_data,setreconcompData] = useState('')
 
  

const fetchData = async (reconId) => {  // Remove the reconId parameter
  try {
  
    const response = await axios.get(`/gstr2b_running/${reconId}`);
    const responseData = JSON.parse(response.data);
    const bookDataValue = responseData.data.book_data ;
    const b2bDataValue = responseData.data.b2b_read ;
    const b2baDataValue = responseData.data.b2ba_read ;
    const cdnrDataValue = responseData.data.cdnr_read ;
    const cdnraDataValue = responseData.data.cdnra_read ;
    const reconDataValue = responseData.data.recon_gstr ;

    const mergeDataValue = responseData.data.merge_both ;
    const rcbookDataValue = responseData.data.recon_book ;
    const itcDataValue = responseData.data.itc_status ;
    const rcmDataValue = responseData.data.rcm_status ;
    const datestatusDataValue = responseData.data.date_status ;

    const taxcalcDataValue = responseData.data.tax_value_calculation ;
    const taxstatusDataValue = responseData.data.tax_value_status ;
    const subctgyDataValue = responseData.data.sub_ctgy_status ;
    const allstatusDataValue = responseData.data.all_status ;
    const dashinvDataValue = responseData.data.dashboard_invoice ;
    const dashcrdDataValue = responseData.data.dashboard_credit ;
    const dashdbtDataValue = responseData.data.dashboard_debit ;
    const reconcompDataValue = responseData.data.recon_complete;
     
    console.log(responseData);
    console.log("Test " + bookDataValue);


        const { book_data } = bookDataValue;
        setBookData(bookDataValue);
        setB2bData(b2bDataValue);
        setB2baData(b2baDataValue);
        setcdnrData(cdnrDataValue);
        setcdnraData(cdnraDataValue);
        setgstrreconData(reconDataValue);

        setmergeData(mergeDataValue);
        setrcbookData(rcbookDataValue);
        setitcData(itcDataValue);
        setrcmreconData(rcmDataValue);
        setdatestatusData(datestatusDataValue);

        settaxcalcData(taxcalcDataValue);
        settaxstatusData(taxstatusDataValue);
        setsubctgyData(subctgyDataValue);
        setallstatusData(allstatusDataValue);
        setdashinvData(dashinvDataValue);
        setdashcrdData(dashcrdDataValue);
        setdashdbtData(dashdbtDataValue);
        setreconcompData(reconcompDataValue);
        

  } catch (error) {
    console.error('Error:', error);
  }
};

useEffect(() => {
  const latestReconId = localStorage.getItem('latestReconId');
  console.log('Latest Recon ID from localStorage:', latestReconId);
  // Perform any necessary operations with the latestReconId
}, []);

const latestReconId = localStorage.getItem('latestReconId');

fetchData();  // Call the function without passing any arguments


  useEffect(() => {
    getApiForGstr2bRecon();
  }, []);

  const getApiForGstr2bRecon = () => {
    axios
      .post('/gstr2b_recon_summary', {
        org_gstin: localStorage.getItem('gst'),
      })
      .then((res) => {
        console.log(res);
        if (res.data.data && res.data.data.length > 0) {
          const reconId = res.data.data[0].recon_id;
          setReconId(reconId); // Set the reconId in state
          // fetchData(reconId);
          setIsLoading(false);
        } else {
          console.error('No recon_id found in the API response');
        }
      })
      .catch(() => setIsLoading(false));
  };
  
  const handleClick = () => {
    // Replace with the actual recon ID
    fetchData(reconId);
  };

  const back = () => {
    history.push('/Gstr2bReconciliation')
 };

  return (
    // <div>

    //   <p>Recon ID: {reconId}</p>

    
    // </div>
    
    <React.Fragment>
       <p> Recon ID: {latestReconId}</p>
      <BoxWrapper boxTitle="GSTR-2B  Recon Progress Status"     
     enableAddButton={true}
      addButtonLabel="Refresh"
      addButtonHandlerFn={handleClick}

      enableAddButton1={true}
           addButtonLabel1="Back"
           addButtonHandlerFn1={back}
        > 
        
     <div align="right">
     {/* { <button onClick={handleClick}>Refresh Status</button> } */}
       </div>
      <div className="responsive-table">
      <table className="custome-table" >
     
        <thead>
          <tr>
            <th style={{ width: "300px" }}>Serial No</th>
            <th style={{ width: "300px" }}>Recon</th>
            <th>Status</th>
          </tr>
          <tr> <td  style={{ width: "200px",height:"40px" }}>1</td> <td> Book Data Reading </td> <td>  {book_data === '2' ? "Completed" : ""} {book_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px",height:"40px"  }}>2</td> <td> B2B Data Reading </td> <td>  {b2b_data === '2' ? "Completed" : ""} {b2b_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px",height:"40px"  }}>3</td> <td> B2BA Reading </td> <td>  {b2ba_data === '2' ? "Completed" : ""} {b2ba_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px",height:"40px"  }}>4</td> <td> CDNR Data Reading </td> <td>  {cdnr_data === '2' ? "Completed" : ""} {cdnr_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px",height:"40px"  }}>5</td> <td> CDNRA GSTR </td> <td>  {cdnra_data=== '2' ? "Completed" : ""} {cdnra_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px",height:"40px"  }}>6</td> <td> Recon GSTR </td> <td>  {recongstr_Data=== '2' ? "Completed" : ""} {recongstr_Data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>7</td> <td> Merging Matched Records  </td> <td>  {merge_data === '2' ? "Completed" : ""} {merge_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>8</td> <td> Recon Book Data </td> <td>  {rcbook_data === '2' ? "Completed" : ""} {rcbook_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>9</td> <td> ITC Status </td> <td>  {itc_data=== '2' ? "Completed" : ""} {itc_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>10</td> <td> RCM Status  </td> <td>  {rcm_data === '2' ? "Completed" : ""} {rcm_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>11</td> <td> Date Status  </td> <td>  {datestatus_data=== '2' ? "Completed" : ""} {datestatus_data=== '1' ? "In Progress" :""} </td></tr>
         

          <tr> <td  style={{ width: "200px" }}>12</td> <td> Tax Difference Calculation </td> <td>  {taxcalc_data === '2' ? "Completed" : ""} {taxcalc_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>13</td> <td> Tax Status  </td> <td>  {taxcalcstatus_data=== '2' ? "Completed" : ""} {taxcalcstatus_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>14</td> <td> Reasons </td> <td>  { subctgy_data=== '2' ? "Completed" : ""} {subctgy_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>15</td> <td> All Status  Updating</td> <td>  {allstatus_data === '2' ? "Completed" : ""} {allstatus_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>16</td> <td> Dashboard Invoice Calculation </td> <td>  {dashinv_data === '2' ? "Completed" : ""} {dashinv_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>17</td> <td>Dashboard Credit Note  </td> <td>  {dashcrd_data=== '2' ? "Completed" : ""} {dashcrd_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>18</td> <td> Dashboard Debit  Note </td> <td>  {dashdbt_data === '2' ? "Completed" : ""} {dashdbt_data === '1' ? "In Progress" :""} </td></tr>
          <tr> <td  style={{ width: "200px" }}>19</td> <td> Final Recon Completion  </td> <td>  {reconcomp_data=== '2' ? "Completed" : ""} {reconcomp_data=== '1' ? "In Progress" :""} </td></tr>
         

            
       
    </thead>
      </table>

      </div>
  
      </BoxWrapper>
      

    </React.Fragment>
  );
}

export default Trial;










