import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import classes from "./GSTReconciliation.module.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

function AllInvoiceRecon(props) {
  const { AllInvoiceReconData } = props;
  const [allInvoiceReconData, setAllInvoiceReconData] =
    useState(AllInvoiceReconData);

  const globalSearch = (e) => {
    let searcData = e.target.value;
    let searchData = AllInvoiceReconData.filter((value) => {
      const query = searcData.toLowerCase();
      return (
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_name).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_date).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_type).toLowerCase().indexOf(query) >= 0
      );
    });
    setAllInvoiceReconData(searchData);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const AllInvoiceReconTableBody = () => {
    if (allInvoiceReconData.length >= 1) {
      return allInvoiceReconData.map((tableData, index) => {
        const {
          cess_diff,
          cgst_diff,
          cust_gstin,
          cust_name,
          cws_cess,
          cws_cgst,
          cws_igst,
          cws_sgst,
          cws_taxable_value,
          cws_total_amount,
          gstr_cess,
          gstr_cgst,
          gstr_igst,
          gstr_sgst,
          gstr_taxable_value,
          gstr_total_amount,
          igst_diff,
          invoice_date,
          invoice_no,
          invoice_type,
          remarks,
          sgst_diff,
          status,
          taxable_value_diff,
          total_amount_diff,
        } = tableData;
        return (
          <tr>
            <td>{cust_gstin}</td>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{invoice_type}</td>
            <td>{numberFormat(cws_taxable_value)}</td>
            <td>{numberFormat(cws_cgst)}</td>
            <td>{numberFormat(cws_sgst)}</td>
            <td>{numberFormat(cws_igst)}</td>
            <td>{numberFormat(cws_cess)}</td>
            <td>{numberFormat(cws_total_amount)}</td>

            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_cgst)}</td>
            <td>{numberFormat(gstr_sgst)}</td>
            <td>{numberFormat(gstr_igst)}</td>
            <td>{numberFormat(gstr_cess)}</td>
            <td>{numberFormat(gstr_total_amount)}</td>

            <td>{numberFormat(taxable_value_diff)}</td>
            <td>{numberFormat(cgst_diff)}</td>
            <td>{numberFormat(sgst_diff)}</td>
            <td>{numberFormat(igst_diff)}</td>
            <td>{numberFormat(cess_diff)}</td>
            <td>{numberFormat(total_amount_diff)}</td>
            <td>{status}</td>
            <td>{remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status : All Invoice(s) Reconciliation Report"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={(e) => globalSearch(e)}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.AllInvoiceReconTableCss}>
            <tr>
              <th className={classes.ThInner} rowSpan={2}>
                GSTIN
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                CUSTOMER NAME
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                INVOICE/CN/DN/NUMBER
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                DATE
              </th>
              <th className={classes.ThInner} rowSpan={2} align="right">
                TYPE
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ffffb3" }}
                colSpan={6}
                align="center"
              >
                As per Book
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#5cd65c" }}
                colSpan={6}
                align="center"
              >
                As per GSTR1
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ff8533" }}
                colSpan={6}
                align="center"
              >
                Difference
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ffc107" }}
                rowSpan={2}
              >
                Remarks 1
              </th>
              <th
                className={classes.ThInner}
                style={{ background: "#ffc107" }}
                rowSpan={2}
              >
                Remarks 2
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                SGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                IGST
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                CESS
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {AllInvoiceReconTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default AllInvoiceRecon;
