import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "5px",
    borderRadius: "5px",
    boxShadow: "0px 1px 2px 0px black",
  },
  tilte: {
    color: "#ddd",
  },
  body: {
    color: "#fff",
  },
});

export default function Card(props) {
  const classes = useStyles();
  return (
    <div className={classes.card} style={{ background: props.backgroundcolor }}>
      <div className={classes.tilte}>
        <p style={{ fontSize: "14px" }}>{props.cardTitle}</p>
      </div>
      <div className={classes.body}>
        <h5 variant="h6">{props.cardBody}</h5>
      </div>
    </div>
  );
}
