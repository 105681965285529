import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import moment from "moment";

function ZohoShowDebit(props) {
  const { showData, closeDrawer } = props;
  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <div>
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="ERP Vendor Code+"
        margin="normal"
        defaultValue={showData.erpVendorCode}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="PAN"
        margin="normal"
        defaultValue={showData.vendorPan}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="E Invoice"
        margin="normal"
        defaultValue={showData.eInvoice}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TCS Payable"
        margin="normal"
        defaultValue={showData.tcsPayable}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Booking date"
        margin="dense"
        defaultValue={
          showData.bookingDate
            ? moment(showData.bookingDate, "YYYY-MM-DD").format("DD-MM-YYYY")
            : showData.bookingDate
        }
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="HSN/SAC Code"
        margin="normal"
        defaultValue={showData.hsnSacCode}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Taxable Value"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.taxableValue)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Non Taxable Value"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.nonTaxableValue)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="SGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.sgst)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="CGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.cgst)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="IGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.igst)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Cess"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.cess)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Tax Amount"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.taxAmount)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TCS Section"
        margin="normal"
        defaultValue={showData.tcsSection}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Total Amount"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.totalAmount)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TDS Section"
        margin="normal"
        defaultValue={showData.tdsSection}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TDS Payable"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.tdsPayable)}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Expense Type"
        margin="normal"
        defaultValue={showData.expenseType}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Type of Input Tax Cedit"
        margin="normal"
        defaultValue={showData.taxCreditType}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="ITC Eligibility"
        margin="normal"
        defaultValue={showData.itcEligibility}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="RCM Applicability"
        margin="normal"
        defaultValue={showData.rcmApplicability}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Dispute"
        margin="normal"
        defaultValue={showData.dispute}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Dispute Value"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.disputeValue)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Debit Note Reason"
        margin="normal"
        defaultValue={showData.debitNoteReason}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Bill of Entry Number"
        margin="normal"
        defaultValue={showData.boeNumber}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Bill of Entry Date"
        margin="dense"
        defaultValue={showData.boeDate}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Reference Date"
        margin="dense"
        defaultValue={showData.referenceDate}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Port Code"
        margin="normal"
        defaultValue={showData.portCode}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Fetrack"
        margin="normal"
        defaultValue={showData.fetrack}
      />

      {/* submit and back button */}
      <button
        type="button"
        className="button-outlined-secondary mt-20"
        onClick={closeDrawer}
      >
        cancel
      </button>
    </div>
  );
}

export default ZohoShowDebit;
