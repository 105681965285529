// @flow
import React, { useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import NumberFormat from "react-number-format";
import axios from "../../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./tdsPayables.module.css";
// import classes from './tdsPaybles.module.css';

export default function Add(props) {
  const {
    cancelEditForm,
    editFormData,
    updateTable,
    updateMasterTable,
    updateReversalOfProvision,
  } = props;
  // const form = useRef("form")
  const [editTdsData, setEditTdsData] = useState({});
  const editInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditTdsData({
      ...editTdsData,
      [name]: value,
    });
  };

  useMemo(() => {
    setEditTdsData(editFormData);
  }, [editFormData]);

  const handleProvisionCreateDate = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setEditTdsData({
        ...editTdsData,
        provision_create_date: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      });
    }
  };

  const finalAddData = {
    org_code: localStorage.getItem("business_entity_id"),
    records: [editTdsData],
  };

  const editFormsubmitHandler = (e) => {
    toast.configure();
    axios
      .put("/tds/provision_vendor/edit", finalAddData)
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, { autoClose: 2000 });
          updateMasterTable();
          updateTable();
          cancelEditForm();
          updateReversalOfProvision();
          editTdsData({});
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ValidatorForm
        useRef="form"
        onSubmit={editFormsubmitHandler}
        onError={(errors) => console.log(errors)}
      >
        <InputField
          textLabel="Vendor Legal Name"
          inputType="text"
          name="vendor_legal_name"
          value={editTdsData.vendor_legal_name ?? ""}
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          inputProps={{ readOnly: true }}
        />

        <InputField
          textLabel="PAN"
          inputType="text"
          name="pan"
          value={editTdsData.pan}
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          textOnchangeFunction={editInputChangeHandler}
          inputProps={{ readOnly: true }}
        />

        <div style={{ marginTop: "20px" }}>
          <label>
            Provision Create Date<span style={{ color: "red" }}>*</span>:
          </label>
          <ValidatedDatePicker
            name="provision_create_date"
            value={editTdsData.provision_create_date ?? ""}
            onChange={(v) => handleProvisionCreateDate(v)}
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>
            ₹Amount<span style={{ color: "red" }}>*</span>:
          </label>{" "}
          <br />
          <NumberFormat
            className={classes.numberFormate}
            style={{ paddingLeft: "10.5px" }}
            value={editTdsData.amount ?? ""}
            thousandsGroupStyle="lakh"
            prefix="₹"
            required
            decimalSeparator="."
            displayType="number"
            type="text"
            decimalScale={2}
            thousandSeparator={true}
            allowNegative={true}
            onValueChange={(values) => {
              const { value } = values;
              setEditTdsData({
                ...editTdsData,
                amount: value,
              });
            }}
          />{" "}
        </div>

        <InputField
          textLabel="Section"
          inputType="text"
          name="tds_section"
          value={editTdsData.tds_section ?? ""}
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          textOnchangeFunction={editInputChangeHandler}
          inputProps={{ readOnly: true }}
        />

        <InputField
          textLabel="TDS %"
          inputType="text"
          name="tds_percentage"
          value={editTdsData.tds_percentage ?? ""}
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          textOnchangeFunction={editInputChangeHandler}
        />

        <div style={{ marginTop: "20px" }}>
          <label>
            ₹ Tds Payable<span style={{ color: "red" }}>*</span>:
          </label>{" "}
          <br />
          <NumberFormat
            className={classes.numberFormate}
            style={{ paddingLeft: "10.5px" }}
            value={editTdsData.tds_payable ?? ""}
            thousandsGroupStyle="lakh"
            prefix="₹"
            required
            decimalSeparator="."
            displayType="number"
            type="text"
            decimalScale={2}
            thousandSeparator={true}
            allowNegative={true}
            onValueChange={(values) => {
              const { value } = values;
              setEditTdsData({
                ...editTdsData,
                tds_payable: value,
              });
            }}
          />{" "}
        </div>

        {/* .......Button........ */}
        <div className="mt-20">
          <button className="button-primary" type="submit">
            Submit
          </button>

          <button
            type="button"
            className="button-outlined-secondary"
            onClick={cancelEditForm}
          >
            cancel
          </button>
        </div>
      </ValidatorForm>
    </div>
  );
}
