// @flow
import React from "react";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../../components/ui/textFieldWithoutLabel";

export default function TabForm(Props) {
  // const form = useRef("form")
  const { data, handleInputChange } = Props;

  return (
    <>
      <ValidatorForm useRef="form" onError={(errors) => console.log(errors)}>
        <div>
          <Grid container spacing={2}>
            {/* 1st row */}
            <Grid item xs={3}>
              <p></p>
            </Grid>

            <Grid item xs={3}>
              <p>Latest Year</p>
            </Grid>

            <Grid item xs={3}>
              <p>Previous Year</p>
            </Grid>

            <Grid item xs={3}>
              <p>Previous-to-Previous year</p>
            </Grid>

            {/* 2nd row */}
            <Grid item xs={3}>
              <p>
                Score<span style={{ color: "red" }}>*</span>
              </p>
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="score_latest"
                value={data && data.score_latest}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="score_previous"
                value={data.score_previous}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="score_previous_to"
                value={data.score_previous_to}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            {/* 3rd row */}
            <Grid item xs={3}>
              <p>
                Minimum/Maximum Fixation Criteria
                <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="latest_fixation_criterion"
                value={data.latest_fixation_criterion}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="previous_fixation_criterion"
                value={data.previous_fixation_criterion}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="previous_to_fixation_criterion"
                value={data.previous_to_fixation_criterion}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>

            {/* 4th row */}
            <Grid item xs={3}>
              <p>
                Allocated Percentage (Across Must Be 100)
                <span style={{ color: "red" }}>*</span>
              </p>
            </Grid>

            <Grid item xs={3}>
              <InputField
                inputType="number"
                name="allocated_percentage"
                value={data.allocated_percentage}
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                textOnchangeFunction={handleInputChange}
              />
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>
        </div>
      </ValidatorForm>
    </>
  );
}
