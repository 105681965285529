import React, { useState, useEffect } from "react";
import "../TDSRecon.css";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import DashboardBarChart from "./dashboardBarChart";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MYParticulars(props) {
  const { paticulars, RevenueReportData } = props;
  const [revenueReport, setrevenueReport] = useState(RevenueReportData);

  useEffect(() => {
    setrevenueReport(RevenueReportData);
  }, [RevenueReportData]);
  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  let chartCat = ["TDS"];
  {
    paticulars.yearWiseData.map((item) => {
      chartCat.push(`FY ${item.financial_year}`);
    });
  }

  let chartData = [];
  let obj1 = {
    name: "Total TDS As Per Books",
    data: [paticulars.tds.total_tds_asper_books],
  };

  let obj2 = {
    name: "Total TDS As Per 26AS",
    data: [paticulars.tds.total_tds_asper_26As],
  };

  let obj3 = {
    name: "Difference",
    data: [paticulars.tds.difference],
  };

  paticulars.yearWiseData.map((item) => {
    const { total_tds_asper_books, total_tds_asper_26As, difference } = item;
    obj1.data.push(total_tds_asper_books);
    obj2.data.push(total_tds_asper_26As);
    obj3.data.push(difference);
  });
  chartData.push(obj1);
  chartData.push(obj2);
  chartData.push(obj3);

  console.log(revenueReport);

  return (
    <BoxWrapper boxTitle="TDS Reconciliation Summary">
      <div>
        <DashboardBarChart
          chartTitle=""
          chartCategories={chartCat}
          yAxisTitle="Amount"
          chartData={chartData}
        />
      </div>
      <div>
        <table className="custome-table">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>TDS</th>
              {paticulars.yearWiseData.map((item) => {
                return <th>FY {item.financial_year}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total TDS As Per Books</td>
              <td>
                {indianRupeesFormat(paticulars.tds.total_tds_asper_books)}
              </td>
              {paticulars.yearWiseData.map((item) => {
                return (
                  <td> {indianRupeesFormat(item.total_tds_asper_books)}</td>
                );
              })}
            </tr>
            <tr>
              <td>Total TDS As Per 26AS</td>
              <td>{indianRupeesFormat(paticulars.tds.total_tds_asper_26As)}</td>
              {paticulars.yearWiseData.map((item) => {
                return (
                  <td> {indianRupeesFormat(item.total_tds_asper_26As)}</td>
                );
              })}
            </tr>
            <tr>
              <td>Difference</td>
              <td>{indianRupeesFormat(paticulars.tds.difference)}</td>
              {paticulars.yearWiseData.map((item) => {
                return <td> {indianRupeesFormat(item.difference)}</td>;
              })}
            </tr>
          </tbody>
        </table>
        {revenueReport.length !== 0 && (
          <div style={{ marginTop: "10px" }}>
            <table className="custome-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Period</th>
                  <th colSpan={3}>As Per Books</th>
                  <th colSpan={2}>As Per 26AS</th>
                  <th colSpan={2}>Difference</th>
                </tr>
                <tr>
                  <th>Taxable Value</th>
                  <th>Non-Taxable Value</th>
                  <th>TDS Value</th>
                  <th>Total Amount Paid/Credited</th>
                  <th>TDS Deposited</th>
                  <th>Taxable Amount</th>
                  <th>TDS</th>
                </tr>
              </thead>
              <tbody>
                {revenueReport?.map((v) => {
                  if (v.financial_year != null) {
                    return (
                      <tr>
                        <td>{v?.financial_year}</td>
                        <td>
                          <span
                            style={{
                              color: v?.taxable_value_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.taxable_value_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color:
                                v?.non_taxable_value_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.non_taxable_value_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: v?.tds_amount_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.tds_amount_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: v?.amount_paid_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.amount_paid_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: v?.tax_deducted_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.tax_deducted_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              color: v?.amount_sum < 0 ? "red" : "black",
                            }}
                          >
                            {indianRupeesFormat(v?.amount_sum)}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{ color: v?.tds_sum < 0 ? "red" : "black" }}
                          >
                            {indianRupeesFormat(v?.tds_sum)}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </BoxWrapper>
  );
}

export default MYParticulars;
