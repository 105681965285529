import React, { Component } from "react";
import classes from "./InvoiceFinancing.module.css";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../../../components/ui/Table/Table";

import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

const dateFormat = (value) => {
  // if (typeof value === "undefined") return false;
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};
class InvoiceFinancing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataCol: [
        { key: "cust_legal_name" },
        { key: "cust_gstin" },
        { key: "invoice_no" },
        {
          key: "invoice_date",
          render: (index, row) => {
            return dateFormat(row["invoice_date"]);
          },
        },
        {
          key: "due_date",
          render: (index, row) => {
            return dateFormat(row["due_date"]);
          },
        },
        { key: "taxable_amount" },
        { key: "tax_amount" },
        { key: "total_amount" },
        {
          key: "request_amount",
          render: (index, row) => {
            return (
              <TextField
                name={index}
                disabled={row["is_financed"] === 0 ? false : true}
                value={
                  row["request_amount"]
                    ? this.numberFormat(row["request_amount"])
                    : this.numberFormat(row["total_amount"])
                }
                onChange={this.handleRequestAmountChange}
              />
            );
          },
        },
        {
          key: "status",
          render: (index, row) => {
            return row["status"] === "" ? (
              <CircularProgress size={25} />
            ) : (
              // <this.GetStatus index={index} row={row} />
              this.GetStatus({ index: index, row: row })
            );
          },
        },
      ],
      data: null,
      oldData: null,
      dataHeader: [
        { label: "Customer Legal Name" },
        { label: "Customer GSTIN" },
        { label: "Invoice No" },
        { label: "Invoice Date" },
        { label: "Due Date" },
        { label: "Taxable Amount" },
        { label: "GST Amount" },
        { label: "Total Amount" },
        { label: "Request Amount" },
        { label: "Status" },
      ],
      searchInput: "",
      creditLimit: null,
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  handleRequestAmountChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;
    let data = this.state.data;
    data[name]["request_amount"] = parseFloat(numb);
    this.setState((prevState) => ({
      data,
    }));
  };

  updateStatus = (index, invoice_id) => {
    let data = this.state.data;
    data[index]["status"] = "";
    this.setState({
      data,
    });
    axios
      .get(
        "/bl/get_invoice_financing_status/" +
          localStorage.getItem("business_entity_id") +
          "/" +
          invoice_id
      )
      .then((res) => {
        if (res.data.status) {
          let status = res.data.data.status;
          if (String(status).toLowerCase() === "approved") {
            this.getCustomers();
          } else {
            data[index]["status"] = status;
            this.setState({
              data,
            });
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error, { autoClose: 5000 });
      });
  };

  GetStatus = (props) => {
    if (props.row["is_financed"] === 0) {
      return "";
    } else if (String(props.row["status"]).toLowerCase() === "rejected") {
      return (
        <Button
          onClick={() => {
            this.updateStatus(props.index, props.row["invoice_id"]);
          }}
        >
          <Alert severity="error">
            {String(props.row["status"]).trim().toLowerCase() === "discounted"
              ? "Submitted for Financing"
              : props.row["status"]}
            <RefreshIcon />
          </Alert>
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            this.updateStatus(props.index, props.row["invoice_id"]);
          }}
        >
          <Alert severity="info">
            {String(props.row["status"]).trim().toLowerCase() === "discounted"
              ? "Submitted for Financing"
              : props.row["status"]}
            <RefreshIcon />
          </Alert>
        </Button>
      );
    }
  };

  getCustomers() {
    this.setState({
      data: null,
    });
    axios
      .get(
        "/bl/get_authorized_invoices/" +
          localStorage.getItem("business_entity_id")
      )
      .then((res) => {
        this.setState({
          data: res.data.data,
          oldData: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error, { autoClose: 5000 });
      });
  }

  getCreditLimit() {
    axios
      .get(
        "/bl/get_credit_details/" + localStorage.getItem("business_entity_id")
      )
      .then((res) => {
        this.setState({
          creditLimit: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error, { autoClose: 5000 });
      });
  }

  componentDidMount() {
    this.getCustomers();
    this.getCreditLimit();
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldData.length) {
        let dataNew = this.state.oldData.filter((value) => {
          return (
            String(value.invoice_no).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.cust_legal_name).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.cust_gstin).toLowerCase().indexOf(search_value) >= 0 ||
            String(value.status).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          data: dataNew,
        });
      }
    } else {
      this.setState({
        data: this.state.oldData,
      });
    }
  };

  onSubmitHandler() {
    let selected_ids = {};
    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );

    Object.keys(selected_input).map((v, i) => {
     return selected_ids[this.state.data[selected_input[v].value]["invoice_id"]] =
        this.state.data[selected_input[v].value]["request_amount"]
          ? this.state.data[selected_input[v].value]["request_amount"]
          : this.state.data[selected_input[v].value]["total_amount"];
    });
    this.setState({
      data: null,
    });
    axios
      .post("/bl/post_invoice_financing", {
        invoices: selected_ids,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status) {
          this.getCustomers();
        }
      })
      .catch((error) => {
        this.getCustomers();
      });
  }

  render() {
    return this.state.data === null ? (
      <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , marginTop:"100px"}}>
        <div><CircularProgress size={40} /></div>
        <div>Loading Please Wait</div>
      </div>
    ) : (
      <React.Fragment>
        <ValidatorForm
          ref="form"
          onSubmit={this.onSubmitHandler}
          onError={(errors) => {
            console.log(errors);
          }}
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.state.creditLimit ? (
                  <div className={classes.loanData}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        Sanctioned Credit Limit<br></br>
                        {this.state.creditLimit.totalCredit}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Consumed Credit<br></br>
                        {this.state.creditLimit.consumedCredit}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        Finance Sanctioned<br></br>
                        {this.state.creditLimit.financeRequested}
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </div>

          {/* table */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.Main_frame}>
                <div className={classes.form_title_bar}>
                  <div class="form_title_name">
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: "0px",
                        padding: "0px",
                        marginTop: "0px",
                      }}
                    >
                      <li style={{ display: "inline-block" }}>
                        Invoice Financing
                      </li>
                      <li
                        style={{ display: "inline-block", float: "right" }}
                      ></li>
                    </ul>
                  </div>
                </div>
                <div className={classes.form_body_part}>
                  <div className={classes.searchField}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <ValidatorForm
                          ref="form"
                          // onSubmit={this.onSubmitHandler}
                          // onError={(errors) => console.log(errors)}
                        >
                          <InputField
                            textLabel="Search"
                            inputType="text"
                            name="Search_by_customer_name"
                            value={this.state.searchInput ?? ""}
                            textOnchangeFunction={this.handleSearchInputChange}
                          />
                        </ValidatorForm>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.tableResponisve}>
                    <CustomTable
                      headers={this.state.dataHeader}
                      columns={this.state.dataCol}
                      data={this.state.data}
                      multiselect={true}
                      // multiselectkey="invoice_id"
                      name="invoices"
                      selectCondition={(item) => item.is_financed === 0}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
      </React.Fragment>
    );
  }
}

export default InvoiceFinancing;
