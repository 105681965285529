import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import moment from "moment";

function ZohoShow(props) {
  const { showData, closeDrawer } = props;
  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  return (
    <div>
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="ERP Vendor Code+"
        margin="normal"
        defaultValue={showData.erpVendorCode}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="PAN"
        margin="normal"
        defaultValue={showData.vendorPan}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="GST Tax %"
        margin="normal"
        defaultValue={showData.GSTTaxRate}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="E-Invoice"
        margin="normal"
        defaultValue={showData.eInvoice}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Due Date"
        margin="normal"
        defaultValue={
          showData.dueDate
            ? moment(showData.dueDate, "YYYY-MM-DD").format("DD-MM-YYYY")
            : showData.dueDate
        }
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="HSN/SAC Code"
        margin="normal"
        defaultValue={showData.hsnSacCode}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Taxable Value"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.taxableValue)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Non Taxable Value"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.nonTaxableValue)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="SGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.sgst)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="CGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.cgst)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="IGST"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.igst)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="CESS"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.cess)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Tax Amount"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.taxAmount)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TCS Section"
        margin="normal"
        defaultValue={showData.tcsSection}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TCS Payable"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.tcsPayable)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Total Amount"
        margin="normal"
        defaultValue={indianRupeesFormat(showData.totalAmount)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TDS Section"
        margin="normal"
        defaultValue={showData.tdsSection}
      />
      {/* ------------------------------------ */}
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="TDS Payables"
        margin="dense"
        defaultValue={indianRupeesFormat(showData.tdsPayable)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Expense Type"
        margin="dense"
        defaultValue={showData.expenseType}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Type of tax credit"
        margin="dense"
        defaultValue={showData.taxCreditType}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="ITC Eligibility"
        margin="dense"
        defaultValue={showData.itcEligibility}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="RCM Applicability"
        margin="dense"
        defaultValue={showData.rcmApplicability}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="PO Number"
        margin="dense"
        defaultValue={showData.poNumber}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="PO Date"
        margin="normal"
        defaultValue={
          showData.poDate
            ? moment(showData.poDate, "YYYY-MM-YYYY").format("DD-MM-YYYY")
            : showData.poDate
        }
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Dispute"
        margin="dense"
        defaultValue={showData.dispute}
      />
      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Dispute Value"
        margin="dense"
        defaultValue={indianRupeesFormat(showData.disputeValue)}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Bill of Entry Number"
        margin="normal"
        defaultValue={showData.boeNumber}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Bill of Entry Date"
        margin="normal"
        defaultValue={
          showData.boeDate
            ? moment(showData.boeDate, "YYYY-MM-DD").format("DD-MM-YYYY")
            : showData.boeDate
        }
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Reference Date"
        margin="normal"
        defaultValue={
          showData.referenceDate
            ? moment(showData.referenceDate, "YYYY-MM-DD").format("DD-MM-YYYY")
            : showData.referenceDate
        }
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Port Code"
        margin="normal"
        defaultValue={showData.portCode}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Comments"
        margin="normal"
        defaultValue={showData.comments}
      />

      <TextField
        inputProps={{ readOnly: true }}
        fullWidth
        label="Fetrack"
        margin="normal"
        defaultValue={showData.fetrack}
      />

      {/* submit and back button */}
      <div className="mt-20">
        <button className="button-outlined-secondary" onClick={closeDrawer}>
          cancel
        </button>
      </div>
    </div>
  );
}

export default ZohoShow;
