import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

const BasicTextFields = (props) => {
    return (
        <div>
            <TextValidator
                validators={props.validator}
                errorMessages={props.errorMsg}
                inputProps={props.inputProps ? props.inputProps : {}}
                value={props.value}
                name={props.name}
                type={props.inputType}
                placeholder={props.inputPlacholder}
                onChange={props.textOnchangeFunction}
                fullWidth="true"
                size="small"
                autoComplete="true"
                // size="small"
                variant="outlined"
                {...props}
            />
        </div>
    );
};

export default BasicTextFields;