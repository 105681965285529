import React from "react";
import Modal from "react-bootstrap/Modal";

const BasicModal = (props) => {
  const {
    open,
    modalSize,
    onClose,
    modalBody,
    title,
    modalFooter,
    enableFooter,
  } = props;
  return (
    <Modal
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={open}
      onHide={onClose}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title className="modal-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      {enableFooter ? (
        <Modal.Footer style={{ padding: "3px" }}>{modalFooter}</Modal.Footer>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default BasicModal;
