import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BoxWrapper from "../../components/ui/boxWrapper";
import "../MDM/mdm.css";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../../axios-order";
import moment from "moment";
import DatePicker from "../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";

const MSME = () => {
  const [mdmType, setMdmType] = useState("");
  const [tableData, setTableData] = useState([]);

  const [triggerAsOfDate, setTriggerAsOfDate] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [triggerDate, setTriggerDate] = useState(false);

  const mdmChangeHandler = (e) => {
    const { value } = e.target;
    setMdmType(value);
  };

  const handleClear = () => {
    setMdmType("");
    setTriggerAsOfDate(false);
    setFromDate(null);
    setToDate(null);
    setTriggerDate(false);
  };

  const mdmFormSubmitHandler = () => {
    toast.configure();

    let changeInDate = {};

    if (mdmType === "" ) {
      toast.error("MSME type is mandatory filed", {
        autoClose: 3000,
        progress: 0,
      });
    } else if(triggerDate === false && triggerAsOfDate === false ) {
      toast.error("Date are mandatory filed", {
        autoClose: 3000,
        progress: 0,
      });
    } else {

      if (triggerAsOfDate) {
        changeInDate = {
          as_of_date: true,
        };
      } else {
        changeInDate = {
          from_date: moment(fromDate).format("YYYY-MM-DD"),
          to_date: moment(toDate).format("YYYY-MM-DD"),
        };
      }

      axios
        .post(`/msme_recon/report`, {
          bid: localStorage.getItem("business_entity_id"),
          msme_type: mdmType,
          ...changeInDate,
        })
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.Message, {
              autoClose: 3000,
              progress: 0,
            });
            getApi();
            setMdmType("");
            changeInDate = {};
            setTriggerAsOfDate(false);
            setFromDate(null);
            setToDate(null);
            setTriggerDate(false);
          } else {
            toast.error(res?.data?.Message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        }).catch = (e) => {
        changeInDate = {};
        toast.error(e?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      };
    }
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadMDMHandler = (jsonData, msme_type) => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `MSME Summary`;

    axios
      .post(`msme/download`, {
        data: jsonData,
        bid: localStorage.getItem("business_entity_id"),
        cust_msme_flag: msme_type === "CUSTOMER" ? true : false,
      })
      .then((res) => {
        if (res?.data?.status === "fail") {
          alert(res?.data?.message);
        } else if (res?.data?.status === "pass") {
          downloadBase64File(contentType, res?.data?.file, filename);
        }
      }).catch = (e) => {
      alert("Unable To Download Please Try Again");
    };
  };

  const getApi = () => {
    axios.get(`/msme_log/${localStorage.getItem("business_entity_id")}`).then((res) => {
      if (res.data.status === true) {
        setTableData(res?.data?.records);
      } else {
        setTableData([]);
      }
    }).catch = (e) => {
      setTableData([]);
      alert(e.response.data.message);
    };
  };

  useEffect(() => {
    getApi();
  }, []);

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="mdmType"
          value={mdmType ?? ""}
          onChange={mdmChangeHandler}
        >
          <FormControlLabel
            value="customers"
            control={<Radio />}
            label="Customers MSME"
          />
          <FormControlLabel
            value="vendors"
            control={<Radio />}
            label="Vendors MSME"
          />
        </RadioGroup>
      </FormControl>

      {/* form */}

      <BoxWrapper boxTitle="Filter">
        <div className="mdm-form">
          <Grid container spacing={1}>
            <Grid item lg={3}>
              <label>As of Date:</label> <br />
              <button
                style={
                  triggerAsOfDate === false
                    ? {
                        width: "100%",
                        padding: "7px 15px",
                      }
                    : {
                        width: "90%",
                        background: "rgb(208, 70, 70)",
                        padding: "7px 15px",
                        color: "#fff",
                      }
                }
                className="button-outlined-primary"
                disabled={triggerDate === false ? false : true}
                onClick={() => setTriggerAsOfDate(!triggerAsOfDate)}
              >
                {triggerAsOfDate
                  ? "selected"
                  : triggerDate
                  ? "Disabled"
                  : "As of Date"}
              </button>
            </Grid>
            <Grid item lg={9}>
              <ValidatorForm
                useRef="form"
                // onSubmit={onSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                    <label>From Date:</label>
                    <DatePicker
                      placeholder={
                        triggerAsOfDate === false ? "From Date" : "Disabled"
                      }
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      disabled={triggerAsOfDate === false ? false : true}
                      value={fromDate ?? ""}
                      onChange={(v) => {
                        setFromDate(v);
                        setTriggerDate(true);
                      }}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <label>To Date:</label>
                    <DatePicker
                      placeholder={
                        triggerAsOfDate === false ? "To Date" : "Disabled"
                      }
                      errorMessages={["This field is required"]}
                      disabled={triggerAsOfDate === false ? false : true}
                      validators={["required"]}
                      value={toDate ?? ""}
                      onChange={(v) => {
                        setToDate(v);
                        setTriggerDate(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>

          <div>
            <button
              className="button-outlined-primary"
              onClick={mdmFormSubmitHandler}
              style={{ marginTop: "25px", marginLeft: "20px" }}
            >
              Submit
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button-outlined-secondary"
              style={{ marginTop: "25px", marginLeft: "10px" }}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
      </BoxWrapper>
      {/* history table */}
      <BoxWrapper
        boxTitle="MSME History"
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApi}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>     
              <tr>
                <th>MSME Type</th>
                <th>Created date & time</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.length > 0 &&
                tableData?.map((item, i) => {
                  return (
                    <tr key={`${i}mdm`}>
                      <td>{item?.msme_type}</td>
                      <td>
                        {moment(item?.created_date).format(
                          "DD/MM/YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td>{item?.status}</td>
                      <td>
                        {item?.status === "Pass" ? (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            onClick={() =>
                              downloadMDMHandler(
                                item?.msme_log?.inv_records,
                                item?.msme_type
                              )
                            }
                          />
                        ) : item?.status === "Fail" ? (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            style={{ color: "red" }}
                            onClick={() => alert(item?.error?.error)}
                          />
                        ) : (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            style={{ color: "#ddd" }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              {tableData?.length === 0 && (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center", color: "red" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
};

export default MSME;
