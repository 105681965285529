import React, { useState ,useEffect} from 'react';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Menu, MenuItem, Button } from "@material-ui/core";
import BoxWrapper from './boxWrapper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import { Snackbar, Alert } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from "../../axios-order";
import { FormControl, InputLabel, Select,  } from '@mui/material';
import {  IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Date from './Date'
import { ValidatorForm } from "react-material-ui-form-validator";
import ClearIcon from '@mui/icons-material/Clear';

import DownloadIcon from '@mui/icons-material/Download';
function Summary() {
    const [fromDateMonthYearDisplay, setFromDateMonthYearDisplay] = useState(null);
    const [toDateMonthYearDisplay, setToDateMonthYearDisplay] = useState(null);
    const [taskIds, setTaskIds] = useState([]);
    const [records, setRecords] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [invoiceIdToCancel, setInvoiceIdToCancel] = useState(null);
    const [reason, setReason] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('STATUS');
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const openStatus = Boolean(statusAnchorEl); 
     const [einvoiceData, setEinvoiceData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [filteredData,setFilteredData] = useState([])
     const [anotherloading, setAnotherLoading] = useState(false);
     const [searchInput, setSearchInput] = useState('');
      const [selectedDocumentType, setSelectedDocumentType] = useState('DOCUMENT TYPE');
      const [documentTypeAnchorEl, setDocumentTypeAnchorEl] = useState(null);
      const openDocumentType = Boolean(documentTypeAnchorEl);
       
    const [selectedCategory, setSelectedCategory] = useState('CATEGORY');
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const openCategory = Boolean(categoryAnchorEl);

   
    const [selectedRcm, setSelectedRcm] = useState('RCM');
    const [rcmAnchorEl, setRcmAnchorEl] = useState(null);
    const openRcm = Boolean(rcmAnchorEl);
    const [selectedRecords, setSelectedRecords] = useState([]);
   
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loadingIds, setLoadingIds] = useState([]);

    const handleAddButtonClick = async () => {
      if (selectedRecords.length === 0) {
          
          return;
      }

      const business_entity_id = localStorage.getItem('business_entity_id');
      const user_id = localStorage.getItem('user_id');
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      const gstin = localStorage.getItem('gstin');
      const status = "Generated"
      const irp_number = "vay"

      try {
        setLoading(true); 
          const response = await axios.post('/einvoice/post_gov_portal_details', {
              id: selectedRecords,
              business_entity_id,
              username,
              password,
              gstin,
              user_id,
              irp_number,
              status
          });
          
        // Use dynamic message from response for both success and failure cases
        setSnackbarMessage(response.data.message);
        
        if (response.data.status) {
            setSnackbarSeverity('success');
        } else {
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
          onSubmitHandler();
          // Handle success, e.g., show a success message or update the UI
      } catch (error) {
          console.error("Error submitting records:", error);    
          if (error.response && error.response.data && error.response.data.message) {
            setSnackbarMessage(error.response.data.message);
        } 

        setSnackbarSeverity('error');
        setSnackbarOpen(true);

          // Handle error, e.g., show an error message
      }
      finally {
        setLoading(false); // Set loading to false once the API call completes
    }
  };

    const handleSelectAll = (event) => {
      if (event.target.checked) {
         
          setSelectedRecords(records.map(record => record.id));
      } else {
       
          setSelectedRecords([]);
      }
  };

  const handleSelect = (id) => {
      setSelectedRecords(prevSelected => 
          prevSelected.includes(id)
              ? prevSelected.filter(recordId => recordId !== id)
              : [...prevSelected, id]
      );
  };

  const handleSecondButtonClick = () => {
   
    
    const business_entity_id = localStorage.getItem('business_entity_id');
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const gstin = localStorage.getItem('gstin');
    const irp_number = 'vay';
    setAnotherLoading(true); 
    axios
      .post('/einvoice/cancel_generated_einvoice', {
        id: selectedRecords,
        business_entity_id,
        username,
        password,
        gstin,
        irp_number,
        reason,
      })
      .then(response => {
        
        if (response.data && response.data.message) {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity('success');  // Show as success message
          setSnackbarOpen(true); // Show the Snackbar
          onSubmitHandler();
        }
      })
      .catch(error => {
        console.error('Error canceling invoice:', error);
        // Handle error here
        if (error.response && error.response.data && error.response.data.message) {
          setSnackbarMessage(error.response.data.message);
        } else {
          setSnackbarMessage("An unexpected error occurred.");
        }
        setSnackbarSeverity('error'); 
        setSnackbarOpen(true); // Show the Snackbar
        console.error('Error canceling invoices:', error);
      })
      .finally(() => {
        setAnotherLoading(false); // Stop loading
      });
     
  };
  

    const downloadBase64File = (contentType, base64Data, fileName) => {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    };

    useEffect(() => {
      const businessEntityId = localStorage.getItem('business_entity_id');
      const getEinvoiceData = () => {
        axios
          .get(`/einvoice/get_conn_details/${businessEntityId}/`)
          .then((res) => {
            const username = res.data.records[0]?.username || '';
            const password = res.data.records[0]?.password || '';
            const gstin = res.data.records[0]?.gstin || '';
            setEinvoiceData(res.data.records);
  
            // Save data to localStorage
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            localStorage.setItem('gstin', gstin);
          })
          .catch((error) => {
            setEinvoiceData([]);
            // toast.error(error?.response?.data?.message, {
            //   autoClose: 3000,
            //   progress: 0,
            // });
          });
      };
  
      getEinvoiceData(); // Call the function when the component mounts
    }, []); 

    const handleExportExcel = () => {
      const bid = localStorage.getItem('business_entity_id');
      const fileName = 'E-invoice Summary';
    
      if (bid) {
        const url = `/einvoice/download_einvoice_summary/${bid}`;
    
        axios.get(url)
          .then(response => { 
             
            // Assuming the response data is a Base64 encoded string
            const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const base64Data = response.data.file; // Adjust based on the actual response format
            downloadBase64File(contentType, base64Data, `${fileName}.xlsx`);
          })
          .catch(error => {
            console.error("Error exporting file:", error);
          });
      } else {
        console.error('Business entity ID not found in localStorage');
      }
    };

    const inputHandlerFromDatePicker = (date) => {
        setFromDateMonthYearDisplay(date);
    };

    const inputHandlerToDatePicker = (date) => {
        setToDateMonthYearDisplay(date);
    };

    const handleOpenAndCloseModel = () => {
        setFromDateMonthYearDisplay(null);
        setToDateMonthYearDisplay(null);
    };

    const onSubmitHandler = async () => {
        try {
          if (!fromDateMonthYearDisplay || !toDateMonthYearDisplay) {
            // toast.error("Please select both From Date and To Date");
            return;
          }
     const business_entity_id = localStorage.getItem('business_entity_id');
    //  moment(toDateAndMonthAndYear).format("YYYY-MM-DD");

          const payload = {
            from_date: moment(fromDateMonthYearDisplay).format("YYYY-MM-DD"),
            to_date: moment(toDateMonthYearDisplay).format("YYYY-MM-DD"),
            business_entity_id:business_entity_id
          };
    
          const response = await axios.post('/einvoice/get_tax_data_by_date', payload);
    
          if (response.status === 200 && response.data.status) {
            setRecords(response.data.records);
            setFilteredData(response.data.records)
         
            // toast.success('Data fetched successfully!');
           
          } else {
            throw new Error('Failed to fetch data');
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(`Error: ${error.response.data.message}`);
         
          } else {
            // toast.error(`Error fetching data: ${error.message}`);
          }
          // setSnackbarSeverity("error");
          // setSnackbarOpen(true);
        }
      };
    

    // Dropdown handlers
    const handleDropdownClick = (setter) => (event) => {
        setter(event.currentTarget);
    };

    const handleDropdownClose = (setter, updater) => (value) => {
        if (value) {
            updater(value);
        }
        setter(null);
    };
   
    
    const pushToIRN  = (id) => {
      setLoadingIds(prev => [...prev, id]);
      const business_entity_id = localStorage.getItem('business_entity_id');
      const user_id = localStorage.getItem('user_id')
     const status = "Generated";
     const  username =  localStorage.getItem('username');
     const password = localStorage.getItem('password');
     const gstin = localStorage.getItem('gstin')
  const irp_number = "vay"
      axios.post('/einvoice/post_gov_portal_details',{
        id:[id],
        business_entity_id,
        username,
        password,
        gstin,
        irp_number,
        user_id,
        status
      })
      .then(response => {
        setLoadingIds(prev => prev.filter(item => item !== id)); 
        if (response.data && response.data.records && response.data.records.task_id) {
          const message = response.data.message || 'E-Invoice generated successfully.';
            setTaskIds(prevTaskIds =>{
                
               const updatedTaskIds =   [...prevTaskIds, response.data.records.task_id];
             
               return updatedTaskIds;
              
            }); 
             setSnackbarMessage(message);
             setSnackbarSeverity('success');
            setSnackbarOpen(true);
            
        }
         onSubmitHandler();
      })
      .catch(error => {
        setLoadingIds(prev => prev.filter(item => item !== id)); 
     
         // If there is an error response from the server, display that message
         const errorMessage =
         error.response && error.response.data && error.response.data.message
           ? error.response.data.message
           : 'Error occurred while generating E-Invoice.';
 
       // Set the error message in the Snackbar
       setSnackbarMessage(errorMessage);
       setSnackbarSeverity('error');
       setSnackbarOpen(true);
      }      )
    }
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
    const handleCancelClose = () => {
      setOpenDialog(false);
      setReason(''); // Clear the reason input when dialog is closed
    };
  
  
    const cancelInvoice = (id) => {
      setInvoiceIdToCancel(id);
      setDialogMessage('Please Enter the Reason');
      setOpenDialog(true);
    };

    const handleCancelConfirm = () => {
      if (!reason.trim()) {
        alert('Please enter a reason for canceling the invoice.');
        return;
      }
  
      const business_entity_id = localStorage.getItem('business_entity_id');
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');
      const gstin = localStorage.getItem('gstin');
      const irp_number = 'vay';
  
      axios.post('/einvoice/cancel_generated_einvoice', {
        id: [invoiceIdToCancel],
        business_entity_id,
        username,
        password,
        gstin,
        irp_number,
        reason
      })
      .then((response) => {
        console.log('Invoice canceled successfully', response.data);
        setOpenDialog(false);
        setReason(''); // Clear the reason input after successful cancel
        setDialogMessage(''); 
        onSubmitHandler();
            // Display success message in Snackbar
    const message = response.data.message ;
    setSnackbarMessage(message);
    setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Error canceling the invoice', error);
        setOpenDialog(false);
        setReason(''); // Clear the reason input
        setDialogMessage(''); // Clear the dialog message
        const errorMessage =
        error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Internal Server Error occurred.';
          setSnackbarMessage(errorMessage);
          setSnackbarOpen(true);
      });
    };
  
    const handleClear = () => {
      setReason('');
    };
  //   const cancelInvoice  = (id) => {
  //     const business_entity_id = localStorage.getItem('business_entity_id');
  //     const  username =  localStorage.getItem('username');
  //     const password = localStorage.getItem('password');
  //     const gstin = localStorage.getItem('gstin')
  //  const irp_number = "vay"

  //  axios.post('/einvoice/cancel_generated_einvoice',{
  //   id:[id],
  //   business_entity_id,
  //   username,
  //   password,
  //   gstin,
  //   irp_number,
  // })
  // .then((response) => {
  //   console.log('Invoice canceled successfully', response.data);
  // })
  // .catch((error) => {
  //   console.error('Error canceling the invoice', error);

  // });

  //   }
    useEffect(() => {
        console.log("Current taskIds:", taskIds); 
    }, [taskIds]);
    const downloadInvoice = (id) => {
      const business_entity_id = localStorage.getItem('business_entity_id');
      const url = `/einvoice/download_generated_einvoice/${business_entity_id}/${id}`;
      
      axios.get(url, { responseType: 'json' })
        .then(response => {
          // Check if the response contains base64 encoded file data
          const base64Data = response.data.file; // Assuming 'file' contains base64 data
          
          // Convert base64 to binary data
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
    
          // Create a blob from the binary data
          const blob = new Blob([byteArray], { type: 'application/zip' }); // Adjust MIME type if necessary
    
          // Create a download link
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'invoice.zip'; // Adjust file name if necessary
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Error downloading zip file:', error);
        });
    };
    
    
 

  const handleStatusDropdownClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
};

  const handleClose = () => {
    setStatusAnchorEl(null);
      setRcmAnchorEl(null);
      setDocumentTypeAnchorEl(null);
      setCategoryAnchorEl(null);
};


const handleStatusMenuItemClick = (status) => {
  setSelectedStatus(status);
  setStatusAnchorEl(null); 
};
const handleClearStatus = (event) => {
  event.stopPropagation();
  setSelectedStatus('STATUS'); 
  setStatusAnchorEl(null); 
};

 
 const handleRcmDropdownClick = (event) => {
  setRcmAnchorEl(event.currentTarget);
};

const handleRcmMenuItemClick = (rcm) => {
  setSelectedRcm(rcm);
  setRcmAnchorEl(null); 
};

const handleClearRcm = (event) => {
  event.stopPropagation();
  setSelectedRcm('RCM'); 
  setRcmAnchorEl(null);
};
    
 
  const handleDocumentTypeDropdownClick = (event) => {
    setDocumentTypeAnchorEl(event.currentTarget);
};

const handleDocumentTypeMenuItemClick = (docType) => {
    setSelectedDocumentType(docType);
    setDocumentTypeAnchorEl(null); 
};

const handleClearDocumentType = (event) => {
    event.stopPropagation(); 
    setSelectedDocumentType('DOCUMENT TYPE'); 
    setDocumentTypeAnchorEl(null); 
};


   
    const handleCategoryDropdownClick = (event) => {
      setCategoryAnchorEl(event.currentTarget);
  };

  const handleCategoryMenuItemClick = (category) => {
      setSelectedCategory(category);
      setCategoryAnchorEl(null); 
  };

  const handleClearCategory = (event) => {
      event.stopPropagation(); 
      setSelectedCategory('CATEGORY'); 
      setCategoryAnchorEl(null); 
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
}

const filterData = () => { 
   if (!searchInput) {

  setFilteredData(records);
  return;
}
  const filtered = filteredData.filter(record => {
    const customerName = (record['inv_det_legal_name'] || '').toLowerCase();
    const customerPan = (record['inv_det_gstin'] || '').toLowerCase();
    const searchTerm = searchInput.toLowerCase();
    const category = (record['inv_det_category'] || '').toLowerCase();
    const reverseCharge = (record['inv_det_reverse_charge'] || '').toLowerCase();
    const documentType = (record['inv_det_document_type'] || '').toLowerCase();
    const documentNumber = (record['inv_det_document_number'] || '').toLowerCase();
    const documentDate = (record['inv_det_document_date'] || '').toLowerCase();
    const taxableValue = (record['amount_det_taxable_value'] || '').toString();
    const totalTaxValue = (record['total_tax_value'] || '').toString();
    const totalItemAmount = (record['amount_det_total_item_amount'] || '').toString();
    const einvoiceStatus = (record['einvoice_status'] || '').toLowerCase();

    // return customerName.includes(searchTerm) || customerPan.includes(searchTerm);
    return (
      customerName.includes(searchTerm) ||
      customerPan.includes(searchTerm) ||
      category.includes(searchTerm) ||
      reverseCharge.includes(searchTerm) ||
      documentType.includes(searchTerm) ||
      documentNumber.includes(searchTerm) ||
      documentDate.includes(searchTerm) ||
      taxableValue.includes(searchTerm) ||
      totalTaxValue.includes(searchTerm) ||
      totalItemAmount.includes(searchTerm) ||
      einvoiceStatus.includes(searchTerm)
    );
  });
  setFilteredData(filtered);
};

useEffect(()=>{
  filterData()
}, [searchInput, records])

const handleSearchChange = (event) => {
  setSearchInput(event.target.value);
  
};





  const filteredRecords = filteredData.filter(record => 
    (selectedStatus === 'STATUS' || record.einvoice_status === selectedStatus) &&
    (selectedDocumentType === 'DOCUMENT TYPE' || record.inv_det_document_type === selectedDocumentType) &&
    (selectedRcm === 'RCM' || record.inv_det_reverse_charge === selectedRcm) &&
    (selectedCategory === 'CATEGORY' || record.inv_det_category === selectedCategory)
   
);
  

    return (
        <>
            <div>
            <BoxWrapper
    boxTitle="E-INVOICE SUMMARY"
    enableAddButton={true}
    addButtonLabel={"BULK GENERATE"}
    addButtonHandlerFn={handleAddButtonClick}
    loading={loading} 
    enableSecondButton={true}
    secondButtonLabel={"BULK CANCELLATION"}
    secondButtonHandlerFn={handleSecondButtonClick}
    anotherloading={anotherloading}
    enableThirdButton={true}
    thirdButtonLabel={"EXPORT"}
    thirdButtonHandlerFn={handleExportExcel}
>

    <ValidatorForm
        useRef="form"
        onSubmit={onSubmitHandler}
        onError={(errors) => console.log(errors)}
    >
        
    
<Grid item xs={12}>
  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ margin: 0 }}>
          From Date<span style={{ color: "red" }}>*</span>:
        </label>
        <Date
          placeholder="From Date"
          errorMessages={["This field is required"]}
          validators={["required"]}
          value={fromDateMonthYearDisplay ?? ""}
          onChange={(v) => inputHandlerFromDatePicker(v)}
          style={{ width: '150px', margin: 0, padding: 0 }} // Adjust width as needed
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ margin: 0 }}>
          To Date<span style={{ color: "red" }}>*</span>:
        </label>
        <Date
          minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
          placeholder="To Date"
          errorMessages={["This field is required"]}
          validators={["required"]}
          value={toDateMonthYearDisplay ?? ""}
          onChange={(v) => inputHandlerToDatePicker(v)}
          style={{ width: '150px', margin: 0, padding: 0 }} // Adjust width as needed
        />
      </div>
      <div>
        <Button
          aria-haspopup="true"
          type="submit"
      variant="contained"
            color="primary"
          sx={{ height: '12px' }}
          style={{ marginTop: '5px' }}
        >
          SUBMIT
        </Button>
      </div>
    </div>
    
    <div style={{ display: 'flex', gap: '16px' }}>
     
        <h5>Filter Options</h5>
   
          <Button
            aria-controls="status-menu"
            aria-haspopup="true"
            onClick={handleStatusDropdownClick}
            variant="contained"
            color="primary"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
          >
            {selectedStatus}
            {selectedStatus !== 'STATUS' && (
              <IconButton onClick={handleClearStatus} size="small" style={{ marginLeft: '2px' }}>
                <ClearIcon fontSize="small" style={{ color: 'white' }} />
              </IconButton>
            )}
          </Button>
          <Menu
            id="status-menu"
            anchorEl={statusAnchorEl}
            open={openStatus}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleStatusMenuItemClick('Pending')}>Pending</MenuItem>
            <MenuItem onClick={() => handleStatusMenuItemClick('Generated')}>Generated</MenuItem>
            <MenuItem onClick={() => handleStatusMenuItemClick('Cancelled')}>Cancelled</MenuItem>
          </Menu>

          <Button
            aria-controls="document-type-menu"
            aria-haspopup="true"
            onClick={handleDocumentTypeDropdownClick}
            variant="contained"
            color="primary"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
          >
            {selectedDocumentType}
            {selectedDocumentType !== 'DOCUMENT TYPE' && (
              <IconButton 
                onClick={handleClearDocumentType} 
                size="small" 
                style={{ marginLeft: '3px', color: 'white' }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Button>
          <Menu
            id="document-type-menu"
            anchorEl={documentTypeAnchorEl}
            open={openDocumentType}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDocumentTypeMenuItemClick('Tax Invoice')}>Tax Invoice</MenuItem>
            <MenuItem onClick={() => handleDocumentTypeMenuItemClick('Debit Note')}>Debit Note</MenuItem>
            <MenuItem onClick={() => handleDocumentTypeMenuItemClick('Credit Note')}>Credit Note</MenuItem>
          </Menu>
    
        
       
          <Button
            aria-controls="rcm-menu"
            aria-haspopup="true"
            onClick={handleRcmDropdownClick}
            variant="contained"
            color="primary"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
          >
            {selectedRcm}
            {selectedRcm !== 'RCM' && (
              <IconButton onClick={handleClearRcm} size="small" style={{ marginLeft: '6px' }}>
                <ClearIcon fontSize="small" style={{ color: 'white' }} />
              </IconButton>
            )}
          </Button>
          <Menu
            id="rcm-menu"
            anchorEl={rcmAnchorEl}
            open={Boolean(rcmAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleRcmMenuItemClick('Yes')}>Yes</MenuItem>
            <MenuItem onClick={() => handleRcmMenuItemClick('No')}>No</MenuItem>
          </Menu>

          <Button
            aria-controls="category-menu"
            aria-haspopup="true"
            onClick={handleCategoryDropdownClick}
            variant="contained"
            color="primary"
            size="small"
            style={{ whiteSpace: 'nowrap' }}
          >
            {selectedCategory}
            {selectedCategory !== 'CATEGORY' && (
              <IconButton 
                onClick={handleClearCategory} 
                size="small" 
                style={{ marginLeft: '2px', color: 'white' }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Button>
          <Menu
            id="category-menu"
            anchorEl={categoryAnchorEl}
            open={openCategory}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleCategoryMenuItemClick('B2B')}>B2B</MenuItem>
            <MenuItem onClick={() => handleCategoryMenuItemClick('SEZWP')}>SEZWP</MenuItem>
            <MenuItem onClick={() => handleCategoryMenuItemClick('SEZWOP')}>SEZWOP</MenuItem>
            <MenuItem onClick={() => handleCategoryMenuItemClick('EXPWP')}>EXPWP</MenuItem>
            <MenuItem onClick={() => handleCategoryMenuItemClick('EXPWOP')}>EXPWOP</MenuItem>
            <MenuItem onClick={() => handleCategoryMenuItemClick('DEXP')}>DEXP</MenuItem>
          </Menu>
     
 
    </div>
  </div>
</Grid>







        

    </ValidatorForm>
</BoxWrapper>

<BoxWrapper  boxTitle="Summary Details"
    enableSearchInput={true}
    searchInputPlaceholder="Search..."
    searchInputOnchange={handleSearchChange}
               
               >
        <div style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'thin',display:'block'}}>
        
            <Table sx={{ tableLayout: 'auto' }} >
               
                <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
  <TableRow>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', padding: '4px 8px', whiteSpace: 'nowrap' }}
    >
    
      <Checkbox
        onChange={handleSelectAll}
        disabled={filteredRecords.every(
          (record) =>
            record.einvoice_status === 'Cancelled' ||
            record.einvoice_status === 'Generated' ||
            loadingIds.includes(record.id)
        )}
      />
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', padding: '4px 8px' }}
    >
      GSTIN
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      LEGAL NAME
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
    CATEGORY
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
     RCM
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
     DOC TYPE
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold',  position: 'sticky',overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      DOC NO
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold',  position: 'sticky',overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      DOC DATE
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold',  position: 'sticky',overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      TAXABLE AMT
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      TAX AMT
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', overflow: 'hidden', textOverflow: 'ellipsis', padding: '4px 8px' }}
    >
      TOTAL INVOICE
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', padding: '4px 8px' }}
    >
      STATUS
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold',  position: 'sticky',padding: '4px 8px' }}
    >
      PUSH TO EINVOICE
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', padding: '4px 8px' }}
    >
      CANCEL
    </TableCell>
    <TableCell
      style={{ fontSize: '15px', fontWeight: 'bold', position: 'sticky', padding: '4px 8px' }}
    >
      DOWNLOAD
    </TableCell>
  </TableRow>
</TableHead>

               
                 <TableBody>
                 {filteredRecords.length > 0 ? (
  filteredRecords.map((record, index) => (
            <TableRow key={index}>
               <TableCell>
                          {!(record.einvoice_status === 'Cancelled') && 
          (record.einvoice_status === 'Generated' && record.time_left) || 
          (record.einvoice_status === 'Pending') ? (
          <Checkbox 
            checked={selectedRecords.includes(record.id)} 
            onChange={() => handleSelect(record.id)} 
          />
        ) : null}
                    </TableCell>
              <TableCell>{record.inv_det_gstin}</TableCell>
              <TableCell>{record.inv_det_legal_name}</TableCell>
              <TableCell>{record.inv_det_category}</TableCell>
              <TableCell>{record.inv_det_reverse_charge}</TableCell>
              <TableCell>{record.inv_det_document_type}</TableCell>
              <TableCell>{record.inv_det_document_number}</TableCell>
              <TableCell>{record.inv_det_document_date}</TableCell>
              <TableCell>{record.amount_det_taxable_value}</TableCell>
      <TableCell>{record.total_tax_value}</TableCell>
              <TableCell>{record.amount_det_total_item_amount}</TableCell>
              <TableCell>{record.einvoice_status}</TableCell>
              
          <>
          <TableCell className="tableCell">
          {loadingIds.includes(record.id) ? (
    <CircularProgress size={24} style={{ color: '#0e1375' }} />
  ) : (
  <Button
    variant="contained"
    onClick={() => pushToIRN(record.id)}
    style={{
      whiteSpace: 'nowrap',
      backgroundColor: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Generated' || loadingIds.includes(record.id) ? '#0e1375' : '#0e1375',
      color: 'white',
      filter: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Generated' || loadingIds.includes(record.id) ? 'blur(0.2px)' : 'none',
                cursor: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Generated' || loadingIds.includes(record.id) ? 'not-allowed' : 'pointer',
      opacity: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Generated' || loadingIds.includes(record.id) ? 0.6 : 1
    }}
              disabled={record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Generated' || loadingIds.includes(record.id)}
  >
    Push to IRN
  </Button>
   )}
</TableCell>

<TableCell className="tableCell">
  <Tooltip title={record.cancel_time_remaining}>
    <Button
      variant="contained"
      onClick={() => cancelInvoice(record.id)}
      style={{
        whiteSpace: 'nowrap',
        backgroundColor: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Pending' || !record.time_left || loadingIds.includes(record.id) ? '#bf1f1f' : '#bf1f1f',
        color: 'white',
        filter: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Pending' || !record.time_left ? 'blur(0.2px)' : 'none',
        opacity: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Pending' || !record.time_left ? 0.6 : 1,
        cursor: record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Pending' || !record.time_left ? 'not-allowed' : 'pointer'
      }}
      disabled={record.einvoice_status === 'Cancelled' || record.einvoice_status === 'Pending' || !record.time_left || loadingIds.includes(record.id)}
    >
      CANCEL
    </Button>
  </Tooltip>
</TableCell>

        <TableCell className="tableCell">
  <IconButton
    onClick={() => downloadInvoice(record.id)}
    style={{
              backgroundColor: record.einvoice_status === 'Pending' ? '#418042' : '#3c963e',
      color: 'white',
      cursor: record.einvoice_status === 'Pending' ? 'not-allowed' : 'pointer',
      opacity: record.einvoice_status === 'Pending' ? 0.5 : 1,
    }}
            disabled={record.einvoice_status === 'Pending'}
  >
    <DownloadIcon />
  </IconButton>
</TableCell>
          </>
            </TableRow>
  ))
) : (
  <TableRow>
    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
      No data found
    </TableCell>
  </TableRow>
)}

                  
                </TableBody>
            </Table>
          
            </div>
     
            </BoxWrapper>
           
            <Dialog
        open={openDialog}
        onClose={handleCancelClose}
        sx={{ "& .MuiDialog-paper": { width: '50%' } }}
      >
        <DialogTitle>Cancel E-Invoice</DialogTitle>
        <DialogContent>
          <p>{dialogMessage}</p>
          <TextField
         
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color="secondary">
            Clear
          </Button>
          <Button onClick={handleCancelConfirm} color="primary">
            Submit
          </Button>
          <Button onClick={handleCancelClose} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
<Snackbar
        open={snackbarOpen}
        sx={{ mt: 4 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={30000}  // Snackbar will auto-hide after 3 seconds
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}  variant="filled"  sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


            </div>
        </>
    );
}

export default Summary;
