import React, { useState, useEffect } from "react";
import {
  Paper,
  Tab,
  Tabs,
  AppBar,
  Container,
  CircularProgress,
} from "@material-ui/core";
import CfoDashBoard from "./Summary/CfoDashBoard";
import AgeAnalysisCurrentMonth from "./AgeAnalysisCurrentMonth/AgeAnalysisCurrentMonth";
import AgeAnalysisLastMonth from "./AgeAnalysisLastMonth/AgeAnalysisLastMonth";
import axios from "../../../../axios-order";
import "./AppBar.css";

export default function AppBarForCfoDebtorsStatus() {
  const [tabIndex, setTabIndes] = useState(0);
  const [loaderFroSummary, setLoaderFroSummary] = useState(false);

  //..............Last Refresh On.......................................
  const [lastRefreshOn, setLastRefreshOn] = useState("");

  //..........State for CfoDashBoard...........
  const [dataForSummary, setDataForSummary] = useState([]);
  const [dataForSummaryTotal, setDataForSummaryTotal] = useState([]);

  //............State For AgeAnalysisCurrentMonth.....................
  const [dataForAgeAnalysisCurrentMonth, setDataForAgeAnalysisCurrentMonth] =
    useState([]);
  const [
    dataForAgeAnalysisCurrentMonthTotal,
    setDataForAgeAnalysisCurrentMonthTotal,
  ] = useState([]);

  //.......................State for AgeAnalysisLastMonth...............................
  const [dataForAgeAnalysisLastMonth, setDataForAgeAnalysisLastMonth] =
    useState([]);
  const [
    dataForAgeAnalysisLastMonthTotal,
    setDataForAgeAnalysisLastMonthTotal,
  ] = useState([]);

  //........................State For Sep 21...............................
  const [dataForSep21ExportToExcel, setDataForSep21ExportToExcel] = useState(
    []
  );

  const tabIndexHandler = (event, value) => {
    setTabIndes(value);
  };

  //.............................get Api For Cfo Dash Board...........................
  const getApiForCfoDashBoard = () => {
    axios
      .get(
        `/view/dashboard_summary/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        if (res.data.status === false) {
          setLoaderFroSummary(false);
        } else {
          setDataForSummary(res.data.records.Partner_Details);
          setDataForSummaryTotal(res.data.records.Total);
          setLoaderFroSummary(false);
        }
      })
      .catch((error) => alert(error));
  };

  //..............Get Api Age Analysis Current Month.....................
  const getApiForAgeAnalysisCurrentMonth = () => {
    axios
      .get(
        `/view/balance_receivable_current_month/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (res.data.status === false) {
          // alert("No data exists for this particular query");
        } else {
          setDataForAgeAnalysisCurrentMonth(res.data.records.Partner_Details);
          setDataForAgeAnalysisCurrentMonthTotal(res.data.records.Total);
        }
      })
      .catch((error) => console.log(error));
  };

  //.........................Get Api For Age Analysis Last Month.......................
  const getApiForAgeAnalysisLastMonth = () => {
    axios
      .get(
        `/view/balance_receivable_last_month/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (res.data.status === false) {
          // alert("No data exists for this particular query");
        } else {
          setDataForAgeAnalysisLastMonth(res.data.records.Partner_Details);
          setDataForAgeAnalysisLastMonthTotal(res.data.records.Total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //.........................Get Api For Sep 21....................
  const getApiForsep21 = () => {
    axios
      .get(
        `/view/balance_receivable/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        if (res.data.status === false) {
        } else {
          setDataForSep21ExportToExcel(res.data.records);
        }
      })
      .catch((error) => console.log(error));
  };

  //........................Last Refresh Date..........................
  const getApiForLastRefreshData = () => {
    axios
      .get(`/cfodashboard/getcfoinsertstatus/`)
      .then((res) => {
        if (res.data.status === false) {
          setLastRefreshOn(res.data.error);
        } else {
          setLastRefreshOn(res.data);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  //..........Handle Refresh Button...............
  const handleRefresh = () => {
    setLoaderFroSummary(true);
    axios
      .post(`/cfodashboard/postcfoinsertstatuscallproc/`)
      .then((res) => {
        if (res.data.status === false) {
          setTimeout(() => {
            setLoaderFroSummary(false);
            alert("No Data");
          }, 2000);
        } else {
          setLoaderFroSummary(false);
          getApiForLastRefreshData();
          getApiForCfoDashBoard();
          getApiForAgeAnalysisCurrentMonth();
          getApiForAgeAnalysisLastMonth();
          getApiForsep21();
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  //..................DownloadBase For Export To Excel..............
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //........................Export To Excel..................................
  const handleExportToExcell = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `CFO Dashboard.xlsx`;

    axios
      .get(
        `/view/balance_receivable_current_month/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (res.data.status === false) {
          alert("Unable to process download....");
        } else {
          downloadBase64File(contentType, res.data.data, filename);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getApiForCfoDashBoard();
    getApiForAgeAnalysisCurrentMonth();
    getApiForAgeAnalysisLastMonth();
    getApiForsep21();
    getApiForLastRefreshData();
    setLoaderFroSummary(true);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <div className="top-div-styling">
          {/* <div style={{ width: "30%" }}>
            <Paper
              style={{
                background: "#C9CCD5",
                fontWeight: "600",
                marginBottom: "10px",
                padding: "10px",
              }}
              className="paperStyle"
            >
              Last Refreshed On : {lastRefreshOn ? lastRefreshOn : "---"}
            </Paper>
          </div> */}

          <div style={{ width: "12%" }}>
            <button
              style={{
                marginBottom: "10px",
                padding: "8px",
              }}
              className="button-primary"
              onClick={handleExportToExcell}
            >
              {" "}
              Export To Excel
            </button>
          </div>

          {/* <div style={{ width: "30%" }}>
            <Paper
              style={{
                background: "#C9CCD5",
                fontWeight: "600",
                marginBottom: "10px",
                padding: "10px",
              }}
              className="paperStyle"
              onClick={handleRefresh}
            >
              Refresh
            </Paper>
          </div> */}
        </div>
      </Container>
      {/* <Container>
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={tabIndex}
              onChange={tabIndexHandler}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab
                style={{ background: "#FFE3E3", color: "#000" }}
                label="Summary"
              />
              <Tab
                style={{ background: "#E4D8DC", color: "#000" }}
                label="Age Analysis Last Month"
              />
              <Tab
                style={{ background: "#C9CCD5", color: "#000" }}
                label="Age Analysis Current Month"
              />
            </Tabs>
          </AppBar>
          {loaderFroSummary ? (
            <div style={{ marginTop: "50px" }}>
              <center>
                <CircularProgress />
                <br></br>
                Loading,Please wait...
              </center>
            </div>
          ) : (
            <React.Fragment>
              {tabIndex === 0
                ? dataForSummary && (
                    <CfoDashBoard
                      dataForSummary={dataForSummary}
                      dataForSummaryTotal={dataForSummaryTotal}
                    />
                  )
                : tabIndex === 1
                ? dataForAgeAnalysisLastMonth && (
                    <AgeAnalysisLastMonth
                      LastMonth={dataForAgeAnalysisLastMonth}
                      LastMonthTotal={dataForAgeAnalysisLastMonthTotal}
                    />
                  )
                : dataForAgeAnalysisCurrentMonth && (
                    <AgeAnalysisCurrentMonth
                      CurrentMonth={dataForAgeAnalysisCurrentMonth}
                      CurrentMonthTotal={dataForAgeAnalysisCurrentMonthTotal}
                    />
                  )}
            </React.Fragment>
          )}
        </div>
      </Container> */}
    </React.Fragment>
  );
}
