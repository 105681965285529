import React, { useState } from "react";
import "./AgeAnalysisCurrentMonth.css";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Container from "@material-ui/core/Container";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

export default function AgeAnalysisCurrentMonth({
  CurrentMonth,
  CurrentMonthTotal,
}) {
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <tr>
          <td>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <DoNotDisturbOnIcon style={{ color: "black" }} />
              ) : (
                <AddCircleIcon style={{ color: "black" }} />
              )}
            </IconButton>
          </td>
          <td>{row.Partner.Partner_Name}</td>
          <td>
            {indianRupeesFormat(
              row.Partner.Sum_of_Receivable_till_Current_Month
            )}
          </td>
        </tr>

        <tr>
          {" "}
          <td colSpan={"3"}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Client Details/{row.Partner.Partner_Name}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <thead>
                    <TableRow>
                      <th>Name Of Client</th>
                      <th>Amt Received Current Month</th>
                    </TableRow>
                  </thead>
                  <tbody>
                    {row.clients.map((value, index) => (
                      <TableRow key={index}>
                        <th>{value.Name_of_Clients}</th>
                        <td>
                          {indianRupeesFormat(
                            value.Sum_of_Receivable_till_Current_Month
                          )}
                        </td>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Collapse>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "30px" }}>
        <BoxWrapper boxTitle="Age analysis current month">
          <div className="Topheading" style={{ background: "#ddd" }}>
            <div style={{ padding: "5px", paddingLeft: "20px" }}>
              {CurrentMonthTotal.Sum_of_Receivable_till_Current_Month ? (
                <div>
                  Amount Received Current Month:{" "}
                  {indianRupeesFormat(
                    CurrentMonthTotal.Sum_of_Receivable_till_Current_Month
                  )}
                </div>
              ) : (
                <div>Amount Received Current Month: ₹0 </div>
              )}
            </div>
          </div>

          <div className="table">
            <div className="tableResponisve">
              <table className="tablecss">
                <thead>
                  <tr>
                    <th>Client Details</th>
                    <th>Partner Name</th>
                    <th>Amt Received Current Month</th>
                  </tr>
                </thead>
                <tbody>
                  {CurrentMonth.map((value) => (
                    <Row row={value} />
                  ))}
                  {CurrentMonth.length === 0 && (
                    <tr>
                      <td
                        colSpan={"7"}
                        style={{ color: "red", textAlign: "center" }}
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </React.Fragment>
  );
}
