import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../../axios-order";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useHistory } from "react-router-dom";
function VendoreViewtable() {
  const location = useLocation();
  let history = useHistory();

  const vendor_gstin = location.state?.vendor_gstin || "";
  const log_id = localStorage.getItem("record_id");

  const [vendorBills, setVendorBills] = useState([]);
  const [vendorDebits, setVendorDebits] = useState([]);
  const [vendorCredits, setVendorCredits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    // Fetch data using vendor_gstin and log_id from localStorage
    if (vendor_gstin && log_id) {
      axios
        .get(`/blocked_gstin/get_detail_data/${log_id}/${vendor_gstin}`)
        .then((response) => {
          if (response.data.status) {
            setVendorBills(response.data.records.vendor_bills || []);
            setVendorDebits(response.data.records.vendor_debits || []);
            setVendorCredits(response.data.records.vendor_credits || []);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [vendor_gstin, log_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Vendor Bills Table
  const renderVendorBillsTable = () => {
    if (loading) {
      // Show loading state while data is being fetched
      return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>Loading...</div>
      );
    }

    return (
      <TableContainer
        component={Paper}
        sx={{ marginTop: 2, maxHeight: "400px", overflowY: "auto", scrollbarWidth: 'thin' }}
      >
        <Table aria-label="customized table">
          <TableRow
            sx={{
              backgroundColor: "#303841",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableCell sx={{ color: "white" }} align="center">
              ERP Vendor Code
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Vendor Name
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Vendor GSTIN
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Document Type
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Document Number
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Document Date
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Taxable Value
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              GST Rate
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
            CESS
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
             IGST
            </TableCell>
         
            <TableCell sx={{ color: "white" }} align="center">
              CGST
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              SGST
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Total Invoice Value
            </TableCell>
          </TableRow>

          <TableBody>
            {vendorBills.length > 0 ? (
              vendorBills.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.erp_vendor_code}</TableCell>
                  <TableCell align="center">{row.vendor_name}</TableCell>
                  <TableCell align="center">{row.vendor_gstin}</TableCell>
                  <TableCell align="center">{row.document_type}</TableCell>
                  <TableCell align="center">{row.document_number}</TableCell>
                  <TableCell align="center">{row.document_date}</TableCell>
                  <TableCell align="center">{row.taxable_value}</TableCell>
                  <TableCell align="center">{row.gst_rate}</TableCell>
                  <TableCell align="center">{row.cess}</TableCell>
                  <TableCell align="center">{row.igst}</TableCell>
                  <TableCell align="center">{row.cgst}</TableCell>
                  <TableCell align="center">{row.sgst}</TableCell>
                  <TableCell align="center">
                    {row.total_invoice_value}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={12}
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  No data found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Vendor Debits Table
  const renderVendorDebitsTable = () => (
    <TableContainer
      component={Paper}
      sx={{ marginTop: 2, maxHeight: "400px", overflowY: "auto" }}
    >
      <Table aria-label="debits table">
        <TableRow
          sx={{
            backgroundColor: "#303841",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableCell sx={{ color: "white" }} align="center">
            ERP Vendor Code
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Vendor Name
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Type
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Number
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Date
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Taxable Value
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            GST Rate
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            CESS
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            IGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            CGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            SGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Total Invoice Value
          </TableCell>
        </TableRow>

        <TableBody>
          {vendorDebits.length > 0 ? (
            vendorDebits.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.erp_vendor_code}</TableCell>
                <TableCell align="center">{row.vendor_name}</TableCell>
                <TableCell align="center">{row.document_type}</TableCell>
                <TableCell align="center">{row.document_number}</TableCell>
                <TableCell align="center">{row.document_date}</TableCell>
                <TableCell align="center">{row.taxable_value}</TableCell>
                <TableCell align="center">{row.gst_rate}</TableCell>
                <TableCell align="center">{row.cess}</TableCell>
                <TableCell align="center">{row.igst}</TableCell>
                <TableCell align="center">{row.cgst}</TableCell>
                <TableCell align="center">{row.sgst}</TableCell>
                <TableCell align="center">{row.total_invoice_value}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={11}
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Vendor Credits Table
  const renderVendorCreditsTable = () => (
    <TableContainer
      component={Paper}
      sx={{ marginTop: 2, maxHeight: "400px", overflowY: "auto" }}
    >
      <Table aria-label="credits table">
        <TableRow
          sx={{
            backgroundColor: "#303841",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableCell sx={{ color: "white" }} align="center">
            ERP Vendor Code
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Vendor Name
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Type
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Number
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Document Date
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Taxable Value
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            GST Rate
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            CESS
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            IGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            CGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            SGST
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Total Invoice Value
          </TableCell>
        </TableRow>

        <TableBody>
          {vendorCredits.length > 0 ? (
            vendorCredits.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.erp_vendor_code}</TableCell>
                <TableCell align="center">{row.vendor_name}</TableCell>
                <TableCell align="center">{row.document_type}</TableCell>
                <TableCell align="center">{row.document_number}</TableCell>
                <TableCell align="center">{row.document_date}</TableCell>
                <TableCell align="center">{row.taxable_value}</TableCell>
                <TableCell align="center">{row.gst_rate}</TableCell>
                <TableCell align="center">{row.cess}</TableCell>
                <TableCell align="center">{row.igst}</TableCell>
                <TableCell align="center">{row.cgst}</TableCell>
                <TableCell align="center">{row.sgst}</TableCell>
                <TableCell align="center">{row.total_invoice_value}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={11}
                align="center"
                sx={{ fontWeight: "bold" }}
              >
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "80%" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SummarizeIcon />
                  <span style={{ marginLeft: "8px" }}>Vendor Bill</span>
                </div>
              }
              sx={{
                width: 280,
                height: 50,
                borderRadius: 1,
                fontWeight: "550",
                transition: "background-color 0.2s, color 0.2s",
                "&:hover": { backgroundColor: "#80d8ff" },
                borderColor: "#000000",
                borderStyle: "solid",
                borderRight: "0.5px",
              }}
            />
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SummarizeIcon />
                  <span style={{ marginLeft: "8px" }}>Vendor Debits</span>
                </div>
              }
              sx={{
                width: 280,
                height: 50,
                borderRadius: 1,
                fontWeight: "550",
                transition: "background-color 0.2s, color 0.2s",
                "&:hover": { backgroundColor: "#80d8ff" },
                borderColor: "#000000",
                borderStyle: "solid",
                borderRight: "0.5px",
              }}
            />
            <Tab
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SummarizeIcon />
                  <span style={{ marginLeft: "8px" }}>Vendor Credits</span>
                </div>
              }
              sx={{
                width: 280,
                height: 50,
                borderRadius: 1,
                fontWeight: "550",
                transition: "background-color 0.2s, color 0.2s",
                "&:hover": { backgroundColor: "#80d8ff" },
                borderColor: "#000000",
                borderStyle: "solid",
                borderRight: "0.5px",
              }}
            />
          </Tabs>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => history.push("/VendorViewData")}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            BACK
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {value === 0 && renderVendorBillsTable()}
        {value === 1 && renderVendorDebitsTable()}
        {value === 2 && renderVendorCreditsTable()}
      </div>
    </div>
  );
}

export default VendoreViewtable;
