import React, { Component, Fragment } from "react";
import classes from "./form26as.module.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";

// fiscal year date ----->
const getCurrentFinancialYear = (i) => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 >= 4 && today.getMonth() + 1 <= 12) {
    fiscalyear = today.getFullYear() - i + "-" + (today.getFullYear() - i + 1);
  } else if (today.getMonth() + 1 < 4) {
    fiscalyear = today.getFullYear() - i - 1 + "-" + (today.getFullYear() - i);
  }
  return fiscalyear;
};

// indian date format
const indianFormat = (date) => {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: this.props.location.data,

      fiscal_year: getCurrentFinancialYear(0),
      from_date: getCurrentFinancialYear(0).split("-")[0] + "-04-01",
      to_date: getCurrentFinancialYear(0).split("-")[1] + "-03-31",
    };
  }

  // invoice Details table header
  customer_detailsTableHeader() {
    const tableHeader = [
      "Customer GSTIN",
      "Customer  name",
      "Customer /Deducted TAN",
      "Total-TDS Deducted (per 26AS)",
      "Total-TDS ( per available payment advice)",
      "Total TDS-Difference",
      "TDS deducted per 26AS",
      "TDS per CWS plateform (per available payment advice) ",
      "Difference",
      "TDS deducted per 26AS",
      "TDS per CWS plateform (per available payment advice) ",
      "Difference",
      "TDS deducted per 26AS",
      "TDS per CWS plateform (per available payment advice) ",
      "Difference",
      "TDS deducted per 26AS",
      "TDS per CWS plateform (per available payment advice) ",
      "Difference",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // ist table header
  customer_infoTableHeader() {
    const tableHeader = [
      "Customer GSTIN",
      "Customer  name",
      "CUSTOMER/Deducted TAN",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // 2nd table header[Reference data as per Payment advice ]
  DataAsPerPaymentAdviceTableHeader() {
    const tableHeader = [
      "Invoice Number",
      "Invoice date",
      "Payment advice date",
      "TDS per Payment advice",
      "TDS Qtr considered",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // 3rd table[Reference data as per Form26 AS ]
  DataAsPerForm26asTableHeader() {
    const tableHeader = [
      "Transaction date",
      "Tax deducted",
      "TDS Qtr considered",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  customer_details_data() {
    return (
      this.state.allData &&
      this.state.allData.main_table.map((tableData, index) => {
        const {
          customer_gstin,
          customer_name,
          tds_deducted_form_26,
          total_tds_payment_advice,
          total_tds_difference,
          q1_tds_deducted_form_26,
          q1_total_tds_payment_advice,
          q1_total_tds_difference,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {customer_gstin}</td>
            <td>{customer_name}</td>
            <td>{tableData["customer/decuctor_tan"]}</td>
            <td>{numberFormat(tds_deducted_form_26)}</td>
            <td>{numberFormat(total_tds_payment_advice)}</td>
            <td>{numberFormat(total_tds_difference)}</td>
            <td>{numberFormat(q1_tds_deducted_form_26)}</td>
            <td>{numberFormat(q1_total_tds_payment_advice)}</td>
            <td>{numberFormat(q1_total_tds_difference)}</td>
            <td>{numberFormat(tableData.q2_tds_deducted_form_26)}</td>
            <td>{numberFormat(tableData.q2_total_tds_payment_advice)}</td>
            <td>{numberFormat(tableData.q2_total_tds_difference)}</td>
            <td>{numberFormat(tableData.q3_tds_deducted_form_26)}</td>
            <td>{numberFormat(tableData.q3_total_tds_payment_advice)}</td>
            <td>{numberFormat(tableData.q3_total_tds_difference)}</td>
            <td>{numberFormat(tableData.q4_tds_deducted_form_26)}</td>
            <td>{numberFormat(tableData.q4_total_tds_payment_advice)}</td>
            <td>{numberFormat(tableData.q4_total_tds_difference)}</td>
          </tr>
        );
      })
    );
  }
 // download for ageing summary

 downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

tdsReportDownload = () => {
  const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const filename = `tds_export.xlsx`;
  axios
    .post(`/tds/download`, { data:this.state.allData })
    .then((res) => {
      this.downloadBase64File(contentType, res.data.file, filename);
    })
    .catch((error) => {
      console.log(error);
    });
}

  render() {
    return (
      <React.Fragment>
        <Container>
          <div>
            {/* ---dashboard header--- */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  className={classes.Paper}
                  style={{
                    background: "#c4d0d9",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {this.state.allData &&
                    this.state.allData.seller_data}
                  <br></br>26AS-TDS Reconciliation Report.
                </Paper>
              </Grid>
            </Grid>
            {/* ---End--- */}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
            
                  <p style={{ margin: "0px" }}>
                    Reporting period : 
                    {this.state.allData.start_date &&
                      indianFormat(this.state.allData.start_date)}{" "}
                    To :
                    {this.state.allData.end_date &&
                      indianFormat(this.state.allData.end_date)}
                  </p>
                  <Button size="small" onClick={this.tdsReportDownload}>Export to excel</Button>
                </div>
              </Grid>
            </Grid>

            {/* TABLES */}
            {/* CUSTOMER DETAILS TABLE */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.tableWapper}>
                  <table className={classes.Customer_detailsTable}>
                    <tbody>
                      <tr style={{ border: "1px solid #ddd" }}>
                        <td colSpan={6}></td>
                        <td colSpan={3} style={{ border: "1px solid #ddd" ,background: "#138e9c"}}>
                          Quarter No 1
                        </td>
                        <td colSpan={3} style={{ border: "1px solid #ddd", background: "#e38e8e" }}>
                          Quarter No 2
                        </td>
                        <td colSpan={3} style={{ border: "1px solid #ddd",background: "#3256a3" }}>
                          Quarter No 3
                        </td>
                        <td colSpan={3} style={{ border: "1px solid #ddd",background: "#89672f" }}>
                          Quarter No 4
                        </td>
                      </tr>
                      <tr>{this.customer_detailsTableHeader()}</tr>
                      {this.customer_details_data()}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
            {/*---TDS Reconciliation Details--- */}
            <div
              style={{
                background: "#C4D0D9",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "10px",
                marginTop: "30px",
                marginBottom: "50px",
              }}
            >
              26AS-TDS Reconciliation Details
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.state.allData &&
                  this.state.allData.table_per_customer.map((items, index) => {
                    return (
                      <Fragment>
                        {/* !st table cutomer inf */}
                        <div
                          style={{
                            borderRadius: "5px",
                            boxShadow: "0px 0px 3px 0px black",
                            width: "50%",
                          }}
                        >
                          <table className={classes.CustomerTableCss}>
                            <tbody>
                              <tr>{this.customer_infoTableHeader()}</tr>
                              <tr>
                                <td>{items.info.customer_gstin}</td>
                                <td>{items.info.customer_name}</td>
                                <td>{items.info["customer/decuctor_tan"]}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* 2nd table data as per payment advice */}
                        <div
                          className={classes.tableWapper}
                          style={{ marginTop: "20px" }}
                        >
                          <table className={classes.tablecss}>
                            <tbody>
                              <tr>
                                {this.DataAsPerPaymentAdviceTableHeader()}
                              </tr>
                              {Object.keys(
                                items.reference_data_payment_advice
                              ).map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      {
                                        items.reference_data_payment_advice[
                                          item
                                        ].invoice_number
                                      }
                                    </td>
                                    <td>
                                      {
                                        items.reference_data_payment_advice[
                                          item
                                        ].invoice_date
                                      }
                                    </td>
                                    <td>
                                      {
                                        items.reference_data_payment_advice[
                                          item
                                        ].payment_advice_date
                                      }
                                    </td>
                                    <td>
                                      {numberFormat(
                                        items.reference_data_payment_advice[
                                          item
                                        ].tds_per_payment_advice
                                      )}
                                    </td>
                                    <td>
                                      {
                                        items.reference_data_payment_advice[
                                          item
                                        ].tds_quarters_considered
                                      }
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        {/* 3rd table Reference data as per Form26 AS	 */}
                        <div
                          className={classes.tableWapper}
                          style={{
                            borderRadius: "5px",
                            boxShadow: "0px 0px 3px 0px black",
                            width: "70%",
                            marginTop: "20px",
                          }}
                        >
                          <table className={classes.tablecss}>
                            <tbody>
                              <tr>{this.DataAsPerForm26asTableHeader()}</tr>
                              {Object.keys(items.reference_data_form_26).map(
                                (item, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        {
                                          items.reference_data_form_26[item]
                                            .transaction_date
                                        }
                                      </td>
                                      <td>
                                        {numberFormat(
                                          items.reference_data_form_26[item]
                                            .tax_deducted
                                        )}
                                      </td>
                                      <td>
                                        {
                                          items.reference_data_form_26[item]
                                            .tds_quarter_considered
                                        }
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div
                          style={{
                            background: "#C4D0D9",
                            paddingTop: "10px",
                            paddingBottom: "15px",
                            paddingLeft: "15px",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        ></div>
                      </Fragment>
                    );
                  })}
              </Grid>
            </Grid>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Showdetails;
