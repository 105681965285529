import React, { useState, useEffect } from "react";
import designForZohoModel from "./ZohoModel.module.css";
import { Box, Paper, Modal } from "@mui/material";
import { Button, Grid, TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../ui/DatePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "../../axios-order";
import moment from "moment";
import CustomTable from "../ui/Table/Table";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import RightDrawer from "../ui/RightDrawer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  // bgcolor: "background.paper",
  // bgcolor: "rgb(244, 246, 249)",
  // border: "2px solid #000",
  boxShadow: 24,
  background: "#fff",
};

const ZohoModel = ({
  openZohoDrawer,
  buttonColor,
  establishConnection,
  zohoDate,
  messageForConnection,
  timerForZohoInSalesCustomer,
  fetchingdataForZoho,
  zohoDateChangeHandler,
  closeZohoDrawer,
  effitracImportFormloader,
  logApitName,
  nameOfTheModel,
  zohoTableHeader,
  zohoTableColumn,
  drawerKeys,
  importToCWS,
  exportToExcel,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const [logsLoader, setLogsLoader] = useState(false);

  const [logRecords, setLogRecords] = useState([]);

  const [drawer, setDrawer] = useState(false);
  const [index, setIndex] = useState();
  const [zohoData, setZohoData] = useState([]);
  const [createdFromDisplay, setCreatedFromDisplay] = useState(null);
  const [modifiedFromDisplay, setModifiedFromDisplay] = useState(null);
  const [createdToDisplay, setCreatedToDisplay] = useState(null);
  const [modifiedToDisplay, setModifiedToDisplay] = useState(null);

  const [triggerZohoTable, setTriggerZohoTable] = useState(false);

  const actions = [
    {
      label: <VisibilityIcon className="action-icon-button showIcon" />,
      onclickEvent: (index, row) => openModal(index),
    },
  ];

  const closeDrawer = () => {
    setIndex();
    setDrawer(false);
  };

  const openModal = (index) => {
    setDrawer(true);
    setIndex(index);
  };

  const getZohoLogs = () => {
    setCreatedFromDisplay(null);
    setModifiedFromDisplay(null);
    setCreatedToDisplay(null);
    setModifiedToDisplay(null);
    setLogsLoader(true);
    axios
      .post(`/arc/zoho_logs`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        number_of_rows_per_page: rowsPerPage,
        page_number: page,
        type_of_data: logApitName,
      })
      .then((res) => {
        if (res.data.status === true) {
          setLogsLoader(false);
          setLogRecords(res.data.records);
          setRowCount(res.data.row_count);
        } else {
          setLogsLoader(false);
        }
      })
      .catch((err) => setLogsLoader(true));
  };

  const handleZohoButton = (id) => {
    axios.get(`/arc/zoho_data/${id}`).then((res) => {
      if (res.data.status === true) {
        setZohoData(res.data.records);
        setTriggerZohoTable(true);
      } else {
        alert(res.data.message);
      }
    });
  };

  useEffect(() => {
    getZohoLogs();
  }, [effitracImportFormloader]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Modal
      keepMounted
      open={openZohoDrawer}
      // onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            color: "#fff",
            background: "#d04646",
            padding: "7px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontFamily: "Dosis", marginBottom: "0px" }}>
            {nameOfTheModel}
          </h4>
          {triggerZohoTable ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    className="button-primary"
                    onClick={() => setTriggerZohoTable(false)}
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className="button-primary"
                    onClick={() => {
                      importToCWS(zohoData);
                      closeZohoDrawer();
                    }}
                  >
                    Import To CWS
                  </button>
                </div>
                <div>
                  <button
                    className="button-primary"
                    onClick={() => exportToExcel(zohoData)}
                  >
                    Export To Excel
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="modal-body">
          {triggerZohoTable ? (
            <>
              <CustomTable
                headers={zohoTableHeader}
                columns={zohoTableColumn}
                data={zohoData}
                actions={drawerKeys.length === 0 ? null : actions}
              />
            </>
          ) : (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse",
                  }}
                >
                  <div>
                    <button
                      className="button-outlined-secondary"
                      disabled={buttonColor === "green" ? true : false}
                      onClick={establishConnection}
                    >
                      {messageForConnection}
                    </button>
                  </div>
                  {buttonColor === "green" && (
                    <div
                      style={{
                        // textAlign: "center",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          color:
                            timerForZohoInSalesCustomer < 30 ? "red" : "green",
                        }}
                      >
                        Connection will be lost In{"  "}
                        {timerForZohoInSalesCustomer} Seconds
                      </span>
                    </div>
                  )}
                </div>
                {buttonColor === "green" && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "10px 0px",
                        borderBottom: "2px solid #ddd",
                        fontFamily: "Dosis",
                      }}
                    >
                      <h5>Filter</h5>
                    </div>
                    <div>
                      <ValidatorForm
                        useref="form"
                        onSubmit={fetchingdataForZoho}
                        onError={(errors) => console.log(errors)}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <div style={{ marginTop: "10px" }}>
                              <label>
                                Created From Date
                                <span style={{ color: "red" }}>*</span>:
                              </label>
                              <ValidatedDatePicker
                                fullWidth
                                name="created_from_date"
                                // value={zohoDate.created_from_date ?? ""}
                                value={createdFromDisplay}
                                onChange={(v) => {
                                  setCreatedFromDisplay(v);
                                  if (v != "Invalid Date" && v != null) {
                                    zohoDateChangeHandler({
                                      name: "created_from_date",
                                      value: v,
                                    });
                                  }
                                }}
                                requiredField="*"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ marginTop: "10px" }}>
                              <label>
                                Modified From Date
                                <span style={{ color: "red" }}>*</span>:
                              </label>
                              <ValidatedDatePicker
                                name="modified_from_date"
                                // value={zohoDate.modified_from_date ?? ""}
                                value={modifiedFromDisplay ?? ""}
                                onChange={(v) => {
                                  setModifiedFromDisplay(v);
                                  if (v != "Invalid Date" && v != null) {
                                    zohoDateChangeHandler({
                                      name: "modified_from_date",
                                      value: v,
                                    });
                                  }
                                }}
                                requiredField="*"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ marginTop: "10px" }}>
                              <label>
                                Created To Date
                                <span style={{ color: "red" }}>*</span>:
                              </label>
                              <ValidatedDatePicker
                                name="created_to_date"
                                // value={zohoDate.modified_from_date ?? ""}
                                value={createdToDisplay ?? ""}
                                onChange={(v) => {
                                  setCreatedToDisplay(v);
                                  if (v != "Invalid Date" && v != null) {
                                    zohoDateChangeHandler({
                                      name: "created_to_date",
                                      value: v,
                                    });
                                  }
                                }}
                                requiredField="*"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ marginTop: "10px" }}>
                              <label>
                                Modified To Date
                                <span style={{ color: "red" }}>*</span>:
                              </label>
                              <ValidatedDatePicker
                                name="modified_to_date"
                                // value={zohoDate.modified_from_date ?? ""}
                                value={modifiedToDisplay ?? ""}
                                onChange={(v) => {
                                  setModifiedToDisplay(v);
                                  if (v != "Invalid Date" && v != null) {
                                    zohoDateChangeHandler({
                                      name: "modified_to_date",
                                      value: v,
                                    });
                                  }
                                }}
                                requiredField="*"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div style={{ textAlign: "right" }}>
                              {effitracImportFormloader ? (
                                <Button
                                  variant="contained"
                                  disabled
                                  style={{
                                    marginTop: "45.01px",
                                    background: "#138e9c",
                                    color: "white",
                                  }}
                                  size="small"
                                >
                                  <CircularProgress
                                    size={22}
                                    style={{ color: "white" }}
                                  />
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    Uploading...
                                  </span>
                                </Button>
                              ) : (
                                <button
                                  style={{
                                    marginTop: "10px ",
                                    padding: "7px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="button-primary"
                                  type="submit"
                                  disabled={
                                    buttonColor === "red" ? true : false
                                  }
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </div>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0px",
                  borderBottom: "2px solid #ddd",
                  fontFamily: "Dosis",
                }}
              >
                <h5>
                  {nameOfTheModel && nameOfTheModel.split(" ")[0]}{" "}
                  {nameOfTheModel && nameOfTheModel.split(" ")[1]} Details
                </h5>
                <Button
                  component="label"
                  size="small"
                  color="primary"
                  style={{ marginTop: "5px" }}
                  onClick={() => getZohoLogs()}
                >
                  <RefreshIcon style={{ fontSize: "20px" }} />
                  Refresh
                </Button>
              </div>

              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Created From Date</th>
                      <th>Modified From Date</th>
                      <th>Created To Date</th>
                      <th>Modified To Date</th>
                      <th>LAST FETCH DATE & TIME</th>
                      <th>Fetch Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logsLoader ? (
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Loading..Please Wait!
                        </td>
                      </tr>
                    ) : (
                      <>
                        {logRecords?.map((v) => {
                          return (
                            <tr>
                              <td>
                                {moment(
                                  v.request_details.created_from_date
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  v.request_details.modified_from_date
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  v.request_details.created_to_date
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(
                                  v.request_details.modified_to_date
                                ).format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {moment(v.initiated_datetime).format(
                                  "DD-MM-YYYY , h:mm:ss a"
                                )}
                              </td>
                              <td>{v.fetch_status}</td>
                              <td style={{ cursor: "pointer" }}>
                                {v.fetch_status === "Pass" ? (
                                  <VisibilityIcon
                                    className="action-icon-button showIcon"
                                    onClick={() => handleZohoButton(v.id)}
                                  />
                                ) : v.fetch_status === "Fail" ? (
                                  <VisibilityIcon
                                    style={{ color: "red" }}
                                    onClick={() => handleZohoButton(v.id)}
                                  />
                                ) : (
                                  <VisibilityIcon color="disabled" />
                                )}
                              </td>
                            </tr>
                          );
                          // onClick={() => handleActionButton(v.id)}
                        })}
                        {logRecords.length === 0 && (
                          <tr>
                            <td
                              style={{ color: "red", fontWeight: "bold" }}
                              colSpan={7}
                            >
                              No Record Found!!
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500]}
                component="div"
                count={rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button-outlined-secondary "
            onClick={closeZohoDrawer}
          >
            Close
          </button>
        </div>

        <RightDrawer
          drawerName="Zoho Details"
          open={drawer}
          // onClose={addFormCloseDrawerHandler}
        >
          {/* zohoData[index][v.id] */}
          {drawerKeys?.map((v) => {
            return (
              <TextField
                className={designForZohoModel.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label={v.name}
                value={
                  index != null && zohoData[index][v.id]
                    ? zohoData[index][v.id]
                    : ""
                }
                style={{ marginTop: "15px" }}
              />
            );
          })}

          <div className="mt-20">
            <button
              type="button"
              className="button-outlined-secondary"
              onClick={closeDrawer}
            >
              close
            </button>
          </div>
        </RightDrawer>
      </Box>
    </Modal>
  );
};

export default ZohoModel;
