import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import axios from "../../../axios-order";
import SelectField from '../../../components/ui/basicSelectInput';
import { Button, Typography, Paper } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DownloadIcon from '@mui/icons-material/Download';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August08", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
];

const reportTypes = [
    { value: "GSTR1", label: "GSTR 1" },
    { value: "GSTR2A", label: "GSTR 2A" },
    { value: "GSTR2B", label: "GSTR 2B" },
    { value: "GSTR3B", label: "GSTR 3B" },
];

export default function MultiMonth() {
    const [yearFromArr, setFromYearArr] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState('');
    const [selectedFromMonth, setSelectedFromMonth] = useState('');
    const [selectedToMonth, setSelectedToMonth] = useState('');
    const [selectedReportType, setSelectedReportType] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState("");

    useEffect(() => {
        setFromYearValues();
    }, []);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };

    const setFromYearValues = () => {
        let current_year = new Date().getFullYear();
        let yearArr = [];
        for (let i = current_year - 10; i <= current_year + 10; i++) {
            let startYear = i;
            let endYear = i + 1;
            let endYearShort = endYear.toString().slice(-2);
            yearArr.push({ id: i, year: `${startYear}-${endYearShort}` });
        }
        setFromYearArr(yearArr);
    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const onSubmitHandler = () => {
        const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `${selectedReportType} Multi Month.xlsx`;

        const payLoadData = {
            org_gstin: localStorage.getItem("gst"),
            from_month: selectedFromMonth,
            to_month: selectedToMonth,
            financial_year: selectedFinancialYear,
            report_type: selectedReportType,
            business_entity_id: localStorage.getItem("business_entity_id")
        };

        axios
            .post('/multi_month_export_gstr', payLoadData)
            .then((res) => {
                if (!res.data.status) {
                    handleSnackbarOpen(res.data.message, 'error')
                } else {
                    downloadBase64File(contentType, res.data.file, filename);
                }

            })
            .catch((error) => console.log(error));
    };

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }} mb={8}>
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: 'center',
                        letterSpacing: '0.04rem',
                        fontFamily: `'Montserrat', sans-serif`,
                        textTransform: 'uppercase',
                    }}
                >
                    <span style={{ fontWeight: 'bold', color: '#1D3557' }}>Multi-Month GSTR</span>
                    <span style={{ fontWeight: 'normal', color: "#212121" }}> REPORT</span>
                    <SaveAltIcon style={{ marginLeft: '10px', color: '#3B3B6B', fontSize: '1.8rem', verticalAlign: 'middle', marginBottom: '4px' }} />
                </Typography>
            </Grid>

            {/* Paper Wrapper for the form */}
            <Paper elevation={3} style={{ padding: '20px' }}>
                <ValidatorForm onSubmit={onSubmitHandler} style={{ width: '100%' }}>
                    <Grid container spacing={2} style={{ width: '100%' }} alignItems="center">
                        <Grid item xs={3} sm={3} md={3}>
                            <SelectField
                                validator={["required"]}
                                SelectLabel="Financial Year"
                                errorMsg={["This field is required"]}
                                choice_id="year"
                                choice_name="year"
                                choices={yearFromArr}
                                name="financial_year"
                                value={selectedFinancialYear}
                                textOnchangeFunction={(e) => setSelectedFinancialYear(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2}>
                            <SelectField
                                validator={["required"]}
                                SelectLabel="From Month"
                                errorMsg={["This field is required"]}
                                choice_id="label"
                                choice_name="label"
                                choices={months}
                                name="period"
                                value={selectedFromMonth}
                                textOnchangeFunction={(e) => setSelectedFromMonth(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2}>
                            <SelectField
                                SelectLabel="To Month"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_id="label"
                                choice_name="label"
                                choices={months}
                                name="period"
                                value={selectedToMonth}
                                textOnchangeFunction={(e) => setSelectedToMonth(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2}>
                            <SelectField
                                SelectLabel="Report Type"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_id="value"
                                choice_name="label"
                                choices={reportTypes}
                                name="report_type"
                                value={selectedReportType}
                                textOnchangeFunction={(e) => setSelectedReportType(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} mt={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ marginTop: '15px' }}
                                fullWidth
                                endIcon={<DownloadIcon />}
                            >
                                Generate
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    );
}
