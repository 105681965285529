import React, { useState } from "react";
import axios from "../../../axios-order";
import { useEffect } from "react";
import BoxWrapper from "../../../components/ui/boxWrapper";

export default function TableFroReasonwise({
  data,
  ForExport,
  postApiForRegionName,
  postApiForBranchName,
  postApiForReportingPeriodName,
}) {
  const [dataTable, setDataTable] = useState(data);
  const [forTable, setForTable] = useState(data.key_list);
  const [searchForTable, setSearchForTable] = useState(data.key_list);
  const length = dataTable.data.length;

  useEffect(() => {
    setDataTable(data);
    setForTable(data.key_list);
    setSearchForTable(data.key_list);
  }, [data]);

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---b2bExportData

  const handleSearch = (e) => {
    let value = e.target.value;
    var search = searchForTable.filter((v) => {
      return String(v).toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    setForTable(search);
  };

  //..................DownloadBase For Export To Excell.............
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //.......................Export To Excel................................
  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Reason Wise Summary.xlsx`;
    axios
      .post(`/iars_reason_wise/download`, {
        records: ForExport,
        business_entity_id: localStorage.getItem("business_entity_id"),
        branchname: postApiForBranchName,
        reportingPeriod: postApiForReportingPeriodName,
        RegionName: postApiForRegionName,
      })
      .then((res) => {
        if (res.data.status === "fail") {
          alert("Unable to process download....");
        } else {
          downloadBase64File(contentType, res.data.file, filename);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>

      <BoxWrapper
        boxTitle="Variance Analysis Report - Shortage and Excess"
        exportToExcelEnable={true}
        exportToExcelFn={export_to_excel}
        enableSearchInput={true}
        searchInputPlaceholder="Search By Reason...."
        searchInputOnchange={handleSearch}
        backButtonForZohoEnable={false}
        importToCwsEnableForZoho={false}
      >
        <>
          <div className="responsive-table">
            <table className="custome-table">

              <thead>
                <tr>
                  <th rowSpan={2} align="right">
                    Reason
                  </th>
                  <th colSpan={length} align="center">
                    Branch Name
                  </th>
                </tr>
                <tr>
                  {dataTable.data.map((v) => {
                    return <th align="right">{v.Branch}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {forTable.map((v) => {
                  if (v === "Grand Total") {
                    return (
                      <tr style={{ fontWeight: "bold", fontSize: "15px", background: "rgb(221, 221, 221)" }}>
                        <td>{v}</td>
                        {dataTable.data.map((item) => {
                          return <td>{item.data[v] < 0 ? <div style={{ color: "red" }}>{numberFormat(item.data[v].toFixed(2))}</div> :
                            <div>{numberFormat(item.data[v].toFixed(2))}</div>}</td>;
                        })}
                      </tr>
                    );
                  }
                  else {
                    return (
                      <tr>
                        <td>{v}</td>
                        {dataTable.data.map((item) => {
                          return <td >{item.data[v] < 0 ? <div style={{ color: "red" }}>{numberFormat(item.data[v].toFixed(2))}</div>
                            : <div>{numberFormat(item.data[v].toFixed(2))}</div>}</td>;
                        })}
                      </tr>
                    );
                  }
                })}

                {dataTable.data.length === 0 && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ color: "red", textAlign: "center" }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              /> */}
        </>

      </BoxWrapper>
    </React.Fragment>
  );
}
