import React from "react";
import classes from "./GSTReconciliation.module.css";

function GSTSummaryDashBoard(props) {
  const { b2bExportData, cdnr, ddnr, b2ba } = props;

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---b2bExportData

  return (
    <>
      <div className={classes.B2cExport}>
        <div
          style={{
            background: "#3b3b6b ",
            padding: "6px 0px 2px 10px",
            marginBottom: "10px",
            color: "#fff",
          }}
        >
          <h6>B2B and Export Invoice(s)</h6>
        </div>
        <div className="responsive-table">
          <table className="custome-table">
            <tr>
              <th>Particulars</th>
              <th>No. of Invoices</th>
              <th>Taxable Value</th>
              <th>IGST</th>
              <th>CGST</th>
              <th>SGST</th>
              <th>Cess</th>
              <th>Total GST</th>
              <th>Total Invoice Value</th>
            </tr>
            <tr>
              <td>Total Invoices - As Per Books</td>
              <td>
                {b2bExportData &&
                  b2bExportData.totalInvoicesAsPerBooks.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_taxable_value
                  )}
              </td>
              {/* <td>{b2bExportData && numberFormat(b2bExportData.totalInvoicesAsPerBooks.total_taxable_value)}</td> */}
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_igst
                  )}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_cgst
                  )}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_sgst
                  )}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_cess
                  )}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerBooks.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerBooks.total_invoice_value
                  )}
              </td>
            </tr>
            <tr>
              <td>Total Invoices - As Per GSTR-1</td>
              <td>
                {b2bExportData &&
                  b2bExportData.totalInvoicesAsPerGstr.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerGstr.total_taxable_value
                  )}
              </td>
              {/*  <td>{b2bExportData && numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_taxable_value)}</td> */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_cess)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.totalInvoicesAsPerGstr.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.totalInvoicesAsPerGstr.total_invoice_value
                  )}
              </td>
            </tr>
            <tr>
              <td>Matched Invoices</td>
              <td>{b2bExportData && b2bExportData.matched.total_invoice}</td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_taxable_value)}
              </td>
              {/*    <td>{b2bExportData && numberFormat(b2bExportData.matched.total_taxable_value)}</td>  */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_cess)}
              </td>
              <td>
                {b2bExportData && numberFormat(b2bExportData.matched.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.matched.total_invoice_value)}
              </td>
            </tr>
            <tr>
              <td>Mis-Match Invoice</td>
              <td>
                {b2bExportData && b2bExportData.missMatched.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_taxable_value)}
              </td>
              {/*    <td>{b2bExportData && numberFormat(b2bExportData.missMatched.total_taxable_value)}</td>  */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_cess)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missMatched.total_invoice_value)}
              </td>
            </tr>
            <tr>
              <td>Intelligence Matching</td>
              <td>
                {b2bExportData &&
                  b2bExportData.intelligentMatched.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.intelligentMatched.total_taxable_value
                  )}
              </td>
              {/*    <td>{b2bExportData && numberFormat(b2bExportData.intelligentMatched.total_taxable_value)}</td>  */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.intelligentMatched.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.intelligentMatched.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.intelligentMatched.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.intelligentMatched.total_cess)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.intelligentMatched.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.intelligentMatched.total_invoice_value
                  )}
              </td>
            </tr>

            <tr>
              <td>Invoices Not in Books</td>
              <td>
                {b2bExportData && b2bExportData.missingInAccounts.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.missingInAccounts.total_taxable_value
                  )}
              </td>
              {/*    <td>{b2bExportData && numberFormat(b2bExportData.missingInAccounts.total_taxable_value)}</td>  */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInAccounts.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInAccounts.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInAccounts.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInAccounts.total_cess)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInAccounts.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(
                    b2bExportData.missingInAccounts.total_invoice_value
                  )}
              </td>
            </tr>
            <tr>
              <td>Invoices Not In GSTR1</td>
              <td>
                {b2bExportData && b2bExportData.missingInGstr.total_invoice}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_taxable_value)}
              </td>
              {/*    <td>{b2bExportData && numberFormat(b2bExportData.missingInGstr.total_taxable_value)}</td>  */}
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_igst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_cgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_sgst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_cess)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_gst)}
              </td>
              <td>
                {b2bExportData &&
                  numberFormat(b2bExportData.missingInGstr.total_invoice_value)}
              </td>
            </tr>
          </table>
        </div>
      </div>

      {cdnr && Object.keys(cdnr).length > 0 && (
        <>
          <div className={classes.b2cCreditNote} style={{ marginTop: "20px" }}>
            <div
              style={{
                background: "#3b3b6b",
                padding: "6px 0px 2px 10px",
                marginBottom: "10px",
                color: "#fff",
              }}
            >
              <h6>B2B Credit Notes</h6>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <tr>
                  <th>Particulars</th>
                  <th>No. of Invoices</th>
                  <th>Taxable Value</th>
                  <th>IGST</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>Cess</th>
                  <th>Total GST</th>
                  <th>Total Invoice Value</th>
                </tr>

                <tr>
                  <td>Total Credit Note - As Per Books</td>
                  <td>{cdnr && cdnr.totalInvoicesAsPerBooks.total_invoice}</td>
                  <td>
                    {cdnr &&
                      numberFormat(
                        cdnr.totalInvoicesAsPerBooks.total_taxable_value
                      )}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.totalInvoicesAsPerBooks.total_taxable_value)}</td> */}
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerBooks.total_igst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerBooks.total_cgst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerBooks.total_sgst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerBooks.total_cess)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerBooks.total_gst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(
                        cdnr.totalInvoicesAsPerBooks.total_invoice_value
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Total Credit Note - As Per GSTR-1</td>
                  <td>{cdnr && cdnr.totalInvoicesAsPerGstr.total_invoice}</td>
                  <td>
                    {cdnr &&
                      numberFormat(
                        cdnr.totalInvoicesAsPerGstr.total_taxable_value
                      )}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.totalInvoicesAsPerGstr.total_taxable_value)}</td>  */}
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerGstr.total_igst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerGstr.total_cgst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerGstr.total_sgst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerGstr.total_cess)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.totalInvoicesAsPerGstr.total_gst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(
                        cdnr.totalInvoicesAsPerGstr.total_invoice_value
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Matched Credit Note</td>
                  <td>{cdnr && cdnr.matched.total_invoice}</td>
                  <td>
                    {cdnr && numberFormat(cdnr.matched.total_taxable_value)}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.matched.total_taxable_value)}</td>  */}
                  <td>{cdnr && numberFormat(cdnr.matched.total_igst)}</td>
                  <td>{cdnr && numberFormat(cdnr.matched.total_cgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.matched.total_sgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.matched.total_cess)}</td>
                  <td>{cdnr && numberFormat(cdnr.matched.total_gst)}</td>
                  <td>
                    {cdnr && numberFormat(cdnr.matched.total_invoice_value)}
                  </td>
                </tr>

                <tr>
                  <td>Mis-Match Credit Note</td>
                  <td>{cdnr && cdnr.missMatched.total_invoice}</td>
                  <td>
                    {cdnr && numberFormat(cdnr.missMatched.total_taxable_value)}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.missMatched.total_taxable_value)}</td> */}
                  <td>{cdnr && numberFormat(cdnr.missMatched.total_igst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missMatched.total_cgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missMatched.total_sgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missMatched.total_cess)}</td>
                  <td>{cdnr && numberFormat(cdnr.missMatched.total_gst)}</td>
                  <td>
                    {cdnr && numberFormat(cdnr.missMatched.total_invoice_value)}
                  </td>
                </tr>
                <tr>
                  <td>Intelligence Matching</td>
                  <td>{cdnr && cdnr.intelligentMatched.total_invoice}</td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.intelligentMatched.total_taxable_value)}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.intelligentMatched.total_taxable_value)}</td>  */}
                  <td>
                    {cdnr && numberFormat(cdnr.intelligentMatched.total_igst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.intelligentMatched.total_cgst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.intelligentMatched.total_sgst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.intelligentMatched.total_cess)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.intelligentMatched.total_gst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.intelligentMatched.total_invoice_value)}
                  </td>
                </tr>
                <tr>
                  <td>Credit Note Not in Books</td>
                  <td>{cdnr && cdnr.missingInAccounts.total_invoice}</td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.missingInAccounts.total_taxable_value)}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.missingInAccounts.total_taxable_value)}</td> */}
                  <td>
                    {cdnr && numberFormat(cdnr.missingInAccounts.total_igst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.missingInAccounts.total_cgst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.missingInAccounts.total_sgst)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.missingInAccounts.total_cess)}
                  </td>
                  <td>
                    {cdnr && numberFormat(cdnr.missingInAccounts.total_gst)}
                  </td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.missingInAccounts.total_invoice_value)}
                  </td>
                </tr>
                <tr>
                  <td>Credit Note Not In GSTR1</td>
                  <td>{cdnr && cdnr.missingInGstr.total_invoice}</td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.missingInGstr.total_taxable_value)}
                  </td>
                  {/*    <td>{cdnr && numberFormat(cdnr.missingInGstr.total_taxable_value)}</td> */}
                  <td>{cdnr && numberFormat(cdnr.missingInGstr.total_igst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missingInGstr.total_cgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missingInGstr.total_sgst)}</td>
                  <td>{cdnr && numberFormat(cdnr.missingInGstr.total_cess)}</td>
                  <td>{cdnr && numberFormat(cdnr.missingInGstr.total_gst)}</td>
                  <td>
                    {cdnr &&
                      numberFormat(cdnr.missingInGstr.total_invoice_value)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </>
      )}

      {ddnr && Object.keys(ddnr).length > 0 && (
        <div className={classes.b2cDebitNote} style={{ marginTop: "20px" }}>
          <div
            style={{
              background: "#3b3b6b",
              padding: "6px 0px 2px 10px",
              marginBottom: "10px",
              color: "#fff",
            }}
          >
            <h6>B2B Debit Notes</h6>
          </div>
          <div className="responsive-table">
            <table className="custome-table">
              <tr>
                <th>Particulars</th>
                <th>No. of Invoices</th>
                <th>Taxable Value</th>
                <th>IGST</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>Cess</th>
                <th>Total GST</th>
                <th>Total Invoice Value</th>
              </tr>

              <tr>
                <td>Total Debit Note - As Per Books</td>
                <td>{ddnr && ddnr.totalInvoicesAsPerBooks.total_invoice}</td>
                <td>
                  {ddnr &&
                    numberFormat(
                      ddnr.totalInvoicesAsPerBooks.total_taxable_value
                    )}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.totalInvoicesAsPerBooks.total_igst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.totalInvoicesAsPerBooks.total_cgst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.totalInvoicesAsPerBooks.total_sgst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.totalInvoicesAsPerBooks.total_cess)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerBooks.total_gst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(
                      ddnr.totalInvoicesAsPerBooks.total_invoice_value
                    )}
                </td>
              </tr>
              <tr>
                <td>Total Debit Note - As Per GSTR-1</td>
                <td>{ddnr && ddnr.totalInvoicesAsPerGstr.total_invoice}</td>
                <td>
                  {ddnr &&
                    numberFormat(
                      ddnr.totalInvoicesAsPerGstr.total_taxable_value
                    )}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerGstr.total_igst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerGstr.total_cgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerGstr.total_sgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerGstr.total_cess)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.totalInvoicesAsPerGstr.total_gst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(
                      ddnr.totalInvoicesAsPerGstr.total_invoice_value
                    )}
                </td>
              </tr>
              <tr>
                <td>Matched Debit Note</td>
                <td>{ddnr && ddnr.matched.total_invoice}</td>
                <td>
                  {ddnr && numberFormat(ddnr.matched.total_taxable_value)}
                </td>
                <td>{ddnr && numberFormat(ddnr.matched.total_igst)}</td>
                <td>{ddnr && numberFormat(ddnr.matched.total_cgst)}</td>
                <td>{ddnr && numberFormat(ddnr.matched.total_sgst)}</td>
                <td>{ddnr && numberFormat(ddnr.matched.total_cess)}</td>
                <td>{ddnr && numberFormat(ddnr.matched.total_gst)}</td>
                <td>
                  {ddnr && numberFormat(ddnr.matched.total_invoice_value)}
                </td>
              </tr>

              <tr>
                <td>Mis-Match Debit Note</td>
                <td>{ddnr && ddnr.missMatched.total_invoice}</td>
                <td>
                  {ddnr && numberFormat(ddnr.missMatched.total_taxable_value)}
                </td>
                <td>{ddnr && numberFormat(ddnr.missMatched.total_igst)}</td>
                <td>{ddnr && numberFormat(ddnr.missMatched.total_cgst)}</td>
                <td>{ddnr && numberFormat(ddnr.missMatched.total_sgst)}</td>
                <td>{ddnr && numberFormat(ddnr.missMatched.total_cess)}</td>
                <td>{ddnr && numberFormat(ddnr.missMatched.total_gst)}</td>
                <td>
                  {ddnr && numberFormat(ddnr.missMatched.total_invoice_value)}
                </td>
              </tr>
              <tr>
                <td>Intelligence Matching</td>
                <td>{ddnr && ddnr.intelligentMatched.total_invoice}</td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.intelligentMatched.total_taxable_value)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.intelligentMatched.total_igst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.intelligentMatched.total_cgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.intelligentMatched.total_sgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.intelligentMatched.total_cess)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.intelligentMatched.total_gst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.intelligentMatched.total_invoice_value)}
                </td>
              </tr>
              <tr>
                <td>Debit Note Not in Books</td>
                <td>{ddnr && ddnr.missingInAccounts.total_invoice}</td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.missingInAccounts.total_taxable_value)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInAccounts.total_igst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInAccounts.total_cgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInAccounts.total_sgst)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInAccounts.total_cess)}
                </td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInAccounts.total_gst)}
                </td>
                <td>
                  {ddnr &&
                    numberFormat(ddnr.missingInAccounts.total_invoice_value)}
                </td>
              </tr>
              <tr>
                <td>Debit Note Not In GSTR1</td>
                <td>{ddnr && ddnr.missingInGstr.total_invoice}</td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInGstr.total_taxable_value)}
                </td>
                <td>{ddnr && numberFormat(ddnr.missingInGstr.total_igst)}</td>
                <td>{ddnr && numberFormat(ddnr.missingInGstr.total_cgst)}</td>
                <td>{ddnr && numberFormat(ddnr.missingInGstr.total_sgst)}</td>
                <td>{ddnr && numberFormat(ddnr.missingInGstr.total_cess)}</td>
                <td>{ddnr && numberFormat(ddnr.missingInGstr.total_gst)}</td>
                <td>
                  {ddnr && numberFormat(ddnr.missingInGstr.total_invoice_value)}
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}

      {b2ba && Object.keys(b2ba).length > 0 && (
        <div className={classes.b2cDebitNote} style={{ marginTop: "20px" }}>
          <div
            style={{
              background: "#3b3b6b",
              padding: "6px 0px 2px 10px",
              marginBottom: "10px",
              color: "#fff",
            }}
          >
            <h6>B2B Amendments</h6>
          </div>
          <div className="responsive-table">
            <table className="custome-table">
              <tr>
                <th>Particulars</th>
                <th>No. of Invoices</th>
                <th>Taxable Value</th>
                <th>IGST</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>Cess</th>
                <th>Total GST</th>
                <th>Total Invoice Value</th>
              </tr>

              <tr>
                <td>Total Invoices - As Per GSTR-1</td>
                <td>{b2ba && b2ba.totalInvoicesAsPerGstr.total_invoice}</td>
                <td>
                  {b2ba &&
                    numberFormat(
                      b2ba.totalInvoicesAsPerGstr.total_taxable_value
                    )}
                </td>
                {/*   <td>{b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_taxable_value)}</td> */}
                <td>
                  {b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_igst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_cgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_sgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_cess)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.totalInvoicesAsPerGstr.total_gst)}
                </td>
                <td>
                  {b2ba &&
                    numberFormat(
                      b2ba.totalInvoicesAsPerGstr.total_invoice_value
                    )}
                </td>
              </tr>

              <tr>
                <td>Matched Invoices</td>
                <td>{b2ba && b2ba.matched.total_invoice}</td>
                <td>
                  {b2ba && numberFormat(b2ba.matched.total_taxable_value)}
                </td>
                {/*    <td>{b2ba && numberFormat(b2ba.matched.total_taxable_value)}</td> */}
                <td>{b2ba && numberFormat(b2ba.matched.total_igst)}</td>
                <td>{b2ba && numberFormat(b2ba.matched.total_cgst)}</td>
                <td>{b2ba && numberFormat(b2ba.matched.total_sgst)}</td>
                <td>{b2ba && numberFormat(b2ba.matched.total_cess)}</td>
                <td>{b2ba && numberFormat(b2ba.matched.total_gst)}</td>
                <td>
                  {b2ba && numberFormat(b2ba.matched.total_invoice_value)}
                </td>
              </tr>

              <tr>
                <td>Mis-Match Invoice</td>
                <td>{b2ba && b2ba.missMatched.total_invoice}</td>
                <td>
                  {b2ba && numberFormat(b2ba.missMatched.total_taxable_value)}
                </td>
                {/*    <td>{b2ba && numberFormat(b2ba.missMatched.total_taxable_value)}</td> */}
                <td>{b2ba && numberFormat(b2ba.missMatched.total_igst)}</td>
                <td>{b2ba && numberFormat(b2ba.missMatched.total_cgst)}</td>
                <td>{b2ba && numberFormat(b2ba.missMatched.total_sgst)}</td>
                <td>{b2ba && numberFormat(b2ba.missMatched.total_cess)}</td>
                <td>{b2ba && numberFormat(b2ba.missMatched.total_gst)}</td>
                <td>
                  {b2ba && numberFormat(b2ba.missMatched.total_invoice_value)}
                </td>
              </tr>
              <tr>
                <td>Intelligence Matching</td>
                <td>{b2ba && b2ba.intelligentMatched.total_invoice}</td>
                <td>
                  {b2ba &&
                    numberFormat(b2ba.intelligentMatched.total_taxable_value)}
                </td>
                {/*    <td>{b2ba && numberFormat(b2ba.intelligentMatched.total_taxable_value)}</td> */}
                <td>
                  {b2ba && numberFormat(b2ba.intelligentMatched.total_igst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.intelligentMatched.total_cgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.intelligentMatched.total_sgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.intelligentMatched.total_cess)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.intelligentMatched.total_gst)}
                </td>
                <td>
                  {b2ba &&
                    numberFormat(b2ba.intelligentMatched.total_invoice_value)}
                </td>
              </tr>
              <tr>
                <td>Invoices Not in Books</td>
                <td>{b2ba && b2ba.missingInAccounts.total_invoice}</td>
                <td>
                  {b2ba &&
                    numberFormat(b2ba.missingInAccounts.total_taxable_value)}
                </td>
                {/*    <td>{b2ba && numberFormat(b2ba.missingInAccounts.total_taxable_value)}</td> */}
                <td>
                  {b2ba && numberFormat(b2ba.missingInAccounts.total_igst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.missingInAccounts.total_cgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.missingInAccounts.total_sgst)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.missingInAccounts.total_cess)}
                </td>
                <td>
                  {b2ba && numberFormat(b2ba.missingInAccounts.total_gst)}
                </td>
                <td>
                  {b2ba &&
                    numberFormat(b2ba.missingInAccounts.total_invoice_value)}
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default GSTSummaryDashBoard;
