import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "./BankReconciliation.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";
import InputField from "../../../../components/ui/inputField";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import SelectField from "../../../../components/ui/basicSelectInput";
import CustomTable from "../../../../components/ui/Table/Table";
import RefreshIcon from "@mui/icons-material/Refresh";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const dateFormat = (value) => {
  if (!value) {
    return "";
  }
  const current_date = new Date(value);
  const yy = current_date.getFullYear().toString();
  const mm =
    (current_date.getMonth() + 1 < 10 ? "0" : "") +
    (current_date.getMonth() + 1).toString();
  const dd =
    (current_date.getDate() < 10 ? "0" : "") +
    current_date.getDate().toString();
  const hh =
    (current_date.getHours() < 10 ? "0" : "") +
    current_date.getHours().toString();
  const m =
    (current_date.getMinutes() < 10 ? "0" : "") +
    current_date.getMinutes().toString();

  return `${dd}/${mm}/${yy} ${hh}:${m}`;
};
class BankReconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: "Botmantra",
      file_name: "",
      historyTableOriginalData: [],
      historyTable: [],
      loading: false,
      modalShow: false,
      customRoundOff: null,
      threshold_value: 0,
      roundOff: 0,
      bankArr: [
        { id: "icici", value: "ICICI" },
        { id: "hdfc", value: "HDFC" },
        { id: "bob", value: "Bank Of Baroda" },
        { id: "axis", value: "AXIS" },
        { id: "canara", value: "CANARA" },
        { id: "yes", value: "YES BANK" },
      ],
      dataCol: [
        {
          key: "bank",
          render: (index, row) => {
            return this.state.bankArr.filter((value) => {
              return value["id"] === row["bank"];
            })[0]["value"];
          },
        },
        {
          key: "created_time",
          render: (index, row) => {
            return dateFormat(row["created_time"]);
          },
        },
        {
          key: "status",
          render: (index, row) => {
            return row["status"] === 1 ? "Success" : "Failed";
          },
        },
      ],
      //   dataCol: null,
      data: null,
      oldData: null,
      dataHeader: [
        { label: "Bank" },
        { label: "Created Date/Time" },
        { label: "Status" },
      ],
      actions: [
        {
          label: "View",
          onclickEvent: (index, row) => {
            if (row["status"] === 1) {
              this.showDetails(index);
            } else {
              alert("Invalid File");
            }
          },
        },
      ],
      searchInput: "",
    };
    this.getDetails = this.getDetails.bind(this);
  }

  showDetails = (id) => {
    this.props.history.push({
      pathname: `/BankReconDetails`,
      data: this.state.data[id]["id"], // your data array of objects
    });
  };

  openModal = (id) => {
    this.setState({
      invoiceModalOpen: true,
      targetId: id,
    });
  };

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchData = this.state.oldData.filter((value) => {
      const query = searchInput.toLowerCase();
      const status_query =
        String("success").toLowerCase().indexOf(query) >= 0 ? 1 : 0;
      return (
        value.status === status_query ||
        String(value.bank).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ data: searchData });
  };

  // ---End---

  componentDidMount = () => {
    this.getDetails();
  };

  // File uploader for [Form 26AS]
  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ GSTJsonfFile: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  getDetails = () => {
    axios
      .get(
        `/arc/bank_reconciliation/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          this.setState({
            data: res.data.data,
            oldData: res.data.data,
          });
        }
      });
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // submit for file upload form
  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    toast.configure();
    axios
      .post(`/arc/bank_reconciliation`, {
        file: `${this.state.GSTJsonfFile}`,
        business_entity_id: localStorage.getItem("business_entity_id"),
        bank: this.state.bank,
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
        } else {
          toast.success("File processing completed.", {
            autoClose: 5000,
            progress: 0,
          });
        }
        this.getDetails();
        this.setState({ loading: false, file_name: "" });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.getDetails();
        this.setState({ loading: false, file_name: "" });
      });
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  newReconModalOpen = () => {
    this.setState({
      modalShow: true,
    });
  };

  newReconModalClose = () => {
    this.setState({
      modalShow: false,
      threshold_value: "",
      roundOffValue: 0,
    });
  };

  inputHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  refreshUploadHistoryTable = () => {
    this.getDetails();
  };

  render() {
    return (
      <React.Fragment>
        {/* invoice modal */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.invoiceModalOpen}
          // onHide={this.addPaymentAdviceModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.paymentAdviceModalSearchInput ?? ""}
                      textOnchangeFunction={this.arcPaymentAdviceModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <CustomTable
                headers={this.state.invoiceHeader}
                columns={this.state.invoiceCol}
                data={this.state.invoiceData}
                // actions={this.state.actions}
                multiselect={true}
                // multiselectkey="cust_pan"
                name="invoices"
                // selectCondition={(item) => !item.empanelled_id}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="button-primary" onClick={this.invoiceUpdate}>
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              onClick={this.invoiceModalClose}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* invoid modal end */}
        <div>
          {this.state.loading ? (
            <Button
              variant="contained"
              disabled
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <CircularProgress size={22} />{" "}
              <span
                style={{
                  textTransform: "capitalize",
                  paddingLeft: "10px",
                }}
              >
                Uploading...
              </span>
            </Button>
          ) : (
            <button
              className="button-outlined-primary"
              onClick={this.newReconModalOpen}
            >
              New Receipts Reconciliation
            </button>
          )}

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            onHide={this.newReconModalClose}
            dialogClassName="modal-50w"
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Receipts Reconciliation</Modal.Title>
            </Modal.Header>
            <ValidatorForm
              ref="form"
              onSubmit={this.onSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <Modal.Body>
                {/* Form 26AS upload [UPLOADER(File input)] */}

                <Grid container spacing={3}>
                  <Grid item sm={2}>
                    <p style={{ fontSize: "16px" }}>
                      Select Bank
                      <span style={{ color: "red" }}>*</span>:
                    </p>
                  </Grid>
                  <Grid item sm={4}>
                    <SelectField
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choice_id="id"
                      choice_name="value"
                      name="bank"
                      value={this.state.bank ?? ""}
                      choices={this.state.bankArr}
                      textOnchangeFunction={this.inputHandler}
                    />
                  </Grid>

                  <Grid item xs={2} md={2}>
                    <label
                      className="button-primary"
                      style={{ cursor: "pointer" }}
                    >
                      Browse
                      <input
                        id="file_value"
                        name="GSTJsonfFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        type="file"
                        hidden
                        onChange={this.fileUploader}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      placeholder="Choose file"
                      value={this.state.file_name}
                      name="GSTJson_file_uploader"
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="button-primary"
                  type="submit"
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </button>
                <button
                  className="button-outlined-secondary"
                  type="button"
                  onClick={this.newReconModalClose}
                >
                  Close
                </button>
              </Modal.Footer>
            </ValidatorForm>
          </Modal>
        </div>

        {/* table */}
        <BoxWrapper
          boxTitle="Receipts Reconciliation"
          // add button
          enableAddButton={true}
          addButtonLabel="Refresh"
          addButtonHandlerFn={this.refreshUploadHistoryTable}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={this.handleSearchInputChange}
        >
          <div className="responsive-table">
            {/* <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_selleridAgency_TableHeader()}</tr>
                      {this.arc_selleridAgency_TableBody()}
                    </tbody>
                  </table> */}
            {this.state.data ? (
              <CustomTable
                headers={this.state.dataHeader}
                columns={this.state.dataCol}
                data={this.state.data}
                actions={this.state.actions}
              />
            ) : (
              ""
            )}
          </div>
        </BoxWrapper>
      </React.Fragment>
    );
  }
}
export default BankReconciliation;
