import { Grid, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import redImage from '../../asserts/images/red.png';
import greenImage from '../../asserts/images/green.png';
import yellowImage from '../../asserts/images/yellow.png';
import jsPDF from "jspdf";

const sheetStyles = makeStyles(() => ({
    mainDiv: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        padding: "0px 5px 0 5px",
        margin: "20px 20px 20px 20px",
        // letterSpacing:"1px"
    },
    topHeadingDiv: {
        textAlign: "center",
        fontSize: "small"
    },
    hrTag: {
        background: "rgb(83, 82, 82)",
        border: "1px solid rgb(83, 82, 82)",
        margin: "0px 0px 5px 0px"
    },
    pargarphTag: {
        lineHeight: "1.5em",
        fontSize: "12px",
        // letterSpacing:"1.5px"
    },
    thTag: {
        width: "30%"
    },
    tableparagraph: {
        textAlign: "center",
        fontSize: "small",
        color: "red",
        // letterSpacing:"2px"
    },
    lastParagraph: {
        color: "red",
        fontSize: "10px",
    },
    confidentialHeader: {
        fontSize: "120px",
        top: "52%",
        left:"57%",
        transform: "translate(-50%,-50%) ; rotate(-30deg)",
        textAlign: "center",
        opacity: "1.0",
        color: "#ddd",
        position: "absolute",
        // transform: "rotate(-30deg)",
        zIndex: "0",
    }
}))


export default function PdfDisplay({ handleCloseButton, dataForPdf, dataForPdfDownload }) {

    const styleClass = sheetStyles();
    const [testTrigger, setTrigger] = React.useState(false);

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            maximumSignificantDigits: 10,
        }).format(value);

    const PdfData = () => {
        setTrigger(true)
    }

    useEffect(() => {
        if (testTrigger) {
            function addWater(doc) {
                doc.setPage(1);
                doc.saveGraphicsState();
                doc.setGState(new doc.GState({ opacity: 0.2 }));
                doc.setTextColor(150);
                doc.setFontSize(100);
                doc.text("CONFIDENTIAL", 50, 700, null, 45);
                doc.restoreGraphicsState();

                return doc;
            };

            var doc = new jsPDF({
                orientation: "p",
                unit: "pt",
                format: "a4"
            });
            // doc.setPage(1);

            doc.html(document.querySelector("#content"), {
                callback: function (pdf) {
                    pdf = addWater(pdf);
                    pdf.save("CEREport.pdf");
                }
            });
            setTrigger(false);
        }
    }, [testTrigger])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <Button
                        size="small"
                        component="label"
                        fullWidth
                        style={{ marginTop: "0 0 0 0", color: "black" }}
                        onClick={handleCloseButton}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                    <p style={{ cursor: "pointer" }} onClick={() => { PdfData(); }}><DownloadIcon /></p>
                </Grid>
            </Grid>

            <div style={{ width: "850px", border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "5px", padding: "0 40px", margin: "3px auto" }} >
                <div style={{ textAlign: "center" }}>
                    <p style={{ color: "blue", fontSize: "large" }}>{dataForPdf?.dynamic_config_values?.config_values_data.EntityName}</p>
                    <p>{dataForPdf?.config_details?.headerSectionOfPDF?.customer_address}</p>
                    <p style={{ color: "red", fontWeight: "bold" }}>CREDIT EVALUATION REPORT FOR {dataForPdf?.dynamic_config_values?.config_values_data?.CustomerName}</p>
                    <p >{dataForPdf?.config_details?.headerSectionOfPDF?.reportingDate}{" "}{dataForPdf?.dynamic_config_values?.config_values_data?.date}</p>
                </div>
                <hr style={{
                    background: "rgb(83, 82, 82)",
                    border: "1px solid rgb(83, 82, 82)",
                    margin: "0px 0px 15px 0px"
                }} />

                <p style={{ lineHeight: "1.5em" }}>{dataForPdf?.config_details?.headerSectionOfPDF?.description1}<strong>{numberFormat(dataForPdf?.dynamic_config_values?.config_values_data?.credit_limit)}</strong>
                    {dataForPdf?.config_details?.headerSectionOfPDF?.description2}<br /><br />{dataForPdf?.config_details?.headerSectionOfPDF?.description3}</p>

                <div style={{ marginTop: "25px", background: "#E6E6E6 " }}>

                    <p className={styleClass.confidentialHeader}>CONFIDENTIAL</p>
                    <div style={{ position: "relative" }}>
                        <table>
                            <tbody>
                                <div style={{ padding: "5px" }}>
                                    <tr>
                                        <th className={styleClass.thTag} >

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.nameDSO}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.firstDescriptionForDSO}</p>
                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.dsoImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={90} height={90} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dsoImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={90} height={90} />) : (<img src={yellowImage} alt="Loading" width={90} height={90} />)}</>)
                                                }
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.secondDescriptionForDSO}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.DSOdays}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.DSO?.thirdDescriptionForDSO}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.dsoComments}</p>
                                            </div>
                                        </th>

                                        <th className={styleClass.thTag}>

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.nameDPO}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.firstDescriptionForDPO}</p>

                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.dpoImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={100} height={100} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dpoImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={100} height={100} />) : (<img src={yellowImage} alt="Loading" width={100} height={100} />)}</>)
                                                }

                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.secondDescriptionForDPO}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.DPOdays}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.DPO?.thirdDescriptionForDPO}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.dpoComments}
                                                </p>
                                            </div>
                                        </th>

                                        <th className={styleClass.thTag}>

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.nameDIO}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.firstDescriptionForDIO}</p>

                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.dioImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={100} height={100} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dioImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={100} height={100} />) : (<img src={yellowImage} alt="Loading" width={100} height={100} />)}</>)
                                                }
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.secondDescriptionForDIO}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.DIOdays}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.DIO?.thirdDescriptionForDIO}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.dioComments}</p>
                                            </div>
                                        </th>

                                    </tr>
                                </div>
                                <div style={{ marginTop: "5px 0 0 0", border: "0px solid black", padding: "5px" }}>
                                    <tr>
                                        <th className={styleClass.thTag}>

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.nameCurrentRatio}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.firstDescriptionForCurrentRatio}</p>

                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.currentRatioImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={100} height={100} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.currentRatioImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={100} height={100} />) : (<img src={yellowImage} alt="Loading" width={100} height={100} />)}</>)
                                                }

                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.secondDescriptionForCurrentRatio}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.Current_ratio}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.thirdDescriptionForCurrentRatio}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.currentRatioComments}</p>
                                            </div>
                                        </th>


                                        <th className={styleClass.thTag}>

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.nameNetWorth}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.firstDescriptionForNetWorth}</p>

                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.networthImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={100} height={100} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.networthImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={100} height={100} />) : (<img src={yellowImage} alt="Loading" width={100} height={100} />)}</>)
                                                }

                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.secondDescriptionForNetWorth}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.networth}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.netWorth?.thirdDescriptionForNetWorth}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.networthComments}
                                                </p>
                                            </div>
                                        </th>


                                        <th className={styleClass.thTag}>

                                            <p className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.opm?.nameOpm}</p>
                                            <div style={{ textAlign: "center", fontSize: "12px" }}>
                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.opm?.firstDescriptionForOpm}</p>

                                                {dataForPdf?.dynamic_config_values?.image_color_config_data?.opmImageColor === "Green" ?
                                                    (<img src={greenImage} alt="Loading" width={100} height={100} />) :
                                                    (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.opmImageColor === "Red" ?
                                                        (<img src={redImage} alt="Loading" width={100} height={100} />) : (<img src={yellowImage} alt="Loading" width={100} height={90} />)}</>)
                                                }

                                                <p>{dataForPdf?.config_details?.bodyOfPDF?.opm?.secondDescriptionForOpm}
                                                    {dataForPdf?.dynamic_config_values?.config_values_data?.OPM}
                                                    {dataForPdf?.config_details?.bodyOfPDF?.opm?.thirdDescriptionForOpm}
                                                    {dataForPdf?.dynamic_config_values?.config_statements_data?.opmComments}
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                </div>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p style={{ margin: "15px 0 15px 0" }}>{dataForPdf?.config_details?.bodyOfPDF?.aboveFooterSection?.lastParagrah}</p>

                <hr className={styleClass.hrTag} />

                <div>{dataForPdf?.config_details?.footerSectionOfPDF?.firstFooterComments}
                    <div className={styleClass.lastParagraph}>{dataForPdf?.config_details?.footerSectionOfPDF?.secondFooterComments}
                        {dataForPdf?.config_details?.footerSectionOfPDF?.thirdFooterComments}</div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <a href='https://www.cappitallwant.com/' style={{ cursor: "pointer", color: "blue" }}>Report powered by www.cappitallwant.com
                    </a>
                </div>
            </div>

            {/*..........For_PDF..............*/}
            {testTrigger &&
                <div id="content" style={{ width: "600px", height: "100vh"}}>
                    <div className={styleClass.mainDiv} >
                        <div className={styleClass.topHeadingDiv}>
                            <div style={{ color: "blue", fontSize: "small" }}>{dataForPdf?.dynamic_config_values?.config_values_data.EntityName}</div>
                            <div>{dataForPdf?.config_details?.headerSectionOfPDF?.customer_address}</div>
                            <div style={{ color: "red" }}>CREDIT EVALUATION REPORT FOR {dataForPdf?.dynamic_config_values?.config_values_data?.CustomerName}</div>
                            <div>{dataForPdf?.config_details?.headerSectionOfPDF?.reportingDate}{" "}{dataForPdf?.dynamic_config_values?.config_values_data?.date}</div>
                        </div>
                        <hr className={styleClass.hrTag} />

                        <div className={styleClass.pargarphTag}>{dataForPdf?.config_details?.headerSectionOfPDF?.description1}<strong>{dataForPdf?.dynamic_config_values?.config_values_data?.credit_limit}</strong>
                            {dataForPdf?.config_details?.headerSectionOfPDF?.description2}<br /><br />{dataForPdf?.config_details?.headerSectionOfPDF?.description3}</div>

                        <div style={{ marginTop: "5px", background: "#E6E6E6 " }}>

                            {/* <div className={styleClass.confidentialHeader}>CONFIDENTIAL</div> */}
                            <div style={{ position: "relative" }}>
                                <table style={{borderCollapse:"collapse"}}>
                                    <tbody>
                                        <div style={{ padding: "5px" }}>
                                            <tr>
                                                <th className={styleClass.thTag} >

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.nameDSO}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.firstDescriptionForDSO}</div>
                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.dsoImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dsoImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DSO?.secondDescriptionForDSO}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.DSOdays}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.DSO?.thirdDescriptionForDSO}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.dsoComments}</div>
                                                    </div>
                                                </th>

                                                <th className={styleClass.thTag}>

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.nameDPO}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.firstDescriptionForDPO}</div>

                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.dpoImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dpoImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }

                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DPO?.secondDescriptionForDPO}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.DPOdays}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.DPO?.thirdDescriptionForDPO}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.dpoComments}
                                                        </div>
                                                    </div>
                                                </th>

                                                <th className={styleClass.thTag}>

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.nameDIO}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.firstDescriptionForDIO}</div>

                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.dioImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.dioImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.DIO?.secondDescriptionForDIO}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.DIOdays}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.DIO?.thirdDescriptionForDIO}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.dioComments}</div>
                                                    </div>
                                                </th>

                                            </tr>
                                        </div>
                                        <div style={{ marginTop: "5px 0 0 0", border: "0px solid black", padding: "5px" }}>
                                            <tr>
                                                <th className={styleClass.thTag}>

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.nameCurrentRatio}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.firstDescriptionForCurrentRatio}</div>

                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.currentRatioImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.currentRatioImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }

                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.secondDescriptionForCurrentRatio}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.Current_ratio}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.currentRatio?.thirdDescriptionForCurrentRatio}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.currentRatioComments}</div>
                                                    </div>
                                                </th>


                                                <th className={styleClass.thTag}>

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.nameNetWorth}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.firstDescriptionForNetWorth}</div>

                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.networthImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.networthImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }

                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.netWorth?.secondDescriptionForNetWorth}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.networth}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.netWorth?.thirdDescriptionForNetWorth}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.networthComments}
                                                        </div>
                                                    </div>
                                                </th>


                                                <th className={styleClass.thTag}>

                                                    <div className={styleClass.tableparagraph}>{dataForPdf?.config_details?.bodyOfPDF?.opm?.nameOpm}</div>
                                                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.opm?.firstDescriptionForOpm}</div>

                                                        {dataForPdf?.dynamic_config_values?.image_color_config_data?.opmImageColor === "Green" ?
                                                            (<img src={greenImage} alt="Loading" width={60} height={60} />) :
                                                            (<>{dataForPdf?.dynamic_config_values?.image_color_config_data?.opmImageColor === "Red" ?
                                                                (<img src={redImage} alt="Loading" width={60} height={60} />) : (<img src={yellowImage} alt="Loading" width={60} height={60} />)}</>)
                                                        }

                                                        <div>{dataForPdf?.config_details?.bodyOfPDF?.opm?.secondDescriptionForOpm}
                                                            {dataForPdf?.dynamic_config_values?.config_values_data?.OPM}
                                                            {dataForPdf?.config_details?.bodyOfPDF?.opm?.thirdDescriptionForOpm}
                                                            {dataForPdf?.dynamic_config_values?.config_statements_data?.opmComments}
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ margin: "15px 0 15px 0" }}>{dataForPdf?.config_details?.bodyOfPDF?.aboveFooterSection?.lastParagrah}</div>

                        <hr className={styleClass.hrTag} />

                        <div>{dataForPdf?.config_details?.footerSectionOfPDF?.firstFooterComments}
                            <div className={styleClass.lastParagraph}>{dataForPdf?.config_details?.footerSectionOfPDF?.secondFooterComments}
                                {dataForPdf?.config_details?.footerSectionOfPDF?.thirdFooterComments}</div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <a href='https://www.cappitallwant.com/' style={{ cursor: "pointer", color: "blue" }}>Report powered by www.cappitallwant.com
                            </a>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
