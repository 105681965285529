import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import axios from "../../../../axios-order";
import MonthDatePicker from "../../../../components/ui/monthWiseDatePicker";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from '@mui/material/Chip';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


export default function ItcReversalReport() {
    const [handleFromDate, setHandleFromDate] = useState("");
    const [handleToDate, setHandleToDate] = useState("");
    const [handleFromDateToDisplay, setHandleFromDateToDisplay] = useState(null);
    const [handleToDateToDisplay, setHandleToDateToDisplay] = useState(null);
    const [trigarLoader, setTrigarLoader] = useState(false);
    const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);
    const [tdsHistoryTable, setTdsHistoryTable] = useState(true);
    const [triggerButton, setTriggerButton] = useState(true);
    const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);
    const [tdsReconHistoryData, setTdsReconHistoryData] = useState([]);
    const [loaderForTable, setLoaderForTable] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState("");
    const [loaderSubmitButton, setLoaderSubmitButton] = useState(false)

    let history = useHistory();

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };


    const getItcReportRecords = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
        }
        setLoaderForTable(true);
        axios
            .post('/get_itc_reversal_report_records', payLoadData)
            .then((res) => {
                setLoaderForTable(false);
                setTdsReconHistoryData(res.data.records);

            })
            .catch(() => setLoaderForTable(false));
    };

    useEffect(() => {
        getItcReportRecords();
    }, []);


    const functionToHandleFromDate = (v) => {
        const pad = "00";
        const yy = v.getFullYear().toString();
        const mm = (v.getMonth() + 1).toString();
        setHandleFromDate(`${(pad + mm).slice(-2)}${yy}`);
        setHandleFromDateToDisplay(v);
    };


    const functionToHandleToDate = (v) => {
        const pad = "00";
        const year = v.getFullYear().toString();
        const month = (v.getMonth() + 1).toString();
        var lastDay = new Date(v.getFullYear(), v.getMonth() + 1, 0);
        setHandleToDate(`${(pad + month).slice(-2)}${year}`);
        setHandleToDateToDisplay(v);
    };

    const handleOpenAndCloseModel = () => {
        setHandleFromDateToDisplay(null);
        setHandleToDateToDisplay(null);
        setTriggerButton(true);
        setTriggerModelFromToDate(false);
        setTrigarLoader(false);
        setTrigarForErrorMessage(false);
    };


    const onSubmitHandlerForData = () => {
        setLoaderSubmitButton(true)
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'start_period': handleFromDate,
            'end_period': handleToDate,
            'org_gstin': localStorage.getItem('gst')
        }
        axios.post('/create_itc_reversal_report_summary', payLoadData)
            .then((res) => {
                if (res.data.status) {
                    handleSnackbarOpen(res.data.message, 'success')
                    getItcReportRecords();
                    handleOpenAndCloseModel()
                } else {
                    handleSnackbarOpen(res.data.message, 'error')
                    getItcReportRecords();
                    handleOpenAndCloseModel()
                }
            })
            .catch((err) => {
                getItcReportRecords();
                handleOpenAndCloseModel()
            }).finally(() => {
                setLoaderSubmitButton(false)
            })

    };


    const updatedShowDetails = (item) => {
        history.push({
            pathname: `/itcRevarsalSummary/${item.recon_id}`,
            state: { items: item }
        })
    }


    const tdsPayablesHistoryTableBody = () => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (loaderForTable) {
            return (
                <tr>
                    <td
                        style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                        colSpan={6}
                    >
                        Loading…Please wait
                    </td>
                </tr>
            );
        } else if (tdsReconHistoryData && tdsReconHistoryData.length !== 0) {
            return tdsReconHistoryData.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{`${monthNames[parseInt(item.from_tax_period.slice(0, 2)) - 1]}-${item.from_tax_period.slice(2)}`}</td>
                        <td>{`${monthNames[parseInt(item.to_tax_period.slice(0, 2)) - 1]}-${item.to_tax_period.slice(2)}`}</td>
                        <td>{item.run_date_time}</td>
                        <td><Chip label={item.report_status} color="success" variant="outlined" /></td>
                        <td><VisibilityOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => updatedShowDetails(item)}
                            style={{ marginTop: '2px' }}
                            color="primary"
                        /></td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
                        No Data Found!
                    </td>
                </tr>
            );
        }
    };


    const handleTDSREconciliationButtonEvent = () => {
        setTriggerModelFromToDate(true);
    };


    return (
        <React.Fragment>
            {triggerButton && (
                <div>
                    <button
                        className="button-outlined-primary"
                        onClick={handleTDSREconciliationButtonEvent}
                    >
                        New ITC Report
                    </button>
                </div>
            )}

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={triggerModelFromToDate}
                onHide={handleOpenAndCloseModel}
                dialogClassName="modal-50w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>ITC Reversal Report</Modal.Title>
                </Modal.Header>
                <ValidatorForm
                    useRef="form"
                    onSubmit={onSubmitHandlerForData}
                    onError={(errors) => console.log(errors)}
                >
                    <Modal.Body>
                        <Grid container spacing={3}>
                            <Grid item sm={2}>
                                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                                    From Month<span style={{ color: "red" }}>*</span>:
                                </p>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <MonthDatePicker
                                        placeholder="Month & Year"
                                        errorMessages={["This field is required"]}
                                        validators={["required"]}
                                        value={handleFromDateToDisplay}
                                        onChange={(v) => functionToHandleFromDate(v)}
                                    />
                                </div>
                            </Grid>

                            <Grid item sm={2}>
                                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                                    To Month<span style={{ color: "red" }}>*</span>:
                                </p>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <MonthDatePicker
                                        minDateForDatepicker={handleFromDateToDisplay}
                                        placeholder="Month & Year"
                                        // minDate={handleFromDateToDisplay}
                                        errorMessages={["This field is required"]}
                                        validators={["required"]}
                                        value={handleToDateToDisplay}
                                        onChange={(v) => functionToHandleToDate(v)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {trigarLoader ? (
                            <div
                                style={{ fontWeight: "600", color: "green", padding: "5px" }}
                            >
                                Please wait recon is in progress
                            </div>
                        ) : (
                            ""
                        )}
                        {trigarForErrorMessage && (
                            <div style={{ color: "red", fontWeight: "700" }}>
                                No Records Present!
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>

                        <button
                            className="button-outlined-secondary"
                            type="button"
                            onClick={handleOpenAndCloseModel}
                        >
                            Cancel
                        </button>
                        <LoadingButton
                            type="submit"
                            size="small"
                            onClick={() => onSubmitHandlerForData()}
                            loading={loaderSubmitButton}
                            variant="outlined"
                            style={{ height: '30px', width: '90px' }}
                        >
                            Submit
                        </LoadingButton>
                    </Modal.Footer>
                </ValidatorForm>
            </Modal>

            {tdsHistoryTable && (
                <BoxWrapper
                    boxTitle="ITC REVARSAL REPORT"

                >
                    <div className="responsive-table">
                        <table className="custome-table">
                            <tbody>
                                <tr>
                                    <th>FROM PERIOD</th>
                                    <th>TO PERIOD</th>
                                    <th>RUN DATE TIME</th>
                                    <th>REPORT STATUS</th>
                                    <th>Report</th>
                                </tr>
                                {tdsPayablesHistoryTableBody()}
                            </tbody>
                        </table>
                    </div>
                </BoxWrapper>
            )}

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>
        </React.Fragment>
    );
}
