import React, { useState, useEffect } from "react";
import "./dashboard.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const BillTable = (props) => {
  const { data, loader } = props;

  // indian rupeess system---
  const indianRupessFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 15,
    }).format(value?.toFixed(2));
  // ---End---

  return (
    <div className="invoice-details">
      <div className="responsive-table">
        <table className="custome-long-table ">
          <thead>
            {data.length === 0 && (
              <tr>
                <th>Vendor GSTIN</th>
                <th>Vendor PAN</th>
                <th>Vendor Name</th>
                <th>Vendor Code</th>
                <th>Account Manager</th>
                <th>Registration Type</th>
                <th>Bill Number</th>
                <th>Bill Date</th>
              </tr>
            )}
            <tr>
              {data.length > 0 &&
                Object.keys(data[0]).map((item) => {
                  if ("Org Code" === item) {
                    return false;
                  } else {
                    return <th>{item}</th>;
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr>
                    {Object.keys(item).map((v, i) => {
                      if (
                        "Excess Amount" === v ||
                        "Amount Outstanding" === v ||
                        "Tds Amount" === v ||
                        "Amount Paid" === v ||
                        "Non Taxable Value" === v ||
                        "Taxable Value" === v ||
                        "Total Amount" === v ||
                        "Tax Value" === v
                      ) {
                        return <td>{indianRupessFormat(item[v]) ?? ""}</td>;
                      } else if ("Org Code" === v) {
                        return false;
                      } else {
                        return <td>{item[v] ?? ""}</td>;
                      }
                    })}
                  </tr>
                );
              })}

            {data.length === 0 && loader === false && (
              <tr>
                <td style={{ color: "red" }}>No Data Found!</td>
              </tr>
            )}

            {loader && (
              <tr>
                <td>Loading.Please wait...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default BillTable;
