import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Typography,
  Checkbox
} from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "../../../../axios-order";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import EmailIcon from '@mui/icons-material/Email';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MonthDatePicker from "../../../../components/ui/monthWiseDatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';


function TableDetails() {
  const location = useLocation();
  let history = useHistory();
  const { report_id, rule, recon_id, item } = location.state || {};
  const [detailedData, setDetailedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([]);
  const [emailButtonLoading, setEmailButtonLoading] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");
  const [responseMessage, setResponseMessage] = useState("");
  const [saveButtonLoading, setSaveButtonLoading] = useState(false)

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = detailedData.map((_, index) => index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleSelectRow = (index) => {
    const currentIndex = selected.indexOf(index);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(index);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (report_id) {
      getItcDetailedData(page, rowsPerPage);

    }
  }, [report_id, page, rowsPerPage]);

  const getItcDetailedData = (page, rowsPerPage) => {
    setLoading(true)
    const payLoadData = {
      summary_id: report_id,
      itc_rule: rule,
      page: page + 1,
      limit: rowsPerPage,
      gstin: item.gstin
    };

    axios.post('/get_itc_reversal_detailed_data', payLoadData)
      .then((res) => {
        if (res.data && res.data.data) {
          setDetailedData(res.data.data);
          setTotalRows(res.data.total);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      }).finally(() => {
        setLoading(false)
      });
  };

  const sendEmailNotification = () => {
    setEmailButtonLoading(true)
    const selectedItems = selected.map(index => detailedData[index]);
    const payLoadData = {
      reversal_notification_data: selectedItems,
      org_code: localStorage.getItem("business_entity_id")
    }
    axios.post('/send_reversal_notification_email', payLoadData)
      .then((res) => {
        if (res.data.status) {
          handleSnackbarOpen(res.data.message, "success")
        } else {
          handleSnackbarOpen(res.data.message, "warning")
        }
      })
      .catch((error) => {
        console.error('Error sending items:', error);
      }).finally(() => {
        setEmailButtonLoading(false)
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const functionToHandleReversalMonth = (value, index) => {
    const updatedData = [...detailedData];
    updatedData[index].reversal_month = value;
    setDetailedData(updatedData);
  };

  // Handle Reclaim Month Change
  const functionToHandleReclaimMonth = (value, index) => {
    const updatedData = [...detailedData];
    updatedData[index].reclaimed_month = value;
    setDetailedData(updatedData);
  };

  const renderTable = (rule) => {
    switch (true) {
      case rule && (rule.includes("Reversal Rule as per 37") || rule.includes("ITC Reversal Summary")):
        return renderReversalRule37Table();

      case rule && rule.includes("ITC Reclaim Summary - Reversal Rule as per 37"):
        return renderReclaimRule37Table();

      case rule && rule.includes("ITC Reclaim Summary - Reversal Rule as per 37(A)"):
        return renderReclaimRule37Table();

      case rule && rule.includes("Lapsed ITC"):
        return renderLapsedItcTable();

      case rule && rule.includes("Section 17(5)"):

        return renderSection175ItcTable();
      default:
        return <p>No data available or rule not matched.</p>;
    }
  };

  const formatCurrency = (value) => {
    if (value == null) return '₹0.00';
    return `₹${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const onSaveReversalAndReclimed = () => {
    setSaveButtonLoading(true)
    const payloadData = {
      "records": detailedData,
      "org_code": localStorage.getItem('business_entity_id')
    }
    axios.post('/update_reversal_and_reclaimed_months_from_records', payloadData)
      .then((res) => {

      }).catch((err) => {

      }).finally(() => {
        setSaveButtonLoading(false)
      })
  }

  const renderSection175ItcTable = () => (
    <TableContainer component={Paper} style={{ maxHeight: '50vh' }}>

      <Table stickyHeader>

        <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
          {[
            "HSN / SAC", "Taxable Value", "IGST", "CGST", "SGST", "Cess", "Total Invoice Value"
          ].map(header => (
            <TableCell key={header} sx={{ color: 'white' }} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{header}</TableCell>
          ))}
        </TableRow>

        <TableBody>
          {detailedData.map((row, index) => (
            <TableRow key={index}>
              {[
                row.hsn_sac_code,
                formatCurrency(row.taxable_value),
                formatCurrency(row.igst),
                formatCurrency(row.cgst),
                formatCurrency(row.sgst),
                formatCurrency(row.cess),
                formatCurrency(row.total_invoice_value)
              ].map((cellData, cellIndex) => (
                <TableCell size='small' key={cellIndex} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cellData}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  const renderReclaimRule37Table = () => (
    <TableContainer component={Paper} style={{ maxHeight: '50vh' }}>

      <Table stickyHeader>

        <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
          <TableCell padding="checkbox" >
            <Checkbox
              onChange={handleSelectAll}
              checked={selected.length === detailedData.length}
              indeterminate={selected.length > 0 && selected.length < detailedData.length}
            />
          </TableCell>
          {[
            "GSTIN",
            "Vendor Name",
            "Document Type",
            "Document No",
            "Original Inv No",
            "Document Date",
            "Taxable Value",
            "Tax Amount",
            "Total Invoice Value",
            "Due date (Rule 37)",
            "Amount Paid",
            "Payment Date",
            "Ageing Days",
            "ITC Reversal Y/N - Current",
            "ITC Reversal Y/N - Previous",
            "ITC Reversal Remarks - Previous",
            "Reversal Amount",
            "Summary Remarks",
            "Reclaim ITC Y/N",
            "Reclaim ITC Amount",
            "Reversal Month",
            "Reclaim Month"
          ].map(header => (
            <TableCell key={header} sx={{ color: 'white' }} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{header}</TableCell>
          ))}
        </TableRow>

        <TableBody>
          {detailedData.map((row, index) => (
            <TableRow key={index}>
              <TableCell padding="checkbox" >
                {row.itc_reversal_flag === "Y" && <Checkbox
                  checked={selected.indexOf(index) !== -1}
                  onChange={() => handleSelectRow(index)}
                />}
              </TableCell>
              {[
                row.gstin,
                row.vendor_name,
                row.document_type,
                row.document_no,
                row.original_invoice_no,
                row.document_date,
                formatCurrency(row.taxable_value),
                formatCurrency(row.igst + row.cgst + row.sgst + row.cess),
                formatCurrency(row.total_invoice_value),
                row.due_date_rule_37,
                formatCurrency(row.amount_paid),
                row.payment_date,
                row.ageing_days,
                row.itc_reversal_flag,
                row.itc_reversal_previous_flag,
                row.itc_reversal_remarks_previous,
                row.reversal_amount,
                row.summary_remarks,
                row.reclaim_itc_flag,
                formatCurrency(row.reclaim_itc_amount),
                <ValidatorForm style={{ width: '200px' }}>
                  <MonthDatePicker
                    placeholder="Month & Year"
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={row.reversal_month}
                    onChange={(v) => functionToHandleReversalMonth(v, index)} // Update reversal month
                  />
                </ValidatorForm>,
                <ValidatorForm style={{ width: '200px' }}>
                  <MonthDatePicker
                    placeholder="Month & Year"
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={row.reclaimed_month}
                    onChange={(v) => functionToHandleReclaimMonth(v, index)} // Update reclaim month
                  />
                </ValidatorForm>
              ].map((cellData, cellIndex) => (
                <TableCell key={cellIndex} size='small' align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cellData}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderReversalRule37Table = () => (
    <>
      <TableContainer component={Paper} style={{ maxHeight: '50vh' }}>
        <Table stickyHeader>

          <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
            <TableCell padding="checkbox">
              <Checkbox
                onChange={handleSelectAll}
                sx={{ color: 'white' }}
                checked={selected.length === detailedData.length}
                indeterminate={selected.length > 0 && selected.length < detailedData.length}
              />
            </TableCell>
            {[
              "GSTIN",
              "Vendor Name",
              "Document Type",
              "Document No",
              "Original Inv No",
              "Document Date",
              "Taxable Value",
              "Tax Amount",
              "Total Invoice Value",
              "Due date (Rule 37)",
              "Amount Paid",
              "Payment Date",
              "Ageing Days",
              "ITC Reversal Y/N - Current",
              "ITC Reversal Y/N - Previous",
              "ITC Reversal Remarks - Previous",
              "Reversal Amount",
              "Summary Remarks",
              "Reversal Month",
              "Reclaim Month"
            ].map(header => (
              <TableCell key={header} align="center" sx={{ color: 'white' }} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{header}</TableCell>
            ))}
          </TableRow>

          <TableBody>
            {detailedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  {row.itc_reversal_flag === "Y" && <Checkbox
                    checked={selected.indexOf(index) !== -1}
                    onChange={() => handleSelectRow(index)}
                  />}
                </TableCell>
                {[
                  row.gstin,
                  row.vendor_name,
                  row.document_type,
                  row.document_no,
                  row.original_invoice_no,
                  row.document_date,
                  formatCurrency(row.taxable_value),
                  formatCurrency(row.igst + row.cgst + row.sgst + row.cess),
                  formatCurrency(row.total_invoice_value),
                  row.due_date_rule_37,
                  formatCurrency(row.amount_paid),
                  row.payment_date,
                  row.ageing_days,
                  row.itc_reversal_flag,
                  row.itc_reversal_previous_flag,
                  row.itc_reversal_remarks_previous,
                  formatCurrency(row.reversal_amount),
                  row.summary_remarks,
                  <ValidatorForm style={{ width: '200px' }}>
                    <MonthDatePicker
                      placeholder="Month & Year"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={row.reversal_month}
                      onChange={(v) => functionToHandleReversalMonth(v, index)} // Update reversal month
                    />
                  </ValidatorForm>,
                  <ValidatorForm style={{ width: '200px' }}>
                    <MonthDatePicker
                      placeholder="Month & Year"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={row.reclaimed_month}
                      onChange={(v) => functionToHandleReclaimMonth(v, index)} // Update reclaim month
                    />
                  </ValidatorForm>
                ].map((cellData, cellIndex) => (
                  <TableCell size='small' key={cellIndex} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cellData}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );

  const renderLapsedItcTable = () => (
    <TableContainer component={Paper} style={{ maxHeight: '50vh' }}>
      <Table stickyHeader>

        <TableRow sx={{ backgroundColor: '#303841', position: 'sticky', top: 0, zIndex: 1 }}>
          {[
            "GSTIN",
            "Vendor Name",
            "Invoice No",
            "Document Date",
            "Taxable Value",
            "Tax Amount",
            "Total Invoice Value",
            "Due Date",
            "Payment Status",
            "Reversal Remarks",
            "Lapsed ITC Amount",
            "Summary Remarks"
          ].map(header => (
            <TableCell key={header} sx={{ color: 'white' }} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{header}</TableCell>
          ))}
        </TableRow>

        <TableBody>
          {detailedData.map((row, index) => (
            <TableRow key={index}>
              {[
                row.gstin,
                row.vendor_name,
                row.invoice_no,
                row.document_date,
                formatCurrency(row.taxable_value),
                formatCurrency(row.tax_amount),
                formatCurrency(row.total_invoice_value),
                row.due_date,
                row.payment_status,
                row.reversal_remarks,
                formatCurrency(row.lapsed_itc_amount),
                row.summary_remarks
              ].map((cellData, cellIndex) => (
                <TableCell size='small' key={cellIndex} align="center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cellData}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000000',
            marginBottom: 2
          }}
          style={{ flex: 1 }} // Allow the Typography to take available space
        >
          {rule}
          <AnalyticsIcon style={{ marginBottom: '4px', color: '#000000' }} />
        </Typography>
        <Button
          onClick={() => history.goBack()}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          style={{ marginBottom: '1%' }}
        >
          BACK
        </Button>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress />
          <br />
          Loading, Please wait...
        </div>
      ) : (
        <>
          {detailedData.length > 0 ? (
            <>
              {renderTable(rule)}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 500]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ marginTop: '16px' }} // Margin for better spacing
              />

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <LoadingButton
                  color="primary"
                  onClick={onSaveReversalAndReclimed}
                  loading={saveButtonLoading}
                  loadingPosition="start"
                  startIcon={<BookmarkBorderIcon />}
                  variant="contained"
                  style={{ width: '120px', height: '30px', marginRight: '10px' }}
                >
                  <span>Save</span>
                </LoadingButton>

                <LoadingButton
                  color="primary"
                  onClick={sendEmailNotification}
                  loading={emailButtonLoading}
                  loadingPosition="start"
                  startIcon={<EmailIcon />}
                  variant="contained"
                  style={{ width: '120px', height: '30px' }}
                >
                  <span>Send</span>
                </LoadingButton>
              </div>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img src="https://res.cloudinary.com/dyivs1j8u/image/upload/v1713335965/Screenshot_2024-04-17_120750-removebg-preview_tdhay3.png" alt="No data available" />
            </Box>
          )}
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}

        </MuiAlert>
      </Snackbar>
    </Box>
  );

}

export default TableDetails;
