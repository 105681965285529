import React, { useState } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const FivePointOne = ({ dateForFivePointOne, cancel, upDateJSON }) => {
  const [fivePointOneEditData, setFivePointOneEditData] =
    useState(dateForFivePointOne);

  const handleSubmit = () => {
    console.log(fivePointOneEditData);
    upDateJSON(fivePointOneEditData, "FivePointOne");
    cancel();
  };

  return (
    <>
      <BoxWrapper boxTitle=" 5.1 Interest & late Fee Payable">
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
            <thead>
              <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                <th>Description</th>
                <th>Integrated Tax</th>
                <th>Central Tax</th>
                <th>State/UT Tax</th>
                <th>Cess</th>
              </tr>
            </thead>
            <tbody>
              {fivePointOneEditData?.map((v, index) => {
                return (
                  <tr>
                    <td>{v.name}</td>
                    <td style={{ background: v.igst === undefined && "#ddd" }}>
                      {v.igst !== undefined ? (
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fivePointOneEditData?.map(
                              (item, i) => {
                                if (index === i) {
                                  return { ...item, igst: parseFloat(value) };
                                }
                                return item;
                              }
                            );
                            setFivePointOneEditData(newData);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.cgst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fivePointOneEditData?.map(
                              (item, i) => {
                                if (index === i) {
                                  return { ...item, cgst: parseFloat(value) };
                                }
                                return item;
                              }
                            );
                            setFivePointOneEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.sgst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fivePointOneEditData?.map(
                              (item, i) => {
                                if (index === i) {
                                  return { ...item, sgst: parseFloat(value) };
                                }
                                return item;
                              }
                            );
                            setFivePointOneEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td style={{ background: v.cess === undefined && "#ddd" }}>
                      {v.cess !== undefined ? (
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.cess}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fivePointOneEditData?.map(
                              (item, i) => {
                                if (index === i) {
                                  return { ...item, cess: parseFloat(value) };
                                }
                                return item;
                              }
                            );
                            setFivePointOneEditData(newData);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <button className="button-primary" onClick={handleSubmit}>
            Save
          </button>

          <button className="button-outlined-secondary" onClick={cancel}>
            Cancel
          </button>
        </div>
      </BoxWrapper>
    </>
  );
};

export default FivePointOne;
