import React, { useState } from "react";
import "../TDSRecon.css";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function SYSummary(props) {
  const { summaryData } = props;

  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(summaryData);

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const handleTableSearch = (event) => {
    setSearch(event.target.value);
    let searchData = summaryData.filter((item) => {
      const query = event.target.value.toLowerCase();
      return (
        (item.customer_gstin &&
          item.customer_gstin.toLowerCase().includes(query)) ||
        (item.customer_name &&
          item.customer_name.toLowerCase().includes(query)) ||
        (item["customer/decuctor_tan"] &&
          item["customer/decuctor_tan"].toLowerCase().includes(query))
      );
    });
    setTableData(searchData);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Summary"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleTableSearch}
      >
        <div className="responsive-table">
          <table className="summary-tab-single-year-table-css">
            <thead>
              <tr>
                <th rowSpan={2}>CUSTOMER PAN</th>
                <th rowSpan={2}>CUSTOMER NAME</th>
                <th rowSpan={2}>CUSTOMER /DEDUCTED TAN</th>
                <th colSpan={3} style={{ background: "green", width: "120px" }}>
                  Total
                </th>
                <th colSpan={3} style={{ background: "red", width: "120px" }}>
                  Quarter1
                </th>
                <th
                  colSpan={3}
                  style={{ background: "orange", width: "120px" }}
                >
                  Quarter2
                </th>
                <th colSpan={3} style={{ background: "blue", width: "120px" }}>
                  Quarter3
                </th>
                <th
                  colSpan={3}
                  style={{ background: "yellow", width: "120px" }}
                >
                  Quarter4
                </th>
              </tr>
              <tr>
                <th>TOTAL-TDS ( AS PER BOOKS)</th>
                <th>TOTAL-TDS DEDUCTED (PER 26AS)</th>
                <th>TOTAL TDS-DIFFERENCE</th>

                <th>TDS PER CWS PLATEFORM (AS PER BOOKS)</th>
                <th>TDS DEDUCTED PER 26AS</th>
                <th>DIFFERENCE</th>

                <th>TDS PER CWS PLATEFORM (AS PER BOOKS)</th>
                <th>TDS DEDUCTED PER 26AS</th>
                <th>DIFFERENCE</th>

                <th>TDS PER CWS PLATEFORM (AS PER BOOKS)</th>
                <th>TDS DEDUCTED PER 26AS</th>
                <th>DIFFERENCE</th>

                <th>TDS PER CWS PLATEFORM (AS PER BOOKS)</th>
                <th>TDS DEDUCTED PER 26AS</th>
                <th>DIFFERENCE</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr>
                    <td>{item.cust_pan}</td>
                    <td>{item.customer_name}</td>
                    <td>{item["customer/decuctor_tan"]}</td>

                    <td>{indianRupeesFormat(item.total_tds_payment_advice)}</td>
                    <td>{indianRupeesFormat(item.tds_deducted_form_26)}</td>
                    <td>{indianRupeesFormat(item.total_tds_difference)}</td>

                    <td>
                      {indianRupeesFormat(item.q1_total_tds_payment_advice)}
                    </td>
                    <td>{indianRupeesFormat(item.q1_tds_deducted_form_26)}</td>
                    <td>{indianRupeesFormat(item.q1_total_tds_difference)}</td>

                    <td>
                      {indianRupeesFormat(item.q2_total_tds_payment_advice)}
                    </td>
                    <td>{indianRupeesFormat(item.q2_tds_deducted_form_26)}</td>
                    <td>{indianRupeesFormat(item.q2_total_tds_difference)}</td>

                    <td>
                      {indianRupeesFormat(item.q3_total_tds_payment_advice)}
                    </td>
                    <td>{indianRupeesFormat(item.q3_tds_deducted_form_26)}</td>
                    <td>{indianRupeesFormat(item.q3_total_tds_difference)}</td>

                    <td>
                      {indianRupeesFormat(item.q4_total_tds_payment_advice)}
                    </td>
                    <td>{indianRupeesFormat(item.q4_tds_deducted_form_26)}</td>
                    <td>{indianRupeesFormat(item.q4_total_tds_difference)}</td>
                  </tr>
                );
              })}
              {tableData.length === 0 && (
                <tr>
                  <td style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default SYSummary;
