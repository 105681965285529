import React, { useEffect, useState } from "react";
import classes from "../BusinessEntityDetails.module.css";
import axios from "../../../axios-order";
import { Button, Grid, Drawer } from "@material-ui/core";
import BoxWrapper from "../../../components/ui/boxWrapper";
import AddTdsForm from "./add";
import EditTdsForm from "./edit";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RightDrawer from "../../../components/ui/RightDrawer";

export default function Ldts(props) {
  const [ltdsData, setLtdsData] = useState(null);
  const [ltdsDataForSearch, setLtdsDataForSearch] = useState(null);
  const [addFormDrawerOpen, setAddFormDrawerOpen] = useState(false);
  const [editFormDrawerOpen, setEditFormDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const getLtdsAllData = () => {
    axios
      .get(`/tds/ltds/get/${localStorage.getItem("gst")}`)
      .then((res) => {
        setLtdsData(res.data.record);
        setLtdsDataForSearch(res.data.record);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SearchEvent = (e) => {
    const search = e.target.value;
    const searchFilter = ltdsDataForSearch.filter((v) => {
      return (
        String(v.pan).toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        String(v.vendor_legal_name)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(v.tds_section_as_per_certificate)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0
      );
    });

    setLtdsData(searchFilter);
  };

  useEffect(() => {
    getLtdsAllData();
  }, []);

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const tableBodyData = () => {
    if (ltdsData && ltdsData.length !== 0) {
      return ltdsData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.type}</td>
            <td>{item.pan}</td>
            <td>{item.vendor_legal_name} </td>
            <td>{indianFormatDate(item.valid_from_date)}</td>
            <td>{indianFormatDate(item.valid_to_date)}</td>
            <td>{item.tds_section_as_per_certificate}</td>
            <td>{item.tds_as_per_certificate}</td>
            <td>{item.certificate_no_for_ltds}</td>
            <td>{numberFormat(item.limit_amount)}</td>
            <td>
              <Button
                style={{ minWidth: "0px" }}
                onClick={() => editFormOpenDrawerHandler(item)}
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>{" "}
              |{" "}
              <Button
                style={{ minWidth: "0px" }}
                onClick={() => deleteTableRow(item.id)}
              >
                <DeleteForeverOutlinedIcon className="deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={10}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // .......close add form drawer.....
  const addFormCloseDrawerHandler = () => {
    setAddFormDrawerOpen(false);
  };
  // ........open edit form drawer.....
  const editFormOpenDrawerHandler = (item) => {
    setEditFormDrawerOpen(true);
    setEditData(item);
  };
  // .......close edit form drawer.....
  const editFormCloseDrawerHandler = () => {
    setEditFormDrawerOpen(false);
  };

  // ........delete table row.........
  const deleteTableRow = (id) => {
    let text = "Are you sure! you want to delete this record.";
    if (window.confirm(text) === true) {
      axios
        .delete(`/tds/ltds/delete/${id}`)
        .then((res) => {
          getLtdsAllData();
        })
        .catch((err) => { });
    } else {
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="TDS LTDS"
        enableAddButton={true}
        addButtonLabel={"ADD"}
        addButtonHandlerFn={() => setAddFormDrawerOpen(true)}
        enableSearchInput={true}
        searchInputPlaceholder="Pan,Legal Name,Section"
        searchInputOnchange={SearchEvent}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>TYPE</th>
                <th>PAN</th>
                <th>legal name</th>
                <th>FROM DATE</th>
                <th>TO DATE</th>

                <th>section</th>

                <th>tds%</th>
                <th>LTDS certificate</th>
                <th>limit amount</th>
                <th>Action</th>
              </tr>
              {tableBodyData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* ........add tds form drawer.......... */}
      <RightDrawer
        drawerName="Add TDS LTDS"
        open={addFormDrawerOpen}
        onClose={addFormCloseDrawerHandler}
      >
        <AddTdsForm
          updateGetApi={getLtdsAllData}
          cancelAddForm={addFormCloseDrawerHandler}
        />
      </RightDrawer>
      {/* ........edit tds form drawer........... */}
      <RightDrawer
        drawerName="Edit TDS LTDS"
        open={editFormDrawerOpen}
        onClose={editFormCloseDrawerHandler}
      >
        <EditTdsForm
          updateGetApi={getLtdsAllData}
          cancelEditForm={editFormCloseDrawerHandler}
          editFormData={editData}
        />
      </RightDrawer>
    </>
  );
}
