import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, TablePagination } from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ErrorIcon from "@mui/icons-material/Error";
import StackedBarChart from "../../../../components/ui/StackedBarChart";
import BarchartRupessFormat from "../../../../components/ui/BarChart_rupeesFormat";
import PieChart from "../../../../components/ui/PieCharts";
import VendorTable from "./vendorTable";
import BillTable from "./billTable";
import Cards from "./cards";
import Filter from "./filter";

import axios from "../../../../axios-order";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";
import UnallocatedTable from "./UnallocatedTable";

export default function Dashboard() {
  // loader
  const [loaderForPage, setLoaderForPage] = useState(true);
  const [filterLoader, setFilterLoader] = useState(false);
  const [showErrorForPage, setErrorForPage] = useState(false);

  //filter dropdown and filter form data
  const [filterDropdownData, setFilterDropdownData] = useState({
    vendor_gstin: [],
    registeration_type: [],
    ageing_bucket: [],
    as_of_date: [],
    vendor_name: [],
    account_manager: [],
    vendor_pan: [],
    vendor_code: [],
  });
  const [filterValues, setFilterValues] = useState({
    gstin: localStorage.getItem("gst"),
  });
  const [filterError, setFilterError] = useState(false);

  // all api data
  const [cardsData, setCardsData] = useState(null);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartDataForNotYetDue, setPieChartDataForNotYetDue] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [maxMinForBarChart, setMaxMinForBarChart] = useState(null);
  const [montlyAgeingChartData, setMontlyAgeingChartData] = useState([]);
  const [montlyTotalOutstandingData, setMontlyTotalOutstandingData] = useState(
    []
  );
  const [vendorDetailsData, setVendorDetailsData] = useState([]);
  const [billDetailsData, setBillDetailsData] = useState([]);
  const [unallocatedTableData , setUnallocatedTableData] = useState([]); 

  const [lastRefreshTime, setLastRefreshTime] = useState("");
  const [lastRefreshDate, setLastRefreshDate] = useState("");
  const [topCustomerNo, setTopCustomerNo] = useState(5);

  // show/hide states for components
  const [tableFilter, setTableFilter] = useState(false);
  const [showBackToSummary, setShowBackToSummary] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [showVendorTable, setShowVendorTable] = useState(false);
  const [showBillTable, setShowBillTable] = useState(false);
  const [showChartsAndCards, setShowChartsAndCards] = useState(true);
  const [showVendorButton, setShowVendorButton] = useState(true);
  const [showBillButton, setShowBillButton] = useState(true);
  const [vendorNameFlag, setVendorNameFlag] = useState(false);

  const [showUallocatedButton , setShowUallocatedButton] = useState(true);
  const [showUallocatedTable , setShowUallocatedTable] = useState(false);

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  // api's functions
  const filterDropdownDatafn = () => {
    axios
      .post(`/cws_reportingv2/ageing_payables/get_dropdown`, {
        gstin: localStorage.getItem("gst"),
      })
      .then((res) => {
        if (res.data.status) {
          setFilterDropdownData(res.data.data);
          // taking max date from array
          let dates = res.data.data.as_of_date;
          let maxDate = dates.sort(function (a, b) {
            let aSplit = a.split("-");
            let bSplit = b.split("-");
            let newDatea = new Date(`${aSplit[2]}-${aSplit[1]}-${aSplit[0]}`);
            let newDateb = new Date(`${bSplit[2]}-${bSplit[1]}-${bSplit[0]}`);
            return newDatea - newDateb;
          });
          let defaultAsOfDate = maxDate[maxDate.length - 1];

          let promise = new Promise((resolve, reject) => {
            setFilterValues({
              ...filterValues,
              ["as_of_date"]: defaultAsOfDate,
            });

            const payload = {
              as_of_date: defaultAsOfDate,
              gstin: localStorage.getItem("gst"),
              org_code:localStorage.getItem("business_entity_id")
            };

            cardsDataFn(payload, topCustomerNo);
            montlyAgeingChartApiFn(payload);
            montlyTotalOutstandingChartApiFn(payload);
            lastRefreshDateTime();
            return resolve(true);
          });

          promise.then((res) => {
            setLoaderForPage(false);
          });
        } else {
          setLoaderForPage(false);
          setErrorForPage(true);
        }
      });
  };

  const cardsDataFn = (payload, customerNo) => {
    axios
      .post(`/cws_reportingv2/ageing_payables/get_summary_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setCardsData(res.data.data);
          setFilterError(false);
          pieChartApiFn(payload);
          pieChartNotYetDue(payload);
          barChartApiFn(payload, customerNo);
        } else {
          setFilterError(true);
        }
      });
  };

  const pieChartApiFn = (payload) => {
    axios
      .post(
        `/cws_reportingv2/ageing_payables/ageing_payable_pie_chart`,
        payload
      )
      .then((res) => {
        if (res.data.status) {
          setPieChartData(res.data.data);
        } else {
          console.log("error");
        }
      });
  };

  const pieChartNotYetDue = (payload) => {
    axios
      .post(`/cws_reportingv2/ageing_payables/due_payable_pie_chart`, payload)
      .then((res) => {
        if (res.data.status) {
          setPieChartDataForNotYetDue(res.data.data);
        } else {
          console.log("error");
        }
      });
  };

  const barChartApiFn = (payload, topCustomer) => {
    axios
      .post(`/cws_reportingv2/ageing_payables/ageing_payable_bar_graph`, {
        ...payload,
        top_customer_number: topCustomer,
      })
      .then((res) => {
        if (res.data.status) {
          setBarChartData(res.data.data.bar_graph);
          setMaxMinForBarChart(res.data.data.max_min_value_for_yaxis);
        } else {
          console.log("error");
        }
      });
  };

  const montlyAgeingChartApiFn = (payload) => {
    axios
      .post(
        `/cws_reportingv2/ageing_payables/ageing_payables_monthly_outstanding_on_ageing_bucket`,
        payload
      )
      .then((res) => {
        if (res.data.status) {
          setMontlyAgeingChartData(res.data.data);
        } else {
          console.log("error");
        }
      });
  };

  const montlyTotalOutstandingChartApiFn = (payload) => {
    axios
      .post(
        `/cws_reportingv2/ageing_payables/ageing_payable_not_yet_due_and_outstanding`,
        payload
      )
      .then((res) => {
        if (res.data.status) {
          setMontlyTotalOutstandingData(res.data.data);
          setLoaderForPage(false);
        } else {
          console.log("error");
        }
      });
  };

  const vendorDetailsApiFn = (payload) => {
    axios
      .post(`/cws_reportingv2/ageing_payables/get_customer_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setVendorDetailsData(res.data.data);
          // setCustomerLoader(false);
          setRowCount(res.data.total_count);
        } else {
          console.log("error");
        }
      });
  };

  const billDetailsApiFn = (payload) => {
    axios
      .post(`/cws_reportingv2/ageing_payables/get_invoice_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setBillDetailsData(res.data.data);
          setRowCount(res.data.total_count);
        } else {
          console.log("error");
        }
      });
  };

  const unallocatedfunc = (payload) => {
    axios
    .post(`/cws_reportingv2/ageing_payables/get_unalloc_payment_details`, payload)
    .then((res) => {
      if (res.data.status) {
        setUnallocatedTableData(res.data.records);
        setRowCount(res.data.row_count);
      } else {
        setUnallocatedTableData([]);
      }
    });
  }

  function lastRefreshDateTime() {
    axios
      .post(
        "/cws_reportingv2/ageing_recievables/last_refresh_date_ageing_payable",
        {
          org_code: localStorage.getItem("business_entity_id"),
        }
      )
      .then((res) => {
        if (res.data.status) {
          let timeZone = res.data.last_refreshed_date.split("T");
          let timeArray = timeZone[1].split("Z");
          setLastRefreshTime(timeArray[0]);
          setLastRefreshDate(res.data.last_refreshed_date);
        } else {
          setLoaderForPage(false);
          setErrorForPage(true);
        }
      });
  }

  useEffect(() => {
    filterDropdownDatafn();
  }, []);

  // top customer handler for bar chart
  const topCustomerDropdownHandler = (e) => {
    let value = e.target.value;
    setTopCustomerNo(value);
    let payload = { ...filterValues };
    barChartApiFn(payload, value);
  };

  // event handlers functions
  const refreshHandler = () => {
    axios
      .post("/cws_reportingv2/ageing_payables/refresh_ageing_payables", {
        org_code: localStorage.getItem("business_entity_id"),
        gstin: localStorage.getItem("gst"),
      })
      .then((res) => {
        if (res.data.status) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
  };

  // back to summary button handler
  const backToSummaryHandler = () => {
    setShowUallocatedButton(true);
    setShowUallocatedTable(false);
    setShowBackToSummary(false);
    setShowBillTable(false);
    setShowVendorTable(false);
    setShowChartsAndCards(true);
    setShowBillButton(true);
    setShowVendorButton(true);
    setTableFilter(false);
    setFilterValues({
      gstin: localStorage.getItem("gst"),
      as_of_date: filterValues.as_of_date,
    });
  };

  // filter button handler
  const showFilterHandler = () => {
    setShowFilter(!showFilter);
  };

  // vendor button handler
  const vendorTableHandler = () => {
    setShowBackToSummary(true);
    setShowVendorTable(true);
    setShowBillTable(false);
    setShowChartsAndCards(false);
    setShowBillButton(true);
    setShowVendorButton(false);
    setTableFilter(true);
    setShowUallocatedButton(true);
    setShowUallocatedTable(false);

    setRowsPerPage(25);
    setPage(0);
    const payload = {
      ...filterValues,
      page_number: 0,
      page_size: 25,
    };
    vendorDetailsApiFn(payload);
  };

  // bill button handler
  const billTableHandler = () => {
    setShowBackToSummary(true);

    setShowBillTable(true);
    setShowVendorTable(false);
    setShowChartsAndCards(false);
    setShowBillButton(false);
    setShowVendorButton(true);
    setTableFilter(true);
    setShowUallocatedButton(true);
    setShowUallocatedTable(false);

    setRowsPerPage(25);
    setPage(0);
    const payload = {
      ...filterValues,
      page_number: 0,
      page_size: 25,
    };
    billDetailsApiFn(payload);
  };

  const unallocatedTableHandler = () => {
    setShowBackToSummary(true);
    setShowChartsAndCards(false);
    setTableFilter(true);

    //table
    setShowUallocatedTable(true);
    setShowBillTable(false);
    setShowVendorTable(false);
    
    //button
    setShowUallocatedButton(false);
    setShowBillButton(true);
    setShowVendorButton(true);

    setRowsPerPage(25);
    setPage(0);
   
    const payload = {
      ...filterValues,
      bid:localStorage.getItem("business_entity_id"),
      page_number: 0,
      page_size: 25,
    };
    //ApiCall
    unallocatedfunc(payload)
  }

  const filterSubmitHandler = () => {
    setFilterLoader(true);
    toast.configure();

    if (filterValues.as_of_date && filterValues.gstin) {
      let promise = new Promise((resolve, reject) => {
        const data = {
          ...filterValues,
          bid:localStorage.getItem("business_entity_id"),
          page_number: 0,
          page_size: 25,
        };

        if (showVendorTable === false && showBillTable === false && showUallocatedTable === false) {
          const payload = {
            ...filterValues,
          };
          cardsDataFn(payload, topCustomerNo);
        }
        if (showVendorTable) {
          vendorDetailsApiFn(data);
        }
        if (showBillTable) {
          billDetailsApiFn(data);
        }
        if(showUallocatedTable) {
          unallocatedfunc(data);
        }
        return resolve(true);
      });
      promise.then((res) => {
        setFilterLoader(false);
      });
      toast.success("Data fetched successfully ", {
        autoClose: 1000,
      });
    } else {
      return false;
    }
  };

  const cancelFilterHandler = () => {
    setFilterValues({
      gstin: localStorage.getItem("gst"),
      as_of_date: filterValues.as_of_date,
    });
  };

  // page chage handler for both table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      ...filterValues,
      page_number: newPage,
      page_size: rowsPerPage,
    };
    if (showVendorTable) {
      vendorDetailsApiFn(data);
    } if(showUallocatedTable) {
      data["bid"] = localStorage.getItem("business_entity_id");
      unallocatedfunc(data);
    } else {
      billDetailsApiFn(data);
    }
  };

  // row per page handler for both table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const data = {
      ...filterValues,
      page_number: 0,
      page_size: +event.target.value,
    };
    if (showVendorTable) {
      vendorDetailsApiFn(data);
    } if(showUallocatedTable) {
      data["bid"] = localStorage.getItem("business_entity_id");
      unallocatedfunc(data);
    } else {
      billDetailsApiFn(data);
    }
  };

  // pie chart & vendor chart filter handler for bill data
  useEffect(() => {
    if (vendorNameFlag) {
      const data = {
        ...filterValues,
        page_number: 0,
        page_size: 25,
      };
      billDetailsApiFn(data);
    }
    setVendorNameFlag(false);
  }, [filterValues.vendor_name || filterValues.ageing || filterValues.due]);

  const drilldownHandlerForAgeingChart = (i) => {
    setVendorNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["ageing"]: i,
    });
    setShowBackToSummary(true);
    setShowBillTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowVendorTable(false);
    setShowChartsAndCards(false);
    setShowBillButton(false);
    setRowsPerPage(25);
    setPage(0);
  };

  const drilldownHandlerForAgeingChartForNotYetDue = (i) => {
    setVendorNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["due"]: i,
    });
    setShowBackToSummary(true);
    setShowBillTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowVendorTable(false);
    setShowChartsAndCards(false);
    setShowBillButton(false);
    setRowsPerPage(25);
    setPage(0);
  };

  const drilldownHandlerForVendorChart = (i) => {
    setVendorNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["vendor_name"]: i,
    });
    setShowBackToSummary(true);
    setShowBillTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowVendorTable(false);
    setShowChartsAndCards(false);
    setShowBillButton(false);
    setRowsPerPage(25);
    setPage(0);
  };

  // monthly trend chart filter for customer data
  const monthlyChartDrilldownHandler = (i) => {
    let promise = new Promise((resolve, reject) => {
      setFilterValues({
        ...filterValues,
        as_of_date: i,
      });
      setShowBackToSummary(true);
      setShowVendorTable(true);
      setShowVendorButton(false);
      setTableFilter(true);
      setShowBillTable(false);
      setShowChartsAndCards(false);
      setRowsPerPage(25);
      setPage(0);
      return resolve(true);
    });
    promise.then((res) => {
      const payload = {
        as_of_date: i,
        gstin: localStorage.getItem("gst"),
        page_number: 0,
        page_size: 25,
        drilldown: true,
      };
      vendorDetailsApiFn(payload);
    });
  };

  // export to excel
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcelForUnallocated = () => {
    let payload = {
      data:unallocatedTableData
      // bid: localStorage.getItem("business_entity_id"),
      // gstin: localStorage.getItem("gst"),
      // ...filterValues,
      // page_number: page,
      // page_size: rowsPerPage,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Vendor Unallocated Payment.xlsx`;
    axios
      .post(`/arc/vendor_unallocated_payments`, payload)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const exportToExcelForBill = () => {
    let payload = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      gstin: localStorage.getItem("gst"),
      ...filterValues,
      page_number: page,
      page_size: rowsPerPage,
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `bill wise ageing report.xlsx`;
    axios
      .post(`/cws_reportingv2/ageing_payables/bill/extract`, payload)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportToExcelForVendor = () => {
    let payload = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      gstin: localStorage.getItem("gst"),
      ...filterValues,
      page_number: page,
      page_size: rowsPerPage,
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendor wise ageing report.xlsx`;
    axios
      .post(`/cws_reportingv2/ageing_payables/vendors/extract`, payload)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const allDataExportToExcelForVendor = () => {
  //   let payload = {
  //     business_entity_id: localStorage.getItem("business_entity_id"),
  //     gstin: localStorage.getItem("gst"),
  //   };

  //   const contentType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const filename = `all_vendor.xlsx`;
  //   axios
  //     .post(`/cws_reportingv2/raw_ageing_payables/vendors/extract`, payload)
  //     .then((res) => {
  //       downloadBase64File(contentType, res.data.file, filename);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const allDataExportToExcelForBill = () => {
  //   let payload = {
  //     business_entity_id: localStorage.getItem("business_entity_id"),
  //     gstin: localStorage.getItem("gst"),
  //   };

  //   const contentType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const filename = `all_bill.xlsx`;
  //   axios
  //     .post(`/cws_reportingv2/raw_ageing_payables/bill/extract`, payload)
  //     .then((res) => {
  //       downloadBase64File(contentType, res.data.file, filename);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <>
      {loaderForPage ? (
        <div className="full-page-loader">
          <div className="page-loader">
            <center>
              <CircularProgress />
              <br></br>
              Loading, Please wait...
            </center>
          </div>
        </div>
      ) : (
        <>
          {showErrorForPage === false && (
            <>
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* last refreshed date and time */}
                <div>
                  Last Refreshed Date :{" "}
                  {`${moment(lastRefreshDate).format(
                    "DD/MM/YYYY"
                  )}, Time : ${moment(lastRefreshTime, "HH:mm:ss").format(
                    "h:mm:ss a"
                  )}`}
                </div>

                <div>
                  <button
                    className="button-outlined-primary"
                    onClick={
                      showBackToSummary ? backToSummaryHandler : refreshHandler
                    }
                  >
                    {showBackToSummary ? "back to summary" : "refresh"}
                  </button>
                  <button
                    className="button-primary"
                    onClick={showFilterHandler}
                  >
                    <span style={{ marginRight: "5px" }}>Filter</span>{" "}
                    <FilterListIcon
                      style={{ fontSize: "18px", marginTop: "-4px" }}
                    />
                  </button>
                </div>
              </div>

              {/* Filter */}
              {showFilter && (
                <div style={{ margin: "15px 0px" }}>
                  <BoxWrapper boxTitle="Filter">
                    <Filter
                      DropdownsData={filterDropdownData}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      filterTableFlag={tableFilter}
                      BillTableFlag={showBillTable}
                      UnallocatedTableFlag={showUallocatedTable}
                      loader={filterLoader}
                      filterSubmitHandler={filterSubmitHandler}
                      cancelFilterForm={cancelFilterHandler}
                      error={filterError}
                    />
                  </BoxWrapper>
                </div>
              )}

              {showChartsAndCards && (
                <>
                  {/* Cards  */}
                  <div style={{ marginTop: "10px" }}>
                    <Cards cardData={cardsData} />
                  </div>
                  {/* Charts */}
                  <div style={{ marginBottom: "20px" }}>
                    <Grid container spacing={3}>
                      <Grid item sm={6}>
                        <BoxWrapper boxTitle="Overdue by Age Summary">
                          <div>
                            <PieChart
                              yAxisTitle="Value"
                              xAxisTitle="Days"
                              enableDrilldownFn={true}
                              fnForDrillDown={drilldownHandlerForAgeingChart}
                              data={pieChartData}
                            />
                          </div>
                        </BoxWrapper>
                      </Grid>
                      <Grid item sm={6}>
                        <BoxWrapper boxTitle="Not Yet Due by Age Summary">
                          {pieChartDataForNotYetDue.length === 0 ? (
                            <div
                              style={{
                                color: "red",
                                textAlign: "center",
                                padding: "33%",
                              }}
                            >
                              No Bills Under Not Yet Due
                            </div>
                          ) : (
                            <div>
                              <PieChart
                                yAxisTitle="Value"
                                xAxisTitle="Days"
                                enableDrilldownFn={true}
                                fnForDrillDown={
                                  drilldownHandlerForAgeingChartForNotYetDue
                                }
                                data={pieChartDataForNotYetDue}
                              />
                            </div>
                          )}
                        </BoxWrapper>
                      </Grid>
                    </Grid>
                    <BoxWrapper
                      boxTitle={`Top ${
                        barChartData && barChartData.length - 1
                      }  Vendors by Outstanding Amount + Others`}
                      enableDropdown={true}
                      dropdownHandlerFn={topCustomerDropdownHandler}
                      dropdownOptions={[
                        { value: 5, label: 5 },
                        { value: 10, label: 10 },
                        { value: 15, label: 15 },
                        { value: 20, label: 20 },
                      ]}
                      dropdownLabel="Vendors"
                      dropdownValue={topCustomerNo}
                    >
                      <div>
                        <BarchartRupessFormat
                          tooltipHeader="customer_manager"
                          tooltipFormat="₹ {point.y:.1f}"
                          yAxisTitle="Amounts"
                          xAxisTitle="Vendors"
                          enableDrilldownFn={true}
                          fnForDrillDown={drilldownHandlerForVendorChart}
                          data={barChartData}
                          maxValue={maxMinForBarChart && maxMinForBarChart.max}
                          minValue={maxMinForBarChart && maxMinForBarChart.min}
                        />
                      </div>
                    </BoxWrapper>

                    <BoxWrapper
                      boxTitle={`Monthly Trend Chart for Not Yet Due and Due Amount.`}
                    >
                      <div style={{ padding: "20px 0px" }}>
                        <StackedBarChart
                          xAxisTitle="Months"
                          yAxisTitle={"Amounts"}
                          fnForDrillDown={monthlyChartDrilldownHandler}
                          data={montlyTotalOutstandingData}
                        />
                      </div>
                    </BoxWrapper>

                    <BoxWrapper
                      boxTitle={`Monthly Trend Chart of Total Overdue Amount For Each Ageing Bucket.`}
                    >
                      <div style={{ padding: "20px 0px", height: "500px" }}>
                        <StackedBarChart
                          xAxisTitle="Months"
                          yAxisTitle={"Total overdue Amount"}
                          fnForDrillDown={monthlyChartDrilldownHandler}
                          data={montlyAgeingChartData}
                        />
                      </div>
                    </BoxWrapper>
                  </div>
                </>
              )}

              {/* Tables */}
              {showVendorTable && (
                <div style={{ margin: "15px 0px" }}>
                  <BoxWrapper
                    boxTitle="Vendor Details"
                    exportToExcelEnable={true}
                    exportToExcelFn={exportToExcelForVendor}
                    // rawExportToExcelEnable={true}
                    // rawExportToExcelFn={allDataExportToExcelForVendor}
                  >
                    <VendorTable data={vendorDetailsData} loader={false} />
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={rowCount} //total row count
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </BoxWrapper>
                </div>
              )}

              {showBillTable && (
                <div style={{ margin: "15px 0px" }}>
                  <BoxWrapper
                    boxTitle="Bill Details"
                    exportToExcelEnable={true}
                    exportToExcelFn={exportToExcelForBill}
                    // rawExportToExcelEnable={true}
                    // rawExportToExcelFn={allDataExportToExcelForBill}
                  >
                    <BillTable data={billDetailsData} loader={false} />
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={rowCount} //total row count
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </BoxWrapper>
                </div>
              )}

               {showUallocatedTable && (
                <div style={{ margin: "15px 0px" }}>
                  <BoxWrapper
                    boxTitle="Unallocated Details"
                    exportToExcelEnable={true}
                    exportToExcelFn={exportToExcelForUnallocated}
                    // rawExportToExcelEnable={true}
                    // rawExportToExcelFn={allDataExportToExcelForBill}
                  >
                    <UnallocatedTable data={unallocatedTableData} loader={false} />
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={rowCount} //total row count
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </BoxWrapper>
                </div>
              )}

              <div>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    {showVendorButton && (
                      <div
                        className="card-button-1"
                        onClick={vendorTableHandler}
                      >
                        <span>Vendor Details</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                  {showUallocatedButton && (
                      <div
                        className="card-button-1"
                        onClick={unallocatedTableHandler}
                      >
                        <span>Unallocated Payment</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {showBillButton && (
                      <div className="card-button-1" onClick={billTableHandler}>
                        <span>Bill Details</span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* Error  */}
            </>
          )}
        </>
      )}
      {/* error component */}
      {showErrorForPage && (
        <div className="error-msg-css">
          <center>
            <span style={{ color: "red", fontSize: "18px" }}>
              <ErrorIcon /> No Data Found!
            </span>
            <br></br>
            <span style={{ marginLeft: "20px" }}>
              For this GSTIN : {localStorage.getItem("gst")}
            </span>
            <br></br>
            <Button variant="outlined" size="small" onClick={refreshHandler}>
              Refresh
            </Button>
          </center>
        </div>
      )}
    </>
  );
}
