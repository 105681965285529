import React, { useState, useEffect } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import DatePicker from "../../../../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import InterestCalculateTwo from "./InterestCalculateTwo";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const InterestCalculator = ({ cancel }) => {
  const indianRupeesFormat = (value) =>
    value !== ""
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumSignificantDigits: 10,
        }).format(value)
      : "";

  const [
    grandtotalDelayedPaymentTaxLiabilityAmount,
    setGrandtotalDelayedPaymentTaxLiabilityAmount,
  ] = useState(0);
  const [
    grandTotalDelayedPaymentInterestAmount,
    setGrandTotalDelayedPaymentInterestAmount,
  ] = useState(0);
  const [
    grandTotalDelayedPaymentPayableAmount,
    setGrandTotalDelayedPaymentPayableAmount,
  ] = useState(0);

  const [interestOnDelayedPaymentOfTax, setInterestOnDelayedPaymentOfTax] =
    useState({
      PaymentDueDateIGST: null,
      PaymentDateIGST: null,
      DelayInPaymentIGST: "",
      TaxLiabilityAmountIGST: "",
      InterestAmountIGST: "",
      TotatPayableAmount: "",
    });

  const [
    interestOnDelayedPaymentOfTaxCGST,
    setInterestOnDelayedPaymentOfTaxCGST,
  ] = useState({
    PaymentDueDateCGST: null,
    PaymentDateCGST: null,
    TaxLiabilityAmountCGST: "",
    DelayInPaymentCGST: "",
    InterestAmountCGST: "",
    TotatPayableAmountCGST: "",
  });

  const [
    interestOnDelayedPaymentOfTaxSGST,
    setInterestOnDelayedPaymentOfTaxSGST,
  ] = useState({
    PaymentDueDateSGST: null,
    PaymentDateSGST: null,
    TaxLiabilityAmountSGST: "",
    DelayInPaymentSGST: "",
    InterestAmountSGST: "",
    TotatPayableAmountSGST: "",
  });

  useEffect(() => {
    let Total =
      parseInt(interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST || 0) +
      parseInt(interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST || 0) +
      parseInt(interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST || 0);
    setGrandtotalDelayedPaymentTaxLiabilityAmount(Total);
  }, [
    interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST,
    interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST,
    interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST,
  ]);

  useEffect(() => {
    let TotalInterest =
      parseInt(interestOnDelayedPaymentOfTax.InterestAmountIGST || 0) +
      parseInt(interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST || 0) +
      parseInt(interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST || 0);
    setGrandTotalDelayedPaymentInterestAmount(TotalInterest);
  }, [
    interestOnDelayedPaymentOfTax.InterestAmountIGST,
    interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST,
    interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST,
  ]);

  useEffect(() => {
    let TotalPayable =
      parseInt(interestOnDelayedPaymentOfTax.TotatPayableAmount || 0) +
      parseInt(interestOnDelayedPaymentOfTaxCGST.TotatPayableAmountCGST || 0) +
      parseInt(interestOnDelayedPaymentOfTaxSGST.TotatPayableAmountSGST || 0);
    setGrandTotalDelayedPaymentPayableAmount(TotalPayable);
  }, [
    interestOnDelayedPaymentOfTax.TotatPayableAmount,
    interestOnDelayedPaymentOfTaxCGST.TotatPayableAmountCGST,
    interestOnDelayedPaymentOfTaxSGST.TotatPayableAmountSGST,
  ]);
  const calculateInterestAmount = (
    TaxLiabilityAmount,
    DelayInPayment,
    TaxType
  ) => {
    const value =
      (parseInt(TaxLiabilityAmount) * 18 * parseInt(DelayInPayment)) / 36500;
    console.log(TaxLiabilityAmount, DelayInPayment, value);
    if (TaxType === "IGST") {
      setInterestOnDelayedPaymentOfTax({
        ...interestOnDelayedPaymentOfTax,
        InterestAmountIGST: Math.round(value),
      });
    } else if (TaxType === "CGST") {
      setInterestOnDelayedPaymentOfTaxCGST({
        ...interestOnDelayedPaymentOfTaxCGST,
        InterestAmountCGST: Math.round(value),
      });
    } else {
      setInterestOnDelayedPaymentOfTaxSGST({
        ...interestOnDelayedPaymentOfTaxSGST,
        InterestAmountSGST: Math.round(value),
      });
    }
  };

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST !== "" &&
      interestOnDelayedPaymentOfTax.DelayInPaymentIGST !== ""
    ) {
      calculateInterestAmount(
        interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST,
        interestOnDelayedPaymentOfTax.DelayInPaymentIGST,
        "IGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST,
    interestOnDelayedPaymentOfTax.DelayInPaymentIGST,
  ]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST !== "" &&
      interestOnDelayedPaymentOfTaxCGST.DelayInPaymentCGST !== ""
    ) {
      calculateInterestAmount(
        interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST,
        interestOnDelayedPaymentOfTaxCGST.DelayInPaymentCGST,
        "CGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST,
    interestOnDelayedPaymentOfTaxCGST.DelayInPaymentCGST,
  ]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST !== "" &&
      interestOnDelayedPaymentOfTaxSGST.DelayInPaymentSGST !== ""
    ) {
      calculateInterestAmount(
        interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST,
        interestOnDelayedPaymentOfTaxSGST.DelayInPaymentSGST,
        "SGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST,
    interestOnDelayedPaymentOfTaxSGST.DelayInPaymentSGST,
  ]);

  const calculateDateDifferent = (DueDate, PaymentDate, TaxType) => {
    const taxLabilityIGSTDueDate = moment(DueDate).format("MM/DD/YYYY");
    const taxLabilityIGSTDate = moment(PaymentDate).format("MM/DD/YYYY");
    const date1 = new Date(taxLabilityIGSTDueDate);
    const date2 = new Date(taxLabilityIGSTDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (TaxType === "IGST") {
      setInterestOnDelayedPaymentOfTax({
        ...interestOnDelayedPaymentOfTax,
        DelayInPaymentIGST: diffDays,
      });
    } else if (TaxType === "CGST") {
      setInterestOnDelayedPaymentOfTaxCGST({
        ...interestOnDelayedPaymentOfTaxCGST,
        DelayInPaymentCGST: diffDays,
      });
    } else {
      setInterestOnDelayedPaymentOfTaxSGST({
        ...interestOnDelayedPaymentOfTaxSGST,
        DelayInPaymentSGST: diffDays,
      });
    }
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
  };

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTax.PaymentDueDateIGST !== null &&
      interestOnDelayedPaymentOfTax.PaymentDateIGST !== null
    ) {
      calculateDateDifferent(
        interestOnDelayedPaymentOfTax.PaymentDueDateIGST,
        interestOnDelayedPaymentOfTax.PaymentDateIGST,
        "IGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTax.PaymentDueDateIGST,
    interestOnDelayedPaymentOfTax.PaymentDateIGST,
  ]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxCGST.PaymentDueDateCGST !== null &&
      interestOnDelayedPaymentOfTaxCGST.PaymentDateCGST !== null
    ) {
      calculateDateDifferent(
        interestOnDelayedPaymentOfTaxCGST.PaymentDueDateCGST,
        interestOnDelayedPaymentOfTaxCGST.PaymentDateCGST,
        "CGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTaxCGST.PaymentDueDateCGST,
    interestOnDelayedPaymentOfTaxCGST.PaymentDateCGST,
  ]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxSGST.PaymentDueDateSGST !== null &&
      interestOnDelayedPaymentOfTaxSGST.PaymentDateSGST !== null
    ) {
      calculateDateDifferent(
        interestOnDelayedPaymentOfTaxSGST.PaymentDueDateSGST,
        interestOnDelayedPaymentOfTaxSGST.PaymentDateSGST,
        "SGST"
      );
    }
  }, [
    interestOnDelayedPaymentOfTaxSGST.PaymentDueDateSGST,
    interestOnDelayedPaymentOfTaxSGST.PaymentDateSGST,
  ]);

  const calculateTotalPayableAmount = (
    TaxLiabilityAmount,
    InterestAmount,
    TaxType
  ) => {
    const intersetAmount =
      parseInt(TaxLiabilityAmount) + parseInt(InterestAmount);
    console.log(intersetAmount, InterestAmount);
    if (TaxType === "IGST") {
      setInterestOnDelayedPaymentOfTax({
        ...interestOnDelayedPaymentOfTax,
        TotatPayableAmount: Math.round(intersetAmount),
      });
    } else if (TaxType === "CGST") {
      setInterestOnDelayedPaymentOfTaxCGST({
        ...interestOnDelayedPaymentOfTaxCGST,
        TotatPayableAmountCGST: Math.round(intersetAmount),
      });
    } else {
      setInterestOnDelayedPaymentOfTaxSGST({
        ...interestOnDelayedPaymentOfTaxSGST,
        TotatPayableAmountSGST: Math.round(intersetAmount),
      });
    }
  };

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST !== "" &&
      interestOnDelayedPaymentOfTax.InterestAmountIGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST,
        interestOnDelayedPaymentOfTax.InterestAmountIGST,
        "IGST"
      );
    }
  }, [interestOnDelayedPaymentOfTax.InterestAmountIGST]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST !== "" &&
      interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST,
        interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST,
        "CGST"
      );
    }
  }, [interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST]);

  useEffect(() => {
    if (
      interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST !== "" &&
      interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST !== ""
    ) {
      calculateTotalPayableAmount(
        interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST,
        interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST,
        "SGST"
      );
    }
  }, [interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST]);

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <button className="button-outlined-primary" onClick={cancel}>
          Back To Report
        </button>
      </div>
      <BoxWrapper boxTitle="Interest on Delayed Payment of Tax">
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <ValidatorForm
            useRef="form"
            // onSubmit={onSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
              <thead>
                <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                  <th>Tax type</th>
                  <th>Tax liability amount</th>
                  <th>Payment due date</th>
                  <th>Payment date</th>
                  <th>Delay in payment</th>
                  <th>Interest amount</th>
                  <th>Total payable amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnDelayedPaymentOfTax({
                          ...interestOnDelayedPaymentOfTax,
                          TaxLiabilityAmountIGST: parseFloat(value),
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={interestOnDelayedPaymentOfTax.PaymentDueDateIGST}
                      disabled={
                        interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST ===
                        ""
                          ? true
                          : false
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTax({
                          ...interestOnDelayedPaymentOfTax,
                          PaymentDueDateIGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={interestOnDelayedPaymentOfTax.PaymentDateIGST}
                      disabled={
                        interestOnDelayedPaymentOfTax.TaxLiabilityAmountIGST ===
                        ""
                          ? true
                          : false
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTax({
                          ...interestOnDelayedPaymentOfTax,
                          PaymentDateIGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {interestOnDelayedPaymentOfTax.DelayInPaymentIGST}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTax.InterestAmountIGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTax.TotatPayableAmount
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <td>CGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnDelayedPaymentOfTaxCGST({
                          ...interestOnDelayedPaymentOfTaxCGST,
                          TaxLiabilityAmountCGST: parseFloat(value),
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnDelayedPaymentOfTaxCGST.PaymentDueDateCGST
                      }
                      disabled={
                        interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTaxCGST({
                          ...interestOnDelayedPaymentOfTaxCGST,
                          PaymentDueDateCGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={interestOnDelayedPaymentOfTaxCGST.PaymentDateCGST}
                      disabled={
                        interestOnDelayedPaymentOfTaxCGST.TaxLiabilityAmountCGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTaxCGST({
                          ...interestOnDelayedPaymentOfTaxCGST,
                          PaymentDateCGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {interestOnDelayedPaymentOfTaxCGST.DelayInPaymentCGST}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTaxCGST.InterestAmountCGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTaxCGST.TotatPayableAmountCGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <td>SGST</td>
                  <td>
                    <NumberFormat
                      className={DesignInGSTR3BThreePointOne.numberFormate}
                      style={{ paddingLeft: "10.5px" }}
                      thousandsGroupStyle="lakh"
                      prefix="₹"
                      decimalSeparator="."
                      displayType="number"
                      type="text"
                      decimalScale={2}
                      // value={v.taxable_value}
                      thousandSeparator={true}
                      allowNegative={true}
                      onValueChange={(values) => {
                        const { value } = values;
                        setInterestOnDelayedPaymentOfTaxSGST({
                          ...interestOnDelayedPaymentOfTaxSGST,
                          TaxLiabilityAmountSGST: parseFloat(value),
                        });
                      }}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={
                        interestOnDelayedPaymentOfTaxSGST.PaymentDueDateSGST
                      }
                      disabled={
                        interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTaxSGST({
                          ...interestOnDelayedPaymentOfTaxSGST,
                          PaymentDueDateSGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholder="DD/MM/YYYY"
                      errorMessages={["This field is required"]}
                      validators={["required"]}
                      value={interestOnDelayedPaymentOfTaxSGST.PaymentDateSGST}
                      disabled={
                        interestOnDelayedPaymentOfTaxSGST.TaxLiabilityAmountSGST !==
                        ""
                          ? false
                          : true
                      }
                      onChange={(v) =>
                        setInterestOnDelayedPaymentOfTaxSGST({
                          ...interestOnDelayedPaymentOfTaxSGST,
                          PaymentDateSGST: v,
                        })
                      }
                    />
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {interestOnDelayedPaymentOfTaxSGST.DelayInPaymentSGST}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTaxSGST.InterestAmountSGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                  <td>
                    {
                      <>
                        <strong style={{ color: "green" }}>
                          {indianRupeesFormat(
                            interestOnDelayedPaymentOfTaxSGST.TotatPayableAmountSGST
                          )}
                        </strong>
                      </>
                    }
                  </td>
                </tr>
                <tr style={{ background: "#BFBFBF", fontWeight: "bold" }}>
                  <td>Total</td>
                  <td>{grandtotalDelayedPaymentTaxLiabilityAmount}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{grandTotalDelayedPaymentInterestAmount}</td>
                  <td style={{ color: "red" }}>
                    {grandTotalDelayedPaymentPayableAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </ValidatorForm>
        </div>
      </BoxWrapper>

      <InterestCalculateTwo backToReport={cancel} />
    </>
  );
};

export default InterestCalculator;
