import React, { Component } from "react";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import CustomTable from "../../../../components/ui/Table/Table";
import SelectField from "../../../../components/ui/selectInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import InputField from "../../../../components/ui/inputField";

class OrigaInvoiceReceipt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCol: [
        { key: "invoice_number" },
        { key: "seller_name" },
        { key: "buyer_name" },
        { key: "agency_name" },
        { key: "event_type" },
        { key: "invoice_amount" },
        { key: "amount" },
      ],
      oldInvoiceData: [],
      invoiceData: [],
      total_records: 0,
      invoiceHeader: [
        { label: "Invoice NUmber" },
        { label: "Seller Name" },
        { label: "Buyer Name" },
        { label: "Agency Name" },
        { label: "Event Type" },
        { label: "Invoice Amount" },
        { label: "Receipt Amount" },
      ],
      receipt_status: "",
      receiptStatusData: [
        { id: "1", value: "Accept" },
        { id: "0", value: "Reject" },
      ],
      searchInput: "",
    };
    this.handleReceiptStatusChange = this.handleReceiptStatusChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
  }

  getInvoices = () => {
    axios
      .get(
        "/origa/get_invoice_receipt/" +
          localStorage.getItem("business_entity_id")
      )
      .then((res) => {
        this.setState({
          oldInvoiceData: res.data.data,
          invoiceData: res.data.data,
          total_records: res.data.data.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getInvoices();
  }

  handleReceiptStatusChange(event) {
    let value = event.target.value;
    this.setState({
      receipt_status: value,
    });
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldInvoiceData.length) {
        let invoiceDataNew = this.state.oldInvoiceData.filter((value) => {
          return (
            String(value.invoice_number).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.buyer_name).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          invoiceData: invoiceDataNew,
        });
      }
    } else {
      this.setState({
        invoiceData: this.state.oldInvoiceData,
      });
    }
  };

  onSubmitHandler() {
    let selected_ids = [];

    let receipt_status = this.state.receipt_status;

    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );

    Object.keys(selected_input).map((v, i) => {
      selected_ids.push(selected_input[v].value);
    });
    axios
      .post("/origa/post_receipt_status_update", {
        receipt_status: receipt_status,
        selected_ids: selected_ids,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.getInvoices();
      })
      .catch((error) => {});
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => {
              console.log(errors);
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <SelectField
                  SelectLabel="Status"
                  requiredField="*"
                  errorMsg={["This field is required"]}
                  choice_id="id"
                  choice_name="value"
                  choices={this.state.receiptStatusData}
                  name="receipt_status"
                  value={this.state.receipt_status}
                  textOnchangeFunction={this.handleReceiptStatusChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  in={true}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatorForm
                ref="form"
                // onSubmit={this.onSubmitHandler}
                // onError={(errors) => console.log(errors)}
              >
                <InputField
                  textLabel="Search By Invoice"
                  inputType="text"
                  name="Search_by_customer_name"
                  value={this.state.searchInput ?? ""}
                  textOnchangeFunction={this.handleSearchInputChange}
                />
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>
        {/* datatable component */}
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTable
                headers={this.state.invoiceHeader}
                columns={this.state.invoiceCol}
                data={this.state.invoiceData}
                multiselect={true}
                multiselectkey="user_invoice_id"
                name="invoices"
                selectCondition={(item) => item.receipt_current_status === 2}
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default OrigaInvoiceReceipt;
