import React, { memo } from "react";
import { TextValidator } from "react-material-ui-form-validator";

const BasicTextFields = (props) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <label style={{ textTransform: "capitalize" }}>
        {props.textLabel}
        <span style={{ color: "red" }}>{props.requiredField}</span>
        {props.textLabel ? ":" : ""}
      </label>
      <TextValidator
        validators={props.validator}
        errorMessages={props.errorMsg}
        inputProps={props.inputProps ? props.inputProps : {}}
        value={props.value}
        name={props.name}
        type={props.inputType}
        placeholder={props.inputPlacholder}
        onChange={props.textOnchangeFunction}
        fullWidth="true"
        size="small"
        variant="outlined"
        autoComplete="true"
        style={{ background: "#fff", borderRadius: "5px" }}
        disabled={props.disabled}
        InputProps ={props.styleProps}
      />
    </div>
  );
};

export default memo(BasicTextFields);
