import React, { useState } from "react";
// import "./ExceutiveSummary.css";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import BoxWrapper from "../../../components/ui/boxWrapper";


export default function Table1({ data, tabIndex  }) {  
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  return (
    <React.Fragment>
      {tabIndex === 1 ? (
        <>
          <BoxWrapper
          boxTitle="Executive Summary Region/Branch Wise Summary"
          exportToExcelEnable={false}
          enableSearchInput={false}
          backButtonForZohoEnable={false}
          importToCwsEnableForZoho={false}
        >
            <>
              <div className="responsive-table">
                <table className="custome-table">
                <thead>
                    <tr>
                      <th>Details</th>
                      <th>Branch Name</th>
                      <th>Total Book Qty</th>
                      <th>Available Qty</th>
                      <th>Damage Qty</th>
                      <th>Total Short/Excess Qty</th>
                      <th>Total Short/Excess Amount</th>
                      <th>Total Adj Qty</th>
                      <th>Total Adj Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.rptBranchWiseJsosn.brachwisedata.map((value) => (
                      <Row
                        row={value}
                        indianRupeesFormat={indianRupeesFormat}
                      />
                    ))}
                    {data.rptBranchWiseJsosn.grandtotalbranchwisedata.map(
                      (v) => {
                        return (
                          <tr className="Executive-Details-Grand-Total">
                            <td></td>
                            <td>{v.BranchName}</td>
                            <td>{v.total_TotalBookQty < 0 ? 
                            <div className="redcolor">{v.total_TotalBookQty}</div> : <div>{v.total_TotalBookQty}</div>}</td>

                            <td>{v.total_TotalAvailableQty < 0 ? 
                              <div className="redcolor">{v.total_TotalAvailableQty}</div> : <div>{v.total_TotalAvailableQty}</div>}</td>

                            <td>{v.total_TotalAvailDamageQty < 0 ? 
                              <div className="redcolor">{v.total_TotalAvailDamageQty}</div> : <div>{v.total_TotalAvailDamageQty}</div>}</td>

                            <td>{v.total_TotalShortExcessQty < 0 ? 
                            <div className="redcolor">{v.total_TotalShortExcessQty}</div> : <div>{v.total_TotalShortExcessQty}</div>}</td>
                            
                            <td> { v.total_TotalShortExcessAmount < 0 ?
                              <div className="redcolor">{indianRupeesFormat(v.total_TotalShortExcessAmount.toFixed(2))}</div> : <div>{indianRupeesFormat(v.total_TotalShortExcessAmount.toFixed(2))}</div> }
                            </td>
                            <td>{v.total_TotalAdjQty < 0 ? 
                              <div className="redcolor">{v.total_TotalAdjQty}</div> : <div>{v.total_TotalAdjQty}</div>}</td>

                            <td>{v.total_TotalAdjAmt < 0 ?
                              <div className="redcolor">{indianRupeesFormat(v.total_TotalAdjAmt.toFixed(2))}</div> : <div>{indianRupeesFormat(v.total_TotalAdjAmt.toFixed(2))}</div> }</td>
                          </tr>
                        );
                      }
                    )}
                    {data.rptBranchWiseJsosn.brachwisedata.length === 0 && (
                      <tr>
                        <td
                          colSpan={"7"}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              
              </div>
            </>
        </BoxWrapper>
        </>
      ) : (
        <>
        <BoxWrapper
          boxTitle="Executive Summary Consolidated"
          exportToExcelEnable={false}
          enableSearchInput={false}
          backButtonForZohoEnable={false}
          importToCwsEnableForZoho={false}
        >
            <>
              <div className="responsive-table">
                <table className="custome-table">
                <thead>
                    <tr>
                      <th>Sub Category Name</th>
                      <th>Total Book Qty</th>
                      <th>Total Available Qty</th> 
                      <th>Available Qty</th>
                      <th>Damage Qty</th>
                      <th>Total Short/Excess Qty</th> 
                      <th>Total Short/Excess Amount</th>
                      <th>Total Adj Qty</th>
                      <th>Total Adj Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.rptConsolidateJson.map((value) => {
                      if(value.colCategoryName === "Grand Total") {
                      return (
                        <tr style={{fontWeight:"bold" , fontSize:"15px"  }}>
                          <td>{value.colCategoryName}</td>
                          <td>{value.TotalBookQty < 0 ? 
                               <div className="redcolor">{value.TotalBookQty}</div> : <div>{value.TotalBookQty}</div>}</td>

                          <td>{value.TotalAvailableQty < 0 ? 
                              <div className="redcolor">{value.TotalAvailableQty}</div> : <div>{value.TotalAvailableQty}</div>}</td>
                          
                          <td>{value.AvailableQty < 0 ? 
                              <div className="redcolor">{value.AvailableQty}</div> : 
                              <div>{value.AvailableQty}</div>}</td>
                          
                              <td>{value.TotalAvailDamageQty < 0 ? 
                            <div className="redcolor">{value.TotalAvailDamageQty}</div> : <div>{value.TotalAvailDamageQty}</div>}</td>

                          <td>{value.TotalShortExcessQty < 0  ?
                          <div className="redcolor">{value.TotalShortExcessQty}</div> : <div>{value.TotalShortExcessQty}</div> }</td>
              
                          <td>{value.TotalShortExcessAmount < 0 ? 
                            <div className="redcolor">{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div> : 
                            <div>{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div>}</td>

                          <td>{value.TotalAdjQty < 0 ? <div className="redcolor">{value.TotalAdjQty}</div> : 
                          <div>{value.TotalAdjQty}</div>}</td>

                          <td>{value.TotalAdjAmt < 0 ? 
                            <div className="redcolor">{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div> : 
                            <div>{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div>}</td>
                        </tr> )
                       }
                      else {
                        return (
                          <tr>
                          <td>{value.colCategoryName}</td>
                          <td>{value.TotalBookQty < 0 ? 
                               <div className="redcolor">{value.TotalBookQty}</div> : <div>{value.TotalBookQty}</div>}</td>

                          <td>{value.TotalAvailableQty < 0 ? 
                              <div className="redcolor">{value.TotalAvailableQty}</div> : <div>{value.TotalAvailableQty}</div>}</td>
                          
                          <td>{value.AvailableQty < 0 ? 
                                <div className="redcolor">{value.AvailableQty}</div> : 
                                <div>{value.AvailableQty}</div>}</td>


                          <td>{value.TotalAvailDamageQty < 0 ? 
                            <div className="redcolor">{value.TotalAvailDamageQty}</div> : <div>{value.TotalAvailDamageQty}</div>}</td>

                          <td>{value.TotalShortExcessQty < 0  ?
                          <div className="redcolor">{value.TotalShortExcessQty}</div> : <div>{value.TotalShortExcessQty}</div> }</td>
                       
                          <td>{value.TotalShortExcessAmount < 0 ? 
                            <div className="redcolor">{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div> : 
                            <div>{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div>}</td>

                          <td>{value.TotalAdjQty < 0 ? <div className="redcolor">{value.TotalAdjQty}</div> : <div>{value.TotalAdjQty}</div>}</td>

                          <td>{value.TotalAdjAmt < 0 ? 
                            <div className="redcolor">{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div> : 
                            <div>{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div>}</td>
                        </tr>
                          )} 
                        }
                    )
                  }
                    {data.rptConsolidateJson.length === 0 && (
                      <tr>
                        <td
                          colSpan={"7"}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              
              </div>
            </>
      
        </BoxWrapper>
        </>
      ) 
       }
    </React.Fragment>
  );
}

function Row(props) {
  const { row, indianRupeesFormat } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <tr>
        <td>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <DoNotDisturbOnIcon style={{ color: "black" }} />
            ) : (
              <AddCircleIcon style={{ color: "black" }} />
            )}
          </IconButton>
        </td>
        <td>{row.Total_data.BranchName}</td>
        <td>{row.Total_data.total_TotalBookQty < 0 ? 
          <div className="redcolor">{row.Total_data.total_TotalBookQty}</div> : 
          <div>{row.Total_data.total_TotalBookQty}</div>  }</td>

        <td>{row.Total_data.total_TotalAvailableQty < 0 ? 
          <div className="redcolor">{row.Total_data.total_TotalAvailableQty}</div> : 
          <div>{row.Total_data.total_TotalAvailableQty}</div>}</td>

          <td>{row.Total_data.TotalAvailDamageQty < 0 ? 
            <div className="redcolor">{row.Total_data.TotalAvailDamageQty}</div> : 
            <div>{row.Total_data.TotalAvailDamageQty}</div>}</td>
        
        <td>{row.Total_data.TotalShortExcessQty < 0 ? 
          <div className="redcolor">{row.Total_data.TotalShortExcessQty}</div> : 
          <div>{row.Total_data.TotalShortExcessQty}</div>}</td>

        <td>{row.Total_data.total_TotalShortExcessAmount < 0 ? 
            <div className="redcolor">{indianRupeesFormat(row.Total_data.total_TotalShortExcessAmount.toFixed(2))}</div> :
            <div>{indianRupeesFormat(row.Total_data.total_TotalShortExcessAmount.toFixed(2))}</div>
          }</td>

        <td>{row.Total_data.TotalAdjQty < 0 ? 
          <div className="redcolor">{row.Total_data.TotalAdjQty}</div> : 
          <div>{row.Total_data.TotalAdjQty}</div>}</td>

        <td>{row.Total_data.TotalAdjAmt < 0 ? 
          <div className="redcolor">{indianRupeesFormat(row.Total_data.TotalAdjAmt.toFixed(2))}</div> :
          <div>{indianRupeesFormat(row.Total_data.TotalAdjAmt.toFixed(2))}</div>}</td>
      </tr>

      <tr>
        {" "}
        <td colSpan={"10"}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1}}>
              <Table size="small" aria-label="purchases">
                <thead>
                  <TableRow>
                    <th style={{width:"fit-content"}}>Branch Name</th>
                    <th style={{width:"fit-content"}}>Sub Category Name</th>
                    <th style={{width:"fit-content"}}>Total Book Qty</th>
                    <th style={{width:"fit-content"}}>Available Qty</th>
                    <th style={{width:"fit-content"}}>DamageQty</th>
                    <th style={{width:"fit-content"}}>Total Short/Excess Qty</th>
                    <th style={{width:"fit-content"}}>Total Short/Excess Amount</th>
                    <th style={{width:"fit-content"}}>Total Adj Qty</th>
                    <th style={{width:"fit-content"}}>Total Adj Amt</th>
                  </TableRow>
                </thead>
                <tbody>
                  {row.grouped_data.map((value, index) => (
                    <TableRow key={index}>
                      <td>{value.BranchName}</td>
                      <td>{value.colCategoryName}</td>
                      <td>{value.TotalBookQty < 0 ? 
                        <div style={{color:"red"}}>{value.TotalBookQty}</div> : 
                         <div>{value.TotalBookQty}</div>}</td>

                      <td>{value.TotalAvailableQty < 0 ? 
                        <div style={{color:"red"}}>{value.TotalAvailableQty}</div> : 
                        <div>{value.TotalAvailableQty}</div>}</td>

                      <td>{value.TotalAvailDamageQty < 0 ? 
                           <div style={{color:"red"}}>{value.TotalAvailDamageQty}</div> : 
                           <div>{value.TotalAvailDamageQty}</div>}</td>

                      <td>{value.TotalShortExcessQty < 0 ? 
                        <div style={{color:"red"}}>{value.TotalShortExcessQty}</div> : 
                         <div>{value.TotalShortExcessQty}</div>}</td>

                      <td>{value.TotalShortExcessAmount < 0 ? 
                        <div style={{color:"red"}}>{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div> : 
                        <div>{indianRupeesFormat(value.TotalShortExcessAmount.toFixed(2))}</div>}</td>
                      <td>
                        {value.TotalAdjQty < 0 ? 
                          <div style={{color:"red"}}>{value.TotalAdjQty}</div> : <div>{value.TotalAdjQty}</div>}
                      </td>
                      <td>{value.TotalAdjAmt < 0 ?
                        <div style={{color:"red"}}>{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div> : 
                         <div>{indianRupeesFormat(value.TotalAdjAmt.toFixed(2))}</div>}</td>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Collapse>
        </td>
      </tr>
    </React.Fragment>
  );
}
