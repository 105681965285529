import React, { useState } from "react";
import classes from "./GSTReconciliation.module.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

function MissingInAccount(props) {
  const { missingInAccountData } = props;
  const [missingInAccountTableData, setMissingInAccountTableData] =
    useState(missingInAccountData);
  const [searchInput, setSearchInput] = useState("");

  const requestSearch = (event) => {
    const searchVal = event.target.value;
    const filteredRows = missingInAccountData.filter((row) => {
      return (
        String(row.cust_gstin).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.cust_name).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.invoice_no).toLowerCase().indexOf(searchVal.toLowerCase()) >=
          0 ||
        String(row.invoice_date)
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) >= 0 ||
        String(row.invoice_type)
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) >= 0
      );
    });
    setMissingInAccountTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchInput("");
    requestSearch(searchInput);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---
  const missingInAccountTableBody = () => {
    if (missingInAccountTableData.length >= 1) {
      return missingInAccountTableData.map((tableData, index) => {
        const {
          cust_gstin,
          cust_name,
          gstr_cess,
          gstr_cgst,
          gstr_igst,
          gstr_sgst,
          gstr_taxable_value,
          gstr_total_amount,
          invoice_date,
          invoice_no,
          invoice_type,
        } = tableData;
        return (
          <tr>
            <td>{cust_gstin}</td>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{invoice_type}</td>
            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_cgst)}</td>
            <td>{numberFormat(gstr_sgst)}</td>
            <td>{numberFormat(gstr_igst)}</td>
            <td>{numberFormat(gstr_cess)}</td>
            <td>{numberFormat(gstr_total_amount)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status: Invoice(s) Missing in Books of Accounts"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={(searchVal) => requestSearch(searchVal)}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                CUSTOMER NAME
              </th>
              <th rowSpan={2} align="right">
                INVOICE/CN/DN/NUMBER
              </th>
              <th rowSpan={2} align="right">
                DATE
              </th>
              <th rowSpan={2} align="right">
                TYPE
              </th>
              <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
                As per GSTR1
              </th>
            </tr>
            <tr>
              <th style={{ background: "#c6ffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {missingInAccountTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MissingInAccount;
