import React, { useState } from "react";
import "../TDSRecon.css";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import DashboardBarChart from "./dashboardBarChart";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MYDefaulters(props) {
  const { defaulterData } = props;
  const [yearWise, setYearwise] = useState(
    defaulterData.length &&
    Object.keys(defaulterData[0].financialYearData).map((item) => item)
  );
  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  const dataForChart = [];

  Object.keys(defaulterData.length && defaulterData[0].financialYearData).map(
    (item) => {
      dataForChart.push({
        name: `FY ${item}`,
        data: [],
      });
    }
  );

  defaulterData.map((item) => {
    yearWise.map((v, i) => {
      let count = Object.keys(item.financialYearData);
      let index = count.findIndex((value) => value === v);
      if (index >= 0) {
        dataForChart[i].data.push(
          item.financialYearData[v].total_tds_difference
        );
      } else {
        dataForChart[i].data.push(0);
      }
    });
  });

  const obj3 = {
    name: "Grand Total",
    data: [],
  };
  defaulterData.map((item) => {
    obj3.data.push(item.adjustment);
  });
  // dataForChart.push(obj3)
  return (
    <>
      {defaulterData.length !== 0 && (
        <>
          <BoxWrapper boxTitle="Major Difference in Top 10 Customer's">
            <div>
              {defaulterData.length > 0 && (
                <DashboardBarChart
                  chartTitle=""
                  chartCategories={defaulterData.map(
                    (item) => item.customer_name
                  )}
                  yAxisTitle="Amount"
                  chartData={dataForChart}
                />
              )}
            </div>

            <div>
              {defaulterData.length > 0 && (
                <p>
                  <span style={{ color: "red" }}>Note</span> :{" "}
                  <strong>
                    “Positive Values indicate excess in Books and Negative
                    Values indicate excess in 26AS"
                  </strong>{" "}
                </p>
              )}
            </div>

            <div>
              <table className="custome-table">
                <thead>
                  <tr>
                    <th>Cust Name</th>
                    <th>PAN</th>
                    <th>TAN</th>

                    {defaulterData.length > 0 &&
                      Object.keys(defaulterData[0].financialYearData).map(
                        (item) => {
                          return <th>FY {item}</th>;
                        }
                      )}
                    <th>Grand Total</th>
                  </tr>
                </thead>
                <tbody>
                  {defaulterData.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.customer_name}</td>
                        <td>{item.customer_pan}</td>
                        <td>{item.customer_tan}</td>
                        {yearWise.map((v, i) => {
                          let count = Object.keys(item.financialYearData);
                          let index = count.findIndex((value) => value === v);

                          if (index >= 0) {
                            return (
                              <td>
                                {indianRupeesFormat(
                                  item.financialYearData[v].total_tds_difference
                                )}
                              </td>
                            );
                          } else {
                            return <td>{indianRupeesFormat(0)}</td>;
                          }
                        })}
                        {/* {Object.keys(item.financialYearData).map((values, index) => {
                                            return (
                                                <td>
                                                    {indianRupeesFormat(item.financialYearData[values].total_tds_difference)}
                                                </td>
                                            )
                                        })} */}
                        <td>{indianRupeesFormat(item.adjustment)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </BoxWrapper>
        </>
      )}
    </>
  );
}

export default MYDefaulters;
