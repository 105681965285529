import * as React from "react";
import Appbar from "../../../../components/appBar/appBar";
import Footer from "../../../../components/footer/footer";
import Tdsthreshold from "./threshold/threshold";

export default function TdspayablePage(props) {
  return (
    <div>
      <Appbar />
      <div style={{ marginTop: "80px", marginBottom: "60px" }}>
        <Tdsthreshold />
      </div>
      <Footer />
    </div>
  );
}
