import React, { useState , useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/textFieldWithoutLabel";

export default function TabForm(Props) {
    // const form = useRef("form")
    const { data, handleInputChange ,steps } = Props
    const [key , setKey] = useState([]);
    
    useEffect(() => {
            var dataForObject =Object.keys(data).map(v=> v) 
            setKey(dataForObject);
    }, [data])

    return (
        <>
            <ValidatorForm
                useRef = "form"
                onError={(errors) => console.log(errors)}
            >
                <div>
                    <Grid container spacing={2}>
                        {/* 1st row */}
                        <Grid item xs={3}>
                            <p></p>
                        </Grid>

                        <Grid item xs={3}>
                            <p>Latest Year</p>
                        </Grid>

                        <Grid item xs={3}>
                            <p>Previous Year</p>
                        </Grid>

                        <Grid item xs={3}>
                            <p>Previous-to-Previous year</p>
                        </Grid>


                        {/* 2nd row */}
                        <Grid item xs={3}>
                            <p>Industry Standard<span style={{ color: "red" }}>*</span></p>
                        </Grid>

                        <Grid item xs={3}>
                            <InputField
                                inputType="number"
                                name={key[0]}
                                value={data[key[0]]}
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                textOnchangeFunction={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <InputField

                                inputType="number"
                                name={key[1]}
                                value={data[key[1]]}
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                textOnchangeFunction={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <InputField
                                inputType="number"
                                name={key[2]}
                                value={data[key[2]]}
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                textOnchangeFunction={handleInputChange}
                            />
                        </Grid>

                        {/* 3rd row */}
                        {steps === 0 && (<>
                            
                            <Grid item xs={3}>
                            <p>PAN Mandatory For Private Circle Template</p>
                        </Grid>

                        <Grid item xs={3}>
                            <InputField
                                inputType="text"
                                name={key[3]}
                                value={data[key[3]]}
                                // validator={["required"]}
                                // errorMsg={["This field is required"]}
                                textOnchangeFunction={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={3}>

                        </Grid>

                        <Grid item xs={3}>

                        </Grid>
                        </>
                        )}
                    </Grid>
                </div>
            </ValidatorForm>
        </>
    );
};


