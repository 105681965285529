import React from 'react';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const Top5CustomerChart = ({ top5CustomerData, selectedReportTypeForTop5, onChngeTop5CustomersChart, singleReport }) => {
    // Transform top5CustomerData into the format required by the BarChart
    const chartData = top5CustomerData.map(item => ({
        Name: selectedReportTypeForTop5 === "ageing_recivable" ? item.cust_legal_name : item.vendor_name,
        Amount: item.total_outstanding_agst_ref, // Use appropriate field for the bar value
    }));

    // Function to truncate y-axis labels
    const truncateLabel = (label) => {
        return label.length > 10 ? `${label.substring(0, 10)}...` : label;
    };

    // Format labels for the X-axis
    const formatLabel = (label) => {
        if (top5CustomerData.length === 5) {
            if (label.length > 25) {
                return ` ${label.substring(0, 25)}..`;
            }
        } else if (top5CustomerData.length === 10) {
            if (label.length > 15) {
                return ` ${label.substring(0, 12)}..`;
            }
        }
        return label;
    };

    return (
        <Grid item xs={12} md={12}>
            <Paper
                sx={{
                    p: 2,
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%", // Ensure full height
                }}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography
                        variant="body1"
                        style={{
                            color: "#3B5998",
                            fontWeight: 700,
                            marginBottom: "5px",
                            textTransform: "capitalize",
                            fontFamily: `'Poppins', sans-serif`,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        TOP 5 CUSTOMERS BY OUTSTANDING AMOUNT + OTHERS
                    </Typography>
                    {!singleReport && <FormControl style={{ flex: "1", minWidth: 200, maxWidth: "20%" }} variant="outlined">
                        <InputLabel>Report Type</InputLabel>
                        <Select
                            value={selectedReportTypeForTop5}
                            onChange={onChngeTop5CustomersChart}
                            label="Report Type"
                            fullWidth
                            style={{ height: "48px" }}
                        >
                            <MenuItem value="ageing_recivable">Ageing Receivable</MenuItem>
                            <MenuItem value="ageing_payble">Ageing Payable</MenuItem>
                        </Select>
                    </FormControl>}
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "16px", height: "300px" }}>
                    <ResponsiveContainer width="95%" height="100%">
                        <BarChart data={chartData}>
                            <defs>
                                <linearGradient id="gradientColor" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#4318FF" stopOpacity={1} />
                                    <stop offset="100%" stopColor="#6AD2FF" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="Name" tickFormatter={formatLabel} textAnchor="middle"
                                interval={0} />
                            <YAxis tickFormatter={truncateLabel} />
                            <Tooltip />
                            <Bar dataKey="Amount" fill="url(#gradientColor)" maxBarSize={50} radius={[10, 10, 0, 0]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </Grid>
    );
};

export default Top5CustomerChart;
