import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BoxWrapper from "../../components/ui/boxWrapper";
import "./mdm.css";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../../axios-order";
import moment from "moment";
import { toast } from "react-toastify";
import { TablePagination } from "@material-ui/core";

const Mdm = () => {
  const [mdmType, setMdmType] = useState("CUSTOMER");
  const [tableData, setTableData] = useState([]);
  const [mutiSelector, setMutiSelector] = useState([]);

  const [tableForRadio, setTableForRadio] = useState([]);
  const [triggerTable, setTriggerTable] = useState(false);

  const [tableCheckBoxSelector, setTableCheckBoxSelector] = useState([]);
  const [isCheck, setIsCheck] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const [triggerPage, setTriggerPage] = useState(false);
  const [triggerRowPerPage, setTriggerRowPerPage] = useState(false);

  const [textsearch  , setTextsearch ] = useState("");

  const [selectAllCheck , setSelectAllCheck ] = useState(false);

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTriggerPage(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTriggerRowPerPage(true);
  };

  const handleSearch = (e) => {
    setTextsearch(e.target.value);
    setRowsPerPage(25);
    setPage(0);
  }

  useEffect(() => {
      let timer = setTimeout(() => {
        getApiForRadio(textsearch);
      } , 300);

      return () => clearTimeout(timer)
  } , [textsearch] )

  useEffect(() => {
    if (triggerPage) {
      getApiForRadio("");
    }
    setTriggerPage(false);
  }, [triggerPage]);

  useEffect(() => {
    if (triggerRowPerPage === true) {
      getApiForRadio("");
    }
    setTriggerRowPerPage(false);
  }, [triggerRowPerPage]);

  const mdmChangeHandler = (e) => {
    const { value } = e.target;
    setSelectAllCheck(false);
    setMdmType(value);

    setPage(0);
    setRowsPerPage(25);
    setRowCount(0);
    setTableCheckBoxSelector([]);
  };

  const handleChange = (event, value) => {
    if (event.target.checked) {
      setMutiSelector((pev) => pev.concat(value));
    } else {
      const index = mutiSelector.findIndex((item) => item === value);
      mutiSelector.splice(index, 1);
    }
  };

  useEffect(()=>{
    console.log(tableCheckBoxSelector,'tableCheckBoxSelector.....')
  },[tableCheckBoxSelector])

  const tableCheckBox = (event, value) => {
    const checked = event.target.checked;
  
    if (checked) {
      setTableCheckBoxSelector((prevSelector) => [...prevSelector, value]);
    } else {
      setTableCheckBoxSelector((prevSelector) =>
        prevSelector.filter((item) => item !== value)
      );
    }
  };
  

  const mdmFormSubmitHandler = () => {
    toast.configure();
    if (mdmType === "" || mutiSelector.length === 0) {
      toast.error("Please select any one MDM type and Any One filter Type", {
        autoClose: 3000,
        progress: 0,
      });
    } else {
      axios
        .post(`/mdm_karza_recon/report`, {
          karza_apis_selected: mutiSelector,
          business_entity_id: localStorage.getItem("business_entity_id"),
          cust_type: mdmType,
          selected_ids: tableCheckBoxSelector ,
          is_all_data: selectAllCheck ? true : false,
        })
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
            getApi();
            setSelectAllCheck(false);
          } else {
            toast.error(res?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        }).catch = (e) => {
        toast.error(e?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      };
    }
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadExcelSheet = (mdm_logs, cust) => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `MDM Recon Summary`;

    axios
      .post(`/mdm_karza_recon/extract`, {
        data: mdm_logs,
        cust_type: cust,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res?.data?.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert("Unable To Download Excel");
        }
      });
  };

  const downloadMDMHandler = (id, cust_type) => {
    axios.get(`/mdm_karza_recon_json_log/${id}`).then((res) => {
      if (res?.data?.status === true) {
        if (res?.data?.record?.status === "Fail") {
          alert(res?.data?.record?.error);
        } else if (res?.data?.record?.status === "Pass") {
          downloadExcelSheet(res?.data?.record?.mdm_log, cust_type);
        }
      } else {
        alert("Unable To Download Please Try Again");
      }
    }).catch = (e) => {
      alert("Unable To Download Please Try Again");
    };
  };

  const getApi = () => {
    axios
      .get(`/mdm_karza_log/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (res.data.status === true) {
          setTableData(res?.data?.record);
        } else {
          setTableData([]);
        }
      }).catch = (e) => {
      setTableData([]);
      alert(e.response.data.message);
    };
  };

  const getApiForRadio = (searchString) => {
    setTriggerTable(true);
    setTableForRadio([]);
    axios
      .post(`/get_mdm_master_data`, {
        bid: localStorage.getItem("business_entity_id"),
        cust_type: mdmType,
        filt_val: searchString,
        pg_sz: rowsPerPage,
        pg_no: page,
      })
      .then((res) => {
        if (res.data.status === true) {
          setTableForRadio(res?.data?.records);
          setRowCount(res?.data?.row_count);
          setTriggerTable(false);
        } else {
          setTriggerTable(false);
        }
      })
      .catch((e) => {
        alert("e?.response?.data?.message");
        setTriggerTable(false);
      });
  };

  useEffect(() => {
    getApiForRadio("");
  }, [mdmType]);

  useEffect(() => {
    getApi();
  }, []);

 

  const isChecked = (val) => {
    return tableCheckBoxSelector.includes(val);
  };
  
  const selectAllItems = () => {
    setSelectAllCheck(!selectAllCheck); 
  };
  
  useEffect(() => {
    if (selectAllCheck) {
      setTableCheckBoxSelector(tableForRadio.map(each => each.unique_key));
    } else {
      setTableCheckBoxSelector([]);
    }
  }, [selectAllCheck, tableForRadio]);

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="mdmType"
          value={mdmType ?? ""}
          onChange={mdmChangeHandler}
        >
          <FormControlLabel
            value="CUSTOMER"
            control={<Radio />}
            label="Customers MDM"
          />
          <FormControlLabel
            value="VENDOR"
            control={<Radio />}
            label="Vendors MDM"
          />
        </RadioGroup>
      </FormControl>

      <BoxWrapper
        boxTitle={mdmType === "CUSTOMER" ? "CUSTOMER MDM" : "VENDOR MDM"}
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearch}
        enableAddButton={true}
        addButtonLabel={!selectAllCheck? "Select All":'Deselect All'}
        buttonWidth="110px"
        addButtonHandlerFn={() => {selectAllItems(); setSelectAllCheck(!selectAllCheck)}}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>
                  {mdmType === "CUSTOMER" ? "Customer Name" : "Vendor Name"}
                </th>
                <th>GSTIN</th>
                <th>PAN</th>
                <th>Created date</th>
                <th>Modified Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mdmType === "CUSTOMER" ? (
                <>
                  {triggerTable === true && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Loading Please Wait!
                      </td>
                    </tr>
                  )}
                  {tableForRadio?.length > 0 &&
                    tableForRadio.map((values, i) => {
                      return (
                        <tr key={i}>
                          <td>{values?.cust_legal_name}</td>
                          <td>{values?.cust_gstin}</td>
                          <td>{values?.cust_pan}</td>
                          <td>{values?.create_date}</td>
                          <td>{values?.modify_date}</td>
                          <td>
                            <Checkbox
                              checked={
                                isChecked(values.unique_key)
                              }
                              onChange={(e) =>
                                tableCheckBox(e, values.unique_key)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {triggerTable?.length === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {triggerTable && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Loading Please Wait!
                      </td>
                    </tr>
                  )}
                  {tableForRadio?.length > 0 &&
                    tableForRadio.map((values, i) => {
                      return (
                        <tr key={i}>
                          <td>{values?.vendor_name}</td>
                          <td>{values?.vendor_gstin}</td>
                          <td>{values?.vendor_pan}</td>
                          <td>{values?.create_date}</td>
                          <td>{values?.modify_date}</td>
                          <td>
                          <Checkbox
                              checked={
                                isChecked(values.unique_key)
                              }
                              onChange={(e) =>
                                tableCheckBox(e, values.unique_key)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {triggerTable?.length === 0 && (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={rowCount} //total row count
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </BoxWrapper>

      {/* form */}

      <BoxWrapper boxTitle="Filter">
        <div className="mdm-form">
          <div>
            <Checkbox onChange={(e) => handleChange(e, "GSTIN")} />{" "}
            <label>GSTIN</label>
          </div>
          <div>
            <Checkbox onChange={(e) => handleChange(e, "PAN")} />{" "}
            <label>PAN</label>
          </div>
          <div>
            <Checkbox onChange={(e) => handleChange(e, "TAN")} />{" "}
            <label>TAN</label>
          </div>
          <div>
            <Checkbox onChange={(e) => handleChange(e, "MSME")} />{" "}
            <label>MSME</label>
          </div>
          <div>
            <Checkbox onChange={(e) => handleChange(e, "CIN/LPIN")} />{" "}
            <label>CIN/LPIN</label>
          </div>
          <div>
            <button
              className="button-outlined-primary"
              onClick={mdmFormSubmitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </BoxWrapper>
      {/* history table */}
      <BoxWrapper
        boxTitle="MDM History"
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApi}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>MDM Type</th>
                <th>Created date & time</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.length > 0 &&
                tableData?.map((item, i) => {
                  return (
                    <tr key={`${i}mdm`}>
                      <td>{item?.cust_type}</td>
                      <td>
                        {moment(item?.initiated_time).format(
                          "DD/MM/YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td>{item?.status}</td>
                      <td>
                        {item?.status === "Pass" ? (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            onClick={() =>
                              downloadMDMHandler(item?.id, item?.cust_type)
                            }
                          />
                        ) : item?.status === "Fail" ? (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            style={{ color: "red" }}
                            onClick={() =>
                              downloadMDMHandler(item?.id, item?.cust_type)
                            }
                          />
                        ) : (
                          <DownloadIcon
                            className="action-icon-button showIcon"
                            style={{ color: "#ddd" }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              {tableData?.length === 0 && (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center", color: "red" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
};

export default Mdm;
