import React,{useState} from 'react';
import BoxWrapper from './boxWrapper';
import SummaryDate from './SummaryDate';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import axios from "../../axios-order";
import { styled } from '@mui/material/styles';
import { Grid,  Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";

const colors = [
  '#339BFF', '#FF33D4', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
  '#FF5733', '#CCCCFF', '#FF6384', '#FF33A1', '#33FFF5'
];

const graphImageUrl = 'https://cdn-icons-png.freepik.com/256/16170/16170706.png?ga=GA1.1.706857113.1721211877&semt=ais_hybrid'

const tableImageUrl = 'https://img.freepik.com/premium-vector/audio-symbol-icon-flat-color-circle-style_755164-19997.jpg?ga=GA1.1.706857113.1721211877&semt=ais_user';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                borderRadius: '40px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                backgroundImage: `url(${graphImageUrl})`, // Use graph image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
        '&:not(.Mui-checked)': {
            color: '#fff',
            transform: 'translateX(-6px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundSize: 'contain',
                borderRadius: '35px',
                backgroundImage: `url(${tableImageUrl})`, // Use table image URL here
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

function EinvoiceDashBoard() {

  const [fromDateMonthYearDisplay, setFromDateMonthYearDisplay] = useState(null);
  const [toDateMonthYearDisplay, setToDateMonthYearDisplay] = useState(null);
  const [records, setRecords] = useState([]);
  const [invoiceTypeDetails, setInvoiceTypeDetails] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState(null); // Initialize as null
  const [selectedRange, setSelectedRange] = useState("");
  const [showCustomDatePickers, setShowCustomDatePickers] = useState(false);
  const [displayTable, setDisplayTable] = useState(true);
   const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const handleClose = () => {
    setOpen(false);
  };


  const handleRangeChange = (range) => {
    setSelectedRange(range);
    const today = new Date();
  
    if (range === "custom") {
      setShowCustomDatePickers(true);
    } else {
      setShowCustomDatePickers(false);
      let fromDate, toDate;
  
      if (range === "today") {
        fromDate = toDate = today;
      } else if (range === "thisMonth") {
        fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
        toDate = today;
      }
  
      if (fromDate && toDate) {
        setFromDateMonthYearDisplay(formatDateISO(fromDate));
        setToDateMonthYearDisplay(formatDateISO(toDate));
      }
    }
  };
  
  // Utility function to format date in YYYY-MM-DD format
  const formatDateISO = (date) => {

    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error('Invalid date object');
    }
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

const handleSwitchChange = (event) => {
  setDisplayTable(event.target.checked);
};


const inputHandlerFromDatePicker = (date) => {
  const dateObject = new Date(date); // Convert the input to a Date object
  if (!isNaN(dateObject.getTime())) { // Check if the date object is valid
    setFromDateMonthYearDisplay(formatDateISO(dateObject));
  } else {
    console.error('Invalid date passed to inputHandlerFromDatePicker:', date);
  }
};

const inputHandlerToDatePicker = (date) => {
  const dateObject = new Date(date); // Convert the input to a Date object
  if (!isNaN(dateObject.getTime())) { // Check if the date object is valid
    setToDateMonthYearDisplay(formatDateISO(dateObject));
  } else {
    console.error('Invalid date passed to inputHandlerToDatePicker:', date);
  }
};

const onSubmitHandler = async () => {
  try {
    let fromDate = fromDateMonthYearDisplay;
    let toDate = toDateMonthYearDisplay;

    // If no range is selected, default to "today"
    if (!fromDate || !toDate) {
      if (!selectedRange || selectedRange === "today") {
        const today = new Date();
        fromDate = formatDateISO(today);
        toDate = formatDateISO(today);
        setFromDateMonthYearDisplay(fromDate);
        setToDateMonthYearDisplay(toDate);
      }
    }

    // Prepare payload with the potentially updated dates
    const business_entity_id = localStorage.getItem('business_entity_id');

    const payload = {
      from_date: fromDate,
      to_date: toDate,
      business_entity_id: business_entity_id
    };

    // Clear previous data before making the API request
    setCardDetails(null);
    setCategoryDetails([]);
    setInvoiceTypeDetails([]);

    const response = await axios.post('/einvoice/dashboard_einvoice', payload);

    if (response.status === 200) {
      const { card_details, category_details, invoice_type_details } = response.data;

      // Set new data if available
      setCardDetails(card_details || {}); // Ensure cardDetails is an empty object if no data
      setCategoryDetails(category_details || []);
      setInvoiceTypeDetails(invoice_type_details || []);
      
      // Handle cases where the data is empty or undefined
      if (!card_details && !category_details.length && !invoiceTypeDetails.length) {
        setMessage('No data available for the selected date range');
        setSeverity('info');
        setOpen(true);
      }
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    setMessage(errorMessage);
    setSeverity('error');
    setOpen(true);
  }
};  



//   try {
//     // If no range is selected, default to "today"
//     if (!fromDateMonthYearDisplay || !toDateMonthYearDisplay) {
//       if (!selectedRange || selectedRange === "today") {
//         const today = new Date();
//         setFromDateMonthYearDisplay(formatDateISO(today));
//         setToDateMonthYearDisplay(formatDateISO(today));
//       }
//     }

//     // if (!fromDateMonthYearDisplay || !toDateMonthYearDisplay) {
//     //   setMessage('Please select both From Date and To Date');
//     //   setSeverity('warning');
//     //   setOpen(true);
//     //   return;
//     // }

//     const business_entity_id = localStorage.getItem('business_entity_id');

//     const payload = {
//       from_date: fromDateMonthYearDisplay,
//       to_date: toDateMonthYearDisplay,
//       business_entity_id: business_entity_id
//     };

//     // Clear previous data before making the API request
//     setCardDetails(null);
//     setCategoryDetails([]);
//     setInvoiceTypeDetails([]);

//     const response = await axios.post('/einvoice/dashboard_einvoice', payload);

//     if (response.status === 200) {
//       const { card_details, category_details, invoice_type_details } = response.data;

//       // Set new data if available
//       setCardDetails(card_details || {}); // Ensure cardDetails is an empty object if no data
//       setCategoryDetails(category_details || []);
//       setInvoiceTypeDetails(invoice_type_details || []);
      
//       // Handle cases where the data is empty or undefined
//       if (!card_details && !category_details.length && !invoiceTypeDetails.length) {
//         setMessage('No data available for the selected date range');
//         setSeverity('info');
//         setOpen(true);
//       }
//     } else {
//       throw new Error('Failed to fetch data');
//     }
//   } catch (error) {
//     const errorMessage = error.response?.data?.message || 'An error occurred';
//     setMessage(errorMessage);
//     setSeverity('error');
//     setOpen(true);
//   }
// };  


// const onSubmitHandler = async () => {
//   try {
//     if (!fromDateMonthYearDisplay || !toDateMonthYearDisplay) {
//       setMessage('Please select both From Date and To Date');
//       setSeverity('warning');
//       setOpen(true);
//       return;
     
//     }
// const business_entity_id = localStorage.getItem('business_entity_id');

//     const payload = {
//       from_date: fromDateMonthYearDisplay,
//       to_date: toDateMonthYearDisplay,
//       business_entity_id:business_entity_id
//     };

//     const response = await axios.post('/einvoice/dashboard_einvoice', payload);

//      // Clear previous data before making the API request
//      setCardDetails(null);
//      setCategoryDetails([]);
//      setInvoiceTypeDetails([]);

//     if (response.status === 200 && response.data.status) {
//       setCardDetails(response.data.card_details);
//       setCategoryDetails(response.data.category_details);
//       setInvoiceTypeDetails(response.data.invoice_type_details);
     
//     } else {
//       throw new Error('Failed to fetch data');
      
//     }
//   } catch (error) {
    
//     const errorMessage = error.response?.data?.message ;
//       setMessage(errorMessage);
//       setSeverity('error');
//       setOpen(true);
//   }
// };
const calculateBarHeight = (amount) => {
  // Example scale: You might need to adjust this based on your data
  const maxAmount = Math.max(...categoryDetails.map(item => item.generated_amount + item.pending_amount + item.cancelled_amount));
  return (amount / maxAmount) * 100;
};


  return (
    <>
    <div>
    <BoxWrapper
boxTitle="E-INVOICE DASHBOARD "

>
<ValidatorForm
useRef="form"
onSubmit={onSubmitHandler}
onError={(errors) => console.log(errors)}
>

<Grid item xs={12}>
<div style={{ display: 'flex', justifyContent:'flex-start' }}>
<div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginRight: '16px' }}>
<div style={{ marginBottom: '1rem' }}>
          <label style={{ margin: 0 }}>
            Period Range<span style={{ color: "red" }}>*</span>:
          </label>
          <select
            value={selectedRange}
            onChange={(e) => handleRangeChange(e.target.value)}
            style={{ width: '100%', margin: '0 0 1rem 0', padding: '0.5rem', fontSize: '14px' }}
          >
            
            <option value="today">Today</option>
            <option value="thisMonth">This Month</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        
  
     {showCustomDatePickers && (
        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ marginBottom: '1rem' , flex: '1' }}>
            <label style={{ margin: 0 }}>
              From Date<span style={{ color: "red" }}>*</span>:
            </label>
            <SummaryDate
              placeholder="From Date"
              errorMessages={["This field is required"]}
              validators={["required"]}
              value={fromDateMonthYearDisplay ?? ""}
              onChange={(v) => inputHandlerFromDatePicker(v)}
              style={{ width: '100%', margin: 0, padding: 0 }}
            />
          </div>
          <div style={{ marginBottom: '1rem' , flex: '1' }}>
            <label style={{ margin: 0 }}>
              To Date<span style={{ color: "red" }}>*</span>:
            </label>
            <SummaryDate
              minDateForDatepicker={fromDateMonthYearDisplay ?? ""}
              placeholder="To Date"
              errorMessages={["This field is required"]}
              validators={["required"]}
              value={toDateMonthYearDisplay ?? ""}
              onChange={(v) => inputHandlerToDatePicker(v)}
              style={{ width: '100%', margin: 0, padding: 0 }}
            />
          </div>
        </div>
      )}
    <div style={{ marginTop: '1rem' }}>
      <Button
      
        aria-haspopup="true"
     type="submit"
        variant="outlined"
        color="primary"
        sx={{ height: '12px' }} 
        style={{marginTop:'5px',marginLeft:'5px'}}
    >
        SUBMIT
       
    </Button>
    </div>
</div>
<div>




</div>
</div>


</Grid>
</ValidatorForm>
</BoxWrapper>







  <Grid item xs={12}>
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', margin: '3px' }}>
      {/* Display the switch only if cardDetails is available */}
      {cardDetails && (
        <FormControlLabel
          control={<MaterialUISwitch checked={displayTable} onChange={handleSwitchChange} />}
          label={
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {displayTable ? "Data Table" : "Graph Report"}
            </Typography>
          }
        />
      )}

      {/* Display cardDetails or Graphs based on switch state */}
      {displayTable ? (
        cardDetails ? (
          <Card >
          
          </Card>
        ) : (
          <Typography variant="body1"></Typography>
        )
      ) : (
        <>
         {cardDetails && (
    <Card sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '8px' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
        {Object.keys(cardDetails).map((key, index) => (
          <Grid key={index} item xs={12} md={8} lg={12} style={{ flex: '1 1 25%', maxWidth: 'calc(49% - 16px)' }}>
            <Paper
              sx={{
                p: 2.1,
                display: 'flex',
                flexDirection: 'column',
                borderTop: `5px solid ${colors[index % colors.length]}`,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography textAlign="center" variant="h7" sx={{ fontSize: '1.25rem' }}>
                {key.replace(/_/g, ' ')}
              </Typography>
              <Typography textAlign="center" variant="h6">
                {cardDetails[key]}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </CardContent>
    </Card>
  )}
          {/* Category Type Graph */}
          {categoryDetails && categoryDetails.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4
                  style={{
                    textAlign: 'center',
                    background: 'linear-gradient(to right, lightblue, darkblue)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  CATEGORY WISE
                </h4>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={categoryDetails.filter(item => item.category !== 'Grand Total')}>
                    <XAxis dataKey="category" tick={{ angle: 45, textAnchor: 'start' }} interval={0} />
                    <YAxis hide={true} />
                    <Tooltip />
                    <Legend />
                    <Bar barSize={50} dataKey="generated_amount" fill="#37afa9" name="Generated Amount" />
                    <Bar barSize={50} dataKey="pending_amount" fill="#2e96ff" name="Pending Amount" />
                    <Bar barSize={50} dataKey="cancelled_amount" fill="#b800d8" name="Cancelled Amount" />
                    <Bar barSize={50} dataKey="generated_count" fill="#60009b" name="Generated Count" />
                    <Bar barSize={50} dataKey="pending_count" fill="#60009b" name="Pending Count" />
                    <Bar barSize={50} dataKey="cancelled_count" fill="#2731c8" name="Cancelled Count" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          )}

          {/* Invoice Type Graph */}
          {invoiceTypeDetails && invoiceTypeDetails.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4
                  style={{
                    textAlign: 'center',
                    background: 'linear-gradient(to right, lightblue, darkblue)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  INVOICE TYPE WISE
                </h4>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={invoiceTypeDetails.filter(item => item.invoice_type !== 'Grand Total')}>
                    <XAxis dataKey="invoice_type" tick={{ angle: 45, textAnchor: 'start' }} interval={0} />
                    <YAxis hide={true} />
                    <Tooltip />
                    <Legend />
                    <Bar barSize={50} dataKey="generated_amount" fill="#37afa9" name="Generated Amount" />
                    <Bar barSize={50} dataKey="pending_amount" fill="#2e96ff" name="Pending Amount" />
                    <Bar barSize={50} dataKey="cancelled_amount" fill="#b800d8" name="Cancelled Amount" />
                    <Bar barSize={50} dataKey="generated_count" fill="#60009b" name="Generated Count" />
                    <Bar barSize={50} dataKey="pending_count" fill="#60009b" name="Pending Count" />
                    <Bar barSize={50} dataKey="cancelled_count" fill="#2731c8" name="Cancelled Count" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {/* Display Tables only when 'Data Table' is selected */}
      {displayTable && (
        <>
          {/* Category Type Details Table */}
          {categoryDetails && categoryDetails.length > 0 ? (
            <TableContainer component={Paper} sx={{ maxHeight: '300px', overflow: 'auto', scrollbarWidth: 'thin' }}>
              <Table sx={{ tableLayout: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>CATEGORY</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>GENERATED COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>GENERATED AMOUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>PENDING COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>PENDING AMOUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>CANCELLED COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>CANCELLED AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryDetails.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.generated_count}</TableCell>
                      <TableCell>{item.generated_amount}</TableCell>
                      <TableCell>{item.pending_count}</TableCell>
                      <TableCell>{item.pending_amount}</TableCell>
                      <TableCell>{item.cancelled_count}</TableCell>
                      <TableCell>{item.cancelled_amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" sx={{ marginTop: '16px' }}></Typography>
          )}

          {/* Invoice Type Details Table */}
          {invoiceTypeDetails && invoiceTypeDetails.length > 0 ? (
            <TableContainer component={Paper} sx={{ maxHeight: '300px', overflow: 'auto', scrollbarWidth: 'thin' }}>
              <Table sx={{ tableLayout: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>INVOICE TYPE</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>GENERATED COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>GENERATED AMOUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>PENDING COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>PENDING AMOUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>CANCELLED COUNT</TableCell>
                    <TableCell style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold', paddingLeft: '15px' }}>CANCELLED AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceTypeDetails.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.invoice_type}</TableCell>
                      <TableCell>{item.generated_count}</TableCell>
                      <TableCell>{item.generated_amount}</TableCell>
                      <TableCell>{item.pending_count}</TableCell>
                      <TableCell>{item.pending_amount}</TableCell>
                      <TableCell>{item.cancelled_count}</TableCell>
                      <TableCell>{item.cancelled_amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" sx={{ marginTop: '16px' }}></Typography>
          )}
        </>
      )}
    </div>
  </Grid>
<Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        action={
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
        }
      >
        <Alert onClose={handleClose} variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
</>
  );
}

export default EinvoiceDashBoard;
