import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import CustomCard from "../../../../components/ui/Cards";

// indian rupeess system---
const indianRupessFormat = (value) => {
  if (value) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 10,
    }).format(value?.toFixed(2));
  } else {
    return `₹0`;
  }
};

// ---End---

const Cards = (props) => {
  const { cardData } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#3b3b6b"
          cardTitle="Total Receivables"
          cardBody={cardData && indianRupessFormat(cardData.total_receivables)}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#00818A"
          cardTitle="Not Yet Due"
          cardBody={cardData && indianRupessFormat(cardData.not_yet_due)}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#065471"
          cardTitle="Total Overdue"
          cardBody={cardData && indianRupessFormat(cardData.total_overdue)}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#1F4068"
          cardTitle="Excess Receivables"
          cardBody={
            cardData && indianRupessFormat(cardData.advance_excess_received)
          }
        />
      </Grid>
      {/* 2nd row */}
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#519872"
          cardTitle="DSO"
          cardBody={cardData && cardData.dso}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#4B5D67"
          cardTitle="# Invoices Not Yet Due"
          cardBody={cardData && cardData.no_of_invoices_not_yet_due}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#322F3D"
          cardTitle="#Invoices Overdue"
          cardBody={cardData && cardData.no_of_invoices_overdue}
        />
      </Grid>

      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#d04646 "
          cardTitle="# Excess"
          cardBody={cardData && cardData.no_of_advance_excess}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#3a79de"
          cardTitle="#Advance"
          cardBody={cardData && cardData.total_advance}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomCard
          backgroundcolor="#3cb560"
          cardTitle="#Count"
          cardBody={cardData && cardData.total_advance_count}
        />
      </Grid>
    </Grid>

  );
};
export default Cards;
