import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function DashboardBarChart(props) {
    const { chartTitle, chartCategories, yAxisTitle, xAxisTitle,chartData } = props

    const setOptions = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false,
        },
        title: {
            text: chartTitle,
        },

        xAxis: {
            categories:chartCategories,
            crosshair: true,
            title: {
                text: xAxisTitle
            }
        },

        yAxis: {
            min: 0,
            title: {
                text: yAxisTitle
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            //     `<td style="padding:0"><b>` + indianRupeesFormat(99999999) + `</b></td></tr>`,
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                `<td style="padding:0"><b>` + `{point.y}` + `</b></td></tr>`,
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            valuePrefix: '₹',
            valueDecimals: 2
        },

        plotOptions: {
            // series: {
            //     pointWidth: 15,
            // },
            column: {
                pointPadding: 0.2,
                borderRadius: 3,
                borderWidth: 0
            }
        },
        series: chartData
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={setOptions} />

    );
}

export default DashboardBarChart;