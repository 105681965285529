import React, { useState, useEffect } from "react";
import { Box, Paper, Modal } from "@mui/material";
import { Button, Grid, TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import RightDrawer from "../../components/ui/RightDrawer";
import IconButton from '@mui/material/IconButton';
import axios from "../../axios-order"
import Tooltip from '@mui/material/Tooltip';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  boxShadow: 24,
  background: "#fff",
};

//getting the api url from env file and converting to string
const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;


const ReportSync = ({
   openZohoDrawer,
   closeZohoDrawer,
   effitracImportFormloader,
   nameOfTheModel,
}) => {

  const [logsLoader, setLogsLoader] = useState(true);
  const [logRecords, setLogRecords] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [index, setIndex] = useState();
  const [zohoData, setZohoData] = useState([]);
  const [triggerZohoTable, setTriggerZohoTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

    const getZohoLogs = () => {
        setLogsLoader(true);
        axios.get(`${apiUrl}/cwsapims/api/v1/notification-tracker/entity-code?entityCode=${localStorage.getItem("business_entity_id")}&page=${page}&size=${rowsPerPage}`)
         .then(response => {
        
        // console.log()
        setLogRecords(response.data.notificationTracker)
        setLogsLoader(false);
        setTotalElements(response.data.totalElements)
        })
        .catch((error) => {
        setLogsLoader(false);
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const rowsPerPageOptions = [10,25,50,100];
    

    const handleReportType = (name) => {
        let type = "";
        switch (name) {
          case "reconciliationReport":
            type = "GST Reconciliation Report";
            break;
          case "collectionStatusReport":
            type = "AR Collection Status Report";
            break;
          case "collectionAnalysisReport":
            type = "AR Collection Analysis Report";
            break;
          case "gstrComputationReport":
            type = "GST Computation Report ";
            break;
          case "itcAvailReport":
            type = "ITC Available Report";
            break;
          case "arapAgeingReport":
            type = "AR and AP Ageing Report";
            break;
          case "multiMonthReport":
            type = "Multi Month Report";
            break;
          case "itemWiseReport":
            type = "Item Wise Report";
            break;
        }
        console.log(type,"type")
        return type;
    };

    useEffect(() => {
    axios.get(`${apiUrl}/cwsapims/api/v1/notification-tracker/entity-code?entityCode=${localStorage.getItem("business_entity_id")}&page=${page}&size=${rowsPerPage}`)
    .then(response => {
        
        // console.log()
        setLogRecords(response.data.notificationTracker)
        setLogsLoader(false);
        setTotalElements(response.data.totalElements)
    })
    .catch((error) => {
        
        setLogsLoader(false);
    });
    }, [rowsPerPage, page]);



  return (
    <Modal
      keepMounted
      open={openZohoDrawer}
      // onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            color: "#fff",
            background: "#d04646",
            padding: "7px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontFamily: "Dosis", marginBottom: "0px" }}>
            {nameOfTheModel}
          </h4>
          
        </div>

        <div className="modal-body">
          {triggerZohoTable ? (
            <>
              {/* <CustomTable
                // headers={zohoTableHeader}
                columns={zohoTableColumn}
                data={zohoData}
                actions={drawerKeys.length === 0 ? null : actions}
              /> */}
            </>
          ) : (
            <>
              

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0px",
                  borderBottom: "2px solid #ddd",
                  fontFamily: "Dosis",
                }}
              > 
              

              <Tooltip title='refresh'>
                <IconButton
                variant="contained"
                rounded
                  component="label"
                  size="large"
                  color="success"
                  style={{ marginLeft:'92%' }}
                  onClick={() => getZohoLogs()}
                >
                  <RefreshIcon style={{ fontSize: "30px" }} />
                 
                </IconButton>
                </Tooltip>
              </div>
             
            <div className="responsive-table">
            <table className="custome-table">
            <thead>
                <tr>
                
                <th>Report Name</th>
                <th>WhatsApp Status</th>
                <th>Mail Status</th>
                <th>Notification Date</th>
                </tr>
            </thead>
            <tbody>
                {logsLoader ? (
                <tr>
                    <td style={{ fontWeight: "bold" }} colSpan={7}>
                    Loading..Please Wait!
                    </td>
                </tr>
                ) : (
                <>
                    {logRecords.length > 0 ? (
                    logRecords.map((v) => (
                        <tr key={v.id}>
                        <td>{handleReportType(v.reportName)}</td>
                        <td>{v.whatsAppStatus}</td>
                        <td>{v.mailStatus}</td>
                        <td>{v.createdDate&&`${v.createdDate[2]}-${v.createdDate[1]}-${v.createdDate[0]}`}</td>
                        
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td style={{ color: "red", fontWeight: "bold" }} colSpan={7}>
                        No Record Found!!
                        </td>
                    </tr>
                    )}
                </>
                )}
            </tbody>
            </table>
            
            </div>
            
            </>
          )}
         {logRecords.length>0 && <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
         
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button-outlined-secondary "
            onClick={closeZohoDrawer}
          >
            Close
          </button>
        </div>
        <RightDrawer>
        </RightDrawer>
      </Box>
    </Modal>
  );
};

export default ReportSync;

