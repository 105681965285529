import React, { Component } from "react";
import classes from "./Faq.module.css";
import axios from "../../../axios-order";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../components/appBar/appBar";
import { NavLink } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import MuiTreeView from "material-ui-treeview";
import Modal from "react-bootstrap/Modal";
import Grid from "@material-ui/core/Grid";
import BasicInputField from "../../../components/ui/basicInput";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProfileData: null,
      editProfileData: {
        user_name: "",
        user_email_id: "",
        user_contact_no: "",
        user_role: "",
        status: ""
      },
      change_password: {},
      editDrawer: false,
      changePasswordDrawer: false,
      original_tree: [],
      addModalOpen: false,
      parent_id: null
    };
  }

  recursion = (first_tree, original_tree) => first_tree.map((v, i) => {
    let parent_id = v.id;
    const inside_tree = original_tree.filter(v => v.parent_id === parent_id);
    if (inside_tree.length) {

      return {
        value: <div>{v.title}{v.file ? <Button size="small" variant="outlined" style={{ marginRight: "10px", marginLeft: "10px" }} onClick={() => this.downloadPdf(v.id, `${v.title}.pdf`)}>  Download</Button> : ""}   <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => this.addButtonHandler(v.id)}

        >Add</Button><Button
        variant="contained"
        color="error"
        size="small"
        onClick={() => this.deleteButtonHandler(v.id)}

      >Delete</Button></div>,
        nodes: this.recursion(inside_tree, original_tree),
      };
    } else {


      return {
        value: <div>{v.title}{v.file ? <Button size="small" variant="outlined" style={{ marginRight: "10px", marginLeft: "10px" }} onClick={() => this.downloadPdf(v.id, `${v.title}.pdf`)}>  Download</Button> : ""}<Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => this.addButtonHandler(v.id)}

        >Add</Button><Button
        variant="contained"
        color="error"
        size="small"
        onClick={() => this.deleteButtonHandler(v.id)}

      >Delete</Button></div>,
        nodes: []
      }
    }

  });


  getFaqTree = () => {
    axios.get("faq").then(res => {
      let tree = res.data;
      let first_tree = tree.filter(v => v.parent_id === 0)
      this.setState({
        original_tree: this.recursion(first_tree, tree)
      })
    })


  }

  downloadPdf = (faq_id, file_name) => {
    axios.get(`download_faq/${faq_id}`).then(res => {
      this.downloadBase64File(res.data.file, file_name);
    })
  }

  deleteButtonHandler = (faq_id) => {
    toast.configure();
    axios.delete(`faq/${faq_id}`).then(res => {
      // this.downloadBase64File(res.data.file, file_name);
      toast.success("File processing completed.", {
        autoClose: 5000,
        progress: 0,
      });
      this.getFaqTree();
    })
  }

  downloadBase64File = (base64Data, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  componentDidMount = () => {
    this.getFaqTree()
  };

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  getTitle = (event) => {
    event.preventDefault();
    const value = event.target.value;
    this.setState((prevState) => ({
      title: value
    }));
  };

  backButton = () => {
    this.props.history.goBack();
  };

  addButtonHandler = (parent_id) => {

    this.setState({
      addModalOpen: true,
      parent_id: parent_id
    })
  }

  addModalCloseHandler = () => {
    this.setState({
      addModalOpen: false
    })
  }

  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ file: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    toast.configure();

    axios
      .post(`/faq`, {
        file: this.state.file,
        title: this.state.title,
        parent_id: this.state.parent_id
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
        } else {
          toast.success("File processing completed.", {
            autoClose: 5000,
            progress: 0,
          });
          this.getFaqTree();
        }
      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 3000,
          progress: 0,
        });
        this.getFaqTree();
      }).finally(() => {
        this.setState({
          file: null,
          title: null,
          parent_id: null,
          file_name: "",
          loading: false
        })
      })
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };


  render() {
    return (
      <>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.addModalOpen}
          onHide={this.addModalCloseHandler}
          dialogClassName="modal-50w"
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Form 26AS upload [UPLOADER(File input)] */}
              <div
                style={{
                  padding: "20px",
                  marginBottom: "10px",
                  boxShadow: "0px 0px 3px 0px #fff",
                  boxShadow: "0px 1px 1px 1px #ddd",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "0px 20px 20px 20px",
                        marginTop: "30px",
                        marginBottom: "10px",
                        boxShadow: "0px 0px 3px 0px #fff",
                        boxShadow: "0px 1px 1px 1px #ddd",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item sm={3}>
                          <p
                            style={{ marginTop: "30px", fontSize: "16px" }}
                          >
                            Title
                            <span style={{ color: "red" }}>*</span>:
                          </p>
                        </Grid>
                        <Grid item sm={9}>
                          <Grid container spacing={3}>

                            <Grid item sm={9}>
                              <BasicInputField
                                inputType="text"
                                name="title"
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                value={this.state.title ?? ""}
                                textOnchangeFunction={this.getTitle}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={3} md={3}>
                        <Button
                          size="small"
                          variant="contained"
                          component="label"
                          fullWidth
                          style={{ marginTop: "18px" }}
                        >
                          Browse
                          <input
                            id="file_value"
                            name="pdf_file"
                            accept="application/pdf"
                            type="file"
                            hidden
                            onChange={this.fileUploader}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={9} md={9}>
                        <ul style={{ width: "100%" }}>
                          <li
                            style={{
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <TextValidator
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                              placeholder="Choose file"
                              value={this.state.file_name}
                              name="GSTJson_file_uploader"
                              inputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="small"
                variant="contained"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={this.addModalCloseHandler}
              >
                Close
              </Button>
            </Modal.Footer>

          </ValidatorForm>
        </Modal>

        <Appbar />
        <div className={classes.profileSection}>
          <div className={classes.PesonalInfo}>
            <div className={classes.ProfileHeader}>FAQ</div>
            <div className={classes.profileBody}>
              <ul>
                <li style={{ marginRight: "10px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={this.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.addButtonHandler(0)}
                    style={{ marginLeft: "10px" }}
                  >
                    add
                  </Button>

                </li>
              </ul>
              {/* ---profile data--- */}
            </div>
            {/* ---End--- */}
            <MuiTreeView tree={this.state.original_tree} />
          </div>
        </div>
        {/* ---Footer--- */}
        <div className={classes.profileFooter}>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}
export default Faq;
