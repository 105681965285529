import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import SelectField from "../../components/ui/basicSelectInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@material-ui/core";
import axios from "../../axios-order";
import gstReconStyle from "./Reporting.module.css";
import ValidatedDatePicker from "../../components/ui/monthWiseDatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import BoxWrapper from "../../components/ui/boxWrapper";
import "../../HOC/layout/layout.css";

export default function Reporting() {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [forDisplayingFromMonthAndYear, setForDisplayingFromMonthAndYear] =
    useState(null);
  const [inputValues, setInputValues] = useState("optionA");
  const [tableData, setTableData] = useState([]);
  const [tableDataSearch, setTableDataSearch] = useState([]);

  //..........On Change Event To Handle From Tax-Period (Month And Year).....
  const inputHandlerForYearAndFromMonth = (v) => {
    setForDisplayingFromMonthAndYear(v);
    const current_date = new Date(v);
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    setMonth(mm);
    setYear(yy);
  };

  //...........On Change Event To Handle Threshold Value...................
  const roundValueInputHandler = (e) => {
    let value = e.target.value;

    setInputValues(value);
  };

  const handleOpenAndCloseModel = () => {
    setMonth(null);
    setYear(null);
    setForDisplayingFromMonthAndYear(null);
    setInputValues("");
  };
  console.log(inputValues);
  //..................Submit Button......................
  const onSubmitHandler = () => {
    let optionFlag;
    if (inputValues === "optionA") {
      optionFlag = true;
    } else {
      optionFlag = false;
    }

    axios
      .post(`/report_generation`, {
        functionality: "report_generation",
        business_entity_id: parseInt(
          localStorage.getItem("business_entity_id")
        ),
        report_month: parseInt(month),
        report_year: parseInt(year),
        option: optionFlag,
      })
      .then((res) => {
        console.log(res.data);
        getApiForReport();
      })
      .catch((err) => console.log(err));
  };

  const getApiForReport = () => {
    axios
      .get(`/get_cws_gst_log/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        setTableData(res.data.records);
        setTableDataSearch(res.data.records);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  };

  useEffect(() => {
    getApiForReport();
  }, []);

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleDownloadExcel = (file_id) => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `GSTR-1_Filing.xlsx`;

    axios
      .post(`/download_gstr_upload_file`, {
        file_id: file_id,
        extension: "xlsx",
      })
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert("Unable download!....");
        }
      })
      .catch((error) => {
        alert("Server Error Try After Some Time");
        // console.log(error);
      });
  };

  const handleSearchEvent = (e) => {
    let searchValue = e.target.value.trim();
    const serachFile = tableDataSearch.filter((v) => {
      return (
        String(v.period).toLowerCase().indexOf(searchValue.toLowerCase()) >=
          0 ||
        String(v.date).indexOf(searchValue) >= 0 ||
        String(v.status).toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );
    });
    setTableData(serachFile);
  };

  return (
    <React.Fragment>
      <BoxWrapper boxTitle="GSTR-1 Filing ">
        <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <Grid container spacing={3}>
            <Grid item sm={5}>
              <label>
                Reporting Month<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                placeholder="Month & Year"
                errorMessages={["This field is required"]}
                validators={["required"]}
                value={forDisplayingFromMonthAndYear}
                onChange={(v) => inputHandlerForYearAndFromMonth(v)}
              />
            </Grid>
            <Grid item sm={5}>
              <label>Option:</label>
              <SelectField
                LabledisplayProperty="none"
                // validator={['required']}
                // errorMsg={['This field is required']}
                choice_id="id"
                choice_name="roundOff"
                name="threshold_value"
                value={inputValues ?? ""}
                choices={[
                  { id: "optionA", roundOff: "Option A" },
                  { id: "optionB", roundOff: "Option B" },
                ]}
                textOnchangeFunction={roundValueInputHandler}
              />
            </Grid>
            <Grid item sm={1}>
              <button
                style={{ marginTop: "28px", width: "100%", padding: "7px" }}
                className="button-primary"
                type="submit"
              >
                Submit
              </button>
            </Grid>
            <Grid item sm={1}>
              {" "}
              <button
                className="button-outlined-secondary"
                style={{ marginTop: "28px", width: "100%", padding: "7px" }}
                type="button"
                onClick={handleOpenAndCloseModel}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </BoxWrapper>

      <BoxWrapper
        boxTitle="GSTR-1 Filing Report"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
        // refresh button
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApiForReport}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Period </th>
                <th>Report Generated Date</th>
                <th>Status</th>
                <th>Download Excel</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((value) => {
                return (
                  <tr>
                    <td>{value.period}</td>
                    <td>{value.date}</td>
                    <td>{value.status}</td>
                    <td>
                      <DownloadIcon
                        style={{
                          color: value.file_id != null ? "#63acf7" : "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          value.file_id != null &&
                            handleDownloadExcel(value.file_id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
              {tableData.length <= 0 && (
                <tr>
                  <td style={{ color: "red", textAlign: "center" }} colSpan={4}>
                    No Data Found!!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </React.Fragment>
  );
}
