import axios from "../../../../axios-order";
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import { useHistory } from "react-router-dom";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const GstComputationDetailedReport = () => {
    const { reconId } = useParams();
    
    let history = useHistory();

    const [data, setData] = useState(null)
    const [formValues, setFormValues] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [loadingexcel, setLoadingExcel] = useState(false)
    const [responseMessage, setResponseMessage] = useState("");

    const [confirmButtonloading, setConfirmButtonloading] = useState(false)

    const [isConfirm, setIsConfirm] = useState(localStorage.getItem('is_confirm'))

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [itemName, field] = name.split('.');

        setFormValues(prevValues => ({
            ...prevValues,
            [itemName]: {
                ...prevValues[itemName],
                [field]: value
            }
        }));
    };


    useEffect(() => {
        getGstComputationData()
    }, [])


    const getGstComputationData = () => {

        axios.get(`get_gst_computation_report_data/${reconId}`)
            .then((res) => {
                setData(res.data)

                const initialValues = {};
                if (res.data) {
                    res.data.Inward.Reversal.forEach(item => {
                        initialValues[item.particulars] = {
                            ...item
                        };
                    });
                }
                setFormValues(initialValues);
            })
            .catch((err) => {

            })
    }

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);


    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarSeverity("success");
        setResponseMessage("");

    };

    const handleSave = () => {
        const payLoadData = {
            'table1_reversal_data': formValues,
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'summary_id': reconId
        }
        setLoading(true)
        axios.post('/save_computation_table1', payLoadData)
            .then(response => {
                handleSnackbarOpen(response?.data?.message, 'success')
                setLoading(false)
                localStorage.setItem('is_confirm', response?.data?.is_confirm)
                setIsConfirm(response?.data?.is_confirm)
            })
            .catch(error => {
                handleSnackbarOpen(error?.data?.message, 'error')
                setLoading(false)
            });
    }

    const handleConfirm = () => {
        const payLoadData = {
            'business_entity_id': localStorage.getItem("business_entity_id"),
            'summary_id': reconId
        }
        setConfirmButtonloading(true)
        axios.post('/generate_computation_table2', payLoadData)
            .then(response => {
                handleSnackbarOpen(response?.data?.message, 'success')
                setConfirmButtonloading(false)
                setIsConfirm(response?.data?.is_confirm)
            })
            .catch(error => {
                handleSnackbarOpen(error?.data?.message, 'error')
                setConfirmButtonloading(false)
            });
    }

    const downloadBase64File = (contentType, base64Data, fileName) => {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };


    const handleExportExcel = () => {
        setLoadingExcel(true)
        const payLoadData = {
            'summary_id': reconId,
            'business_entity_id': localStorage.getItem("business_entity_id"),
        }
        const contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = `GST Computation Report.xlsx`;

        axios.post('/computation_excel_report', payLoadData)
            .then((res) => {
                downloadBase64File(contentType, res?.data?.file, filename);
            }).catch((err) => {

            })
            .finally(()=>{
                setLoadingExcel(false)
            })
    }

    const numberFormat = (value) => {
       
        if (value === "(Blank)" || value === null) {
            return "₹ 0";
          }
      
        return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumSignificantDigits: 10,
        }).format(value);
      };
      


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1.5%' }}>

                <div style={{ marginLeft: '35%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', color: '#000080' }}>GST COMPUTATION STATEMENT<AnalyticsIcon style={{ marginBottom: '4px', color: '#ff6361' }} /></Typography>
                </div>


                {<Button variant="contained" style={{ marginLeft: '22.6%' }} onClick={() => history.push(`/gst_computation_paybality_report_data/${reconId}`)} color="secondary" endIcon={< BarChartIcon />}>
                    GST Payablity
                </Button>}
                

            </div>
            <BoxWrapper
                boxTitle="GST Computation Statement"
                exportToExcelEnable={true}
                exportToExcelFn={handleExportExcel}
                loadingexcel={loadingexcel}
                backButtonForZohoEnable={true}
                backButton={() => history.push('/gst_computation_report')}
            >
                <div className="responsive-table" style={{ minHeight: '54vh' }}>
                    <table className="custome-table">
                        <tbody>
                            <tr>
                                <th style={{ width: '210%' }}>Particulars</th>
                                <th>Tax Rate</th>
                                <th>Taxable Value</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>CESS</th>
                                <th>Total GST</th>
                                <th>Invoice Value</th>
                            </tr>

                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold' }}>
                                    (A) Outward Supplies
                                </td>
                            </tr>

                            {/* Render Export Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    Export
                                </td>
                            </tr>
                            {data?.Outward?.Export?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render Intra State Sales Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    Intra State
                                </td>
                            </tr>
                            {data && data?.Outward['Intra State']?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render Inter State Sales Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    Inter State
                                </td>
                            </tr>
                            {data && data?.Outward['Inter State']?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render B2C   Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    B2CS
                                </td>
                            </tr>
                            {data && data?.Outward?.B2CS?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render Missing in accounts   Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    Missing in account
                                </td>
                            </tr>
                            {data && data?.Outward['Missing in account']?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold' }}>
                                    (B) Inward Supplies
                                </td>
                            </tr>

                            {data && data?.Inward?.without_rcm?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render rcm   Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    RCM
                                </td>
                            </tr>
                            {data && data?.Inward?.rcm?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>{numberFormat(item.tax_rate)}</td>
                                    <td>{numberFormat(item.taxable_value)}</td>
                                    <td>{numberFormat(item.igst)}</td>
                                    <td>{numberFormat(item.cgst)}</td>
                                    <td>{numberFormat(item.sgst)}</td>
                                    <td>{numberFormat(item.cess)}</td>
                                    <td>{numberFormat(item.total_gst)}</td>
                                    <td>{numberFormat(item.invoice_value)}</td>
                                </tr>
                            ))}

                            {/* Render reversal   Section */}
                            <tr>
                                <td colSpan={9} style={{ fontWeight: 'bold', textAlign: 'start', paddingLeft: '1%' }}>
                                    Reversal
                                </td>
                            </tr>
                            {data && data?.Inward?.Reversal?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.particulars}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.tax_rate`}
                                            value={formValues[item.particulars]?.tax_rate || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.taxable_value`}
                                            value={formValues[item.particulars]?.taxable_value || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.igst`}
                                            value={formValues[item.particulars]?.igst || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.cgst`}
                                            value={formValues[item.particulars]?.cgst || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.sgst`}
                                            value={formValues[item.particulars]?.sgst || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.cess`}
                                            value={formValues[item.particulars]?.cess || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.total_gst`}
                                            value={formValues[item.particulars]?.total_gst || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className='blue-color'
                                            name={`${item.particulars}.invoice_value`}
                                            value={formValues[item.particulars]?.invoice_value || ''}
                                            onChange={handleChange}
                                            style={{ width: '80%', borderStyle: 'solid', borderRadius: '3px', fontWeight: 'bold' }}

                                        />
                                    </td>
                                </tr>
                            ))}



                        </tbody>
                    </table>
                </div>
            </BoxWrapper>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isConfirm === "Y" && <LoadingButton
                    color="warning"
                    onClick={handleConfirm}
                    loading={confirmButtonloading}
                    loadingPosition="start"
                    startIcon={<ErrorOutlineIcon />}
                    variant="contained"
                    style={{ width: '10%', height: '35px', marginTop: '10px' }}
                >
                    <span>Confirm</span>
                </LoadingButton>}

                <LoadingButton
                    color="primary"
                    onClick={handleSave}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    style={{ width: '10%', height: '35px', marginTop: '10px', marginLeft: '2%' }}
                >
                    <span>Save</span>
                </LoadingButton>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {responseMessage}
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default GstComputationDetailedReport