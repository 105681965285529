// import React, { useState } from "react";
// import BoxWrapper from "../../../../../components/ui/boxWrapper";
// import classes from "./Report.module.css";

// function AllInvoiceRecon(props) {
//   const { AllInvoiceReconData } = props;
//   const [allInvoiceReconData, setAllInvoiceReconData] =
//     useState(AllInvoiceReconData);

//   const globalSearch = (event) => {
//     let searchValue = event.target.value;
//     let searchData = AllInvoiceReconData.filter((value) => {
//       // const query = searchInput.toLowerCase();
//       const query = searchValue.trim().toLowerCase();
//       return (
//         String(value.GSTIN).toLowerCase().indexOf(query) >= 0 ||
//         String(value.Vendor_Name).toLowerCase().indexOf(query) >= 0 ||
//         String(value.DOC_NUMBER).toLowerCase().indexOf(query) >= 0 ||
//         String(indianFormat(value.DATE)).toLowerCase().indexOf(query) >= 0 ||
//         String(value.TYPE).toLowerCase().indexOf(query) >= 0
//       );
//     });
//     setAllInvoiceReconData(searchData);
//   };

//   // indian date format
//   const indianFormat = (date) => {
//     let dateArr = date.split("-");
//     return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
//   };

//   // indian rupeess system---
//   const numberFormat = (value) =>
//     new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumSignificantDigits: 10,
//     }).format(value);
//   // ---End---

//   const AllInvoiceReconTableBody = () => {
//     if (allInvoiceReconData.length >= 1) {
//       return allInvoiceReconData.map((tableData, index) => {
//         const {
//           GSTIN,
//           Vendor_Name,
//           DOC_NUMBER,
//           DATE,
//           TYPE,
//           Taxable_Value_Books,
//           CGST_Books,
//           SGST_Books,
//           IGST_Books,
//           Cess_Books,
//           Total_Amount_Books,

//           Taxable_Value_GSTR2A,
//           IGST_GSTR2A,
//           SGST_GSTR2A,
//           CGST_GSTR2A,
//           Cess_GSTR2A,
//           Total_Amount_Books_GSTR2A,

//           Taxable_Value_Diff,
//           TaxValue_Diff,
//           Total_Amount_Diff,
//         } = tableData;
//         return (
//           <tr>
//             <td>{GSTIN}</td>
//             <td>{Vendor_Name}</td>
//             <td>{DOC_NUMBER}</td>
//             <td>{DATE && indianFormat(DATE)}</td>

//             <td>{TYPE}</td>

//             <td>{numberFormat(Taxable_Value_Books)}</td>
//             <td>{numberFormat(CGST_Books)}</td>
//             <td>{numberFormat(SGST_Books)}</td>
//             <td>{numberFormat(IGST_Books)}</td>
//             <td>{numberFormat(Cess_Books)}</td>
//             <td>{numberFormat(Total_Amount_Books)}</td>
//             <td>{tableData.books_itc}</td>
//             <td>{tableData.books_rcm}</td>

//             <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
//             <td>{numberFormat(CGST_GSTR2A)}</td>
//             <td>{numberFormat(SGST_GSTR2A)}</td>
//             <td>{numberFormat(IGST_GSTR2A)}</td>
//             <td>{numberFormat(Cess_GSTR2A)}</td>
//             <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>
//             <td>{tableData.gst_itc}</td>
//             <td>{tableData.gst_rcm}</td>

//             <td>{numberFormat(Taxable_Value_Diff)}</td>
//             <td>{numberFormat(TaxValue_Diff)}</td>
//             <td>{numberFormat(Total_Amount_Diff)}</td>

//             <td>{tableData["Remarks 2"]}</td>
//             <td>{tableData.itc_status}</td>
//             <td>{tableData.rcm_status}</td>
//             <td>{tableData.Is_amended}</td>
//             <td>{tableData["Original_tax_period(if amended)"]}</td>
//           </tr>
//         );
//       });
//     } else {
//       return (
//         <tr>
//           <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
//             No Data Found!
//           </td>
//         </tr>
//       );
//     }
//   };

//   return (
//     <>
//       <BoxWrapper
//         boxTitle="Reconciliation Status : All Invoice(s) Reconciliation Report"
//         // search input
//         enableSearchInput={true}
//         searchInputPlaceholder="Search..."
//         searchInputOnchange={(e) => globalSearch(e)}
//       >
//         <div className={classes.ResponsiveTable}>
//           <table className={classes.AllInvoiceReconTableCss}>
//             <tr>
//               <th className={classes.ThInner} rowSpan={2}>
//                 GSTIN
//               </th>
//               <th className={classes.ThInner} rowSpan={2} align="right">
//                 VENDOR NAME
//               </th>
//               <th className={classes.ThInner} rowSpan={2} align="right">
//                 DOC NUMBER
//               </th>
//               <th className={classes.ThInner} rowSpan={2} align="right">
//                 DATE
//               </th>
//               <th className={classes.ThInner} rowSpan={2} align="right">
//                 DOC TYPE
//               </th>
//               <th
//                 className={classes.ThInner}
//                 style={{ background: "#ffffb3" }}
//                 colSpan={8}
//                 align="center"
//               >
//                 As per Book
//               </th>
//               <th
//                 className={classes.ThInner}
//                 style={{ background: "#5cd65c" }}
//                 colSpan={8}
//                 align="center"
//               >
//                 As per GSTR2B
//               </th>
//               <th
//                 className={classes.ThInner}
//                 style={{ background: "#ff8533" }}
//                 colSpan={3}
//                 align="center"
//               >
//                 Difference
//               </th>

//               <th
//                 className={classes.ThInner}
//                 style={{ background: "#ffc107" }}
//                 rowSpan={2}
//               >
//                 Remarks
//               </th>
//               <th className={classes.ThInner} rowSpan={2}>
//                 ITC Status
//               </th>
//               <th className={classes.ThInner} rowSpan={2}>
//                 RCM Status
//               </th>
//               <th className={classes.ThInner} rowSpan={2}>
//                 Is Amended
//               </th>
//               <th className={classes.ThInner} rowSpan={2}>
//                 Original Tax Period (if amended){" "}
//               </th>
//             </tr>
//             <tr>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 ITC
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 RCM
//               </th>

//               <th style={{ background: "#5cd65c" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 ITC
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 RCM
//               </th>

//               <th style={{ background: "#ff8533" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TAX
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//             </tr>
//             {AllInvoiceReconTableBody()}
//           </table>
//         </div>
//       </BoxWrapper>
//     </>
//   );
// }

// export default AllInvoiceRecon;

import React, { useEffect, useState } from 'react';
import classes from './Report.module.css';
import BoxWrapper from '../../../../../components/ui/boxWrapper';

function AllInvoiceRecon({ onTabClick, allinvoicerecondata }) {
  useEffect(() => {
    onTabClick();
  }, [onTabClick]);

  const [searchInput, setSearchInput] = useState('');
  const invoiceData = allinvoicerecondata?.record?.allInvoiceRecon ?? [];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = invoiceData.filter((invoice) => {
    // Perform a null check before accessing the 'includes' method
    const gstin = invoice.GSTIN || '';
    const vendorName = invoice.Vendor_Name || '';
    
          return (
     gstin.includes(searchInput) ||
    vendorName.includes(searchInput)
    );
     });

  console.log("All Invoice" + allinvoicerecondata);
  return (
    <>
      <BoxWrapper
        searchInputPlaceholder="Search..."
        boxTitle="Reconciliation Status:  All Invoice Recon(s)"
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <thead>
              <tr>
                <th rowSpan={2}>GSTIN</th>
                <th rowSpan={2} align="right">
                  VENDOR NAME
                </th>
                <th rowSpan={2} align="right">
                  DOC NUMBER
                </th>
                <th rowSpan={2} align="right">
                  DATE
                </th>
                <th rowSpan={2} align="right">
                  DOC TYPE
                </th>

                <th style={{ background: '#ffcc66' }} colSpan={8} align="center">
                  As per Books
                </th>
                <th style={{ background: '#c6ffb3' }} colSpan={8} align="center">
                  As per GSTR2B
                </th>
                <th style={{ background: 'ORANGE' }} colSpan={3} align="center">
                  Difference
                </th>
                <th rowSpan={2} align="right">
                  REMARKS
                </th>
                <th rowSpan={2} align="right">
                  ITC STATUS
                </th>
                <th rowSpan={2} align="right">
                  RCM STATUS
                </th>
                <th rowSpan={2} align="right">
                  IS AMENDED
                </th>
              </tr>
              <tr>
                <th style={{ background: '#ffcc66' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  TOTAL AMOUNT
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  ITC
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  RCM
                </th>

                <th style={{ background: '#c6ffb3' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  TOTAL AMOUNT
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                ITC
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                 RCM
                </th>

                <th style={{ background: 'ORANGE' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: 'ORANGE' }} align="right">
                  TAX
                </th>
                <th style={{ background: 'ORANGE' }} align="right">
                  TOTAL AMOUNT
                </th>
              </tr>
            </thead>

            <tbody>
              
              {filteredData.map((invoice, index) => (
                <tr key={index}>
                  <td style={{ color: 'black' }}>{invoice.GSTIN}</td>
                  <td style={{ color: 'black' }}>{invoice.Vendor_Name}</td>
                  <td style={{ color: 'black' }}>{invoice.DOC_NUMBER}</td>
                  <td style={{ color: 'black' }}>{invoice.DATE}</td>
                  <td style={{ color: 'black' }}>{invoice.TYPE}</td>

                  <td style={{ color: 'black' }}>{invoice.Taxable_Value_Books}</td>
                  <td style={{ color: 'black' }}>{invoice.CGST_Books}</td>
                  <td style={{ color: 'black' }}>{invoice.SGST_Books}</td>
                  <td style={{ color: 'black' }}>{invoice.IGST_Books}</td>
                  <td style={{ color: 'black' }}>{invoice.Cess_Books}</td>
                  <td style={{ color: 'black' }}>{invoice.Total_Amount_Books}</td>


                  <td style={{ color: 'black' }}>{invoice.books_itc}</td>
                  <td style={{ color: 'black' }}>{invoice.books_rcm}</td>
                  <td style={{ color: 'black' }}>{invoice.Taxable_Value_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.CGST_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.SGST_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.IGST_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.Cess_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.Total_Amount_Books_GSTR2B}</td>
                  <td style={{ color: 'black' }}>{invoice.gst_itc}</td>
                  <td style={{ color: 'black' }}>{invoice.gst_rcm}</td>
                  <td style={{ color: 'black' }}>{invoice.Taxable_Value_Diff}</td>
                  <td style={{ color: 'black' }}>{invoice.TaxValue_Diff}</td>
                  <td style={{ color: 'black' }}>{invoice.Total_Amount_Diff}</td>

                  <td style={{ color: 'black' }}>{invoice['Remarks 2']}</td>
                  <td style={{ color: 'black' }}>{invoice.itc_status}</td>
                  <td style={{ color: 'black' }}>{invoice.rcm_status}</td>

                 
                  <td style={{ color: 'black' }}>{invoice.Is_amended}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
     </>
  );
}

export default AllInvoiceRecon;
