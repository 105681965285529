import React, { Component } from "react";
import classes from "./BusinessEntityMgmt.module.css";
import axios from "../../../axios-order";
import Button from "@material-ui/core/Button";
import { TablePagination } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/inputField";
import SelectField from "../../../components/ui/select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "date-fns";
import ValidatedDatePicker from "../../../components/ui/DatePicker";
import Appbar from "../../../components/appBar/appBar";
import Grid from "@material-ui/core/Grid";
import Footer from "../../../components/footer/footer";
import BoxWapper from "../../../components/ui/boxWrapper";
import RightDrawer from "../../../components/ui/RightDrawer";
import "../../../HOC/layout/layout.css";

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class BusinessEntityMgmt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalBusiness_entity_data: null,
      business_entity_data: [],
      showDrawer: false,
      editDrawer: false,
      addDrawer: false,
      addEntityDrawer: false,
      showData: {},
      editData: {},
      addEntityData: {},
      is_cin: false,
      loading: true,
      page: 0,
      rowsPerPage: 5,
      rowCount: 0
    };
  }

  // table pagination fns
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };


  // ---entity table search---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.entityTableSearch(event);
    });
  };

  entityTableSearch = (event) => {
    let { searchInput } = this.state;
    let searchData = this?.state?.originalBusiness_entity_data?.filter(
      (value) => {
        const query = searchInput.toLowerCase();

        return (
          (value?.entity_name || "").toLowerCase().indexOf(query) >= 0 ||
          (value?.gst || "").toLowerCase().indexOf(query) >= 0 ||
          (value?.status || "").toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ business_entity_data: searchData });
  };
  // ---ONLOAD API CALL----
  componentDidMount = () => {
    this.getListEntity();
    toast.configure();
    axios
      .get("/get_constitution_data")
      .then((res) => {
        if (res.data.records) {
          this.setState({ constitution: res.data.records });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });

    toast.configure();
    axios
      .get("/get_sector_data")
      .then((res) => {
        if (res.data.records) {
          this.setState({ business_sector: res.data.records });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });

    toast.configure();
    axios
      .get("/get_industry_data")
      .then((res) => {
        if (res.data.records) {
          this.setState({ industry_data: res.data.records });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };
  // ---All Data[Entity]
  getListEntity = () => {
    const finalData = {
      
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page
      
    }
    toast.configure();
    axios
      .get(`/get_list_entities?number_of_rows_per_page=${this.state.rowsPerPage}&page_number=${this.state.page}`)
      .then((res) => {
        if (res.data.records) {
          this.setState({
            business_entity_data: res.data.records,
            originalBusiness_entity_data: res.data.records,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };
  //---End--
  // ---Add Entity ---
  addEntityEntityData = () => {
    this.setState({ addDrawer: true, addEntityData: {} });
  };

  handleAddEntityInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "constitution_id") {
      this.setState((prevState) => ({
        addEntityData: {
          ...prevState.addEntityData,
          [name]: String(value),
        },
        is_cin: value === 3 || value === 4 ? true : false,
      }));
    } else if (name == "gst" || name == "pan") {
      this.setState((prevState) => ({
        addEntityData: {
          ...prevState.addEntityData,
          [name]: String(value.toUpperCase()),
        },
      }));
    } else {
      this.setState((prevState) => ({
        addEntityData: {
          ...prevState.addEntityData,
          [name]: String(value),
        },
      }));
    }
  };

  handleDateChange(v) {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addEntityData: {
          ...prevState.addEntityData,
          date_incorporation: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
            -2
          )}`,
        },
      }));
    }
  }

  handleEditDateChange(v) {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          date_incorporation: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
            -2
          )}`,
        },
      }));
    }
  }

  addEntitySubmitHandler = () => {
    toast.configure();
    axios
      .post("/post_entity_data", this.state.addEntityData)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.setState({ addDrawer: false });
        this.getListEntity();
        this.setState({ addEntityData: {} });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ addDrawer: false });
      });
  };

  addEntityToggleClose = () => {
    this.setState({ addDrawer: false });
  };
  // ---End---

  // ---show drwaer---
  showEntityData = (
    entity_name,
    contact_email_id,
    gst,
    pan,
    status,
    contact_number,
    entity_address,
    description,
    industry_id,
    date_incorporation,
    constitution_id,
    contact_person,
    cin,
    business_sector_id,
    business_sub_segment,
    website,
    customer_type_id,
    export_orientation,
    msme_flag
  ) => {
    this.setState({ showDrawer: true });
    this.setState((prevState) => ({
      showData: {
        ...prevState.showData,
        entity_name: entity_name,
        contact_email_id: contact_email_id,
        gst: gst,
        pan: pan,
        status: status,
        contact_number: contact_number,
        entity_address: entity_address,
        description: description,
        industry_id: industry_id,
        date_incorporation: date_incorporation,
        constitution_id: constitution_id,
        contact_person: contact_person,
        cin: cin,
        business_sector_id: business_sector_id,
        business_sub_segment: business_sub_segment,
        website: website,
        export_orientation: export_orientation,
        msme_flag: msme_flag,
      },
    }));
  };

  toggleShowClose = () => {
    this.setState({ showDrawer: false });
  };
  // ---End---

  //---edit drawer---
  editEntityData = (
    business_entity_id,
    entity_name,
    contact_email_id,
    gst,
    pan,
    status,
    contact_number,
    entity_address,
    description,
    industry_id,
    date_incorporation,
    business_sub_segment,
    constitution_id,
    contact_person,
    cin,
    business_sector_id,
    website,
    export_orientation,
    msme_flag
  ) => {
    this.setState({ editDrawer: true });
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        entity_name: entity_name,
        business_entity_id : business_entity_id,
        contact_email_id: contact_email_id,
        gst: gst,
        pan: pan,
        status: status,
        contact_number: contact_number,
        business_sub_segment: business_sub_segment,
        entity_address: entity_address,
        description: description,
        industry_id: industry_id,
        industry_id_value: industry_id
          ? this.state.industry_data.find((o) => {
              return (
                String(o.industry_name).trim().toLowerCase() ===
                String(industry_id).trim().toLowerCase()
              );
            })["industry_id"]
          : null,
        date_incorporation: date_incorporation,
        constitution_id: constitution_id,
        constitution_id_value: constitution_id
          ? this.state.constitution.find((o) => {
              return (
                String(o.constitution_name).trim().toLowerCase() ===
                String(constitution_id).trim().toLowerCase()
              );
            })["constitution_id"]
          : null,
        contact_person: contact_person,
        cin: cin,
        business_sector_id: business_sector_id,
        business_sector_value: business_sector_id
          ? this.state.business_sector.find((o) => {
              return (
                String(o.sector_name).trim().toLowerCase() ===
                String(business_sector_id).trim().toLowerCase()
              );
            })["sector_id"]
          : null,
        website: website,
        export_orientation: export_orientation,
        msme_flag: msme_flag,
      },
    }));
  };

  handleSerialNumber = (index) => {
    const arrSerialNumber = []
    for (let i = ((this.state.page * this.state.rowsPerPage) + 1); i < (((this.state.page + 1) * this.state.rowsPerPage) + 1); i++) {
      arrSerialNumber.push(i)
    }
    return arrSerialNumber[index]
  }

  handleEditInputChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;

    if (name === "business_sector_id") {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: String(value),
          business_sector_value: this.state.business_sector.find((o) => {
            return o.sector_name === String(value);
          })["sector_id"],
        },
      }));
    } else if (name === "constitution_id") {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: String(value),
          constitution_id_value: this.state.constitution.find((o) => {
            return o.constitution_name === String(value);
          })["constitution_id"],
        },
        cin: value === 3 || value === 4 ? this.state.editData.cin : "",
      }));
    } else if (name === "industry_id") {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: String(value),
          industry_id_value: this.state.industry_data.find((o) => {
            return o.industry_name === String(value);
          })["industry_id"],
        },
      }));
    } else if (name === "gst" || name === "pan") {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: String(value.toUpperCase()),
        },
      }));
    } else {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          [name]: String(value),
        },
      }));
    }
  };

  editEntitySubmitHandler = () => {
    toast.configure();
    let data = this.state.editData;
    data["business_sector_id"] = data["business_sector_value"];
    data["constitution_id"] = data["constitution_id_value"];
    data["industry_id"] = data["industry_id_value"];

    axios
      .put(`/put_entity_data`, data)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.getListEntity();
        this.setState({ editDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ editDrawer: false });
      });
  };

  toggleEditClose = () => {
    this.setState({ editDrawer: false });
  };
  // ---End---

  //   ---Assign User---
  assignUser = (business_entity_id, entity_name) => {
    localStorage.setItem("business_mgmt_entity_id", business_entity_id);
    localStorage.setItem("entity_name_for_assign_user_page", entity_name);
    this.props.history.push({
      pathname: `/businessEntityManagement/assignUser`,
      // data: `${business_entity_id}`,
    });
  };
  // ---End---

  // ---Assign Services---
  assignServices = (business_entity_id) => {
    localStorage.setItem("business_mgmt_entity_id", business_entity_id);
    this.props.history.push({
      pathname: `/businessEntityManagement/assignServices`,
      // data: `${business_entity_id}`,
    });
  };
  // ---End---

  // ---user table---
  entityTableHeader() {
    const tableHeader = [
      "No",
      "Entity Name",
      "Email Id",
      "GSTIN",
      "PAN",
      "status",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index} style={{ width: key === "No" && '6%' }}>{key.toUpperCase()}</th>;
    });
  }
  entityTableData() {
    if (this?.state?.business_entity_data?.length > 0) {
      return this?.state?.business_entity_data?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((tableData, index) => {
        const {
          business_entity_id,
          entity_name,
          contact_email_id,
          gst,
          pan,
          status,
          contact_number,
          entity_address,
          description,
          industry_id,
          date_incorporation,
          business_sub_segment,
          constitution_id,
          contact_person,
          cin,
          business_sector_id,
          website,
          customer_type_id,
          export_orientation,
          msme_flag,
        } = tableData; //destructuring
        return (
          <tr key={index}>
             <td>{this.handleSerialNumber(index)}</td>
            <td style={{ textTransform: "capitalize" }}>{entity_name}</td>
            <td>{contact_email_id}</td>
            <td>{gst}</td>
            <td>{pan}</td>
            <td style={{ textTransform: "capitalize" }}>{status}</td>
            <td>
              <Button
                size="small"
                onClick={() =>
                  this.showEntityData(
                    entity_name,
                    contact_email_id,
                    gst,
                    pan,
                    status,
                    contact_number,
                    entity_address,
                    description,
                    industry_id,
                    date_incorporation,
                    constitution_id,
                    contact_person,
                    cin,
                    business_sector_id,
                    business_sub_segment,
                    website,
                    customer_type_id,
                    export_orientation,
                    msme_flag
                  )
                }
              >
                Show
              </Button>
              |
              <Button
                size="small"
                onClick={() =>
                  this.editEntityData(
                    business_entity_id,
                    entity_name,
                    contact_email_id,
                    gst,
                    pan,
                    status,
                    contact_number,
                    entity_address,
                    description,
                    industry_id,
                    date_incorporation,
                    business_sub_segment,
                    constitution_id,
                    contact_person,
                    cin,
                    business_sector_id,
                    website,
                    export_orientation,
                    msme_flag
                  )
                }
              >
                Edit
              </Button>
              |
              <Button
                size="small"
                onClick={() => this.assignUser(business_entity_id, entity_name)}
              >
                Assign User
              </Button>
              |
              <Button
                size="small"
                onClick={() => this.assignServices(business_entity_id)}
              >
                Assign Services
              </Button>
            </td>
          </tr>
        );
      });
    } else if (
      this.state?.business_entity_data?.length === 0 &&
      this.state.loading
    ) {
      return (
        <tr>
          <td colspan={8}>Loading.Please wait...</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ---End--
  backHanlder = () => {
    this.props.history.push(`/home`);
  };

  render() {
    return (
      <>
        <Appbar />

        {/* ---Entity details table--- */}
        <div className={classes.mainSectionBusinessEntityMgmt}>
          <BoxWapper
            boxTitle="Business Entities Details"
            // search input
            enableSearchInput={this.state.loading === false ? true : ""}
            searchInputPlaceholder="Search..."
            searchInputOnchange={this.handleSearchInputChange}
            // add button
            enableAddButton={this.state.loading === false ? true : ""}
            addButtonLabel={"Add Entity"}
            addButtonHandlerFn={this.addEntityEntityData}
            // back button
            backButtonForZohoEnable={true}
            backButton={this.backHanlder}
          >
            <div className={classes.responsiveTable}>
              <table className={classes.customeTable}>
                <tbody>
                  <tr>{this.entityTableHeader()}</tr>
                  {this.entityTableData()}
                </tbody>
              </table>
              
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={this.state.business_entity_data.length} //total row count
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </BoxWapper>

          {/* ---add entity details--- */}
          <RightDrawer
            drawerName="Add Entity"
            open={this.state.addDrawer}
            onClose={this.addEntityToggleClose}
          >
            {/* date_incorporation, msme */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addEntitySubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Entity Name"
                name="entity_name"
                value={this.state.addEntityData.entity_name ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Entity Address"
                name="entity_address"
                value={this.state.addEntityData.entity_address ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Person"
                name="contact_person"
                value={this.state.addEntityData.contact_person ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contact_number"
                value={this.state.addEntityData.contact_number ?? ""}
                requiredField="*"
                validator={["required", "matchRegexp:^[0-9]{10}$"]}
                errorMsg={["This field is required", "Invalid contact number"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Email Id"
                name="contact_email_id"
                value={this.state.addEntityData.contact_email_id ?? ""}
                requiredField="*"
                validator={["required", "isEmail"]}
                errorMsg={["This field is required", "Email is not valid"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gst"
                value={this.state.addEntityData.gst ?? ""}
                requiredField="*"
                validator={[
                  "required",
                  "matchRegexp:^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$",
                ]}
                errorMsg={["This field is required", "Invalid GST Number"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                value={this.state.addEntityData.pan ?? ""}
                requiredField="*"
                validator={[
                  "required",
                  "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}",
                ]}
                errorMsg={["This field is required", "Invalid PAN Number"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />

              <InputField
                inputType="text"
                textLabel="Website"
                name="website"
                value={this.state.addEntityData.website ?? ""}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Business Description"
                name="description"
                value={this.state.addEntityData.description ?? ""}
                requiredField=""
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Business Sub Segment"
                name="business_sub_segment"
                value={this.state.addEntityData.business_sub_segment ?? ""}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />

              <SelectField
                SelectLabel="Constitution"
                choice_id="constitution_id"
                choice_name="constitution_name"
                choices={this.state.constitution}
                name="constitution_id"
                value={this.state.addEntityData.constitution_id ?? ""}
                validator={["required"]}
                requiredField="*"
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />

              {this.state.is_cin && (
                <>
                  <InputField
                    inputType="text"
                    textLabel="CIN"
                    name="cin"
                    value={this.state.addEntityData.cin ?? ""}
                    requiredField="*"
                    validator={[
                      "required",
                      "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
                    ]}
                    errorMsg={["This field is required", "Invalid CIN"]}
                    textOnchangeFunction={this.handleAddEntityInputChange}
                  />
                </>
              )}

              <SelectField
                SelectLabel="Business Sector"
                choice_id="sector_id"
                choice_name="sector_name"
                choices={this.state.business_sector}
                name="business_sector_id"
                value={this.state.addEntityData.business_sector_id ?? ""}
                validator={["required"]}
                requiredField="*"
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <SelectField
                SelectLabel="Industry"
                choice_id="industry_id"
                choice_name="industry_name"
                choices={this.state.industry_data}
                name="industry_id"
                value={this.state.addEntityData.industry_id ?? ""}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <div style={{ marginTop: "12px" }}>
                <label>
                  Date of incorporation
                  <span style={{ color: "red" }}></span>:
                </label>
                <ValidatedDatePicker
                  name="date_incorporation"
                  value={this.state.addEntityData.date_incorporation ?? ""}
                  onChange={(v) => this.handleDateChange(v)}
                  // validators={["required"]}
                  // errorMessages={["Date is required"]}
                />
              </div>
              <SelectField
                SelectLabel="Export Orientation"
                choice_id="export_orientation"
                choice_name="export_orientation"
                name="export_orientation"
                value={this.state.addEntityData.export_orientation ?? ""}
                choices={[
                  { id: 1, export_orientation: "Yes" },
                  { id: 0, export_orientation: "No" },
                ]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />
              <SelectField
                SelectLabel="MSME"
                choice_id="name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="name"
                name="msme_flag"
                value={this.state.addEntityData.msme_flag ?? ""}
                choices={[
                  { id: 1, name: "Yes" },
                  { id: 0, name: "No" },
                ]}
                textOnchangeFunction={this.handleAddEntityInputChange}
              />

              {/* <SelectField
                  SelectLabel="Customer Type"
                  choice_id="customer_type"
                  requiredField="*"
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  choice_name="customer_type"
                  name="customer_type_id"
                  value={this.state.addEntityData.customer_type_id ?? ""}
                  choices={[{ id: 1, customer_type: "Seller" }]}
                  textOnchangeFunction={this.handleAddEntityInputChange}
                /> */}

              {/* <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                <SelectInput
                  choice_id="id"
                  requiredField="*"
                  validator={["required"]}
                  errorMsg={["This field is required"]}
                  choice_name="entity_reference"
                  SelectLabel="ENTITY TYPE"
                  name="entity_type"
                  value={this.state.addEntityData.entity_type ?? ""}
                  textOnchangeFunction={this.handleInputChange}
                  choices={[
                    {
                      id: "Head Office",
                      entity_reference: "Head Office",
                    },
                    {
                      id: "Branch",
                      entity_reference: "Branch",
                    },
                  ]}
                />
              </Grid>

              {this.state.showBranchFields && (
                <>
                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px" }}>
                    <label>
                      Branch Name<span style={{ color: "red" }}>*</span>:
                    </label>

                    <TextValidator
                      fullWidth={true}
                      size="small"
                      variant="outlined"
                      name="branch_name"
                      value={this.state.addEntityData.branch_name ?? ""}
                      onChange={this.handleAddEntityInputChange}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} style={{ paddingTop: "0px", marginTop: '8px' }}>
                    <SelectInput
                      choice_id="business_entity_id"
                      requiredField="*"
                      validator={["required"]}
                      errorMsg={["This field is required"]}
                      choice_name="entity_name"
                      SelectLabel="Main Branch"
                      name="main_branch"
                      value={this.state.addEntityData.main_branch ?? ""}
                      textOnchangeFunction={this.handleAddEntityInputChange}
                      choices={this.state.entities}
                    />
                  </Grid>
                </>
              )} */}

              <SelectField
                choice_id="status"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="status"
                SelectLabel="Status"
                name="status"
                value={this.state.addEntityData.status ?? ""}
                textOnchangeFunction={this.handleAddEntityInputChange}
                choices={[
                  {
                    id: 1,
                    status: "Inactive",
                  },
                  {
                    id: 0,
                    status: "Active",
                  },
                ]}
              />

              <div className="mt-20">
                <button className="button-primary" type="submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={this.addEntityToggleClose}
                >
                  cancel
                </button>
              </div>
            </ValidatorForm>
          </RightDrawer>

          {/* ---Show table details--- */}
          <RightDrawer
            drawerName="Entity Details"
            open={this.state.showDrawer}
            onClose={this.toggleShowClose}
          >
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Entity Name"
              value={this.state.showData.entity_name ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Entity Address"
              value={this.state.showData.entity_address ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Person"
              value={this.state.showData.contact_person ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Number"
              value={this.state.showData.contact_number ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Email Id"
              value={this.state.showData.contact_email_id ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="GST"
              value={this.state.showData.gst ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="PAN"
              value={this.state.showData.pan ?? ""}
            />

            {this.state.showData.website && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Website"
                value={this.state.showData.website ?? ""}
              />
            )}

            {this.state.showData.description && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Business Description"
                value={this.state.showData.description ?? ""}
              />
            )}

            {this.state.showData.business_sub_segment && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Business Sub segemnt"
                value={this.state.showData.business_sub_segment ?? ""}
              />
            )}

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Business Sector"
              value={this.state.showData.business_sector_id ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Constitution"
              value={this.state.showData.constitution_id ?? ""}
            />

            {this.state.showData.cin && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Cin"
                value={this.state.showData.cin ?? ""}
              />
            )}

            {this.state.showData.industry_id && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Industry"
                value={this.state.showData.industry_id ?? ""}
              />
            )}

            {this.state.showData.date_incorporation && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Date of Incorparation"
                value={dateFormat(this.state.showData.date_incorporation)}
              />
            )}

            {this.state.showData.export_orientation && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Export Orientation"
                value={this.state.showData.export_orientation ?? ""}
              />
            )}

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Msme"
              value={this.state.showData.msme_flag ?? ""}
            />
            {this.state.showData.status && (
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Status"
                defaultValue={this.state.showData.status ?? ""}
              />
            )}

            <button
              type="button"
              className="button-outlined-secondary mt-20"
              onClick={this.toggleShowClose}
            >
              Close
            </button>
          </RightDrawer>

          {/* ---edit table details--- */}
          <RightDrawer
            drawerName="Edit Entity"
            open={this.state.editDrawer}
            onClose={this.toggleEditClose}
          >
            <ValidatorForm
              ref="form"
              onSubmit={this.editEntitySubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Entity Name"
                name="entity_name"
                value={this.state.editData.entity_name ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Entity Address"
                name="entity_address"
                value={this.state.editData.entity_address ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Person"
                name="contact_person"
                value={this.state.editData.contact_person ?? ""}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contact_number"
                value={this.state.editData.contact_number ?? ""}
                requiredField="*"
                validator={["required", "matchRegexp:^[0-9]{10}$"]}
                errorMsg={["This field is required", "Invalid contact number"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Contact Email Id"
                name="contact_email_id"
                value={this.state.editData.contact_email_id ?? ""}
                requiredField="*"
                validator={["required", "isEmail"]}
                errorMsg={["This field is required", "Email is not valid"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gst"
                value={this.state.editData.gst ?? ""}
                requiredField="*"
                validator={[
                  "required",
                  "matchRegexp:^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[A-Za-z][0-9A-Za-z]{1}$",
                ]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required", "Invalid GST Number"]}
                textOnchangeFunction={this.handleEditInputChange}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                value={this.state.editData.pan ?? ""}
                requiredField="*"
                validator={[
                  "required",
                  "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}",
                ]}
                errorMsg={["This field is required", "Invalid PAN Number"]}
                textOnchangeFunction={this.handleEditInputChange}
              />

              <InputField
                inputType="text"
                textLabel="Website"
                name="website"
                value={this.state.editData.website ?? ""}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Business Description"
                name="description"
                value={this.state.editData.description ?? ""}
                requiredField=""
                textOnchangeFunction={this.handleEditInputChange}
              />
              <InputField
                inputType="text"
                textLabel="Business Sub Segment"
                name="business_sub_segment"
                value={this.state.editData.business_sub_segment ?? ""}
                textOnchangeFunction={this.handleEditInputChange}
              />

              <SelectField
                SelectLabel="Constitution"
                choice_id="constitution_name"
                choice_name="constitution_name"
                choices={this.state.constitution}
                name="constitution_id"
                value={this.state.editData.constitution_id ?? ""}
                validator={["required"]}
                requiredField="*"
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />

              {(this.state.editData.constitution_id_value === 3 ||
                this.state.editData.constitution_id_value === 4) && (
                <InputField
                  inputType="text"
                  textLabel="CIN"
                  name="cin"
                  value={this.state.editData.cin ?? ""}
                  requiredField="*"
                  validator={[
                    "required",
                    "matchRegexp:^([L|U]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})$",
                  ]}
                  errorMsg={["This field is required", "Invalid CIN"]}
                  textOnchangeFunction={this.handleEditInputChange}
                />
              )}

              <SelectField
                SelectLabel="Business Sector"
                choice_id="sector_name"
                choice_name="sector_name"
                choices={this.state.business_sector}
                name="business_sector_id"
                value={this.state.editData.business_sector_id ?? ""}
                validator={["required"]}
                requiredField="*"
                errorMsg={["This field is required"]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <SelectField
                SelectLabel="Industry"
                choice_id="industry_name"
                choice_name="industry_name"
                choices={this.state.industry_data}
                name="industry_id"
                value={this.state.editData.industry_id ?? ""}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <div style={{ marginTop: "15px" }}>
                <label>Date of incorporation :</label>
                <ValidatedDatePicker
                  name="date_incorporation"
                  value={this.state.editData.date_incorporation ?? ""}
                  onChange={(v) => this.handleEditDateChange(v)}
                />
              </div>
              <SelectField
                SelectLabel="Export Orientation"
                choice_id="export_orientation"
                choice_name="export_orientation"
                name="export_orientation"
                value={this.state.editData.export_orientation ?? ""}
                choices={[
                  { id: 1, export_orientation: "YES" },
                  { id: 0, export_orientation: "NO" },
                ]}
                textOnchangeFunction={this.handleEditInputChange}
              />
              <SelectField
                SelectLabel="MSME"
                choice_id="name"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="name"
                name="msme_flag"
                value={this.state.editData.msme_flag ?? ""}
                choices={[
                  { id: 1, name: "YES" },
                  { id: 0, name: "NO" },
                ]}
                textOnchangeFunction={this.handleEditInputChange}
              />

              <SelectField
                choice_id="status"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                choice_name="status"
                SelectLabel="Status"
                name="status"
                value={this.state.editData.status ?? ""}
                textOnchangeFunction={this.handleEditInputChange}
                choices={[
                  {
                    id: 1,
                    status: "Inactive",
                  },
                  {
                    id: 0,
                    status: "Active",
                  },
                ]}
              />

              {/* buttons */}
              <div className="mt-20">
                <button className="button-primary" type="submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={this.toggleEditClose}
                >
                  cancel
                </button>
              </div>
            </ValidatorForm>
          </RightDrawer>
          {/* ---End--- */}
        </div>
        {/* ---Footer--- */}
        <div>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(BusinessEntityMgmt);
