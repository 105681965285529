// @flow
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../components/ui/inputField";
import ValidatedDatePicker from "../../../components/ui/DatePicker";
import NumberFormat from "react-number-format";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../axios-order";
import style from "../BusinessEntityDetails.module.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function Add(props) {
  const { cancelAddForm, updateGetApi } = props;
  // const form = useRef("form")
  const [addTdsData, setAddTdsData] = useState({});
  const [fromDateToDisplay, setFromDateToDisplay] = useState(null);

  const addInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddTdsData({
      ...addTdsData,
      [name]: value,
    });
  };

  const handleFromDateChange = (v) => {
    setFromDateToDisplay(v);
    setAddTdsData({
      ...addTdsData,
      valid_to_date: null,
    });
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setAddTdsData({
        ...addTdsData,
        valid_from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      });
    }
  };

  const handleToDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setAddTdsData({
        ...addTdsData,
        valid_to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };

  const finalAddData = {
    org_gstin: `${localStorage.getItem("gst")}`,
    records: [addTdsData],
  };

  const addFormsubmitHandler = (e) => {
    toast.configure();
    if (addTdsData.valid_to_date < addTdsData.valid_from_date) {
      alert("To Date is lesser than From date");
      return false;
    }
    if(!addTdsData.TDSType){
        alert("Please Select the Sales or  Purchase ")
        return false
      }

    axios
      .post("/tds/ltds", finalAddData)
      .then((res) => {
        if (res.data.status === true) {
          cancelAddForm();
          updateGetApi();
          setAddTdsData({});
          toast.success(res.data.message, { autoClose: 2000 });
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelAddFormDrawer = () => {
    setAddTdsData({});
    cancelAddForm();
  };

  return (
    <ValidatorForm
      useRef="form"
      onSubmit={addFormsubmitHandler}
      onError={(errors) => console.log(errors)}
    >
      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group">TDS Type</FormLabel> */}
        <label style={{ marginBottom: "0px" }}>
          TDS Type<span style={{ color: "red" }}>*</span>
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="TDSType"
          value={addTdsData.TDSType ?? ""}
          onChange={addInputChangeHandler}
        >
          <FormControlLabel value="Sales" control={<Radio />} label="Sales" />
          <FormControlLabel
            value="Purchase"
            control={<Radio />}
            label="Purchase"
          />
        </RadioGroup>
      </FormControl>

      <InputField
        textLabel="Legal Name"
        inputType="text"
        name="vendor_legal_name"
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        value={addTdsData.vendor_legal_name ?? ""}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="PAN"
        inputType="text"
        name="pan"
        value={addTdsData.pan ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <div style={{ marginTop: "20px" }}>
        <label>
          From Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="valid_from_date"
          value={addTdsData.valid_from_date ?? null}
          onChange={(v) => handleFromDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          To Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          minDateForDatepicker={addTdsData.valid_from_date ?? null}
          name="to_date"
          value={addTdsData.valid_to_date ?? null}
          onChange={(v) => handleToDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
          disabled={fromDateToDisplay ? false : true}
        />
      </div>

      <InputField
        textLabel="Certificate no for ltds"
        inputType="text"
        name="certificate_no_for_ltds"
        value={addTdsData.certificate_no_for_ltds ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="Section as per certificate"
        inputType="text"
        name="tds_section_as_per_certificate"
        value={addTdsData.tds_section_as_per_certificate ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="Tds as per certificate"
        inputType="text"
        name="tds_as_per_certificate"
        value={addTdsData.tds_as_per_certificate ?? ""}
        validator={[
          "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
          "maxFloat:100.00",
        ]}
        errorMsg={["invalid percentage.", "invalid percentage."]}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <div style={{ marginTop: "20px" }}>
        <label>
          ₹ Limit amount<span style={{ color: "red" }}>*</span>:
        </label>{" "}
        <br />
        <NumberFormat
          className={style.numberFormate}
          style={{ paddingLeft: "10.5px" }}
          value={addTdsData.limit_amount ? addTdsData.limit_amount : ""}
          thousandsGroupStyle="lakh"
          prefix="₹"
          required
          decimalSeparator="."
          displayType="number"
          type="text"
          decimalScale={2}
          thousandSeparator={true}
          allowNegative={true}
          onValueChange={(values) => {
            const { value } = values;
            setAddTdsData({
              ...addTdsData,
              limit_amount: parseFloat(value),
            });
          }}
        />{" "}
      </div>

      {/* .......Button........ */}
      <div className="mt-20">
        <button className="button-primary" type="submit">
          Submit
        </button>
        <button
          className="button-outlined-secondary"
          type="button"
          onClick={cancelAddFormDrawer}
        >
          cancel
        </button>
      </div>
    </ValidatorForm>
  );
}
