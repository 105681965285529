// @flow
import React, { useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../../../components/ui/inputField";
import ValidatedDatePicker from "../../../../../components/ui/DatePicker";
import axios from "../../../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberFormat from "react-number-format";
import SelectField from "../../../../../components/ui/select";

export default function Edit(props) {
  const { cancelEditForm, editFormData, updateGetApi } = props;
  // const form = useRef("form")
  const [editTdsData, setEditTdsData] = useState({});

  useMemo(() => {
    setEditTdsData(editFormData);
  }, [editFormData]);

  const editInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEditTdsData({
      ...editTdsData,
      [name]: value,
    });
  };

  const handleFromDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setEditTdsData({
      ...editTdsData,
      from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const handleToDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setEditTdsData({
      ...editTdsData,
      to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });
  };

  const finalEditData = {
    user: localStorage.getItem("user_id"),
    records: [editTdsData],
  };

  const editFormsubmitHandler = (e) => {
    toast.configure();
    axios
      .put("/tds/threshold/edit", finalEditData)
      .then((res) => {
        if (res.data.status === true) {
          cancelEditForm();
          updateGetApi();
          toast.success(res.data.message, { autoClose: 2000 });
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {});
  };
  // ....section dropdown data......
  const sectionDropdownValue = [
    { section: "192A" },
    { section: "193(Securites)" },
    { section: "193(Debentures)" },
    { section: "194" },
    { section: "194A" },
    { section: "194B" },
    { section: "194BB" },
    { section: "194C" },
    { section: "194D" },
    { section: "194DA" },
    { section: "194E" },
    { section: "194EE" },
    { section: "194G" },
    { section: "194H" },
    { section: "194IA" },
    { section: "194I(A)" },
    { section: "194IB" },
    { section: "194I(B)" },
    { section: "194IC" },
    { section: "194J(2%)" },
    { section: "194J(10%)" },
    { section: "194LA" },
    { section: "194LB" },
    { section: "194LD" },
    { section: "194Q" },
    { section: "194R" },
  ];
  // ..........194C dropdown..........
  const section_194c = [
    { criteria: "HUF and Individual/Single Payment" },
    { criteria: "HUF and Individual/Aggregate Payment" },
    { criteria: "Other than HUF and Individual/Single Payment" },
    { criteria: "Other than HUF and Individual/Aggregate Payment" },
  ];
  // ...........194Q dropdown value.......
  const section_194q = [{ criteria: "With PAN" }, { criteria: " Without PAN" }];
  // ...........194D dropdown value.......
  const section_194d = [
    { criteria: "Domestic Companies" },
    { criteria: " Others than Domestic companies" },
  ];

  return (
    <ValidatorForm
      useRef="form"
      onSubmit={editFormsubmitHandler}
      onError={(errors) => console.log(errors)}
    >
      <SelectField
        choice_id="section"
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        choice_name="section"
        SelectLabel="Section"
        name="section"
        value={editTdsData.section ?? ""}
        textOnchangeFunction={editInputChangeHandler}
        choices={sectionDropdownValue}
        inputProps={{ readOnly: true }}
      />

      <InputField
        textLabel="Standard TDS rate"
        inputType="text"
        name="standard_tds_rate"
        value={editTdsData.standard_tds_rate}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={editInputChangeHandler}
      />
      <InputField
        textLabel="In forced TDS rate"
        inputType="text"
        name="inforced_tds_rate"
        value={editTdsData.inforced_tds_rate}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={editInputChangeHandler}
      />

      <div style={{ marginTop: "20px" }}>
        <label>₹ Threshold limit for deduction:</label> <br />
        <NumberFormat
          className="numberFormate"
          style={{ paddingLeft: "10.5px" }}
          value={
            editTdsData.threshold_limit_for_deduction
              ? editTdsData.threshold_limit_for_deduction
              : ""
          }
          thousandsGroupStyle="lakh"
          prefix="₹"
          required
          decimalSeparator="."
          displayType="number"
          type="text"
          decimalScale={2}
          thousandSeparator={true}
          allowNegative={true}
          onValueChange={(values) => {
            const { value } = values;
            setEditTdsData({
              ...editTdsData,
              threshold_limit_for_deduction: parseFloat(value),
            });
          }}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          From Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="from_date"
          value={editTdsData.from_date ?? ""}
          onChange={(v) => handleFromDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
          disabled={true}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          To Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="to_date"
          value={editTdsData.to_date ?? ""}
          onChange={(v) => handleToDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
          disabled={true}
        />
      </div>

      {editTdsData.section !== "194C" &&
        editTdsData.section !== "194Q" &&
        editTdsData.section !== "194D" && (
          <InputField
            textLabel="criteria"
            inputType="text"
            name="criteria"
            value={editTdsData.criteria}
            // requiredField="*"
            // validator={["required"]}
            // errorMsg={["This field is required"]}
            textOnchangeFunction={editInputChangeHandler}
          />
        )}

      {editTdsData.section === "194C" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={editTdsData.criteria ?? ""}
          textOnchangeFunction={editInputChangeHandler}
          choices={section_194c}
        />
      )}

      {editTdsData.section === "194Q" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={editTdsData.criteria ?? ""}
          textOnchangeFunction={editInputChangeHandler}
          choices={section_194q}
        />
      )}

      {editTdsData.section === "194D" && (
        <SelectField
          choice_id="criteria"
          requiredField="*"
          validator={["required"]}
          errorMsg={["This field is required"]}
          choice_name="criteria"
          SelectLabel="Criteria"
          name="criteria"
          value={editTdsData.criteria ?? ""}
          textOnchangeFunction={editInputChangeHandler}
          choices={section_194d}
        />
      )}

      <InputField
        textLabel="Particulars"
        inputType="text"
        name="particulars"
        value={editTdsData.particulars}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={editInputChangeHandler}
      />

      {/* .......Button........ */}

      {/* .......Button........ */}
      <div className="mt-20">
        <button className="button-primary" type="submit">
          Submit
        </button>
        <button
          type="button"
          className="button-outlined-secondary"
          onClick={cancelEditForm}
        >
          cancel
        </button>
      </div>
    </ValidatorForm>
  );
}
