import React, { Component } from "react";
import axios from "../../../../axios-order";
import Grid from "@material-ui/core/Grid";
import Table from "../../../../components/ui/Table/Table";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";

class OrigaFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceCol: [
        { key: "invoice_number" },
        { key: "buyer_name" },
        { key: "agency_name" },
        { key: "event_type" },
        { key: "invoice_amount" },
        { key: "amount" },
      ],
      oldInvoiceData: [],
      invoiceData: [],
      total_records: 0,
      invoiceHeader: [
        { label: "Invoice NUmber" },
        { label: "Buyer Name" },
        { label: "Agency Name" },
        { label: "Event Type" },
        { label: "Invoice Amount" },
        { label: "Receipt Amount" },
      ],

      searchInput: "",
    };
  }

  componentDidMount() {
    axios
      .get(
        "/origa/get_invoice_feedback/" +
          localStorage.getItem("business_entity_id")
      )
      .then((res) => {
        this.setState({
          oldInvoiceData: res.data.data,
          invoiceData: res.data.data,
          total_records: res.data.data.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSearchInputChange = (event) => {
    const search_value = String(event.target.value).toLowerCase();
    this.setState({
      searchInput: search_value,
    });
    if (search_value !== "") {
      if (this.state.oldInvoiceData.length) {
        let invoiceDataNew = this.state.oldInvoiceData.filter((value) => {
          return (
            String(value.invoice_number).toLowerCase().indexOf(search_value) >=
              0 ||
            String(value.buyer_name).toLowerCase().indexOf(search_value) >= 0
          );
        });
        this.setState({
          invoiceData: invoiceDataNew,
        });
      }
    } else {
      this.setState({
        invoiceData: this.state.oldInvoiceData,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatorForm
                ref="form"
                // onSubmit={this.onSubmitHandler}
                // onError={(errors) => console.log(errors)}
              >
                <InputField
                  textLabel="Search By Invoice"
                  inputType="text"
                  name="Search_by_customer_name"
                  value={this.state.searchInput ?? ""}
                  textOnchangeFunction={this.handleSearchInputChange}
                />
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>
        {/* datatable component */}
        <div style={{ height: "300", margin: "20px 30px 0px 30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Table
                headers={this.state.invoiceHeader}
                columns={this.state.invoiceCol}
                data={this.state.invoiceData}
                name="invoices"
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default OrigaFeedback;
