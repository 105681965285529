import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axios from "../../../axios-order";
import { Grid } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import TableForDamageStock from "./TableForDamageStock";
import BoxWrapper from "../../../components/ui/boxWrapper";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(0,123,255)",
    },
  },
}));

export default function DamageStockSummary() {
  //....................useState for graph.............
  const [dataForGraphBarChart, setDataForGraphBarChart] = useState([]);
  const [dataForGraphPieChart, setDataForGraphPieChart] = useState([]);

  //....................useState for Drop Down...................
  const [getApiDetailsForRegionName, setGetApiDetailsForRegionName] = useState(
    []
  );
  const [getApiDetailsForBranch, setGetApiDetailsForBranch] = useState([]);
  const [getApiDetailsForReportingPeriod, setGetApiDetailsForReportingPeriod] =
    useState([]);
  const [postApiForRegionId, setPostApiForRegionId] = useState("");
  const [postApiForRegionName, setPostApiForRegionName] = useState("");
  const [postApiForReportingPeriodId, setPostApiForReportingPeriodId] =
    useState("");
  const [postApiForReportingPeriodName, setPostApiForReportingPeriodName] =
    useState("");
  const [postApiForBranchId, setPostApiForBranchId] = useState("");
  const [postApiForBranchName, setPostApiForBranchName] = useState("");

  //................useState For Error Message.....................
  const [toDisplay, setToDisplay] = useState("");
  const [divColor, setDivColor] = useState("red");
  const [divVisibility, setDivVisibility] = useState("visible");
  const [displayproperty, setDisplayproperty] = useState("none");

  //...............useState To Trigar the table...............
  const [trigar, setTrigar] = useState(false);
  const [getApiForTableDamageStock, SetGetApiForTableDamageStock] = useState(
    []
  );

  /*...............Api For Region Master.................*/
  function getApiForRegionName() {
    fuctionToDisplayErrorMessage("Loading...", "green", "block");
    axios
      .post(`/getRegionMaster`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          fuctionToDisplayErrorMessage("", "", "none");
          setGetApiDetailsForRegionName(res.data.data);
        } else {
          fuctionToDisplayErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        fuctionToDisplayErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  }

  const getApiForBranch = () => {
    fuctionToDisplayErrorMessage("Loading...", "green", "block");
    axios
      .post(`getBranchMaster`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        region_id: postApiForRegionId,
      })
      .then((res) => {
        if (res.data.status === true) {
          fuctionToDisplayErrorMessage("", "green", "none");
          setGetApiDetailsForBranch(res.data.data);
        } else {
          fuctionToDisplayErrorMessage(res.data.message, "red", "none");
        }
      })
      .catch((error) =>
        fuctionToDisplayErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        )
      );
  };

  //.....................Api For Reporting Period............
  const getApiForReportingPeriod = () => {
    fuctionToDisplayErrorMessage("Loading...", "green", "block");
    axios
      .post(`/getReportingPeriod`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          fuctionToDisplayErrorMessage("", "green", "none");
          setGetApiDetailsForReportingPeriod(res.data.data);
        } else {
          fuctionToDisplayErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        fuctionToDisplayErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  };

  //..................Bar Charts..................
  const getApiForGraphBarChart = () => {
    axios
      .post(`/iars_damage_stocks_bar_graph_summary`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        ReportingPeriodId: postApiForReportingPeriodId,
        BranchId: postApiForBranchId,
      })
      .then((res) => {
        if (res.data.status === true) setDataForGraphBarChart(res.data.records);
      })
      .catch((error) => console.log(error));
  };

  //.........................Pie Charts........................
  const getApiForGraphPieChart = () => {
    axios
      .post(`/iars_damage_stocks_pie_graph_summary`, {
        BranchId: postApiForBranchId,
        ReportingPeriodId: postApiForReportingPeriodId,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          setDataForGraphPieChart(res.data.records);
        }
      })
      .catch((error) => console.log(error));
  };

  //........................handleOpenAndCloseModel......................
  const handleOpenAndCloseModel = () => {
    setPostApiForRegionId("");
    setPostApiForReportingPeriodId("");
    setPostApiForBranchId("");
    setPostApiForBranchName("");
    setTrigar(false);
    setToDisplay("");
    setDivColor("red");
    setDisplayproperty("none");
  };

  const fuctionToDisplayErrorMessage = (
    print,
    color,
    displaypropertyToPrint
  ) => {
    setToDisplay(print);
    setDivColor(color);
    setDivVisibility("visible");
    setDisplayproperty(displaypropertyToPrint);
  };

  //...............Submit Handler.....................
  const handleSubmit = () => {
    if (
      postApiForReportingPeriodId === "" ||
      postApiForBranchId === "" ||
      postApiForRegionId === ""
    ) {
      fuctionToDisplayErrorMessage("* Mandatory Fields", "red", "block");
    } else {
      fuctionToDisplayErrorMessage("Loading...", "green", "block");
      getApiForGraphPieChart();
      getApiForGraphBarChart();
      axios
        .post(`/iars_damage_stock_summary`, {
          ReportingPeriodId: postApiForReportingPeriodId,
          BranchId: postApiForBranchId,
          business_entity_id: localStorage.getItem("business_entity_id"),
        })
        .then((res) => {
          if (res.data.status === false) {
            fuctionToDisplayErrorMessage("No Data Present!", "red", "block");
            setTrigar(false);
          }
          if (res.data.status === true) {
            fuctionToDisplayErrorMessage(
              "Data Fetch Successfully",
              "green",
              "block"
            );
            SetGetApiForTableDamageStock(res.data.rptdamagestocksJson);
            setTrigar(true);
          }
        })
        .catch((error) => {
          fuctionToDisplayErrorMessage(
            "Server Error Try After Some Time or Check Your Internet",
            "red",
            "block"
          );
          setTrigar(false);
        });
    }
  };

  useEffect(() => {
    getApiForRegionName();
    getApiForReportingPeriod();
  }, []);

  useEffect(() => {
    if (postApiForRegionId !== "") {
      getApiForBranch();
      getApiDetailsForRegionName?.map((item) => {
        if (postApiForRegionId === item.RegionId) {
          setPostApiForRegionName(item.RegionName);
        }
      });
    }
  }, [postApiForRegionId]);

  useEffect(() => {
    if (postApiForReportingPeriodId !== "") {
      getApiDetailsForReportingPeriod?.map((item) => {
        if (postApiForReportingPeriodId === item.ReportingPeriodId) {
          setPostApiForReportingPeriodName(item.ReportingPeriod);
        }
      });
    }
  }, [postApiForReportingPeriodId]);

  useEffect(() => {
    if (postApiForBranchId !== "") {
      getApiDetailsForBranch?.map((item) => {
        if (postApiForBranchId === item.BranchId) {
          setPostApiForBranchName(item.BranchName);
        }
      });
    }
  }, [postApiForBranchId]);

  return (
    <React.Fragment>
      <BoxWrapper boxTitle="Filter">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <label>
              Region Name <span className="mandatory-field-IARS">*</span>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForRegionId}
                disabled={getApiDetailsForRegionName === "" ? true : false}
                onChange={(e) => {
                  setPostApiForRegionId(e.target.value);
                  setPostApiForBranchId("");
                }}
                input={<BootstrapInput />}
              >
                {getApiDetailsForRegionName.map((v) => {
                  return <MenuItem value={v.RegionId}>{v.RegionName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <label>
              Branch Name <span className="mandatory-field-IARS">*</span>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForBranchId}
                disabled={getApiDetailsForBranch === "" ? true : false}
                onChange={(e) => setPostApiForBranchId(e.target.value)}
                input={<BootstrapInput />}
              >
                {getApiDetailsForBranch.map((v) => {
                  return <MenuItem value={v.BranchId}>{v.BranchName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <label>
              Reporting Period <span className="mandatory-field-IARS">*</span>:
            </label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForReportingPeriodId}
                disabled={getApiDetailsForReportingPeriod === "" ? true : false}
                onChange={(e) => setPostApiForReportingPeriodId(e.target.value)}
                input={<BootstrapInput />}
              >
                {getApiDetailsForReportingPeriod.map((v) => {
                  return (
                    <MenuItem value={v.ReportingPeriodId}>
                      {v.ReportingPeriod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: "20px" }}>
                <button
                  style={{ padding: "8px 15px" }}
                  className="button-primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  style={{ padding: "8px 15px" }}
                  className="button-outlined-secondary"
                  type="button"
                  onClick={handleOpenAndCloseModel}

                  // disabled = {postApiForReportingPeriodId == "" ? true : false}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            color: divColor,
            visibility: divVisibility,
            display: displayproperty,
            fontWeight: "600",
            paddingTop: "5px",
          }}
        >
          {toDisplay}
        </div>
      </BoxWrapper>

      <div>
        {trigar && (
          <TableForDamageStock
            dataForBarChart={dataForGraphBarChart}
            dataForPieChart={dataForGraphPieChart}
            data={getApiForTableDamageStock}
            postApiForRegionName={postApiForRegionName}
            postApiForReportingPeriodName={postApiForReportingPeriodName}
            postApiForBranchName={postApiForBranchName}
          />
        )}
      </div>
    </React.Fragment>
  );
}
