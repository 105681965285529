import NumberFormat from "react-number-format"; // Ensure you have this package installed
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from "react";
import axios from "../../axios-order";
import BoxWrapper from "../../components/ui/boxWrapper";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
const NewProduct = ({ open, onClose, onSave ,  additionalValue ,existingSlNos = [], selectedProduct, isEditing , isNewItem }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [productData, setProductData] = useState({
    slNo: "",
    product_det_product_description: "",
    product_det_hsn_sac: "",
    inv_det_quantity: "",
    product_det_unit_price: "",
    amount_det_gross_amount: "",
    amount_det_discount: "",
    amount_det_taxable_value: "",
    amount_det_gst_rate: "",
    amount_det_igst_value: "",
    amount_det_cgst_value: "",
    amount_det_sgst_value: "",
    amount_det_cess_rate: "",
    amount_det_other_charges: "",
    amount_det_total_item_amount: 0,
    amount_det_free_qty: "", // New field
    amount_det_cess_ad_value: "", // New field
    amount_det_cess_non_ad_value: "", // New field
    amount_det_state_cess_rate: "", // New field
    amount_det_state_cess_value: "", // New field
    amount_det_state_cess_non_ad_value: "", // New field
    // is_service: "", 
    product_det_is_service: "",
    // unit: "",
    product_det_unit:"",
    // gst_rate: "", 
    // gst_amount: 0,
  });

 
  const [shouldCallCessRate, setShouldCallCessRate] = useState(false);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showProductDropdown, setShowProductDropdown] = useState(false);

  const [errors, setErrors] = useState({
    slNo: "",
    product_det_hsn_sac: "",
    product_det_unit_price
: "",
    amount_det_gst_rate: "",
    amount_det_state_cess_rate: "",
    product_det_product_description: "",
    // unit: "",
    product_det_unit:"",
    amount_det_gross_amount: "",
    amount_det_taxable_value: "",
    amount_det_total_item_amount: "",
    // other field errors
  });

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  const getItemStyle = (index) => ({
    ...styles.dropdownItem,
    backgroundColor: hoveredIndex === index ? "#f0f0f0" : "#fff",
  });

  useEffect(()=>{
    if (additionalValue) {
      console.log(`Additional Value: ${additionalValue}`);
    }
  },[additionalValue])


  useEffect(() => {
    if (selectedProduct) {
      setProductData({
        ...selectedProduct, // Fill with selected product data
      });
    }
  }, [selectedProduct]);
  useEffect(() => {
    const updatedErrors = {
      product_det_unit_price:
        (String(productData.unit_price) || "").trim() === "",
        amount_det_free_qty:
        (String(productData.amount_det_free_qty) || "").trim() === "",
      amount_det_cess_ad_value:
        (String(productData.amount_det_cess_ad_value) || "").trim() === "",
      amount_det_cess_non_ad_value:
        (String(productData.amount_det_cess_non_ad_value) || "").trim() === "",
      amount_det_state_cess_rate:
        (String(productData.amount_det_state_cess_rate) || "").trim() === "",
      amount_det_state_cess_value:
        (String(productData.amount_det_state_cess_value) || "").trim() === "",
      amount_det_state_cess_non_ad_value:
        (
          String(productData.amount_det_state_cess_non_ad_value) || ""
        ).trim() === "",
      // is_service: (String(productData.is_service) || "").trim() === "", 
      is_service: (String(productData.is_service) || "").trim() === "", 
      // unit: (String(productData.unit) || "").trim() === "", 
      
product_det_unit: (String(productData.
  product_det_unit) || "").trim() === "", 
      amount_det_gst_rate: (String(productData.gst_rate) || "").trim() === "", // New field
    };

    // setErrors(updatedErrors);

    const requiredFieldsFilled = !Object.values(updatedErrors).includes(true);
    setButtonEnabled(requiredFieldsFilled);
  }, [productData]);

  
 
 
  const handleOpenLink = () => {
    const currentUrl = window.location.href;
    const newUrl = new URL('/einvoice', currentUrl).toString();
    window.open(newUrl, '_blank');
  };

  useEffect(() => {
    // Check if amount_det_gst_rate has a value and is valid
    const gstRate = productData.amount_det_gst_rate;
    const taxableValue = productData.amount_det_taxable_value;

    if (gstRate && !isNaN(parseFloat(gstRate)) && taxableValue) {
      fetchGSTValue(gstRate, taxableValue);
    }
  }, [productData.amount_det_gst_rate, productData.amount_det_taxable_value]);

  useEffect(() => {
    
    const taxableValue = parseFloat(productData.amount_det_taxable_value) || 0;
    const gstRate = parseFloat(productData.amount_det_gst_rate) || 0;
    const cessRate = parseFloat(productData.amount_det_cess_rate) || 0;
    const stateCessRate = parseFloat(productData.amount_det_state_cess_rate) || 0;
    
    const result = (taxableValue * gstRate) / 100;
    // console.log("Total Taxable Result",result);
    const results = parseFloat((taxableValue * gstRate) / 100);
    const halfResult = result / 2;

    // console.log('cgst and sgst..........', halfResult)
    
    // console.log(`Taxable Value: ${taxableValue} * GST Rate: ${gstRate} = ${result}`);
  

    const cessResult = parseFloat((taxableValue * cessRate) / 100);
    const stateCessResult = parseFloat((taxableValue * stateCessRate) / 100);
    
    // console.log(`Cess Result: ${cessResult}`);
    // console.log(`State Cess Result: ${stateCessResult}`);
  
  
    setProductData((prevData) => {
      const updatedData = {
        ...prevData,
        // amount_det_sgst_value: halfResult.toFixed(2),
        // amount_det_cgst_value: halfResult.toFixed(2),
        // amount_det_igst_value: results.toFixed(2),
        amount_det_cess_ad_value: cessResult.toFixed(2),
        amount_det_state_cess_value: stateCessResult.toFixed(2),
      };
  
 return updatedData;
     
    });
  }, [
    productData.amount_det_taxable_value,
    productData.amount_det_gst_rate,
    productData.amount_det_cess_rate,
    productData.amount_det_state_cess_rate,
    productData.amount_det_cess_non_ad_value,
    productData.amount_det_other_charges,
  ]);
  
  useEffect(() => {
    setProductData(prevData => ({
      ...prevData,
      amount_det_taxable_value: prevData.amount_det_gross_amount
    }));
  }, [productData.amount_det_gross_amount]);



  const handleChange = async (event) => {
    if (additionalValue) {
      return;
    }
  
    const { name, value } = event.target;
 
    // Update the product data state
    setProductData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // Clear the error when input is valid
      // Validation logic
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        switch (name) {
          case "product_det_product_description":
            newErrors.product_det_product_description =
              value.trim() === ""
                ? "Product Description is required"
                : undefined;
            break;
          case "product_det_hsn_sac":
            newErrors.product_det_hsn_sac =
              value.trim() === "" ? "HSN/SAC Code is required" : undefined;
            break;
          case "product_det_unit ":
            newErrors.product_det_unit =
              value.trim() === "" ? "Unit is required" : undefined;
            break;
          case "product_det_unit_price":
            newErrors.product_det_unit_price
            =
              value <= 0 ? "Unit Price must be greater than 0" : undefined;
            break;
          case "amount_det_gross_amount":
            newErrors.amount_det_gross_amount =
              value <= 0 ? "Gross Amount must be greater than 0" : undefined;
            break;
          case "gst_rate":
            newErrors.amount_det_gst_rate =
              value <= 0 ? "GST Rate must be greater than 0" : undefined;
            break;
          case "amount_det_state_cess_rate":
            newErrors.amount_det_state_cess_rate =
              value < 0 ? "State Cess Rate cannot be negative" : undefined;
            break;
          case "amount_det_total_item_amount":
            newErrors.amount_det_total_item_amount =
              value <= 0
                ? "Total Item Amount must be greater than 0"
                : undefined;
            break;
          // Add other cases for each field as needed
          default:
            break;
        }

        return newErrors;
      });

      if (
        name === "amount_det_gross_amount" ||
        name === "amount_det_discount"
      ) {
        const grossAmount =
          parseFloat(updatedData.amount_det_gross_amount) || 0;
        const discount = parseFloat(updatedData.amount_det_discount) || 0;
        const taxableValue = grossAmount - discount;

        updatedData.amount_det_taxable_value = taxableValue.toFixed(2);
      }
 // Update total item amount
 if (
  name === "amount_det_taxable_value" ||
      name === "amount_det_igst_value" ||
      name === "amount_det_cgst_value" ||
      name === "amount_det_sgst_value" ||
      name === "amount_det_state_cess_value" ||
      name === "amount_det_state_cess_non_ad_value" ||
      name === "amount_det_cess_ad_value" ||
      name === "amount_det_cess_non_ad_value" ||
      name === "amount_det_other_charges"
) {
  const taxableValue = parseFloat(updatedData.amount_det_taxable_value) || 0;
  const igstValue = parseFloat(updatedData.amount_det_igst_value) || 0;
  const cgstValue = parseFloat(updatedData.amount_det_cgst_value) || 0;
  const sgstValue = parseFloat(updatedData.amount_det_sgst_value) || 0;
  const stateCessValue = parseFloat(updatedData.amount_det_state_cess_value) || 0;
  const stateCessNonAdValue = parseFloat(updatedData.amount_det_state_cess_non_ad_value) || 0;
  const cessAdValue = parseFloat(updatedData.amount_det_cess_ad_value) || 0;
  const cessNonAdValue = parseFloat(updatedData.amount_det_cess_non_ad_value) || 0;
  const otherCharges = parseFloat(updatedData.amount_det_other_charges) || 0;


  updatedData.amount_det_total_item_amount = (
    taxableValue + igstValue + cgstValue + sgstValue +
    stateCessValue + stateCessNonAdValue +
    cessAdValue + cessNonAdValue +
    otherCharges
  ).toFixed(2);

}
      
      return updatedData;
    });
 
    
    // Handle async operations outside state updates
    if (name === "product_det_product_description") {
      if (value.length > 0) {
        // Fetch filtered product data
        await fetchProductData(value);
      } else {
        setFilteredProducts([]);
      }
    
  }
  };

  const fetchProductData = async (description) => {
    const business_entity_id = localStorage.getItem("business_entity_id");

    try {
      const response = await axios.get(
        `/einvoice/get_product_by_description/${business_entity_id}/${description}`
      );
      if (response.data.status && response.data.records.length > 0) {
        setFilteredProducts(
          response.data.records.map((record) => record.product_description)
        );
      } else {
        setFilteredProducts([]);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchProductDetails = async (productDescription) => {
    const business_entity_id = localStorage.getItem("business_entity_id");

    try {
      const response = await axios.get(
        `/einvoice/get_product_by_description/${business_entity_id}/${productDescription}`
      );

      if (response.data.status && response.data.records.length > 0) {
        const productDetails = response.data.records[0]; // Assuming single record

        setProductData((prevData) => ({
          ...prevData,
          product_det_product_description: productDetails.product_description,
          // is_service: productDetails.is_service,
          product_det_is_service: productDetails.is_service,

          // Add other fields from productDetails as needed
          product_det_hsn_sac: productDetails.hsn_code,
          amount_det_cess_rate: productDetails.cess_ad_rate || "",         
product_det_unit: productDetails.unit || "", // Ensure default empty string
product_det_unit_price
: productDetails.unit_price || 0, // Ensure default number
          amount_det_gst_rate: productDetails.gst_rate || "", // Ensure default empty string
          amount_det_cess_ad_value: productDetails.cess_ad_rate || "", // Ensure default empty string
          amount_det_cess_non_ad_value: productDetails.cess_non_adj_amt || "", // Ensure default empty string
          amount_det_state_cess_rate: productDetails.state_cess_rate || "", // Ensure default empty string
          // Add any other fields you need
        }));
      } else {
        // console.log("No product details found");
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const fetchGSTValue = async (gstRate) => {
    try {
      const taxRate = gstRate ? gstRate : 0
      const s_gstin = localStorage.getItem("gst");
      const placeOfSupply = localStorage.getItem("placeOfSupply")
      const reverSeCharger = localStorage.getItem("reverSeCharger");
      const igstOnIntra = localStorage.getItem("igstOnIntra")
      const response = await axios.get(
        `/einvoice/derive_gst_value/${taxRate}/${placeOfSupply}/${productData.amount_det_taxable_value}/${s_gstin}/${reverSeCharger}/${igstOnIntra}`
      );

      // Update productData with response values
      setProductData((prevState) => ({
        ...prevState,
        amount_det_igst_value: response.data.igst || "",
        amount_det_cgst_value: response.data.cgst || "",
        amount_det_sgst_value: response.data.sgst || "",
      }));
      // console.log("GST Value:", response.data);
    } catch (error) {
      console.error("Error fetching GST value:", error);
    }
  };


  useEffect(() => {
    // console.log("GST Rate changed:", typeof(productData.amount_det_gst_rate)); // Add this

      fetchGSTValue(productData.amount_det_gst_rate);
    
  }, [productData.amount_det_gst_rate, productData.amount_det_taxable_value]);

  const handleProductSelect = async (productDescription) => {
    await fetchProductDetails(productDescription); // Fetch additional details based on selection
    setShowProductDropdown(false);
  };

  const handleSave = () => {
    let hasError = false;
    const newErrors = {};
    // Validation
    if (!productData.product_det_hsn_sac) {
      newErrors.product_det_hsn_sac = "Please fill this";
      hasError = true;
    }
    // Validation
    if (!productData.product_det_product_description) {
      newErrors.product_det_product_description = "Please fill this";
      hasError = true;
    }
    if (!productData.
      product_det_unit) {
      newErrors.
      product_det_unit = "Please fill this";
      hasError = true;
    }
    if (
      !productData.amount_det_gross_amount ||
      productData.amount_det_gross_amount <= 0
    ) {
      newErrors.amount_det_gross_amount = "Please enter a valid Gross Amount";
      hasError = true;
    }
    if (
      !productData.amount_det_taxable_value ||
      productData.amount_det_taxable_value < 0
    ) {
      newErrors.amount_det_taxable_value = "Please enter a valid Taxable Value";
      hasError = true;
    }
    if (
      !productData.amount_det_total_item_amount ||
      productData.amount_det_total_item_amount < 0
    ) {
      newErrors.amount_det_total_item_amount =
        "Please enter a valid Total Item Amount";
      hasError = true;
    }

    if (!productData.product_det_unit_price
      || productData.product_det_unit_price
      <= 0) {
      newErrors.product_det_unit_price
      = "Please enter a valid Unit Price";
      hasError = true;
    }
    if (
      !productData.amount_det_gst_rate ||
      productData.amount_det_gst_rate < 0
    ) {
      newErrors.amount_det_gst_rate = "Please enter a valid GST Rate";
      hasError = true;
    }

    // if (!productData.amount_det_state_cess_rate || productData.amount_det_state_cess_rate < 0) {
    //   newErrors.amount_det_state_cess_rate = 'Please enter a valid State Cess Rate';
    //   hasError = true;
    // }

    // Validation
    if (!productData.slNo) {
      newErrors.slNo = "Please fill this";
      hasError = true;
    } else if (existingSlNos.includes(productData.slNo)) {
      newErrors.slNo = "SL No should not be a duplicate";
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) return;

    
    const productData1 = {
      ...productData,
      id: uuidv4() // Generate a unique ID
    };
    // console.log("Product Data:", productData1);
    onSave(productData1);
    onClose();
  };

  if (!open) return null;

  const styles = {
    dialogOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    dialogContainer: {
      background: "white",
      maxHeight: "100vh", // Adjust the height as needed
      overflowY: "auto",
      borderRadius: "8px",
      padding: "20px",
      width: "140%",
      maxWidth: "1100px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 1001,
    },
    formContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "15px",
      justifyContent: "space-between",
    },
    inputField: {
      flex: "1 1 18%", // 24% to fit 4 fields in a row with some gap
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    input: {
      padding: "8px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      width: "100%",
    },
    inputError: {
      padding: "8px",
      borderRadius: "4px",
      border: "1px solid red",
      width: "100%",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
      marginTop: "20px",
    },
    button: {
      padding: "10px 20px",
      borderRadius: "4px",
      cursor: "pointer",
      border: "none",  
      backgroundColor:'#0b3887',
      color:'white'
    },
    closeButton: {
      backgroundColor: "#657085",
      color: "white",
    },
    saveButton: {
      backgroundColor: "#0b3887",
      color: "white",
    },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", 
    marginBottom: "20px", 
  },
    dropdownList: {},
    dropdownItem: {},
    dropdownItemHover: {
      backgroundColor: "#f0f0f0", // Change background color on hover
    },
    errorMessage: {
      color: "red",
      fontSize: "0.875rem",
      marginTop: "5px",
    },
    title:{
      color:'#0b3887'
    }
  };

  return (
    <BoxWrapper boxTitle="New Product">
      <div style={styles.dialogOverlay}>
        <div style={styles.dialogContainer} >
        <div style={styles.headerContainer}>
          <h2 style={styles.title}>
            Add New Item
          </h2>
          {!additionalValue && (
 <button
 type="button"
 onClick={handleOpenLink}
 style={styles.button}
>
 Press Here To Go Product Master Page
</button>
          )}
         
        </div>

          <div style={styles.formContainer}>
            <div style={styles.inputField}>
              <label>
                Sl No <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="slNo"
                  type="text"
                  value={productData.slNo}
                  onChange={handleChange}
                  style={styles.input}
                />
              </label>
              {errors.slNo && (
                <div style={styles.errorMessage}>{errors.slNo}</div>
              )}
            </div>
            <div style={styles.inputField}>
              <label>
                Product Description
                <input
                  name="product_det_product_description"
                  type="text"
                  value={productData.product_det_product_description}
                  onChange={handleChange}
                  style={styles.input}
                  onFocus={() => setShowProductDropdown(true)}
                  onBlur={() =>
                    setTimeout(() => setShowProductDropdown(false), 9000)
                  }
                />
              </label>
              {errors.product_det_product_description && (
                <div style={styles.errorMessage}>
                  {errors.product_det_product_description}
                </div>
              )}
              {showProductDropdown && filteredProducts.length > 0 && (
                <div
                  style={{
                    border: "1px solid #ddd",
                    maxHeight: "140px",
                    overflowY: "auto",
                    position: "absolute",
                    backgroundColor: "#fff",
                    width: "100%",
                    top: "100%",
                    left: "0",
                    zIndex: 1000,
                  }}
                >
                  {filteredProducts.map((product) => (
                    <div
                      key={product}
                      onClick={() => handleProductSelect(product)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {product}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={styles.inputField}>
              <label>
                Is Service{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              
                  <input
          name="product_det_is_service"
          type="text" // Change to text input for manual entry
          value={productData.product_det_is_service || ""}
        
          style={errors.product_det_is_service ? styles.inputError : styles.input}
 
        />
              </label>
            </div>
            <div style={styles.inputField}>
              <label>
                HSN/SAC Code{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="product_det_hsn_sac"
                  type="number"
                  step="1"
                  value={productData.product_det_hsn_sac}
                  // onChange={handleChange}
                  style={styles.input}
                />
              </label>
              {errors.product_det_hsn_sac &&
                !productData.product_det_hsn_sac && (
                  <div style={styles.errorMessage}>
                    {errors.product_det_hsn_sac}
                  </div>
                )}
            </div>
            <div style={styles.inputField}>
            
              
<label>
  Unit <span style={{ color: "red", marginLeft: "4px" }}>*</span>
  
   <input
                    type="text"
                    value={productData.
                      product_det_unit}
                    readOnly
                   
                    style={styles.input} // Adjust this style as needed for the input
                  />
  
</label>

              {errors.
product_det_unit && !productData.
product_det_unit && (
                <div style={styles.errorMessage}>{errors.
                  product_det_unit}</div>
              )}
            </div>
            <div style={styles.inputField}>
              <label>
                Quantity
                <input
                  name="inv_det_quantity
"
                  type="number"
                  step="1"
                  value={productData.inv_det_quantity
                  }
                  // onChange={handleChange}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    handleChange(e); // Update state with valid input
                      if (additionalValue) {
      return;
    }
                    const quantity = parseInt(value, 10) || 0;
                    const unitPrice = parseFloat(productData.product_det_unit_price
                    ) || 0;
                    const total = quantity * unitPrice;
                    console.log(
                      "Quantity:",
                      quantity,
                      "Unit Price:",
                      unitPrice,
                      "Total:",
                      total
                    );
                    setProductData({
                      ...productData,
                      inv_det_quantity: value,
                      amount_det_gross_amount: total.toFixed(2), // Update Gross Amount with Total
                     amount_det_discount: '0'
                    });
                  }}
                  style={styles.input}
                  onInput={(e) => {
                    // Allow only integer input
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </label>
            </div>
            <div style={styles.inputField}>
              <label>
                Unit Price{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="product_det_unit_price
"
                  type="number"
                  readOnly
                  value={productData.product_det_unit_price
                  }
                 
                
                  style={styles.input}
                  // style={errors.unit_price ? styles.inputError : styles.input}
                />
              </label>
              {errors.product_det_unit_price
 && !productData.product_det_unit_price
 && (
                <div style={styles.errorMessage}>{errors.product_det_unit_price
}</div>
              )}
            </div>
            <div style={styles.inputField}>
              <label>
                Free Qty
                <input
                  name="amount_det_free_qty"
                  type="number"
                  step="1" // Prevents decimals
                  value={productData.amount_det_free_qty}
                  onChange={handleChange}
                  style={styles.input}
                  onInput={(e) => {
                    // Allow only integer input
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  // style={errors.amount_det_free_qty ? styles.inputError : styles.input}
                />
              </label>
            </div>
            <div style={styles.inputField}>
             
              <label style={{ marginBottom: 0, display: "block" }}>
                Gross Amount{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              </label>
              <NumberFormat
          readOnly
                customInput={TextField}
                fullWidth
                // label="Gross Amount"
                name="amount_det_gross_amount"
                value={productData.amount_det_gross_amount}
             
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true
                }}

                // style={errors.amount_det_gross_amount ? styles.inputError : styles.input}
              />
              {errors.amount_det_gross_amount &&
                !productData.amount_det_gross_amount && (
                  <div style={styles.errorMessage}>
                    {errors.amount_det_gross_amount}
                  </div>
                )}
            </div>
            <div style={styles.inputField}>
             
              <label style={{ marginBottom: 0, display: "block" }}>
                Discount
              </label>
              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="Discount"
                name="amount_det_discount"
                value={productData.amount_det_discount}
                // onValueChange={({ value }) =>
                //   handleChange({
                //     target: { name: "amount_det_discount", value },
                //   })
                // }
                onValueChange={({ value }) => {
                  // Only allow change if additionalValue is not set
                  if (!additionalValue) {
                    handleChange({
                      target: { name: "amount_det_discount", value }
                    });
                  }
                }}
        
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  readOnly: !!additionalValue,
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                }}
                // style={styles.input}
              />
            </div>
            <div style={styles.inputField}>
             
              <label style={{ marginBottom: 0, display: "block" }}>
                Taxable Value{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="Taxable Value"
                name="amount_det_taxable_value"
                value={productData.amount_det_taxable_value}
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_taxable_value", value},
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                // style={styles.input}
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}
              />
              {/* {errors.amount_det_taxable_value && <div style={styles.errorMessage}>{errors.amount_det_taxable_value}</div>} */}
            </div>
            <div style={styles.inputField}>
              <label>
                GST Rate{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="amount_det_gst_rate"
                  type="number"
                  step="1"
                  readOnly
                  value={productData.amount_det_gst_rate}
                  // onChange={(e) => {
                  //   const { value } = e.target;
                    
             
                  //   if (/^\d*\.?\d*$/.test(value)) {
                  //     setProductData({
                  //       ...productData,
                  //       amount_det_gst_rate: value,
                  //     });
                  //   }
                  // }}
                  // onChange={(e) =>  setProductData({ ...productData, amount_det_gst_rate: e.target.value })}
                  style={styles.input}
               
                />
              </label>

              {errors.amount_det_gst_rate &&
                !productData.amount_det_gst_rate && (
                  <div style={styles.errorMessage}>
                    {errors.amount_det_gst_rate}
                  </div>
                )}
            </div>
            <div style={styles.inputField}>
             
              <label style={{ marginBottom: 0, display: "block" }}>
                SGST VALUE
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="SGST VALUE"
                name="amount_det_sgst_value"
                // value={productData.amount_det_sgst_value}
                value={
                  productData.amount_det_sgst_value !== "0.00"
                    ? productData.amount_det_sgst_value
                    : ""
                }
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_sgst_value", value },
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                // style={styles.input}
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}
              />
            </div>
            <div style={styles.inputField}>
           
              <label style={{ marginBottom: 0, display: "block" }}>
                CGST Value
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="CGST Value"
                name="amount_det_cgst_value"
                // value={productData.amount_det_cgst_value}
                value={
                  productData.amount_det_cgst_value !== "0.00"
                    ? productData.amount_det_cgst_value
                    : ""
                }
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_cgst_value", value },
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                // style={styles.input}
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}
              />
            </div>
            <div style={styles.inputField}>
              {/* <label>
              IGST
              <input
                name="amount_det_igst_value"
                type="number"
                value={productData.amount_det_igst_value}
                onChange={handleChange}
                style={styles.input}
              />
            </label> */}
              <label style={{ marginBottom: 0, display: "block" }}>
                IGST Value
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="IGST Value"
                name="amount_det_igst_value"
                value={
                  productData.amount_det_igst_value !== "0.00"
                    ? productData.amount_det_igst_value
                    : ""
                }
                // value={productData.amount_det_igst_value}
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_igst_value", value },
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}
              />
            </div>

            <div style={styles.inputField}>
              <label>
                Cess Rate (%)
               
                 <input
                 readOnly
    name="amount_det_cess_rate" // This should match the state property
    type="number"
    step="0.01" // Use step for decimal values
    value={productData.amount_det_cess_rate}
   
    
    style={styles.input}
  />
              </label>
            </div>
            <div style={styles.inputField}>
            
              <label style={{ marginBottom: 0, display: "block" }}>
                {" "}
                Cess Ad Value
              </label>
              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="Cess Ad Value"
                name="amount_det_cess_ad_value"
                // value={productData.amount_det_cess_ad_value}
                value={
                  productData.amount_det_cess_ad_value !== "0.00"
                    ? productData.amount_det_cess_ad_value
                    : ""
                }
               
                onValueChange={({ value }) => {
                  if (!additionalValue) {
                    handleChange({
                      target: { name: "amount_det_cess_ad_value", value }
                    });
                  }
                }}
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  readOnly: !!additionalValue,
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                }}

                // style={errors.amount_det_cess_ad_value ? styles.inputError : styles.input}
              />
            </div>
            <div style={styles.inputField}>
          
              <label style={{ marginBottom: 0, display: "block" }}>
                Cess Non-Ad Value
              </label>
              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="Cess Non-Ad Value"
                name="amount_det_cess_non_ad_value"
                value={productData.amount_det_cess_non_ad_value}
               
                onValueChange={({ value }) => {
                  if (!additionalValue) {
                    handleChange({
                      target: { name: "amount_det_cess_non_ad_value", value }
                    });
                  }
                }}
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  readOnly: !!additionalValue,
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                }}
                // style={errors.amount_det_cess_non_ad_value ? styles.inputError : styles.input}
              />
            </div>
            <div style={styles.inputField}>
            
              <label style={{ marginBottom: 0, display: "block" }}>
                State Cess Rate
              </label>
              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="State Cess Rate"
                name="amount_det_state_cess_rate"
                value={productData.amount_det_state_cess_rate}
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_state_cess_rate", value },
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}

                // style={errors.amount_det_state_cess_rate ? styles.inputError : styles.input}
              />
              {errors.amount_det_state_cess_rate && (
                <div style={styles.errorMessage}>
                  {errors.amount_det_state_cess_rate}
                </div>
              )}
              {/* </label> */}
            </div>
            <div style={styles.inputField}>
          
              <label style={{ marginBottom: 0, display: "block" }}>
                State Cess Value
              </label>
              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="State Cess Value"
                name="amount_det_state_cess_value"
                // value={productData.amount_det_state_cess_value}
                value={
                  productData.amount_det_state_cess_value !== "0.00"
                    ? productData.amount_det_state_cess_value
                    : ""
                }
                onValueChange={({ value }) =>
                  handleChange({
                    target: { name: "amount_det_state_cess_value", value },
                  })
                }
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                  readOnly: true 
                }}

                // style={errors.amount_det_state_cess_value ? styles.inputError : styles.input}
              />
            </div>
            <div style={styles.inputField}>
            
              <label style={{ marginBottom: 0, display: "block" }}>
                State Cess Non-Ad Value
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="State Cess Non-Ad Value"
                name="amount_det_state_cess_non_ad_value"
                value={productData.amount_det_state_cess_non_ad_value}
             
                onValueChange={({ value }) => {
                  if (!additionalValue) {
                    handleChange({
                      target: { name: "amount_det_state_cess_non_ad_value", value }
                    });
                  }
                }}
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  readOnly: !!additionalValue,
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                }}
                // style={errors.amount_det_state_cess_non_ad_value ? styles.inputError : styles.input}
              />
            </div>
            <div style={styles.inputField}>
              
              <label style={{ marginBottom: 0, display: "block" }}>
                Other Charges
              </label>

              <NumberFormat
                customInput={TextField}
                fullWidth
                // label="Other Charges"
                name="amount_det_other_charges"
                value={productData.amount_det_other_charges}
                // onValueChange={({ value }) =>
                //   handleChange({
                //     target: { name: "amount_det_other_charges", value },
                //   })
                // }
                onValueChange={({ value }) => {
                  if (!additionalValue) {
                    handleChange({
                      target: { name: "amount_det_other_charges", value }
                    });
                  }
                }}
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                variant="outlined"
                InputProps={{
                  readOnly: !!additionalValue,
                  style: { height: "35px", marginTop: "0px" }, // Adjust the height here
                }}
                // style={styles.input}
              />
            </div>
            <div style={styles.inputField}>
              <label>
                Total Item Amount{" "}
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                <input
                  name="amount_det_total_item_amount"
                  type="number"
                  value={productData.amount_det_total_item_amount || ""}
                  onChange={handleChange}
                  style={styles.input}
                  readOnly
                />
              </label>
              {errors.amount_det_total_item_amount &&
                !productData.amount_det_total_item_amount && (
                  <div style={styles.errorMessage}>
                    {errors.amount_det_total_item_amount}
                  </div>
                )}
            </div>

            {/* New fields */}
          </div>
          <div style={styles.buttonGroup}>
          {!additionalValue && (
    <button
      type="button"
      onClick={handleSave}
      style={{ ...styles.button, ...styles.saveButton }}
      // disabled={!buttonEnabled}
    >
      Submit
    </button>
  )}
            <button
              type="button"
              onClick={onClose}
              style={{ ...styles.button, ...styles.closeButton }}
            >
              Exit
            </button>
          </div>
        </div>
      </div>
  
    </BoxWrapper>
    
  );
};

export default NewProduct;
