import React, { useState, useEffect } from "react";
import "./dashboard.css";

const CustomerTable = (props) => {
  const { data, loader } = props;

  // indian rupeess system---
  const indianRupessFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 10,
    }).format(value?.toFixed(2));
  // ---End---

  return (
    <div class="CustomerTable">
      <div className="responsive-table">
        <table className="custome-long-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer GSTIN </th>
              <th>Customer Code</th>
              <th>Customer PAN </th>
              <th>Account Manager</th>
              <th>GST Registration Type</th>
              <th>As of Date</th>
              <th>Invoices Due</th>
              <th>Invoices Not Due</th>
              <th>Total Monthly Sales</th>
              {data.length > 0 &&
                Object.keys(data[0]).map((item) => {
                  if (
                    "Customer Name" === item ||
                    "Customer GSTIN" === item ||
                    "Customer PAN" === item ||
                    "Account Manager" === item ||
                    "Registration Type" === item ||
                    "Customer Code" === item ||
                    "Invoices Due" === item ||
                    "Invoices Not Due" === item ||
                    "Total Monthly Sales" === item ||
                    "As of Date" === item
                  ) {
                    return false;
                  } else {
                    return <th>{item}</th>;
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr>
                    <td>{item["Customer Name"] ?? ""} </td>
                    <td>{item["Customer GSTIN"] ?? ""}</td>
                    <td>{item["Customer Code"] ?? ""}</td>
                    <td>{item["Customer PAN"] ?? ""}</td>
                    <td>{item["Account Manager"] ?? ""}</td>
                    <td>{item["Registration Type"] ?? ""}</td>
                    <td>{item["As of Date"] ?? ""}</td>

                    <td>{item["Invoices Due"] ?? ""}</td>
                    <td>{item["Invoices Not Due"] ?? ""}</td>
                    <td>{item["Total Monthly Sales"] ?? ""}</td>
                    {Object.keys(item).map((v, i) => {
                      if (
                        "Customer Name" === v ||
                        "Customer GSTIN" === v ||
                        "Customer PAN" === v ||
                        "Account Manager" === v ||
                        "Registration Type" === v ||
                        "Customer Code" === v ||
                        "Invoices Due" === v ||
                        "Invoices Not Due" === v ||
                        "Total Monthly Sales" === v ||
                        "As of Date" === v
                      ) {
                      } else {
                        return <td>{indianRupessFormat(item[v]) ?? ""}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            {/* no data found */}
            {data.length === 0 && loader === false && (
              <tr>
                <td style={{ color: "red" }}>No Data Found!</td>
              </tr>
            )}

            {loader && (
              <tr>
                <td>Loading.Please wait...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CustomerTable;
