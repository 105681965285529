import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import highcharts3d from "highcharts/highcharts-3d";
// import { setDayWithOptions } from 'date-fns/fp';

// highcharts3d(Highcharts);

function generateDarkColorHex() {
  let color = "#";
  for (let i = 0; i < 3; i++)
    color += (
      "0" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
    ).slice(-2);
  return color;
}

function getRandColor(same, darkness, total_series) {
  let arr = [];
  let i = 0;
  for (i; i < total_series; i++) {
    const randomColor = generateDarkColorHex();
    arr.push(randomColor);
  }
  return arr;
}

const setOptions = (props) => {
  const { fnForDrillDown, enableDrilldownFn } = props;
  return (
    props.data && {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      title: {
        text: props.pieChartTitle,
      },
      tooltip: {
        pointFormat: "<b>{point.percentage:.1f}%</b>",
      },
      // accessibility: {
      //     point: {
      //         valueSuffix: '%'
      //     }
      // },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
          point: {
            events: {
              click: function (e) {
                if (enableDrilldownFn) {
                  fnForDrillDown(e.point.name);
                }
              },
            },
          },
        },
      },
      series: [
        {
          showInLegend: true,
          data: props.data,
          colors: getRandColor(false, 5, props.data.length),
        },
      ],
    }
  );
};

const MyStockChart = (props) => (
  <HighchartsReact
    highcharts={Highcharts}
    allowChartUpdate={true}
    options={setOptions(props)}
  />
);

export default MyStockChart;
