import React, { useState } from "react";
import "../TDSRecon.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function SYForm26asDetails(props) {
  const { form26asData } = props;
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(form26asData);

  // indian date format
  const indianDateFormat = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const handleTableSearch = (event) => {
    setSearch(event.target.value);
    let searchData = form26asData.filter((item) => {
      const query = event.target.value.toLowerCase();
      return (
        item.financial_year.toLowerCase().includes(query) ||
        item.quarter.toLowerCase().includes(query) ||
        item.customer_tan.toLowerCase().includes(query)
      );
    });
    setTableData(searchData);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Form 26AS Details"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleTableSearch}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>TAN</th>
                <th>Financial Year</th>
                <th>TDS Quarter</th>

                <th>Transaction date</th>
                <th>Tax deducted</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.customer_name}</td>
                    <td>{item.customer_tan}</td>
                    <td>FY {item.financial_year}</td>
                    <td>Q {item.quarter}</td>

                    <td>{indianDateFormat(item.transaction_date)}</td>
                    <td>{indianRupeesFormat(item.tax_deducted)}</td>
                  </tr>
                );
              })}
              {tableData.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default SYForm26asDetails;
