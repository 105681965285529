import React from "react";
import "./dashboard.css";
import moment from "moment";

const UnallocatedTable = (props) => {
  const { data, loader } = props;

  // indian rupeess system---
  const indianRupessFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 10,
    }).format(value);
  // ---End---
  return (
    <div class="VendorTable">
      <div className="responsive-table">
        <table className="custome-table">
          <thead>
            <tr>
              <th>ERP Vendor code</th>
              <th>Vendor GSTIN </th>
              <th>Vendor PAN </th>
              <th>Transaction Type</th>
              <th>Payment Date</th>
              <th>Amount Paid</th>
              <th>Transaction Ref Number</th>
              <th>Adjustment Method</th>
              <th>Gst Registration Type</th>
              <th>Purchase Manager</th>
              <th>voucher Id</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr>
                    <td>{item.erp_vendor_code ?? ""}</td>
                    <td>{item.vendor_gstin ?? ""}</td>
                    <td>{item.vendor_pan ?? ""}</td>
                    <td>{item.transaction_type ?? ""}</td>
                    <td>{moment(item.payment_date).format("DD-MM-YYYY")}</td>
                    <td>{item.amount_paid ?? ""}</td>
                    <td>{item.transaction_Ref_number ?? ""}</td>
                    {/* <td>{item["Days Past Due Date"]}</td> */}
                    <td>{item.adjustment_method ?? ""}</td>
                    <td>{item.gst_registration_type ?? ""}</td>
                    <td>{item.purchase_manager_name ?? ""}</td>
                    <td>{item.voucher_id ?? ""}</td>
                  </tr>
                );
              })}
            {/* no data found */}
            {data.length === 0 && loader === false && (
              <tr>
                <td style={{ color: "red" }}>No Data Found!</td>
              </tr>
            )}

            {loader && (
              <tr>
                <td>Loading.Please wait...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UnallocatedTable;
