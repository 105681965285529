import React, { Component } from "react";
import classes from "./Dashboard.module.css";
import {
  TablePagination,
  Button,
  Grid,
  Paper,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import "../../../../asserts/css/Onboarding.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import Card from "../../../../components/ui/Cards";
import BarChartRupeesFormat from "../../../../components/ui/BarChart_rupeesFormat";
import BasicBarChart from "../../../../components/ui/BasicBarCharts";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import InputField from "../../../../components/ui/basicInput";
import SelectField from "../../../../components/ui/basicSelectInput";
import axios from "../../../../axios-order";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);

const dateFormat = (value) => {
  if (typeof value === "undefined") return "";
  let old_date = String(value).split("-");
  return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
};
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: null,
      cardValues: [],
      month_wise_total_invoices: {},
      customer_payables_chart: {},
      outstanding_amount_by_customers: {},
      invoice_amounts_by_customers: {},
      AsOfDate: new Date(),
      originalDataCustomerInvoiceDetails: [],
      dataRawForCustomerInvoiceDetails: [],
      searchInput: "",
      loading: false,
      filter_from_and_todate: "" || this.props.location.data,
      invoiceFilter: "Current Year",
      showInvoiceDateFilter: false,
      fromDate: "",
      toDate: "",
      buttonDisabled: false,
      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",
    };
  }

  // customer invoice details
  customerInvoiceDetailsApiFn = () => {
    let payload = {
      number_of_rows_per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      search_txt: this.state.searchInput,
      seller_code: localStorage.getItem("business_entity_id"),
    };
    
    axios
      .post(`invoices/customer_invoicing_details`, payload)
      .then((res) => {
        if (typeof res.data.error !== "undefined") {
        } else {
          this.setState({
            dataRawForCustomerInvoiceDetails: res.data.data,
            originalDataCustomerInvoiceDetails: res.data.data,
            rowCount: res.data.row_count,
          });
        }
      })
      .catch(() => this.setState({ loading: false }));
  };

  // pagination handler
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.customerInvoiceDetailsApiFn();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.customerInvoiceDetailsApiFn();
    });
  };
  //table search by customer name-----
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.customerInvoiceDetailsApiFn();
    });
  };
  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredDataForCustomerInvoiceDetals =
      this.state.originalDataCustomerInvoiceDetails.filter((value) => {
        return String(value.customer_name)
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    this.setState({
      dataRawForCustomerInvoiceDetails: filteredDataForCustomerInvoiceDetals,
    });
  };

  //Dashboard onload----------
  componentDidMount = () => {
    this.setState({ loading: true });
    var fromDate;
    var toDate;
    if (this.state.filter_from_and_todate) {
      fromDate = this.state.filter_from_and_todate[0];
      toDate = this.state.filter_from_and_todate[1];
      if (this.state.filter_from_and_todate[2] === "Date Filter") {
        this.setState({
          showInvoiceDateFilter: true,
          fromDate: fromDate,
          toDate: toDate,
        });
      }
      this.setState({
        AsOfDate: toDate,
        invoiceFilter: this.state.filter_from_and_todate[2],
      });
    } else {
      // current date[mm-dd-yyyy]
      const current_date = new Date();
      const pad = "00";
      const yy = current_date.getFullYear().toString();
      const mm = (current_date.getMonth() + 1).toString();

      // from date logic
      if (mm == 1 || mm == 2 || mm == 3) {
        const year = parseInt(yy) - 1;
        fromDate = `${year.toString()}-04-01`;
        toDate = `${yy}-03-31`;
      } else {
        const year1 = parseInt(yy) + 1;
        fromDate = `${yy}-04-01`;
        toDate = `${year1.toString()}-03-31`
      }
      // to date logic
      // var date = new Date();
      // const lastDate = new Date(
      //   date.getFullYear(),
      //   date.getMonth() + 1,
      //   0
      // ).getDate();
      // toDate = `${yy}-${(pad + mm).slice(-2)}-${lastDate.toString()}`;
    }

    // this.MyDataProvider = dataProvider(process.env.REACT_APP_DOMAIN);
    let promise = new Promise((resolve, reject) => {
      axios
        .get(
          `invoices/generateInvoices/${fromDate}/${toDate}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          resolve(true);
        });
    });
    Promise.all([promise]).then((res) => {
      // getting data for card
      axios
        .get(
          `invoices/cards/${toDate}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({ loading: false });
          } else {
            this.setState({
              loading: false,
              cardValues: res.data.cards_data,
              sellerName: res.data.seller_name,
            });
          }
        });

      axios
        .get(
          `invoices/month_wise_total_invoices/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
          } else {
            this.setState({ month_wise_total_invoices: res.data });
          }
        });

      axios
        .get(
          `invoices/invoice_amounts_by_customers/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data === "undefined" || res.data === "") {
          } else {
            this.setState({ invoice_amounts_by_customers: res.data });
          }
        });

      this.customerInvoiceDetailsApiFn();
    });
  };
  handleFromDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState({
        fromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };

  handleToDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState({
        toDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };
  // from date and to dat e filter
  filterDateSubmitHandler = () => {
    this.setState({
      buttonDisabled: true,
      from_date: this.state.fromDate,
      to_date: this.state.toDate,
      loading: true,
      sellerName: null,
    });

    let promise = new Promise((resolve, reject) => {
      // calling base api for data
      axios
        .get(
          `invoices/generateInvoices/${this.state.fromDate}/${
            this.state.toDate
          }/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          resolve(true);
        });
    });
    Promise.all([promise]).then((res) => {
      // getting data for card
      axios
        .get(
          `invoices/cards/${this.state.toDate}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({
              sellerName: null,
              buttonDisabled: false,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              cardValues: res.data.cards_data,
              sellerName: res.data.seller_name,
              buttonDisabled: false,
            });
          }
        });

      // getting data for month_wise_total_invoices [Barchart 1]
      axios
        .get(
          `invoices/month_wise_total_invoices/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
          } else {
            this.setState({ month_wise_total_invoices: res.data });
          }
        });

      // getting data for invoice_amounts_by_customers [Barchart2]
      axios
        .get(
          `invoices/invoice_amounts_by_customers/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data === "undefined" || res.data === "") {
          } else {
            this.setState({ invoice_amounts_by_customers: res.data });
          }
        });

      // getting data for customer invoice  table

      this.customerInvoiceDetailsApiFn();
    });
  };

  //current year and past year filter
  handleInvoiceFilter = (event) => {
    const value = event.target.value;

    this.setState({
      invoiceFilter: value,
    });

    // current date[mm-dd-yyyy]
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();

    // to date logic for current fiscal year
    var date = new Date();
    const lastDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    // var toDate = `${yy}-${(pad + mm).slice(-2)}-${lastDate.toString()}`;

    let fromDate;
    let toDate;
    if (value === "Current Year") {
      this.setState({ loading: true, sellerName: null });
      if (mm == 1 || mm == 2 || mm == 3) {
        const year = parseInt(yy) - 1;
        fromDate = `${year.toString()}-04-01`;
        toDate = `${yy}-03-31`;
      } else {
        const year1 = parseInt(yy) + 1;
        fromDate = `${yy}-04-01`;
        toDate = `${year1.toString()}-03-31`
      }
      
      let promise = new Promise((resolve, reject) => {
        // calling base api for data
        axios
          .get(
            `invoices/generateInvoices/${fromDate}/${toDate}/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            resolve(true);
          });
      });
      Promise.all([promise]).then((res) => {
        // getting data for card
        axios
          .get(
            `invoices/cards/${toDate}/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data.error !== "undefined") {
              this.setState({
                sellerName: null,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
                cardValues: res.data.cards_data,
                sellerName: res.data.seller_name,
              });
            }
          });

        // getting data for month_wise_total_invoices [Barchart 1]
        axios
          .get(
            `invoices/month_wise_total_invoices/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data.error !== "undefined") {
            } else {
              this.setState({ month_wise_total_invoices: res.data });
            }
          });

        // getting data for invoice_amounts_by_customers [Barchart2]
        axios
          .get(
            `invoices/invoice_amounts_by_customers/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data === "undefined" || res.data === "") {
            } else {
              this.setState({ invoice_amounts_by_customers: res.data });
            }
          });

        // getting data for customer invoice  table

        this.customerInvoiceDetailsApiFn();
      });

      this.setState({
        showInvoiceDateFilter: false,
        from_date: fromDate,
        to_date: toDate,
        AsOfDate: toDate,
        fromDate: "",
        toDate: "",
      });
    } else if (value === "Past Year") {
      this.setState({ loading: true, sellerName: null });
      let fromDate;
      let toDate;
      if (mm == 1 || mm == 2 || mm == 3) {
        const year = parseInt(yy) - 1;
        var fromDateYear = parseInt(yy) - 2;
        fromDate = `${fromDateYear.toString()}-04-01`;
        toDate = `${year.toString()}-03-31`;
      } else {
        const year = parseInt(yy) - 1;
        fromDate = `${year}-04-01`;
        toDate = `${yy.toString()}-03-31`;
      }

      let promise = new Promise((resolve, reject) => {
        // calling base api for data
        axios
          .get(
            `invoices/generateInvoices/${fromDate}/${toDate}/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            resolve(true);
          });
      });
      Promise.all([promise]).then((res) => {
        // getting data for card
        axios
          .get(
            `invoices/cards/${toDate}/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data.error !== "undefined") {
              this.setState({
                sellerName: null,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
                cardValues: res.data.cards_data,
                sellerName: res.data.seller_name,
              });
            }
          });

        // getting data for month_wise_total_invoices [Barchart 1]
        axios
          .get(
            `invoices/month_wise_total_invoices/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data.error !== "undefined") {
            } else {
              this.setState({ month_wise_total_invoices: res.data });
            }
          });

        // getting data for invoice_amounts_by_customers [Barchart2]
        axios
          .get(
            `invoices/invoice_amounts_by_customers/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            if (typeof res.data === "undefined" || res.data === "") {
            } else {
              this.setState({ invoice_amounts_by_customers: res.data });
            }
          });

        // getting data for customer invoice  table

        this.customerInvoiceDetailsApiFn();
      });

      this.setState({
        showInvoiceDateFilter: false,
        from_date: fromDate,
        to_date: toDate,
        fromDate: "",
        toDate: "",
      });
    } else {
      this.setState({
        showInvoiceDateFilter: true,
      });
    }
  };

  // ---TABLE LOGIC---
  renderTableHeader() {
    const tableHeader = [
      "customer name",
      // "customer code",
      // "Account Manager",
      "Invoice number",
      "invoice amount basic",
      "total invoice amount",
      "net amount collected",
      "invoice date",
      "due date",
      "Action",
    ];
    // let header = Object.keys(tableHeader)
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  renderTableData() {
    const showDetails = (
      id,
      invoice_id,
      cust_id,
      cust_pan,
      invoice_number,
      cust_gstin,
      invoice_date,
      customer_code
    ) => {
      const invoiceNumber = invoice_number.replaceAll("/", "|");
      console.log(cust_gstin);
      this.props.history.push({
        pathname: `/InvoiceDashboardTableDetail`,
        data: [
          `/${invoice_id}/${cust_id}/${
            cust_gstin ? cust_gstin : "null"
          }/${customer_code}/${cust_pan}/${invoiceNumber}/${invoice_date}`,
          this.state.from_date,
          this.state.to_date,
          this.state.invoiceFilter,
        ], // your data array of objects
      });
    };
    if (this.state.dataRawForCustomerInvoiceDetails.length > 0) {
      return this.state.dataRawForCustomerInvoiceDetails.map(
        (tableData, index) => {
          const {
            id,
            account_manager,
            customer_name,
            invoice_amount_basic,
            total_invoice_amount,
            net_amount_collected,
            invoice_date,
            due_date,
            invoice_number,
            invoice_id,
            cust_id,
            cust_gstin,
            customer_code,
            cust_pan,
          } = tableData; //destructuring
          return (
            <tr key={id}>
              <td>{customer_name}</td>
              {/* <td>{customer_code}</td> */}
              {/* <td>{account_manager}</td> */}
              <td>{invoice_number}</td>
              <td>{numberFormat(invoice_amount_basic)}</td>
              <td>{numberFormat(total_invoice_amount)}</td>
              <td>{numberFormat(net_amount_collected)}</td>
              <td>{dateFormat(invoice_date)}</td>
              <td style={{ width: 100 }}>{dateFormat(due_date)}</td>
              <td>
                <VisibilityOutlinedIcon
                  className="action-icon-button showIcon"
                  onClick={() =>
                    showDetails(
                      id,
                      invoice_id,
                      cust_id,
                      cust_pan,
                      invoice_number,
                      cust_gstin,
                      invoice_date,
                      customer_code
                    )
                  }
                />
              </td>
            </tr>
          );
        }
      );
    } else {
      return (
        <tr>
          <td colSpan={8} style={{ color: "red" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  }

  // export to excel
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  exportToExcelForCustomerInvoice = () => {
    let payload = {
      seller_code: localStorage.getItem("business_entity_id"),
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Customer_Invoice_details.xlsx`;

    axios
      .post(`/customer/invoice_summary/download`, payload)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          {/* dashboard header */}
          {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container spacing={3}>
              {this.state.sellerName != null ? (
                <Grid item xs={12}>
                  <Paper
                    className={classes.Paper}
                    style={{
                      padding: "8px",
                      fontSize: "18px",
                      textAlign: "center",
                      boxShadow: "1px 0px 4px #000",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      {this.state.sellerName ? (
                        this.state.sellerName
                      ) : (
                        <CircularProgress size={25} />
                      )}
                      - Invoicing Summary
                    </div>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div> */}

          {/* date filter */}
          <div style={{ marginBottom: "20px" }}>
            <BoxWrapper boxTitle={`Filter`}>
              <ValidatorForm
                ref="form"
                onSubmit={this.filterDateSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={this.state.showInvoiceDateFilter ? 4 : 12}>
                    <div style={{ marginTop: "7px" }}>
                      <SelectField
                        choice_id="invoiceFilter"
                        requiredField=""
                        choice_name="invoiceFilter"
                        SelectLabel="Fiscal Year"
                        name="invoiceFilter"
                        value={this.state.invoiceFilter}
                        textOnchangeFunction={this.handleInvoiceFilter}
                        choices={[
                          {
                            id: 0,
                            invoiceFilter: "Current Year",
                          },
                          {
                            id: 1,
                            invoiceFilter: "Past Year",
                          },
                          {
                            id: 2,
                            invoiceFilter: "Date Filter",
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                  {this.state.showInvoiceDateFilter ? (
                    <>
                      <Grid item xs={3}>
                        <div>
                          <label>
                            From Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            name="from_date_picker"
                            value={this.state.fromDate ?? ""}
                            onChange={(v) => this.handleFromDateChange(v)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <label>
                            To Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            minDateForDatepicker={this.state.fromDate ?? ""}
                            name="to_date_picker"
                            value={this.state.toDate}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            onChange={(v) => this.handleToDateChange(v)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <button
                          type="submit"
                          disabled={this.state.buttonDisabled}
                          style={{
                            width: "100%",
                            marginTop: "28px",
                            padding: "8px",
                          }}
                          className="button-primary"
                        >
                          Submit
                        </button>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </ValidatorForm>
            </BoxWrapper>
          </div>

          {this.state.sellerName !== null ? (
            <>
              {/* ---cards--- */}
              <div>
                {this.state.cardValues.length === 0 ? (
                  "Data not Available for Selected Period"
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Card
                        backgroundcolor="#3b3b6b"
                        cardTitle="Total Invoice(MTD)"
                        cardBody={
                          this.state.cardValues && this.state.cardValues ? (
                            numberFormat(
                              this.state.cardValues.total_invoice_amount_mtd
                            )
                          ) : (
                            <CircularProgress size={25} />
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        backgroundcolor="#4B5D67"
                        cardTitle="Total Invoice(YTD)"
                        cardBody={
                          this.state.cardValues && this.state.cardValues ? (
                            numberFormat(
                              this.state.cardValues.total_invoice_amount_ytd
                            )
                          ) : (
                            <CircularProgress size={25} />
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        backgroundcolor="#d04646"
                        cardTitle="Total invoices(#) YTD"
                        cardBody={
                          this.state.cardValues && this.state.cardValues ? (
                            this.state.cardValues.total_invoices_ytd
                          ) : (
                            <CircularProgress size={25} />
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </div>

              {/* ---chart--- */}
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <BoxWrapper boxTitle={`Month Wise Invoices Count`}>
                      {this.state?.month_wise_total_invoices?.data ? (
                        <BasicBarChart
                          yAxisTitle="Invoice Count"
                          xAxisTitle="Months"
                          data={this.state?.month_wise_total_invoices?.data?.sort(
                            function (a, b) {
                              let monthAndYear1 = moment(a.name).format(
                                "M-YYYY"
                              );
                              let monthAndYear2 = moment(b.name).format(
                                "M-YYYY"
                              );

                              var as = monthAndYear1.split("-");
                              var bs = monthAndYear2.split("-");

                              return as[1] - bs[1] || as[0] - bs[0];
                            }
                          )}
                        />
                      ) : (
                        <div style={{ color: "red" }}>No Data Found!</div>
                      )}
                    </BoxWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <BoxWrapper boxTitle={`Invoice Amounts by Customers`}>
                      {this.state.invoice_amounts_by_customers.data ? (
                        <BarChartRupeesFormat
                          tooltipHeader="account_manager"
                          yAxisTitle="Amounts"
                          xAxisTitle="Customers"
                          data={this.state.invoice_amounts_by_customers.data}
                        />
                      ) : (
                        <div style={{ color: "red" }}>No Data Found!</div>
                      )}
                    </BoxWrapper>
                  </Grid>
                </Grid>
              </div>

              {/* Accounts Receivable Ageing Report [table]*/}
              <div>
                <BoxWrapper
                  boxTitle={`Customer Invoicing Details`}
                  exportToExcelEnable={true}
                  exportToExcelFn={this.exportToExcelForCustomerInvoice}
                  // search input
                  enableSearchInput={true}
                  searchInputPlaceholder="Search..."
                  searchInputOnchange={this.handleSearchInputChange}
                >
                  <div className="responsive-table">
                    <table className="custome-table">
                      <tbody>
                        <tr>{this.renderTableHeader()}</tr>
                        {this.renderTableData()}
                      </tbody>
                    </table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[25, 100, 500, 1000]}
                    component="div"
                    count={this.state.rowCount} //total row count
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </BoxWrapper>
              </div>
            </>
          ) : (
            <div>
              <p style={{ textAlign: "center", padding: "10px" }}>
                {this.state.loading ? (
                  <>
                    {" "}
                    <CircularProgress size={30} />
                    <br></br>
                    Loading,Please wait...
                  </>
                ) : (
                  <span
                    style={{
                      textAlign: "center",
                      color: "red",
                      padding: "10px",
                    }}
                  >
                    Data not Available for Selected Period.
                  </span>
                )}
              </p>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default Dashboard;
