// import React, { useState } from "react";

// import classes from "./Report.module.css";
// import moment from "moment";
// import BoxWrapper from "../../../../../components/ui/boxWrapper";

// function MismatchedInvoice(props) {
//   const { mismatchedInvoiceData } = props;
//   const [mismatchedInvoiceTableData, setMismatchedInvoiceTableData] = useState(
//     mismatchedInvoiceData
//   );
//   const [searchInput, setSearchInput] = useState("");

//   const requestSearch = (event) => {
//     const searchVal = event.target.value;
//     const filteredRows = mismatchedInvoiceData.filter((row) => {
//       return (
//         String(row.GSTIN).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.Vendor_Name)
//           .toLowerCase()
//           .indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.DOC_NUMBER).toLowerCase().indexOf(searchVal.toLowerCase()) >=
//           0 ||
//         String(row.DOC_DATE).toLowerCase().indexOf(searchVal.toLowerCase()) >=
//           0 ||
//         String(row.DOC_TYPE).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
//       );
//     });
//     setMismatchedInvoiceTableData(filteredRows);
//   };

//   const cancelSearch = () => {
//     setSearchInput("");
//     requestSearch(searchInput);
//   };

//   // indian rupeess system---
//   const numberFormat = (value) =>
//     new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumSignificantDigits: 10,
//     }).format(value);
//   // ---End---

//   const mismatchedInvoiceTableBody = () => {
//     if (
//       mismatchedInvoiceTableData &&
//       mismatchedInvoiceTableData.length !== undefined &&
//       mismatchedInvoiceTableData.length >= 1
//     ) {
//       return mismatchedInvoiceTableData.map((tableData, index) => {
//         const {
//           GSTIN,
//           Vendor_Name,
//           DOC_NUMBER,
//           DOC_DATE,
//           DOC_TYPE,
//           Taxable_Value_Books,
//           CGST_Books,
//           SGST_Books,
//           IGST_Books,
//           Cess_Books,
//           Total_Amount_Books,
//           Taxable_Value_GSTR2A,
//           CGST_GSTR2A,
//           SGST_GSTR2A,
//           IGST_GSTR2A,
//           Cess_GSTR2A,
//           Total_Amount_Books_GSTR2A,
//           Taxable_Value_Diff,
//           TaxValue_Diff,
//           Total_Amount_Diff,
//           Remarks,
//         } = tableData;
//         return (
//           <tr>
//             <td>{GSTIN}</td>
//             <td>{Vendor_Name}</td>
//             <td>{DOC_NUMBER}</td>
//             <td>{DOC_DATE}</td>
//             <td>{DOC_TYPE}</td>
//             <td>{numberFormat(Taxable_Value_Books)}</td>
//             <td>{numberFormat(CGST_Books)}</td>
//             <td>{numberFormat(SGST_Books)}</td>
//             <td>{numberFormat(IGST_Books)}</td>
//             <td>{numberFormat(Cess_Books)}</td>
//             <td>{numberFormat(Total_Amount_Books)}</td>
//             <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
//             <td>{numberFormat(CGST_GSTR2A)}</td>
//             <td>{numberFormat(SGST_GSTR2A)}</td>
//             <td>{numberFormat(IGST_GSTR2A)}</td>
//             <td>{numberFormat(Cess_GSTR2A)}</td>
//             <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>
//             <td>{numberFormat(Taxable_Value_Diff)}</td>
//             <td>{numberFormat(TaxValue_Diff)}</td>
//             <td>{numberFormat(Total_Amount_Diff)}</td>
//             <td>{Remarks}</td>
//           </tr>
//         );
//       });
//     } else {
//       return (
//         <tr>
//           <td colSpan={6} style={{ color: "red", textAlign: "left" }}>
//             No Data Found!
//           </td>
//         </tr>
//       );
//     }
//   };

//   return (
//     <>
//       <BoxWrapper
//         boxTitle="Reconciliation Status : Mis-Matched Invoice(s)"
//         // search input
//         enableSearchInput={true}
//         searchInputPlaceholder="Search..."
//         searchInputOnchange={requestSearch}
//       >
//         <div className={classes.ResponsiveTable}>
//           <table className={classes.AllInvoiceReconTableCss}>
//             <tr>
//               <th rowSpan={2}>GSTIN</th>
//               <th rowSpan={2} align="right">
//                 CUSTOMER NAME
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC NUMBER
//               </th>
//               <th rowSpan={2} align="right">
//                 DATE
//               </th>
//               <th rowSpan={2} align="right">
//                 TYPE
//               </th>
//               <th style={{ background: "#ffffb3" }} colSpan={6} align="center">
//                 As per Book
//               </th>
//               <th style={{ background: "#5cd65c" }} colSpan={6} align="center">
//                 As per GSTR2B
//               </th>
//               <th style={{ background: "#ff8533" }} colSpan={3} align="center">
//                 Difference
//               </th>
//               <th style={{ background: "#ffc107" }} rowSpan={2}>
//                 Remarks{" "}
//               </th>
//             </tr>
//             <tr>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TOTAL AMOUNT
//               </th>

//               <th style={{ background: "#5cd65c" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 TOTAL AMOUNT
//               </th>

//               <th style={{ background: "#ff8533" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TAX
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//             </tr>
//             {mismatchedInvoiceTableBody()}
//           </table>
//         </div>
//       </BoxWrapper>
//     </>
//   );
// }

// export default MismatchedInvoice;

import React, { useEffect, useState } from 'react';

import classes from './Report.module.css';
import BoxWrapper from '../../../../../components/ui/boxWrapper';

function MismatchedInvoice({ mismatchedInvoiceData, onTabClick }) {
  useEffect(() => {
    onTabClick(); // Call the onTabClick function when the component mounts
  }, [onTabClick]);

  const invoiceData = mismatchedInvoiceData?.record?.missMatched ?? [];

  const [searchInput, setSearchInput] = useState('');
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

   



  const filteredData = invoiceData.filter((invoice) => {
    // Perform a null check before accessing the 'includes' method
    const gstin = invoice.GSTIN || '';
    const vendorName = invoice.Vendor_Name || '';
  
    return (
      gstin.includes(searchInput) ||
      vendorName.includes(searchInput)
    );
  });

  
  

  return (
    <>
    
      <BoxWrapper
        searchInputPlaceholder="Search..."
        boxTitle="Reconciliation Status: Mis-Matched Invoice(s)"
          
        
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <thead>
              <tr>
                <th rowSpan={2}>GSTIN</th>
                <th rowSpan={2} align="right">
                 CUSTOMER  NAME
                </th>
                <th rowSpan={2} align="right">
                  DOC NUMBER
                </th>
                <th rowSpan={2} align="right">
                 DATE
                </th>
                <th rowSpan={2} align="right">
                   TYPE
                </th>
                <th style={{ background: '#ffcc66' }} colSpan={6} align="center">
                  As per Books
                </th>
                <th style={{ background: '#c6ffb3' }} colSpan={6} align="center">
                  As per GSTR2B
                </th>
                <th style={{ background: '#85c0d4' }} colSpan={3} align="center">
                  DIFFERENCE
                </th>
                <th style={{ background: 'RED' }} rowSpan={2} align="right">
                  REMARKS
                </th>
                
              </tr>
              <tr>
                <th style={{ background: '#ffcc66' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  TOTAL AMOUNT
                </th>

                <th style={{ background: '#c6ffb3' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  TOTAL AMOUNT
                </th>
                <th style={{ background: '#85c0d4' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#85c0d4' }} align="right">
                  TAX
                </th>
                <th style={{ background: '#85c0d4' }} align="right">
                  TOTAL AMOUNT
                </th>
              </tr>
            </thead>

            <tbody>
             
            {filteredData.map((invoice, index) => (
  <tr key={index}>
    <td style={{ color: 'black' }}>{invoice.GSTIN}</td>
    <td style={{ color: 'black' }}>{invoice.Vendor_Name}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_NUMBER}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_DATE}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_TYPE}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_Books}</td>
    <td style={{ color: 'black' }}>{invoice.CGST_Books}</td>
    <td style={{ color: 'black' }}>{invoice.SGST_Books}</td>
    <td style={{ color: 'black' }}>{invoice.IGST_Books}</td>
    <td style={{ color: 'black' }}>{invoice.Cess_Books}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Amount_Books}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.CGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.SGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.IGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Cess_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Amount_Books_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.TaxValue_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Amount_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.Remarks}</td>

  </tr>
))}

            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MismatchedInvoice;



