import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import axios from "../../../../axios-order";
import MonthDatePicker from "../../../../components/ui/monthWiseDatePicker";
import designForTdsReconciliation from "./TdsREconciliationGst2A.module.css";
import classes from "./TdsREconciliationGst2A.module.css";
import TdsDashboardReport from "./sectionWisereport";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function TdsReconciliationGst2a() {
  const [handleFromDate, setHandleFromDate] = useState("");
  const [handleToDate, setHandleToDate] = useState("");
  const [handleFromDateToDisplay, setHandleFromDateToDisplay] = useState(null);
  const [handleToDateToDisplay, setHandleToDateToDisplay] = useState(null);

  const [trigarLoader, setTrigarLoader] = useState(false);
  const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);

  const [tdsHistoryTable, setTdsHistoryTable] = useState(true);
  const [tdsReportHeader, setTdsReportHeader] = useState(false);
  const [tdsHistoryTableReport, setTdsHistoryTableReport] = useState(false);
  const [
    tdsHistoryTableReportSectionWise,
    setTdsHistoryTableReportSectionWise,
  ] = useState(false);

  const [triggerButton, setTriggerButton] = useState(true);
  const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);

  const [tdsReconHistoryData, setTdsReconHistoryData] = useState();
  const [tdsAllHistoryRecord, setTdsAllHistoryRecord] = useState();
  const [tdsLiabilityCalculation, setTdsLiablityCalculation] = useState([]);
  const [tdsDashboardSummary, setTdsDashboardSummary] = useState([]);
  const [dashboardSummaryTableData, setDashboardSummaryTableData] = useState(
    []
  );
  const [sectionWiseReport, setSectionWiseReport] = useState(null);
  const [sectionNameForReport, setSectionNameForReport] = useState("");

  const [allDashboardData, setAllDashboardData] = useState([]);
  const [withoutReversalData, setWithoutReversalData] = useState([]);

  // ...........search.......
  const [historyTableSearchValue, setHistoryTableSearch] = useState("");
  const [tdsReconHistoryDataForSearch, setTdsReconHistoryDataForSearch] =
    useState();

  const [liabilatyCalTableSearchValue, setLiabilatyCalTableSearch] =
    useState("");
  const [
    tdsLiabilityCalculationForSearch,
    setTdsLiabilityCalculationForSearch,
  ] = useState();

  const [tdsProvisionSearch, setTdsProvisionSearch] = useState();

  const [
    dashboardSummaryTableDataFroSearch,
    setDashboardSummaryTableDataFroSearch,
  ] = useState([]);
  const [
    tdsDashboardSummaryTableSearchValue,
    setTdsDashboardSummaryTableSearch,
  ] = useState("");

  const [errorMessageForAsy, setErrorMessageForAsy] = useState("");
  const [closeButtonForBoxWrapper, setCloseButtonForBoxWrapper] =
    useState(false);

  const [loaderForTable, setLoaderForTable] = useState(false);

  // .............use Effect ..........
  const getTdsPayableRecon = () => {
    setLoaderForTable(true);
    axios
      .get(
        `/tds/get_tds_payable_reconciliation/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTable(false);
        setTdsReconHistoryData(res.data.record);
        setTdsReconHistoryDataForSearch(res.data.record);
      })
      .catch(() => setLoaderForTable(false));
  };

  useEffect(() => {
    getTdsPayableRecon();
  }, []);

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  //.............Indian Rupees............
  const indianRupeesFormat = (value) =>
    value
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumSignificantDigits: 10,
        }).format(Number(value)?.toFixed(2))
      : 0;

  const numberToRupees = (num) => {
    return `₹${num.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")}`;
  };

  //.............function To handle Form Date............
  const functionToHandleFromDate = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();

    setHandleFromDate(`${yy}-${(pad + mm).slice(-2)}-01`);
    setHandleFromDateToDisplay(v);
  };

  //..................function To handle To Date...........
  const functionToHandleToDate = (v) => {
    const pad = "00";
    const year = v.getFullYear().toString();
    const month = (v.getMonth() + 1).toString();

    var lastDay = new Date(v.getFullYear(), v.getMonth() + 1, 0);
    const lastDateOfMonth = lastDay.getDate().toString();
    setHandleToDate(`${year}-${(pad + month).slice(-2)}-${lastDateOfMonth}`);
    setHandleToDateToDisplay(v);
  };
  //................Handle Close Event..............
  const handleOpenAndCloseModel = () => {
    setHandleFromDateToDisplay(null);
    setHandleToDateToDisplay(null);
    setTriggerButton(true);
    setTriggerModelFromToDate(false);
    // setTrigarModel(false);
    setTrigarLoader(false);
    setTrigarForErrorMessage(false);
  };

  //.......................Submit Handler...............
  const onSubmitHandlerForData = () => {
    setTrigarForErrorMessage(false);
    setTrigarLoader(true);
    axios
      .post(`/tds_payable_recon`, {
        org_gstin: localStorage.getItem("gst"),
        from_date: handleFromDate,
        to_date: handleToDate,
      })
      .then((res) => {
        if (res.data.status === true) {
          setTrigarLoader(false);
          setTriggerModelFromToDate(false);
          setHandleFromDateToDisplay(null);
          setHandleToDateToDisplay(null);
          getTdsPayableRecon();
          setHandleFromDate("");
          setHandleToDate("");
        } else {
          setTrigarLoader(false);
          setTriggerModelFromToDate(true);
          setTrigarForErrorMessage(true);
        }
      })
      .catch((error) => console.log(error));
  };

  // ...................tds payable history table body.............
  const tdsPayablesHistoryReportData = (id) => {
    setTriggerModelFromToDate(false);
    axios
      .get(`/tds/get_tds_payable_json_output/${id}`)
      .then((res) => {
        if (res.data.record.json_data.status === true) {
          //Edited By Harsh
          setTriggerButton(false);

          setTdsAllHistoryRecord(res.data.record);

          setTdsLiabilityCalculationForSearch(
            res?.data?.record?.json_data?.tds_liablity_calculation
          );
          setTdsLiablityCalculation(
            res?.data?.record?.json_data?.tds_liablity_calculation
          );

          setAllDashboardData(
            res?.data?.record?.json_data?.tds_liablity_dashboard_and_Summary
          );

          setDashboardSummaryTableDataFroSearch(
            res?.data?.record?.json_data?.tds_liablity_dashboard_and_Summary
              ?.first_summary
          );
          setDashboardSummaryTableData(
            res?.data?.record?.json_data?.tds_liablity_dashboard_and_Summary
              ?.first_summary
          );

          setTdsDashboardSummary(
            res?.data?.record?.json_data?.tds_liablity_dashboard_and_Summary
              ?.sec_wise_summary
          );

          setWithoutReversalData(
            res?.data?.record?.json_data?.without_reversal_data
          );
          setTdsProvisionSearch(
            res?.data?.record?.json_data?.without_reversal_data
          );

          setTdsHistoryTable(false);
          setTdsHistoryTableReport(true);
          setTdsReportHeader(true);
        } else {
          setErrorMessageForAsy(res?.data?.record?.json_data?.message);
          setCloseButtonForBoxWrapper(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchInputHandlerForHistoryTable = async (e) => {
    let value = e.target.value;
    let filteredValue = tdsReconHistoryDataForSearch.filter((row) => {
      return (
        row.from_month.includes(value) ||
        row.to_month.includes(value) ||
        row.fiscal_year.includes(value)
      );
    });
    setHistoryTableSearch(value);
    setTdsReconHistoryData(filteredValue);
  };

  const tdsPayablesHistoryTableBody = () => {
    if (loaderForTable) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={5}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (tdsReconHistoryData && tdsReconHistoryData.length !== 0) {
      return tdsReconHistoryData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{indianFormatDate(item.from_month)}</td>
            <td>{indianFormatDate(item.to_month)}</td>
            <td>{item.upload_date}</td>
            <td>{item.status}</td>
            <td style={{ cursor: "pointer" }}>
              {item.status === "Pass" ? (
                <VisibilityOutlinedIcon
                  className="action-icon-button showIcon"
                  onClick={() => tdsPayablesHistoryReportData(item.id)}
                />
              ) : item.status === "Fail" ? (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  onClick={() => tdsPayablesHistoryReportData(item.id)}
                />
              ) : (
                <VisibilityOutlinedIcon color="disabled" />
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={5}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // ......................tds Liablity calculation table body............
  const searchInputHandlerForLiabilityTable = async (e) => {
    let value = e.target.value;
    let filteredValue = tdsLiabilityCalculationForSearch.filter((row) => {
      return (
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.pan.toLowerCase().includes(value.toLowerCase()) ||
        (row.bill_date && row.bill_date.includes(value))
      );
    });
    setLiabilatyCalTableSearch(value);
    setTdsLiablityCalculation(filteredValue);
  };

  //....................tds Provision.....................
  const searchInputHandlerForTdsPayable = async (e) => {
    let valueTdsProvision = e.target.value;
    let filteredValueForTdsProvision = tdsProvisionSearch.filter((row) => {
      return (
        String(row.vendor_legal_name)
          .toLowerCase()
          .indexOf(valueTdsProvision.toLowerCase()) >= 0 ||
        String(row.pan)
          .toLowerCase()
          .indexOf(valueTdsProvision.toLowerCase()) >= 0 ||
        String(row.provision_amount).indexOf(valueTdsProvision) >= 0 ||
        String(row.tds_section)
          .toLowerCase()
          .indexOf(valueTdsProvision.toLowerCase()) >= 0 ||
        String(row.tds_percentage)
          .toLowerCase()
          .indexOf(valueTdsProvision.toLowerCase()) >= 0 ||
        String(row.provision_tds)
          .toLowerCase()
          .indexOf(valueTdsProvision.toLowerCase()) >= 0 ||
        String(
          indianFormatDate(row["provision_create_date"]).indexOf(
            valueTdsProvision
          )
        ) >= 0
      );
    });
    setWithoutReversalData(filteredValueForTdsProvision);
  };

  const tdsLiabilityCalculationTableBody = () => {
    if (tdsLiabilityCalculation && tdsLiabilityCalculation.length !== 0) {
      return tdsLiabilityCalculation.map((item, i) => {
        // console.log(item.tds_payable_per_books);
        return (
          <tr key={i}>
            <td>{item.name}</td>

            <td>{item.pan}</td>

            <td>{item["resident/non_resident"]}</td>

            <td>{item.bill_no}</td>

            <td>{item.bill_date && indianFormatDate(item.bill_date)}</td>

            <td>{item.booking_date && indianFormatDate(item.booking_date)}</td>

            <td>
              {item["taxable+nontaxable"]
                ? indianRupeesFormat(item["taxable+nontaxable"].toFixed(2))
                : indianRupeesFormat(item["taxable+nontaxable"])}
            </td>

            <td>{item.tds_section}</td>

            <td>{item["206AB_applicable"]}</td>

            <td>
              {item.tds_payable_per_books
                ? indianRupeesFormat(item.tds_payable_per_books.toFixed(2))
                : indianRupeesFormat(item.tds_payable_per_books)}
            </td>

            <td>
              {item.ltds_amount
                ? indianRupeesFormat(item.ltds_amount.toFixed(2))
                : indianRupeesFormat(item.ltds_amount)}
            </td>

            {/* <td>{indianRupeesFormat(item.ltds_amount_as_per_certificate)}</td> */}

            <td>
              {item.gross_amount_as_per_provision
                ? indianRupeesFormat(
                    item.gross_amount_as_per_provision.toFixed(2)
                  )
                : indianRupeesFormat(item.gross_amount_as_per_provision)}
            </td>

            <td>
              {item.net_gross_amount
                ? indianRupeesFormat(item.net_gross_amount.toFixed(2))
                : indianRupeesFormat(item.net_gross_amount)}
            </td>

            <td>
              {item.tds_on_provision
                ? indianRupeesFormat(item.tds_on_provision.toFixed(2))
                : indianRupeesFormat(item.tds_on_provision)}
            </td>

            <td>
              {item.net_tds
                ? indianRupeesFormat(item.net_tds.toFixed(2))
                : indianRupeesFormat(item.net_tds)}
            </td>
            <td>{item.ltds_amount && indianRupeesFormat(item.ltds_amount)}</td>

            <td>{item.ltds_percent}</td>

            <td>{item["company/non_company"]}</td>
            <td>{item.tds_percent_per_cws_tool}</td>

            <td>
              {item.tds_payable_amount_per_cws
                ? indianRupeesFormat(item.tds_payable_amount_per_cws.toFixed(2))
                : indianRupeesFormat(item.tds_payable_amount_per_cws)}
            </td>

            <td>
              {item.difference_in_value
                ? indianRupeesFormat(item.difference_in_value.toFixed(2))
                : indianRupeesFormat(item.difference_in_value)}
            </td>

            <td>{item.criteria}</td>
            <td>{item.remarks}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={23}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  const tdsProvision = () => {
    if (withoutReversalData && withoutReversalData.length !== 0) {
      return withoutReversalData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vendor_legal_name}</td>
            <td>{item.pan}</td>
            <td>{indianFormatDate(item["provision_create_date"])}</td>
            <td>{indianRupeesFormat(item.provision_amount)}</td>
            <td>{item.tds_section}</td>
            <td>{item.tds_percentage}</td>
            <td>
              {item["provision_tds"]
                ? indianRupeesFormat(item["provision_tds"].toFixed(2))
                : indianRupeesFormat(item["provision_tds"])}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={7}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // .............tds dashboard and summary table body................

  const tdsSectionWiseReportData = (sectionName, companyOrNonCompany) => {
    setTdsHistoryTableReportSectionWise(true);
    setTdsHistoryTableReport(false);
    setSectionNameForReport(sectionName);
    console.log(companyOrNonCompany);
    tdsDashboardSummary.map((item, i) => {
      let section = Object.keys(item);
      console.log(section);
      if (section[0] === sectionName) {
        if (companyOrNonCompany.toLowerCase() == "company") {
          console.log(item[sectionName].company);
          setSectionWiseReport(item[sectionName].company);
        } else {
          console.log(item[sectionName]["Non-Company"]);
          setSectionWiseReport(item[sectionName]["Non-Company"]);
        }
      }
    });
  };

  const searchInputHandlerForDashboardSummaryTable = async (e) => {
    let value = e.target.value;
    let filteredValue = dashboardSummaryTableDataFroSearch.filter((row) => {
      return (
        (row.section || "").toLowerCase().includes(value.toLowerCase()) ||
        row["company/non_company"].toLowerCase().includes(value.toLowerCase())
      );
    });
    setTdsDashboardSummaryTableSearch(value);
    setDashboardSummaryTableData(filteredValue);
  };

  const tdsDashboardSummaryTableBody = () => {
    if (dashboardSummaryTableData && dashboardSummaryTableData.length !== 0) {
      return dashboardSummaryTableData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.section}</td>
            <td>{item["company/non_company"]}</td>
            <td>
              {item.gross_amount
                ? indianRupeesFormat(item.gross_amount.toFixed(2))
                : indianRupeesFormat(item.gross_amount)}
            </td>

            <td>
              {item.tds_payables
                ? indianRupeesFormat(item.tds_payables.toFixed(2))
                : indianRupeesFormat(item.tds_payables)}
            </td>
            <td
              style={{ cursor: "pointer" }}
              onClick={() =>
                tdsSectionWiseReportData(
                  item.section,
                  item["company/non_company"]
                )
              }
            >
              <VisibilityIcon className="action-icon-button showIcon" />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={23}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  //............................Base64File.......................
  const downloadBase64FileForTdsHistory = (
    contentType,
    base64Data,
    fileName
  ) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcelTdsHistory = () => {
    let finalData = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      record: tdsAllHistoryRecord,
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `TDS Payable Reconciliation Report.xlsx`;

    axios
      .post(`/vendor/tdspayable_history/extract`, finalData)
      .then((res) => {
        downloadBase64FileForTdsHistory(contentType, res.data.file, filename);
      })
      .catch((error) => console.log(error));
  };

  // .......................back button hanlder..................
  const backToMainHistoryTable = () => {
    setTdsHistoryTable(true);
    setTdsHistoryTableReport(false);
    setTdsReportHeader(false);
    setTriggerButton(true);
  };

  const backToMainHistoryReport = () => {
    setTdsHistoryTableReport(true);
    setTdsHistoryTableReportSectionWise(false);
  };
  const handleTDSREconciliationButtonEvent = () => {
    setTriggerModelFromToDate(true);
  };

  const refreshUploadHistoryTable = () => {
    getTdsPayableRecon();
  };

  const FunForcloseBoxWapper = () => {
    setCloseButtonForBoxWrapper(false);
  };
  return (
    <React.Fragment>
      {triggerButton && (
        <div>
          <button
            className="button-outlined-primary"
            onClick={handleTDSREconciliationButtonEvent}
          >
            New TDS Reconciliation
          </button>
        </div>
      )}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={triggerModelFromToDate}
        onHide={handleOpenAndCloseModel}
        dialogClassName="modal-50w"
      >
        <Modal.Header closeButton>
          <Modal.Title>TDS Reconciliation</Modal.Title>
        </Modal.Header>
        <ValidatorForm
          useRef="form"
          onSubmit={onSubmitHandlerForData}
          onError={(errors) => console.log(errors)}
        >
          <Modal.Body>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                  From Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div>
                  <MonthDatePicker
                    placeholder="Month & Year"
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={handleFromDateToDisplay}
                    onChange={(v) => functionToHandleFromDate(v)}
                  />
                </div>
              </Grid>

              <Grid item sm={2}>
                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                  To Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <div>
                  <MonthDatePicker
                    minDateForDatepicker={handleFromDateToDisplay}
                    placeholder="Month & Year"
                    // minDate={handleFromDateToDisplay}
                    errorMessages={["This field is required"]}
                    validators={["required"]}
                    value={handleToDateToDisplay}
                    onChange={(v) => functionToHandleToDate(v)}
                  />
                </div>
              </Grid>
            </Grid>
            {trigarLoader ? (
              <div
                style={{ fontWeight: "600", color: "green", padding: "5px" }}
              >
                Please wait recon is in progress
              </div>
            ) : (
              ""
            )}
            {trigarForErrorMessage && (
              <div style={{ color: "red", fontWeight: "700" }}>
                No Records Present!
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={handleOpenAndCloseModel}
            >
              Cancel
            </button>
          </Modal.Footer>
        </ValidatorForm>
      </Modal>

      {closeButtonForBoxWrapper && (
        <BoxWrapper
          boxTitle="Error"
          cutIcon={true}
          children={errorMessageForAsy}
          closeBoxWapper={FunForcloseBoxWapper}
          colorForMessage="red"
          ErrorIcon={<ErrorOutlineOutlinedIcon />}
        />
      )}

      {tdsHistoryTable && (
        <BoxWrapper
          boxTitle="TDS Payable History"
          // add button
          enableAddButton={true}
          addButtonLabel="Refresh"
          addButtonHandlerFn={refreshUploadHistoryTable}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={searchInputHandlerForHistoryTable}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Recon Date</th>
                  <th>Status</th>
                  <th>Report</th>
                </tr>
                {tdsPayablesHistoryTableBody()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      )}

      {tdsReportHeader && (
        <>
          {/* ---page header--- */}
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {tdsHistoryTableReport ? (
                <button
                  type="button"
                  className="button-secondary"
                  onClick={backToMainHistoryTable}
                >
                  Back
                </button>
              ) : (
                <button
                  type="button"
                  className="button-secondary"
                  onClick={backToMainHistoryReport}
                >
                  Back
                </button>
              )}
            </Grid>

            <Grid item xs={6}>
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "16px" }}>
                  TDS Payable Reconciliation Report{" "}
                </p>
              </div>
            </Grid>

            <Grid item xs={3}>
              <div style={{ textAlign: "right" }}>
                {tdsHistoryTableReport && (
                  <button
                    className="button-primary"
                    type="button"
                    onClick={exportToExcelTdsHistory}
                  >
                    Export to Excel
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* .............tds payable history report........... */}
      {tdsHistoryTableReport && (
        <>
          {/* ............tds Dashboard and summmary table........ */}
          {dashboardSummaryTableData && (
            <BoxWrapper
              boxTitle=" TDS Dashboard and Summary"
              // search input
              enableSearchInput={true}
              searchInputPlaceholder="Search..."
              searchInputOnchange={searchInputHandlerForDashboardSummaryTable}
            >
              <div className="responsive-table">
                <table className="custome-two-row-header-short-table">
                  <tbody>
                    <tr>
                      <th rowSpan={2} style={{ background: "#C4D0D9" }}>
                        Section
                      </th>
                      <th rowSpan={2} style={{ background: "#C4D0D9" }}>
                        Company/ Non-Company
                      </th>
                      <th colSpan={2} style={{ background: "#C4D0D9" }}>
                        TDS Working
                      </th>

                      <th rowSpan={2} style={{ background: "#C4D0D9" }}>
                        Details
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: "#C4D0D9" }}>Gross Amount</th>
                      <th style={{ background: "#C4D0D9" }}>TDS Payables</th>
                    </tr>
                    {tdsDashboardSummaryTableBody()}
                    <tr>
                      <td colSpan={2}>Net total</td>
                      <td>
                        {dashboardSummaryTableData.length === 0 && ""}
                        {dashboardSummaryTableData.length > 0 &&
                          tdsAllHistoryRecord &&
                          indianRupeesFormat(
                            allDashboardData.total_net_gross_amount
                          )}
                      </td>
                      <td>
                        {dashboardSummaryTableData.length === 0 && ""}
                        {dashboardSummaryTableData.length > 0 &&
                          tdsAllHistoryRecord &&
                          indianRupeesFormat(allDashboardData.total_net_tds)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          )}

          {/* .........tds payables liability calculation table......... */}
          {tdsLiabilityCalculation && (
            <BoxWrapper
              boxTitle=" TDS liability Calculation"
              // search input
              enableSearchInput={true}
              searchInputPlaceholder="Search..."
              searchInputOnchange={searchInputHandlerForLiabilityTable}
            >
              <div className="responsive-table">
                <table className="custome-two-row-header-table">
                  <tbody>
                    <tr>
                      <th
                        style={{ background: "yellow", width: "300px" }}
                        colSpan={11}
                      >
                        As Per Books
                      </th>
                      <th
                        style={{ background: "#f0ba42", width: "100px" }}
                        colSpan={4}
                      >
                        Provisions/Reversal Tracker
                      </th>
                      <th
                        style={{ background: "#007bff", width: "100px" }}
                        colSpan={7}
                      >
                        CWS
                      </th>
                      <th
                        style={{ background: "#ebc668", width: "50px" }}
                        rowSpan={2}
                      >
                        Remarks
                      </th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>PAN</th>
                      <th>Resident/Non Resident</th>
                      <th>Bill No</th>
                      <th>Bill Date</th>
                      <th>Booking/recording date</th>
                      <th>Taxable + Non Taxable Amount (excluding GST/CESS)</th>
                      <th>TDS Section</th>
                      <th>206AB applicable - Y/N</th>
                      <th>TDS Payable per books</th>
                      <th>Net LTDS</th>
                      {/* <th>LTDS Amount As Per Certificate</th> */}

                      <th>Gross amount as per provision</th>
                      <th>Net Gross Amount</th>
                      <th>TDS On Provision</th>
                      <th>Net TDS</th>

                      <th>LTDS Amount</th>
                      <th>LTDS%</th>

                      <th>Company/Non Company</th>
                      <th>TDS % per CWS tool</th>
                      <th>TDS Payable Amount as per CWS</th>
                      <th>Difference in Value</th>
                      <th>Criteria</th>
                    </tr>
                    {tdsLiabilityCalculationTableBody()}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          )}

          {/* .........tds payables Provision......... */}
          {withoutReversalData && (
            <div>
              <BoxWrapper
                boxTitle="Provision"
                // search input
                enableSearchInput={true}
                searchInputPlaceholder="Search..."
                searchInputOnchange={searchInputHandlerForTdsPayable}
              >
                <div className="responsive-table">
                  <table className="custome-table">
                    <tbody>
                      <tr>
                        <th>Vendor Legal Name</th>
                        <th>PAN</th>
                        <th>Provision Created Date</th>
                        <th>Provision Amount</th>
                        <th>TDS Section</th>
                        <th>TDS %</th>
                        <th>TDS Payable</th>
                      </tr>
                      {tdsProvision()}
                    </tbody>
                  </table>
                </div>
              </BoxWrapper>
            </div>
          )}
        </>
      )}

      {tdsHistoryTableReportSectionWise && (
        <>
          {/* ............report page ........... */}
          {sectionWiseReport && (
            <TdsDashboardReport
              reportData={sectionWiseReport}
              sectionNameForReportPage={sectionNameForReport}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
