import React, { useEffect, useState } from "react";
import axios from "../../axios-order";
import BoxWrapper from "../../components/ui/boxWrapper";

function AisViewData() {
  const [data, setData] = useState(null);
  const [financialYears, setFinancialYears] = useState([]);
  const id = localStorage.getItem("aisid");
  const business_entity_id = localStorage.getItem("business_entity_id");

  useEffect(() => {
    const fetchData = async () => {
      if (id && business_entity_id) {
        axios
          .post(`/ais/dashboard`, {
            id: id,
            business_entity_id: business_entity_id,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            setData(response.data.records);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        console.error("IDs not found in localStorage");
      }
    };

    fetchData();
  }, [id, business_entity_id]);

  const salesGstData = data?.Sales?.["Sales as per GST"];
  const salesTdsData = data?.Sales?.["Sales as per TDS"];
  const purchasesData = data?.Purchases?.["Purchases as per GST"];
  const salesGstVsTdsBooks =
    data?.Sales?.["GST VS TDS Sales as Per Books"]?.["Total Sales"];
  const salesGstVsTdsAis =
    data?.Sales?.["GST VS TDS Sales as Per AIS Report"]?.["Total Sales"];

  useEffect(() => {
    const storedFinancialYears = localStorage.getItem("financialYears");
    if (storedFinancialYears) {
      setFinancialYears(JSON.parse(storedFinancialYears));
    }
  }, []);

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleDownload = async () => {
    const business_entity_id = localStorage.getItem("business_entity_id");
    const id = localStorage.getItem("aisid");
    const storedFinancialYears = localStorage.getItem("financialYears");

    if (!business_entity_id || !id || !storedFinancialYears) {
      console.error("Required ID(s) are missing in localStorage");
      return;
    }

    const financialYearsArray = JSON.parse(storedFinancialYears);

    const financial_year =
      financialYearsArray.length > 0 ? financialYearsArray[0] : "";

    const payload = {
      business_entity_id,
      id,
      financial_year,
    };

    axios
      .post("/ais/download_file", payload)
      .then((response) => {
        const contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const base64Data = response.data.file;

        if (base64Data) {
          downloadBase64File(contentType, base64Data, "AisReport.xlsx");
        } else {
          console.error("Base64 data is missing in the response");
        }
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <div>
      <BoxWrapper
        boxTitle="AIS Details"
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        enableAddButton={true}
        addButtonLabel="Export"
        addButtonHandlerFn={handleDownload}
      >
        {salesGstData && (
          <>
            <style>
              {`
                .sales-table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-top: 20px;
                }

                .sales-table th, .sales-table td {
                  border: 1px solid #ddd;
                  padding: 12px;
                  text-align: left;
                }

                .sales-table th {
                  background-color: #f2f2f2;
                  color: #333;
                }

                .sales-table tr:hover {
                  background-color: #f1f1f1;
                }

                .sales-table tr:nth-child(even) {
                  background-color: #f9f9f9;
                }

                .sales-table tr:nth-child(odd) {
                  background-color: #ffffff;
                }
              `}
            </style>

            <h3>Sale</h3>
            <table className="sales-table">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    style={{ backgroundColor: "#185785", color: "white" ,textAlign:'center'}}
                  >
                    {" "}
                    GST vs TDS Sales as Per AIS Report
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>As Per GST</th>
                  <th>As Per TDS</th>
                  <th> Difference </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>{salesGstVsTdsAis?.["As Per GST"]}</td>
                  <td>{salesGstVsTdsAis?.["As Per TDS"]}</td>
                  <td>{salesGstVsTdsAis?.["Difference"]}</td>
                </tr>
              </tbody>
            </table>

            <table className="sales-table">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    style={{ backgroundColor: "#185785", color: "white",textAlign:'center' }}
                  >
                    GST vs TDS Sales as Per Books
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>As Per GST</th>
                  <th>As Per TDS</th>
                  <th> Difference </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>{salesGstVsTdsBooks?.["As Per GST"]}</td>
                  <td>{salesGstVsTdsBooks?.["As Per TDS"]}</td>
                  <td>{salesGstVsTdsBooks?.["Difference"]}</td>
                </tr>
              </tbody>
            </table>

            <table className="sales-table">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    style={{ backgroundColor: "#185785", color: "white",textAlign:'center' }}
                  >
                    Sales as per GST
                  </th>
                </tr>
                <tr>
                  <th></th>

                  <th>As Per Books</th>
                  <th>As Per AIS</th>
                  <th>Difference</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Taxable</td>

                  <td>
                    {
                      salesGstData["GST Turnover"]["As Per Books"][
                        "Total Taxable"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesGstData["GST Turnover"]["As Per AIS"][
                        "Total Taxable"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesGstData["GST Turnover"]["Difference"][
                        "Taxable Difference"
                      ]
                    }
                  </td>
                </tr>
                <tr>
                  <td>Total Turnover</td>

                  <td>
                    {
                      salesGstData["GST Turnover"]["As Per Books"][
                        "Total Turnover"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesGstData["GST Turnover"]["As Per AIS"][
                        "Total Turnover"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesGstData["GST Turnover"]["Difference"][
                        "Turnover Difference"
                      ]
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {salesTdsData && (
          <>
            <table className="sales-table">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    style={{ backgroundColor: "#185785", color: "white",textAlign:'center' }}
                  >
                    Sales as per TDS
                  </th>
                </tr>
                <tr>
                  <th></th>

                  <th>As Per Books</th>
                  <th>As Per AIS</th>
                  <th>Difference</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["As Per Books"][
                        "Total Sales"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["As Per AIS"][
                        "Total Sales"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["Difference"][
                        "Sales Difference"
                      ]
                    }
                  </td>
                </tr>
                <tr>
                  <td>TDS Collected</td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["As Per Books"][
                        "TDS Collected"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["As Per AIS"][
                        "TDS Collected"
                      ]
                    }
                  </td>
                  <td>
                    {
                      salesTdsData["Sales As Per TDS"]["Difference"][
                        "Collected Difference"
                      ]
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {purchasesData && (
          <>
            <h3>Purchase </h3>
            <table className="sales-table">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    style={{ backgroundColor: "#185785", color: "white",textAlign:'center' }}
                  >
                    {" "}
                    Purchases as per GST
                  </th>
                </tr>
                <tr>
                  <th></th>
              
                  <th>As Per Books</th>
                  <th>As Per AIS</th>
                  <th>Difference</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Purchases</td>
                  <td>{purchasesData["Total Purchases"]["As Per Books"]}</td>
                  <td>{purchasesData["Total Purchases"]["As Per AIS"]}</td>
                  <td>{purchasesData["Total Purchases"]["Difference"]}</td>
                </tr>
               
              </tbody>
            </table>
          </>
        )}
      </BoxWrapper>
    </div>
  );
}

export default AisViewData;
