import React, { Component } from "react";
import classes from "./addNewUser.module.css";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../../components/appBar/appBar";

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class addNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEntityUsers: [],
      business_entity_id: localStorage.getItem("business_entity_id"),
    };
  }

  componentDidMount = () => {};

  backHanlder = () => {
    this.props.history.push(`/businessEntityManagement/assignUser`);
  };
  render() {
    return (
      <>
        <Appbar />
        <div className={classes.addNewUserSection}>
          <Button variant="contained" size="small" onClick={this.backHanlder}>
            back
          </Button>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(addNewUser);
