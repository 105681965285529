import React, { useEffect, useState } from "react";
import design from "./controlReport.module.css";
import axios from "../../../../axios-order";
import {
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import BoxWapper from "../../../../components/ui/boxWrapper";
import CardForDispaly from "../../../../components/ui/Cards";

export default function ControlReport() {
  const [invalidCrdrNote, setInvalidCrdrNote] = useState([]);
  const [invalidCrdrNoteSearch, setInvalidCrdrNoteSearch] = useState([]);
  const [loader, setLoader] = useState(false);

  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  // indian date format
  const date = () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    return formattedDate;
  };

  // table header
  const tableHead = () => {
    const tableHeadData1 = [
      "Financial Year",
      "Legal Name",
      "GSTIN",
      "PAN",
      "Original Invoice No",
      "Original Invoice Date",
      "Credit/Debit Note No",
      "Credit/Debit Note Date",
      "HSN/SAC Code",
      "Taxable Value",
      "Non Taxable Value",
      "GST Tax Rate",
      "SGST",
      "CGST",
      "IGST",
      "CESS",
      "Tax Amount",
      "Total Amount",
      "Debit/Credit Note Reason",
      "ERP Customer Code",
    ];
    return tableHeadData1.map((v, i) => {
      return <th key={i}>{v}</th>;
    });
  };

  // table search
  const SearchEvent = (e) => {
    let Seach = e.target.value;
    const seachName = invalidCrdrNoteSearch.filter((v) => {
      return (
        String(v.financialYear).toLowerCase().indexOf(Seach.toLowerCase()) >=
          0 ||
        String(v.cust_name).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.cust_gstin).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.cust_pan).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.invoice_date).toLowerCase().indexOf(Seach.toLowerCase()) >=
          0 ||
        String(v.invoice_no).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.credit_note_no).toLowerCase().indexOf(Seach.toLowerCase()) >= 0
      );
    });
    setInvalidCrdrNote(seachName);
  };

  // export to excel/download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Invalid Credit/Debit Note Under GST Act.xlsx`;
    axios
      .post(`/seller_invalid_cr_dr_under_gstAct_control_report/download`, {
        invalid_cr_or_dr_under_gst_act: invalidCrdrNote,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert("Unable to download check your internet or try again");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetApiForInvoiceCrDrNoteInSales();
  }, []);

  // api call
  const GetApiForInvoiceCrDrNoteInSales = () => {
    setLoader(true);
    axios
      .get(
        `/get_seller_first_control_report/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoader(false);
        setInvalidCrdrNote(res.data);
        setInvalidCrdrNoteSearch(res.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {/* page header */}
      {/* <div className={design.heading} style={{ backgroundColor: "#ddd" }}>
        Invalid Credit/Debit Note Under GST Act{" "}
      </div> */}

      {/* basic info in card */}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#3b3b6b"
            cardTitle="Name"
            cardBody={localStorage.getItem("entity_name")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#4B5D67"
            cardTitle="GSTIN"
            cardBody={localStorage.getItem("gst")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#d04646"
            cardTitle="Report Generated On"
            cardBody={date()}
          />
        </Grid>
      </Grid>

      {/* table box/container */}
      <BoxWapper
        boxTitle="Debit or Credit note raised in connection with original
                  invoice of previous financial year should not exceed 30th Nov
                  of the current year"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={SearchEvent}
        // export to excel
        exportToExcelEnable={true}
        exportToExcelFn={export_to_excel}
      >
        <div className="responsive-table">
          <table className="custome-long-table">
            <thead>
              <tr>{tableHead()}</tr>
            </thead>
            <tbody>
              {invalidCrdrNote.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{v.financialYear}</td>
                    <td>{v.cust_name}</td>
                    <td>{v.cust_gstin}</td>
                    <td>{v.cust_pan}</td>
                    <td>{v.invoice_no}</td>
                    <td>{v.invoice_date}</td>
                    <td>{v.credit_note_no}</td>
                    <td>{v.credit_note_date}</td>
                    <td>{v.hsn_sac_code}</td>
                    <td>{indianRupeesFormat(v.taxable_value)}</td>
                    <td>{indianRupeesFormat(v.non_taxable_adj)}</td>
                    <td>{v.gst_tax_rate}</td>
                    <td>{indianRupeesFormat(v.sgst)}</td>
                    <td>{indianRupeesFormat(v.cgst)}</td>
                    <td>{indianRupeesFormat(v.igst)}</td>
                    <td>{indianRupeesFormat(v.cess)}</td>
                    <td>{indianRupeesFormat(v.tax_amount)}</td>
                    <td>{indianRupeesFormat(v.total_amount)}</td>
                    <td>{v.credit_note_reason}</td>
                    <td>{v.cust_code}</td>
                  </tr>
                );
              })}
              {loader ? (
                <tr>
                  <td
                    colSpan={"7"}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading...Please Wait
                  </td>
                </tr>
              ) : invalidCrdrNote.length === 0 ? (
                <tr>
                  <td
                    colSpan={"7"}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWapper>
    </React.Fragment>
  );
}
