import React, { useState } from "react";
import "../TDSRecon.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MYSummary(props) {
  const { summaryData } = props;
  // const [fiscalYearData, setfiscalYearData] = useState(Object.keys(summaryData[0].financialYearData).map(item => (item)))
  const fiscalYData = Object.keys(
    summaryData.find((i) => Object.keys(i.financialYearData).length > 1)
      .financialYearData
  ).map((item) => item);

  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(summaryData);

  // indian rupeess system---
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const handleTableSearch = (event) => {
    setSearch(event.target.value);
    let searchData = summaryData.filter((item) => {
      const query = event.target.value.toLowerCase();
      return (
        (item.customer_gstin &&
          item.customer_gstin.toLowerCase().includes(query)) ||
        (item.customer_name &&
          item.customer_name.toLowerCase().includes(query)) ||
        (item.customer_tan && item.customer_tan.toLowerCase().includes(query))
      );
    });
    setTableData(searchData);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Summary" // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleTableSearch}
      >
        <div className="responsive-table">
          <table className="revenue-report-tab-customer-data-table-css">
            <thead>
              <tr>
                <th colSpan={3}>Customer Data</th>
                {fiscalYData.map((item, index) => {
                  return (
                    <th colSpan={3} style={{ background: "#FFEF78" }}>
                      FY {item}
                    </th>
                  );
                })}

                <th rowSpan={2}>Adjustment</th>
              </tr>

              <tr>
                <th>Customer PAN</th>
                <th>Customer Name</th>
                <th>Customer TAN</th>
                {fiscalYData.map((item, index) => {
                  return (
                    <>
                      <th>TOTAL-TDS ( AS PER BOOKS )</th>
                      <th>TOTAL-TDS DEDUCTED (PER 26AS)</th>
                      <th>TOTAL TDS-DIFFERENCE</th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr>
                    <td>{item.customer_pan}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.customer_tan}</td>
                    {fiscalYData.map((v, i) => {
                      if (item.financialYearData[v]) {
                        return (
                          <>
                            <td>
                              {indianRupeesFormat(
                                item.financialYearData[v]
                                  .total_tds_payment_advice
                              )}
                            </td>
                            <td>
                              {indianRupeesFormat(
                                item.financialYearData[v].tds_deducted_form_26
                              )}
                            </td>

                            <td>
                              {indianRupeesFormat(
                                item.financialYearData[v].total_tds_difference
                              )}
                            </td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td>{indianRupeesFormat(0)}</td>
                            <td>{indianRupeesFormat(0)}</td>
                            <td>{indianRupeesFormat(0)}</td>
                          </>
                        );
                      }
                    })}
                    <td>{indianRupeesFormat(item.adjustment)}</td>
                  </tr>
                );
              })}

              {tableData.length === 0 && (
                <tr>
                  <td
                    colSpan={10}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MYSummary;
