import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import classes from "./Report.module.css";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MatchedInvoice(props) {
  const { matchedInvoiceData } = props;
  const [matchedInvoiceTableData, setMatchedInvoiceTableData] =
    useState(matchedInvoiceData);

  // indian date format
  const indianFormat = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const matchedInvoiceTableBody = () => {
    if (matchedInvoiceTableData.length >= 1) {
      return matchedInvoiceTableData.map((tableData, index) => {
        const {
          GSTIN,
          Vendor_Name,
          DOC_NUMBER,
          DOC_DATE,
          DOC_TYPE,

          Taxable_Value_Books,
          CGST_Books,
          SGST_Books,
          IGST_Books,
          Cess_Books,
          Total_Amount_Books,
          Taxable_Value_GSTR2A,
          CGST_GSTR2A,
          SGST_GSTR2A,
          IGST_GSTR2A,
          Cess_GSTR2A,
          Total_Amount_Books_GSTR2A,
        } = tableData;

        return (
          <tr>
            <td>{GSTIN}</td>
            <td>{Vendor_Name}</td>
            <td>{DOC_NUMBER}</td>
            <td>{indianFormat(DOC_DATE)}</td>
            <td>{DOC_TYPE}</td>
            <td>{numberFormat(Taxable_Value_Books)}</td>
            <td>{numberFormat(CGST_Books)}</td>
            <td>{numberFormat(SGST_Books)}</td>
            <td>{numberFormat(IGST_Books)}</td>
            <td>{numberFormat(Cess_Books)}</td>
            <td>{numberFormat(Total_Amount_Books)}</td>

            <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
            <td>{numberFormat(CGST_GSTR2A)}</td>
            <td>{numberFormat(SGST_GSTR2A)}</td>
            <td>{numberFormat(IGST_GSTR2A)}</td>
            <td>{numberFormat(Cess_GSTR2A)}</td>
            <td>{numberFormat(Total_Amount_Books_GSTR2A)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };
  const searchInputHandler = (event) => {
    let searchInput = event.target.value;
    let searchData = matchedInvoiceData.filter((value) => {
      const query = searchInput.toLowerCase();
      return (
        String(value.GSTIN).toLowerCase().indexOf(query) >= 0 ||
        String(value.Vendor_Name).toLowerCase().indexOf(query) >= 0 ||
        String(value.DOC_NUMBER).toLowerCase().indexOf(query) >= 0 ||
        String(value.DOC_DATE).toLowerCase().indexOf(query) >= 0 ||
        String(value.DOC_TYPE).toLowerCase().indexOf(query) >= 0
      );
    });
    setMatchedInvoiceTableData(searchData);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status :Matched Invoice(s)"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={searchInputHandler}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                VENDOR NAME
              </th>
              <th rowSpan={2} align="right">
                DOC NUMBER
              </th>
              <th rowSpan={2} align="right">
                DOC DATE
              </th>
              <th rowSpan={2} align="right">
                DOC TYPE
              </th>
              <th style={{ background: "#ffcc66" }} colSpan={6} align="center">
                As per Books
              </th>
              <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
                As per GSTR1
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffcc66" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#c6ffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {matchedInvoiceTableBody()}
          </table>
        </div>
      </BoxWrapper>

      <TableContainer component={Paper}></TableContainer>
    </>
  );
}

export default MatchedInvoice;
