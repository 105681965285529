


import {  Button } from "@mui/material"; 
import KarzaModal from "../ui/basicModal";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../axios-order";
import React, { useState, useEffect ,useRef} from 'react';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Grid } from "@mui/material";

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const TrialModal = ({ open, onClose }) => {
    const formRef = useRef(null);
      const [selectedEntities, setSelectedEntities] = useState([]);
       const [entityData, setEntityData] = useState([]);
      const [selectedrcmstatus, setSelectedrcmstatus] = useState('');

      useEffect(() => {
        // Fetch data from the first API
        axios.get('/get_admin_home_stats')
          .then(response => {
            const entityNames = response.data.List_of_Business_entities_with_Services_assigned;
            setEntityData(entityNames);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);

      const handleRCMStatusChange = (event) => {
          setSelectedrcmstatus(event.target.value)
      }

     

    const handleFormSubmit = () => {
      // Extract an array of selected business_entity_ids from the selected entities and convert them to strings
      const selectedEntityIds = selectedEntities.map(entity => String(entity.business_entity_id));

      if (selectedEntityIds.length > 0) {
          // Make the API call with the selected business_entity_ids
          const apiUrl1 = `${apiUrl}/cwsapims/api/v1/report-config`;

          axios.post(apiUrl1, {
            customKey: selectedrcmstatus,
            customValue: selectedEntityIds // Sending an array of selected IDs as strings
          })
          .then(response => {
              // Handle success response
          })
          .catch(error => {
              // Handle error
          });
      } else {
          console.error('No selected entities to submit.');
      }
  }

  
    
      

      return (
        <div>

  <KarzaModal
      title="REPORT CONFIGURATION"
      open={open}
      onClose={onClose}
      modalBody={
          <ValidatorForm
          ref={formRef}
              onSubmit={handleFormSubmit}
              onError={(errors) => console.log(errors)}
          >
              <Grid container spacing={2}>
                  <Grid item xs={6}>
                      <Autocomplete
                          multiple
                          id="entity-names-autocomplete"
                          value={selectedEntities}
                          onChange={(event, newValue) => setSelectedEntities(newValue)}
                          options={entityData}
                          disableCloseOnSelect
                          // getOptionLabel={(option) => option}
                          getOptionLabel={(option) => option.entity_name}
                          renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                  />
                                  {/* {option} */}
                                  {option.entity_name}
                              </li>
                          )}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                              <TextField {...params} label="Entity Names" placeholder="Select entities" />
                          )}
                      />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <FormControl style={{ width: 200 }}>
                          <InputLabel>Custom Configuration</InputLabel>
                          <Select value={selectedrcmstatus} onChange={handleRCMStatusChange}>
                              <MenuItem value="rcm-status">RCM Customize</MenuItem>

                          </Select>
                      </FormControl>
                  </Grid>
              </Grid>
              <div style={{ textAlign: "right", marginTop: "1rem" }}>
                  <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                      // disabled={karzaLoader}
                  >
                      {/* {karzaLoader ? "Submitting..." : "Submit"} */}
                      Submit
                  </Button>
                  <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={onClose}
                      style={{ marginLeft: 8 }}
                  >
                      Close
                  </Button>
              </div>
          </ValidatorForm>
      }
      enableFooter={false}
  />
 </div>

      );
  };

export default TrialModal;

