import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import InfoIcon from "@mui/icons-material/Info";
import BoxWrapper from "./boxWrapper";
import GenerateReport from "./GenerateReport";
import axios from "../../axios-order";
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from "react-modal";
import ReportSync from "./ReportSync";
import ReportInfo from "./ReportInfo";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TablePagination from "@mui/material/TablePagination";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from '@mui/material/IconButton';
import Email from "./Email";
import Divider from "@mui/material/Divider";

const customModalStyles = {
  content: {
    width: "200px",
    height: "40px",
    margin: "470px",
    paddingLeft: "50px",
    backgroundColor: "#ed3434",
    overflow: "hidden",
    left: "90px",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//getting the api url from env file and converting to string
const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;


export default function ReportingLogs({ errorMessage }) {
  const [openGenerateReportPage, setOpenGenerateReportPage] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isZohoModelOpen, setIsZohoModelOpen] = useState(false);
  const [isReportInfoOpen, setReportInfoOpen] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [openZohoDrawer, setOpenZohoDrawer] = useState(false);
  const [buttonColor, setButtonColor] = useState("");
  const [selected, setSelectedId] = useState(null);
  const [open, setState] = React.useState(false);
  const [erroMsg, setErrorMsg] = React.useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchInput, setSearchInput] = useState("");
  const [loadingButton,displayLoadingButton]=useState(false)
  const [itemId,setItemId]=useState('')
  const [msgServity,setMsgServity]=useState('')
  const [displayLoadingView,setLoadingView]=useState(true)

  const generateReport = () => {
    setOpenGenerateReportPage(!openGenerateReportPage);
  };

  const handleClick = (error) => {
    setState(true);
    setErrorMsg(error);
  };

  const handleClose = () => {
    setState(false);
  };

  useEffect(() => {
    let timeoutId;
    if (modalIsOpen) {
      timeoutId = setTimeout(() => {
        setModalIsOpen(false);
      }, 700);
    }
    return () => {
      clearTimeout(timeoutId);
    };
    
  }, [modalIsOpen]);

  // download.href = `https://demo.cappitallwant.com/cwsapims/api/v1/report/download-file/${itemId}`
  const clickDownloadFile = (itemId) => {
    setItemId(itemId)
    displayLoadingButton(true)
    const downLoadUrl=`${apiUrl}/cwsapims/api/v1/report/download-file/${itemId}`
    axios({
      method: 'get',
      url:downLoadUrl ,
      responseType: 'blob',
    })
      .then((response) => {
        if (response.status === 200) {
          const download = document.createElement("a");
          download.href = downLoadUrl;
          download.target = "_self";
          download.click();
          displayLoadingButton(false)
        }
      })
      .catch((error) => {
        handleClick('Download error: Please contact the system administrator');
        setMsgServity('warning');
        displayLoadingButton(false)
      });
  };

  useEffect(() => {
    axios
      .get(
      `${apiUrl}/cwsapims/api/v1/report/reportlogs?entityCode=${localStorage.getItem(
          "business_entity_id"
        )}&size=${rowsPerPage}&page=${page}`
      )
      .then((response) => {
        console.log(response.data);
        const embeddedData = response.data._embedded || [];
        const updatedData = embeddedData.map((item) => {
          return { ...item, loading: false };
        });
        setLoadingView(false)
        
        setData(updatedData);
        setTotalElements(response.data.totalElements || 0);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoader(false);
        setLoadingView(false)
      });
      
      
  }, [rowsPerPage, page]); 

  const handleRefresh = () => {
    
    setLoader(true); // Set loader to true while fetching the data
    axios
      .get(
        `${apiUrl}/cwsapims/api/v1/report/reportlogs?entityCode=${localStorage.getItem(
          "business_entity_id"
        )}&size=${rowsPerPage}&page=${page}`
      )
      .then((response) => {
        console.log(response.data);
        const embeddedData = response.data._embedded || [];
        const updatedData = embeddedData.map((item) => {
          return { ...item, loading: false };
        });
        setData(updatedData);
        setTotalElements(response.data.totalElements || 0);
        setCurrentPage(0);
        
        setLoader(false); // Set loader to false after data is updated
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        
        setLoader(false); // Set loader to false in case of an error
      });
  };

  const sync = () => {
    setIsZohoModelOpen(true);
  };

  const Info = () => {
    setReportInfoOpen(true);
  };

  const confi = () => {
    setConfigure(true);
  };

  const establishConnection = () => {};

  const closeZohoDrawer = () => {
    setIsZohoModelOpen(false);
  };

  const DrawerClose = () => {
    setConfigure(false);
  };

  const handleEmailIconClick = (id) => {
    setSelectedId(id);
    setConfigure(true);
  };

  //handling the reporttype by using report name conversion
  const handleReportType = (name) => {
    let type = "";
    switch (name) {
      case "GST Reconciliation Report":
        type = "reconciliationReport";
        break;
      case "AR Collection Status Report":
        type = "collectionStatusReport";
        break;
      case "AR Collection Analysis Report":
        type = "collectionAnalysisReport";
        break;
      case "GST Computation Report":
        type = "gstrComputationReport";
        break;
      case "ITC Available Report":
        type = "itcAvailReport";
        break;
      case "AR and AP Ageing Report":
        type = "arapAgeingReport";
        break;
      case "Multi Month Report":
        type = "multiMonthReport";
        break;
      case "Item Wise Report":
        type = "itemWiseReport";
        break;
    }
    return type;
  };

  // handling whatsapp icon cliking (sending the whatsapp notification)
  const handleWhatsAppIconClick = (item) => {
    
    setData((prev) =>
      prev.map((each) => {
        if (item.id === each.id) {
          return { ...each, loading: true };
        } else {
          return { ...each, loading: each.loading };
        }
      })
    );

    const notificationObject = {
      reportLogId: item.id,
      internalReport: true,
      reportType: handleReportType(item.reportName),
      businessEntityCode: item.entityCode,
      fromDate: item.fromDate.split(" ")[0],
      toDate: item.toDate.split(" ")[0],
    };

    axios
      .post(`${apiUrl}/cwsapims/api/v1/notification/send-notification`, notificationObject)
      .then((response) => {

        if (response.data.whatsAppStatus==='SUCCESS'){
          handleClick('Message Delivered 🚀')
          setMsgServity('success')
        }else{
          handleClick('Message Delivery Failed ❌')
          setMsgServity('error')
        }
        setData((prev) =>
        prev.map((each) => {
          if (each.id === item.id) {
            return { ...each, loading: false };
          } else {
            return { ...each, loading: each.loading };
          }
        })
      );
         // handleClick(response.data.whatsAppStatus)
        
      })
      .catch((err) => {
        handleClick("Message Delivery Failed ❌");
        setMsgServity('error')
        
        setData((prev) =>
        prev.map((each) => {
          if (each.id === item.id) {
            return { ...each, loading: false };
          } else {
            return { ...each, loading: each.loading };
          }
        })
      );
        
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSerialNumber=(index)=>{
    const arrSerialNumber=[]
    for (let i=((page*rowsPerPage)+1);i<(((page+1)*rowsPerPage)+1);i++){
      arrSerialNumber.push(i)
    }
    return arrSerialNumber[index]
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowsPerPageOptions = [25, 100, 200, 300, 500];

  const filteredData = data.filter((item) =>
    item.reportName.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      {openGenerateReportPage ? (
        <GenerateReport backfun={generateReport} />
      ) : (
        <BoxWrapper
          enableAddButton2={true}
          addButtonLabel2="Generate Report"
          addButtonHandlerFn2={generateReport}
          boxTitle="Report Logs"
          enableSearchInput={true}
          searchInputPlaceholder="Search Reports"
          searchInputOnchange={(e) => setSearchInput(e.target.value)}

          // enableAddButton={false}
          // addButtonLabel="Sync"
          // addButtonHandlerFn={sync}

          //Refresh Icon

          enableIcon1={true}
          enableIcon1Value={
            <Tooltip title="Refresh">
              <RefreshIcon fontSize="small" style={{ color: "black" }} />
            </Tooltip>
          }
          handleIcon1ClickFunction={handleRefresh}

        

          //Info Icon
          enableIcon3={true}
          enableIcon3Value={
            <Tooltip title="Info">
              <InfoIcon fontSize="small" style={{ color: "black" }} />
            </Tooltip>
          }
          handleIcon3ClickFunction={Info}

          // enableAddButton3={false}
          // addButtonLabel3="Refresh"
          // addButtonHandlerFn3={handleRefresh}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>
                  <th style={{width:'60%'}}>No</th>
                  <th style={{width:'180%'}}>Report Name</th>
                  <th>Generated Date </th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Status</th>
                  <th>Download</th>
                  <th>Notification</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => {
                  
                  const fromDate = new Date(item.fromDate);
                  const toDate = new Date(item.toDate);
                  
                  const formattedFromDate = `${fromDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}-${(fromDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${fromDate.getFullYear()}`;
                  const formattedToDate = `${toDate
                    .getDate()
                    .toString()
                    .padStart(2, "0")}-${(toDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${toDate.getFullYear()}`;

                  

                  const handleIconClick = () => {
                    if (item.status === "FAILED") {
                      //  alert(item.errorMsg)
                      setModalIsOpen(true);
                    }
                  };

                  return (
                    <tr key={item.id}>
                      <td>{handleSerialNumber(index)}</td>
                      <td>{item.reportName}</td>
                      <td>{item.generateDate}</td>
                      <td>{formattedFromDate}</td>
                      <td>{formattedToDate}</td>
                      <td>{item.status}</td>
                      <td>
                        {/* download button */}
                        {loadingButton && itemId===item.id? (
                          <LoadingButton style={{
                            alignItems: "top",
                            
                          }} loading >
                          .
                        </LoadingButton>
                         ):(item.status === "SUCCESS"&&
                          (<IconButton onClick={() => clickDownloadFile(item.id)} color="error">
                          <DownloadIcon
                            
                            style={{
                              alignItems: "top",
                              cursor: "pointer",
                              height: "21px",
                              width: "21px",
                              color:'brown'
                            }}
                          />                     
                        </IconButton>))
                         }
                      </td>
                       
                      <td  
                      style={{
                              display: "flex",
                              flexDirection: "row",
                               marginLeft:!item.loading&&'28%'
                            }}>

                        {/* whatsapp Icon button */}
                        {
                          (item.status === "SUCCESS" && item.loading)?
                          (
                            <LoadingButton style={{
                              alignItems: "top",
                              marginLeft:'10.5%'
                            }} loading >
                            Submit
                          </LoadingButton>
                          ):
                          (
                            item.status === "SUCCESS"&&(<IconButton style={{
                              alignItems: "top",
                              
                            }}
                            color="success"
                            onClick={() => handleWhatsAppIconClick(item)}
                            size="small">
                              <WhatsAppIcon
                              
                              style={{color: "#25D366",cursor: "pointer",height: "21px",
                              width: "21px",}}
                             />
                            </IconButton>)
                            
                          )
                        }

                        {((item.reportName === "GST Reconciliation Report" ||
                          item.reportName === "ITC Available Report") && item.status === "SUCCESS") && (

                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{ borderColor: "black",height:'20px',marginTop:'5px',marginLeft:'6.5%',marginRight:'6.5%' }}
                            /> 

                            )}

                        {/* Email Icon button */}

                        {((item.reportName === "GST Reconciliation Report" ||
                          item.reportName === "ITC Available Report")&& item.status === "SUCCESS") && (
                          <div>
                            <IconButton style={{
                            alignItems: "top"
                          }}
                          onClick={() => handleEmailIconClick(item.id)}
                          size="small"
                          color="primary">                       
                            <EmailIcon
                              style={{
                                alignItems: "top",
                                cursor: "pointer",
                                color: "#4285F4",
                                height: "21px",
                                width: "21px",
                              }}
                              
                            />
                            </IconButton>  
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}

                {data.length <= 0 && (
                  <tr>
                    {!displayLoadingView?(
                      <td
                      style={{ color: "red", textAlign: "center" }}
                      colSpan={5}
                      >
                      No Data Found!!
                    </td>):
                    (<td
                       style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
                       colspan={8}
                    >
                      Loading...Please wait
                    </td>)}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {filteredData.length>0&&
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </BoxWrapper>
      )}

      {modalIsOpen && (
        <div>
          <Modal isOpen={modalIsOpen} style={customModalStyles}>
            <h4>Error</h4>
          </Modal>{" "}
        </div>
      )}

      {/* Sync Button */}
      {isZohoModelOpen && (
        <div className="zoho-model">
          <ReportSync
            openZohoDrawer={isZohoModelOpen}
            buttonColor="blue"
            closeZohoDrawer={() => setIsZohoModelOpen(false)}
            logApitName="SYNC"
            nameOfTheModel="SYNC LOG"
          />
        </div>
      )}

      {isReportInfoOpen && (
        <div className="zoho-model">
          <ReportInfo
            openZohoDrawer={isReportInfoOpen}
            buttonColor="blue"
            closeZohoDrawer={() => setReportInfoOpen(false)}
            logApitName="SYNC"
            nameOfTheModel="NOTIFICATIONS"
          />
        </div>
      )}

      {configure && (
        <div className="configure">
          <Email
            openZohoDrawer={configure}
            buttonColor="blue"
            DrawerClose={() => setConfigure(false)}
            logApitName="EMAIL"
            nameOfTheModel="EMAIL"
            selected={selected}
            handleClick={handleClick}
            setMsgServity={setMsgServity}
          />
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3500}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={msgServity}
          sx={{ width: "100%" }}
        >
          {erroMsg}
          
        </Alert>
      </Snackbar>
    </>
  );
}
