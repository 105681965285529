import React, { useState } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const Five = ({ dataForFive, cancel, upDateJSON }) => {
  const [fiveEditData, setFiveEditData] = useState(dataForFive);

  const handleSubmit = () => {
    console.log(fiveEditData);
    upDateJSON(fiveEditData, "Five");
    cancel();
    
  };

  return (
    <>
      <BoxWrapper boxTitle="5. Values of Exempt, Nil-rated and Non-GST Inward Supplies">
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
            <thead>
              <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                <th>Nature of supplies</th>
                <th>Inter-State supplies</th>
                <th>Intra-state supplies</th>
              </tr>
            </thead>
            <tbody>
              {fiveEditData?.map((v, index) => {
                return (
                  <tr>
                    <td>{v.name}</td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.interstate}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fiveEditData?.map((item, i) => {
                              if (i === index) {
                                return { ...item, interstate: parseFloat(value) };
                              }
                              return item;
                            });
                            setFiveEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.intrastate}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = fiveEditData?.map((item, i) => {
                              if (i === index) {
                                return { ...item, intrastate: parseFloat(value) };
                              }
                              return item;
                            });
                            setFiveEditData(newData);
                          }}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <button className="button-primary" onClick={handleSubmit}>
            Save
          </button>

          <button className="button-outlined-secondary" onClick={cancel}>
            Cancel
          </button>
        </div>
      </BoxWrapper>
    </>
  );
};

export default Five;
