import React, { Component } from "react";
import "./unallocatedPayment.css";
import axios from "../../../../axios-order";
import CardForDispaly from "../../../../components/ui/Cards";
import Card from "@material-ui/core/Card";
import {
  TextField,
  Typography,
  Button,
  Grid,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
  }
};

class UnallocatedPayment extends Component {
  state = {
    originalTableData: [],
    table_data: [],
    total_count: 0,
    total_unallocated_payments: 0,
    loader: false,
  };

  componentDidMount() {
    this.setState({ loader: true });
    axios
      .get(
        `un/getAllUnallocatedPayments/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        this.setState({
          loader: false,
          total_count: res.data.total_count,
          total_unallocated_payments: res.data.total_unallocated_payments,
          table_data: res.data.table_data,
          originalTableData: res.data.table_data,
        });
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  }

  // table logic
  unallocatedPayment_TableHeader() {
    const tableHeader = [
      "customer name",
      "ERP Customer Code",
      "gstin",
      "Customer PAN",
      "Collection Date",
      "collection ref no",
      "unallocated Amount",
      "bank account number",
      "adjustment method",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  unallocatedPayment_TableData() {
    if (this.state.loader) {
      return (
        <td
          colSpan={6}
          style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
        >
          Loading…Please wait
        </td>
      );
    } else if (this.state.table_data.length > 0) {
      return this.state.table_data.map((tableData, index) => {
        return (
          <tr key={index}>
            <td>{tableData["customer_name"]}</td>
            <td>{tableData["cust_code"]}</td>
            <td>{tableData["gstin"]}</td>
            <td>{tableData["cust_pan"]}</td>
            <td>{dateFormat(tableData.collection_date)}</td>
            <td>{tableData["collection_reference_number"]}</td>
            <td>{numberFormat(tableData.unallocated_amount)}</td>
            <td>{tableData["bank_account_number"]}</td>
            <td>{tableData["adjustment_method"].toUpperCase()}</td>
          </tr>
        );
      });
    } else {
      return (
        <td colSpan={6} style={{ color: "red" }}>
          {" "}
          No Data Found!
        </td>
      );
    }
  }

  serchInputHandleChage = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    if (searchInput === null) {
      // Handle the case when searchInput is null
      return;
    }
  
    let searchData = this.state.originalTableData.filter((value) => {
      const query = searchInput.toLowerCase();
      const customerName = value.customer_name ? value.customer_name.toLowerCase() : '';
      const gstin = value.gstin ? value.gstin.toLowerCase() : '';
      const collectionRefNumber = value.collection_reference_number ? String(value.collection_reference_number).toLowerCase() : '';
  
      return (
        customerName.indexOf(query) >= 0 ||
        gstin.indexOf(query) >= 0 ||
        collectionRefNumber.indexOf(query) >= 0
      );
    });
    this.setState({ table_data: searchData });
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadUnallocatedPayments = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Unallocated Receipts Report.xlsx`;
    axios
      .post(`unallocated_payments/Download`, {
        data: {
          table_data: this.state.table_data,
          total_count: this.state.total_count,
          total_unallocated_payments: this.state.total_unallocated_payments,
        },
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <div className="card-section">
          <Grid container spacing={3}>
            <Grid items xs={3} style={{ paddingLeft: "13px" }}>
              <CardForDispaly
                backgroundcolor="#3b3b6b"
                cardTitle="Total Unallocated Receipts"
                cardBody={numberFormat(this.state.total_unallocated_payments)}
              />
            </Grid>
            <Grid items xs={6}></Grid>
            <Grid items xs={3} style={{ paddingRight: "12px" }}>
              <CardForDispaly
                backgroundcolor="#ba4242"
                cardTitle="#Unallocated Receipts"
                cardBody={this.state.total_count}
              />
            </Grid>
          </Grid>
        </div>

        <BoxWrapper
          boxTitle="Unallocated Receipts"
          exportToExcelEnable={true}
          exportToExcelFn={this.downloadUnallocatedPayments}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={this.serchInputHandleChage}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.unallocatedPayment_TableHeader()}</tr>
                {this.unallocatedPayment_TableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      </>
    );
  }
}

export default UnallocatedPayment;
