import React, { useState, useEffect } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const Four = ({ data, cancel, upDateJSON }) => {
  const [fourEditData, setFourEditData] = useState(data);
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [cess, setCess] = useState(0);

  const handleSubmit = () => {
    upDateJSON(fourEditData, "Four");
    cancel();
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  const functionToAddTheValue = () => {
    let IGST = 0;
    let CGST = 0;
    let SGST = 0;
    let CESS = 0;

    let BIGST = 0;
    let BCGST = 0;
    let BSGST = 0;
    let BCESS = 0;

    for (let i in fourEditData) {
      if (fourEditData[i]?.igst !== undefined && i <= 4) {
        IGST =
          IGST +
          parseFloat(fourEditData[i]?.igst !== "" ? fourEditData[i]?.igst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.cgst !== undefined && i <= 4) {
        CGST =
          CGST +
          parseFloat(fourEditData[i]?.cgst !== "" ? fourEditData[i]?.cgst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.sgst !== undefined && i <= 4) {
        SGST =
          SGST +
          parseFloat(fourEditData[i]?.sgst !== "" ? fourEditData[i]?.sgst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.cess !== undefined && i <= 4) {
        CESS =
          CESS +
          parseFloat(fourEditData[i]?.cess !== "" ? fourEditData[i]?.cess : 0);
      }
    }

    for (let i in fourEditData) {
      if (fourEditData[i]?.igst !== undefined && i > 4 && i < 7) {
        BIGST =
          BIGST +
          parseFloat(fourEditData[i]?.igst !== "" ? fourEditData[i]?.igst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.cgst !== undefined && i > 4 && i < 7) {
        BCGST =
          BCGST +
          parseFloat(fourEditData[i]?.cgst !== "" ? fourEditData[i]?.cgst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.sgst !== undefined && i > 4 && i < 7) {
        BSGST =
          BSGST +
          parseFloat(fourEditData[i]?.sgst !== "" ? fourEditData[i]?.sgst : 0);
      }
    }
    for (let i in fourEditData) {
      if (fourEditData[i]?.cess !== undefined && i > 4 && i < 7) {
        BCESS =
          BCESS +
          parseFloat(fourEditData[i]?.cess !== "" ? fourEditData[i]?.cess : 0);
      }
    }

    setIgst(IGST - BIGST);
    setCgst(CGST - BCGST);
    setSgst(SGST - BSGST);
    setCess(CESS - BCESS);
  };

  useEffect(() => {
    functionToAddTheValue();
  }, [fourEditData]);

  return (
    <>
      <BoxWrapper boxTitle="4.Eligible ITC">
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
            <thead>
              <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                <th>Details</th>
                <th>Integrated Tax</th>
                <th>Central Tax</th>
                <th>State/UT Tax</th>
                <th>Cess</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5} style={{ textAlign: "left", padding: "8px" }}>
                  <strong>(A) ITC Available (Whether in full or part)</strong>
                </td>
              </tr>
              {fourEditData?.map((v, index) => {
                if (index <= 4) {
                  return (
                    <tr>
                      <td>{v.name}</td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.igst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, igst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td
                        style={{ background: v.cgst === undefined && "#ddd" }}
                      >
                        {v.cgst !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{ background: v.sgst === undefined && "#ddd" }}
                      >
                        {v.sgst !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.sgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, sgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cess}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cess: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td colSpan={5} style={{ textAlign: "left", padding: "8px" }}>
                  <strong>(B) ITC Reversed</strong>
                </td>
              </tr>
              {fourEditData?.map((v, index) => {
                if (index === 5 || index === 6) {
                  return (
                    <tr>
                      <td>{v.name}</td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.igst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, igst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.sgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, sgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cess}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cess: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td colSpan={5} style={{ textAlign: "left", padding: "8px" }}>
                  <strong>(C) Net ITC Available</strong>
                </td>
              </tr>
              {fourEditData?.map((v, index) => {
                if (index === 7) {
                  return (
                    <tr
                      style={{
                        fontWeight: "bold",
                        background: "#3b3b6b",
                        color: "white",
                      }}
                    >
                      <td>{v.name}</td>
                      <td>
                        {igst ? numberFormat(igst) : "₹0"}

                        {/* <NumberFormat
                              className={
                                DesignInGSTR3BThreePointOne.numberFormate
                              }
                              style={{ paddingLeft: "10.5px" }}
                              thousandsGroupStyle="lakh"
                              prefix="₹"
                              decimalSeparator="."
                              displayType="number"
                              type="text"
                              decimalScale={2}
                              value={v.igst}
                              thousandSeparator={true}
                              allowNegative={true}
                              onValueChange={(values) => {
                                const { value } = values;
                                let newData = fourEditData?.map( (item , i) => {
                                     if(i===index) {
                                      return {...item , igst:value }
                                     }
                                     return item 
                                })
                                setFourEditData(newData)
                              }}
                            /> */}
                      </td>
                      <td>
                        {cgst ? numberFormat(cgst) : "₹0"}
                        {/* //     <NumberFormat
                          //   className={
                          //     DesignInGSTR3BThreePointOne.numberFormate
                          //   }
                          //   style={{ paddingLeft: "10.5px" }}
                          //   thousandsGroupStyle="lakh"
                          //   prefix="₹"
                          //   decimalSeparator="."
                          //   displayType="number"
                          //   type="text"
                          //   decimalScale={2}
                          //   value={v.cgst}
                          //   thousandSeparator={true}
                          //   allowNegative={true}
                          //   onValueChange={(values) => {
                          //     const { value } = values;
                          //     let newData = fourEditData?.map( (item , i) => {
                          //       if(i===index) {
                          //        return {...item , cgst:value }
                          //       }
                          //       return item 
                          //  })
                          //  setFourEditData(newData)
                          //   }}
                          // /> */}
                      </td>
                      <td>
                        {sgst ? numberFormat(sgst) : "₹0"}
                        {/* //     <NumberFormat
                          //   className={
                          //     DesignInGSTR3BThreePointOne.numberFormate
                          //   }
                          //   style={{ paddingLeft: "10.5px" }}
                          //   thousandsGroupStyle="lakh"
                          //   prefix="₹"
                          //   decimalSeparator="."
                          //   displayType="number"
                          //   type="text"
                          //   decimalScale={2}
                          //   value={v.sgst}
                          //   thousandSeparator={true}
                          //   allowNegative={true}
                          //   onValueChange={(values) => {
                          //     const { value } = values;
                          //     let newData = fourEditData?.map( (item , i) => {
                          //       if(i===index) {
                          //        return {...item , sgst:value }
                          //       }
                          //       return item 
                          //  })
                          //  setFourEditData(newData)
                          //   }}
                          // />           */}
                      </td>
                      <td>
                        {cess ? numberFormat(cess) : "₹0"}
                        {/* //   <NumberFormat
                          //   className={
                          //     DesignInGSTR3BThreePointOne.numberFormate
                          //   }
                          //   style={{ paddingLeft: "10.5px" }}
                          //   thousandsGroupStyle="lakh"
                          //   prefix="₹"
                          //   decimalSeparator="."
                          //   displayType="number"
                          //   type="text"
                          //   decimalScale={2}
                          //   value={v.cess}
                          //   thousandSeparator={true}
                          //   allowNegative={true}
                          //   onValueChange={(values) => {
                          //     const { value } = values;
                          //     let newData = fourEditData?.map( (item , i) => {
                          //       if(i===index) {
                          //        return {...item , cess:value }
                          //       }
                          //       return item 
                          //  })
                          //  setFourEditData(newData)
                          //   }}
                          // /> */}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td colSpan={5} style={{ textAlign: "left", padding: "8px" }}>
                  <strong>(D) Other Details</strong>
                </td>
              </tr>
              {fourEditData?.map((v, index) => {
                if (index === 8 || index === 9) {
                  return (
                    <tr>
                      <td>{v.name}</td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.igst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, igst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.sgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, sgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cess}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = fourEditData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cess: parseFloat(value) };
                                }
                                return item;
                              });
                              setFourEditData(newData);
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <button className="button-primary" onClick={handleSubmit}>
            Save
          </button>

          <button className="button-outlined-secondary" onClick={cancel}>
            Cancel
          </button>
        </div>
      </BoxWrapper>
    </>
  );
};

export default Four;
