import React, { Component } from "react";
import classes from "./Dashboard.module.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "../../../../components/ui/Cards";
import BarChartRupeesFormat from "../../../../components/ui/BarChart_rupeesFormat";
import PieChart from "../../../../components/ui/PieCharts";
import Container from "@material-ui/core/Container";
import axios from "../../../../axios-order";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputField from "../../../../components/ui/inputField";
import SelectField from "../../../../components/ui/selectInput";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import "../../../../asserts/css/Onboarding.css";

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

class Dashboard extends Component {
  state = {
    sellerName: null,
    DueByAgeSummary: {},
    totalOutstanding: [],
    cardValues: null,
    originalDataAgeingSummary: [],
    originalDataAgeingDetails: [],
    dataRawForAgeingSummary: [],
    dataRawForAgeingDetails: [],
    searchInput: "",
    Ageing_summary_search_field: 1,
    AsOfDate: new Date(),
    loaderForTableOne: false,
    loaderForTableTwo: false,
  };

  //table search by customer name-----
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredDataForAgeingsummary =
      this.state.originalDataAgeingSummary.filter((value) => {
        return String(value.customer_name)
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

    let filteredDataForAgeingDetails =
      this.state.originalDataAgeingDetails.filter((value) => {
        return String(value["customer_name"])
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    this.setState({ dataRawForAgeingSummary: filteredDataForAgeingsummary });
    this.setState({ dataRawForAgeingDetails: filteredDataForAgeingDetails });
  };

  // table filter on due date  by ageing summary dropdown
  handleAgeingSummarySearchSChange = (event) => {
    this.setState({ Ageing_summary_search_field: event.target.value }, () => {
      this.ageingSummarySearch();
    });
  };

  ageingSummarySearch = () => {
    const date = new Date();
    const pad = "00";
    const yy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    const currentDate = new Date(
      `${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}-${yy}`
    );
    // ageing details table filter logic
    const { Ageing_summary_search_field } = this.state;
    if (Ageing_summary_search_field === 1) {
      let filteredDataForAgeingsummary =
        this.state.originalDataAgeingDetails.filter((value) => {
          return String(value["due date"]);
        });
      this.setState({ dataRawForAgeingDetails: filteredDataForAgeingsummary });
    } else if (Ageing_summary_search_field === 2) {
      let filteredDataForAgeingsummary =
        this.state.originalDataAgeingDetails.filter((value) => {
          const dateFromBack = value["due date"].split("-");
          const new_date = new Date(
            `${dateFromBack[1]}-${dateFromBack[0]}-${dateFromBack[2]}`
          );
          if (currentDate <= new_date) {
            return String(value["due date"]);
          }
        });
      this.setState({ dataRawForAgeingDetails: filteredDataForAgeingsummary });
    } else if (Ageing_summary_search_field === 3) {
      let filteredDataForAgeingsummary =
        this.state.originalDataAgeingDetails.filter((value) => {
          const dateFromBack = value["due date"].split("-");
          const new_date = new Date(
            `${dateFromBack[1]}-${dateFromBack[0]}-${dateFromBack[2]}`
          );
          if (currentDate >= new_date) {
            return String(value["due date"]);
          }
        });
      this.setState({ dataRawForAgeingDetails: filteredDataForAgeingsummary });
    }
  };

  //Dashboard onload----------
  componentDidMount = () => {
    // current date[mm-dd-yyyy]
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    this.setState({
      loaderForTableOne: true,
      loaderForTableTwo: true,
    });

    // from date logic
    // if (mm === 1 || mm === 2 || mm === 3) {
    //   const year = parseInt(yy) - 1;
    //   var fromDate = `${year.toString()}-04-01`;
    // } else {
    //   const fromDate = `${yy}-04-01`;
    // }
    // to date logic
    // var date = new Date();
    // const lastDate = new Date(
    //   date.getFullYear(),
    //   date.getMonth() + 1,
    //   0
    // ).getDate();

    var currentDateForApiCall = `${yy}-${(pad + mm).slice(-2)}-${(
      pad + dd
    ).slice(-2)}`;

    this.setState({
      AsOfDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });

    let promise = new Promise((resolve, reject) => {
      resolve(true);
      axios
        .get(
          `ageing/generateAging/${currentDateForApiCall}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data !== "undefined") {
            resolve(res.data.error);
          } else {
            resolve(true);
          }
        });
    });

    Promise.all([promise]).then((error) => {
      if (error[0] !== "Data not Found") {
        axios
          .get(
            `ageing/dueBySummary/${localStorage.getItem("business_entity_id")}`
          )
          .then((res) => {
            let newData = res.data.data.filter((v) => {
              return v.y !== 0;
            });
            res.data.data = newData;
            this.setState({ DueByAgeSummary: res.data });
          });
      } else {
        this.setState({ sellerName: "" });
      }

      if (error[0] !== "Data not Found") {
        axios
          .get(
            `ageing/totalOutstanding/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            this.setState({ totalOutstanding: res.data });
          });
      } else {
        this.setState({ sellerName: "" });
      }
      axios
        .get(`ageing/cards/${localStorage.getItem("business_entity_id")}`)
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({ cardValues: "" });
            this.setState({ sellerName: "" });
          } else {
            this.setState({ cardValues: res.data.cards_data });
            this.setState({ sellerName: res.data.seller_name });
          }
        });

      axios
        .get(
          `ageing/ageingSummary/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({
              dataRawForAgeingSummary: "",
              loaderForTableOne: false,
            });
            this.setState({ originalDataAgeingSummary: "" });
          } else {
            this.setState({
              dataRawForAgeingSummary: res.data.data,
              loaderForTableOne: false,
            });
            this.setState({ originalDataAgeingSummary: res.data.data });
          }
        })
        .catch(() => this.setState({ loaderForTableOne: false }));

      axios
        .get(
          `ageing/ageingDetails/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({
              dataRawForAgeingDetails: "",
              loaderForTableTwo: false,
            });
            this.setState({ originalDataAgeingDetails: "" });
          } else {
            this.setState({
              dataRawForAgeingDetails: res.data.data,
              loaderForTableTwo: false,
            });
            this.setState({ originalDataAgeingDetails: res.data.data });
          }
        })
        .catch(() => this.setState({ loaderForTableTwo: false }));
    });
  };

  // table logic for acccount receivable ageing report
  accountReceivableTableHeader() {
    const tableHeader = [
      "customer name",
      "customer code",
      "Outstanding amount",
      "Account Manager",
      "adv/Excess amount",
      "Amount not yet due",
      "Ageing amount 1-30",
      "Ageing amount 31-60",
      "Ageing amount 61-90",
      "ageing amount 91-120",
      "ageing amount 121-180",
      "ageing amount 181-270",
      "ageing amount 271-360",
      "ageing amount >360",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  accountReceivableTableData() {
    if (this.state.loaderForTableOne) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={6}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (
      this.state.dataRawForAgeingSummary &&
      this.state.dataRawForAgeingSummary.length > 0
    ) {
      return this.state.dataRawForAgeingSummary.map((tableData, index) => {
        return (
          <tr key={index}>
            <td style={{ width: 170 }}>{tableData["customer_name"]}</td>
            <td>{tableData["customer code"]}</td>
            <td>{numberFormat(tableData["outstanding amount"])}</td>
            <td>{tableData.customer_manager}</td>
            <td>{numberFormat(tableData["advance amount"])}</td>
            <td>{numberFormat(tableData["amount not yet due"])}</td>
            <td>{numberFormat(tableData["ageing amount 0-30"])}</td>
            <td>{numberFormat(tableData["ageing amount 31-60"])}</td>
            <td>{numberFormat(tableData["ageing amount 61-90"])}</td>
            <td>{numberFormat(tableData["ageing amount 91-120"])}</td>
            <td>{numberFormat(tableData["ageing amount 121-180"])}</td>
            <td>{numberFormat(tableData["ageing amount 181-270"])}</td>
            <td>{numberFormat(tableData["ageing amount 271-360"])}</td>
            <td>{numberFormat(tableData["ageing amount >360"])}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
            No Data Found!
          </td>
        </tr>
      );
    }
  }

  // table logic for  ageing Details
  ageingDetailsTableHeader() {
    const tableHeader = [
      "customer name",
      "customer code",
      "customer gstin",
      "Account Manager",
      "invoice number",
      "due date",
      "taxable amount",
      "non taxable amount",
      "gst amount",
      "total invoice amount",
      "tds",
      "net amount collected",
      "amount outstanding",
      "adv/Excess amount",
      "days past due date",
      "aging bucket",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  ageingDetailsTableData() {
    if (this.state.loaderForTableTwo) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colSpan={6}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (
      this.state.dataRawForAgeingDetails &&
      this.state.dataRawForAgeingDetails.length > 0
    ) {
      return this.state.dataRawForAgeingDetails.map((tableData, index) => {
        return (
          <tr key={index}>
            <td style={{ width: 150 }}>{tableData["customer_name"]}</td>
            <td>{tableData["customer code"]}</td>
            <td>{tableData["customer gstin"]}</td>
            <td>{tableData.customer_manager}</td>
            <td>{tableData["invoice number"]}</td>
            <td style={{ width: 150 }}>{tableData["due date"]}</td>
            <td>{numberFormat(tableData["taxable invoice amount"])}</td>
            <td>{numberFormat(tableData["non taxable invoice amount"])}</td>
            <td>{numberFormat(tableData["gst invoice amount"])}</td>
            <td>{numberFormat(tableData["total invoice amount"])}</td>
            <td>{numberFormat(tableData["tds"])}</td>
            <td>{numberFormat(tableData["net amount collected"])}</td>
            <td>{numberFormat(tableData["amount outstanding"])}</td>
            <td>{numberFormat(tableData["advance amount"])}</td>
            <td>{tableData["days past due date"]}</td>
            <td>{tableData["aging bucket"]}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={6}>
            No Data Found!
          </td>
        </tr>
      );
    }
  }

  handleDateChange = (v) => {
    // current date[mm-dd-yyyy]
    const current_date = new Date(v);
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    // from date logic
    //  if (mm == 1 || mm == 2 || mm == 3) {
    //   const year = parseInt(yy) - 1;
    //   var fromDate = `${year.toString()}-04-01`;
    // } else {
    //   const fromDate = `${yy}-04-01`;
    // }
    // to date logic
    // var date = new Date(v);
    // const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    var toDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
    this.setState({
      AsOfDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
    });

    let promise = new Promise((resolve, reject) => {
      axios
        .get(
          `ageing/generateAging/${toDate}/${localStorage.getItem(
            "business_entity_id"
          )}`
        )
        .then((res) => {
          if (typeof res.data !== "undefined") {
            resolve(res.data.error);
          } else {
            resolve(true);
          }
        });
    });

    Promise.all([promise]).then((error) => {
      // this.MyDataProvider = dataProvider(process.env.REACT_APP_DOMAIN);
      // getting data for dueBysummary chart
      if (error[0] !== "Data not Found") {
        axios
          .get(
            `ageing/dueBySummary/${localStorage.getItem("business_entity_id")}`
          )
          .then((res) => {
            this.setState({ DueByAgeSummary: res.data });
          });
      } else {
        this.setState({ sellerName: "" });
      }

      // getting data for totalOutstanding chart
      if (error[0] !== "Data not Found") {
        axios
          .get(
            `ageing/totalOutstanding/${localStorage.getItem(
              "business_entity_id"
            )}`
          )
          .then((res) => {
            this.setState({ totalOutstanding: res.data });
          });
      } else {
        this.setState({ sellerName: "" });
      }

      // getting data for card
      axios
        .get(`ageing/cards/${localStorage.getItem("business_entity_id")}`)
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({ cardValues: "" });
            this.setState({ sellerName: "" });
          } else {
            this.setState({ cardValues: res.data.cards_data });
            this.setState({ sellerName: res.data.seller_name });
          }
        });

      // getting data for ageing summary table
      axios
        .get(
          `ageing/ageingSummary/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({ dataRawForAgeingSummary: "" });
            this.setState({ originalDataAgeingSummary: "" });
          } else {
            this.setState({ dataRawForAgeingSummary: res.data.data });
            this.setState({ originalDataAgeingSummary: res.data.data });
          }
        });

      // getting data for ageing details table
      axios
        .get(
          `ageing/ageingDetails/${localStorage.getItem("business_entity_id")}`
        )
        .then((res) => {
          if (typeof res.data.error !== "undefined") {
            this.setState({ dataRawForAgeingDetails: "" });
            this.setState({ originalDataAgeingDetails: "" });
          } else {
            this.setState({ dataRawForAgeingDetails: res.data.data });
            this.setState({ originalDataAgeingDetails: res.data.data });
          }
        });
    });
  };

  // refresh button logic
  refreshButtonHandler = () => {
    // current date[mm-dd-yyyy]
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    // from date logic
    // if (mm === 1 || mm === 2 || mm === 3) {
    //   const year = parseInt(yy) - 1;
    //   var fromDate = `${year.toString()}-04-01`;
    // } else {
    //   const fromDate = `${yy}-04-01`;
    // }

    // to date logic
    // var date = new Date();
    // const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    var currentDateForApiCall = `${yy}-${(pad + mm).slice(-2)}-${(
      pad + dd
    ).slice(-2)}`;

    axios
      .get(
        `ageing/generateAging/${currentDateForApiCall}/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        window.location.reload();
      });
  };

  // download for ageing summary
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  AgeingSummaryDownload = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `ageing_summary_export.xlsx`;
    axios
      .post(`/ageing_summary/download`, {
        data: this.state.dataRawForAgeingSummary,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // download for ageing details report
  AgeingDetailsDownload = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `ageing_details_report.xlsx`;
    axios
      .post(`/ageing_detail/download`, {
        data: this.state.dataRawForAgeingDetails,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          {/*---header for dashboard--- */}
          <div style={{ marginTop: "30px", marginBottom: "20px" }}>
            <Grid container spacing={3}>
              {this.state.sellerName ? (
                <Grid item xs={6}>
                  <Paper
                    className={classes.Paper}
                    style={{ paddingBottom: "14px" }}
                  >
                    <div
                      style={{
                        textTransform: "capitalize",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                    >
                      {this.state.sellerName ? (
                        this.state.sellerName
                      ) : (
                        <CircularProgress size={25} />
                      )}{" "}
                      : Receivables and Ageing Dashboard
                    </div>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}
              {this.state.sellerName ? (
                <Grid item xs={3}>
                  <Paper className={classes.Paper}>
                    <div>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <ValidatedDatePicker
                          name="date_picker"
                          value={this.state.AsOfDate}
                          onChange={(v) => this.handleDateChange(v)}
                        />
                      </ValidatorForm>
                    </div>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}
              {/* refresh button */}
              {this.state.sellerName ? (
                <Grid item xs={3}>
                  <Paper
                    className={classes.Paper}
                    style={{ paddingBottom: "14px" }}
                  >
                    <div style={{ textAlign: "center", cursor: "pointer" }}>
                      <Button
                        variant="outlined"
                        onClick={this.refreshButtonHandler}
                      >
                        Refresh Dashboard
                      </Button>
                    </div>
                  </Paper>
                </Grid>
              ) : (
                <Button variant="contained" onClick={this.refreshButtonHandler}>
                  Refresh Dashboard
                </Button>
              )}
            </Grid>
          </div>

          {/* ---cards--- */}
          <div style={{ marginBottom: "30px" }}>
            {this.state.cardValues ? (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Card
                    backgroundcolor="#6f9eaf"
                    cardTitle="Total Receivables"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        numberFormat(this.state.cardValues.total_receivables)
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Card
                    backgroundcolor="#a9294f"
                    cardTitle="Not Yet Due"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        numberFormat(this.state.cardValues.not_yet_due)
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Card
                    backgroundcolor="#c7753d"
                    cardTitle="Total Overdue"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        numberFormat(this.state.cardValues.total_overdue)
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Card
                    backgroundcolor="#eb596e"
                    cardTitle="% Overdue"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        this.state.cardValues.overdue_percent
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Card
                    backgroundcolor="#6f9eaf"
                    cardTitle="#Invoices Overdue"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        this.state.cardValues.invoice_due
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Card
                    backgroundcolor="#6f9eaf"
                    cardTitle="Advance/Excess Recieved"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        numberFormat(this.state.cardValues.adv_recieved)
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Card
                    backgroundcolor="#6f9eaf"
                    cardTitle="#Invoices Advance/Excess"
                    cardBody={
                      this.state.cardValues && this.state.cardValues ? (
                        this.state.cardValues.adv_invoice_count
                      ) : (
                        <CircularProgress size={25} />
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </div>

          {/* ---chart--- */}
          <div style={{ marginBottom: "30px" }}>
            {this.state.sellerName ? (
              <Paper
                className={classes.Paper}
                style={{ background: "#c4d0d9" }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <PieChart
                      pieChartTitle="Overdue by Age Summary"
                      yAxisTitle="Value"
                      xAxisTitle="Days"
                      data={this.state.DueByAgeSummary.data}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <BarChartRupeesFormat
                      tooltipHeader="customer_manager"
                      BarChartTitle={`Top ${
                        this.state.totalOutstanding.data &&
                        this.state.totalOutstanding.data.length - 1
                      }  Customers by Outstanding Amount + Others`}
                      tooltipFormat="₹ {point.y:.1f}"
                      yAxisTitle="Value"
                      xAxisTitle="Customers"
                      data={this.state.totalOutstanding.data}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ) : (
              ""
            )}
          </div>

          {/* filter for ageing tables */}
          {this.state.dataRawForAgeingSummary ? (
            <div
              style={{ marginTop: "10px", marginBottom: "20px" }}
              className={classes.Header}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    className={classes.Paper}
                    style={{ background: "#c4d0d9" }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <ValidatorForm
                          ref="form"
                          onSubmit={this.onSubmitHandler}
                          onError={(errors) => console.log(errors)}
                        >
                          <InputField
                            textLabel="Search By Customer Name"
                            inputPlacholder="eg. WeCools Lts"
                            inputType="text"
                            requiredField=" "
                            name="Search_by_customer_name"
                            value={this.state.searchInput ?? ""}
                            textOnchangeFunction={this.handleSearchInputChange}
                          />
                        </ValidatorForm>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          ) : (
            ""
          )}

          {/* Accounts Receivable Ageing Report [Datatable]*/}
          {this.state.dataRawForAgeingSummary ? (
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: "none",
                      marginBottom: "0px",
                      padding: "0px",
                    }}
                  >
                    <li style={{ display: "inline-block" }}>
                      <p>Accounts Receivable Ageing Report</p>
                    </li>
                    <li style={{ display: "inline-block", float: "right" }}>
                      <Button size="small" onClick={this.AgeingSummaryDownload}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                {/*---HTML TABLE---*/}
                <div className={classes.tableResponsive}>
                  <table
                    id="AgeingReportDetails"
                    className={classes.LongTableCss}
                  >
                    <tbody>
                      <tr>{this.accountReceivableTableHeader()}</tr>
                      {this.accountReceivableTableData()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/*  Ageing Report details [Datatable]*/}
          {this.state.dataRawForAgeingDetails ? (
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: "none",
                      marginBottom: "0px",
                      padding: "0px",
                    }}
                  >
                    <li style={{ display: "inline-block" }}>
                      {" "}
                      <p>Ageing Report Detailed</p>
                    </li>
                    <li style={{ display: "inline-block", float: "right" }}>
                      <Button size="small" onClick={this.AgeingDetailsDownload}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <Grid container spacing={3}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3} style={{ padding: "0px" }}>
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.onSubmitHandler}
                      onError={(errors) => console.log(errors)}
                    >
                      <div
                        style={{ marginBottom: "30px", marginRight: "10px" }}
                      >
                        <SelectField
                          SelectLabel="Ageing Summary"
                          validator={["required"]}
                          requiredField=" "
                          errorMsg={["This field is required"]}
                          choice_id="id"
                          choice_name="Ageing_summary_search_field"
                          choices={[
                            {
                              Ageing_summary_search_field: "All",
                              id: 1,
                            },
                            {
                              Ageing_summary_search_field: "Not Due",
                              id: 2,
                            },
                            {
                              Ageing_summary_search_field: "Due",
                              id: 3,
                            },
                          ]}
                          name="Ageing_summary_search_field"
                          value={this.state.Ageing_summary_search_field ?? "1"}
                          textOnchangeFunction={
                            this.handleAgeingSummarySearchSChange
                          }
                        />
                      </div>
                    </ValidatorForm>
                  </Grid>
                </Grid>
                {/*---HTML TABLE---*/}
                <div className={classes.tableResponsive}>
                  <table className={classes.LongTableCss}>
                    <tbody>
                      <tr>{this.ageingDetailsTableHeader()}</tr>
                      {this.ageingDetailsTableData()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default Dashboard;
