// @flow
import React, { useState, useRef } from "react";
import { Button } from "@material-ui/core";
import InputField from "../../../components/ui/inputField";
import ValidatedDatePicker from "../../../components/ui/DatePicker";
import axios from "../../../axios-order";
import {
  ValidatorForm,
  resetValidations,
} from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Add(props) {
  const { cancelAddForm, updateGetApi } = props;
  const addForm = useRef();
  // const form = useRef("form")
  const [addTdsData, setAddTdsData] = useState({});
  const [fromDate, setFromDate] = useState(null);

  const addInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddTdsData({
      ...addTdsData,
      [name]: value,
    });
  };

  const handleFromDateChange = (v) => {
    setFromDate(v);
    setAddTdsData({
      ...addTdsData,
      valid_to_date: null,
    });
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setAddTdsData({
        ...addTdsData,
        valid_from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      });
    }
  };

  const handleToDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setAddTdsData({
        ...addTdsData,
        valid_to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };

  const finalAddData = {
    org_gstin: `${localStorage.getItem("gst")}`,
    records: [addTdsData],
  };

  const addFormsubmitHandler = (e) => {
    toast.configure();
    if (addTdsData.valid_to_date < addTdsData.valid_from_date) {
      alert("To Date is lesser than From date");
      return false;
    }
    if (!addTdsData.TDSType) {
      alert("Please Select the TDS Type.");
      return false;
    }
    axios
      .post("/tds/exemption", finalAddData)
      .then((res) => {
        if (res.data.status === true) {
          cancelAddForm();
          updateGetApi();
          setAddTdsData({});

          toast.success(res.data.message, { autoClose: 2000 });
        } else {
          toast.error(res.data.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {});
  };

  const cancelAddFormDrawer = () => {
    cancelAddForm();
    setAddTdsData({});

    addForm.current.resetValidations();
  };

  return (
    <ValidatorForm
      ref={addForm}
      onSubmit={addFormsubmitHandler}
      onError={(errors) => console.log(errors)}
    >
      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group">TDS Type</FormLabel> */}
        <label style={{ marginBottom: "0px" }}>TDS Type</label>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="TDSType"
          value={addTdsData.TDSType ?? ""}
          onChange={addInputChangeHandler}
        >
          <FormControlLabel value="Sales" control={<Radio />} label="Sales" />
          <FormControlLabel
            value="Purchase"
            control={<Radio />}
            label="Purchase"
          />
        </RadioGroup>
      </FormControl>

      <InputField
        textLabel="Legal Name"
        inputType="text"
        name="vendor_legal_name"
        value={addTdsData.vendor_legal_name ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="PAN"
        inputType="text"
        name="pan"
        value={addTdsData.pan ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <div style={{ marginTop: "20px" }}>
        <label>
          From Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          name="valid_from_date"
          value={addTdsData.valid_from_date ?? ""}
          onChange={(v) => handleFromDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>
          To Date
          <span style={{ color: "red" }}>*</span>:
        </label>
        <ValidatedDatePicker
          minDateForDatepicker={fromDate ?? ""}
          name="to_date"
          value={addTdsData.valid_to_date ?? ""}
          onChange={(v) => handleToDateChange(v)}
          validators={["required"]}
          errorMessages={["This field is required"]}
          disabled={fromDate ? false : true}
        />
      </div>

      <InputField
        textLabel="Section"
        inputType="text"
        name="tds_section"
        value={addTdsData.tds_section ?? ""}
        requiredField="*"
        validator={["required"]}
        errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      <InputField
        textLabel="Description"
        inputType="text"
        name="description"
        value={addTdsData.description ?? ""}
        // requiredField="*"
        // validator={["required"]}
        // errorMsg={["This field is required"]}
        textOnchangeFunction={addInputChangeHandler}
      />

      {/* .......Button........ */}
      <div className="mt-20">
        <button className="button-primary" type="submit">
          Submit
        </button>
        <button
          className="button-outlined-secondary"
          type="button"
          onClick={cancelAddFormDrawer}
        >
          cancel
        </button>
      </div>
    </ValidatorForm>
  );
}
