import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import designForTable from "./DueBucket.module.css";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/textFieldWithoutLabel";
import axios from "../../../../axios-order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "680px",
  height: "auto",
  bgcolor: "rgb(241, 244, 246)",
  borderRadius: "0.5rem",
  boxShadow: 24,
};

const DueBucket = () => {
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);

  const handleOpenModel = () => setOpenAndCloseModel(true);

  const [handleInputNumber, setHandleInputNumber] = useState();
  const [jsonForAgeingBucket, setJsonForAgeingBucket] = useState({});
  const [tableData, setTableData] = useState([]);

  const [errorTable, setErrorTable] = useState([]);

  const [displayErrorMessageForNumber, setDisplayErrorMessageForNumber] =
    useState("none");
  const [messageForErrorNumber, setMessageForErrorNumber] = useState("");

  const [dropDownForAgeingPayable, setDropDownForAgeingPayable] =
    useState("DueReceivable");

  const [loader, setLoader] = useState(false);

  const [errorMessageForNonEditingTabs, setErrorMessageForNonEditingTabs] =
    useState(false);
  const [isDisableSubmitButton, SetIsDisableSubmitButton] = useState(false);

  const ErrorMessageIfUserPutWrongNumber = (display, message) => {
    setDisplayErrorMessageForNumber(display);
    setMessageForErrorNumber(message);
  };

  const handleCloseModel = () => {
    setOpenAndCloseModel(false);
    setErrorTable([]);
    ErrorMessageIfUserPutWrongNumber("none", "");
    setJsonForAgeingBucket({});
    setHandleInputNumber();
    cancellButtonForErrorMessage();
  };

  const ageingBucketTableData = () => {
    if (tableData?.length > 0) {
      return tableData?.map((v) => {
        return Object?.keys(v)?.map((g) => {
          return (
            <tr>
              <td>{v[g]?.DueBucketName}</td>
              <td>{v[g]?.RangeFrom}</td>
              <td>{v[g]?.RangeTwo}</td>
            </tr>
          );
        });
      });
    } else {
      return (
        <tr>
          <td colspan={3}>
            {loader === true ? (
              <span style={{ textAlign: "center", color: "black" }}>
                Loading...Please wait
              </span>
            ) : (
              <span style={{ color: "red", textAlign: "center" }}>
                No Data Found!!
              </span>
            )}
          </td>
        </tr>
      );
    }
  };

  const getApiForTable = () => {
    setLoader(true);
    if (dropDownForAgeingPayable === "DueReceivable") {
      axios
        .get(`/get_seller_due_bucket_details/${localStorage.getItem("gst")}`)
        .then((res) => {
          setLoader(false);
          setTableData(
            res?.data?.data[0]?.seller_ageing_recievable_bucket_details
          );
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      let FetchGstinData = localStorage.getItem("gst");
      let GstinPan = FetchGstinData.substring(2, 12);
      axios
        .get(`/get_vendor_due_bucket_details/${GstinPan}`)
        .then((res) => {
          setLoader(false);
          setTableData(
            res?.data?.data[0]?.vendor_ageing_payable_bucket_details
          );
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const DefaultHandle = () => {
    setLoader(true);
    let data = {};
    if (dropDownForAgeingPayable === "DueReceivable") {
      data = {
        seller_code: localStorage.getItem("business_entity_id"),
        seller_gstin: localStorage.getItem("gst"),
        default: true,
      };
      axios
        .post(`/add_seller_due_bucket_details`, { ...data })
        .then((res) => {
          if (res.data.status === true) {
            setLoader(false);
            getApiForTable();
            handleCloseModel();
            toast.success(res.data.message, {
              autoClose: 3000,
              progress: 0,
            });
          } else {
            setLoader(false);
            toast.error(res.data.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      let FetchGstinData = localStorage.getItem("gst");
      let GstinToPan = FetchGstinData.substring(2, 12);
      data = {
        business_entity_id: localStorage.getItem("business_entity_id"),
        vendor_pan: GstinToPan,
        default: true,
      };
      axios
        .post(`/add_vendor_due_bucket_details`, { ...data })
        .then((res) => {
          if (res.data.status === true) {
            setLoader(false);
            getApiForTable();
            handleCloseModel();
            toast.success(res.data.message, {
              autoClose: 3000,
              progress: 0,
            });
          } else {
            setLoader(false);
            toast.error(res.data.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const onSubmitHandler = () => {
    cancellButtonForErrorMessage();
    console.log("Data    ", jsonForAgeingBucket);
    let flag = false;
    let Error = [];
    for (let i in jsonForAgeingBucket) {
      if (i == `DueBucket${handleInputNumber}`) {
      } else if (
        parseInt(jsonForAgeingBucket[i].RangeFrom) <
        parseInt(jsonForAgeingBucket[i].RangeTwo)
      ) {
        Error.push(jsonForAgeingBucket[i].DueBucketName);
        setErrorTable(Error);
        flag = true;
      }
    }
    if (flag === false) {
      if (dropDownForAgeingPayable === "DueReceivable") {
        let data = {};
        data = {
          seller_code: localStorage.getItem("business_entity_id"),
          default: false,
          seller_gstin: localStorage.getItem("gst"),
          seller_ageing_recievable_bucket_details: [jsonForAgeingBucket],
        };
        axios
          .post(`/add_seller_due_bucket_details`, { ...data })
          .then((res) => {
            if (res.data.status === true) {
              getApiForTable();
              handleCloseModel();
              toast.success(res.data.message, {
                autoClose: 3000,
                progress: 0,
              });
            } else {
              toast.error(res.data.message, {
                autoClose: 3000,
                progress: 0,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        let data = {};
        let FetchGstinData = localStorage.getItem("gst");
        let GstinToPan = FetchGstinData.substring(2, 12);

        data = {
          business_entity_id: localStorage.getItem("business_entity_id"),
          default: false,
          vendor_pan: GstinToPan,
          vendor_ageing_payable_bucket_details: [jsonForAgeingBucket],
        };

        // Not Edited
        axios
          .post(`/add_vendor_due_bucket_details`, { ...data })
          .then((res) => {
            if (res.data.status === true) {
              getApiForTable();
              handleCloseModel();
              toast.success(res.data.message, {
                autoClose: 3000,
                progress: 0,
              });
            } else {
              toast.error(res.data.message, {
                autoClose: 3000,
                progress: 0,
              });
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleCustomerNumber = (e) => {
    cancellButtonForErrorMessage();
    let value = e.target.value;
    setHandleInputNumber(e.target.value);
    ErrorMessageIfUserPutWrongNumber("none", "");
    e.preventDefault();
    if (value.length > 0 && value <= 50 && value >= 3) {
      ErrorMessageIfUserPutWrongNumber("none", "");
      setErrorTable([]);
      setJsonForAgeingBucket({});
      SetIsDisableSubmitButton(false);
    } else {
      SetIsDisableSubmitButton(true);
      ErrorMessageIfUserPutWrongNumber(
        "block",
        "Number Must be greater or equal to 3 and less than or equal to 50"
      );
      setErrorTable([]);
      setJsonForAgeingBucket({});
    }
  };

  const onBlurMathod = (arg) => {
    if (arg.keyNumber > 1 && parseInt(arg.keyNumber) < handleInputNumber) {
      let No = parseInt(arg.keyNumber);
      setJsonForAgeingBucket({
        ...jsonForAgeingBucket,
        [`DueBucket${No + 1}`]: {
          ...jsonForAgeingBucket[`DueBucket${No + 1}`],
          RangeFrom: String(
            parseInt(jsonForAgeingBucket[`DueBucket${No}`]?.RangeTwo) - 1
          ),
        },
      });
    } else {
      setJsonForAgeingBucket({
        ...jsonForAgeingBucket,
        ["DueBucket2"]: {
          ...jsonForAgeingBucket["DueBucket2"],
          RangeFrom: String(
            parseInt(jsonForAgeingBucket["DueBucket1"]?.RangeTwo) - 1
          ),
        },
      });
    }
  };

  const handleChangeForNumberAndValue = (arg) => {
    console.log(arg.Name);
    cancellButtonForErrorMessage();
    if (arg.Name === "DueBucketName") {
      if (arg.keyNumber === handleInputNumber) {
        setJsonForAgeingBucket({
          ...jsonForAgeingBucket,
          [`DueBucket${arg.keyNumber}`]: {
            ...jsonForAgeingBucket[`DueBucket${arg.keyNumber}`],
            [arg.Name]: arg.NameValue,
            RangeTwo: ">",
          },
        });
      } else if (arg.keyNumber == 1) {
        setJsonForAgeingBucket({
          ...jsonForAgeingBucket,
          [`DueBucket${arg.keyNumber}`]: {
            ...jsonForAgeingBucket[`DueBucket${arg.keyNumber}`],
            [arg.Name]: arg.NameValue,
            RangeFrom: "0",
            RangeTwo: String(jsonForAgeingBucket[`DueBucket${1}`]?.RangeTwo),
          },
        });
      } else {
        setJsonForAgeingBucket({
          ...jsonForAgeingBucket,
          [`DueBucket${arg.keyNumber}`]: {
            ...jsonForAgeingBucket[`DueBucket${arg.keyNumber}`],
            [arg.Name]: arg.NameValue,
          },
        });
      }
    } else if (arg.RangeFrom === "RangeFrom") {
      setJsonForAgeingBucket({
        ...jsonForAgeingBucket,
        [arg.keyOne]: {
          ...jsonForAgeingBucket[arg.keyOne],
          [arg.RangeFrom]: arg.RangeFromValue,
        },
      });
    } else if (arg.RangeTwo === "RangeTwo") {
      setJsonForAgeingBucket({
        ...jsonForAgeingBucket,
        [arg.keyOne]: {
          ...jsonForAgeingBucket[arg.keyOne],
          [arg.RangeTwo]: arg.RangeTwoValue,
        },
      });
    }
  };

  const cancellButtonForErrorMessage = () => {
    setErrorMessageForNonEditingTabs(false);
  };

  const handleClickToThrowError = () => {
    setErrorMessageForNonEditingTabs(true);
  };

  const InputFunction = () => {
    let a = [];
    if (handleInputNumber >= 3 && handleInputNumber <= 50) {
      for (let i = 1; i <= handleInputNumber; i++) {
        a.push(
          <>
            <Grid item sm={4} style={{ marginTop: "5px" }}>
              {/*<label>Ageing Bucket Name {i}:</label>*/}
              <InputField
                style={{ background: "white" }}
                placeholder={`DueBucket Name${i}`}
                inputProps={
                  i <= 1
                    ? { readOnly: false }
                    : jsonForAgeingBucket[`DueBucket${i - 1}`]
                        ?.AgeingBucketName != ""
                    ? { readOnly: false }
                    : { readOnly: true }
                }
                inputType="text"
                value={jsonForAgeingBucket[`DueBucket${i}`]?.DueBucketName}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={(e) =>
                  handleChangeForNumberAndValue({
                    keyOne: `DueBucket${i}`,
                    keyNumber: `${i}`,
                    Name: "DueBucketName",
                    NameValue: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sm={4} style={{ marginTop: "5px" }}>
              {/*<label>Range From </label>*/}
              <InputField
                style={{ background: "#ddd" }}
                inputProps={
                  // i == "1" ? { readOnly: false } :
                  { readOnly: true }
                }
                placeholder="From"
                inputType="number"
                value={jsonForAgeingBucket[`DueBucket${i}`]?.RangeFrom}
                validator={["required"]}
                errorMsg={["This field is required"]}
                onClick={handleClickToThrowError}
                textOnchangeFunction={(e) =>
                  handleChangeForNumberAndValue({
                    keyOne: `DueBucket${i}`,
                    RangeFrom: "RangeFrom",
                    RangeFromValue: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sm={4} style={{ marginTop: "5px" }}>
              {/*<label>Range To </label>*/}
              <InputField
                style={{
                  background: i == handleInputNumber ? "#ddd" : "white",
                }}
                placeholder="To"
                InputProps={{
                  inputProps: { max: -1 },
                }}
                inputProps={
                  i == handleInputNumber
                    ? { readOnly: true }
                    : { readOnly: false }
                }
                onBlur={() =>
                  onBlurMathod({
                    keyOne: `DueBucket${i}`,
                    keyNumber: `${i}`,
                    Name: "DueBucketName",
                  })
                }
                inputType={i == handleInputNumber ? "text" : "number"}
                value={jsonForAgeingBucket[`DueBucket${i}`]?.RangeTwo}
                validator={["required"]}
                errorMsg={["This field is required"]}
                textOnchangeFunction={(e) =>
                  handleChangeForNumberAndValue({
                    event: e,
                    keyOne: `DueBucket${i}`,
                    keyNumber: `${i}`,
                    RangeTwo: "RangeTwo",
                    RangeTwoValue: e.target.value,
                  })
                }
              />
            </Grid>
          </>
        );
      }
    }
    return a;
  };

  useEffect(() => {
    toast.configure();
    getApiForTable();
  }, [dropDownForAgeingPayable]);

  return (
    <>
      <BoxWrapper boxTitle="Due Bucket">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div>
            <button
              className="button-outlined-primary"
              variant="outlined"
              onClick={handleOpenModel}
            >
              Customize
            </button>
            <button
              style={{ marginLeft: "10px" }}
              variant="outlined"
              onClick={DefaultHandle}
              className="button-outlined-primary"
            >
              Default
            </button>
          </div>
          <div style={{ width: "250px" }}>
            {/* <Box sx={{ minWidth: 120 }}> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Due Bucket</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dropDownForAgeingPayable}
                label="Ageing Bucket"
                onChange={(e) => setDropDownForAgeingPayable(e.target.value)}
                size="small"
              >
                <MenuItem value={"DueReceivable"}>Due Receivable</MenuItem>
                <MenuItem value={"DuePayable"}>Due Payable</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>Due Buckets Name</th>
                <th>From</th>
                <th>To</th>
              </tr>
              {ageingBucketTableData()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      <Modal
        open={openAndCloseModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ borderRadius: "0.5rem" }}>
            <div className={designForTable.modelTopHeader}>
              {dropDownForAgeingPayable === "DueReceivable"
                ? "Due Receivable Bucket"
                : "Due Payable Bucket"}
            </div>
            <div
              style={{
                background: "#f1f4f6",
                padding: "15px",
                minHeight: "430px",
              }}
            >
              <>
                <ValidatorForm
                  useRef="form"
                  onSubmit={onSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <div style={{ minHeight: "350px", margin: "-2.5rem 0 1rem" }}>
                    <Grid container spacing={7}>
                      <Grid item sm={6}>
                        <label>
                          <p style={{ marginTop: "10px", fontSize: "16px" }}>
                            Create Due Bucket:
                          </p>
                        </label>
                        <InputField
                          style={{ background: "#fff" }}
                          inputType="number"
                          name="score_latest"
                          value={handleInputNumber}
                          validator={["required"]}
                          errorMsg={["This field is required"]}
                          textOnchangeFunction={handleCustomerNumber}
                        />
                        {
                          <div
                            style={{
                              color: "red",
                              fontSize: "10px",
                              display: displayErrorMessageForNumber,
                            }}
                          >
                            {messageForErrorNumber}
                          </div>
                        }
                      </Grid>
                      <Grid item sm={6}>
                        {errorMessageForNonEditingTabs && (
                          <div
                            className={designForTable.StyleForNotEditingMessage}
                          >
                            <div>From Range Field can not be Edited </div>
                            <div
                              onClick={cancellButtonForErrorMessage}
                              style={{ marginLeft: "auto", cursor: "pointer" }}
                            >
                              x
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <div className={designForTable.AgeingBucketModelTableDiv}>
                      <Grid container spacing={1}>
                        {handleInputNumber >= 3 && handleInputNumber <= 50 && (
                          <>
                            <Grid
                              item
                              sm={4}
                              className={designForTable.AgeingBucketModelTable}
                            >
                              Due Bucket Name
                            </Grid>
                            <Grid
                              item
                              sm={4}
                              className={designForTable.AgeingBucketModelTable}
                            >
                              From Range
                            </Grid>
                            <Grid
                              item
                              sm={4}
                              className={designForTable.AgeingBucketModelTable}
                            >
                              To Range
                            </Grid>
                          </>
                        )}

                        {InputFunction()}
                      </Grid>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "end",
                    }}
                  >
                    {errorTable.length > 0 && (
                      <div>
                        From Range should be less than To Range Error In{" "}
                        <span style={{ color: "Black", fontWeight: "bold" }}>
                          Due Bucket Name:
                        </span>{" "}
                        {errorTable.map((v) => {
                          return (
                            <span style={{ marginLeft: "3px", color: "red" }}>
                              {v},
                            </span>
                          );
                        })}
                      </div>
                    )}
                    <div>
                      <Button
                        variant="contained"
                        style={{
                          background: "#138e9c",
                          color: "#fff",
                          marginRight: "10px",
                        }}
                        disabled={isDisableSubmitButton}
                        type="submit"
                        size="small"
                      >
                        Submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="small"
                        // style={{ color: "red" }}
                        onClick={handleCloseModel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DueBucket;
