import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import "./Cfo.css";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

export default function CfoDashBoard({ dataForSummary, dataForSummaryTotal }) {
  //..............Indian Rupees..............
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <tr>
          <td>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <DoNotDisturbOnIcon style={{ color: "black" }} />
              ) : (
                <AddCircleIcon style={{ color: "black" }} />
              )}
            </IconButton>
          </td>
          <td>{row.Partner.Partner_Name}</td>
          <td>{indianRupeesFormat(row.Partner.Sum_of_Total_Receivable)}</td>
          <td>{indianRupeesFormat(row.Partner.Sum_of_Receivable_till_Last)}</td>
          <td>
            {indianRupeesFormat(row.Partner.Sum_of_Receivable_till_Current)}
          </td>
          <td>{indianRupeesFormat(row.Partner.Sum_of_Balance_Receivable)}</td>
        </tr>

        <tr>
          {" "}
          <td colSpan={"6"}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Client Details/{row.Partner.Partner_Name}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <thead>
                    <TableRow>
                      <th>Name Of Client</th>
                      <th>Sum Of Total Receivables</th>
                      <th>Amt Received - Last Month</th>
                      <th>Amt Received Current Month</th>
                      <th>Balance Receivable Excl TDS</th>
                    </TableRow>
                  </thead>
                  <tbody>
                    {row.clients.map((value, index) => (
                      <TableRow key={index}>
                        <td>{value.Name_of_Clients}</td>
                        <td>
                          {indianRupeesFormat(value.Sum_of_Total_Receivable)}
                        </td>
                        <td>
                          {indianRupeesFormat(
                            value.Sum_of_Receivable_till_Last
                          )}
                        </td>
                        <td>
                          {indianRupeesFormat(
                            value.Sum_of_Receivable_till_Current
                          )}
                        </td>
                        <td>
                          {indianRupeesFormat(value.Sum_of_Balance_Receivable)}
                        </td>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Collapse>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* <Container style={{marginTop:"10px"}}> */}
      <div style={{ marginTop: "10px" }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Card style={{ marginTop: "5px", marginBottom: "5px" }}>
              <CardHeader
                style={{ backgroundColor: "#ddd", padding: "5px 0px 5px 20px" }}
                title="Sum Of Total Receivables"
                titleTypographyProps={{ variant: "subtitle1" }}
              />
              <CardContent>
                {dataForSummaryTotal.Total_Sum_of_Total_Receivable ? (
                  indianRupeesFormat(
                    dataForSummaryTotal.Total_Sum_of_Total_Receivable
                  )
                ) : (
                  <div>₹0</div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ marginTop: "5px", marginBottom: "5px" }}>
              <CardHeader
                style={{ backgroundColor: "#ddd", padding: "5px 0px 5px 20px" }}
                title="Amt Received - Last Month"
                titleTypographyProps={{ variant: "subtitle1" }}
              />
              <CardContent>
                {dataForSummaryTotal.Total_Sum_of_Receivable_till_Last ? (
                  indianRupeesFormat(
                    dataForSummaryTotal.Total_Sum_of_Receivable_till_Last
                  )
                ) : (
                  <div>₹0</div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ marginTop: "5px", marginBottom: "5px" }}>
              <CardHeader
                style={{ backgroundColor: "#ddd", padding: "5px 0px 5px 20px" }}
                title="Amt Received Current Month"
                titleTypographyProps={{ variant: "subtitle1" }}
              />
              <CardContent>
                {dataForSummaryTotal.Total_Sum_of_Receivable_till_Current ? (
                  indianRupeesFormat(
                    dataForSummaryTotal.Total_Sum_of_Receivable_till_Current
                  )
                ) : (
                  <div>₹0</div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ marginTop: "5px", marginBottom: "5px" }}>
              <CardHeader
                style={{ backgroundColor: "#ddd", padding: "5px 0px 5px 20px" }}
                title="Balance Receivable Excl TDS"
                titleTypographyProps={{ variant: "subtitle1" }}
              />
              <CardContent>
                {dataForSummaryTotal.Total_Sum_of_Balance_Receivable ? (
                  indianRupeesFormat(
                    dataForSummaryTotal.Total_Sum_of_Balance_Receivable
                  )
                ) : (
                  <div>₹0</div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <BoxWrapper boxTitle="Summary">
        <div className="table">
          <div className="tableResponisve">
            <table className="tablecss">
              <thead>
                <tr>
                  <th>Client Details</th>
                  <th>Partner Name</th>
                  <th>Sum Of Total Receivables</th>
                  <th>Amt Received - Last Month</th>
                  <th>Amt Received Current Month</th>
                  <th>Balance Receivable Excl TDS</th>
                </tr>
              </thead>
              <tbody>
                {dataForSummary.map((value) => (
                  <Row row={value} />
                ))}
                {dataForSummary.length === 0 && (
                  <tr>
                    <td
                      colSpan={"7"}
                      style={{ color: "red", textAlign: "center" }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </BoxWrapper>
    </React.Fragment>
  );
}
