import React, { Component, Fragment } from "react";
import axios from "../../../../../axios-order";

import './Loader.css'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
} from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Summary from "./summary";
import moment from "moment";
import MatchedInvoice from "./MatchedInvoice";
import MismatchedInvoice from "./MisMatched";
import MissingInAccount from "./MissingInAccount";
import MissingInGSTR from "./MissingInGSTR";
import VendorInvoiceSummary from "./VendorInvoiceSummary";
import AllInvoiceRecon from "./AllInvoiceRecon";
import ProbableMatchedInvoice from "./ProbableMatched";
import CryptoJS from "crypto-js";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const styles = function (theme) {
  return {
    root: {
      width: "400px",
    },
    tab: {
      [theme.breakpoints.up("md")]: {
        minWidth: "auto",
      },
    },
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGST2aReconReportData: {
        matched:[]
      },
      reconId: '',
      tabValue: 0,
      loading: true,
      urlValue: this.props.location.data || "",
      matchedInvoiceData: null,
      mismatchedInvoiceData: null,
      probablematchedData:null,
      missineAccountData:null,
      missingstr:null,
      Vendorsummarydata:null,
      allinvoicerecondata:null,
      isDownloading:false,
      progress:0,
      new_id: '',
      isDownloading: false,
    };

  }

  // componentDidMount() {
    
  //   const decrypt = (data) => {
  //     return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  //   };
  //   let activeUrl = window.location.href;
  //   let id = activeUrl.split("/");

  //   axios.get(`/gstr2b/summary/${decrypt(id[4])}`).then((res) => {
  //     if (typeof res.data === "undefined") {
  //     } else {
  //       this.setState({
  //         allGST2aReconReportData: res.data.record,
  //         loading: false,
  //       });
  //     }
  //   });
  // }

  componentDidMount() {
    const decrypt = (data) => {
      return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    };
    let activeUrl = window.location.href;
    let id = activeUrl.split("/");
    const reconId = decrypt(id[4])
    const new_id = reconId; 

  console.log("reconId:", reconId); 


    axios.get(`/gstr2b/summary/${decrypt(id[4])}`).then((res) => {
      if (typeof res.data === "undefined") {
        // Handle the case when the data is undefined
      } else {
        this.setState({
          allGST2aReconReportData: res.data.record,
          loading: false,
        });
      }
    });
  
    axios
      .post('/gstr2b_recon_summary', {
        org_gstin: localStorage.getItem('gst'),
      })
      .then((res) => {
        console.log(res);
        if (res.data.data && res.data.data.length > 0) {
          const reconId = res.data.data[0].recon_id;
          this.setState({ reconId });
          this.setState({new_id})
          // this.fetchData(reconId);
        } else {
          console.error('No recon_id found in the API response');
        }
      })
      .catch(() => console.log('Error in API request'));
  }

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const { new_id } = this.state;
    const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `gst_recon2b_export.xlsx`;

    const startTime = performance.now(); // Record the start time

    this.setState({ isDownloading: true, progress: 0 });

    axios
      .get(`/onlygstr2breport/summary/${new_id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          this.setState({ progress });
        },
      })
      .then((res) => {

        // const timeoutHeaderValue = res.headers.get('X-Content-Timeout');
        // const timeoutInSeconds = parseInt(timeoutHeaderValue);
        
        // clearInterval(interval); // Clear the interval once the response is received
        console.log("Start time:", startTime);
        console.log(res);
        if (res.status === 200) {
          if (res.data.size === 0) {
            console.error("No data available");
            this.setState({ isDownloading: false }); // Stop the loader
            return;
          }

          const blob = new Blob([res.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          // document.body.removeChild(link);

          const endTime = performance.now();
          const timeTaken = (endTime - startTime) / 1000;
          console.log(`Time taken to download: ${timeTaken} seconds`);
          console.log("End time:", endTime);
          console.log(`Time taken to download: ${timeTaken.toFixed(2)} seconds`);
        } else {
          console.error("Error occurred while fetching the data");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isDownloading: false });
      });
  };

  handleTabChangeHandler = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };


// Trial matched Invoices

handleTabChange = (event, newValue) => {
  this.setState({ tabValue: newValue });
};

//Trial mismatched invoices

handleTabChan = (event,newValue) => {
    this.setState({tabValue:newValue})
}






// fetchData = async (reconId) => {
//   try {
//     const response = await axios.get(`/gstr2b_running/${reconId}`);
//     console.log(response.data);
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// componentDidMount() {
//   axios
//     .post('/gstr2b_recon_summary', {
//       org_gstin: localStorage.getItem('gst'),
//     })
//     .then((res) => {
//       console.log(res);
//       if (res.data.data && res.data.data.length > 0) {
//         const reconId = res.data.data[0].recon_id;
//         this.setState({ reconId });
//         this.fetchData(reconId);
//       } else {
//         console.error('No recon_id found in the API response');
//       }
//     })
//     .catch(() => console.log('Error in API request'));
// }



handleMatchedInvoiceTabClick = async () => {
  // const { new_id } = this.state;
  
  try {
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_for_matched_invoices/${this.state.new_id}`);
    const data = response.data;
    this.setState({ matchedInvoiceData: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }
};

handleMatchedInvoiceTabCli = async () => {
   
   try {
    // const { reconId } = this.state;
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_for_mismatched_invoices/${this.state.new_id}`);
    const data = response.data;
    this.setState({ mismatchedInvoiceData: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }
}




handleProbableMatch = async ()  => {
  
  try {
  //  const { reconId } = this.state;
  const { new_id } = this.state;
   const response = await axios.get(`/gstr2b_dashboard_summary_for_probablematch_invoices/${this.state.new_id}`);
   const data = response.data;
   this.setState({ probablematchedData: data });
   console.log('API Response:', data);
 } catch (error) {
   console.error('API Error:', error);
 }
   
}

handleMissingAccount = async () => {
  
   try {
    // const { reconId } = this.state;
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_for_missing_in_books/${this.state.new_id}`);
    const data = response.data;
    this.setState({ missineAccountData: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }

}

handleMissingGSTR = async () => {
  
   try {
    // const { reconId } = this.state;
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_for_missing_in_gstr2b/${this.state.new_id}`);
    const data = response.data;
    this.setState({ missingstr: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }
}

handleVoiceSummary = async () => {
 
  try {
    // const { reconId } = this.state;
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_vendor_summary/${this.state.new_id}`);
    const data = response.data;
    this.setState({Vendorsummarydata: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }
}


handleallvoiceRecon =async () => {
   
   try {
    // const { reconId } = this.state;
    const { new_id } = this.state;
    const response = await axios.get(`/gstr2b_dashboard_summary_all_invoice/${this.state.new_id}`);
    const data = response.data;
    this.setState({allinvoicerecondata: data });
    console.log('API Response:', data);
  } catch (error) {
    console.error('API Error:', error);
  }
}










 

  render() {
    const { classes } = this.props;

    const { isDownloading, progress } = this.state;
    const isRolling = progress === 0;

    const { matchedInvoiceData,mismatchedInvoiceData,Vendorsummarydata ,probablematchedData,missineAccountData,missingstr, allinvoicerecondata} = this.state;
 
    return (
      <React.Fragment>
        <Container>
          {this.state.loading === true && (
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <CircularProgress />
              <br></br>
              Loading,Please wait...
            </div>
          )}

          {this.state.loading === false && (
            <>
              {/* ---sub header--- */}
              <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs={4}>
                  <div>
                    Report Dated :{" "}
                    {moment(
                      this.state.allGST2aReconReportData.entity_details
                        .Reconciliation_Date
                    ).format("DD-MM-YYYY")}
                    <br></br>
                    Threshold Used:{" "}
                    {
                      this.state.allGST2aReconReportData.entity_details
                        .Threshold_Value
                    }
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "16px" }}>
                      GSTR2B Reconciliation Report
                    </p>
                  
                    {isDownloading && (
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {!isDownloading && (
                    <button
                      className="button-outlined-primary"
                      onClick={this.downloadToExcel}
                    >
                      Export to Excel
                    </button>
        )}
        {isDownloading && !isRolling && (
          <div className="loader-container">
            <div className="loader-bar" ></div>
          </div>
        )}
          
         
      
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ float: "right" }}>
                    Report Period {" "}
                    <br></br>
                    Books Period :{" "}
                    {this.state.allGST2aReconReportData.entity_details.books_period}
                    <br></br>
                    GSTR Period :{" "}
                    {
                      this.state.allGST2aReconReportData.entity_details.gstr_period
                    }
                  </div>
                </Grid>
              </Grid>

              {/* tab panel */}
              <div className={classes.TabPanelSection}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChangeHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#fff", color: "#000" }}
                      label="Summary "
                      {...a11yProps(0)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#28a745", color: "#000" }}
                      label="Matched Invoices"
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "red", color: "#fff" }}
                      label="Mismatch Invoices"
                      {...a11yProps(2)}
                    />

                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ddd", color: "#000" }}
                      label="Probable Match"
                      {...a11yProps(3)}
                    />

                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffad33", color: "#000" }}
                      label="Missing in Accounts"
                      {...a11yProps(4)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffbf80", color: "#000" }}
                      label="Missing in GSTR2B"
                      {...a11yProps(5)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#b3d9ff", color: "#000" }}
                      label="Vendor wise Summary"
                      {...a11yProps(6)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffff4d", color: "#000" }}
                      label="All Invoice(s) Recon"
                      {...a11yProps(7)}
                    />
                  </Tabs>
                </AppBar>

                {/*  Summary Dashboard */}
                <TabPanel value={this.state.tabValue} index={0}>
                  <BoxWrapper boxTitle="Summary">
                    <Summary
                      SummaryData={
                        this.state.allGST2aReconReportData.gstSummaryDashBoard
                      }
                    />
                  </BoxWrapper>
                </TabPanel>

                {/* Matched*/}
                {/* <TabPanel value={this.state.tabValue} index={1}>
                  <MatchedInvoice
                    matchedInvoiceData={
                      this.state.allGST2aReconReportData.matched
                    }
                  />
                </TabPanel> */}

  {/* Matched*/}
<TabPanel value={this.state.tabValue} index={1} >
  <MatchedInvoice
    // matchedInvoiceData={this.state.allGST2aReconReportData.matched}
    matchedInvoiceData={matchedInvoiceData}
   
     onTabClick={this.handleMatchedInvoiceTabClick}
  />
                </TabPanel>

                {/* .......Mis-Matched....... */}
                {/* <TabPanel value={this.state.tabValue} index={2}>
                  <MismatchedInvoice
                    mismatchedInvoiceData={
                      this.state.allGST2aReconReportData.missMatched
                    }
                  />
                </TabPanel> */}

 {/* .......Mis-Matched....... */}

    <TabPanel value={this.state.tabValue} index={2} onChange={this.handleTabChange}>
                  <MismatchedInvoice
                  
                  onTabClick={this.handleMatchedInvoiceTabCli}
                  mismatchedInvoiceData={mismatchedInvoiceData}
                  />
                </TabPanel>

                {/* ........Probable Matched........ */}
                {/* <TabPanel value={this.state.tabValue} index={3}>
                  <ProbableMatchedInvoice
                    probableMatchedInvoiceData={
                      this.state.allGST2aReconReportData.ProbableMatching
                    }
                  />
                </TabPanel> */}

                {/* Probable Match */}
                  <TabPanel value={this.state.tabValue} index={3} onChange={this.handleTabChange}>
                  <ProbableMatchedInvoice
                    onTabClick={this.handleProbableMatch}
                    probablematchedData={probablematchedData}
                  />
                </TabPanel>

                {/* .........Missing in Account......... */}
                {/* <TabPanel value={this.state.tabValue} index={4}>
                  <MissingInAccount
                    missingInAccountData={
                      this.state.allGST2aReconReportData.missingInAccounts
                    }
                  />
                </TabPanel> */}


                   {/* .........Missing in Account......... */}
                   <TabPanel value={this.state.tabValue} index={4} onChange={this.handleTabChange}>
                  <MissingInAccount
                    onTabClick={this.handleMissingAccount}
                    missineAccountData={missineAccountData}
                  />
                </TabPanel>

                {/* Missing in GSTR1 */}
                {/* <TabPanel value={this.state.tabValue} index={5}>
                  <MissingInGSTR
                    missingInGSTRData={
                      this.state.allGST2aReconReportData.missingInGstr
                    }
                  />
                </TabPanel> */}

           {/* Missing in GSTR1 */}

           <TabPanel value={this.state.tabValue} index={5} onChange={this.handleTabChange}>
                  <MissingInGSTR
                  onTabClick={this.handleMissingGSTR}
                  missingstr={missingstr}
                  />
                </TabPanel>

                {/* Vendor Invoice Summary */}
                {/* <TabPanel value={this.state.tabValue} index={6}>
                  <VendorInvoiceSummary
                    VendorInvoiceSummaryData={
                      this.state.allGST2aReconReportData.VendorInvoiceSummary
                    }
                  />
                </TabPanel> */}

                {/* {Vendor Voice Summary} */}
                <TabPanel value={this.state.tabValue} index={6} onChange={this.handleTabChange}>
                  <VendorInvoiceSummary
                   onTabClick={this.handleVoiceSummary}
                   Vendorsummarydata={Vendorsummarydata}  
                  />
                </TabPanel>

                {/* All invoice Recon */}
                {/* <TabPanel value={this.state.tabValue} index={7}>
                  <AllInvoiceRecon
                    AllInvoiceReconData={
                      this.state.allGST2aReconReportData.allInvoiceRecon
                    }
                  />
                </TabPanel> */}
         {/* {All Invoice  Recon} */}
<TabPanel value={this.state.tabValue} index={7} onChange={this.handleTabChange}>
                  <AllInvoiceRecon
                     onTabClick={this.handleallvoiceRecon}
                     allinvoicerecondata={allinvoicerecondata}
                  />
                </TabPanel>
              </div>
            </>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

Showdetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Showdetails);
