import React, { useState, useEffect } from "react";
import "./dashboard.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const InvoiceTable = (props) => {
  const { data, loader } = props;

  // indian rupeess system---
  const indianRupessFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 10,
    }).format(value?.toFixed(2));
  // ---End---

  return (
    <div className="invoice-details">
      <div className="responsive-table">
        <table className="custome-long-table">
          <thead>
            {data.length === 0 && (
              <tr>
                <th>Customer GSTIN</th>
                <th>Customer PAN</th>
                <th>Customer Name</th>
                <th>Customer Code</th>
                <th>Account Manager</th>
                <th>Registration Type</th>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
              </tr>
            )}
            <tr>
              {data.length > 0 &&
                Object.keys(data[0]).map((item) => {
                  return <th>{item}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr>
                    {Object.keys(item).map((v, i) => {
                      if (
                        "Advance / Excess Amount" === v ||
                        "Amount Outstanding" === v ||
                        "Amount Collected" === v ||
                        "TDS Collected" === v ||
                        "GST Amount" === v ||
                        "Non Taxable Amount" === v ||
                        "Taxable Amount" === v ||
                        "Total Invoice Amount" === v
                      ) {
                        return <td>{indianRupessFormat(item[v]) ?? ""}</td>;
                      } else {
                        return <td>{item[v] ?? ""}</td>;
                      }
                    })}
                  </tr>
                );
              })}

            {data.length === 0 && loader === false && (
              <tr>
                <td style={{ color: "red" }}>No Data Found!</td>
              </tr>
            )}

            {loader && (
              <tr>
                <td>Loading.Please wait...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default InvoiceTable;
