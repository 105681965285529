import React, { Component } from "react";
import classes from "./ARCCollections.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SellerCollection from "../../../../asserts/templates/vendor_payment.xlsx";
import ClearIcon from "@material-ui/icons/Clear";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgress from "@material-ui/core/CircularProgress";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import CustomTable from "../../../../components/ui/Table/Table";
import "react-toastify/dist/ReactToastify.css";
import { TablePagination } from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";
import ZohoModel from "../../../../components/ZohoModel/ZohoModel";

let flagForZoho = 1;
let variableForTimerForZohoInCollection = null;

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 15,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  // if (typeof value === "undefined") return false;
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};

const dateFormatWithDash = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
  }
};

const indianFormat = (date) => {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};

class Collections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      effitracCol: [
        {
          key: "customerCode",
          render: (index, row) => {
            return row["erpVendorCode"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["vendorGstin"];
          },
        },
        {
          key: "collectionDate",
          render: (index, row) => {
            // return dateFormatWithDash(row['collectionDate']);
            return row["collectionDate"];
          },
        },

        {
          key: "collectionAmount",
          render: (index, row) => {
            return numberFormat(row["amountPaid"]);
          },
        },

        {
          key: "collectionRefNo",
          render: (index, row) => {
            return row["collectionReferenceNumber"];
          },
        },
        {
          key: "bankAccountNo",
          render: (index, row) => {
            return row["bankAccountNumber"];
          },
        },
        // {
        //   key: 'fetrack',
        //   render: (index, row) => {
        //     return row['fetrack'];
        //   },
        // },
      ],
      effitracData: null,
      oldEffitracData: null,
      editCollectionData:{},
      editOpenDrawer:false,
      effitracHeader: [
        { label: "ERP Vendor Code" },

        { label: "GSTIN" },
        { label: "PAYMENT Date" },
        { label: "PAYMENT Amount" },

        { label: "TRANSACTION Ref No" },
        { label: "Bank Account No" },
        // { label: 'Fetrack' },
      ],

      read_only: "",
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["GSTIN"];
          },
        },
        {
          label: "Invoice Number",
          render: (name, all) => {
            return all["record"]["InvoiceNo"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,

      allArcCollectionData: [],
      OriginalAllArcCollectionData: [],
      showUploadData: false,
      loading: false,

      // add collection modal
      allArcSellerCustomerData: [],
      OriginalDataAllArcSellerCustomerData: [],
      collectionModalBackDrop: false,

      //............Edited by Harsh..............
      openZohoDrawer: false,
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      grantToken: "",
      url: "",
      zohoDate: {},

      // import effitrac / tally
      openImportEffitracDrawer: false,
      effitracFetchFromData: {},
      lastUploadDate: "",

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",

      // upload history table data
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      flagForZohoInCollection: false,
      timerForZohoInCollection: 60,
      // variableForTimerForZohoInCollection:null ,
      loader: true,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.sellerCollectionDataGetAPi(event, this.state.searchInput);
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.sellerCollectionDataGetAPi(event, this.state.searchInput);
    });
  };
  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.sellerCollectionDataGetAPi(event, this.state.searchInput);
      }
    );
  };

  // seller collection get api function
  sellerCollectionDataGetAPi = (event, serachIn) => {
    axios
      .post(`/get_vendor_collection_details`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_text: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcCollectionData: res.data.record,
          OriginalAllArcCollectionData: res.data.record,
          rowCount: res?.data?.row_count,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
      });
  };

  getSellerCollectionLastUploadDate = () => {
    const customer = "Seller Collection";
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/${customer}`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = indianFormat(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDate: Date,
          lastUploadTime: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${16}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });

    let drawer = domain.get("drawer");
    drawer &&
      this.setState({
        openZohoDrawer: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    this.getApiForClientID();
    this.sellerCollectionDataGetAPi();
    this.getSellerCollectionLastUploadDate();
    this.getHistoryTableUploadData();
  }

  componentDidUpdate() {
    if (
      this.state.buttonColor === "green" &&
      !this.state.flagForZohoInCollection
    ) {
      this.setState({ flagForZohoInCollection: true });
      variableForTimerForZohoInCollection = setInterval(() => {
        this.setState((prevstate) => ({
          timerForZohoInCollection: prevstate.timerForZohoInCollection - 1,
        }));
      }, 1000);
    }

    if (this.state.timerForZohoInCollection === 0) {
      clearInterval(variableForTimerForZohoInCollection);
      this.setState({
        flagForZohoInCollection: false,
        timerForZohoInCollection: 10,
        buttonColor: "red",
      });
    }
  }

  handleSearchInputChangeForZohoEffitrack = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchData = this.state.oldEffitracData.filter((value) => {
      const query = searchInput.trim().toLowerCase();
      return (
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_code).toLowerCase().indexOf(query) >= 0 ||
        String(numberFormat(value.collection_amount))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(value.collection_amount).toLowerCase().indexOf(query) >= 0 ||
        String(value.collection_date).indexOf(query) >= 0 ||
        String(value.collection_ref_no).toLowerCase().indexOf(query) >= 0 ||
        String(value.bank_account_no).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ effitracData: searchData });
  };

  fileUploader = (event, serachIn) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendorcollection`, {
          file: file_value,
          org_code: localStorage.getItem("business_entity_id"),
        })
        .then((data) => {
          console.log(data.data);
          // dis.getHistoryTableUploadData();
          // dis.setState({ loading: false });
          // axios
          //   .post(`/arc/sellerCollection`, {
          //     number_of_rows_per_page: this.state.rowsPerPage,
          //     page_number: this.state.page,
          //     search_text: serachIn,
          //     seller_code: localStorage.getItem("business_entity_id"),
          //   })
          //   .then((res) => {
          //     dis.setState({
          //       allArcCollectionData: res.data.records,
          //       OriginalAllArcCollectionData: res.data.records,
          //       rowCount: res?.data?.row_count,
          //     });
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        })
        .catch((error) => {
          // toast.error(error, { autoClose:5000, progress: 0 })
        });
    };
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.sellerCollectionDataGetAPi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerCollection}
        download={"vendor_payment.xlsx"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>
      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        UPLOAD TEMPLATE{" "}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>
      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
    </div>
  );

  // ------------------------------------------ARC collection modal[ customer table] end---------------------------

  // table
  arc_collection_TableHeader() {
    const tableHeader = [
      "Vendor name",
      "gstin",
      "Pan",
      "ERP Vendor Code",
      "Payment Date",
      "Amount Paid",
      "transaction ref no",
      "bank account number",
      'Action',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // table body
  arc_collection__TableData() {
    const editCollectionDrawer = (
      tds_tcs_section,
      voucherId,
     creditLedger,
       group,
       nonCashTxnSettlement,
     billNumber,
     flag,
     modified_date,
     created_date, 
    ) => {
      // let collectionDate = dateFormat(collection_date);
      this.setState({
        editCollectionData: {
          tds_tcs_section,
          voucherId,
         creditLedger,
           group,
           nonCashTxnSettlement,
         billNumber,
         flag,
         modified_date,
         created_date, 
        },
        editOpenDrawer:true
      });
    };

    console.log(this.state.editCollectionData);

    if (this.state.allArcCollectionData.length >= 1) {
      return this.state.allArcCollectionData.map((tableData, index) => {
        const {
          vendorName,
          vendorPan,
          vendorGstin,
          bankAccountNumber,
          erpVendorCode,
          amountPaid,
          collectionDate,
          collectionReferenceNumber,
          tds_tcs_section,
          voucherId,
         creditLedger,
           group,
           nonCashTxnSettlement,
         billNumber,
           flag,
           modified_date,
          created_date,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{vendorName}</td>
            <td> {vendorGstin}</td>
            <td> {vendorPan}</td>
            <td>{erpVendorCode}</td>
            <td>{collectionDate}</td>
            <td>{numberFormat(amountPaid)}</td>
            <td>{collectionReferenceNumber}</td>
            <td>{bankAccountNumber}</td>
            <td>
              <RemoveRedEyeOutlinedIcon 
               className="action-icon-button showIcon"
               onClick={() =>
                editCollectionDrawer(
                  tds_tcs_section,
                  voucherId,
                 creditLedger,
                   group,
                   nonCashTxnSettlement,
                 billNumber,
                 flag,
                 modified_date,
                 created_date,  
                )
              }
              />
            </td>
            {/* <td>{createDate.split(" ")[0]}</td>
            <td>{modifyDate.split(" ")[0]}</td> */}
            {/* <td>
              <Button
                onClick={() =>
                  editCollectionDrawer(
                    cust_code,
                    cust_name,
                    cust_gstin,
                    collection_date,
                    collection_amount,
                    create_date,
                    modify_date,
                    bank_account_number,
                    collection_ref_no
                  )
                }
              >
                Edit
              </Button>
            </td> */}
          </tr>
        );
      });
    } else if (this.state.loader) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={10}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={10}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = (datafromfun) => {
 
    let data = {}
    if (this.state.openZohoDrawer) {
      data = {
        zoho_data:datafromfun,
        business_entity_id:localStorage.getItem("business_entity_id")
      }  
    } else {
      data = {
        business_entity_id:localStorage.getItem("business_entity_id")
      }
    }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Vendor Payment.xlsx`;

    axios
      .post("/vendor/vendorcollectiondownload", data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ------------------------edit collection end-----------------------
  closeUploadData = () => {
    this.setState({ showUploadData: false });
    this.sellerCollectionDataGetAPi();
  };

  showToggleDrawerClose = () => {
    this.setState({ showDrawer: false , editOpenDrawer:false });
  };

  // add collection modal
  addCollectionModalOpen = () => {
    this.setState({
      collectionModalBackDrop: true,
    });
  };

  //...............Edited By Harsh Handle Drawer.........
  handleZohoDrawer = () => {
    flagForZoho = 1;
    this.setState({ openZohoDrawer: true });
  };

  closeZohoDrawer = () => {
    clearInterval(variableForTimerForZohoInCollection);
    this.setState({
      zohoDate: {},
      openZohoDrawer: false,
      buttonColor: "red",
      messageForConnection: "Establish Connection",
      grantToken: "",
    });
  };

  // import effitrac / tally form section
  importEffitracTallyOpenDrawer = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        createdFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        createdToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importModifiedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  fetchEffitacFormSubmitHandler = () => {
    toast.configure();
    this.setState({ effitracFormSubmitLoader: true });
    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      },
    }));
    const data = this.state.effitracFetchFromData;
    axios
      .post("/arc/effitrac/collectionreport", data)
      .then((res) => {
        this.setState({
          isEffitrac: true,
          effitracData: res.data.records.records,
          oldEffitracData: res.data.records.records,
          importEffitracFetchData: {},
          openImportEffitracDrawer: false,
          effitracFormSubmitLoader: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ effitracFormSubmitLoader: false });
      });
  };

  closeEffitracTallyOpenDrawer = () => {
    this.setState({
      openImportEffitracDrawer: false,
      effitracFetchFromData: {},
    });
  };

  // import to cws button onclick--
  importToCWS = (dataForFun) => {
    toast.configure();
    axios
      .post("/arc/effitrac/vendorcollection", {
        business_entity_id: localStorage.getItem("business_entity_id"),

        records: dataForFun.map((row, index) => {
          let newRow = { ...row };
          newRow["collectionDate"] = dateFormatWithDash(
            newRow["collectionDate"]
          );

          return newRow;
        }),
      })
      .then((res) => {
        this.setState({ showUploadHistroyTable: true });
        this.refreshUploadHistoryTable();
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
      })
      .catch((error) => {
        toast.error("Server error try after same time", {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // back button after import effitrac data
  backHandler = () => {
    this.setState({ isEffitrac: false, showUploadHistroyTable: false });
  };

  establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.settings.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${
      this.state.url
    }/zohoTesting&access_type=offline&prompt=Consent`;
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    }
  };

  fetchingdataForZoho = () => {
    clearInterval(variableForTimerForZohoInCollection);
    flagForZoho += 1;
    toast.configure();
    if (flagForZoho === 2) {
      this.setState({ effitracImportFormloader: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "purchase_payment",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              zohoDate: {},
              effitracImportFormloader: false,
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
          } else {
            this.setState({
              effitracImportFormloader: false,
              zohoDate: {},
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            effitracImportFormloader: false,
            zohoDate: {},
            buttonColor: "red",
            messageForConnection: "Establish Connection",
            grantToken: "",
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({
          showUploadData: true,
          uploadStatusFail: response.data.records,
        });
        let res = response.data.records.records;
        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `CollectionUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <ZohoModel
          openZohoDrawer={this.state.openZohoDrawer}
          buttonColor={this.state.buttonColor}
          establishConnection={this.establishConnection}
          messageForConnection={this.state.messageForConnection}
          timerForZohoInSalesCustomer={this.state.timerForZohoInCollection}
          closeZohoDrawer={this.closeZohoDrawer}
          zohoDateChangeHandler={this.zohoDateChangeHandler}
          effitracImportFormloader={this.state.effitracImportFormloader}
          fetchingdataForZoho={this.fetchingdataForZoho}
          zohoDate={this.state.zohoDate}
          logApitName="purchase_payment"
          nameOfTheModel="Payment Data"
          importToCWS={this.importToCWS}
          exportToExcel={this.export_to_excel}
          zohoTableHeader={this.state.effitracHeader}
          zohoTableColumn={this.state.effitracCol}
          drawerKeys={[]}
        />

        {/* import Collection effitrac/tally */}
        <RightDrawer
          drawerName="import Effitrac/Tally"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeEffitracTallyOpenDrawer}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.fetchEffitacFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDate} {this.state.lastUploadTime}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>Create From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="fromDate"
                value={this.state.effitracFetchFromData.createdFromDate ?? ""}
                onChange={(v) => this.importFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Create To Date:</label>
              <ValidatedDatePicker
                name="createdToDate"
                value={this.state.effitracFetchFromData.createdToDate ?? ""}
                onChange={(v) => this.importToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={this.state.effitracFetchFromData.modifiedFromDate ?? ""}
                onChange={(v) => this.importModifiedFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.effitracFetchFromData.modifiedToDate ?? ""}
                onChange={(v) => this.importModifiedToDateChangeHandler(v)}
              />
            </div>

            {/* submit and back button */}
            <div className="mt-20">
              {this.state.effitracFormSubmitLoader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeEffitracTallyOpenDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* upload temp Button */}
        {(localStorage.getItem('is_uploads') !== "N")&&<>
        <div>
          <div>
            <ToggleSwitch
              onChange={() =>
                this.setState({
                  showUploadHistroyTable: !this.state.showUploadHistroyTable,
                })
              }
              value={this.state.showUploadHistroyTable}
              label="Upload Payment"
            />
          </div>
        </div>

        {/* File upload history table  */}
        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                !this.state.isEffitrac &&
                true
              }
              zohoEffitracFn={this.handleZohoDrawer}
              enableImportToEffTallybutton={
                localStorage.getItem("Trigger_Effitrac/Tally_Button") &&
                localStorage.getItem("Trigger_Effitrac/Tally_Button") ===
                  "true" &&
                !this.state.isEffitrac
              }
              importToEffiTallyFn={this.importEffitracTallyOpenDrawer}
            >
              <div style={{ marginBottom: "10px" }}>
                {" "}
                <this.CollectionListActions />
              </div>

              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === "In Progress" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled"
                                color="disabled"
                              />
                            ) : item.status === "Uploaded" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled"
                                color="disabled"
                              />
                            ) : (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button showIcon"
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}
        </>}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        <BoxWrapper
          boxTitle="Payment Details"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={() =>
            this.export_to_excel(this.state.allArcCollectionData)
          }
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={
            this.state.isEffitrac
              ? this.handleSearchInputChangeForZohoEffitrack
              : this.handleSearchInputChange
          }
          // back button
          backButtonForZohoEnable={this.state.isEffitrac}
          backButton={this.backHandler}
          // import to cws button
          importToCwsEnableForZoho={this.state.isEffitrac}
          importToCwsForZoho={this.importToCWS}
        >
          {this.state.isEffitrac ? (
            <CustomTable
              headers={this.state.effitracHeader}
              columns={this.state.effitracCol}
              data={this.state.effitracData}
            />
          ) : (
            <>
              <div className="responsive-table">
                <table className="custome-table">
                  <tbody>
                    <tr>{this.arc_collection_TableHeader()}</tr>
                    {this.arc_collection__TableData()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </BoxWrapper>

        <RightDrawer
          drawerName="Payment Details"
          open={this.state.editOpenDrawer}
          onClose={this.showToggleDrawerClose}
        >
          {Object.keys(this.state.editCollectionData).length && (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="TDS/TCS Section"
                value={this.state.editCollectionData.tds_tcs_section}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Voucher ID"
                value={this.state.editCollectionData.voucherId}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Credit Ledger"
                value={this.state.editCollectionData.creditLedger}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Group"
                value={this.state.editCollectionData.group}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Non-cash Txn Settlement(Y/N)"
                value={this.state.editCollectionData.nonCashTxnSettlement}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Bill No"
                value={this.state.editCollectionData.billNumber}
              />
           
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Flag"
                value={this.state.editCollectionData.flag}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.editCollectionData.modified_date}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.editCollectionData.created_date}
              />
            </>
          )}
    
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.showToggleDrawerClose}
          >
            close
          </button>
        </RightDrawer>

      </React.Fragment>
    );
  }
}

export default Collections;
