import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TDSRecon.css";
import SelectField from "../../../../components/ui/basicSelectInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function TDSReconciliation() {
  let history = useHistory();
  const [newReconFormOpen, setNewReconFormOpen] = useState(false);
  const [newReconFormData, setNewReconFormData] = useState({
    business_entity_id: localStorage.getItem("business_entity_id"),
  });
  const [newReconHistoryTable, setNewReconHistoryTable] = useState([]);
  const [yearFromArr, setFromYearArr] = useState([]);
  const [yearToArr, setToYearArr] = useState([]);

  const [revenueCheck, setRevenueCheckbox] = useState(false);
  const [nontaxableCheck, setNontaxableCheckbox] = useState(false);

  const [errorMessageForBoxWrapper, setErrorMessageForBoxWrapper] =
    useState("");
  const [closeBoxWrapper, setCloseBoxWrapper] = useState(false);

  const [loaderForTable, setLoaderForTable] = useState(true);

  const getTdsReconDetails = () => {
    toast.configure();
    axios
      .get(
        `/form26/tds_recon_details/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTable(false);
        setNewReconHistoryTable(res.data.records);
      })
      .catch((error) => {
        setLoaderForTable(false);
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  const setFromYearValues = () => {
    let current_year = new Date().getFullYear();
    let yearArr = [];
    for (let i = current_year - 10; i <= current_year + 10; i++) {
      yearArr.push({ id: i, year: `${i}-${i + 1}` });
    }
    setFromYearArr(yearArr);
  };

  const setToYearValues = (fromYear) => {
    let current_year = new Date().getFullYear();
    if (fromYear) {
      let selected_year = fromYear;
      let yearArr = [];
      for (let i = selected_year; i <= current_year + 10; i++) {
        yearArr.push({ id: i, year: `${i}-${i + 1}` });
      }
      setToYearArr(yearArr);
    } else {
      let yearArr = [];
      for (let i = current_year - 10; i <= current_year + 10; i++) {
        yearArr.push({ id: i, year: `${i}-${i + 1}` });
      }
      setToYearArr(yearArr);
    }
  };

  useEffect(() => {
    getTdsReconDetails();
    setFromYearValues();
    setToYearValues(null);
  }, []);

  // new recon form
  const newReconFormModalOpen = () => {
    setNewReconFormOpen(true);
  };

  const newReconYearChangeHandler = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    if (name === "fromYear") {
      setToYearValues(value);
    }
    setNewReconFormData({
      ...newReconFormData,
      [name]: value,
    });
  };

  const newReconFormSubmitHandler = () => {
    toast.configure();
    setRevenueCheckbox(false);
    setNontaxableCheckbox(false);
    axios
      .post("/form26/upload", newReconFormData)
      .then((res) => {
        setNewReconFormOpen(false);
        setNewReconFormData({
          business_entity_id: localStorage.getItem("business_entity_id"),
        });
        getTdsReconDetails();
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else { toast.warning(res?.data?.message); }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };

  const newReconFormModalClose = () => {
    setNewReconFormOpen(false);
    setNewReconFormData({
      business_entity_id: localStorage.getItem("business_entity_id"),
    });
    setRevenueCheckbox(false);
  };

  // history table
  const historyTableBody = () => {
    const showErrorDetails = (item) => {
      axios
        .get(`/form_26/get_json/${item.id}`)
        .then((res) => {
          setErrorMessageForBoxWrapper(res?.data?.records?.message);
          setCloseBoxWrapper(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const downloadBase64File = (contentType, base64Data, fileName) => {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    };

    const updatedShowDetails = (item) => {
      localStorage.setItem("TDS_report_json_id", `${item.id}`);
      history.push({
        pathname: `/TDSReportSaleV`,
      });

      // const contentType =
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      // const filename = `TDS Receivables Reconciliation Report.xlsx`;

      // axios
      //   .get(`/form_26/get_json/${item.id}`)
      //   .then((res) => {
      //     downloadBase64File(contentType, res.data.data, filename);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    };

    if (loaderForTable) {
      console.log("Loader");
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={4}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (newReconHistoryTable && newReconHistoryTable.length > 0) {
      return newReconHistoryTable.map((item, index) => {
        const { fiscal_year, datetime, status } = item; //destructuring
        return (
          <tr key={index}>
            <td>{fiscal_year}</td>
            <td>{datetime}</td>
            <td>{status}</td>
            <td>
              {status === "Pass" && (
                // <FileDownloadIcon
                //   className="action-icon-button showIcon"
                //   onClick={() => updatedShowDetails(item)}
                // />
                <VisibilityOutlinedIcon
                  className="action-icon-button showIcon"
                  onClick={() => updatedShowDetails(item)}
                />
              )}

              {status === "Fail" && (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  onClick={() => showErrorDetails(item)}
                />
              )}

              {status === "Initiated" && (
                <VisibilityOutlinedIcon
                  className="action-icon-button deleteIcon"
                  color="disabled"
                />
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={4}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  const handleRevenueCheckboxHandler = (event) => {
    const checked = event.target.checked;
    setNontaxableCheckbox(false);
    if (checked) {
      setRevenueCheckbox(checked);
      if (newReconFormData.revnue) {
        setNewReconFormData({
          ...newReconFormData,
          ["revenue"]: { taxable_value: "Yes", non_taxable_value: "Yes" },
        });
      } else {
        setNewReconFormData({
          ...newReconFormData,
          ["revenue"]: { taxable_value: "Yes" },
        });
      }
    } else {
      setRevenueCheckbox(false);
    }
  };

  const handleNontaxableCheckboxHandler = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setNontaxableCheckbox(checked);
      if (newReconFormData.revnue) {
        setNewReconFormData({
          ...newReconFormData,
          ["revenue"]: { taxable_value: "Yes", non_taxable_value: "Yes" },
        });
      } else {
        setNewReconFormData({
          ...newReconFormData,
          ["revenue"]: { non_taxable_value: "Yes" },
        });
      }
    } else {
      setNontaxableCheckbox(false);
    }
  };

  const refreshUploadHistoryTable = () => {
    getTdsReconDetails();
  };

  const FunForcloseBoxWapper = () => {
    setCloseBoxWrapper(false);
  };

  return (
    <>
      <div className="new-reconciliation-form">
        <button
          className="button-outlined-primary"
          onClick={newReconFormModalOpen}
        >
          New TDS Reconciliation
        </button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={newReconFormOpen}
          onHide={newReconFormModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton className="modal-header">
            <Modal.Title className="modal-title">
              New TDS Reconciliation
            </Modal.Title>
          </Modal.Header>
          <div style={{ color: "green", padding: "15px 0px 0px 15px" }}>
            DN and CN are included
          </div>
          <ValidatorForm
            // ref="form"
            onSubmit={newReconFormSubmitHandler}
          // onError={(errors) => console.log(errors)}
          >
            <Modal.Body>
              <div className="new-recon-form-body">
                <Grid container spacing={3}>
                  <Grid item sm>
                    <SelectField
                      SelectLabel="From Year"
                      validator={["required"]}
                      requiredField="*"
                      errorMsg={["This field is required"]}
                      choice_id="id"
                      choice_name="year"
                      choices={yearFromArr}
                      name="fromYear"
                      value={newReconFormData.fromYear}
                      textOnchangeFunction={newReconYearChangeHandler}
                    />
                  </Grid>
                  <Grid item sm>
                    <SelectField
                      SelectLabel="To Year"
                      validator={["required"]}
                      requiredField="*"
                      errorMsg={["This field is required"]}
                      choice_id="id"
                      choice_name="year"
                      choices={yearToArr}
                      name="toYear"
                      value={newReconFormData.toYear}
                      textOnchangeFunction={newReconYearChangeHandler}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                  <Grid item sm={12} style={{ paddingBottom: "0px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleRevenueCheckboxHandler}
                          name="checkedB"
                          color="primary"
                          checked={revenueCheck}
                        />
                      }
                      label="If you want revenue report"
                    />
                  </Grid>

                  {revenueCheck && (
                    <Grid item sm={12} style={{ paddingTop: "0px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleNontaxableCheckboxHandler}
                            name="checkedB"
                            color="primary"
                            checked={nontaxableCheck}
                          />
                        }
                        label="Non-Taxable"
                      />
                    </Grid>
                  )}
                </Grid> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="button-primary"
                type="submit"
                style={{ marginRight: "10px" }}
              >
                Submit
              </button>
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={newReconFormModalClose}
              >
                Close
              </button>
            </Modal.Footer>
          </ValidatorForm>
        </Modal>
      </div>

      {closeBoxWrapper && (
        <BoxWrapper
          boxTitle="Error"
          cutIcon={true}
          children={errorMessageForBoxWrapper}
          closeBoxWapper={FunForcloseBoxWapper}
          colorForMessage="red"
          ErrorIcon={<ErrorOutlineOutlinedIcon />}
        />
      )}

      {/* History table */}
      <BoxWrapper
        boxTitle="TDS Reconciliation"
        // add button
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={refreshUploadHistoryTable}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>Fiscal Year</th>
                <th>Date Time</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{historyTableBody()}</tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}
