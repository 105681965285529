import React, { useState, useEffect } from 'react';
import BoxWrapper from '../boxwra';
import ConfirmationDialog from '../../../Sales/TDSReconciliationSale/ConfirmationDailog';

function Five({ handleBack, updateTableData, data }) {
  const [tableData, setTableData] = useState(data);
  const [tableName, setTableName] = useState("5.Details of outward supplies made during the financial year on which tax is not payable")

  const [saveDailog, setSaveDailogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [confirmBack, setConfirmBack] = useState(false);

  const submitHandle = () => {
    updateTableData(tableName, tableData)
    setUnsavedChanges(false)
  };





  const handleBackButton = () => {
    if (unsavedChanges) {
      setConfirmBack(true);
    } else {
      handleBack();
    }
  };

  const confirmBackHandle = () => {
    submitHandle()
    handleBack()
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [itemName, field] = name.split('.');

    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === itemName
          ? { ...item, [field]: value }
          : item
      )
    );

    setUnsavedChanges(true)
  };

  const getSubTotalRowValue = (item, key_name) => {
    // console.log('caling............', item.name)
    // console.log(key_name)
    let value = 0
    switch (item.name) {
      case "(G) Sub total (A to F above)":
        for (let i = 0; i < 6; i++) {
          value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }
        return value.toFixed(2)
      case "(L) Sub total (H to K above)":
        for (let i = 7; i < 11; i++) {
          if (i===7){
            value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else if (i===10){
            value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else{
            value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }
         
        }
        return value.toFixed(2)
      case "(M) Turnover on which tax is not to be paid  (G + L) above":
        let l_value = 0
        let g_value = 0
        for (let i = 7; i < 11; i++) {
          if (i===7){
            l_value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else if (i===10){
            l_value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else{
            l_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }
        }
        for (let i = 0; i < 6; i++) {
          g_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }
        
        const value_1 = parseFloat(l_value) + parseFloat(g_value)
        return value_1.toFixed(2)

      case "(N) Total Turnover (including advances) (4N + 5M - 4G) above":
        const storedTableItemsJSON = localStorage.getItem('tableItems');

        // Parse JSON string back into an object
        const storedTableItems = JSON.parse(storedTableItemsJSON);

        let l1_value = 0
        let g1_value = 0
        for (let i = 7; i < 11; i++) {
          if (i===7){
            l1_value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else if (i===10){
            l1_value -= tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }else{
            l1_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;
          }
        }
        for (let i = 0; i < 6; i++) {
          g1_value += tableData[i][key_name] ? parseFloat(tableData[i][key_name]) : 0;

        }

        
        const m_val =  parseFloat(l1_value) + parseFloat(g1_value)

        const res = parseFloat(storedTableItems['four_n'][key_name]) + m_val - parseFloat(storedTableItems['four_g'][key_name])
        return res.toFixed(2)
       

      

       
    }
    // console.log(value)
    return value.toFixed(2)
  }

  const renderTableRows = () => {
    return tableData.map((item, index) => {
      switch (true) {
        case item.name === "(G) Sub total (A to F above)":
        case item.name === "(L) Sub total (H to K above)":
        case item.name === "(M) Turnover on which tax is not to be paid  (G + L) above":
        case item.name === "(N) Total Turnover (including advances) (4N + 5M - 4G) above":
          return (
            <tr key={index} style={{ backgroundColor: 'white' }}>
              <td>{item.name}</td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.taxable_value`}
                  value={getSubTotalRowValue(item, 'taxable_value')}
                  // onChange={handleInputChange}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.igst`}
                  value={getSubTotalRowValue(item, 'igst')}
                  // onChange={handleInputChange}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.cgst`}
                  value={getSubTotalRowValue(item, 'cgst')}
                  // onChange={handleInputChange}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.sgst`}
                  value={getSubTotalRowValue(item, 'sgst')}
                  // onChange={handleInputChange}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.cess`}
                  value={getSubTotalRowValue(item, 'cess')}
                  // onChange={handleInputChange}
                  style={{ width: '150px', borderBlockColor: 'green', borderStyle: 'solid', borderRadius: '2px', fontWeight: 'bold' }}
                  disabled={!item.manual_type}
                />
              </td>
            </tr>
          );

        default:
          return (
            <tr key={index} style={{ backgroundColor: 'white' }}>
              <td>{item.name}</td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.taxable_value`}
                  value={item.taxable_value}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.igst`}
                  value={item.igst}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.cgst`}
                  value={item.cgst}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.sgst`}
                  value={item.sgst}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                  disabled={!item.manual_type}
                />
              </td>
              <td>
                <input
                  type="number"
                  className='blue-color'
                  name={`${item.name}.cess`}
                  value={item.cess}
                  onChange={handleInputChange}
                  style={{ width: '150px' }}
                  disabled={!item.manual_type}
                />
              </td>
            </tr>
          );
      }
    });
  };


  return (
    <>
      <BoxWrapper
        backButtonForZohoEnable={true}
        backButton={handleBackButton}
        boxTitle={tableName}
      >
        <>
          <p style={{ marginLeft: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Note: </span> The fields where the system computed values would be modified by more/less 20% shall be highlighted in <span style={{ color: 'red' }}>Red</span> for reference and attention.
          </p>
          <hr style={{ marginLeft: '10px' }} />
        </>

        <div style={{ maxHeight: '47vh' }} className="responsive-table">
          <table style={{ width: '100%', backgroundColor: 'white' }} className="summary-tab-single-year-table-css">
            <thead>
              <tr >
                <th style={{ width: '30%' }}>Nature of Supplies</th>
                <th >Taxable value (₹)</th>
                <th>Integrated Tax (₹)</th>
                <th>Central Tax (₹)</th>
                <th>State/UT Tax (₹)</th>
                <th>CESS (₹)</th>
              </tr>
            </thead>
            <tbody>
              {(tableData && tableData.length >= 1) ? renderTableRows() : (
                <tr>
                  <td colSpan={6} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style
          ={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
          <button type="button" className='button-primary' onClick={() => setSaveDailogOpen(true)}>Save</button>
          <button type="button" className='button-primary'>Cancel</button>
        </div>

      </BoxWrapper>
      <ConfirmationDialog
        open={saveDailog}
        onClose={() => setSaveDailogOpen(false)}
        onConfirm={submitHandle}
        title="Confirm Save"
        text="Do you want to save your changes? This action will update the current data."
      />

      <ConfirmationDialog
        open={confirmBack}
        onConfirm={confirmBackHandle}
        onClose={() => handleBack()}
        title="Unsaved Changes"
        text="You have unsaved changes. Do you want to leave without saving?"
      />

    </>
  );
}

export default Five;
