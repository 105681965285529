import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import classes from "./BankReconciliation.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TextValidator } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";
import InputField from "../../../../components/ui/inputField";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import SelectField from "../../../../components/ui/basicSelectInput";
import CustomTable from "../../../../components/ui/Table/Table";

const dateFormat = (value) => {
  if (!value) {
    return "";
  }
  const current_date = new Date(value);

  const yy = current_date.getFullYear().toString();
  const mm = (current_date.getMonth() + 1).toString();
  const dd = current_date.getDate().toString();
  return `${dd}/${mm}/${yy}`;
};
class BankReconDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: "Botmantra",
      file_name: "",
      loading: false,
      modalShow: false,
      dataCol: null,
      data: null,
      oldData: null,
      dataHeader: null,
      invoiceCol: null,
      invoiceData: null,
      oldInvoiceData: null,
      invoiceHeader: null,
      invoiceModalOpen: false,
      actions: [
        {
          label: "Edit",
          onclickEvent: (index, row) => this.openModal(index),
        },
      ],
      searchInput: "",
    };
    axios
      .get(
        `/arc/bank_reconciliation/${localStorage.getItem(
          "business_entity_id"
        )}/${this.props.location.data}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          let data = res.data.data.updated_output;
          let dataCol = [];
          let dataHeader = [];
          data?.length &&
             Object.keys(data[0]).map((k, i) => {
              if (k === "Invoice Date") {
                dataCol.push({
                  key: k,
                  render: (index, row) => {
                    return row["Invoice Date"];
                  },
                });
                dataHeader.push({ label: k });
              } else {
                dataCol.push({ key: k });
                dataHeader.push({ label: k });
              }
            });

          data?.length &&
            this.setState({
              dataCol: dataCol,
              dataHeader: dataHeader,
              data: data,
              oldData: data,
            });
        }
      });

    axios
      .get(
        `/arc/bank_reconciliation/not_yet_collected/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          let invoiceData = res.data.data;
          let invoiceCol = [];
          let invoiceHeader = [];
          invoiceData.length &&
            Object.keys(invoiceData[0]).map((k, i) => {
              if (k === "Invoice Date") {
                invoiceCol.push({
                  key: k,
                  render: (index, row) => {
                    return dateFormat(row["Invoice Date"]);
                  },
                });
                invoiceHeader.push({ label: k });
              } else {
                invoiceCol.push({ key: k });
                invoiceHeader.push({ label: k });
              }
            });

          invoiceData.length &&
            this.setState({
              invoiceCol: invoiceCol,
              invoiceHeader: invoiceHeader,
              invoiceData: invoiceData,
              oldInvoiceData: invoiceData,
            });
        }
      });
  }

  openModal = (id) => {
    this.setState({
      invoiceModalOpen: true,
      targetId: id,
    });
  };

  invoiceModalClose = () => {
    this.setState({
      invoiceModalOpen: false,
      targetId: null,
    });
  };

  invoiceUpdate = () => {
    let data = [...this.state.data];

    let selected_input = document.querySelectorAll(
      "input[name='invoices[]'][type=checkbox]:checked"
    );
    const value = data[this.state.targetId];
    data.splice(this.state.targetId, 1);
    let total = 0;
    Object.keys(selected_input).map((v, i) => {
      let invoice_key = selected_input[v].value;
      const invoice_value = this.state.invoiceData[invoice_key];
      const key = Object.keys(data).find(
        (key) => data[key]["Invoice Number"] === invoice_value["Invoice Number"]
      );
      if (key) {
        data[key]["Invoice Number"] = "";
        data[key]["Invoice Date"] = "";
        data[key]["Invoice Amount"] = "";
        data[key]["Collected Amount less TDS"] = "";
        data[key]["TDS"] = "";
        data[key]["Status"] = "DON'T KNOW";
      }
      total += parseFloat(invoice_value["Collected Amount less TDS"]);

      let assign_value = { ...value, ...invoice_value };
      if (assign_value["Invoice Number"]) {
        assign_value["Status"] = "DIRECT MATCH";
      } else {
        assign_value["Status"] = "DON'T KNOW";
      }

      data.splice(this.state.targetId, 0, assign_value);
    });

    if (total > value["Transaction Amount"]) {
      toast.error("Invoice Amount is more than Transaction Amount", {
        autoClose: 2000,
      });
      return false;
    } else {
      this.setState({
        data: data,
        invoiceModalOpen: false,
        targetId: null,
      });
    }
  };

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  invoiceSearchInputChange = (event) => {
    this.setState({ invoiceSearchInput: event.target.value }, () => {
      this.invoiceSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    let searchData = this.state.oldData.filter((value) => {
      const query = searchInput.toLowerCase();
      return String(value["Description"]).toLowerCase().indexOf(query) >= 0;
    });
    this.setState({ data: searchData });
  };

  invoiceSearch = (event) => {
    let { invoiceSearchInput } = this.state;
    let searchData = this.state.oldInvoiceData.filter((value) => {
      const query = invoiceSearchInput.toLowerCase();
      return String(value["Customer Name"]).toLowerCase().indexOf(query) >= 0;
    });
    this.setState({ invoiceData: searchData });
  };

  componentDidMount = () => {
    //  this.MyDataProvider = dataProvider(process.env.REACT_APP_DOMAIN);
    // axios
    //   .get(
    //     `/arc/get_gst_reconciliation/${localStorage.getItem(
    //       "business_entity_id"
    //     )}`
    //   )
    //   .then((res) => {
    //     if (typeof res.data === "undefined") {
    //     } else {
    //       this.setState({
    //         historyTable: res.data.data,
    //         historyTableOriginalData: res.data.data,
    //       });
    //     }
    //   });
    this.getDetails();
  };

  // File uploader for [Form 26AS]
  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ GSTJsonfFile: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  getDetails = () => {
    axios
      .get(`/arc/get_gst_repo/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (typeof res.data === "undefined") {
        } else {
          this.setState({
            historyTable: res.data.data,
            historyTableOriginalData: res.data.data,
          });
        }
      });
  };

  // submit for file upload form
  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    toast.configure();

    axios
      .post(`/arc/bank_reconciliation`, {
        file: `${this.state.GSTJsonfFile}`,
        business_entity_id: localStorage.getItem("business_entity_id"),
        bank: this.state.bank,
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
        } else {
          toast.success("File processing completed.", {
            autoClose: 5000,
            progress: 0,
          });
          let data = res.data.data;
          let dataCol = [];
          let dataHeader = [];
          Object.keys(data[0]).map((k, i) => {
            dataCol.push({ key: k });
            dataHeader.push({ label: k });
          });
          let invoiceData = res.data.not_yet_collected;
          let invoiceCol = [];
          let invoiceHeader = [];
          Object.keys(invoiceData[0]).map((k, i) => {
            invoiceCol.push({ key: k });
            invoiceHeader.push({ label: k });
          });

          this.setState({
            dataCol: dataCol,
            dataHeader: dataHeader,
            data: data,
            oldData: data,
            invoiceCol: invoiceCol,
            invoiceHeader: invoiceHeader,
            invoiceData: invoiceData,
            oldInvoiceData: invoiceData,
            loading: false,
            file_name: "",
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, file_name: "" });
      });
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  //---History table logic---
  historyTableHeader = () => {
    const tableHeader = [
      "Financial Year",
      "Filing Month",
      "Filing Type",
      "Created Date",
      "Updated Date",
    ];
    // let header = Object.keys(tableHeader)
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  historyTableData = () => {

    return (
      this.state.historyTable &&
      this.state.historyTable.map((tableData, index) => {
        const {
          filing_type,
          filing_year,
          filing_month,
          created_at,
          updated_at,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{`${filing_year}-${filing_year + 1}`}</td>
            <td style={{ textTransform: "capitalize" }}>{filing_month}</td>
            <td style={{ textTransform: "capitalize" }}>{filing_type}</td>
            <td style={{ textTransform: "capitalize" }}>
              {dateFormat(created_at)}
            </td>
            <td style={{ textTransform: "capitalize" }}>
              {dateFormat(updated_at)}
            </td>
          </tr>
        );
      })
    );
  };

  newReconModalOpen = () => {
    this.setState({
      modalShow: true,
    });
  };

  newReconModalClose = () => {
    this.setState({
      modalShow: false,
      threshold_value: "",
      roundOffValue: 0,
    });
  };

  roundOffInputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 11 && name === "threshold_value") {
      this.setState({
        customRoundOff: "",
        roundOffValue: value,
      });
    } else if (name === "customRoundOff") {
      if (
        String(value).split(".")[1] &&
        String(String(value).split(".")[1]).length > 2
      ) {
        return false;
      }

      this.setState({ threshold_value: value, customRoundOff: value });
    } else {
      this.setState({
        threshold_value: value,
        roundOffValue: value,
        customRoundOff: null,
      });
    }
  };

  inputHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  saveOutput = () => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `/arc/bank_reconciliation/${localStorage.getItem(
          "business_entity_id"
        )}/${this.props.location.data}`,
        {
          data: this.state.data,
        }
      )
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
        } else {
          toast.success("File processing completed.", {
            autoClose: 5000,
            progress: 0,
          });
        }
        this.getDetails();
        this.setState({ loading: false, file_name: "" });
      })
      .catch((error) => {
        this.setState({ loading: false, file_name: "" });
      });
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Receipts Reconciliation.xlsx`;

    axios
      .post("/arc/bank_reconciliation/convert_to_excel", {
        data: this.state.data,
        columns: Object.keys(this.state.data[0]),
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.invoiceModalOpen}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      textLabel="Search"
                      inputType="text"
                      requiredField=" "
                      name="Search_by_invoice"
                      value={this.state.invoiceSearchInput ?? ""}
                      textOnchangeFunction={this.invoiceSearchInputChange}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <CustomTable
                headers={this.state.invoiceHeader}
                columns={this.state.invoiceCol}
                data={this.state.invoiceData}
                // actions={this.state.actions}
                multiselect={true}
                // multiselectkey="cust_pan"
                name="invoices"
                // selectCondition={(item) => !item.empanelled_id}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.invoiceUpdate}
            >
              Submit
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={this.invoiceModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* invoid modal end */}
        <Container>
          <div>
            {this.state.loading ? (
              <Button
                variant="contained"
                disabled
                style={{ marginTop: "10px", marginBottom: "20px" }}
              >
                <CircularProgress size={22} />{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    paddingLeft: "10px",
                  }}
                >
                  Uploading...
                </span>
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                component="label"
                style={{ marginTop: "10px", marginBottom: "20px" }}
                onClick={this.saveOutput}
              >
                Save
              </Button>
            )}

            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.modalShow}
              onHide={this.newReconModalClose}
              dialogClassName="modal-50w"
            >
              <Modal.Header closeButton>
                <Modal.Title>Upload Bank Statement</Modal.Title>
              </Modal.Header>
              <ValidatorForm
                ref="form"
                onSubmit={this.onSubmitHandler}
                onError={(errors) => console.log(errors)}
              >
                <Modal.Body>
                  {/* Form 26AS upload [UPLOADER(File input)] */}
                  <div
                    style={{
                      padding: "20px",
                      marginBottom: "10px",
                      boxShadow: "0px 0px 3px 0px #fff , 0px 1px 1px 1px #ddd",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            padding: "0px 20px 20px 20px",
                            marginTop: "30px",
                            marginBottom: "10px",
                            boxShadow: "0px 0px 3px 0px #fff , 0px 1px 1px 1px #ddd",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item sm={3}>
                              <p
                                style={{ marginTop: "30px", fontSize: "16px" }}
                              >
                                Select Bank
                                <span style={{ color: "red" }}>*</span>:
                              </p>
                            </Grid>
                            <Grid item sm={9}>
                              <SelectField
                                validator={["required"]}
                                errorMsg={["This field is required"]}
                                choice_id="id"
                                choice_name="value"
                                name="bank"
                                value={this.state.bank ?? ""}
                                choices={this.state.bankArr}
                                textOnchangeFunction={this.inputHandler}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <Grid container spacing={3}>
                          <Grid item xs={3} md={3}>
                            <Button
                              size="small"
                              variant="contained"
                              component="label"
                              fullWidth
                              style={{ marginTop: "18px" }}
                            >
                              Browse
                              <input
                                id="file_value"
                                name="GSTJsonfFile"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                type="file"
                                hidden
                                onChange={this.fileUploader}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={9} md={9}>
                            <ul style={{ width: "100%" }}>
                              <li
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                }}
                              >
                                <TextValidator
                                  validators={["required"]}
                                  errorMessages={["This field is required"]}
                                  placeholder="Choose file"
                                  value={this.state.file_name}
                                  name="GSTJson_file_uploader"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  fullWidth
                                  size="small"
                                />
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    style={{ marginRight: "10px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={this.newReconModalClose}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </ValidatorForm>
            </Modal>
          </div>

          {/* table */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.Main_frame}>
                <div className={classes.form_title_bar}>
                  <div class="form_title_name">
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: "0px",
                        padding: "0px",
                        marginTop: "0px",
                      }}
                    >
                      <li style={{ display: "inline-block" }}>
                        Receipts Reconciliation
                      </li>
                      <li style={{ display: "inline-block", float: "right" }}>
                        <Button size="small" onClick={this.export_to_excel}>
                          Export to Excel
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={classes.form_body_part}>
                  <div className={classes.searchField}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <ValidatorForm
                          ref="form"
                          // onSubmit={this.onSubmitHandler}
                          // onError={(errors) => console.log(errors)}
                        >
                          <InputField
                            textLabel="Search"
                            inputType="text"
                            name="Search_by_customer_name"
                            value={this.state.searchInput ?? ""}
                            textOnchangeFunction={this.handleSearchInputChange}
                          />
                        </ValidatorForm>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.tableResponisve}>
                    {/* <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_selleridAgency_TableHeader()}</tr>
                      {this.arc_selleridAgency_TableBody()}
                    </tbody>
                  </table> */}
                    <CustomTable
                      headers={this.state.dataHeader}
                      columns={this.state.dataCol}
                      data={this.state.data}
                      actions={this.state.actions}
                      // multiselect={true}
                      // multiselectkey="cust_pan"
                      // name="invoices"
                      // selectCondition={(item) => !item.empanelled_id}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
export default BankReconDetails;
