import React, { useState } from "react";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import classes from "./GSTReconciliation.module.css";

function CustomerInvoiceSummary(props) {
  const { CustomerInvoiceSummaryData } = props;
  const [customerInvoiceSummaryData, setCustomerInvoiceSummaryData] = useState(
    CustomerInvoiceSummaryData
  );

  const searchInputHandler = (event) => {
    let searchInput = event.target.value;
    let searchData = CustomerInvoiceSummaryData.filter((value, index, arr) => {
      const query = searchInput.toLowerCase();
      return (
        String(value.cust_name).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0
      );
    });
    setCustomerInvoiceSummaryData(searchData);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const CustomerInvoiceSummary = () => {
    if (customerInvoiceSummaryData.length >= 1) {
      return customerInvoiceSummaryData.map((tableData, index) => {
        const {
          cust_gstin,
          cust_name,
          cws_gst,
          cws_invoice_count,
          cws_taxable_value,
          gst_diff,
          gstr_gst,
          gstr_invoice_count,
          gstr_taxable_value,
          invoice_count_diff,
          taxable_value_diff,
        } = tableData;
        return (
          <tr>
            <td>{cust_gstin}</td>
            <td>{cust_name}</td>
            <td>{cws_invoice_count}</td>
            <td>{numberFormat(cws_taxable_value)}</td>
            <td>{numberFormat(cws_gst)}</td>
            <td>{gstr_invoice_count}</td>
            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_gst)}</td>
            <td>{invoice_count_diff}</td>
            <td>{numberFormat(taxable_value_diff)}</td>
            <td>{numberFormat(gst_diff)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status : GSTR-1 - Customer Summary"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={searchInputHandler}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                CUSTOMER NAME
              </th>
              <th
                style={{ textAlign: "center", background: "#ffffb3" }}
                colSpan={3}
                align="center"
              >
                As per Books
              </th>
              <th
                style={{ textAlign: "center", background: "#5cd65c" }}
                colSpan={3}
                align="center"
              >
                As per GSTR1
              </th>
              <th
                style={{ textAlign: "center", background: "#ff8533" }}
                colSpan={3}
                align="center"
              >
                Difference
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>
            </tr>
            {CustomerInvoiceSummary()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default CustomerInvoiceSummary;
