import React, { useEffect, useState } from "react";
import design from "./ControlReport.module.css";
import axios from "../../../../axios-order";
import {
  Card,
  Grid,
  Container,
  CardHeader,
  CardContent,
  Button,
} from "@material-ui/core";
import BoxWapper from "../../../../components/ui/boxWrapper";
import CardForDispaly from "../../../../components/ui/Cards";

export default function ControlReport() {
  const [dataForFirstTable, setDataForFirstTable] = useState([]);
  const [dataForFirstTableSearch, setDataForFirstTableSearch] = useState([]);
  const [loaderForTable, setLoaderForTable] = useState(false);

  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  const tableHead = () => {
    const tableHeadData1 = [
      "Financial Year",
      "GSTIN",
      "PAN",
      "Original Bill No.",
      "Original Bill Date",
      "Credit/Debit  Note No.",
      "Credit/Debit  Note Date",
      "HSN/SAC Code",
      "Taxable Value",
      "Non Taxable Value",
      "TDS Payables",
      "SGST",
      "CGST",
      "IGST",
      "CESS",
      "Tax Amount",
      "Total Amount",
      "Dispute (Y/N)",
      "Dispute Value",
      "Debit/Credit Note Reason",
    ];
    return tableHeadData1.map((v, i) => {
      return <th key={i}>{v}</th>;
    });
  };

  const SearchEvent = (e) => {
    let Seach = e.target.value;
    const seachName = dataForFirstTableSearch.filter((v) => {
      return (
        String(v.financialYear).toLowerCase().indexOf(Seach.toLowerCase()) >=
          0 ||
        String(v.vendorGstin).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.vendorPan).toLowerCase().indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.originalBillNumber)
          .toLowerCase()
          .indexOf(Seach.toLowerCase()) >= 0 ||
        String(v.originalBillDate).toLowerCase().indexOf(Seach.toLowerCase()) >=
          0 ||
        String(v.creditNoteNumber).toLowerCase().indexOf(Seach.toLowerCase()) >=
          0 ||
        String(v.creditNoteDate).toLowerCase().indexOf(Seach.toLowerCase()) >= 0
      );
    });
    setDataForFirstTable(seachName);
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Invalid Credit/Debit Note Under GST Act.xlsx`;
    axios
      .post(`/vendor/vendorcontrolreport`, {
        data: dataForFirstTable,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const date = () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    return formattedDate;
  };

  const firstTableDataGetApi = () => {
    setLoaderForTable(true);
    axios
      .get(
        `/get_vendor_first_control_report/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTable(false);
        setDataForFirstTable(res.data);
        setDataForFirstTableSearch(res.data);
      })
      .catch((error) => {
        setLoaderForTable(false);
      });
  };

  useEffect(() => {
    firstTableDataGetApi();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#3b3b6b"
            cardTitle="Name"
            cardBody={localStorage.getItem("entity_name")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#4B5D67"
            cardTitle="GSTIN"
            cardBody={localStorage.getItem("gst")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#d04646"
            cardTitle="Report Generated On"
            cardBody={date()}
          />
        </Grid>
      </Grid>

      <BoxWapper
        boxTitle=" Debit or Credit note raised in connection with original
                  invoice of previous financial year should not exceed 30th Nov
                  of the current year"
        // export to excel
        exportToExcelEnable={true}
        exportToExcelFn={export_to_excel}
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={SearchEvent}
      >
        <div className="responsive-table">
          <table className="custome-long-table">
            <thead>
              <tr>{tableHead()}</tr>
            </thead>
            <tbody>
              {dataForFirstTable.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{v.financialYear}</td>
                    <td>{v.vendorGstin}</td>
                    <td>{v.vendorPan}</td>
                    <td>{v.originalBillNumber}</td>
                    <td>{v.originalBillDate}</td>
                    <td>{v.creditNoteNumber}</td>
                    <td>{v.creditNoteDate}</td>
                    <td>{v.hsnSacCode}</td>
                    <td>{indianRupeesFormat(v.taxableValue)}</td>
                    <td>{indianRupeesFormat(v.nonTaxableValue)}</td>
                    <td>{indianRupeesFormat(v.tdsPayable)}</td>
                    <td>{indianRupeesFormat(v.sgst)}</td>
                    <td>{indianRupeesFormat(v.cgst)}</td>
                    <td>{indianRupeesFormat(v.igst)}</td>
                    <td>{indianRupeesFormat(v.cess)}</td>
                    <td>{indianRupeesFormat(v.taxAmount)}</td>
                    <td>{indianRupeesFormat(v.totalAmount)}</td>
                    <td>{v.dispute}</td>
                    <td>{v.disputeValue}</td>
                    <td>{v.creditNoteReason ?? ""}</td>
                  </tr>
                );
              })}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={7}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : dataForFirstTable.length === 0 ? (
                <tr>
                  <td
                    colSpan={"7"}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWapper>
    </React.Fragment>
  );
}
