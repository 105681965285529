import React, { Component } from "react";
import classes from "./GSTReconciliation.module.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import axios from "../../../../axios-order";

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: null,
      downloadData: null,
    };
    axios.get(`/arc/get_gst_json/${this.props.location.data}`).then((res) => {
      if (typeof res.data == "undefined") {
      } else {
        res.data.record.json_data.jsonForUi["threshold_value"] =
          res.data.record["threshold_value"];
        res.data.record.json_data.jsonForExportExcel["threshold_value"] =
          res.data.record["threshold_value"];

        this.setState({
          allData: res.data.record.json_data.jsonForUi,
          downloadData: res.data.record.json_data.jsonForExportExcel,
        });
      }
    });
  }

  // invoice Details table header
  customer_detailsTableHeader() {
    const tableHeader = [
      "status",
      "GSTIN",
      "Customer Name",
      "Invoice/CN/DN/Number",
      "date",
      "type",
      "taxable value",
      "CGST",
      "SGST",
      "IGST",
      "Cess",
      "Total amount",
      "taxable value",
      "CGST",
      "SGST",
      "IGST",
      "Cess",
      "Total amount",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  customer_details_data() {
    return (
      this.state.allData &&
      this.state.allData["Sales Invoice"].map((tableData, index) => {
        const {
          cust_gstin,
          invoice_no,
          invoice_date,
          cust_name,
          invoice_type,
          gstr_taxable_value,
          gstr_cgst,
          gstr_sgst,
          gstr_igst,
          gstr_cess,
          gstr_total_amount,
          cws_taxable_value,
          cws_cgst,
          cws_sgst,
          cws_igst,
          cws_cess,
          cws_total_amount,
          status,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{status}</td>
            <td> {cust_gstin}</td>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{invoice_type}</td>

            <td>{numberFormat(cws_taxable_value)}</td>

            <td>{numberFormat(cws_cgst)}</td>
            <td>{numberFormat(cws_sgst)}</td>
            <td>{numberFormat(cws_igst)}</td>
            <td>{numberFormat(cws_cess)}</td>
            <td>{numberFormat(cws_total_amount)}</td>

            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_cgst)}</td>
            <td>{numberFormat(gstr_sgst)}</td>
            <td>{numberFormat(gstr_igst)}</td>
            <td>{numberFormat(gstr_cess)}</td>
            <td>{numberFormat(gstr_total_amount)}</td>
          </tr>
        );
      })
    );
  }

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `gst_Summary_Report.xlsx`;
    axios
      .post(`/gst/download`, {
        data: { jsonForExportExcel: this.state.downloadData },
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
  
    return (
      <React.Fragment>
        <Container>
          {/* ---dashboard header--- */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={classes.Paper}
                style={{
                  background: "#c4d0d9",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {localStorage.getItem("entity_name")}
                <br></br>GST Reconciliation Report
              </Paper>
            </Grid>
          </Grid>

          {/* ---sub header--- */}
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                Report Dated :{" "}
                {this.state.allData && this.state.allData.report_date}
                <br></br>Report Period :{" "}
                {this.state.allData && this.state.allData.from_date} To{" "}
                {this.state.allData && this.state.allData.to_date}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ float: "right" }}>
                Threshold Used:
                {this.state.allData && this.state.allData.threshold_value}
              </div>
            </Grid>
          </Grid>
          {/* ---End--- */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.Main_frame}>
                <div className={classes.form_title_bar}>
                  <div class="form_title_name">
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: "0px",
                        padding: "0px",
                        marginTop: "0px",
                      }}
                    >
                      <li style={{ display: "inline-block" }}>History</li>
                      <li style={{ display: "inline-block", float: "right" }}>
                        <div>
                          <Button onClick={this.downloadToExcel}>
                            Export to Excel
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={classes.form_body_part}>
                  <div className={classes.tableResponisve}>
                    <table className={classes.LongTableCss}>
                      <tbody>
                        <tr style={{ border: "1px solid #ddd" }}>
                          <td colSpan={6}></td>
                          <td
                            colSpan={6}
                            style={{
                              border: "1px solid #ddd",
                              background: "#138e9c",
                            }}
                          >
                            As per Books
                          </td>
                          <td
                            colSpan={6}
                            style={{
                              border: "1px solid #ddd",
                              background: "#e38e8e",
                            }}
                          >
                            GSTR 1
                          </td>
                        </tr>
                        <tr>{this.customer_detailsTableHeader()}</tr>
                        {this.customer_details_data()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
export default Showdetails;
