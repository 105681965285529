import React, { useState } from "react";

import BoxWrapper from "../../../../../components/ui/boxWrapper";

import classes from "./Report.module.css";

function VendorInvoiceSummary(props) {
  const { VendorInvoiceSummaryData } = props;
  const [vendorInvoiceSummaryData, setVendorInvoiceSummaryData] = useState(
    VendorInvoiceSummaryData
  );

  const searchInputHandler = (event) => {
    let searchInput = event.target.value;
    let searchData = vendorInvoiceSummaryData.filter((value, index, arr) => {
      const query = searchInput.toLowerCase();
      return (
        String(value.GSTIN).toLowerCase().indexOf(query) >= 0 ||
        String(value.VENDOR_NAME).toLowerCase().indexOf(query) >= 0
      );
    });
    setVendorInvoiceSummaryData(searchData);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const VendorInvoiceSummary = () => {
    if (vendorInvoiceSummaryData.length >= 1) {
      return vendorInvoiceSummaryData.map((tableData, index) => {
        const {
          GSTIN,
          VENDOR_NAME,
          Total_GST_Including_Tax_Books,
          Invoice_Count_Books,
          Taxable_Value_Books,

          Total_GST_Including_Tax_GSTR2A,
          Invoice_Count_GSTR2A,
          Taxable_Value_GSTR2A,

          Total_GST_Diff,
          Invoice_Count_Diff,
          Taxable_Value_Diff,
        } = tableData;
        return (
          <tr>
            <td>{GSTIN}</td>
            <td>{VENDOR_NAME}</td>
            <td>{Invoice_Count_Books}</td>
            <td>{numberFormat(Taxable_Value_Books)}</td>
            <td>{numberFormat(Total_GST_Including_Tax_Books)}</td>

            <td>{Invoice_Count_GSTR2A}</td>
            <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
            <td>{numberFormat(Total_GST_Including_Tax_GSTR2A)}</td>

            <td>{Invoice_Count_Diff}</td>
            <td>{numberFormat(Taxable_Value_Diff)}</td>
            <td>{numberFormat(Total_GST_Diff)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };
  return (
    <>
      <BoxWrapper
        boxTitle="Reconciliation Status : GSTR-2A - Vendor Summary"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={searchInputHandler}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                VENDOR NAME
              </th>
              <th
                style={{ textAlign: "center", background: "#ffffb3" }}
                colSpan={3}
                align="center"
              >
                As per Books
              </th>
              <th
                style={{ textAlign: "center", background: "#5cd65c" }}
                colSpan={3}
                align="center"
              >
                As per GSTR2A
              </th>
              <th
                style={{ textAlign: "center", background: "#ff8533" }}
                colSpan={3}
                align="center"
              >
                Difference
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffffb3" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffffb3" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>

              <th style={{ background: "#5cd65c" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#5cd65c" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>

              <th style={{ background: "#ff8533" }} align="right">
                INVOICE COUNT
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ff8533" }} align="right">
                TOTAL GST INCLUDING CESS
              </th>
            </tr>
            {VendorInvoiceSummary()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default VendorInvoiceSummary;
