import React, { useState, useEffect } from "react";
import { Button, TextField, Grid } from "@mui/material";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


import designForGstr2bRep from "../GSTR-2A_Repository/Gst2aRepository.module.css";
import axios from "../../../../axios-order";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
// import DatePicker from "../../../../components/ui/DatePicker";
import  DatePicker from '../GSTR-2A_Repository/DatePicker';
import { ValidatorForm } from "react-material-ui-form-validator";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";


const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;



export default function Gstr8ARepository() {
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileDataToUpload, setFileDataToUpload] = useState("");
  //error
  const [showSuccessOnly, setShowSuccessOnly] = useState(false);
  
  


  

  //...............For Error Message..........................
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
    useState("hidden");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");

  //...........Record From Get Api.................
  const [recordForTable, setRecordForTable] = useState([]);
  const [recordForTableSearch, setRecordForTableSearch] = useState([]);

  const [loaderForTable, setLoaderForTable] = useState(false);

  // ..............this is Auto GSTR Downaload
  const [fromMonthYearDisplay, setFromMonthYearDisplay] = useState(null)
  const [fromToYearDisplay,setFromToYearDisplay] = useState(null)
  const [next, setNext] = useState(false)
  const [responseMessage, setResponseMessage] = useState("");

  
//   Gstr Status code begin

  const [status, setStatus] = useState(false);
  const [data, setData] = useState([]);

  const [errorMessages, setErrorMessages] = useState('');
  const [showBoxWrapper, setShowBoxWrapper] = useState(false);
  const [responseData, setResponseData] = useState("");
    const [displayResponse, setDisplayResponse] = useState(false);  
    const [taskId, setTaskId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    //Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  

    useEffect(() => {
      // Call the additional API only when taskId is available
      if (taskId) {
        const additionalApiUrl = `/autodownloadapi/${taskId}`;
        axios.get(additionalApiUrl)
          .then(response => {
            console.log('Additional API response:', response.data);
            // Process the additional API response as needed
          })
          .catch(error => {
            console.error('Error calling additional API:', error);
          });
      }
    }, [taskId]);

  


const handleButtonClick = () => {
  setShowBoxWrapper(!showBoxWrapper);
};


 
  


   // Function to open Snackbar
   const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  // Function to close Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarSeverity("success");
    setResponseMessage("");
  };
    const handleDownload = (gst_task_id, business_entity_id) => {
      
      const url = `${apiUrl}/mydemo/gstDownload/gstrtaskdownload/`;
        const requestData = new FormData();
        requestData.append('task_id', gst_task_id);
        requestData.append('entity_id', business_entity_id);
    
      axios.post(url, requestData)
        .then(response => {
          console.log('API response:', response.data);
          setResponseData(response.data)
          setDisplayResponse(true);
          const autoTaskId = extractAutoTaskId(response.data);
      if (autoTaskId) {
        setTaskId(autoTaskId);
      }

          setTimeout(() => {
            setResponseData('');
            setDisplayResponse(false);
          }, 10000); // 5 seconds in milliseconds
        })
        
          // Process the response data as needed
       
        .catch(error => {
          console.error('Error sending data:', error);
         
        });
    };
    const extractAutoTaskId = (response) => {
      const autoTaskIdPattern = /Auto Task id :(\d+)/;
      const match = response.match(autoTaskIdPattern);
      return match ? match[1] : null;
    };
    
 

  const handle = (gst_task_id, business_entity_id) => {
    const url =  `${apiUrl}/mydemo/gstDownload/gstrtaskstatus/`;
    const requestData = new FormData();
    requestData.append('task_id', gst_task_id);
    requestData.append('entity_id', business_entity_id);
  
    axios.post(url, requestData)
      .then(response => {
         
        console.log('API response:', response.data);
      
        // Process the response data as needed
        // handleRefresh();
      })
      .catch(error => {
        console.error('Error sending data:', error);
        // setErrorMessage('An error occurred. Please try again later.');
        if (error.response && error.response.status !== 200) {
          setErrorMessages('An error occurred.');
        }
       
      });
  };
  
  const business_entity_id = localStorage.getItem('business_entity_id');


  const handleClick = (first_col, second_col) => {
    axios.post(`${apiUrl}/table/`, { first_col, second_col })
      .then(response => {
        console.log('Data sent to backend:', response.data);
      })
      .catch(error => {
        console.error('Error sending data:', error);
      });
  };

  //Gststatus code end 

  

  const handleChange = (event) => {
    setNext(event.target.checked);
  };

  const handleToastifyNotification = (uploadFailReason) => {
    handleSnackbarOpen(uploadFailReason, 'success');
  };
  const v = {
    upload_status: true,
    upload_fail_reason: 'Upload failed due to an error.',
  };
    //functionFromMonth and DatePicker
    const inputHandlerFromPicker = (v) => {
      setFromMonthYearDisplay(v)
      const formattedDate = moment(v).format("MMYYYY");
       
      setFromMonthYearDisplay(formattedDate);
      console.log(formattedDate)
    }

        //Function for the Date and Month Picker
        const  inputHandlerToPicker = (v) => {
          setFromToYearDisplay(v)
          const formatDate = moment(v).format("MMYYYY");
       
          setFromToYearDisplay(formatDate)
          console.log(formatDate)
       
        }

        useEffect(() => {
       
          const timer = setTimeout(() => {
            setResponseMessage(""); 
          }, 7000);
      
         
          return () => clearTimeout(timer);
        }, [responseMessage]);
      


        /// This changes for the handleGstr2b 
        const handleGSTR1Download = () => {
           setIsSubmitting(true); // Disable the button-------# here need to change the url whatever you want
        
        const url =  `${apiUrl}/gstr8A_upload`;
          const entity_id = localStorage.getItem('business_entity_id');
          const fromRetPeriod = fromMonthYearDisplay ? moment(fromMonthYearDisplay).format('MMYYYY') : '';
          const toRetPeriod = fromMonthYearDisplay ? moment(fromMonthYearDisplay).format('MMYYYY') : '';
          
          const formData = new FormData();
          formData.append('entity_id', entity_id);
          
          formData.append('documentName', fileName)
          
          // Perform a POST request to the backend with the payload
          axios.post(url, formData)
            .then(response => {
              try {
                const responseData = response.data;

// Check if the response contains the specific format
                if (responseData.message_type && responseData.message) {
                handleSnackbarOpen(
                    responseData.message,
                    responseData.message_type
                  );
                } else {
              handleSnackbarOpen('An unexpected response was received.', 'error');
              }
              } catch (error) {
                console.log(error);
                handleSnackbarOpen('Unexpected Error.', 'error');
            }
            })
            .catch(error => {
              console.log(error);
              handleSnackbarOpen('Unexpected Error.', 'error');
              })
            .finally(() => {
              setIsSubmitting(false); // Re-enable the button
            });
        };
    

  //.............Open And Close Event For Model.............
  const handleOpenAndClose = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
    setFileDataToUpload("");
    setFileName("");
  };

  const fileUploadCancelButtonHandler = () => {
    functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
    setFileDataToUpload("");
    setFileName("");
  };

  //.......Handle Search....................
  const handleSearch = (e) => {
    let Search = e.target.value.trim();
    const searchName = recordForTableSearch.filter((v) => {
      return (
        String(v.gstr_upload_file_type)
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0 ||
        String(v.financial_year).toLowerCase().indexOf(Search.toLowerCase()) >=
        0 ||
        String(v.generation_date).toLowerCase().indexOf(Search.toLowerCase()) >=
        0 ||
        String(moment(v.tax_period, "MMYYYY").format("MM-YYYY")).indexOf(
          Search
        ) >= 0 ||
        String(
          moment(v.upload_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        ).indexOf(Search) >= 0
      );
    });
    setRecordForTable(searchName);
  };

  //........For Succesfull Message..................
  const functionForSuccesfullMessageAndErroeMessage = (
    color,
    visible,
    display,
    message
  ) => {
    setColorForErrorMessage(color);
    setErrorMessageVisibilityStyle(visible);
    setErrorMessageDisplayStyle(display);
    setErrorMessage(message);
  };

  //.............File Upload Data Saving in useState..............
  const handleFileUpload = (e) => {
    functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
    if (e.target.files.length) {
      const file_data = e.target.files[0];
      let fileName = file_data.name;
      setFileName(fileName);
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      reader.onload = function () {
        let file_value = reader.result;
        setFileDataToUpload(file_value);
      };
    }
  };

  //......Submit Event For File Upload in axios.......
  const handleSubmitEvent = (e) => {
    functionForSuccesfullMessageAndErroeMessage(
      "green",
      "visible",
      "block",
      "Uploading file Please Wait..."
    );
    if (fileName === "") {
      functionForSuccesfullMessageAndErroeMessage(
        "red",
        "visible",
        "block",
        "Please Upload The File!"
      );
    } else {
      axios
        .post(`/gstr8A_upload`, {
          file: fileDataToUpload,
          gstin: localStorage.getItem("gst"),
          document_name : fileName
        })
        .then((res) => {
          if (res.data && res.data.Status === true) {
            // handleOpenAndClose();
            functionForSuccesfullMessageAndErroeMessage("", "hidden", "none", "");
            getApiForGst2bRepository();
          } else {
            functionForSuccesfullMessageAndErroeMessage(
              "red",
              "visible",
              "block",
              res.data.Message
            );
          }
        })
        .catch((error) => {
          functionForSuccesfullMessageAndErroeMessage(
            "red",
            "visible",
            "block",
            error.response.data.Message
          );
        });
    }
  };

  //.......................Get Api For Gst................
  const getApiForGst2bRepository = () => {
    setLoaderForTable(true);
    axios
      .get(`/gstr8A_repository/${localStorage.getItem("gst")}`)
      .then((res) => {
        if (res.data.status === true) {
          setLoaderForTable(false);
          setRecordForTable(res.data.records);
          setRecordForTableSearch(res.data.records);
        } else {
          setLoaderForTable(false);
          alert(res.data.message);
        }
      })
      .catch(() => setLoaderForTable(false));
  };

  useEffect(() => {
    getApiForGst2bRepository();
  }, []);

  




  return (
    <React.Fragment>
      <ToggleSwitch
        onChange={handleOpenAndClose}
        label="Upload GSTR-8A Return"
      />
       

      {next ? (
      <BoxWrapper boxTitle="GSTR-8A Auto Download">

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {/* //Auto GST Downalod */}

        <ValidatorForm>
        <Grid container spacing={3}>
        <Grid item sm={5} >
        <label style={{ color: 'black', width: '820px' }}>
                Please Select FromMonth and Year <span style={{ color: 'red' }}>*</span>:
              </label>
              { <DatePicker
                views={['year', 'month']}
                placeholder="From Year to Month"
                format="MM/yyyy"
                value={fromMonthYearDisplay ?? ""}

                onChange={(v)=>inputHandlerFromPicker(v)}
               

                /> }
                



            
        </Grid>
        <Grid item sm={5} >
           <label style={{ color: 'black', width: '820px' }}>
                Please Select ToMonth and Year <span style={{ color: 'red'  }}>*</span>:
              </label>
              <DatePicker
                      views={['year', 'month']}
                      placeholder="From Year to Month"
                      format="MM/yyyy"
                      value={fromMonthYearDisplay ?? ""}
                      onChange={(v)=>inputHandlerFromPicker(v)}
                      disabled
               />
           </Grid>
           <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              
                 <button
            className={`button-primary ${isSubmitting || !fromMonthYearDisplay || !fromMonthYearDisplay ? 'disabled' : ''}`}
            type="button" // Change the type to "button" to prevent form submission
            style={{ width: '100%', padding: '8px' }}
            disabled={isSubmitting || !fromMonthYearDisplay || !fromMonthYearDisplay}
            onClick={handleGSTR1Download}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </Grid>
   

        </Grid>

        </ValidatorForm>
     



         
      </div>
      {/* <p style={{ color: "red" }}>{errorMessage}</p> */}
    </BoxWrapper>
      ) : (
        <div></div>
      )}

      



      {openAndCloseModel ? (
        <>
          <BoxWrapper boxTitle="Upload GSTR-8A Return">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label className="button-primary" style={{ cursor: "pointer" }}>
                Upload
                <input
                  type="file"
                  accept=".xlsx , .json , .zip"
                  onChange={handleFileUpload}
                  hidden
                />
              </label>
              <div>
                <TextField
                  value={fileName}
                  variant="standard"
                  style={{ width: "350px", paddingLeft: "10px" }}
                  placeholder="No Data Have Uploaded"
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div>
                <button
                  className="button-primary"
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmitEvent}
                >
                  Submit
                </button>

                <button
                  className="button-outlined-secondary"
                  onClick={fileUploadCancelButtonHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div
              style={{
                color: colorForErrorMessage,
                padding: "5px",
                fontWeight: "600",
                visibility: errorMessageVisibilityStyle,
                display: errorMessageDisplayStyle,
              }}
            >
              {errorMessage}
            </div>
          </BoxWrapper>
        </>
      ) : (
        ""
      )}

      {/*..................Table.......................*/}
      <BoxWrapper
        boxTitle="GSTR-8A Repository"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearch}

        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApiForGst2bRepository}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>GSTR8A upload file type</th>
                <th>Tax Period </th>
                <th>Financial Year </th>
                <th>Generation Date</th>
                <th>Upload Date</th>
                <th>Status</th>
        <th>
            <label>
              <input
                type="checkbox"
                checked={showSuccessOnly}
                onChange={() => setShowSuccessOnly(!showSuccessOnly)}
              />
              Show Success Only
            </label>
          </th>
        {/* <th>Log</th> */}
              </tr>
            </thead>
            <tbody>
              
    
 

    {recordForTable.map((v,index) => {
  let statusText = v.upload_status;
  if (v.upload_status === "Success") {
    statusText = "Success";
  } else if (v.upload_status === "Initiated") {
    statusText = "Initiated";
  } else if (v.upload_status === "In Progress") {
    statusText = "In Progress";
  }

  if (showSuccessOnly && v.upload_status !== "Success") {
    return null; // Skip rendering this row
  }


  return (
    <tr key={v.id}>
      <td>{v.gstr_upload_file_type}</td>
      <td>
        {v.tax_period !== "None" ? moment(v.tax_period, "MMYYYY").format("MMMM-YYYY") : ""}
      </td>
      <td>{v.financial_year !== "None" ? v.financial_year : ""}</td>
      <td>{v.generation_date}</td>
      <td>
        {v.upload_date !== "None" ? moment(v.upload_date, "YYYY-MM-DD").format("DD-MM-YYYY") : ""}
      </td>
      <td>{statusText}</td>
      <td>
        {v.upload_status ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleToastifyNotification(v.upload_fail_reason)}
          >
            <VisibilityOutlinedIcon
              className="action-icon-button showIcon"
              style={{ color: v.upload_status === "Success" ? "#2938c2" : "#b4bfbf" }}
            />
          </div>
        ) : (
          <VisibilityOutlinedIcon
            className="action-icon-button showIcon"
            onClick={() => alert("Data Uploaded")}
          />
        )}
      </td>
    </tr>
                );
              })}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={7}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : recordForTable.length === 0 ? (
                <tr>
                  <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
</div> 
  


   
      </BoxWrapper>

    

    {responseData && (
      <div>
        <p style={{fontSize:'20px',color:'green'}}>DOWNLOADED GSTR DATA <br /></p>
        <p>{responseData}</p>
      </div>
    )}
     
<Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{maxWidth: '25%'}}
      >
        <MuiAlert
          elevation={2}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}
        </MuiAlert>
      </Snackbar>
     
 
    </React.Fragment>
  );
}
