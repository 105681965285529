import React, { useState } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const ThreePointOne = ({ cancel, data, upDateJSON }) => {
  const [editData, setEditData] = useState(data);

  const submitHandle = () => {
    console.log(editData);
    upDateJSON(editData, "ThreePointOne");
    cancel();
  };

  console.log(data);
  return (
    <>
      <BoxWrapper
        boxTitle=" 3.1 Details of Outward Supplies and Inward Supplies Liable to
                Reverse Charge"
      >
        <div className={DesignInGSTR3BThreePointOne.tablebodyForTable}>
          <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
            <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
              <thead>
                <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                  <th>Nature of Supplies</th>
                  <th>Total Taxable value</th>
                  <th>Integrated Tax</th>
                  <th>Central Tax</th>
                  <th>State/UT Tax</th>
                  <th>Cess</th>
                </tr>
              </thead>
              <tbody>
                {editData?.map((v, index) => {
                  return (
                    <tr>
                      <td>{v.name}</td>
                      <td>
                        {
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.taxable_value}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = editData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, taxable_value: parseFloat(value) };
                                }
                                return item;
                              });
                              setEditData(newData);
                            }}
                          />
                        }
                      </td>
                      <td
                        style={{ background: v.igst === undefined && "#ddd" }}
                      >
                        {v.igst !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.igst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = editData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, igst: parseFloat(value) };
                                }
                                return item;
                              });
                              setEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{ background: v.cgst === undefined && "#ddd" }}
                      >
                        {v.cgst !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = editData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{ background: v.sgst === undefined && "#ddd" }}
                      >
                        {v.sgst !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.sgst}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = editData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, sgst: parseFloat(value) };
                                }
                                return item;
                              });
                              setEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{ background: v.cess === undefined && "#ddd" }}
                      >
                        {v.cess !== undefined ? (
                          <NumberFormat
                            className={
                              DesignInGSTR3BThreePointOne.numberFormate
                            }
                            style={{ paddingLeft: "10.5px" }}
                            thousandsGroupStyle="lakh"
                            prefix="₹"
                            decimalSeparator="."
                            displayType="number"
                            type="text"
                            decimalScale={2}
                            value={v.cess}
                            thousandSeparator={true}
                            allowNegative={true}
                            onValueChange={(values) => {
                              const { value } = values;
                              let newData = editData?.map((item, i) => {
                                if (i === index) {
                                  return { ...item, cess: parseFloat(value) };
                                }
                                return item;
                              });
                              setEditData(newData);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "10px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="button-primary"
              onClick={submitHandle}
            >
              Save
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button-outlined-secondary"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </BoxWrapper>
    </>
  );
};

export default ThreePointOne;
