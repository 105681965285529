import React, { Component } from 'react';
import classes from './ARCCollectionAgencyTransactions.module.css';
import axios from '../../../../axios-order';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '../../../../components/ui/Cards';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import DataTables from 'material-ui-datatables';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SellerCollectionAgencyTransactions from '../../../../asserts/templates/seller_collection_agency_transactions.xlsx';
import Drawer from '@material-ui/core/Drawer';
import ClearIcon from '@material-ui/icons/Clear';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputField from '../../../../components/ui/inputField';
import NumberFormat from 'react-number-format';
import ValidatedDatePicker from '../../../../components/ui/DatePicker';
import Modal from 'react-bootstrap/Modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TablePagination } from '@material-ui/core';
import BoxWrapper from '../../../../components/ui/boxWrapper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  // if (typeof value === "undefined") return false;
  if (value) {
    let old_date = String(value).split('-');
    return old_date[2] + '/' + old_date[1] + '/' + old_date[0];
  }
};

class CollectionAgencyTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      read_only: '',
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        {
          label: 'GSTIN',
          render: (name, all) => {
            return all['record']['GSTIN'];
          },
        },
        {
          label: 'Invoice Number',
          render: (name, all) => {
            return all['record']['CollectionAgencyInvoiceNo'];
          },
        },
        { key: 'row', label: 'Row #' },
        {
          label: 'Errors',
          render: (name, all) => {
            return Object.keys(all['errors_in']).map((i, v) => {
              return (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: 'red' }}>
                    {all['errors_in'][i].join(',')}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,
      allArcCollectionAgTransactionData: [],
      OriginalArcCollectionAgTransactionData: [],
      showUploadData: false,
      loading: false,

      add_DrawerOpen: false,
      addCollectionAgencyTransactionData: {},

      edit_DrawerOpen: false,
      editCollectionAgencyTransactionData: {},

      // add collection modal
      allArcSellerCustomerData: [],
      OriginalDataAllArcSellerCustomerData: [],
      collectionModalOpen: false,
      collectionModalBackDrop: false,

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: '',

      // upload history table section
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      pageForCustomer: 0,
      rowsPerPageForCustomer: 25,
      rowCountForCustomer: 0,
      loading: true,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.collectionAgencyTransactionGetAPi(this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.collectionAgencyTransactionGetAPi(this.state.searchInput);
    });
  };

  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.collectionAgencyTransactionGetAPi(this.state.searchInput);
      }
    );
  };

  //Select Customer
  handleChangePageForCustomer = (event, newPage) => {
    this.setState({ pageForCustomer: newPage }, () => {
      this.getSellerCustomerData(this.state.collectionModalSearchInput);
    });
  };

  handleChangeRowsPerPageForCustomer = (event) => {
    this.setState(
      { rowsPerPageForCustomer: +event.target.value, pageForCustomer: 0 },
      () => {
        this.getSellerCustomerData(this.state.collectionModalSearchInput);
      }
    );
  };

  arcCollectionModalSearch = (event) => {
    this.setState(
      {
        collectionModalSearchInput: event.target.value,
        pageForCustomer: 0,
        rowsPerPageForCustomer: 25,
      },
      () => {
        this.getSellerCustomerData(this.state.collectionModalSearchInput);
      }
    );
  };

  // collection agency transaction get api call
  collectionAgencyTransactionGetAPi = (serachIn) => {
    axios
      .post(`/arc/seller_collection_agency_transactions`, {
        number_of_rows_per_page: this.state.rowsPerPageForCustomer,
        page_number: this.state.pageForCustomer,
        search_txt: serachIn,
        business_entity_id: localStorage.getItem('business_entity_id'),
      })
      .then((res) => {
        this.setState({
          allArcCollectionAgTransactionData: res.data.records,
          OriginalArcCollectionAgTransactionData: res.data.records,
          rowCount: res.data.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller customer api call for popup[modal]
  getSellerCustomerData = (serachInForSeller) => {
    axios
      .post(`/arc/sellerCustomer`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_txt: serachInForSeller,
        seller_code: localStorage.getItem('business_entity_id'),
      })
      .then((res) => {
        this.setState({
          allArcSellerCustomerData: res.data.records,
          OriginalDataAllArcSellerCustomerData: res.data.records,
          rowCountForCustomer: res.data.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          'business_entity_id'
        )}/${7}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.collectionAgencyTransactionGetAPi();
    this.getSellerCustomerData();
    this.getHistoryTableUploadData();
  }

  fileUploader = (event) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/sellercollectionagencytansactions`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem('business_entity_id')
          ),
        })
        .then((data) => {
          this.getHistoryTableUploadData();
        })
        .catch((error) => {
          // toast.error(error, { autoClose:5000, progress: 0 })
        });
    };
  };

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = () => {
    const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Seller Collection Agency Transactions.xlsx`;
    axios
      .post('/seller_collection_transaction_extract/download', {
        data: this.state.allArcCollectionAgTransactionData,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.collectionAgencyTransactionGetAPi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerCollectionAgencyTransactions}
        download={'seller_collection_agency_transactions.xlsx'}
        style={{ textDecoration: 'none' }}
      >
        {' '}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: '20px' }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: '20px' }} />
        UPLOAD TEMPLATE{' '}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>
      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: 'right' }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: '20px' }} />
        Refresh
      </Button>
    </div>
  );

  // arc collection table header[customer table data in modal]---
  collectionModal_TableHeader() {
    const tableHeader = ['legal Name', 'gstin', 'pan', 'select'];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc collection table body[customer table data in modal]---
  collectionModal_tableBody() {
    const addGstin = (cust_gstin, cust_pan) => {
      this.getSellerCustomerData();
      this.setState({
        addCollectionAgencyTransactionData: {
          gstin: cust_gstin,
        },
        collectionModalOpen: false,
        collectionModalBackDrop: false,
        collectionModalSearchInput: '',
      });
    };

    if (this.state.allArcSellerCustomerData.length >= 1) {
      return this.state.allArcSellerCustomerData.map((tableData, index) => {
        const { cust_gstin, cust_legal_name, cust_pan } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {cust_legal_name}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>
              <AddCircleOutlineIcon onClick={() => addGstin(cust_gstin)} />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ------------------------------------------ARC invoice modal[ customer table] end---------------------------

  //-----------------------Collection agency transaction table -----------------
  arc_collAgencyTrans_TableHeader() {
    const tableHeader = [
      'customer name',
      'gstin',
      'agency name',
      'collection agency invoice no',
      'collection agency invoice date',
      'collection fees gst',
      'collection fees tds',
      'collection fees',
      'create date',
      'modified date',
      'action',
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_collAgencyTrans_TableBody() {
    const editCollectionAgencyTrans = (
      agency_name,
      cust_gstin,
      collection_agency_invoice_no,
      collection_agency_invoice_date,
      collection_fees,
      collection_fees_tds,
      collection_fees_gst
    ) => {
      let collectionAgencyInvoiceDate_yyy_mm = dateFormat(
        collection_agency_invoice_date
      );

      this.setState({
        editCollectionAgencyTransactionData: {
          agencyName: agency_name,
          gstin: cust_gstin,
          collectionAgencyInvoiceNo: collection_agency_invoice_no,
          collectionAgencyInvoiceDate: collection_agency_invoice_date,
          collectionFees: collection_fees,
          collectionFeesTds: collection_fees_tds,
          collectionFeesGst: collection_fees_gst,
        },
        collectionAgencyInvoiceDateDisplay: collectionAgencyInvoiceDate_yyy_mm,
        edit_DrawerOpen: true,
      });
    };

    if (this.state.allArcCollectionAgTransactionData.length >= 1) {
      return this.state.allArcCollectionAgTransactionData.map(
        (tableData, index) => {
          const {
            cust_name,
            cust_gstin,
            collection_fees_gst,
            collection_agency_invoice_no,
            collection_fees_tds,
            collection_fees,
            collection_agency_invoice_date,
            agency_name,
            create_date,
            modify_date,
          } = tableData; //destructuring
          return (
            <tr key={index}>
              <td>{cust_name}</td>
              <td> {cust_gstin}</td>
              <td>{agency_name}</td>
              <td>{collection_agency_invoice_no}</td>
              <td>{collection_agency_invoice_date}</td>
              <td>{numberFormat(collection_fees_gst)}</td>
              <td>{numberFormat(collection_fees_tds)}</td>
              <td>{numberFormat(collection_fees)}</td>
              <td>{create_date}</td>
              <td>{modify_date}</td>
              <td>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    editCollectionAgencyTrans(
                      agency_name,
                      cust_gstin,
                      collection_agency_invoice_no,
                      collection_agency_invoice_date,
                      collection_fees,
                      collection_fees_tds,
                      collection_fees_gst
                    )
                  }
                >
                  edit
                </Button>
              </td>
            </tr>
          );
        }
      );
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}
            colspan={11}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: 'red' }} colspan={11}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  //-----------------------Collection agency transaction table end-----------------
  //--------------------------Collection agency transaction add-------------------
  // add collection agency transaction [add button] drawer open handeler---
  addCollectionAgencyTransaction_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // collection agency invoice date handler
  handleCollectionAgencyInvoiceDateChange = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addCollectionAgencyTransactionData: {
          ...prevState.addCollectionAgencyTransactionData,
          collectionAgencyInvoiceDate: `${(pad + dd).slice(-2)}-${(
            pad + mm
          ).slice(-2)}-${yy}`,
        },
        collectionAgencyInvoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // add Collection agency transaction input field handler[number format]
  addCollectionAgencyTransaction_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;
    this.setState((prevState) => ({
      addCollectionAgencyTransactionData: {
        ...prevState.addCollectionAgencyTransactionData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add collection agency transaction inputfield handler---
  addCollectionAgencyTransaction_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      addCollectionAgencyTransactionData: {
        ...prevState.addCollectionAgencyTransactionData,
        [name]: String(value),
      },
    }));
  };

  // add collection agency transaction add submit handler
  addCollectionAgencyTransaction_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercollectionagencytransactions`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.addCollectionAgencyTransactionData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.collectionAgencyTransactionGetAPi();
          this.setState({
            add_DrawerOpen: false,
            addCollectionAgencyTransactionData: {},
            collectionAgencyInvoiceDateDisplay: '',
          });
          toast.success('Collection agency transaction added successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add collection agency Transaction drawer close handler---
  addCollectionAgencyTransaction_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addCollectionAgencyTransactionData: {},
    });
  };

  //-----------------------Collection agency transaction add end-----------------
  //--------------------------Collection agency transaction edit-------------------
  // edit collection agency transaction [edit button] drawer open handeler---
  editCollectionAgencyTransaction_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // collection agency invoice date handler
  editCollectionAgencyInvoiceDateChangeHandler = (v) => {
    if (v != 'Invalid Date' && v != null) {
      const pad = '00';
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editCollectionAgencyTransactionData: {
          ...prevState.editCollectionAgencyTransactionData,
          collectionAgencyInvoiceDate: `${(pad + dd).slice(-2)}-${(
            pad + mm
          ).slice(-2)}-${yy}`,
        },
        collectionAgencyInvoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      }));
    }
  };

  // edit Collection agency transaction input field handler[number format]
  editCollectionAgencyTransaction_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join('') : 0;

    this.setState((prevState) => ({
      editCollectionAgencyTransactionData: {
        ...prevState.editCollectionAgencyTransactionData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit collection agency transaction inputfield handler---
  editCollectionAgencyTransaction_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      editCollectionAgencyTransactionData: {
        ...prevState.editCollectionAgencyTransactionData,
        [name]: String(value),
      },
    }));
  };

  // edit collection agency transaction edit submit handler
  editCollectionAgencyTransaction_submitHamdler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellercollectionagencytransactions`, {
        entityGst: localStorage.getItem('gst'),
        records: [this.state.editCollectionAgencyTransactionData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = '';
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + ' ' + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.collectionAgencyTransactionGetAPi();
          this.setState({
            edit_DrawerOpen: false,
          });
          toast.success('Collection Agency Transaction updated successfully', {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        this.setState({
          edit_DrawerOpen: false,
        });
      });
  };

  // edit collection agency Transaction drawer close handler---
  editCollectionAgencyTransaction_DrawerClose = () => {
    this.setState({
      edit_DrawerOpen: false,
      editCollectionAgencyTransactionData: {},
    });
  };

  //-----------------------Collection agency transaction add end-----------------

  closeUploadData = () => {
    this.setState({ showUploadData: false });
  };

  // add collection modal
  addCollectionModalOpen = () => {
    this.setState({
      collectionModalOpen: true,
      collectionModalBackDrop: true,
    });
  };

  addCollectionModalClose = () => {
    this.getSellerCustomerData();
    this.setState({
      collectionModalOpen: false,
      collectionModalBackDrop: false,
      collectionModalSearchInput: '',
    });
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({ showUploadData: true });
        let res = response.data.records.records;

        if (response.data.records.type === 'json') {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === 'Fail') {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    // const { push, ...props } = this.props;
    return (
      <React.Fragment>
        {/* -----------------add collection modal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.collectionModalOpen}
          onHide={this.addCollectionModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.collectionModalSearchInput ?? ''}
                      textOnchangeFunction={this.arcCollectionModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div>
              <div className={classes.modalTableResponisve}>
                <table className={classes.tableCss}>
                  <tbody>
                    <tr>{this.collectionModal_TableHeader()}</tr>
                    {this.collectionModal_tableBody()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCountForCustomer} //total row count
                rowsPerPage={this.state.rowsPerPageForCustomer}
                page={this.state.pageForCustomer}
                onPageChange={this.handleChangePageForCustomer}
                onRowsPerPageChange={this.handleChangeRowsPerPageForCustomer}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addCollectionModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* add collection modal end  */}
        <div style={{ marginRight: '34px', marginBottom: '10px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/providerSelection">
              Home
            </Link>
            <Link color="inherit">ARC</Link>
            <Typography color="textPrimary">
              Upload Collection Agency Transactions Data
            </Typography>
          </Breadcrumbs>
        </div>

        {/* upload temp Button */}
        <div>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
          >
            upload{' '}
          </Button>
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper boxTitle="Upload History">
              <this.CollectionListActions />
              <div className="tableResponisve">
                <table className="tableCss">
                  <thead>
                    <tr>
                      <th>Last Upload Data & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === 'In Progress' ? (
                              <VisibilityIcon color="disabled" />
                            ) : item.status === 'Uploaded' ? (
                              <VisibilityIcon color="disabled" />
                            ) : (
                              <VisibilityIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: 'red', textAlign: 'center' }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: 'none',
                          marginBottom: '0px',
                          padding: '0px',
                          marginTop: '0px',
                        }}
                      >
                        <li style={{ display: 'inline-block' }}>
                          Upload Details
                        </li>
                        <li style={{ display: 'inline-block', float: 'right' }}>
                          <ClearIcon
                            onClick={this.closeUploadData}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: '30px', marginRight: '30px' }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: '300',
                            margin: '20px 30px 0px 30px',
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: 'red' }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}

        {/* table */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class="form_title_name">
                  <ul
                    style={{
                      listStyle: 'none',
                      marginBottom: '0px',
                      padding: '0px',
                      marginTop: '0px',
                    }}
                  >
                    <li style={{ display: 'inline-block' }}>
                      Collection Agency Transacation Details
                    </li>
                    <li style={{ display: 'inline-block', float: 'right' }}>
                      <Button size="small" onClick={this.export_to_excel}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Button
                        style={{ marginTop: '35px' }}
                        size="small"
                        variant="outlined"
                        onClick={this.addCollectionAgencyTransaction_drawerOpen}
                      >
                        Add Collection Agency Transaction
                      </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <InputField
                          // textLabel="Search"
                          inputPlacholder="Search...."
                          inputType="text"
                          requiredField=" "
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ''}
                          textOnchangeFunction={this.handleSearchInputChange}
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.tableResponisve}>
                  <table className={classes.tableCss}>
                    <tbody>
                      <tr>{this.arc_collAgencyTrans_TableHeader()}</tr>
                      {this.arc_collAgencyTrans_TableBody()}
                    </tbody>
                  </table>
                  <TablePagination
                    rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                    component="div"
                    count={this.state.rowCount} //total row count
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          variant={
            this.state.collectionModalBackDrop === true ? 'persistent' : ''
          }
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addCollectionAgencyTransaction_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: '45px' }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addCollectionModalOpen}
              >
                select customer*
              </Button>

              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.addCollectionAgencyTransactionData.gstin ?? ''
                }
                inputProps={{
                  readOnly: true,
                }}
                textOnchangeFunction={
                  this.addCollectionAgencyTransaction_inputHandler
                }
              />
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.addCollectionAgencyTransactionData.agencyName ?? ''
                }
                textOnchangeFunction={
                  this.addCollectionAgencyTransaction_inputHandler
                }
              />

              <InputField
                inputType="text"
                textLabel="Collection Agency Invoice"
                name="collectionAgencyInvoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.addCollectionAgencyTransactionData
                    .collectionAgencyInvoiceNo ?? ''
                }
                textOnchangeFunction={
                  this.addCollectionAgencyTransaction_inputHandler
                }
              />

              <div style={{ marginTop: '20px' }}>
                <label>
                  Collection Agency Invoice Date
                  <span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="collectionAgencyInvoiceDate"
                  value={this.state.collectionAgencyInvoiceDateDisplay ?? ''}
                  onChange={(v) =>
                    this.handleCollectionAgencyInvoiceDateChange(v)
                  }
                  validators={['required']}
                  errorMessages={['Date is required']}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyTransactionData.collectionFees
                      ? this.state.addCollectionAgencyTransactionData
                          .collectionFees
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyTransactionData: {
                        ...prevState.addCollectionAgencyTransactionData,
                        collectionFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees Gst<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyTransactionData
                      .collectionFeesGst
                      ? this.state.addCollectionAgencyTransactionData
                          .collectionFeesGst
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyTransactionData: {
                        ...prevState.addCollectionAgencyTransactionData,
                        collectionFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees Gst<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.addCollectionAgencyTransactionData
                      .collectionFeesTds
                      ? this.state.addCollectionAgencyTransactionData
                          .collectionFeesTds
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCollectionAgencyTransactionData: {
                        ...prevState.addCollectionAgencyTransactionData,
                        collectionFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.addCollectionAgencyTransaction_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* arc add drawer end */}

        {/* arc edit drawer  */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.editCollectionAgencyTransaction_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editCollectionAgencyTransaction_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyTransactionData.gstin ?? ''
                }
                textOnchangeFunction={
                  this.editCollectionAgencyTransaction_inputHandler
                }
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="Agency Name"
                name="agencyName"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyTransactionData.agencyName ??
                  ''
                }
                inputProps={{
                  readOnly: true,
                }}
                textOnchangeFunction={
                  this.editCollectionAgencyTransaction_inputHandler
                }
              />

              <InputField
                inputType="text"
                textLabel="Collection Agency Invoice"
                name="collectionAgencyInvoiceNo"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyTransactionData
                    .collectionAgencyInvoiceNo ?? ''
                }
                inputProps={{
                  readOnly: true,
                }}
                textOnchangeFunction={
                  this.editCollectionAgencyTransaction_inputHandler
                }
              />
              <InputField
                inputType="text"
                textLabel="Collection Agency Invoice Date"
                name="collectionAgencyInvoiceDate"
                requiredField="*"
                validator={['required']}
                errorMsg={['This field is required']}
                value={
                  this.state.editCollectionAgencyTransactionData
                    .collectionAgencyInvoiceDate ?? ''
                }
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editCollectionAgencyTransactionData
                      .collectionFees
                      ? this.state.editCollectionAgencyTransactionData
                          .collectionFees
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyTransactionData: {
                        ...prevState.editCollectionAgencyTransactionData,
                        collectionFees: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees Gst<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editCollectionAgencyTransactionData
                      .collectionFeesGst
                      ? this.state.editCollectionAgencyTransactionData
                          .collectionFeesGst
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyTransactionData: {
                        ...prevState.editCollectionAgencyTransactionData,
                        collectionFeesGst: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  ₹ Collection Fees Tds<span style={{ color: 'red' }}>*</span>:
                </label>{' '}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: '10.5px' }}
                  value={
                    this.state.editCollectionAgencyTransactionData
                      .collectionFeesTds
                      ? this.state.editCollectionAgencyTransactionData
                          .collectionFeesTds
                      : ''
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCollectionAgencyTransactionData: {
                        ...prevState.editCollectionAgencyTransactionData,
                        collectionFeesTds: parseFloat(value),
                      },
                    }));
                  }}
                />{' '}
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: '20px' }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: 'right',
                  marginTop: '20px',
                }}
                variant="contained"
                size="small"
                onClick={this.editCollectionAgencyTransaction_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>
        {/* arc edit drawer end */}
      </React.Fragment>
    );
  }
}

export default CollectionAgencyTransactions;
