import React from "react";
import "../TDSRecon.css";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import DashboardBarChart from "./dashboardBarChart";

function MYDefaulters(props) {
  const { defaulterData } = props;

  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  const dataForChart = [];
  let obj = {
    name: "Quarter 1",
    data: [],
  };

  let obj2 = {
    name: "Quarter 2",
    data: [],
  };

  let obj3 = {
    name: "Quarter 3",
    data: [],
  };
  let obj4 = {
    name: "Quarter 4",
    data: [],
  };
  let obj5 = {
    name: "Grand Total",
    data: [],
  };

  defaulterData.map((item) => {
    const {
      q1_total_tds_difference,
      q2_total_tds_difference,
      q3_total_tds_difference,
      q4_total_tds_difference,
      grand_total,
    } = item;
    obj.data.push(q1_total_tds_difference);
    obj2.data.push(q2_total_tds_difference);
    obj3.data.push(q3_total_tds_difference);
    obj4.data.push(q4_total_tds_difference);
    obj5.data.push(grand_total);
  });
  dataForChart.push(obj);
  dataForChart.push(obj2);
  dataForChart.push(obj3);
  dataForChart.push(obj4);
  // dataForChart.push(obj5)

  return (
    <>
      <BoxWrapper boxTitle="Major Difference in top 10 Customer's">
        <div>
          <DashboardBarChart
            chartTitle=""
            chartCategories={defaulterData.map((item) => item.customer_name)}
            yAxisTitle="Amount"
            chartData={dataForChart}
          />
        </div>
        {defaulterData.length !== 0 && (
          <>
            <div>
              <p>
                <span style={{ color: "red" }}>Note</span> :{" "}
                <strong>
                  “Positive Values indicate excess in Books and Negative Values
                  indicate excess in 26AS"
                </strong>{" "}
              </p>
            </div>
            <div className="responsive-table">
              <table className="custome-table">
                <thead>
                  <tr>
                    <th>Cust Name</th>
                    <th>PAN</th>
                    <th>TAN</th>
                    <th>Quarter 1</th>
                    <th>Quarter 2</th>
                    <th>Quarter 3</th>
                    <th>Quarter 4</th>
                    <th>Grand Total</th>
                  </tr>
                </thead>
                <tbody>
                  {defaulterData.map((item) => {
                    return (
                      <tr>
                        <td>{item.customer_name}</td>
                        <td>{item.cust_pan}</td>
                        <td>{item["customer/decuctor_tan"]}</td>
                        <td>
                          {indianRupeesFormat(item.q1_total_tds_difference)}
                        </td>
                        <td>
                          {indianRupeesFormat(item.q2_total_tds_difference)}
                        </td>
                        <td>
                          {indianRupeesFormat(item.q3_total_tds_difference)}
                        </td>
                        <td>
                          {indianRupeesFormat(item.q4_total_tds_difference)}
                        </td>
                        <td>{indianRupeesFormat(item.grand_total)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </BoxWrapper>
    </>
  );
}

export default MYDefaulters;
