import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from "@mui/material/Tooltip";
import BoxWrapper from '../../../../components/ui/boxWrapper';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import { useLocation } from "react-router-dom";
import { saveAs } from 'file-saver';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Prompt } from 'react-router-dom';
import XLSX from 'xlsx';
import axios from "../../../../axios-order";
import ConfirmationDialog from './ConfirmationDailog'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from "@mui/material/OutlinedInput";
import { Checkbox, FormControlLabel } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

import "./TDSRecon.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Form26AsPairing() {


  const [pairingData, setPairingData] = useState([])

  const [advancedPairData, setAdvancedPairData] = useState([])
  const location = useLocation();
  const payloadData = location.state.pairingData || {};

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  const [responseMessage, setResponseMessage] = useState("");

  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [targeturl, setTargetUrl] = useState([])

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false)

  const [loading, setLoading] = useState(false)

  const [pairIdSrNum, setPairIdSrNum] = useState(0)

  const [isAdvaced, setIsAdvanced] = useState(false)

  const [booksTds, setBooksTds] = useState(0)

  const [form26asTds, setForm26asTds] = useState(0)

  const history = useHistory();

  const [uniqueRemarks, setUniqueRemarks] = useState([]);

  const [selectedRemarks, setRemarks] = useState([])

  const [uniqueRemarks_2, setUniqueRemarks_2] = useState([]);

  const [selectedRemarks_2, setRemarks_2] = useState([])

  const [selectAllBooks, setSelectAllBooks] = useState(false);
  const [selectAll26AS, setSelectAll26AS] = useState(false);

  useEffect(() => {
    const unhiddenRows = localStorage.getItem('unhiddenRows') ? JSON.parse(localStorage.getItem('unhiddenRows')) : [];

    const remarksSet = new Set(pairingData.map(item => {
      if (!unhiddenRows.includes(item.id) && !item.matching_id && !isAdvaced && item.form_26as_id && item.grouping_key !== payloadData.grouping_key) {
        return null;
      }
      return item.remarks;
    }).filter(remark => remark !== null));

    console.log('remarksSet................', remarksSet)

    setUniqueRemarks([...remarksSet]);

    const remarksSet_2 = new Set(pairingData.map(item => {
      if (!unhiddenRows.includes(item.id) && !item.matching_id && !isAdvaced && item.form_26as_id && item.grouping_key !== payloadData.grouping_key) {
        return null;
      }
      return item.remarks_2;
    }));

    setUniqueRemarks_2([...remarksSet_2])

  }, [pairingData, isAdvaced]);


  const handleDialogConfirm = () => {
    setUnsavedChanges(false)
    history.goBack();
  }

  const handleBackFunction = () => {
    if (unsavedChanges) {
      setDialogOpen(true)
    } else {
      history.goBack();
    }
  }

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const generatePairId = () => {

    const srNo = pairIdSrNum
    const financial_year = localStorage.getItem('financial_year_26_as')
    let pairId = "M/";
    pairId += financial_year + "/" + (parseInt(srNo) + 1)
    // if (fyBook > fy26as) {

    //   pairId += fyBook + "/" + (parseInt(srNo) + 1)
    // } else {
    //   if (fy26as) {
    //     pairId += fy26as + "/" + (parseInt(srNo) + 1)
    //   } else {
    //     pairId += fyBook + "/" + (parseInt(srNo) + 1)
    //   }
    // }
    return pairId
  }

  useEffect(() => {
    getPairingData()
    setIsLoading(true);
    return (() => {
      localStorage.removeItem('unhiddenRows')
    })
  }, []);

  const saveReconData = () => {
    setLoading(true)
    axios.post('/form26/saveReconData', { 'business_entity_id': localStorage.getItem("business_entity_id"), 'paired_data': pairingData })
      .then((res) => {
        if (res.data.status) {
          setUnsavedChanges(false)
          handleSnackbarOpen(res.data.message, 'success')
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const getPairingData = () => {
    axios.post('/form26/get_pairing_table_data', payloadData)
      .then((res) => {
        if (res.data) {

          const updatedRecords = res.data.records.map(record => ({
            ...record,
            books_checked: false,
            form_26as_checked: false
          }));

          setPairingData(updatedRecords);
          setPairIdSrNum(localStorage.getItem("max_pair_id"))
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setPairingData([])
      });
  }

  const updateTdsAmounts = (checked, booksTdsAmount, form26asTdsAmount) => {
    if (checked) {
      setBooksTds(prevBooksTds => prevBooksTds + booksTdsAmount);
      setForm26asTds(prevForm26asTds => prevForm26asTds + form26asTdsAmount);
    } else {
      setBooksTds(prevBooksTds => {
        const newBooksTds = prevBooksTds - booksTdsAmount;
        return newBooksTds > 0 ? newBooksTds : 0;
      });
      setForm26asTds(prevForm26asTds => {
        const newForm26asTds = prevForm26asTds - form26asTdsAmount;
        return newForm26asTds > 0 ? newForm26asTds : 0;
      });
    }
  }

  const handleBooksCheckboxClcik = (event, item) => {
    const checked = event.target.checked;
    setPairingData(prevPairingData => {
      return prevPairingData.map(row => {
        if ((item.matching_id && row.matching_id === item.matching_id) || row.id === item.id) {
          updateTdsAmounts(
            checked,
            parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
            parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0)
          );
          return {
            ...row,
            books_checked: row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 ? checked : row.books_checked,
            form_26as_checked: row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 ? checked : row.form_26as_checked
          };
        }
        return row;
      });
    });
  };


  const handleForm26ASCheckboxClick = (event, item) => {
    const checked = event.target.checked;
    setPairingData(prevPairingData => {
      return prevPairingData.map(row => {
        if ((item.matching_id && row.matching_id === item.matching_id) || row.id === item.id) {
          updateTdsAmounts(
            checked,
            parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
            parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0)
          );
          return {
            ...row,
            books_checked: row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 ? checked : row.books_checked,
            form_26as_checked: row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 ? checked : row.form_26as_checked
          };
        }
        return row;
      });
    });
  };

  const handleRemarkColor = (remark) => {
    let color = "";
    switch (remark) {
      case "Exact Match":
        color = "#14A44D";
        break;
      case "Probable Match":
        color = "#3B71CA";
        break;
      case "Manually Paired":
        color = "#14A44D";
        break;
      case "Mismatch":
        color = "#E4A11B";
        break;
      case "Manually Unpaired":
        color = "#DC4C64";
        break;
      default:
        color = "#3B71CA";
    }
    return color;
  };

  const converBookData = (row) => {
    return {
      ...row,
      customer_name: row.books_name,
      // remarks: 'Unpaired',
      remarks_2: "Manually Unpaired",
      form_26as_id: '',
      id: uuidv4(),
      section: '',
      matching_id: null,
      form_26as_tds_amount: '',
      form_26as_financial_year: '',
      transaction_date: '',
      form_26_as_quarter: '',
      books_checked: false,
      form_26as_checked: false,
      form_26_as_checkbox_visble: true,

    }
  }

  const convertForm26asData = (row) => {
    return {
      ...row,
      id: uuidv4(),
      customer_name: row.form_26as_name,
      books_id: '',
      // remarks: 'Unpaired',
      remarks_2: "Manually Unpaired",
      invoice_no: '',
      matching_id: null,
      invoice_date: '',
      taxable_value: '',
      non_taxable_value: '',
      collection_date: '',
      amount_paid: '',
      books_tds_amount: '',
      books_quarter: '',
      books_financial_year: '',
      books_checked: false,
      form_26as_checked: false,
      books_checkbox_visble: true,

    }
  }

  const handleUnpair = () => {

    const unPairedRows = [];
    const unhiddenRows = []
    let displayRecordHaveNotPairId = false

    const checkedRecords = pairingData.filter(book => book.books_checked || book.form_26as_checked);
    if (checkedRecords.length === 0) {
      handleSnackbarOpen('Please select records to unpair.', 'warning');
      return ''
    }
    pairingData.forEach(row => {
      if (row.books_checked && row.form_26as_checked) {
        const converted26ASRow = convertForm26asData(row)
        unPairedRows.push(converBookData(row), converted26ASRow);
        unhiddenRows.push(converted26ASRow.id)
      } else if (row.books_checked || row.form_26as_checked) {
        if (row.matching_id) {
          unPairedRows.push({ ...row, matching_id: '', remarks_2: "Manually Unpaired", books_checked: false, form_26as_checked: false })
          unhiddenRows.push(row.id)
        } else {
          displayRecordHaveNotPairId = true;
        }
      } else {
        unPairedRows.push(row);
      }
      localStorage.setItem('unhiddenRows', JSON.stringify(unhiddenRows))
    });

    if (displayRecordHaveNotPairId) {
      handleSnackbarOpen('The selection contains records which are not paired. Please select only the paired records.', 'warning')
      const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
      setPairingData(pairedRows);
    } else {
      setRemarks_2([])
      setRemarks([])
      setUnsavedChanges(true)
      setPairingData(unPairedRows);
    }

    setBooksTds(0)
    setForm26asTds(0)
    setSelectAll26AS(false)
    setSelectAllBooks(false)
  };

  const handlePair = () => {

    let booksTdsSum = 0;
    let form26asTdsSum = 0;
    let booksSelectedCount = 0;
    let form26asSelectedCount = 0;
    let isNotUnpaired = false

    // calculate total selected books amount and 26AS amount and no of records selected from both sides
    pairingData.forEach((row) => {
      if (row.books_checked) {
        if (row.matching_id) {
          isNotUnpaired = true
        } else {
          booksTdsSum += parseFloat(row.books_tds_amount || 0);
          booksSelectedCount++;
        }
      }
      if (row.form_26as_checked) {
        if (row.matching_id) {
          isNotUnpaired = true
        } else {

          form26asTdsSum += parseFloat(row.form_26as_tds_amount || 0);
          form26asSelectedCount++;
        }
      }
    });

    // If there is already a paired record (matching_id exists), show a warning and reset selection
    if (isNotUnpaired === true) {
      const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
      setPairingData(pairedRows);
      handleSnackbarOpen('Please unpair the record before attempting to pair it', 'warning')
      setBooksTds(0)
      setForm26asTds(0)
      setSelectAll26AS(false)
      setSelectAllBooks(false)
      return;
    }

    // If the absolute TDS difference is small (<= 2) and both books and Form 26AS records are selected
    const tdsDifference = Math.abs(booksTdsSum - form26asTdsSum)
    if (tdsDifference <= 2 && (booksSelectedCount != 0 && form26asSelectedCount != 0)) {

      // Case: Exactly one record from both books and Form 26AS is selected
      if (booksSelectedCount === 1 && form26asSelectedCount === 1) {

        const selectedBooksRow = pairingData.find(row => row.books_checked);
        const selectedForm26AsRow = pairingData.find(row => row.form_26as_checked);

        const pairId1 = generatePairId() // Generate a new pairing ID

        // Prepare new row from the books and 26AS rows and merge as one record
        const newRow = {
          ...selectedBooksRow,
          form_26as_tds_amount: selectedForm26AsRow.form_26as_tds_amount,
          form_26as_id: selectedForm26AsRow.form_26as_id,
          section: selectedForm26AsRow.section,
          transaction_date: selectedForm26AsRow.transaction_date,
          form_26as_name: selectedForm26AsRow.form_26as_name,
          form_26_as_quarter: selectedForm26AsRow.form_26_as_quarter,
          form_26as_financial_year: selectedForm26AsRow.form_26as_financial_year,
          books_checked: false,
          form_26as_checked: false,
          matching_id: pairId1,
          grouping_key: selectedForm26AsRow.grouping_key,
          form_26_as_checkbox_visble: false,
          // remarks: "Manually Paired",
          remarks_2: "Manually Paired"
        };

        // Set the pairing data with the new paired row
        setUnsavedChanges(true)
        setPairingData(prevData => prevData.map(row => {
          if (row.id === selectedBooksRow.id) {
            return newRow;
          }
          if (row.id === selectedForm26AsRow.id) {
            return null;
          }
          return row;
        }).filter(Boolean));

        // Update localStorage with the new max pairing ID
        localStorage.setItem('max_pair_id', parseInt(pairId1.slice(10,)))
        setPairIdSrNum(parseInt(pairId1.slice(10,)))

      }
      else {
        // Case: Multiple rows selected for pairing from any side (Books or 26AS)
        const pairedRows = [];
        let pairId = ''
        pairingData.forEach((row) => {
          if (row.books_checked || row.form_26as_checked) { // If row is selected
            pairId = generatePairId() // Generate a new pairing ID
            // Assign the pairing ID to selected rows 
            const newRow = { ...row, matching_id: pairId, books_checked: false, form_26as_checked: false, remarks_2: "Manually Paired" };
            pairedRows.push(newRow);
          } else {
            pairedRows.push(row);
          }
        });
        setUnsavedChanges(true)
        setPairingData(pairedRows);
        localStorage.setItem('max_pair_id', parseInt(pairId.slice(10,)))
        setPairIdSrNum(parseInt(pairId.slice(10,)))
      }
      handleSnackbarOpen('Records paired manually successfully.', 'success');
      setRemarks_2([])
      setRemarks([])
    } else {
      // Handle the case where the TDS amounts do not match or no records were selected
      if (booksSelectedCount === 0 && form26asSelectedCount === 0) {
        handleSnackbarOpen('Please select records to pair.', 'warning');
      }
      // If books are selected but Form 26AS is not selected
      else if (booksSelectedCount >= 1 && form26asSelectedCount === 0) {
        handleSnackbarOpen('Please select the corresponding 26AS records to pair.', 'warning');
        const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
        setPairingData(pairedRows);
      }
      // If Form 26AS is selected but books are not selected
      else if (form26asSelectedCount >= 1 && booksSelectedCount === 0) {
        handleSnackbarOpen('Please select the corresponding books records to pair.', 'warning');
        const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
        setPairingData(pairedRows);
      }
      // If TDS amounts do not match, reset selection and show warning
      else {
        const pairedRows = pairingData.map(row => ({ ...row, books_checked: false, form_26as_checked: false }));
        setPairingData(pairedRows);
        handleSnackbarOpen('TDS amounts do not match. Unable to pair.', 'warning');
      }
    }
    setBooksTds(0)
    setForm26asTds(0)
    setSelectAll26AS(false)
    setSelectAllBooks(false)
  };

  const changeToAdvacedStatuts = (e) => {
    const checked = e.target.checked


    if (checked === false) {
      const checkedRecords = pairingData.filter(book => book.books_checked || book.form_26as_checked);
      if (checkedRecords.length >= 1) {
        handleSnackbarOpen('Please deselect all records before removing the advanced filter.', 'warning');
      } else {
        setIsAdvanced(checked)
        setRemarks([])
        setRemarks_2([])
      }


    } else {
      setIsAdvanced(checked)
      setRemarks([])
      setRemarks_2([])
    }

  }

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleExport = () => {

    const unhiddenRows = localStorage.getItem('unhiddenRows') ? JSON.parse(localStorage.getItem('unhiddenRows')) : []

    const excelData = pairingData
      .filter(row =>
        unhiddenRows.includes(row.id) ||
        row.matching_id ||
        isAdvaced ||
        !row.form_26as_id ||
        row.grouping_key === payloadData.grouping_key
      )
      .map(item => item);

    const payloadData_1 = {

      "business_entity_id": localStorage.getItem("business_entity_id"),
      "financial_year": localStorage.getItem('financial_year_26_as'),
      "records": excelData
    }



    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `All Transaction Details.xlsx`;

    axios.post('/form26asreport_for_table3', payloadData_1)
      .then((res) => {
        downloadBase64File(contentType, res?.data?.file, filename);
      }).catch((err) => {

      })


  };


  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    setRemarks(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSelectRemarks2 = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    setRemarks_2(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  const handleSelectAllBooks = (event) => {
    const unhiddenRows = localStorage.getItem('unhiddenRows') ? JSON.parse(localStorage.getItem('unhiddenRows')) : []
    const checked = event.target.checked;
    setSelectAllBooks(checked)
    setPairingData(prevPairingData => {
      return prevPairingData.map(row => {

        if (!unhiddenRows.includes(row.id) && !row.matching_id && isAdvaced === false && row.form_26as_id && row.grouping_key !== payloadData.grouping_key) {
          return row; // Do not select this row, just return as is
        }

        const shouldSelectRow = selectedRemarks.length >= 1 ? selectedRemarks.includes(row.remarks) : true;
        const shouldSelectRow_2 = selectedRemarks_2.length >= 1 ? selectedRemarks_2.includes(row.remarks_2) : true;

        if (shouldSelectRow && shouldSelectRow_2 && !row.books_checkbox_visble && row.remarks !== 'Exact Match' && (row.books_tds_amount && parseFloat(row.books_tds_amount) > 0)) {
          if (!row.books_checked || !checked) {
            updateTdsAmounts(
              checked,
              parseFloat(row.books_tds_amount ? row.books_tds_amount : 0),
              0
            );
          }

          return {
            ...row,
            books_checked: checked,
            // form_26as_checked: row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0 ? checked : row.form_26as_checked
          };
        }
        return row;
      });
    });
  };

  const handleSelectAll26AS = (event) => {
    const unhiddenRows = localStorage.getItem('unhiddenRows') ? JSON.parse(localStorage.getItem('unhiddenRows')) : []
    const checked = event.target.checked;
    setSelectAll26AS(checked)
    setPairingData(prevPairingData => {
      return prevPairingData.map(row => {

        if (!unhiddenRows.includes(row.id) && !row.matching_id && isAdvaced === false && row.form_26as_id && row.grouping_key !== payloadData.grouping_key) {
          return row; // Do not select this row, just return as is
        }

        const shouldSelectRow = selectedRemarks.length >= 1 ? selectedRemarks.includes(row.remarks) : true;
        const shouldSelectRow_2 = selectedRemarks_2.length >= 1 ? selectedRemarks_2.includes(row.remarks_2) : true;
        if (shouldSelectRow && shouldSelectRow_2 && !row.form_26as_checkbox_visble && row.remarks !== 'Exact Match' && (row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0)) {
          if (!row.form_26as_checked || !checked) {
            updateTdsAmounts(
              checked,
              0,
              parseFloat(row.form_26as_tds_amount ? row.form_26as_tds_amount : 0),
            );
          }

          return {
            ...row,
            form_26as_checked: checked,
            // books_checked: row.books_tds_amount && parseFloat(row.books_tds_amount) > 0 ? checked : row.books_checked
          };
        }
        return row;
      });
    });
  };



  let prevId = null;
  let prevBackgroundColor = "#f2f2f2";
  const financial_year = localStorage.getItem('financial_year_26_as')

  return (
    <div>


      <BoxWrapper boxTitle={`Form 26AS Pairing (${financial_year})`}

        enableAdvancedCheckBox={true}
        enableIcon2={true}
        enableIcon3={true}
        exportToExcelEnable={true}
        exportToExcelFn={handleExport}
        enableAdvancedCheckBoxVal={isAdvaced}
        handleIconAdvancedCheckBoxClickFunction={changeToAdvacedStatuts}
        enableIcon2Value={
          <Tooltip title="Pair">
            <AddCircleIcon sx={{ color: green[500], fontSize: '30px' }} />
          </Tooltip>
        }
        handleIcon2ClickFunction={handlePair}

        enableIcon3Value={
          <Tooltip title="Unpair">
            <RemoveCircleIcon sx={{ color: red[500], fontSize: '30px' }} color="secondary" />
          </Tooltip>
        }
        handleIcon3ClickFunction={handleUnpair}

      >

        <div
          className="responsive-table"
          style={{ scrollbarWidth: "thin", scrollbarHeight: '25px', maxHeight: '45vh' }}
          id="scrollableDiv"
        >
          <table className="summary-tab-single-year-table-css" >
            <thead>

              <tr>
                {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }}>Books ID</th>}
                {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }}>26AS ID</th>}
                {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080', width: '3.5%' }}>Grouping Key</th>}
                <th style={{ width: '4%' }}>Customer TAN</th>
                <th style={{ width: '9%' }}>Customer Name</th>
                {/* <th>Section</th> */}
                <th>Invoice No.</th>
                <th>Invoice Date</th>
                <th>Taxable Value</th>
                <th>Non Taxable Value</th>
                <th>Collection Date</th>
                {/* <th>Amount Paid</th> */}
                <th>Transaction Date</th>
                <th>Form26AS TDS Amount</th>
                <th>Books Tds Amount</th>
                <th>
                  Books
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllBooks}
                        onChange={handleSelectAllBooks}
                        disabled={localStorage.getItem('locked_status') === "Y"}
                        title={localStorage.getItem('locked_status') === "Y" && "This financial year is locked"}
                      />
                    }
                    style={{ marginLeft: '0.5px', fontWeight: 'bold' }}
                    label="Books"
                    labelPlacement="middle"
                  /> */}
                </th>
                <th>
                  26AS
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll26AS}
                        onChange={handleSelectAll26AS}
                        disabled={localStorage.getItem('locked_status') === "Y"}
                        title={localStorage.getItem('locked_status') === "Y" && "This financial year is locked"}
                      />
                    }
                    style={{ marginLeft: '0.5px', fontWeight: 'bold' }}
                    label="26AS"
                    labelPlacement="middle"  // Label appears after the checkbox
                  /> */}
                </th>
                <th style={{ width: '6.5%' }}>
                  {uniqueRemarks.length >= 1 ? (
                    <FormControl sx={{ m: 1, width: 120 }} variant="filled">
                      <InputLabel id="demo-multiple-checkbox-label">Remarks</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedRemarks}
                        onChange={handleSelect}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        size='small'
                      >
                        {uniqueRemarks.map((remark) => (
                          <MenuItem key={remark} value={remark}>
                            <Checkbox checked={selectedRemarks.indexOf(remark) > -1} />
                            <ListItemText primary={remark === null ? 'None' : remark} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <p>Remarks 2 is empty</p>
                  )}
                </th>

                <th style={{ width: '5.5%' }}>
                  {uniqueRemarks_2.length >= 1 ? (
                    <FormControl sx={{ m: 1, width: 120 }} variant="filled">
                      <InputLabel id="demo-multiple-checkbox-label">Remarks-2</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedRemarks_2}
                        onChange={handleSelectRemarks2}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        size='small'
                      >
                        {uniqueRemarks_2.map((remark) => (
                          <MenuItem key={remark} value={remark}>
                            <Checkbox checked={selectedRemarks_2.indexOf(remark) > -1} />
                            <ListItemText primary={remark === null ? 'None' : remark} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <p>Remarks-2</p>
                  )}
                </th>

                <th style={{ width: '7%' }}>Reason</th>
                <th style={{ width: '4%' }}>Matching ID</th>
                <th>Books Quarter</th>
                <th>26AS Quarter</th>
                <th>Books - FY</th>
                <th>26AS - FY</th>
                <th style={{ width: '5%' }}>Entity GSTIN</th>
                <th style={{ width: '7%' }}>Branch Name</th>
                <th>TDS %</th>
                <th>LTDS %</th>
              </tr>

            </thead>
            <tbody>

              {isLoading ? (
                <tr>
                  <td
                    style={{ color: "black", textAlign: 'center', fontWeight: "bold" }}
                    colspan={12}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) :

                pairingData.length >= 1 ? (pairingData.map((row, index) => {
                  const unhiddenRows = localStorage.getItem('unhiddenRows') ? JSON.parse(localStorage.getItem('unhiddenRows')) : []

                  if (!unhiddenRows.includes(row.id) && !row.matching_id && isAdvaced === false && row.form_26as_id && row.grouping_key !== payloadData.grouping_key) {
                    return null;
                  }

                  let backgroundColor;
                  if (row.matching_id && (row.matching_id === prevId ||
                    (pairingData.find((item) => item.matching_id === prevId) && prevId === row.matching_id))) {
                    backgroundColor = prevBackgroundColor;
                  } else if (!row.matching_id) {
                    backgroundColor = prevBackgroundColor === "white" ? "#f2f2f2" : "white"
                  }
                  else if (prevBackgroundColor === "white") {
                    backgroundColor = "#f2f2f2";
                  } else {

                    backgroundColor = "white";
                  }
                  prevId = row.matching_id;
                  prevBackgroundColor = backgroundColor;

                  if ((selectedRemarks.length >= 1 && !selectedRemarks.includes(row.remarks)) || (selectedRemarks_2.length >= 1 && !selectedRemarks_2.includes(row.remarks_2))) {
                    return null;
                  }


                  return (
                    <tr
                      key={row.id}
                      style={{ backgroundColor: backgroundColor }}
                    >
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{row.books_id}</td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{row.form_26as_id}</td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{row.grouping_key}</td>}
                      <td >{row.tan}</td>
                      <td>{row.customer_name}</td>
                      {/* <td>{row.section}</td> */}
                      <td align="center">{row.invoice_no}</td>
                      <td align="center">{row.invoice_date}</td>

                      <td align="center">
                        {row.taxable_value}
                      </td>

                      <td align="center">{row.non_taxable_value}</td>
                      <td align="center">{row.collection_date}</td>
                      {/* <td align="center">{row.amount_paid}</td> */}
                      <td>{row.transaction_date}</td>
                      <td>{row.form_26as_tds_amount}</td>
                      <td>{row.books_tds_amount}</td>
                      <td align="center" padding="checkbox" style={{ height: '40px' }}>
                        {!row.books_checkbox_visble && row.remarks !== 'Exact Match' && (row.books_tds_amount && parseFloat(row.books_tds_amount) > 0) ?
                          <input type='checkbox'
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#fff',
                              border: '2px solid #4CAF50',
                              borderRadius: '5px',
                              cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                            }}

                            title={localStorage.getItem('locked_status') === "Y" && "This financial year is locked"}

                            disabled={localStorage.getItem('locked_status') === "Y" ? true : false}
                            checked={row.books_checked}
                            onChange={(event) => handleBooksCheckboxClcik(event, row)}
                          /> : ''}
                      </td>
                      <td align="center" padding="checkbox">
                        {!row.form_26_as_checkbox_visble && row.remarks !== "Exact Match" && (row.form_26as_tds_amount && parseFloat(row.form_26as_tds_amount) > 0) ?
                          <input type='checkbox'
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#fff',
                              border: '2px solid #4CAF50',
                              borderRadius: '5px',
                              cursor: localStorage.getItem('locked_status') === "Y" ? 'not-allowed' : 'pointer'
                            }}
                            disabled={localStorage.getItem('locked_status') === "Y" ? true : false}
                            checked={row.form_26as_checked}
                            onChange={(event) => handleForm26ASCheckboxClick(event, row)}
                          /> : ''}
                      </td>
                      <td align="center" style={{ paddingLeft: '20px' }}>
                        {row.remarks ? <div style={{ width: '110px', color: 'white', height: '26px', textAlign: 'center', borderStyle: 'solid', borderWidth: '0px', borderColor: 'gray', borderRadius: '8px', backgroundColor: handleRemarkColor(row.remarks), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {row.remarks}
                        </div> : ''}
                      </td>
                      <td align="center" style={{ paddingLeft: '10px' }}>
                        {row.remarks_2 ? <div style={{ width: '110px', color: 'white', height: '26px', textAlign: 'center', borderStyle: 'solid', borderWidth: '0px', borderColor: 'gray', borderRadius: '8px', backgroundColor: handleRemarkColor(row.remarks_2), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {row.remarks_2}
                        </div> : ''}
                      </td>
                      <td >{row.match_status_reason}</td>
                      <td>{row.matching_id}</td>
                      <td>{row.books_quarter}</td>
                      <td>{row.form_26_as_quarter}</td>
                      <td>{row.books_financial_year}</td>
                      <td>{row.form_26as_financial_year}</td>
                      <td>{row.entity_gstin}</td>
                      <td>{row.branch_name}</td>
                      <td>{row.tds_percentage}</td>
                      <td>{row.ltds_percentage}</td>
                    </tr>
                  );
                })) : (
                  <tr>
                    <td style={{ color: "red" }} colspan={6}>
                      <img src="https://res.cloudinary.com/dyivs1j8u/image/upload/v1713335965/Screenshot_2024-04-17_120750-removebg-preview_tdhay3.png" />
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>

        </div>
        {/* color: '#007bff', */}
      </BoxWrapper>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginTop: '10px', marginLeft: '1%' }}>
          <p style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', color: '#007bff', fontWeight: 'bold' }}>
            <span style={{ width: '280px' }}>SELECTED BOOKS TDS AMOUNT : <span style={{ fontWeight: 'bold' }}> {booksTds.toFixed(2)}</span></span>

          </p>
          <p style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', color: '#007bff', fontWeight: 'bold' }}>
            <span style={{ width: '280px' }}>SELECTED 26AS TDS AMOUNT : <span style={{ fontWeight: 'bold' }}> {form26asTds.toFixed(2)}</span></span>

          </p>
          <p style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', color: '#007bff', fontWeight: 'bold' }}>
            <span style={{ width: '280px' }}>DIFFERENCE : <span style={{ fontWeight: 'bold' }}> {Math.abs(booksTds - form26asTds).toFixed(2)}</span></span>

          </p>
        </div>
        <div style={{ position: 'absolute', bottom: '20px', right: '20px', display: 'flex', marginBottom: '1.5%' }}>
          <button
            onClick={handleBackFunction}
            className="button-outlined-primary"
            style={{ width: '120px', height: '35px', marginRight: '10px' }}
          >
            Back
          </button>
          <LoadingButton
            color="primary"
            onClick={saveReconData}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            style={{ width: '120px', height: '35px' }}
          >
            <span>Save</span>
          </LoadingButton>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}
          {message}
        </MuiAlert>
      </Snackbar>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDialogConfirm}
        title={'Confirm Leaving Page'}
        text={'You have unsaved changes. Are you sure you want to leave this page without saving?'}
      />
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave this page without saving?"
      // message={(targetUrl) => {
      //   setDialogOpen(true); // Open the dialog
      //   setTargetUrl(targetUrl)
      //   return false; // Pass the target URL back for navigation
      // }}
      />
    </div>
  );
}
