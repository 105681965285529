import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Grid, Paper, TablePagination } from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import BarchartRupessFormat from "../../../../components/ui/BarChart_rupeesFormat";
import PieChart from "../../../../components/ui/PieCharts";
import DashboardFilter from "./DashboardFilter";
import Cards from "./Cards";
import CustomerTable from "./CustomerTable";
import InvoiceTable from "./InvoiceTable";
import StackedBarChart from "../../../../components/ui/StackedBarChart";
import axios from "../../../../axios-order";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  // last refresh date and time
  const [lastRefreshDate, setLastRefreshDate] = useState("");
  const [lastRefreshTime, setLastRefreshTime] = useState("");
  // filter and card
  const [filterValues, setFilterValues] = useState({
    seller_gstin: localStorage.getItem("gst"),
  });

  const [filterDropdownData, setFilterDropdownData] = useState({
    cust_gstin_list: [],
    registration_type_list: [],
    agieng_list: [],
    as_of_date_list: [],
    cust_pan_list: [],
    account_manager_list: [],
    customer_name_list: [],
    customer_code_list: [],
  });
  const [filterError, setFilterError] = useState(false);
  const [cardsData, setCardsData] = useState(null);
  // const [errorMsgForFilterGetApi, setErrorMsgForFilterGetApi] = useState(null);

  // show/hide component state
  const [showCustomerTable, setShowCustomerTable] = useState(false);
  const [showInvoiceTable, setShowInvoiceTable] = useState(false);
  const [showCharts, setShowCharts] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [tableFilter, setTableFilter] = useState(false);
  // const [showContent, setShowContent] = useState(true);

  // loader
  const [loaderForPage, setLoaderForPage] = useState(true);
  const [filterLoader, setFilterLoader] = useState(false);
  const [showErrorForPage, setErrorForPage] = useState(false);
  const [invoiceLoader, setInvoiceLoader] = useState(true);
  const [customerLoader, setCustomerLoader] = useState(true);

  // charts
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartDataForNotYrtDue, setPieChartDataForNotYrtDue] = useState([]);
  const [barChartData, setBarchartData] = useState([]);
  const [maxAndMinValueforChart, setMaxAndMinValueForBarChart] = useState(null);
  const [customerNotYetDueDataForChart, setCustomerNotYetDueDataForChart] =
    useState([]);
  const [eachBucketData, setEachBucketData] = useState([]);
  const [customerNameFlag, setCustomerNameFlag] = useState(false);
  const [yearForMontlyChart, setYearForMontlyChart] = useState("");
  const [topCustomerNo, setTopCustomerNo] = useState(5);

  // table
  const [customerData, setCustomerData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  // pagination for customer
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  // api's functions
  const filterDropdownDatafn = () => {
    axios
      .post(`/get_seller_ageing_receivables_dropdown_details`, {
        seller_gstin: localStorage.getItem("gst"),
      })
      .then((res) => {
        if (res.data.status) {
          setFilterDropdownData(res.data.data);
          // ......
          let dates = res.data.data.as_of_date_list;
          let maxDate = dates.sort(function (a, b) {
            let aSplit = a.split("-");
            let bSplit = b.split("-");
            let newDatea = new Date(`${aSplit[2]}-${aSplit[1]}-${aSplit[0]}`);
            let newDateb = new Date(`${bSplit[2]}-${bSplit[1]}-${bSplit[0]}`);
            return newDatea - newDateb;
          });
          let defaultAsOfDate = maxDate[maxDate.length - 1];

          let promise = new Promise((resolve, reject) => {
            setFilterValues({
              ...filterValues,
              ["as_of_date"]: defaultAsOfDate,
            });
            const payload = {
              as_of_date: defaultAsOfDate,
              seller_gstin: localStorage.getItem("gst"),
            };
            cardsDataFn(payload, topCustomerNo);
            stackedApiForTotalOutstand(payload);
            monthlyChartForEachAgeingBucket(payload);
            lastRefreshDateTime();
            return resolve(true);
          });

          promise.then((res) => {
            setLoaderForPage(false);
          });
        } else {
          setLoaderForPage(false);
          setErrorForPage(true);
        }
      });
  };

  const cardsDataFn = (payload, CustomerNo) => {
    axios
      .post(`/get_seller_ageing_receivables_cards_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setCardsData(res.data.cards_data);
          setFilterError(false);
          pieChartsApi(payload);
          pieChartsApiForNotYetDue(payload);
          barChartsApi(payload, CustomerNo);
        } else {
          setFilterError(true);
        }
      });
  };

  function invoiceDetailsApi(payload) {
    axios
      .post(`/get_seller_ageing_receivables_invoice_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setInvoiceLoader(false);
          setInvoiceData(res.data.data);
          setRowCount(res.data.row_count);
        } else {
          setInvoiceData(res.data.data);
          setRowCount(res.data.row_count);
          setInvoiceLoader(false);
        }
      })
      .catch((error) => {});
  }

  function customerDetailsApi(payload) {
    axios
      .post(`/get_seller_ageing_receivables_customers_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setCustomerLoader(false);
          setCustomerData(res.data.data);
          setRowCount(res.data.row_count);
        } else {
          setCustomerData(res.data.data);
          setCustomerLoader(false);
          setRowCount(res.data.row_count);
        }
      })
      .catch((error) => {});
  }

  function pieChartsApi(payload) {
    axios
      .post(`/get_seller_ageing_receivables_pie_graph_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setPieChartData(res.data.pie_graph_data);
        } else {
          console.log("error");
        }
      });
  }

  function pieChartsApiForNotYetDue(payload) {
    axios
      .post(`get_seller_due_receivables_pie_graph_details`, payload)
      .then((res) => {
        if (res.data.status) {
          setPieChartDataForNotYrtDue(res.data.pie_graph_data);
        } else {
          console.log("error");
        }
      });
  }

  function barChartsApi(payload, topCustomer) {
    axios
      .post(`/get_seller_ageing_receivables_bar_graph_details`, {
        ...payload,
        top_customer_number: topCustomer,
      })
      .then((res) => {
        if (res.data.status) {
          setBarchartData(res.data.data);
          setMaxAndMinValueForBarChart(res.data.max_min_value_for_yaxis);
        } else {
          console.log("error");
        }
      });
  }

  function stackedApiForTotalOutstand(payload) {
    axios
      .post(`/monthly_not_yet_due_and_total_outstanding_amount`, payload)
      .then((res) => {
        if (res.data.status) {
          setCustomerNotYetDueDataForChart(res.data.data);
        } else {
          console.log("error");
        }
      });
  }

  function monthlyChartForEachAgeingBucket(payload) {
    axios
      .post(`/get_drilldown_monthly_outstanding_on_ageing_bucket`, payload)
      .then((res) => {
        if (res.data.status) {
          setEachBucketData(res.data.data);
          setLoaderForPage(false);
        } else {
          console.log("error");
        }
      });
  }

  function lastRefreshDateTime() {
    axios
      .post(
        "/cws_reportingv2/ageing_recievables/last_refresh_date_ageing_recievable",
        {
          seller_code: localStorage.getItem("business_entity_id"),
        }
      )
      .then((res) => {
        if (res.data.status) {
          let timeZone = res.data.last_refreshed_date.split("T");
          let timeArray = timeZone[1].split("Z");
          setLastRefreshTime(timeArray[0]);
          setLastRefreshDate(res.data.last_refreshed_date);
        } else {
          setLoaderForPage(false);
          setErrorForPage(true);
        }
      });
  }

  useEffect(() => {
    filterDropdownDatafn();
  }, []);

  // display invoice table handler
  const handleTriggerInvoive = () => {
    setShowInvoiceTable(true);
    setTableFilter(true);
    setShowCharts(false);
    setShowCustomerTable(false);
    setRowsPerPage(25);
    setPage(0);
    const payload = {
      ...filterValues,
      page_number: 0,
      page_size: 25,
    };
    invoiceDetailsApi(payload);
  };

  // display customer table handler
  const handleTriggerCustomer = () => {
    setShowCustomerTable(true);
    setTableFilter(true);
    setShowInvoiceTable(false);
    setShowCharts(false);
    setRowsPerPage(25);
    setPage(0);
    const payload = {
      ...filterValues,
      page_number: 0,
      page_size: 25,
    };
    customerDetailsApi(payload);
  };

  // back to summary handler
  const handleBackEvent = () => {
    setShowCharts(true);
    setFilterValues({
      as_of_date: filterValues.as_of_date,
      seller_gstin: localStorage.getItem("gst"),
    });
    setShowCustomerTable(false);
    setShowInvoiceTable(false);
    setTableFilter(false);
    setRowsPerPage(2);
    setPage(0);
  };

  // refresh button handler
  const handleRefresh = () => {
    axios
      .post("/cws_reportingv2/ageing_recievables/refresh_ageing_recievable", {
        seller_code: localStorage.getItem("business_entity_id"),
        gstin: localStorage.getItem("gst"),
      })
      .then((res) => {
        if (res.data.status) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
  };

  // display filter section
  const showFilterHandler = () => {
    setShowFilter(!showFilter);
  };

  // filter/search final submit handler
  const filterSubmitHandler = () => {
    setFilterLoader(true);
    toast.configure();

    if (filterValues.as_of_date && filterValues.seller_gstin) {
      let promise = new Promise((resolve, reject) => {
        const data = {
          ...filterValues,
          page_number: 0,
          page_size: 25,
        };

        if (showCustomerTable === false && showInvoiceTable === false) {
          const payload = {
            ...filterValues,
          };
          cardsDataFn(payload, topCustomerNo);
          // pieChartsApi(payload);
          // barChartsApi(payload);
          // stackedApiForTotalOutstand(payload);
          // monthlyChartForEachAgeingBucket(payload);
        }
        if (showCustomerTable) {
          customerDetailsApi(data);
        }

        if (showInvoiceTable) {
          invoiceDetailsApi(data);
        }

        return resolve(true);
      });

      promise.then((res) => {
        setFilterLoader(false);
      });

      toast.success("Data fetched successfully ", {
        autoClose: 1000,
      });
    } else {
      return false;
    }
  };

  const cancelFilterHandler = (e) => {
    setFilterValues({
      as_of_date: filterValues.as_of_date,
      seller_gstin: localStorage.getItem("gst"),
    });
  };

  // page chage handler for both table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      ...filterValues,
      page_number: newPage,
      page_size: rowsPerPage,
    };
    if (showCustomerTable) {
      customerDetailsApi(data);
    } else {
      invoiceDetailsApi(data);
    }
  };

  // row per page handler for both table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const data = {
      ...filterValues,
      page_number: 0,
      page_size: +event.target.value,
    };
    if (showCustomerTable) {
      customerDetailsApi(data);
    } else {
      invoiceDetailsApi(data);
    }
  };

  // chart filter handler for invoice data
  useEffect(() => {
    if (customerNameFlag) {
      const data = {
        ...filterValues,
        page_number: 0,
        page_size: 25,
      };
      invoiceDetailsApi(data);
    }
    setCustomerNameFlag(false);
  }, [filterValues.customer_name || filterValues.ageing || filterValues.due]);

  const drilldownHandlerForAgeingChart = (i) => {
    setCustomerNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["ageing"]: i,
    });

    setShowInvoiceTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowCustomerTable(false);
    setShowCharts(false);
    setRowsPerPage(25);
    setPage(0);
  };

  const drilldownHandlerForAgeingChartForNotYetDue = (i) => {
    setCustomerNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["due"]: i,
    });

    setShowInvoiceTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowCustomerTable(false);
    setShowCharts(false);
    setRowsPerPage(25);
    setPage(0);
  };

  const drilldownHandlerForCustomerChart = (i) => {
    setCustomerNameFlag(true);
    setFilterValues({
      ...filterValues,
      ["customer_name"]: i,
    });

    setShowInvoiceTable(true);
    setShowFilter(true);
    setTableFilter(true);
    setShowCustomerTable(false);
    setShowCharts(false);
    setRowsPerPage(25);
    setPage(0);
  };

  const topCustomerDropdownHandler = (e) => {
    let value = e.target.value;
    setTopCustomerNo(value);
    let payload = { ...filterValues };
    barChartsApi(payload, value);
  };

  // monthly trend chart filter for customer data
  const monthlyChartDrilldownHandler = (i) => {
    let year = i.split("-");
    let selectedYear = year[2];
    setYearForMontlyChart(selectedYear);

    let promise = new Promise((resolve, reject) => {
      setFilterValues({
        ...filterValues,
        as_of_date: i,
      });
      setShowCustomerTable(true);
      setTableFilter(true);
      setShowInvoiceTable(false);
      setShowCharts(false);
      setRowsPerPage(25);
      setPage(0);
      return resolve(true);
    });
    promise.then((res) => {
      const data = {
        as_of_date: i,
        seller_gstin: localStorage.getItem("gst"),
        page_number: 0,
        page_size: 25,
        drilldown: true,
      };
      customerDetailsApi(data);
    });
  };

  // export to excel
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const exportToExcelForInvoice = () => {
    let payload = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      ...filterValues,
      seller_gstin: localStorage.getItem("gst"),
      page_number: page,
      page_size: rowsPerPage,
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Invoices Wise Ageing Report.xlsx`;
    axios
      .post(`/cws_reportingv2/ageing_recievables/invoices/extract`, payload)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportToExcelForCustomer = () => {
    let payload = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      ...filterValues,
      seller_gstin: localStorage.getItem("gst"),
      page_number: page,
      page_size: rowsPerPage,
    };

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Customer Wise Ageing Report.xlsx`;
    axios
      .post(`/cws_reportingv2/ageing_recievables/customers/extract`, payload)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const allDataExportToExcelForCustomer = () => {
  //   let payload = {
  //     business_entity_id: localStorage.getItem("business_entity_id"),
  //     seller_gstin: localStorage.getItem("gst"),
  //   };

  //   const contentType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const filename = `all_customer.xlsx`;
  //   axios
  //     .post(
  //       `/cws_reportingv2/ageing_recievables/raw_customers/extract`,
  //       payload
  //     )
  //     .then((res) => {
  //       downloadBase64File(contentType, res.data.file, filename);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const allDataExportToExcelForInvoice = () => {
  //   let payload = {
  //     business_entity_id: localStorage.getItem("business_entity_id"),
  //     seller_gstin: localStorage.getItem("gst"),
  //   };

  //   const contentType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const filename = `all_invoice.xlsx`;
  //   axios
  //     .post(`/cws_reportingv2/ageing_recievables/raw_invoices/extract`, payload)
  //     .then((res) => {
  //       downloadBase64File(contentType, res.data.file, filename);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <>
      {loaderForPage ? (
        <div className="full-page-loader">
          <div className="page-loader">
            <center>
              <CircularProgress />
              <br></br>
              Loading, Please wait...
            </center>
          </div>
        </div>
      ) : (
        <>
          {showErrorForPage === false && (
            <>
              {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    style={{
                      padding: "6px",
                      fontSize: "18px",
                      textAlign: "center",
                      boxShadow: "1px 0px 4px #000",
                      fontWeight: "600",
                    }}
                  >
                    Organization Name : {localStorage.getItem("entity_name")}
                  </Paper>
                </Grid>
              </Grid> */}
              {/* last refreshed date and time */}
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  Last Refreshed Date :{" "}
                  {`${moment(lastRefreshDate).format(
                    "DD/MM/YYYY"
                  )}, Time : ${moment(lastRefreshTime, "HH:mm:ss").format(
                    "h:mm:ss a"
                  )}`}
                </div>
                <div>
                  <button
                    className="button-outlined-primary"
                    onClick={
                      showCustomerTable == true || showInvoiceTable === true
                        ? handleBackEvent
                        : handleRefresh
                    }
                  >
                    {showCustomerTable == true || showInvoiceTable === true ? (
                      <span>
                        {/* <ArrowBackIcon /> */}
                        Back to Summary
                      </span>
                    ) : (
                      <span>REFRESH</span>
                    )}
                  </button>
                  <button
                    className="button-primary"
                    onClick={showFilterHandler}
                  >
                    <span style={{ marginRight: "5px" }}>Filter</span>{" "}
                    <FilterListIcon
                      style={{ fontSize: "18px", marginTop: "-4px" }}
                    />
                  </button>
                </div>
              </div>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                {showFilter && (
                  <BoxWrapper boxTitle={`Filter`}>
                    <DashboardFilter
                      DropdownsData={filterDropdownData}
                      filterTableFlag={tableFilter}
                      customerTableFlag={showCustomerTable}
                      filterSubmitHandler={filterSubmitHandler}
                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      loader={filterLoader}
                      cancelFilterForm={cancelFilterHandler}
                      error={filterError}
                    />
                  </BoxWrapper>
                )}
              </div>

              {/* charts */}

              {showCharts && (
                <>
                  {/* cards */}
                  <div style={{ marginTop: "5px" }}>
                    <Cards cardData={cardsData} />
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <BoxWrapper boxTitle={`Overdue by Age Summary`}>
                        <div>
                          <PieChart
                            yAxisTitle="Value"
                            xAxisTitle="Days"
                            enableDrilldownFn={true}
                            fnForDrillDown={drilldownHandlerForAgeingChart}
                            data={pieChartData}
                          />
                        </div>
                      </BoxWrapper>
                    </Grid>

                    <Grid item xs={6}>
                      <BoxWrapper boxTitle={`Not Yet Due by Age Summary`}>
                        <div>
                          {pieChartDataForNotYrtDue.length === 0 ? (
                            <div
                              style={{
                                color: "red",
                                textAlign: "center",
                                padding: "33%",
                              }}
                            >
                              No Bills Under Not Yet Due
                            </div>
                          ) : (
                            <PieChart
                              yAxisTitle="Value"
                              xAxisTitle="Days"
                              enableDrilldownFn={true}
                              fnForDrillDown={
                                drilldownHandlerForAgeingChartForNotYetDue
                              }
                              data={pieChartDataForNotYrtDue}
                            />
                          )}
                        </div>
                      </BoxWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <BoxWrapper
                        boxTitle={`Top ${
                          barChartData && barChartData.length - 1
                        }  Customers by Outstanding Amount + Others`}
                        enableDropdown={true}
                        dropdownHandlerFn={topCustomerDropdownHandler}
                        dropdownOptions={[
                          { value: 5, label: 5 },
                          { value: 10, label: 10 },
                          { value: 15, label: 15 },
                          { value: 20, label: 20 },
                        ]}
                        dropdownLabel="Customers"
                        dropdownValue={topCustomerNo}
                      >
                        <div>
                          <BarchartRupessFormat
                            tooltipHeader="customer_manager"
                            tooltipFormat="₹ {point.y:.1f}"
                            yAxisTitle="Amounts"
                            xAxisTitle="Customers"
                            enableDrilldownFn={true}
                            fnForDrillDown={drilldownHandlerForCustomerChart}
                            data={barChartData}
                            maxValue={
                              maxAndMinValueforChart &&
                              maxAndMinValueforChart.max
                            }
                            minValue={
                              maxAndMinValueforChart &&
                              maxAndMinValueforChart.min
                            }
                          />
                        </div>
                      </BoxWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <BoxWrapper
                        boxTitle={`Monthly Trend Chart for Not Yet Due and Due Amount.`}
                      >
                        <div style={{ padding: "20px 0px" }}>
                          <StackedBarChart
                            xAxisTitle="Months"
                            yAxisTitle={"Amounts"}
                            fnForDrillDown={monthlyChartDrilldownHandler}
                            data={customerNotYetDueDataForChart}
                          />
                        </div>
                      </BoxWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <BoxWrapper
                        boxTitle={`Monthly Trend Chart of Total Overdue Amount for each ageing bucket.`}
                      >
                        <div style={{ padding: "20px 0px" }}>
                          <StackedBarChart
                            xAxisTitle="Months"
                            yAxisTitle={"Total overdue Amount"}
                            fnForDrillDown={monthlyChartDrilldownHandler}
                            data={eachBucketData}
                          />
                        </div>
                      </BoxWrapper>
                    </Grid>
                  </Grid>
                </>
              )}

              {showCustomerTable && (
                <>
                  <BoxWrapper
                    boxTitle={`Customer Details`}
                    exportToExcelEnable={true}
                    exportToExcelFn={exportToExcelForCustomer}
                    // rawExportToExcelEnable={true}
                    // rawExportToExcelFn={allDataExportToExcelForCustomer}
                  >
                    <CustomerTable
                      data={customerData}
                      loader={customerLoader}
                    />
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={rowCount} //total row count
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </BoxWrapper>
                </>
              )}

              {showInvoiceTable && (
                <>
                  <BoxWrapper
                    boxTitle={`Invoice Details`}
                    exportToExcelEnable={true}
                    exportToExcelFn={exportToExcelForInvoice}
                    // rawExportToExcelEnable={true}
                    // rawExportToExcelFn={allDataExportToExcelForInvoice}
                  >
                    <InvoiceTable data={invoiceData} loader={invoiceLoader} />
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={rowCount} //total row count
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </BoxWrapper>
                </>
              )}

              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    {showCustomerTable === false && (
                      <div
                        className="card-button-1"
                        onClick={handleTriggerCustomer}
                      >
                        <span>Customer Details</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    {showInvoiceTable === false && (
                      <div
                        className="card-button-1"
                        onClick={handleTriggerInvoive}
                      >
                        <span>Invoice Details</span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </>
      )}

      {showErrorForPage && (
        <div className="error-msg-css">
          <center>
            <span style={{ color: "red", fontSize: "18px" }}>
              <ErrorIcon /> No Data Found!
            </span>
            <br></br>
            <span style={{ marginLeft: "20px" }}>
              For this GSTIN : {localStorage.getItem("gst")}
            </span>
            <br></br>
            <Button variant="outlined" size="small" onClick={handleRefresh}>
              Refresh
            </Button>
          </center>
        </div>
      )}
    </>
  );
};

export default Dashboard;
