import React, { useState,useEffect } from 'react';
import BoxWrapper from '../../components/ui/boxWrapper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import NumberFormat from 'react-number-format';
import Box from '@mui/material/Box';
import viewImage from './view.png';
import ToggleSwitch from '../../components/ui/ToggleSwitch/ToggleSwitch';
import { Snackbar, SnackbarContent } from '@mui/material';
import Alert from '@mui/material/Alert';
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {  Input } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import axios  from  '../../axios-order';
import Productxl from "./excel/ProductMaster.xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';


const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function ProductMaster({handleClose}) {
  const [formData, setFormData] = useState({
    id:null,
    product_description: '',
    is_service: '',
    hsn_code: '',
    unit: '',
    unit_price: '',
    gst_rate: '',
    cess_ad_rate: '',
    cess_non_adj_amt: '',
    state_cess_rate: ''
  });
  const [rowCount, setRowCount] = useState(0); 
  const [fieldErrors, setFieldErrors] = useState({});
  const [rows, setRows] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [uploadHistory, setUploadHistory] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [messageType, setMessageType] = useState('')
  const [errorMessage,setErrorMessage] = useState('')
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [responseData, setResponseData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [Product, setProduct] = useState(false);
  const [highlightedCell, setHighlightedCell] = useState({ rowId: null, cellKey: null });
  const [editedRowId, setEditedRowId] = useState(null);
  console.log("Dialog Data",dialogData)
  // useEffect(() => {
  //   // Fetch data from API when the component mounts
  //   const fetchData = async () => {
  //     try {
  //       const businessEntityId = localStorage.getItem('business_entity_id');
  //       const response = await axios.get(`/einvoice/get_product_master/${businessEntityId}/`);
  //       setRows(response.data.records);
  //     } catch (error) {
  //       console.error('Error fetching product master data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // const fetchData = async () => {
  //   try {
  //     const businessEntityId = localStorage.getItem('business_entity_id');
  //     const response = await axios.get(`/einvoice/get_product_master/${businessEntityId}/`);
  //     setRows(response.data.records);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setLoading(false);
  //   }
  // };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

      // If "Is Service" is "No", clear HSN/SAC code and error
      if (name === 'is_service' && value === 'No') {
        setFormData((prevState) => ({
          ...prevState,
          hsn_code: ''
        }));
        setError('');
      }
  };

  const handleProd = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  








  





const handlehsnChange = (event) => {
  const { name, value } = event.target;
  
  // Limit the value to 8 characters
  const newValue = value.slice(0, 8);
  
  let errorMessage = '';
  
  if (/^\d*$/.test(newValue)) {
    if (formData.is_service === 'No') {
      if (newValue.startsWith('99')) {
        errorMessage = 'HSN/SAC code cannot start with 99 when service is No';
      }
    } else if (formData.is_service === 'Yes') {
      if (newValue.length === 1 && newValue !== '9') {
        errorMessage = 'For services, HSN code must start with "99".';
      } else if (newValue.length >= 2 && parseInt(newValue.slice(0, 2), 10) !== 99) {
        errorMessage = 'For services, HSN code must start with "99".';
      }
    }

    if (errorMessage) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        hsn_code: errorMessage,
      }));
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        hsn_code: '', // Clear the error message
      }));
    }
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue, // Update form data with limited value
    }));
  } else {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      hsn_code: 'HSN/SAC code must be numeric and up to 8 characters.',
    }));
  }
};




  const formatNumber = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    refresh();
    // Retrieve business_entity_id from localStorage
    const businessEntityId = localStorage.getItem('business_entity_id');
        const mandatoryFields = [
      { name: 'product_description', message: 'Product Description is required' },
      { name: 'hsn_code', message: 'HSN/SAC is required' },
      { name: 'unit_price', message: 'Unit Price is required' },
   
    ];

      // Validate mandatory fields
  const newFieldErrors = {};
  mandatoryFields.forEach(({ name, message }) => {
    if (!formData[name]) {
      newFieldErrors[name] = message;
    }
  });

  // Check for any field errors
  if (Object.keys(newFieldErrors).length > 0) {
    setFieldErrors(newFieldErrors); // Set field errors
    return; // Exit if there are validation errors
  }
    
  
    // Create or update formData with business_entity_id and format numeric values
    const updatedFormData = {
      ...formData,
      id: formData.id ?? null,
      business_entity_id: businessEntityId,
      unit_price: formatNumber(formData.unit_price),
      gst_rate: formatNumber(formData.gst_rate),
      cess_ad_rate: formatNumber(formData.cess_ad_rate),
      cess_non_adj_amt: formatNumber(formData.cess_non_adj_amt),
      state_cess_rate: formatNumber(formData.state_cess_rate),
    };
  
    try {
      const response = await axios.post(`/einvoice/post_product_master`, updatedFormData);
      console.log('Success:', response.data);
      if (response.data.status) {
        toast.success(response.data.message); // Display success message
        setHighlightedCell({ rowId: formData.id, cellKey: 'product_description' }); // Change to your desired key
        setTimeout(() => {
          setHighlightedCell({ rowId: null, cellKey: null });
        }, 7000);
          // Clear all fields in formData
      setFormData({
        product_description: '',
        is_service: '',
        hsn_code: '',
        unit: '',
        unit_price: '',
        gst_rate: '',
        cess_ad_rate: '',
        cess_non_adj_amt: '',
        state_cess_rate: ''
      });
      handleToggle({ target: { checked: false } });
      } else {
        // Display specific error message from the response
        toast.error(response.data.message || 'Failed to update data');
      }
      await refresh();
      handleClose();
      
     
    } catch (error) {
      

    const errorMessage = error.response?.data?.message ;
    console.error('Error:', error); // Log the full error for debugging
    toast.error(errorMessage);
    }

  };

  


  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
  link.href = Productxl;
  link.download = 'ProductMaster.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  };


  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     setFile(selectedFile); // Set the file state
  //     handleUpload(selectedFile); // Call handleUpload with the selected file
  //   }
  // };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Set the file state
  
      // Handle the file upload
      const businessEntityId = localStorage.getItem('business_entity_id');
      const user = localStorage.getItem('user_id');
      
      if (!businessEntityId) {
        // alert('Business entity ID is missing.');
        return;
      }
      
      // Set loading state
      setLoading(true);
      
      try {
        // Convert file to Base64
        const base64File = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = `data:${selectedFile.type};base64,${btoa(reader.result)}`;
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsBinaryString(selectedFile);
        });
  
        await axios.post('/einvoice/import_product_master', {
          business_entity_id: businessEntityId,
          user: user,
          file: base64File,
        });
          
        setSnackbar({ open: true, message: 'File uploaded successfully.', severity: 'success' });
  
        // After successful upload, make the second URL call
        const formNumber = '101'; // Replace with actual form number or state variable if needed
        const response = await axios.get(`/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`);
     
        console.log('Upload History:', response.data);
        setUploadHistory(response.data.record);
  
        setTimeout(() => {
          console.log('Refreshing data...');
          refresh();
        }, 1000);
  
      } catch (error) {
        console.error('Error uploading file:', error);
        // alert('Error uploading file.');
      } finally {
        setLoading(false); // Reset loading state
        event.target.value = null;
      }
    } else {
      // alert('Please select a file first.');
    }
  };
  
 

 

   const handleClear = () => {
    setFormData({
      product_description: '',
      is_service: '',
      hsn_code: '',
      unit: '',
      unit_price: '',
      gst_rate: '',
      cess_ad_rate: '',
      cess_non_adj_amt: '',
      state_cess_rate: ''
    });

   }

   const handleCloseBox = () => {
    setBoxVisible(false); // Hide the box when close button is clicked
  };

  const handleExportExcel = () => {
    const bid = localStorage.getItem('business_entity_id');
    const fileName = 'Products'; // Assuming 'RecipientMaster' is the file name you want to use
  
    if (bid) {
      const url = `/einvoice/export_product_master/${fileName}/${bid}`;
  
      axios.get(url)
        .then(response => { 
           console.log(response.data);
          // Assuming the response data is a Base64 encoded string
          const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const base64Data = response.data.file; // Adjust based on the actual response format
          downloadBase64File(contentType, base64Data, `${fileName}.xlsx`);
        })
        .catch(error => {
          console.error("Error exporting file:", error);
        });
    } else {
      console.error('Business entity ID not found in localStorage');
    }
  };

  const handleExcel = async () => {
    const businessEntityIdRaw = localStorage.getItem('business_entity_id');
    // const str = businessEntityIdRaw ? String(businessEntityIdRaw) : null;
    
    // Check if business entity ID is available
    if (!businessEntityIdRaw) {
      console.error('Business entity ID is missing');
      return;
    }
  
    try {
      // Check if response data is available
      if (!responseData) {
        console.error('Response data is missing');
        return;
      }
  
      const payloadData = {
        'business_entity_id': businessEntityIdRaw,
        'data': responseData
      };
  
     
  
      // Make the POST request with query parameters
      const response = await axios.post(`/einvoice/download_error_data`, payloadData);
  
      // Log the response for debugging purposes
      console.log(response.data);
  
      // Check if base64 data is present in the response
      const base64Data = response.data.file; // Ensure this matches the structure of your response
      if (base64Data) {
        const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const filename = 'ProductUploadErrorLogs.xlsx';
        downloadBase64File(contentType, base64Data, filename);
      } else {
        console.error('Base64 data not found in the response');
      }
  
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error fetching Excel file:', error);
    }
  };
  
  // // Utility function to handle Base64 file download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  
  const refresh = async (pageNumber = 0, rowsPerPage = 25, searchTxt = '') => {
   
    const businessEntityId = localStorage.getItem('business_entity_id');
    const formNumber = '101'; // Replace with actual form number if needed
  
    try {
      // First URL call - Static URL with number_of_rows_per_page and page_number
      const postResponse = await axios.post('/einvoice/upload_product', {
        number_of_rows_per_page: rowsPerPage,
        page_number: pageNumber,
        business_entity_id: businessEntityId,
        search_txt: searchTxt,
      });
  
      // // After successful POST, make the second URL call to retrieve upload history
      const response = await axios.get(`/einvoice/get_data_upload_history/${businessEntityId}/${formNumber}`);
     
      console.log('Upload History:', response.data);
      setRowCount(postResponse.data.row_count);
      setUploadHistory(response.data.record);
      if (postResponse.data.records) {
        setRows(postResponse.data.records);  // Update the rows state with the received records
      }
      setRefreshTrigger(prev => !prev);
    } catch (error) {
      console.error('Error refreshing data:', error);
      // Handle error if necessary
    }
  };
  // useEffect(() => {
  //   fetchData(); // Call fetchData when refreshTrigger changes
  // }, [refreshTrigger]);
  useEffect(() => {
    refresh(page, rowsPerPage, searchTerm); // Call the refresh function when the component mounts
  }, [page, rowsPerPage, searchTerm]);

  const searchInputOnchange = async (event) => {
    const value = event.target.value;
    setSearchText(value);
    await refresh(value); // Call refresh with the search text
  };



const handleSearchChange = (event) => {
  const newSearchTerm = event.target.value;
  setSearchTerm(newSearchTerm);
  refresh(page, rowsPerPage, newSearchTerm); 
};


const handleEyeIconClick = async (id) => {
  try {
    const response = await axios.get(`/einvoice/get_data_upload_json/${id}`);
    const { data } = response;
    setResponseData(data);
    if (data && data.records && data.records.records && data.records.records.summary) {
      setBoxVisible(true);
      setSummaryData(data.records.records.summary);
    }
    // Check if the response contains a message
    if (data && data.records && data.records.message) {
      // Display success or informational message based on the response
      if (!data.records.status) {
        setErrorMessage(data.records.message);
        setSnackbar({ open: true, message: data.records.message, severity: 'error' });
        handleCloseBox();
      } else {
        setErrorMessage('');
      }
    }
    setDialogData(data.records.records);
   
        // Toggle visibility
     
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error('Failed to fetch data');
  }
};

const cardStyle1 = {
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#6f9eaf',
  textAlign: 'left', // Aligns text to the left
  width: '300px',
  height: '70px',
  color: 'white',
  fontSize: '20px',
  padding: '10px', // Adds padding for spacing
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Aligns content to the top
  alignItems: 'flex-start'

};

const cardStyle2 = {
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#007965',
  textAlign: 'left', // Aligns text to the left
  width: '300px',
  height: '70px', // Ensure consistent height with the first card
  color: 'white',
  fontSize: '20px',
  padding: '10px', // Adds padding for spacing
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Aligns content to the top
  alignItems: 'flex-start' // Aligns content to the start (left)
};


const cardStyle3 = {
  border: '1px solid #ccc',
  borderRadius: '4px',
  backgroundColor: '#eb596e',
  textAlign: 'left', // Aligns text to the left
  width: '300px',
  height: '70px', // Ensure consistent height with other cards
  color: 'white',
  fontSize: '20px',
  padding: '10px', // Adds padding for spacing
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Aligns content to the top
  alignItems: 'flex-start' // Aligns content to the start (left)
};




const handleEdit = (row) => {
  handleToggle({ target: { checked: true } });
    // Scroll the page up by 20px
    window.scrollTo({
      top: window.pageYOffset - 580,
      behavior: 'smooth'
    });
    setEditedRowId(row.id);
    setTimeout(() => {
      setEditedRowId(null);
    }, 7000);
  setFormData({
       id:row.id || '',
    product_description: row.product_description || '',
    is_service: row.is_service || '',
    hsn_code: row.hsn_code || '',
    unit: row.unit || '',
    unit_price: row.unit_price || '',
    gst_rate: row.gst_rate || '',
    cess_ad_rate: row.cess_ad_rate || '',
    cess_non_adj_amt: row.cess_non_adj_amt || '',
    state_cess_rate: row.state_cess_rate || '',
  });
};
  
const handleToggleChange = () => {
  setShowUploadHistoryTable(!showUploadHistoryTable);
};

const handleToggle = (event) =>{
  setProduct(event.target.checked)
    // Clear form data when toggle is turned off
    if (!event.target.checked) {
      setFormData({
        id: null,
        product_description: '',
        is_service: '',
        hsn_code: '',
        unit: '',
        unit_price: '',
        gst_rate: '',
        cess_ad_rate: '',
        cess_non_adj_amt: '',
        state_cess_rate: ''
      });
    }
  
}

  return (
    <div>
       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <ToggleSwitch
          onChange={handleToggleChange}
          value={showUploadHistoryTable}
          label="Upload Product"
        />
          <div className="container">
          <div>
            <input
              type="checkbox"
              className="toggle-switch"
              id="default1"
              onChange={handleToggle}
              checked={Product}
            />
            <label htmlFor="default1"></label>
          </div>
          <p> Add Product</p>
        </div>
      </div>
    </div>
      { showUploadHistoryTable && (
       <BoxWrapper boxTitle="Product Uploads"

       >

<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
<div> 
      <Button 
        onClick={handleDownloadTemplate}
      > 
          <GetAppIcon style={{ fontSize: "20px" }} />
        Download Template
      </Button>  
      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
       
        UPLOAD TEMPLATE
        <input
          accept=".xlsx"
          name="file"
          type="file"
          hidden
          onChange={handleFileChange} // Handle file input change
        />
      </Button>
      </div>

      <Button
  variant="contained"
  onClick={() => refresh()} 
  style={{ 
    marginTop: '10px', 
    marginLeft: '440px',
    backgroundColor: 'white',
    border: 'none',
    color: '#3e71c2', // To ensure text is visible on white background
    boxShadow: 'none', // Optional: Remove the box-shadow if desired
  }}
  startIcon={<RefreshIcon />}
>
REFRESH
</Button>
</div>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell  style={{ fontSize: '15px', fontWeight: 'bold' ,paddingLeft:'15px'}}>Upload Date</TableCell>
            
            <TableCell  style={{ fontSize: '15px', fontWeight: 'bold',paddingLeft:'15px' }}>Status</TableCell>
            <TableCell  style={{ fontSize: '15px', fontWeight: 'bold' ,paddingLeft:'15px'}}>Upload Type</TableCell>
            <TableCell style={{ fontSize: '15px', fontWeight: 'bold',paddingLeft:'15px' }}>Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {uploadHistory.map((record) => (
            <TableRow key={record.id}>
                 <TableCell style={{ padding: '3px',paddingLeft:'15px' }}>{record.upload_date}</TableCell>     
              <TableCell style={{ padding: '3px',paddingLeft:'15px' }}>{record.status}</TableCell>    
              <TableCell style={{ padding: '3px',paddingLeft:'15px' }}>{record.upload_type}</TableCell>
              <TableCell style={{ padding: '3px',paddingLeft:'15px' }}>
                <IconButton onClick={() => handleEyeIconClick(record.id)}>
                  {/* <VisibilityIcon /> */}
                  <img src={viewImage} alt="View" style={{ width: '24px', height: '24px' }} />
                </IconButton>
              </TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
      </BoxWrapper>
      )}

{isBoxVisible && (
  <BoxWrapper
   boxTitle="Uploads Details"
   exportToExcelEnable={true}
   exportToExcelFn={handleExcel}
   cutIcon={true}  // This enables the close icon
   closeBoxWapper={handleCloseBox} 
   >
    {summaryData ? (
      <div>
        {/* Cards Section */}
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={cardStyle1}>
            <div>Total Records</div>
            <div>{summaryData.total_records}</div>
          </div>
          <div style={cardStyle2}>
            <div>Valid Records</div>
            <div>{summaryData.valid_records}</div>
          </div>
          <div style={cardStyle3}>
            <div>Invalid Records</div>
            <div>{summaryData.invalid_records}</div>
          </div>
        </div>

        {/* Table Section */}
        <div style={{ marginTop: '20px' }}> {/* This wrapper creates a new line for the table */} 
          {summaryData.invalid_records !== 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>GSTIN</TableCell>
                  <TableCell>INVOICE NUMBER</TableCell>
                  <TableCell>ROW#</TableCell>
                  <TableCell>ERRORS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogData && Array.isArray(dialogData.details) ? (
                  dialogData.details.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{}</TableCell> {/* Replace with actual field */}
                      <TableCell>{}</TableCell> {/* Replace with actual field */}
                      <TableCell>{item.row}</TableCell>
                      <TableCell>
                        {item.errors_in ? (
                          Object.entries(item.errors_in).map(([field, errors], i) => (
                            <div key={i}>
                              {field}: {errors.join(', ')}
                            </div>
                          ))
                        ) : 'No errors'}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          )}
        </div>
      </div>
    ) : (
      <div>Loading...</div>
    )}
  </BoxWrapper>
)}

       {Product && (
      <BoxWrapper boxTitle="Product Master">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
           
                label="Product Description"
                name="product_description"
                value={formData.product_description}
                onChange={handleProd}
                variant="outlined"
                error={!!fieldErrors.product_description} // Apply red border if GSTIN is invalid
                helperText={fieldErrors.product_description || ''} 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Is Service</InputLabel>
                <Select
        
                  name="is_service"
                  value={formData.is_service}
                  onChange={handleChange}
                  label="Is Service*"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
          
                label="HSN/SAC"
                name="hsn_code"
                value={formData.hsn_code}
                onChange={handlehsnChange}
                variant="outlined"
                error={!!fieldErrors.hsn_code} // Apply red border if GSTIN is invalid
                helperText={fieldErrors.hsn_code || ''} 
              />
            </Grid>
          
            <Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>Unit</InputLabel>
    <Select
      name="unit"
      value={formData.unit}
      onChange={handleChange}
      label="Unit"
    >
      <MenuItem value="BAGS">BAGS</MenuItem>
      <MenuItem value="BALE">BALE</MenuItem>
      <MenuItem value="BUNDLES">BUNDLES</MenuItem>
      <MenuItem value="BUCKLES">BUCKLES</MenuItem>
      <MenuItem value="BILLION OF UNITS">BILLION OF UNITS</MenuItem>
      <MenuItem value="BOX">BOX</MenuItem>
      <MenuItem value="BOTTLES">BOTTLES</MenuItem>
      <MenuItem value="BUNCHES">BUNCHES</MenuItem>
      <MenuItem value="CANS">CANS</MenuItem>
      <MenuItem value="CUBIC METERS">CUBIC METERS</MenuItem>
      <MenuItem value="CUBIC CENTIMETERS">CUBIC CENTIMETERS</MenuItem>
      <MenuItem value="CENTIMETERS">CENTIMETERS</MenuItem>
      <MenuItem value="CARTONS">CARTONS</MenuItem>
      <MenuItem value="DOZENS">DOZENS</MenuItem>
      <MenuItem value="DRUMS">DRUMS</MenuItem>
      <MenuItem value="GREAT GROSS">GREAT GROSS</MenuItem>
      <MenuItem value="GRAMMES">GRAMMES</MenuItem>
      <MenuItem value="GROSS">GROSS</MenuItem>
      <MenuItem value="GROSS YARDS">GROSS YARDS</MenuItem>
      <MenuItem value="KILOGRAMS">KILOGRAMS</MenuItem>
      <MenuItem value="KILOLITRE">KILOLITRE</MenuItem>
      <MenuItem value="KILOMETRE">KILOMETRE</MenuItem>
      <MenuItem value="LITRES">LITRES</MenuItem>
      <MenuItem value="MILILITRE">MILILITRE</MenuItem>
      <MenuItem value="METERS">METERS</MenuItem>
      <MenuItem value="METRIC TON">METRIC TON</MenuItem>
      <MenuItem value="NUMBERS">NUMBERS</MenuItem>
      <MenuItem value="PACKS">PACKS</MenuItem>
      <MenuItem value="PIECES">PIECES</MenuItem>
      <MenuItem value="PAIRS">PAIRS</MenuItem>
      <MenuItem value="QUINTAL">QUINTAL</MenuItem>
      <MenuItem value="ROLLS">ROLLS</MenuItem>
      <MenuItem value="SETS">SETS</MenuItem>
      <MenuItem value="SQUARE FEET">SQUARE FEET</MenuItem>
      <MenuItem value="SQUARE METERS">SQUARE METERS</MenuItem>
      <MenuItem value="SQUARE YARDS">SQUARE YARDS</MenuItem>
      <MenuItem value="TABLETS">TABLETS</MenuItem>
      <MenuItem value="TEN GROSS">TEN GROSS</MenuItem>
      <MenuItem value="THOUSANDS">THOUSANDS</MenuItem>
      <MenuItem value="TONNES">TONNES</MenuItem>
      <MenuItem value="TUBES">TUBES</MenuItem>
      <MenuItem value="US GALLONS">US GALLONS</MenuItem>
      <MenuItem value="UNITS">UNITS</MenuItem>
      <MenuItem value="YARDS">YARDS</MenuItem>
      <MenuItem value="OTHERS">OTHERS</MenuItem>
    </Select>
  </FormControl>
</Grid>



            <Grid item xs={12} md={6}>
              
               <NumberFormat
    customInput={TextField}
    fullWidth

    label="Unit Price"
    name="unit_price"
    value={formData.unit_price}
    onValueChange={({ value }) => handleChange({ target: { name: 'unit_price', value } })}
    thousandSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
    allowNegative={false}
    variant="outlined"
    error={!!fieldErrors.unit_price} // Apply red border if GSTIN is invalid
    helperText={fieldErrors.unit_price || ''} 
    
  />
            </Grid>
        
            <Grid item xs={12} md={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel id="gst-rate-label">GST Rate%</InputLabel>
    <Select
      labelId="gst-rate-label"
      name="gst_rate"
      value={formData.gst_rate}
      onChange={handleChange}
      label="GST Rate%"
    >
      {[0, 0.1, 1, 3, 5, 12, 18, 28].map(rate => (
        <MenuItem key={rate} value={rate}>
          {rate}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

            <Grid item xs={12} md={6}>
             
              <NumberFormat
  customInput={TextField}
  fullWidth
  label="Cess Ad Rate%"
  name="cess_ad_rate"
  value={formData.cess_ad_rate}
  onValueChange={({ value }) => handleChange({ target: { name: 'cess_ad_rate', value } })}
  thousandSeparator=","
  decimalScale={2}
  fixedDecimalScale={true}
  allowNegative={false}
  variant="outlined"
  />
            </Grid>
            <Grid item xs={12} md={6}>
             
               <NumberFormat
          customInput={TextField}
          fullWidth
          label="Cess Non-Ad Amount"
          name="cess_non_adj_amt"
          value={formData.cess_non_adj_amt}
          onValueChange={({ value }) => handleChange({ target: { name: 'cess_non_adj_amt', value } })}
          thousandSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          variant="outlined"
        />
            </Grid>
            <Grid item xs={12} md={6}>
           
              <NumberFormat
  customInput={TextField}
  fullWidth
  label="State Cess Rate%"
  name="state_cess_rate"
  value={formData.state_cess_rate}
  onValueChange={({ value }) => handleChange({ target: { name: 'state_cess_rate', value } })}
  thousandSeparator=","
  decimalScale={2}
  fixedDecimalScale={true}
  allowNegative={false}
  variant="outlined"

/>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start">
              <Box  sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
      type="submit"
      variant="contained"
      color="primary"
      sx={{ width: 120 }}
    >
      Add
    </Button>
    <Button
      variant="outlined"
      color="secondary"
      onClick={handleClear}
    >
      Clear
    </Button>
                
              </Box>
            </Grid>
            
            <Grid item xs={12} style={{ marginTop: '16px' }}>
  
      </Grid>
          </Grid>
        </form>

      </BoxWrapper>
      )}
      <Snackbar
  open={snackbar.open}
  autoHideDuration={6000}
  onClose={() => setSnackbar({ ...snackbar, open: false })}
  sx={{ mt: 4 }}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Positioned at the top-right corner
>
  <Alert onClose={() => setSnackbar({ ...snackbar, open: false })}  variant="filled" severity={snackbar.severity} sx={{ width: '100%' }}>
    {snackbar.message}
  </Alert>
</Snackbar>

    
      <BoxWrapper
  boxTitle="Product Master Details"
  enableSearchInput={true}
  searchInputPlaceholder="Search..."  
   searchInputOnchange={handleSearchChange}  
   exportToExcelEnable={true}
   exportToExcelFn={handleExportExcel} 
>   
 
  <div style={{ maxHeight: '400px', overflow: 'auto',scrollbarWidth: 'thin' }}>
      <Table sx={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow>
            {[
              'PRODUCT DESCRIPTION',
              'IS SERVICE',
              'UNIT',
              'HSN/SAC',
              'UNIT PRICE',
              'GST RATE',
              'CESS AD RATE',
              'Cess NON-AD RATE',
              'STATE CESS RATE',
              'ACTION',
            ].map((header) => (
              <TableCell
                key={header}
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 1,
                  padding: '8px 16px',
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 ? (
         rows.map((row) => (
         
              <TableRow key={row.id} style={{
                backgroundColor: editedRowId === row.id ? 'lightgreen' : 'transparent',
                transition: 'background-color 4s ease',
              }}>
                <TableCell style={{ padding: '8px 16px'  }}>{row.product_description}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.is_service}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.unit}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.hsn_code}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.unit_price}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.gst_rate}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.cess_ad_rate}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.cess_non_adj_amt}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>{row.state_cess_rate}</TableCell>
                <TableCell style={{ padding: '8px 16px'  }}>
                  <EditIcon style={{ cursor: 'pointer' }} 
                   sx={{
                    '&:hover': {
                      color: 'blue',  // Change to your desired hover color
                    },
                  }}
                  onClick={() => handleEdit(row)} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10}>No data found!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
</BoxWrapper>
<TablePagination
      
      rowsPerPageOptions={[25, 50, 100,1000,3000]}
      component="div"
      count={rowCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => {
        setPage(newPage);
        refresh(newPage, rowsPerPage, searchTerm); // Pass the newPage to refresh
      }}
      onRowsPerPageChange={(event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
        refresh(0, newRowsPerPage, searchTerm); // Refresh with page 0 when changing rows per page
      }}
    />

    </div>
  );
}

export default ProductMaster;
