import React, { useState } from 'react';
import BoxWrapper from '../boxwra';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



function Eighteen({ reconId: propReconId, handleBack, updateTableData, data }) {
  const [tableData, setTableData] = useState(data);
  const [tableName, setTableName] = useState("18. HSN wise summary of Inward Supplies")

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [itemName, field] = name.split('.');

    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === itemName
          ? { ...item, [field]: value }
          : item
      )
    );
  };


  const submitHandle = () => {

    updateTableData(tableName, tableData)
  };

  const handleBackButton = () => {
    handleBack()
  }


  return (
    <>
      <BoxWrapper
        backButtonForZohoEnable={true}
        backButton={handleBackButton}
        boxTitle={tableName}
      >

        

        <p style={{ marginLeft: '10px', color: '#4D4C4C', marginBottom: '5px' }}>
          Added/Edited HSN details to be saved
        </p>

        <div style={{ maxHeight: '47vh' }} className="responsive-table">
          <table style={{ width: '100%', backgroundColor: 'white' }} className="summary-tab-single-year-table-css">
            <thead>
              <tr >
                <th >HSN Code</th>
                <th>Description</th>
                <th>UQC</th>
                <th>Total Quantity</th>
                <th>Taxable Value (₹)</th>
                <th>In supply applicabel for concesstional rate of tax</th>
                <th>Rate of Tax (%)</th>
                <th>Integrated Tax (₹)</th>
                <th>Central Tax (₹)</th>
                <th>State/UT Tax (₹)</th>
                <th>Cess (₹)</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {(tableData && tableData.length>=1) ? tableData.map((item, index) => {
                return (
                  <tr key={index} style={{ backgroundColor: 'white' }}>
                    <td>{item.hsn_sac_code}</td>
                    <td>{item.description_of_goods}</td>
                    <td>{value === "1" ? item.uqc : ''}</td>
                    <td>{value === "1" ? item.qty : ''}</td>
                    <td>{item.taxable_value}</td>
                    <td>{item.concessionalRate}</td>
                    <td>{item.rateOfTax}</td>
                    <td>{item.igst}</td>
                    <td>{item.cgst}</td>
                    <td>{item.sgst}</td>
                    <td>{item.cess}</td>
                    {/* <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                      <EditIcon
                        style={{ cursor: 'pointer', marginTop: '2px', backgroundColor: '#14385D', color: 'white', marginRight: '6px' }}

                      />
                      <DeleteIcon
                        style={{ cursor: 'pointer', marginTop: '2px', backgroundColor: 'red', color: 'white', marginLeft: '6px' }}
                      />
                    </td> */}
                  </tr>
                );
              }):(
                <tr>
                  <td colSpan={12} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '20px' }}>
          <button type="button" className='button-primary' onClick={submitHandle}>Save</button>
          <button type="button" className='button-primary'>Cancel</button>
        </div>

      </BoxWrapper>
    </>
  );
}

export default Eighteen;
