import React, { useState, useEffect } from "react";
import BoxWrapper from "../../../components/ui/boxWrapper";
import RightDrawer from "../../../components/ui/RightDrawer";
import InputField from "../../../components/ui/inputField";
import SelectInputField from "../../../components/ui/select";
import axios from "../../../axios-order";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ValidatedDatePicker from "../../../components/ui/DatePicker";
import { parseJSON } from "date-fns";
import { Button } from "@material-ui/core";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


const MsmeInterest = () => {
  const [msmeInterestTableData, setMsmeInterestTableData] = useState([]);
  const [isOpenAddDrawer, setIsOpenAddDrawer] = useState(false);
  const [addFormData, setAddFormData] = useState({});

  const [isOpenEditDrawer , setIsOpenEditDrawer] = useState(false);

  const [editfromDate , setEditfromDate] = useState(null);
  const [edittoDate , setEdittoDate] = useState(null);
  const [interestRate , setInterestRate ] = useState("");
  const [compountInt , setCompountInt ] = useState("");
  const [typeCalculation , setTypeCalculation] = useState("");


  const addDrawerOpenHandler = () => {
    setIsOpenAddDrawer(true);
  };

  const editFormOpenDrawerHandler = (data) => {
    setEditfromDate(data.from_date);
    setEdittoDate(data.to_date);
    setInterestRate(data.interest_rate);
    setCompountInt(data.compounding_rate);
    setTypeCalculation(data.type_of_calculation);

    setIsOpenEditDrawer(true);
  }

  const closeAddDrawerHandler = () => {
    setIsOpenAddDrawer(false);
    setAddFormData({});
  };

  const closeEditDrawerHandler = () => {
    setEditfromDate(null);
    setEdittoDate(null);
    setInterestRate("");
    setCompountInt("");
    setTypeCalculation("");
    setIsOpenEditDrawer(false);
  }

  const handleFromDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      setAddFormData({
        ...addFormData,
        from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };

  const handleToDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      setAddFormData({
        ...addFormData,
        to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      });
    }
  };

  const handleToDateChangeForEdit = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      setEdittoDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`)
    }
  }

  const addInputChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddFormData({
      ...addFormData,
      [name]: value,
    });
  };

  const editInputChangeHandler = (e , name) => {
    if(name === "interestRate" ) {
      setInterestRate(e.target.value);
    } else {
      setCompountInt(e.target.value);
    }
  } 

  const editFormsubmitHandler = () => {
    axios.post(`/post_msme_details`, {
      from_date:editfromDate,
      to_date:edittoDate,
      intrst_rate:interestRate,
      comp_rate:compountInt,
      type_of_calc:typeCalculation,
      bid: localStorage.getItem("business_entity_id")
    }).then(res => {
      if (res.data.status === true) {
        setIsOpenEditDrawer(false);
        toast.success(res?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
        getApiForMsme();
      } else {
        toast.error(res?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      }
    })
      .catch(err => {
        toast.error(err?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      })
  }

  const addFormsubmitHandler = () => {
    axios.post(`/post_msme_details`, {
      ...addFormData,
      bid: localStorage.getItem("business_entity_id")
    }).then(res => {
      if (res.data.status === true) {
        setAddFormData({});
        setIsOpenAddDrawer(false);
        toast.success(res?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
        getApiForMsme();
      } else {
        toast.error(res?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      }
    })
      .catch(err => {
        toast.error(err?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      })
  };

  const getApiForMsme = () => {
    axios.get(`/get_msme_details/${localStorage.getItem("business_entity_id")}`)
      .then(res => {
        if (res.data.status === true) {
          setMsmeInterestTableData(res?.data?.record);
        } else {
          setMsmeInterestTableData([]);
        }
      })
      .catch(err => console.log(err));
  }

  // /post_msme_details

  useEffect(() => {
    toast.configure();
    getApiForMsme();
  }, [])

  return (
    <div>
      <BoxWrapper
        boxTitle="MSME INTEREST"
        enableAddButton={true}
        addButtonLabel="Add"
        addButtonHandlerFn={addDrawerOpenHandler}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>From Date</th>
                <th>To Date</th>
                <th>Interest Rate %</th>
                <th>Number of times Interest is compounded</th>
                <th>Type Of Calculation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {msmeInterestTableData.length > 0 &&
                msmeInterestTableData.map((item, i) => {
                  return (
                    <tr key={`msme${i}`}>
                      <td>{moment(item.from_date).format("DD-MMM-YYYY")}</td>
                      <td>{moment(item.to_date).format("DD-MMM-YYYY")}</td>
                      <td>{item.interest_rate}</td>
                      <td>{item.compounding_rate}</td>
                      <td>{item.type_of_calculation}</td>
                      <td>
                        <Button
                          style={{ minWidth: "0px" }}
                          onClick={() => editFormOpenDrawerHandler(item)}
                        >
                          <EditOutlinedIcon className="editIcon" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      <RightDrawer
        open={isOpenAddDrawer}
        drawerName="ADD MSME INTEREST"
        onClose={closeAddDrawerHandler}
      >
        <ValidatorForm
          useRef="form"
          onSubmit={addFormsubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <div style={{ marginTop: "20px" }}>
            <label>
              From Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              name="valid_from_date"
              value={addFormData.from_date ?? ""}
              onChange={(v) => handleFromDateChange(v)}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <label>
              To Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              minDateForDatepicker={addFormData.from_date ?? ""}
              name="to_date"
              value={addFormData.to_date ?? ""}
              onChange={(v) => handleToDateChange(v)}
              validators={["required"]}
              errorMessages={["This field is required"]}
              disabled={addFormData.from_date ? false : true}
            />
          </div>

          <InputField
            textLabel="Interest Rate%"
            inputType="number"
            name="intrst_rate"
            value={addFormData.intrst_rate ?? ""}
            // requiredField="*"
            // validator={["required"]}
            // errorMsg={["This field is required"]}
            textOnchangeFunction={addInputChangeHandler}
          />

          <SelectInputField
            SelectLabel="Number of times Interest is compounded"
            name="comp_rate"
            choice_id="id"
            choice_name="id"
            value={addFormData.comp_rate}
            textOnchangeFunction={addInputChangeHandler}
            choices={[
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
            ]}
          />
          {/* .......Button........ */}
          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={closeAddDrawerHandler}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </RightDrawer>

      <RightDrawer
        open={isOpenEditDrawer}
        drawerName="EDIT MSME INTEREST"
        onClose={closeEditDrawerHandler}
      >
        <ValidatorForm
          useRef="form"
          onSubmit={editFormsubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <div style={{ marginTop: "20px" }}>
            <label>
              From Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              name="valid_from_date"
              value={editfromDate ?? ""}
              // onChange={(v) => handleFromDateChange(v)}
              // validators={["required"]}
              // errorMessages={["This field is required"]}
              disabled={true}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <label>
              To Date
              <span style={{ color: "red" }}>*</span>:
            </label>
            <ValidatedDatePicker
              minDateForDatepicker={editfromDate ?? ""}
              name="to_date"
              value={edittoDate ?? ""}
              onChange={(v) => handleToDateChangeForEdit(v)}
              // validators={["required"]}
              // errorMessages={["This field is required"]}
              // disabled={addFormData.from_date ? false : true}
            />
          </div>

          <InputField
            textLabel="Interest Rate%"
            inputType="number"
            name="intrst_rate"
            value={interestRate ?? ""}
            // requiredField="*"
            // validator={["required"]}
            // errorMsg={["This field is required"]}
            textOnchangeFunction={(e) => editInputChangeHandler(e , "interestRate")}
          />

          <SelectInputField
            SelectLabel="Number of times Interest is compounded"
            name="comp_rate"
            choice_id="id"
            choice_name="id"
            value={compountInt}
            textOnchangeFunction={(e) => editInputChangeHandler(e , "comp_rate")}
            choices={[
              {
                id: 0,
              },
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
            ]}
          />
          {/* .......Button........ */}
          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={closeEditDrawerHandler}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </RightDrawer>

    </div>
  );
};

export default MsmeInterest;