// import React, { useState } from "react";

// import classes from "./Report.module.css";
// import moment from "moment";
// import BoxWrapper from "../../../../../components/ui/boxWrapper";

// function ProbableMatchedInvoice(props) {
//   const { probableMatchedInvoiceData } = props;
//   const [probableMatchedInvoiceTableData, setProbableMatchedInvoiceTableData] =
//     useState(probableMatchedInvoiceData);
//   const [searchInput, setSearchInput] = useState("");

//   const requestSearch = (event) => {
//     const searchVal = event.target.value;
//     const filteredRows = probableMatchedInvoiceData.filter((row) => {
//       return (
//         String(row.GSTIN).toLowerCase().indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.Vendor_Name)
//           .toLowerCase()
//           .indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.DOC_TYPE).toLowerCase().indexOf(searchVal.toLowerCase()) >=
//           0 ||
//         String(row.GSTR2A_DOC_NUMBER)
//           .toLowerCase()
//           .indexOf(searchVal.toLowerCase()) >= 0 ||
//         String(row.GSTR2A_DOC_DATE)
//           .toLowerCase()
//           .indexOf(searchVal.toLowerCase()) >= 0
//       );
//     });
//     setProbableMatchedInvoiceTableData(filteredRows);
//   };

//   const cancelSearch = () => {
//     setSearchInput("");
//     requestSearch(searchInput);
//   };

//   // indian date format
//   // const indianFormat = (date) => {
//   //     let dateArr = date.split("-");
//   //     return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
//   // };

//   // indian rupeess system---
//   const numberFormat = (value) =>
//     new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumSignificantDigits: 10,
//     }).format(value);
//   // ---End---

//   const probableMatchedInvoiceTableBody = () => {
//     if (probableMatchedInvoiceTableData.length >= 1) {
//       return probableMatchedInvoiceTableData.map((tableData, index) => {
//         const {
//           GSTIN,
//           Vendor_Name,
//           PR_DOC_NUMBER,
//           GSTR2A_DOC_NUMBER,
//           PR_DOC_DATE,
//           GSTR2A_DOC_DATE,
//           DOC_TYPE,

//           Taxable_Value_Int,
//           IGST_Int,
//           CGST_Int,
//           SGST_Int,
//           Cess_Int,
//           Total_Invoice_Value_Int,

//           Taxable_Value_GSTR2A,
//           IGST_GSTR2A,
//           CGST_GSTR2A,

//           Cess_GSTR2A,
//           Total_Invoice_Value_GSTR2A,

//           Taxable_Value_Diff,
//           Tax_Diff,
//           Total_Amount_Diff,
//           Remarks,
//         } = tableData;
//         return (
//           <tr>
//             <td>{GSTIN}</td>
//             <td>{Vendor_Name}</td>
//             <td>{PR_DOC_NUMBER}</td>
//             <td>{GSTR2A_DOC_NUMBER}</td>
//             <td>{PR_DOC_DATE}</td>
//             <td>{moment(GSTR2A_DOC_DATE).format("DD-MM-YYYY")}</td>
//             <td>{DOC_TYPE}</td>

//             <td>{numberFormat(Taxable_Value_Int)}</td>
//             <td>{numberFormat(CGST_Int)}</td>
//             <td>{numberFormat(SGST_Int)}</td>
//             <td>{numberFormat(IGST_Int)}</td>
//             <td>{numberFormat(Cess_Int)}</td>
//             <td>{numberFormat(Total_Invoice_Value_Int)}</td>

//             <td>{numberFormat(Taxable_Value_GSTR2A)}</td>
//             <td>{numberFormat(CGST_GSTR2A)}</td>
//             <td>{numberFormat(CGST_GSTR2A)}</td>
//             <td>{numberFormat(IGST_GSTR2A)}</td>
//             <td>{numberFormat(Cess_GSTR2A)}</td>
//             <td>
//               {Total_Invoice_Value_GSTR2A
//                 ? numberFormat(Total_Invoice_Value_GSTR2A)
//                 : "₹0"}
//             </td>

//             <td>{numberFormat(Taxable_Value_Diff)}</td>
//             <td>{numberFormat(Tax_Diff)}</td>
//             <td>{numberFormat(Total_Amount_Diff)}</td>

//             <td>{Remarks}</td>
//           </tr>
//         );
//       });
//     } else {
//       return (
//         <tr>
//           <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
//             No Data Found!
//           </td>
//         </tr>
//       );
//     }
//   };

//   return (
//     <>
//       <BoxWrapper
//         boxTitle="Reconciliation Status :Invoice(s) Matching with Intelligence"
//         // search input
//         enableSearchInput={true}
//         searchInputPlaceholder="Search..."
//         searchInputOnchange={requestSearch}
//       >
//         <div className={classes.ResponsiveTable}>
//           <table className={classes.AllInvoiceReconTableCss}>
//             <tr>
//               <th rowSpan={2}>GSTIN</th>
//               <th rowSpan={2} align="right">
//                 VENDOR NAME
//               </th>
//               <th rowSpan={2} align="right">
//                 PR DOC NUMBER
//               </th>
//               <th rowSpan={2} align="right">
//                 GSTR2B DOC NUMBER
//               </th>
//               <th rowSpan={2} align="right">
//                 PR DOC DATE
//               </th>
//               <th rowSpan={2} align="right">
//                 GSTR2B DOC DATE
//               </th>
//               <th rowSpan={2} align="right">
//                 DOC TYPE
//               </th>

//               <th style={{ background: "#ffffb3" }} colSpan={6} align="center">
//                 As per Book
//               </th>
//               <th style={{ background: "#5cd65c" }} colSpan={6} align="center">
//                 As per GSTR2B
//               </th>
//               <th style={{ background: "#ff8533" }} colSpan={3} align="center">
//                 Difference
//               </th>
//               <th style={{ background: "#ffc107" }} rowSpan={2}>
//                 Remarks{" "}
//               </th>
//             </tr>
//             <tr>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#ffffb3" }} align="right">
//                 TOTAL AMOUNT
//               </th>

//               <th style={{ background: "#5cd65c" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 SGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 IGST
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 CESS
//               </th>
//               <th style={{ background: "#5cd65c" }} align="right">
//                 TOTAL AMOUNT
//               </th>

//               <th style={{ background: "#ff8533" }} align="right">
//                 TAXABLE VALUE
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TAX
//               </th>
//               <th style={{ background: "#ff8533" }} align="right">
//                 TOTAL AMOUNT
//               </th>
//             </tr>
//             {probableMatchedInvoiceTableBody()}
//           </table>
//         </div>
//       </BoxWrapper>
//     </>
//   );
// }

// export default ProbableMatchedInvoice;

import React, { useEffect, useState } from 'react';
import classes from './Report.module.css';
import BoxWrapper from '../../../../../components/ui/boxWrapper';

function ProbableMatched({ probablematchedData, onTabClick }) {
  useEffect(() => {
    onTabClick();
  }, [onTabClick]);

  const [searchInput, setSearchInput] = useState('');
  const invoiceData = probablematchedData?.record?.ProbableMatching ?? [];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredData = invoiceData.filter((invoice) => {
    // Perform a null check before accessing the 'includes' method
    const gstin = invoice.GSTIN || '';
    const vendorName = invoice.Vendor_Name || '';
    
          return (
     gstin.includes(searchInput) ||
    vendorName.includes(searchInput)
    );
     });

  return (
    <>
      <BoxWrapper
        searchInputPlaceholder="Search..."
        boxTitle="Reconciliation Status: Invoice(s) Matching with Intelligence"
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <thead>
              <tr>
                <th rowSpan={2}>GSTIN</th>
                <th rowSpan={2} align="right">
                  VENDOR NAME
                </th>
                <th rowSpan={2} align="right">
                  PR DOC NUMBER
                </th>
                <th rowSpan={2} align="right">
                  GSTR2B DOC NUMBER
                </th>
                <th rowSpan={2} align="right">
                  PR DOC DATE
                </th>
                <th rowSpan={2} align="right">
                  GSTR2B DOC DATE
                </th>
                <th rowSpan={2} align="right">
                  DOC TYPE
                </th>
                <th style={{ background: '#ffcc66' }} colSpan={6} align="center">
                  As per Books
                </th>
                <th style={{ background: '#c6ffb3' }} colSpan={6} align="center">
                  As per GSTR2B
                </th>
                <th style={{ background: 'ORANGE' }} colSpan={3} align="center">
                  Difference
                </th>
                <th style={{ background: 'ORANGE' }} rowSpan={2}  align="center">
                 REMARKS
                </th>
                
              </tr>
              <tr>
                <th style={{ background: '#ffcc66' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#ffcc66' }} align="right">
                  TOTAL AMOUNT
                </th>

                <th style={{ background: '#c6ffb3' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  SGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  IGST
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  CESS
                </th>
                <th style={{ background: '#c6ffb3' }} align="right">
                  TOTAL AMOUNT
                </th>

                <th style={{ background: 'ORANGE' }} align="right">
                  TAXABLE VALUE
                </th>
                <th style={{ background: 'ORANGE' }} align="right">
                  TAX
                </th>
                <th style={{ background: 'ORANGE' }} align="right">
                  TOTAL AMOUNT
                </th>
               

                
              </tr>
            </thead>

            <tbody>
             
              {filteredData.map((invoice, index) => (
  <tr key={index}>
    <td style={{ color: 'black' }}>{invoice.GSTIN}</td>
    <td style={{ color: 'black' }}>{invoice.Vendor_Name}</td>
    <td style={{ color: 'black' }}>{invoice.PR_DOC_NUMBER}</td>
    <td style={{ color: 'black' }}>{invoice.GSTR2A_DOC_NUMBER}</td>
    <td style={{ color: 'black' }}>{invoice.PR_DOC_DATE}</td>
    <td style={{ color: 'black' }}>{invoice.GSTR2A_DOC_DATE}</td>
    <td style={{ color: 'black' }}>{invoice.DOC_TYPE}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_Int}</td>
    <td style={{ color: 'black' }}>{invoice.CGST_Int}</td>
    <td style={{ color: 'black' }}>{invoice.SGST_Int}</td>
    <td style={{ color: 'black' }}>{invoice.IGST_Int}</td>
    <td style={{ color: 'black' }}>{invoice.Cess_Int}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Invoice_Value_Int}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.CGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.SGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.IGST_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Cess_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Invoice_Value_GSTR2B}</td>
    <td style={{ color: 'black' }}>{invoice.Taxable_Value_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.Tax_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.Total_Amount_Diff}</td>
    <td style={{ color: 'black' }}>{invoice.Remarks}</td>
  </tr>
))}

            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default ProbableMatched;

