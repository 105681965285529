import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import axios from "../../../axios-order";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory, useLocation } from "react-router-dom";
import AnalyticsIcon from "@mui/icons-material/Analytics";
function VendorViewData() {
  let history = useHistory();
  const [value, setValue] = useState(0);
  const [summaryRecords, setSummaryRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  let record_id = localStorage.getItem("record_id");
  const [excelLoading, setExcelLoading] = React.useState(false);

  useEffect(() => {
    axios
      .get(`/blocked_gstin/get_summary/${record_id}`)
      .then((response) => {
        if (response.data.records) {
          setSummaryRecords(response.data.records);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [record_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue); // Set the tab value on change
  };

  const handleViewData = (item) => {
    // Navigate to VendoreViewtable and pass vendor_gstin and item
    history.push("/VendoreViewtable", {
      vendor_gstin: item.vendor_gstin, // Ensure you're passing vendor_gstin
      item: item,
    });
  };
  const renderSummaryTable = () => (
    <TableContainer
      component={Paper}
      sx={{ marginTop: 2, maxHeight: "400px", overflowY: "auto" }}
    >
      <Table aria-label="customized table" sx={{ borderRadius: "20px" }}>
        <TableRow sx={{ backgroundColor: "#303841" }}>
          <TableCell sx={{ color: "white" }} align="center">
            ERP Code
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Vendor Name
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Vendor GSTIN
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Date of Cancellation
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            GST Registration Status
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Total Taxable Value
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Total Tax Value
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Total Invoice Amount
          </TableCell>
          <TableCell sx={{ color: "white" }} align="center">
            Action
          </TableCell>
        </TableRow>

        <TableBody>
          {summaryRecords.length > 0 ? (
            summaryRecords.map((row, index) => (
              <TableRow key={row.erp_code}>
                <TableCell align="center">{row.erp_code}</TableCell>
                <TableCell align="center">{row.vendor_name}</TableCell>
                <TableCell align="center">{row.vendor_gstin}</TableCell>
                <TableCell align="center">{row.date_of_cancellation}</TableCell>
                <TableCell align="center">
                  {row.gst_registration_status}
                </TableCell>
                <TableCell align="center">
                  {row.total_taxable_value_final.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {row.total_tax_value_final.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {row.total_invoice_amount_final.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  <VisibilityIcon
                    sx={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleViewData(row)} // Ensure row.id is passed
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center" sx={{ fontWeight: "bold" }}>
                No data found!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleDownloadReport = (item) => {
    setExcelLoading(true);
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = "Vendor Cancellation Report.xlsx";
    const record_id = localStorage.getItem("record_id");

    if (record_id) {
      axios
        .get(`/blocked_gstin/download_report/${record_id}`)
        .then((res) => {
          if (res.data && res.data.file) {
            downloadBase64File(contentType, res.data.file, filename);
          } else {
            console.error("No file data in the response");
          }
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        })
        .finally(() => {
          setExcelLoading(false);
        });
    } else {
      console.error("No record_id found in localStorage");
      setExcelLoading(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Aligns items to the start and end of the container
          alignItems: "center", // Vertically center the items
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#2f5e26",
            fontStyle: "Montserrat",
            flexGrow: 1, // Ensures the typography takes available space
          }}
        >
          VENDOR CANCELLATION SUMMARY
          <AnalyticsIcon style={{ marginBottom: "4px", color: "#2f5e26" }} />
        </Typography>

        <Box sx={{ display: "flex", gap: "16px" }}>
          <Button
            onClick={() => history.push("/VendorCancellation")}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            BACK
          </Button>

          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={handleDownloadReport}
            endIcon={<FileDownloadIcon />}
            style={{ marginLeft: "16px" }}
            loading={excelLoading}
          >
            Export
          </LoadingButton>
        </Box>
      </Box>

      {value === 0 && renderSummaryTable()}
    </div>
  );
}

export default VendorViewData;
