import React, { useState, useEffect, useRef } from "react";
import classes from "../BusinessEntityDetails.module.css";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/inputField";
import SelectField from "../../../components/ui/select";
import axios from "../../../axios-order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BoxWrapper from "../../../components/ui/boxWrapper";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RightDrawer from "../../../components/ui/RightDrawer";


import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const MailConnectionDetails = () => {
    const formRef = useRef("form");
    const [mailDeatils, setMailDetails] = useState([]);
    const [addData, setAddData] = useState({});
    const [openAddConnectionDrawer, setOpenAddConnectionDrawer] = useState(false);
    const [openEditConnectionDrawer, setOpenEditConnectionDrawer] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

    const [responseMessage, setResponseMessage] = useState("");

    const getConnectionDetails = () => {
        axios
            .get(`/business_entity/get_mail_details/${localStorage.getItem("business_entity_id")}`)
            .then((res) => {
                setMailDetails(res.data.records);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);
        setResponseMessage(message);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        getConnectionDetails();
    }, []);


    const connectionDetailTableBody = () => {

        if (mailDeatils.length >= 1) {
            return mailDeatils.map((tableData, index) => {

                return (
                    <tr key={index}>
                        <td>{tableData.sender_email}</td>
                        <td>{tableData.password ? "*******" : ""}</td>
                        <td>{tableData.smtp_server}</td>
                        <td>{tableData.smtp_port}</td>
                        <td>{tableData.cc_mail}</td>
                        <td>
                            <Button
                                style={{ minWidth: "0px" }}
                                size="small"
                                onClick={() => handleEdit(tableData)}
                            >
                                <EditOutlinedIcon className="editIcon" />
                            </Button>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                        No Data found!
                    </td>
                </tr>
            );
        }
    };

    // add connection section
    const openAddDrawer = () => {
        setOpenAddConnectionDrawer(true);
    };

    const addMailDetailsSubmitHandler = () => {

        axios.post('/business_entity/add_mail_details', { ...addData, business_entity_id: localStorage.getItem("business_entity_id") })
            .then((res) => {
                if (res.data) {
                    setAddData({})
                    getConnectionDetails();
                    setOpenAddConnectionDrawer(false);
                    handleSnackbarOpen(res.data.message, 'success')
                }
            })
            .catch((err) => {
                setAddData({})
            })

    }


    const addConnectionChangeHandlerForIars = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setAddData({
            ...addData,
            [name]: String(value),
        });
    };

    const handleEdit = (item) => {
        setEditItem(item)
        setOpenEditConnectionDrawer(true)
    }

    const closeAddDrawer = () => {
        setOpenAddConnectionDrawer(false);
        setAddData({});
        setShowPassword(false)
    };

    const editMailDetailsSubmitHandler = () => {

        axios.post('/business_entity/edit_mail_details', { ...editItem, business_entity_id: localStorage.getItem("business_entity_id") })
            .then((res) => {
                if (res.data) {
                    setAddData({})
                    getConnectionDetails();
                    setOpenEditConnectionDrawer(false)
                    handleSnackbarOpen(res.data.message, 'success')
                }
            })
            .catch((err) => {
                setAddData({})
            })

    }

    const editConnectionChangeHandle = (e) => {
        const { name, value } = e.target;
        setEditItem(prevEditItem => ({
            ...prevEditItem,
            [name]: value,
        }));
    };



    const closeEditDrawer = () => {
        setOpenEditConnectionDrawer(false);
        setShowEditPassword(false)
        setEditItem({})
    };

    return (
        <>
            <BoxWrapper
                boxTitle="Mail Details"
                enableAddButton={true}
                addButtonLabel={"ADD Mail Details"}
                addButtonLabelDisbled={mailDeatils.length >= 1 ? true : false}
                addButtonHandlerFn={() => openAddDrawer()}
            >
                <div className="responsive-table">
                    <table className="custome-table">
                        <tbody>
                            <tr>
                                <th>sender email</th>
                                <th>password</th>
                                <th>smtp server</th>
                                <th>smtp port</th>
                                <th>CC Email</th>
                                <th>Action</th>
                            </tr>
                            {connectionDetailTableBody()}
                        </tbody>
                    </table>
                </div>
            </BoxWrapper>


            <RightDrawer
                drawerName="Add Mail Details"
                open={openAddConnectionDrawer}
                onClose={closeAddDrawer}
            >
                <ValidatorForm
                    ref={formRef}
                    onSubmit={addMailDetailsSubmitHandler}
                    onError={(errors) => console.log(errors)}
                >


                    <div>
                        <InputField
                            inputType="text"
                            textLabel="Sender Email"
                            name="sender_email"
                            value={addData.sender_email ?? ""}
                            requiredField="*"
                            validator={["required", "isEmail"]}
                            errorMsg={["This field is required", 'Email is not valid']}
                            textOnchangeFunction={addConnectionChangeHandlerForIars}
                        />
                        <InputField
                            inputType={showPassword ? "text" : "password"}
                            textLabel="Password"
                            name="password"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            value={addData.password ?? ""}
                            textOnchangeFunction={addConnectionChangeHandlerForIars}
                            styleProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowPassword(!showPassword) }}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff size="small" />
                                            ) : (
                                                <Visibility size="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <InputField
                            inputType="text"
                            textLabel="Smtp Server"
                            name="smtp_server"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            value={addData.smtp_server ?? ""}
                            textOnchangeFunction={addConnectionChangeHandlerForIars}
                        />

                        <InputField
                            inputType="text"
                            textLabel="Smtp Port"
                            name="smtp_port"
                            requiredField="*"
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                            value={addData.smtp_port ?? ""}
                            textOnchangeFunction={addConnectionChangeHandlerForIars}
                        />

                        <InputField
                            inputType="text"
                            textLabel="CC Mail"
                            name="cc_mail"
                            value={addData.cc_mail ?? ""}
                            textOnchangeFunction={addConnectionChangeHandlerForIars}
                        />


                    </div>


                    <div className="mt-20">
                        <button className="button-primary" type="submit">
                            Submit
                        </button>
                        <button
                            type="button"
                            className="button-outlined-secondary"
                            onClick={closeAddDrawer}
                        >
                            cancel
                        </button>
                    </div>
                </ValidatorForm>
            </RightDrawer>

            {/* edit Mail Details drawer */}
            <RightDrawer
                drawerName="Edit Connection Details"
                open={openEditConnectionDrawer}
                onClose={closeEditDrawer}
            >
                <ValidatorForm
                    ref={formRef}
                    onSubmit={editMailDetailsSubmitHandler}

                >


                    <div>
                        <InputField
                            inputType="text"
                            textLabel="Sender Email"
                            requiredField="*"
                            name="sender_email"
                            value={editItem.sender_email || ""}
                            // requiredField={true}
                            textOnchangeFunction={editConnectionChangeHandle}
                            validator={["required", "isEmail"]}
                            errorMsg={["This field is required", 'Email is not valid']}
                        />
                        <InputField
                            inputType={showEditPassword ? "text" : "password"}
                            textLabel="Password"
                            name="password"
                            value={editItem.password ?? ""}
                            textOnchangeFunction={editConnectionChangeHandle}
                            validator={["required"]}
                            errorMsg={["This field is required"]}

                            styleProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setShowEditPassword(!showEditPassword) }}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff size="small" />
                                            ) : (
                                                <Visibility size="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <InputField
                            inputType="text"
                            textLabel="Smtp Server"
                            name="smtp_server"
                            value={editItem.smtp_server ?? ""}
                            textOnchangeFunction={editConnectionChangeHandle}
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                        />

                        <InputField
                            inputType="text"
                            textLabel="Smtp Port"
                            name="smtp_port"
                            value={editItem.smtp_port ?? ""}
                            textOnchangeFunction={editConnectionChangeHandle}
                            validator={["required"]}
                            errorMsg={["This field is required"]}
                        />

                        <InputField
                            inputType="text"
                            textLabel="CC Mail"
                            name="cc_mail"
                            value={editItem.cc_mail ?? ""}
                            textOnchangeFunction={editConnectionChangeHandle}
                        />
                    </div>


                    <div className="mt-20">
                        <button className="button-primary" type="submit">
                            Submit
                        </button>
                        <button
                            type="button"
                            className="button-outlined-secondary"
                            onClick={closeEditDrawer}
                        >
                            cancel
                        </button>
                    </div>
                </ValidatorForm>
            </RightDrawer>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={snackbarOpen}
                autoHideDuration={5000} // Adjust as needed
                onClose={handleSnackbarClose}
                style={{ maxWidth: '25%' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Use the state variable to determine severity
                >
                    {responseMessage}

                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default MailConnectionDetails;
