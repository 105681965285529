import React, { useState } from "react";
import classes from "./GSTReconciliation.module.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

function MatchedInvoice(props) {
  const { matchedInvoiceData } = props;
  const [matchedInvoiceTableData, setMatchedInvoiceTableData] =
    useState(matchedInvoiceData);

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const matchedInvoiceTableBody = () => {
    if (matchedInvoiceTableData.length >= 1) {
      return matchedInvoiceTableData.map((tableData, index) => {
        const {
          cust_gstin,
          cust_name,
          cws_cess,
          cws_cgst,
          cws_igst,
          cws_sgst,
          cws_taxable_value,
          cws_total_amount,
          gstr_cess,
          gstr_cgst,
          gstr_igst,
          gstr_sgst,
          gstr_taxable_value,
          gstr_total_amount,
          invoice_date,
          invoice_no,
          invoice_type,
        } = tableData;
        return (
          <tr>
            <td>{cust_gstin}</td>
            <td>{cust_name}</td>
            <td>{invoice_no}</td>
            <td>{invoice_date}</td>
            <td>{invoice_type}</td>
            <td>{numberFormat(gstr_taxable_value)}</td>
            <td>{numberFormat(gstr_cgst)}</td>
            <td>{numberFormat(gstr_sgst)}</td>
            <td>{numberFormat(gstr_igst)}</td>
            <td>{numberFormat(gstr_cess)}</td>
            <td>{numberFormat(gstr_total_amount)}</td>

            <td>{numberFormat(cws_taxable_value)}</td>
            <td>{numberFormat(cws_cgst)}</td>
            <td>{numberFormat(cws_sgst)}</td>
            <td>{numberFormat(cws_igst)}</td>
            <td>{numberFormat(cws_cess)}</td>
            <td>{numberFormat(cws_total_amount)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={17} style={{ color: "red", textAlign: "left" }}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  const searchInputHandler = (e) => {
    let searchInput = e.target.value;
    let searchData = matchedInvoiceData.filter((value) => {
      const query = searchInput.toLowerCase();
      return (
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_name).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_date).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_type).toLowerCase().indexOf(query) >= 0
      );
    });
    setMatchedInvoiceTableData(searchData);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Matched Invoices"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={searchInputHandler}
      >
        <div className={classes.ResponsiveTable}>
          <table className={classes.missingInAccountTableCss}>
            <tr>
              <th rowSpan={2}>GSTIN</th>
              <th rowSpan={2} align="right">
                CUSTOMER NAME
              </th>
              <th rowSpan={2} align="right">
                INVOICE/CN/DN/NUMBER
              </th>
              <th rowSpan={2} align="right">
                DATE
              </th>
              <th rowSpan={2} align="right">
                TYPE
              </th>
              <th style={{ background: "#ffcc66" }} colSpan={6} align="center">
                As per Books
              </th>
              <th style={{ background: "#c6ffb3" }} colSpan={6} align="center">
                As per GSTR1
              </th>
            </tr>
            <tr>
              <th style={{ background: "#ffcc66" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                CGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                SGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                IGST
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                CESS
              </th>
              <th style={{ background: "#ffcc66" }} align="right">
                TOTAL AMOUNT
              </th>

              <th style={{ background: "#c6ffb3" }} align="right">
                TAXABLE VALUE
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                SGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                IGST
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                CESS
              </th>
              <th style={{ background: "#c6ffb3" }} align="right">
                TOTAL AMOUNT
              </th>
            </tr>
            {matchedInvoiceTableBody()}
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default MatchedInvoice;
