import React, { Component } from "react";
import classes from "./PersonalInfo.module.css";
import axios from "../../../axios-order";
import { toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/inputField";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../components/appBar/appBar";
import Footer from "../../../components/footer/footer";

class PersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProfileData: null,
      editProfileData: {
        user_name: "",
        user_email_id: "",
        user_contact_no: "",
        user_role: "",
        status: "",
      },
      change_password: {},
      editDrawer: false,
      changePasswordDrawer: false,
    };
  }

  componentDidMount = () => {
    this.profileData();
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.change_password.new_password) {
        return false;
      }
      return true;
    });
  };

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  profileData = () => {
    axios
      .get(`/get_user`)
      .then((res) => {
        this.setState({ ProfileData: res.data.records });
        localStorage.setItem("user_name", res.data.records.user_name);
        this.setState((prevState) => ({
          editProfileData: {
            ...prevState.editProfileData,
            user_name: this.state.ProfileData.user_name,
            user_email_id: this.state.ProfileData.user_email_id,
            user_contact_number: this.state.ProfileData.user_contact_number,
            user_role: this.state.ProfileData.user_role,
            status: this.state.ProfileData.status,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // edit---
  editToggleDrawer = (event) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ editDrawer: true });
  };

  handleEditInputChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      editProfileData: {
        ...prevState.editProfileData,
        [name]: String(value),
      },
    }));
  };

  editProfileSubmitHandler = () => {
    toast.configure();
    axios
      .put(
        `/put_user_data/${this.state.ProfileData.user_id}`,
        this.state.editProfileData
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.setState({ editDrawer: false });
        this.profileData();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ editDrawer: false });
      });
  };

  editToggleDrawerClose = (event) => {
    event.preventDefault();
    this.setState({ editDrawer: false });
  };

  // change password---
  changePasswordToggleDrawer = (event) => {
    event.preventDefault();
    this.setState({ changePasswordDrawer: true });
  };

  handlePasswordInputChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    if (name === "new_password" || name === "old_password") {
      this.setState((prevState) => ({
        change_password: {
          ...prevState.change_password,
          [name]: String(value),
        },
      }));
    } else {
      this.setState({
        [name]: String(value),
      });
    }
  };

  changePasswordSubmitHandler = () => {
    toast.configure();
    axios
      .post("/change_password", this.state.change_password)
      .then((res) => {
        toast.success(res.data.message, { autoClose: 3000, progress: 0 });
        this.setState({ changePasswordDrawer: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        this.setState({ changePasswordDrawer: false });
      });
  };

  changePasswordToggleDrawerClose = (event) => {
    event.preventDefault();
    this.setState({ changePasswordDrawer: false });
  };

  backButton = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <Appbar />
        <div className={classes.profileSection}>
          <div className={classes.PesonalInfo}>
            <div className={classes.ProfileHeader}>Personal Profile</div>
            <div className={classes.profileBody}>
              <ul>
                <li style={{ marginRight: "10px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={this.backButton}
                  >
                    Back
                  </Button>
                </li>
                <li>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={this.editToggleDrawer}
                  >
                    Edit
                  </Button>
                </li>
                <li style={{ marginLeft: "10px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={this.changePasswordToggleDrawer}
                  >
                    Change Password
                  </Button>
                </li>
              </ul>
              {/* ---profile data--- */}
              {this.state.ProfileData ? (
                <table className={classes.profileTableCss}>
                  <tr>
                    <td style={{ width: 300 }}>Name :</td>
                    <td>{this.state.ProfileData.user_name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 300 }}>Email Id :</td>
                    <td>{this.state.ProfileData.user_email_id}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 300 }}>Contact Number :</td>
                    <td>{this.state.ProfileData.user_contact_number}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 300 }}>Role :</td>
                    <td>{this.state.ProfileData.user_role}</td>
                  </tr>
                </table>
              ) : (
                ""
              )}
            </div>
            {/* ---End--- */}
            {/* ---Edit profile --- */}
            <Drawer
              anchor="right"
              open={this.state.editDrawer}
              onClose={this.editToggleDrawerClose}
            >
              <div className={classes.EditProfile}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.editProfileSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <InputField
                    textLabel="User Name"
                    inputType="text"
                    name="user_name"
                    value={this.state.editProfileData.user_name}
                    requiredField="*"
                    validator={["required"]}
                    errorMsg={["This field is required"]}
                    textOnchangeFunction={this.handleEditInputChange}
                  />
                  <InputField
                    textLabel="Email Id"
                    inputType="text"
                    name="user_email_id"
                    value={this.state.editProfileData.user_email_id}
                    requiredField="*"
                    inputProps={{
                      readOnly: true,
                    }}
                    validator={["required"]}
                    errorMsg={["This field is required"]}
                    textOnchangeFunction={this.handleEditInputChange}
                  />
                  <InputField
                    textLabel="Contact No"
                    inputType="text"
                    name="user_contact_number"
                    value={this.state.editProfileData.user_contact_number}
                    requiredField="*"
                    validator={["required", "matchRegexp:^[0-9]{10}$"]}
                    errorMsg={[
                      "This field is required",
                      "Invalid contact number",
                    ]}
                    textOnchangeFunction={this.handleEditInputChange}
                  />
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    size="small"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={this.editToggleDrawerClose}
                  >
                    cancel
                  </Button>
                </ValidatorForm>
              </div>
            </Drawer>
            {/* ---End--- */}
            {/* ---Change password--- */}
            <Drawer
              anchor="right"
              open={this.state.changePasswordDrawer}
              onClose={this.changePasswordToggleDrawerClose}
            >
              <div className={classes.EditProfile}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.changePasswordSubmitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <InputField
                    textLabel="Old Password"
                    inputType="password"
                    name="old_password"
                    value={this.state.change_password.old_password}
                    requiredField="*"
                    validator={["required"]}
                    errorMsg={["This field is required"]}
                    textOnchangeFunction={this.handlePasswordInputChange}
                  />
                  <div style={{ marginTop: "20px" }}>
                    <label>
                      New Password<span style={{ color: "red" }}>*</span>:
                    </label>
                    <TextValidator
                      type="password"
                      fullWidth={true}
                      size="small"
                      name="new_password"
                      variant="outlined"
                      placeholder="eg.Cws@1234"
                      value={this.state.change_password.new_password ?? ""}
                      onChange={this.handlePasswordInputChange}
                      validators={[
                        "required",
                        "matchRegexp:^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20})$",
                      ]}
                      errorMessages={[
                        "This field is required",
                        "Must have one capital character,One small character,One special character(!@#$%^&*) and One number.",
                      ]}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <label style={{ marginTop: "20px" }}>
                      Confirm Password<span style={{ color: "red" }}>*</span>:
                    </label>
                    <TextValidator
                      type="password"
                      fullWidth={true}
                      size="small"
                      variant="outlined"
                      name="confirm_password"
                      value={this.state.confirm_password ?? ""}
                      onChange={this.handlePasswordInputChange}
                      validators={["required", "isPasswordMatch"]}
                      errorMessages={[
                        "This field is required",
                        "password mismatch",
                      ]}
                    />
                  </div>
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="contained"
                    size="small"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      marginTop: "20px",
                      marginBottom: "30px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={this.changePasswordToggleDrawerClose}
                  >
                    cancel
                  </Button>
                </ValidatorForm>
              </div>
            </Drawer>
            {/* ---End--- */}
          </div>
        </div>
        {/* ---Footer--- */}
        <div className={classes.profileFooter}>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}
export default PersonalProfile;
