import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import axios from "../../../../axios-order";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
} from "@material-ui/core";
import GSTSummaryDashboard from "./GSTSummary";
import B2CSummary from "./B2CSummary";
import MatchedInvoice from "./matchedInvoice";
import MismatchedInvoice from "./mismatchedInvoice";
import MissingInAccount from "./missingInAccount";
import MissingInGSTR from "./missingInGSTR";
import CustomerInvoiceSummary from "./CustomerInvoiceSummary";
import AllInvoiceRecon from "./AllInvoiceRecon";
import IntelligentMatching from "./intelligentMatching";
import CryptoJS from "crypto-js";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const styles = function (theme) {
  return {
    root: {
      width: "400px",
    },
    tab: {
      [theme.breakpoints.up("md")]: {
        minWidth: "auto",
      },
    },
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGSTReconData: [],
      GSTRecon_summary: [],
      customerInvoiceSummary: [],
      allInvoiceRecon: [],
      gstSummaryDashBoard: [],
      b2cs: [],
      threshold_value: 0,
      tabValue: 0,
    };
    const decrypt = (data) => {
      return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    };
    let activeUrl = window.location.href;
    let id = activeUrl.split("/");

    axios.get(`/arc/get_gst_json/${decrypt(id[4])}`).then((res) => {
      if (typeof res.data == "undefined") {
      } else {
        this.setState({
          allGSTReconData: res?.data?.record?.json_data?.jsonForExportExcel,
          GSTRecon_summary:
            res?.data?.record?.json_data?.jsonForExportExcel?.summary,
          customerInvoiceSummary:
            res?.data?.record?.json_data?.jsonForExportExcel
              ?.custInvoiceSummary,
          allInvoiceRecon:
            res?.data?.record?.json_data?.jsonForExportExcel?.allInvoiceRecon,
          b2cs: res?.data?.record?.json_data?.jsonForExportExcel?.b2cs,
          gstSummaryDashBoard:
            res?.data?.record?.json_data?.jsonForExportExcel
              ?.gstSummaryDashBoard,
          threshold_value:
            res?.data?.record?.json_data?.jsonForExportExcel?.threshold_value,
          fromDate: res?.data?.record?.json_data?.jsonForExportExcel?.fromDate,
          toDate: res?.data?.record?.json_data?.jsonForExportExcel?.toDate,
          reportDate:
            res?.data?.record?.json_data?.jsonForExportExcel?.reportDate,
          gstin: res?.data?.record?.json_data?.jsonForExportExcel?.gstin,
          financialYear:
            res?.data?.record?.json_data?.jsonForExportExcel?.financialYear,
        });
      }
    });
  }

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Gstr-1 Reconciliation Report.xlsx`;
    axios
      .post(`/gst/download`, {
        data: { jsonForExportExcel: this.state.allGSTReconData },
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleTabChangeHandler = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {/* ---header--- */}
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            <div>
              Report Dated : {this.state.reportDate}
              <br></br>Report Period : {this.state.fromDate} To{" "}
              {this.state.toDate}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "18px" }}>GSTR-1 Reconciliation Report</p>

              <button
                className="button-outlined-primary"
                onClick={this.downloadToExcel}
              >
                Export to Excel
              </button>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "right" }}>
              Threshold Used: {this.state.threshold_value}
              <br></br>
              Financial Year:{" "}
              {`${this.state.financialYear} - ${
                parseInt(this.state.financialYear) + 1
              }`}
            </div>
          </Grid>
        </Grid>

        {/* tab panel */}
        <div className={classes.TabPanelSection}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChangeHandler}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#fff", color: "#000" }}
                label="GST Summary "
                {...a11yProps(0)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#ddd", color: "#000" }}
                label="B2C Summary"
                {...a11yProps(1)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#28a745", color: "#000" }}
                label="Matched Invoices"
                {...a11yProps(2)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "red", color: "#fff" }}
                label="Mismatch Invoices"
                {...a11yProps(3)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#1c50b1", color: "#fff" }}
                label="Intelligent Matching "
                {...a11yProps(4)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#ffad33", color: "#000" }}
                label="Missing in Accounts"
                {...a11yProps(5)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#ffbf80", color: "#000" }}
                label="Missing in GSTR1"
                {...a11yProps(6)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#b3d9ff", color: "#000" }}
                label="Customerwise Summary"
                {...a11yProps(7)}
              />
              <Tab
                classes={{ root: classes.tab }}
                style={{ background: "#ffff4d", color: "#000" }}
                label="All Invoice(s)"
                {...a11yProps(8)}
              />
            </Tabs>
          </AppBar>

          {/* GST Recon Summary Dashboard */}
          <TabPanel value={this.state.tabValue} index={0}>
            <BoxWrapper boxTitle="GST Summary Dashboard">
              <GSTSummaryDashboard
                b2bExportData={this.state.gstSummaryDashBoard["b2b+exp"]}
                cdnr={this.state.gstSummaryDashBoard.cdnr}
                ddnr={this.state.gstSummaryDashBoard.ddnr}
                b2ba={this.state.gstSummaryDashBoard.b2ba}
              />
            </BoxWrapper>
          </TabPanel>

          {/* B2C summary */}
          <TabPanel value={this.state.tabValue} index={1}>
            <B2CSummary B2CSummaryData={this.state.b2cs} />
          </TabPanel>

          {/* Matched Invoices */}
          <TabPanel value={this.state.tabValue} index={2}>
            <MatchedInvoice
              matchedInvoiceData={this.state.GSTRecon_summary.matched}
            />
          </TabPanel>

          {/* Mismatch Invoices */}
          <TabPanel value={this.state.tabValue} index={3}>
            <MismatchedInvoice
              mismatchedInvoiceData={this.state.GSTRecon_summary.missMatched}
            />
          </TabPanel>

          {/* Intelligent Matching */}
          <TabPanel value={this.state.tabValue} index={4}>
            <IntelligentMatching
              IntelligentMatchingData={
                this.state.GSTRecon_summary.intelligentMatched
              }
            />
          </TabPanel>

          {/* Missing in Account */}
          <TabPanel value={this.state.tabValue} index={5}>
            <MissingInAccount
              missingInAccountData={
                this.state.GSTRecon_summary.missingInAccounts
              }
            />
          </TabPanel>

          {/* Missing in GSTR1 */}
          <TabPanel value={this.state.tabValue} index={6}>
            <MissingInGSTR
              missingInGSTRData={this.state.GSTRecon_summary.missingInGstr}
            />
          </TabPanel>

          {/* Customer Invoice Summary */}
          <TabPanel value={this.state.tabValue} index={7}>
            <CustomerInvoiceSummary
              CustomerInvoiceSummaryData={this.state.customerInvoiceSummary}
            />
          </TabPanel>

          {/* All invoice Recon */}
          <TabPanel value={this.state.tabValue} index={8}>
            <AllInvoiceRecon AllInvoiceReconData={this.state.allInvoiceRecon} />
          </TabPanel>
        </div>
      </React.Fragment>
    );
  }
}

Showdetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Showdetails);
