import React, { useState, useEffect } from "react";
import axios from "../../axios-order";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityIcon from "@mui/icons-material/Visibility";
import BoxWrapper from "../../components/ui/boxWrapper";
import {
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";

function AisDashboard() {
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [financialYears, setFinancialYears] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const business_entity_id = localStorage.getItem("business_entity_id");
    const fetchFinancialYears = async () => {
      axios
        .get(`/ais/get_ais_financial_years/${business_entity_id}`)
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            setFinancialYears(response.data.data);
            fetchLogs(business_entity_id);
          } else {
            console.error("Unexpected response structure:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching financial years:", error);
        });
    };

    const fetchLogs = (businessEntityId) => {
      setLoaderForTable(true);
      axios
        .get(`/ais/get_ais_logs/${businessEntityId}`)
        .then((response) => {
       
          if (Array.isArray(response.data.data)) {
            setApiData(response.data.data); 
          } else {
            console.error("Unexpected response structure for logs:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching logs:", error);
        })
        .finally(() => {
          setLoaderForTable(false); 
        });
    };
    

  
    fetchFinancialYears();
  }, []);
  

  const handleFinancialYearChange = (event) => {
    setSelectedFinancialYear(event.target.value);
  };

  useEffect(() => {
    if (financialYears.length > 0 && !selectedFinancialYear) {
      setSelectedFinancialYear(financialYears[0]);
    }
  }, [financialYears]);

  const handleSubmitEvent = async () => {
    if (!selectedFinancialYear) {
      toast.error("Please select a financial year."); 
      return;
    }
    const business_entity_id = localStorage.getItem("business_entity_id");
    setLoading(true);
    axios
      .post("/ais/run_recon", {
        business_entity_id: business_entity_id,
        financial_year: selectedFinancialYear,
      })
      
      .then((response) => {
        console.log("API Response:", response.data);
        fetchLogs();
      
      })
      .catch((error) => {
        console.error("Error calling API:", error);
      })
      .finally(() => {
        setLoading(false); // Reset loading state after the API call
      });
  };
  const fetchLogs = () => {
   
    const business_entity_id = localStorage.getItem("business_entity_id");
    axios
      .get(`/ais/get_ais_logs/${business_entity_id}`)
      .then((response) => {
      
        if (Array.isArray(response.data.data)) {
          setApiData(response.data.data); 
        } else {
          console.error("Unexpected response structure for logs:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
      });
  };

  const handleViewData = (id) => {
    localStorage.setItem("aisid", id);
    localStorage.setItem("financialYears", JSON.stringify(financialYears));

    history.push(`/aisviewdata/${id}`);
  };

  const tableStyle = { width: "100%", borderCollapse: "collapse" };
  const headerStyle = {
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    padding: "10px",
  };
  const rowStyle = { border: "1px solid #ddd", padding: "8px" };

  return (
    <div>
  
<div style={{ display: "flex", alignItems: "center" }}>
  <div style={{ display: "flex", flexDirection: "column", minWidth: "200px", marginRight: "10px" }}>
    <label
      htmlFor="financial-year-select"
      style={{ marginBottom: "5px" }} // Adjust label spacing
    >
      Select Financial Year
    </label>
    <select
      id="financial-year-select"
      value={selectedFinancialYear}
      onChange={handleFinancialYearChange}
      style={{
        width: "100%",
        height: "40px",
        textAlign: "center",
         backgroundColor: "white"
      }}
    >
      
      {financialYears.length > 0 ? (
        financialYears.map((finYear) => (
          <option key={finYear} value={finYear} style={{ textAlign: "center" , backgroundColor: "white"}}>
            {finYear}
          </option>
        ))
      ) : (
        <option value="" disabled>
          {/* <em>No Financial Years Available</em> */}
        </option>
      )}
    </select>
  </div>

  <Button
    type="button"
    onClick={handleSubmitEvent}
    variant="contained"
    color="primary"
    disabled={loading} 
    style={{
      marginLeft: "10px",
      marginTop: "24px", 
      height: "40px",
      backgroundColor: "#003366", 
      color: loading ? "#ffffff" : "#ffffff", 
      opacity: loading ? 1 : 1, 
    }}
  >
   {loading ? "Submitting..." : "Submit"}
  </Button>
</div>




      <BoxWrapper
        boxTitle="AIS Dashboard"
        // enableSearchInput={true}
        // searchInputPlaceholder="Search..."
        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={() => { fetchLogs()}}
      >
       

        <div className="responsive-table">
          <table className="custome-table" style={tableStyle}>
            <thead>
              <tr>
                <th>Financial Year</th>
                <th>Recon Date Time</th>
                <th>Create Date</th>
                <th>Modify Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading… Please wait
                  </td>
                </tr>
              ) : apiData.length === 0 ? (
                <tr>
                  <td colSpan={4} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                apiData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.financial_year}</td>
                    <td>{item.recon_date_time}</td>
                    <td>{item.create_date}</td>
                    <td>{item.modify_date}</td>
                    <td>{item.status}</td>
                    <td>
                      <VisibilityIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleViewData(item.id)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </div>
  );
}

export default AisDashboard;
