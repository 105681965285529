import React, { useState, useEffect } from "react";
import { Button, TextField, Grid } from "@mui/material";
import gststyle from "./Gst2aRepository.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import DownloadIcon from "@mui/icons-material/Download";

import { ValidatorForm } from "react-material-ui-form-validator";
import axios from "../../../../axios-order";
import Switch from '@mui/material/Switch';
// import DatePicker from "../../../../components/ui/DatePicker";
import DatePicker from './DatePicker'
import moment from "moment";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL;



export default function Gst2aRepository() {
  const [openAndCloseModel, setOpenAndCloseModel] = useState(false);
  const [next, setNext] = useState(false)

  const [fileName, setFileName] = useState("");
  const [fileDataToUpload, setFileDataToUpload] = useState("");
  const [trigarCircularProgress, setTrigarCircularProgress] = useState(false);

  //...............For Error Message..........................
  const [colorForErrorMessage, setColorForErrorMessage] = useState("red");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisibilityStyle, setErrorMessageVisibilityStyle] =
    useState("hidden");
  const [errorMessageDisplayStyle, setErrorMessageDisplayStyle] =
    useState("none");

  //.........Record From Get Api.................
  const [recordFromApi, setRecordFromApi] = useState([]);
  const [recordFromApiForSearch, setRecordFromApiForSearch] = useState([]);

  const [loaderForTable, setLoaderForTable] = useState(false);
  const [loaderForFileUpload, setLoaderForFileUpload] = useState(false);

    //This is for Gst Auto Download
    const [fromMonthYearDisplay, setFromMonthYearDisplay] = useState('')
    const [fromToYearDisplay,setFromToYearDisplay] = useState('')
    const [responseMessage, setResponseMessage] = useState("");

//Gstr Status code 

    const [status, setStatus] = useState(false);
    const [data, setData] = useState([]);
  
    const [errorMessages, setErrorMessages] = useState('');
    const [showBoxWrapper, setShowBoxWrapper] = useState(false);
    const [responseData, setResponseData] = useState("");
      const [displayResponse, setDisplayResponse] = useState(false);  
      const [taskId, setTaskId] = useState('');
      const [isSubmitting, setIsSubmitting] = useState(false);

      //SnackBar
      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarSeverity, setSnackbarSeverity] = useState("success");  
const [message, setMessage] = useState('');
const [showSuccessOnly, setShowSuccessOnly] = useState(false);
        // Function to open Snackbar
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);


    // setSnackbarOpen(false);
  };

  // Function to close Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarSeverity("success");
    setResponseMessage("");

  };

  const handleIconClick = (msg) => {
    setMessage(msg);
    setSnackbarOpen(true);
  
  };

      useEffect(() => {
        // Call the additional API only when taskId is available
        if (taskId) {
          const additionalApiUrl = `/autodownloadapi/${taskId}`;
          axios.get(additionalApiUrl)
            .then(response => {
              
              // Process the additional API response as needed
            })
            .catch(error => {
              console.error('Error calling additional API:', error);
            });
        }
      }, [taskId]);

    


  const handleButtonClick = () => {
    setShowBoxWrapper(!showBoxWrapper);
  };

  
 
      const handleDownload = (gst_task_id, business_entity_id) => {
        
        const url = `${apiUrl}/gstDownload/gstrtaskdownload/`;
          const requestData = new FormData();
          requestData.append('task_id', gst_task_id);
          requestData.append('entity_id', business_entity_id);
      
        axios.post(url, requestData)
          .then(response => {
            // console.log('API response:', response.data);
            setResponseData(response.data)
            setDisplayResponse(true);
            const autoTaskId = extractAutoTaskId(response.data);
        if (autoTaskId) {
          setTaskId(autoTaskId);
        }

            setTimeout(() => {
              setResponseData('');
              setDisplayResponse(false);
            }, 10000); // 5 seconds in milliseconds
          })
          
            // Process the response data as needed
         
          .catch(error => {
            console.error('Error sending data:', error);
           
          });
      };
      const extractAutoTaskId = (response) => {
        const autoTaskIdPattern = /Auto Task id :(\d+)/;
        const match = response.match(autoTaskIdPattern);
        return match ? match[1] : null;
      };
      

      

 
      
      
        
     
    

   

        
          
    const business_entity_id = localStorage.getItem('business_entity_id');


    
    //Gststatus code end


  const handleChange = (event) => {
    setNext(event.target.checked);
  };

    //functionFromMonth and DatePicker
    const inputHandlerFromPicker = (v) => {
      setFromMonthYearDisplay(v)
      const formattedDate = moment(v).format("MMYYYY");
      setFromMonthYearDisplay(formattedDate);

      inputHandlerToPicker(v)
    }

     console.log(fromMonthYearDisplay)


        //Function for the Date and Month Picker
    const  inputHandlerToPicker = (v) => {
      setFromToYearDisplay(v)
      const formatDate = moment(v).format("MMYYYY")
      setFromToYearDisplay(formatDate)
    }


    
    useEffect(() => {
       
      const timer = setTimeout(() => {
        setResponseMessage(""); 
      }, 7000);
  
     
      return () => clearTimeout(timer);
    }, [responseMessage]);
    const handleGSTR1Download = async () => {
      setIsSubmitting(true); // Disable the button

      const url = `${apiUrl}/gstDownload/gstr2adownload/`;
      const entity_id = localStorage.getItem('business_entity_id');
      const fromRetPeriod = fromMonthYearDisplay ? moment(fromMonthYearDisplay).format('MMYYYY') : '';
      const toRetPeriod = fromToYearDisplay ? moment(fromToYearDisplay).format('MMYYYY') : '';
      
      const formData = new FormData();
      formData.append('entity_id', entity_id);
      formData.append('fromRetPeriod', fromMonthYearDisplay);
      formData.append('toRetPeriod', fromToYearDisplay);

      try {
        const response = await axios.post(url, formData);
        const responseData = response.data;
        // console.log("responseData: " + JSON.stringify(responseData));
        
        if (responseData.message_type && responseData.message) {
          handleSnackbarOpen(
              responseData.message,
              responseData.message_type
            );
          } 
        else {
          handleSnackbarOpen('An unexpected response was received.', 'error');
        }
      } catch (error) {
          console.log(error);
          handleSnackbarOpen('Unexpected Error.', 'error');
          // Handle any errors that occurred during the request
        } finally {
        setIsSubmitting(false); // Re-enable the button
      }
    };    
      
  
  //...........Open And Close Event For Model............
  const handleOpenAndClose = () => {
    setOpenAndCloseModel(!openAndCloseModel);
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setErrorMessage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
  };

  const fileUploadCancelButtonHandler = () => {
    setTrigarCircularProgress(false);
    setColorForErrorMessage("red");
    setErrorMessage("");
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setFileDataToUpload("");
    setFileName("");
    setLoaderForFileUpload(false);
  };

  //......Handle Search....................
  const handleSearch = (e) => {
    let Search = e.target.value;
    const searchName = recordFromApiForSearch.filter((v) => {
      return (
        String(v.gstr_upload_file_type)
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0 ||
        String(v.financial_year).toLowerCase().indexOf(Search.toLowerCase()) >=
        0 ||
        String(v.generation_date).toLowerCase().indexOf(Search.toLowerCase()) >=
        0 ||
        String(v.upload_date).toLowerCase().indexOf(Search.toLowerCase()) >=
        0 ||
        String(moment(v.tax_period, "MMYYYY").format("MMMM-YYYY"))
          .toLowerCase()
          .indexOf(Search.toLowerCase()) >= 0
      );
    });
    setRecordFromApi(searchName);
  };

  //........For Succesfull Message..................
  const functionForSuccesfullMessage = () => {
    setColorForErrorMessage("green");
    setErrorMessageVisibilityStyle("visible");
    setErrorMessageDisplayStyle("block");
    // setErrorMessage("Data Fetched Successfully");
    // setTimeout(() => handleOpenAndClose(), 3000);
  };

  //............File Upload Data Saving in useState................
  const handleFileUpload = (e) => {
    setErrorMessageVisibilityStyle("hidden");
    setErrorMessageDisplayStyle("none");
    setErrorMessage("");
    if (e.target.files.length) {
      const file_data = e.target.files[0];
      let fileName = file_data.name;
      setFileName(fileName);
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      reader.onload = function () {
        let file_value = reader.result;
        setFileDataToUpload(file_value);
      };
      e.target.value = null;
    }
  };

  //....................Submit Event For File Upload in axios...............
  const handleSubmitEvent = (e) => {
    if (fileDataToUpload === "") {
      setErrorMessageVisibilityStyle("visible");
      setErrorMessageDisplayStyle("block");
      setErrorMessage("Please Upload The File!");
      setLoaderForFileUpload(false);
      window.setTimeout(() => {
        setErrorMessageVisibilityStyle("visible")
      }, 2000)
    } else {
      setTrigarCircularProgress(true);
      // e.preventDefault();
      setLoaderForFileUpload(true);
      axios
        .post(`/gst2a_recon_upload`, {
          file: fileDataToUpload,
          gstin: localStorage.getItem("gst"),
        })
        .then((res) => {
          if (res.data.status === false) {
            setTrigarCircularProgress(false);
            setErrorMessageVisibilityStyle("visible");
            setErrorMessageDisplayStyle("block");
            setErrorMessage(res.data.message);
            setLoaderForFileUpload(false);
          }
          if (res.data.status === true) {
            setTrigarCircularProgress(false);
            setErrorMessage(res.data.message);
            functionForSuccesfullMessage();
            getApiForGst2aRepository();
            setLoaderForFileUpload(false);

         
            resetFileUpload();
          }
        })
        .catch((error) => {
          setErrorMessageVisibilityStyle("visible");
          setErrorMessageDisplayStyle("block");
          setErrorMessage(error.response.data.message);
          setLoaderForFileUpload(false);
          resetFileUpload();
        });
    }
  };

  const resetFileUpload = () => {
    setFileDataToUpload(null);
    setFileName(""); 
  };
  //.......................Get Api For Gst................
  const getApiForGst2aRepository = () => {
    setLoaderForTable(true);
    axios
      .post(`/get_gst2a_repo`, { gst: localStorage.getItem("gst") })
      .then((res) => {
        if (res.data.status === false) {
          setLoaderForTable(false);
        } else {
          setLoaderForTable(false);
          setRecordFromApi(res.data.record);
          setRecordFromApiForSearch(res.data.record);
        }
      })
      .catch((error) => {
        setLoaderForTable(false);
        setErrorMessage("Error Fetching Data")

      });
  };

  useEffect(() => {
    getApiForGst2aRepository();
  }, []);

  return (
    <React.Fragment>
      <ToggleSwitch
        onChange={handleOpenAndClose}
        label="Upload GSTR-2A Return  "
      />
      
       <div className="container">
        <div>
          <input
            type="checkbox"
            className="toggle-switch"
            id="default1"
            onChange={handleChange}
            checked={next}
          />
          <label for="default1"></label>
        </div>
       <p>GSTR-2A Auto Download</p>
      </div>

      {next ? (
      <BoxWrapper boxTitle="GSTR-2A Auto Download">

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {}

        <ValidatorForm>
        <Grid container spacing={3}>
        <Grid item sm={5} >
        <label style={{ color: 'black', width: '420px' }}>
                Please Select FromMonth and Year <span style={{ color: 'red' }}>*</span>:
              </label>
              <DatePicker
                views={['year', 'month']}
                placeholder="From Year to Month"
                format="MM/yyyy"
                value={fromMonthYearDisplay ?? ""}

                onChange={(v)=>inputHandlerFromPicker(v)}

                />
            
        </Grid>
        <Grid item sm={5} >
           <label style={{ color: 'black', width: '420px' }}>
                Please Select ToMonth and Year <span style={{ color: 'red'  }}>*</span>:
              </label>
              <DatePicker
                      views={['year', 'month']}
                      placeholder="From Year to Month"
                      format="MM/yyyy"
                      value={fromToYearDisplay ?? ""}
                      onChange={(v)=>inputHandlerToPicker(v)}
                      disabled
               />
           </Grid>
           <Grid item sm={1} style={{ marginTop: "30px" }}>
              {" "}
              {/* <button
                className="button-primary"
                type="submit"
                style={{ width: "100%", padding: "8px" }}
                 onClick={handleGSTR1Download}
              >
                Submit
              </button> */}
                <button
            className={`button-primary ${isSubmitting || !fromMonthYearDisplay || !fromToYearDisplay ? 'disabled' : ''}`}
            type="button" // Change the type to "button" to prevent form submission
            style={{ width: '100%', padding: '8px' }}
            disabled={isSubmitting || !fromMonthYearDisplay || !fromToYearDisplay}
            onClick={handleGSTR1Download}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </Grid>


        </Grid>

        </ValidatorForm>
     



         
      </div>
      {/* <p style={{ color: "red" }}>{errorMessage}</p> */}
    </BoxWrapper>
      ) : (
        <div></div>
      )}



      {openAndCloseModel ? (
        <>
          <BoxWrapper boxTitle="Upload GSTR-2A Return">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <label className="button-primary" style={{ cursor: "pointer" }}>
                Upload
                <input
                  type="file"
                  accept=".xlsx,.json,.zip"
                  onChange={handleFileUpload}
                  hidden
                />
              </label>
              <div>
                <TextField
                  value={fileName}
                  variant="standard"
                  style={{ width: "350px", paddingLeft: "10px" }}
                  placeholder="No Data Have Uploaded"
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div>
                {loaderForFileUpload ? (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Uploading...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button-primary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleSubmitEvent}
                  >
                    Submit
                  </button>
                )}

                <button
                  type="button"
                  className="button-outlined-secondary"
                  onClick={fileUploadCancelButtonHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
            <p style={{ color: "red" }}>{errorMessage}</p>
          </BoxWrapper>
        </>
      ) : (
        ""
      )}

      {/*..................Table.......................*/}

      <BoxWrapper
        boxTitle="GSTR-2A Repository"
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearch}

        enableAddButton={true}
        addButtonLabel="Refresh"
        addButtonHandlerFn={getApiForGst2aRepository}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
                <th>GSTR2A upload file type</th>
                <th>Tax Period </th>
                <th>Financial Year </th>
                <th>Generation Date</th>
                <th>Upload Date</th>
                <th>Status</th>
                <th>   <label>
              <input
                type="checkbox"
                checked={showSuccessOnly}
                onChange={() => setShowSuccessOnly(!showSuccessOnly)}
              />
              Show Pass Only
            </label></th>
              </tr>
            </thead>
            <tbody>
              {recordFromApi?.filter((v) => !showSuccessOnly || v.upload_status === "Pass")
              
              .map((v) => {
                const formattedTaxPeriod =
                v.tax_period && moment(v.tax_period, "MMYYYY").isValid()
                  ? moment(v.tax_period, "MMYYYY").format("MMMM-YYYY")
                  : "";
                return (
                  <tr >
                    <td>{v.gstr_upload_file_type}</td>
                    <td>
                      {/* {v.tax_period !== "None" ? moment(v.tax_period, "MMYYYY").format("MMMM-YYYY") : ""} */}
                      {formattedTaxPeriod}

                    </td>
                    <td>{v.financial_year !== "None" ? v.financial_year : ""}</td>
                    {/* <td>{v.generation_date}</td> */}
                    <td>{v.generation_date ? moment(v.generation_date, "YYYY-MM-DD").format("DD-MM-YYYY") : ""}</td>
                    <td>{v.upload_date}</td>
                    <td>{v.upload_status}</td>
                    <td>
  {/* {v.upload_status && v.upload_status.includes("Fail") ? null : (

    <VisibilityOutlinedIcon className="action-icon-button showIcon" onClick={() => handleSnackbarOpen("Data Uploaded", "success")} />
  )} */}
   {v.upload_status === 'Fail' ? (
            <div>
                        <VisibilityOutlinedIcon
                className="action-icon-button showIcon failIcon" style={{ color: 'red' }}
                onClick={() => handleIconClick(v.upload_fail_reason)} // Corrected this line
              />
                      </div>
                    ) : (
            <div>
              <VisibilityOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() => handleIconClick('Data Uploaded')}
              />
            </div>
          )}
</td>

{/*                   
                    <td>
                      <VisibilityOutlinedIcon className="action-icon-button showIcon" onClick={() => alert("Data Uploaded")} />
                    </td> */}
        
                  </tr>
                );
              })}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={7}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : recordFromApi?.length === 0 ? (
                <tr>
                  <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>


      {/* <Switch
      checked={next}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />  */}
 
     

    <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{maxWidth: '25%'}}
    >
      <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}
          {message}
        </MuiAlert>
        </Snackbar>

    {responseData && (
      <div>
        <p style={{fontSize:'20px',color:'green'}}>DOWNLOADED GSTR DATA <br /></p>
        <p>{responseData}</p>
      </div>
    )}
     

    </React.Fragment>
  );
}
