import React from "react";
import "../TDSRecon.css";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import DashboardBarChart from "./dashboardBarChart";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function SYSection(props) {
  const { sectionData } = props;

  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  const dataForChart = [];
  let obj = {
    name: "Quarter 1",
    data: [],
  };

  let obj2 = {
    name: "Quarter 2",
    data: [],
  };

  let obj3 = {
    name: "Quarter 3",
    data: [],
  };
  let obj4 = {
    name: "Quarter 4",
    data: [],
  };
  let obj5 = {
    name: "Grand Total",
    data: [],
  };

  sectionData.map((item) => {
    const { quarter1, quarter2, quarter3, quarter4, grand_total } = item;
    obj.data.push(quarter1);
    obj2.data.push(quarter2);
    obj3.data.push(quarter3);
    obj4.data.push(quarter4);
    obj5.data.push(grand_total);
  });

  dataForChart.push(obj);
  dataForChart.push(obj2);
  dataForChart.push(obj3);
  dataForChart.push(obj4);
  // dataForChart.push(obj5)

  return (
    <>
      <BoxWrapper boxTitle="TDS Section Wise Report - Based on 26AS">
        <div>
          <DashboardBarChart
            chartTitle=""
            chartCategories={sectionData.map((item) => item.section)}
            yAxisTitle="Amount"
            chartData={dataForChart}
          />
        </div>
        <div>
          <table className="custome-table">
            <thead>
              <tr>
                <th>Section</th>
                <th>Quarter 1</th>
                <th>Quarter 2</th>
                <th>Quarter 3</th>
                <th>Quarter 4</th>
                <th>Grand Total</th>
              </tr>
            </thead>
            <tbody>
              {sectionData.map((item) => {
                return (
                  <tr>
                    <td>{item.section}</td>
                    <td>{indianRupeesFormat(item.quarter1)}</td>
                    <td>{indianRupeesFormat(item.quarter2)}</td>
                    <td>{indianRupeesFormat(item.quarter3)}</td>
                    <td>{indianRupeesFormat(item.quarter4)}</td>
                    <td>{indianRupeesFormat(item.grand_total)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </BoxWrapper>
    </>
  );
}

export default SYSection;
