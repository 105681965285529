import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  TextField,
  InputAdornment,
  withStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from '@mui/material/Tooltip';

//  import "../../HOC/layout/layout.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#c4d0d9",
    padding: "7px 0px 7px 20px",
    boxShadow: "1px 0px 2px #000",
 
  },
  title: {
    padding: "4px 20px 4px 0px",
    fontSize: "18px",
    
    fontFamily: "Dosis",
    fontWeight: 600,
    
  },
  cardContent: {
    // paddingBottom: "0px",
  },
}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "7px 5px",
    },
    "& label.Mui-focused": {
      color: "#666666",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#737373",
      },
      "&:hover fieldset": {
        borderColor: "#595959",
      },
    },
  },
})(TextField);

function BoxWrapper(props) {
  const classes = useStyles();
  const {
    boxTitle,
    children,

    cutIcon,
    closeBoxWapper,

    zohoEffitracEnable,
    zohoEffitracFn,
    importToCwsEnableForZoho,
    importToCwsForZoho,

    enableImportToEffTallybutton,
    importToEffiTallyFn,
    colorForMessage,
    ErrorIcon,
    // back button
    backButtonForZohoEnable,
    backButton,
    // export to excel
    exportToExcelEnable,
    exportToExcelFn,
    // raw export button
    rawExportToExcelEnable,
    rawExportToExcelFn,
    // Dropdown
    enableDropdown,
    dropdownHandlerFn,
    dropdownOptions,
    dropdownValue,

    // search textfield
    enableSearchInput,
    searchInputPlaceholder,
    searchInputOnchange,
    // add button
    enableAddButton,
    addButtonLabel,
    addButtonHandlerFn,
    enableAddButton2,
    addButtonLabel2,
    addButtonHandlerFn2,
    enableAddButton3,
    addButtonLabel3,
    addButtonHandlerFn3,
    enableAddButton4,
    addButtonLabel4,

    addButtonHandlerFn4,
    //Icons 

    enableIcon1,
    enableIcon2,
    enableIcon3,
    enableIcon1Value,
    enableIcon2Value,
    enableIcon3Value,
    handleIcon1ClickFunction,
    handleIcon2ClickFunction,
    handleIcon3ClickFunction

  
  } = props;
  return (
    <div style={{ marginTop: "20px", boxShadow: "1px 0px 4px #ddd" }}>
     <Card>
      <CardHeader
        classes={{ root: classes.root, title: classes.title }}
        title={boxTitle}
        action={
          <>
            <div
              style={{
                marginTop: "10px",
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              >
        {/* SEARCH INPUT FIELD */}
        {enableSearchInput && (
          <CssTextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder={searchInputPlaceholder}
            onChange={searchInputOnchange}
            style={{
              padding: "0px",
              background: "#fff",
              borderRadius: "7px",
              marginRight: "10px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#ddd" }} />
                </InputAdornment>
              ),
            }}
          />
        )}

        {/* BACK BUTTON FOR ZOHO */}
        {backButtonForZohoEnable && (
          <button
            onClick={backButton}
            className="button-outlined-primary"
          >
            Back
          </button>
        )}


        {/* {Fourth Button} */}
        { enableAddButton4 && (
          <button onClick={addButtonHandlerFn4}  className="button-outlined-primary" >{addButtonLabel4}</button>
        )    
        } 

        {/* Third Add Button */}
        {enableAddButton3 && (
            <button
              onClick={addButtonHandlerFn3}
              className="button-outlined-primary"
            >
                {addButtonLabel3}
            </button>
        )}

        {/* enable Icon 1 */}

        {
            enableIcon1&& 
            <IconButton size="small" style={{cursor:'pointer',marginRight:'10px',marginLeft:'10px'}} onClick={handleIcon1ClickFunction}>
              {enableIcon1Value}
            </IconButton> 
        }

        {/* ZOHO EFFITRAC BUTTON */}
        {zohoEffitracEnable && (
          <button
            onClick={zohoEffitracFn}
            className="button-outlined-primary"
          >
            Zoho
          </button>
        )}
        {/* Second Add Button */}

        {enableAddButton2 && (
          <button
            onClick={addButtonHandlerFn2}
            className="button-outlined-primary"
          >
            {addButtonLabel2}
          </button>
        )}

        {/* IMPORT TO ZOHO */}
        {importToCwsEnableForZoho && (
          <button
            onClick={importToCwsForZoho}
            className="button-outlined-primary"
          >
            Import To Cws
          </button>
        )}

        {/* IMPORT TO EFFITRAC BUTTON */}
        {enableImportToEffTallybutton && (
          <button
            onClick={importToEffiTallyFn}
            className="button-outlined-primary"
          >
            import effitrac/tally
          </button>
        )}

        {/* Add Details Button */}
        {enableAddButton && (
          <button
            onClick={addButtonHandlerFn}
            className="button-outlined-primary"
          >
            {addButtonLabel}
          </button>
        )}
        
        {/* Enable Icon 2 */}
        {
            enableIcon2&&
            <IconButton size="small" style={{cursor:'pointer',marginLeft:'10px'}} onClick={handleIcon2ClickFunction}>
                {enableIcon2Value}      
            </IconButton>  
        }

        {/* Enable Icon 3 */}
        {
            enableIcon3&&
            <IconButton size="small" style={{cursor:'pointer',marginLeft:'10px'}} onClick={handleIcon3ClickFunction} >
            {enableIcon3Value}
            </IconButton>
        }
        {/* RAW EXPORT TO EXCEL */}
        {rawExportToExcelEnable && (
          <button
            onClick={rawExportToExcelFn}
            className="button-outlined-primary"
          >
            raw export <FileDownloadIcon style={{ fontSize: "20px" }} />
          </button>
        )}

        {/* NORMAL EXPORT TO EXCEL */}
        {exportToExcelEnable && (
          <button
            className="button-outlined-primary"
            onClick={exportToExcelFn}
          >
            export <FileDownloadIcon style={{ fontSize: "20px" }} />
          </button>
        )}

        {/* DROPDOWN  */}
        {enableDropdown && (
          <TextField
            id="outlined-select-currency"
            select
            label={props.dropdownLabel ?? ""}
            value={dropdownValue}
            onChange={dropdownHandlerFn}
            size="small"
            style={{ width: "100px" }}
          >
            {dropdownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}

        {/* CLOSE ICON */}
        {cutIcon && (
          <button
            className="close-icon-button"
            onClick={closeBoxWapper}
          >
            <CloseIcon style={{ color: "red" }} />
          </button>
        )}
              </div>
            </>
          }
        ></CardHeader>
        <CardContent
          className={classes.cardContent}
          style={{ paddingBottom: "15px" }}
        >
          <div style={{ color: colorForMessage ? colorForMessage : "white" }}>
            {ErrorIcon && ErrorIcon} {children}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default BoxWrapper;
