import React, { Component, Fragment } from "react";
import axios from "../../../../../axios-order";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
} from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Summary from "./summary";
import moment from "moment";
import MatchedInvoice from "./MatchedInvoice";
import MismatchedInvoice from "./MisMatched";
import MissingInAccount from "./MissingInAccount";
import MissingInGSTR from "./MissingInGSTR";
import VendorInvoiceSummary from "./VendorInvoiceSummary";
import AllInvoiceRecon from "./AllInvoiceRecon";
import ProbableMatchedInvoice from "./ProbableMatched";
import CryptoJS from "crypto-js";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const styles = function (theme) {
  return {
    root: {
      width: "400px",
    },
    tab: {
      [theme.breakpoints.up("md")]: {
        minWidth: "auto",
      },
    },
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Showdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allGST2aReconReportData: {},
      tabValue: 0,
      loading: true,
      snackbarOpen: false,
      snackbarSeverity: "",
      message: "",
    };

    const decrypt = (data) => {
      return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    };
    let activeUrl = window.location.href;
    let id = activeUrl.split("/");

    axios
      .get(`/gstr2a/summary/${decrypt(id[4])}`)
      .then((res) => {
        console.log(res.data);
        if (typeof res.data === "undefined") {
        } else {
          if (res.data.status) {
            this.setState({
              allGST2aReconReportData: res.data.record,
              loading: false,
            });
          } else {
            this.handleSnackbarOpen(res.data.message, "error");
            this.setState({
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  handleSnackbarOpen = (message, severity) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      message: message,
    });
  };

  // Function to close Snackbar
  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarSeverity: "success",
      message: "",
    });
  };

  handleIconClick = (msg) => {
    this.setState({
      message: msg,
      snackbarOpen: true,
    });
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `gst_recon2a_export.xlsx`;
    axios
      .post(`/gstr2a/gstr2a_complete_summary/download`, {
        record: this.state.allGST2aReconReportData,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleTabChangeHandler = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Container>
          {this.state.loading === true && (
            <div style={{ textAlign: "center", marginTop: 50 }}>
              <CircularProgress />
              <br></br>
              Loading,Please wait...
            </div>
          )}

          {this.state.loading === false && (
            <>
              {/* ---sub header--- */}
              <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs={4}>
                  <div>
                    Report Dated :{" "}
                    {Object.keys(this.state.allGST2aReconReportData).length > 0 ?
                      moment(
                          this.state.allGST2aReconReportData.entity_details
                            .Reconciliation_Date
                        ).format("DD-MM-YYYY")
                      : ""}
                    <br></br>
                    Threshold Used:{" "}
                    {Object.keys(this.state.allGST2aReconReportData).length > 0 ?
                      this.state.allGST2aReconReportData.entity_details
                          .Threshold_Value
                      : ""}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "18px" }}>
                      {" "}
                      GSTR2A Reconciliation Report
                    </p>

                    <button
                      className="button-outlined-primary"
                      onClick={this.downloadToExcel}
                    >
                      Export to Excel
                    </button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ float: "right" }}>
                    Report Period :{" "}
                    {Object.keys(this.state.allGST2aReconReportData).length > 0 ?
                       this.state.allGST2aReconReportData.entity_details.Period
                      : ""}
                  </div>
                </Grid>
              </Grid>

              {/* tab panel */}
              <div className={classes.TabPanelSection}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChangeHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#fff", color: "#000" }}
                      label="Summary "
                      {...a11yProps(0)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#28a745", color: "#000" }}
                      label="Matched Invoices"
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "red", color: "#fff" }}
                      label="Mismatch Invoices"
                      {...a11yProps(2)}
                    />

                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ddd", color: "#000" }}
                      label="Probable Match"
                      {...a11yProps(3)}
                    />

                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffad33", color: "#000" }}
                      label="Missing in Accounts"
                      {...a11yProps(4)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffbf80", color: "#000" }}
                      label="Missing in GSTR2A"
                      {...a11yProps(5)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#b3d9ff", color: "#000" }}
                      label="Vendor wise Summary"
                      {...a11yProps(6)}
                    />
                    <Tab
                      classes={{ root: classes.tab }}
                      style={{ background: "#ffff4d", color: "#000" }}
                      label="All Invoice(s) Recon"
                      {...a11yProps(7)}
                    />
                  </Tabs>
                </AppBar>

                {/*  Summary Dashboard */}
                <TabPanel value={this.state.tabValue} index={0}>
                  <BoxWrapper boxTitle="Summary">
                    <Summary
                      SummaryData={
                        Object.keys(this.state.allGST2aReconReportData).length > 0 ?
                           this.state.allGST2aReconReportData
                              .gstSummaryDashBoard
                          : []
                      }
                    />
                  </BoxWrapper>
                </TabPanel>

                {/* Matched*/}
                <TabPanel value={this.state.tabValue} index={1}>
                  <MatchedInvoice
                    matchedInvoiceData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.matched
                        : []
                    }
                  />
                </TabPanel>


                {/* .......Mis-Matched....... */}
                <TabPanel value={this.state.tabValue} index={2}>
                  <MismatchedInvoice
                    mismatchedInvoiceData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.missMatched
                        : []
                    }
                  />
                </TabPanel>

                {/* ........Probable Matched........ */}
                <TabPanel value={this.state.tabValue} index={3}>
                  <ProbableMatchedInvoice
                    probableMatchedInvoiceData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.ProbableMatching
                        : []
                    }
                  />
                </TabPanel>

                {/* .........Missing in Account......... */}
                <TabPanel value={this.state.tabValue} index={4}>
                  <MissingInAccount
                    missingInAccountData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.missingInAccounts
                        : []
                    }
                  />
                </TabPanel>

                {/* Missing in GSTR1 */}
                <TabPanel value={this.state.tabValue} index={5}>
                  <MissingInGSTR
                    missingInGSTRData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.missingInGstr
                        : []
                    }
                  />
                </TabPanel>

                {/* Vendor Invoice Summary */}
                <TabPanel value={this.state.tabValue} index={6}>
                  <VendorInvoiceSummary
                    VendorInvoiceSummaryData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData
                            .VendorInvoiceSummary
                        : []
                    }
                  />
                </TabPanel>

                {/* All invoice Recon */}
                <TabPanel value={this.state.tabValue} index={7}>
                  <AllInvoiceRecon
                    AllInvoiceReconData={
                      Object.keys(this.state.allGST2aReconReportData).length > 0 ? this.state.allGST2aReconReportData.allInvoiceRecon
                        : []
                    }
                  />
                </TabPanel>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={this.state.snackbarOpen}
                  autoHideDuration={5000} // Adjust as needed
                  onClose={this.handleSnackbarClose}
                  style={{ maxWidth: "25%" }}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackbarClose}
                    severity={this.state.snackbarSeverity} // Use the state variable to determine severity
                  >
                   
                    {this.state.message}
                  </MuiAlert>
                </Snackbar>

                {/* <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={this.state.snackbarOpen}
                  autoHideDuration={6000}
                  onClose={this.handleSnackbarClose}
                >
                  <MuiAlert
                    onClose={this.handleSnackbarClose}
                    severity={this.state.snackbarSeverity}
                  >
                    {this.state.message}
                  </MuiAlert>
                </Snackbar> */}
              </div>
            </>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

Showdetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Showdetails);
