// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_FooterForHome__28M4u {\n  left: 32%;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 2rem;\n  transition: all 400ms ease;\n  background: #fff;\n}\n.footer_FooterForGlobal__3HDmo {\n  left: 0;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 2rem;\n  transition: all 400ms ease;\n  background: #fff;\n  z-index: 3;\n}\n", "",{"version":3,"sources":["webpack://src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,eAAe;EACf,SAAS;EACT,QAAQ;EACR,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,OAAO;EACP,eAAe;EACf,SAAS;EACT,QAAQ;EACR,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".FooterForHome {\n  left: 32%;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 2rem;\n  transition: all 400ms ease;\n  background: #fff;\n}\n.FooterForGlobal {\n  left: 0;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 2rem;\n  transition: all 400ms ease;\n  background: #fff;\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FooterForHome": "footer_FooterForHome__28M4u",
	"FooterForGlobal": "footer_FooterForGlobal__3HDmo"
};
export default ___CSS_LOADER_EXPORT___;
