import React, { useState ,useEffect} from "react";
import { useHistory,useLocation } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import axios from "../../../axios-order";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import MonthDatePicker from "../../../components/ui/monthWiseDatePicker";
import BoxWrapper from "../../../components/ui/boxWrapper";
import Modal from "react-bootstrap/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export default function VendorCancellation() {
  const [handleFromDate, setHandleFromDate] = useState("");
  const [handleToDate, setHandleToDate] = useState("");
  const [handleFromDateToDisplay, setHandleFromDateToDisplay] = useState(null);
  const [handleToDateToDisplay, setHandleToDateToDisplay] = useState(null);
  const [data, setData] = useState([]);
  const [isExportButtonVisible, setIsExportButtonVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trigarLoader, setTrigarLoader] = useState(false);
  const [trigarForErrorMessage, setTrigarForErrorMessage] = useState(false);
  const [triggerButton, setTriggerButton] = useState(true);
  const [triggerModelFromToDate, setTriggerModelFromToDate] = useState(false);
  const [loaderSubmitButton, setLoaderSubmitButton] = useState(false);
  const [datesSubmitted, setDatesSubmitted] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  let history = useHistory();
  const location = useLocation();

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const fetchLogs = () => {
    const business_entity_id = localStorage.getItem("business_entity_id"); 
    setLoading(true);
    axios
      .get(`/blocked_gstin/get_logs/${business_entity_id}`)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.records || []);
          setIsExportButtonVisible(true);
        } else {
          setSnackbarMessage(res.data.message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsExportButtonVisible(false);
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An unexpected error occurred";
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLogs(); // Load logs when the component mounts
  }, []);
  
  const functionToHandleFromDate = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    setHandleFromDate(`${(pad + mm).slice(-2)}${yy}`);
    setHandleFromDateToDisplay(v);
  };

  const functionToHandleToDate = (v) => {
    const pad = "00";
    const year = v.getFullYear().toString();
    const month = (v.getMonth() + 1).toString();
    setHandleToDate(`${(pad + month).slice(-2)}${year}`);
    setHandleToDateToDisplay(v);
  };

  const handleOpenAndCloseModel = () => {
    setHandleFromDateToDisplay(null);
    setHandleToDateToDisplay(null);
    setTriggerButton(true);
    setTriggerModelFromToDate(false);
    setTrigarLoader(false);
    setTrigarForErrorMessage(false);
  };

 
  const onSubmitHandlerForData = () => {
    setDatesSubmitted(true);
    setLoading(true);
    setLoaderSubmitButton(true);

    // Use values from localStorage if they're not provided by the current state
    const startPeriod = handleFromDate || localStorage.getItem("start_period");
    const endPeriod = handleToDate || localStorage.getItem("end_period");

    const payLoadData = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      start_period: startPeriod,
      end_period: endPeriod,
      org_gstin: localStorage.getItem("gst"),
      vendor_gstin: localStorage.getItem("vendor_gstin"), // Use vendor GSTIN from localStorage
    };

    axios
      .post("/blocked_gstin/save_logs", payLoadData)
      .then((res) => {
        if (res.data.status) {
          setSnackbarMessage(res.data.message);
          setSnackbarSeverity("success");
          setData(res.data.records);
          setIsExportButtonVisible(true);
          fetchLogs();
        } else {
          setSnackbarMessage(res.data.message);
          setSnackbarSeverity("error");
          setData([]);
          setIsExportButtonVisible(false);
        }
        setSnackbarOpen(true);
        handleOpenAndCloseModel();
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An unexpected error occurred";
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setData([]);
        handleOpenAndCloseModel();
        setIsExportButtonVisible(false);
      })
      .finally(() => {
        setLoading(false);
        setLoaderSubmitButton(false);
      });
  };

  const handleViewData = (id) => {
    localStorage.setItem("record_id", id); 
    history.push(`/VendorViewData`);
 
  };



  const handleTDSREconciliationButtonEvent = () => {
    setTriggerModelFromToDate(true);
  };

  return (
    <React.Fragment>
      {triggerButton && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            className="button-outlined-primary"
            onClick={handleTDSREconciliationButtonEvent}
          >
            Vendor Cancellation Report
          </button>
        </div>
      )}

      <Modal
        size="lg"
        centered
        show={triggerModelFromToDate}
        onHide={handleOpenAndCloseModel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vendor Cancellation Report</Modal.Title>
        </Modal.Header>
        <ValidatorForm onSubmit={onSubmitHandlerForData}>
          <Modal.Body>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                  From Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <MonthDatePicker
                  placeholder="Month & Year"
                  validators={["required"]}
                  value={handleFromDateToDisplay}
                  onChange={(v) => functionToHandleFromDate(v)}
                />
              </Grid>

              <Grid item sm={2}>
                <p style={{ marginTop: "10px", fontSize: "16px" }}>
                  To Month<span style={{ color: "red" }}>*</span>:
                </p>
              </Grid>
              <Grid item sm={4}>
                <MonthDatePicker
                  minDateForDatepicker={handleFromDateToDisplay}
                  placeholder="Month & Year"
                  validators={["required"]}
                  value={handleToDateToDisplay}
                  onChange={(v) => functionToHandleToDate(v)}
                />
              </Grid>
            </Grid>
            {trigarLoader && (
              <div style={{ fontWeight: "600", color: "green", padding: "5px" }}>
                Please wait, recon is in progress
              </div>
            )}
            {trigarForErrorMessage && (
              <div style={{ color: "red", fontWeight: "700" }}>No Records Present!</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="button-outlined-secondary"
              type="button"
              onClick={handleOpenAndCloseModel}
            >
              Cancel
            </button>
            <LoadingButton
              type="submit"
              loading={loaderSubmitButton}
              variant="outlined"
              style={{ height: "30px", width: "90px" }}
            >
              Submit
            </LoadingButton>
          </Modal.Footer>
        </ValidatorForm>
      </Modal>

      <BoxWrapper boxTitle="Vendor Cancellation Report"
   
      >
        <div className="responsive-table">
          <table className="custome-table">
            <thead>
              <tr>
           
                <th>From Period</th>
                <th>To Period</th>
                <th>Run date Time</th>
                <th>Report status</th>
                <th>Action</th>
           
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9">Loading...</td>
                </tr>
              ) : data && data.length === 0 ? (
                <tr>
                  <td colSpan="9" style={{ color: "red" }}>No Data Found</td>
                </tr>
              ) : (
                data.map((record, index) => (
                  <tr key={index}>
                
                    <td>{record.from_tax_period}</td>
                    <td>{record.to_tax_period}</td>
                    <td>{record.run_date_time}</td>
                    <td><Chip label={record.report_status.toUpperCase()}color="success" variant="outlined"/></td>

                    <td>
                      <VisibilityIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleViewData(record.id)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
     
        <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                  
                </MuiAlert>
      </Snackbar>


    </React.Fragment>
  );
}