import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import axios from "../../../axios-order";
import BarCharts from './BarCharts';
import PieChart from './PieChartFroDamage';
import BoxWrapper from "../../../components/ui/boxWrapper";


export default function TableForDamageStock({
  data,
  dataForBarChart,
  dataForPieChart,
  postApiForRegionName,
  postApiForReportingPeriodName,
  postApiForBranchName,
}) {
  const [dataTable, setDataTable] = useState(data);
  const [searchForTable, setSearchForTable] = useState(data);
  
  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---b2bExportData

  useEffect(() => {
    setDataTable(data);
    setSearchForTable(data);
  }, [data]);

  const handleSearch = (e) => {
    let value = e.target.value;
    var search = searchForTable.filter((v) => {
      return String(v.ItemName).toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    setDataTable(search);
  };

  //.................DownloadBase For Export To Excell..............
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //.......................Export To Excel.......................
  const export_to_excel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Damage Stock Summary.xlsx`;
    axios
      .post(`/iars_damage_stocks/download`, {
        records: dataTable,
        business_entity_id: localStorage.getItem("business_entity_id"),
        regionName: postApiForRegionName,
        branchname: postApiForBranchName,
        reportingPeriod: postApiForReportingPeriodName,
      })
      .then((res) => {
        if (res.data.status === "fail") {
          alert("Unable to process download....");
        } else {
          downloadBase64File(contentType, res.data.file, filename);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataForChart = []
  Object.keys(dataForBarChart.length && dataForBarChart[0].y_axis).map(item => {
    if (item === "costValue") {
      dataForChart.push({
          name: "Cost Value",
          data: []
      }) } else {
        dataForChart.push({
          name: "MRP Value",
          data: []
      })
      }
  })

  dataForBarChart.map((item,i) => {
    dataForChart.map((v , i)=>{ 
      let key = Object.keys(dataForBarChart[i].y_axis);
      if(v.name === key[i]){
     dataForChart[i].data.push(item.y_axis[key[i]]) ;  
    } else {
      dataForChart[i].data.push(item.y_axis[key[i]]) ; 
    }
  })
}) 

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6} style={{border:"2px solid rgb(231, 222, 222)" , textAlign:"center"}}>
          <PieChart
            pieChartTitle="Physical Audit Avail Damage Qty"
            data={dataForPieChart}
        />
          </Grid>   
        <Grid item xs={6} style={{border:"2px solid rgb(231, 222, 222)" , textAlign:"center"}}>
        <BarCharts
        chartTitle="Cost Value and MRP Value"
        chartCategories={dataForBarChart.map(item => item.ItemName)}
        yAxisTitle="Rupees"
        chartData={dataForChart} 
        />
        </Grid>
      </Grid>

      <BoxWrapper
          boxTitle="Damage Stock Summary"
          exportToExcelEnable={true}
          exportToExcelFn={export_to_excel}
          enableSearchInput={true}
          searchInputPlaceholder="Search By Category Name..."
          searchInputOnchange={handleSearch}
          backButtonForZohoEnable={false}
          importToCwsEnableForZoho={false}
        >
            <>
              <div className="responsive-table">
                <table className="custome-table">
               
              <thead>
                <tr>
                  <th>Sub Category Name</th>
                  <th>Physical audit Avail Damage Qty</th>
                  <th>Cost Price</th>
                  <th>Cost Value</th>
                  <th>MRP</th>
                  <th>MRP Value</th>
                </tr>
              </thead>
              <tbody>
                {dataTable.map((v) => {
                    if(v.ItemName === "Grand Total")  
                    { return(
                      <tr style={{fontWeight:"bold" , fontSize:"15px"}}>
                      <td>{v.ItemName}</td>
                      
                      <td>{v.PhysicalauditAvailDamageQty < 0 ? 
                           <div>{v.PhysicalauditAvailDamageQty}</div> : <div>{v.PhysicalauditAvailDamageQty}</div>}</td>
                      
                      <td>{v.CostPrice < 0 ? 
                          <div>{numberFormat(v.CostPrice.toFixed(2))}</div> : <div>{numberFormat(v.CostPrice.toFixed(2))}</div>}</td>
                      
                      <td>{v.CostValue < 0 ? 
                          <div>{numberFormat(v.CostValue.toFixed(2))}</div> : <div>{numberFormat(v.CostValue.toFixed(2))}</div>}</td>
                      
                      <td>{v.MRP < 0 ? 
                          <div>{numberFormat(v.MRP.toFixed(2))}</div> : <div>{numberFormat(v.MRP.toFixed(2))}</div>}</td>
                      
                      <td>{v.MRPValue < 0 ?
                           <div>{numberFormat(v.MRPValue.toFixed(2))}</div> : <div>{numberFormat(v.MRPValue.toFixed(2))}</div>}</td>
                  </tr> )
                     } else 
                     { return(
                      <tr>
                      <td>{v.ItemName}</td>
                      
                      <td>{v.PhysicalauditAvailDamageQty < 0 ? 
                           <div>{v.PhysicalauditAvailDamageQty}</div> : <div>{v.PhysicalauditAvailDamageQty}</div>}</td>
                      
                      <td>{v.CostPrice < 0 ? 
                          <div>{numberFormat(v.CostPrice.toFixed(2))}</div> : <div>{numberFormat(v.CostPrice.toFixed(2))}</div>}</td>
                      
                      <td>{v.CostValue < 0 ? 
                          <div>{numberFormat(v.CostValue.toFixed(2))}</div> : <div>{numberFormat(v.CostValue.toFixed(2))}</div>}</td>
                      
                      <td>{v.MRP < 0 ? 
                          <div>{numberFormat(v.MRP.toFixed(2))}</div> : <div>{numberFormat(v.MRP.toFixed(2))}</div>}</td>
                      
                      <td>{v.MRPValue < 0 ?
                           <div>{numberFormat(v.MRPValue.toFixed(2))}</div> : <div>{numberFormat(v.MRPValue.toFixed(2))}</div>}</td>
                  </tr> )
                       }
                })}
                {dataTable.length === 0 && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ color: "red", textAlign: "center" }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
              
              </div>
              {/* <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              /> */}
            </>
      
        </BoxWrapper>
    </React.Fragment>
  );
}
