import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import classes from "../GST-1_Reconciliation/GSTReconciliation.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { TextValidator } from "react-material-ui-form-validator";
// import { toast,ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../../axios-order";
import "../../../../asserts/css/Onboarding.css";
import DatePicker from './DatePicker'
import './App.css'

import moment from "moment";
import InputField from "../../../../components/ui/inputField";

import Modal from "react-bootstrap/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL



const dateFormat = (value) => {
  if (!value) {
    return "";
  }
  const current_date = new Date(value);
  const yy = current_date.getFullYear().toString();
  const mm = (current_date.getMonth() + 1).toString();
  const dd = current_date.getDate().toString();
  return `${dd}/${mm}/${yy}`;
};
class GSTRepo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerName: "Botmantra",
      file_name: "",
      historyTableOriginalData: [],
      historyTable: [],
      loading: false,
      modalShow: false,
      customRoundOff: null,
      threshold_value: 0,
      roundOff: 0,
      loaderForTable: false,
      showUpload: false,
      showGSTR:false, 
      showAutoDownload: false,
      next: false,
      fromMonthYearDisplay: '',
      fromToYearDisplay: '',

      //GstStatus 
      status: false,
      data: [],
      errorMessage: '',
      showBoxWrapper: false,
      responseData: '',
      displayResponse: false,
      taskId: ''  ,
      isSubmitting: false,
      

//Snackbar
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarSeverity: "success",

   
    };
  }

  //Gststatus code start
  componentDidMount() {
    this.handleRefresh();
    this.fetchData();
  }
  fetchData = () => {
    axios
      .get(`${apiUrl}/gstDownload/gstrclientstatus/${localStorage.getItem('business_entity_id')}`)
      .then(response => {
        this.setState({ data: response.data });
        console.log(response);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

    // Function to open Snackbar
    handleSnackbarOpen = (message, severity) => {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        snackbarSeverity: severity,
      });
    };
  
    // Function to close Snackbar
    handleSnackbarClose = () => {
      this.setState({
        snackbarOpen: false,
        snackbarMessage: "",
        snackbarSeverity: "success",
      });
    };

  

  extractAutoTaskId = response => {
    const autoTaskIdPattern = /Auto Task id :(\d+)/;
    const match = response.match(autoTaskIdPattern);
    return match ? match[1] : null;
  };

  handleRefresh = () => {
    axios
      .get(`${apiUrl}/gstDownload/gstrclientstatus/${localStorage.getItem('business_entity_id')}`)
      .then(response => {
        this.setState({ data: response.data });
        console.log(response);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  

  handleClick = (first_col, second_col) => {
    axios
      .post(`${apiUrl}/table/`, { first_col, second_col })
      .then(response => {
        console.log('Data sent to backend:', response.data);
      })
      .catch(error => {
        console.error('Error sending data:', error);
      });
  };
  

  handleGSTR1Download = (event) => {
    event.preventDefault(); // Prevent default form submission
    this.setState({ isSubmitting: true }); // Disable the button

    const url = `${apiUrl}/gstDownload/gstr1download/`;
    const fromRetPeriod = this.state.fromMonthYearDisplay;
    const toRetPeriod = this.state.fromToYearDisplay;
    const entity_id = localStorage.getItem('business_entity_id');

    const formData = new FormData();
    formData.append('entity_id', entity_id);
    formData.append('fromRetPeriod', fromRetPeriod);
    formData.append('toRetPeriod', toRetPeriod);

    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        // toast.success("Now Go to the GSTR Status and check if Data is Ready!");
        // Handle the response from the backend
        const responseData = response.data;
        if (responseData.message_type && responseData.message) {
          this.handleSnackbarOpen(
              responseData.message,
              responseData.message_type
            );
          } else {
            this.handleSnackbarOpen(
              'An unexpected response was received.', 'error'
            )
          }
      })
      .catch((error) => {
        console.log(error);
        // Handle any errors that occurred during the request

        this.handleSnackbarOpen(
          "Error occurred during download. Please try again later.",
          "error"
        );
})
      .finally(() => {
        this.setState({ isSubmitting: false }); // Re-enable the button
      });
  };


  handleChange = (event) => {
    this.setState({ next: event.target.checked });
  };

  inputHandlerFromPicker = (v) => {
    const formattedDate = v ? moment(v).format('MMYYYY'):'';
    this.setState({ fromMonthYearDisplay: formattedDate });
    console.log(formattedDate)

    this.inputHandlerToPicker(v)
  };

  inputHandlerToPicker = (v) => {
    const formattedDate = v  ? moment(v).format('MMYYYY'):'';
    this.setState({ fromToYearDisplay: formattedDate });
    console.log(formattedDate)
  };
  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchAdminData = this.state.historyTableOriginalData.filter(
      (value) => {
        const query = searchInput.toLowerCase();
        return (
          String(value.filing_year).toLowerCase().indexOf(query) >= 0 ||
          String(value.filing_type).toLowerCase().indexOf(query) >= 0 ||
          String(value.created_at).toLowerCase().indexOf(query) >= 0 ||
          String(value.updated_at).toLowerCase().indexOf(query) >= 0 ||
          String(value.filing_month).toLowerCase().indexOf(query) >= 0
        );
      }
    );
    this.setState({ historyTable: searchAdminData });
  };

  // ---End---

  componentDidMount = () => {
    this.getDetails();
  };

  // File uploader for [Form 26AS]
  fileUploader = (event) => {
    if (event.target.files[0]) {
      const file_data = event.target.files[0];
      let fileName = file_data.name;
      this.setState({ file_name: fileName });
      const reader = new FileReader();
      reader.readAsDataURL(file_data);
      const dis = this;
      reader.onload = function () {
        let file_value = reader.result;
        dis.setState({ GSTJsonfFile: file_value });
      };
      document.getElementById("file_value").value = null;
    }
  };

  getDetails = () => {
    this.setState({ loaderForTable: true });
    axios
      .get(`/arc/get_gst_repo/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (typeof res.data === "undefined") {
          this.setState({ loaderForTable: false });
        } else {
          this.setState({
            loaderForTable: false,
            historyTable: res.data.data,
            historyTableOriginalData: res.data.data,
          });

        }
      })
      .catch(() => this.setState({ loaderForTable: false }));
  };

  // submit for file upload form
  onSubmitHandler = () => {
    this.setState({ loading: true, modalShow: false });
    // toast.configure();
    axios
      .post(`/arc/gst_repo`, {
        file: `${this.state.GSTJsonfFile}`,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (
          typeof res.data.error !== "undefined" ||
          typeof res.data === "undefined"
        ) {
this.handleSnackbarOpen("File processing failed.", "error");
        } else {
          // toast.success("File processing completed.", {
            //   autoClose: 5000,
            //   progress: 0,
          // });
          this.getDetails();
          this.setState({ loading: false, file_name: "" });
this.handleSnackbarOpen("File processing completed.", "success");
        }
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.message, {
          //   autoClose: 5000,
          //   progress: 0,
        // });
        axios
          .get(
            `/arc/get_gst_repo/${localStorage.getItem("business_entity_id")}`
          )
          .then((res) => {
            if (typeof res.data === "undefined") {
            } else {
              this.setState({
                historyTable: res.data.data,
                historyTableOriginalData: res.data.data,
              });
            }
          });
        this.setState({ loading: false, file_name: "" });
this.handleSnackbarOpen(
          `Error: ${error?.response?.data?.message}`,
          "error"
        );
      });
  };

  handleCloseLoader = () => {
    this.setState({ loading: false });
  };

  // ---TABLE LOGIC---
  //---History table logic---
  historyTableHeader = () => {
    const tableHeader = [
      "Financial Year",
      "Filing Month",
      "Filing Type",
      "Created Date",
      "Updated Date",
      "Status",
      "Download",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  downloadBase64File = (contentType, base64Data, fileName) => {
    console.log(contentType, base64Data, fileName);
    const linkSource = `data:${contentType};base64,${
      base64Data?.split("'")[1]
    }`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handleDownloadJson = (arg, gstr1_status, gstr1_reason) => {
    if (gstr1_status === 'Pass') {
      // If status is "Pass," download the file
    const contentType = 'application/json';
    const filename = 'GSTR_Report.json';
  
    axios
      .get(`/gstr1/download_document/${localStorage.getItem('business_entity_id')}/${arg}`)
      .then((res) => {
          // Download the file
          this.downloadBase64File(contentType, res.data.records, filename);
        })
      .catch((err) => {
          // If an error occurs during downloading, show the Snackbar with the error message
          this.setState({
            snackbarMessage: gstr1_reason, // Use the gstr1_reason value for the error message
            snackbarOpen: true,
          });
        });
    } else {
      // If status is not "Pass," show the Snackbar with the error message
      this.setState({
        snackbarMessage: gstr1_reason, // Use the gstr1_reason value for the error message
        snackbarOpen: true,
      });
}
  };

  historyTableData = () => {
    const { loaderForTable, historyTable } = this.state;
  
    if (loaderForTable) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={5}
          >
            Loading…Please wait
          </td>
        </tr>
      );
    } else if (this.state.historyTable.length >= 1) {
      return this.state.historyTable.map((tableData, index) => {
        const {
          filing_type,
          filing_year,
          filing_month,
          created_at,
          updated_at,
          gstr1_status,
          id,
        gstr1_reason,
        } = tableData; // destructuring

        // const passCell = gstr1_status === "Pass" ? "Pass" : '';
  
        return (
          <tr key={index}>
            <td>{`${filing_year}-${filing_year + 1}`}</td>
            <td style={{ textTransform: "capitalize" }}>{filing_month}</td>
            <td style={{ textTransform: "capitalize" }}>{filing_type}</td>
            <td style={{ textTransform: "capitalize" }}>{dateFormat(created_at)}</td>
            <td style={{ textTransform: "capitalize" }}>
              {updated_at ? dateFormat(updated_at) : ""}
            </td>
            <td>{gstr1_status}</td>
            <td onClick={() => this.handleDownloadJson(id, gstr1_status, gstr1_reason)}>
              <DownloadIcon
                className={`action-icon-button ${gstr1_status === 'Fail' ? 'download-fail' : 'download'}`}
                style={{ color: gstr1_status === 'Fail' ? 'red' : '' }}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colSpan={5}>
            No data found!
          </td>
        </tr>
      );
    }
  };

  newReconModalOpen = () => {
    this.setState({
      modalShow: true,
    });
  };

  newReconModalClose = () => {
    this.setState({
      modalShow: false,
      threshold_value: "",
      roundOffValue: 0,
    });
  };

  roundOffInputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === 11 && name === "threshold_value") {
      this.setState({
        customRoundOff: "",
        roundOffValue: value,
      });
    } else if (name === "customRoundOff") {
      if (
        String(value).split(".")[1] &&
        String(String(value).split(".")[1]).length > 2
      ) {
        return false;
      }

      this.setState({ threshold_value: value, customRoundOff: value });
    } else {
      this.setState({
        threshold_value: value,
        roundOffValue: value,
        customRoundOff: null,
      });
    }
  };

  render() {
    const { next, fromMonthYearDisplay, fromToYearDisplay,isSubmitting } = this.state;
    const { data, responseData, displayResponse } = this.state;
    const business_entity_id = localStorage.getItem('business_entity_id');
    return (
      <React.Fragment>
        <ToggleSwitch
          label="Upload GSTR-1 Return"
          onChange={() => this.setState({ showUpload: !this.state.showUpload })}
        />
         <div className="container">
          <div>
            <input
              type="checkbox"
              className="toggle-switch"
              id="default1"
              onChange={this.handleChange}
              checked={next}
            />
            <label htmlFor="default1"></label>
          </div>
          <p>GSTR-1 Auto Download</p>
        </div>
        {next && (
          <BoxWrapper boxTitle="GSTR-1 Auto Download">
            <div style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
              <ValidatorForm onSubmit={this.handleGSTR1Download}>
                <Grid container spacing={3}>
                  <Grid item sm={5}>
                    <label style={{ color: 'black', width: '420px' }}>
                      Please Select FromMonth and Year <span style={{ color: 'red' }}>*</span>:
                    </label>
                    <DatePicker
                      views={['year', 'month']}
                      placeholder="From Year to Month"
                      format="MM/yyyy"
                      value={fromMonthYearDisplay}
                      onChange={this.inputHandlerFromPicker}
                    />
                  </Grid>
                  <Grid item sm={5}>
                    <label style={{ color: 'black', width: '420px' }}>
                      Please Select ToMonth and Year <span style={{ color: 'red' }}>*</span>:
                    </label>
                    <DatePicker
                      views={['year', 'month']}
                      placeholder="From Year to Month"
                      format="MM/yyyy"
                      value={fromToYearDisplay}
                      onChange={this.inputHandlerToPicker}
                      disabled
                    />
                  </Grid>
                  <Grid item sm={1} style={{ marginTop: '30px' }}>
                    {/* <button
                      className="button-primary"
                      type="submit"
                      style={{ width: '100%', padding: '8px' }}
                      onClick={this.handleGSTR1Download}
                    >
                      Submit
                {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>  */}
                      <button
              // className={`button-primary ${isSubmitting ? 'disabled' : ''}`}
// type="submit"
              // style={{ width: '100%', padding: '8px' }}
              // disabled={isSubmitting}
              className={`button-primary ${isSubmitting || !fromMonthYearDisplay || !fromToYearDisplay ? 'disabled' : ''}`}
              type="submit"
              style={{ width: '100%', padding: '8px' }}
              disabled={isSubmitting || !fromMonthYearDisplay || !fromToYearDisplay}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </BoxWrapper>
        )}
        
        {this.state.showUpload && (
          <BoxWrapper boxTitle="GSTR-1 Return">
            <ValidatorForm
              ref="form"
              onSubmit={this.onSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={2} md={1}>
                    <label
                      className="button-primary"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Browse
                      <input
                        id="file_value"
                        name="GSTJsonfFile"
                        accept="application/json"
                        type="file"
                        hidden
                        onChange={this.fileUploader}
                      />
                    </label>
                  </Grid>
                  <Grid item xs={5} md={4}>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      placeholder="Choose file"
                      value={this.state.file_name}
                      name="GSTJson_file_uploader"
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={2}>
                    {" "}
                    <button className="button-primary" type="submit">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="button-outlined-secondary"
                      onClick={this.newReconModalClose}
                    >
                      cancel
                    </button>
                  </Grid>
                </Grid>
              </div>
            </ValidatorForm>
          </BoxWrapper>
        )}
        <BoxWrapper
          boxTitle="History"
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={this.handleSearchInputChange}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <tbody>
                <tr>{this.historyTableHeader()}</tr>
                {this.historyTableData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>


        {responseData && (
          <div>
            <p style={{ fontSize: '20px', color: 'green' }}>DOWNLOADED GSTR DATA</p>
            <p>{responseData}</p>
          </div>
        )}

        {/*---END---*/}
<Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000} // Adjust as needed
          onClose={this.handleSnackbarClose}
          style={{maxWidth: '25%'}}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={this.state.snackbarSeverity} // Use the state variable to determine severity
          >
            {this.state.snackbarMessage}
          </MuiAlert>
        </Snackbar>

      </React.Fragment>
    );
  }
}
export default GSTRepo;
