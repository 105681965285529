
import React, { useState, useEffect } from "react";
import designForZohoModel from "./ZohoModel.module.css";
import { Box, Paper, Modal } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Grid, TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ValidatorForm } from "react-material-ui-form-validator";
import RightDrawer from "../../components/ui/RightDrawer";
import TextField from "@material-ui/core/TextField";
import axios from "../../axios-order"
import moment from "moment";
import CustomTable from "../../components/ui/Table/Table"
import VisibilityIcon from "@material-ui/icons/Visibility";
import ValidatedDatePicker from "../../components/ui/DatePicker";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  
  boxShadow: 24,
  background: "#fff",
};

const apiUrl = process.env.REACT_APP_BACKEND_BASE_URL

const ReportSync = ({
   openZohoDrawer,
   buttonColor,
  establishConnection,
  zohoDate,
  messageForConnection,
  timerForZohoInSalesCustomer,
  fetchingdataForZoho,
  zohoDateChangeHandler,
   closeZohoDrawer,
  effitracImportFormloader,
  logApitName,
   nameOfTheModel,
  zohoTableHeader,
  zohoTableColumn,
  drawerKeys,
  importToCWS,
  exportToExcel,
}) => {
  

  const [logsLoader, setLogsLoader] = useState(true);
const [logRecords, setLogRecords] = useState([]);

  const [drawer, setDrawer] = useState(false);
  const [index, setIndex] = useState();
  const [zohoData, setZohoData] = useState([]);


  const [triggerZohoTable, setTriggerZohoTable] = useState(false);

  // const actions = [
  //   {
  //     label: <VisibilityIcon className="action-icon-button showIcon" />,
  //     onclickEvent: (index, row) => openModal(index),
  //   },
  // ];

  const closeDrawer = () => {
    setIndex();
    setDrawer(false);
  };

  const openModal = (index) => {
    setDrawer(true);
    setIndex(index);
  };

  
  const handle = () => {
    axios.get(`${apiUrl}/cwsapims/api/v1/table-sync/syncManually/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
      
      })
      .catch((error) => {
     
      });
  };

  const getZohoLogs = () => {
    setLogsLoader(true);
    axios
      .get(`${apiUrl}/cwsapims/api/v1/report/syncHistory?entityId=${localStorage.getItem("business_entity_id")}` )
      .then((res) => {
      
        if (Array.isArray(res.data._embeddedObj)) {
          setLogRecords(res.data._embeddedObj);
        }
        setLogsLoader(false);
      })
      .catch(error => {
        console.log(error);
        setLogsLoader(false);
      });
  };

 
useEffect(() => {
  axios.get(`${apiUrl}/cwsapims/api/v1/report/syncHistory?entityId=${localStorage.getItem("business_entity_id")}`)
    .then(response => {
     
      if (Array.isArray(response.data._embeddedObj)) {
        setLogRecords(response.data._embeddedObj);
      }
      
      setLogsLoader(false);
    })
    .catch(error => {
     
      setLogsLoader(false);
    });
}, []);


  useEffect(() => {
    
  }, [effitracImportFormloader]);

 

  return (
    <Modal
      keepMounted
      open={openZohoDrawer}
      // onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            color: "#fff",
            background: "#d04646",
            padding: "7px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 style={{ fontFamily: "Dosis", marginBottom: "0px" }}>
            {nameOfTheModel}
          </h4>
          {triggerZohoTable ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    className="button-primary"
                    onClick={() => setTriggerZohoTable(false)}
                  >
                    Back
                  </button>
                </div>
                {/* <div>
                  <button
                    className="button-primary"
                    onClick={() => {
                      importToCWS(zohoData);
                      closeZohoDrawer();
                    }}
                  >
                    Import To CWS
                  </button>
                </div> */}
                <div>
                  {/* <button
                    className="button-primary"
                    onClick={() => exportToExcel(zohoData)}
                  >
                    Export To Excel
                  </button> */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="modal-body">
          {triggerZohoTable ? (
            <>
              {/* <CustomTable
                // headers={zohoTableHeader}
                columns={zohoTableColumn}
                data={zohoData}
                actions={drawerKeys.length === 0 ? null : actions}
              /> */}
            </>
          ) : (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row-reverse",
                  }}
                >
                  <div>
                    <button type="button"
            className="button-outlined-secondary " onClick={handle}>SYNC</button>
                  </div>
                  
                </div>
               
              </div>
              

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "20px 0px",
                  borderBottom: "2px solid #ddd",
                  fontFamily: "Dosis",
                }}
              > 
                <h5>
               
                </h5>
               
                <Button
                  component="label"
                  size="small"
                  color="primary"
                  style={{ marginTop: "5px" }}
                  onClick={() => getZohoLogs()}
                >
                  <RefreshIcon style={{ fontSize: "20px" }} />
                  Refresh
                </Button>
              </div>
             
<div className="responsive-table">
  <table className="custome-table">
    <thead>
      <tr>
        <th>Template Name</th>
        <th>Record Count</th>
        <th>Last Updated Date</th>
        <th>Status</th>
        <th>Action</th>
        
      </tr>
    </thead>
    <tbody>
      {logsLoader ? (
        <tr>
          <td style={{ fontWeight: "bold" }} colSpan={7}>
            Loading..Please Wait!
          </td>
        </tr>
      ) : (
        <>
          {logRecords.length > 0 ? (
            logRecords.map((v) => (
              <tr key={v.id}>
                <td>{v.templateName}</td>
                <td>{v.recordCount}</td>
                <td>{v.lastSyncDate}</td>
                <td>{v.status}</td>
                <td></td>
                <td></td>
              </tr>
            ))
          ) : (
            <tr>
              <td style={{ color: "red", fontWeight: "bold" }} colSpan={7}>
                No Record Found!!
              </td>
            </tr>
          )}
        </>
      )}
    </tbody>
  </table>
</div>
            
            </>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button-outlined-secondary "
            onClick={closeZohoDrawer}
          >
            Close
          </button>
        </div>

        <RightDrawer
         
        >
    
        </RightDrawer>
      </Box>
    </Modal>
  );
};

export default ReportSync;

