import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import designForControlRep from "./ControlReport2.module.css";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import axios from "../../../../axios-order";
import CircularProgress from "@mui/material/CircularProgress";
import CardForDispaly from "../../../../components/ui/Cards";
import BoxWapper from "../../../../components/ui/boxWrapper";

export default function ControlReport2() {
  const [dateForInputTaxCrToBeReversed, setDateForInputTaxCrToBeReversed] =
    useState([]);
  const [
    dateForInputTaxCrToBeReversedSearch,
    setDateForInputTaxCrToBeReversedSearch,
  ] = useState([]);
  const [forExportToExcel, setForExportToExcel] = useState([]);
  const [triggerTheLoader, setTriggerTheLoader] = useState(true);

  const tableHeadForTaxAgeingIneligibleReport = () => {
    const tableHeadData2 = [
      "Legal Name",
      "GSTIN",
      "Number of Invoices",
      "Adj Total Invoice Value",
      "ERP Customer Code",
    ];
    return tableHeadData2.map((v, i) => {
      return <th key={i}>{v}</th>;
    });
  };

  const handleSearchEvent = (e) => {
    let search = e.target.value;
    let searchData = dateForInputTaxCrToBeReversedSearch.filter((value) => {
      return (
        String(value.Legal_Name).toLowerCase().indexOf(search.toLowerCase()) >=
          0 ||
        String(value.GSTIN).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setDateForInputTaxCrToBeReversed(searchData);
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //...............Indian Rupees..............
  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  const Export_to_excell = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Input Tax Credit to be reversed.xlsx`;

    axios
      .post("/seller_second_control_report/download", {
        json_for_Export: forExportToExcel,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === "pass") {
          downloadBase64File(contentType, res.data.file, filename);
        } else {
          alert("Unable to download check your internet or try again");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getApiForInputTaxToBeReversedInSales = () => {
    axios
      .get(
        `/view/seller_second_control_report/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        if (res.data.status === true) {
          setTriggerTheLoader(false);
          setDateForInputTaxCrToBeReversed(res.data.records.json_for_UI);
          setDateForInputTaxCrToBeReversedSearch(res.data.records.json_for_UI);
          setForExportToExcel(res.data.records.json_for_Export);
        } else {
          setTriggerTheLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setTriggerTheLoader(false);
        alert("Server Error");
      });
  };

  const date = () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    return formattedDate;
  };

  useEffect(() => {
    setTriggerTheLoader(true);
    getApiForInputTaxToBeReversedInSales();
  }, []);
  return (
    <React.Fragment>
      {/* basic info in card */}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#3b3b6b"
            cardTitle="Name"
            cardBody={localStorage.getItem("entity_name")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#4B5D67"
            cardTitle="GSTIN"
            cardBody={localStorage.getItem("gst")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#d04646"
            cardTitle="Report Generated On"
            cardBody={date()}
          />
        </Grid>
      </Grid>

      {triggerTheLoader ? (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <CircularProgress />
          <br></br>
          Loading,Please wait...
        </div>
      ) : (
        <BoxWapper
          boxTitle=" Input Tax Credit Turning Ineligible Due To Non Receipt(s)
        From Customer(s)"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={Export_to_excell}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={handleSearchEvent}
        >
          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>{tableHeadForTaxAgeingIneligibleReport()}</tr>
              </thead>
              <tbody>
                {dateForInputTaxCrToBeReversed.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{value.Legal_Name}</td>
                      <td>{value.GSTIN}</td>
                      <td>{value.Number_of_Invoices}</td>
                      <td>
                        {indianRupeesFormat(
                          value.Adj_Total_Invoice_Value.toFixed(2)
                        )}
                      </td>
                      <td>{value.Customer_Code}</td>
                    </tr>
                  );
                })}
                {dateForInputTaxCrToBeReversed.length === 0 && (
                  <tr>
                    <td
                      colSpan={"5"}
                      style={{ color: "red", textAlign: "center" }}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWapper>
      )}
    </React.Fragment>
  );
}
