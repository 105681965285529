import React, { useState, useEffect } from "react";
import DesignInGSTR3BThreePointOne from "../GSTR-3B_recon.module.css";
import { Grid, Button } from "@material-ui/core";
import NumberFormat from "react-number-format";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "../../../../../axios-order";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

const ThreePointTwo = ({
  dataForPointTwo,
  cancel,
  upDateJSON,
  dropDownForPlaceOfSupply,
}) => {
  const [threePointTwoEditData, setThreePointTwoEditData] =
    useState(dataForPointTwo);
  const [newRowJson, setNewRowJson] = useState([]);
  const [newJsonToSend, setNewJsonToSend] = useState([]);

  const [arrayToAddRow, setArrayToAddRow] = useState([]);
  const [number, setNumber] = useState(0);
  const [triggerNumber, setTriggerNumber] = useState(false);

  const functionToAddNumber = (num) => {
    setArrayToAddRow(arrayToAddRow.concat(num));
  };

  const functionToSubNumber = (num) => {
    const data = arrayToAddRow.filter((id) => id !== num);
    setArrayToAddRow(data);
  };

  useEffect(() => {
    if (triggerNumber) functionToAddNumber(number);
  }, [number]);

  const subtractRowNumber = () => {
    if (number > 0) {
      setTriggerNumber(false);
      console.log("number ==>  ", number);
      if (newRowJson.length !== 0) {
        let numbers = number - 1;
        console.log("num - 1 ==>>> ", numbers);
        console.log(newRowJson);
        const data = newRowJson.filter((id, index) => index !== numbers);
        console.log("data ===>  ", data);
        setNewRowJson(data);
      }
      setNumber(number - 1);
      functionToSubNumber(number);
    } else {
      setTriggerNumber(false);
    }
  };

  useEffect(() => {
    if (newJsonToSend.length !== 0) {
      upDateJSON(newJsonToSend, "ThreePointTwo");
      cancel();
    }
    //  console.log("length ==>>>  " , newJsonToSend.length)
  }, [newJsonToSend]);

  const handleSubmit = () => {
    setNewJsonToSend([...threePointTwoEditData, ...newRowJson]);
    // setThreePointTwoEditData(...threePointTwoEditData , ...newRowJson)
    // upDateJSON(threePointTwoEditData, "ThreePointTwo");
    // cancel();
  };

  const funcToUpdateTheValue = (index, value, keys) => {
    let data = newRowJson.map((item, id) => {
      if (index === id) {
        return { ...item, [keys]: value };
      }
      return item;
    });
    setNewRowJson(data);
  };
  console.log(newRowJson);
  return (
    <>
      <BoxWrapper
        boxTitle="3.2 Out Of The Supplies Shown in 3.1 (a), Details of Inter-state
                Supplies Made to Unregistered Persons, Composition Taxable
                Person and UIN Holders"
      >
        <div className={DesignInGSTR3BThreePointOne.tableResponsiveForTable}>
          <table className={DesignInGSTR3BThreePointOne.LongTableCssForTable}>
            <thead>
              <tr style={{ borderBottom: "2.5px solid #ddd" }}>
                <th rowSpan={2}>Place of Supply(State/UT)</th>
                <th colSpan={2} align="center">
                  Supplies made to Unregistered Persons
                </th>
                <th colSpan={2} align="center">
                  Supplies made to Composition Taxable Persons
                </th>
                <th colSpan={2} align="center">
                  Supplies made to UIN holders
                </th>
              </tr>
              <tr>
                <th align="right">Total Taxable value</th>
                <th align="right">Amount of Integrated Tax</th>

                <th align="right">Total Taxable value</th>
                <th align="right">Amount of Integrated Tax</th>

                <th align="right">Total Taxable value</th>
                <th align="right">Amount of Integrated Tax</th>
              </tr>
            </thead>
            <tbody>
              {threePointTwoEditData?.map((v, index) => {
                return (
                  <tr>
                    <td>
                      {
                        <Autocomplete
                          id="combo-box-demo"
                          name="placeOfSupply"
                          value={v.pos}
                          onChange={(e, value) => {
                            if (value === null) {
                              let newData = threePointTwoEditData?.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      pos: parseFloat(value),
                                    };
                                  }
                                  return item;
                                }
                              );
                              setThreePointTwoEditData(newData);
                            } else {
                              let newData = threePointTwoEditData?.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      pos: parseFloat(value),
                                    };
                                  }
                                  return item;
                                }
                              );
                              setThreePointTwoEditData(newData);
                            }
                          }}
                          options={dropDownForPlaceOfSupply}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                              name="placeOfSupply"
                            />
                          )}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.unreg_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    unreg_taxable_value: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.unreg_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    unreg_igst: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.comp_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    comp_taxable_value: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.comp_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    comp_igst: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.uin_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    uin_taxable_value: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          value={v.uin_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            let newData = threePointTwoEditData?.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    uin_igst: parseFloat(value),
                                  };
                                }
                                return item;
                              }
                            );
                            setThreePointTwoEditData(newData);
                          }}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
              {arrayToAddRow.map((v, index) => {
                return (
                  <tr>
                    <td>
                      <Autocomplete
                        id="combo-box-demo"
                        name="placeOfSupply"
                        onChange={(e, value) => {
                          if (value === null) {
                            setNewRowJson([
                              ...newRowJson,
                              {
                                ...newRowJson[`${index}`],
                                pos: "",
                              },
                            ]);
                          } else {
                            setNewRowJson([
                              ...newRowJson,
                              {
                                ...newRowJson[`${index}`],
                                pos: value,
                              },
                            ]);
                          }
                        }}
                        options={dropDownForPlaceOfSupply}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            name="placeOfSupply"
                          />
                        )}
                      />
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          // value={v.unreg_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(
                              index,
                              parseFloat(value),
                              "unreg_taxable_value"
                            );
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          // value={v.unreg_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(index, parseFloat(value), "unreg_igst");
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          // value={v.comp_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(
                              index,
                              parseFloat(value),
                              "comp_taxable_value"
                            );
                          }}
                        />
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          // value={v.comp_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(index, parseFloat(value), "comp_igst");
                          }}
                        />
                      }
                    </td>
                    <td>
                      {" "}
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          //  value={v.uin_taxable_value}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(
                              index,
                              parseFloat(value),
                              "uin_taxable_value"
                            );
                          }}
                        />
                      }
                    </td>
                    <td>
                      {
                        <NumberFormat
                          className={DesignInGSTR3BThreePointOne.numberFormate}
                          style={{ paddingLeft: "10.5px" }}
                          thousandsGroupStyle="lakh"
                          prefix="₹"
                          decimalSeparator="."
                          displayType="number"
                          type="text"
                          decimalScale={2}
                          // value={v.uin_igst}
                          thousandSeparator={true}
                          allowNegative={true}
                          onValueChange={(values) => {
                            const { value } = values;
                            funcToUpdateTheValue(index, parseFloat(value), "uin_igst");
                          }}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={DesignInGSTR3BThreePointOne.iconsAndButton}>
          <div className={DesignInGSTR3BThreePointOne.icons}>
            <div
              className={DesignInGSTR3BThreePointOne.iconsPosition}
              onClick={() => {
                setNumber(number + 1);
                setTriggerNumber(true);
              }}
            >
              <AddIcon />
            </div>
            <div
              className={DesignInGSTR3BThreePointOne.iconsPosition}
              onClick={subtractRowNumber}
            >
              <RemoveIcon />
            </div>
          </div>
          <div className={DesignInGSTR3BThreePointOne.icons}>
            <div style={{ marginRight: "10px" }}>
              <button className="button-primary" onClick={handleSubmit}>
                Save
              </button>
            </div>
            <div>
              <button className="button-outlined-secondary" onClick={cancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </>
  );
};

export default ThreePointTwo;
