import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "../../../axios-order";
import { Grid } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import TableFroReasonwise from "./TableFroReasonwise";
import BoxWrapper from "../../../components/ui/boxWrapper";

export default function Reasonwise() {
  const [getApiDetailsForRegionName, setGetApiDetailsForRegionName] = useState(
    []
  );
  const [getApiDetailsForReportingPeriod, setGetApiDetailsForReportingPeriod] =
    useState([]);
  const [getApiForBranch, setGetApiForBranch] = useState([]);
  const [getApiForTable, SetGetApiForTable] = useState([]);
  const [getDataForTableExport, setGetDataForTableExport] = useState([]);

  const [postApiForRegionId, setPostApiForRegionId] = useState("");
  const [postApiForRegionName, setPostApiForRegionName] = useState("");
  const [postApiForReportingPeriodId, setPostApiForReportingPeriodId] =
    useState("");
  const [postApiForReportingPeriodName, setPostApiForReportingPeriodName] =
    useState("");
  const [postApiForBranchId, setPostApiForBranchId] = useState("");
  const [postApiForBranchName, setPostApiForBranchName] = useState("");

  //.................useState For Error Message...............
  const [toDisplay, setToDisplay] = useState("");
  const [divColor, setDivColor] = useState("red");
  const divVisibility = "visible";
  const [displayproperty, setDisplayproperty] = useState("none");
  const [trigar, setTrigar] = useState(false);

  /*...............Api For Region Master.................*/
  function getApiForRegionName() {
    functionForErrorMessage(
      "Please wait while we fetch information...",
      "green",
      "block"
    );
    axios
      .post(`/getRegionMaster`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          functionForErrorMessage("", "red", "none");
          setGetApiDetailsForRegionName(res.data.data);
        } else {
          functionForErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        functionForErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  }

  /*......................Api For Reporting Period............*/
  const getApiForReportingPeriod = () => {
    functionForErrorMessage("Loading...", "green", "block");
    axios
      .post(`/getReportingPeriod`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        if (res.data.status === true) {
          functionForErrorMessage("", "", "none");
          setGetApiDetailsForReportingPeriod(res.data.data);
        } else {
          functionForErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => {
        functionForErrorMessage(
          "Server Error Try After Some Time or Check Your Internet",
          "red",
          "block"
        );
      });
  };

  /*........................Api For Branch Name............*/
  const getApiForBranchId = () => {
    functionForErrorMessage("Loading...", "green", "block");
    axios
      .post(`getBranchMaster`, {
        business_entity_id: localStorage.getItem("business_entity_id"),
        region_id: postApiForRegionId,
      })
      .then((res) => {
        if (res.data.status === true) {
          functionForErrorMessage("", "red", "none");
          setGetApiForBranch(res.data.data);
        } else {
          functionForErrorMessage(res.data.message, "red", "block");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOpenAndCloseModel = () => {
    setPostApiForRegionId("");
    setPostApiForBranchId("");
    setPostApiForReportingPeriodId("");
    setPostApiForBranchName("");
    setTrigar(false);
    setToDisplay("");
    setDivColor("red");
    setDisplayproperty("none");
  };

  const functionForErrorMessage = (print, color, displaypropertyToPrint) => {
    setToDisplay(print);
    setDivColor(color);
    setDisplayproperty(displaypropertyToPrint);
  };

  //.......................Submit Handler.......................
  const handleSubmit = () => {
    if (postApiForReportingPeriodId === "" || postApiForRegionId === "") {
      functionForErrorMessage("* Mandatory Fields", "red", "block");
    } else {
      functionForErrorMessage("Loading....", "green", "block");
      axios
        .post(`get_reasonwise_response`, {
          RegionId: postApiForRegionId,
          ReportingPeriodId: postApiForReportingPeriodId,
          BranchId: postApiForBranchId,
          business_entity_id: localStorage.getItem("business_entity_id"),
        })
        .then((res) => {
          if (res.data.status === false) {
            functionForErrorMessage("No Data Present!", "red", "block");
            setTrigar(false);
          }
          if (res.data.status === true) {
            functionForErrorMessage(
              "Data Fetch Successfully",
              "green",
              "block"
            );
            SetGetApiForTable(res.data);
            setGetDataForTableExport(res.data);
            setTrigar(true);
          }
        })
        .catch(() => {
          functionForErrorMessage(
            "Server Error Try After Some Time or Check Your Internet",
            "red",
            "block"
          );
          setTrigar(false);
        });
    }
  };

  useEffect(() => {
    getApiForRegionName();
    getApiForReportingPeriod();
  }, []);

  useEffect(() => {
    if (postApiForRegionId !== "") {
      getApiForBranchId();
      getApiDetailsForRegionName?.map((item) => {
        if (postApiForRegionId === item.RegionId) {
          setPostApiForRegionName(item.RegionName);
        }
      });
    }
  }, [postApiForRegionId]);

  useEffect(() => {
    if (postApiForBranchId !== "") {
      let branch = getApiForBranch.filter((item) => {
        if (postApiForBranchId === item.BranchId) {
          return item;
        }
      });
      setPostApiForBranchName(branch[0].BranchName);
    }
  }, [postApiForBranchId]);

  useEffect(() => {
    if (postApiForReportingPeriodId !== "") {
      let reportingPeriod = getApiDetailsForReportingPeriod.filter((item) => {
        if (postApiForReportingPeriodId === item.ReportingPeriodId) {
          return item;
        }
      });
      setPostApiForReportingPeriodName(reportingPeriod[0].ReportingPeriod);
    }
  }, [postApiForReportingPeriodId]);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "rgba(0,123,255)",
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)  #80bdff',
      },
    },
  }));

  return (
    <React.Fragment>
      <BoxWrapper boxTitle="Reasonwise Report Filter">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <span style={{color:"red" , fontWeight:"bold"}}>*</span>
            <label>Region Name:</label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForRegionId}
                disabled={getApiDetailsForRegionName === "" ? true : false}
                onChange={(e) => {
                  setPostApiForRegionId(e.target.value);
                  setPostApiForBranchId("");
                }}
                input={<BootstrapInput />}
              >
                {getApiDetailsForRegionName.map((v) => {
                  return <MenuItem value={v.RegionId}>{v.RegionName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <label>Branch Name:</label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForBranchId}
                onChange={(e) => setPostApiForBranchId(e.target.value)}
                input={<BootstrapInput />}
                disabled={postApiForRegionId === "" ? true : false}
              >
                {/*  <option aria-label="None" value="" /> */}
                {getApiForBranch.map((v) => {
                  return <MenuItem value={v.BranchId}>{v.BranchName}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <span style={{color:"red" , fontWeight:"bold"}}>*</span>
            <label>Reporting Period:</label>
            <FormControl sx={{ minWidth: "100%" }}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={postApiForReportingPeriodId}
                disabled={getApiDetailsForReportingPeriod === "" ? true : false}
                onChange={(e) => setPostApiForReportingPeriodId(e.target.value)}
                input={<BootstrapInput />}
              >
                {/*  <option aria-label="None" value="" /> */}
                {getApiDetailsForReportingPeriod.map((v) => {
                  return (
                    <MenuItem value={v.ReportingPeriodId}>
                      {v.ReportingPeriod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <div style={{ marginTop: "30px" }}>
              <button
                style={{ padding: "8px 12px" }}
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Submit
              </button>
              <button
                style={{ padding: "8px 12px" }}
                type="button"
                onClick={handleOpenAndCloseModel}
                className="button-outlined-secondary"
              >
                Cancel
              </button>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            color: divColor,
            visibility: divVisibility,
            display: displayproperty,
            fontWeight: "bold",
            paddingTop: "5px",
          }}
        >
          {toDisplay}
        </div>
      </BoxWrapper>

      <div>
        {trigar && (
          <TableFroReasonwise
            data={getApiForTable}
            ForExport={getDataForTableExport}
            postApiForRegionName={postApiForRegionName}
            postApiForBranchName={postApiForBranchName}
            postApiForReportingPeriodName={postApiForReportingPeriodName}
          />
        )}
      </div>
    </React.Fragment>
  );
}
