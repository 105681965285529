import React from "react";
import { Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "./dashboard.css";
import CircularProgress from "@mui/material/CircularProgress";

const DashboardFilter = (props) => {
  const {
    DropdownsData,
    filterTableFlag,
    filterSubmitHandler,
    setFilterValues,
    filterValues,
    loader,
    cancelFilterForm,
    customerTableFlag,
    error,
  } = props;

  return (
    <div className="top_header_container">
      <Grid container spacing={1}>
        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>
            {" "}
            As of Date <span style={{ color: "red" }}>*</span>:
          </label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.as_of_date_list}
            value={filterValues.as_of_date ?? ""}
            onChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["as_of_date"]: newInputValue,
              });
            }}
            // onInputChange={(event, newInputValue) => {
            //   setFilterValues({
            //     ...filterValues,
            //     ['as_of_date']: newInputValue,
            //   });
            // }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
          {filterValues.as_of_date ? (
            ""
          ) : (
            <span style={{ color: "red" }}>This field is required.</span>
          )}
        </Grid>

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Customer GSTIN :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.cust_gstin_list}
              value={filterValues.customer_gstin ?? ""}
              onChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["customer_gstin"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Customer Name :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.customer_name_list}
              value={filterValues.customer_name ?? ""}
              inputValue={filterValues.customer_name ?? ""}
              onInputChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["customer_name"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Customer PAN :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.cust_pan_list}
              value={filterValues.customer_pan ?? ""}
              onChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["customer_pan"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        {filterTableFlag && (
          <Grid item xs={3}>
            <label> Customer Code :</label>
            <Autocomplete
              id="combo-box-demo"
              options={DropdownsData && DropdownsData.customer_code_list}
              inputValue={filterValues.customer_code ?? ""}
              onInputChange={(event, newInputValue) => {
                setFilterValues({
                  ...filterValues,
                  ["customer_code"]: newInputValue,
                });
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Grid>
        )}

        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>GST Invoice Type:</label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.registration_type_list}
            value={filterValues.registration_type ?? ""}
            onChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["registration_type"]: newInputValue,
              });
            }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
        </Grid>

        <Grid item xs={filterTableFlag ? 3 : 4}>
          <label>Account Manager:</label>
          <Autocomplete
            id="combo-box-demo"
            options={DropdownsData && DropdownsData.account_manager_list}
            inputValue={filterValues.Account_manager ?? ""}
            onInputChange={(event, newInputValue) => {
              setFilterValues({
                ...filterValues,
                ["Account_manager"]: newInputValue,
              });
            }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" />
            )}
          />
        </Grid>

        {filterTableFlag && !customerTableFlag && (
          <>
            <Grid item xs={3}>
              <label> Ageing Bucket :</label>
              <Autocomplete
                id="combo-box-demo"
                options={DropdownsData && DropdownsData.agieng_list}
                value={filterValues.ageing ?? ""}
                onChange={(event, newInputValue) => {
                  setFilterValues({
                    ...filterValues,
                    ["ageing"]: newInputValue,
                  });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <label>Due Bucket :</label>
              <Autocomplete
                id="combo-box-demo"
                options={DropdownsData && DropdownsData.due_bucket_list}
                value={filterValues.due ?? ""}
                onChange={(event, newInputValue) => {
                  setFilterValues({
                    ...filterValues,
                    ["due"]: newInputValue,
                  });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div style={{ marginTop: "10px" }}>
        <button
          className="button-primary"
          onClick={filterSubmitHandler}
          disabled={loader}
        >
          {loader ? (
            <>
              <CircularProgress size={24} style={{ margin: "5px " }} />
              loading...
            </>
          ) : (
            "Search"
          )}
        </button>

        <button
          className="button-outlined-secondary "
          onClick={cancelFilterForm}
        >
          clear
        </button>

        {error && <span style={{ color: "red" }}>No Data Found!</span>}
      </div>
    </div>
  );
};

export default DashboardFilter;
