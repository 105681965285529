import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function DashboardBarChart(props) {
  const { chartTitle, chartCategories, yAxisTitle, chartData } = props;

  const setOptions = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: chartTitle,
    },

    xAxis: {
      categories: chartCategories,
      crosshair: true,
    },

    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //     `<td style="padding:0"><b>` + indianRupeesFormat(99999999) + `</b></td></tr>`,
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        `<td style="padding:0"><b>` +
        `{point.y}` +
        `</b></td></tr>`,

      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      valuePrefix: "₹",
      valueDecimals: 2,
    },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        cursor: "pointer"
      },
    },
    series: chartData,
    exporting: {
      enabled: false, // Disable exporting menu
    },
    
  };

  return <HighchartsReact highcharts={Highcharts} options={setOptions} />;
}

export default DashboardBarChart;
