import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
} from "@material-ui/core";
import axios from "../../../../axios-order";
import PropTypes from "prop-types";
import MYParticulars from "./Dashboard/MYParticulars";
import MYSection from "./Dashboard/MYSection";
import MYDefaulters from "./Dashboard/MYDefaulters";
import MYSummary from "./Summary/MYSummary";
import MYBooksDetails from "./Booksdetails/MYBooksDetails";
import MYForm26asDetails from "./Form26As/MYForm26asDetails";
import SYParticulars from "./Dashboard/SYParticular";
import SYSection from "./Dashboard/SYSection";
import SYDefaulters from "./Dashboard/SYDefaulter";
import SYSummary from "./Summary/SYSummary";
import SYForm26asDetails from "./Form26As/SYForm26asDetails";
import SYBooksDetails from "./Booksdetails/SYBooksDetails";
import RevenueReport from "./RevenueReport/revenueReport";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ReportPage(props) {
  const classes = useStyles();
  const [allJsonData, setAllJsonData] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [bookDetailsData, setBookDetailsData] = useState();
  const [form26asDetailsData, setForm26asDetailsData] = useState();
  const [singleYear, setSingleYear] = useState();
  const [financialYears, setFinancialYears] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [revenueReportData, setRevenueReportData] = useState([]);
  const [revenueflag, setRevenueFlag] = useState();
  const [nonTaxableValue, setNonTaxableValue] = useState();

  const getTdsReconJsonDetails = () => {
    axios
      .get(`/form_26/get_json/${localStorage.getItem("TDS_report_json_id")}`)
      .then((res) => {
        setAllJsonData(res.data.records);
        setSummaryData(res.data.records.summary);
        setDashboardData(res?.data?.records?.dashboard);
        setBookDetailsData(res.data.records.BooksDetails);
        setForm26asDetailsData(res.data.records.form26AsDetails);
        setSingleYear(res.data.records.singleYear);
        setFinancialYears(res.data.records.financialYears);
        setRevenueReportData(res?.data?.records?.revenue_details);
        setRevenueFlag(res.data.records.revenue);
        setNonTaxableValue(res?.data?.records?.non_taxable_value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTdsReconJsonDetails();
  }, []);

  const tabIndexHandler = (event, newValue) => {
    setTabIndex(newValue);
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadToExcel = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `TDS Receivables Reconciliation Report.xlsx`;
    axios
      .post(`/tds/dashboard/download`, { data: allJsonData })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {/* ---page header--- */}
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            className={classes.Paper}
            style={{
              background: "#c4d0d9",
              textTransform: "capitalize",
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            TDS Receivables Reconciliation Report <br></br>
            {localStorage.getItem("entity_name")}
          </Paper>
        </Grid>
      </Grid> */}
      {/* ---sub header--- */}
      <Grid container spacing={3} style={{ marginBottom: "5px" }}>
        <Grid item xs={4}>
          <div>
            {/* Report Dated :  14-09-2021 */}

            {/* <br></br>Report Period : 2020 To 2021 */}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "18px" }}>
              {" "}
              TDS Receivables Reconciliation Report
            </p>
            <button
              className="button-outlined-primary"
              onClick={downloadToExcel}
            >
              Export to Excel
            </button>
            <br></br>

            {financialYears &&
              singleYear === "No" &&
              `Financial Year : ${financialYears[0]} To ${
                financialYears[financialYears.length - 1]
              }`}
            {financialYears &&
              singleYear === "Yes" &&
              `Financial Year : ${financialYears}`}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ float: "right" }}>{/* Financial Year: 2020-21 */}</div>
        </Grid>
      </Grid>
      {/* ---End--- */}

      <div className="tab-panel-section">
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={tabIndexHandler}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              style={{ background: "#FFE3E3", color: "#000" }}
              label="Dashboard"
              {...a11yProps(0)}
            />
            <Tab
              style={{ background: "#E4D8DC", color: "#000" }}
              label="Summary"
              {...a11yProps(1)}
            />
            <Tab
              style={{ background: "#C9CCD5", color: "#000" }}
              label="Books Details"
              {...a11yProps(2)}
            />
            <Tab
              style={{ background: "#93B5C6", color: "#000" }}
              label="Form 26AS Details"
              {...a11yProps(3)}
            />

            {revenueflag === "Yes" && (
              <Tab
                style={{ background: "#337ab7", color: "#000" }}
                label="Revenue Recon"
                {...a11yProps(4)}
              />
            )}
          </Tabs>
        </AppBar>

        <TabPanel value={tabIndex} index={0}>
          {singleYear === "No" && (
            <MYParticulars
              RevenueReportData={
                allJsonData?.revenue === "Yes" ? revenueReportData : []
              }
              paticulars={dashboardData.particulars}
            />
          )}
          {singleYear === "Yes" && (
            <SYParticulars
              RevenueReportData={
                allJsonData?.revenue === "Yes" ? revenueReportData : []
              }
              paticulars={dashboardData.particulars}
            />
          )}
          {singleYear === "No" && (
            <MYSection sectionData={dashboardData.section} />
          )}
          {singleYear === "Yes" && (
            <SYSection sectionData={dashboardData.section} />
          )}
          {singleYear === "No" && (
            <MYDefaulters defaulterData={dashboardData.top10Customers} />
          )}
          {singleYear === "Yes" && (
            <SYDefaulters defaulterData={dashboardData.top10Customers} />
          )}
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          {singleYear === "No" && <MYSummary summaryData={summaryData} />}
          {singleYear === "Yes" && <SYSummary summaryData={summaryData} />}
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          {singleYear === "No" && (
            <MYBooksDetails booksDetails={bookDetailsData} />
          )}
          {singleYear === "Yes" && (
            <SYBooksDetails booksDetails={bookDetailsData} />
          )}
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          {singleYear === "No" && (
            <MYForm26asDetails form26asData={form26asDetailsData} />
          )}
          {singleYear === "Yes" && (
            <SYForm26asDetails form26asData={form26asDetailsData} />
          )}
        </TabPanel>

        {revenueflag === "Yes" && (
          <TabPanel value={tabIndex} index={4}>
            <BoxWrapper boxTitle="TDS is Calculated Based on Taxable and Non Taxable Excluding GST and Cess or One of Them Depending on The Goods or Services">
              <RevenueReport
                revenueData={revenueReportData ? revenueReportData : []}
                nonTaxableValue={nonTaxableValue}
                onlyOneYear={singleYear}
                fy={financialYears}
              />
            </BoxWrapper>
          </TabPanel>
        )}
      </div>
    </>
  );
}

export default ReportPage;
