import React, { useEffect, useState } from "react";
import axios from "../../../../axios-order";
import designForControlRep from "./ControlReport2.module.css";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Button,
} from "@material-ui/core";
import moment from "moment";
import BoxWapper from "../../../../components/ui/boxWrapper";
import CardForDispaly from "../../../../components/ui/Cards";

export default function ControlReport2() {
  const [tableData, setTableData] = useState([]);
  const [tableDataSearch, setTableDataSearch] = useState([]);
  const [loaderForTable, setLoaderForTable] = useState(false);

  const tableHeadForTaxAgeingIneligibleReport = () => {
    const tableHeadData2 = [
      "Financial Year",
      "Vendor Name",
      "Vendor Code",
      "Vendor GSTIN",
      "Nature of Expenses",
      "Purchase Manager",
      "Bill Number",
      "Bill Date",
      "Due Date",
      "Taxable Amount",
      "Non Taxable Amount",
      "GST Amount",
      "Total Bill Amount",
      "TDS",
      "Net Amount Paid",
      // "Dispute/Under Negotiation Amount",
      "Amount OutStanding",
      "Adv/Excess Amount",
      "Days Past Invoice Date",
      "Ageing Bucket",
    ];
    return tableHeadData2.map((v, i) => {
      return <th key={i}>{v}</th>;
    });
  };

  const handleSearchEvent = (e) => {
    let search = e.target.value;
    let searchData = tableDataSearch.filter((value) => {
      return (
        String(value.Vendor_Name).toLowerCase().indexOf(search.toLowerCase()) >=
          0 ||
        String(value.Vendor_GSTIN)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(value.Financial_Year)
          .toLowerCase()
          .indexOf(search.toLowerCase()) >= 0 ||
        String(value.BillNumber).toLowerCase().indexOf(search.toLowerCase()) >=
          0 ||
        String(value.Vendor_Code).toLowerCase().indexOf(search.toLowerCase()) >=
          0
      );
    });
    setTableData(searchData);
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  const indianDateFormat = (date) => {
    let dateArr = date.split("-");
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  };

  const Export_to_excell = () => {
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Input Tax Credit to be reversed.xlsx`;
    axios
      .post("/vendor/vendorpayablecontrolreport", {
        data: tableData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tableDataGetApi = () => {
    setLoaderForTable(true);
    axios
      .get(
        `/get_vendor_second_control_report/${localStorage.getItem(
          "business_entity_id"
        )}`
      )
      .then((res) => {
        setLoaderForTable(false);
        setTableData(res.data.record);
        setTableDataSearch(res.data.record);
      })
      .catch((err) => {
        setLoaderForTable(false);
      });
  };

  const date = () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");

    return formattedDate;
  };

  useEffect(() => {
    tableDataGetApi();
  }, []);
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#3b3b6b"
            cardTitle="Name"
            cardBody={localStorage.getItem("entity_name")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#4B5D67"
            cardTitle="GSTIN"
            cardBody={localStorage.getItem("gst")}
          />
        </Grid>
        <Grid item xs={4}>
          <CardForDispaly
            backgroundcolor="#d04646"
            cardTitle="Report Generated On"
            cardBody={date()}
          />
        </Grid>
      </Grid>

      <BoxWapper
        boxTitle=" Not Eligible For Input Tax Credit If Invoice Not Settled -
        More Than 180 Days"
        // export to excel
        exportToExcelEnable={true}
        exportToExcelFn={Export_to_excell}
        // search input
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
      >
        <div className="responsive-table">
          <table className="custome-long-table">
            <thead>
              <tr>{tableHeadForTaxAgeingIneligibleReport()}</tr>
            </thead>
            <tbody>
              {tableData?.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.Financial_Year}</td>
                    <td>{value.Vendor_Name}</td>
                    <td>{value["ERP Vendor Code"]}</td>
                    <td>{value.Vendor_GSTIN}</td>
                    <td>{value.Nature_of_Expense}</td>
                    <td>{value.Account_Manager}</td>
                    <td>{value.BillNumber}</td>
                    <td>
                      {value.BillDate &&
                        moment(value.BillDate).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {value.Due_date &&
                        moment(value.Due_date).format("DD-MM-YYYY")}
                    </td>
                    <td>{indianRupeesFormat(value.Taxable_Amount)}</td>
                    <td>{indianRupeesFormat(value.Non_Taxable_Amount)}</td>
                    <td>{indianRupeesFormat(value.GST_Amount)}</td>
                    <td>{indianRupeesFormat(value.Total_Bill_Amount)}</td>
                    <td>{indianRupeesFormat(value.TDS_Amount)}</td>
                    <td>{indianRupeesFormat(value.Net_Amount_Paid)}</td>
                    {/* <td>{value.Dispute_Under_Negotation_Amount}</td> */}
                    <td>{indianRupeesFormat(value.Amount_Outstanding)}</td>
                    <td>{indianRupeesFormat(value.Adv_Or_Excess_Amount)}</td>
                    <td>{value.Days_Past_Invoice_Date}</td>
                    <td>{value.Ageing_Bucket}</td>
                  </tr>
                );
              })}
              {loaderForTable ? (
                <tr>
                  <td
                    colSpan={7}
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Loading…Please wait
                  </td>
                </tr>
              ) : tableData?.length === 0 ? (
                <tr>
                  <td
                    colSpan={"7"}
                    style={{ color: "red", textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </BoxWapper>
    </React.Fragment>
  );
}
