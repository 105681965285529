import React, { Component } from "react";
import classes from "./AssignUser.module.css";
import axios from "../../../../axios-order";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Appbar from "../../../../components/appBar/appBar";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import InputField from "../../../../components/ui/inputField";
import Footer from "../../../../components/footer/footer";
import CheckIcon from "@material-ui/icons/Check";
// import "../../../../HOC/layout/layout.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class AssignUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityUsers: [],
      original_new_user_data: null,
      newEntityUsers: [],
      business_entity_id: localStorage.getItem("business_mgmt_entity_id"),
      entity_name_for_assign_user_page: localStorage.getItem(
        "entity_name_for_assign_user_page"
      ),
      business_entity_notification_flag: false,
    };
  }

  // ---Add user table search---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.addUserTableSearch(event);
    });
  };

  addUserTableSearch = (event) => {
    let { searchInput } = this.state;
    let searchData = this.state.original_new_user_data.filter((value) => {
      const query = searchInput.toLowerCase();
      return (
        value.user_name.toLowerCase().indexOf(query) >= 0 ||
        value.user_role.toLowerCase().indexOf(query) >= 0 ||
        value.status.toLowerCase().indexOf(query) >= 0 ||
        value.user_email_id.toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ newEntityUsers: searchData });
  };

  // --End--

  componentDidMount = () => {
    this.getEntityUser();
    this.getAddUserData();
  };

  getEntityUser = () => {
    toast.configure();
    let business_entity_id = this.state.business_entity_id;
    axios
      .get(`/get_entity_users/${business_entity_id}`)
      .then((res) => {
        if (res.data.records) {
          this.setState({ entityUsers: res.data.records });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ entityUsers: "" });
      });
  };

  getAddUserData = () => {
    toast.configure();
    axios
      .get(`/get_new_entity_users/${this.state.business_entity_id}`)
      .then((res) => {
        if (res.data.records) {
          this.setState({ newEntityUsers: res.data.records });
          this.setState({ original_new_user_data: res.data.records });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDeleteAssignedUser = (user_id) => {
    toast.configure();
    axios
      .delete(
        `/post_delete_entity_user/${this.state.business_entity_id}/${user_id}`
      )
      .then((res) => {
        this.getEntityUser();
        this.getAddUserData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ---Entity user table---
  entityTableHeader() {
    const tableHeader = [
      "user name",
      "user email id",
      "user contact number",
      "user role",
      "status",
      "business notification",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  entityTableData() {
    if (this.state.entityUsers.length) {
      return this.state.entityUsers.map((tableData, index) => {
        const {
          user_name,
          user_email_id,
          user_contact_number,
          user_id,
          user_role,
          business_entity_notification_flag,
          status,
        } = tableData;
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{user_name}</td>
            <td>{user_email_id}</td>
            <td>{user_contact_number}</td>
            <td>{user_role}</td>
            <td style={{ textTransform: "capitalize" }}>{status}</td>
            {business_entity_notification_flag === "True" ? (
              <td>
                <CheckIcon style={{ color: "green" }} />
              </td>
            ) : (
              <td></td>
            )}

            <td>
              <Button
                style={{
                  fontFamily: "Dosis",
                  fontWeight: "650",
                  color: "#d04646",
                }}
                size="small"
                onClick={() => this.handleDeleteAssignedUser(user_id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No Assigned User!
          </td>
        </tr>
      );
    }
  }

  // ---add new  user table---
  addNewUserTableHeader() {
    const tableHeader = [
      "user name",
      "user email id",
      "user contact number",
      "user role",
      "status",
      "business notification",
      "Action",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }
  addNewUserTableData() {
    if (this.state.newEntityUsers.length) {
      return this.state.newEntityUsers.map((tableData, index) => {
        const {
          user_name,
          user_email_id,
          user_contact_number,
          user_id,
          user_role,
          status,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td style={{ textTransform: "capitalize" }}>{user_name}</td>
            <td>{user_email_id}</td>
            <td>{user_contact_number}</td>
            <td>{user_role}</td>
            <td style={{ textTransform: "capitalize" }}>{status}</td>
            <td>
              <Checkbox
                name="business_entity_notification_flag"
                onChange={this.handleCheckBoxChange}
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                size="small"
              />
            </td>
            <td>
              <Button
                style={{
                  fontFamily: "Dosis",
                  fontWeight: "650",
                  color: "#3b3b6b",
                }}
                size="small"
                onClick={() => this.adduserSubmitHandler(user_id, status)}
              >
                add
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  handleCheckBoxChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    this.setState({
      [name]: checked,
    });
  };

  adduserSubmitHandler = (user_id, status) => {
    let add_entity_data = {
      status: status,
      business_entity_notification_flag:
        this.state.business_entity_notification_flag,
      user_id: user_id,
      business_entity_id: parseInt(this.state.business_entity_id),
    };

    axios
      .post("/post_entity_user", add_entity_data)
      .then((res) => {
        this.getAddUserData();
        this.getEntityUser();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   ---Add new User---
  addNewUser = () => {
    this.props.history.push(`/businessEntityManagement/addNewUser`);
  };

  backHanlder = () => {
    localStorage.removeItem("entity_name_for_assign_user_page");
    this.props.history.push(`/businessEntityMgmt`);
  };

  render() {
    return (
      <>
        <Appbar />
        <div className={classes.AssignUserSection}>
          <button
            className="button-outlined-primary"
            onClick={this.backHanlder}
          >
            back
          </button>

          <BoxWrapper
            boxTitle={`Assigned Users for ${localStorage.getItem(
              "entity_name_for_assign_user_page"
            )}`}
          >
            <div className="responsive-table">
              <table className="custome-table">
                <tbody>
                  <tr>{this.entityTableHeader()}</tr>
                  {this.entityTableData()}
                </tbody>
              </table>
            </div>
          </BoxWrapper>

          <BoxWrapper
            boxTitle="Add New User"
            // search input
            enableSearchInput={true}
            searchInputPlaceholder="Search..."
            searchInputOnchange={this.handleSearchInputChange}
          >
            <div className="responsive-table">
              <table className="custome-table">
                <tbody>
                  <tr>{this.addNewUserTableHeader()}</tr>
                  {this.addNewUserTableData()}
                </tbody>
              </table>
            </div>
          </BoxWrapper>
        </div>
        {/* ---Footer--- */}
        <div>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(AssignUser);
