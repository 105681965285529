import React from "react";
import "./dashboard.css";

const VendorTable = (props) => {
  const { data, loader } = props;

  // indian rupeess system---
  const indianRupessFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      // maximumSignificantDigits: 10,
    }).format(value);
  // ---End---
  return (
    <div class="VendorTable">
      <div className="responsive-table">
        <table className="custome-long-table ">
          <thead>
            <tr>
              <th>Vendor Name</th>
              <th>Vendor GSTIN </th>
              <th>Vendor PAN </th>
              <th>ERP Vendor code</th>
              <th>Purchase Manager</th>
              <th>GST Registration Type</th>
              <th>As of Date</th>

              {/* <th>Days Past Due Date</th> */}
              <th>No of Bill due</th>
              <th>No of Bill not Due</th>
              <th>Excess</th>
              {data.length > 0 &&
                Object.keys(data[0]).map((item) => {
                  if (
                    "Vendor Name" === item ||
                    "Vendor GSTIN" === item ||
                    "Vendor PAN" === item ||
                    "GST Registration Type" === item ||
                    "As of Date" === item ||
                    "ERP Vendor Code" === item ||
                    // "Days Past Due Date" === item ||
                    "# Due" === item ||
                    "# Not Due" === item ||
                    "Purchase Manager" === item ||
                    "Org Code" === item ||
                    "# Advance / Excess" === item
                  ) {
                    return false;
                  } else {
                    return <th>{item}</th>;
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr>
                    <td>{item["Vendor Name"] ?? ""}</td>
                    <td>{item["Vendor GSTIN"] ?? ""}</td>
                    <td>{item["Vendor PAN"] ?? ""}</td>
                    <td>{item["ERP Vendor Code"] ?? ""}</td>
                    <td>{item["Purchase Manager"] ?? ""}</td>
                    <td>{item["GST Registration Type"] ?? ""}</td>
                    <td>{item["As of Date"] ?? ""}</td>
                    {/* <td>{item["Days Past Due Date"]}</td> */}
                    <td>{item["# Due"] ?? ""}</td>
                    <td>{item["# Not Due"] ?? ""}</td>
                    <td>{item["# Advance / Excess"] ?? ""}</td>
                    {Object.keys(item).map((v, i) => {
                      if (
                        "Vendor Name" === v ||
                        "Vendor GSTIN" === v ||
                        "Vendor PAN" === v ||
                        "GST Registration Type" === v ||
                        "As of Date" === v ||
                        "ERP Vendor Code" === v ||
                        // "Days Past Due Date" === v ||
                        "# Due" === v ||
                        "# Not Due" === v ||
                        "Purchase Manager" === v ||
                        "Org Code" === v ||
                        "# Advance / Excess" === v
                      ) {
                        return false;
                      } else {
                        return <td>{indianRupessFormat(item[v]) ?? ""}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            {/* no data found */}
            {data.length === 0 && loader === false && (
              <tr>
                <td style={{ color: "red" }}>No Data Found!</td>
              </tr>
            )}

            {loader && (
              <tr>
                <td>Loading.Please wait...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default VendorTable;
