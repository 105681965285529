import React, { useEffect, useState } from "react";
import "../TDSRecon.css";

import BoxWrapper from "../../../../../components/ui/boxWrapper";
import DashboardBarChart from "./dashboardBarChart";

function SYParticulars(props) {
  const { paticulars, RevenueReportData } = props;
  const [revenueReportDataForTable, setRevenueReportDataForTable] =
    useState(RevenueReportData);
  const {
    quarter1Difference,
    quarter1books,
    quarter2Difference,
    quarter2books,
    quarter3Difference,
    quarter3books,
    quarter4Difference,
    quarter4books,
    quarter226As,
    quarter326As,
    quarter426As,
    quarter126As,
    tds26As,
    tdsDifference,
    tdsbooks,
  } = props.paticulars;

  useEffect(() => {
    setRevenueReportDataForTable(RevenueReportData);
  }, [RevenueReportData]);
  const dataForChart = [
    {
      name: "Total TDS As Per Books",
      data: [
        tdsbooks,
        quarter1books,
        quarter2books,
        quarter3books,
        quarter4books,
      ],
    },
    {
      name: "Total TDS As Per 26AS",
      data: [tds26As, quarter126As, quarter226As, quarter326As, quarter426As],
    },
    {
      name: "Difference",
      data: [
        tdsDifference,
        quarter1Difference,
        quarter2Difference,
        quarter3Difference,
        quarter4Difference,
      ],
    },
  ];

  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  return (
    <>
      <BoxWrapper boxTitle="TDS Reconciliation Summary">
        <div>
          <DashboardBarChart
            chartTitle=""
            chartCategories={[
              "TDS",
              "Quarter 1",
              "Quarter 2",
              "Quarter 3",
              "Quarter 4",
            ]}
            yAxisTitle="Amount"
            chartData={dataForChart}
          />
        </div>
        <table className="custome-table">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>TDS</th>
              <th>Quarter 1</th>
              <th>Quarter 2</th>
              <th>Quarter 3</th>
              <th>Quarter 4</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total TDS As Per Books</td>
              <td>{indianRupeesFormat(paticulars.tdsbooks)}</td>
              <td>{indianRupeesFormat(paticulars.quarter1books)}</td>
              <td>{indianRupeesFormat(paticulars.quarter2books)}</td>
              <td>{indianRupeesFormat(paticulars.quarter3books)}</td>
              <td>{indianRupeesFormat(paticulars.quarter4books)}</td>
            </tr>
            <tr>
              <td>Total TDS As Per 26AS</td>
              <td>{indianRupeesFormat(paticulars.tds26As)}</td>
              <td>{indianRupeesFormat(paticulars.quarter126As)}</td>
              <td>{indianRupeesFormat(paticulars.quarter226As)}</td>
              <td>{indianRupeesFormat(paticulars.quarter326As)}</td>
              <td>{indianRupeesFormat(paticulars.quarter426As)}</td>
            </tr>
            <tr>
              <td>Difference</td>
              <td>{indianRupeesFormat(paticulars.tdsDifference)}</td>
              <td>{indianRupeesFormat(paticulars.quarter1Difference)}</td>
              <td>{indianRupeesFormat(paticulars.quarter2Difference)}</td>
              <td>{indianRupeesFormat(paticulars.quarter3Difference)}</td>
              <td>{indianRupeesFormat(paticulars.quarter4Difference)}</td>
            </tr>
          </tbody>
        </table>
        {RevenueReportData.length > 0 && (
          <div style={{ marginTop: "10px" }}>
            <table className="custome-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Period</th>
                  <th colSpan={3}>As Per Books</th>
                  <th colSpan={2}>As Per 26AS</th>
                  <th colSpan={2}>Difference</th>
                </tr>
                <tr>
                  <th>Taxable Value</th>
                  <th>Non-Taxable Value</th>
                  <th>TDS Value</th>
                  <th>Total Amount Paid/Credited</th>
                  <th>TDS Deposited</th>
                  <th>Taxable Amount</th>
                  <th>TDS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{revenueReportDataForTable[0]?.financial_year}</td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.taxable_value_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.taxable_value_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.non_taxable_value_sum <
                          0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.non_taxable_value_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.tds_amount_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.tds_amount_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.amount_paid_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.amount_paid_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.tax_deducted_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.tax_deducted_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.amount_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.amount_sum
                      )}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color:
                          revenueReportDataForTable[0]?.tds_sum < 0
                            ? "red"
                            : "black",
                      }}
                    >
                      {indianRupeesFormat(
                        revenueReportDataForTable[0]?.tds_sum
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </BoxWrapper>
    </>
  );
}

export default SYParticulars;
