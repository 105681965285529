import React, { useState } from "react";
import "../TDSRecon.css";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import DashboardBarChart from "./dashboardBarChart";
import BoxWrapper from "../../../../../components/ui/boxWrapper";

function MYSection(props) {
  const { sectionData } = props;
  const [yearWise, setYearwise] = useState(
    sectionData[0].yearlyWiseData.map((item) => item.financial_year)
  );

  // indian rupeess system---
  const indianRupeesFormat = (value) => {
    if (value != undefined) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumSignificantDigits: 10,
      }).format(value);
    } else {
      return "₹0";
    }
  };

  // ---End---

  const dataForChart = [];

  sectionData[0].yearlyWiseData.map((item) => {
    dataForChart.push({
      name: `FY ${item.financial_year}`,
      data: [],
    });
  });

  sectionData.map((item) => {
    yearWise.map((v, i) => {
      let index = item.yearlyWiseData.findIndex(
        (value) => value.financial_year === v
      );
      if (index >= 0) {
        dataForChart[i].data.push(item.yearlyWiseData[index].tds_amount);
      } else {
        dataForChart[i].data.push(0);
      }
    });
  });

  const obj3 = {
    name: "Grand Total",
    data: [],
  };
  sectionData.map((item) => {
    obj3.data.push(item.grand_total);
  });

  // dataForChart.push(obj3)

  return (
    <BoxWrapper boxTitle="TDS Section wise report - Based on 26AS">
      <div>
        <DashboardBarChart
          chartTitle=""
          chartCategories={sectionData.map((item) => item.section)}
          yAxisTitle="Amount"
          chartData={dataForChart}
        />
      </div>
      <div>
        <table className="custome-table">
          <thead>
            <tr>
              <th>Section</th>
              {sectionData[0].yearlyWiseData.map((item) => {
                return <th>FY {item.financial_year}</th>;
              })}
              <th>Grand Total</th>
            </tr>
          </thead>
          <tbody>
            {sectionData.map((item) => {
              return (
                <tr>
                  <td>{item.section}</td>

                  {yearWise.map((v, i) => {
                    let index = item.yearlyWiseData.findIndex(
                      (value) => value.financial_year === v
                    );
                    if (index >= 0) {
                      return (
                        <td>
                          {indianRupeesFormat(
                            item.yearlyWiseData[index].tds_amount
                          )}
                        </td>
                      );
                    } else {
                      return <td>&nbsp;</td>;
                    }
                  })}

                  <td>{indianRupeesFormat(item.grand_total)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </BoxWrapper>
  );
}

export default MYSection;
