// @flow
import React, { useEffect, useState } from "react";
import "../../../../../HOC/layout/layout.css";
import axios from "../../../../../axios-order";
import { Button, Grid, Drawer } from "@material-ui/core";
import AddTdsForm from "./add";
import EditTdsForm from "./edit";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RightDrawer from "../../../../../components/ui/RightDrawer";

export default function Threshold(props) {
  const [thresholdData, setThresholdData] = useState(null);
  const [thresholdDataForSearch, setThresholdDataForSearch] = useState(null);
  const [addFormDrawerOpen, setAddFormDrawerOpen] = useState(false);
  const [editFormDrawerOpen, setEditFormDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});

  const getThresholdData = () => {
    axios
      .get("/get_tds_threshold_details")
      .then((res) => {
        setThresholdData(res.data.record);
        setThresholdDataForSearch(res.data.record);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getThresholdData();
  }, []);

  // indian date format
  const indianFormatDate = (date) => {
    let dateArr = date.split("-");
    return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
  };

  const SearchEvent = (e) => {
    const search = e.target.value;
    const searchJson = thresholdDataForSearch.filter((v) => {
      return String(v.section).toLowerCase().indexOf(search.toLowerCase()) >= 0;
    });
    setThresholdData(searchJson);
  };

  // indian rupeess system---
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);
  // ---End---

  const tableBodyData = () => {
    if (thresholdData) {
      return thresholdData.map((item, i) => {
        return (
          <tr key={i}>
            <td>{indianFormatDate(item.from_date)}</td>
            <td>{indianFormatDate(item.to_date)}</td>
            <td>{item.section}</td>
            <td>{numberFormat(item.threshold_limit_for_deduction)}</td>
            <td>{item.standard_tds_rate}</td>
            <td>{item.inforced_tds_rate}</td>
            <td style={{ width: "200px" }}>{item.particulars}</td>
            <td>{item.criteria ?? ""}</td>
            <td>
              <Button
                style={{ minWidth: "0px", padding: "1px" }}
                onClick={() => editFormOpenDrawerHandler(item)}
              >
                <EditOutlinedIcon className="action-icon-button editIcon" />
              </Button>{" "}
              |{" "}
              <Button
                style={{ minWidth: "0px", padding: "1px" }}
                onClick={() => deleteTableRow(item.id)}
              >
                <DeleteForeverOutlinedIcon className="action-icon-button deleteIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ textAlign: "center", color: "red" }} colSpan={9}>
            No Data Found!
          </td>
        </tr>
      );
    }
  };

  // .......close add form drawer.....
  const addFormCloseDrawerHandler = () => {
    setAddFormDrawerOpen(false);
  };
  // ........open edit form drawer.....
  const editFormOpenDrawerHandler = (item) => {
    setEditFormDrawerOpen(true);
    setEditData(item);
  };
  // .......close edit form drawer.....
  const editFormCloseDrawerHandler = () => {
    setEditFormDrawerOpen(false);
  };

  // ........delete table row.........
  const deleteTableRow = (id) => {
    let text = "Are you sure! you want to delete this record.";
    if (window.confirm(text) === true) {
      axios
        .delete(`/tds/threshold/delete/${id}`)
        .then((res) => {
          getThresholdData();
        })
        .catch((err) => {});
    } else {
    }
  };

  return (
    <>
      <div style={{ margin: "30px" }}>
        <BoxWrapper
          boxTitle="TDS Threshold"
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={SearchEvent}
          // add button
          enableAddButton={true}
          addButtonLabel="Add"
          addButtonHandlerFn={() => setAddFormDrawerOpen(true)}
        >
          <div className="responsive-table-tds-threshold">
            <table className="custome-table">
              <tbody>
                <tr>
                  <th>FROM DATE</th>
                  <th>TO DATE</th>
                  <th>section</th>
                  <th>threshold limit</th>
                  <th>Standard tds rate</th>
                  <th>In forced tds rate</th>
                  <th>particulars</th>
                  <th>criteria</th>
                  <th>Action</th>
                </tr>
                {tableBodyData()}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      </div>
      {/* ........add tds form drawer.......... */}
      <RightDrawer
        drawerName="Add TDS Threshold"
        open={addFormDrawerOpen}
        onClose={addFormCloseDrawerHandler}
      >
        <AddTdsForm
          updateGetApi={getThresholdData}
          cancelAddForm={addFormCloseDrawerHandler}
        />
      </RightDrawer>
      {/* ........edit tds form drawer........... */}
      <RightDrawer
        drawerName="Edit TDS Threshold"
        open={editFormDrawerOpen}
        onClose={editFormCloseDrawerHandler}
      >
        <EditTdsForm
          updateGetApi={getThresholdData}
          cancelEditForm={editFormCloseDrawerHandler}
          editFormData={editData}
        />
      </RightDrawer>
    </>
  );
}
