import React, { Component } from "react";
import classes from "./assignServices.module.css";
import axios from "../../../../axios-order";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@material-ui/core/Checkbox";
import Appbar from "../../../../components/appBar/appBar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import InputField from "../../../../components/ui/inputField";
import SelectField from "../../../../components/ui/select";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Footer from "../../../../components/footer/footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import ServicesTable from "./servicesTable";
import BoxWrapper from "../../../../components/ui/boxWrapper";

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class AssignServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityDetails: {},
      business_entity_id: localStorage.getItem("business_mgmt_entity_id"),

      ID_ServiceData: {},
      showIDData: false,
      showIDFromDate: true,
      showIDToDate: true,
      showIDReason: false,

      IC_ServiceData: {},
      showICData: false,
      showICFromDate: true,
      showICToDate: true,
      showICReason: false,

      ARC_ServiceData: {},
      showARCData: false,
      showARCFromDate: true,
      showARCToDate: true,
      showARCReason: false,
      ARC_radioButton_value: "",
      loader: false,
      cinLoader: false,
      panLoader: false,
      radiaButtonErrorMsg: "",
      requested_categories: "",
    };
  }

  componentDidMount = () => {
    toast.configure();
    if (this.state.showIDData === true) {
      this.setState((prevState) => ({
        ID_ServiceData: {
          ...prevState.ID_ServiceData,
          service_id: 1,
        },
      }));
    } else if (this.state.showICData === true) {
    } else if (this.state.ARC_radioButton_value === "3") {
    } else if (this.state.ARC_radioButton_value === "4") {
    }

    // ---Entity Data API Call---
    axios
      .get(`/get_entity_data/${this.state.business_entity_id}`)
      .then((res) => {
        this.setState({
          entityDetails: res.data.records,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `/cws_services/get_entity_services/${localStorage.getItem(
          "business_mgmt_entity_id"
        )}`
      )
      .then((res) => {
        this.setState({ requested_categories: res.data.requested_categories });
      });
    // ---End---
    // axios
    //   .get(`/get_entity_services/${this.state.business_entity_id}`)
    //   .then((res) => {
    //     this.setState({
    //       ID_ServiceData: res.data.records[0],
    //       IC_ServiceData: res.data.records[1],
    //       ARC_ServiceData: res.data.records[2],
    //     });
    //     if (
    //       res.data.records[0].status === "Approved" ||
    //       res.data.records[0].status === "Hold" ||
    //       res.data.records[0].status === "Rejected" ||
    //       res.data.records[0].status === "Stop" ||
    //       res.data.records[0].status === "Pending" ||
    //       res.data.records[0].status === "Not Requested"
    //     ) {
    //       this.setState((prevState) => ({
    //         ID_ServiceData: {
    //           ...prevState.ID_ServiceData,
    //           service_id: 1,
    //         },
    //         showIDData: true,
    //       }));

    //       if (res.data.records[0].status === "Pending") {
    //         this.setState({
    //           showIDFromDate: false,
    //           showIDToDate: false,
    //           showIDReason: false,
    //         });
    //       } else if (res.data.records[0].status === "Not Requested") {
    //         this.setState({
    //           showIDFromDate: false,
    //           showIDToDate: false,
    //           showIDReason: false,
    //           showICData: false,
    //         });
    //       } else if (res.data.records[0].status === "Rejected") {
    //         this.setState({
    //           showIDFromDate: false,
    //           showIDToDate: false,
    //           showIDReason: true,
    //         });
    //       } else if (
    //         res.data.records[0].status === "Hold" ||
    //         res.data.records[0].status === "Stop"
    //       ) {
    //         this.setState({
    //           showIDFromDate: true,
    //           showIDToDate: false,
    //           showIDReason: false,
    //         });
    //       }
    //     }
    //     if (
    //       res.data.records[1].status === "Approved" ||
    //       res.data.records[1].status === "Hold" ||
    //       res.data.records[1].status === "Rejected" ||
    //       res.data.records[1].status === "Stop" ||
    //       res.data.records[1].status === "Pending" ||
    //       res.data.records[1].status === "Not Requested"
    //     ) {
    //       this.setState((prevState) => ({
    //         IC_ServiceData: {
    //           ...prevState.IC_ServiceData,
    //           service_id: 2,
    //         },
    //         showICData: true,
    //       }));

    //       if (res.data.records[1].status === "Pending") {
    //         this.setState({
    //           showICFromDate: false,
    //           showICToDate: false,
    //           showICReason: false,
    //         });
    //       } else if (res.data.records[1].status === "Not Requested") {
    //         this.setState({
    //           showICFromDate: false,
    //           showICToDate: false,
    //           showICReason: false,
    //           showICData: false,
    //         });
    //       } else if (res.data.records[1].status === "Rejected") {
    //         this.setState({
    //           showICFromDate: false,
    //           showICToDate: false,
    //           showICReason: true,
    //         });
    //       } else if (
    //         res.data.records[1].status === "Hold" ||
    //         res.data.records[1].status === "Stop"
    //       ) {
    //         this.setState({
    //           showICFromDate: true,
    //           showICToDate: false,
    //           showICReason: false,
    //         });
    //       }
    //     }
    //     if (
    //       res.data.records[2].status === "Approved" ||
    //       res.data.records[2].status === "Hold" ||
    //       res.data.records[2].status === "Rejected" ||
    //       res.data.records[2].status === "Stop" ||
    //       res.data.records[2].status === "Pending" ||
    //       res.data.records[2].status === "Not Requested"
    //     ) {
    //       this.setState({
    //         showARCData: true,
    //       });
    //       // radio button--
    //       if (res.data.records[2].service_id === "ARC Basic (ARC only)") {
    //         this.setState((prevState) => ({
    //           ARC_ServiceData: {
    //             ...prevState.ARC_ServiceData,
    //             service_id: 3,
    //           },
    //           ARC_radioButton_value: "3",
    //         }));
    //       } else {
    //         this.setState((prevState) => ({
    //           ARC_ServiceData: {
    //             ...prevState.ARC_ServiceData,
    //             service_id: 4,
    //           },
    //           ARC_radioButton_value: "4",
    //         }));
    //       }
    //       // ---End---

    //       if (res.data.records[2].status === "Pending") {
    //         this.setState({
    //           showARCFromDate: false,
    //           showARCToDate: false,
    //           showARCReason: false,
    //         });
    //       } else if (res.data.records[2].status === "Rejected") {
    //         this.setState({
    //           showARCFromDate: false,
    //           showARCToDate: false,
    //           showARCReason: true,
    //         });
    //       } else if (
    //         res.data.records[2].status === "Hold" ||
    //         res.data.records[2].status === "Stop"
    //       ) {
    //         this.setState({
    //           showARCFromDate: true,
    //           showARCToDate: false,
    //           showARCReason: false,
    //         });
    //       }

    //       if (res.data.records[2].status === "Not Requested") {
    //         this.setState({
    //           showARCFromDate: false,
    //           showARCToDate: false,
    //           showARCReason: false,
    //           showARCData: false,
    //           ARC_radioButton_value: "",
    //         });
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // ---End--
  };

  // ---Service Checkbox---
  handleIDCheckBoxChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState((prevState) => ({
        ID_ServiceData: {
          ...prevState.ID_ServiceData,
          service_id: 1,
        },
        showIDData: checked,
        showIDFromDate: false,
        showIDToDate: false,
        showIDReason: false,
      }));
    } else {
      this.setState((prevState) => ({
        ID_ServiceData: {
          ...prevState.ID_ServiceData,
          service_id: 2,
          status: "Not Requested",
        },
        showIDData: checked,
      }));
    }
  };

  handleICCheckBoxChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState((prevState) => ({
        IC_ServiceData: {
          ...prevState.IC_ServiceData,
          service_id: 2,
        },
        showICData: checked,
        showICFromDate: false,
        showICToDate: false,
        showICReason: false,
      }));
    } else {
      this.setState((prevState) => ({
        IC_ServiceData: {
          ...prevState.IC_ServiceData,
          service_id: 2,
          status: "Not Requested",
        },
        showICData: checked,
      }));
    }

    this.setState({
      showICData: checked,
    });
  };

  handleARCCheckBoxChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      this.setState((prevState) => ({
        ARC_ServiceData: {
          ...prevState.ARC_ServiceData,
          service_id: 3,
        },
        showARCData: checked,
        showARCFromDate: false,
        showARCToDate: false,
        showARCReason: false,
      }));
    } else {
      this.setState((prevState) => ({
        ARC_ServiceData: {
          ...prevState.ARC_ServiceData,
          service_id: 2,
          status: "Not Requested",
        },
        showARCData: checked,
        radiaButtonErrorMsg: "",
        ARC_radioButton_value: "",
      }));
    }
  };

  // ---input && select field ---
  handleEntityServiceChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "ID_status") {
      if (value === "Rejected") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showIDReason: true,
          showIDToDate: false,
          showIDFromDate: false,
        }));
      } else if (value === "Hold" || value === "Stop") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showIDToDate: false,
          showIDFromDate: true,
          showIDReason: false,
        }));
      } else if (value === "Approved") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showIDToDate: true,
          showIDFromDate: true,
          showIDReason: false,
        }));
      } else if (value === "Pending") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showIDToDate: false,
          showIDFromDate: false,
          showIDReason: false,
        }));
      } else {
        this.setState({
          showIDToDate: false,
          showIDFromDate: false,
          showIDReason: false,
        });
      }
      // ------

      this.setState((prevState) => ({
        ID_ServiceData: {
          ...prevState.ID_ServiceData,
          status: String(value),
        },
      }));
    } else if (name === "ID_reason") {
      this.setState((prevState) => ({
        ID_ServiceData: {
          ...prevState.ID_ServiceData,
          reason: String(value),
        },
      }));
    } else if (name === "IC_status") {
      // ------
      if (value === "Rejected") {
        this.setState((prevState) => ({
          IC_ServiceData: {
            ...prevState.IC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showICReason: true,
          showICToDate: false,
          showICFromDate: false,
        }));
      } else if (value === "Hold" || value === "Stop") {
        this.setState((prevState) => ({
          IC_ServiceData: {
            ...prevState.IC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showICToDate: false,
          showICFromDate: true,
          showICReason: false,
        }));
      } else if (value === "Approved") {
        this.setState((prevState) => ({
          IC_ServiceData: {
            ...prevState.IC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showICToDate: true,
          showICFromDate: true,
          showICReason: false,
        }));
      } else {
        this.setState({
          showICToDate: false,
          showICFromDate: false,
          showICReason: false,
        });
      }
      // -----
      this.setState((prevState) => ({
        IC_ServiceData: {
          ...prevState.IC_ServiceData,
          status: String(value),
        },
      }));
    } else if (name === "ic_reason") {
      this.setState((prevState) => ({
        IC_ServiceData: {
          ...prevState.IC_ServiceData,
          reason: String(value),
        },
      }));
    } else if (name === "arc_status") {
      // ------
      if (value === "Rejected") {
        this.setState((prevState) => ({
          ARC_ServiceData: {
            ...prevState.ARC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showARCReason: true,
          showARCToDate: false,
          showARCFromDate: false,
        }));
      } else if (value === "Hold" || value === "Stop") {
        this.setState((prevState) => ({
          ARC_ServiceData: {
            ...prevState.ARC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showARCToDate: false,
          showARCFromDate: true,
          showARCReason: false,
        }));
      } else if (value === "Approved") {
        this.setState((prevState) => ({
          ARC_ServiceData: {
            ...prevState.ARC_ServiceData,
            from_date: null,
            to_date: null,
            reason: null,
          },
          showARCToDate: true,
          showARCFromDate: true,
          showARCReason: false,
        }));
      } else {
        this.setState({
          showARCToDate: false,
          showARCFromDate: false,
          showARCReason: false,
        });
      }
      // -----
      this.setState((prevState) => ({
        ARC_ServiceData: {
          ...prevState.ARC_ServiceData,
          status: String(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        ARC_ServiceData: {
          ...prevState.ARC_ServiceData,
          reason: String(value),
        },
      }));
    }
  };
  // ----input && select field End---

  // ---From date change for service---
  handleFromDateChange(v, name) {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      if (name === "ID_from_date") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      } else if (name === "ic_from_date") {
        this.setState((prevState) => ({
          IC_ServiceData: {
            ...prevState.IC_ServiceData,
            from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ARC_ServiceData: {
            ...prevState.ARC_ServiceData,
            from_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      }
    } else {
      return false;
    }
  }

  // ---To date change for service---
  handleToDateChange(v, name) {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      if (name === "ID_to_date") {
        this.setState((prevState) => ({
          ID_ServiceData: {
            ...prevState.ID_ServiceData,
            to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      } else if (name === "ic_to_date") {
        this.setState((prevState) => ({
          IC_ServiceData: {
            ...prevState.IC_ServiceData,
            to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ARC_ServiceData: {
            ...prevState.ARC_ServiceData,
            to_date: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
          },
        }));
      }
    } else {
      return false;
    }
  }

  // ---Assign service submit hanlder---
  AssignServiceSubmitHandler = () => {
    const dataObject = {
      ID: this.state.ID_ServiceData,
      IC: this.state.IC_ServiceData,
      ARC: this.state.ARC_ServiceData,
    };
    axios
      .post(
        `/post_entity_services/${this.state.business_entity_id}`,
        dataObject
      )
      .then((res) => {
        toast.success("Service successfully assigned.", {
          autoClose: 3000,
          progress: 0,
        });
      })
      .catch((error) => {
        toast.error(
          "some error occurred,Please contact system administrator!",
          {
            autoClose: 3000,
            progress: 0,
          }
        );
      });
  };

  backHanlder = () => {
    this.props.history.goBack();
  };

  handleChange = (event) => {
    // alert("1");
  };

  handleVerifyGSTIN = (event) => {
    event.preventDefault();
    this.setState({
      gstinLoader: true,
    });
    axios
      .get(`/validateGSTIN/${this.state.business_entity_id}`)
      .then((res) => {
        this.setState({
          verifyGSTIN: res.data.status,
          verifyGSTINError: res.data.message,
          gstinLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          verifyGSTINError: error.response.data.message,
          gstinLoader: false,
        });
      });
  };

  handleVerifyCIN = (event) => {
    event.preventDefault();
    this.setState({
      cinLoader: true,
    });
    axios
      .get(`/validateCIN/${this.state.business_entity_id}`)
      .then((res) => {
        this.setState({
          verifyCIN: res.data.status,
          verifyCINError: res.data.message,
          cinLoader: false,
        });
      })
      .catch((error) => {
        this.setState({
          verifyCINError: error.response.data.message,
          cinLoader: false,
        });
      });
  };

  handleVerifyPAN = (event) => {
    event.preventDefault();
    this.setState({
      panLoader: true,
    });
    axios
      .get(`/validatePAN/${this.state.business_entity_id}`)
      .then((res) => {
        this.setState({
          verifyPAN: res.data.status,
          verifyPANError: res.data.message,
          panLoader: false,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.setState({
          verifyPANError: error.response.data.message,
          panLoader: false,
        });
      });
  };

  render() {
    return (
      <>
        <Appbar />
        <div className={classes.AssignServicesSection}>
          {/* BACK BUTTON */}
          <button
            className="button-outlined-primary"
            variant="contained"
            size="small"
            onClick={this.backHanlder}
          >
            back
          </button>
          {/* BUSINESS ENTITY DETAILS */}

          <BoxWrapper
            boxTitle={`Entity Details : ${this.state.entityDetails.entity_name}`}
          >
            {/* Entity Details */}
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ paddingRight: "0px", border: "none" }}
                        >
                          Constitution :
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px", border: "none" }}
                        >
                          {this.state.entityDetails.constitution_id}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            minWidth: 100,
                            paddingRight: "0px",
                            border: "none",
                          }}
                        >
                          Business Sector :
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px", border: "none" }}
                        >
                          {this.state.entityDetails.business_sector_id}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            minWidth: 100,
                            paddingRight: "0px",
                            border: "none",
                          }}
                        >
                          Entity Address :
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px", border: "none" }}
                        >
                          {this.state.entityDetails.entity_address}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            minWidth: 180,
                            paddingRight: "0px",
                            border: "none",
                          }}
                        >
                          Mobile Number :
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px", border: "none" }}
                        >
                          {this.state.entityDetails.contact_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            minWidth: 180,
                            paddingRight: "0px",
                            border: "none",
                          }}
                        >
                          Requested Categories:
                        </TableCell>
                        <TableCell
                          style={{ paddingLeft: "0px", border: "none" }}
                        >
                          {this.state.requested_categories === null ? (
                            <span style={{ color: "red" }}>Not Requested</span>
                          ) : (
                            this.state.requested_categories
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={7}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ border: "none" }}>
                          GSTIN :{" "}
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.entityDetails.gst}
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.gstinLoader === true ? (
                            <CircularProgress size={30} thickness={5} />
                          ) : (
                            <>
                              <a
                                href="javscript:void(0)"
                                style={{ textDecoration: "none" }}
                                onClick={this.handleVerifyGSTIN}
                              >
                                {this.state.verifyGSTIN ||
                                this.state.entityDetails.is_gstin_verified === 1
                                  ? "Verified - Verify Again"
                                  : "Verify"}
                              </a>
                              <div style={{ color: "red" }}>
                                {this.state.verifyGSTINError ||
                                  (this.state.entityDetails
                                    .gstin_verify_return === ""
                                    ? ""
                                    : this.state.entityDetails
                                        .gstin_verify_return)}
                              </div>
                            </>
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ border: "none" }}>PAN : </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.entityDetails.pan}
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.panLoader === true ? (
                            <CircularProgress size={30} thickness={5} />
                          ) : (
                            <>
                              <a
                                href="javscript:void(0)"
                                style={{ textDecoration: "none" }}
                                onClick={this.handleVerifyPAN}
                              >
                                {this.state.verifyPAN ||
                                this.state.entityDetails.is_pan_verified
                                  ? "Verified - Verify Again"
                                  : "Verify"}
                              </a>
                              <div style={{ color: "red" }}>
                                {this.state.verifyPANError ||
                                  (this.state.entityDetails
                                    .pan_verify_return === ""
                                    ? ""
                                    : this.state.entityDetails
                                        .pan_verify_return)}
                              </div>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ minWidth: 180, border: "none" }}>
                          CIN :{" "}
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.entityDetails.cin}
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.cinLoader === true ? (
                            <CircularProgress size={30} thickness={5} />
                          ) : (
                            <>
                              <a
                                href="javscript:void(0)"
                                style={{ textDecoration: "none" }}
                                onClick={this.handleVerifyCIN}
                              >
                                {this.state.verifyCIN ||
                                this.state.entityDetails.is_cin_verified
                                  ? "Verified - Verify Again"
                                  : "Verify"}
                              </a>
                              <div style={{ color: "red" }}>
                                {this.state.verifyCINError ||
                                  (this.state.entityDetails
                                    .cin_verify_return === ""
                                    ? ""
                                    : this.state.entityDetails
                                        .cin_verify_return)}
                              </div>
                            </>
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ minWidth: 180, border: "none" }}>
                          MSME :
                        </TableCell>
                        <TableCell style={{ border: "none" }}>
                          {this.state.entityDetails.msme_flag}
                        </TableCell>
                        <TableCell style={{ border: "none" }}></TableCell>
                      </TableRow>

                      {/* <TableRow>
                        <TableCell style={{ minWidth: 150 }}>
                          Opted Services :
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                        {this.state.entityDetails.msme_flag}
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* Services */}

            <div style={{ borderBottom: "2px solid #ddd", marginTop: "20px" }}>
              <h5 style={{ fontFamily: "Dosis", fontWeight: "550" }}>
                Services
              </h5>
            </div>
            <div style={{ marginBottom: "50px" }}>
              <div>
                <ServicesTable />
              </div>
            </div>
          </BoxWrapper>

          {/* ASSIGN SERVICE FOR USER V.1.O*/}
          {/* <div className={classes.paperHeader}>User Services V.1.O</div>
          <div style={{ marginBottom: "100px" }}>
            <ValidatorForm
              ref="form"
              onSubmit={this.AssignServiceSubmitHandler}
              onError={(errors) => console.log(errors)}
            >
              <Grid
                container
                spacing={3}
                style={{
                  paddingLeft: "30px",
                  marginTop: "5px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
               
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleIDCheckBoxChange}
                          name="checkedB"
                          color="primary"
                          checked={this.state.showIDData}
                        />
                      }
                      label="Invoice Discounting"
                    />
                  </Grid>
                </Grid>
                {this.state.showIDData && (
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingLeft: "40px", paddingRight: "40px" }}
                  >
                    <Grid item xs>
                      <SelectField
                        SelectLabel="Status"
                        choice_id="ID_status_name"
                        choice_name="ID_status_name"
                        choices={[
                          {
                            ID_status_id: "1",
                            ID_status_name: "Not Requested",
                          },
                          { ID_status_id: "2", ID_status_name: "Approved" },
                          { ID_status_id: "3", ID_status_name: "Rejected" },
                          { ID_status_id: "4", ID_status_name: "Stop" },
                          { ID_status_id: "5", ID_status_name: "Hold" },
                          { ID_status_id: "6", ID_status_name: "Pending" },
                        ]}
                        name="ID_status"
                        value={
                          this.state.ID_ServiceData.status ?? "Not Requested"
                        }
                        validator={["required"]}
                        requiredField="*"
                        errorMsg={["This field is required"]}
                        textOnchangeFunction={this.handleEntityServiceChange}
                      />
                    </Grid>
                    {this.state.showIDFromDate && (
                      <Grid item xs>
                        <div style={{ marginTop: "20px" }}>
                          <label>
                            From Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            name="ID_from_date"
                            maxDate={this.state.ID_ServiceData.to_date ?? ""}
                            value={this.state.ID_ServiceData.from_date ?? ""}
                            onChange={(v) =>
                              this.handleFromDateChange(v, "ID_from_date")
                            }
                            inputProps={{ readOnly: true }}
                            validators={["required"]}
                            errorMessages={["Date is required"]}
                          />
                        </div>
                      </Grid>
                    )}
                    {this.state.showIDToDate && (
                      <Grid item xs>
                        <div style={{ marginTop: "20px" }}>
                          <label>
                            To Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            minDateForDatepicker={
                              this.state.ID_ServiceData.from_date
                            }
                            name="to_date"
                            value={this.state.ID_ServiceData.to_date ?? ""}
                            onChange={(v) =>
                              this.handleToDateChange(v, "ID_to_date")
                            }
                            validators={["required"]}
                            inputProps={{ readOnly: true }}
                            errorMessages={["Date is required"]}
                          />
                        </div>
                      </Grid>
                    )}
                    {this.state.showIDReason && (
                      <Grid item xs>
                        <InputField
                          inputType="text"
                          textLabel="Reason"
                          name="ID_reason"
                          value={this.state.ID_ServiceData.reason ?? ""}
                          textOnchangeFunction={this.handleEntityServiceChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
               
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                 
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleICCheckBoxChange}
                          name="checkedB"
                          color="primary"
                          checked={this.state.showICData}
                        />
                      }
                      label="Invoice Collection"
                    />
                  </Grid>
                </Grid>
                {this.state.showICData && (
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingLeft: "40px", paddingRight: "40px" }}
                  >
                    <Grid item xs>
                      <SelectField
                        SelectLabel="Status"
                        choice_id="IC_status_name"
                        choice_name="IC_status_name"
                        name="IC_status"
                        choices={[
                          {
                            IC_status_id: "1",
                            IC_status_name: "Not Requested",
                          },
                          { IC_status_id: "2", IC_status_name: "Approved" },
                          { IC_status_id: "3", IC_status_name: "Rejected" },
                          { IC_status_id: "4", IC_status_name: "Stop" },
                          { IC_status_id: "5", IC_status_name: "Hold" },
                          { IC_status_id: "6", IC_status_name: "Pending" },
                        ]}
                        value={
                          this.state.IC_ServiceData.status ?? "Not Requested"
                        }
                        validator={["required"]}
                        requiredField="*"
                        errorMsg={["This field is required"]}
                        textOnchangeFunction={this.handleEntityServiceChange}
                      />
                    </Grid>
                    {this.state.showICFromDate && (
                      <Grid item xs>
                        <div style={{ marginTop: "20px" }}>
                          <label>
                            From Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            name="ic_from_date"
                            inputProps={{ readOnly: true }}
                            maxDate={this.state.IC_ServiceData.to_date ?? ""}
                            value={this.state.IC_ServiceData.from_date ?? ""}
                            onChange={(v) =>
                              this.handleFromDateChange(v, "ic_from_date")
                            }
                            validators={["required"]}
                            errorMessages={["Date is required"]}
                          />
                        </div>
                      </Grid>
                    )}
                    {this.state.showICToDate && (
                      <Grid item xs>
                        <div style={{ marginTop: "20px" }}>
                          <label>
                            To Date
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <ValidatedDatePicker
                            minDateForDatepicker={
                              this.state.IC_ServiceData.from_date
                            }
                            name="ic_to_date"
                            inputProps={{ readOnly: true }}
                            value={this.state.IC_ServiceData.to_date ?? ""}
                            onChange={(v) =>
                              this.handleToDateChange(v, "ic_to_date")
                            }
                            validators={["required"]}
                            errorMessages={["Date is required"]}
                          />
                        </div>
                      </Grid>
                    )}
                    {this.state.showICReason && (
                      <Grid item xs>
                        <InputField
                          inputType="text"
                          textLabel="Reason"
                          name="ic_reason"
                          value={this.state.IC_ServiceData.reason ?? ""}
                          textOnchangeFunction={this.handleEntityServiceChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
               
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleARCCheckBoxChange}
                          name="checkedB"
                          color="primary"
                          checked={this.state.showARCData}
                        />
                      }
                      label="ARC (Accounting, Reconciliation & Compliance)"
                    />
                  </Grid>
                </Grid>
                {this.state.showARCData && (
                  <>
                    <Grid container spacing={3}></Grid>

                    <Grid
                      container
                      spacing={3}
                      style={{ paddingLeft: "40px", paddingRight: "40px" }}
                    >
                      <Grid item xs>
                        <SelectField
                          SelectLabel="Status"
                          choice_id="arc_status_name"
                          choice_name="arc_status_name"
                          name="arc_status"
                          choices={[
                            {
                              arc_status_id: "1",
                              arc_status_name: "Not Requested",
                            },
                            {
                              arc_status_id: "2",
                              arc_status_name: "Approved",
                            },
                            { arc_status_id: "3", arc_status_name: "Rejected" },
                            { arc_status_id: "4", arc_status_name: "Stop" },
                            { arc_status_id: "5", arc_status_name: "Hold" },
                            {
                              arc_status_id: "6",
                              arc_status_name: "Pending",
                            },
                          ]}
                          value={
                            this.state.ARC_ServiceData.status ?? "Not Requested"
                          }
                          validator={["required"]}
                          requiredField="*"
                          errorMsg={["This field is required"]}
                          textOnchangeFunction={this.handleEntityServiceChange}
                        />
                      </Grid>
                      {this.state.showARCFromDate && (
                        <Grid item xs>
                          <div style={{ marginTop: "20px" }}>
                            <label>
                              From Date
                              <span style={{ color: "red" }}>*</span>:
                            </label>
                            <ValidatedDatePicker
                              name="arc_from_date"
                              inputProps={{ readOnly: true }}
                              maxDate={this.state.ARC_ServiceData.to_date ?? ""}
                              value={this.state.ARC_ServiceData.from_date ?? ""}
                              onChange={(v) => this.handleFromDateChange(v)}
                              validators={["required"]}
                              errorMessages={["Date is required"]}
                            />
                          </div>
                        </Grid>
                      )}
                      {this.state.showARCToDate && (
                        <Grid item xs>
                          <div style={{ marginTop: "20px" }}>
                            <label>
                              To Date
                              <span style={{ color: "red" }}>*</span>:
                            </label>
                            <ValidatedDatePicker
                              minDateForDatepicker={
                                this.state.ARC_ServiceData.from_date
                              }
                              name="arc_to_date"
                              inputProps={{ readOnly: true }}
                              value={this.state.ARC_ServiceData.to_date ?? ""}
                              onChange={(v) => this.handleToDateChange(v)}
                              validators={["required"]}
                              errorMessages={["Date is required"]}
                            />
                          </div>
                        </Grid>
                      )}
                      {this.state.showARCReason && (
                        <Grid item xs>
                          <InputField
                            inputType="text"
                            textLabel="Reason"
                            name="arc_reason"
                            value={this.state.ARC_ServiceData.reason ?? ""}
                            textOnchangeFunction={
                              this.handleEntityServiceChange
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              
              </Grid>
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
               
              >
                save
              </Button>
            </ValidatorForm>
          </div> */}
        </div>

        {/* ---Footer--- */}
        <div>
          <Footer />
        </div>
        {/* ---Footer End--- */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(AssignServices);
